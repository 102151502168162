"use strict";

angular.module("prisma")

    .directive("colored", function ($timeout, colors) {
        return {
            link: function (scope, element, attrs) {


                scope.$watch(function () {
                    return element.css("border-bottom-color");
                }, styleChangedCallBack, true);

                attrs.$observe("subcategory", function (val) {
                    setBackgroundColor(attrs.catColor, val);
                });


                function styleChangedCallBack(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        attrs.catColor = newValue;
                        setBackgroundColor(attrs.catColor, attrs.subcategory);
                    }
                }

                function setBackgroundColor(categoryColor, subCategory) {

                    var colorName = colors.getSubcategoryColor(categoryColor, subCategory);

                    element.css("background-color", colorName);
                }
            }
        }

    });




