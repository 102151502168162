angular.module("prisma")
    .controller("RolesCtrl", function ($scope, adminService, authService, $timeout, $stateParams, $filter, ngDialog) {
        var self = this;

        self.isLoading = true;
        self.roles = [];
        self.permissions = [];
        self.searchPermission = "";
        self.readOnly = authService.hasPermission('admin_security_readOnly');

        self.showAssignPermissionsToRole = function (role) {

            self.editRole = role;

            //marco los permisos que el usuario ya tiene
            angular.forEach(self.permissions, function (permission) {
                permission.selected = self.roleHasPermission(role, permission);
            });


            ngDialog.open({
                template: 'assignPerissionsDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            });
        }

        self.newRole = function () {
            swal({
                title: "Ingrese nombre del Rol",
                text: "Recuerde que luego debera asignarle permisos",
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                animation: "slide-from-top",
                inputPlaceholder: "Nombre del Rol"
            },
                function (inputValue) {
                    if (inputValue === false) return false;

                    if (inputValue.length < 2) {
                        swal.showInputError("El Rol debe tener al menos 2 caracteres");
                        return false
                    }
                    swal({
                        title: "Ingrese una justificación",
                        text: "Debe tener 10 caracteres como mínimo",
                        type: "input",
                        showCancelButton: true,
                        confirmButtonColor: "#1AB394",
                        confirmButtonText: "Guardar",
                        cancelButtonText: "Cancelar",
                        showLoaderOnConfirm: true,
                        closeOnConfirm: false,
                        closeOnCancel: true
                    },
                        function (observations) {
                            if (observations === false) return;
                            if (observations.trim().length > 9) {
                                adminService.security.createRole(inputValue, observations)
                                    .then(function () {
                                        swal("Rol Creado", "Recordá asignarle los permisos necesarios a: " + inputValue, "success");
                                        init();
                                    }, function () {
                                        swal("Error", "Ocurrio un error al guardar el rol", "error");
                                    });
                            }
                            else swal.showInputError('Minimo 10 caracteres');

                        });


                });
        }

        self.roleHasPermission = function (role, permission) {

            var hasPermission = false;

            angular.forEach(role.permissions, function (permissionName) {
                if (permission.name == permissionName)
                    hasPermission = true;
            });

            return hasPermission;
        }

        self.assignPermissionsToRole = function () {
            swal({
                title: "Ingrese una justificación",
                text: "Debe tener 10 caracteres como mínimo",
                type: "input",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Guardar",
                cancelButtonText: "Cancelar",
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (observations) {
                    if (observations === false) return;
                    if (observations.trim().length > 9) {
                        swal({
                            title: "¿Confirma los cambios?",
                            text: "Se van modificar los permisos del Rol",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#1AB394",
                            confirmButtonText: "Continuar",
                            cancelButtonText: "Cancelar",
                            showLoaderOnConfirm: true,
                            closeOnConfirm: false,
                            closeOnCancel: true
                        },
                            function (isConfirm) {
                                if (isConfirm) {

                                    var assignPermissionsToRoleDto = {
                                        roleId: self.editRole.id,
                                        permissions: [],
                                        observations: observations
                                    }

                                    angular.forEach(self.permissions, function (permission) {
                                        if (permission.selected)
                                            assignPermissionsToRoleDto.permissions.push(permission.name);
                                    });

                                    adminService.security.assignPermissionsToRole(assignPermissionsToRoleDto).then(function () {
                                        ngDialog.close();

                                        self.editRole.permissions = [];
                                        angular.forEach(self.permissions, function (permission) {
                                            if (permission.selected)
                                                self.editRole.permissions.push(permission.name);
                                        });

                                        swal('Permisos Asignados', 'Se asignaron correctamente los permisos al rol', 'success');
                                    });

                                }
                            });
                    }
                    else swal.showInputError('Minimo 10 caracteres');

                });

        }

        self.selectAllPermissions = function () {
            var filteredPermissions = $filter('filter')(self.permissions, self.searchPermission);

            var doSelect = undefined;
            angular.forEach(filteredPermissions, function (permission) {
                if (doSelect == undefined)
                    doSelect = !permission.selected;

                permission.selected = doSelect;
            });
        }

        function init() {

            adminService.security.getPermissions().then(function (permissions) {
                self.permissions = permissions;

                adminService.security.getRoles().then(function (roles) {
                    self.roles = roles;
                    self.isLoading = false;
                });

            });

        }

        init();
    });