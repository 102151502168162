
angular.module("prisma")
    .filter('abbreviate', ['$filter', function ($filter) {
        return function (input, p1, p2) {
            var precision = 2;
            var prefix = null;

            if (p1 && typeof (p1) === "number") {
                precision = p1;
            }
            else if (p2 && typeof (p2) === "number") {
                precision = p2;
            }

            if (p1 && typeof (p1) === "string") {
                prefix = p1;
            }
            else if (p2 && typeof (p2) === "string") {
                prefix = p2;
            }

            return ian.abbreviateNumber(input, precision, prefix);
        };
    }]);



