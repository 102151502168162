angular.module("prisma")
    .controller("AssortmentDecisionsCtrl", function ($scope, $rootScope, $timeout, $interval, $stateParams, $state, $filter, assortmentService, adminService, ngDialog, companyJson) {

        /*** PRIVATE VARIABLES ***/
        var numberColWidth = 100;
        var editableColWidth = 140;
        var columnDefs = null;
        var totalMarketSalesActiveItems = 0;
        var totalMarketSales = 0;
        var selectedFilterForText = '';
        var self = this;
        var y;

        /*** PUBLIC PROPERTIES ***/
        self.title = null;
        self.isEndorse = false;
        self.busy = false;
        self.currentCategoryId = $stateParams.subcategoryId != 0 ? $stateParams.subcategoryId : $stateParams.categoryId;
        self.itemStatus = ['Todos', 'Activo', 'Inactivo'];
        self.itemClusters = ['Todos'];
        self.dropDrowncluster = { isopen: false };
        self.dropDrownstatus = { isopen: false };
        self.onlyEndorse = [];
        self.allEndorse = [];
        self.selectedFilterForStatus = 'Todos';
        self.selectedFilterForCluster = 'Todos';
        self.selectedFilterForAssortmentStatus = 'Todos';
        self.isEndorse = $stateParams.isEndorse ? JSON.parse($stateParams.isEndorse) : false;
        self.selectedFilterForStatus = self.isEndorse ? 'Para Validar' : 'Todos';
        self.gridOptions = null;
        self.pareto = null;
        self.tags = [];
        self.isLoadingReport = false;
        self.clusters = [];
        self.allClusters = [];
        self.categoryDetails = null;
        self.actualCoverage = 0;
        self.actualSuggested = 0;
        self.actualNew = 0;
        self.totalItemClusters = 0;
        self.totalActiveItemClusters = 0;
        self.listingSuggestions = 0;
        self.listingNew = 0;
        self.delistingNew = 0;
        self.delistingSuggestions = 0;
        self.impact = 0;
        self.pendingImpact = 0;
        self.totalSales = 0;
        self.timerange = 'year';
        self.paretoSections = null;
        self.showParam1 = false;
        self.showPresence2 = false;
        self.showUnitsTotal = true;
        self.showSalesTotal = true;
        self.showWasteUnits = true;
        self.showPlanogramEdition = companyJson.Navigation.Spaces;
        self.filters = [];
        self.matrixOfIndexes = [];
        self.loadGrid = false;
        self.totalSalesOnColumnGroupShow = null;//se muestra la venta TOTAL sin desplegar
        self.salesOnColumnGroupShow = null;//se muestra la venta sin desplegar

        self.showPresence2 = companyJson.AssortmentDecisions.Grid.ShowPresence2; //Mostrar u ocultar Presence2
        self.showParam1 = companyJson.AssortmentDecisions.Grid.Param1;
        self.hideSpaces = companyJson.AssortmentDecisions && companyJson.AssortmentDecisions.Grid && companyJson.AssortmentDecisions.Grid.HideSpaces;
        self.showWasteUnits = companyJson.AssortmentDecisions.Grid.WasteUnits;
        self.PrevCategory = (companyJson.AssortmentDecisions.Grid ? companyJson.AssortmentDecisions.Grid.PrevCategory : false);
        self.PrevSubCategory = (companyJson.AssortmentDecisions.Grid ? companyJson.AssortmentDecisions.Grid.PrevSubCategory : false);

        //estos setColumnVisible estan administrados desde columndef por la propiedad hide
        //$timeout(function () {

            //self.gridOptions.columnApi.setColumnVisible('presence', !self.showPresence2); //Presencia 1
            //self.gridOptions.columnApi.setColumnVisible('presence2', self.showPresence2); //Presencia 2 (Cenco)

            //self.gridOptions.columnApi.setColumnVisible('param1', self.showParam1); //Param1 (Cenco colombia)
            //self.gridOptions.columnApi.setColumnVisible('param2', self.showParam1); //Param1 (Cenco colombia)
            //self.gridOptions.columnApi.setColumnVisible('param3', self.showParam1); //Param1 (Cenco colombia)

            //self.gridOptions.columnApi.setColumnVisible('unitsTotal', self.showUnitsTotal);
            //self.gridOptions.columnApi.setColumnVisible('salesTotal', self.showSalesTotal);

            //self.gridOptions.columnApi.setColumnVisible('currentAdjust', self.showWasteUnits);


        //}, 0);

        /*** PUBLIC FUNCTIONS ***/
        //actions
        self.showItemInDashboard = function (itemId, clusterId) {
            $state.go('home.itemDashboard', { 'itemId': itemId, 'clusterId': clusterId });
        }

        self.endorse = function () {
            swal({
                title: "Esta seguro?",
                text: 'Esta seguro que desea guardar los cambios realizados?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        var itemsToSave = [];
                        self.gridOptions.api.forEachNode(function (node) {
                            var item = node.data;

                            if ((item.assortmentStatus == 1 || item.isSuggestionIgnored) && node.selected && node.group === undefined)
                                itemsToSave.push(item);
                        });

                        if (itemsToSave.length > 0) {
                            assortmentService.decisions.saveDecisions(itemsToSave, true, $stateParams.categoryId)
                                .then(function () {
                                    //ReloadGrid
                                    loadData(true);
                                    swal("Confirmado", "Se guardaron los cambios seleccionados", "success");
                                },
                                    function (error) {
                                        swal("Error", "Ha ocurrido un error", "error");
                                    });

                        }
                        else
                            swal("Sin Items", "No hay items seleccionados", "error");

                    }
                    else {
                        swal("Cancelado", "Se ignoraron las decisiones seleccionadas", "error");
                    }
                });
        }
        self.reject = function () {
            if (self.gridOptions.api.getSelectedNodes().length > 0) {
                swal({
                    title: "Comentario de rechazo",
                    text: "Ingrese un comentario por el cual se rechazaron los items sugeridos:",
                    type: "input",
                    showCancelButton: true,
                    closeOnConfirm: false,
                    showLoaderOnConfirm: true,
                    animation: "slide-from-top",
                    //inputPlaceholder: ""
                },
                    function (inputValue) {
                        if (inputValue === false)
                            return false;

                        var assortmentIdsToReject = [];

                        // rechazo solo los seleccionados.
                        angular.forEach(self.gridOptions.api.getSelectedNodes(), function (node, key) {
                            var item = node.data;

                            if ((item.assortmentStatus == 1 || item.isSuggestionIgnored) && node.selected && node.group === undefined)
                                assortmentIdsToReject.push(item.assortmentId);
                        });

                        if (assortmentIdsToReject.length > 0) {
                            // Guardar cambios
                            var currentUrl = window.location.href;
                            assortmentService.decisions.rejectEndorse(assortmentIdsToReject, inputValue, currentUrl, $stateParams.categoryId)
                                .then(function () {
                                    //ReloadGrid
                                    loadData(true);
                                    swal("Items Rechazados", "Se han rechazado los items seleccionados", "success");
                                },
                                    function (fallback) {
                                        swal("Error", "Ha ocurrido un error", "error");
                                    }
                                );
                        }
                    });
            }
        }
        self.exportToExcel = function () {
            var params = {
                skipHeader: false,
                skipFooters: false,
                skipGroups: false,
                allColumns: false,
                onlySelected: false,
                suppressQuotes: false,
                fileName: self.category + '.xls',
                columnSeparator: ','
            };

            self.gridOptions.api.exportDataAsCsv(params);
        }
        self.markAsReview = function (showModal) {
            self.busy = true;
            assortmentService.decisions.updateLastRevisionDate(self.currentCategoryId)
                .then(function (resp) {
                    if (showModal) {
                        swal("Categoria revisada!", "Se marco como revisada a esta categoria", "success");
                    }
                },
                    function (fallback) {
                        swal("Error", "Hubo un error al guardar los cambios", "error");
                    });

            self.busy = false;
        }
        self.updateCoverage = function () {
            swal({
                title: 'Modificar Cobertura',
                text: 'Ingrese el valor de cobertura a incorporar:',
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                animation: "slide-from-top",
                inputPlaceholder: "Valor en %"
            },
                function (inputValue) {
                    if (inputValue === false)
                        return false;
                    if (inputValue === "") {
                        swal.showInputError("Debe ingresar un valor");
                        return false
                    }
                    inputValue = inputValue.replace(',', '.');
                    if (isNaN(inputValue)) {
                        swal.showInputError("Debe ingresar un valor numerico");
                        return false
                    }

                    //Saco todos los filtros
                    removeFilters();

                    if (self.actualCoverage * 100 < parseInt(inputValue)) {
                        self.gridOptions.api.forEachNode(function (node) {
                            //Esto solo considera subir la cobertura actual
                            if (!node.group && self.actualCoverage * 100 < parseInt(inputValue)) {
                                if ((node.data.newIsListed == false || (node.data.newIsListed == null && node.data.isListed == true)) && node.data.marketSales > 0) {
                                    node.setSelected(true);
                                }
                            }
                        });
                    }

                    swal("Cobertura Actualizada!", "Se actualizo correctamente la cobertura a " + inputValue + " %", "success");
                });
        }
        self.updateChart = function (timerange) {

            self.timerange = timerange;

            if (self.assortmentDashboard == null)
                return;

            var lastWeekXValues = [];
            var lastMonthXValues = [];
            var lastYearXValues = [];
            var xValues = [];
            var lastXValues = [];


            if (timerange == 'week')
                xValues = self.assortmentDashboard.lastWeekXValues;
            else if (timerange == 'month')
                xValues = self.assortmentDashboard.lastMonthXValues;
            else
                xValues = self.assortmentDashboard.lastYearXValues;

            var lastDateString = null;
            var lastXValue = null;
            var selectedClusterId = 0;
            var cluster = _.filter(self.clusters, function (o) {
                return o.clusterCode == self.selectedFilterForCluster;
            });

            if (cluster.length > 0)
                selectedClusterId = cluster[0].clusterId;

            xValues.forEach(function (xValue) {

                if (lastDateString != xValue.dateString) {
                    if (lastXValue != null) {
                        lastXValue.precio = lastXValue.ventas_Un != 0 ? Math.round((lastXValue.ventas_$ / lastXValue.ventas_Un) * 100) / 100 : 0;
                        lastXValues.push(lastXValue);
                    }

                    lastXValue = { dateString: xValue.dateString, ventas_$: 0, ventas_Un: 0, margen_$: 0, precio: 0 };
                }

                if (selectedClusterId == 0 || (xValue.clusterId == selectedClusterId)) {
                    lastXValue.ventas_$ += xValue.sales;
                    lastXValue.ventas_Un += xValue.units;
                    lastXValue.margen_$ += xValue.margin;
                }

                lastDateString = xValue.dateString;
            });

            if (lastXValue != null) {
                lastXValue.precio = lastXValue.ventas_Un != 0 ? Math.round((lastXValue.ventas_$ / lastXValue.ventas_Un) * 100) / 100 : 0;
                lastXValues.push(lastXValue);
            }


            if (timerange == 'week')
                lastWeekXValues = lastXValues;
            else if (timerange == 'month')
                lastMonthXValues = lastXValues;
            else
                lastYearXValues = lastXValues;

            self.chart = c3.generate({
                bindto: '#saleschart',
                size: {
                    height: 220
                },
                data: {
                    json: (timerange == 'week' ? lastWeekXValues : timerange == 'month' ? lastMonthXValues : lastYearXValues),
                    keys: {
                        x: 'dateString',
                        value: ['ventas_$', 'ventas_Un', 'margen_$', 'precio'],
                    },
                    axes: {
                        ventas_$: 'y',
                        ventas_Un: 'y',
                        margen_$: 'y',
                        precio: 'y2'
                    },
                    type: 'bar',
                    types: {
                        precio: 'line',
                    },
                    colors: {
                        ventas_$: '#1ab394',
                        ventas_Un: '#1c84c6',
                        margen_$: '#f8ac59',
                        precio: '#ED5565'
                    }
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            fit: true,
                            format: function (x) {
                                if (self.timerange == 'week') {
                                    return $rootScope.shortWeekDayNames[x.getDay()];
                                } else if (self.timerange == 'month') {
                                    return x.getDate() + '-' + $rootScope.shortMonthNames[(x.getMonth())];
                                } else if (self.timerange == 'year') {
                                    return x.getFullYear() + '-' + (x.getMonth() + 1);
                                }
                            }
                        }
                    },
                    y: {
                        tick: {
                            format: d3.format("s")
                        }
                    },
                    y2: {
                        show: true,
                        label: 'Precio en $',
                        tick: {
                            format: d3.format('.1f')
                        },
                        min: 0
                    }
                },
                tooltip: {
                    format: {
                        title: function (d) {
                            var toFormat = '%B %d, %Y';
                            if (self.timerange == 'year')
                                toFormat = '%B %Y';

                            var format = d3.time.format(toFormat);
                            return format(d);
                        },
                        value: function (value, ratio, id) {
                            if (id === 'precio') {
                                var format = d3.format('$,.2f');
                            }
                            else {
                                var format = d3.format(',.0f');
                            }

                            return format(value);
                        }
                    }
                }
            });
        }
        self.filterByText = function () {
            var eInput = document.querySelector('#searchGridInput');
            if (eInput) {

                if ((eInput.value.length > 2 && selectedFilterForText != eInput.value) || (eInput.value == '' && selectedFilterForText != '')) {
                    selectedFilterForText = eInput.value;
                    externalFilterChanged();
                }
            }
        }
        self.filterByTextIfEnter = function (e) {
            if (e.which === 13) { //Enter keycode
                self.filterByText();
            }
        }
        self.filterByStatus = function (status) {

            self.selectedFilters.status = status;

            //if (self.selectedFilterForStatus != status) {
            //    self.selectedFilterForStatus = status;
            //externalFilterChanged();
            //}
        }
        self.filterByAssortmentStatus = function (assortmentStatus) {

            self.selectedFilters.assortmentStatus = assortmentStatus;

            //if (self.selectedFilterForAssortmentStatus != assortmentStatus) {
            //    self.selectedFilterForAssortmentStatus = assortmentStatus;
            //    externalFilterChanged();
            //}
        }
        self.filterByCluster = function (cluster) {

            self.selectedFilters.cluster = cluster;

            //if (self.selectedFilterForCluster != cluster) {
            //    self.selectedFilterForCluster = cluster;

            //    if (self.selectedFilterForCluster == 'Todos') {
            //        self.acceptedAllSuggestions = false;
            //        self.gridOptions.api.setRowData(self.pareto.items);
            //    }
            //    else {
            //        self.acceptedAllSuggestions = false;
            //        var onlySelectedCluster = [];
            //        for (var i = 0; i < self.pareto.items.length; i++) {
            //            if (self.pareto.items[i].subcategories.length > 0) {
            //                for (var j = 0; j < self.pareto.items[i].subcategories.length; j++) {
            //                    if (self.pareto.items[i].subcategories[j].clusterCode == cluster)
            //                        onlySelectedCluster.push(angular.copy(self.pareto.items[i].subcategories[j]));
            //                }
            //            }
            //        }

            //        self.gridOptions.api.setRowData(onlySelectedCluster);
            //    }

            //    externalFilterChanged();
            //    self.updateChart(self.timerange);
            //}
        }

        self.selectedFilters = {};

        self.filterByBrand = function (brand) {
            self.selectedFilters.brand = brand;
        }
        self.filterByStoreFormat = function (storeFormat) {
            self.selectedFilters.storeFormat = storeFormat;
        }
        self.filterByCustomerType = function (customerType) {
            self.selectedFilters.customerType = customerType;
        }
        self.filterByRegion = function (region) {
            self.selectedFilters.region = region;
        }
        self.applyFilters = function () {

            var pendingChanges = false;

            self.gridOptions.api.forEachNode(function (node) {
                if (node.data.dirty === true)
                    pendingChanges = true;
            });            

            if (pendingChanges) {
                swal({
                    title: "Se perderan los cambios pendientes",
                    text: "Si continua con esta accion se pederan los cambios que no hayan sido guardados. &#191;Desea continuar?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: "Continuar",
                    cancelButtonText: "Cancelar",
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: true,
                    html: true
                },
                    function (isConfirm) {
                        if (isConfirm) {

                            self.newListedItems = 0;
                            self.newDelistedItems = 0;
                            self.totalListedItems = 0;
                            doApplyFilters();

                            $timeout(function () {
                                swal("Confirmado", "Se aceptaron todas las sugerencias", "success");
                            }, 1000);

                        }
                    });
            }
            else {
                doApplyFilters();
            }



        }

        function doApplyFilters () {
            //capturo los filtros seleccionados
            var filters = {
                onlySuggested: self.selectedFilters.status == 'Sugeridos' ? true : false,
                onlyNew: self.selectedFilters.status == 'Nuevos' ? true : false,
                onlyToEndorse: self.selectedFilters.status == 'Para Validar' ? true : false,
                onlyActive: self.selectedFilters.assortmentStatus == 'Activo' ? true : false,
                onlyInactive: self.selectedFilters.assortmentStatus == 'Inactivo' ? true : false,
                categoryId: self.currentCategoryId,
                clusterId: self.selectedFilters.cluster ? self.selectedFilters.cluster.id : null,
                brandId: self.selectedFilters.brand ? self.selectedFilters.brand.id : null,
                storeFormatId: self.selectedFilters.storeFormat ? self.selectedFilters.storeFormat.id : null,
                customerTypeId: self.selectedFilters.customerType ? self.selectedFilters.customerType.id : null,
                regionId: self.selectedFilters.region ? self.selectedFilters.region.id : null,
                groupByBrand: self.selectedFilters.groupByBrand,
                groupByFormat: self.selectedFilters.groupByFormat,
                groupByCustomer: self.selectedFilters.groupByCustomer,
                groupByRegion: self.selectedFilters.groupByRegion,
            }

            //recargo el pareto
            loadPareto(filters);
        }

        self.downloadSalesAndMarginReport = function () {
            self.isLoadingReport = true;
            assortmentService.reports.getSalesAndMargin($stateParams.categoryId).then(function (url) {
                window.open(url);
                self.isLoadingReport = false;
            });
        }

        self.checkGroupByOption = function (groupByOption) {

            switch (groupByOption) {
                case 'Brand': self.selectedFilters.groupByBrand = !self.selectedFilters.groupByBrand; break;
                case 'Format': self.selectedFilters.groupByFormat = !self.selectedFilters.groupByFormat; break;
                case 'Customer': self.selectedFilters.groupByCustomer = !self.selectedFilters.groupByCustomer; break;
                case 'Region': self.selectedFilters.groupByRegion = !self.selectedFilters.groupByRegion; break;
            }

            //Pedido especial de colombia (si se usa Region no se usa BTC)
            if (groupByOption == 'Region' && self.selectedFilters.groupByRegion == true) {
                self.selectedFilters.groupByBrand = false;
                self.selectedFilters.groupByFormat = false;
                self.selectedFilters.groupByCustomer = false;
            }
            else if (self.selectedFilters.groupByBrand || self.selectedFilters.groupByFormat || self.selectedFilters.groupByCustomer) {
                self.selectedFilters.groupByRegion = false;
            }
        }

        //dialogs
        self.openChatDialog = function (itemId, clusterId) {

            var newScope = $scope.$new();
            newScope.showLoader = true;
            assortmentService.decisions.getChatForItem(itemId, clusterId).then(function (data) {
                newScope.showLoader = false;

                newScope.chats = [];
                newScope.isSaving = false;
                newScope.newChat = {
                    id: 0,
                    assortmentId: data.length > 0 ? data[0].assortmentId : 0,
                    clusterId: clusterId,
                    itemId: itemId,
                    message: "",
                    pictureUrl: "defaultUser.jpg", //TODO: Reemplazar por el current userPicture
                    sendDate: new Date()
                };

                if (data.length > 0 && data[0].id != null && data[0].id != 0)
                    newScope.chats = data;

                newScope.saveMessage = function () {
                    newScope.isSaving = true;
                    assortmentService.decisions.saveChatForItem(newScope.newChat)
                        .then(function (resp) {
                            if (resp != null) {
                                newScope.chats.push(resp);
                                newScope.newChat.message = "";
                                newScope.isSaving = false;
                            }
                            else {
                                newScope.isSaving = true;
                            }
                        },
                            function (fallback) {
                                newScope.isSaving = true;
                            });
                }
            });

            ngDialog.open({
                template: 'chatDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-300',
                scope: newScope
            });
        };

        self.openColumnViewerDialog = function () {
            var newScope = $scope.$new();
            newScope.auxColumnIndexesToHide = [];
            newScope.allColumns = self.gridOptions.columnDefs;

            angular.forEach(newScope.allColumns, function (p) {
                angular.forEach(p.children, function (c) {
                    c.isChecked = !c.hide;//checkeado si no esta oculto
                });
            });

            newScope.filterColumnViewer = function (c) {
                rv = true;
                if (c.id == "select") {
                    rv = false;
                }
                if (c.headerName == "Codigo" && self.hideSpaces) {
                    rv = false;
                }
                if (c.headerName == "Discontinuidad" && !canView("BlockedForPurchase")) {
                    rv = false;
                }
                if ((c.headerName == "Id Mix" || c.headerName == "Id Cluster" || c.headerName == "SKU") && !self.showParam1) {
                    rv = false;
                }
                if ((c.headerName == "SubRubro" || c.headerName == "Grupo") && !self.PrevCategory) {
                    rv = false;
                }
                if (c.headerName == "Decision" && c.field == "isSuggestionIgnored" && !self.isEndorse) {
                    rv = false;
                }
                else if (c.headerName == "Decision" && c.field == "newIsListed" && self.isEndorse) {
                    rv = false;
                }
                if (c.headerName == "%" && c.field == "presence2" && !self.showPresence2) {
                    rv = false;
                }
                else if (c.headerName == "%" && c.field == "presence" && self.showPresence2) {
                    rv = false;
                }
                if (c.headerName == "Venta Total" && self.showSalesTotal) {
                    rv = false;
                }
                if (c.headerName == "Ventas Un Total" && !self.showUnitsTotal) {
                    rv = false;
                }
                if ((c.headerName == "Metros" || c.headerName == "Frentes" || c.headerName == "% Espacio" || c.headerName == "GMROS") && self.hideSpaces) {
                    rv = false;
                }
                if (c.headerName == "Planograma" && (!self.showPlanogramEdition || self.hideSpaces)) {
                    rv = false;
                }
                if (c.headerName == "Disponibilidad" && !canView("Availability")) {
                    rv = false;
                }
                if (c.headerName == "Merma Desconocida %" && c.hide) {
                    rv = false;
                }
                if (c.headerName == "Merma" && !self.showWasteUnits) {
                    rv = false;
                }
                return rv;
            }

            self.saveColumnsToHide = function () {
                angular.forEach(newScope.allColumns, function (p, parentIdx) {
                    newScope.auxColumnIndexesToHide[parentIdx] = [];
                    angular.forEach(p.children, function (c, childrenIdx) {
                        if (!c.isChecked && newScope.filterColumnViewer(c)) {
                            newScope.auxColumnIndexesToHide[parentIdx].push(childrenIdx);
                        }
                    });
                });
                var jsonColumnIndexesToHide = JSON.stringify(newScope.auxColumnIndexesToHide);
                assortmentService.decisions.saveColumnsToHide(jsonColumnIndexesToHide).then(function (resp) {
                    if (resp != null) {
                        swal("Guardado exitosamente", "Recargue la pantalla para visualizar sus cambios.", "success");
                        ngDialog.close();
                    }
                })
            }

            ngDialog.open({
                template: 'columnViewerDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-height-500 tagsDialog',
                scope: newScope
            });
        }

        

        self.openTagsDialog = function (itemId) {
            var itemTags = null;
            self.gridOptions.api.forEachNode(function (node) {
                if (node.data.itemId === itemId)
                    itemTags = node.data.tagIds;
            });

            var tagGroups = _.groupBy(self.tags, 'group');
            angular.forEach(tagGroups, function (tg) {
                tg.selectedTags = [];
                tg.itemId = itemId
            });

            var newScope = $scope.$new();
            newScope.tagGroups = tagGroups;
            newScope.selectedItemId = itemId;

            //Load existing tags
            if (itemTags != null && itemTags != 'null') {
                var existingTagIds = itemTags.split(',');

                for (var i = 0; i < existingTagIds.length; i++) {
                    angular.forEach(newScope.tagGroups, function (tg) {
                        if (_.find(tg, function (t) {
                            return t.id == existingTagIds[i].trim();
                        }) != null) {
                            let t = tg.filter(function (t) { return t.id == existingTagIds[i].trim(); });
                            if (t.length === 1)
                                tg.selectedTags.push(t[0]);
                        }
                    });
                }
            }

            //SaveTags
            newScope.saveTags = function () {
                var tagIdsToSave = [];
                var tagNamesToSave = [];

                angular.forEach(newScope.tagGroups, function (tg) {
                    angular.forEach(tg.selectedTags, function (tag) {
                        tagIdsToSave.push(tag.id);
                        tagNamesToSave.push(tag.name);
                    });
                });

                adminService.items.saveItemTags(tagIdsToSave, newScope.selectedItemId)
                    .then(function (resp) {
                        if (resp) {
                            ngDialog.close();
                            //loadData(); no recargo la grilla porque pierdo las deciciones tomadas. recargar solo estos tags.
                            self.gridOptions.api.forEachNode(function (node) {
                                if (node.data.itemId === itemId) {
                                    node.data.tagIds = tagIdsToSave.join(', ');
                                    node.data.tags = tagNamesToSave.join(', ');
                                }
                            });
                        }
                        else {
                            //Todo: Ocurrio un error, Pedir que se guarden nuevamnete.
                        }
                    });
            }

            ngDialog.open({
                template: 'tagDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-height-500 tagsDialog',
                backdrop: 'static',
                keyboard: false,
                scope: newScope
            });
        };
        self.openPresenceDialog = function (itemId, clusterId) {

            var newScope = $scope.$new();
            newScope.showLoader = true;
            assortmentService.decisions.getPresenceDetail(itemId, clusterId).then(function (data) {
                newScope.showLoader = false;

                newScope.presence = [];
                newScope.isSaving = false;

                if (data && data.length > 0)
                    newScope.presence = data
            });

            ngDialog.open({
                template: 'presenceDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: newScope
            });
        };
        self.openParetoSectionsDialog = function () {

            var newScope = $scope.$new();
            newScope.isSaving = false;
            newScope.applyParetoSections = function () {
                //re-count
                countParetoSections();

                //refresh grid
                self.gridOptions.api.setRowData(self.pareto.items);
                self.gridOptions.api.refreshView();

                ngDialog.close();
            }
            newScope.saveParetoSections = function () {

                assortmentService.decisions.saveParetoSections(self.paretoSections)
                    .then(function () {
                        newScope.applyParetoSections();
                    });
            }
            newScope.edit = function (prop, $$hashKey) {
                newScope[prop + $$hashKey] = true;
            }
            newScope.done = function (prop, $$hashKey) {
                newScope[prop + $$hashKey] = false;
            }
            newScope.editing = function (prop, $$hashKey) {
                if (newScope[prop + $$hashKey])
                    return newScope[prop + $$hashKey];

                return false;
            }
            newScope.newSection = function () {
                self.paretoSections.push({
                    categoryId: self.currentCategoryId,
                    name: 'NuevaSeccion',
                    valueFrom: 0,
                    valueTo: 0,
                    alpha: null
                });
            }
            newScope.deleteSection = function (section) {
                var ix = self.paretoSections.indexOf(section);
                self.paretoSections.splice(ix, 1);

            }

            if (!self.paretoSections) {
                assortmentService.decisions.getParetoSections(self.currentCategoryId).then(function (sections) {

                    self.paretoSections = sections;
                    newScope.paretoSections = self.paretoSections;

                    ngDialog.open({
                        template: 'paretoSectionsDialog',
                        className: 'ngdialog-theme-default ngdialog-theme-custom',
                        scope: newScope
                    });
                });
            }
            else {
                newScope.paretoSections = self.paretoSections;

                ngDialog.open({
                    template: 'paretoSectionsDialog',
                    className: 'ngdialog-theme-default ngdialog-theme-custom',
                    scope: newScope
                });
            }

        };
        self.openSuggestionActionsDialog = function (nodeId) {

            y = window.scrollY;
            $scope.selectedNode = nodeId ? getNodeById(nodeId) : undefined;

            ngDialog.open({
                template: 'suggestionActionsDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                preCloseCallback: function () {
                    $timeout(function () {
                        window.scroll(0, y);
                    }, 550);
                },
                scope: $scope
            });

        };
        self.openDecisionActionDialog = function (nodeId) {

            y = window.scrollY;
            $scope.selectedNode = nodeId ? getNodeById(nodeId) : undefined;

            ngDialog.open({
                template: 'decisionActionsDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                preCloseCallback: function () {
                    $timeout(function () {
                        window.scroll(0, y);
                    }, 550);
                },
                scope: $scope
            });

        };

        //main actions
        self.toggleAllSuggestions = function () {
            if (self.acceptedAllSuggestions)
                self.revertAllSuggestions();
            else
                self.acceptAllSuggestions();
        }
        self.acceptAllSuggestions = function () {
            swal({
                title: "Esta seguro?",
                text: "Esta accion aceptara todas las sugerencias hechas por prisma.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        self.gridOptions.api.forEachNode(function (node) {
                            if (node.group) {
                                acceptSuggestionsForAllChildren(node);
                            }
                            else {
                                //solo actualizo los que tienen sugerencia sin decision
                                if (node.data.suggestionListed != null) {
                                    if (node.data.suggestionListed != node.data.newIsListed) {
                                        if (node.data.suggestionListed == false)
                                            removeFromAssortment(node);
                                        else if (node.data.suggestionListed == true)
                                            addToAssortment(node);
                                    }
                                }
                            }
                        });

                        self.acceptedAllSuggestions = true;

                        $timeout(function () {
                            swal("Confirmado", "Se aceptaron todas las sugerencias", "success");
                        }, 1000);

                    }
                });
        }
        self.revertAllSuggestions = function () {
            swal({
                title: "Esta seguro?",
                text: "Esta accion revertira todas las sugerencias aceptadas.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        self.gridOptions.api.forEachNode(function (node) {
                            if (node.group) {
                                revertAcceptedSuggestionsForAllChildren(node);
                            }
                            else {
                                //solo revierto los que tienen sugerencia y decision
                                if (node.data.suggestionListed != null) {
                                    if (node.data.suggestionListed === node.data.newIsListed) {
                                        revertDecision(node);
                                    }
                                }
                            }
                        });

                        self.acceptedAllSuggestions = false;

                        $timeout(function () {
                            swal("Confirmado", "Se revirtieron todas las sugerencias", "success");
                        }, 1000);

                    }
                });
        }
        self.addToAssortment = function (nodeId) {

            //obtengo el nodo de la grilla
            var node = getNodeById(nodeId);

            addToAssortment(node);
            self.updateSummaryBox();
        }
        self.removeFromAssortment = function (nodeId) {
            //obtengo el nodo de la grilla
            var node = getNodeById(nodeId);

            removeFromAssortment(node);
            self.updateSummaryBox();
        }
        self.acceptSuggestionsForAllChildren = function (nodeId) {

            if (nodeId) {
                //obtengo el nodo de la grilla
                var node = getNodeById(nodeId);
                acceptSuggestionsForAllChildren(node);
            }
            else {
                self.acceptAllSuggestions();
            }

        }
        self.revertAcceptedSuggestionsForAllChildren = function (nodeId) {

            if (nodeId) {
                //obtengo el nodo de la grilla
                var node = getNodeById(nodeId);
                revertAcceptedSuggestionsForAllChildren(node);
            }
            else {
                self.revertAllSuggestions();
            }
        }
        self.ignoreAll = function () {
            swal({
                title: "Esta seguro?",
                text: "Se ignoraran todas las sugerencias hechas por prisma.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        self.gridOptions.api.forEachNode(function (node) {
                            if (!node.group) {
                                self.ignore(node);
                            }
                        });

                        self.ignoredAll = true;

                        $timeout(function () {
                            swal("Confirmado", "Se ignoraron todas las sugerencias", "success");
                        }, 1000);

                    }
                });
        }
        self.undoIgnoreAll = function () {
            swal({
                title: "Esta seguro?",
                text: "Se volveran a incluir todas las sugerencias hechas por prisma.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        self.gridOptions.api.forEachNode(function (node) {
                            if (!node.group) {
                                self.undoIgnore(node);
                            }
                        });

                        self.ignoredAll = false;

                        $timeout(function () {
                            swal("Confirmado", "Se incluyeron todas las sugerencias", "success");
                        }, 1000);
                    }
                });
        }

        self.addToAssortmentForAllChildren = function (node) {
            //recorro todos los hijos (no solo filtrados porque el filtro puede ser cualquiera)
            node.children.forEach(function (node) {

                //si hay rows de grupos en la jerarquia bajo un nivel mas para llegar a los ClusterRow
                if (node.data.rowType == "GroupRow") {

                    node.children.forEach(function (node) {
                        self.addToAssortment(node);
                    });
                }
                else {
                    self.addToAssortment(node);
                }

            });
        }
        self.removeFromAssortmentForAllChildren = function (node) {
            //recorro todos los hijos (no solo filtrados porque el filtro puede ser cualquiera)
            node.children.forEach(function (node) {

                //si hay rows de grupos en la jerarquia bajo un nivel mas para llegar a los ClusterRow
                if (node.data.rowType == "GroupRow") {

                    node.children.forEach(function (node) {
                        self.removeFromAssortment(node);
                    });
                }
                else {
                    self.removeFromAssortment(node);
                }

            });
        }

        self.ignore = function (pNode) {

            if (pNode) {
                var node = getNodeById(pNode);

                if (node.group) {
                    //si es un nodo padre ignoro las sugerencias de cada hijo
                    node.children.forEach(function (childNode) {
                        if (!childNode.group && childNode.data.suggestionListed != null) {
                            childNode.data.ignored = true;
                            childNode.data.dirty = true;
                            self.gridOptions.api.refreshRows([childNode]);
                        }
                    });

                    node.data.ignored = true;
                    self.gridOptions.api.refreshRows([node]);
                }
                else if (node.data.suggestionListed != null) {
                    //si es un nodo hijo ignoro la sugerencia
                    node.data.ignored = true;
                    node.data.dirty = true;
                    self.gridOptions.api.refreshRows([node]);
                }
            }
            else {
                self.ignoreAll();
            }
            updateParentAndRefreshScreen(node, false);

        }
        self.undoIgnore = function (pNode) {

            if (pNode) {

                var node = getNodeById(pNode);

                if (node.group) {
                    //si es un nodo padre ignoro las sugerencias de cada hijo
                    node.children.forEach(function (childNode) {
                        if (!childNode.group && childNode.data.suggestionListed != null) {
                            childNode.data.ignored = false;
                            childNode.data.dirty = true;
                            self.gridOptions.api.refreshRows([childNode]);
                        }
                    });

                    node.data.ignored = false;
                    self.gridOptions.api.refreshRows([node]);
                }
                else if (node.data.suggestionListed != null) {
                    //si es un nodo hijo ignoro la sugerencia
                    node.data.ignored = false;
                    node.data.dirty = true;
                    self.gridOptions.api.refreshRows([node]);
                }
            }
            else {
                self.undoIgnoreAll();
            }
            updateParentAndRefreshScreen(node, false);
        }

        self.isIgnored = function (nodeId) {
            var node = getNodeById(nodeId);

            if (node.data.ignored)
                return true;

            return false;
        }

        self.saveDecisions = function () {

            var swalText = "Esta seguro que desea guardar los cambios seleccionados?";

            swal({
                title: "Esta seguro?",
                text: swalText,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        var itemsToSave = [];
                        self.gridOptions.api.forEachNode(function (node) {
                            var item = node.data;

                            if (!node.group && item.dirty) {

                                var itemToSave = {
                                    itemId: item.itemId,
                                    clusterId: item.clusterId,
                                    subcategoryId: item.subcategoryId,
                                    assortmentId: item.assortmentId,
                                    isListed: item.isListed,
                                    newIsListed: item.newIsListed,
                                    suggestionListed: item.suggestionListed,
                                    isSuggestionIgnored: item.ignored
                                };

                                itemsToSave.push(itemToSave);
                            }
                        });

                        if (itemsToSave.length > 0) {
                            assortmentService.decisions.saveDecisions(itemsToSave, false, $stateParams.categoryId)
                                .then(function () {
                                    //ReloadGrid
                                    loadData(true);
                                    swal("Confirmado", "Se guardaron los cambios seleccionados", "success");
                                    doApplyFilters();
                                },
                                    function (status) {
                                        if (status != 502) {
                                            swal("Error", "Hubo un error al generar las sugerencias para esta categoria", "error");
                                        }
                                    });

                        }
                        else {
                            $timeout(function () {
                                swal("Sin Items", "No hay items con modificaciones", "error");
                            }, 1000);
                        }
                    }
                });
        }

        self.goToPlanogram = function (itemId, clusterId) {
            $state.go('spaces.editPlanogram', { planogramId: 0, itemId: itemId, clusterId: clusterId });
        }



        /*** PRIVATE FUNCTIONS ***/
        //grid config
        function setColumnDefinitions() {
            columnDefs = [
                {
                    headerName: 'Informacion General',
                    children: [
                        {
                            id: "select",
                            field: "itemId",
                            pinned: 'left',
                            colIndex: 0,
                            width: 25,
                            suppressMenu: true,
                            suppressSorting: true,
                            hide: !self.isEndorse,
                            filter: false,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.emptyRenderer,
                            checkboxSelection: function (params) {
                                // we put checkbox on only if we are not grouping
                                return params.columnApi.getRowGroupColumns().length === 0;
                            },
                            headerCellRenderer: function (params) {
                                return '';
                            }
                        },
                        {
                            headerName: "EAN",
                            headerTooltip: "",
                            field: "itemEan",
                            pinned: 'left',
                            width: numberColWidth + 40,
                            hide: self.hideColumn(0, 1),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: {
                                renderer: 'group',
                                checkbox: false,
                                innerRenderer: function (params) {
                                    var description = '';

                                    if (!params.node.group) {
                                        var totalChats = params.data.chatsQuantity != 0 ? '(' + params.data.chatsQuantity + ') ' : '';
                                        description = '<a style="padding: 2px" ng-click="ct.openChatDialog(' + params.data.itemId + ',' + params.data.clusterId + ')" title="' + totalChats + 'Click aqui para ver las conversaciones asociadas a esta decision"><i class="fa fa-comments-o"></i> </a>'
                                    }

                                    description += params.value || "";
                                    return description;
                                }
                            }
                        },
                        {
                            headerName: "Fecha de Creacion",
                            pinned: 'left',
                            headerTooltip: "",
                            field: "created",
                            filter: 'text',
                            width: numberColWidth + 40,
                            hide: self.hideColumn(0, 2),
                            cellClass: $rootScope.getClassForCell
                        },                       
                        {
                            headerName: "Codigo",
                            pinned: 'left',
                            //columnGroupShow: 'open',
                            headerTooltip: "",
                            field: "itemCode",
                            filter: 'number',
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            hide: self.hideSpaces || self.hideColumn(0, 3)
                        },
                        {
                            headerName: "Discontinuidad",
                            pinned: 'left',
                            hide: !canView("BlockedForPurchase") || self.hideColumn(0, 4),
                            headerTooltip: "",
                            field: "blockedForPurchase",
                            filter: 'text',
                            width: numberColWidth + 20,
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "Id Mix",
                            columnGroupShow: 'open',
                            hide: !self.showParam1 || self.hideColumn(0, 5),
                            headerTooltip: "",
                            field: "param1",
                            filter: 'text',
                            width: numberColWidth + 20,
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "Id Cluster",
                            columnGroupShow: 'open',
                            hide: !self.showParam1 || self.hideColumn(0, 6),
                            headerTooltip: "",
                            field: "param2",
                            filter: 'text',
                            width: numberColWidth + 20,
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "SKU",
                            columnGroupShow: 'open',
                            hide: !self.showParam1 || self.hideColumn(0, 7),
                            headerTooltip: "",
                            field: "param3",
                            filter: 'text',
                            width: numberColWidth + 20,
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "Desc Articulo",
                            headerTooltip: "",
                            field: "itemName",
                            pinned: 'left',
                            width: numberColWidth + 240,
                            hide: self.hideColumn(0, 8),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer:
                                function (params) {

                                    var description = '';

                                    if (params.data.itemId != null && params.node.group) {
                                        //var tagIds = "'" + params.data.tagIds + "'";
                                        description += ' <a style="padding: 2px" ng-click="ct.openTagsDialog(' + params.data.itemId + ')" title="Click aqui para cargar tags asociados a este producto"><i class="fa fa-tags"></i> </a> ';
                                    }
                                    var newLabel = '';
                                    if (params.data.isNew && params.node.group)
                                        newLabel = ' <span class="label label-warning" style="margin-top: 5px; margin-right: 5px; padding: 2px 2px; padding-right: 3px;">NUEVO</span>';

                                    var name = newLabel + params.value + (params.node.group && params.node.children != null ? ' (' + params.node.children.length + ')' : '');

                                    description += '<a style="cursor: default;">' + name + '</a>';
                                    //description += '<a title="Ir al dashboard para este producto" ng-click="ct.showItemInDashboard(' + params.data.itemId + ',' + params.data.clusterId + ')">' + name + '</a>';

                                    return description;

                                }
                        },
                        {
                            headerName: "Cluster",
                            headerTooltip: "",
                            field: "clusterCode",
                            pinned: 'left',
                            width: numberColWidth,
                            hide: self.hideColumn(0, 9),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer:
                                function (params) {
                                    if (params.data.clusterCode)
                                        return params.data.clusterCode + ' | ' + params.data.cluster;
                                    else
                                        return '';
                                }
                        },
                        {
                            headerName: "SubRubro",
                            headerTooltip: "",
                            hide: !self.PrevCategory || self.hideColumn(0, 10), // Hacer el parametro arriba y poner true solo si es chile !
                            field: "param2",
                            columnGroupShow: 'open',
                            width: numberColWidth + 20,
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "Grupo",
                            headerTooltip: "",
                            hide: !self.PrevCategory || self.hideColumn(0, 11), // Hacer el parametro arriba y poner true solo si es chile !
                            field: "param1",
                            columnGroupShow: 'open',
                            width: numberColWidth + 15,
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "Marca",
                            columnGroupShow: 'open',
                            field: "brand",
                            headerTooltip: "",
                            width: numberColWidth,
                            hide: self.hideColumn(0, 12),
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "Fabricante",
                            columnGroupShow: 'open',
                            field: "manufacturer",
                            headerTooltip: "",
                            width: numberColWidth,
                            hide: self.hideColumn(0, 13),
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "Tags",
                            columnGroupShow: 'open',
                            field: "tags",
                            width: 220,
                            hide: self.hideColumn(0, 14),
                            cellClass: $rootScope.getClassForCell
                        }]
                },
                {
                    headerName: 'Estado',
                    children: [
                        {
                            headerName: "Actual",
                            field: "isListed",
                            headerTooltip: "",
                            width: numberColWidth,
                            hide: self.hideColumn(1, 0),
                            cellClass: getClassForSuggestionCell,
                            cellRenderer: function (params) {

                                //Si es padre evaluo de mostrar los * o mismo valor
                                if (params.node.group) {
                                    var textToShow = '';
                                    allIsListed = null;
                                    angular.forEach(params.node.data.subcategories, function (nodoHijo) {
                                        if (allIsListed == null && nodoHijo.isListed != null) //Si es el primero con isListed != a null
                                            allIsListed = nodoHijo.isListed;
                                        else if (allIsListed != null && nodoHijo.isListed != null && nodoHijo.isListed != allIsListed)
                                            textToShow = '*';
                                    })

                                    if (textToShow != '*') //Estaban todos Listados o delistados o sin valor
                                        if (allIsListed == true)
                                            textToShow = 'Activo';
                                        else if (allIsListed == false)
                                            textToShow = 'Inactivo';

                                    return textToShow;
                                }
                                else {
                                    if (params.value == true)
                                        return 'Activo';
                                    else
                                        return 'Inactivo';
                                }
                            }
                        },
                        {
                            headerName: "Sugerencia",
                            headerCellRenderer: function (params) {
                                var header = '<span>Sugerencia</span>';

                                //header += '<button ng-show="!ct.isEndorse && ct.anySuggestions() && !ct.ignoredAll" class="btn-link btn-link-warning" ng-click="ct.ignoreAll();" uib-tooltip="Ignorar todas las sugerencias." tooltip-placement="down"><i class="fa fa-times"></i></button>'
                                //    + '<button ng-show="!ct.isEndorse && ct.anySuggestions() && ct.ignoredAll" class="btn-link btn-link-warning" ng-click="ct.undoIgnoreAll();" uib-tooltip="Incluir todas las sugerencias." tooltip-placement="down"><i class="fa fa-history"></i></button>';

                                //var action = 'toggleAllSuggestions';
                                //var tooltip = 'Aceptar / Revertir las sugerencias';
                                //var icon = 'fa-check';

                                //header += ian.format('<button class="btn-link" ng-show="!ct.isEndorse && ct.anySuggestions() && !ct.ignoredAll" ng-click="ct.{0}();" uib-tooltip="{1}" tooltip-placement="down"><i class="fa" ng-class="{\'fa-check\': !ct.acceptedAllSuggestions, \'fa-undo\': ct.acceptedAllSuggestions }"></i></button>', action, tooltip, icon);

                                if (!self.isEndorse)
                                    header += '<button class="btn-link btn-link-warning" ng-click="ct.openSuggestionActionsDialog()"><i class="fa fa-bolt"></i></button>';

                                return header;
                            },
                            field: "suggestionListed",
                            headerTooltip: "",
                            width: numberColWidth + 60,
                            hide: self.hideColumn(1, 1),
                            cellClass: $rootScope.getClassForCell,
                            filter: 'select',
                            suppressSorting: true,
                            cellRenderer: function (params) {

                                //NEW WAY
                                var suggestionText = "";
                                if (params.value != null)
                                    suggestionText = params.value == true ? "Incorporar" : "Quitar";

                                var cell = '<span title="' + (params.data.assortmentSuggestionRule != null ? params.data.assortmentSuggestionRule : '') + '">' + (suggestionText != null ? suggestionText : '') + '</span>';

                                if (params.node.data.rowType == "ItemRow") {

                                    if (self.anyChildSuggestions(params.node)) {
                                        cell += '<button type="button" class="btn btn-link btn-link-warning btn-sm mb5" uib-dropdown-toggle ng-click="ct.openSuggestionActionsDialog(' + params.node.id + ')"><i class="fa fa-bolt"></i></button>';
                                    }

                                }
                                else if (params.node.data.rowType == "GroupRow") {

                                    if (self.anyChildSuggestions(params.node)) {
                                        cell += '<button type="button" class="btn btn-link btn-link-warning btn-sm mb5" uib-dropdown-toggle ng-click="ct.openSuggestionActionsDialog(' + params.node.id + ')"><i class="fa fa-bolt"></i></button>';
                                    }

                                }
                                else if (params.node.data.rowType == "ClusterRow") {

                                    if (!self.isEndorse && (params.node.data.isSuggestionIgnored || params.node.data.suggestionListed == undefined || params.node.data.suggestionListed == null)) {
                                        cell = '';
                                    }
                                    else {
                                        cell += '<button class="btn btn-link btn-link-warning btn-md" ng-hide="ct.isIgnored(' + params.node.id + ') || ct.isEndorse" ng-click="ct.ignore(' + params.node.id + ')" title="Ignorar Sugerencia"><i class="fa fa-times"></i></button>'
                                            + '<button class="btn btn-link btn-link-warning btn-md" ng-hide="!ct.isIgnored(' + params.node.id + ') || ct.isEndorse" ng-click="ct.undoIgnore(' + params.node.id + ')" title="Volver a incluir sugerencia"><i class="fa fa-history"></i></button>';
                                    }

                                }

                                return cell;

                                ////OLD WAY
                                //if (params.value == null)
                                //    return '';
                                //else {
                                //    var suggestionText = params.value == true ? "Incorporar" : "Quitar";

                                //    if (params.node.group) {

                                //        var cell = '<span title="' + (params.data.assortmentSuggestionRule != null ? params.data.assortmentSuggestionRule : '') + '">' + (suggestionText != null ? suggestionText : '') + '</span>';
                                //        cell += '<button class="btn btn-link btn-link-warning btn-xs btn-grid-upper" ng-hide="ct.isEndorse || ct.isIgnored(' + params.node.id + ')"  ng-click="ct.ignore(' + params.node.id + ')" title="Ignorar Todas las sugerencias para este Item"><i class="fa fa-times"></i></button>'
                                //        cell += '<button class="btn btn-link btn-link-warning btn-xs btn-grid-upper" ng-hide="ct.isEndorse || !ct.isIgnored(' + params.node.id + ')" ng-click="ct.undoIgnore(' + params.node.id + ')" title="Volver a incluir sugerencias para este Item"><i class="fa fa-history"></i></button>'

                                //        if (self.anyChildSuggestions(params.node)) {
                                //            var icon = params.node.data.accepted ? 'fa-undo' : 'fa-check';
                                //            var tooltip = params.node.data.accepted ? 'Revertir sugerencias aceptadas' : 'Aceptar todas las sugerencias para este Item';
                                //            var action = params.node.data.accepted ? 'revertAcceptedSuggestionsForAllChildren' : 'acceptSuggestionsForAllChildren';

                                //            cell += ian.format('<button class="btn btn-link btn-xs btn-grid-upper" style="margin-left:12px" title="{0}" ng-hide="ct.isIgnored(' + params.node.id + ') || ct.isEndorse" ng-click="ct.{1}({2})"><i class="fa {3}" aria-hidden="true"></i></button>', tooltip, action, params.node.id, icon);
                                //        }

                                //        return cell;
                                //    }

                                //    if (!self.isEndorse && params.node.data.isSuggestionIgnored)
                                //        return '';

                                //    return '<span title="' + params.data.assortmentSuggestionRule + '">' + suggestionText + '</span>'
                                //        + '<button class="btn btn-link btn-link-warning btn-md" ng-hide="ct.isIgnored(' + params.node.id + ') || ct.isEndorse" ng-click="ct.ignore(' + params.node.id + ')" title="Ignorar Sugerencia"><i class="fa fa-times"></i></button>'
                                //        + '<button class="btn btn-link btn-link-warning btn-md" ng-hide="!ct.isIgnored(' + params.node.id + ') || ct.isEndorse" ng-click="ct.undoIgnore(' + params.node.id + ')" title="Volver a incluir sugerencia"><i class="fa fa-history"></i></button>';
                                //}



                            }

                        },
                        {
                            headerName: "Decision",
                            headerCellRenderer: function (params) {
                                var header = '<span>Decision</span>';

                                if (!self.isEndorse)
                                    header += '<button class="btn-link btn-link-warning" ng-click="ct.openDecisionActionDialog()"><i class="fa fa-bolt"></i></button>';

                                return header;
                            },
                            field: "newIsListed",
                            filter: 'select',
                            hide: self.isEndorse || self.hideColumn(1, 2),
                            headerTooltip: "",
                            suppressSorting: true,
                            width: numberColWidth + 20,
                            cellClass: getClassForSuggestionCell,
                            cellRenderer: function (params) {

                                var cell = '';

                                if (params.node.data.rowType == "ItemRow") {
                                    cell += '<button type="button" class="btn btn-link btn-link-warning btn-sm mb5" uib-dropdown-toggle ng-click="ct.openDecisionActionDialog(' + params.node.id + ')"><i class="fa fa-bolt"></i></button>';
                                }
                                else if (params.node.data.rowType == "GroupRow") {
                                    cell += '<button type="button" class="btn btn-link btn-link-warning btn-sm mb5" uib-dropdown-toggle ng-click="ct.openDecisionActionDialog(' + params.node.id + ')"><i class="fa fa-bolt"></i></button>';
                                }
                                else if (params.node.data.rowType == "ClusterRow") {

                                    var nodeId = params.node.id;

                                    var decision = params.data.newIsListed;
                                    var suggestion = params.data.suggestionListed;
                                    var actual = params.data.isListed;

                                    var btnIncorporar = '<button class="btn btn-primary btn-xs btn-grid" ng-click="ct.addToAssortment(' + nodeId + ')">Incorporar</button>';
                                    var btnQuitar = '<button class="btn btn-danger btn-xs btn-grid" ng-click="ct.removeFromAssortment(' + nodeId + ')">Quitar</button>';

                                    if (decision != null) {
                                        if (decision == false)
                                            cell = btnIncorporar
                                        else if (decision == true)
                                            cell = btnQuitar;
                                    }
                                    else if (suggestion != null) {
                                        if (suggestion == false)
                                            cell = btnQuitar;
                                        else if (suggestion == true)
                                            cell = btnIncorporar;
                                    }
                                    else {
                                        if (actual == true)
                                            cell = btnQuitar
                                        else if (actual == false)
                                            cell = btnIncorporar;
                                    }
                                }

                                return cell;
                            }
                        },
                        {
                            headerName: "Decision",
                            field: "isSuggestionIgnored",
                            filter: 'select',
                            hide: !self.isEndorse || self.hideColumn(1, 3),
                            headerTooltip: "",
                            suppressSorting: true,
                            width: numberColWidth + 20,
                            cellClass: getClassForSuggestionCell,
                            cellRenderer: function (params) {

                                if (params.node.group) {//es padre
                                    var allIgnored = true;
                                    angular.forEach(params.node.data.subcategories, function (nodoHijo) {
                                        if (!nodoHijo.isSuggestionIgnored)
                                            allIgnored = false;
                                    })
                                    if (allIgnored)
                                        return "Sugerencia Ignorada";
                                    else return "";
                                }
                                else {// es hijo
                                    if (params.value == true)
                                        return "Sugerencia Ignorada";
                                    else return "";
                                }
                            }
                        },
                        {
                            headerName: "Nuevo",
                            field: "newIsListed",
                            filter: 'text',
                            filter: 'select',
                            headerTooltip: "Refleja el nuevo estado de catalogacion",
                            width: numberColWidth - 20,
                            hide: self.hideColumn(1, 4),
                            cellClass: getClassForSuggestionCell,
                            cellRenderer: function (params) {

                                //Si es padre evaluo de mostrar los * o mismo valor
                                if (params.node.group) {
                                    var textToShow = '';
                                    var allNewIsListed = true;//todos los hijos tienen decision tomada
                                    var allIgnored = true;
                                    var currentNewIsListed = null;//flag de  decision tomada 

                                    angular.forEach(params.node.data.subcategories, function (nodoHijo) {
                                        if (currentNewIsListed == null) {//si todavia no encontramos una decision tomada
                                            if (nodoHijo.newIsListed != null) {//y si este tiene una decision tomada
                                                currentNewIsListed = nodoHijo.newIsListed;//tenemos un nuevo flag
                                                allIgnored = false;
                                            }
                                            else {//si no tiene una decision tomada
                                                allNewIsListed = false;//entonces no TODAS comparten una decision
                                                if (!nodoHijo.ignored)
                                                    allIgnored = false;
                                            }
                                        }
                                        else {//si ya hay un hijo con decision tomada...
                                            if (nodoHijo.newIsListed != null) {//y si este tiene una decision tomada
                                                if (nodoHijo.newIsListed != currentNewIsListed) {//y este tiene distinta
                                                    textToShow = '*';//muestro *
                                                    allNewIsListed = false; // no TODAS comparten una decision
                                                }
                                            }
                                            else//si no tiene para ofreser
                                                allNewIsListed = false;//entonces no TODAS comparten una decision
                                        }
                                        //else if (currentNewIsListed == null && nodoHijo.ignored)
                                        //    textToShow = 'Sugerencia Ignorada';
                                    })

                                    if (textToShow != '*') {
                                        if (allNewIsListed) {
                                            if (currentNewIsListed == true) {
                                                textToShow = 'Incorporado';
                                            }
                                            else if (currentNewIsListed == false) {
                                                textToShow = 'Quitado';
                                            }
                                        }
                                        else if (allIgnored)
                                            textToShow = "Sugerencia Ignorada";
                                    }

                                    return textToShow;
                                }
                                else {// es hijo
                                    if (params.value == null) {
                                        if (params.node.data.ignored)
                                            return 'Sugerencia Ignorada';
                                        return '';
                                    }
                                    else if (params.value == true)
                                        return 'Incorporado';
                                    else if (params.value == false)
                                        return 'Quitado';
                                }
                            }
                        }]
                },
                {
                    headerName: 'Impacto',
                    children: [
                        {
                            headerName: "Sugerido",
                            field: "absImpact",
                            filter: 'number',
                            headerTooltip: "Impacto Sugerido",
                            width: 110,
                            hide: self.hideColumn(2, 0),
                            cellClass: $rootScope.getClassForImpactCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: "Pendiente",
                            field: "absPendingImpact",
                            filter: 'number',
                            headerTooltip: "Impacto Pendiente",
                            width: 110,
                            hide: self.hideColumn(2, 1),
                            cellClass: $rootScope.getClassForImpactCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        //{
                        //    headerName: "Sugerido Promedio",
                        //    field: "impact",
                        //    filter: 'number',
                        //    columnGroupShow: 'open',
                        //    headerTooltip: "Impacto por Cluster x Mes (si se acepta la sugerencia)",
                        //    width: numberColWidth + 45,
                        //    cellClass: $rootScope.getClassForImpactCell,
                        //    cellRenderer: $rootScope.moneyRendererNoDecimals
                        //},
                        //{
                        //    headerName: "Pendiente Promedio",
                        //    field: "pendingImpact",
                        //    filter: 'number',
                        //    columnGroupShow: 'open',
                        //    headerTooltip: "Impacto por Cluster x Mes (si se valida la decision)",
                        //    width: numberColWidth + 55,
                        //    cellClass: $rootScope.getClassForImpactCell,
                        //    cellRenderer: $rootScope.moneyRendererNoDecimals
                        //}
                    ]
                },
                {
                    headerName: 'Rankings',
                    children: [
                        //{
                        //    headerName: "Indice",
                        //    columnGroupShow: 'open',
                        //    field: "indexRank",
                        //    filter: 'number',
                        //    headerTooltip: "Ranking de promedio ponderado entre Ventas, Unidades, Margen $ y Venta Mercado",
                        //    width: numberColWidth - 30,
                        //    cellClass: $rootScope.getClassForCell
                        //}     
                        {
                            headerName: "Indice Ponderado",
                            field: "indexRank",
                            filter: 'number',
                            //sort: 'asc',
                            headerTooltip: "Ranking por indice ponderado de la categor&iacute;a",
                            width: numberColWidth + 45,
                            hide: self.hideColumn(3, 0),
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "Indice % Acum",
                            field: "acumIndex",
                            filter: 'number',
                            headerTooltip: "",
                            width: numberColWidth + 20,
                            hide: self.hideColumn(3, 1),
                            cellStyle: function (params) {
                                return calculateParetoSectionColor(params.data);
                            },
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererNoDecimals
                        },
                        {
                            headerName: "SKUs % Acum",
                            columnGroupShow: 'open',
                            field: "acumSKUs",
                            filter: 'number',
                            headerTooltip: "% de SKUs acumulados hasta este SKU",
                            width: numberColWidth + 15,
                            hide: self.hideColumn(3, 2),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererNoDecimals
                        },
                    ]
                },
                {
                    headerName: 'Performance Interna',
                    children: [
                        //{
                        //    headerName: "Indice Ponderado",
                        //    field: "index",
                        //    filter: 'number',
                        //    columnGroupShow: 'open',
                        //    headerTooltip: "",
                        //    width: numberColWidth + 40,
                        //    cellClass: $rootScope.getClassForCell,
                        //    cellRenderer: $rootScope.integerRenderer
                        //},
                        {
                            headerName: "Venta",
                            field: "sales",
                            filter: 'number',
                            columnGroupShow: self.salesOnColumnGroupShow,
                            headerTooltip: "Venta promedio mensual por Tienda en el Cluster",
                            width: numberColWidth,
                            hide: self.hideColumn(4, 0),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: "Venta Total",
                            field: "salesTotal",
                            hide: !self.showSalesTotal || self.hideColumn(4, 1),
                            columnGroupShow: self.totalSalesOnColumnGroupShow,
                            filter: 'number',
                            headerTooltip: "Venta promedio mensual del Cluster",
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: "% Ventas",
                            columnGroupShow: 'open',
                            field: "salesShare",
                            filter: 'number',
                            headerTooltip: "Participacion en las ventas de este SKU sobre el total de la categoria",
                            width: 120,
                            hide: self.hideColumn(4, 2),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRenderer
                        },
                        {
                            headerName: "Venta % Acum",
                            columnGroupShow: 'open',
                            field: "acumSales",
                            filter: 'number',
                            headerTooltip: "% de Venta acumulada hasta este SKU",
                            width: numberColWidth + 20,
                            hide: self.hideColumn(4, 3),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererNoDecimals
                        },
                        {
                            headerName: "Rank Venta",
                            columnGroupShow: 'open',
                            field: "salesRank",
                            filter: 'number',
                            headerTooltip: "Ranking de ventas en unidades dentro de la categoria",
                            width: numberColWidth + 25,
                            hide: self.hideColumn(4, 4),
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "Ventas Un",
                            field: "units",
                            filter: 'number',
                            columnGroupShow: self.salesOnColumnGroupShow,
                            headerTooltip: "Venta en unidades promedio mensual por Tienda en el Cluster",
                            width: numberColWidth,
                            hide: self.hideColumn(4, 5),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.integerRenderer
                        },
                        {
                            headerName: "Ventas Un Total",
                            field: "unitsTotal",
                            hide: !self.showUnitsTotal || self.hideColumn(4, 6),
                            filter: 'number',
                            columnGroupShow: self.totalSalesOnColumnGroupShow,
                            headerTooltip: "Venta en unidades promedio mensual del Cluster",
                            width: numberColWidth + 40,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.integerRenderer
                        },
                        {
                            headerName: "% Ventas Un",
                            field: "unitsShare",
                            columnGroupShow: 'open',
                            filter: 'number',
                            headerTooltip: "Participaci&oacute;n en las ventas unitarias de este SKU sobre el total de la categor&iacute;a",
                            width: numberColWidth + 10,
                            hide: self.hideColumn(4, 7),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRenderer
                        },
                        {
                            headerName: "Rank Venta Un",
                            columnGroupShow: 'open',
                            field: "unitsRank",
                            filter: 'number',
                            headerTooltip: "Ranking de unidades dentro de la categoria",
                            width: numberColWidth + 30,
                            hide: self.hideColumn(4, 8),
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "Margen $",
                            field: "margin",
                            filter: 'number',
                            headerTooltip: "Margen promedio mensual por Tienda en el Cluster",
                            width: numberColWidth + 15,
                            hide: self.hideColumn(4, 9),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: "Margen 2 $",
                            field: "margin2",
                            filter: 'number',
                            headerTooltip: "Margen 2",
                            width: numberColWidth + 15,
                            hide: self.hideColumn(4, 10),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: "% Margen",
                            field: "marginShare",
                            columnGroupShow: 'open',
                            filter: 'number',
                            headerTooltip: "Participacion en margen de este SKU sobre el total de la categoria",
                            width: numberColWidth + 25,
                            hide: self.hideColumn(4, 11),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRenderer
                        },
                        {
                            headerName: "% Margen 2",
                            field: "margin2Share",
                            columnGroupShow: 'open',
                            filter: 'number',
                            headerTooltip: "Participacion en margen2 de este SKU sobre el total de la categoria",
                            width: numberColWidth + 25,
                            hide: self.hideColumn(4, 12),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRenderer
                        },
                        {
                            headerName: "Rank Margen",
                            columnGroupShow: 'open',
                            field: "marginRank",
                            filter: 'number',
                            headerTooltip: "Ranking de margen dentro de la categoria",
                            width: numberColWidth + 15,
                            hide: self.hideColumn(4, 13),
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "Precio Promedio",
                            field: "price",
                            filter: 'number',
                            headerTooltip: "Precio promedio de este SKU en este Cluster",
                            width: numberColWidth + 35,
                            hide: self.hideColumn(4, 14),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRenderer
                        },
                        {
                            headerName: "Rotacion",
                            columnGroupShow: 'open',
                            field: "turnsC",
                            filter: 'number',
                            headerTooltip: "",
                            width: numberColWidth,
                            hide: self.hideColumn(4, 15),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.decimalRenderer
                        },
                    ]
                },
                {
                    headerName: 'Presencia',
                    children: [
                        {
                            headerName: "%",
                            field: "presence",
                            hide: self.showPresence2 || self.hideColumn(5, 0),
                            filter: 'number',
                            headerTooltip: "Meses y Tiendas en los que este SKU tuvo ventas / Total de meses y Total de Tiendas del Cluster",
                            width: numberColWidth - 10,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: function (params) {
                                if (!params.value)
                                    return '';

                                var presencia = $rootScope.percentageRendererNoDecimals(params);
                                if (!params.node.group) {
                                    presencia = '<a style="padding: 2px" ng-click="ct.openPresenceDialog(' + params.data.itemId + ',' + params.data.clusterId + ')">' + $rootScope.percentageRendererNoDecimals(params) + '</a>';
                                }
                                return presencia;
                            }
                        },
                        {
                            headerName: "%",
                            field: "presence2",
                            hide: !self.showPresence2 || self.hideColumn(5, 1),
                            filter: 'number',
                            headerTooltip: "Total de tiendas donde el item est� activo / Total de tiendas del cluster",
                            width: numberColWidth - 10,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: function (params) {
                                if (!params.value)
                                    return '';

                                var presencia = $rootScope.percentageRendererNoDecimals(params);
                                if (!params.node.group) {
                                    presencia = '<a style="padding: 2px" ng-click="ct.openPresenceDialog(' + params.data.itemId + ',' + params.data.clusterId + ')">' + $rootScope.percentageRendererNoDecimals(params) + '</a>';
                                }
                                return presencia;
                            }
                        },
                        //{ //REVISAR DATOS, VIENEN NUMEROS MUY GRANDES
                        //    headerName: "Tiendas",
                        //    field: "numberOfStores",
                        //    columnGroupShow: 'open',
                        //    filter: 'number',
                        //    headerTooltip: "N&uacute;mero de stores con ventas de este SKU",
                        //    width: numberColWidth - 10,
                        //    cellClass: $rootScope.getClassForCell,
                        //    cellRenderer: $rootScope.integerRenderer
                        //},
                        //{
                        //    headerName: "Meses",
                        //    field: "numberOfMonths",
                        //    columnGroupShow: 'open',
                        //    filter: 'number',
                        //    headerTooltip: "N&uacute;mero de meses con ventas de este SKU",
                        //    width: numberColWidth - 10,
                        //    cellClass: $rootScope.getClassForCell,
                        //    cellRenderer: $rootScope.integerRenderer
                        //}              
                    ]
                },
                {
                    headerName: 'Performance Mercado',
                    children: [
                        {
                            headerName: "Venta",
                            field: "marketSales",
                            filter: 'number',
                            headerTooltip: "Venta Mercado",
                            width: numberColWidth,
                            hide: self.hideColumn(6, 0),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: "% Ventas",
                            field: "marketSalesShare",
                            columnGroupShow: 'open',
                            filter: 'number',
                            headerTooltip: "Participacion en las ventas de mercado de este SKU sobre el total de la categoria ",
                            width: numberColWidth + 40,
                            hide: self.hideColumn(6, 1),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRenderer
                        },
                        {
                            headerName: "Venta Mercado % Acum",
                            columnGroupShow: 'open',
                            field: "acumMarketSales",
                            filter: 'number',
                            headerTooltip: "% de Venta Mercado acumulada hasta este SKU",
                            width: numberColWidth + 75,
                            hide: self.hideColumn(6, 2),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererNoDecimals
                        },
                        {
                            headerName: "Rank Ventas M",
                            columnGroupShow: 'open',
                            field: "marketSalesRank",
                            filter: 'number',
                            headerTooltip: "Ranking de ventas del mercado en unidades dentro de la categoria",
                            width: numberColWidth + 20,
                            hide: self.hideColumn(6, 3),
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "GAP Vs Mercado",
                            field: "salesGap",
                            columnGroupShow: 'open',
                            filter: 'number',
                            headerTooltip: "Diferencia de Participacion entre ventas propias y ventas de mercado",
                            width: numberColWidth + 30,
                            hide: self.hideColumn(6, 4),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererPP
                        },
                        {
                            headerName: "Ventas Un",
                            field: "marketUnits",
                            filter: 'number',
                            headerTooltip: "Venta Mercado en unidades",
                            width: numberColWidth,
                            hide: self.hideColumn(6, 5),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.integerRenderer
                        },
                        {
                            headerName: "% Ventas Un",
                            field: "marketUnitsShare",
                            columnGroupShow: 'open',
                            filter: 'number',
                            headerTooltip: "Participacion en las ventas mercado unitarias de este SKU sobre el total de la categoria",
                            width: numberColWidth + 15,
                            hide: self.hideColumn(6, 6),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRenderer
                        },
                        //{
                        //    headerName: "Rank Ventas Un",
                        //    columnGroupShow: 'open',
                        //    field: "marketUnitsRank",
                        //    filter: 'number',
                        //    headerTooltip: "Ranking de unidades en el mercado dentro de la categoria",
                        //    width: numberColWidth + 25,
                        //    cellClass: $rootScope.getClassForCell
                        //},
                        {
                            headerName: "GAP vs Mercado",
                            field: "unitsGap",
                            columnGroupShow: 'open',
                            filter: 'number',
                            headerTooltip: "Diferencia de Participacion entre ventas unitarias propias y ventas unitarias de mercado",
                            width: numberColWidth + 45,
                            hide: self.hideColumn(6, 7),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererPP
                        },
                        {
                            headerName: "Precio",
                            field: "marketPrice",
                            filter: 'number',
                            headerTooltip: "Precio promedio del mercado para este SKU en este cluster",
                            width: numberColWidth,
                            hide: self.hideColumn(6, 8),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRenderer
                        }]
                },
                {
                    headerName: 'Espacios',
                    children: [
                        {
                            headerName: "Metros",
                            field: "linearSpace",
                            filter: 'number',
                            headerTooltip: "",
                            width: numberColWidth - 15,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.decimalRenderer,
                            hide: self.hideSpaces || self.hideColumn(7, 0)
                        },
                        {
                            headerName: "Frentes",
                            field: "spaceFronts",
                            columnGroupShow: 'open',
                            filter: 'number',
                            headerTooltip: "",
                            width: numberColWidth - 15,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.decimalRenderer,
                            hide: self.hideSpaces || self.hideColumn(7, 1)
                        },
                        {
                            headerName: "% Espacio",
                            field: "spaceShare",
                            columnGroupShow: 'open',
                            filter: 'number',
                            headerTooltip: "Participacion en el espacio lineal de este SKU sobre el total de la categoria",
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRenderer,
                            hide: self.hideSpaces || self.hideColumn(7, 2)
                        },
                        {
                            headerName: "GMROS",
                            field: "gmros",
                            columnGroupShow: 'open',
                            filter: 'number',
                            headerTooltip: "Margen / Espacio Lineal en m2",
                            width: numberColWidth - 15,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRenderer,
                            hide: self.hideSpaces || self.hideColumn(7, 3)
                        },
                        {
                            headerName: "Planograma",
                            field: "itemId",
                            //columnGroupShow: 'open',
                            hide: !self.showPlanogramEdition ? true : self.hideSpaces || self.hideColumn(7, 4),
                            filter: 'text',
                            headerTooltip: "Ver Planograma asociado a este item-cluster",
                            width: numberColWidth + 10,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: function (params) {
                                if (!params.value)
                                    return '';

                                var planogram = '';

                                if (!params.node.group) {
                                    planogram = '<a style="padding: 2px" ng-click="ct.goToPlanogram(' + params.data.itemId + ',' + params.data.clusterId + ')" title="Ir al planograma para este Item-Cluster"> <i class="fa fa-align-justify"></i> </a>';
                                }
                                return planogram;
                            }
                        },
                    ]
                },
                {
                    headerName: 'Inventarios',
                    children: [
                        {
                            headerName: "Disponibilidad",
                            hide: !canView("Availability") || self.hideColumn(8, 0),
                            headerTooltip: "",
                            field: "availability",
                            filter: 'text',
                            width: numberColWidth + 20,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererNoDecimals
                        },
                        {
                            headerName: "Stock $",
                            field: "currentInventory",
                            filter: 'number',
                            headerTooltip: "Costo del Inventario en el Cluster del dia anterior",
                            width: numberColWidth,
                            hide: self.hideColumn(8, 1),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: "Stock %",
                            field: "inventoryShare",
                            filter: 'number',
                            columnGroupShow: 'open',
                            headerTooltip: "Participacion en el costo del inventario de este SKU sobre el total de la categoria",
                            width: numberColWidth + 10,
                            hide: self.hideColumn(8, 2),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRenderer
                        },
                        //{
                        //    headerName: "Dias de Inv",
                        //    field: "inventoryDays",
                        //    columnGroupShow: 'open',
                        //    filter: 'number',
                        //    headerTooltip: "Dias de Inventario mensual por Tienda en el Cluster",
                        //    width: numberColWidth,
                        //    cellClass: $rootScope.getClassForCell,
                        //    cellRenderer: $rootScope.integerRenderer
                        //},
                        {
                            headerName: "Stock Un",
                            field: "currentInventoryUnits",
                            filter: 'number',
                            headerTooltip: "Unidades del Inventario en el Cluster del dia anterior",
                            width: numberColWidth,
                            hide: self.hideColumn(8, 3),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.integerRenderer
                        },
                        {
                            headerName: "Stock CD",
                            field: "inventoryInCds",
                            filter: 'number',
                            headerTooltip: "Suma de unidades de Inventario de los Centros de distribucion del dia anterior",
                            width: numberColWidth,
                            hide: self.hideColumn(8, 4),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.integerRenderer
                        },
                        {
                            headerName: "Dias In-Stock",
                            field: "inStockDays",
                            filter: 'number',
                            headerTooltip: "Dias de stock del item en el mes, considerando el promedio de los meses anteriores.",
                            width: numberColWidth + 15,
                            hide: self.hideColumn(8, 5),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.integerRenderer
                        },
                        {
                            headerName: !companyJson.AssortmentDecisions.Grid.ShowDDSextended ? "DDS" : "Dias de Stock",
                            field: "inventoryDays3",
                            filter: 'number',
                            width: numberColWidth + 15,
                            hide: self.hideColumn(8, 6),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.integerRenderer
                        },
                        {
                            headerName: "Merma",
                            field: "wasteUnitsPercentage",
                            filter: 'number',
                            width: numberColWidth + 15,
                            hide: self.hideColumn(8, 7),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRenderer
                        },
                        {
                            headerName: "Merma Desconocida %",
                            field: "wasteUnknownUnitsPercentage",
                            filter: 'number',
                            width: numberColWidth + 15,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRenderer,
                            hide : true
                        },
                        {
                            headerName: "GMROI",
                            field: "gmroi",
                            filter: 'number',
                            columnGroupShow: 'open',
                            headerTooltip: "Margen / Costo Promedio del Inventario",
                            width: numberColWidth,
                            hide: self.hideColumn(8, 9),
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.decimalRenderer
                        },
                        {
                            headerName: "Merma",
                            field: "currentAdjust",
                            filter: 'number',
                            hide: !self.showWasteUnits || self.hideColumn(8, 10),
                            columnGroupShow: 'open',
                            headerTooltip: "Unidades de Merma Conocida + Merma desconocida Actual",
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.decimalRenderer
                        },]
                }]
        }

        self.anySuggestions = function () {
            var rv = false;

            if (self.pareto) {
                for (var i = 0; i < self.pareto.items.length; i++) {
                    var item = self.pareto.items[i];
                    if (item.suggestionListed != null) {
                        rv = true;
                        break;
                    }
                }
            }

            return rv;
        }
        self.anyChildSuggestions = function (node) {

            var rv = false;

            if (node && node.children) {

                var isListedFn = function (node) {
                    var item = node.data;
                    if (item.suggestionListed != null && item.suggestionListed != undefined && !item.isSuggestionIgnored) {
                        return true;
                    }
                }

                node.children.forEach(function (node) {
                    if (node.data.rowType == "GroupRow") {
                        //bajo un nivel mas para llegar al ClusterRow
                        node.children.forEach(function (node) {
                            if (isListedFn(node))
                                rv = true;
                        });
                    }
                    else {
                        if (isListedFn(node))
                            rv = true;
                    }
                });

            }

            return rv;
        }

        function getClassForSuggestionCell(params) {
            var cls = ["text-center"];

            if (params.value == 'Quitar') {
                cls.push("text-danger");
            }
            else if (params.value == 'Incorporar') {
                cls.push("text-ok");
            }
            else {
                cls = $rootScope.getClassForCell(params);
            }

            //cls = $rootScope.getClassForCell(params);

            return cls;
        }
        function setGridOptions() {

            setColumnDefinitions();

            self.gridOptions = {
                columnDefs: columnDefs,
                enableColResize: true,
                enableSorting: true,
                enableFilter: true,
                groupHideGroupColumns: true,
                groupUseEntireRow: false,
                groupSelectsChildren: false,
                rowHeight: 30,
                rowSelection: "multiple",
                rowDeselection: true,
                showToolPanel: false,// window.innerWidth > 1500,
                suppressRowClickSelection: true,
                suppressCellSelection: false,
                singleClickEdit: true,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                suppressColumnMoveAnimation: $rootScope.suppressColumnMoveAnimation(),
                enableRangeSelection: false,
                angularCompileRows: true,
                angularCompileHeaders: true,
                isExternalFilterPresent: isExternalFilterPresent,
                doesExternalFilterPass: doesExternalFilterPass,
                onRowSelected: rowSelected,
                getNodeChildDetails: getNodeChildDetails,
                onAfterFilterChanged: afterFilterChanged,
                //onGridReady: function () { console.log("Grilla lista"); loadData(); },
                //getRowStyle: function (params) {
                //    var acum = params.data.acumIndex;
                //    if (acum >= 0.2) {
                //        return {
                //            'background-color': 'green'
                //        };
                //    } else if (acum >= 0.95) {
                //        return {
                //            'background-color': '#FFF'
                //        };
                //    } else {
                //        return {
                //            'background-color': '#red'
                //        };
                //    }
                //    return null;
                //}
            }

        }
        function getNodeChildDetails(rowItem) {
            if (rowItem.subcategories && rowItem.subcategories.length > 0 && self.selectedFilterForCluster == 'Todos') {
                return {
                    group: true,
                    expanded: false,
                    // provide ag-Grid with the children of this group
                    children: rowItem.subcategories,
                    // the key is used by the default group cellRenderer
                    key: rowItem.itemEan
                };
            }
            else {
                return null;
            }
        }

        self.hideColumn = function (parentIndex, childIndex) {
            let retorno = false;
            if ((self.matrixOfIndexes != null && self.matrixOfIndexes.length > 0) && (self.matrixOfIndexes[parentIndex].includes(childIndex))) {
                retorno = true;
            }
            return retorno;
        }

        //grid filters
        function isExternalFilterPresent() {
            return self.selectedFilterForStatus != 'Todos' || self.selectedFilterForCluster != 'Todos' || self.selectedFilterForAssortmentStatus != 'Todos' || selectedFilterForText != '';
        }
        function afterFilterChanged(event) {
            getKPIs();
        }
        function doesExternalFilterPass(node) {

            var filter1 = true;
            var filter2 = true;
            var filter3 = true;
            var filter4 = true;

            //filter1 = (node.data.clusterCode == self.selectedFilterForCluster || self.selectedFilterForCluster == 'Todos');

            //switch (self.selectedFilterForStatus) {
            //    case 'Para Validar': filter2 = (node.data.assortmentStatus == 1 || node.data.isSuggestionIgnored); break;
            //    case 'Nuevos': filter2 = (node.data.newIsListed != null); break;
            //    case 'Sugeridos': filter2 = (node.data.suggestionListed != null && !node.data.isSuggestionIgnored); break;
            //    default: filter2 = true; break;
            //}

            //filter3 = (self.selectedFilterForAssortmentStatus == 'Todos' || (self.selectedFilterForAssortmentStatus == 'Activo' && node.data.isListed == true) || (self.selectedFilterForAssortmentStatus == 'Inactivo' && node.data.isListed == false));

            var splittedText = selectedFilterForText.split(';');
            filter4 = (selectedFilterForText == ''
                || (node.data.itemCode != null && node.data.itemCode.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1)
                || (node.data.itemEan != null && node.data.itemEan.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1)
                || (node.data.itemName != null && node.data.itemName.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1)
                || (node.data.subcategory != null && node.data.subcategory.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1)
                || (node.data.brand != null && node.data.brand.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1)
                || (node.data.manufacturer != null && node.data.manufacturer.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1))
                || (node.data.tags != null && containsAllTags(splittedText, node.data.tags));

            return filter1 && filter2 && filter3 && filter4;
        }
        function externalFilterChanged() {

            if (self.gridOptions.api)
                self.gridOptions.api.onFilterChanged();
        }
        function removeFilters() {
            self.selectedFilterForStatus = 'Todos';
            self.selectedFilterForAssortmentStatus = 'Todos';
            self.selectedFilterForCluster = 'Todos';

            externalFilterChanged();
        }
        function containsAllTags(splittedText, tags) {
            if (splittedText.length == 1)
                return tags.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1;
            else {
                var rv = true;
                _.forEach(splittedText, function (value) {
                    var containsTag = _.includes(tags.toLowerCase().trim(), value.toLowerCase().trim());
                    if (!containsTag)
                        rv = false;
                });

                return rv;
            }
        }
        function selectAll(checked, rowNode) {
            if (rowNode == null) {
                self.gridOptions.api.forEachNodeAfterFilter(function (node) {
                    if (node.group)
                        node.setSelected(checked);
                });
            }
        }
        //main actions
        function addToAssortment(node) {

            //solo ejecuto si es un nodo hijo (item-cluster)
            if (!node.group) {

                if ((node.data.isListed === false && node.data.newIsListed == null) || node.data.newIsListed === false) {

                    if (node.data.assortmentStatus < 2 && node.data.newIsListed == null) { //Si tiene estado current o new y no tiene decicion

                        //actualizo decision en NewIsListed y el impacto del nodo              
                        node.data.newIsListed = true;
                        node.data.absPendingImpact = node.data.listingImpact;

                        //actualizo el impacto total (indicador del box)
                        self.pendingImpact += node.data.absPendingImpact;

                        //actualizo el impacto del padre
                        if (node.parent != null)
                            node.parent.data.absPendingImpact += node.data.absPendingImpact;

                        //marco el nodo como dirty
                        node.data.dirty = true;

                        //actualizo el nodo padre
                        updateParentAndRefreshScreen(node, true);
                    }
                    else if (node.data.assortmentStatus < 2 && node.data.newIsListed != null) { //Si tiene estado current o new y tiene decicion
                        //revierto la decision
                        revertDecision(node);

                        //actualizo el nodo padre
                        updateParentAndRefreshScreen(node, false);
                    }
                    else if (node.data.assortmentStatus == 2) //Si est� endosado pero aun no se informo y se lo quiere volver al status anterior
                    {
                        if (node.data.newIsListed == node.data.isListed) { //Es una vuelta atras al estado anterior
                            //actualizo decision en NewIsListed    
                            node.data.newIsListed = true;

                            //desmarco el nodo como dirty
                            node.data.dirty = false;

                            //actualizo el nodo padre
                            updateParentAndRefreshScreen(node, true);
                        }
                        else {
                            //actualizo decision en NewIsListed    
                            node.data.newIsListed = true;

                            //marco el nodo como dirty
                            node.data.dirty = true;

                            //actualizo el nodo padre
                            updateParentAndRefreshScreen(node, true);
                        }
                    }

                }
            }
        }
        function removeFromAssortment(node) {

            //solo ejecuto si es un nodo hijo (item-cluster)
            if (!node.group) {

                if ((node.data.isListed === true && node.data.newIsListed == null) || node.data.newIsListed === true) {

                    //if (node.data.newIsListed == null) {
                    if (node.data.assortmentStatus < 2 && node.data.newIsListed == null) { //Si tiene estado current o new y no tiene decicion
                        //actualizo decision en NewIsListed y el impacto del nodo
                        node.data.newIsListed = false;
                        node.data.absPendingImpact = node.data.delistingImpact;

                        //actualizo el impacto total (indicador del box)
                        self.pendingImpact += node.data.absPendingImpact;

                        //actualizo el impacto del padre
                        if (node.parent != null)
                            node.parent.data.absPendingImpact += node.data.absPendingImpact;

                        //marco el nodo como dirty
                        node.data.dirty = true;

                        //actualizo el nodo padre
                        updateParentAndRefreshScreen(node, true);
                    }
                    //else {
                    else if (node.data.assortmentStatus < 2 && node.data.newIsListed != null) { //Si tiene estado current o new y tiene decicion
                        //si ya habia tomado una decision la revierto
                        revertDecision(node);

                        //actualizo el nodo padre
                        updateParentAndRefreshScreen(node, false);
                    }
                    else if (node.data.assortmentStatus == 2) //Si est� endosado, pero aun no se informo,
                    {
                        if (node.data.newIsListed == node.data.isListed) { //Es una vuelta atras al estado anterior
                            //actualizo decision en NewIsListed    
                            node.data.newIsListed = false;

                            //desmarco el nodo como dirty
                            node.data.dirty = false;

                            //actualizo el nodo padre
                            updateParentAndRefreshScreen(node, true);
                        }
                        else {
                            //actualizo decision en NewIsListed    
                            node.data.newIsListed = false;

                            //marco el nodo como dirty
                            node.data.dirty = true;

                            //actualizo el nodo padre
                            updateParentAndRefreshScreen(node, true);
                        }
                    }
                }
            }
        }

        self.updateSummaryBox = function () {

            self.newListedItems = 0;//cantidad de items dados de alta
            self.newDelistedItems = 0;//cantidad de items dados de baja    
            self.totalListedItems = 0;//diferencia de altas y bajas
            var totalActiveItems = 0;//Items ya listados 

            self.gridOptions.api.forEachNode(function (node) {
                if (node.data.rowType == "ItemRow" && node.data.subcategories) {//evaluo solo a nivel item
                    var allClustersDeListedFlag = true;//indica si todos los clusters del item estan delistados
                    var takenDecision = false;//indica si se tomo una decision en al menos un cluster 
                    var anyClusterListed = node.data.subcategories.some(function (x) {
                        return x.isListed;
                    });//indica si algun cluster ya esta listado
                    var anyClusterNewIsListed = false;// indica si al menos se listo un cluster

                    if (anyClusterListed)
                        totalActiveItems++;

                    for (var i = 0; i < node.data.subcategories.length; i++) {
                        var decision = node.data.subcategories[i].newIsListed;
                        var suggestion = node.data.subcategories[i].suggestionListed;
                        var actual = node.data.subcategories[i].isListed;

                        var clusterIsListed = null; //define el estado del cluster
                        if (decision != null) {
                            takenDecision = true;
                            if (decision == false)
                                clusterIsListed = false;
                            else if (decision == true) {
                                clusterIsListed = true;
                                anyClusterNewIsListed = true;
                            }
                        }
                        else if (suggestion != null) {
                            if (suggestion == false)
                                clusterIsListed = true;
                            else if (suggestion == true)
                                clusterIsListed = false;
                        }
                        else {
                            if (actual == true)
                                clusterIsListed = true;
                            else if (actual == false)
                                clusterIsListed = false;
                        }

                        if (clusterIsListed)
                            allClustersDeListedFlag = false;

                    }
                    if (takenDecision) {
                        if (anyClusterNewIsListed == true && !anyClusterListed) {//tiene que haber al menos uno listado pero antes debian estar todos delistados
                            self.newListedItems++;
                        }
                        else if (allClustersDeListedFlag) {
                            self.newDelistedItems++;
                        }
                    }
                }
            });

            self.totalListedItems = totalActiveItems + self.newListedItems - self.newDelistedItems;
        }

        function revertDecision(node) {

            if (node.data.suggestionListed == null || node.data.suggestionListed == node.data.newIsListed) {

                var revertImpact = 0;
                if (node.data.assortmentStatus < 2 && node.data.newIsListed == false)
                    revertImpact = node.data.delistingImpact;
                else if (node.data.assortmentStatus < 2 && node.data.newIsListed == true)
                    revertImpact = node.data.listingImpact;

                //revierto la decision del nodo hijo (item-cluster)
                node.data.newIsListed = null;
                node.data.absPendingImpact = 0;

                //revierto el impacto en el indicador del box
                self.pendingImpact -= revertImpact;

                //revierto el impacto en el padre
                if (node.parent != null)
                    node.parent.data.absPendingImpact -= revertImpact;

                //marco el nodo como no dirty si es la primera vez. Si es un endosado o nuevo, lo marco dirty, si es un current le saco el dirty
                if (node.data.dirty == undefined)
                    node.data.dirty = true;
                else if (node.data.dirty == true) {
                    if (node.data.assortmentStatus != 0)
                        node.data.dirty = true;
                    else
                        node.data.dirty = false;
                }

                //refresco el nodo
                self.gridOptions.api.refreshRows([node]);

                //actualizo el nodo padre
                updateParentAndRefreshScreen(node, true);
            }
        }
        function acceptSuggestionsForAllChildren(node) {
            //solo ejecuto si es un nodo padre
            if (node.group) {

                //flagueo el nodo como aceptado / revertido
                node.data.accepted = !node.data.accepted;

                //defino la funcion que voy a usar para actualizar los nodos
                var updateFunc = function (node) {
                    //solo actualizo los que tienen sugerencia sin decision
                    if (node.data.suggestionListed != null && node.data.suggestionListed != node.data.newIsListed) {
                        if (node.data.suggestionListed == false)
                            removeFromAssortment(node);
                        else if (node.data.suggestionListed == true)
                            addToAssortment(node);

                    }
                }

                //recorro todos los hijos (no solo filtrados porque el filtro puede ser cualquiera)
                node.children.forEach(function (node) {

                    //si hay rows de grupos en la jerarquia bajo un nivel mas para llegar a los ClusterRow
                    if (node.data.rowType == "GroupRow") {

                        //flagueo el nodo como aceptado / revertido
                        node.data.accepted = !node.data.accepted;

                        node.children.forEach(function (node) {
                            updateFunc(node);
                        });
                    }
                    else {
                        updateFunc(node);
                    }


                });
                self.updateSummaryBox();
            }
        }
        function revertAcceptedSuggestionsForAllChildren(node) {
            //solo ejecuto si es un nodo padre
            if (node.group) {

                //flagueo el nodo como aceptado / revertido
                node.data.accepted = !node.data.accepted;

                //recorro todos los hijos (no solo filtrados porque el filtro puede ser cualquiera)
                node.children.forEach(function (node) {

                    //si hay rows de grupos en la jerarquia bajo un nivel mas para llegar a los ClusterRow
                    if (node.data.rowType == "GroupRow") {

                        //flagueo el nodo como aceptado / revertido
                        node.data.accepted = !node.data.accepted;

                        node.children.forEach(function (node) {
                            //solo revierto los que tienen sugerencia y decision
                            if (node.data.suggestionListed != null && node.data.suggestionListed == node.data.newIsListed) {
                                revertDecision(node);
                            }
                        });
                    }
                    else {
                        //solo revierto los que tienen sugerencia y decision
                        if (node.data.suggestionListed != null && node.data.suggestionListed == node.data.newIsListed) {
                            revertDecision(node);
                        }
                    }


                });
                self.updateSummaryBox();
            }
        }
        function updateParentAndRefreshScreen(node, updateKpis) {

            //actualiza la grilla
            self.gridOptions.api.refreshRows([node]);
            self.gridOptions.api.refreshRows([node.parent]);

            //actualiza los KPI
            if (updateKpis)
                updateKPIs(node, true);
        }
        function rowSelected(event) {
            if (!event.node.group) { //if it's not a group row
                var data = event.node.data;

                if (!self.isEndorse) {
                    changeAssortment(event.node, event.node.selected);
                }
            }
            else {
                event.node.childrenAfterFilter.forEach(function (node) {
                    node.setSelected(event.node.selected);
                });
            }
        }
        //helpers
        function getNodeById(nodeOrId) {
            var rv = nodeOrId;

            if (typeof (nodeOrId) !== "object") {
                self.gridOptions.api.forEachNode(function (node) {
                    if (node.id === nodeOrId) {
                        rv = node;
                    }
                });
            }

            return rv;
        }
        function countParetoSections() {

            angular.forEach(self.paretoSections, function (section) {
                var index = 0;

                angular.forEach(self.pareto.items, function (item) {

                    if (item.acumIndex > section.valueFrom && item.acumIndex <= section.valueTo) {
                        item.paretoSectionIndex = ++index;
                    }
                });

                section.count = index;
            });
        }

        //esta funcion se hizo porque el cliente quiere ver en el filtro solo los cluster que se obvservan en la grilla y esta puede ir cambiando
        self.updateClusterFilter = function (pareto) {
            var auxArray = [];

            angular.forEach(pareto.items, function (item) {

                angular.forEach(item.subcategories, function (sc) {

                    if (!auxArray.length || !auxArray.map(function (x) { return x => x.id }).includes(sc.clusterId)) {

                        auxArray.push({ id: sc.clusterId, name: sc.cluster });
                    }
                });

            });

            self.filters.clusters = auxArray;
            self.filters.clusters.splice(0, 0, { id: null, name: 'Todos' });
        }

        function calculateParetoSectionColor(item) {

            var finalColor = null;

            angular.forEach(self.paretoSections, function (section) {

                if (item.acumIndex > section.valueFrom && item.acumIndex <= section.valueTo) {
                    var color = section.colorRgb;

                    if (section.alpha) {
                        var ix = item.paretoSectionIndex;
                        var alpha = 0;
                        if (section.alpha === "desc")
                            alpha = ix / section.count;
                        if (section.alpha === "asc")
                            alpha = (section.count - (ix - 1)) / section.count;
                        var colors = color.replace("rgb(", "").replace(")", "").split(",");
                        finalColor = ian.format("rgba({0}, {1}, {2}, {3})", colors[0], colors[1], colors[2], alpha)
                    }
                    else {
                        finalColor = color;
                    }

                }
            });

            if (finalColor)
                return { 'background-color': finalColor };


            return finalColor;
        }
        //fetch data
        function getKPIs() {
            //Reseteo las variables
            self.listingSuggestions = 0;
            self.delistingSuggestions = 0;
            self.impact = 0;
            self.pendingImpact = 0;
            self.listingNew = 0;
            self.delistingNew = 0;
            self.totalItemClusters = 0;
            self.totalActiveItemClusters = 0;
            self.actualCoverage = 0;
            self.actualSuggested = 0;
            self.actualNew = 0;
            totalMarketSalesActiveItems = 0;
            totalMarketSales = 0;
            self.totalSales = 0;

            self.gridOptions.api.forEachNodeAfterFilterAndSort(function (node) {
                if (!node.group) {

                    self.impact += node.data.absImpact;
                    self.pendingImpact += node.data.absPendingImpact;
                    totalMarketSales += node.data.marketSales;
                    self.totalSales += node.data.salesTotal;
                    self.totalItemClusters++;

                    if (node.data.isListed)
                        self.totalActiveItemClusters++;

                    updateKPIs(node, false);
                }
            });

            self.actualCoverage = totalMarketSales != null && totalMarketSales != 0 ? totalMarketSalesActiveItems / totalMarketSales : 1; //Si no tengo data de mercado, asumo que tengo el 100% de cobertura

            angular.forEach(self.categoryDetails, function (cd) {
                cd.brandCoverage = totalMarketSales != null && totalMarketSales != 0 ? cd.totalMarketSalesActiveItemsBrand / totalMarketSales : 1;
            });

            //Si ambos filtros son todos, uso el total del surtido activo para las cajitas de decisiones. 
            //Me importa saber cuantos items activos me van a quedar cuando selecciono Todos los Skus y Todos los Estados.
            if (self.selectedFilterForStatus == 'Todos' && self.selectedFilterForAssortmentStatus == 'Todos')
                self.totalItemClusters = self.totalActiveItemClusters;
        }
        function updateKPIs(node, assortmentChanged) {
            var item = node.data;

            var clusters = _.filter(self.clusters, function (o) {
                return o.clusterId == item.clusterId;
            });

            var cluster = clusters.length > 0 ? clusters[0] : null;

            if (!assortmentChanged) {
                self.listingSuggestions += item.listingSuggestions;
                self.delistingSuggestions += item.delistingSuggestions;

                if (item.isListed == true) {
                    self.actualSuggested++;
                    self.actualNew++;
                }
            }


            if (item.newIsListed == true) {
                totalMarketSalesActiveItems += item.marketSales;
                self.listingNew++;
                if (cluster != null) {
                    cluster.usedSpace += item.space == null ? 1 : item.space;
                }
            }
            else if (item.newIsListed == false) {
                totalMarketSalesActiveItems -= item.marketSales;
                self.delistingNew++;
                if (cluster != null) {
                    cluster.usedSpace -= item.space == null ? 1 : item.space;
                }
            }
            else if (item.newIsListed == null) {
                if (item.isListed == true) {

                    totalMarketSalesActiveItems += item.marketSales;

                    if (assortmentChanged) {
                        self.delistingNew--;
                        if (cluster) {
                            cluster.usedSpace += item.space == null ? 1 : item.space;
                        }
                    }
                }
                else {
                    totalMarketSalesActiveItems -= assortmentChanged ? item.marketSales : 0;

                    if (assortmentChanged) {
                        self.listingNew--;
                        if (cluster) {
                            cluster.usedSpace -= item.space == null ? 1 : item.space;
                        }
                    }
                }
            }

            //Cobertura real por bandera

            angular.forEach(self.categoryDetails, function (cd) {

                if (item.storeBrandId == cd.brandId) {

                    if (item.newIsListed == true) {
                        cd.totalMarketSalesActiveItemsBrand += item.marketSales || 0;
                    }
                    else if (item.newIsListed == false) {
                        cd.totalMarketSalesActiveItemsBrand -= item.marketSales || 0;
                    }
                    else if (item.newIsListed == null) {

                        if (item.isListed == true) {

                            cd.totalMarketSalesActiveItemsBrand += item.marketSales || 0;

                        }

                        else {
                            cd.totalMarketSalesActiveItemsBrand -= assortmentChanged && item.marketSales ? item.marketSales : 0;
                        }
                    }

                }
            });

            if (assortmentChanged) {
                self.actualCoverage = totalMarketSales != null && totalMarketSales != 0 ? totalMarketSalesActiveItems / totalMarketSales : 1; //Si no tengo data de mercado, asumo que tengo el 100% de cobertura

                angular.forEach(self.categoryDetails, function (cd) {
                    cd.brandCoverage = totalMarketSales != null && totalMarketSales != 0 ? cd.totalMarketSalesActiveItemsBrand / totalMarketSales : 1;
                });
            }

        }
        function loadData(forceRefresh) {


            ////defino los filtros del pareto
            //var filters = {
            //    categoryId: self.currentCategoryId,
            //    brandId: null,
            //    storeFormatId: null,
            //    customerTypeId: null,
            //    regionId: null,
            //    onlySuggested: false
            //}

            ////Cargo el pareto
            //loadPareto(filters);

            self.applyFilters();

            //Cargo los clusters
            assortmentService.clusters.getClustersByCategory(self.currentCategoryId).then(function (allClusters) {

                self.allClusters = allClusters;

                for (var i = 0; i < self.allClusters.length; i++) {
                    self.itemClusters.push(angular.copy(self.allClusters[i].code));
                }
            });

            //Cargo el pareto Sections
            assortmentService.decisions.getParetoSections(self.currentCategoryId)
                .then(function (sections) {
                    self.paretoSections = sections;
                });
        }

        function loadPareto(filters) {
            if (self.gridOptions.api)
                self.gridOptions.api.showLoadingOverlay();

            //Cargo los datos del pareto
            assortmentService.decisions.getParetoByCategory(filters)
                .then(function (dto) {

                    self.pareto = dto;

                    self.updateClusterFilter(self.pareto);

                    countParetoSections();

                    setColumnDefinitions();
                    self.gridOptions.columnDefs = columnDefs;
                    self.gridOptions.api.refreshView();

                    if (self.gridOptions.api) {
                        self.gridOptions.api.setRowData(dto.items);
                        self.gridOptions.api.refreshView();
                    }

                    if (dto != null && dto.items != null && dto.items.length > 0) {
                        //Seteo los datos del header
                        self.pareto = dto;

                        self.currentCategoryId

                        self.allEndorse = dto.items;

                        if (self.gridOptions.api)
                            loadClusterCategorySpaces();

                        //loadChartData();
                    }

                    //Si vengo con un clusterId desde el categorySummary, filtro por ese cluster.
                    if ($stateParams.clusterId != 0) {

                        var result = _.filter(self.allClusters, function (o) {
                            return o.id == $stateParams.clusterId;
                        });

                        self.filterByCluster(result[0].code);
                    }

                    externalFilterChanged();
                });
        }

        function loadClusterCategorySpaces() {
            assortmentService.clusters.getClusterCategorySpaces(self.currentCategoryId).then(function (clusters) {
                self.clusters = clusters;

                //actualizo los kpi de espacios
                self.gridOptions.api.forEachNode(function (node) {

                    var item = node.data;

                    var clusters = _.filter(self.clusters, function (o) {
                        return o.clusterId == item.clusterId;
                    });

                    var cluster = clusters.length > 0 ? clusters[0] : null;

                    if (item.newIsListed == null) {
                        if (item.isListed == true) {

                            if (cluster != null) {
                                cluster.usedSpace += item.space == null ? 1 : item.space;
                            }
                        }
                        else {
                            if (cluster != null) {
                                //cluster.usedSpace -= item.space == null ? 1 : item.space;
                            }
                        }
                    }
                });

            });
        }
        function loadTags() {
            adminService.tags.getTags()
                .then(function (tags) {
                    self.tags = tags;
                });
        }
        function loadCategoryDetails() {
            assortmentService.decisions.getCategoryDetail(self.currentCategoryId).then(function (catDet) {
                self.categoryDetails = catDet;
                self.categoryDetails.forEach(function (cd) {
                    cd.totalMarketSalesActiveItemsBrand = 0; //no lo agrego al Dto por que es un campo que solo se calcula y se usa en pantalla.
                });
            });
        }
        function getDataFromCategorySummary() {

            //Seteo el titulo tipo Breadcrumb
            var categoryGroup = decodeURIComponent($stateParams.categoryGroup.replace(/-/g, " ").replace(/_/g, "\/"));
            var category = decodeURIComponent($stateParams.category.replace(/-/g, " ").replace(/_/g, "\/"));
            var subcategory = decodeURIComponent($stateParams.subcategory.replace(/-/g, " ").replace(/_/g, "\/"));

            self.title = categoryGroup
            if (category != '') {
                self.title += ' / ' + category;
                if (subcategory != '') {
                    self.title += ' / ' + subcategory;
                }
            }
        }
        function loadChartData() {
            //Traigo data para el chart
            assortmentService.decisions.getAssortmentDashboard($stateParams.categoryId)
                .then(function (assortmentDashboard) {
                    self.assortmentDashboard = assortmentDashboard;

                    self.updateChart(self.timerange);
                    if (companyJson.AssortmentDecisions.CollapseSalesGraph) {
                        $('div.ibox-content.ibox-p-sm').slideUp();
                    }
                });
        }

        function changeSalesOnColumnGroupShow() {
            if (companyJson.Erp && companyJson.Erp.Implementation) {
                if (companyJson.Erp.Implementation == "CencosudColombia"
                    || companyJson.Erp.Implementation == "CencosudChile") {
                    self.salesOnColumnGroupShow = 'open';//venta se muestra solo al desplegar
                }
                else {
                    self.totalSalesOnColumnGroupShow = 'open';//venta TOTAL se muestra solo al desplegar
                }
            }
        }

        function getHiddenColumnIndexes(callback) {
            assortmentService.decisions.getHiddenColumnIndexes().then(function (matrix) {
                self.matrixOfIndexes = matrix;
                callback();
            });
        }

        function loadFiltersByCategory() {
            assortmentService.decisions.getParetoFiltersByCategory(self.currentCategoryId).then(function (filters) {
                //agrego opcion 'Todos' a los filtros
                self.filters = {
                    brands: [{ id: null, name: 'Todas' }],
                    storeFormats: [{ id: null, name: 'Todos' }],
                    customerTypes: [{ id: null, name: 'Todos' }],
                    regions: [{ id: null, name: 'Todas' }],
                    clusters: [{ id: null, name: 'Todos' }]
                }
                self.filters.brands = self.filters.brands.concat(filters.brands);
                self.filters.storeFormats = self.filters.storeFormats.concat(filters.storeFormats);
                self.filters.customerTypes = self.filters.customerTypes.concat(filters.customerTypes);
                self.filters.regions = self.filters.regions.concat(filters.regions);
                self.filters.clusters = self.filters.clusters.concat(filters.clusters);

                //seteo la seleccion inicial de filtros
                self.selectedFilters.status = self.isEndorse ? "Para Validar" : "Sugeridos";
                self.selectedFilters.assortmentStatus = "Todos";
                self.selectedFilters.brand = self.filters.brands[0];
                self.selectedFilters.storeFormat = self.filters.storeFormats[0];
                self.selectedFilters.customerType = self.filters.customerTypes[0];
                self.selectedFilters.region = self.filters.regions[0];
                if ($stateParams.clusterId) {
                    self.selectedFilters.cluster = self.filters.clusters.find(function (c) {
                        return c.id === parseInt($stateParams.clusterId);
                    });

                    self.selectedFilters.cluster = self.selectedFilters.cluster || self.filters.clusters[0];
                }
                else {
                    self.selectedFilters.cluster = self.filters.clusters[0];
                }
                self.selectedFilters.groupByBrand = false;
                self.selectedFilters.groupByFormat = false;
                self.selectedFilters.groupByCustomer = false;
                self.selectedFilters.groupByRegion = false;

                //init data
                loadData();

            });
        }

        function canView(property) {
            return $rootScope.decisionsConfig[property] != undefined ? $rootScope.decisionsConfig[property] : false;
        }


        function init() {
            
            changeSalesOnColumnGroupShow();

            //init columnDefs and gridOptions
            setGridOptions();

            loadFiltersByCategory();

            //Load data for title and endorsement
            getDataFromCategorySummary();

            loadCategoryDetails();
            loadChartData();
            loadTags();
            self.loadGrid = true;
        }

        getHiddenColumnIndexes(function () {
            init();
        });

        self.changeAssortmentDecision = function (nodeId) {
            //Obtengo el nodo de la grilla
            var node = getNodeById(nodeId);

            //Logica para distinguir nodos hijos y padres
            if (!node.group) {
                if (!self.isEndorse) {
                    updateAssortmentDecision(node);
                }
            }

            //marco el nodo como dirty
            node.data.dirty = true;

            //actualiza la grilla
            self.gridOptions.api.refreshRows([node]);
            self.gridOptions.api.refreshRows([node.parent]);

            //actualiza los KPI
            updateKPIs(node, true);
        }
        function updateAssortmentDecision(node) {

            if (node.data.newIsListed == null) {
                if (node.data.isListed == true) {
                    node.data.newIsListed = false;
                    node.data.absPendingImpact = node.data.delistingImpact;
                }
                else {
                    node.data.newIsListed = true;
                    node.data.absPendingImpact = node.data.listingImpact;
                }

                self.pendingImpact += node.data.absPendingImpact;

                if (node.parent != null)
                    node.parent.data.absPendingImpact += node.data.absPendingImpact;
            }
            else {
                var revertImpact = 0;
                if (node.data.newIsListed == false)
                    revertImpact = node.data.delistingImpact;
                else if (node.data.newIsListed == true)
                    revertImpact = node.data.listingImpact;

                node.data.newIsListed = null;
                node.data.absPendingImpact = 0;

                self.pendingImpact -= revertImpact;
                if (node.parent != null)
                    node.parent.data.absPendingImpact -= revertImpact;
            }
        }
        function changeAssortment(rowNode, isSelected) {
            if (isSelected) {
                //Si no tengo decision tomada
                if (rowNode.data.assortmentStatus == 0) {
                    changeAssortmentUpdate(true, rowNode);
                }
                else {
                    changeAssortmentUpdate(false, rowNode);
                }
            }
            else //Si la estoy deseleccionando 
            {
                if (rowNode.data.assortmentStatus == 0)
                    changeAssortmentUpdate(false, rowNode);
                else {
                    changeAssortmentUpdate(true, rowNode);
                }
            }
            rowNode.data.dirty = true;

            self.gridOptions.api.refreshRows([rowNode]);
            self.gridOptions.api.refreshRows([rowNode.parent]);

            updateKPIs(rowNode, true);
        }
        function changeAssortmentUpdate(add, rowNode) {
            if (add) {
                if (rowNode.data.isListed == true) {
                    rowNode.data.newIsListed = false;
                    rowNode.data.absPendingImpact = rowNode.data.delistingImpact;
                }
                else {
                    rowNode.data.newIsListed = true;
                    rowNode.data.absPendingImpact = rowNode.data.listingImpact;
                }

                self.pendingImpact += rowNode.data.absPendingImpact;

                if (rowNode.parent != null)
                    rowNode.parent.data.absPendingImpact += rowNode.data.absPendingImpact;
            }
            else {
                var revertImpact = 0;
                if (rowNode.data.newIsListed == false)
                    revertImpact = rowNode.data.delistingImpact;
                else if (rowNode.data.newIsListed == true)
                    revertImpact = rowNode.data.listingImpact;

                rowNode.data.newIsListed = null;
                rowNode.data.absPendingImpact = 0;

                self.pendingImpact -= revertImpact;
                if (rowNode.parent != null)
                    rowNode.parent.data.absPendingImpact -= revertImpact;
            }
        }
        //FIN DEPRECATED: To Be Deleted
    });