"use strict";

angular.module("prisma")

    .controller("DashboardOilCtrl", function ($scope, $rootScope, $translate, homeService, $filter, companyJson) {
        var self = this;

        self.sameStores = true;
        self.timerange = 'year';
        self.homeDashboard = null;
        self.homeDashboard2 = null;
        self.compareYtd = null;
        self.compareMonth = null;
        self.boxesCompareTo = 'Goal'; //Goal | Ytd | Month
        self.selectedMonth = 8;
        self.sliderOptions = {
            showTicksValues: true,
            stepsArray: [
                { value: 1, legend: 'Ene' },
                { value: 2 },
                { value: 3, legend: 'Mar' },
                { value: 4 },
                { value: 5, legend: 'May' },
                { value: 6 },
                { value: 7, legend: 'Jul' },
                { value: 8 },
            ]
        }
        self.notification = null;
        self.notificationPriceArrow = 'fa fa-level-up';
        self.notificationUnitsArrow = 'fa fa-level-up';

        self.hideBoxes = false;
        self.hideBoxes = companyJson.Dashboard.HideBoxes;
        self.decisionsConfig = companyJson.AssortmentDecisions;

        $scope.$on("slideEnded", function () {
            self.updateBoxes('Month');
        });


        $scope.$watch('ct.sameStores', function (newVal, oldVar) {
            if (newVal != oldVar) {
                getYTDComparison(newVal);
                getDashboard(newVal);
                getNotifiactions(newVal);
            }
        });



        //chart
        self.updateChart = function (timerange) {

            self.timerange = timerange;

            if (self.homeDashboard == null || self.homeDashboard == '')
                return;

            self.chart = c3.generate({
                bindto: '#homeDashboardSaleschart',
                data: {
                    json: (timerange == 'week' ? self.homeDashboard.lastWeekXValues : timerange == 'month' ? self.homeDashboard.lastMonthXValues : self.homeDashboard.lastYearXValues),
                    keys: {
                        x: 'dateString',
                        value: ['sales', 'units', 'margin', 'price'],
                    },
                    names: {
                        sales: translations.SalesLegend,
                        units: translations.UnitsLegend,
                        margin: translations.MarginLegend,
                        price: translations.PriceLegend
                    },
                    axes: {
                        sales: 'y',
                        units: 'y',
                        margin: 'y',
                        price: 'y2'
                    },
                    type: 'bar',
                    types: {
                        price: 'line',
                    },
                    colors: {
                        sales: '#1ab394',
                        units: '#1c84c6',
                        margin: '#f8ac59',
                        price: '#ED5565'
                    },
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            fit: true,
                            format: function (x) {
                                if (self.timerange == 'week') {
                                    return $rootScope.shortWeekDayNames[x.getDay()];
                                } else if (self.timerange == 'month') {
                                    return x.getDate() + '-' + $rootScope.shortMonthNames[(x.getMonth())];
                                } else if (self.timerange == 'year') {
                                    return x.getFullYear() + '-' + (x.getMonth() + 1);
                                }
                            }
                        }
                    },
                    y: {
                        tick: {
                            format: d3.format("s")
                        }
                    },
                    y2: {
                        show: true,
                        label: translations.PriceInS,
                        tick: {
                            format: d3.format('.1f')
                        },
                        min: 0
                    }
                },
                tooltip: {
                    format: {
                        title: function (d) {
                            var toFormat = '%B %d, %Y';
                            if (self.timerange == 'year')
                                toFormat = '%B %Y';

                            var format = d3.time.format(toFormat);
                            return format(d);
                        },
                        value: function (value, ratio, id) {
                            var format = id === 'price' ? d3.format('$.2f') : id === 'units' ? d3.format(',.0f') : d3.format('$,.0f');
                            return format(value);
                        }
                    }
                }
            });
        }

        //boxes
        self.getMainBoxTitle = function (box) {
            var rv = "";
            switch (box) {
                case "sales": rv = "Ventas"; break;
                case "units": rv = "Unidades"; break;
                case "cost": rv = "Costos"; break;
                case "margin": rv = "Margen $"; break;
            }

            if (self.boxesCompareTo === "Month") {
                switch (self.selectedMonth) {
                    case 1: rv += " Enero"; break;
                    case 2: rv += " Febrero"; break;
                    case 3: rv += " Marzo"; break;
                    case 4: rv += " Abril"; break;
                    case 5: rv += " Mayo"; break;
                    case 6: rv += " Junio"; break;
                    case 7: rv += " Julio"; break;
                    case 8: rv += " Agosto"; break;
                    case 9: rv += " Septiembre"; break;
                    case 10: rv += " Octubre"; break;
                    case 11: rv += " Noviembre"; break;
                    case 12: rv += " Diciembre"; break;
                }
            }
            else {
                rv += " YTD";
            }

            return rv;
        }
        self.getBoxCompareValue = function (box) {
            if (box && self.compareYtd) {

                var value = null;

                if (self.boxesCompareTo === 'Month') {
                    value = $filter('percentage')(self.compareMonth[box + 'PyDelta'], 1);
                }
                else {
                    value = $filter('percentage')(self.compareYtd[box + 'PyDelta'], 1);
                }

                return self.compareYtd[box + 'PyDelta'] != 0 ? value : 'N/A';
            }
        }
        self.getBoxCompareTooltip = function (box) {
            if (box && self.compareYtd) {

                var value = null;
                var filter = box === 'units' ? $filter('number') : $filter('currency');

                if (self.boxesCompareTo === 'Month') {
                    value = box === 'units' ? filter(self.compareMonth[box + 'Py'], 0) : filter(self.compareMonth[box + 'Py'], '$', 0);
                }
                else {
                    value = box === 'units' ? filter(self.compareYtd[box + 'Py'], 0) : filter(self.compareYtd[box + 'Py'], '$', 0);
                }

                return value;
            }
        }

        self.getMainBoxValueGoal = function (box) {
            if (box && self.compareYtd) {

                var value = null;
                var filter = box === 'units' ? $filter('abbreviate') : $filter('abbreviate');
                var prefix = box === 'units' ? null : "$";
                var decimals = box === 'units' ? 0 : 2;

                //if (self.boxesCompareTo === 'Month') {
                //    value = filter(self.compareMonth[box], decimals, prefix);
                //}
                //else if (self.boxesCompareTo === 'Goal') {
                filter = $filter('percentage');
                value = filter(self.compareYtd[box + 'GoalGapP'], 2);
                //}
                //else {
                //    value = filter(self.compareYtd[box + 'Ytd'], decimals, prefix);
                //}

                return self.compareYtd[box + 'GoalGapP'] != 0 ? value : 'N/A';
            }
        }
        self.getMainBoxTooltip = function (box) {
            if (box && self.compareYtd) {

                var value = null;
                var filter = box === 'units' ? $filter('number') : $filter('currency');

                if (self.boxesCompareTo === 'Month') {
                    value = box === 'units' ? filter(self.compareMonth[box], 0) : filter(self.compareMonth[box], '$', 0);
                }
                else {
                    value = box === 'units' ? filter(self.compareYtd[box + 'Ytd'], 0) : filter(self.compareYtd[box + 'Ytd'], '$', 0);
                }

                return value;
            }
        }
        self.isMainBoxGoalSignPositive = function (box) {
            if (box && self.compareYtd) {

                return self.compareYtd[box + 'GoalGapP'] > 0;
            }
        }

        self.getMainBoxValueYTD = function (box) {
            if (box && self.compareYtd) {

                var value = null;
                var filter = box === 'units' ? $filter('abbreviate') : $filter('abbreviate');
                var prefix = box === 'units' ? null : "$";
                var decimals = box === 'units' ? 0 : 2;

                value = filter(self.compareYtd[box + 'Ytd'], decimals, prefix);
            }

            return value;
        }
        self.getMainBoxValueAnual = function (box) {
            if (box && self.compareYtd) {

                var value = null;
                var filter = box === 'units' ? $filter('abbreviate') : $filter('abbreviate');
                var prefix = box === 'units' ? null : "$";
                var decimals = box === 'units' ? 0 : 2;

                value = filter(self.compareYtd[box + 'GoalTotal'], decimals, prefix);

                return self.compareYtd[box + 'GoalTotal'] != 0 ? value : 'N/A';
            }
        }
        self.getMainBoxAnualTooltip = function (box) {
            if (box && self.compareYtd) {

                var value = null;
                var filter = box === 'units' ? $filter('number') : $filter('currency');

                if (self.boxesCompareTo === 'Month') {
                    value = box === 'units' ? filter(self.compareMonth[box + 'GoalTotal'], 0) : filter(self.compareMonth[box + 'GoalTotal'], '$', 0);
                }
                else {
                    value = box === 'units' ? filter(self.compareYtd[box + 'GoalTotal'], 0) : filter(self.compareYtd[box + 'GoalTotal'], '$', 0);
                }

            }
            return value;
        }

        self.getActualYtdValue = function (box) {
            if (self.compareYtd != null) {
                var value = null;
                var filter = box === 'units' ? $filter('abbreviate') : $filter('abbreviate');

                value = box === 'units' ? filter(self.compareYtd[box + 'Goal'], 0) : filter(self.compareYtd[box + 'Goal'], '$', 0);

                return value;
            }
        }
        self.getActualYtdTooltipValue = function (box) {
            if (self.compareYtd != null) {
                var value = null;
                var filter = box === 'units' ? $filter('number') : $filter('currency');

                value = box === 'units' ? filter(self.compareYtd[box + 'Goal'], 0) : filter(self.compareYtd[box + 'Goal'], '$', 0);

                return value;
            }
        }

        self.getProjectedYtdValue = function (box) {
            if (self.compareYtd != null) {
                var value = null;
                var filter = box === 'units' ? $filter('abbreviate') : $filter('abbreviate');

                value = box === 'units' ? filter((self.compareYtd[box + 'Projected'] + self.compareYtd[box + 'Ytd']), 0) : filter((self.compareYtd[box + 'Projected'] + self.compareYtd[box + 'Ytd']), '$', 0);

                return self.compareYtd[box + 'Projected'] != 0 ? value : 'N/A';
            }
        }
        self.getProjectedYtdTooltipValue = function (box) {
            if (self.compareYtd != null) {
                var value = null;
                var filter = box === 'units' ? $filter('number') : $filter('currency');

                value = box === 'units' ? filter((self.compareYtd[box + 'Projected'] + self.compareYtd[box + 'Ytd']), 0) : filter((self.compareYtd[box + 'Projected'] + self.compareYtd[box + 'Ytd']), '$', 0);

                return value;
            }
        }
        self.projectedPositive = function (box) {
            if (box && self.compareYtd) {
                var projected = self.compareYtd[box + 'Projected'] + self.compareYtd[box + 'Ytd'];
                var annual = self.compareYtd[box + 'GoalTotal'];

                if (box != 'cost')
                    return projected >= annual;
                else
                    return projected < annual;
            }
        }

        self.getBoxCompareIcon = function (box) {

            if (box && self.compareYtd) {

                var delta = null;
                var cls = '';

                if (self.boxesCompareTo === 'Month') {
                    delta = self.compareMonth[box + 'PyDelta'];
                }
                else {
                    delta = self.compareYtd[box + 'PyDelta'];
                }

                if (box != 'cost') {
                    if (!delta)
                        cls = '';
                    else if (delta > 0.005)
                        cls = 'fa fa-level-up text-navy';
                    else if (delta < -0.005)
                        cls = 'fa fa-level-down text-danger';
                    else
                        cls = 'fa fa-anchor';
                }
                else {
                    if (!delta)
                        cls = '';
                    else if (delta > 0.005)
                        cls = 'fa fa-level-up text-danger';
                    else if (delta < -0.005)
                        cls = 'fa fa-level-down text-navy';
                    else
                        cls = 'fa fa-anchor';
                }


                return cls;
            }
        }
        self.isBoxSignPositive = function (box) {
            if (box && self.compareYtd) {

                var value = null;

                value = self.compareYtd[box + 'PyDelta'];

                if (box == 'cost') {
                    if (value <= 0)
                        return true
                    else
                        return false;
                }
                else {
                    if (value > 0)
                        return true
                    else
                        return false;
                }
            }

        }

        self.toggleLegend = function (chart) {
            if (!chart.legend.visible) {
                chart.legend.show();
                chart.legend.visible = true;
            }
            else {
                chart.legend.hide();
                chart.legend.visible = false;
            }
        }
        self.updateBoxes = function (compareTo) {

            if (compareTo === 'Month') {
                findMonthToCompare(self.selectedMonth);
            }

            self.boxesCompareTo = compareTo;

            if (compareTo === 'Goal')
                renderGoalCharts();

        }

        function findMonthToCompare(month) {

            var rv = null;
            angular.forEach(self.compareYtd.vsPriorYearByMonth, function (item) {
                if (item.month == month && item.year == self.compareYtd.currentYear) {
                    rv = item;
                }
            });

            self.compareMonth = rv;

            return rv;
        }

        function renderGoalCharts() {

            var boxes = ['sales', 'margin', 'units', 'cost'];

            angular.forEach(boxes, function (box) {

                var chart = c3.generate(getGoalChartTemplate(box));


                setTimeout(function () {
                    chart.load({
                        columns: [['objetivo', self.compareYtd[box + 'Goal']]],
                    });
                }, 500);

                setTimeout(function () {
                    chart.load({
                        columns: [['actual', self.compareYtd[box + 'Ytd']],
                        ['proyectado', self.compareYtd[box + 'Projected']]
                        ],
                    });
                    chart.groups([['proyectado', 'actual']]);
                }, 1500);

                self[box + 'GoalChart'] = chart;
            });
        }
        function getGoalChartTemplate(box) {

            var template = {
                bindto: ian.format('#{0}GoalChart', box),
                data: {
                    columns: [],
                    type: 'bar',
                    groups: [],
                    colors: {
                        actual: '#1f77b4',
                        proyectado: '#aec7e8',
                        objetivo: '#1ab394',
                    },
                    order: null
                },
                size: {
                    height: 65,
                },
                bar: {
                    width: 5
                },
                axis: {
                    rotated: true,
                    x: { show: false },
                    y: { show: false }
                },
                tooltip: {
                    show: true,
                    format: {
                        title: function (x) { return 'vs Plan'; },
                        value: function (value, ratio, id) {
                            return box === 'units' ? $filter('abbreviate')(value, 0) : $filter('abbreviate')(value, '$', 0);
                        }
                    }
                },
                legend: {
                    show: false
                }
            };

            return template;
        }

        //competitor in map
        
        self.getPriceLabel = function (competitor) {
            var myPrice = 4.00;

            if (competitor.price > myPrice) {
                return "danger";
            }

            if (competitor.price < myPrice) {
                return "primary";
            }

            return "warning";
        }

        //notification
        self.reason1 = true;
        self.reason2 = true;
        self.showR1 = function () { return self.reason1 };
        self.showR2 = function () { return self.reason2 };
        self.reason1Agreed = false;
        self.reason1Disagreed = false;
        self.reason2Agreed = false;
        self.reason2Disagreed = false;
        self.disagreeClass = function (reason) {
            if (reason === 1 && self.reason1Disagreed)
                return "disagreed";

            if (reason === 2 && self.reason2Disagreed)
                return "disagreed";

            return "";
        }

        var translations = null;
        $translate(["DiscardConfirmTitle", "DiscardConfirmSub", "YesDiscard", "DiscardMessageTitle", "DiscardMessageComment", "ThanksForFeedback", "ThanksForFeedbackComment", "ThanksForFeedbackPoints", "ThanksForFeedbackTitle2", "DisagreeTitle", "DisagreeSub", "Send", "SalesLegend", "PriceInS", "UnitsLegend", "MarginLegend", "PriceLegend"])
            .then(function (all) {
                translations = all;
            });

        self.discard = function () {
            swal({
                title: translations.DiscardConfirmTitle,
                text: translations.DiscardConfirmSub,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: translations.YesDiscard,
                closeOnConfirm: false
            }, function () {
                self.reason1 = false;
                $scope.$apply();
                swal(translations.DiscardMessageTitle, translations.DiscardMessageComment, "success");
            });
        }

        self.agree = function (reason) {

            var points = 500; //por el agree / disagree;
            if (reason === 1) {
                self.reason1Agreed = true;
            } else {
                self.reason2Agreed = true;
            }
            grantPoints(points);
            $scope.$apply();

            swal(translations.ThanksForFeedback, translations.ThanksForFeedbackComment + points + translations.ThanksForFeedbackPoints, "success");
        }

        self.disagree = function (reason) {

            swal({
                title: translations.DisagreeTitle,
                text: translations.DisagreeSub,
                type: "input",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: translations.Send,
                closeOnConfirm: false,
                closeOnCancel: false
            }, function (feedback) {
                var points = 500; //por el agree / disagree;

                if (reason === 1) {
                    self.reason1Disagreed = true;
                    self.reason1feedback = feedback ? feedback : null;
                } else {
                    self.reason2Disagreed = true;
                    self.reason2feedback = feedback ? feedback : null;
                }

                if (feedback)
                    points += 500;

                grantPoints(points);

                $scope.$apply();

                if (feedback) {
                    swal(translations.ThanksForFeedback, translations.ThanksForFeedbackComment + points + translations.ThanksForFeedbackPoints, "success");
                } else {
                    swal(translations.ThanksForFeedbackTitle2, translations.ThanksForFeedbackComment, "error");
                }
            });
        }

        function grantPoints(points) {
            $rootScope.$broadcast("pointsAdded", { points: points });
        }

        function loadData() {
            //Traigo data para el chart
            getDashboard(true);
            //traigo data para los boxes
            getYTDComparison(true);

            getNotifiactions(true);



        }

        function getNotifiactions(withSameStores) {
            homeService.dashboards.getNotification(withSameStores)
                .then(function (notif) {
                    if (notif) {
                        self.notification = notif;
                        self.notificationPriceArrow = notif.causePrice.split('%')[0].indexOf('aument&oacute;') > 0 ? 'fa fa-level-up' : notif.causePrice.split('%')[0].indexOf('cay&oacute;') > 0 ? 'fa fa-level-down' : '';
                        self.notificationUnitsArrow = notif.causeUnits.split('%')[0].indexOf('aument&oacute;') > 0 ? 'fa fa-level-up' : notif.causeUnits.split('%')[0].indexOf('cay&oacute;') > 0 ? 'fa fa-level-down' : '';
                    }
                });
        }

        self.isLoadingChart = false;
        function getDashboard(withSameStores) {
            self.isLoadingChart = true;
            homeService.dashboards.getHomeDashboard(withSameStores)
                .then(function (homeDashboard) {
                    self.isLoadingChart = false;
                    if (homeDashboard == null || homeDashboard == '')
                        return;

                    self.homeDashboard = homeDashboard;

                    self.updateChart(self.timerange);
                });
        }

        self.isLoadingBoxes = false;
        function getYTDComparison(withSameStores) {
            self.isLoadingBoxes = true;
            homeService.dashboards.getYtdComparison(withSameStores)
                .then(function (dashboardComparisonDto) {
                    self.isLoadingBoxes = false;

                    self.compareYtd = dashboardComparisonDto;
                    self.updateBoxes('Goal');
                });

        }
        self.compositionInf = {
            name: 'INF',
            icl: 7.83,
            co2: 0.48,
            iva: 6.35,
            comision: 6.06,
            flete: 0.08,
            iibb: 1.36,
            refineria: 22.02,
            price: 44.18,
            iclPrc: 17,
            co2Prc: 1,
            ivaPrc: 14,
            comisionPrc: 14,
            fletePrc: 0.2,
            iibbPrc: 3,
            refineriaPrc: 50,
        }
        self.compositionInfD = {
            name: 'IDS',
            icl: 4.94,
            co2: 0.56,
            iva: 6.78,
            comision: 4.57,
            flete: 0.08,
            iibb: 1.33,
            refineria: 25.53,
            price: 43.80,
            iclPrc: 11,
            co2Prc: 1,
            ivaPrc: 15,
            comisionPrc: 10,
            fletePrc: 0.1,
            iibbPrc: 3,
            refineriaPrc: 58,

        }
        self.compositionSup = {
            name: 'SUP',
            icl: 7.83,
            co2: 0.48,
            iva: 5.25,
            comision: 4.48,
            flete: 0.08,
            iibb: 1.17,
            refineria: 18.67,
            price: 37.98,
            iclPrc: 21,
            co2Prc: 1,
            ivaPrc: 14,
            comisionPrc: 12,
            fletePrc: 0.2,
            iibbPrc: 3,
            refineriaPrc: 49,
        }
        self.compositionD = {
            name: 'DSL',
            icl: 4.94,
            co2: 0.56,
            iva: 5.49,
            comision: 3.38,
            flete: 0.08,
            iibb: 1.11,
            refineria: 20.93,
            price: 36.50,
            iclPrc: 13.5,
            co2Prc: 2,
            ivaPrc: 15,
            comisionPrc: 9,
            fletePrc: 0.2,
            iibbPrc: 3,
            refineriaPrc: 57,
        }

        self.selectedComposition = angular.copy(self.compositionInf);


        function init() {

            //load data
            loadData();
        }

        init();
    });

