angular.module("prisma")

    .controller("spacesTicketsCtrl", function ($scope, $stateParams, $translate, promotionsService, $timeout, authService, ngDialog, spaceService) {

        //Varibles
        //console.log('authService', authService.authentication.userName);
        var self = this;
        self.search = "";
        console.log('$stateParams', $stateParams);

        self.editedTicket = null;

        self.showFormPriority = true;

        self.commentary = { username: authService.authentication.userName, message: '', createdDate: '' };

        self.STATUS = {
            NEW: 1,
            RESOLVED: 2,
            CLOSED: 3
        }

        self.PRIORITY = {
            LOW: 1,
            MEDIUM: 2,
            HIGH: 3
        }


        self.filterPass = function (item) {
            return
            (self.search == '' || item.title.toLowerCase().indexOf(self.search.toLowerCase()) > -1 || item.description.toLowerCase().indexOf(self.search.toLowerCase()) > -1);

        }

        self.removeItemPlanogramFilter = function () {
            self.setFilterPriority('');
        }

        self.textPrioritys = "";

        self.textTypes = "";

        self.priorityChange = function (ticket) {
            self.showFormPriority = !self.showFormPriority
            self.editTicket(ticket);
        }

        self.editTicket = function (ticket) {
            var dataEdit = {
                id: ticket.id,
                priority: ticket.priority,
                status: ticket.status,
                layoutObjectId: ticket.layoutObjectId,
                planogramLevelItemId: ticket.planogramLevelItemId
            }

            console.log('dataedit', dataEdit);
            spaceService.tickets.editTicket(dataEdit).then(function (res) {
                console.log('guardado');
            });
        }

        //Funciones

        self.getColor = function (status) {
            rv = ""

            if (status == self.STATUS.NEW)
                rv = "#1C84C6";

            else if (status == self.STATUS.RESOLVED)
                rv = "#F8AC59";

            else if (status == self.STATUS.CLOSED)
                rv = "#1AB394";

            else
                rv = "#f5f5f5";

            return rv;
        }


        self.getPriorityText = function (priority) {
            for (var i = 0; i < self.priorityTickets.length; i++) {
                if (self.priorityTickets[i].id == priority)
                    return self.priorityTickets[i].name;
            }
        }

        self.getStatusText = function (status) {
            if (status == 1)
                return translations.NEW;
            if (status == 2)
                return translations.Resolved;
            if (status == 3)
                return translations.Closed;

            return status;
        }

        self.showNewTicketModal = function () {
            self.newTicket = GenerateNewTicket();

            ngDialog.open({
                template: 'ticketNewDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-500',
                scope: $scope
            });
        }

        self.showEditTicketModal = function (ticket) {
            if (ticket != null)
                self.editedTicket = ticket;


            ngDialog.open({
                template: 'ticketEditDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            });
        }

        function GenerateNewTicket() {
            return {
                id: 0,
                status: translations.NEW,
                title: '',
                description: '',
                type: '',
                priority: '',
                created: new Date(),
                update: new Date(),
                user: authService.authentication.userName,
                comments: [],
                watch: true
            };
        }

        self.saveNewTicket = function () {
            self.newTicket.id = self.tickets.length + 1;
            self.tickets.push(self.newTicket);
            ngDialog.close();
        }

        self.saveEditTicket = function () {
            ngDialog.close();
            swal(translations.SaveEditTicketTitle, translations.SaveEditTicketTitleSubtitle, 'success');
        }

        self.resolverTicket = function () {
            self.editedTicket.status = self.STATUS.RESOLVED;
            self.editTicket(self.editedTicket);
        }

        self.closeTicket = function () {
            self.editedTicket.status = self.STATUS.CLOSED;
            self.editTicket(self.editedTicket);
        }



        self.timeSince = function (date) {

            if (typeof date != 'object')
                date = new Date(date);

            var seconds = Math.floor((new Date() - date) / 1000);

            var interval = Math.floor(seconds / 31536000);

            if (interval >= 1) {
                return translations.Since + interval + translations.YearAgo + (interval > 1 ? 's' : '');
            }
            interval = Math.floor(seconds / 2592000);
            if (interval >= 1) {
                return translations.Since + interval + translations.MonthAgo + (interval > 1 ? 'es' : '');
            }
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
                return translations.Since + interval + translations.DayAgo + (interval > 1 ? 's' : '');
            }
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
                return translations.Since + interval + translations.HourAgo + (interval > 1 ? 's' : '');
            }
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
                return translations.Since + interval + translations.MinuteAgo + (interval > 1 ? 's' : '');
            }
            return Math.floor(seconds) + translations.Seconds;
        }

        self.commentTicket = function () {

            var dataComment = {
                ticketId: self.editedTicket.id,
                message: self.commentary.message
            }

            spaceService.tickets.addComment(dataComment).then(function (res) {
                console.log('guardado');
                var comment = angular.copy(self.commentary);
                comment.createdDate = new Date();
                self.editedTicket.comments.unshift(comment);
                self.commentary.message = '';

            });
        }

        self.toggleLook = function (ticket) {
            ticket.watch = !ticket.watch;
        }

        function loadData() {

            if ($stateParams.planogramLevelItemId != 0) {
                spaceService.tickets.getItemTickets($stateParams.planogramLevelItemId).then(function (tickets) {
                    console.log('tickets', tickets);
                    self.tickets = tickets;
                });
            } else if ($stateParams.layoutObjectId != 0) {
                spaceService.tickets.getPlanogramTickets($stateParams.layoutObjectId).then(function (tickets) {
                    console.log('tickets', tickets);
                    self.tickets = tickets;
                });
            } else {
                spaceService.tickets.getTickets().then(function (tickets) {
                    console.log('tickets', tickets);
                    self.tickets = tickets;
                });
            }
        }

        self.priorityTickets = [];
        var translations = null;
        self.newTicket = null;

        function init() {
            $translate(["Low", "Medium", "High", "NEW", "Resolved", "Closed", "SaveEditTicketTitle",
                "SaveEditTicketTitleSubtitle", "Seconds", "Since", "YearAgo", "MonthAgo", "DayAgo", "HourAgo", "MinuteAgo"])
                .then(function (all) {
                    translations = all;

                    self.newTicket = GenerateNewTicket();

                    self.priorityTickets = [
                        { id: 1, name: translations.Low },
                        { id: 2, name: translations.Medium },
                        { id: 3, name: translations.High },
                    ]
                });

            loadData();
        }

        init();
    });