'use strinct';
(function () {
    // Link: https://parkji.co.uk/2013/08/11/native-drag-and-drop-in-angularjs.html
    angular.module('prisma')
        .directive('draggable', ['$log', '$parse', '$document', '$window', '$timeout', 'DnDService', draggableDirective]);

    /**
     * Directiva draggable permite arrastrar un elemento por la pagina.
     * @param {any} $log Servicio de log de angular.
     * @param {any} $parse Servicio de parse de angular.
     * @param {any} $document Servicio de document de angular.
     * @param {any} $window Servicio de window de angular.
     * @param {any} dnDService Servicio de Drag and Drop.
     */
    function draggableDirective($log, $parse, $document, $window, $timeout, dnDService) {
        // $log.debug('draggableDirective::ctor');
        var uid = 0;
        var duid = 0;

        function linkFn(scope, $element, $attr) {
            var localUId = ++duid;
            var methodName = 'draggableDirective::link';
            var hasOnHold = false;
            var holdTimeoutId;
            // $log.debug(methodName);
            $element.data('duid', localUId);

            var dataGetter;
            if ($attr.draggableData) {
                dataGetter = $parse($attr.draggableData);
            } else {
                dataGetter = angular.noop;
            }

            // Parsea la expresion para obtener el Id del elemento que se esta arrastrando.
            var idGetter;
            if ($attr.draggableId) {
                idGetter = $parse($attr.draggableId);
            } else {
                idGetter = function () {
                    var id = ++uid;
                    var ret = 'ian-draggable-target-' + String(id);
                    return ret;
                };
            }

            // Parsea la expresion para obtener el target del elemento que se esta arrastrando.
            var targetGetter;
            if ($attr.draggableTarget) {
                targetGetter = $parse($attr.draggableTarget);
            } else {
                targetGetter = angular.noop;
            }

            var snapToGridGetter;
            if ($attr.draggableSnapToGrid) {
                snapToGridGetter = $parse($attr.draggableSnapToGrid);
            } else {
                snapToGridGetter = function snapToGridFn() {
                    return { x: 1, y: 1 };
                }
            }

            var moveAtSetter;
            if ($attr.draggableMoveAt) {
                var moveAtSetterAux = $parse($attr.draggableMoveAt);
                moveAtSetter = function ($event, x, y) {
                    moveAtSetterAux(scope, { $event: $event, x: x, y: y });
                };
            } else {
                moveAtSetter = function wrapperMoveAt($event, x, y) {
                    // Actualiza la posici�n de la imagen.
                    moveAt(x, y);
                }
            }

            var raiseDragEnd;
            if ($attr.onDragend) {
                raiseDragEnd = $parse($attr.onDragend);
            } else {
                raiseDragEnd = angular.noop;
            }

            var enableGetter;
            var unWatchDraggableEnable;

            if ($attr.draggableEnable) {
                enableGetter = $parse($attr.draggableEnable);

                unWatchDraggableEnable = scope.$watch(function () { return enableGetter(scope); }, function (newVal, oldVal) {
                    //$log.debug('draggableDirective::draggableEnable ($watch) newVal: %o  oldVal: %o', newVal, oldVal);
                    enableEvents(newVal);

                });

            } else {
                unWatchDraggableEnable = angular.noop;
                enableGetter = function () {
                    return true;
                };
            }

            var onHold;
            if ($attr.onHold) {
                hasOnHold = true;
                onHold = $parse($attr.onHold);
            }

            var holdTimeoutGetter;
            if ($attr.draggableHoldTimeout) {
                holdTimeoutGetter = $parse($attr.draggableHoldTimeout);
            }
            else {
                holdTimeoutGetter = function () { return 500; };
            }

            // TODO: Cancelar cuando se presiona la tecla ESC.
            var currentDroppable = null;

            /**
             * Handler del evento global mouseup/touchend.
             * @event 
             * @param {any} $event Evento capturado.
             */
            function onGlobalEndHandler($event) {
                //$log.debug(methodName + ' (end) $event: %o', $event);
                $event.preventDefault();
                $event.stopPropagation();

                $timeout.cancel(holdTimeoutId);

                $timeout().then(function () {
                    angular.element('.ian-dragging').remove();
                    angular.element('.ian-active-drag').removeClass('ian-active-drag');

                    // Cursor
                    //setCursor('auto');
                });

                // Cursor
                //setCursor('auto');
                $element.removeClass('drag');
                var $directive = dnDService.getDirective($element);
                if (angular.isObject($directive)) {
                    $directive.removeClass('drag');
                }

                // Dispara el evento drop.
                raiseEvent(currentDroppable, 'drop', $event);

                var $clone = dnDService.getDraggeable();
                if ($clone && $clone.length) {
                    var dndRemoveAfter = $clone.data('dragAndDropRemoveAfterUse');
                    if (angular.isDefined(dndRemoveAfter) && dndRemoveAfter) {
                        $clone.remove();
                    }
                }

                // Dispara el evento darg-end
                raiseDragEnd(scope, { $event: $event });

                angular.element('.ian-dragging').remove();
                angular.element('.ian-active-drag').removeClass('ian-active-drag');

                // Libera los recursos al terminar al soltar el drag.
                $document
                    .off('mousemove touchmove', onGlobalMoveHandler);
                angular.element($window)
                    .off('mouseup touchend', onGlobalEndHandler);

                // Limpia los recursos.
                currentDroppable = null;
                dnDService.setData(null);
                dnDService.setDirective(null);
                dnDService.setOnDrag(false);
                dnDService.setDraggeable(null);
                //dnDService.setOffset(null);
            };

            /**
             * Handler del evento global mousemove/touchmove.
             * @event 
             * @param {any} $event Evento capturado.
             */
            function onGlobalMoveHandler($event) {
                var isOnDrag = dnDService.isOnDrag();
                // $log.debug(methodName + ' (move)\n\t$event: %o\n\tisOnDrag: %o', $event, isOnDrag);
                if (isOnDrag) {
                    $event.preventDefault();
                    $event.stopPropagation();

                    $timeout.cancel(holdTimeoutId);

                    // Obtiene el elemento que se va a mover.
                    var $clone = dnDService.getDraggeable();
                    if (!angular.isObject($clone)) {
                        $clone = getDragabble($event, $element);
                        dnDService.setDraggeable($clone);

                        // Cambia la posici�n del elemento.
                        moveAtSetter($event, $event.pageX, $event.pageY);

                        // Cursor
                        //setCursor('move');
                        $element.addClass('drag');
                    }

                    var cursor;

                    // Oculta el elemtento y se fija que hay debajo.
                    var dragging = angular.element('.ian-dragging');
                    $event.target.hidden = true;
                    dragging.hide();
                    var elemBelow = document.elementFromPoint($event.clientX, $event.clientY);
                    $event.target.hidden = false;
                    dragging.show();

                    var x = $event.pageX;
                    var y = $event.pageY;

                    // mousemove events may trigger out of the window (when the ball is dragged off-screen)
                    // if clientX/clientY are out of the window, then elementfromPoint returns null
                    if (elemBelow) {
                        // Busca si el elemento es droppables.
                        var droppableBelow = angular.element(elemBelow).closest('[droppable]');
                        var $droppableBelow = angular.element(droppableBelow);
                        var $currentDroppable = angular.element(currentDroppable);

                        // Hay dropeable abajo.
                        if (droppableBelow.length) {
                            // Si son distintos dispara los eventos dragleave y dragenter.
                            if ($currentDroppable.data('dropZoneId') !== $droppableBelow.data('dropZoneId')) {
                                raiseDragLeave($event);
                                currentDroppable = droppableBelow;
                                raiseEvent($droppableBelow, 'dragenter', $event);
                            } else {
                                // Se disparava de forma recursiva el evento mousemove.
                                raiseEvent(currentDroppable, 'dragover', $event);
                            }

                            // Si esta sobre el area de Drop aplica el snap to grid.
                            var grid = snapToGridGetter(scope);
                            if (angular.isNumber(grid)) {
                                grid = {
                                    x: grid,
                                    y: grid
                                };
                            } else if (angular.isObject(grid)) {
                                grid.x = grid.x || 1;
                                grid.y = grid.y || 1;
                            } else {
                                grid = {
                                    x: 1,
                                    y: 1
                                };
                            }

                            x = $event.pageX - ($event.pageX % grid.x);
                            y = $event.pageY - ($event.pageY % grid.y);

                            cursor = 'copy';
                        } else {
                            // Verifica si no esta sobre el indicador de drop.
                            var dropIndicator = angular.element(elemBelow).closest('.drop-indicator');
                            var $dropIndicator = angular.element(dropIndicator);
                            if ($dropIndicator.length) {
                                cursor = 'copy';
                            } else {
                                raiseDragLeave($event);
                                cursor = 'no-drop';
                            }
                        }
                    } else {
                        raiseDragLeave($event);
                        cursor = 'no-drop';
                    }

                    // Actualiza la posici�n de la imagen.
                    moveAtSetter($event, x, y);

                    // Cursor
                    //setCursor(cursor);
                }
            };

            /**
             * Dispara el evento dragleave en el droppable objetivo.
             */
            function raiseDragLeave($event) {
                raiseEvent(currentDroppable, 'dragleave', $event);
                currentDroppable = null;
            }

            /**
             * Dispara el evento en el elemento objetivo.
             * @param  {Element} target Droppable objetivo.
             * @param  {string} eventName Nombre del evento.
             * @param  {jQuery.Event} $event Evento original.
             */
            function raiseEvent(target, eventName, $event) {
                if (target) {
                    var offset = $element.data('offset');
                    var newEvent = {
                        type: eventName,
                        originalEvent: $event.originalEvent,
                        toElement: $event.toElement,
                        screenY: $event.screenY,
                        screenX: $event.screenX,
                        pageY: $event.pageY,
                        pageX: $event.pageX,
                        offsetY: $event.offsetY,
                        offsetX: $event.offsetX,
                        clientY: $event.clientY,
                        clientX: $event.clientX,
                        buttons: $event.buttons,
                        button: $event.button,
                        which: $event.which,
                        view: $event.view,
                        target: $event.target,
                        shiftKey: $event.shiftKey,
                        relatedTarget: $event.relatedTarget,
                        metaKey: $event.metaKey,
                        eventPhase: $event.eventPhase,
                        currentTarget: $event.currentTarget,
                        ctrlKey: $event.ctrlKey,
                        cancelable: $event.cancelable,
                        bubbles: $event.bubbles,
                        altKey: $event.altKey,
                        delegateTarget: $event.delegateTarget,
                        handleObj: $event.handleObj,
                        data: $event.data || offset,
                        offset: offset
                    };
                    angular.element(target).trigger(newEvent);
                }
            }

            /**
             * Mueve la copia del elemento que se esta arrastrando a la posici�n solicitada.
             * @param {any} pageX Coordenada X.
             * @param {any} pageY Coordenada Y.
             */
            function moveAt(pageX, pageY) {
                //$log.debug('draggableDirective::moveAt\n\tpageX: %o\n\tpageY: %o', pageX, pageY);
                var $clone = dnDService.getDraggeable();
                if ($clone && $clone.length) {
                    // Fix posici�n.
                    var shiftXAux = $clone.data('shiftX') || 0;
                    var shiftYAux = $clone.data('shiftY') || 0;
                    var shiftX = parseFloat(shiftXAux);
                    var shiftY = parseFloat(shiftYAux);
                    $clone.css({
                        left: String(pageX - shiftX) + 'px',
                        top: String(pageY - shiftY) + 'px'
                    });
                }
            };

            /**
             * Configura el cursor en el body y en el elemento que se esta arrastrando.
             * @param {any} value Tipo de cursor.
             */
            function setCursor(value) {
                // $log.debug('draggableDirective::setCursor value: %o', value);
                //if (value) {
                //    angular.element('body').css('cursor', value);

                //    var $clone = dnDService.getDraggeable();
                //    if ($clone && $clone.length) {
                //        $clone.css('cursor', value);
                //    }

                //    $element.css('cursor', value);
                //}
            };

            /**
             * Devuelve el elemento draggable.
             * @param {any} $event Referencia del evento.
             * @param {any} $refElement Referencia del elemento.
             * @returns El elemento draggable.
             */
            function getDragabble($event, $refElement) {
                var $clone = null;

                // Si existe el target lo resuelve.
                var target = targetGetter(scope, { $event: $event, $element: $refElement });
                if (angular.isString(target)) {
                    // Busca el elemento.
                    $clone = angular.element(target);
                } else {
                    var id = idGetter(scope, { $event: $event });

                    // Clona el elemento.
                    $clone = $refElement.clone();
                    $refElement.addClass('ian-active-drag');
                    var rect = $refElement[0].getBoundingClientRect();

                    // ReSharper disable once UsageOfPossiblyUnassignedValue
                    var shiftX = $event.clientX - rect.left;
                    var shiftY = $event.clientY - rect.top;
                    $clone.data('id', id)
                        .data('shiftX', shiftX)
                        .data('shiftY', shiftY)
                        .addClass('dragging')
                        .addClass('ian-dragging')
                        .css({
                            'zIndex': Number.MAX_SAFE_INTEGER,
                            'position': 'absolute',
                            // ReSharper disable once QualifiedExpressionMaybeNull
                            'width': $refElement.width() + 'px',
                            'heigth': $refElement.height() + 'px',
                            'zoom': 1,
                            'opacity': 0.5
                        });

                    angular.element('body').append($clone);

                    var offset = { x: shiftX, y: shiftY };
                    dnDService.setOffset(offset);
                }

                return $clone;
            }


            function onGlobaDragStarHandler($event) {
                $event.preventDefault();
                $event.stopPropagation();

                // $log.debug(methodName + ' (mousedown) $event: %o', $event);
                var enable = enableGetter(scope);

                // Solo click izquierdo.
                if (enable && $event.which === 1) {
                    var data = dataGetter(scope, { $event: $event });
                    dnDService.setData(data);
                    dnDService.setDirective($element);
                    dnDService.setOnDrag(true);

                    $document
                        .off('mousemove touchmove', onGlobalMoveHandler)
                        .on('mousemove touchmove', onGlobalMoveHandler);

                    // Libera los recursos al terminar al soltar el drag.
                    angular.element($window)
                        .off('mouseup touchend', onGlobalEndHandler)
                        .on('mouseup touchend', onGlobalEndHandler);

                    if (hasOnHold) {
                        $timeout.cancel(holdTimeoutId);
                        var timeout = holdTimeoutGetter(scope);
                        if (!angular.isNumber(timeout)) {
                            timeout = 500;
                        }

                        holdTimeoutId = $timeout(function () {
                            onHold(scope, { $event: $event });
                        }, 500);
                    }
                }
            }

            function enableEvents(enable) {
                $element
                    //.css('cursor', 'pointer')
                    .off('mousedown touchstart mouseup touchend dragstart');
                if (enable) {
                    $element
                        //.css('cursor', 'move')
                        .on('dragstart', function onDragStart($event) {
                            return false;
                        })
                        .on('mousedown touchstart', onGlobaDragStarHandler);
                }
            }

            //$element.data('cursor', $element.css('cursor'));
            $element.prop('draggable', false);

            var enable = enableGetter(scope);
            enableEvents(enable);

            scope.$on('$destroy',
                function $destroy() {
                    enableEvents(false);
                    unWatchDraggableEnable();
                    // $log.debug(methodName + ' ($destroy)');
                    // var cursor = $element.data('cursor');
                    //var cursor = $element.data('cursor');

                    // $element.css('cursor', cursor);
                    //$element.css('cursor', cursor);
                });
        }

        var ret = {
            restrict: 'A',
            link: linkFn
        };
        return ret;
    }
})();