"use strict";

angular.module("prisma")
    .service("queueService", function (httpService, $window, $q) {
        var self = this;

        self.queuedTasks = {
            queue: [],
            getTop5: function () {
                var deferred = $q.defer();

                httpService.get('queuedtasks/tasks/' + 5).then(function (newTasks) {
                    updateQueuedTasks(newTasks);
                    deferred.resolve(newTasks);
                });

                return deferred.promise;
            },
            createDummyTask: function () {
                var deferred = $q.defer();

                httpService.post('queuedtasks/tasks/createDummy').then(function () {
                    self.queuedTasks.getTop5().then(function () {
                        deferred.resolve();
                    });
                });

                return deferred.promise;
            },
            pendingTasksCount: function () {
                var c = 0;
                angular.forEach(self.queuedTasks.queue, function (task) {
                    if (task.statusString === 'Queued' || task.statusString === 'InProgress')
                        c++;
                });

                return c;
            }
        }

        var polling = false;
        var pollingIntervalId = null;
        function updateQueuedTasks(newTasks) {

                //OJO: esto siempre tiene que estar antes de actualizar self.queuedTasks.queue porque se compara contra las mismas para ver si cambiaron.
                showToastForChangedTasks(newTasks);

                //Actualizo las queuedTasks
                self.queuedTasks.queue = newTasks;

                //if all done then stop polling
                if (polling && self.queuedTasks.pendingTasksCount() === 0) {
                    $window.clearInterval(pollingIntervalId);
                    pollingIntervalId = null;
                    polling = false;
                }

                //if pending work start polling
                if (!polling && self.queuedTasks.pendingTasksCount() > 0) {
                    pollingIntervalId = $window.setInterval(self.queuedTasks.getTop5, 10000);
                    polling = true;
                }
        }

        function showToastForChangedTasks(newTasks) {

            if (self.queuedTasks.queue && self.queuedTasks.queue.length > 0) {

                angular.forEach(self.queuedTasks.queue, function (task) {
                    if (task.statusString === 'InProgress') {
                        angular.forEach(newTasks, function (newTask) {
                            if (newTask.id === task.id) {
                                if (newTask.statusString === 'Succeeded') {
                                    toastr.success('La tarea finalizo correctamente', task.displayName, { timeOut: 5000, positionClass: "toast-top-right" })
                                } else if (newTask.statusString === 'Failed') {
                                    toastr.error('Ocurrió un error ejecutando la tarea', task.displayName, { timeOut: 5000, positionClass: "toast-top-right" })
                                }
                            }
                        })
                    }
                    if (task.statusString === 'Queued') {
                        angular.forEach(newTasks, function (newTask) {
                            if (newTask.id === task.id) {
                                if (newTask.statusString === 'InProgress') {
                                    toastr.info('La tarea se comenzó a procesar', task.displayName, { timeOut: 5000, positionClass: "toast-top-right" })
                                }
                                else if (newTask.statusString === 'Succeeded') {
                                    toastr.success('La tarea finalizo correctamente', task.displayName, { timeOut: 5000, positionClass: "toast-top-right" })
                                } else if (newTask.statusString === 'Failed') {
                                    toastr.error('Ocurrió un error ejecutando la tarea', task.displayName, { timeOut: 5000, positionClass: "toast-top-right" })
                                }
                            }
                        })
                    }

                });
            }

        }

    });