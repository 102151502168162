var _controller = function ($scope, $attrs, $filter, $timeout, $rootScope, priceService, homeService) {
    var self = this;
    //console.log('suggestions', self);


}

angular.module('prisma').component('notifications', {
    templateUrl: 'app/shared_logic/components/notifications/notifications.component.html',
    bindings: {
        price: '<'
    },

    controller: _controller
});


