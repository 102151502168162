angular.module("prisma")
    .service('errorService', function errorService($log, ngDialog, authService, $location) {
        var self = this;

        self.isOpen401 = false;

        self.handle = function (data, status, headers, config, finalUrl) {
            if (status && finalUrl) {
                $log.error(ian.format('Error {0} invocando al servicio {1}', status, finalUrl));
            }

            if (data) {
                $log.error(data);
            }

            switch (status) {
                case 401: {

                    //AHORA SE MANEJA DESDE EL AUTHINTERCEPTOR

                    //if (!self.isOpen401) {
                    //    self.isOpen401 = true;
                    //    ngDialog.open({
                    //        template: 'sessionExpiredDialog',
                    //        className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-300',
                    //        preCloseCallback: function () {
                    //            self.isOpen401 = false;
                    //            $location.path('/login');
                    //        }
                    //    });
                    //}

                    break;
                }
                case 403: {
                    ngDialog.closeAll();
                    ngDialog.open({
                        template: 'unauthorizedDialog',
                        className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-300'
                    });
                    break;
                }
                case 409: {
                    ngDialog.open({
                        template: 'validationExceptionDialog',
                        className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-300',
                        controller: function ($scope) {
                            $scope.error = data;
                            $scope.url = finalUrl;
                        }
                    });
                    break;
                }
                case 500: {
                    ngDialog.open({
                        template: 'unhandledExceptionDialog',
                        className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-450',
                        controller: function ($scope) {
                            $scope.message = data.message;
                            $scope.stackTrace = data.stackTrace;
                            $scope.url = finalUrl;
                            $scope.download = function () {

                                var element = document.createElement('a');
                                var content = buildFileContent($scope.url, $scope.message, $scope.stackTrace)
                                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
                                element.setAttribute('download', getFileName());

                                element.style.display = 'none';
                                document.body.appendChild(element);

                                element.click();

                                document.body.removeChild(element);
                            }
                        }
                    });
                    break;
                }
                case 502: {
                    ngDialog.closeAll();
                    ngDialog.open({
                        template: 'longProcessDialog',
                        className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-300'
                    });
                    break;
                }

            }

        }


        function getFileName() {
            return ian.format("Prisma error log {0}.txt", getDate());
        }

        function getDate() {
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1; //January is 0!

            var h = today.getHours();
            var m = today.getMinutes();
            var s = today.getSeconds();

            var yyyy = today.getFullYear();
            if (dd < 10) {
                dd = '0' + dd
            }
            if (mm < 10) {
                mm = '0' + mm
            }
            var today = ian.format("{0}.{1}.{2}-{3}.{4}.{5}", yyyy, mm, dd, h, m, s);
            return today;
        }

        function buildFileContent(url, message, stackTrace) {
            var text = "PRISMA ERROR LOG FILE";

            text += ian.format("Client Timestamp: {0}", getDate());
            text += "\n \n";
            text += ian.format("Prisma Url: {0}", url);
            text += "\n \n";
            text += ian.format("Message: {0}", message);
            text += "\n \n";
            text += ian.format("Stacktrace: {0}", stackTrace);

            return text;
        }
    })


