"use strict";

angular.module("prisma")
  .controller("ScorecardCtrl", function (homeService, assortmentService, $rootScope) {
      var self = this;
      var numberColWidth = 100;
      self.scorecard = [];
      self.allBrands = [];
      self.selectedBrand = 2;

      self.columnDefs = [
          {
              headerName: "Informaci&oacute;n General",
              children: [
                {
                    headerName: "Sector / Categoria",
                    headerTooltip: "",
                    field: "categoryName",
                    width: numberColWidth + 55,
                    pinned: 'left',
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: {
                        renderer: 'group',
                        checkbox: false,
                        innerRenderer: function (params) {
                            return params.value;
                        }
                    }
                },
                {
                    headerName: "Peso de Categoria",
                    headerTooltip: "Peso de la categoria Vs Sector",
                    field: "categoryWeight",
                    filter: 'number',
                    sort: 'desc',
                    columnGroupShow: 'open',
                    width: numberColWidth + 44,
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: $rootScope.percentageRendererNoDecimals,
                },
                {
                    headerName: "Peso de Categoria M",
                    headerTooltip: "Peso de la categoria Vs Sector",
                    field: "marketCategoryWeight",
                    filter: 'number',
                    columnGroupShow: 'open',
                    width: numberColWidth + 55,
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: $rootScope.percentageRendererNoDecimals,
                },
                //{
                //    headerName: "&Uacute;ltima Revisi&oacute;n",
                //    headerTooltip: "Fecha de ultima revision",
                //    field: "rangeReviewLastRevision",
                //    filter: 'number',
                //    columnGroupShow: 'open',
                //    width: numberColWidth + 30,
                //    cellClass: $rootScope.getClassForCell,
                //    cellRenderer: $rootScope.dateRenderer
                //},
              ],
          },
          {
              headerName: "Ventas",
              children: [
                    {
                        headerName: "Ventas",
                        headerTooltip: "Suma de Ventas YTD",
                        field: "sales",
                        filter: 'number',
                        columnGroupShow: 'open',
                        width: numberColWidth,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.moneyRendererNoDecimals
                    },
                    {
                        headerName: "Ventas AA",
                        headerTooltip: "Suma de Ventas a�o anterior",
                        field: "salesPY",
                        filter: 'number',
                        columnGroupShow: 'open',
                        width: numberColWidth,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.moneyRendererNoDecimals
                    },
                    {
                        headerName: "Objetivo Venta",
                        headerTooltip: "Objetivo de venta YTD",
                        field: "salesGoal",
                        filter: 'number',
                        width: numberColWidth + 20,
                        columnGroupShow: 'open',
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.moneyRendererNoDecimals,
                    },
                    {
                        headerName: "VS AA",
                        headerTooltip: "Ventas vs a�o anterior",
                        field: "salesPYDelta",
                        filter: 'number',
                        columnGroupShow: 'open',
                        width: numberColWidth - 10,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.percentageRendererPP,
                        //cellStyle: function (params) { return params.value < 0 ? { color: 'red' } : { color: 'green' }; }
                    },
                    {
                        headerName: "VS Objetivo",
                        headerTooltip: "Ventas vs Objetivo",
                        field: "salesGoalDelta",
                        filter: 'number',
                        width: numberColWidth + 10,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.percentageRendererPP,
                        cellStyle: function (params) { return (params.value < -0.02) ? { color: 'red' } : params.value >= -0.02 && params.value <= 0 ? { color: '#caca00' } : { color: 'green' }; }
                    },
              ]
          },
          {
              headerName: "Margen $",
              children: [
                    {
                        headerName: "Margen $",
                        headerTooltip: "Suma de Margen YTD",
                        field: "margin",
                        filter: 'number',
                        columnGroupShow: 'open',
                        width: numberColWidth + 40,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.moneyRendererNoDecimals
                    },
                    {
                        headerName: "Margen $ AA",
                        headerTooltip: "Suma de Margen AA",
                        field: "marginPY",
                        filter: 'number',
                        columnGroupShow: 'open',
                        width: numberColWidth + 30,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.moneyRendererNoDecimals
                    },
                    {
                        headerName: "Objetivo Margen $",
                        headerTooltip: "Objetivo de Margen YTD",
                        field: "marginGoal",
                        filter: 'number',
                        columnGroupShow: 'open',
                        width: numberColWidth + 65,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.moneyRendererNoDecimals,
                    },
                    {
                        headerName: "VS AA",
                        headerTooltip: "Margen $ vs a�o anterior",
                        field: "marginPYDelta",
                        filter: 'number',
                        columnGroupShow: 'open',
                        width: numberColWidth - 10,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.percentageRendererPP,
                    },
                    {
                        headerName: "VS Objetivo",
                        headerTooltip: "Margen $ vs Objetivo",
                        field: "marginGoalDelta",
                        filter: 'number',
                        width: numberColWidth + 10,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.percentageRendererPP,
                        cellStyle: function (params) { return (params.value < 0) ? { color: 'red' } : params.value > 0 ? { color: 'green' } : { color: '#caca00' }; }
                    },
              ]
          },
          {
              headerName: "Margen 2",
              children: [
                    {
                        headerName: "Margen 2",
                        headerTooltip: "Suma de Margen 2 YTD",
                        field: "margin2",
                        filter: 'number',
                        columnGroupShow: 'open',
                        width: numberColWidth + 40,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.moneyRendererNoDecimals
                    },
                    {
                        headerName: "Margen 2 AA",
                        headerTooltip: "Suma de Margen 2 AA",
                        field: "margin2PY",
                        filter: 'number',
                        columnGroupShow: 'open',
                        width: numberColWidth + 30,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.moneyRendererNoDecimals
                    },
                    {
                        headerName: "VS AA",
                        headerTooltip: "Margen 2 vs a�o anterior",
                        field: "margin2PYDelta",
                        filter: 'number',
                        width: numberColWidth - 10,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.percentageRendererPP,
                    },
              ]
          },
          {
              headerName: "Unidades",
              children: [
                    {
                        headerName: "Unidades",
                        headerTooltip: "Suma de UnidadesYTD",
                        field: "units",
                        filter: 'number',
                        columnGroupShow: 'open',
                        width: numberColWidth,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.integerRenderer
                    },
                    {
                        headerName: "Unidades AA",
                        headerTooltip: "Suma de unidades A�o anterior",
                        field: "unitsPY",
                        filter: 'number',
                        columnGroupShow: 'open',
                        width: numberColWidth + 5,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.integerRenderer
                    },
                    {
                        headerName: "Objetivo Unidades",
                        headerTooltip: "Objetivo de unidades YTD",
                        field: "unitsGoal",
                        filter: 'number',
                        width: numberColWidth + 50,
                        columnGroupShow: 'open',
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.integerRenderer,
                    },
                    {
                        headerName: "VS AA",
                        headerTooltip: "Unidades Vs a�o anterior",
                        field: "unitsPYDelta",
                        filter: 'number',
                        width: numberColWidth - 10,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.percentageRendererPP,
                        cellStyle: function (params) { return (params.value < -0.01) ? { color: 'red' } : params.value >= -0.01 && params.value <= 0.01 ? { color: '#caca00' } : { color: 'green' }; }
                    },
              ]
          },
          {
              headerName: "Ventas Mercado",
              children: [
                  {
                      headerName: "Ventas Mercado",
                      headerTooltip: "Suma de Ventas Mercado YTD",
                      field: "marketSales",
                      filter: 'number',
                      columnGroupShow: 'open',
                      width: numberColWidth + 27,
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.moneyRendererNoDecimals
                  },
                  {
                      headerName: "Ventas Mercado AA",
                      headerTooltip: "Suma de Ventas Mercado a�o anterior",
                      field: "marketSalesPY",
                      filter: 'number',
                      columnGroupShow: 'open',
                      width: numberColWidth + 55,
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.moneyRendererNoDecimals
                  },
                  {
                      headerName: "VS Mercado AA",
                      headerTooltip: "Ventas Mercado vs a�o anterior",
                      field: "marketSalesPYDelta",
                      filter: 'number',
                      width: numberColWidth + 35,
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.percentageRendererPP,
                      cellStyle: function (params) { return (params.value < 0) ? { color: 'red' } : params.value >= 0 && params.value <= 0.02 ? { color: '#caca00' } : { color: 'green' }; }
                  },
              ]
          },
          {
              headerName: "Comp. vs Mercado",
              children: [
                  {
                      headerName: "Vs Mercado",
                      headerTooltip: "Comparacion vs Mercado",
                      field: "shareCencosud",
                      filter: 'number',
                      width: numberColWidth + 35,
                      cellClass: $rootScope.getClassForCell,
                      cellStyle: function (params) { return (params.value < 0) ? { color: 'red' } : params.value == 0 ? { color: '#caca00' } : { color: 'green' }; },
                      cellRenderer: function (params) {
                          return ((params.data.salesPYDelta - params.data.marketSalesPYDelta) * 100).toFixed(1) + " PP";;
                      },
                  },
              ]
          },
          {
              headerName: "Art&iacute;culos",
              children: [
                  {
                      headerName: "Activos",
                      headerTooltip: "Cantidad total de articulos activos",
                      field: "activeSkus",
                      filter: 'number',
                      width: numberColWidth - 10,
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.integerRenderer,
                  },
                  {
                      headerName: "Activos AA",
                      headerTooltip: "Cantidad total de articulos activos el a�o anterior",
                      field: "activeSkusPY",
                      filter: 'number',
                      columnGroupShow: 'open',
                      width: numberColWidth,
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.integerRenderer,
                  },
              ]
          },
          {
              headerName: "Cobertura",
              children: [
                  {
                      headerName: "Cobertura",
                      headerTooltip: "% de Cobertura actual de la categoria",
                      field: "coverage",
                      columnGroupShow: 'open',
                      filter: 'number',
                      width: numberColWidth - 10,
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.percentageRendererNoDecimals,
                  },
                  {
                      headerName: "Objetivo",
                      headerTooltip: "% de Cobertura objetivo de la cadena seleccionada",
                      field: "targetCoverage",
                      columnGroupShow: 'open',
                      filter: 'number',
                      width: numberColWidth - 15,
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.percentageRendererNoDecimals,
                  },
                  {
                      headerName: "VS Objetivo",
                      headerTooltip: "Cobertura Actual vs Objetivo",
                      field: "targetCoverageDelta",
                      filter: 'number',
                      width: numberColWidth,
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.percentageRendererPP,
                      cellStyle: function (params) {
                          var valor = params.value * 100;
                          return (valor > 2 && valor <= 5 || (valor < -2 && valor >= -5)) ? { color: '#caca00' } : (valor >= -2&& valor <= 2) ? { color: 'green' } : { color: 'red' };
                      }
                  },
              ]
          },
          {
              headerName: "D&iacute;as de inventario",
              children: [
                  {
                      headerName: "Dias Restantes",
                      headerTooltip: "Dias Restantes de inventario",
                      field: "inventoryDaysPYDelta",
                      filter: 'number',
                      width: numberColWidth + 33,
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.integerRenderer,
                      cellStyle: function (params) { return (params.value > 1) ? { color: 'red' } : params.value == 0 || params.value == 1 ? { color: '#caca00' } : { color: 'green' }; }
                  },
              ]
          },
      ]

      self.gridOptions = {
          columnDefs: self.columnDefs,
          enableColResize: true,
          enableSorting: true,
          //enableFilter: true,
          groupHideGroupColumns: true,
          groupUseEntireRow: false,
          groupSelectsChildren: true,
          rowHeight: 30,
          rowSelection: "multiple",
          rowDeselection: true,
          showToolPanel: false,
          suppressRowClickSelection: true,
          suppressCellSelection: true,
          singleClickEdit: true,
          icons: {
              columnRemoveFromGroup: '<i class="fa fa-remove"/>',
              filter: '<i class="fa fa-filter"/>',
              sortAscending: '<i class="fa fa-long-arrow-down"/>',
              sortDescending: '<i class="fa fa-long-arrow-up"/>',
              groupExpanded: '<i class="fa fa-minus-square-o"/>',
              groupContracted: '<i class="fa fa-plus-square-o"/>',
              columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
              columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
          },
          suppressColumnMoveAnimation: $rootScope.suppressColumnMoveAnimation(),
          enableRangeSelection: false,
          angularCompileRows: true,
          //isExternalFilterPresent: isExternalFilterPresent,
          //doesExternalFilterPass: doesExternalFilterPass,
          getNodeChildDetails: getNodeChildDetails
      }

      self.changeBrand = function (brandId)
      {
          self.gridOptions.api.showLoadingOverlay();
          self.selectedBrand = brandId;
          getScorecard(self.selectedBrand, self.year);
      }

      function getNodeChildDetails(rowItem) {
          if (rowItem.categories && rowItem.categories.length > 0) {
              return {
                  group: true,
                  expanded: false,
                  // provide ag-Grid with the children of this group
                  children: rowItem.categories,
                  // the key is used by the default group cellRenderer
                  key: rowItem.categoryId
              };
          } else {
              return null;
          }
      }

      function getScorecard(brandId, year)
      {
          homeService.scorecard.getScorecard(brandId, year)
          .then(function (dtos) {
              self.scorecard = dtos;

              self.gridOptions.api.setRowData(self.scorecard);
              self.gridOptions.api.refreshView();
          });
      }

      function getBrands()
      {
          homeService.scorecard.getBrands()
          .then(function (brands) {
              self.allBrands = brands;
          });
      }

      function init() {

          getBrands();

          if (self.selectedBrand != 0)
              getScorecard(self.selectedBrand, self.year);
      }

      init();
  });
