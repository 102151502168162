angular.module("prisma")
 .controller("activationCtrl", function ($scope, $rootScope, $state, $stateParams, promotionsService, $timeout, ngDialog, authService) {

     var self = this;

     self.task = null;

     self.showMechanicProgram = authService.hasPermission('promotion_activation_edit_panguiCode');
     self.showInformPrices = authService.hasPermission('promotion_activation_informPrices');
     self.promotionPriceFileUrl = null;

     self.link = '';

     self.canPublish = false;

 

     self.publish = function () {
         if ($stateParams.taskId) {
             if (self.canPublish) {
                 promotionsService.tasks.publishTask($stateParams.taskId)
                     .then(function (isOk) {
                         swal('Tarea Publicada!', 'La tarea se ha publicado exitosamente', 'success');
                         $state.go('promotions.myTasks');
                     }
                     , function (status) {
                         if (status == 502) {
                             swal("El proceso continua...", "El proceso de publicacion continua. Puede revisar el estado en unos minutos.");
                         }
                         else {
                             swal("Error", "Hubo un error al publicar", "error");
                         }
                     });
             }
             else {
                 swal("No se puede publicar", "Debe cargar el número de motor pangui a todas las mecanicas y enviar informacion a precios", "error");
             }
         } else {
             $timeout(function () {
                 swal("Error", "no se pudo publicar la tarea", "error");
             }, 100);
         }
     }

     self.goToLoadItems = function () {
         $state.go('promotions.addItemToLayout', { promotionId: $stateParams.promotionId, taskId: $stateParams.taskId });
     }
     self.goToOutput = function () {

         swal({
             title: 'Desea importar los precios promocionales al modulo de Precios?',
             text: 'Si lo hace mas de una vez, se sobreescribiran los precios promocionales que aun no se hayan informado.',
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: "Continuar",
             cancelButtonText: "Cancelar",
             showLoaderOnConfirm: true,
             closeOnConfirm: false,
             closeOnCancel: true
         },
     function (isConfirm) {
         if (isConfirm) {

             promotionsService.tasks.writePromotionPrices($stateParams.promotionId)
                 .then(function (lastPriceFileUrl) {
                     if (lastPriceFileUrl != null && lastPriceFileUrl != '' && lastPriceFileUrl != undefined) {
                         swal('Precios Publicados!', 'Los precios se han publicado exitosamente', 'success');
                         self.promotionPriceFileUrl = lastPriceFileUrl;
                         //$state.go('prices.toInform');
                         promotionsService.tasks.canPublishActivation($stateParams.promotionId)
                             .then(function (isOk) {
                                 self.canPublish = isOk;
                             });
                     }
                 }, function (status) {
                     if (status == 502) {
                         swal("El proceso continua...", "Se enviara un e-mail a cada usuario de promociones cuando se reciba la informacion en precios.")
                     }
                     else {
                         swal("Error", "Hubo un error al publicar los precios", "error");
                     }
                 });
         }
     });
     }

     self.generateAndDownload = function () {
         promotionsService.reports.buildItemTasksReport(self.task.promotionId)
                 .then(function (link) {
                     self.link = link;
                     self.showSwalWithLink();
                 });
     }

     self.showSwalWithLink = function () {
         swal({
             title: "Reporte Generado!",
             text: 'Haga click <a href="' + link + '"><u style = "color: #1AB394;"><b style = "color: #1AB394;">AQUÍ</b></u></a> para descargar el archivo',
             confirmButtonText: "Cerrar",
             confirmButtonColor: "#c2c2c2",
             html: true
         });
     }

     function init() {
         promotionsService.tasks.startTask($stateParams.taskId);
         promotionsService.tasks.getTask($stateParams.taskId)
         .then(function (task) {
             self.task = task;
         });
         promotionsService.tasks.canPublishActivation($stateParams.promotionId)
             .then(function (isOk) {
                 self.canPublish = isOk;
             });

     }

     init();
 });