'use strinct';
(function () {
    angular.module('prisma').factory('SvgService', ['$log', 'DnDService', svgService]);

    function svgService($log, dnDService) {
        //$log.debug('SvgService::ctor');        

        function newSvgMatrix() {
            var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            var ret = svg.createSVGMatrix();
            return ret;
        }

        /**
         * Devuelve una instancia de SVGMatrix con los datos cargados.
         * @private 
         * @param {any} matrix Matriz SVGMatrix u objeto.
         * @returns Instancia de SVGMatrix con los datos cargados.
         */
        function convertToSvgMatrix(matrix) {
            var ret;
            if (matrix) {
                if (matrix instanceof SVGMatrix) {
                    ret = matrix;
                } else {
                    ret = newSvgMatrix();
                    ret.a = matrix.a;
                    ret.b = matrix.b;
                    ret.c = matrix.c;
                    ret.d = matrix.d;
                    ret.e = matrix.e;
                    ret.f = matrix.f;
                }
            } else {
                ret = newSvgMatrix();
            }

            return ret;
        }

        /**
         * Devuelve un objeto con las coordenadas x,y de la posición del mouse/touch al ocurrir el evento en el sistema de coordenadas de l elemento svg.
         * @param {any} $svg  Contenedor SVG.
         * @param {any} $element Elemento SVG.
         * @param {any} $event Evento.
         * @returns Un objeto con las coordenadas x,y del mouse/touch sobre el elemento svg.
         */
        function eventToPointFn($svg, $element, $event, matrix) {
            var ret = null;
            if ($svg.length && $element.length) {
                var svg = $svg[0];
                var element = $element[0];

                var point = svg.createSVGPoint();
                point.x = $event.clientX;
                point.y = $event.clientY;

                // Local.
                var localCtm = element.getScreenCTM();
                var localCtminvAux = matrix || localCtm.inverse();
                var localCtminv = convertToSvgMatrix(localCtminvAux);
                var localPoint = point.matrixTransform(localCtminv);

                // Global.
                var globalCtm = element.getCTM();
                var globalCtmInvAux = matrix || globalCtm.inverse();
                var globalCtmInv = convertToSvgMatrix(globalCtmInvAux);
                var globalPoint = point.matrixTransform(globalCtmInv);

                // Box
                var box = element.getBBox();
                var offset = dnDService.getOffset();
                ret = {
                    local: {
                        x: localPoint.x,
                        y: localPoint.y,
                        matrix: {
                            a: localCtminv.a,
                            b: localCtminv.b,
                            c: localCtminv.c,
                            d: localCtminv.d,
                            e: localCtminv.e,
                            f: localCtminv.f
                        }
                    },
                    global: {
                        x: globalPoint.x,
                        y: globalPoint.y,
                        matrix: {
                            a: globalCtmInv.a,
                            b: globalCtmInv.b,
                            c: globalCtmInv.c,
                            d: globalCtmInv.d,
                            e: globalCtmInv.e,
                            f: globalCtmInv.f
                        }
                    },
                    box: {
                        x: box.x,
                        y: box.y,
                        width: box.width,
                        height: box.height
                    },
                    initialOffset: offset
                };
            }

            return ret;
        }

        /**
         * Devuelve la constante de ubicación relativa de donde se debe ubicar
         * el elemento en relación a la posición del cursor y el elemento.
         * @private 
         * @param {any} point Punto de referencia.
         * @returns Constante de ubicación relativa.
         */
        function getPlaceFn(point) {
            var ret = 'after';
            var middle = point.box.width / 2;
            if (point.local.x <= middle) {
                ret = 'before';
            }

            return ret;
        }

        // Link: https://toddmotto.com/hacking-svg-traversing-with-ease-addclass-removeclass-toggleclass-functions/
        function getDom(el) {
            return el.length ? el[0] : el;
        }

        function get(el) {
            return getDom(el).getAttribute('class');
        }

        function has(elem, name) {
            return new RegExp('(\\s|^)' + name + '(\\s|$)').test(get(elem));
        }

        function add(elem, name) {
            if (!has(elem, name)) {
                getDom(elem).setAttribute('class', (get(elem) && get(elem) + ' ') + name);
            }
        }

        function remove(elem, name) {
            if (has(elem, name)) {
                var news = get(elem).replace(new RegExp('(\\s|^)' + name + '(\\s|$)', 'g'), '$2');
                getDom(elem).setAttribute('class', news);
            }
        }

        function toggle(elem, name) {
            (has(elem, name) ? remove : add)(elem, name);
        }

        return {
            hasClass: has,
            addClass: add,
            removeClass: remove,
            toggleClass: toggle,
            eventToPoint: eventToPointFn,
            getPlace: getPlaceFn
        }
    }
})();