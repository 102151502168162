angular.module("prisma")
  .controller("AssortmentSummaryReportCtrl", function ($scope, $rootScope, $stateParams, $timeout, $filter, assortmentService, homeService) {
      var self = this;
      self.gridData = null;
      self.allCategoryGroups = null;
      self.selectedCategoryGroup = null;

      self.exportData = function () {
          var params = {
              skipHeader: false,
              skipFooters: false,
              skipGroups: false,
              allColumns: true,
              onlySelected: false,
              suppressQuotes: false,
              fileName: 'ExportReporteEstatico-Dinamico' + (new Date()).toISOString().replace(/[^0-9]/g, "").substring(0, 12) + '.csv',
              columnSeparator: ','
          };

          self.gridOptions.api.exportDataAsCsv(params);
      }

      //grid config
      function setColumnDefinitions() {
          columnDefs = [
          {
              headerName: 'General',
              children: [
              {
                  headerName: "Sector / Categorias",
                  field: "categoryName",
                  headerTooltip: "Nombre del Sector / Categoria / SubCategoria",
                  width: 240,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellRenderer: {
                      renderer: 'group',
                      checkbox: false,
                      innerRenderer: function (params) {
                          var name = params.value + (params.node.group && params.node.children != null ? ' (' + params.node.children.length + ')' : '');

                          if (params.node.level > 1)
                              return params.value;
                          else if (name != null)
                              return name;
                          else
                              return '';
                      }
                  }
              },
              {
                  headerName: "Cluster",
                  field: "clusterName",
                  width: 70,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              },
              {
                  headerName: "Tienda",
                  field: "storeName",
                  width: 210,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              },
              ]
          },
          {
              headerName: 'Estatico',
              children: [
              {
                  headerName: "# Articulos",
                  headerTooltip: "Categoria: Suma de SubCategorias | SubCategoria: Cantidad maxima de articulos de Clusters | Cluster: Cantiad maxima de articulos de Tiendas",
                  field: "activeSkus",
                  width: 80,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
                  cellRenderer: $rootScope.integerRenderer
              },
              {
                  headerName: "Cobertura",
                  field: "coverage",
                  headerTooltip: "Categoria: Promedio de SubCategorias | SubCategoria: Promedio de Clusters | Cluster: Promedio de Tiendas",
                  width: 90,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
                  cellRenderer: $rootScope.percentageRenderer
              }]
          },
          {
              headerName: 'Dinamico',
              children: [
              {
                  headerName: "# Articulos PM",
                  field: "activeSkusPM",
                  headerTooltip: "Cantidad de articulos activos en el Mes anterior",
                  width: 100,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
                  cellRenderer: $rootScope.integerRenderer
              },
              {
                  headerName: "Altas PM",
                  field: "listedSkusPM",
                  headerTooltip: "Altas en el Mes anterior",
                  width: 80,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
                  cellRenderer: $rootScope.integerRenderer
              },
              {
                  headerName: "Bajas PM",
                  field: "delistedSkusPM",
                  headerTooltip: "Bajas en el Mes anterior",
                  width: 80,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
                  cellRenderer: $rootScope.integerRenderer
              },
              {
                  headerName: "Impacto",
                  field: "totalImpact",
                  headerTooltip: "Impacto generado por los cambios realizados",
                  width: 100,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
                  cellRenderer: $rootScope.moneyRenderer
              }]
          },
          ]
      }
      function setGridOptions() {

          setColumnDefinitions();

          self.gridOptions = {
              columnDefs: columnDefs,
              enableColResize: true,
              enableSorting: true,
              enableFilter: true,
              groupHideGroupColumns: true,
              groupUseEntireRow: false,
              groupSelectsChildren: false,
              rowHeight: 30,
              rowSelection: "multiple",
              rowDeselection: true,
              showToolPanel: false,// window.innerWidth > 1500,
              suppressRowClickSelection: true,
              suppressCellSelection: true,
              singleClickEdit: true,
              icons: {
                  columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                  filter: '<i class="fa fa-filter"/>',
                  sortAscending: '<i class="fa fa-long-arrow-down"/>',
                  sortDescending: '<i class="fa fa-long-arrow-up"/>',
                  groupExpanded: '<i class="fa fa-minus-square-o"/>',
                  groupContracted: '<i class="fa fa-plus-square-o"/>',
                  columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                  columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
              },
              suppressColumnMoveAnimation: $rootScope.suppressColumnMoveAnimation(),
              enableRangeSelection: false,
              angularCompileRows: true,
              angularCompileHeaders: true,
              getNodeChildDetails: getNodeChildDetails,
          }
      }

      function getNodeChildDetails(rowItem) {
          if (rowItem.categories && rowItem.categories.length > 0) {
              return {
                  group: true,
                  expanded: false,
                  // provide ag-Grid with the children of this group
                  children: rowItem.categories,
                  // the key is used by the default group cellRenderer
                  key: rowItem.categoryName
              };
          }
          else if (rowItem.clusterStores && rowItem.clusterStores.length > 0) {
              return {
                  group: true,
                  expanded: false,
                  // provide ag-Grid with the children of this group
                  children: rowItem.clusterStores,
                  // the key is used by the default group cellRenderer
                  key: rowItem.categoryName
              };
          }
          else {
              return '';
          }
      }


      function loadData() {

          homeService.categories.getCategoryGroups()
         .then(function (cg) {
             self.allCategoryGroups = cg;
         });
      }

      self.changedCategoryGroup = function () {
          {
              self.gridOptions.api.showLoadingOverlay();

              assortmentService.summaryReport.getSummaryReport(self.selectedCategoryGroup)
                  .then(function (data) {
                  self.gridData = data;

                  self.gridOptions.api.setRowData(self.gridData);
                  self.gridOptions.api.refreshView();
              });
          }
      }

      function init() {
          loadData();

          setGridOptions();

          $timeout(function () {
            self.gridOptions.api.showNoRowsOverlay();
          }, 200)

      }

      init();
  });

