angular.module("prisma")
    .controller("promotionsCalendarCtrl", function ($scope, $filter, $rootScope, $stateParams, $state, $timeout, $element, promotionsService, adminService, ngDialog) {
        var self = this;

        self.promotions = null;
        self.campaigns = [];
        self.promotionMediaTypes = promotionsService.promotionMediaTypes;
        self.promotionMediaTypes.splice(5, 1);
        self.userAreas = promotionsService.userAreas;
        var colors = promotionsService.colors;

        self.selectedCampaign = 'Todas';
        self.selectedMediaType = 'Todos';
        self.selectedUserArea = 'Todas';
        self.selectedCampaignId = 0;
        self.selectedMediaTypeId = 0;
        self.selectedView = 'Mensual';

        self.ganttdata = [];

        loadData();

        self.ganttOptions = {
            columns: [],
            columnsHeaders: { 'model.name': 'Tarea' },
            columnsClasses: { 'model.name': 'gantt-column-name' },
            columnMagnet: '1 day',
            currentDate: 'line',
            currentDateValue: moment(new Date()),
            timeFramesWorkingMode: 'hidden',
            timeFramesNonWorkingMode: 'hidden',
            scale: 'month',
            timeFrames: {
                'day': {
                    start: moment('8:00', 'HH:mm'),
                    end: moment('20:00', 'HH:mm'),
                    color: '#ACFFA3',
                    working: true,
                    default: true
                },
                'noon': {
                    start: moment('12:00', 'HH:mm'),
                    end: moment('13:30', 'HH:mm'),
                    working: false,
                    default: true
                },
                'closed': {
                    working: false,
                    default: true
                },
                'weekend': {
                    working: false
                },
                'holiday': {
                    working: false,
                    color: 'red',
                    classes: ['gantt-timeframe-holiday']
                },
                'startPromotion': {
                    working: true,
                    color: 'green',
                    classes: ['gantt-timeframe-holiday']
                }
            },
            dateFrames: {
                'weekend': {
                    evaluator: function (date) {
                        return date.isoWeekday() === 6 || date.isoWeekday() === 7;
                    },
                    targets: ['weekend']
                },
                'startPromotion': {
                    evaluator: function (date) {
                        return date != null && self.promotion.validFrom != null ? moment(date).isSame(moment(self.promotion.validFrom)) : false;
                    },
                    targets: ['startPromotion']
                },
            },
            api: function (api) {
                // API Object is used to control methods and events from angular-gantt.
                self.api = api;

                api.core.on.ready($scope, function () {
                    if (api.tasks.on.moveBegin) {
                        api.tasks.on.moveEnd($scope, function (task) {
                            taskMoved(task);
                        });

                        api.tasks.on.resizeEnd($scope, function (task) {
                            taskMoved(task);
                        });
                    }
                })
            }
        };

        function taskMoved(movedTask) {
            angular.forEach(self.promotions, function (promotion) {
                angular.forEach(promotion.tasks, function (task) {

                    if (movedTask.row.model.id == task.id) {
                        task.plannedStartDate = movedTask.row.from;
                        task.plannedEndDate = movedTask.row.to;
                    }
                });
            });
        }

        function loadData() {

            promotionsService.campaigns.getCampaigns()
                .then(function (campaigns) {
                    self.campaigns = campaigns;

                    promotionsService.promotions.getPromotionsCalendar()
                        .then(function (promotions) {
                            self.promotions = promotions;
                            redrawGantt();
                        });

                });
        }

        //self.save = function () {
        //    if (self.promotion != null) {
        //        swal({
        //            title: 'Esta seguro que desea guardar los cambios realizados al Calendario?',
        //            type: "warning",
        //            showCancelButton: true,
        //            confirmButtonColor: "#1AB394",
        //            confirmButtonText: "Continuar",
        //            cancelButtonText: "Cancelar",
        //            showLoaderOnConfirm: true,
        //            closeOnConfirm: false,
        //            closeOnCancel: true
        //        },
        //       function (isConfirm) {
        //           if (isConfirm) {
        //               /*
        //               promotionsService.promotions.savePromotion(self.promotion)
        //                   .then(function (promotion) {
        //                       swal('Accion Promocional Guardada!', 'La accion promocional ' + self.promotion.name + ' se ha guardado exitosamente', 'success');
        //                       self.promotion = promotion;
        //                   }, function (status) {
        //                       if (status == 502) {
        //                           swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
        //                       }
        //                       else {
        //                           swal("Error", "Hubo un error al guardar esta accion promocional", "error");
        //                       }
        //                   });
        //                   */
        //           }

        //       });
        //    }
        //}

        self.changeView = function (scale, view) {
            // Fix Issue #6 Falta la apertura diaria del calendario.
            //if (scale == 'day') {
            //    if (self.selectedCampaign == 'Todas')
            //        swal("Error", "Debe filtrar por una campaña para utilizar la vista diaria del gantt.", "error")
            //    else {
            //        self.ganttOptions.scale = scale;
            //        self.selectedView = view;
            //    }
            //}
            //else {
            //    self.ganttOptions.scale = scale;
            //    self.selectedView = view;
            //}
            self.ganttOptions.scale = scale;
            self.selectedView = view;
            
            $timeout(function () {
                // Reinicia el scroll del contenedor.
                $element.find('.gantt-scrollable').scrollLeft(0);
            });
        }

        self.filterByCampaign = function (campaignName, campaignId) {
            if (self.selectedView == 'Diario' && campaignId == 0)
                self.changeView('week', 'Semanal');

            self.selectedCampaign = campaignName;
            self.selectedCampaignId = campaignId;

            redrawGantt();
        }

        self.filterByMediaType = function (mediaTypeName, mediaTypeId) {

            self.selectedMediaType = mediaTypeName;
            self.selectedMediaTypeId = mediaTypeId;

            redrawGantt();
        }

        self.filterByUserArea = function (userArea) {

            self.selectedUserArea = userArea;

            redrawGantt();
        }

        /**
         * Devuelve el tamaño de las columnas segun el tipo de vista.
         * Fix Issue #6 Falta la apertura diaria del calendario.
         * Link: https://www.angular-gantt.com/configuration/attributes/#column-width
         */
        self.getColumnWidth = function () {
            var ret = undefined;

            // Si la vista es por día se fuerza un ancho de columna.
            // Para todas las otras vistas se deja que lo resuelve el componente gantt.
            if (self.ganttOptions.scale === 'day') {
                // TODO: Verificar el valor del ancho para distintas resoluciones.  
                ret = 22; 
            }

            return ret;
        };

        //self.publish = function () {
        //    if ($stateParams.taskId) {
        //        promotionsService.tasks.publishTask($stateParams.taskId)
        //           .then(function (task) {
        //               swal('Tarea Publicada!', 'La tarea se ha publicado exitosamente', 'success');

        //               $state.go('promotions.myTasks');
        //           });
        //    }
        //}

        //self.canPublish = function () {
        //    if ($stateParams.taskId && parseInt($stateParams.taskId) > 0)
        //        return true;

        //    return false;
        //}

        function redrawGantt() {
            var ganttdataitems = [];

            angular.forEach(self.promotions, function (promotion, i) {

                var draw = true;

                if (self.selectedCampaignId != 0 && promotion.promotionCampaignId != self.selectedCampaignId) {
                    draw = false;
                }

                if (self.selectedMediaTypeId != 0 && promotion.promotionMediaTypeId != self.selectedMediaTypeId) {
                    draw = false;
                }

                if (draw) {

                    var promotionGroup = { id: 'promotionGroup' + i, name: promotion.name, children: [] };

                    var prevGroupName = '';
                    var groupCount = 0;
                    var groupId = 1;
                    var group = { id: '', name: '', children: [] };

                    //Paso 1: Recorro todos los tasks para crear los items de los groupnames
                    angular.forEach(promotion.tasks, function (task) {

                        var drawTask = true;
                        if (self.selectedUserArea != 'Todas' && task.userArea != self.selectedUserArea) {
                            drawTask = false;
                        }

                        if (drawTask) {
                            if (prevGroupName != '' && prevGroupName != task.groupName) {
                                var groupCopy = {};
                                ganttdataitems.push(angular.copy(group, groupCopy));
                                promotionGroup.children.push(groupCopy.id);

                                groupCount++;
                                group.children = [];
                                groupId++;
                            }

                            group.id = 'taskGroup' + promotion.id + '-' + groupId;

                            group.name = task.groupName;
                            group.children.push(task.id);

                            if (groupCount > colors.length) {
                                groupCount = 0;
                            }

                            var taskFrom = null;
                            var taskTo = null;

                            var ganttItem = null;

                            if (task.isStartMilestone) {
                                taskFrom = moment(promotion.validFrom);
                                taskTo = moment(promotion.validTo);
                            }
                            else {
                                taskFrom = moment(task.plannedStartDate);
                                taskTo = moment(task.plannedEndDate).subtract(1,'second');
                            }

                            ganttItem = {
                                id: task.id,
                                name: task.name,
                                tasks: [{ name: task.name, from: taskFrom, to: taskTo, color: colors[groupCount] }],
                            };

                            if (task.promotionTaskPreviousId != null) {
                                //TODO: esto no está funcando
                                ganttItem.tasks[0].dependencies = [{ from: task.promotionTaskPreviousId }];
                            }

                            ganttdataitems.push(ganttItem);

                            prevGroupName = task.groupName;
                        }
                    });

                    if (group.children.length > 0) {
                        ganttdataitems.push(group);

                        promotionGroup.children.push(group.id);
                    }

                    if (promotionGroup.children.length > 0) {
                        ganttdataitems.push(promotionGroup);
                    }
                }
            });

            self.ganttdata = ganttdataitems;
        }

        var currentGroups = [];
        function redrawGanttNEW() {
            var ganttdataitems = [];

            angular.forEach(self.promotions, function (promotion, i) {

                var draw = true;

                if (self.selectedCampaignId != 0 && promotion.promotionCampaignId != self.selectedCampaignId) {
                    draw = false;
                }

                if (self.selectedMediaTypeId != 0 && promotion.promotionMediaTypeId != self.selectedMediaTypeId) {
                    draw = false;
                }

                if (draw) {

                    //Paso 1: Recorro todos los tasks para crear los items de los groupnames
                    angular.forEach(promotion.tasks, function (task) {

                        var drawTask = true;
                        if (self.selectedUserArea != 'Todas' && task.userArea != self.selectedUserArea) {
                            drawTask = false;
                        }

                        if (drawTask) {

                            var currentGroup = null;
                            angular.forEach(ganttdataitems, function (gdi) {
                                if (gdi.id == (promotion.name + '-' + task.groupName) && gdi.children)
                                    currentGroup = gdi;
                            });

                            if (currentGroup == null) {
                                currentGroup = {
                                    id: promotion.name + '-' + task.groupName,
                                    name: task.groupName,
                                    children: []
                                };

                                ganttdataitems.push(currentGroup);
                                currentGroups.push(currentGroup.id);
                            }

                            //agrego la tarea actual como hija del grupo
                            if (currentGroup.children.indexOf(task.id) == -1)
                                currentGroup.children.push(task.id);

                            //defino el color para el grupo
                            var color = currentGroups.indexOf(currentGroup.id);

                            var taskFrom = null;
                            var taskTo = null;

                            var ganttItem = null;

                            if (task.isStartMilestone) {
                                taskFrom = moment(promotion.validFrom);
                                taskTo = moment(promotion.validTo);
                            }
                            else {
                                taskFrom = moment(task.plannedStartDate);
                                taskTo = moment(task.plannedEndDate);
                            }

                            ganttItem = {
                                id: task.id,
                                name: task.name,
                                tasks: [{ name: task.name, from: taskFrom, to: taskTo, color: colors[color] }],
                            };

                            //if (task.promotionTaskPreviousId != null) {
                            //    //TODO: esto no está funcando
                            //    ganttItem.tasks[0].dependencies = [{ from: task.promotionTaskPreviousId }];
                            //}

                            ganttdataitems.push(ganttItem);

                            prevGroupName = task.groupName;
                        }
                    });


                    //if (promotionGroup.children.length > 0) {
                    //    ganttdataitems.push(promotionGroup);
                    //}
                }
            });

            self.ganttdata = ganttdataitems;
        }

    });