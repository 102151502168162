angular.module("prisma")
    .controller("promotionalCampaignsCtrl", function ($scope, promotionsService, $timeout) {
        var self = this;

        self.campaigns = [];
        self.promotionsList = [];

        self.isLoading = true;

        self.delete = function (campaignId, campaignName) {

            swal({
                title: 'Esta seguro que desea borrar la campaña ' + campaignName + ' ?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        if (canDelete(campaignId)) {

                            promotionsService.campaigns.deleteCampaign(campaignId)
                                .then(function (isOk) {
                                    swal('Campaña Borrada!', 'La campaña ' + campaignName + ' se ha borrado exitosamente', 'success');
                                    loadData();
                                }, function (status) {
                                    if (status == 502) {
                                        swal("El proceso continua...", "El proceso de borrado continua. Puede revisar el estado en unos minutos.")
                                    }
                                    else {
                                        swal("Error", "Hubo un error al borrar esta campaña", "error");
                                    }
                                });
                        } else {
                            $timeout(function () {
                                swal("Error", "La campaña está activa", "error");
                            }, 100);
                        }
                    }
                });
        }

        function canDelete(campaignId) {
            var can = true;
            angular.forEach(self.promotionsList, function (promotion) {
                if (promotion.promotionCampaignId == campaignId)
                    can = false;
            });
            return can;
        }

        function loadData() {
            promotionsService.campaigns.getCampaigns()
                .then(function (campaigns) {
                    self.campaigns = campaigns;
                    self.isLoading = false;
                });

            //Cargar despues, no esperar a que carguen las promos para ver la data
            promotionsService.promotions.getPromotions(false) //No Traer las historicas
                .then(function (promotions) {
                    self.promotionsList = promotions;
                });
        }

        function init() {
            loadData();
        }

        init();
    });