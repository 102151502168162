angular.module("prisma")
    .controller("AuditCtrl", function ($scope, $rootScope, adminService, $translate, $timeout, $stateParams, $filter, ngDialog, $window) {
        var self = this;

        self.auditTrails = [];
        self.events = [];
        self.search = '';
        self.eventFilter = '';
        self.pageNumber = 1;
        self.pageSize = 50;
        pageLoaded = false;

        self.toDate = new Date(moment(new Date()).add(1, 'days'));//hasta mañana

        self.fromDate = new Date();//desde hoy

        $(window).scroll(function () {
            if ($(window).scrollTop() + $(window).height() > ($(document).height() * 0.95)) {
                if (pageLoaded) {
                    $timeout(function () {
                        self.pageNumber++;
                    }, 0)
                }
            }
        });

        self.getEventFormat = function (event) {
            var style = {
                'border-radius': '7px',
                'padding': '2px 11px',
                'color': '#fff',
                'font-size': '11px',
                'background-color': '#000'
            }

            switch (event) {
                case 'SecurityUserPasswordReseted': style['background-color'] = '#753'; break;
                case 'SecurityUserCreated': style['background-color'] = '#612'; break;
                case 'SecurityUserModified': style['background-color'] = '#147'; break;
                case 'SecurityUserDisabled': style['background-color'] = '#a98'; break;
                case 'SecurityUserEnabled': style['background-color'] = '#b46'; break;
                case 'SecurityUserRoleChanged': style['background-color'] = '#888'; break;
                case 'SecurityUserStoresChanged': style['background-color'] = '#346'; break;
                case 'SecurityUserCategoriesChanged': style['background-color'] = '#283'; break;
                case 'SecurityRoleCreated': style['background-color'] = '#445'; break;
                case 'SecurityRolePermissionsChanged': style['background-color'] = '#987'; break;
                case 'SecurityLogin': style['background-color'] = '#123'; break;
                case 'SecurityLogout': style['background-color'] = '#824'; break;
            }

            return style;

        }

        function convertDate(inputFormat) {
            function pad(s) { return (s < 10) ? '0' + s : s; }
            var d = new Date(inputFormat);
            return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
        }

        self.loadData = function () {

            if (self.fromDate && self.toDate) {

                var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                var diffDays = Math.round(Math.abs((self.fromDate.getTime() - self.toDate.getTime()) / (oneDay)));

                if (diffDays > 30) {
                    swal('Error', 'Solo puede ver los registros de auditoria de 30 dias', 'error');
                    return;
                }
            }
            else {
                swal('Error', 'Debe seleccionar una fecha desde y una fecha hasta', 'error');
                return;
            }

            self.isLoading = true;

            var fromDate = convertDate(self.fromDate);
            var toDate = convertDate(self.toDate);

            adminService.security.getSecurityAuditTrails(fromDate, toDate).then(function (audits) {
                self.auditTrails = audits;

                angular.forEach(self.auditTrails, function (a) {
                    a.createdDate = $rootScope.convertUTCDateToLocalDate(a.createdDate);
                });

                self.events = audits.map(function (audit) {
                    return audit.event
                }).filter(function (value, index, self) {
                    return self.indexOf(value) === index
                });

                self.isLoading = false;
                pageLoaded = true;

            });
        }

        self.downloadExcel = function () {
            var html = '<style>.text{mso-number-format:"\@";/*force text*/}</style><table width="100%"><thead><tr>';

            //header
            html += '<th>ID</th>';
            html += '<th>Usuario</th>';
            html += '<th>Fecha</th>';
            html += '<th>Evento</th>';
            html += '<th>Descripci&oacute;n</th>';
            html += '<th>Tipo</th>';
            html += '<th>Identificador</th>';
            html += '</tr></thead><tbody>';

            var filteredAudit = self.auditTrails;
            if (self.eventFilter != "")
                filteredAudit = $filter('filter')(filteredAudit, { event: self.eventFilter }, true);
            filteredAudit = $filter('filter')(filteredAudit, self.search, false);
                angular.forEach(filteredAudit, function (auditTrail) {
                html += buildRow(auditTrail);

            });

            html += '</tbody></table>';

            var exportHref = ExportToExcel(html, 'Pagina1');
            $timeout(function () {
                location.href = exportHref;
            }, 100); // trigger download
        }

        function buildRow(auditTrail) {

            var html = '';

            html += '<tr><td>' + auditTrail.id +
                '</td><td class="text">' + auditTrail.username +
                '</td><td>' + auditTrail.createdDate +
                '</td><td>' + auditTrail.event +
                '</td><td>' + auditTrail.observations +
                '</td><td>' + auditTrail.objectType +
                '</td><td>' + auditTrail.objectId +
                '</td></tr>';

            return html;
        }

        function ExportToExcel(tableHtml, worksheetName) {
            var uri = 'data:application/vnd.ms-excel;base64,',
                template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
                base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
                format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

            var ctx = { worksheet: worksheetName, table: tableHtml },
                href = uri + base64(format(template, ctx));
            return href;
        }

        self.filterByEvent = function (a) {
            return a.event === self.eventFilter || self.eventFilter === "";
        }

        function init() {
            self.loadData();
        }

        init();
    });