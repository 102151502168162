angular.module("prisma")
    .controller("promotionSearchCtrl", function ($rootScope, $scope, $timeout, $stateParams, $state, promotionsService) {
        var self = this;

        self.isLoadingPromotions = false;
        self.promotions = [];
        self.validFrom = null;
        self.validTo = null;

        self.criterions = [
            { name: 'PromocionIds', value: '', ph: 'Ids Promocion' }
            , { name: 'Promocion', value: '', ph: 'Nombre Promocion' }
            , { name: 'Usuario', value: '', ph: 'Nombre Usuario'}
            , { name: 'CodMaterial', value: '', ph: 'Codigo Material'}
            , { name: 'NameMaterial', value: '', ph: 'Nombre Material'}
            , { name: 'Tienda', value: '', ph: 'Tienda'}
            , { name: 'Categoria', value: '', ph: 'Categoria' }
            , { name: 'Marca', value: '', ph: 'Marca' }
            , { name: 'Fabricante', value: '', ph: 'Fabricante' }
        ];

        self.setFiltersInLocalStorage = function () {
            var filters = {
                'criterios': self.criterions,
                'validFrom': self.validFrom,
                'validTo': self.validTo,
            };
            localStorage.setItem('filtros', JSON.stringify(filters));
            self.setSearchResultInLocalStorage();
        }

        self.setSearchResultInLocalStorage = function () {
            var promotions = self.promotions;
            localStorage.setItem('promociones', JSON.stringify(promotions));
        }

        function getFilters() {
            if (localStorage.getItem('filtros') != null) {
                var filtersAux = localStorage.getItem('filtros');
                var filters = JSON.parse(filtersAux);

                self.criterions = filters.criterios;
                self.validFrom = new Date(filters.validFrom);
                self.validTo = new Date(filters.validTo);
            }
            localStorage.removeItem("filtros");
        }


        function getSearchResult() {
            if (localStorage.getItem('promociones') != null) {
                var promotionstAux = localStorage.getItem('promociones');
                var promotions = JSON.parse(promotionstAux);

                self.promotions = promotions;
            }
            localStorage.removeItem("promociones");
        }

        self.advancedSearch = function () {
            if (!self.validFrom) { // || !self.validTo) {
                swal("Error", "Debe elegir una fecha Desde", "error");
                return;
            }
            if (!validateTerms()) {
                swal("Error", "Los terminos de busqueda deben tener mas de dos (2) caracteres.", "error");
                return;
            }

            self.isLoadingPromotions = true;
            var dto = {
                validFrom: self.validFrom,
                validTo: self.validTo,
                searchCriterions: self.criterions
            }

            promotionsService.promotions.getPromotionsByAdvancedSearch(dto)
                .then(function (promotions) {
                    self.promotions = promotions;
                    self.isLoadingPromotions = false;
                    console.log(self.promotions);
                });
        }

        function validateTerms() {
            return self.criterions.some(function (x) { return x.value.length >= 3 });           
        }

        function init() {
            getFilters();
            getSearchResult();
        }

        init();

    });