angular.module('prisma').component('planogramPegComponent', {
    templateUrl: 'app/spaces/planograms/components/planogramPeg.component.html',
    controller: planogramPegController,
    bindings: {
        module: '=',
        planogram: '=',
        isloading: '=',
        itemstry: '=',
        newitem: '=',
        hideitems: '=',
    }
});
function planogramPegController($scope, $stateParams, spaceService) {
    var self = this;

    self.calcHeightPx = function (item) {
        if (!item.type && !item.dndId) {
            return 50
        }
        var domElement = document.getElementById(item.type + '_' + item.dndId);
        var domElementSize = domElement.getBoundingClientRect()
        var height = item.height * domElementSize.width / item.width;
        return height;
    }

    self.calcWidthPx = function (container, item) {
        var domContainer = document.getElementById(container.type + '_' + container.dndId);
        var domContainerSize = domContainer.getBoundingClientRect()
        var width = item.width * domContainerSize.width / container.width
        return width;
    }

    self.planogramLevel = {
        "id": 1,
        "width": 4000,
        "depth": 5300.88,
        "levelObjects": [
            {
                "items": [
                    {
                        "id": 2,
                        "planogramLevelId": 2,
                        "itemId": 107,
                        "order": 0,
                        "nFronts": 3,
                        "orientation": 0,
                        "ticketCount": 0,
                        "status": null,
                        "categoryId": 112,
                        "categoryName": "TV LED y Smart TV",
                        "name": "TELEVISOR 32 \" SMART TV 32J4300",
                        "imageUrl": "assets/images/items/ElectroDemo/168919.png",
                        "width": 74.54,
                        "height": 44.2,
                        "depth": 6.9,
                        "tags": [],
                        "brand": "Samsung",
                        "stackNum": null
                    }, {
                        "id": 3,
                        "planogramLevelId": 2,
                        "itemId": 108,
                        "order": 1,
                        "nFronts": 1,
                        "orientation": 0,
                        "ticketCount": 0,
                        "status": null,
                        "categoryId": 112,
                        "categoryName": "TV LED y Smart TV",
                        "name": "TELEVISOR 32 \" SMART TV 32LH575B",
                        "imageUrl": "assets/images/items/ElectroDemo/171815.png",
                        "width": 73.4,
                        "height": 47.4,
                        "depth": 17.2,
                        "tags": [],
                        "brand": "Lg",
                        "stackNum": null
                    }, {
                        "id": 4,
                        "planogramLevelId": 3,
                        "itemId": 105,
                        "order": 0,
                        "nFronts": 1,
                        "orientation": 0,
                        "ticketCount": 0,
                        "status": null,
                        "categoryId": 112,
                        "categoryName": "TV LED y Smart TV",
                        "name": "TELEVISOR 32 \" SMART TV 32PHG5102",
                        "imageUrl": "assets/images/items/ElectroDemo/172641.png",
                        "width": 73.25,
                        "height": 42.96,
                        "depth": 8.68,
                        "tags": [],
                        "brand": "Philips",
                        "stackNum": null
                    }, {
                        "id": 5,
                        "planogramLevelId": 3,
                        "itemId": 106,
                        "order": 1,
                        "nFronts": 3,
                        "orientation": 0,
                        "ticketCount": 0,
                        "status": null,
                        "categoryId": 112,
                        "categoryName": "TV LED y Smart TV",
                        "name": "TELEVISOR 32 \" SMART TV SH3216MHIX",
                        "imageUrl": "assets/images/items/ElectroDemo/172485.png",
                        "width": 73.2,
                        "height": 43.7,
                        "depth": 6.4,
                        "tags": [],
                        "brand":
                        "Sharp",
                        "stackNum": null
                    }, {
                        "id": 1,
                        "planogramLevelId": 4,
                        "itemId": 101,
                        "order": 0,
                        "nFronts": 1,
                        "orientation": 0,
                        "ticketCount": 0,
                        "status": null,
                        "categoryId": 112,
                        "categoryName": "TV LED y Smart TV",
                        "name": "TELEVISOR 32 \" SMART TV KB32S2000SA",
                        "imageUrl": "assets/images/items/ElectroDemo/172747.png",
                        "width": 73.5,
                        "height": 44,
                        "depth": 8,
                        "tags": [],
                        "brand": "Ken Brown",
                        "stackNum": null
                    }, {
                        "id": 6,
                        "planogramLevelId": 4,
                        "itemId": 102,
                        "order": 1,
                        "nFronts": 1,
                        "orientation": 0,
                        "ticketCount": 0,
                        "status": null,
                        "categoryId": 112,
                        "categoryName": "TV LED y Smart TV",
                        "name": "TELEVISOR 32 \" SMART TV PLD3226HI",
                        "imageUrl": "assets/images/items/ElectroDemo/172490.png",
                        "width": 73.2,
                        "height": 43.7,
                        "depth": 6.4,
                        "tags": [],
                        "brand": "Philco",
                        "stackNum": null
                    }, {
                        "id": 7,
                        "planogramLevelId": 4,
                        "itemId": 103,
                        "order": 2,
                        "nFronts": 1,
                        "orientation": 0,
                        "ticketCount": 0,
                        "status": null,
                        "categoryId": 112,
                        "categoryName": "TV LED y Smart TV",
                        "name": "TELEVISOR 32 \" SMART TV HLE3216RT",
                        "imageUrl": "assets/images/items/ElectroDemo/172118.png",
                        "width": 73.38,
                        "height": 47.4,
                        "depth": 16.85,
                        "tags": [],
                        "brand": "Hisense",
                        "stackNum": null
                    }],
                "id": 1,
                "categoryId": 20,
                "color": "#AEDB9A",
                "spaceObjectId": 1,
                "name": "TV-Planogram",
                "points": [[28.59, 18.37], [28.59, 20.26], [39.99, 20.26], [39.99, 18.37]],
                "rotation": 0,
                "isPlanogram": true,
                "ticketCount": 0,
                "width": 450,
                "depth": 100
            }],
        "points": [[0, 0], [100, 0], [100, 100], [0, 100]],
        "order": 1,
        "sellingAreaSpace": 200,
        "photoUri": "https://prismablob.blob.core.windows.net/electrodemo/Development/Spaces/LayoutImages/9ac3880f-c5d4-44e0-b07b-613ed5971613.jpg",
        "totalSpace": 200,
        "squareMeters": 2120.352,
        "linearFeets": "450"
    }

    console.log('planogramPegsAreaController', self)

    $scope.$watch('$ctrl.newitem', function (newVal, oldVal) {
        console.log('change self.itemstry', newVal, oldVal, newVal != undefined)

        if (newVal != undefined) {
            self.newitem = false;
            angular.forEach(self.itemstry, function (planogramObject) {
                planogramObject.draggable = true;
                planogramObject.selectable = true;
                drawPlanogramObject(planogramObject, self.planogram);
            })
        }

    });

    function percentageToPixels(points, planogram) {
        var r = [];
        _.each(points, function (point) {
            r.push([getPixelFromPercentage(point[0], planogram.planogramDiv.clientWidth), getPixelFromPercentage(point[1], planogram.planogramDiv.clientHeight)]);
        });
        return r;
    }

    function getPixelFromPercentage(perc, maxPX) {
        return perc * maxPX / 100;
    }


    self.calcPlanogramSize = function (planogram) {
        //debugger
        var widthPx = planogram.planogramDiv.clientWidth;

        var heightPx = planogram.height * widthPx / planogram.width;

        planogram.widthPx = widthPx;
        planogram.heightPx = heightPx;

        planogram.planogramDiv.style.height = heightPx + 'px';

        if (planogram.polygonContainer)
            drawLevelObject(planogram.polygonContainer[0], self.planogram);
        console.log('self.planogram', self.planogram)
    }

    function loadPlanogramPeg(planogram) {
        // initialize SVG.js
        var drawCanvas = SVG('drawing')
        //drawCanvas.rect(100, 100)
        //drawCanvas.viewbox(0, 0, 297, 210).fill('none').stroke({ width: 1 })
        self.planogram.canvas = drawCanvas;
        self.planogram.planogramDiv = document.getElementById('drawing')
        self.calcPlanogramSize(self.planogram);

        self.planogram.pointsPx = percentageToPixels(self.planogramLevel.points, self.planogram)

        self.planogram.polygonContainer = {
            svgReference: drawCanvas.polygon(self.planogram.pointsPx.join(' ')).fill('transparent').stroke({ width: 1 }),
            fill: 'transparent',
            points: self.planogram.points,
            pointsPx: self.planogram.pointsPx,
            draggable: false,
            selectable: false,
            stroke: { width: 1, color: 'black' }
        }

        console.log('self.planogramSvg', self.planogram)

        // draw blue square
        var rect = self.planogram.canvas.rect(100, 100).attr({ fill: '#8181F7' }).attr({ x: 20, y: 60 }).draggable()
        rect.draggable().on('beforedrag', function (e) {
            e.preventDefault()
            console.log('beforeDrag', e)
            // no other events are bound
            // drag was completely prevented
        })

        //draw square img
        //var image = self.planogram.canvas.image('assets/images/items/ElectroDemo/168919.png')

        //image.size(100, 100).move(200, 200).draggable();

        rect.draggable().on('dragmove', function (e) {
            e.preventDefault()
            this.move(e.detail.p.x, e.detail.p.y)
            console.log('dragmove', e)
            // events are still bound e.g. dragend will fire anyway
        })
        var point = rect.point()
        console.log('point', point)
        //self.planogram.canvas.viewbox({ x: 0, y: 0, width: 200, height: 100 }).attr({ fill: '#8181F7' })

        //angular.forEach(self.planogram.levels, function (level) {
        angular.forEach(self.itemstry, function (planogramObject) {
            planogramObject.draggable = true;
            planogramObject.selectable = true;
            drawPlanogramObject(planogramObject, self.planogram);
        })
        //});
    }

    function drawPlanogramObject(component, planogram) {
        if (component.svgReference != undefined) {
            component.svgReference.remove()
            component.svgReference = undefined;
        }

        var pxPoints = percentageToPixels(component.points, planogram);
        component.pointsPx = pxPoints;
        if (!component.imageUrl) {
            component.svgReference = planogram.canvas.rect(component.width, component.height)
                .attr({ fill: '#8181F7' })
                .move(component.position[0], component.position[1])
                .draggable()
                .stroke({ width: 1, opacity: '1' });

        } else {

            //Si es una imagen, tengo que tratarlo un poco diferente
            //Tengo que crear un grupo, y asignar una imagen y un poligono (para que le rellene el fondo)

            component.svgReference = planogram.canvas.image(component.imageUrl)
                .size(component.width, component.height)
                .move(component.position[0], component.position[1])
                .draggable();

        }


        if (component.stroke)
            component.svgReference.stroke(component.stroke);

        //if (component.selectable == undefined || component.selectable == true)
        //    component.svgReference.click(function () { self.levelObjectClick(c) });

        if (component.move) {
            component.svgReference.dmove(getPixelFromPercentage(component.move[0], level.layoutDiv.clientWidth), getPixelFromPercentage(component.move[1], level.layoutDiv.clientHeight));
        }

        if (component.rotation) {
            component.svgReference.transform({ rotation: component.rotation });

            //component.svgReference.transform({ rotation: component.rotation, relative: true });
            //component.svgReference.rotate(component.rotation);
        }

        if (component.color) {
            //component.svgReference.fill(component.color);
            //component.svgReference.fill(getSectionColor(component.categoryId));
        }

        //Esto es por si quiero sobreescribir un color  
        //component.svgReference.fill('transparent');
        if (component.fill) {
            component.svgReference.fill(component.fill);
            //component.svgReference.stroke({ width: 1, color: component.fill })
        }

        if (component.back) {
            component.svgReference.back();
        }

        if (component.front) {
            component.svgReference.front();
        }

        if (component.attr) {
            component.svgReference.attr(component.attr);
        }

        //if (component.svgReference._array)
        //    component.pointsPx = angular.copy(component.svgReference._array.value);

        if (/*self.isEdit()*/false && (component.draggable == undefined || component.draggable == true)) {

            component.svgReference.draggable();


            component.svgReference.on('dragmove.namespace', function (event) {
                if (!isValidPosition(event.srcElement, c)) {
                    //console.log('mal');
                    component.svgReference.fill('#ED5666');
                } else {
                    //console.log('bien');
                    //console.log('c', c);
                    if (component.fill) {
                        component.svgReference.fill(component.fill);
                    }
                    else if (component.isPlanogram && component.color) {
                        component.svgReference.fill(component.color);
                    } else {
                        component.svgReference.fill(self.getComponentColor(c));
                    }
                }
            });

            component.svgReference.on('dragend.namespace', function (event) {
                if (!isValidPosition(event.srcElement, c)) {
                    //console.log('mal');
                    component.svgReference.remove();
                    drawLevelObject(c, level);
                } else {
                    //console.log('bien');
                    if (component.image) {
                        component.pointsPx = getDivPoints(event.srcElement);
                        component.points = pixelsToPercentage(component.pointsPx, level);
                        component.svgReference.remove();
                        drawLevelObject(c, level);
                        component.imageMove = angular.copy(component.pointsPx[0]);
                        component.move = undefined;
                    } else {
                        component.pointsPx = getDivPoints(event.srcElement);
                        component.points = pixelsToPercentage(component.pointsPx, level);
                        component.move = undefined;
                        component.rotation = 0;
                        component.svgReference.remove();
                        drawLevelObject(c, level);
                    }
                }
                self.levelObjectClick(c);
                //self.toggleLabels(false);

            });

        }

    }


    function initEvents(planogram) {

    }

    function init() {
        self.planogram.height = 200
        loadPlanogramPeg();

        initEvents();

    }
    init();
}