angular.module("prisma")
    .controller("EditPriceRegionCtrl", function ($scope, $state, $stateParams, priceService, adminService, $timeout, $translate) {

     //Variable globales
     var self = this;
     self.selectedPriceList = '';
     self.distributionCenters = [];
     

     self.region = {
         id: 0,
         storeCode : '',
         storeId: 0,
         name : ''
     }

     self.storeSelected = function () {
         angular.forEach(self.distributionCenters, function (store) {
             if (store.id == self.region.storeId) {
                 console.log(self.region);
                 self.region.storeId = store.id;
                 self.region.storeCode = store.code;
             }
         });
     }

     self.save = function () {
         var title = translations.SaveRegion;
         swal({
             title: title,
             text: '',
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: translations.Save,
             cancelButtonText: translations.Cancel,
             showLoaderOnConfirm: true,
             closeOnConfirm: false,
             closeOnCancel: true
         },
             function (isConfirm) {
                 if (isConfirm) {
                     priceService.regions.saveRegion(self.region)
                         .then(function (status) {
                             swal(translations.SavedRegion, translations.SavedRegionSubtitle, 'success');

                             $state.go('prices.regions', {reload: true});
                         },
                         function () {
                             swal(translations.ErrorTitleSwal, translations.ErrorToSaveTheRegion, 'error');

                         });
                 }
             });
     }

     function getRegion(id) {

         priceService.regions.getRegion(id)
             .then(function (region) {
                 console.log(region);
                 self.region = region;
             });
     }

     function getDistributionCenters() {
         adminService.stores.getDistributionCentersCombo().then(function (dc) {
             self.distributionCenters = dc;
         })
     }

     var translations = null;

     function init() {

         $translate(["SaveRegion", "Save", "Cancel", "SavedRegion", "SavedRegionSubtitle", "ErrorTitleSwal", "ErrorToSaveTheRegion"])
             .then(function (all) {
                 translations = all;
             });

         getDistributionCenters();

         var regionId = $stateParams.regionId;
         if (regionId != 0)
             getRegion(regionId);
     }

     init();

 });




