angular.module("prisma")
    .controller("MarkdownActivationCtrl", function ($scope, markdownService, $translate, $rootScope, $window, $timeout) {
        var self = this;

        var allSelected = true;
        self.loadingMarkdowns = false;
        self.informingMarkdowns = false;

        self.markdownsToInform = [];

        self.reasons = [];

        self.selectedReasons = [];
        self.selectedMarkdownPlanType = null;

        self.showMarkdownGrid = false;

        self.canInform = function () {
            return self.markdownsToInform.length > 0;
        }

        self.activateMarkdowns = function () {

            if (!self.canInform()) {
                swal("No hay liquidaciones para activar", "No hay liquidaciones para activar.", "warning");
                return;
            }

            var title = 'Esta seguro que desea activar las liquidaciones?';
            var text = '';
            var closeOnConfirm = false;

            var swalOptions = {
                title: title,
                text: text,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: closeOnConfirm,
                closeOnCancel: true
            }

            swal(swalOptions, doInform);

        }

        function doInform(isConfirm) {

            if (!isConfirm)
                return;

            self.informingMarkdowns = true;
            //preparo el dto reducido para el save
            var idsToInform = getIdsToInform();

            markdownService.activation.activateMarkdowns(idsToInform).then(function (informResult) {

                swal("Reporte de Activación Generado!", "Se genero el reporte de activacion exitosamente", "success");

                //Vuelvo a cargar la info
                self.getFilteredMarkdowns();
                loadPreviousReports();

                loadPreviousReports();
                self.informingMarkdowns = false;
            },
                function (fallback) {
                    console.log(fallback);
                    self.informingMarkdowns = false;
            swal("Error", "Ha ocurrido un error", "error");
                });

        }

        self.downloadLastReport = function () {
            markdownService.activation.downloadLastOutput().then(function (reportUrl) {
                if (reportUrl) {
                    $window.open(reportUrl);
                }
            });
        }

        self.downloadFile = function (url) {
            $window.open(url);
        }

        self.getFilteredMarkdowns = function () {

            if (self.selectedMarkdownPlanType != null) {

                self.showMarkdownGrid = true;

                //if (self.gridOptions.api)
                //    self.gridOptions.api.showLoadingOverlay();

                var filters = {
                    markdownTypeId: self.selectedMarkdownPlanType,
                    reasons: self.selectedReasons
                }

                //if (filters.markdownTypeId == null)
                //    swal("Debe seleccionar un tipo de precio.", " No se ha seleccionado ningun tipo de precio.", "error");
                //else if ((filters.markdownTypeId == 4 || filters.markdownTypeId == 5) && filters.reasons.length == 0) {
                //    swal("Debe seleccionar un motivo para estos tipos de precio.", " En el caso de que no aparezca ninguno, no existen precios a informar del tipo seleccionado.", "error");
                //}
                //else {
                self.loadingMarkdowns = true;
                markdownService.activation.getMarkdownsToInformFiltered(filters)
                    .then(function (markdownsToInform) {
                        self.markdownsToInform = markdownsToInform;
                        self.loadingMarkdowns = false;
                    });
                //}
            }
        }

        function getIdsToInform() {

            var idsToInform = self.markdownsToInform;
                //.map(function (p) {
                //return {
                //    markdownType: p.markdownTypeId,
                //    subcategoryId: p.subcategoryId,
                //    itemId: p.itemId,
                //    unitOfMeasureId: p.unitOfMeasureId,
                //    storeId: p.storeId,
                //    reason: p.reason,
                //}})

            return idsToInform;
        }



        function loadData() {

            loadPreviousReports();

        }

        self.getReasonsFilter = function () {
            if (self.selectedMarkdownPlanType != 0) {

                self.loadingReasons = true;

                markdownService.activation.getReasonsFilter(self.selectedMarkdownPlanType).then(function (reasons) {
                    self.reasons = reasons;
                    self.loadingReasons = false;
                },
                    function (fallback) {
                        self.loadingReasons = false;
                        console.log(fallback);
                    });
            }
        }

        function loadPreviousReports() {
            markdownService.activation.getLastOutputReports()
                .then(function (reports) {
                    self.generatedFiles = reports;
                });

        }

        function init() {

            loadData();
        }

        init();
    });

