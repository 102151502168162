
angular.module("prisma")
    .controller("PriceCompetitiveReportCtrl", function ($state, $rootScope, $timeout, $translate, priceService, adminService) {
        var self = this;

        var columnDefs = null;
        var numberColWidth = 100;
        self.dataSet = [];
        self.isLoading = false;

        self.filters = {
            selectedPriceListId: 0,
            selectedMarketId: 0,
            selectedItem: null,
            selectedCalculationType: 1,
            validFrom: null,
            validTo: null
        };
        self.prices = null;

        self.calculationTypes = [];
        self.filters.validTo = new Date();
        self.filters.validFrom = new Date();
        self.filters.validFrom.setDate(self.filters.validFrom.getDate() - 15);


        self.applyFilters = function () {
            if (self.filters.selectedItem.id == 0) {
                swal('Complete todos los campos', '', 'error');
                return;
            }

            //if (convertDate(self.filters.validFrom).addDays(32) > convertDate(self.filters.validTo)) {
            //    swal('No puede elegir mas de un mes en el rango de fechas.', '', 'error');
            //    return;
            //}

            self.isLoading = true;

            priceService.reports.getCompetitiveReport(self.filters.selectedMarketId, self.filters.selectedPriceListId, self.filters.selectedItem.id, self.filters.selectedCalculationType, convertDate(self.filters.validFrom), convertDate(self.filters.validTo))
                .then(function (data) {
                    self.prices = data;
                    self.isLoading = false;
                    self.gridOptions.api.setRowData(self.prices);
                });
        }


        function setColumnDefinitions() {
            columnDefs = [
                {
                    headerName: "Plaza",
                    field: "plaza",
                    width: numberColWidth + 60,
                    sort: 'asc',
                    cellClass: $rootScope.getClassForCell,
                },
                {
                    headerName: "OxxoGas",
                    field: "oxxoGas",
                    width: numberColWidth + 10,
                    cellRenderer: $rootScope.moneyRenderer,
                },
                {
                    headerName: "Petro7",
                    field: "petro7",
                    width: numberColWidth + 10,
                    cellRenderer: $rootScope.moneyRenderer,
                },
                {
                    headerName: "BP",
                    field: "bp",
                    width: numberColWidth + 10,
                    cellRenderer: $rootScope.moneyRenderer,
                },
                {
                    headerName: "Mobil",
                    field: "mobil",
                    width: numberColWidth + 10,
                    cellRenderer: $rootScope.moneyRenderer,
                },
                {
                    headerName: "Rendi",
                    field: "rendi",
                    width: numberColWidth + 10,
                    cellRenderer: $rootScope.moneyRenderer,
                },
                {
                    headerName: "Shell",
                    field: "shell",
                    width: numberColWidth + 10,
                    cellRenderer: $rootScope.moneyRenderer,
                },
                {
                    headerName: "Chevron",
                    field: "chevron",
                    width: numberColWidth + 10,
                    cellRenderer: $rootScope.moneyRenderer,
                },
                {
                    headerName: "Simsa",
                    field: "simsa",
                    width: numberColWidth + 10,
                    cellRenderer: $rootScope.moneyRenderer,
                },
                {
                    headerName: "Repsol",
                    field: "repsol",
                    width: numberColWidth + 10,
                    cellRenderer: $rootScope.moneyRenderer,
                },
                {
                    headerName: "Grupo_GR",
                    field: "grupo_GR",
                    width: numberColWidth + 10,
                    cellRenderer: $rootScope.moneyRenderer,
                },
                {
                    headerName: "Hidrosina",
                    field: "hidrosina",
                    width: numberColWidth + 10,
                    cellRenderer: $rootScope.moneyRenderer,
                },
                {
                    headerName: "Lodemo",
                    field: "lodemo",
                    width: numberColWidth + 10,
                    cellRenderer: $rootScope.moneyRenderer,
                },
                {
                    headerName: "Rapiditos",
                    field: "rapiditos",
                    width: numberColWidth + 10,
                    cellRenderer: $rootScope.moneyRenderer,
                },
                {
                    headerName: "Resto_Mercado",
                    field: "resto_Mercado",
                    width: numberColWidth + 20,
                    cellRenderer: $rootScope.moneyRenderer,
                },
            ]
        }

        self.gridOptions = {
            columnDefs: columnDefs,
            enableColResize: true,
            enableSorting: true,
            enableFilter: false,
            groupHeaders: false,
            suppressCellSelection: true,
            rowHeight: 35,
            groupHideGroupColumns: true,
            icons: {
                columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                filter: '<i class="fa fa-filter"/>',
                sortAscending: '<i class="fa fa-long-arrow-down"/>',
                sortDescending: '<i class="fa fa-long-arrow-up"/>',
                groupExpanded: '<i class="fa fa-minus-square-o"/>',
                groupContracted: '<i class="fa fa-plus-square-o"/>',
                columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
            },
            angularCompileRows: true
        }

        self.exportToExcel = function () {
            var params = {
                skipHeader: false,
                skipFooters: false,
                skipGroups: false,
                allColumns: false,
                onlySelected: false,
                suppressQuotes: false,
                fileName: self.filters.selectedItem.name + '.xls',
                columnSeparator: ','
            };

            self.gridOptions.api.exportDataAsCsv(params);
        }

        function setGridOptions() {

            setColumnDefinitions();

            self.gridOptions = {
                columnDefs: columnDefs,
                enableColResize: true,
                enableSorting: true,
                enableFilter: false,
                groupHeaders: false,
                suppressCellSelection: true,
                rowHeight: 35,
                groupHideGroupColumns: true,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                }
            }
        }


        self.loadData = function () {

            adminService.items.getItemsByCategory(0).then(function (items) {
                self.items = items;
            });
            adminService.priceLists.getPriceLists().then(function (priceLists) {
                //priceLists.push({ id: 0, name: "TODAS", brand: "OxxoGas", brandId: 1, code: "00", isBase: false, isExcluded: false, isNational: false, listIndex: 0, market: null, marketId: null, region: null, regionId: null });
                self.priceLists = _.orderBy(priceLists, ['name'], ['asc']);
            });

            adminService.priceLists.getMarkets().then(function (markets) {
                //markets.push({ genericId: 0, genericValue: "TODAS" });
                self.markets = _.orderBy(markets, ['genericValue'], ['asc']);
            });

        }


        function convertDate(inputFormat) {
            function pad(s) { return (s < 10) ? '0' + s : s; }
            var d = new Date(inputFormat);
            return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
        }

        function init() {

            setGridOptions();

            //load data
            self.loadData();

            $translate(["Minimum", "Average", "Maximum", "Mode"])
                .then(function (all) {
                    translations = all;
                    self.calculationTypes = [{ id: 0, name: translations.Minimum }, { id: 1, name: translations.Average }, { id: 2, name: translations.Maximum }, { id: 3, name: translations.Mode }];
                });
        }

        init();

    });

