"use strict";

angular.module("prisma")
    .controller("MainCtrl", function ($translate, $state, $rootScope, $scope, $location, $window, authService, adminService, companyJson, queueService) {
        var self = this;

        self.points = 1500;
        self.prevPoints = 0;
        self.search = "";
        self.notifications = [];
        self.nav = companyJson.Navigation;


        self.isActive = function (name) {
            var active = $state.includes(name);
            return active;
        }

        self.changeLanguage = function (langKey) {
            $translate.use(langKey);
        }

        self.goBack = function () {
            $state.go($rootScope.prevState);
        }

        self.canGoBack = function () {
            if ($rootScope.prevState && !$rootScope.prevState.abstract) {
                return true;
            }

            return false;
        }

        self.logout = function () {
            authService.logOut();
            $state.go("login");
        }

        self.navigateToUrl = function (url) {
            $location.path(url);
        }

        self.username = function () {
            return authService.authentication.firstname + " " + authService.authentication.lastname;
        }

        self.role = function () {
            return authService.authentication.roles ? authService.authentication.roles[0] : '';
        }

        self.hasPermission = function (permission) {
            return authService.hasPermission(permission);
        }

        self.getTaskIcon = function (task) {

            var icon = 'fa-tasks';
            switch (task.statusString) {
                case 'Queued': icon = 'fa-clock-o'; break;
                case 'Failed': icon = 'fa-exclamation text-danger'; break;
                case 'Succeeded': icon = 'fa-check-circle text-green'; break;
                case 'InProgress': icon = 'fa-circle-o-notch fa-spin fa-fw'; break;
            }

            return icon;
        }


        self.pendingTasksCount = function () {
            return queueService.queuedTasks.pendingTasksCount();
        }

        self.createDummyTask = function () {
            queueService.queuedTasks.createDummyTask();
        }

        self.getQueuedTasks = function () {
            return queueService.queuedTasks.queue;
        }

        $rootScope.$on('pointsAdded', function (event, args) {
            self.prevPoints = self.points;
            $scope.$apply();

            self.points += parseInt(args.points);

        });


        function init() {
            //get user notifications

            if (authService.authentication.isAuth) {
                adminService.notifications.getTop5().then(function (notifications) {
                    self.notifications = notifications;
                });

                queueService.queuedTasks.getTop5().then(function () {
                    self.queuedTasks = queueService.queuedTasks.queue;
                }.bind(self));
            }
            

        }


        init();
    });