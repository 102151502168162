angular.module("prisma")
    .controller("EditPricelistCtrl", function ($scope, $state, $stateParams, priceService, adminService, $timeout, $translate) {

     //Variable globales
     var self = this;
     self.storePriceLists = [];
     self.priceList = {
         id: 0,
         code: '',
         name: '',
         isNational: false,
         isExcluded: false,
         isBase: false,
         listIndex: -1,
     }

     //Funciones

     function getStorePriceListNames(priceListId) {
         let storeNames = [];
         self.storePriceLists.forEach(function (spl) {
             if (spl.priceListId == priceListId)
                 storeNames.push(spl.storeName);
         });
         return storeNames;
     }

     self.save = function () {
         var storeNames = getStorePriceListNames($stateParams.priceListId);
         var title = translations.SavePriceZoneSwalTitle;
         swal({
             title: title,
             text: self.priceList.isExcluded && storeNames.length > 0 ? translations.WarningStoresSwal + storeNames.join(', ') + translations.BelongZoneSwall : "",
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: translations.SaveSwal,
             cancelButtonText: translations.CancelSwal,
             showLoaderOnConfirm: true,
             closeOnConfirm: false,
             closeOnCancel: true
         },
            function (isConfirm) {
                if (isConfirm) {
                    priceService.pricelists.savePricelist(self.priceList)
                       .then(function (status) {
                           swal(translations.SavedZoneSwal, translations.SavedZoneSwalSubtitle, 'success');

                           $state.go('prices.priceLists');
                       },
                       function () {
                           swal(translations.ErrorTitleSwal, translations.ErrorOccurredWhileSavingZoneSwal, 'error');

                       });
                }
            });
     }


     function getPricelist(id) {

         priceService.pricelists.getPricelist(id)
            .then(function (pl) {
                self.priceList = pl;
            });
         adminService.priceLists.getStorePriceLists()
        .then(function (storePricelists) {
            self.storePriceLists = storePricelists;
        });
     }

     var translations = null;

     function init() {

         $translate(["SavePriceZoneSwalTitle", "WarningStoresSwal", "BelongZoneSwall", "CancelSwal", "SaveSwal", "SavedZoneSwal", "SavedZoneSwalSubtitle", "ErrorOccurredWhileSavingZoneSwal", "ErrorTitleSwal" ])
             .then(function (all) {
                 translations = all;
             });

         var priceListId = $stateParams.priceListId;
         if (priceListId != 0)
             getPricelist(priceListId);
     }

     init();

 });




