angular.module("prisma")
    .controller("EditItemSensitivityCtrl", function ($scope, $state, $stateParams, priceService, $translate ) {
     var self = this;


     self.sensitivity = null;
     self.surveyFrequencies = [
         {
             value: 0,
             name: "Never"
         }, {
             value: 1,
             name: "Daily"
         }, {
             value: 7,
             name: "Weekly"
         }, {
             value: 15,
             name: "Biweekly"
         }, {
             value: 30,
             name: "Monthly"
         }, {
             value: 30,
             name:"Bimonthly"

         }, {
             value: 30,
             name:"Quarterly"
         }

         
     ];

    

     if ($stateParams.itemSensitivityId != '0') {
         priceService.itemSensitivities.getItemSensitivity($stateParams.itemSensitivityId)
            .then(function (itemSensitivity) {
                itemSensitivity.validFrom = new Date(itemSensitivity.validFrom);
                itemSensitivity.validTo = new Date(itemSensitivity.validTo);
                self.sensitivity = itemSensitivity;
            });
     }

     self.save = function () {
         if (self.sensitivity != null) {
             swal({
                 title: translations.SaveSensitivitySwal,
                 type: "warning",
                 showCancelButton: true,
                 confirmButtonColor: "#1AB394",
                 confirmButtonText: translations.ContinueSwal,
                 cancelButtonText: translations.CancelSwal,
                 showLoaderOnConfirm: true,
                 closeOnConfirm: false,
                 closeOnCancel: true
             },
      function (isConfirm) {
          if (isConfirm) {


              priceService.itemSensitivities.saveItemSensitivity(self.sensitivity)
               .then(function (itemSensitivity) {
                   swal(translations.SavedSensitivitySwal, translations.TheSensibilitySwal + self.sensitivity.name + translations.HasBeenSuccessfullySavedSwal, 'success');

                   $state.go('prices.itemSensitivities');

               }, function (status) {
                   if (status == 502) {
                       swal(translations.TheProcessContinuesSwal, translations.TheProcessOfContinuousStorageSwal)
                   }
                   else {
                       swal(translations.ErrorTitleSwal, translations.ErrorSavingSensitivitySwal, "error");
                   }
               });
          }
      });
         }
     }

     self.getTitle = function () {
         if ($stateParams.itemSensitivityId != '0')
             return translations.EditSensitivityTitle;
         else
             return translations.NewSensitivityTitle;
     }

     self.cleanInputs = function () {
         self.sensitivity.monday = false;
         self.sensitivity.tuesday = false;
         self.sensitivity.wednesday = false;
         self.sensitivity.thursday = false;
         self.sensitivity.friday = false;
         self.sensitivity.saturday = false;
         self.sensitivity.sunday = false;
     }



     self.selectCheckbox = function(day)
     {
      
         if (self.sensitivity.surveyFrequency > 1) {
             //Blanqueas todos lo campos
             self.cleanInputs();
             switch (day) {
                 case 'MO': {
                     if (self.sensitivity.monday == true) {
                         self.sensitivity.monday = false;
                     }
                     else {
                         self.sensitivity.monday = true;
                     }
                     break;
                 }
                 case 'TU': {
                     self.sensitivity.tuesday = true;
                     break;
                 }
                 case 'WE': {
                     self.sensitivity.wednesday = true;
                     break;
                 }
                 case 'TH': {
                     self.sensitivity.thursday = true;
                     break;
                 }
                 case 'FR': {
                     self.sensitivity.friday = true;
                     break;
                 }
                 case 'SA': {
                     self.sensitivity.saturday = true;
                     break;
                 }
                 case 'SU': {
                     self.sensitivity.sunday = true;
                     break;
                 }

             }
         }

       
     }

     var translations = null;

     function init() {
         $translate(["EditSensitivityTitle", "NewSensitivityTitle", "ContinueSwal", "CancelSwal", "SaveSensitivitySwal", "SavedSensitivitySwal", "TheSensibilitySwal", "HasBeenSuccessfullySavedSwal", "TheProcessContinuesSwal", "TheProcessOfContinuousStorageSwal", "ErrorTitleSwal", "ErrorSavingSensitivitySwal"])
             .then(function (all) {
                 translations = all;
             });
     }

     init();

 });

