"use strict";

angular.module("prisma")

    .directive("sectionTools", function ($timeout) {
        return {
            link: function (scope, element, args) {
                var s = scope;
                var e = element;
                var a = args;

                scope.close = args.close === "false" ? false : true;
                scope.config = args.config === "false" ? false : true;
            },
            controller: function ($scope, $element) {
                // Function for collapse ibox
                $scope.toggle = function (name) {
                    var section = $element.find('section[name=\'' + name + '\']');
                    var icon = section.find("i:first");
                    var content = section.find("div.section-content");
                    content.slideToggle(200);
                    // Toggle icon from up to down
                    icon.toggleClass("fa-chevron-up").toggleClass("fa-chevron-down");
                    section.toggleClass("").toggleClass("border-bottom");
                    $timeout(function () {
                        section.resize();
                        section.find("[id^=map-]").resize();
                    }, 50);
                }

                $scope.$watch('ct.inputVisibility', function (newVal, oldVal) {
                    if (newVal == 1) {
                        $element.find('.view-all').hide();
                        $element.find('.view-fill').show();
                    } else {
                        $element.find('.view-all').show();
                    }
                }, true);
            }
        };
    });