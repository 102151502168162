'use strinct';
(function () {
    Link: https://parkji.co.uk/2013/08/11/native-drag-and-drop-in-angularjs.html
    angular.module('prisma')
        .directive('droppable', ['$log', '$parse', '$timeout', '$rootScope', droppableDirective]);

    function droppableDirective($log, $parse, $timeout, $rootScope) {
        //$log.debug('droppableDirective::ctor');
        var uid = 0;

        return {
            restrict: 'A',
            link: function link(scope, $element, $attr) {
                var newId = ++uid;
                // $log.debug('droppableDirective::link id: %o', newId);
                $element.data('dropZoneId', String(newId));
                var raiseDragover;
                if ($attr.onDragover) {
                    raiseDragover = $parse($attr.onDragover);
                } else {
                    raiseDragover = angular.noop;
                }

                var raiseDragenter;
                if ($attr.onDragenter) {
                    raiseDragenter = $parse($attr.onDragenter);
                } else {
                    raiseDragenter = angular.noop;
                }

                var raiseDragleave;
                if ($attr.onDragleave) {
                    raiseDragleave = $parse($attr.onDragleave);
                } else {
                    raiseDragleave = angular.noop;
                }

                var raiseDrop;
                if ($attr.onDrop) {
                    raiseDrop = $parse($attr.onDrop);
                } else {
                    raiseDrop = angular.noop;
                }

                function preventAndStop($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                }

                $element.off('dragenter dragover dragleave drop')
                    .on('dragover', function dragover($event) {
                        // $log.debug('droppableDirective::link (dragover) $event: %o', $event);
                        preventAndStop($event);
                        $element.addClass('over');
                        scope.$apply(function () {
                            raiseDragover(scope, { '$event': $event });
                        });
                        return false;
                    }).on('dragenter', function dragenter($event) {
                        // $log.debug('droppableDirective::link (dragenter) $event: %o', event);
                        preventAndStop($event);
                        $element.addClass('over');
                        scope.$apply(function () {
                            raiseDragenter(scope, { '$event': $event });
                        });
                    }).on('dragleave', function dragleave($event) {
                        // $log.debug('droppableDirective::link (dragleave) $event: %o', $event);
                        preventAndStop($event);
                        $element.removeClass('over');
                        scope.$apply(function () {
                            raiseDragleave(scope, { '$event': $event });
                        });
                    }).on('drop', function drop($event) {
                        // $log.debug('droppableDirective::link (drop) $event: %o', $event);
                        preventAndStop($event);
                        $element.removeClass('over');
                        //scope.$apply(function () {
                        if ($rootScope.$$phase) {
                            $timeout(function () {
                                raiseDrop(scope, { '$event': $event });
                            });
                        }
                        else {
                            scope.$apply(function () {
                                raiseDrop(scope, { '$event': $event });
                            });
                        }

                        return false;
                    });
                scope.$on("$destroy", function () {
                    $element.off('dragenter dragover dragleave drop');
                });
            }
        };
    }
})();