angular.module("prisma")
    .controller("AbTestingIndexCtrl", function ($rootScope, $state, priceService, $translate) {

      var allSelected = true;
      var self = this;
      var dic = {};
      var numberColWidth = 100;
      var editableColWidth = 140;
      var lastAdded = null;
      var columnDefs = null;

      self.gridOptions = null;
      self.summary = null;

      self.competitorGroups = [];

      //self.goToRules = function (id, categoryGroup, category, subcategory) {

      //    categoryGroup = categoryGroup.replace(/ /g, "-").replace(/\//g, "_");
      //    category = category.replace(/ /g, "-").replace(/\//g, "_");
      //    subcategory = subcategory.replace(/ /g, "-").replace(/\//g, "_");

      //    var encodedcategoryGroup = categoryGroup != 'null' ? encodeURIComponent(categoryGroup) : '';
      //    var encodedcategory = category != 'null' ? encodeURIComponent(category) : '';
      //    var encodedsubcategory = subcategory != 'null' ? encodeURIComponent(subcategory) : '';

      //    $state.go('prices.rules', { categoryId: id, categoryGroup: encodedcategoryGroup, category: encodedcategory, subcategory: encodedsubcategory });
      //}


      self.newTest = function () {

      }

      self.deleteSelection = function () {
          var notSelected = [];
          swal({
              title: translations.DeleteSelectedTests,
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#1AB394",
              confirmButtonText: translations.ContinueSwal,
              cancelButtonText: translations.CancelSwal,
              showLoaderOnConfirm: true,
              closeOnConfirm: false,
              closeOnCancel: true
          }, function (isConfirm) {
              if (!isConfirm) return;
              //self.gridOptions.api.setDatasource([]);
              //swal('Los tests fueron borrados!', 'Los tests fueron borrados exitosamente', 'success');

              var ids = [];
              self.gridOptions.api.forEachNode(function (node) {
                  if (node.isSelected()) {
                      ids.push(node.data.id);
                  } else {
                      notSelected.push(node.data);
                  }


              });

              priceService.abTesting.deleteTests(ids).then(function (res) {
                  swal(translations.TestsWereDeleted, translations.TestsWereDeletedSuccessfully, 'success');
                  self.gridOptions.api.setRowData(notSelected);

              }, function (status) {
                  if (status == 502) {
                      swal(translations.TheProcessContinues, translations.TheProcessOfContinuousDeletionSwal)
                  }
                  else {
                      swal(translations.ErrorTitleSwal, translations.ErrorInWantingToEraseTests, "error");
                  }
              });

          })
      }

      self.toggleSelection = function () {

          if (self.gridOptions.api.isAnyFilterPresent()) {
              //selecciono solo los filtrados
              self.gridOptions.api.forEachNodeAfterFilter(function (node) {
                  node.setSelected(!allSelected);
              });
          }
          else {
              //selecciono todos
              allSelected ? self.gridOptions.api.deselectAll() : self.gridOptions.api.selectAll();
          }

          //toggle select / deselect
          allSelected = !allSelected;
      }


      function iconRenderer(params) {
          return '<a title="Click aqui para ingresar a la categoria" ng-click="ct.goToRules(' + params.data.id + ')"><i class="fa fa-arrow-circle-o-right" style="padding: 5px; font-size:16px"></i></a>';
      }

      self.test = function () {
          console.log('test');
      }


      //grid config
      function setColumnDefinitions() {
          columnDefs = [
          {
                  headerName: translations.Description,
              width: 220,
              field: 'description',
              checkboxSelection: true,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              pinned: 'left',
              //suppressMenu: true,
              //suppressSorting: true,
              filter: false,
              cellClass: $rootScope.getClassForCel,
              cellRenderer: {

                  renderer: 'group',
                  //checkbox: false,
                  innerRenderer: function (params) {
                      console.log('params', params);
                      var link = '<a style="margin-left:4px;" title="Ver reporte" ui-sref="prices.abTestingReports({abTestingId: ' + params.data.id + '})"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>';
                      1
                      return link + params.value;
                  }
              }

          },
          {
              headerName: translations.Data,

              children: [
                 {
                      headerName: translations.Hypothesis,
                     field: "hypothesis",
                     headerTooltip: "",
                     //filter: 'number',
                     suppressMenu: true,
                     suppressSorting: true,
                     width: 700,
                     cellClass: $rootScope.getClassForCell,
                     //cellRenderer: $rootScope.percentageEditorWithDecimal
                 },
                  {
                      headerName: "Item",
                      field: "itemName",
                      headerTooltip: "",
                      //filter: 'number',
                      suppressMenu: true,
                      suppressSorting: true,
                      width: 350,
                      cellClass: $rootScope.getClassForCell,
                      //cellRenderer: $rootScope.percentageEditorWithDecimal
                  },
                 {
                     headerName: translations.From,
                     field: "validFrom",
                     headerTooltip: "",
                     filter: 'date',
                     suppressMenu: true,
                     suppressSorting: true,
                     width: 80,
                     cellClass: $rootScope.getClassForCell,
                     cellRenderer: $rootScope.dateRenderer
                 }, 
                 {
                     headerName: translations.To,
                     field: "validTo",
                     headerTooltip: "",
                     filter: 'date',
                     suppressMenu: true,
                     suppressSorting: true,
                     width: 80,
                     cellClass: $rootScope.getClassForCell,
                     cellRenderer: $rootScope.dateRenderer
                 }]
          },
          {
              headerName: translations.VariationInPrices,

              children: [
                   {
                      headerName: translations.Change,
                       field: "changeInPrice",
                       filter: 'number',
                       headerTooltip: "",
                       suppressMenu: true,
                       suppressSorting: true,
                       width: 70,
                       cellClass: $rootScope.getClassForCell,
                       cellRenderer: $rootScope.decimalRenderer
                   },
                   {
                       headerName: translations.Type,
                       field: "changeInPriceType",
                       headerTooltip: "",
                       suppressMenu: true,
                       suppressSorting: true,
                       width: 50,
                       cellClass: $rootScope.getClassForCell,
                       //filter: 'input',
                       //cellRenderer: $rootScope.checkboxEditor
                   },
                  {
                      headerName: translations.Expected,
                      field: "expectedChangeInDemand",
                      headerTooltip: "",
                      suppressMenu: true,
                      suppressSorting: true,
                      width: 70,
                      cellClass: $rootScope.getClassForCell,
                      //filter: 'input',
                      cellRenderer: $rootScope.decimalRenderer
                  }]
          }
          ]
      }

      //Grid Renderer
      //self.checkboxEditorMarginGuides = function (params) {
      //    var eInput = document.createElement("input");
      //    eInput.type = "checkbox";
      //    eInput.checked = params.value;

      //    eInput.addEventListener("change", function (event) {
      //        params.value = eInput.checked;
      //        params.data[params.column.colId] = eInput.checked;

      //        if (params.colDef.field == 'goToTargetMargin') { //Si toque Ir a Obj, destildo Mantener
      //            params.node.data.keepMargin = false;
      //        }
      //        else if (params.colDef.field == 'keepMargin') { //Si toque Mantener, destildo Ir a Obj
      //            params.node.data.goToTargetMargin = false;
      //        }


      //        if (params.node != null && params.node.children != null) {
      //            params.node.children.forEach(function (node) {
      //                if (node.children) {
      //                    node.children.forEach(function (node) {
      //                        node.data[params.column.colId] = eInput.checked;

      //                        if (params.colDef.field == 'goToTargetMargin') { //Si toque Ir a Obj, destildo Mantener
      //                            node.data.keepMargin = false;
      //                        }
      //                        else if (params.colDef.field == 'keepMargin') { //Si toque Mantener, destildo Ir a Obj
      //                            node.data.goToTargetMargin = false;
      //                        }

      //                    });
      //                }
      //                node.data[params.column.colId] = eInput.checked;

      //                if (params.colDef.field == 'goToTargetMargin') { //Si toque Ir a Obj, destildo Mantener
      //                    node.data.keepMargin = false;
      //                }
      //                else if (params.colDef.field == 'keepMargin') { //Si toque Mantener, destildo Ir a Obj
      //                    node.data.goToTargetMargin = false;
      //                }

      //            });
      //        }

      //        params.data.dirty = true;

      //        params.api.refreshView();
      //    });

      //    return eInput;
      //}

      //function dropDownDecisionEditor(params) {

      //    var editing = false;

      //    var eCell = document.createElement('div');

      //    var eLabel = null

      //    if (params.value != null && params.value != "")
      //        eLabel = document.createTextNode(params.value);
      //    else
      //        eLabel = document.createTextNode("------------");

      //    eCell.appendChild(eLabel);

      //    var eSelect = document.createElement("select");
      //    eSelect.className = "form-control";
      //    eSelect.style = "margin:0px !important; border: 0px; line-height: 100%; font-size:12px !important";

      //    for (var i = 0; i < self.competitorGroups.length; i++) {
      //        var eOption = document.createElement("option");
      //        eOption.setAttribute("value", self.competitorGroups[i].id);
      //        eOption.innerHTML = self.competitorGroups[i].name;
      //        eSelect.appendChild(eOption);
      //    }

      //    eSelect.value = params.data.mainCompetitorGroupId;


      //    eCell.addEventListener('click', function () {
      //        if (!editing) {
      //            eCell.removeChild(eLabel);
      //            eCell.appendChild(eSelect);
      //            eSelect.focus();
      //            editing = true;
      //        }
      //    });

      //    eSelect.addEventListener('blur', function () {
      //        if (editing) {
      //            editing = false;
      //            eCell.removeChild(eSelect);
      //            eCell.appendChild(eLabel);
      //        }
      //    });

      //    eSelect.addEventListener('change', function () {
      //        if (editing) {

      //            var colIds = [];
      //            colIds.push(params.column.colId);
      //            var changedRows = [];
      //            changedRows.push(params.node);

      //            editing = false;
      //            var newValue = '';

      //            for (var i = 0; i < self.competitorGroups.length; i++) {
      //                if (self.competitorGroups[i].id == eSelect.value) {
      //                    newValue = self.competitorGroups[i].name;
      //                    params.data['mainCompetitorGroup'] = newValue;
      //                    params.data['mainCompetitorGroupId'] = eSelect.value;
      //                    //Children
      //                    if (params.node != null && params.node.children != null) {
      //                        params.node.children.forEach(function (node) {
      //                            if (node.children) {
      //                                node.children.forEach(function (node) {
      //                                    node.data['mainCompetitorGroup'] = newValue;
      //                                    node.data['mainCompetitorGroupId'] = eSelect.value;
      //                                    node.data.dirty = true;
      //                                    changedRows.push(node);
      //                                });
      //                            }
      //                            node.data['mainCompetitorGroup'] = newValue;
      //                            node.data['mainCompetitorGroupId'] = eSelect.value;
      //                            node.data.dirty = true;
      //                            changedRows.push(node);
      //                        });
      //                    }
      //                    break;
      //                }
      //            }


      //            params.data.dirty = true;

      //            params.api.refreshCells(changedRows, colIds);

      //            eLabel.nodeValue = newValue;
      //            eCell.removeChild(eSelect);
      //            eCell.appendChild(eLabel);
      //        }
      //    });

      //    return eCell;
      //}

      function setGridOptions() {

          

          self.gridOptions = {
              columnDefs: columnDefs,
              enableColResize: true,
              enableSorting: true,
              enableFilter: true,
              groupHeaders: true,
              groupUseEntireRow: false,
              rowSelection: "multiple",
              suppressCellSelection: true,
              groupSelectsChildren: true,
              groupSuppressAutoColumn: false, // or undefined
              groupColumnDef: null, // or undefined
              singleClickEdit: true,
              rowHeight: 35,
              icons: {
                  columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                  filter: '<i class="fa fa-filter"/>',
                  sortAscending: '<i class="fa fa-long-arrow-down"/>',
                  sortDescending: '<i class="fa fa-long-arrow-up"/>',
                  groupExpanded: '<i class="fa fa-minus-square-o"/>',
                  groupContracted: '<i class="fa fa-plus-square-o"/>',
                  columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                  columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
              },
              suppressCellSelection: true,
              enableRangeSelection: false,
              angularCompileRows: true,
              //getNodeChildDetails: getNodeChildDetails
          }
      }

      //function getNodeChildDetails(rowItem) {
      //    if (rowItem.subcategories && rowItem.subcategories.length > 0) {
      //        return {
      //            group: true,
      //            expanded: false,
      //            // provide ag-Grid with the children of this group
      //            children: rowItem.subcategories,
      //            // the key is used by the default group cellRenderer
      //            key: rowItem.id
      //        };
      //    } else {
      //        return null;
      //    }
      //}

      //behaviour
      //function changeValue(element, value) {
      //    element.value = value;
      //}

      //grid filter
      //self.onFilterChanged = function (value) {
      //    self.gridOptions.api.setQuickFilter(value);
      //}


      function loadData() {

          priceService.abTesting.getAbTestingGrid()
              .then(function (test) {

                  self.gridOptions.api.setRowData(test);

                  console.log('response', test);
              })

          //priceService.rules.getCategories()
          //    .then(function (summary) {

          //        self.summary = summary;
          //        self.gridOptions.api.setRowData(summary);
          //    })
      }

      var translations = null;

      function init() {

          setGridOptions();
          
          $translate(["DeleteSelectedTests", "ContinueSwal", "CancelSwal", "TestsWereDeleted", "TestsWereDeletedSuccessfully", "TheProcessContinues"
              , "TheProcessOfContinuousDeletionSwal", "ErrorTitleSwal", "ErrorInWantingToEraseTests", "Description", "Data", "Hypothesis"
              , "From", "To", "VariationInPrices", "Change", "Type", "Expected"])
              .then(function (all) {
                  translations = all;

                  setColumnDefinitions();

                  self.gridOptions.api.setColumnDefs(columnDefs);
              });

          //init columnDefs and gridOptions
          
          loadData();
      }

      init();
  });

