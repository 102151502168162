"use strict";

angular.module("prisma")
  .controller("PivotCtrl", function ($scope, $timeout) {
      var $container = null;
      var filters = {};

      var filterFns = {
          GMGreaterThan: function () {
              var number = $(this).find(".GM").text().replace("$", "").replace(",", "").replace(".", "");
              return parseInt(number) >= 150;
          },
          UnitsRange: function () {
              var number = $(this).find(".Units").text();
              return (parseInt(number) >= $scope.advancedFilters.units.from && parseInt(number) <= $scope.advancedFilters.units.to);
          },
          PriceRange: function () {
              var number = $(this).find(".Price").text().replace("$", "");
              return (parseFloat(number) >= $scope.advancedFilters.price.from && parseFloat(number) <= $scope.advancedFilters.price.to);
          },
          GMRange: function () {
              var number = $(this).find(".GM").text().replace("$", "");
              return (parseFloat(number) >= $scope.advancedFilters.gm.from && parseFloat(number) <= $scope.advancedFilters.gm.to);
          },
          ium: function () {
              var name = $(this).find(".Name").text();
              return name.match(/bebidas/);
          }
      };

      var init = function () {
          //init isotope
          $timeout(function () {
              $container = $(".isotope").isotope({
                  itemSelector: ".element-item",
                  layoutMode: "fitRows",
                  getSortData: {
                      Name: ".Name",
                      Manufacturer: ".Manufacturer",
                      Category: "[data-category]",
                      Units: ".Units parseInt",
                      Price: function (itemElem) {
                          var number = $(itemElem).find(".Price").text().replace("$", "").replace(",", "").replace(".", "");
                          return parseFloat(number);
                      },
                      Sales: function (itemElem) {
                          var number = $(itemElem).find(".Sales").text().replace("$", "").replace(",", "").replace(".", "");
                          return parseFloat(number);
                      },
                      GM: function (itemElem) {
                          var number = $(itemElem).find(".GM").text().replace("$", "").replace(",", "").replace(".", "");
                          return parseFloat(number);
                      }
                  },
                  sortAscending: {
                      Name: true,
                      Manufacturer: true,
                      Category: true,
                      Units: false,
                      Price: false,
                      Sales: false,
                      GM: false
                  },
                  filter: function () {
                      var isMatched = true;
                      var $this = $(this);

                      for (var prop in filters) {
                          var filter = filters[prop];
                          // use function if it matches
                          filter = filterFns[filter] || filter;
                          // test each filter
                          if (filter) {
                              isMatched = isMatched && $(this).is(filter);
                          }
                          // break if not matched
                          if (!isMatched) {
                              break;
                          }
                      }
                      return isMatched;
                  }
              });

              filters["gm"] = "GMRange";
              $container.isotope("arrange");

          }, 1000);

          // change is-checked class on buttons
          $(".button-group").each(function (i, buttonGroup) {
              var $buttonGroup = $(buttonGroup);
              $buttonGroup.on("click", "button", function () {
                  $buttonGroup.find(".is-checked").removeClass("is-checked");
                  $(this).addClass("is-checked");
              });
          });
      }

      $scope.classify = function (cat) {
          return cat.toLowerCase()
              .replaceAll(" ", "-")
              .replaceAll("/", "")
              .removeDiacritics();
      }
      $scope.$watch($scope.articles, function () {
          init();
      });

      //filtering and sorting
      $scope.sort = function (sortByValue) {

          $container.isotope({ sortBy: sortByValue });
      }
      $scope.frequentFilter = function (type, filterValue) {
          //clear filters
          filters = {};
          // set filter for group
          filters[type] = filterValue;
          // arrange, and use filter fn
          $container.isotope("arrange");
      }
      $scope.advancedFilters = {
          units: { from: 0, to: 3000 },
          price: { from: 0, to: 110 },
          gm: { from: 0, to: 2600 }
      }
      $scope.unitsSliderOptions = {
          min: 0,
          max: 3000,
          from: 0,
          to: 2909,
          to_max: 2909,
          to_shadow: true,
          type: "double",
          postfix: " uni",
          prettify: false,
      };
      $scope.priceSliderOptions = {
          min: 0,
          max: 105,
          from: 0,
          to: 104,
          to_max: 104,
          to_shadow: true,
          type: "double",
          prefix: "$",
          prettify: false,
      }
      $scope.gmSliderOptions = {
          min: 0,
          max: 2504,
          from: 0,
          to: 2504,
          to_max: 2504,
          to_shadow: true,
          type: "double",
          prefix: "$",
          prettify: false,
      }
      $scope.onCategoryChanged = function () {
          var catName = "*";
          if ($scope.selectedCategory) {
              var cat = $scope.selectedCategory;
              catName = "." + $scope.classify(cat.Name);
          }
          filters["category"] = catName;
          $container.isotope("arrange");
      }
      $scope.onUnitsSliderChanged = function (data) {
          $scope.advancedFilters.units.from = data.fromNumber;
          $scope.advancedFilters.units.to = data.toNumber;
          filters["units"] = "UnitsRange";
          $container.isotope("arrange");
      }
      $scope.onPriceSliderChanged = function (data) {
          $scope.advancedFilters.price.from = data.fromNumber;
          $scope.advancedFilters.price.to = data.toNumber;
          filters["price"] = "PriceRange";
          $container.isotope("arrange");
      }
      $scope.onGmSliderChanged = function (data) {
          $scope.advancedFilters.gm.from = data.fromNumber;
          $scope.advancedFilters.gm.to = data.toNumber;
          filters["gm"] = "GMRange";
          $container.isotope("arrange");
      }
      $scope.toggleAdvancedFilters = function (turnOn) {
          $scope.showAdvanced = turnOn;

          $("#filters button").removeClass("is-checked");
          $("#filters button#all").addClass("is-checked");

          filters = {};
          filters.category = "*";
          $container.isotope("arrange");
      }

      //data
      $scope.categories = [
          {
              "Id": 2,
              "Name": "Lavado de Autos"
          },
          {
              "Id": 7,
              "Name": "Cigarrillos"
          },
          {
              "Id": 13,
              "Name": "Otro Tabaco"
          },
          {
              "Id": 21,
              "Name": "Cerveza"
          },
          {
              "Id": 33,
              "Name": "Vinos"
          },
          {
              "Id": 39,
              "Name": "Licor"
          },
          {
              "Id": 44,
              "Name": "Bebidas Embotelladas"
          },
          {
              "Id": 55,
              "Name": "Golosinas"
          },
          {
              "Id": 65,
              "Name": "Leches"
          },
          {
              "Id": 75,
              "Name": "Lacteos"
          },
          {
              "Id": 86,
              "Name": "Comida Empacada"
          },
          {
              "Id": 92,
              "Name": "Helados"
          },
          {
              "Id": 98,
              "Name": "Comida Congelada"
          },
          {
              "Id": 103,
              "Name": "Pan"
          },
          {
              "Id": 106,
              "Name": "Snacks Salados"
          },
          {
              "Id": 115,
              "Name": "Snacks Dulces"
          },
          {
              "Id": 120,
              "Name": "Cereales"
          },
          {
              "Id": 126,
              "Name": "Frutas y Verduras"
          },
          {
              "Id": 134,
              "Name": "Almacen"
          },
          {
              "Id": 140,
              "Name": "Limpieza"
          },
          {
              "Id": 148,
              "Name": "Salud y Belleza"
          },
          {
              "Id": 162,
              "Name": "Mercader�a General"
          },
          {
              "Id": 178,
              "Name": "Publicaciones"
          },
          {
              "Id": 188,
              "Name": "Productos para el Automotor"
          },
          {
              "Id": 195,
              "Name": "Hielo"
          },
          {
              "Id": 198,
              "Name": "Comida"
          },
          {
              "Id": 215,
              "Name": "Bebidas Calientes"
          },
          {
              "Id": 224,
              "Name": "Bebidas fr�as dispensadas"
          },
          {
              "Id": 232,
              "Name": "Bebidas Congeladas dispensadas"
          },
          {
              "Id": 236,
              "Name": "Bebidas alcoholicas dispensadas"
          },
          {
              "Id": 243,
              "Name": "Combustibles"
          },
          {
              "Id": 252,
              "Name": "Servicios para el automotor"
          },
          {
              "Id": 257,
              "Name": "Store Services"
          },
          {
              "Id": 275,
              "Name": "Loter�a"
          },
          {
              "Id": 282,
              "Name": "Servicios varios"
          },
          {
              "Id": 290,
              "Name": "Store Use / Supply"
          }
      ];
      $scope.articles = [
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 216,
              "Subcategory": "COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2023,
              "Name": "Cafe Exp Doble/Cortado/C Leche",
              "Price": "4.70",
              "Units": 768,
              "Sales": 2980.43,
              "GM": 2503.51
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 216,
              "Subcategory": "COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2021,
              "Name": "Cafe Jarrito Tric Lag",
              "Price": "4.00",
              "Units": 768,
              "Sales": 2540.73,
              "GM": 2228.92
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 216,
              "Subcategory": "COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2020,
              "Name": "Cafe Expr/ Cortado",
              "Price": "3.60",
              "Units": 624,
              "Sales": 1858.71,
              "GM": 1598.47
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "BUFFALO",
              "EAN": 7795070203194,
              "Name": "BFF Buffalo Escobilla C 19 P 1und",
              "Price": "16.20",
              "Units": 162,
              "Sales": 2169.18,
              "GM": 831.94
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3116,
              "Name": "JAMON FRESH TM",
              "Price": "9.75",
              "Units": 195,
              "Sales": 1571.65,
              "GM": 826.97
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "BUFFALO",
              "EAN": 7795070203200,
              "Name": "BFF Buffalo Escobilla C 20 PL 1und",
              "Price": "16.50",
              "Units": 144,
              "Sales": 1963.72,
              "GM": 734.25
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77918482,
              "Name": "PM Marlboro Box 20und",
              "Price": "4.05",
              "Units": 2474,
              "Sales": 9359.29,
              "GM": 671.09
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895006968,
              "Name": "Coca Cola Botella 600ml",
              "Price": "3.30",
              "Units": 528,
              "Sales": 1446.93,
              "GM": 652.56
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "BUFFALO",
              "EAN": 7795070703182,
              "Name": "BFF Buffalo Escobilla C 18 PLUS",
              "Price": "15.20",
              "Units": 140,
              "Sales": 1758.41,
              "GM": 644.35
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895000430,
              "Name": "Coca Cola 1.5L",
              "Price": "5.50",
              "Units": 306,
              "Sales": 1392.36,
              "GM": 557.17
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895000997,
              "Name": "Coca Cola 2.25L",
              "Price": "6.20",
              "Units": 251,
              "Sales": 1287.61,
              "GM": 534.77
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7795070030226,
              "Name": "BFF Buffalo Escobilla C 22 PLUS",
              "Price": "22.10",
              "Units": 69,
              "Sales": 1260,
              "GM": 491.34
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 207,
              "Subcategory": "BAKERY",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3726,
              "Name": "Medialuna De Manteca",
              "Price": "1.30",
              "Units": 800,
              "Sales": 859,
              "GM": 472.62
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7790315000446,
              "Name": "DNN Villavicencio Agua 500ml",
              "Price": "2.50",
              "Units": 439,
              "Sales": 908.95,
              "GM": 469.94
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 209,
              "Subcategory": "OTHER CUISINE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3437,
              "Name": "EMPANADA JAMON Y QUESO x 1 UNIDAD",
              "Price": "2.60",
              "Units": 552,
              "Sales": 1186.8,
              "GM": 430.61
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3115,
              "Name": "GRAN IBERICO TM",
              "Price": "11.00",
              "Units": 71,
              "Sales": 645.39,
              "GM": 392.77
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895006975,
              "Name": "Coca Cola Light 600ml",
              "Price": "3.30",
              "Units": 309,
              "Sales": 843.4,
              "GM": 376.01
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "WYNNS",
              "EAN": 73579610017,
              "Name": "WNNS Injector Cleaner 354ml",
              "Price": "27.37",
              "Units": 38,
              "Sales": 859.48,
              "GM": 374.76
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "BUFFALO",
              "EAN": 7795070103241,
              "Name": "BFF Buffalo Escobilla C 24 PLUS",
              "Price": "35.00",
              "Units": 41,
              "Sales": 1186.1,
              "GM": 360.7
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 193,
              "Subcategory": "OTHER ADDITIVES",
              "Manufacturer": "BARDAHL LUBRICANT",
              "EAN": 7790627112523,
              "Name": "BRDL TIR Long Life deBardhal 950ml",
              "Price": "26.00",
              "Units": 44,
              "Sales": 945.56,
              "GM": 353.8
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 217,
              "Subcategory": "HOT TEA",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2027,
              "Name": "Infusiones/Te/Mate/Inf.Arbolit",
              "Price": "3.00",
              "Units": 153,
              "Sales": 379.44,
              "GM": 352.7
          },
          {
              "CategoryId": 195,
              "Category": "Hielo",
              "SubcategoryId": 196,
              "Subcategory": "BAGGED/BLOCKED ICE",
              "Manufacturer": "REDONHIELO",
              "EAN": 7798021470010,
              "Name": "RDH Redonhielo x 4kg",
              "Price": "7.00",
              "Units": 147,
              "Sales": 850.74,
              "GM": 328.89
          },
          {
              "CategoryId": 86,
              "Category": "Comida Empacada",
              "SubcategoryId": 87,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "CANON",
              "EAN": 7795947000178,
              "Name": "CN CaNon Pan Blanco Trip 3und",
              "Price": "6.90",
              "Units": 115,
              "Sales": 655.54,
              "GM": 311.91
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "OTHER",
              "EAN": 77990303,
              "Name": "Alf Cachafaz Chocolate",
              "Price": "2.54",
              "Units": 384,
              "Sales": 806.68,
              "GM": 307.35
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895639814,
              "Name": "COCA COLA F.GRIP 410ML",
              "Price": "2.70",
              "Units": 257,
              "Sales": 578.17,
              "GM": 307.06
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "BUFFALO",
              "EAN": 7795070000212,
              "Name": "BFF Buffalo Escobilla C 21 1und",
              "Price": "19.80",
              "Units": 45,
              "Sales": 736.26,
              "GM": 303.15
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 190,
              "Subcategory": "ANTIFREEZE ETC.",
              "Manufacturer": "GILIO HERMANOS",
              "EAN": 7793735000089,
              "Name": "GHN Ros Rig Agua Destilada 950ml",
              "Price": "3.44",
              "Units": 120,
              "Sales": 340.58,
              "GM": 293.04
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 7611,
              "Name": "Ajuste Revistas",
              "Price": "0.10",
              "Units": 2909,
              "Sales": 290.9,
              "GM": 290.9
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "OTHER",
              "EAN": 7791489177736,
              "Name": "PM Massalin Tripack & Regalo",
              "Price": "12.14",
              "Units": 324,
              "Sales": 3672.34,
              "GM": 287.51
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770328850021,
              "Name": "Para Ti Decoracion",
              "Price": "11.00",
              "Units": 25,
              "Sales": 275,
              "GM": 275
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3123,
              "Name": "PECHUGUITA LIGHT TM",
              "Price": "11.50",
              "Units": 52,
              "Sales": 494.04,
              "GM": 266.27
          },
          {
              "CategoryId": 86,
              "Category": "Comida Empacada",
              "SubcategoryId": 87,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "CANON",
              "EAN": 7795947008112,
              "Name": "CN Canon Triang Pollo Rucula  Parmesa",
              "Price": "9.75",
              "Units": 80,
              "Sales": 644.8,
              "GM": 260.05
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 219,
              "Subcategory": "CAPPUCC./SPEC.COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2031,
              "Name": "Capuccino Italiano",
              "Price": "6.10",
              "Units": 59,
              "Sales": 297.36,
              "GM": 258.88
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "FERRERO",
              "EAN": 40084107,
              "Name": "FRR Kinder Sorpresa 20gr",
              "Price": "3.70",
              "Units": 188,
              "Sales": 575.19,
              "GM": 255.48
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548686,
              "Name": "DNN Villa del Sur Agua Pomelo 500ml",
              "Price": "3.10",
              "Units": 195,
              "Sales": 501.15,
              "GM": 251.35
          },
          {
              "CategoryId": 86,
              "Category": "Comida Empacada",
              "SubcategoryId": 87,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "CANON",
              "EAN": 7795947000208,
              "Name": "CN CaNon Pebete J y Q 1und",
              "Price": "6.50",
              "Units": 92,
              "Sales": 494.05,
              "GM": 248.49
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3094,
              "Name": "Sacramento Jamon y Queso",
              "Price": "4.60",
              "Units": 122,
              "Sales": 463.6,
              "GM": 248.1
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895001451,
              "Name": "Coca Cola Light 1.5L",
              "Price": "5.50",
              "Units": 141,
              "Sales": 648.78,
              "GM": 246.99
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 51,
              "Subcategory": "Bebidas Alternativas",
              "Manufacturer": "ENERGY GROUP SRL",
              "EAN": 7798119220015,
              "Name": "EG Speed 250ml",
              "Price": "4.97",
              "Units": 135,
              "Sales": 553.92,
              "GM": 243.42
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3121,
              "Name": "PAVITA FRESH TM",
              "Price": "10.50",
              "Units": 60,
              "Sales": 520.78,
              "GM": 235.3
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 170,
              "Subcategory": "SMOKING ACCESSOIRES",
              "Manufacturer": "PURO TABACO",
              "EAN": 6902004095218,
              "Name": "PTBC Candela Encendedor Und",
              "Price": "1.30",
              "Units": 377,
              "Sales": 402.06,
              "GM": 233.22
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77916433,
              "Name": "PM Marlboro KS 20und",
              "Price": "3.93",
              "Units": 854,
              "Sales": 3132.6,
              "GM": 232.83
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 51,
              "Subcategory": "Bebidas Alternativas",
              "Manufacturer": "RED BULL",
              "EAN": 9002490100070,
              "Name": "RB Red Bull Lata 250ml",
              "Price": "5.88",
              "Units": 107,
              "Sales": 519.93,
              "GM": 232.74
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "OTHER",
              "EAN": 7798085680127,
              "Name": "EC Citric Jugo 500 Ml",
              "Price": "3.80",
              "Units": 160,
              "Sales": 502.4,
              "GM": 230.4
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7790315000439,
              "Name": "DNN Villavicencio Agua 1.5L",
              "Price": "3.40",
              "Units": 175,
              "Sales": 493.5,
              "GM": 228.19
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 179,
              "Subcategory": "NEWSPAPERS",
              "Manufacturer": "OTHER",
              "EAN": 7670,
              "Name": "Clarin Vier",
              "Price": "2.00",
              "Units": 113,
              "Sales": 226,
              "GM": 226
          },
          {
              "CategoryId": 86,
              "Category": "Comida Empacada",
              "SubcategoryId": 87,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "CANON",
              "EAN": 7795947008105,
              "Name": "CN Canon Triangulo Caprese",
              "Price": "9.25",
              "Units": 70,
              "Sales": 534.82,
              "GM": 221.92
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895007439,
              "Name": "Sprite Botella 600ml",
              "Price": "3.30",
              "Units": 178,
              "Sales": 485.9,
              "GM": 221.26
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77927279,
              "Name": "CAD BELDENT X CARE CONF MENT X50",
              "Price": "12.00",
              "Units": 48,
              "Sales": 476.13,
              "GM": 214.95
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "FERRERO",
              "EAN": 78909434,
              "Name": "FRR Ferrero Rocher Chocolate 37.",
              "Price": "3.60",
              "Units": 175,
              "Sales": 521.25,
              "GM": 212.79
          },
          {
              "CategoryId": 86,
              "Category": "Comida Empacada",
              "SubcategoryId": 87,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "CANON",
              "EAN": 7795947001878,
              "Name": "CN Canon Jam Queso y Muzzarella",
              "Price": "11.25",
              "Units": 52,
              "Sales": 483.6,
              "GM": 212.69
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "BUFFALO",
              "EAN": 7795070803165,
              "Name": "BFF Buffalo Escobilla C 16 PLUS",
              "Price": "14.10",
              "Units": 49,
              "Sales": 570.98,
              "GM": 211.83
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 164,
              "Subcategory": "BATTERIES",
              "Manufacturer": "ENERGIZER",
              "EAN": 39800014009,
              "Name": "ENZ Energizer Alcalina AAA 2",
              "Price": "6.00",
              "Units": 88,
              "Sales": 436.47,
              "GM": 208.55
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 209,
              "Subcategory": "OTHER CUISINE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3438,
              "Name": "EMPANADA CARNE x 1 UNIDAD",
              "Price": "2.60",
              "Units": 259,
              "Sales": 556.84,
              "GM": 201.35
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895062926,
              "Name": "COCA COLA BOTELLA 237ML",
              "Price": "2.30",
              "Units": 202,
              "Sales": 385.82,
              "GM": 199.99
          },
          {
              "CategoryId": 86,
              "Category": "Comida Empacada",
              "SubcategoryId": 87,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "CANON",
              "EAN": 7795947006002,
              "Name": "CN CaNon Pan Negro Jam y Ques Tr",
              "Price": "6.90",
              "Units": 75,
              "Sales": 427.5,
              "GM": 195.09
          },
          {
              "CategoryId": 86,
              "Category": "Comida Empacada",
              "SubcategoryId": 87,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "CANON",
              "EAN": 7795947001861,
              "Name": "CN Canon Roll Pollo",
              "Price": "11.75",
              "Units": 50,
              "Sales": 485.5,
              "GM": 191
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770328429005,
              "Name": "Noticias",
              "Price": "8.30",
              "Units": 23,
              "Sales": 190.9,
              "GM": 190.9
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895067549,
              "Name": "Coca Cola Zero Botella 600ml",
              "Price": "3.30",
              "Units": 153,
              "Sales": 420.75,
              "GM": 190.84
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3125,
              "Name": "SICILIANO TM",
              "Price": "9.25",
              "Units": 50,
              "Sales": 382.04,
              "GM": 188.04
          },
          {
              "CategoryId": 86,
              "Category": "Comida Empacada",
              "SubcategoryId": 87,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "CANON",
              "EAN": 7795947000239,
              "Name": "CN CaNon Super Milanesa",
              "Price": "7.40",
              "Units": 63,
              "Sales": 385.49,
              "GM": 187.12
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77900166,
              "Name": "CAD Beldent S/Azucar Menta 10gr",
              "Price": "1.30",
              "Units": 454,
              "Sales": 484.43,
              "GM": 186.17
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "OTHER",
              "EAN": 22000000675,
              "Name": "Hubba Bubba Chiclets S. W 42g",
              "Price": "3.80",
              "Units": 151,
              "Sales": 474.14,
              "GM": 183.23
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7790315058201,
              "Name": "DNN Villavicencio Sport Agua 750ml",
              "Price": "3.00",
              "Units": 138,
              "Sales": 343.62,
              "GM": 181.98
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 51,
              "Subcategory": "Bebidas Alternativas",
              "Manufacturer": "DANONE",
              "EAN": 7790315058195,
              "Name": "DAN Energizante V x 250 ML",
              "Price": "3.75",
              "Units": 112,
              "Sales": 347.2,
              "GM": 176.34
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77912879,
              "Name": "PM Philip Morris KS 20und",
              "Price": "3.43",
              "Units": 821,
              "Sales": 2619.74,
              "GM": 174.9
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895000447,
              "Name": "Sprite Botella 1.5L",
              "Price": "5.50",
              "Units": 91,
              "Sales": 414.96,
              "GM": 168.59
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895639821,
              "Name": "COCA COLA LIGHT F.GRIP 410ML",
              "Price": "2.70",
              "Units": 142,
              "Sales": 319.44,
              "GM": 168.16
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 8414090200400,
              "Name": "Casa Viva",
              "Price": "13.90",
              "Units": 12,
              "Sales": 166.8,
              "GM": 166.8
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77929877,
              "Name": "CAD BELDENT S/A MENTA FUERT",
              "Price": "1.30",
              "Units": 361,
              "Sales": 386.52,
              "GM": 166.45
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77912954,
              "Name": "PM Philip Morris Box 20und",
              "Price": "3.56",
              "Units": 719,
              "Sales": 2385.36,
              "GM": 165.64
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 203,
              "Subcategory": "HOT DOGS/GRILL PROD.",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3022,
              "Name": "Hot Dog Grande",
              "Price": "2.50",
              "Units": 149,
              "Sales": 308.23,
              "GM": 164.01
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 179,
              "Subcategory": "NEWSPAPERS",
              "Manufacturer": "OTHER",
              "EAN": 7648,
              "Name": "Clarin Dom",
              "Price": "4.80",
              "Units": 34,
              "Sales": 163.2,
              "GM": 163.2
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 190,
              "Subcategory": "ANTIFREEZE ETC.",
              "Manufacturer": "WAGNER BRAKE",
              "EAN": 7797409057867,
              "Name": "WAG Wagner Lockheed Refrg 1L",
              "Price": "30.33",
              "Units": 15,
              "Sales": 376.05,
              "GM": 159.48
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310980552,
              "Name": "FL Lays Clasicas 50gr",
              "Price": "2.40",
              "Units": 221,
              "Sales": 437.68,
              "GM": 159.22
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 77914217,
              "Name": "KR Shot Bloque 35gr",
              "Price": "1.90",
              "Units": 250,
              "Sales": 392.5,
              "GM": 152.38
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222041604,
              "Name": "BNFD Vizzio Almendra 80gr",
              "Price": "8.14",
              "Units": 36,
              "Sales": 242.11,
              "GM": 151.03
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770328856009,
              "Name": "Gente",
              "Price": "8.30",
              "Units": 18,
              "Sales": 149.4,
              "GM": 149.4
          },
          {
              "CategoryId": 86,
              "Category": "Comida Empacada",
              "SubcategoryId": 87,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "CANON",
              "EAN": 7795947000192,
              "Name": "CN CaNon Luneta Gigante J y Q 1u",
              "Price": "6.90",
              "Units": 55,
              "Sales": 313.52,
              "GM": 149.07
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77953124,
              "Name": "Cofler B38gr",
              "Price": "2.00",
              "Units": 205,
              "Sales": 338.59,
              "GM": 147.97
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 190,
              "Subcategory": "ANTIFREEZE ETC.",
              "Manufacturer": "GILIO HERMANOS",
              "EAN": 7793735000072,
              "Name": "GHN Ros Rig Agua Destilada 5L",
              "Price": "6.86",
              "Units": 60,
              "Sales": 340.41,
              "GM": 146.61
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300056506,
              "Name": "KR Milka Mjoy Almendras 70g",
              "Price": "4.40",
              "Units": 97,
              "Sales": 352.99,
              "GM": 143.8
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 60,
              "Subcategory": "Caramelos en bolsa",
              "Manufacturer": "ARCOR",
              "EAN": 7790580577001,
              "Name": "Sugus Confitados 50gr",
              "Price": "2.00",
              "Units": 200,
              "Sales": 330.23,
              "GM": 142.68
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3118,
              "Name": "PAMPA TM",
              "Price": "11.50",
              "Units": 27,
              "Sales": 256.52,
              "GM": 141.46
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 164,
              "Subcategory": "BATTERIES",
              "Manufacturer": "ENERGIZER",
              "EAN": 39800015464,
              "Name": "ENZ Energizer Pila Chica 2AA",
              "Price": "5.50",
              "Units": 62,
              "Sales": 282.08,
              "GM": 140.98
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77910066,
              "Name": "Lucky Strike Box 20und",
              "Price": "4.05",
              "Units": 517,
              "Sales": 1947.89,
              "GM": 138.46
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 207,
              "Subcategory": "BAKERY",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3729,
              "Name": "Sacramento",
              "Price": "1.30",
              "Units": 234,
              "Sales": 251.04,
              "GM": 138.26
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 193,
              "Subcategory": "OTHER ADDITIVES",
              "Manufacturer": "BARDAHL LUBRICANT",
              "EAN": 7790627112561,
              "Name": "BRDL TIR 500ml",
              "Price": "15.70",
              "Units": 28,
              "Sales": 363.43,
              "GM": 137.62
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 209,
              "Subcategory": "OTHER CUISINE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3439,
              "Name": "EMPANADA POLLO x 1 UNIDAD",
              "Price": "2.60",
              "Units": 175,
              "Sales": 376.25,
              "GM": 136.88
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 203,
              "Subcategory": "HOT DOGS/GRILL PROD.",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3023,
              "Name": "Pancho Wall Street",
              "Price": "5.00",
              "Units": 52,
              "Sales": 214.77,
              "GM": 136.59
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895005312,
              "Name": "Coca Cola Light 2.25L",
              "Price": "6.20",
              "Units": 67,
              "Sales": 346.45,
              "GM": 135.1
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 218,
              "Subcategory": "HOT CHOCOLATE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2029,
              "Name": "Submarino",
              "Price": "6.20",
              "Units": 33,
              "Sales": 169,
              "GM": 134.02
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895063091,
              "Name": "Fanta Naranja 600ml",
              "Price": "3.30",
              "Units": 107,
              "Sales": 292.1,
              "GM": 132.76
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77927262,
              "Name": "CADBELDENTX CARE CONF SPERARMIN",
              "Price": "2.30",
              "Units": 162,
              "Sales": 307.81,
              "GM": 131.46
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77917843,
              "Name": "PM Marlboro Box 10und",
              "Price": "2.03",
              "Units": 1017,
              "Sales": 1925.01,
              "GM": 130.89
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954055660,
              "Name": "SFA Filtros Fram PH-5566",
              "Price": "24.18",
              "Units": 12,
              "Sales": 239.78,
              "GM": 129.69
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3112,
              "Name": "GAUCHO TM",
              "Price": "11.50",
              "Units": 31,
              "Sales": 294.57,
              "GM": 128.53
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 190,
              "Subcategory": "ANTIFREEZE ETC.",
              "Manufacturer": "REVIGAL",
              "EAN": 7790911000307,
              "Name": "RVG Lava Parabrisas 580ml",
              "Price": "9.01",
              "Units": 43,
              "Sales": 320.39,
              "GM": 128.02
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 164,
              "Subcategory": "BATTERIES",
              "Manufacturer": "ENERGIZER",
              "EAN": 7798087660721,
              "Name": "ENZ Energizer Pila Alcalina AA 4",
              "Price": "10.00",
              "Units": 32,
              "Sales": 264.36,
              "GM": 125.48
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548310,
              "Name": "DNN Ser Agua Plus Citrus 500ml",
              "Price": "3.10",
              "Units": 100,
              "Sales": 256.95,
              "GM": 124.68
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "KRAFT",
              "EAN": 7790114014323,
              "Name": "KR SHOT TABLETA 200GR",
              "Price": "9.00",
              "Units": 40,
              "Sales": 297.6,
              "GM": 124.32
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "FELFORT",
              "EAN": 77907509,
              "Name": "FLFR DOS CORAZONES 26GR",
              "Price": "1.76",
              "Units": 211,
              "Sales": 306.13,
              "GM": 121.68
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 216,
              "Subcategory": "COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2042,
              "Name": "Cafe Americano Grande",
              "Price": "3.50",
              "Units": 66,
              "Sales": 190.8,
              "GM": 121.34
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310981221,
              "Name": "FL Twistos Jamon 110g",
              "Price": "4.99",
              "Units": 69,
              "Sales": 284.34,
              "GM": 121.28
          },
          {
              "CategoryId": 86,
              "Category": "Comida Empacada",
              "SubcategoryId": 87,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "CANON",
              "EAN": 7795947001885,
              "Name": "CN Canon Roll Peceto",
              "Price": "12.75",
              "Units": 30,
              "Sales": 316.17,
              "GM": 121.17
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771514607016,
              "Name": "Living",
              "Price": "12.00",
              "Units": 10,
              "Sales": 120,
              "GM": 120
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 216,
              "Subcategory": "COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2041,
              "Name": "Cafe Americano Chico",
              "Price": "3.30",
              "Units": 65,
              "Sales": 177.39,
              "GM": 119.03
          },
          {
              "CategoryId": 86,
              "Category": "Comida Empacada",
              "SubcategoryId": 87,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "CANON",
              "EAN": 7795947000222,
              "Name": "CN CaNon Pebete S y Q 1und",
              "Price": "6.50",
              "Units": 44,
              "Sales": 236.28,
              "GM": 118.87
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 190,
              "Subcategory": "ANTIFREEZE ETC.",
              "Manufacturer": "WAGNER BRAKE",
              "EAN": 7797409057881,
              "Name": "WAG Wagner Lockh Refrg Anticong 500ml",
              "Price": "17.74",
              "Units": 19,
              "Sales": 278.57,
              "GM": 118.32
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310980569,
              "Name": "FL LAYS CLASICAS 80GRS",
              "Price": "3.90",
              "Units": 101,
              "Sales": 325.24,
              "GM": 118.19
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77910981,
              "Name": "Camel Filters Box 20und",
              "Price": "4.04",
              "Units": 455,
              "Sales": 1709.96,
              "GM": 117.31
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895064302,
              "Name": "Cepita Naranja 250ml",
              "Price": "1.70",
              "Units": 158,
              "Sales": 222.77,
              "GM": 116.03
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062547436,
              "Name": "DNN Villa del Sur Agua 1.5L",
              "Price": "3.10",
              "Units": 101,
              "Sales": 259.6,
              "GM": 115.14
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 208,
              "Subcategory": "SOUP & SALAD",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 7795947001984,
              "Name": "Ensalada Primavera 200gr",
              "Price": "6.75",
              "Units": 70,
              "Sales": 472.5,
              "GM": 113.79
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7791249020067,
              "Name": "CAD CADBURY FRUTILLA 27GR",
              "Price": "1.80",
              "Units": 163,
              "Sales": 242.8,
              "GM": 112.01
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "OTHER",
              "EAN": 7798085680141,
              "Name": "EC Citric Jugo 1 Lt",
              "Price": "5.90",
              "Units": 56,
              "Sales": 273.25,
              "GM": 111.97
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "FERRERO",
              "EAN": 80177609,
              "Name": "FRR KINDER CHOCOLATE 50GR",
              "Price": "4.00",
              "Units": 89,
              "Sales": 294.51,
              "GM": 110.88
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 210,
              "Subcategory": "FRENCH FRIES/ WEDGES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3092,
              "Name": "Papas Fritas Baston",
              "Price": "2.90",
              "Units": 71,
              "Sales": 170.33,
              "GM": 109.5
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170061013,
              "Name": "Gatorade Sport 600ml Frutas Trop",
              "Price": "5.90",
              "Units": 51,
              "Sales": 248.85,
              "GM": 109.14
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77971524,
              "Name": "CAD BELDENT S/AZUCAR MENTOL TURB",
              "Price": "1.30",
              "Units": 235,
              "Sales": 251.65,
              "GM": 108.67
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310980576,
              "Name": "FL Lays Clasicas 150gr",
              "Price": "5.90",
              "Units": 60,
              "Sales": 292.79,
              "GM": 105.61
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813420118,
              "Name": "Pepsi Botella 500ml",
              "Price": "3.10",
              "Units": 82,
              "Sales": 211.56,
              "GM": 104.86
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813423119,
              "Name": "Pepsi Pet 2250ml",
              "Price": "5.80",
              "Units": 45,
              "Sales": 217.35,
              "GM": 103.69
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "FERRERO",
              "EAN": 7861002900117,
              "Name": "FRR Ferrero Rocher 100Gr",
              "Price": "12.50",
              "Units": 28,
              "Sales": 289.24,
              "GM": 103.11
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548679,
              "Name": "DNN Villa del Sur Agua Pomelo 1.5L",
              "Price": "4.90",
              "Units": 64,
              "Sales": 259.89,
              "GM": 102.75
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 207,
              "Subcategory": "BAKERY",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3077,
              "Name": "Cuernito Unidad",
              "Price": "0.75",
              "Units": 266,
              "Sales": 164.92,
              "GM": 102.72
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895067556,
              "Name": "Coca Cola Zero 1.5L",
              "Price": "5.50",
              "Units": 60,
              "Sales": 274.2,
              "GM": 102.35
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 179,
              "Subcategory": "NEWSPAPERS",
              "Manufacturer": "OTHER",
              "EAN": 7610,
              "Name": "Ajuste Diarios",
              "Price": "0.10",
              "Units": 1021,
              "Sales": 102.1,
              "GM": 102.1
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310981245,
              "Name": "FL Twistos Queso 110g",
              "Price": "4.99",
              "Units": 59,
              "Sales": 243.14,
              "GM": 101.67
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "OTHER",
              "EAN": 40000000518,
              "Name": "MAR STARBURST 58,7G",
              "Price": "3.00",
              "Units": 105,
              "Sales": 260.4,
              "GM": 100.93
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "OTHER",
              "EAN": 77922106,
              "Name": "Alf Cachafaz Maicena",
              "Price": "2.22",
              "Units": 147,
              "Sales": 270.13,
              "GM": 100.66
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895000454,
              "Name": "Fanta Naranja Botella 1.5L",
              "Price": "5.50",
              "Units": 54,
              "Sales": 246.78,
              "GM": 100.28
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895640025,
              "Name": " Powerade Mountain Blast 0,5 lts",
              "Price": "3.25",
              "Units": 93,
              "Sales": 250.12,
              "GM": 100
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "ARCOR",
              "EAN": 77935274,
              "Name": "Cereal Mix Frutillas Yoghurt",
              "Price": "1.40",
              "Units": 227,
              "Sales": 262.85,
              "GM": 99.98
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548709,
              "Name": "DNN Villa del Sur Agua Manzana 1.5L",
              "Price": "4.90",
              "Units": 65,
              "Sales": 263.93,
              "GM": 99.79
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770328430001,
              "Name": "Caras",
              "Price": "8.30",
              "Units": 12,
              "Sales": 99.6,
              "GM": 99.6
          },
          {
              "CategoryId": 86,
              "Category": "Comida Empacada",
              "SubcategoryId": 87,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "CANON",
              "EAN": 7795947001915,
              "Name": "CN CANON PANINI CAPRESE",
              "Price": "10.25",
              "Units": 20,
              "Sales": 169.4,
              "GM": 99.2
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 179,
              "Subcategory": "NEWSPAPERS",
              "Manufacturer": "OTHER",
              "EAN": 7649,
              "Name": "La Nacion Dom",
              "Price": "4.50",
              "Units": 22,
              "Sales": 99,
              "GM": 99
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "PAULISTA",
              "EAN": 77991430,
              "Name": "PLT Biznique Nevado 28gr",
              "Price": "1.76",
              "Units": 150,
              "Sales": 218.2,
              "GM": 98.49
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770328824008,
              "Name": "Cosmopolitan",
              "Price": "7.50",
              "Units": 13,
              "Sales": 97.5,
              "GM": 97.5
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "DON SATUR",
              "EAN": 7795735000328,
              "Name": "DST Don Satur Bizcocho Salado 20",
              "Price": "2.30",
              "Units": 128,
              "Sales": 243.2,
              "GM": 96.84
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "FERRERO",
              "EAN": 78600010,
              "Name": "FRR Tic Tac Menta 16gr",
              "Price": "1.30",
              "Units": 191,
              "Sales": 204.6,
              "GM": 96.8
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "FERRERO",
              "EAN": 80052487,
              "Name": "FER Raffaello 30g",
              "Price": "3.40",
              "Units": 92,
              "Sales": 258.52,
              "GM": 96.39
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7790315058225,
              "Name": "DNN Ser Vidactiv 715ml",
              "Price": "3.30",
              "Units": 77,
              "Sales": 210.16,
              "GM": 96.3
          },
          {
              "CategoryId": 65,
              "Category": "Leches",
              "SubcategoryId": 70,
              "Subcategory": "FLAVORED  MILK",
              "Manufacturer": "DANONE",
              "EAN": 7793940198007,
              "Name": "DNN Cindor Larga Duracion 250ml",
              "Price": "2.37",
              "Units": 172,
              "Sales": 336.93,
              "GM": 95.27
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 207,
              "Subcategory": "BAKERY",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3735,
              "Name": "Entrerriano",
              "Price": "1.30",
              "Units": 133,
              "Sales": 142.7,
              "GM": 95.12
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895064159,
              "Name": "Sprite Zero 600ml",
              "Price": "3.30",
              "Units": 76,
              "Sales": 207.47,
              "GM": 93.59
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895063107,
              "Name": "Fanta Naranja Light 600ml",
              "Price": "3.30",
              "Units": 74,
              "Sales": 202.02,
              "GM": 92.62
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300056483,
              "Name": "KR Milka Mjoy Leche 70g",
              "Price": "4.40",
              "Units": 61,
              "Sales": 221.94,
              "GM": 90.38
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770328438007,
              "Name": "Parabrisas",
              "Price": "9.00",
              "Units": 10,
              "Sales": 90,
              "GM": 90
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222041444,
              "Name": "BNFD Bonafide Bocad dulc lech 16G",
              "Price": "0.94",
              "Units": 252,
              "Sales": 196.21,
              "GM": 87.72
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 207,
              "Subcategory": "BAKERY",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3732,
              "Name": "Palmerita Con Azucar",
              "Price": "1.30",
              "Units": 125,
              "Sales": 134.04,
              "GM": 86.74
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 156,
              "Subcategory": "PRESERVATIVOS",
              "Manufacturer": "LOC",
              "EAN": 7791519200069,
              "Name": "LOC Prime Preservativos Textura 3 Un",
              "Price": "4.60",
              "Units": 54,
              "Sales": 205.2,
              "GM": 86.7
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548716,
              "Name": "DNN Villa del Sur Agua Manzana 500ml",
              "Price": "3.10",
              "Units": 69,
              "Sales": 177.33,
              "GM": 86.68
          },
          {
              "CategoryId": 86,
              "Category": "Comida Empacada",
              "SubcategoryId": 87,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "CANON",
              "EAN": 7795947001892,
              "Name": "CN CANON PAN DE CAMPO",
              "Price": "7.25",
              "Units": 28,
              "Sales": 167.73,
              "GM": 86.53
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7793940135217,
              "Name": "DNN Yogurisimo cereal zucaritas 185gr",
              "Price": "2.48",
              "Units": 172,
              "Sales": 352.21,
              "GM": 85.98
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770328850007,
              "Name": "Para Ti",
              "Price": "7.80",
              "Units": 11,
              "Sales": 85.8,
              "GM": 85.8
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548655,
              "Name": "DNN Villa del Sur Agua Naranja 500ml",
              "Price": "3.10",
              "Units": 69,
              "Sales": 177.33,
              "GM": 85.53
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "FERRERO",
              "EAN": 80052760,
              "Name": "FRR KINDER BUENO 43GR",
              "Price": "4.00",
              "Units": 68,
              "Sales": 224.98,
              "GM": 84.62
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "BUFFALO",
              "EAN": 7795070603154,
              "Name": "BFF Buffalo Escobilla C 15 PL 1und",
              "Price": "15.70",
              "Units": 15,
              "Sales": 194.69,
              "GM": 83.1
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "OTHER",
              "EAN": 22000001726,
              "Name": "WR WRIGLEYS DOUBLEMINT 15G",
              "Price": "1.70",
              "Units": 137,
              "Sales": 192.02,
              "GM": 82.74
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 174,
              "Subcategory": "SEASONAL",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7797871983541,
              "Name": "TPS Carbon Bolsa Pack 4Kg",
              "Price": "11.00",
              "Units": 31,
              "Sales": 281.79,
              "GM": 82.17
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 77939548,
              "Name": "Aguila Hel Barrita 49ml",
              "Price": "2.30",
              "Units": 114,
              "Sales": 216.61,
              "GM": 82.01
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77916983,
              "Name": "CAD Beldent S/Azucar Frutas Roja",
              "Price": "1.30",
              "Units": 177,
              "Sales": 189.54,
              "GM": 81.91
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "BUFFALO",
              "EAN": 7795070000229,
              "Name": "BFF Buffalo C 22 1und",
              "Price": "25.40",
              "Units": 8,
              "Sales": 167.92,
              "GM": 81.68
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "QUILMES",
              "EAN": 7792799000011,
              "Name": "QLMS Eco de los Andes Agua 500ml",
              "Price": "2.20",
              "Units": 94,
              "Sales": 171.15,
              "GM": 81.01
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813420248,
              "Name": "H2OH CITRUS 500ML",
              "Price": "2.60",
              "Units": 70,
              "Sales": 151.19,
              "GM": 80.91
          },
          {
              "CategoryId": 86,
              "Category": "Comida Empacada",
              "SubcategoryId": 87,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "CANON",
              "EAN": 7795947001908,
              "Name": "CN CANON PANINI PRIMAVERA",
              "Price": "10.25",
              "Units": 20,
              "Sales": 169.4,
              "GM": 80.6
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548648,
              "Name": "DNN Villa del Sur Agua Naranja 1.5L",
              "Price": "4.90",
              "Units": 51,
              "Sales": 207.57,
              "GM": 80.59
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170042241,
              "Name": "GATORADE PET UVA FIERCE 500CC",
              "Price": "4.90",
              "Units": 45,
              "Sales": 182.25,
              "GM": 79.27
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 209,
              "Subcategory": "OTHER CUISINE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3440,
              "Name": "EMPANADA QUESO Y CEBOLLA x 1 U",
              "Price": "2.60",
              "Units": 96,
              "Sales": 206.4,
              "GM": 79.02
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77913418,
              "Name": "PM Philip Morris Box 10und",
              "Price": "1.79",
              "Units": 701,
              "Sales": 1172.42,
              "GM": 78.61
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170002054,
              "Name": "Gatorade Manzana 473ml",
              "Price": "4.40",
              "Units": 42,
              "Sales": 152.84,
              "GM": 77.09
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "OTHER",
              "EAN": 22000001733,
              "Name": "WR WRIGLEYS JUICY FRUIT 15G",
              "Price": "1.70",
              "Units": 125,
              "Sales": 175.13,
              "GM": 76.2
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 6520,
              "Name": "TPS Bidon de Plastico Und",
              "Price": "3.50",
              "Units": 32,
              "Sales": 92.52,
              "GM": 75.7
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 191,
              "Subcategory": "BRAKE FLUIDS",
              "Manufacturer": "WAGNER BRAKE",
              "EAN": 7797409093605,
              "Name": "WAG DOT3 Wagner Liq Fren 200ml",
              "Price": "9.00",
              "Units": 25,
              "Sales": 186,
              "GM": 74.94
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77912701,
              "Name": "CAD Halls Caramelos Strong 28G",
              "Price": "1.20",
              "Units": 168,
              "Sales": 166.37,
              "GM": 74.13
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77925794,
              "Name": "MENTHOPL BLIZ SA0.6GMEN",
              "Price": "3.70",
              "Units": 61,
              "Sales": 186.65,
              "GM": 73.66
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "FERRERO",
              "EAN": 78600027,
              "Name": "FRR Tic Tac Naranja 16gr",
              "Price": "1.30",
              "Units": 145,
              "Sales": 155.33,
              "GM": 73.55
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77995162,
              "Name": "CAD Beldent S/Azucar Polar 10gr",
              "Price": "1.20",
              "Units": 184,
              "Sales": 182.92,
              "GM": 73.32
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 74570610051,
              "Name": "GMA Haagen Pints Belgian Choc 473ml",
              "Price": "23.90",
              "Units": 10,
              "Sales": 197.5,
              "GM": 73.31
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "OTHER",
              "EAN": 40000001607,
              "Name": "MAS Skittles Origi Fruit 61.5g",
              "Price": "3.00",
              "Units": 76,
              "Sales": 188.48,
              "GM": 73.01
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 219,
              "Subcategory": "CAPPUCC./SPEC.COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2044,
              "Name": "CAFE AMERICANO CAPUCCINO GRANDE",
              "Price": "4.00",
              "Units": 32,
              "Sales": 105.89,
              "GM": 72.96
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "KRAFT",
              "EAN": 77903792,
              "Name": "KR Terrabusi Triple Alfajor 70gr",
              "Price": "2.30",
              "Units": 72,
              "Sales": 136.82,
              "GM": 72.37
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954677305,
              "Name": "SFA Filtros Fram PH-4701",
              "Price": "19.54",
              "Units": 8,
              "Sales": 129.16,
              "GM": 72
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813421528,
              "Name": "7 UP 1.5L",
              "Price": "5.40",
              "Units": 35,
              "Sales": 156.8,
              "GM": 71.96
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 3415583272275,
              "Name": "GMA Haagen Dazs Dulce de Leche 100ml",
              "Price": "8.50",
              "Units": 24,
              "Sales": 168.53,
              "GM": 70.94
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 207,
              "Subcategory": "BAKERY",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3727,
              "Name": "Medialuna De Grasa",
              "Price": "1.30",
              "Units": 114,
              "Sales": 122.33,
              "GM": 69.89
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "GEORGIA",
              "EAN": 7797212000012,
              "Name": "GRGA Matafuego Polvo 1kg",
              "Price": "92.00",
              "Units": 2,
              "Sales": 152.06,
              "GM": 69.77
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 216,
              "Subcategory": "COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2064,
              "Name": "Cafe Doble Cortado c Leche To Go",
              "Price": "4.70",
              "Units": 24,
              "Sales": 93.14,
              "GM": 69.47
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 149,
              "Subcategory": "ANALGESICOS",
              "Manufacturer": "BAYER",
              "EAN": 9802,
              "Name": "BY Bayaspirina Tira 10und",
              "Price": "2.51",
              "Units": 52,
              "Sales": 130.5,
              "GM": 69.26
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770329229000,
              "Name": "Pronto Semanal",
              "Price": "4.33",
              "Units": 16,
              "Sales": 69.2,
              "GM": 69.2
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7798002419366,
              "Name": "CAD BELDENT SPLASH FRUT LIMA 18G",
              "Price": "3.00",
              "Units": 84,
              "Sales": 208.32,
              "GM": 68.98
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "QUILMES",
              "EAN": 7792799000059,
              "Name": "QLMS Eco de los Andes Agua 1.5L",
              "Price": "3.20",
              "Units": 50,
              "Sales": 132.96,
              "GM": 68.53
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "FELFORT",
              "EAN": 77909008,
              "Name": "FLFR Cereal Fort Fel-F 23gr",
              "Price": "1.50",
              "Units": 130,
              "Sales": 161.2,
              "GM": 68.53
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77913685,
              "Name": "PM Marlboro Light Box 20und",
              "Price": "4.07",
              "Units": 243,
              "Sales": 922.79,
              "GM": 68.37
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 193,
              "Subcategory": "OTHER ADDITIVES",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 77910226,
              "Name": "TPS Bardahl Top Adit P/Gas Oil 250ml",
              "Price": "9.40",
              "Units": 12,
              "Sales": 93.24,
              "GM": 68.25
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954028701,
              "Name": "SFA Filtros Fram PH-2870A",
              "Price": "20.59",
              "Units": 9,
              "Sales": 153.19,
              "GM": 68.01
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770328186007,
              "Name": "Dis y Deco",
              "Price": "17.00",
              "Units": 4,
              "Sales": 68,
              "GM": 68
          },
          {
              "CategoryId": 65,
              "Category": "Leches",
              "SubcategoryId": 70,
              "Subcategory": "FLAVORED  MILK",
              "Manufacturer": "DANONE",
              "EAN": 7793940192005,
              "Name": "DNN Cindor Larga Duracion 1L",
              "Price": "4.77",
              "Units": 63,
              "Sales": 248.02,
              "GM": 67.29
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170042258,
              "Name": "GATORADE PET COOL BLUE 500CC",
              "Price": "4.90",
              "Units": 38,
              "Sales": 153.9,
              "GM": 67.26
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 7797674049420,
              "Name": "GMA Nature Valley Almend Tostadas 21g",
              "Price": "1.52",
              "Units": 130,
              "Sales": 162.89,
              "GM": 67.19
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895640018,
              "Name": " Powerade Manzana 0,5 lts",
              "Price": "3.25",
              "Units": 57,
              "Sales": 153.31,
              "GM": 66.09
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170002061,
              "Name": "Gatorade Frutas Tropicales 473ml",
              "Price": "4.40",
              "Units": 36,
              "Sales": 131,
              "GM": 65.98
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 74570024001,
              "Name": "GMA Haag D Pints Strawberry 473ml",
              "Price": "23.90",
              "Units": 9,
              "Sales": 177.75,
              "GM": 65.79
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310980774,
              "Name": "FL  3D MEGA QUESO 85G",
              "Price": "3.90",
              "Units": 56,
              "Sales": 180.36,
              "GM": 65.56
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813420521,
              "Name": "7 UP Botella 500ml",
              "Price": "3.10",
              "Units": 53,
              "Sales": 136.21,
              "GM": 65.54
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7798002419380,
              "Name": "CAD BELDENT SPLASH MANZFRAM 18G",
              "Price": "3.00",
              "Units": 81,
              "Sales": 200.88,
              "GM": 65.54
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "BUFFALO",
              "EAN": 7795070303719,
              "Name": "BFF Buffalo Escobilla C 17 PL 1und",
              "Price": "15.30",
              "Units": 16,
              "Sales": 202.26,
              "GM": 65.27
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "DON SATUR",
              "EAN": 7795735000335,
              "Name": "DST Don Satur Bizcocho Dulce 200",
              "Price": "2.30",
              "Units": 84,
              "Sales": 159.6,
              "GM": 65.23
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "JORGITO",
              "EAN": 77905741,
              "Name": "JRG Jorgito Alfajor Chocolate 55",
              "Price": "1.30",
              "Units": 151,
              "Sales": 161.9,
              "GM": 65.14
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77912732,
              "Name": "CAD HALLS CHERRY-IYPTUS 28GR",
              "Price": "1.20",
              "Units": 147,
              "Sales": 145.54,
              "GM": 64.81
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "ARCOR",
              "EAN": 77916525,
              "Name": "Cereal Mix Chocolate 23gr",
              "Price": "1.30",
              "Units": 153,
              "Sales": 163.99,
              "GM": 64.55
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222041055,
              "Name": "BNFD NUGATON LECHE 27GR",
              "Price": "1.21",
              "Units": 130,
              "Sales": 130.02,
              "GM": 63.94
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300056568,
              "Name": "KR Milka Mjoy Choc Crunchy 70g",
              "Price": "4.40",
              "Units": 43,
              "Sales": 156.49,
              "GM": 63.78
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "FELFORT",
              "EAN": 77922267,
              "Name": "FLFR Refresco 38gr",
              "Price": "1.50",
              "Units": 132,
              "Sales": 163.68,
              "GM": 63.59
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 156,
              "Subcategory": "PRESERVATIVOS",
              "Manufacturer": "BUHL",
              "EAN": 7791519200021,
              "Name": "BUHL Prime Anatomico 3u",
              "Price": "4.84",
              "Units": 35,
              "Sales": 140,
              "GM": 63.34
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771514001005,
              "Name": "Auto Test",
              "Price": "7.90",
              "Units": 8,
              "Sales": 63.2,
              "GM": 63.2
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300179434,
              "Name": "SHOT CRUNCHY 35G",
              "Price": "1.90",
              "Units": 106,
              "Sales": 166.42,
              "GM": 63.17
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "UNILEVER",
              "EAN": 7794000730014,
              "Name": "UNI Ades Manzana 1L",
              "Price": "5.30",
              "Units": 34,
              "Sales": 149.26,
              "GM": 62.92
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040931206,
              "Name": "BGLY Sonrisas Nutrileche Framb x 118g",
              "Price": "2.70",
              "Units": 67,
              "Sales": 149.41,
              "GM": 62.81
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300108892,
              "Name": "KR PEPITOS CHOCOLATE 126G",
              "Price": "2.50",
              "Units": 66,
              "Sales": 136.59,
              "GM": 62.77
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77925817,
              "Name": "Menthoplus Bliz s Az Mentol",
              "Price": "3.70",
              "Units": 53,
              "Sales": 162.18,
              "GM": 62.56
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 77916112,
              "Name": "KR Milka Leger Almendras 45gr",
              "Price": "3.00",
              "Units": 68,
              "Sales": 168.63,
              "GM": 62.47
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77930002,
              "Name": "Bon o Bon Leche 17gr",
              "Price": "0.70",
              "Units": 280,
              "Sales": 162.29,
              "GM": 61.93
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895064173,
              "Name": "Sprite Zero 1.5L",
              "Price": "5.50",
              "Units": 34,
              "Sales": 156.44,
              "GM": 61.82
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813423522,
              "Name": "7Up Pet 2250",
              "Price": "5.80",
              "Units": 34,
              "Sales": 163.54,
              "GM": 61.17
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170042135,
              "Name": "Gatorade Propel Limon  0.5Lts",
              "Price": "4.90",
              "Units": 32,
              "Sales": 129.6,
              "GM": 61.13
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 207,
              "Subcategory": "BAKERY",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3702,
              "Name": "MUFFIN CHOCOLATE",
              "Price": "2.60",
              "Units": 67,
              "Sales": 144.05,
              "GM": 61
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77927255,
              "Name": "CAD BELDENT X CARE CONF MENTA",
              "Price": "2.30",
              "Units": 75,
              "Sales": 142.5,
              "GM": 60.9
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77905291,
              "Name": "CAD STANI MIEL CON MENTA 28GR",
              "Price": "1.10",
              "Units": 166,
              "Sales": 151.06,
              "GM": 60.84
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 37634136744,
              "Name": "National Geographic",
              "Price": "7.50",
              "Units": 8,
              "Sales": 60,
              "GM": 60
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77950031,
              "Name": "COFLER CHOC LECH MANI BLOCK",
              "Price": "5.30",
              "Units": 32,
              "Sales": 140.16,
              "GM": 59.96
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 5037,
              "Name": "Panino J&Q TM",
              "Price": "7.00",
              "Units": 17,
              "Sales": 98.4,
              "GM": 59.74
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "PEPSI",
              "EAN": 7790017400001,
              "Name": "Tropicana Naranja 200ml",
              "Price": "1.60",
              "Units": 86,
              "Sales": 114.38,
              "GM": 59.6
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770329565016,
              "Name": "Rolling Stone",
              "Price": "8.50",
              "Units": 7,
              "Sales": 59.5,
              "GM": 59.5
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7794600009435,
              "Name": "KR Oreo 111gr",
              "Price": "2.50",
              "Units": 71,
              "Sales": 146.9,
              "GM": 58.83
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 179,
              "Subcategory": "NEWSPAPERS",
              "Manufacturer": "OTHER",
              "EAN": 7633,
              "Name": "LA NACION VIERNES",
              "Price": "2.10",
              "Units": 28,
              "Sales": 58.8,
              "GM": 58.8
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7891455313370,
              "Name": "SFA Filtros Fram PH-4967",
              "Price": "62.37",
              "Units": 2,
              "Sales": 103.1,
              "GM": 58.4
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548303,
              "Name": "DNN AGUA SER PLUS CITRUS 1.5 L",
              "Price": "4.90",
              "Units": 32,
              "Sales": 129.92,
              "GM": 58.02
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "ARCOR",
              "EAN": 77955272,
              "Name": "Arcor Ser Alf Dulce Leche 42gr",
              "Price": "1.80",
              "Units": 95,
              "Sales": 141.5,
              "GM": 57.88
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170042005,
              "Name": "GATORADE PET MAN. 500CC",
              "Price": "4.90",
              "Units": 33,
              "Sales": 133.65,
              "GM": 57.87
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 207,
              "Subcategory": "BAKERY",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3736,
              "Name": "MUFFIN VAINILLA Y DDL",
              "Price": "2.60",
              "Units": 65,
              "Sales": 139.75,
              "GM": 57.81
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "KRAFT",
              "EAN": 7622300190361,
              "Name": "KR EXPR CAPRICH PARMALBAH 100G",
              "Price": "2.90",
              "Units": 64,
              "Sales": 153.59,
              "GM": 57.56
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77914064,
              "Name": "PM Marlboro Box 16und",
              "Price": "3.30",
              "Units": 256,
              "Sales": 787.57,
              "GM": 57.5
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310979921,
              "Name": "FL LAYS MEDI JAM SERRANO 65GR",
              "Price": "3.90",
              "Units": 49,
              "Sales": 157.79,
              "GM": 57.34
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "CADBURYADAMS",
              "EAN": 7791249997048,
              "Name": "CAD CADBURY YOGURT AIREADO 50G",
              "Price": "4.50",
              "Units": 37,
              "Sales": 137.64,
              "GM": 57.05
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770329219001,
              "Name": "Mega Autos",
              "Price": "9.50",
              "Units": 6,
              "Sales": 57,
              "GM": 57
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548785,
              "Name": "DDNN SER SPORT MANZANA  600ML",
              "Price": "4.16",
              "Units": 41,
              "Sales": 140.86,
              "GM": 56.56
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 164,
              "Subcategory": "BATTERIES",
              "Manufacturer": "ENERGIZER",
              "EAN": 39800013613,
              "Name": "ENZ Energizer Bateria 9 Voltios",
              "Price": "9.50",
              "Units": 15,
              "Sales": 117.76,
              "GM": 56.41
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895001369,
              "Name": "Quatro Botella 500ml",
              "Price": "3.30",
              "Units": 44,
              "Sales": 120.12,
              "GM": 56.14
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77926470,
              "Name": "CAD BELDENT X CARE COOL BUBBLE",
              "Price": "1.30",
              "Units": 118,
              "Sales": 126.36,
              "GM": 56.14
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "JORGITO",
              "EAN": 77901729,
              "Name": "JRG Jorgelin Maxialfajor Negro 85gr",
              "Price": "1.90",
              "Units": 94,
              "Sales": 147.58,
              "GM": 56.08
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580395803,
              "Name": "Arcor Cofler Almendras 80ml",
              "Price": "4.00",
              "Units": 45,
              "Sales": 148.94,
              "GM": 56
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548372,
              "Name": "DNN Ser Agua Plus Naranja Dur 0.5L",
              "Price": "3.10",
              "Units": 44,
              "Sales": 113.08,
              "GM": 55.97
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771139529007,
              "Name": "Casa y Campo",
              "Price": "13.90",
              "Units": 4,
              "Sales": 55.6,
              "GM": 55.6
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7791249010730,
              "Name": "CAD Cadbury Tres Suenos 24gr",
              "Price": "1.80",
              "Units": 81,
              "Sales": 120.61,
              "GM": 55.59
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "MARS",
              "EAN": 7896423421507,
              "Name": "MARS MM Mani 40 Gr",
              "Price": "2.80",
              "Units": 58,
              "Sales": 134.07,
              "GM": 55.57
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082741098,
              "Name": "TM Tia Maruca Fajitas 150gr",
              "Price": "3.50",
              "Units": 45,
              "Sales": 130.07,
              "GM": 55.43
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 156,
              "Subcategory": "PRESERVATIVOS",
              "Manufacturer": "BUHL",
              "EAN": 7791519200076,
              "Name": "BUHL Prime Esper 3u",
              "Price": "4.78",
              "Units": 31,
              "Sales": 122.55,
              "GM": 55.37
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770329832002,
              "Name": "Veintitres",
              "Price": "6.90",
              "Units": 8,
              "Sales": 55.2,
              "GM": 55.2
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 60,
              "Subcategory": "Caramelos en bolsa",
              "Manufacturer": "ARCOR",
              "EAN": 7790580055806,
              "Name": "Sugus Evolution 50 gr",
              "Price": "2.00",
              "Units": 75,
              "Sales": 123.83,
              "GM": 55.06
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170002009,
              "Name": "Gatorade Pomelo 473ml",
              "Price": "4.40",
              "Units": 30,
              "Sales": 109.2,
              "GM": 54.93
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813421115,
              "Name": "Pepsi Cola 1.5L",
              "Price": "5.40",
              "Units": 29,
              "Sales": 130.5,
              "GM": 54.7
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "OTHER",
              "EAN": 78916401,
              "Name": "PRO  Mentos Fruit 29.7Gr",
              "Price": "1.40",
              "Units": 110,
              "Sales": 127.51,
              "GM": 54.68
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 3415581533279,
              "Name": "GMA Haagen Dazs Belgian Choco 100ml",
              "Price": "8.50",
              "Units": 22,
              "Sales": 154.44,
              "GM": 54.55
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "ARCOR",
              "EAN": 77935144,
              "Name": "Cereal Mix Vain Yogurt 28g",
              "Price": "1.40",
              "Units": 123,
              "Sales": 142.43,
              "GM": 54.46
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895001345,
              "Name": "Quatro Botella 1.5L",
              "Price": "5.50",
              "Units": 29,
              "Sales": 132.53,
              "GM": 54.18
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 8480002029049,
              "Name": "El Mueble",
              "Price": "13.50",
              "Units": 4,
              "Sales": 54,
              "GM": 54
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "BUFFALO",
              "EAN": 7795070503140,
              "Name": "BFF Buffalo Escobilla C 14 PLUS 1und",
              "Price": "15.40",
              "Units": 10,
              "Sales": 127.3,
              "GM": 53.94
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 1111,
              "Name": "Cabsha Bocadito 10gr",
              "Price": "0.60",
              "Units": 223,
              "Sales": 110.93,
              "GM": 53.56
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "KRAFT",
              "EAN": 77995681,
              "Name": "KR Rhodesia 22gr",
              "Price": "1.00",
              "Units": 153,
              "Sales": 126.57,
              "GM": 53.25
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062547801,
              "Name": "DNN Villavicencio Agua Gas 500ml",
              "Price": "2.70",
              "Units": 48,
              "Sales": 107.52,
              "GM": 53.12
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "QUILMES",
              "EAN": 7792799005184,
              "Name": "QLMS AGUA NESTL� POMELO 1500CC",
              "Price": "4.40",
              "Units": 27,
              "Sales": 98.55,
              "GM": 53.03
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77900173,
              "Name": "CAD Beldent S/Azucar Mentol 10gr",
              "Price": "1.30",
              "Units": 114,
              "Sales": 122.09,
              "GM": 52.86
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222003510,
              "Name": "BNF DRF LATA MENTA 20G",
              "Price": "3.02",
              "Units": 60,
              "Sales": 149.63,
              "GM": 52.79
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "INTERLINK",
              "EAN": 7790095070134,
              "Name": "ITLK Vauquita 25gr",
              "Price": "1.16",
              "Units": 202,
              "Sales": 194.04,
              "GM": 52.7
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77929891,
              "Name": "PM Parliament RC Box 20",
              "Price": "4.32",
              "Units": 199,
              "Sales": 797.62,
              "GM": 52.62
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "QUILMES",
              "EAN": 7792799005177,
              "Name": "QLMS AGUA NESTL� POMELO 500CC",
              "Price": "2.50",
              "Units": 45,
              "Sales": 93.16,
              "GM": 52.29
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7794600002047,
              "Name": "KR Variedad Chocolate 300gr",
              "Price": "3.90",
              "Units": 38,
              "Sales": 122.36,
              "GM": 52.1
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77917010,
              "Name": "CAD Beldent Fruta Verdes",
              "Price": "1.30",
              "Units": 112,
              "Sales": 119.92,
              "GM": 51.92
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "PEPSI",
              "EAN": 7790017405006,
              "Name": "Tropicana Naranja 1L",
              "Price": "4.40",
              "Units": 37,
              "Sales": 135.05,
              "GM": 51.9
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BRITISH AMERICAN",
              "EAN": 77910646,
              "Name": "LUCKY STRIKE BOX 10UND",
              "Price": "2.02",
              "Units": 375,
              "Sales": 708,
              "GM": 51.39
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310980125,
              "Name": "FL PEP PALITOS SALADOS 90GR",
              "Price": "2.20",
              "Units": 79,
              "Sales": 143.78,
              "GM": 51.35
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 74570274000,
              "Name": "GMA Haagen Pints Cookies&Cream 473ml",
              "Price": "23.90",
              "Units": 7,
              "Sales": 138.25,
              "GM": 51.28
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "GEORGALOS",
              "EAN": 1185,
              "Name": "GEO Tokke Lec Mani 9g",
              "Price": "0.60",
              "Units": 206,
              "Sales": 102.57,
              "GM": 51.07
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 179,
              "Subcategory": "NEWSPAPERS",
              "Manufacturer": "OTHER",
              "EAN": 9771514965000,
              "Name": "Clarin Sabado",
              "Price": "2.21",
              "Units": 23,
              "Sales": 50.9,
              "GM": 50.9
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7793940133732,
              "Name": "DNN Ser Cereales Crons flakes 183gr",
              "Price": "2.36",
              "Units": 96,
              "Sales": 187.49,
              "GM": 50.87
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "FELFORT",
              "EAN": 7790206502400,
              "Name": "FLFR MARROC BOMBON 198GR",
              "Price": "18.14",
              "Units": 7,
              "Sales": 104.94,
              "GM": 50.54
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790580327415,
              "Name": "Arcor Rocklets Chocolate 40gr",
              "Price": "1.80",
              "Units": 81,
              "Sales": 120.66,
              "GM": 50.12
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222040980,
              "Name": "BNFD Mecano 19gr",
              "Price": "1.23",
              "Units": 102,
              "Sales": 103.21,
              "GM": 49.97
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "KRAFT",
              "EAN": 77903860,
              "Name": "KR Terrabusi Torta Deli Alfajor 70gr",
              "Price": "2.30",
              "Units": 49,
              "Sales": 93.1,
              "GM": 49.92
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "QUILMES",
              "EAN": 7792799005252,
              "Name": "QLMS NPV Manzana 500ml",
              "Price": "2.50",
              "Units": 42,
              "Sales": 86.96,
              "GM": 49.47
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77910585,
              "Name": "Gitanes Blondes Box 20und",
              "Price": "4.06",
              "Units": 196,
              "Sales": 740.33,
              "GM": 49.36
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170002856,
              "Name": "Gatorade Manzana 946ml",
              "Price": "6.50",
              "Units": 20,
              "Sales": 107.4,
              "GM": 49.17
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310980903,
              "Name": "FL  CHEETOS CLASSIC 85G",
              "Price": "3.90",
              "Units": 42,
              "Sales": 135.24,
              "GM": 49.14
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTHFOOD",
              "EAN": 5052,
              "Name": "SALTENO DE POLLO",
              "Price": "11.30",
              "Units": 8,
              "Sales": 74.72,
              "GM": 49.09
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "KRAFT",
              "EAN": 7622300094041,
              "Name": "KR CLUB SOCIAL CHIPS PARMES 120G",
              "Price": "5.10",
              "Units": 26,
              "Sales": 109.47,
              "GM": 49
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 135,
              "Subcategory": "CAFE Y TE",
              "Manufacturer": "QUIMICA ESTRELLA",
              "EAN": 7791445001013,
              "Name": "QE Cruz de Malta Yerba 500gr",
              "Price": "5.01",
              "Units": 22,
              "Sales": 91.08,
              "GM": 48.91
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "BUFFALO",
              "EAN": 7795070403136,
              "Name": "BFF Buffalo Escobilla C 13 1und",
              "Price": "14.20",
              "Units": 11,
              "Sales": 129.14,
              "GM": 48.84
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 191,
              "Subcategory": "BRAKE FLUIDS",
              "Manufacturer": "WAGNER BRAKE",
              "EAN": 7797409411140,
              "Name": "WAG DOT1 Wagner Liq Fren 1L",
              "Price": "33.00",
              "Units": 4,
              "Sales": 109.09,
              "GM": 48.77
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 77916105,
              "Name": "KR Milka Leger Leche 45gr",
              "Price": "3.00",
              "Units": 53,
              "Sales": 131.44,
              "GM": 48.72
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "ARCOR",
              "EAN": 7790040298705,
              "Name": "Aguila Alfajor Mini Torta 72",
              "Price": "2.20",
              "Units": 58,
              "Sales": 105.56,
              "GM": 48.63
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77933478,
              "Name": "COFLER BCO  COOKIES 28 GS",
              "Price": "1.40",
              "Units": 103,
              "Sales": 119.32,
              "GM": 48.57
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040996403,
              "Name": "BGLY Bagley Alfajor B&N Blanco 50g",
              "Price": "1.80",
              "Units": 79,
              "Sales": 117.71,
              "GM": 48.19
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580395704,
              "Name": "Aguila Hel Mini Torta 80ml",
              "Price": "3.00",
              "Units": 48,
              "Sales": 119.04,
              "GM": 48.18
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77912725,
              "Name": "CAD Halls Menthol-Lyptus 28gr",
              "Price": "1.20",
              "Units": 109,
              "Sales": 107.92,
              "GM": 48.03
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 193,
              "Subcategory": "OTHER ADDITIVES",
              "Manufacturer": "BARDAHL LUBRICANT",
              "EAN": 7790627101329,
              "Name": "BRDL Limpia Inyector Nafta Adit 250ml",
              "Price": "11.50",
              "Units": 13,
              "Sales": 123.51,
              "GM": 47.71
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTHFOOD",
              "EAN": 5051,
              "Name": "SALTENO DE CARNE",
              "Price": "11.30",
              "Units": 9,
              "Sales": 84.06,
              "GM": 47.68
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310001417,
              "Name": "FL Pehuamar Palitos c Queso 200g",
              "Price": "4.00",
              "Units": 23,
              "Sales": 76.13,
              "GM": 47.61
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170042234,
              "Name": "GATORADE PET BERRY FIERCE 500CC",
              "Price": "4.90",
              "Units": 27,
              "Sales": 109.35,
              "GM": 47.39
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "KRAFT",
              "EAN": 7622300190330,
              "Name": "KR CEREALITA CAPRICHOS 5 CEREAL",
              "Price": "2.90",
              "Units": 54,
              "Sales": 129.59,
              "GM": 47.37
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082740169,
              "Name": "TM Galleta Pepas 250G",
              "Price": "3.50",
              "Units": 37,
              "Sales": 106.93,
              "GM": 47.3
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77910325,
              "Name": "Parisienes Filtre KS 20und",
              "Price": "3.78",
              "Units": 192,
              "Sales": 674.18,
              "GM": 47.27
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "KRAFT",
              "EAN": 7622400991929,
              "Name": "KR MILKA I LOVE PRALINES LEC",
              "Price": "19.00",
              "Units": 8,
              "Sales": 125.6,
              "GM": 47.2
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895064319,
              "Name": "Cepita Manzana 250ml",
              "Price": "1.70",
              "Units": 63,
              "Sales": 88.83,
              "GM": 47.05
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813420491,
              "Name": "7 UP Light 500ml",
              "Price": "3.10",
              "Units": 38,
              "Sales": 97.66,
              "GM": 46.82
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895067990,
              "Name": "Fanta Mandarina 600ml",
              "Price": "3.30",
              "Units": 37,
              "Sales": 101.75,
              "GM": 46.8
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895639807,
              "Name": "COCA COLA ZERO F.GRIP 410ML",
              "Price": "2.70",
              "Units": 39,
              "Sales": 87.74,
              "GM": 46.6
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 28400055154,
              "Name": "FL Lays Stax Onion 155Gr",
              "Price": "9.50",
              "Units": 17,
              "Sales": 133.45,
              "GM": 46.58
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "INTERLINK",
              "EAN": 77927361,
              "Name": "ITLK Vauquita Light 25gr",
              "Price": "1.29",
              "Units": 141,
              "Sales": 150.22,
              "GM": 46.53
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895640032,
              "Name": " Powerade Naranja 0,5 lts",
              "Price": "3.25",
              "Units": 36,
              "Sales": 96.77,
              "GM": 46.38
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770505014000,
              "Name": "Vanidades",
              "Price": "6.61",
              "Units": 7,
              "Sales": 46.3,
              "GM": 46.3
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170002016,
              "Name": "Gatorade Naranja 473ml",
              "Price": "4.40",
              "Units": 25,
              "Sales": 90.99,
              "GM": 46.29
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 156,
              "Subcategory": "PRESERVATIVOS",
              "Manufacturer": "LOC",
              "EAN": 7791519200045,
              "Name": "BUHL Prime Extrafino 3u",
              "Price": "4.79",
              "Units": 25,
              "Sales": 99,
              "GM": 46.2
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 7790114015191,
              "Name": "KR Milka Leger Almendras 100g",
              "Price": "5.20",
              "Units": 27,
              "Sales": 116.1,
              "GM": 45.95
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "FELFORT",
              "EAN": 77909039,
              "Name": "FLFR CEREAL FORT FEL-F GRANRICO",
              "Price": "1.50",
              "Units": 85,
              "Sales": 105.4,
              "GM": 45.88
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7793940102882,
              "Name": "DNN Ser Colchon Frutilla 190gr",
              "Price": "2.47",
              "Units": 81,
              "Sales": 165.1,
              "GM": 45.85
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300057091,
              "Name": "KR Milka Mjoy Castanas 70gr",
              "Price": "3.60",
              "Units": 55,
              "Sales": 163.86,
              "GM": 45.32
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 3415586405007,
              "Name": "GMA HAAGEN DAZS VAINI&ALMON 90ML",
              "Price": "8.50",
              "Units": 18,
              "Sales": 126.37,
              "GM": 45.09
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 77916136,
              "Name": "KR Milka Leger Blanco 45gr",
              "Price": "3.00",
              "Units": 49,
              "Sales": 121.52,
              "GM": 45
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771514735009,
              "Name": "Elle Argentina",
              "Price": "7.50",
              "Units": 6,
              "Sales": 45,
              "GM": 45
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "ARCOR",
              "EAN": 77936271,
              "Name": "Barra Cereal Ser Frutilla",
              "Price": "1.70",
              "Units": 81,
              "Sales": 113.59,
              "GM": 44.94
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "MOLINO CANUELAS",
              "EAN": 7792200000159,
              "Name": "MLC 9 de Oro Bizcocho 200gr",
              "Price": "2.60",
              "Units": 49,
              "Sales": 105.35,
              "GM": 44.9
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222041673,
              "Name": "BNFD Bonafide Choc Crocante 25g",
              "Price": "1.50",
              "Units": 86,
              "Sales": 106.62,
              "GM": 44.79
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "KRAFT",
              "EAN": 7794910011647,
              "Name": "KR CEREALITAS GALLET 200GR",
              "Price": "2.50",
              "Units": 54,
              "Sales": 111.77,
              "GM": 44.78
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 156,
              "Subcategory": "PRESERVATIVOS",
              "Manufacturer": "BUHL",
              "EAN": 7791519200090,
              "Name": "BUHL Prime Pres Tacha 3u",
              "Price": "4.81",
              "Units": 24,
              "Sales": 95.45,
              "GM": 44.71
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "QUILMES",
              "EAN": 7792799000035,
              "Name": "QLMS Eco Andes Agua C Gas 500ml",
              "Price": "2.20",
              "Units": 65,
              "Sales": 118.32,
              "GM": 44.54
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77910370,
              "Name": "Jockey Club Suave KS 20und",
              "Price": "3.42",
              "Units": 210,
              "Sales": 669.03,
              "GM": 44.28
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "OSRAM",
              "EAN": 4050300001470,
              "Name": "OSRM Lampara 12W 64193 Und",
              "Price": "17.50",
              "Units": 8,
              "Sales": 115.68,
              "GM": 44.28
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 7790114015177,
              "Name": "KR MILKA LEGER LECHE 100G",
              "Price": "5.20",
              "Units": 26,
              "Sales": 111.8,
              "GM": 44.26
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790040740006,
              "Name": "Recetas de la Abuela 200gr",
              "Price": "2.40",
              "Units": 58,
              "Sales": 114.9,
              "GM": 44.26
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813420200,
              "Name": "H2OH LIMA LIM�N 500ML",
              "Price": "2.60",
              "Units": 37,
              "Sales": 79.92,
              "GM": 44.24
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "PEPSI",
              "EAN": 7790017400018,
              "Name": "Tropicana Manzana 200ml",
              "Price": "1.60",
              "Units": 64,
              "Sales": 85.12,
              "GM": 44.23
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "MARS",
              "EAN": 7896423420166,
              "Name": "MARS MM Chocolate 40 Gr",
              "Price": "2.80",
              "Units": 46,
              "Sales": 106.32,
              "GM": 44.18
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "EL TRIGAL",
              "EAN": 7730116460131,
              "Name": "ETG BRIDGE OBLEAS 140GR",
              "Price": "3.20",
              "Units": 44,
              "Sales": 116.23,
              "GM": 44.17
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 164,
              "Subcategory": "BATTERIES",
              "Manufacturer": "ENERGIZER",
              "EAN": 39800011398,
              "Name": "ENZ Energizer E2 Grande D2",
              "Price": "16.00",
              "Units": 7,
              "Sales": 92.56,
              "GM": 44.05
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "JORGITO",
              "EAN": 77905758,
              "Name": "JRG Jorgito Alfajor Blanco 50gr",
              "Price": "1.30",
              "Units": 101,
              "Sales": 108.26,
              "GM": 44.02
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170042159,
              "Name": "Gatorade Propel Manzana  0.5Lts",
              "Price": "4.90",
              "Units": 23,
              "Sales": 93.15,
              "GM": 43.87
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 74570810116,
              "Name": "GMA Haagen Daz Pints Dulce Lech 473ml",
              "Price": "23.90",
              "Units": 6,
              "Sales": 118.5,
              "GM": 43.86
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790580607418,
              "Name": "Arcor Chocolate Blanco 25g",
              "Price": "1.00",
              "Units": 137,
              "Sales": 113.41,
              "GM": 43.6
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310980279,
              "Name": "FL Doritos Queso 80gr",
              "Price": "3.90",
              "Units": 37,
              "Sales": 119.15,
              "GM": 43.3
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222042120,
              "Name": "BNFD CHOCKMAN 35GR",
              "Price": "1.00",
              "Units": 123,
              "Sales": 101.83,
              "GM": 43.27
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77917119,
              "Name": "PM Virginia Superslims 100 20und",
              "Price": "4.10",
              "Units": 173,
              "Sales": 662.26,
              "GM": 43.22
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 203,
              "Subcategory": "HOT DOGS/GRILL PROD.",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3026,
              "Name": "Pancho Criollo",
              "Price": "5.00",
              "Units": 17,
              "Sales": 70.21,
              "GM": 42.75
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77910950,
              "Name": "Parisienes  Box 20und",
              "Price": "3.92",
              "Units": 172,
              "Sales": 628.13,
              "GM": 42.63
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580389109,
              "Name": "Arcor Tofi Cono 135ml",
              "Price": "4.00",
              "Units": 34,
              "Sales": 112.5,
              "GM": 42.28
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580397104,
              "Name": "Arcor Rocklets Cono 120ml",
              "Price": "4.00",
              "Units": 34,
              "Sales": 112.47,
              "GM": 42.24
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790580607210,
              "Name": "Arcor Chocolate con Leche 25g",
              "Price": "1.00",
              "Units": 132,
              "Sales": 109.25,
              "GM": 42.08
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "ARCOR",
              "EAN": 7790040449701,
              "Name": "SER BUDIN TRADICIONAL 250G",
              "Price": "6.50",
              "Units": 26,
              "Sales": 139.62,
              "GM": 42.02
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170042012,
              "Name": "GATORADE PET NAR. 500CC",
              "Price": "4.90",
              "Units": 24,
              "Sales": 97.2,
              "GM": 42
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 193,
              "Subcategory": "OTHER ADDITIVES",
              "Manufacturer": "BARDAHL LUBRICANT",
              "EAN": 7790627112103,
              "Name": "BRDL no smoke Platinum 400ml",
              "Price": "14.50",
              "Units": 9,
              "Sales": 107.86,
              "GM": 41.82
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954058036,
              "Name": "SFA Filtros Fram PH-5803",
              "Price": "29.03",
              "Units": 3,
              "Sales": 71.97,
              "GM": 41.67
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 156,
              "Subcategory": "PRESERVATIVOS",
              "Manufacturer": "BUHL",
              "EAN": 7791519000676,
              "Name": "BUHL Prime preserv Extralu 3u",
              "Price": "4.74",
              "Units": 23,
              "Sales": 90.15,
              "GM": 41.5
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "JORGITO",
              "EAN": 77901705,
              "Name": "JRG Jorgelin Maxialf Blanc Leche 85gr",
              "Price": "1.90",
              "Units": 68,
              "Sales": 106.76,
              "GM": 41.5
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "ARCOR",
              "EAN": 77915818,
              "Name": "Cereal Mix Light Manzana 23gr",
              "Price": "1.40",
              "Units": 80,
              "Sales": 92.67,
              "GM": 41.45
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954054960,
              "Name": "SFA Filtros Fram CA-5496",
              "Price": "18.35",
              "Units": 5,
              "Sales": 75.83,
              "GM": 41.43
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7791249020074,
              "Name": "CAD CADBURY YOGURT FRUTILLA 80G",
              "Price": "5.00",
              "Units": 24,
              "Sales": 99.12,
              "GM": 41.37
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "OTHER",
              "EAN": 40000001621,
              "Name": "MAS Skittles Wildberry 61,5g",
              "Price": "3.00",
              "Units": 43,
              "Sales": 106.64,
              "GM": 41.34
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "KRAFT",
              "EAN": 7622300094010,
              "Name": "KR CLUB SOCIAL CHIPSCREMCEB 120G",
              "Price": "5.10",
              "Units": 22,
              "Sales": 92.63,
              "GM": 41.33
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771667651003,
              "Name": "Hombre",
              "Price": "5.90",
              "Units": 7,
              "Sales": 41.3,
              "GM": 41.3
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170042050,
              "Name": "GATORADE PET LIMA-LIMON 500CC",
              "Price": "4.90",
              "Units": 23,
              "Sales": 93.15,
              "GM": 40.6
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222041505,
              "Name": "BNFD BOMBON DDL30GRS",
              "Price": "1.72",
              "Units": 74,
              "Sales": 104.74,
              "GM": 40.36
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813420231,
              "Name": "PEPSI MAX 0.5L",
              "Price": "3.10",
              "Units": 36,
              "Sales": 92.88,
              "GM": 40.28
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 74490209328,
              "Name": "Vougue",
              "Price": "10.00",
              "Units": 4,
              "Sales": 40,
              "GM": 40
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6879,
              "Name": "SFA Filtros Fram PH-5592",
              "Price": "104.00",
              "Units": 1,
              "Sales": 85.95,
              "GM": 39.72
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "FELFORT",
              "EAN": 1117,
              "Name": "FLFR Marroc Bombon 14gr",
              "Price": "0.95",
              "Units": 113,
              "Sales": 88.84,
              "GM": 39.7
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082740336,
              "Name": "TM Tia Maruca Junglitas 250gr",
              "Price": "3.50",
              "Units": 32,
              "Sales": 92.48,
              "GM": 39.69
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9789500828406,
              "Name": "Para Teens",
              "Price": "7.90",
              "Units": 5,
              "Sales": 39.5,
              "GM": 39.5
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "NESTLE",
              "EAN": 7792799005221,
              "Name": "Nestle Nar Dur 500ml",
              "Price": "2.50",
              "Units": 34,
              "Sales": 70.4,
              "GM": 39.46
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "KRAFT",
              "EAN": 7622300190316,
              "Name": "KR CEREALITA CAPRICH MEDITER 00G",
              "Price": "2.90",
              "Units": 45,
              "Sales": 107.97,
              "GM": 39.45
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170002870,
              "Name": "Gatorade Naranja Pom 473ml",
              "Price": "4.40",
              "Units": 21,
              "Sales": 76.41,
              "GM": 38.94
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6432,
              "Name": "SFA Filtros Fram CA-5513D",
              "Price": "26.09",
              "Units": 4,
              "Sales": 86.22,
              "GM": 38.94
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6472,
              "Name": "SFA Filtros Fram CA-5970",
              "Price": "41.39",
              "Units": 2,
              "Sales": 68.42,
              "GM": 38.88
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "OTHER",
              "EAN": 22000000705,
              "Name": "HUBBA BUBBA CHICLETS G.S  42G",
              "Price": "3.80",
              "Units": 32,
              "Sales": 100.48,
              "GM": 38.76
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895063251,
              "Name": "Fanta Naranja Light 1.5L",
              "Price": "5.50",
              "Units": 24,
              "Sales": 109.2,
              "GM": 38.58
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "FELFORT",
              "EAN": 77909190,
              "Name": "FLFR CEREAL FORT FLEKOS 18G",
              "Price": "1.50",
              "Units": 73,
              "Sales": 90.52,
              "GM": 38.55
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813421382,
              "Name": "PST Pomelo 1.5L",
              "Price": "5.40",
              "Units": 19,
              "Sales": 85.5,
              "GM": 38.48
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954095192,
              "Name": "SFA Fram Filtro CA 9519",
              "Price": "26.00",
              "Units": 4,
              "Sales": 85.94,
              "GM": 38.44
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "INTERLINK",
              "EAN": 7798138290037,
              "Name": "ITLK Vauquita  140G",
              "Price": "3.61",
              "Units": 43,
              "Sales": 128.28,
              "GM": 38.41
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170061006,
              "Name": "Gatorade Sport 600ml Naranja",
              "Price": "5.90",
              "Units": 18,
              "Sales": 87.84,
              "GM": 38.35
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 60,
              "Subcategory": "Caramelos en bolsa",
              "Manufacturer": "ARCOR",
              "EAN": 7790580061807,
              "Name": "Sugus Mini Sugus Confitado 25g",
              "Price": "1.00",
              "Units": 130,
              "Sales": 107.62,
              "GM": 38.26
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040001855,
              "Name": "BGLY Melitas 170gr",
              "Price": "2.70",
              "Units": 43,
              "Sales": 95.89,
              "GM": 38.23
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 179,
              "Subcategory": "NEWSPAPERS",
              "Manufacturer": "OTHER",
              "EAN": 7608,
              "Name": "CLARIN LUNES",
              "Price": "2.00",
              "Units": 19,
              "Sales": 38,
              "GM": 38
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77913500,
              "Name": "PM Marlboro Light KS 20und",
              "Price": "3.93",
              "Units": 147,
              "Sales": 539.12,
              "GM": 37.58
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 51,
              "Subcategory": "Bebidas Alternativas",
              "Manufacturer": "OTHER",
              "EAN": 7798133510017,
              "Name": "RF Rocket Fuel 250cc",
              "Price": "4.25",
              "Units": 23,
              "Sales": 80.7,
              "GM": 37.48
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "FELFORT",
              "EAN": 77909145,
              "Name": "FLFR Cereal Fort Light Frut Manz",
              "Price": "1.50",
              "Units": 69,
              "Sales": 85.56,
              "GM": 37.42
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580395902,
              "Name": "Arcor Cofler Dulce Leche 80ml",
              "Price": "4.00",
              "Units": 30,
              "Sales": 99.27,
              "GM": 37.3
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954870645,
              "Name": "SFA Fram Filtro PH 5548",
              "Price": "23.34",
              "Units": 4,
              "Sales": 77.15,
              "GM": 37.15
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "SCJHONSON",
              "EAN": 7501032920142,
              "Name": "SCJ Auto Sport Glade 7ml",
              "Price": "21.00",
              "Units": 4,
              "Sales": 69.44,
              "GM": 37.1
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310980910,
              "Name": "FL  Cheetos Classic 150g",
              "Price": "5.10",
              "Units": 24,
              "Sales": 101.06,
              "GM": 36.98
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "NESTLE",
              "EAN": 79200317881,
              "Name": "Wonka Rainbow 6 oz",
              "Price": "9.00",
              "Units": 13,
              "Sales": 96.72,
              "GM": 36.67
          },
          {
              "CategoryId": 140,
              "Category": "Limpieza",
              "SubcategoryId": 144,
              "Subcategory": "PAPER/PLASTIC/FOIL",
              "Manufacturer": "PORTACION",
              "EAN": 7806540320102,
              "Name": "PT Savion Soft Tissue 10und",
              "Price": "0.70",
              "Units": 180,
              "Sales": 104.4,
              "GM": 36.63
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "BAGGIO",
              "EAN": 7790036972558,
              "Name": "RPBB Baggio Multifruta 200ml",
              "Price": "1.70",
              "Units": 50,
              "Sales": 70.5,
              "GM": 36.56
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CHUPA CHUPS",
              "EAN": 84155634,
              "Name": "CH SMINT AZUL 8G",
              "Price": "3.50",
              "Units": 34,
              "Sales": 98.27,
              "GM": 36.39
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310979969,
              "Name": "FL LAYS MEDITERRAN TOMAT Y ALB",
              "Price": "3.90",
              "Units": 31,
              "Sales": 99.82,
              "GM": 36.27
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77924285,
              "Name": "CAD Halls Creamy Frutilla 27gr",
              "Price": "1.20",
              "Units": 82,
              "Sales": 81.18,
              "GM": 36.18
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580394707,
              "Name": "Arcor Tofi Crocante 80ml",
              "Price": "3.00",
              "Units": 36,
              "Sales": 89.28,
              "GM": 36.08
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "ARCOR",
              "EAN": 77936288,
              "Name": "SER BARRA CEREAL DURAZNO 23G",
              "Price": "1.70",
              "Units": 63,
              "Sales": 88.32,
              "GM": 36.06
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770017291005,
              "Name": "El Grafico",
              "Price": "9.00",
              "Units": 4,
              "Sales": 36,
              "GM": 36
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 10722990305,
              "Name": "Glamour",
              "Price": "9.00",
              "Units": 4,
              "Sales": 36,
              "GM": 36
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 25274174219,
              "Name": "HOLA",
              "Price": "18.00",
              "Units": 2,
              "Sales": 36,
              "GM": 36
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770328639008,
              "Name": "Informacion Technology",
              "Price": "9.00",
              "Units": 4,
              "Sales": 36,
              "GM": 36
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062547788,
              "Name": "DNN Villavicencio Agua Gas 1.5L",
              "Price": "3.60",
              "Units": 28,
              "Sales": 83.7,
              "GM": 35.99
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "WYNNS",
              "EAN": 73579622058,
              "Name": "WNNS Radiator 325ml",
              "Price": "30.33",
              "Units": 3,
              "Sales": 75.2,
              "GM": 35.99
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "NESTLE",
              "EAN": 79200003579,
              "Name": "NES Nerd Apple & Lemonade 46.7",
              "Price": "3.70",
              "Units": 27,
              "Sales": 82.62,
              "GM": 35.91
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040057081,
              "Name": "BGLY CRIOLLITAS 105GR",
              "Price": "1.60",
              "Units": 70,
              "Sales": 92.4,
              "GM": 35.84
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77974631,
              "Name": "Tofi Chocolate 28G",
              "Price": "1.82",
              "Units": 52,
              "Sales": 78.36,
              "GM": 35.72
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895066443,
              "Name": "Dasani Mineralizada 500ml",
              "Price": "2.10",
              "Units": 38,
              "Sales": 66.12,
              "GM": 35.59
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77920928,
              "Name": "Camel Filters Box 10und",
              "Price": "2.02",
              "Units": 268,
              "Sales": 506.38,
              "GM": 35.49
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548365,
              "Name": "DNN Ser Agua Plus Naran Dur 1.5L",
              "Price": "4.90",
              "Units": 20,
              "Sales": 82,
              "GM": 35.2
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 81,
              "Subcategory": "OTHER DAIRY",
              "Manufacturer": "DANONE",
              "EAN": 7791337580855,
              "Name": "DNN Actimel 0% Multifrutas 100gr",
              "Price": "1.47",
              "Units": 96,
              "Sales": 116.53,
              "GM": 35.03
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "QUILMES",
              "EAN": 7792799005153,
              "Name": "QLMS AGUA NESTL� LIMONADA 500CC",
              "Price": "2.50",
              "Units": 30,
              "Sales": 62.1,
              "GM": 35.01
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "MARS",
              "EAN": 7896423424539,
              "Name": "MARS Twix 45gr",
              "Price": "2.70",
              "Units": 39,
              "Sales": 86.97,
              "GM": 34.84
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77910905,
              "Name": "John Player Gold Leaf 20und",
              "Price": "4.03",
              "Units": 132,
              "Sales": 495.81,
              "GM": 34.82
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "KRAFT",
              "EAN": 77960160,
              "Name": "KR MILKA ALFAJOR MOUSSE 40G",
              "Price": "1.70",
              "Units": 64,
              "Sales": 89.69,
              "GM": 34.76
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77933409,
              "Name": "COFLER LECHE 28G",
              "Price": "1.40",
              "Units": 73,
              "Sales": 84.54,
              "GM": 34.7
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222002865,
              "Name": "BNFD DRF Menta  23 Gr",
              "Price": "0.81",
              "Units": 114,
              "Sales": 76.62,
              "GM": 34.61
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895066429,
              "Name": "Dasani Mineralizada Con Gas 500ml",
              "Price": "2.10",
              "Units": 36,
              "Sales": 62.62,
              "GM": 34.33
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 9319,
              "Name": "PEPSI BOTELLA 237ML",
              "Price": "2.20",
              "Units": 40,
              "Sales": 73.2,
              "GM": 34.3
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77930019,
              "Name": "Bon o Bon Choc Blanco 17gr",
              "Price": "0.80",
              "Units": 110,
              "Sales": 72.64,
              "GM": 34.16
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "QUILMES",
              "EAN": 7792799005160,
              "Name": "QLMS AGUA NESTL� LIMONADA 1500CC",
              "Price": "4.40",
              "Units": 16,
              "Sales": 58.4,
              "GM": 34.13
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580396107,
              "Name": "Cofler Hel B80ml",
              "Price": "3.00",
              "Units": 34,
              "Sales": 84.32,
              "GM": 34.12
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 60,
              "Subcategory": "Caramelos en bolsa",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222003312,
              "Name": "BNFD Billiken Gotitas de Amor 35g",
              "Price": "1.02",
              "Units": 97,
              "Sales": 81.77,
              "GM": 34.11
          },
          {
              "CategoryId": 13,
              "Category": "Otro Tabaco",
              "SubcategoryId": 15,
              "Subcategory": "Cigarros",
              "Manufacturer": "PURO TABACO",
              "EAN": 8821,
              "Name": "PTBC Romeo Y Julieta Nro 2 Tubo Mano",
              "Price": "35.75",
              "Units": 2,
              "Sales": 59.72,
              "GM": 34.06
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222041468,
              "Name": "BNFD BOCADITO BONAFIDE RHUM 16G",
              "Price": "0.92",
              "Units": 104,
              "Sales": 78.98,
              "GM": 33.88
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222041079,
              "Name": "BNFD Nugaton black 27gr",
              "Price": "1.20",
              "Units": 60,
              "Sales": 59.44,
              "GM": 33.8
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 149,
              "Subcategory": "ANALGESICOS",
              "Manufacturer": "BAYER",
              "EAN": 9803,
              "Name": "BY Cafiaspirina Tira 10gr",
              "Price": "2.52",
              "Units": 25,
              "Sales": 63,
              "GM": 33.66
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "KRAFT",
              "EAN": 77956699,
              "Name": "KR Shot Tri Alfajor 60gr",
              "Price": "2.30",
              "Units": 48,
              "Sales": 91.2,
              "GM": 33.21
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300121198,
              "Name": "KR TERRAB ALFAJOR MOUSSE 55G",
              "Price": "2.30",
              "Units": 38,
              "Sales": 72.2,
              "GM": 33.11
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "JORGITO",
              "EAN": 7790957000545,
              "Name": "JRG Jorgito Alfajorcito Choc 6un",
              "Price": "3.70",
              "Units": 28,
              "Sales": 85.68,
              "GM": 33.03
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 28400055093,
              "Name": "FL Lays Stax Original 163g",
              "Price": "9.50",
              "Units": 12,
              "Sales": 94.2,
              "GM": 32.88
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7794600007530,
              "Name": "KR Duquesa 115gr",
              "Price": "2.00",
              "Units": 47,
              "Sales": 77.58,
              "GM": 32.87
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813422532,
              "Name": "7Up H2OH Citrus 2000ml",
              "Price": "4.90",
              "Units": 17,
              "Sales": 69.02,
              "GM": 32.79
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "ARCOR",
              "EAN": 77917850,
              "Name": "Cofler Rally 38g",
              "Price": "1.60",
              "Units": 55,
              "Sales": 72.62,
              "GM": 32.72
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954047030,
              "Name": "SFA Filtros Fram PH-4703",
              "Price": "23.05",
              "Units": 3,
              "Sales": 57.13,
              "GM": 32.72
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "ARCOR",
              "EAN": 77936264,
              "Name": "Barra Cereal Ser Manzana",
              "Price": "1.70",
              "Units": 60,
              "Sales": 84.14,
              "GM": 32.51
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 8413042772804,
              "Name": "Glamour Espanol",
              "Price": "6.50",
              "Units": 5,
              "Sales": 32.5,
              "GM": 32.5
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "PEPSI",
              "EAN": 7792799578756,
              "Name": "Nestle  500 sin Gas",
              "Price": "2.20",
              "Units": 38,
              "Sales": 69.53,
              "GM": 32.36
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580397203,
              "Name": "Bon o Bon Hel Cono 135ml",
              "Price": "4.00",
              "Units": 26,
              "Sales": 86.03,
              "GM": 32.32
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "NESTLE",
              "EAN": 79200277567,
              "Name": "NES Nerds W.Berry & W.melon 46.7 gr",
              "Price": "3.70",
              "Units": 24,
              "Sales": 73.43,
              "GM": 32.08
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082740381,
              "Name": "TM TM Galletas Light 200gr",
              "Price": "3.50",
              "Units": 26,
              "Sales": 75.2,
              "GM": 32.04
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 3415586410001,
              "Name": "GMA HAAGEN DAZS COOKIES&CRE 90ML",
              "Price": "8.50",
              "Units": 13,
              "Sales": 91.26,
              "GM": 32
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300121976,
              "Name": "KR Manon 135g",
              "Price": "2.20",
              "Units": 38,
              "Sales": 69.16,
              "GM": 31.91
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "DON SATUR",
              "EAN": 7798092780551,
              "Name": "DST Don Satur Talitas 160g",
              "Price": "3.75",
              "Units": 27,
              "Sales": 83.7,
              "GM": 31.9
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "GEORGALOS",
              "EAN": 77926760,
              "Name": "GRG MARROC CHOCOLATE 25G",
              "Price": "1.40",
              "Units": 64,
              "Sales": 74.13,
              "GM": 31.84
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040930506,
              "Name": "BGLY Rumba 112G",
              "Price": "2.70",
              "Units": 35,
              "Sales": 78.05,
              "GM": 31.75
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7790715013121,
              "Name": "TPS Autopolish 450ml",
              "Price": "14.42",
              "Units": 6,
              "Sales": 71.47,
              "GM": 31.63
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580397005,
              "Name": "Arcor Kopa Chocolate 190ml",
              "Price": "3.50",
              "Units": 28,
              "Sales": 80.97,
              "GM": 31.38
          },
          {
              "CategoryId": 65,
              "Category": "Leches",
              "SubcategoryId": 69,
              "Subcategory": "SKIM/NON FAT MILK",
              "Manufacturer": "DANONE",
              "EAN": 7793940346002,
              "Name": "DNN La Serenis Lech Descrem Larg D 1L",
              "Price": "3.30",
              "Units": 54,
              "Sales": 147.31,
              "GM": 31.13
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "BAGGIO",
              "EAN": 7790036971605,
              "Name": "RPBB Baggio Naranja 1L",
              "Price": "4.50",
              "Units": 19,
              "Sales": 70.88,
              "GM": 31.12
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813421368,
              "Name": "PST Tonica 1.5L",
              "Price": "5.40",
              "Units": 16,
              "Sales": 72,
              "GM": 30.94
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 135,
              "Subcategory": "CAFE Y TE",
              "Manufacturer": "MOL RIO DELA PLATA",
              "EAN": 7790060054060,
              "Name": "MRP Nobleza Gaucha Yerba 500gr",
              "Price": "5.50",
              "Units": 12,
              "Sales": 54.6,
              "GM": 30.88
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "FERRERO",
              "EAN": 78602649,
              "Name": "FRR TIC TAC EXTRA FUERTE 16GR",
              "Price": "1.30",
              "Units": 61,
              "Sales": 65.33,
              "GM": 30.88
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "BARDAHL LUBRICANT",
              "EAN": 7790558000012,
              "Name": "BRDL Silicona A3 Vinilico Autom 120ml",
              "Price": "12.29",
              "Units": 7,
              "Sales": 71.07,
              "GM": 30.88
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895066344,
              "Name": "DASANI BALANCE DURAZ C/G 500",
              "Price": "2.40",
              "Units": 31,
              "Sales": 61.38,
              "GM": 30.78
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "ARCOR",
              "EAN": 7793360475009,
              "Name": "La Campagnola BC Naranja Lt",
              "Price": "4.90",
              "Units": 17,
              "Sales": 69.19,
              "GM": 30.6
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "KRAFT",
              "EAN": 77903785,
              "Name": "KR MILKA TRIPLE MOUSSE ALF 55GR",
              "Price": "2.30",
              "Units": 44,
              "Sales": 83.6,
              "GM": 30.43
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6274,
              "Name": "SFA Filtros Fram CA-8675D",
              "Price": "32.32",
              "Units": 2,
              "Sales": 53.42,
              "GM": 30.36
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6279,
              "Name": "SFA Fram G 5995",
              "Price": "25.37",
              "Units": 4,
              "Sales": 83.87,
              "GM": 30.35
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "OTHER",
              "EAN": 9044400841000,
              "Name": "Pez 25,5g",
              "Price": "9.50",
              "Units": 10,
              "Sales": 78.5,
              "GM": 30.29
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "NESTLE",
              "EAN": 1113,
              "Name": "NES Dolca Bananita 30gr",
              "Price": "1.36",
              "Units": 90,
              "Sales": 101.08,
              "GM": 30.28
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954869717,
              "Name": "SFA Fram Filtro CH 9443",
              "Price": "32.00",
              "Units": 2,
              "Sales": 52.9,
              "GM": 30.04
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6425,
              "Name": "SFA Filtros Fram CA-5466",
              "Price": "79.38",
              "Units": 1,
              "Sales": 65.6,
              "GM": 30.03
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77917713,
              "Name": "CAD Beldent Power Mints Menta 8gr",
              "Price": "2.30",
              "Units": 42,
              "Sales": 79.8,
              "GM": 30.02
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7791337265301,
              "Name": "DNN Ser Total 183g",
              "Price": "2.58",
              "Units": 67,
              "Sales": 143.15,
              "GM": 30
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7791337332027,
              "Name": "DNN Ser Bebible Frutilla 200gr",
              "Price": "2.13",
              "Units": 76,
              "Sales": 133.45,
              "GM": 29.94
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "ARCOR",
              "EAN": 7790580300302,
              "Name": "Bon o Bon Display Leche 18 Unid",
              "Price": "15.00",
              "Units": 5,
              "Sales": 62,
              "GM": 29.91
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 217,
              "Subcategory": "HOT TEA",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2036,
              "Name": "Te Saborizado Mate Cocido",
              "Price": "3.20",
              "Units": 12,
              "Sales": 31.68,
              "GM": 29.52
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082740176,
              "Name": "TM Tia Maruca Palmeritas 200gr",
              "Price": "3.50",
              "Units": 24,
              "Sales": 69.36,
              "GM": 29.52
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770329816002,
              "Name": "CASA COUNTRY",
              "Price": "9.80",
              "Units": 3,
              "Sales": 29.4,
              "GM": 29.4
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "KRAFT",
              "EAN": 7794600460335,
              "Name": "KR Mayco Galleta Sandwich 172g",
              "Price": "2.60",
              "Units": 29,
              "Sales": 62.35,
              "GM": 29.25
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300108328,
              "Name": "KR CEREALITAS CEREAL Y MIEL 142G",
              "Price": "2.90",
              "Units": 26,
              "Sales": 62.39,
              "GM": 29.19
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "ARCOR",
              "EAN": 7790580385514,
              "Name": "Topline C Sin Azuc SandiaMta 14g",
              "Price": "1.20",
              "Units": 76,
              "Sales": 75.24,
              "GM": 29.17
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300420833,
              "Name": "KR OREO BA�ADAS CHOCOLATE 204G",
              "Price": "6.60",
              "Units": 14,
              "Sales": 76.3,
              "GM": 29.14
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 151,
              "Subcategory": "REMEDIOS PARA ESTOMAGO",
              "Manufacturer": "GLAXO SMITHKLINE",
              "EAN": 9805,
              "Name": "LOCKGSK Alikal Limon 4.5gr",
              "Price": "1.30",
              "Units": 56,
              "Sales": 72.8,
              "GM": 29.12
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "FELFORT",
              "EAN": 1118,
              "Name": "FLFR Paraguitas 13gr",
              "Price": "0.98",
              "Units": 74,
              "Sales": 60.15,
              "GM": 29.08
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6285,
              "Name": "SFA Fram CH 6848",
              "Price": "79.38",
              "Units": 1,
              "Sales": 65.6,
              "GM": 29.02
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040996304,
              "Name": "BGLY Bagley Alfajor B&N Neg 50g",
              "Price": "1.80",
              "Units": 47,
              "Sales": 70.02,
              "GM": 28.99
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "OTHER",
              "EAN": 78916418,
              "Name": "PRO  Mentos Mints Caramelos 29.7Gr",
              "Price": "1.40",
              "Units": 61,
              "Sales": 70.68,
              "GM": 28.98
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 60,
              "Subcategory": "Caramelos en bolsa",
              "Manufacturer": "ARCOR",
              "EAN": 7790580200114,
              "Name": "Fruti Gelatin Tiburoncit 30g",
              "Price": "1.10",
              "Units": 66,
              "Sales": 60.06,
              "GM": 28.94
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 191,
              "Subcategory": "BRAKE FLUIDS",
              "Manufacturer": "WAGNER BRAKE",
              "EAN": 7797409305005,
              "Name": "WAG DOT4 Wagner Liq Fren 200ml",
              "Price": "10.90",
              "Units": 8,
              "Sales": 72.08,
              "GM": 28.93
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 62,
              "Subcategory": "Caramelos sueltos",
              "Manufacturer": "GEORGALOS",
              "EAN": 7790380002826,
              "Name": "GRG Flynn Paff Confitado 50gr",
              "Price": "1.60",
              "Units": 46,
              "Sales": 60.76,
              "GM": 28.86
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77910974,
              "Name": "Camel Filters KS 20und",
              "Price": "3.93",
              "Units": 116,
              "Sales": 423.89,
              "GM": 28.8
          },
          {
              "CategoryId": 13,
              "Category": "Otro Tabaco",
              "SubcategoryId": 15,
              "Subcategory": "Cigarros",
              "Manufacturer": "PURO TABACO",
              "EAN": 8500001542100,
              "Name": "PTBC Partagas Chicos Caja 5Und",
              "Price": "43.50",
              "Units": 2,
              "Sales": 72.76,
              "GM": 28.78
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062547559,
              "Name": "DNN Villa del Sur 2.25L",
              "Price": "3.62",
              "Units": 22,
              "Sales": 65.97,
              "GM": 28.77
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 207,
              "Subcategory": "BAKERY",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3730,
              "Name": "Panal De Membrillo",
              "Price": "1.30",
              "Units": 48,
              "Sales": 51.52,
              "GM": 28.76
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77927309,
              "Name": "CAD HALLS CREAMY MANZANA 27G",
              "Price": "1.20",
              "Units": 65,
              "Sales": 64.36,
              "GM": 28.68
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "ARCOR",
              "EAN": 77916389,
              "Name": "Topline Tableta Fruta 4und",
              "Price": "0.90",
              "Units": 83,
              "Sales": 61.51,
              "GM": 28.6
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "MOLINO CANUELAS",
              "EAN": 7792200000128,
              "Name": "MLC 9 de Oro Bizc. Agridulce 200G",
              "Price": "2.60",
              "Units": 31,
              "Sales": 66.65,
              "GM": 28.57
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "ARCOR",
              "EAN": 77916419,
              "Name": "Topline Tablet Defenses 4U",
              "Price": "0.90",
              "Units": 86,
              "Sales": 63.76,
              "GM": 28.48
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300111922,
              "Name": "KR OREO MINI 50 GRS",
              "Price": "1.40",
              "Units": 62,
              "Sales": 71.79,
              "GM": 28.39
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 193,
              "Subcategory": "OTHER ADDITIVES",
              "Manufacturer": "BARDAHL LUBRICANT",
              "EAN": 7790627101275,
              "Name": "BRDL Max Compresion Platinum 400ml",
              "Price": "16.70",
              "Units": 5,
              "Sales": 69,
              "GM": 28.36
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7794600003532,
              "Name": "KR Melba Galletitas 120 Grs",
              "Price": "2.10",
              "Units": 38,
              "Sales": 66.1,
              "GM": 28.36
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "FELFORT",
              "EAN": 1116,
              "Name": "FLFR Licoritas 20gr",
              "Price": "1.46",
              "Units": 57,
              "Sales": 68.79,
              "GM": 28.3
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 208,
              "Subcategory": "SOUP & SALAD",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 7795947001977,
              "Name": "Ensalada Huerta 200gr",
              "Price": "6.75",
              "Units": 12,
              "Sales": 81,
              "GM": 28.11
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300301309,
              "Name": "KR VARIEDAD 300G",
              "Price": "4.20",
              "Units": 17,
              "Sales": 58.99,
              "GM": 28.05
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "ARCOR",
              "EAN": 7790580475802,
              "Name": "LA CAMPAGNOLA BC CITRUS 250ML",
              "Price": "1.90",
              "Units": 42,
              "Sales": 66.32,
              "GM": 28.02
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 3415581312270,
              "Name": "GMA Haagen Dazs Strawberry 100ml",
              "Price": "8.50",
              "Units": 11,
              "Sales": 77.23,
              "GM": 28
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BAT",
              "EAN": 77910455,
              "Name": "Viceroy Classic FF Soft Cup 20und",
              "Price": "2.63",
              "Units": 173,
              "Sales": 421.61,
              "GM": 27.74
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 110,
              "Subcategory": "Mani y Semillas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310920749,
              "Name": "FL Pehuamar Mani Pelado 180gr",
              "Price": "3.50",
              "Units": 33,
              "Sales": 95.39,
              "GM": 27.74
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "KRAFT",
              "EAN": 77900845,
              "Name": "KR Tita 18gr",
              "Price": "0.80",
              "Units": 103,
              "Sales": 68.01,
              "GM": 27.7
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7793940332012,
              "Name": "DNN Ser Bebible Vainilla 200gr",
              "Price": "2.13",
              "Units": 70,
              "Sales": 123.34,
              "GM": 27.59
          },
          {
              "CategoryId": 13,
              "Category": "Otro Tabaco",
              "SubcategoryId": 15,
              "Subcategory": "Cigarros",
              "Manufacturer": "PURO TABACO",
              "EAN": 8812,
              "Name": "PTBC Guantanamera Decimos Und",
              "Price": "14.50",
              "Units": 4,
              "Sales": 48.79,
              "GM": 27.59
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77946829,
              "Name": "Mentho Plus s azucar Mentol 26.6G",
              "Price": "1.50",
              "Units": 50,
              "Sales": 62,
              "GM": 27.51
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "BAGGIO",
              "EAN": 7790036559223,
              "Name": "RPBB Baggio Multifrutal 1L",
              "Price": "4.50",
              "Units": 17,
              "Sales": 63.41,
              "GM": 27.39
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77926463,
              "Name": "CAD BELDENTET WHITE MINT",
              "Price": "1.30",
              "Units": 58,
              "Sales": 62.12,
              "GM": 27.35
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "ARCOR",
              "EAN": 7790040614000,
              "Name": "Bon o Bon Alfajor Triple 60gr",
              "Price": "2.00",
              "Units": 40,
              "Sales": 66.03,
              "GM": 27.14
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "GUAYMAYEN",
              "EAN": 77980229,
              "Name": "GYM Guaymayen Alfajor Chocolate 38gr",
              "Price": "0.60",
              "Units": 121,
              "Sales": 60.08,
              "GM": 27.11
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "KRAFT",
              "EAN": 77915481,
              "Name": "KR Pepitos Alfajor 57gr",
              "Price": "2.30",
              "Units": 39,
              "Sales": 74.1,
              "GM": 27.11
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "GEORGALOS",
              "EAN": 7790380016328,
              "Name": "GEO TOKKE CHOC CEREAL CROCANTE",
              "Price": "1.60",
              "Units": 53,
              "Sales": 69.98,
              "GM": 27.07
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813420354,
              "Name": "Pepsi Light 500ml",
              "Price": "3.10",
              "Units": 21,
              "Sales": 54.18,
              "GM": 27.06
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770328459002,
              "Name": "Buena Salud",
              "Price": "9.00",
              "Units": 3,
              "Sales": 27,
              "GM": 27
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "FELFORT",
              "EAN": 7790206515257,
              "Name": "FLFR JACK SORPRESA 10GRS",
              "Price": "5.27",
              "Units": 15,
              "Sales": 65.3,
              "GM": 26.84
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "FELFORT",
              "EAN": 1138,
              "Name": "FLFR LICONFORT BOMBOM WHISKY 13G",
              "Price": "1.07",
              "Units": 77,
              "Sales": 67.92,
              "GM": 26.81
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548280,
              "Name": "DNN SER CITRUS 2 LTS",
              "Price": "5.10",
              "Units": 13,
              "Sales": 54.73,
              "GM": 26.77
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "KRAFT",
              "EAN": 7622300202897,
              "Name": "KR Milka Alf Xtreme  61 Grs",
              "Price": "2.30",
              "Units": 35,
              "Sales": 66.5,
              "GM": 26.61
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310924075,
              "Name": "FL LAYS AIREADAS 50G",
              "Price": "3.50",
              "Units": 25,
              "Sales": 72.25,
              "GM": 26.5
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040003606,
              "Name": "BGLY KESITAS 75GR",
              "Price": "1.80",
              "Units": 43,
              "Sales": 64.07,
              "GM": 26.37
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "KRAFT",
              "EAN": 7622300093983,
              "Name": "KR CLUB SOCIAL CHIPS 120G",
              "Price": "5.10",
              "Units": 14,
              "Sales": 58.95,
              "GM": 26.37
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77911988,
              "Name": "PM Marlboro Light Box 10 und",
              "Price": "2.03",
              "Units": 186,
              "Sales": 351.84,
              "GM": 26.21
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954869144,
              "Name": "SFA Fram Filtro CA 9080",
              "Price": "39.69",
              "Units": 2,
              "Sales": 65.6,
              "GM": 26.17
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 7790580308315,
              "Name": "TOPLINE C SIN AZUCAR BERRY",
              "Price": "1.30",
              "Units": 56,
              "Sales": 60.02,
              "GM": 25.96
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082740237,
              "Name": "TM Tia Maruca Biscuit 140gr",
              "Price": "3.50",
              "Units": 21,
              "Sales": 60.71,
              "GM": 25.85
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770329463015,
              "Name": "Lugares",
              "Price": "12.90",
              "Units": 2,
              "Sales": 25.8,
              "GM": 25.8
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040929609,
              "Name": "BGLY Surtido 400gr",
              "Price": "4.80",
              "Units": 16,
              "Sales": 63.51,
              "GM": 25.77
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "BAGGIO",
              "EAN": 7790036512228,
              "Name": "RPBB Baggio Manzana 1L",
              "Price": "4.50",
              "Units": 15,
              "Sales": 55.96,
              "GM": 25.7
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 5022650272687,
              "Name": "SFA Fram Filtro CH 9973",
              "Price": "43.00",
              "Units": 2,
              "Sales": 71.08,
              "GM": 25.68
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770328427001,
              "Name": "Weekend",
              "Price": "8.50",
              "Units": 3,
              "Sales": 25.5,
              "GM": 25.5
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "KRAFT",
              "EAN": 7622300190323,
              "Name": "KR CEREALIT CAPRI QUECIBOU 100G",
              "Price": "2.90",
              "Units": 29,
              "Sales": 69.59,
              "GM": 25.46
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040003569,
              "Name": "BGLY Rex Original 75 Grs",
              "Price": "1.80",
              "Units": 40,
              "Sales": 59.6,
              "GM": 25.39
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "ARCOR",
              "EAN": 7790580219000,
              "Name": "CABSHA CAJA ACRILIC 18 UN.",
              "Price": "12.00",
              "Units": 6,
              "Sales": 59.51,
              "GM": 25.33
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7791249999547,
              "Name": "CAD Mantecol 40g",
              "Price": "1.60",
              "Units": 45,
              "Sales": 59.41,
              "GM": 25.3
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813420415,
              "Name": "PST Pomelo Light 500ml",
              "Price": "3.10",
              "Units": 19,
              "Sales": 49.02,
              "GM": 25.13
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580395209,
              "Name": "Bombon Helado 60ml",
              "Price": "1.50",
              "Units": 50,
              "Sales": 62,
              "GM": 25.09
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77912824,
              "Name": "PM Next FF KS 20und",
              "Price": "2.64",
              "Units": 142,
              "Sales": 348.74,
              "GM": 25.06
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548808,
              "Name": "DNN SER SPORT NARANJA 600ML",
              "Price": "4.25",
              "Units": 17,
              "Sales": 59.67,
              "GM": 24.99
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222041529,
              "Name": "BNFD BOMBON MOUSE 30GRS",
              "Price": "1.70",
              "Units": 47,
              "Sales": 65.86,
              "GM": 24.97
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 60,
              "Subcategory": "Caramelos en bolsa",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7791249792001,
              "Name": "CAD Yummy 25 Gr",
              "Price": "1.00",
              "Units": 87,
              "Sales": 72.05,
              "GM": 24.92
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "ARCOR",
              "EAN": 7790040934801,
              "Name": "BAGLEY BUDIN VAINILLA 250G",
              "Price": "6.00",
              "Units": 15,
              "Sales": 74.4,
              "GM": 24.9
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "KRAFT",
              "EAN": 77916495,
              "Name": "KR Oreo Triple 55g",
              "Price": "2.30",
              "Units": 36,
              "Sales": 68.4,
              "GM": 24.84
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "OTHER",
              "EAN": 7895144610757,
              "Name": "Mentos Menta Helada",
              "Price": "1.40",
              "Units": 46,
              "Sales": 53.32,
              "GM": 24.69
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222041628,
              "Name": "BNFD Bonafide Cubanito Choco 74g",
              "Price": "6.20",
              "Units": 12,
              "Sales": 61.45,
              "GM": 24.67
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548433,
              "Name": "DNN Ser Agua Plus Lima Limon 0.5 L",
              "Price": "3.10",
              "Units": 20,
              "Sales": 51.4,
              "GM": 24.66
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "ARCOR",
              "EAN": 7790580716707,
              "Name": "Saladix Jamon 100gr",
              "Price": "2.80",
              "Units": 28,
              "Sales": 64.69,
              "GM": 24.65
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "KRAFT",
              "EAN": 7794910011531,
              "Name": "KR Cerealitas Pocket 105 Gr",
              "Price": "1.40",
              "Units": 52,
              "Sales": 60.3,
              "GM": 24.62
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "FELFORT",
              "EAN": 1137,
              "Name": "FLFR LICONFORT BOMBOM CHERRY 13G",
              "Price": "1.07",
              "Units": 70,
              "Sales": 61.8,
              "GM": 24.36
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 149,
              "Subcategory": "ANALGESICOS",
              "Manufacturer": "GLAXO SMITHKLINE",
              "EAN": 9804,
              "Name": "GSK Geniol Tira 10und",
              "Price": "2.25",
              "Units": 20,
              "Sales": 44.9,
              "GM": 24.34
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7802215512247,
              "Name": "BNFD Costa Frac Galletita Class 135g",
              "Price": "2.80",
              "Units": 29,
              "Sales": 67.03,
              "GM": 24.3
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 7797674049468,
              "Name": "GMA NATURE VALLEY BANANA Y ALM",
              "Price": "1.52",
              "Units": 47,
              "Sales": 58.92,
              "GM": 24.22
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77925077,
              "Name": "SUGUS CREAMY FRUTILLA 18G",
              "Price": "0.80",
              "Units": 83,
              "Sales": 54.79,
              "GM": 24.13
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "UNILEVER",
              "EAN": 7794000730021,
              "Name": "UNI Ades Manzana 200ml",
              "Price": "2.00",
              "Units": 33,
              "Sales": 54.78,
              "GM": 24.11
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 170,
              "Subcategory": "SMOKING ACCESSOIRES",
              "Manufacturer": "BIC",
              "EAN": 70330909229,
              "Name": "BIC Bic Full Size Encendedor",
              "Price": "4.40",
              "Units": 13,
              "Sales": 47.3,
              "GM": 24.03
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771666343008,
              "Name": "Paparazzi",
              "Price": "4.00",
              "Units": 6,
              "Sales": 24,
              "GM": 24
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770329242009,
              "Name": "Planeta Urbano",
              "Price": "12.00",
              "Units": 2,
              "Sales": 24,
              "GM": 24
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 179,
              "Subcategory": "NEWSPAPERS",
              "Manufacturer": "OTHER",
              "EAN": 7620,
              "Name": "Clarin Miercoles",
              "Price": "2.00",
              "Units": 12,
              "Sales": 24,
              "GM": 24
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813421245,
              "Name": "H2OH Citrus 1.5L",
              "Price": "4.50",
              "Units": 12,
              "Sales": 44.76,
              "GM": 23.97
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222041062,
              "Name": "BNFD NUGATON BLANCO 27GR",
              "Price": "1.21",
              "Units": 42,
              "Sales": 41.86,
              "GM": 23.86
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7794600458523,
              "Name": "KR BANADOS RHODESIA 75GR",
              "Price": "2.10",
              "Units": 53,
              "Sales": 92.18,
              "GM": 23.84
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77915627,
              "Name": "PM Benson & Hedges 100 Box 20und",
              "Price": "4.31",
              "Units": 82,
              "Sales": 330.01,
              "GM": 23.82
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790407490605,
              "Name": "Cofler Aireado blanco leche 6",
              "Price": "3.60",
              "Units": 19,
              "Sales": 56.62,
              "GM": 23.81
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 77921598,
              "Name": "KR MILKA M JOY CASTANAS CARAMELO",
              "Price": "1.60",
              "Units": 66,
              "Sales": 87.14,
              "GM": 23.76
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 76145513,
              "Name": "KR Toblerone 50gr",
              "Price": "4.50",
              "Units": 16,
              "Sales": 59.52,
              "GM": 23.75
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790040439306,
              "Name": "ARC ARCOR MANA RELLENAS CHOCO",
              "Price": "2.90",
              "Units": 26,
              "Sales": 62.4,
              "GM": 23.74
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77918536,
              "Name": "CAD Halls Fr Frut Mentol Eucalip 20G",
              "Price": "1.30",
              "Units": 51,
              "Sales": 54.59,
              "GM": 23.72
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771668482002,
              "Name": "FTV Mag",
              "Price": "7.90",
              "Units": 3,
              "Sales": 23.7,
              "GM": 23.7
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771851361008,
              "Name": "PLAN D",
              "Price": "7.90",
              "Units": 3,
              "Sales": 23.7,
              "GM": 23.7
          },
          {
              "CategoryId": 13,
              "Category": "Otro Tabaco",
              "SubcategoryId": 15,
              "Subcategory": "Cigarros",
              "Manufacturer": "PURO TABACO",
              "EAN": 8500001542131,
              "Name": "RJ Romeo y Julieta Purito",
              "Price": "12.00",
              "Units": 4,
              "Sales": 40.36,
              "GM": 23.68
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7793940133299,
              "Name": "DNN Ser Cereales muesli 191gr",
              "Price": "2.47",
              "Units": 38,
              "Sales": 77.51,
              "GM": 23.65
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548341,
              "Name": "DNN SER NARANJ DURAZN 2 LTS",
              "Price": "5.10",
              "Units": 13,
              "Sales": 54.73,
              "GM": 23.63
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "ARCOR",
              "EAN": 7790580476007,
              "Name": "La Campagnola BC Manzana 250ml",
              "Price": "1.90",
              "Units": 34,
              "Sales": 53.71,
              "GM": 23.62
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6210,
              "Name": "SFA Filtros Fram PH-5317",
              "Price": "26.08",
              "Units": 2,
              "Sales": 43.1,
              "GM": 23.45
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222002827,
              "Name": "BNFD DRF Naranja 23 Gr",
              "Price": "0.82",
              "Units": 76,
              "Sales": 51.14,
              "GM": 23.28
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 60,
              "Subcategory": "Caramelos en bolsa",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222001844,
              "Name": "BNFD Billiken Gom Tutti-Frutti 30gr",
              "Price": "0.91",
              "Units": 62,
              "Sales": 46.67,
              "GM": 23.05
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 62,
              "Subcategory": "Caramelos sueltos",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 1153,
              "Name": "CAD Palitos de la Selva 3.1gr",
              "Price": "0.10",
              "Units": 477,
              "Sales": 39.14,
              "GM": 23.04
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "JORGITO",
              "EAN": 7790957000538,
              "Name": "JRG Jorgito Blanco 155gr",
              "Price": "3.70",
              "Units": 19,
              "Sales": 58.14,
              "GM": 22.97
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 60,
              "Subcategory": "Caramelos en bolsa",
              "Manufacturer": "ARCOR",
              "EAN": 7790580169312,
              "Name": "Fruti Gelatin Cerebritos Rell 30g",
              "Price": "1.10",
              "Units": 59,
              "Sales": 53.69,
              "GM": 22.88
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7794600458530,
              "Name": "KR BANADOS SNACK 75 GRS",
              "Price": "2.10",
              "Units": 51,
              "Sales": 88.66,
              "GM": 22.85
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7794600459605,
              "Name": "KR Terrabusi Variedad  Dorada 300g",
              "Price": "4.20",
              "Units": 16,
              "Sales": 55.52,
              "GM": 22.8
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77910059,
              "Name": "Lucky Strike KS 20und",
              "Price": "3.93",
              "Units": 89,
              "Sales": 325.49,
              "GM": 22.79
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77918543,
              "Name": "CAD Halls Free Menta Mentol 20gr",
              "Price": "1.30",
              "Units": 49,
              "Sales": 52.48,
              "GM": 22.74
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300130213,
              "Name": "KR TERRABUSI ALFAJOR MIX 55G",
              "Price": "2.30",
              "Units": 25,
              "Sales": 47.5,
              "GM": 22.73
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7793940104015,
              "Name": "DNN Ser Firme Vainilla 200gr",
              "Price": "1.58",
              "Units": 75,
              "Sales": 98.24,
              "GM": 22.61
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 3415581534276,
              "Name": "GMA Haagen Dazs Cookies & Cream 100ml",
              "Price": "8.50",
              "Units": 9,
              "Sales": 63.2,
              "GM": 22.59
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "NESTLE",
              "EAN": 79200311025,
              "Name": "IMS: Wonka Runt 6 oz",
              "Price": "9.00",
              "Units": 8,
              "Sales": 59.52,
              "GM": 22.56
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "NESTLE",
              "EAN": 79200158866,
              "Name": "NES Wonka Sweetarts 170g",
              "Price": "9.00",
              "Units": 8,
              "Sales": 59.52,
              "GM": 22.56
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771850413005,
              "Name": "7 DIAS",
              "Price": "4.50",
              "Units": 5,
              "Sales": 22.5,
              "GM": 22.5
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 135,
              "Subcategory": "CAFE Y TE",
              "Manufacturer": "MOL RIO DELA PLATA",
              "EAN": 7790387120370,
              "Name": "ELM Termo Taragui",
              "Price": "7.73",
              "Units": 8,
              "Sales": 51.1,
              "GM": 22.4
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7794600458554,
              "Name": "KR BANADOS HABANITOS 75GRS",
              "Price": "2.10",
              "Units": 43,
              "Sales": 74.79,
              "GM": 22.34
          },
          {
              "CategoryId": 13,
              "Category": "Otro Tabaco",
              "SubcategoryId": 15,
              "Subcategory": "Cigarros",
              "Manufacturer": "PURO TABACO",
              "EAN": 8810,
              "Name": "PTBC Fonseca Delicias Und",
              "Price": "22.00",
              "Units": 2,
              "Sales": 36.94,
              "GM": 22.24
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040938908,
              "Name": "BGLY REX ORIGINAL 125G",
              "Price": "3.20",
              "Units": 21,
              "Sales": 55.45,
              "GM": 22.2
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895064906,
              "Name": "QUATRO POMELO LIGHT 500ML",
              "Price": "3.30",
              "Units": 17,
              "Sales": 46.39,
              "GM": 22.15
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BAGLEY",
              "EAN": 77903518,
              "Name": "BGLY Opera 92gr",
              "Price": "2.60",
              "Units": 23,
              "Sales": 49.45,
              "GM": 22.13
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 93,
              "Subcategory": "HELADOS PREMIUM",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 3415581377149,
              "Name": "GM HAAG DAZS V CARA BROWN 100ML",
              "Price": "8.50",
              "Units": 9,
              "Sales": 63.18,
              "GM": 22.1
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77933416,
              "Name": "Cofler Leche  Alm 28g",
              "Price": "1.40",
              "Units": 46,
              "Sales": 53.29,
              "GM": 22.03
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771668284002,
              "Name": "El Federal",
              "Price": "5.50",
              "Units": 4,
              "Sales": 22,
              "GM": 22
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 28400055116,
              "Name": "FL Lays Stax Cheddar 155gr",
              "Price": "9.50",
              "Units": 8,
              "Sales": 62.8,
              "GM": 21.92
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040929906,
              "Name": "BGLY Chocolinas 170gr",
              "Price": "3.00",
              "Units": 21,
              "Sales": 52.08,
              "GM": 21.85
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771667431001,
              "Name": "Jardin de Genios",
              "Price": "10.90",
              "Units": 2,
              "Sales": 21.8,
              "GM": 21.8
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 9311,
              "Name": "FANTA BOTELLA 237ML",
              "Price": "2.30",
              "Units": 23,
              "Sales": 43.73,
              "GM": 21.79
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 60,
              "Subcategory": "Caramelos en bolsa",
              "Manufacturer": "ARCOR",
              "EAN": 7790580039301,
              "Name": "SUGUS SORPRESA 33GR",
              "Price": "2.80",
              "Units": 27,
              "Sales": 62.41,
              "GM": 21.76
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954051112,
              "Name": "SFA Filtros Fram CA-5111",
              "Price": "24.72",
              "Units": 2,
              "Sales": 40.86,
              "GM": 21.71
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040930407,
              "Name": "BGLY Amor 112g",
              "Price": "2.70",
              "Units": 22,
              "Sales": 49.06,
              "GM": 21.66
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "ARCOR",
              "EAN": 7790040351103,
              "Name": "Cereal Mix Yogurt Frut X 3  84g",
              "Price": "4.10",
              "Units": 18,
              "Sales": 61.02,
              "GM": 21.62
          },
          {
              "CategoryId": 65,
              "Category": "Leches",
              "SubcategoryId": 66,
              "Subcategory": "WHOLE MILK",
              "Manufacturer": "DANONE",
              "EAN": 7793940345005,
              "Name": "DNN La Serenis Lech Ent Larga D",
              "Price": "3.30",
              "Units": 34,
              "Sales": 92.76,
              "GM": 21.51
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CHUPA CHUPS",
              "EAN": 84196033,
              "Name": "CH Smint White Fresa",
              "Price": "3.50",
              "Units": 20,
              "Sales": 57.82,
              "GM": 21.43
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082740268,
              "Name": "TM Tia Maruca Bizc de Grasa 250g",
              "Price": "3.50",
              "Units": 17,
              "Sales": 49.15,
              "GM": 21.39
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040929807,
              "Name": "BGLY Coquitas 170gr",
              "Price": "2.90",
              "Units": 22,
              "Sales": 52.79,
              "GM": 21.36
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "KRAFT",
              "EAN": 7622300093952,
              "Name": "KR CLUB SOCIAL CHIPS PARMES 40G",
              "Price": "2.40",
              "Units": 23,
              "Sales": 45.55,
              "GM": 21.32
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040932708,
              "Name": "BGLY Merengadas 93gr",
              "Price": "2.70",
              "Units": 22,
              "Sales": 49.06,
              "GM": 21.17
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "ARCOR",
              "EAN": 77916426,
              "Name": "Topline Tableta Menta 4und",
              "Price": "0.90",
              "Units": 62,
              "Sales": 45.97,
              "GM": 21.13
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310980736,
              "Name": "FL Doritos Queso 85G",
              "Price": "3.90",
              "Units": 18,
              "Sales": 57.97,
              "GM": 21.07
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7702133264108,
              "Name": "CAD HALLS CRUNCHY MENTA 18G",
              "Price": "1.10",
              "Units": 58,
              "Sales": 52.78,
              "GM": 21.06
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7794600460083,
              "Name": "KR Pepitos Rellenas Chocolate 130gr",
              "Price": "2.50",
              "Units": 23,
              "Sales": 47.59,
              "GM": 21.02
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954045586,
              "Name": "SFA Filtros Fram PH-4558",
              "Price": "17.97",
              "Units": 3,
              "Sales": 44.56,
              "GM": 20.95
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6743,
              "Name": "SFA Filtros Fram G-5857",
              "Price": "34.13",
              "Units": 2,
              "Sales": 56.42,
              "GM": 20.92
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "GEORGALOS",
              "EAN": 77909497,
              "Name": "GR CHOCGEORGALOS S/A FRUT30G",
              "Price": "3.00",
              "Units": 20,
              "Sales": 49.6,
              "GM": 20.8
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "ARCOR",
              "EAN": 7790580591816,
              "Name": "TOPLINE C S/A FRUTA 12GR",
              "Price": "1.20",
              "Units": 55,
              "Sales": 54.47,
              "GM": 20.8
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "KRAFT",
              "EAN": 7622300093938,
              "Name": "KR CLUB SOCIAL CHIPS CREMCEB 40G",
              "Price": "2.40",
              "Units": 22,
              "Sales": 43.59,
              "GM": 20.78
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "OTHER",
              "EAN": 37000424536,
              "Name": "LMAR PRINGLES ONION 80GR",
              "Price": "9.00",
              "Units": 7,
              "Sales": 52.08,
              "GM": 20.78
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770328488002,
              "Name": "Muy Interesante",
              "Price": "6.90",
              "Units": 3,
              "Sales": 20.7,
              "GM": 20.7
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771669825006,
              "Name": "TKM Revista",
              "Price": "6.90",
              "Units": 3,
              "Sales": 20.7,
              "GM": 20.7
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7798011660674,
              "Name": "TPS Cuerda Emergencia Remolq Und",
              "Price": "30.00",
              "Units": 2,
              "Sales": 49.58,
              "GM": 20.68
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77946805,
              "Name": "Mentho Plus S.Az Cherry 26.6",
              "Price": "1.50",
              "Units": 37,
              "Sales": 45.88,
              "GM": 20.56
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7793940016028,
              "Name": "DNN Yogurisimo Firme Frutilla 200gr",
              "Price": "1.51",
              "Units": 71,
              "Sales": 88.59,
              "GM": 20.49
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 60,
              "Subcategory": "Caramelos en bolsa",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222001868,
              "Name": "BNFD Billiken Gom frutas 30gr",
              "Price": "0.91",
              "Units": 55,
              "Sales": 41.49,
              "GM": 20.46
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790040677005,
              "Name": "TORTITAS CHOCOLATE 125GR",
              "Price": "2.60",
              "Units": 23,
              "Sales": 49.45,
              "GM": 20.4
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580394806,
              "Name": "Arcor Slice Frutilla 70ml",
              "Price": "2.00",
              "Units": 33,
              "Sales": 54.51,
              "GM": 20.39
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222041659,
              "Name": "BNFD CHOCO BONAFIDE ALMENDRA 25G",
              "Price": "1.66",
              "Units": 31,
              "Sales": 42.58,
              "GM": 20.27
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7798011660599,
              "Name": "TPS Conector Bateria 2und",
              "Price": "31.50",
              "Units": 2,
              "Sales": 52.06,
              "GM": 20.26
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "ARCOR",
              "EAN": 7790580697303,
              "Name": "Saladix Pizza 100gr",
              "Price": "2.80",
              "Units": 22,
              "Sales": 50.83,
              "GM": 20.22
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580396909,
              "Name": "Arcor Kopa Frutilla 190ml",
              "Price": "3.50",
              "Units": 18,
              "Sales": 52.07,
              "GM": 20.19
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "KRAFT",
              "EAN": 77939234,
              "Name": "KR Terrabusi Choc Simple Alfaj 50gr",
              "Price": "1.60",
              "Units": 42,
              "Sales": 55.46,
              "GM": 20.18
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310980897,
              "Name": "FL  CHEETOS CLASSIC 55G",
              "Price": "2.40",
              "Units": 28,
              "Sales": 55.44,
              "GM": 20.16
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "OTHER",
              "EAN": 7895144610788,
              "Name": "Caramelos Mentos Frutilla",
              "Price": "1.40",
              "Units": 43,
              "Sales": 49.86,
              "GM": 20.11
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895065200,
              "Name": "Cepita Multifruta 250ml",
              "Price": "1.70",
              "Units": 27,
              "Sales": 38.07,
              "GM": 20.05
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 191,
              "Subcategory": "BRAKE FLUIDS",
              "Manufacturer": "WAGNER BRAKE",
              "EAN": 7797409672626,
              "Name": "WAG Wagner Lockh Dot 4 Liq Frenos 1L",
              "Price": "48.00",
              "Units": 1,
              "Sales": 39.67,
              "GM": 20.01
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 7609,
              "Name": "Joy",
              "Price": "10.00",
              "Units": 2,
              "Sales": 20,
              "GM": 20
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770325068009,
              "Name": "MERCADO",
              "Price": "10.00",
              "Units": 2,
              "Sales": 20,
              "GM": 20
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6643,
              "Name": "SFA Filtros Fram CA-8675",
              "Price": "25.52",
              "Units": 2,
              "Sales": 42.18,
              "GM": 19.98
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895064333,
              "Name": "Cepita Naranja 1L",
              "Price": "4.50",
              "Units": 10,
              "Sales": 37.3,
              "GM": 19.93
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7794600459124,
              "Name": "KR OREO CHOCOLATE 111GR",
              "Price": "2.50",
              "Units": 24,
              "Sales": 49.66,
              "GM": 19.9
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580396008,
              "Name": "Arcor Cofler Blanco 80ml",
              "Price": "4.00",
              "Units": 16,
              "Sales": 52.93,
              "GM": 19.89
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77921406,
              "Name": "CAD HALLS UVA VERDE 27GR",
              "Price": "1.20",
              "Units": 45,
              "Sales": 44.56,
              "GM": 19.87
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895640049,
              "Name": " POWERADE NARAN LIGHT 0,5 LTS",
              "Price": "3.25",
              "Units": 12,
              "Sales": 32.27,
              "GM": 19.74
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895065217,
              "Name": "Cepita Multifrutas 1L",
              "Price": "4.50",
              "Units": 9,
              "Sales": 33.57,
              "GM": 19.71
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 135,
              "Subcategory": "CAFE Y TE",
              "Manufacturer": "MOL RIO DELA PLATA",
              "EAN": 7790387110234,
              "Name": "MRP Taragui Yerba Palo 500gr",
              "Price": "5.80",
              "Units": 10,
              "Sales": 47.95,
              "GM": 19.7
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "ARCOR",
              "EAN": 7790580489601,
              "Name": "Arcor Bombones de Fruta 500g",
              "Price": "12.00",
              "Units": 5,
              "Sales": 49.6,
              "GM": 19.69
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790407049001,
              "Name": "Cofler Aireado Leche 100g",
              "Price": "6.70",
              "Units": 8,
              "Sales": 44.31,
              "GM": 19.62
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 47,
              "Subcategory": "Bebidas Deportivas",
              "Manufacturer": "PEPSI",
              "EAN": 7792170002818,
              "Name": "Gatorade Naranja 946ml",
              "Price": "6.50",
              "Units": 8,
              "Sales": 42.96,
              "GM": 19.6
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77977731,
              "Name": "PM Virginia Lights Soft 20",
              "Price": "4.33",
              "Units": 61,
              "Sales": 245.29,
              "GM": 19.6
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790580349011,
              "Name": "COFLER LECHE X 55G",
              "Price": "3.40",
              "Units": 17,
              "Sales": 47.77,
              "GM": 19.55
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771668548005,
              "Name": "Maxim Argentina",
              "Price": "6.50",
              "Units": 3,
              "Sales": 19.5,
              "GM": 19.5
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "KRAFT",
              "EAN": 77921697,
              "Name": "KRA ALFAJOR TERRABU DUL LECH 48G",
              "Price": "1.40",
              "Units": 40,
              "Sales": 46.36,
              "GM": 19.47
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6653,
              "Name": "SFA Filtros Fram CA-8963",
              "Price": "61.24",
              "Units": 1,
              "Sales": 50.61,
              "GM": 19.38
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790407049018,
              "Name": "Cofler Aireado  Mixto 100g",
              "Price": "6.70",
              "Units": 8,
              "Sales": 44.32,
              "GM": 19.37
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6796,
              "Name": "SFA Filtros Fram P-5328",
              "Price": "57.83",
              "Units": 1,
              "Sales": 47.79,
              "GM": 19.37
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 3415581332162,
              "Name": "GMA Haagen Dazs Macadam Brittle 100ml",
              "Price": "8.50",
              "Units": 8,
              "Sales": 56.16,
              "GM": 19.36
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77946836,
              "Name": "Menthoplus S Az Pomelo 26.6g",
              "Price": "1.50",
              "Units": 33,
              "Sales": 40.92,
              "GM": 19.31
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790040676800,
              "Name": "Tortitas Black 125gr",
              "Price": "2.60",
              "Units": 22,
              "Sales": 47.3,
              "GM": 19.31
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7802215512254,
              "Name": "BNFD COSTA FRAC GALLETI VAINILLA",
              "Price": "2.80",
              "Units": 23,
              "Sales": 53.16,
              "GM": 19.29
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813421269,
              "Name": "PEPSI MAX 1.5L",
              "Price": "5.40",
              "Units": 9,
              "Sales": 40.5,
              "GM": 19.16
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222042427,
              "Name": "BNFD DONUTS CHOCOLATE 78 GR",
              "Price": "4.20",
              "Units": 9,
              "Sales": 31.23,
              "GM": 19.16
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "ARCOR",
              "EAN": 7790040298903,
              "Name": "Aguila Mini Torta Coco 73gr",
              "Price": "2.20",
              "Units": 24,
              "Sales": 43.68,
              "GM": 19.15
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77916198,
              "Name": "PM Le Mans Suave 100 20und",
              "Price": "3.59",
              "Units": 57,
              "Sales": 191.1,
              "GM": 19.02
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "PEPSI",
              "EAN": 7792799005214,
              "Name": "Nestle Nar Dur 1500ml",
              "Price": "4.40",
              "Units": 10,
              "Sales": 36.5,
              "GM": 18.97
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6214,
              "Name": "SFA Fram  PH 9566",
              "Price": "40.00",
              "Units": 1,
              "Sales": 33.06,
              "GM": 18.91
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "OTHER",
              "EAN": 5011053014956,
              "Name": "TPPS Spray Pop 14ml",
              "Price": "5.70",
              "Units": 8,
              "Sales": 37.71,
              "GM": 18.9
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954869267,
              "Name": "SFA Fram Filtro CA 9511",
              "Price": "28.00",
              "Units": 2,
              "Sales": 46.28,
              "GM": 18.84
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 81,
              "Subcategory": "OTHER DAIRY",
              "Manufacturer": "DANONE",
              "EAN": 7791337222151,
              "Name": "DDNN Activia Manz-Ciruela 200gr",
              "Price": "2.18",
              "Units": 51,
              "Sales": 92.06,
              "GM": 18.77
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 164,
              "Subcategory": "BATTERIES",
              "Manufacturer": "ENERGIZER",
              "EAN": 39800027955,
              "Name": "ENZ Energizer Titanium AA 4",
              "Price": "12.50",
              "Units": 4,
              "Sales": 41.32,
              "GM": 18.68
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580398903,
              "Name": "Arcor Cono Snack 75ml",
              "Price": "2.50",
              "Units": 21,
              "Sales": 43.43,
              "GM": 18.64
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77910684,
              "Name": "Jockey Club Suave 100 S 20und",
              "Price": "3.56",
              "Units": 79,
              "Sales": 261.61,
              "GM": 18.62
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 165,
              "Subcategory": "FILM / PHOTO",
              "Manufacturer": "KODAK",
              "EAN": 86806033947,
              "Name": "KOD KODAK GOLD PLUS 100 X 36",
              "Price": "21.00",
              "Units": 2,
              "Sales": 34.72,
              "GM": 18.58
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "FELFORT",
              "EAN": 7790206515301,
              "Name": "FLFR JACKS HUEVO 14GR",
              "Price": "5.30",
              "Units": 10,
              "Sales": 43.82,
              "GM": 18.57
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 216,
              "Subcategory": "COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2046,
              "Name": "CAF� AMERICANO VIEN�S GRANDE",
              "Price": "4.50",
              "Units": 8,
              "Sales": 29.76,
              "GM": 18.56
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 149,
              "Subcategory": "ANALGESICOS",
              "Manufacturer": "BAYER",
              "EAN": 9801,
              "Name": "BY Bayaspirina C Calien 3.385gr",
              "Price": "1.44",
              "Units": 31,
              "Sales": 44.6,
              "GM": 18.56
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895064890,
              "Name": "Quatro Pomelo Light 1.5L",
              "Price": "5.50",
              "Units": 10,
              "Sales": 45.5,
              "GM": 18.54
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040941601,
              "Name": "BGLY Ser Dulces 125 Gr",
              "Price": "2.90",
              "Units": 20,
              "Sales": 48,
              "GM": 18.4
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222041635,
              "Name": "BNFD Chocolate Bonafide Leche",
              "Price": "1.56",
              "Units": 32,
              "Sales": 41.33,
              "GM": 18.34
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7794600460106,
              "Name": "KR Pepitos Rellenas Vainilla 130gr",
              "Price": "2.50",
              "Units": 20,
              "Sales": 41.39,
              "GM": 18.28
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "FELFORT",
              "EAN": 77909053,
              "Name": "FLFR Cereal Fort Manzana 22g",
              "Price": "1.50",
              "Units": 33,
              "Sales": 40.92,
              "GM": 18.22
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "OTHER",
              "EAN": 53997784,
              "Name": "Push Porp Triple 34 gr",
              "Price": "6.91",
              "Units": 7,
              "Sales": 39.96,
              "GM": 18.22
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "OTHER",
              "EAN": 7891962021607,
              "Name": "BAUDUCCO TOSTADA INTEGRAL 140G",
              "Price": "4.30",
              "Units": 14,
              "Sales": 49.71,
              "GM": 18.21
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "DON SATUR",
              "EAN": 7795735000342,
              "Name": "DST Don Satur Bizcocho Negro 200",
              "Price": "2.30",
              "Units": 24,
              "Sales": 45.6,
              "GM": 18.18
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77913258,
              "Name": "PM Next FF Box 20und",
              "Price": "2.79",
              "Units": 89,
              "Sales": 231.08,
              "GM": 18.02
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771668204000,
              "Name": "LA VALIJITA",
              "Price": "9.00",
              "Units": 2,
              "Sales": 18,
              "GM": 18
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580396800,
              "Name": "Arcor Kopa Dulce Leche 190ml",
              "Price": "3.50",
              "Units": 16,
              "Sales": 46.28,
              "GM": 17.93
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813421542,
              "Name": "7 UP Light 1.5L",
              "Price": "5.40",
              "Units": 9,
              "Sales": 40.32,
              "GM": 17.85
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 9320,
              "Name": "SPRITE BOTELLA 237ML",
              "Price": "2.30",
              "Units": 19,
              "Sales": 36.1,
              "GM": 17.8
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771668300009,
              "Name": "La Mano",
              "Price": "8.90",
              "Units": 2,
              "Sales": 17.8,
              "GM": 17.8
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7793940333026,
              "Name": "DNN Yogurisimo Beb Frutilla 190 grs",
              "Price": "2.06",
              "Units": 45,
              "Sales": 76.65,
              "GM": 17.76
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7802215505409,
              "Name": "BNFD COSTA GALLETITA COCO 125G",
              "Price": "2.50",
              "Units": 27,
              "Sales": 55.89,
              "GM": 17.75
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "MOLINO CANUELAS",
              "EAN": 7792200000319,
              "Name": "MLC 9 de Oro Bizcocho Azucar 210gr",
              "Price": "2.60",
              "Units": 19,
              "Sales": 40.85,
              "GM": 17.73
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "BAGGIO",
              "EAN": 7790036972497,
              "Name": "RPBB Baggio Naranja 200ml",
              "Price": "1.70",
              "Units": 24,
              "Sales": 33.84,
              "GM": 17.55
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77954923,
              "Name": "TOPLINE ONE MENTA 0.8GR",
              "Price": "3.80",
              "Units": 14,
              "Sales": 43.96,
              "GM": 17.55
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 81,
              "Subcategory": "OTHER DAIRY",
              "Manufacturer": "DANONE",
              "EAN": 7791337571853,
              "Name": "DNN Actimel Multifruta 100gr",
              "Price": "1.48",
              "Units": 49,
              "Sales": 59.8,
              "GM": 17.53
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954869175,
              "Name": "SFA Fram Filtro CA 9315",
              "Price": "45.36",
              "Units": 1,
              "Sales": 37.49,
              "GM": 17.51
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040930209,
              "Name": "BGLY Mellizas 112G",
              "Price": "2.70",
              "Units": 18,
              "Sales": 40.14,
              "GM": 17.51
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790580665708,
              "Name": "Cofler Almendras 55gr",
              "Price": "3.40",
              "Units": 14,
              "Sales": 39.34,
              "GM": 17.45
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "KRAFT",
              "EAN": 7794910452372,
              "Name": "KR CEREALITAS COPOS 170GR",
              "Price": "2.50",
              "Units": 21,
              "Sales": 43.47,
              "GM": 17.42
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7791249010525,
              "Name": "CAD ADAMS MENTOL",
              "Price": "0.80",
              "Units": 71,
              "Sales": 46.86,
              "GM": 17.39
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310979952,
              "Name": "FL LAYS MEDITERRANEA TOMAT Y ALB",
              "Price": "2.40",
              "Units": 24,
              "Sales": 47.52,
              "GM": 17.28
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77920355,
              "Name": "Jockey Club KS 20und",
              "Price": "3.41",
              "Units": 83,
              "Sales": 262.89,
              "GM": 17.26
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7793940333019,
              "Name": "DNN Yogurisimo Beb Vainilla 190 grs",
              "Price": "2.01",
              "Units": 47,
              "Sales": 78.29,
              "GM": 17.23
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813421351,
              "Name": "Pepsi Light 1.5L",
              "Price": "5.40",
              "Units": 9,
              "Sales": 40.5,
              "GM": 17.22
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6208,
              "Name": "SFA Filtros Fram PH-3593A",
              "Price": "34.81",
              "Units": 1,
              "Sales": 28.77,
              "GM": 17.22
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "OTHER",
              "EAN": 7793427000908,
              "Name": "SIL Mr Sapito Lav Parabri 42ml",
              "Price": "4.50",
              "Units": 12,
              "Sales": 44.64,
              "GM": 17.04
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "FUSEL",
              "EAN": 7798011060030,
              "Name": "FSL Fusibles F Univ Ron Bay 8und",
              "Price": "5.50",
              "Units": 9,
              "Sales": 40.94,
              "GM": 16.98
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77926258,
              "Name": "PM L&M Light Box 20und",
              "Price": "3.64",
              "Units": 48,
              "Sales": 163.17,
              "GM": 16.93
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954868796,
              "Name": "SFA Fram Filtro CA 5680",
              "Price": "43.66",
              "Units": 1,
              "Sales": 36.08,
              "GM": 16.9
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "ARCOR",
              "EAN": 7790040613706,
              "Name": "BON O BON ALFAJOR NEGRO 40G",
              "Price": "1.40",
              "Units": 34,
              "Sales": 39.41,
              "GM": 16.9
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222042434,
              "Name": "BNFD BONAFIDE DONUTS BLANCAS 78 GRS",
              "Price": "4.20",
              "Units": 8,
              "Sales": 27.76,
              "GM": 16.76
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "ARCOR",
              "EAN": 7790040298804,
              "Name": "Aguila Mini Torta Brownie 74gr",
              "Price": "2.20",
              "Units": 21,
              "Sales": 38.22,
              "GM": 16.74
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222042229,
              "Name": "BNFD BONAFIDE BOCADITO BLANC 16G",
              "Price": "1.03",
              "Units": 34,
              "Sales": 29.07,
              "GM": 16.72
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 9317,
              "Name": "PST POMELO BOTELLA 237ML",
              "Price": "2.20",
              "Units": 19,
              "Sales": 34.77,
              "GM": 16.67
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77915542,
              "Name": "PM Benson & Hedges 100 20und",
              "Price": "4.34",
              "Units": 45,
              "Sales": 182.32,
              "GM": 16.62
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771667466003,
              "Name": "Fortuna",
              "Price": "8.30",
              "Units": 2,
              "Sales": 16.6,
              "GM": 16.6
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771514700007,
              "Name": "Sophia",
              "Price": "8.30",
              "Units": 2,
              "Sales": 16.6,
              "GM": 16.6
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310979914,
              "Name": "FL LAYS MEDITERRAN JAM SERRANO",
              "Price": "2.40",
              "Units": 23,
              "Sales": 45.54,
              "GM": 16.56
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 191,
              "Subcategory": "BRAKE FLUIDS",
              "Manufacturer": "WAGNER BRAKE",
              "EAN": 7797409183009,
              "Name": "WAG Wagner Lockheed Liq Fre 350ml",
              "Price": "14.50",
              "Units": 3,
              "Sales": 35.94,
              "GM": 16.53
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77922786,
              "Name": "CAD PowerMints Frutas",
              "Price": "2.30",
              "Units": 23,
              "Sales": 43.7,
              "GM": 16.51
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "P&G",
              "EAN": 37000117278,
              "Name": "PG PRINGLES ORIGINAL CHICA 43G",
              "Price": "6.00",
              "Units": 8,
              "Sales": 39.68,
              "GM": 16.31
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "CHUPA CHUPS",
              "EAN": 76350610053,
              "Name": "CH CRAZY DIPS FRESA 12G",
              "Price": "1.40",
              "Units": 34,
              "Sales": 39.38,
              "GM": 16.3
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7802215505270,
              "Name": "BNFD COSTA GALLETITA LIMON 140G",
              "Price": "2.50",
              "Units": 25,
              "Sales": 51.75,
              "GM": 16.3
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082740107,
              "Name": "TM Tia Maruca Coco 250gr",
              "Price": "3.50",
              "Units": 13,
              "Sales": 37.58,
              "GM": 16.27
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "ARCOR",
              "EAN": 77916501,
              "Name": "Cereal Mix 23g",
              "Price": "1.40",
              "Units": 29,
              "Sales": 33.59,
              "GM": 16.26
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7791249985915,
              "Name": "CAD MANTECOL 250G",
              "Price": "6.00",
              "Units": 9,
              "Sales": 44.64,
              "GM": 16.11
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580395308,
              "Name": "Arcor Mr Pops Max Crazy 100ml",
              "Price": "2.00",
              "Units": 26,
              "Sales": 42.95,
              "GM": 16.11
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "ARCOR",
              "EAN": 7790580716806,
              "Name": "Saladix Calabresa 100g",
              "Price": "2.80",
              "Units": 18,
              "Sales": 41.59,
              "GM": 16.1
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770329329007,
              "Name": "A Todo Motor",
              "Price": "8.00",
              "Units": 2,
              "Sales": 16,
              "GM": 16
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548938,
              "Name": "DNN VILLA DEL SUR NARAN CG 500ML",
              "Price": "2.90",
              "Units": 14,
              "Sales": 33.6,
              "GM": 15.96
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77921932,
              "Name": "KR MILKA M JOY LECHE 30G",
              "Price": "1.60",
              "Units": 46,
              "Sales": 60.72,
              "GM": 15.83
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771668351001,
              "Name": "Soy Rock",
              "Price": "7.90",
              "Units": 2,
              "Sales": 15.8,
              "GM": 15.8
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "NESTLE",
              "EAN": 22110079820,
              "Name": "NES Bubble Tape  Strawerry 56,7 gr",
              "Price": "6.70",
              "Units": 8,
              "Sales": 44.31,
              "GM": 15.75
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77995186,
              "Name": "CAD Beldent S/Azucar Tutti Frutt",
              "Price": "1.30",
              "Units": 33,
              "Sales": 35.33,
              "GM": 15.69
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 135,
              "Subcategory": "CAFE Y TE",
              "Manufacturer": "MOL RIO DELA PLATA",
              "EAN": 7790387120363,
              "Name": "MRP Taragui Mate Listo 35gr",
              "Price": "3.58",
              "Units": 12,
              "Sales": 35.49,
              "GM": 15.68
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 191,
              "Subcategory": "BRAKE FLUIDS",
              "Manufacturer": "WAGNER BRAKE",
              "EAN": 7797409030938,
              "Name": "WAG DOT3 Wagner Liq Fren 110ml",
              "Price": "8.50",
              "Units": 5,
              "Sales": 35.1,
              "GM": 15.64
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "QUILMES",
              "EAN": 7792799005245,
              "Name": "QLMS NPV Manzana 1500ml",
              "Price": "4.20",
              "Units": 9,
              "Sales": 31.33,
              "GM": 15.58
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 164,
              "Subcategory": "BATTERIES",
              "Manufacturer": "ENERGIZER",
              "EAN": 39800034083,
              "Name": "ENZ ENERGIZER LITHIUM AA2",
              "Price": "19.90",
              "Units": 2,
              "Sales": 32.9,
              "GM": 15.58
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 167,
              "Subcategory": "GREETING/GIFT/TOYS",
              "Manufacturer": "MATTEL",
              "EAN": 27084120134,
              "Name": "MTT HotWheels Autos Und",
              "Price": "10.00",
              "Units": 4,
              "Sales": 33.04,
              "GM": 15.56
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77922656,
              "Name": "Menthoplus Caramel Cherry 30.6",
              "Price": "1.00",
              "Units": 47,
              "Sales": 38.96,
              "GM": 15.47
          },
          {
              "CategoryId": 13,
              "Category": "Otro Tabaco",
              "SubcategoryId": 15,
              "Subcategory": "Cigarros",
              "Manufacturer": "PURO TABACO",
              "EAN": 8820,
              "Name": "PTBC Guantanamera Compay und",
              "Price": "9.26",
              "Units": 5,
              "Sales": 38.97,
              "GM": 15.46
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548914,
              "Name": "DNN Villa del Sur 6000 ml",
              "Price": "7.00",
              "Units": 11,
              "Sales": 63.98,
              "GM": 15.35
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895066436,
              "Name": "Dasani Mineralizada 1.5L",
              "Price": "3.30",
              "Units": 10,
              "Sales": 27.4,
              "GM": 15.3
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895066290,
              "Name": "DASANI BAL CITRUS C GAS 500ML",
              "Price": "2.40",
              "Units": 15,
              "Sales": 29.7,
              "GM": 15.2
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7793940104022,
              "Name": "DNN Ser Firme Frutilla 200gr",
              "Price": "1.66",
              "Units": 43,
              "Sales": 59.16,
              "GM": 15.2
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040726505,
              "Name": "BGLY BAGLEY  SALVADO BAG FIBRACT",
              "Price": "2.70",
              "Units": 16,
              "Sales": 35.68,
              "GM": 15.2
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "NESTLE",
              "EAN": 79200814441,
              "Name": "NES WONKA RUNTS CHEWY 52.4G",
              "Price": "3.50",
              "Units": 13,
              "Sales": 37.57,
              "GM": 15.14
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "NESTLE",
              "EAN": 22110058504,
              "Name": "NES BUBBLE GUM 53GRS",
              "Price": "7.90",
              "Units": 9,
              "Sales": 58.77,
              "GM": 15.1
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040939400,
              "Name": "BGLY Ser Equilibrio 120g",
              "Price": "2.00",
              "Units": 22,
              "Sales": 36.3,
              "GM": 15.09
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6205,
              "Name": "SFA Filtros Fram CA-5456",
              "Price": "22.79",
              "Units": 2,
              "Sales": 37.66,
              "GM": 15.08
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 81,
              "Subcategory": "OTHER DAIRY",
              "Manufacturer": "DANONE",
              "EAN": 7791337222212,
              "Name": "DNN Activia Pera Kiwi 200gr",
              "Price": "2.19",
              "Units": 41,
              "Sales": 74.2,
              "GM": 15.06
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77932136,
              "Name": "BON O BON BARRITA CHOCOL 35G",
              "Price": "1.60",
              "Units": 26,
              "Sales": 34.32,
              "GM": 15.05
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "ARCOR",
              "EAN": 7790580139506,
              "Name": "ARCOR CARAMEL CUBIX SORPRES",
              "Price": "2.80",
              "Units": 15,
              "Sales": 34.68,
              "GM": 15.04
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 7797674049383,
              "Name": "GMA Nature Avena y Miel 21g",
              "Price": "1.54",
              "Units": 29,
              "Sales": 36.85,
              "GM": 15.03
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770329652006,
              "Name": "BICICLUB",
              "Price": "7.50",
              "Units": 2,
              "Sales": 15,
              "GM": 15
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770329910007,
              "Name": "DMODE",
              "Price": "15.00",
              "Units": 1,
              "Sales": 15,
              "GM": 15
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BAGLEY",
              "EAN": 77903501,
              "Name": "BGLY Opera 55gr",
              "Price": "1.60",
              "Units": 28,
              "Sales": 36.96,
              "GM": 14.82
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 164,
              "Subcategory": "BATTERIES",
              "Manufacturer": "ENERGIZER",
              "EAN": 39800008688,
              "Name": "ENZ ENERGIZER RECHARGEABLE AA2",
              "Price": "37.00",
              "Units": 1,
              "Sales": 30.58,
              "GM": 14.76
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310980316,
              "Name": "FL Doritos Pizza 80gr",
              "Price": "3.90",
              "Units": 12,
              "Sales": 38.66,
              "GM": 14.7
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "BAGGIO",
              "EAN": 7790036972510,
              "Name": "RPBB Baggio Manzan 200ml",
              "Price": "1.70",
              "Units": 20,
              "Sales": 28.2,
              "GM": 14.63
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7791249999523,
              "Name": "CAD MANTECOL MARMOLADO 40G",
              "Price": "1.60",
              "Units": 26,
              "Sales": 34.32,
              "GM": 14.6
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3711,
              "Name": "Medialuna Pillsbury Jamon Ques",
              "Price": "3.60",
              "Units": 7,
              "Sales": 20.83,
              "GM": 14.53
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548969,
              "Name": "DNN VILLA DEL SUR POMELO C.G 500",
              "Price": "2.90",
              "Units": 13,
              "Sales": 31.2,
              "GM": 14.41
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7793940016011,
              "Name": "DNN Yogurisimo Firme Vainilla 200gr",
              "Price": "1.50",
              "Units": 51,
              "Sales": 63.3,
              "GM": 14.38
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222041246,
              "Name": "BNFD Nugaton Mini 72gr",
              "Price": "3.40",
              "Units": 13,
              "Sales": 36.53,
              "GM": 14.37
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77949080,
              "Name": "Cofler Aireado Leche 30gr",
              "Price": "1.90",
              "Units": 20,
              "Sales": 31.4,
              "GM": 14.34
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895006715,
              "Name": "Swcheppes Tonica 1.5L",
              "Price": "5.50",
              "Units": 8,
              "Sales": 36.56,
              "GM": 14.33
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 164,
              "Subcategory": "BATTERIES",
              "Manufacturer": "ENERGIZER",
              "EAN": 39800027962,
              "Name": "ENZ Energizer Titanium AAA 2",
              "Price": "7.50",
              "Units": 5,
              "Sales": 31,
              "GM": 14.3
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "GEORGALOS",
              "EAN": 1140,
              "Name": "GRG Tokke Bombon Marroc 10gr",
              "Price": "0.60",
              "Units": 61,
              "Sales": 30.33,
              "GM": 14.29
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "ARCOR",
              "EAN": 7790580476106,
              "Name": "La Campagnola BC Naranja 250ml",
              "Price": "1.90",
              "Units": 21,
              "Sales": 33.17,
              "GM": 14.28
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580388201,
              "Name": "Aguila Slice Batido Pina 70ml",
              "Price": "2.00",
              "Units": 23,
              "Sales": 37.99,
              "GM": 14.22
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 60,
              "Subcategory": "Caramelos en bolsa",
              "Manufacturer": "ARCOR",
              "EAN": 7790580604707,
              "Name": "Fruti Gomitas Dientes 150gr",
              "Price": "3.80",
              "Units": 12,
              "Sales": 37.68,
              "GM": 14.17
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813420156,
              "Name": "Mirinda 500ml",
              "Price": "3.10",
              "Units": 11,
              "Sales": 28.27,
              "GM": 14.14
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 9318,
              "Name": "PST TONICA BOTELLA 237ML",
              "Price": "2.20",
              "Units": 16,
              "Sales": 29.28,
              "GM": 14.14
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6306,
              "Name": "SFA Filtros Fram C-8827",
              "Price": "30.39",
              "Units": 1,
              "Sales": 25.12,
              "GM": 14.14
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7794600460014,
              "Name": "KR OREO DULCE DE LECHE 111GR",
              "Price": "2.50",
              "Units": 16,
              "Sales": 33.12,
              "GM": 14.14
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222042090,
              "Name": "BNFD NUGATON OBLEA 50G",
              "Price": "1.43",
              "Units": 29,
              "Sales": 34.41,
              "GM": 14.12
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813421238,
              "Name": "H2OH LIMA LIM�N 1.5L",
              "Price": "4.50",
              "Units": 7,
              "Sales": 26.11,
              "GM": 14.11
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "NESTLE",
              "EAN": 79200310424,
              "Name": "NES Wonka Gobstopper 170 G",
              "Price": "9.00",
              "Units": 5,
              "Sales": 37.2,
              "GM": 14.1
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954053086,
              "Name": "SFA Filtros Fram CA-5308",
              "Price": "29.03",
              "Units": 1,
              "Sales": 23.99,
              "GM": 14.1
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954053574,
              "Name": "SFA Filtros Fram CA-5357",
              "Price": "30.05",
              "Units": 1,
              "Sales": 24.83,
              "GM": 14.1
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "MOLINO CANUELAS",
              "EAN": 7792200000777,
              "Name": "MLC 9 de Oro Bizcocho Light 170gr",
              "Price": "2.60",
              "Units": 14,
              "Sales": 30.1,
              "GM": 14.1
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954869427,
              "Name": "SFA Fram Filtro CF 9071",
              "Price": "41.39",
              "Units": 1,
              "Sales": 34.21,
              "GM": 14.09
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77913173,
              "Name": "PM Marlboro Box Mild flavor 20und",
              "Price": "4.06",
              "Units": 47,
              "Sales": 177.93,
              "GM": 14.07
          },
          {
              "CategoryId": 13,
              "Category": "Otro Tabaco",
              "SubcategoryId": 15,
              "Subcategory": "Cigarros",
              "Manufacturer": "PURO TABACO",
              "EAN": 8811,
              "Name": "PTBC Guantanamera Cristales Und",
              "Price": "23.00",
              "Units": 1,
              "Sales": 19.35,
              "GM": 14.02
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 179,
              "Subcategory": "NEWSPAPERS",
              "Manufacturer": "OTHER",
              "EAN": 9770325094022,
              "Name": "LA NACION LUNES",
              "Price": "2.00",
              "Units": 7,
              "Sales": 14,
              "GM": 14
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "PEPSI",
              "EAN": 9315,
              "Name": "7 UP BOTELLA 237ML",
              "Price": "2.20",
              "Units": 16,
              "Sales": 29.27,
              "GM": 13.96
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7802215512230,
              "Name": "BNFD COSTA FRAC GALLETI  CHOCOL",
              "Price": "2.40",
              "Units": 28,
              "Sales": 55.45,
              "GM": 13.95
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 7659,
              "Name": "DISC Revista Ser Salud",
              "Price": "6.90",
              "Units": 2,
              "Sales": 13.8,
              "GM": 13.8
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77926289,
              "Name": "PM L&M Light KS 20und",
              "Price": "3.42",
              "Units": 60,
              "Sales": 191.31,
              "GM": 13.71
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "OSRAM",
              "EAN": 4050300838069,
              "Name": "LAMPARA OSRAM 7528  12V 21/5WBAYBLx 2",
              "Price": "2.90",
              "Units": 12,
              "Sales": 28.79,
              "GM": 13.67
          },
          {
              "CategoryId": 232,
              "Category": "Bebidas Congeladas dispensadas",
              "SubcategoryId": 234,
              "Subcategory": "FROZEN NON-CARBONATE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2604,
              "Name": "BATIDO DURAZNO",
              "Price": "7.75",
              "Units": 4,
              "Sales": 25.62,
              "GM": 13.64
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 135,
              "Subcategory": "CAFE Y TE",
              "Manufacturer": "ESTABL LAS MARIAS",
              "EAN": 7790387110319,
              "Name": "ELM Taragui Yerba Mate 250g",
              "Price": "3.12",
              "Units": 11,
              "Sales": 28.36,
              "GM": 13.62
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 191,
              "Subcategory": "BRAKE FLUIDS",
              "Manufacturer": "BARDAHL LUBRICANT",
              "EAN": 7790627112202,
              "Name": "BRDL Liquido Frenos 200ml",
              "Price": "7.30",
              "Units": 6,
              "Sales": 36.18,
              "GM": 13.56
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954053253,
              "Name": "SFA Filtros Fram CA-5325",
              "Price": "20.37",
              "Units": 2,
              "Sales": 33.67,
              "GM": 13.53
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "OTHER",
              "EAN": 5011053015298,
              "Name": "TOP BIG BABY POP 31G",
              "Price": "5.79",
              "Units": 6,
              "Sales": 28.71,
              "GM": 13.47
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 219,
              "Subcategory": "CAPPUCC./SPEC.COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2030,
              "Name": "CAPUCCINO VIENES",
              "Price": "6.80",
              "Units": 3,
              "Sales": 16.86,
              "GM": 13.44
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "OTHER",
              "EAN": 40000001638,
              "Name": "MAS SKITTLES TROPICAL 61,5G",
              "Price": "3.00",
              "Units": 14,
              "Sales": 34.72,
              "GM": 13.44
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "OSRAM",
              "EAN": 4050300222028,
              "Name": "OSRM Osram Lamp 64198 Und",
              "Price": "20.40",
              "Units": 2,
              "Sales": 33.72,
              "GM": 13.42
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77949059,
              "Name": "Cofler Aireado Mixto 30g.",
              "Price": "1.90",
              "Units": 20,
              "Sales": 31.4,
              "GM": 13.4
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954869137,
              "Name": "SFA Fram Filtro CA 9074",
              "Price": "44.91",
              "Units": 1,
              "Sales": 37.12,
              "GM": 13.34
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790040677203,
              "Name": "TORTITAS SUPERCHOCALETES 125G",
              "Price": "2.60",
              "Units": 15,
              "Sales": 32.25,
              "GM": 13.34
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6273,
              "Name": "SFA Filtros Fram CA-5929",
              "Price": "30.62",
              "Units": 1,
              "Sales": 25.31,
              "GM": 13.16
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954055110,
              "Name": "SFA Filtros Fram PH-5511",
              "Price": "27.10",
              "Units": 1,
              "Sales": 22.4,
              "GM": 13.16
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7790400021806,
              "Name": "TPS WD 40 155gr",
              "Price": "12.50",
              "Units": 3,
              "Sales": 30.99,
              "GM": 13.11
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790407490704,
              "Name": "Cofler Aireado Leche 60gr",
              "Price": "3.60",
              "Units": 10,
              "Sales": 29.8,
              "GM": 13.1
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "ARCOR",
              "EAN": 7790580240608,
              "Name": "Butter Toffees Black 250g",
              "Price": "9.10",
              "Units": 4,
              "Sales": 30.08,
              "GM": 13.09
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "AMBROSOLI",
              "EAN": 7792222003732,
              "Name": "AMB BONAFIDE MENTITAS FRUTA 25GR",
              "Price": "0.81",
              "Units": 36,
              "Sales": 24.09,
              "GM": 13.03
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771667201000,
              "Name": "DEBATE",
              "Price": "6.50",
              "Units": 2,
              "Sales": 13,
              "GM": 13
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 81,
              "Subcategory": "OTHER DAIRY",
              "Manufacturer": "DANONE",
              "EAN": 7791337303072,
              "Name": "DNN Ser Postre Choc Pack x 2",
              "Price": "3.00",
              "Units": 26,
              "Sales": 64.48,
              "GM": 12.98
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "FELFORT",
              "EAN": 7790206004188,
              "Name": "FLFR FELFORT FELICIDADES 385GR",
              "Price": "34.00",
              "Units": 1,
              "Sales": 28.1,
              "GM": 12.95
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954867850,
              "Name": "SFA Fram Filtro C 8837",
              "Price": "28.35",
              "Units": 1,
              "Sales": 23.43,
              "GM": 12.85
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77927453,
              "Name": "CAD HALLS CREAMY LEMON PIE 27G",
              "Price": "1.20",
              "Units": 29,
              "Sales": 28.72,
              "GM": 12.79
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6289,
              "Name": "SFA Fram CF 5475",
              "Price": "30.00",
              "Units": 1,
              "Sales": 24.79,
              "GM": 12.73
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6654,
              "Name": "SFA Filtros Fram CA-8964",
              "Price": "34.70",
              "Units": 1,
              "Sales": 28.68,
              "GM": 12.7
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082740015,
              "Name": "TM Tia Maruca Obleas 120gr",
              "Price": "3.50",
              "Units": 9,
              "Sales": 26.01,
              "GM": 12.69
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77954930,
              "Name": "Topline One Mentol 0.8gr",
              "Price": "3.80",
              "Units": 10,
              "Sales": 31.4,
              "GM": 12.6
          },
          {
              "CategoryId": 86,
              "Category": "Comida Empacada",
              "SubcategoryId": 87,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "CANON",
              "EAN": 7795947000475,
              "Name": "CN CaNon J/T/H Trip 3und",
              "Price": "6.90",
              "Units": 4,
              "Sales": 22.8,
              "GM": 12.52
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040936003,
              "Name": "BGLY OPERA OBLEA TRIPLE ORIG 75G",
              "Price": "2.10",
              "Units": 16,
              "Sales": 27.83,
              "GM": 12.43
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "KRAFT",
              "EAN": 7622300087722,
              "Name": "KR Express Litght 110g",
              "Price": "1.70",
              "Units": 21,
              "Sales": 29.42,
              "GM": 12.39
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 193,
              "Subcategory": "OTHER ADDITIVES",
              "Manufacturer": "BARDAHL LUBRICANT",
              "EAN": 77910219,
              "Name": "BRDL Top Diesel 110ml",
              "Price": "6.30",
              "Units": 6,
              "Sales": 31.25,
              "GM": 12.29
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580395605,
              "Name": "Bon o Bon Hel Corazon  60ml",
              "Price": "2.30",
              "Units": 17,
              "Sales": 32.3,
              "GM": 12.2
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771514268002,
              "Name": "Autofoco",
              "Price": "6.00",
              "Units": 2,
              "Sales": 12,
              "GM": 12
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "ARCOR",
              "EAN": 7790580591717,
              "Name": "TOPLINE C S/A MENTA 12GR",
              "Price": "1.20",
              "Units": 31,
              "Sales": 30.7,
              "GM": 11.96
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 7790114015573,
              "Name": "KR MILKA LEGER BLANCO 100G",
              "Price": "5.20",
              "Units": 7,
              "Sales": 30.1,
              "GM": 11.91
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954044824,
              "Name": "SFA Filtros Fram PH-4482",
              "Price": "24.83",
              "Units": 1,
              "Sales": 20.52,
              "GM": 11.91
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "UNILEVER",
              "EAN": 7794000730052,
              "Name": "UNI Ades Naranja 200ml",
              "Price": "2.00",
              "Units": 16,
              "Sales": 26.56,
              "GM": 11.89
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300122027,
              "Name": "KR OREO GALLETA DORADAS 111G",
              "Price": "2.00",
              "Units": 18,
              "Sales": 29.7,
              "GM": 11.84
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "GEORGALOS",
              "EAN": 7790380016199,
              "Name": "GRG CEREAL FLOW FRUT BOS C/ YOG",
              "Price": "1.30",
              "Units": 23,
              "Sales": 24.63,
              "GM": 11.83
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "OTHER",
              "EAN": 5011053003325,
              "Name": "TPPS Push Pop 15gr",
              "Price": "3.29",
              "Units": 9,
              "Sales": 24.49,
              "GM": 11.8
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 81,
              "Subcategory": "OTHER DAIRY",
              "Manufacturer": "DANONE",
              "EAN": 7791337571020,
              "Name": "DNN Actimel Clasico Frutilla 100ml",
              "Price": "1.47",
              "Units": 33,
              "Sales": 39.94,
              "GM": 11.8
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77910189,
              "Name": "Kool Box 20und",
              "Price": "3.98",
              "Units": 38,
              "Sales": 140.78,
              "GM": 11.79
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082741517,
              "Name": "TM Tia Maruca Fajitas Sab Cebo 160gr",
              "Price": "3.50",
              "Units": 9,
              "Sales": 26.01,
              "GM": 11.79
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7791337489851,
              "Name": "DNN Ser Fibramix 200gr",
              "Price": "2.70",
              "Units": 14,
              "Sales": 31.22,
              "GM": 11.76
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "PEPSI",
              "EAN": 7791813422518,
              "Name": "7Up H2OH 2000ml",
              "Price": "4.90",
              "Units": 6,
              "Sales": 24.36,
              "GM": 11.75
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "OTHER",
              "EAN": 22000001719,
              "Name": "WR WRIGLEYS SPEARMINT 15G",
              "Price": "1.70",
              "Units": 19,
              "Sales": 26.63,
              "GM": 11.71
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77963697,
              "Name": "MENTHOPLUS S AZ MANZANA 26.6G",
              "Price": "1.50",
              "Units": 20,
              "Sales": 24.8,
              "GM": 11.69
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "OTHER",
              "EAN": 7792430607227,
              "Name": "BARILOCHITO CAJA BOMBONES MIX",
              "Price": "12.39",
              "Units": 4,
              "Sales": 40.96,
              "GM": 11.69
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 81,
              "Subcategory": "OTHER DAIRY",
              "Manufacturer": "DANONE",
              "EAN": 77953070,
              "Name": "DNN Danette Chocolate 100gr",
              "Price": "2.41",
              "Units": 23,
              "Sales": 45.82,
              "GM": 11.64
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "BUFFALO",
              "EAN": 7795070128190,
              "Name": "BFF Buffalo Escob Duna/Uno 19 1und",
              "Price": "14.20",
              "Units": 4,
              "Sales": 46.95,
              "GM": 11.59
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "KRAFT",
              "EAN": 77903778,
              "Name": "KR Milka Triple Dulce leche Alf 70gr",
              "Price": "2.30",
              "Units": 16,
              "Sales": 30.4,
              "GM": 11.58
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6463,
              "Name": "SFA Filtros Fram CA-5827",
              "Price": "39.69",
              "Units": 1,
              "Sales": 32.8,
              "GM": 11.51
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222002902,
              "Name": "BNFD RUEDITAS BILLIKEN 25G",
              "Price": "0.81",
              "Units": 39,
              "Sales": 25.98,
              "GM": 11.5
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "AMBROSOLI",
              "EAN": 7792222003718,
              "Name": "AMB Bonafide Mentitas Menta 25",
              "Price": "0.81",
              "Units": 32,
              "Sales": 21.36,
              "GM": 11.45
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "GEORGALOS",
              "EAN": 7790380016120,
              "Name": "GEO GEORGALOS  ALFAJOR DDL 50G",
              "Price": "1.40",
              "Units": 24,
              "Sales": 27.83,
              "GM": 11.32
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 149,
              "Subcategory": "ANALGESICOS",
              "Manufacturer": "OTHER",
              "EAN": 9815,
              "Name": "GSK SOBRE ALIKAL COMUN 4.5G",
              "Price": "1.85",
              "Units": 11,
              "Sales": 20.4,
              "GM": 11.31
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6715,
              "Name": "SFA Filtros Fram CH-8530",
              "Price": "31.75",
              "Units": 1,
              "Sales": 26.24,
              "GM": 11.29
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BAT",
              "EAN": 77910158,
              "Name": "Derby KS Suave 20und",
              "Price": "3.42",
              "Units": 52,
              "Sales": 165.14,
              "GM": 11.2
          },
          {
              "CategoryId": 13,
              "Category": "Otro Tabaco",
              "SubcategoryId": 15,
              "Subcategory": "Cigarros",
              "Manufacturer": "PURO TABACO",
              "EAN": 8806,
              "Name": "PTBC Quintero Brevas Mano Und",
              "Price": "9.50",
              "Units": 4,
              "Sales": 31.96,
              "GM": 11.2
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770328042006,
              "Name": "Semanario",
              "Price": "2.80",
              "Units": 4,
              "Sales": 11.2,
              "GM": 11.2
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77924025,
              "Name": "CAD HALLS FUSI�N FRUTOS ROJOS",
              "Price": "1.30",
              "Units": 23,
              "Sales": 24.63,
              "GM": 11.18
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790580327613,
              "Name": "Rocklets Con Mani 40gr",
              "Price": "1.80",
              "Units": 17,
              "Sales": 25.33,
              "GM": 11.14
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "OTHER",
              "EAN": 7792170110063,
              "Name": "Quaker Barra Frito Cream 25Gr",
              "Price": "1.00",
              "Units": 34,
              "Sales": 28.15,
              "GM": 11.12
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6212,
              "Name": "SFA Filtros Fram PH-5949",
              "Price": "25.63",
              "Units": 1,
              "Sales": 21.18,
              "GM": 11.08
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 60,
              "Subcategory": "Caramelos en bolsa",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7791249977187,
              "Name": "CAD YUMMY BANANITAS  24.5G",
              "Price": "1.20",
              "Units": 30,
              "Sales": 29.7,
              "GM": 11.04
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 5039,
              "Name": "PANINO CAMPERO TM",
              "Price": "8.00",
              "Units": 6,
              "Sales": 39.66,
              "GM": 11.03
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082742040,
              "Name": "TM MARUQUITAS FRUTILLA 160 GRS",
              "Price": "3.50",
              "Units": 8,
              "Sales": 23.13,
              "GM": 11.03
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580388409,
              "Name": "Aguila Granizado 80ml",
              "Price": "3.00",
              "Units": 11,
              "Sales": 27.28,
              "GM": 11.02
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 28400055161,
              "Name": "FL Lays Stax Barbecue 155gr",
              "Price": "9.50",
              "Units": 4,
              "Sales": 31.4,
              "GM": 10.96
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "OSRAM",
              "EAN": 4050300332185,
              "Name": "LAMPARA OSRAM 64210 H7 12V 55W BLx1",
              "Price": "7.10",
              "Units": 5,
              "Sales": 29.35,
              "GM": 10.95
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770328436003,
              "Name": "PLAYBOY",
              "Price": "10.90",
              "Units": 1,
              "Sales": 10.9,
              "GM": 10.9
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 7790329981007,
              "Name": "Tiempo Aventura",
              "Price": "10.90",
              "Units": 1,
              "Sales": 10.9,
              "GM": 10.9
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7791337019942,
              "Name": "DNN Yogurisimo Licuado Frutas 190G",
              "Price": "2.10",
              "Units": 31,
              "Sales": 53.9,
              "GM": 10.86
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77910936,
              "Name": "Camel Blue Box 20und",
              "Price": "4.05",
              "Units": 44,
              "Sales": 165.76,
              "GM": 10.83
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040944602,
              "Name": "BGLY REX 3 JAMON SERRANO 60GR",
              "Price": "1.80",
              "Units": 18,
              "Sales": 26.82,
              "GM": 10.79
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "ARCOR",
              "EAN": 7790580530334,
              "Name": "BEAT MENSAJITOS 30G",
              "Price": "0.90",
              "Units": 41,
              "Sales": 30.38,
              "GM": 10.76
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300108373,
              "Name": "KR CEREALITAS MANZAN Y CAN 142G",
              "Price": "2.90",
              "Units": 10,
              "Sales": 24,
              "GM": 10.74
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7798011660384,
              "Name": "TPS Topaes Franela Bli 50x60 U",
              "Price": "8.50",
              "Units": 4,
              "Sales": 28.08,
              "GM": 10.64
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "QUILMES",
              "EAN": 77927651,
              "Name": "QLMS NPV Naranja 330ml",
              "Price": "2.40",
              "Units": 11,
              "Sales": 21.89,
              "GM": 10.55
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77946812,
              "Name": "MENTHOPLUS S AZ DURAZNO 26.6G",
              "Price": "1.50",
              "Units": 18,
              "Sales": 22.32,
              "GM": 10.54
          },
          {
              "CategoryId": 13,
              "Category": "Otro Tabaco",
              "SubcategoryId": 15,
              "Subcategory": "Cigarros",
              "Manufacturer": "PURO TABACO",
              "EAN": 8800,
              "Name": "PTBC Gonzalez Panet Extra Und",
              "Price": "14.00",
              "Units": 2,
              "Sales": 23.15,
              "GM": 10.53
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "KRAFT",
              "EAN": 7794600458905,
              "Name": "KR Club Social 26gr",
              "Price": "0.70",
              "Units": 38,
              "Sales": 22.04,
              "GM": 10.46
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300121037,
              "Name": "KR Lincoln Chocolate 161gr",
              "Price": "2.30",
              "Units": 14,
              "Sales": 26.6,
              "GM": 10.44
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "OTHER",
              "EAN": 7792170110186,
              "Name": "Quaker Barra Chispa Choc 20G",
              "Price": "1.00",
              "Units": 29,
              "Sales": 24,
              "GM": 10.42
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 193,
              "Subcategory": "OTHER ADDITIVES",
              "Manufacturer": "WYNNS",
              "EAN": 73579643022,
              "Name": "WNNS Super For Oil 325ml",
              "Price": "25.00",
              "Units": 1,
              "Sales": 20.66,
              "GM": 10.42
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "LEHRITIER",
              "EAN": 7792860016217,
              "Name": "LHRT Baby Doll Chupetines 24g",
              "Price": "1.33",
              "Units": 24,
              "Sales": 26.25,
              "GM": 10.38
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77915115,
              "Name": "PM Colorado CF 20und",
              "Price": "3.66",
              "Units": 38,
              "Sales": 129.64,
              "GM": 10.37
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 219,
              "Subcategory": "CAPPUCC./SPEC.COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2043,
              "Name": "CAFE AMERICANO CAPUCCINO CHICO",
              "Price": "3.60",
              "Units": 5,
              "Sales": 14.89,
              "GM": 10.27
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 155,
              "Subcategory": "HIGIENE FEMENINA",
              "Manufacturer": "JHONSON & JHONSON",
              "EAN": 7891010010546,
              "Name": "J&J OB Medio 10und",
              "Price": "12.00",
              "Units": 2,
              "Sales": 19.84,
              "GM": 10.25
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77918802,
              "Name": "PM L&M KS 20und",
              "Price": "3.42",
              "Units": 44,
              "Sales": 140.47,
              "GM": 10.23
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77925084,
              "Name": "SUGUS CREAMY ANANA 18G",
              "Price": "0.80",
              "Units": 33,
              "Sales": 21.78,
              "GM": 10.19
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77924261,
              "Name": "CAD Halls Creamy Mora 27gr",
              "Price": "1.20",
              "Units": 23,
              "Sales": 22.77,
              "GM": 10.17
          },
          {
              "CategoryId": 65,
              "Category": "Leches",
              "SubcategoryId": 70,
              "Subcategory": "FLAVORED  MILK",
              "Manufacturer": "DANONE",
              "EAN": 7791337519053,
              "Name": "DNN Yogurisimo Licuado de Banana 200g",
              "Price": "2.12",
              "Units": 28,
              "Sales": 48.94,
              "GM": 10.15
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082741524,
              "Name": "TM Maruca fajitas Pizza 160 Gr",
              "Price": "3.50",
              "Units": 8,
              "Sales": 23.13,
              "GM": 10.13
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "ARCOR",
              "EAN": 7790580717407,
              "Name": "Saladix Parmesano 100gr",
              "Price": "2.80",
              "Units": 11,
              "Sales": 25.41,
              "GM": 10.08
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77910172,
              "Name": "Kool Milds 20und",
              "Price": "3.95",
              "Units": 34,
              "Sales": 125.04,
              "GM": 10.05
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "P&G",
              "EAN": 37000117285,
              "Name": "PG PRINGLES QUESO CHICA 43G",
              "Price": "6.00",
              "Units": 5,
              "Sales": 24.8,
              "GM": 10.05
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77926371,
              "Name": "PM L&M Box 20und",
              "Price": "3.57",
              "Units": 35,
              "Sales": 116.6,
              "GM": 9.96
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 93,
              "Subcategory": "HELADOS PREMIUM",
              "Manufacturer": "GENERAL MILLS ARG",
              "EAN": 3415581306149,
              "Name": "GM Haagen Dazs Summer Berries 100ml",
              "Price": "8.50",
              "Units": 4,
              "Sales": 28.08,
              "GM": 9.96
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 60,
              "Subcategory": "Caramelos en bolsa",
              "Manufacturer": "ARCOR",
              "EAN": 7790580618315,
              "Name": "Mogul Fruta 50g",
              "Price": "1.10",
              "Units": 27,
              "Sales": 24.57,
              "GM": 9.92
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6200,
              "Name": "SFA Filtros Fram C-1191",
              "Price": "20.07",
              "Units": 1,
              "Sales": 16.59,
              "GM": 9.92
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6270,
              "Name": "SFA Filtros Fram C 5563",
              "Price": "22.00",
              "Units": 1,
              "Sales": 18.18,
              "GM": 9.9
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6666,
              "Name": "SFA Filtros Fram CA-9391",
              "Price": "30.16",
              "Units": 1,
              "Sales": 24.93,
              "GM": 9.89
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BAT",
              "EAN": 77920423,
              "Name": "Viceroy Classic Rojo Box 20",
              "Price": "2.78",
              "Units": 53,
              "Sales": 136.14,
              "GM": 9.83
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77974648,
              "Name": "Tofi Chocolate Blanco 27Gr",
              "Price": "1.56",
              "Units": 21,
              "Sales": 27.05,
              "GM": 9.83
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 165,
              "Subcategory": "FILM / PHOTO",
              "Manufacturer": "KODAK",
              "EAN": 86806034074,
              "Name": "KOD KODAK ULTRA 400 X 36",
              "Price": "25.00",
              "Units": 1,
              "Sales": 20.66,
              "GM": 9.78
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "OTHER",
              "EAN": 77965233,
              "Name": "PRO Pronovelties Pipas 18g",
              "Price": "0.60",
              "Units": 50,
              "Sales": 24.84,
              "GM": 9.72
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "LEHRITIER",
              "EAN": 1107,
              "Name": "LHRT Pico Dulce Chupetin 15gr",
              "Price": "0.54",
              "Units": 50,
              "Sales": 22.13,
              "GM": 9.7
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 156,
              "Subcategory": "PRESERVATIVOS",
              "Manufacturer": "BUHL",
              "EAN": 7791014090325,
              "Name": "Tulipan preservativos classic 3u",
              "Price": "3.15",
              "Units": 8,
              "Sales": 20.81,
              "GM": 9.7
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 51,
              "Subcategory": "Bebidas Alternativas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895056765,
              "Name": "Black Fire 250ml",
              "Price": "4.10",
              "Units": 6,
              "Sales": 20.34,
              "GM": 9.63
          },
          {
              "CategoryId": 13,
              "Category": "Otro Tabaco",
              "SubcategoryId": 15,
              "Subcategory": "Cigarros",
              "Manufacturer": "PURO TABACO",
              "EAN": 8822,
              "Name": "PTBC Romeo Y Julieta Nro 3 Tubo Mano",
              "Price": "27.70",
              "Units": 1,
              "Sales": 22.91,
              "GM": 9.6
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7791337181021,
              "Name": "DNN Yogurisimo Total Frutilla 185g",
              "Price": "2.42",
              "Units": 20,
              "Sales": 40.03,
              "GM": 9.59
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 219,
              "Subcategory": "CAPPUCC./SPEC.COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2032,
              "Name": "CAPUCCINO SABORIZADO",
              "Price": "7.10",
              "Units": 2,
              "Sales": 11.74,
              "GM": 9.58
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 219,
              "Subcategory": "CAPPUCC./SPEC.COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2033,
              "Name": "Mocaccino",
              "Price": "7.10",
              "Units": 2,
              "Sales": 11.74,
              "GM": 9.56
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7791249008720,
              "Name": "CAD CADBURY TAB 72GR",
              "Price": "4.20",
              "Units": 9,
              "Sales": 31.23,
              "GM": 9.55
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 193,
              "Subcategory": "OTHER ADDITIVES",
              "Manufacturer": "BARDAHL LUBRICANT",
              "EAN": 7790627101237,
              "Name": "BRDL Nafta Adit Nafta Top Gun 250ml",
              "Price": "11.50",
              "Units": 2,
              "Sales": 19,
              "GM": 9.53
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300108960,
              "Name": "KR PEPITOS CHIPS BLANCOS 126G",
              "Price": "2.50",
              "Units": 10,
              "Sales": 20.7,
              "GM": 9.47
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895066412,
              "Name": "Dasani Minerali Con Gas 1.5L",
              "Price": "3.30",
              "Units": 7,
              "Sales": 19.18,
              "GM": 9.44
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7792993002033,
              "Name": "TPS Laffitte Llave Cruz Crom 4 B",
              "Price": "28.75",
              "Units": 1,
              "Sales": 23.76,
              "GM": 9.41
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 179,
              "Subcategory": "NEWSPAPERS",
              "Manufacturer": "OTHER",
              "EAN": 7665,
              "Name": "Diario Perfil",
              "Price": "4.70",
              "Units": 2,
              "Sales": 9.4,
              "GM": 9.4
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 81,
              "Subcategory": "OTHER DAIRY",
              "Manufacturer": "DANONE",
              "EAN": 7793940034169,
              "Name": "DNN Serenito Combinado 130gr",
              "Price": "1.68",
              "Units": 24,
              "Sales": 33.39,
              "GM": 9.38
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310980309,
              "Name": "FL Lays Doritos Pizza 50g",
              "Price": "2.40",
              "Units": 13,
              "Sales": 25.75,
              "GM": 9.37
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77917324,
              "Name": "CAD VITA-C NARANJA 27GR",
              "Price": "1.20",
              "Units": 21,
              "Sales": 20.79,
              "GM": 9.35
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "MARS",
              "EAN": 7896423419276,
              "Name": "MARS MM Minis Tube 30 Gr",
              "Price": "3.90",
              "Units": 7,
              "Sales": 22.55,
              "GM": 9.3
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6447,
              "Name": "SFA Filtros Fram CA-5627",
              "Price": "23.36",
              "Units": 1,
              "Sales": 19.31,
              "GM": 9.3
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 164,
              "Subcategory": "BATTERIES",
              "Manufacturer": "ENERGIZER",
              "EAN": 39800011367,
              "Name": "ENZ Energizer Bateria Med CX2",
              "Price": "11.50",
              "Units": 2,
              "Sales": 19.01,
              "GM": 9.27
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954535742,
              "Name": "SFA Filtros Fram CA-5357D",
              "Price": "27.56",
              "Units": 1,
              "Sales": 22.78,
              "GM": 9.23
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77916006,
              "Name": "PM Le Mans Suave KS 20und",
              "Price": "3.42",
              "Units": 45,
              "Sales": 143.6,
              "GM": 9.22
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 191,
              "Subcategory": "BRAKE FLUIDS",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7790627112226,
              "Name": "TPS Liquido para frenos 500ml",
              "Price": "14.70",
              "Units": 2,
              "Sales": 24.3,
              "GM": 9.22
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040055414,
              "Name": "BGLY SER Salvador 145Gr",
              "Price": "2.60",
              "Units": 10,
              "Sales": 21.5,
              "GM": 9.2
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "REVIGAL",
              "EAN": 7790911003551,
              "Name": "REVIGAL AMB AUT LEMON BREGE",
              "Price": "16.50",
              "Units": 1,
              "Sales": 13.64,
              "GM": 9.19
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 135,
              "Subcategory": "CAFE Y TE",
              "Manufacturer": "CABRALES S.A.",
              "EAN": 7790550012716,
              "Name": "CAB Cabral Cafe Ins Clas Frasc 170g",
              "Price": "11.75",
              "Units": 6,
              "Sales": 58.26,
              "GM": 9.18
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6206,
              "Name": "SFA Filtros Fram CA-8674",
              "Price": "21.09",
              "Units": 1,
              "Sales": 17.43,
              "GM": 9.15
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "QUILMES",
              "EAN": 77927644,
              "Name": "QLMS NPV Manzana 330ml",
              "Price": "2.40",
              "Units": 10,
              "Sales": 19.9,
              "GM": 9.1
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300160975,
              "Name": "KR Cerealita Arros 160g",
              "Price": "4.00",
              "Units": 6,
              "Sales": 19.83,
              "GM": 9.09
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "GEORGALOS",
              "EAN": 7790380016168,
              "Name": "GRG CEREAL FLOW LIGHT DURAZ YOG",
              "Price": "1.30",
              "Units": 18,
              "Sales": 19.28,
              "GM": 9
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771668808000,
              "Name": "APERTURA",
              "Price": "9.00",
              "Units": 1,
              "Sales": 9,
              "GM": 9
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771851382003,
              "Name": "POP STAR",
              "Price": "4.50",
              "Units": 2,
              "Sales": 9,
              "GM": 9
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 138,
              "Subcategory": "OTROS ALMACEN",
              "Manufacturer": "ARCOR",
              "EAN": 7793360004971,
              "Name": "FRUTILLA MERMELADA BC 390G",
              "Price": "9.00",
              "Units": 3,
              "Sales": 22.32,
              "GM": 8.92
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7798011661657,
              "Name": "Motorcraft Filtro Aceite E FL 600",
              "Price": "26.00",
              "Units": 1,
              "Sales": 21.49,
              "GM": 8.92
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "OTHER",
              "EAN": 37000446217,
              "Name": "LMAR PRINGLES CHEDDAR 80G",
              "Price": "9.00",
              "Units": 3,
              "Sales": 22.32,
              "GM": 8.9
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 77916013,
              "Name": "KR MILKA LEGER ALMENDRAS 25GR",
              "Price": "1.50",
              "Units": 37,
              "Sales": 45.88,
              "GM": 8.88
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790040345102,
              "Name": "ARCOR BIZCOCHOS AZUCARAD 225G",
              "Price": "2.40",
              "Units": 11,
              "Sales": 21.79,
              "GM": 8.88
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "CLAMER SRL",
              "EAN": 3709,
              "Name": "CSRL Martin de Ridder Brownie",
              "Price": "2.90",
              "Units": 8,
              "Sales": 19.18,
              "GM": 8.87
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7791249007280,
              "Name": "CAD Mantecol 25gr",
              "Price": "1.00",
              "Units": 24,
              "Sales": 19.89,
              "GM": 8.85
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77910653,
              "Name": "Jockey Club S 100 20und",
              "Price": "3.54",
              "Units": 38,
              "Sales": 125.12,
              "GM": 8.78
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895064340,
              "Name": "Cepita Manzana 1L",
              "Price": "4.50",
              "Units": 4,
              "Sales": 14.92,
              "GM": 8.75
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7792993001890,
              "Name": "TPS Triang Senal Bal Trian Est 2",
              "Price": "27.00",
              "Units": 1,
              "Sales": 22.31,
              "GM": 8.57
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77915894,
              "Name": "PM CHESTERFIELD KS BOX",
              "Price": "3.27",
              "Units": 88,
              "Sales": 268.89,
              "GM": 8.51
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "ARCOR",
              "EAN": 7790040609501,
              "Name": "BON O BON ALF TRIP CHOC 60G",
              "Price": "2.00",
              "Units": 12,
              "Sales": 19.8,
              "GM": 8.51
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771515264003,
              "Name": "TC Urbano",
              "Price": "8.50",
              "Units": 1,
              "Sales": 8.5,
              "GM": 8.5
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "ARCOR",
              "EAN": 7790580387105,
              "Name": "Saladix Jamon 30gr",
              "Price": "1.00",
              "Units": 23,
              "Sales": 19.07,
              "GM": 8.46
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3063,
              "Name": "DISC Adicional Jamon Cocido",
              "Price": "1.00",
              "Units": 17,
              "Sales": 14.05,
              "GM": 8.44
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580397500,
              "Name": "Arcor Tacita Americana DDL 90ml",
              "Price": "1.80",
              "Units": 14,
              "Sales": 20.85,
              "GM": 8.44
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 155,
              "Subcategory": "HIGIENE FEMENINA",
              "Manufacturer": "JHONSON & JHONSON",
              "EAN": 7790010003124,
              "Name": "J&J SIEMP LIBRE PERMASEC ALAS 8U",
              "Price": "7.00",
              "Units": 3,
              "Sales": 17.37,
              "GM": 8.44
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7791337686946,
              "Name": "DNN YOGURISIMO FULL 185G",
              "Price": "2.51",
              "Units": 19,
              "Sales": 39.33,
              "GM": 8.4
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6886,
              "Name": "SFA Filtros Fram PH-5796",
              "Price": "22.57",
              "Units": 1,
              "Sales": 18.65,
              "GM": 8.4
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "P&G",
              "EAN": 37000117261,
              "Name": "PG PRINGLES CREM CEBOLLA C43G",
              "Price": "6.00",
              "Units": 4,
              "Sales": 19.84,
              "GM": 8.4
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "KRAFT",
              "EAN": 7622300093877,
              "Name": "KR CLUB SOCIAL CHIPS 40G",
              "Price": "2.40",
              "Units": 9,
              "Sales": 17.83,
              "GM": 8.37
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77924162,
              "Name": "CAD HALLS FUSI�N FRUTOS VERDES",
              "Price": "1.30",
              "Units": 17,
              "Sales": 18.22,
              "GM": 8.34
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "OSRAM",
              "EAN": 7798011660834,
              "Name": "OSRM Osram Lamp 2821 2und",
              "Price": "7.40",
              "Units": 2,
              "Sales": 12.24,
              "GM": 8.34
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 7662,
              "Name": "Para Ti con Regalo",
              "Price": "8.30",
              "Units": 1,
              "Sales": 8.3,
              "GM": 8.3
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 135,
              "Subcategory": "CAFE Y TE",
              "Manufacturer": "CABRALES S.A.",
              "EAN": 7790550000157,
              "Name": "CAB Cabrales 250gr",
              "Price": "7.40",
              "Units": 4,
              "Sales": 24.48,
              "GM": 8.28
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77927408,
              "Name": "SUCHARD CARAMELO ACIDOS 26.6G",
              "Price": "0.90",
              "Units": 24,
              "Sales": 17.81,
              "GM": 8.28
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 175,
              "Subcategory": "OTHER GM",
              "Manufacturer": "OTHER",
              "EAN": 86806120135,
              "Name": "Camara  Descartable Ultra 27 Flash",
              "Price": "19.90",
              "Units": 2,
              "Sales": 32.89,
              "GM": 8.23
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "OTHER",
              "EAN": 7792170110421,
              "Name": "Cereal Quaker Naran Choc 25g",
              "Price": "1.00",
              "Units": 25,
              "Sales": 20.7,
              "GM": 8.17
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040944800,
              "Name": "BGLY Rex 3 Queso 60gr",
              "Price": "1.80",
              "Units": 14,
              "Sales": 20.86,
              "GM": 8.16
          },
          {
              "CategoryId": 13,
              "Category": "Otro Tabaco",
              "SubcategoryId": 15,
              "Subcategory": "Cigarros",
              "Manufacturer": "PURO TABACO",
              "EAN": 8802,
              "Name": "PTBC JL Piedra Cazadores Und",
              "Price": "15.40",
              "Units": 1,
              "Sales": 12.95,
              "GM": 8.15
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954015664,
              "Name": "SFA Fram Filtro C 1566",
              "Price": "20.87",
              "Units": 1,
              "Sales": 17.25,
              "GM": 8.09
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "KRAFT",
              "EAN": 7794600460342,
              "Name": "KR Mayco sin Sal 169g",
              "Price": "2.60",
              "Units": 8,
              "Sales": 17.2,
              "GM": 8.07
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7790715013152,
              "Name": "TPS Auto Polish Dos Lustre 450ml",
              "Price": "22.50",
              "Units": 1,
              "Sales": 18.6,
              "GM": 8.06
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "OTHER",
              "EAN": 7792170110506,
              "Name": "CEREAL QUAKER DURAZ A LA CREM 25",
              "Price": "1.00",
              "Units": 24,
              "Sales": 19.88,
              "GM": 7.84
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 135,
              "Subcategory": "CAFE Y TE",
              "Manufacturer": "QUIMICA ESTRELLA",
              "EAN": 7791445052305,
              "Name": "QE Cruz de Malta Yerba Naranja 500gr",
              "Price": "5.50",
              "Units": 3,
              "Sales": 13.65,
              "GM": 7.78
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 135,
              "Subcategory": "CAFE Y TE",
              "Manufacturer": "OTHER",
              "EAN": 7790387003482,
              "Name": "LM Taragui Mate Listo todo en uno 65g",
              "Price": "7.50",
              "Units": 3,
              "Sales": 18.6,
              "GM": 7.72
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77925091,
              "Name": "SUGUS CREAMY MENTA 18G",
              "Price": "0.80",
              "Units": 24,
              "Sales": 15.84,
              "GM": 7.72
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954031640,
              "Name": "SFA Filtros Fram CA-3164",
              "Price": "28.12",
              "Units": 1,
              "Sales": 23.24,
              "GM": 7.72
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040001022,
              "Name": "BGLY SER TOST CLASICAS 110G",
              "Price": "4.50",
              "Units": 5,
              "Sales": 18.6,
              "GM": 7.69
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 7797954055134,
              "Name": "SFA Filtros Fram CA-5513",
              "Price": "23.25",
              "Units": 1,
              "Sales": 19.21,
              "GM": 7.68
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "KRAFT",
              "EAN": 7622300299637,
              "Name": "KR Terrabusi Express 108G",
              "Price": "1.50",
              "Units": 15,
              "Sales": 18.6,
              "GM": 7.66
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "WYNNS",
              "EAN": 73579508079,
              "Name": "WNNS Alto al Humo 325ml",
              "Price": "25.00",
              "Units": 1,
              "Sales": 20.66,
              "GM": 7.63
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "CLAMER SRL",
              "EAN": 3707,
              "Name": "CSRL Martin de Ridder Limonito",
              "Price": "2.00",
              "Units": 10,
              "Sales": 16.51,
              "GM": 7.51
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "OTHER",
              "EAN": 7792170110469,
              "Name": "CEREAL QUAKER MENTA C CHOC 22G",
              "Price": "1.00",
              "Units": 23,
              "Sales": 19.04,
              "GM": 7.5
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770006255001,
              "Name": "Billiken",
              "Price": "7.50",
              "Units": 1,
              "Sales": 7.5,
              "GM": 7.5
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "KRAFT",
              "EAN": 77917232,
              "Name": "KR CEREALITA MIEL/CEREALES BARRA",
              "Price": "1.20",
              "Units": 16,
              "Sales": 15.84,
              "GM": 7.46
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "ARCOR",
              "EAN": 7790040455405,
              "Name": "ARCOR BIZCOCHO GRASA 200G",
              "Price": "2.40",
              "Units": 9,
              "Sales": 17.83,
              "GM": 7.42
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040545960,
              "Name": "BGLY Criollitas Bizcochos 140Gr",
              "Price": "1.90",
              "Units": 12,
              "Sales": 18.84,
              "GM": 7.42
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "BARDAHL LUBRICANT",
              "EAN": 7790627100698,
              "Name": "BRDL Infla Neumatico Bardhal S 300gr",
              "Price": "23.00",
              "Units": 1,
              "Sales": 19.01,
              "GM": 7.38
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082740152,
              "Name": "TM Tia Maruca Hojaldre 220gr",
              "Price": "3.50",
              "Units": 6,
              "Sales": 17.34,
              "GM": 7.38
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIPMORRIS",
              "EAN": 77926333,
              "Name": "PM L&M MENTHOL KS 20 UND",
              "Price": "3.41",
              "Units": 34,
              "Sales": 108.09,
              "GM": 7.29
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 165,
              "Subcategory": "FILM / PHOTO",
              "Manufacturer": "KODAK",
              "EAN": 86806034036,
              "Name": "KOD KODAK ULTRA 400 X 24",
              "Price": "20.00",
              "Units": 1,
              "Sales": 16.53,
              "GM": 7.24
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082740312,
              "Name": "TM Tia Maruca Tortitas Dulce 220gr",
              "Price": "3.50",
              "Units": 5,
              "Sales": 14.45,
              "GM": 7.22
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 179,
              "Subcategory": "NEWSPAPERS",
              "Manufacturer": "OTHER",
              "EAN": 7612,
              "Name": "La Nacion Jueves",
              "Price": "2.40",
              "Units": 3,
              "Sales": 7.2,
              "GM": 7.2
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "ARCOR",
              "EAN": 7790040379305,
              "Name": "Cereal Mix Manzana Light X3 69g",
              "Price": "3.90",
              "Units": 6,
              "Sales": 19.33,
              "GM": 7.11
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BAT",
              "EAN": 77910943,
              "Name": "Parisienes Box 10und",
              "Price": "2.00",
              "Units": 58,
              "Sales": 108.02,
              "GM": 7.1
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77922670,
              "Name": "Menthoplus Strong 30.6 Gr",
              "Price": "1.00",
              "Units": 22,
              "Sales": 18.24,
              "GM": 7.1
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "CLAMER SRL",
              "EAN": 3714,
              "Name": "CSRL Martin Ridder Alfajorcito 4",
              "Price": "2.10",
              "Units": 9,
              "Sales": 15.66,
              "GM": 7.02
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9991599000001,
              "Name": "10 Segundos",
              "Price": "7.00",
              "Units": 1,
              "Sales": 7,
              "GM": 7
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77973078,
              "Name": "PM Particulares LS 20und",
              "Price": "3.29",
              "Units": 29,
              "Sales": 89.03,
              "GM": 6.99
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 137,
              "Subcategory": "CONDIMENTOS",
              "Manufacturer": "MERISANT",
              "EAN": 7792850000066,
              "Name": "MER Chucker Edulcorante 250ml",
              "Price": "7.00",
              "Units": 2,
              "Sales": 11.58,
              "GM": 6.97
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771514852003,
              "Name": "Genios",
              "Price": "6.90",
              "Units": 1,
              "Sales": 6.9,
              "GM": 6.9
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771851280002,
              "Name": "HIGH SCHOOL  La revista",
              "Price": "6.90",
              "Units": 1,
              "Sales": 6.9,
              "GM": 6.9
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BAT",
              "EAN": 77910622,
              "Name": "Viceroy Classic Light Soft Cu 20un",
              "Price": "2.62",
              "Units": 40,
              "Sales": 97.22,
              "GM": 6.89
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "ARCOR",
              "EAN": 7790580592417,
              "Name": "TOP LINE C SIN AZUC MENTOL",
              "Price": "1.20",
              "Units": 18,
              "Sales": 17.83,
              "GM": 6.85
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790040933408,
              "Name": "Panchitas Chocolate 120g",
              "Price": "2.20",
              "Units": 9,
              "Sales": 16.38,
              "GM": 6.85
          },
          {
              "CategoryId": 65,
              "Category": "Leches",
              "SubcategoryId": 70,
              "Subcategory": "FLAVORED  MILK",
              "Manufacturer": "DANONE",
              "EAN": 7791337430020,
              "Name": "DNN Danone Bebible Maxi 200G",
              "Price": "2.20",
              "Units": 15,
              "Sales": 27.25,
              "GM": 6.79
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "KRAFT",
              "EAN": 7622300108878,
              "Name": "KR LINCOLN GALLETITA 161GR",
              "Price": "2.00",
              "Units": 14,
              "Sales": 23.1,
              "GM": 6.74
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77923264,
              "Name": "MENTHOPLUS PLUS UVA LIMON",
              "Price": "1.00",
              "Units": 20,
              "Sales": 16.57,
              "GM": 6.67
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "WYNNS",
              "EAN": 7790039515196,
              "Name": "WNNS Kwik Kool 354ml",
              "Price": "16.00",
              "Units": 1,
              "Sales": 13.22,
              "GM": 6.66
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "KRAFT",
              "EAN": 7794600459636,
              "Name": "KR Club Social Jamon 25gr",
              "Price": "0.70",
              "Units": 24,
              "Sales": 13.92,
              "GM": 6.59
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "REVIGAL",
              "EAN": 7790911000291,
              "Name": "RVG Revigal Silicon Citrico Aer 260G",
              "Price": "18.00",
              "Units": 1,
              "Sales": 14.88,
              "GM": 6.58
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 81,
              "Subcategory": "OTHER DAIRY",
              "Manufacturer": "DANONE",
              "EAN": 7793940114076,
              "Name": "DNN Postre Ser Chocolate 130gr",
              "Price": "2.30",
              "Units": 11,
              "Sales": 20.9,
              "GM": 6.57
          },
          {
              "CategoryId": 232,
              "Category": "Bebidas Congeladas dispensadas",
              "SubcategoryId": 234,
              "Subcategory": "FROZEN NON-CARBONATE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2601,
              "Name": "SMOOTHIE FRUTILLA",
              "Price": "7.25",
              "Units": 2,
              "Sales": 11.98,
              "GM": 6.55
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "ARCOR",
              "EAN": 7790580167806,
              "Name": "Butter Toffees Leche 250g",
              "Price": "9.10",
              "Units": 2,
              "Sales": 15.04,
              "GM": 6.55
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 193,
              "Subcategory": "OTHER ADDITIVES",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 73579679120,
              "Name": "TPS wynns Emision Plus 325ml",
              "Price": "18.25",
              "Units": 1,
              "Sales": 15.08,
              "GM": 6.52
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 216,
              "Subcategory": "COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2066,
              "Name": "Cafe Jarrito Tric Lagrima To go",
              "Price": "4.00",
              "Units": 35,
              "Sales": 115.81,
              "GM": 6.5
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "GEORGALOS",
              "EAN": 7790380016366,
              "Name": "GEO TOKKE CHOC DDL 25G",
              "Price": "1.41",
              "Units": 14,
              "Sales": 16.39,
              "GM": 6.48
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "OSRAM",
              "EAN": 7798011660131,
              "Name": "OSRM Lamparita 5007 2und",
              "Price": "2.30",
              "Units": 7,
              "Sales": 13.3,
              "GM": 6.44
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "KRAFT",
              "EAN": 7794600459650,
              "Name": "KR Club Social Pizza 25gr",
              "Price": "0.70",
              "Units": 23,
              "Sales": 13.34,
              "GM": 6.32
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 9313,
              "Name": "SPRITE ZERO 237ML",
              "Price": "2.30",
              "Units": 7,
              "Sales": 13.37,
              "GM": 6.31
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 208,
              "Subcategory": "SOUP & SALAD",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3447,
              "Name": "Ensalada Verde 200gr",
              "Price": "9.25",
              "Units": 2,
              "Sales": 15.28,
              "GM": 6.31
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 208,
              "Subcategory": "SOUP & SALAD",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3446,
              "Name": "ESSO Ensalad Primav 200g Canast",
              "Price": "10.00",
              "Units": 2,
              "Sales": 16.52,
              "GM": 6.28
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 81,
              "Subcategory": "OTHER DAIRY",
              "Manufacturer": "DANONE",
              "EAN": 7791337005709,
              "Name": "DNN Actimel Natural 100gr",
              "Price": "1.43",
              "Units": 20,
              "Sales": 23.59,
              "GM": 6.21
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 170,
              "Subcategory": "SMOKING ACCESSOIRES",
              "Manufacturer": "CIA ARG FOSFOROS",
              "EAN": 9913,
              "Name": "CAF Fragata x 50und",
              "Price": "0.30",
              "Units": 51,
              "Sales": 12.75,
              "GM": 6.16
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "GEORGALOS",
              "EAN": 7790380016359,
              "Name": "GEO Tokke Choc  Cookie 25g",
              "Price": "1.42",
              "Units": 13,
              "Sales": 15.31,
              "GM": 6.1
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 77916044,
              "Name": "KR Milka Leger Leche 25gr",
              "Price": "1.50",
              "Units": 25,
              "Sales": 31,
              "GM": 6.06
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222042144,
              "Name": "BNFD NUGATON MINI BCO 72GR",
              "Price": "3.48",
              "Units": 5,
              "Sales": 14.38,
              "GM": 6.05
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 216,
              "Subcategory": "COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2045,
              "Name": "Cafe Americano Vienes Chico",
              "Price": "4.00",
              "Units": 3,
              "Sales": 9.93,
              "GM": 6.01
          },
          {
              "CategoryId": 13,
              "Category": "Otro Tabaco",
              "SubcategoryId": 15,
              "Subcategory": "Cigarros",
              "Manufacturer": "PURO TABACO",
              "EAN": 8816,
              "Name": "PTBC JL Piedra Petit Cetros Und",
              "Price": "12.30",
              "Units": 1,
              "Sales": 10.35,
              "GM": 5.95
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "OTHER",
              "EAN": 7792860012967,
              "Name": "LHE CHUPET�N GIRO LOCO 12G",
              "Price": "1.90",
              "Units": 10,
              "Sales": 15.7,
              "GM": 5.94
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "KRAFT",
              "EAN": 7794910452648,
              "Name": "KR CEREALITAS SALVADO 200G",
              "Price": "2.60",
              "Units": 7,
              "Sales": 15.05,
              "GM": 5.94
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 174,
              "Subcategory": "SEASONAL",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 9914,
              "Name": "TPS Carbon Vegetal Pack 10kg",
              "Price": "28.00",
              "Units": 1,
              "Sales": 23.14,
              "GM": 5.89
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790040720107,
              "Name": "Mana Vainillas 145g",
              "Price": "2.20",
              "Units": 8,
              "Sales": 14.56,
              "GM": 5.84
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 62,
              "Subcategory": "Caramelos sueltos",
              "Manufacturer": "GEORGALOS",
              "EAN": 1155,
              "Name": "GRG FLYNN PAFF CARAMELO MANZANA",
              "Price": "0.20",
              "Units": 65,
              "Sales": 10.84,
              "GM": 5.83
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "ARCOR",
              "EAN": 7790040534803,
              "Name": "Arcor Formis Choc 108g",
              "Price": "2.20",
              "Units": 8,
              "Sales": 14.56,
              "GM": 5.71
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "OTHER",
              "EAN": 8410031935349,
              "Name": "ADVENTURE POP  13 GR.",
              "Price": "10.00",
              "Units": 2,
              "Sales": 16.53,
              "GM": 5.69
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "ARCOR",
              "EAN": 7790580387006,
              "Name": "Saladix Pizza 30gr",
              "Price": "1.00",
              "Units": 16,
              "Sales": 13.28,
              "GM": 5.67
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 50,
              "Subcategory": "Otras Bebidas",
              "Manufacturer": "OTHER",
              "EAN": 7790950104615,
              "Name": "CA Terma Bosq del Sur 420ml Pet",
              "Price": "2.40",
              "Units": 6,
              "Sales": 11.88,
              "GM": 5.62
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 219,
              "Subcategory": "CAPPUCC./SPEC.COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2062,
              "Name": "Adicional  Crema",
              "Price": "1.00",
              "Units": 16,
              "Sales": 13.26,
              "GM": 5.58
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771850450000,
              "Name": "SEVENTEEN",
              "Price": "5.50",
              "Units": 1,
              "Sales": 5.5,
              "GM": 5.5
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "MARS INCORPORATED",
              "EAN": 7896423424379,
              "Name": "MARS TWIX 30GR",
              "Price": "2.00",
              "Units": 8,
              "Sales": 13.21,
              "GM": 5.45
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "ARCOR",
              "EAN": 7790580253912,
              "Name": "Mr Pops Chupetin Bots 12.5g",
              "Price": "3.50",
              "Units": 5,
              "Sales": 14.46,
              "GM": 5.43
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548426,
              "Name": "DNN SER AGUA PLUS LIMA LIM 1.5L",
              "Price": "4.90",
              "Units": 3,
              "Sales": 12.15,
              "GM": 5.42
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3064,
              "Name": "Adicional Queso Pratto",
              "Price": "1.00",
              "Units": 25,
              "Sales": 20.69,
              "GM": 5.42
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "PEPSI",
              "EAN": 7792799578794,
              "Name": "NESTLE 5000   5LTS",
              "Price": "6.50",
              "Units": 5,
              "Sales": 26.85,
              "GM": 5.41
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "KRAFT",
              "EAN": 77917225,
              "Name": "KR CEREALITA BANANA ALM BARRA 23",
              "Price": "1.20",
              "Units": 12,
              "Sales": 11.88,
              "GM": 5.4
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082740183,
              "Name": "TM Tia Maruca Naranjitas 250gr",
              "Price": "3.50",
              "Units": 4,
              "Sales": 11.56,
              "GM": 5.4
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77904744,
              "Name": "CAD Yapa 17gr",
              "Price": "0.50",
              "Units": 38,
              "Sales": 15.66,
              "GM": 5.36
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 63,
              "Subcategory": "Golosinas a granel",
              "Manufacturer": "ARCOR",
              "EAN": 7790580470807,
              "Name": "ARCOR GAJITOS 150G",
              "Price": "3.50",
              "Units": 5,
              "Sales": 14.45,
              "GM": 5.35
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "CLAMER SRL",
              "EAN": 3708,
              "Name": "CSRL Martin R Alf Maic Dulc Lech",
              "Price": "2.60",
              "Units": 5,
              "Sales": 10.75,
              "GM": 5.32
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 50,
              "Subcategory": "Otras Bebidas",
              "Manufacturer": "OTHER",
              "EAN": 7790950106633,
              "Name": "CA Terma Bosq d Sur Light Pet",
              "Price": "2.40",
              "Units": 7,
              "Sales": 13.86,
              "GM": 5.29
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "POSTRES BALCARCE",
              "EAN": 6221,
              "Name": "PB Balcarce Alf Mixto caja 6 ud",
              "Price": "14.00",
              "Units": 1,
              "Sales": 11.57,
              "GM": 5.24
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222002803,
              "Name": "BNFD DRF LIMON 23 GR",
              "Price": "0.80",
              "Units": 13,
              "Sales": 8.58,
              "GM": 5.13
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 171,
              "Subcategory": "VIDEO / AUDIO / TAPE",
              "Manufacturer": "KODAK",
              "EAN": 881295710696,
              "Name": "KOD Kodak DVD en Sobre 16x",
              "Price": "3.46",
              "Units": 7,
              "Sales": 20.02,
              "GM": 5.04
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310980729,
              "Name": "FL DORITOS QUESO 58G",
              "Price": "2.40",
              "Units": 7,
              "Sales": 13.86,
              "GM": 5.04
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BAT",
              "EAN": 77920249,
              "Name": "Derby Suave 100 20und",
              "Price": "3.51",
              "Units": 23,
              "Sales": 75.12,
              "GM": 4.98
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580395100,
              "Name": "Arcor Slice Mix Frutas 70ml",
              "Price": "2.00",
              "Units": 8,
              "Sales": 13.23,
              "GM": 4.96
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77935731,
              "Name": "PM Imparciales 100 20und",
              "Price": "3.40",
              "Units": 23,
              "Sales": 73.05,
              "GM": 4.95
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 1133,
              "Name": "Aguila Medallon Menta 15gr",
              "Price": "0.70",
              "Units": 22,
              "Sales": 12.76,
              "GM": 4.95
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7791249010518,
              "Name": "CAD ADAMS MENTA 11.6 GR",
              "Price": "0.90",
              "Units": 15,
              "Sales": 11.13,
              "GM": 4.91
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 81,
              "Subcategory": "OTHER DAIRY",
              "Manufacturer": "DANONE",
              "EAN": 7791337074071,
              "Name": "DNN Ser Serenito Shake 135ml",
              "Price": "2.33",
              "Units": 12,
              "Sales": 23.09,
              "GM": 4.9
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "OTHER",
              "EAN": 5011053015618,
              "Name": "MEGA ROLL 22 ML",
              "Price": "4.10",
              "Units": 3,
              "Sales": 10.17,
              "GM": 4.83
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 138,
              "Subcategory": "OTROS ALMACEN",
              "Manufacturer": "KRAFT",
              "EAN": 7790050987057,
              "Name": "KR Tang Sobre Naranja 45gr",
              "Price": "1.20",
              "Units": 9,
              "Sales": 8.99,
              "GM": 4.8
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 156,
              "Subcategory": "PRESERVATIVOS",
              "Manufacturer": "BUHL",
              "EAN": 7791014090332,
              "Name": "Tulipan preservativo texturado3u",
              "Price": "3.10",
              "Units": 4,
              "Sales": 10.24,
              "GM": 4.73
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 50,
              "Subcategory": "Otras Bebidas",
              "Manufacturer": "OTHER",
              "EAN": 7790950104608,
              "Name": "CA Terma Traslasierra 420ml Pet",
              "Price": "2.40",
              "Units": 5,
              "Sales": 9.9,
              "GM": 4.65
          },
          {
              "CategoryId": 224,
              "Category": "Bebidas fr�as dispensadas",
              "SubcategoryId": 225,
              "Subcategory": "FOUNTAIN-CARBONATED",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2307,
              "Name": "Gaseosa FastFood ChicaCoca Light",
              "Price": "2.50",
              "Units": 5,
              "Sales": 10.35,
              "GM": 4.65
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "OSRAM",
              "EAN": 7798011660841,
              "Name": "OSRM Lamparita 2825 2und",
              "Price": "3.70",
              "Units": 3,
              "Sales": 9.18,
              "GM": 4.65
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 149,
              "Subcategory": "ANALGESICOS",
              "Manufacturer": "BAYER",
              "EAN": 9808,
              "Name": "BY Bayaspirina C 3.385gr",
              "Price": "1.00",
              "Units": 12,
              "Sales": 12,
              "GM": 4.59
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 165,
              "Subcategory": "FILM / PHOTO",
              "Manufacturer": "KODAK",
              "EAN": 86806034265,
              "Name": "KOD KODAK KCX24",
              "Price": "10.20",
              "Units": 2,
              "Sales": 16.86,
              "GM": 4.56
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9771515851005,
              "Name": "Vivir Mejor",
              "Price": "4.50",
              "Units": 1,
              "Sales": 4.5,
              "GM": 4.5
          },
          {
              "CategoryId": 232,
              "Category": "Bebidas Congeladas dispensadas",
              "SubcategoryId": 234,
              "Subcategory": "FROZEN NON-CARBONATE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2600,
              "Name": "Cafe Frappe",
              "Price": "7.75",
              "Units": 3,
              "Sales": 19.21,
              "GM": 4.49
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "OSRAM",
              "EAN": 7798011660124,
              "Name": "OSRM Lampara 533 2und",
              "Price": "2.90",
              "Units": 4,
              "Sales": 9.6,
              "GM": 4.48
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BAT",
              "EAN": 77920416,
              "Name": "Winston Box 20und",
              "Price": "3.92",
              "Units": 17,
              "Sales": 62.07,
              "GM": 4.44
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082740275,
              "Name": "TM Bizc Azucarados 250gr",
              "Price": "3.50",
              "Units": 3,
              "Sales": 8.67,
              "GM": 4.44
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77922694,
              "Name": "MENTHOPLUS MENTA 30.6 GR",
              "Price": "1.00",
              "Units": 12,
              "Sales": 9.95,
              "GM": 4.42
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 50,
              "Subcategory": "Otras Bebidas",
              "Manufacturer": "OTHER",
              "EAN": 7790950106602,
              "Name": "CA Terma Traslasierra Lig Pet",
              "Price": "2.40",
              "Units": 5,
              "Sales": 9.9,
              "GM": 4.35
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BAT",
              "EAN": 77910967,
              "Name": "Derby KS 20und",
              "Price": "3.43",
              "Units": 10,
              "Sales": 31.92,
              "GM": 4.32
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BAT",
              "EAN": 77910318,
              "Name": "43/70 KS 20und",
              "Price": "3.27",
              "Units": 19,
              "Sales": 58,
              "GM": 4.25
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222041130,
              "Name": "BNFD BONAFIDE OBLEAS VAINILLA 48",
              "Price": "1.38",
              "Units": 8,
              "Sales": 9.07,
              "GM": 4.22
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222042441,
              "Name": "BNFD DONUTS NARANJA 91G",
              "Price": "4.20",
              "Units": 2,
              "Sales": 6.94,
              "GM": 4.16
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 7790895067570,
              "Name": "Coca Cola Zero 2.25",
              "Price": "5.40",
              "Units": 3,
              "Sales": 13.38,
              "GM": 4.14
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "ARCOR",
              "EAN": 77916457,
              "Name": "Topline Tableta Mento 4und",
              "Price": "0.80",
              "Units": 15,
              "Sales": 9.9,
              "GM": 4.12
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 216,
              "Subcategory": "COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2047,
              "Name": "CAF� AMERICANO SABORIZADO CHICO",
              "Price": "4.00",
              "Units": 2,
              "Sales": 6.62,
              "GM": 4.1
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 171,
              "Subcategory": "VIDEO / AUDIO / TAPE",
              "Manufacturer": "KODAK",
              "EAN": 881295710719,
              "Name": "KOD Kodak CD en Sobr 52x",
              "Price": "2.46",
              "Units": 8,
              "Sales": 16.26,
              "GM": 4.1
          },
          {
              "CategoryId": 13,
              "Category": "Otro Tabaco",
              "SubcategoryId": 15,
              "Subcategory": "Cigarros",
              "Manufacturer": "PURO TABACO",
              "EAN": 8815,
              "Name": "PTBC JL Piedra Nacionales Und",
              "Price": "11.50",
              "Units": 1,
              "Sales": 9.51,
              "GM": 4.06
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580394400,
              "Name": "Arcor Space Frutilla 65ml",
              "Price": "1.50",
              "Units": 8,
              "Sales": 9.92,
              "GM": 4
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222003121,
              "Name": "BND Bonafide Mentitas Citrus 25g",
              "Price": "0.80",
              "Units": 11,
              "Sales": 7.27,
              "GM": 3.98
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BAT",
              "EAN": 77910431,
              "Name": "43/70 100 S 20und",
              "Price": "3.44",
              "Units": 16,
              "Sales": 51.32,
              "GM": 3.96
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 1126,
              "Name": "CAD Bazoo Chic Horosc Oc Ment 4g",
              "Price": "0.10",
              "Units": 75,
              "Sales": 6.21,
              "GM": 3.96
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77933423,
              "Name": "COFLER CEREAL 28 GS",
              "Price": "1.40",
              "Units": 8,
              "Sales": 9.27,
              "GM": 3.95
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "CLAMER SRL",
              "EAN": 3706,
              "Name": "CSRL Martin de Ridder Pasta Frol",
              "Price": "2.10",
              "Units": 5,
              "Sales": 8.7,
              "GM": 3.95
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BRITISH AMERICAN",
              "EAN": 77910479,
              "Name": "JOCKEY CLUB SUAVE SOFT KS10U",
              "Price": "1.76",
              "Units": 32,
              "Sales": 52.64,
              "GM": 3.87
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 5049,
              "Name": "PANINO CEBOLLA Y QUESO",
              "Price": "8.50",
              "Units": 1,
              "Sales": 7.02,
              "GM": 3.77
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "NESTLE",
              "EAN": 7798130950052,
              "Name": "NES NERDS HUEVO 134G",
              "Price": "11.50",
              "Units": 1,
              "Sales": 9.5,
              "GM": 3.69
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 6538,
              "Name": "TPS Plumero",
              "Price": "11.00",
              "Units": 1,
              "Sales": 9.09,
              "GM": 3.69
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 118,
              "Subcategory": "Galletas",
              "Manufacturer": "TIA MARUCA",
              "EAN": 7798082741449,
              "Name": "TM Tia Maruca Tubo Gluten 180gr",
              "Price": "3.50",
              "Units": 3,
              "Sales": 8.67,
              "GM": 3.69
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 9314,
              "Name": "SWCHEPPES TONICA 237ML",
              "Price": "2.30",
              "Units": 4,
              "Sales": 7.6,
              "GM": 3.68
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 121,
              "Subcategory": "MEAT SNACKS",
              "Manufacturer": "GEORGALOS",
              "EAN": 7790380016137,
              "Name": "GRG Cereal Flow Merme Frut 25g",
              "Price": "1.30",
              "Units": 6,
              "Sales": 6.42,
              "GM": 3.54
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77946843,
              "Name": "VACA LECHERA CARAMELO 27GR",
              "Price": "1.00",
              "Units": 9,
              "Sales": 7.46,
              "GM": 3.52
          },
          {
              "CategoryId": 232,
              "Category": "Bebidas Congeladas dispensadas",
              "SubcategoryId": 234,
              "Subcategory": "FROZEN NON-CARBONATE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2603,
              "Name": "BATIDO FRUTILLA",
              "Price": "7.75",
              "Units": 1,
              "Sales": 6.4,
              "GM": 3.44
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "LEHRITIER",
              "EAN": 1109,
              "Name": "LHRT PICO DULCE ICE COOL 15G",
              "Price": "0.50",
              "Units": 21,
              "Sales": 8.67,
              "GM": 3.44
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "KRAFT",
              "EAN": 77917249,
              "Name": "KR CEREALITA MANZANA BARRA 23GR",
              "Price": "1.20",
              "Units": 7,
              "Sales": 6.93,
              "GM": 3.37
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "CHUPA CHUPS",
              "EAN": 76350553008,
              "Name": "CH Crazy Dips Uva 12Gr",
              "Price": "1.40",
              "Units": 7,
              "Sales": 8.11,
              "GM": 3.36
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 154,
              "Subcategory": "GROOMING AIDS",
              "Manufacturer": "ENERGIZER",
              "EAN": 75920036,
              "Name": "ENZ Exacta Piel Sensible Shick 1und",
              "Price": "2.10",
              "Units": 2,
              "Sales": 3.48,
              "GM": 3.36
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "ARCOR",
              "EAN": 7790580244415,
              "Name": "Monstruos Locos 12g",
              "Price": "3.50",
              "Units": 3,
              "Sales": 8.68,
              "GM": 3.28
          },
          {
              "CategoryId": 232,
              "Category": "Bebidas Congeladas dispensadas",
              "SubcategoryId": 234,
              "Subcategory": "FROZEN NON-CARBONATE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2605,
              "Name": "SMOOTHIE MIX FRUTILLA-DURAZNO",
              "Price": "7.25",
              "Units": 1,
              "Sales": 5.99,
              "GM": 3.25
          },
          {
              "CategoryId": 178,
              "Category": "Publicaciones",
              "SubcategoryId": 180,
              "Subcategory": "MAGAZINES/TABLOIDS",
              "Manufacturer": "OTHER",
              "EAN": 9770328423003,
              "Name": "Mia Semanario Femenino",
              "Price": "3.20",
              "Units": 1,
              "Sales": 3.2,
              "GM": 3.2
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77919298,
              "Name": "PM Chesterfield King Size 20und",
              "Price": "3.60",
              "Units": 14,
              "Sales": 47.04,
              "GM": 3.17
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "KRAFT",
              "EAN": 77922168,
              "Name": "KR CEREALITA COCO BARRA 23GR",
              "Price": "1.20",
              "Units": 7,
              "Sales": 6.94,
              "GM": 3.14
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3062,
              "Name": "Adicional Salsa Cr.Con Tomate",
              "Price": "1.00",
              "Units": 17,
              "Sales": 14.07,
              "GM": 3
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 216,
              "Subcategory": "COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2025,
              "Name": "Cafe Expresso/Cortado Descaf",
              "Price": "2.50",
              "Units": 2,
              "Sales": 4.13,
              "GM": 2.96
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 112,
              "Subcategory": "Crackers",
              "Manufacturer": "BAGLEY",
              "EAN": 7790040545939,
              "Name": "BGLY Criollitas Tres Cereales 140G",
              "Price": "2.00",
              "Units": 4,
              "Sales": 6.6,
              "GM": 2.95
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "GUAYMAYEN",
              "EAN": 77980212,
              "Name": "GYM Guaymayen Alfajor Dulc Leche 38gr",
              "Price": "0.60",
              "Units": 13,
              "Sales": 6.49,
              "GM": 2.93
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 6521,
              "Name": "TPS Blister Lamparas 529 2und",
              "Price": "4.70",
              "Units": 2,
              "Sales": 7.77,
              "GM": 2.89
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 6525,
              "Name": "TPS Blister Lamparas 64196 Und",
              "Price": "9.20",
              "Units": 1,
              "Sales": 7.6,
              "GM": 2.85
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7798011660377,
              "Name": "TPSTopaes Rejilla Florest-Bli",
              "Price": "9.00",
              "Units": 1,
              "Sales": 7.44,
              "GM": 2.85
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 149,
              "Subcategory": "ANALGESICOS",
              "Manufacturer": "BAYER",
              "EAN": 9800,
              "Name": "BY Aspirinetas Tira 10und",
              "Price": "2.50",
              "Units": 2,
              "Sales": 5,
              "GM": 2.82
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 165,
              "Subcategory": "FILM / PHOTO",
              "Manufacturer": "KODAK",
              "EAN": 86806034401,
              "Name": "KOD KODAK KCX36",
              "Price": "12.70",
              "Units": 1,
              "Sales": 10.5,
              "GM": 2.81
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "GEORGALOS",
              "EAN": 1119,
              "Name": "GRG Tokke Bombon Relleno DDL10gr",
              "Price": "0.50",
              "Units": 11,
              "Sales": 4.55,
              "GM": 2.79
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 5040,
              "Name": "ADICIONAL QUESO DE M�QUINA",
              "Price": "1.30",
              "Units": 6,
              "Sales": 6.44,
              "GM": 2.77
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "ARCOR",
              "EAN": 77916396,
              "Name": "Topline Tableta Stron 4und",
              "Price": "0.80",
              "Units": 10,
              "Sales": 6.6,
              "GM": 2.75
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 189,
              "Subcategory": "MOTOR OIL",
              "Manufacturer": "SOGEFI FILTRATION",
              "EAN": 6725,
              "Name": "SFA Filtros Fram G-12",
              "Price": "7.82",
              "Units": 1,
              "Sales": 6.46,
              "GM": 2.73
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7798011661213,
              "Name": "TPS Cem Polv Ver Got Rad Bloc20G",
              "Price": "5.80",
              "Units": 1,
              "Sales": 4.79,
              "GM": 2.71
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TACSA",
              "EAN": 7798039290327,
              "Name": "TCS Tacsa Cinta Aislad Und",
              "Price": "3.70",
              "Units": 2,
              "Sales": 6.12,
              "GM": 2.68
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "NESTLE",
              "EAN": 79200003364,
              "Name": "NES Nerd Grape & Strawberry 46.7Gr",
              "Price": "3.70",
              "Units": 2,
              "Sales": 6.12,
              "GM": 2.66
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 207,
              "Subcategory": "BAKERY",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3734,
              "Name": "Vigilante",
              "Price": "1.30",
              "Units": 4,
              "Sales": 4.28,
              "GM": 2.64
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 62,
              "Subcategory": "Caramelos sueltos",
              "Manufacturer": "GEORGALOS",
              "EAN": 1156,
              "Name": "GRG Flynn Paff Caramelo T Fruti",
              "Price": "0.20",
              "Units": 30,
              "Sales": 4.96,
              "GM": 2.64
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77923288,
              "Name": "MENTHOPLUS AQUA MENTA EUC",
              "Price": "1.00",
              "Units": 8,
              "Sales": 6.62,
              "GM": 2.62
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BAT",
              "EAN": 77920713,
              "Name": "Viceroy Classic Rojo Box 10",
              "Price": "1.41",
              "Units": 28,
              "Sales": 36.56,
              "GM": 2.49
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 138,
              "Subcategory": "OTROS ALMACEN",
              "Manufacturer": "QUIMICA ESTRELLA",
              "EAN": 7791150040765,
              "Name": "QE TODDY 180GR",
              "Price": "4.40",
              "Units": 1,
              "Sales": 3.64,
              "GM": 2.43
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "LEHRITIER",
              "EAN": 1108,
              "Name": "LHRT Pico Dulce Chips 15G",
              "Price": "0.50",
              "Units": 15,
              "Sales": 6.18,
              "GM": 2.43
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580397609,
              "Name": "Arcor Tacita Vainilla Choco 90ml",
              "Price": "1.80",
              "Units": 4,
              "Sales": 5.96,
              "GM": 2.42
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 154,
              "Subcategory": "GROOMING AIDS",
              "Manufacturer": "UNILEVER",
              "EAN": 7791293020303,
              "Name": "UNI Impulse Desod dynamique 82ml",
              "Price": "7.00",
              "Units": 1,
              "Sales": 5.79,
              "GM": 2.37
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77949349,
              "Name": "COFLER CUBANITO SEMIAMAR 16G",
              "Price": "0.90",
              "Units": 6,
              "Sales": 4.46,
              "GM": 2.34
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77923769,
              "Name": "PALL MALL BOX 20 BE",
              "Price": "3.27",
              "Units": 23,
              "Sales": 70.24,
              "GM": 2.33
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77919915,
              "Name": "CAD HALLS FREE MORA 20G",
              "Price": "1.30",
              "Units": 5,
              "Sales": 5.35,
              "GM": 2.33
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 175,
              "Subcategory": "OTHER GM",
              "Manufacturer": "COCA COLA",
              "EAN": 7403,
              "Name": "Coca Cola Bandeja Canje",
              "Price": "5.00",
              "Units": 6,
              "Sales": 24.79,
              "GM": 2.29
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 154,
              "Subcategory": "GROOMING AIDS",
              "Manufacturer": "UNILEVER",
              "EAN": 9807,
              "Name": "UNI Sedal Shampoo 15ml",
              "Price": "0.60",
              "Units": 9,
              "Sales": 4.48,
              "GM": 2.29
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 1129,
              "Name": "CAD BUBALOO FRUTILLA 5.5G",
              "Price": "0.20",
              "Units": 40,
              "Sales": 6.63,
              "GM": 2.26
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "OSRAM",
              "EAN": 7798011660889,
              "Name": "OSRM Osram Lamp 6428 2und",
              "Price": "8.40",
              "Units": 1,
              "Sales": 6.94,
              "GM": 2.24
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7791249977170,
              "Name": "CAD MANTECOL MARMOLADO 25G",
              "Price": "1.00",
              "Units": 6,
              "Sales": 4.98,
              "GM": 2.22
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77926791,
              "Name": "PM L&M Menthol Box 20und",
              "Price": "3.50",
              "Units": 10,
              "Sales": 32.69,
              "GM": 2.21
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 107,
              "Subcategory": "Papas Fritas",
              "Manufacturer": "FRITO LAY",
              "EAN": 7790310980262,
              "Name": "FL Lays Doritos Queso 50g",
              "Price": "2.40",
              "Units": 3,
              "Sales": 5.94,
              "GM": 2.16
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 138,
              "Subcategory": "OTROS ALMACEN",
              "Manufacturer": "DANONE",
              "EAN": 7790315058324,
              "Name": "DAN SER POLVO MANZANA TENTACION",
              "Price": "0.90",
              "Units": 6,
              "Sales": 4.45,
              "GM": 2.13
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BRITISH AMERICAN",
              "EAN": 77910592,
              "Name": "VICEROY CLAS FF SOFT CUP 10U",
              "Price": "1.33",
              "Units": 20,
              "Sales": 24.46,
              "GM": 2.11
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222003756,
              "Name": "BND Bonafide Mentitas Mentol 25g",
              "Price": "0.80",
              "Units": 6,
              "Sales": 3.96,
              "GM": 2.05
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 48,
              "Subcategory": "Jugos",
              "Manufacturer": "BAGGIO",
              "EAN": 7790036512372,
              "Name": "RPBB Baggio Manzana 250ml",
              "Price": "1.30",
              "Units": 4,
              "Sales": 4.28,
              "GM": 2
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77913098,
              "Name": "PM Chesterfield KS Box 10",
              "Price": "1.75",
              "Units": 7,
              "Sales": 11.43,
              "GM": 1.92
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7798011060016,
              "Name": "TPS Ron Bay Fus Americana 10L",
              "Price": "5.50",
              "Units": 1,
              "Sales": 4.55,
              "GM": 1.91
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77949035,
              "Name": "COFLER AIREADO BLANCO 30G.",
              "Price": "1.40",
              "Units": 6,
              "Sales": 6.96,
              "GM": 1.87
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 156,
              "Subcategory": "PRESERVATIVOS",
              "Manufacturer": "OTHER",
              "EAN": 7798134870042,
              "Name": "PRESERV JAGER SENSITIVE",
              "Price": "4.40",
              "Units": 1,
              "Sales": 3.64,
              "GM": 1.87
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7798011060023,
              "Name": "TPS Ron Bay Fus Europea 10L",
              "Price": "5.50",
              "Units": 1,
              "Sales": 4.55,
              "GM": 1.85
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "BONAFIDE",
              "EAN": 7792222003534,
              "Name": "BND Bonafide Mentitas Light 25g",
              "Price": "0.82",
              "Units": 5,
              "Sales": 3.38,
              "GM": 1.83
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77923387,
              "Name": "CAD HALLS AIR MINT 28G",
              "Price": "1.20",
              "Units": 4,
              "Sales": 3.96,
              "GM": 1.78
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 154,
              "Subcategory": "GROOMING AIDS",
              "Manufacturer": "UNILEVER",
              "EAN": 9806,
              "Name": "UNI Sedal Enjuague 10ml",
              "Price": "0.51",
              "Units": 8,
              "Sales": 3.39,
              "GM": 1.7
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "OSRAM",
              "EAN": 7798011660865,
              "Name": "OSRM Lampara 6411 2und",
              "Price": "6.90",
              "Units": 1,
              "Sales": 5.7,
              "GM": 1.68
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 167,
              "Subcategory": "GREETING/GIFT/TOYS",
              "Manufacturer": "PEPSI",
              "EAN": 7207,
              "Name": "Llavero Pepsi",
              "Price": "3.00",
              "Units": 1,
              "Sales": 2.48,
              "GM": 1.65
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 7790580305314,
              "Name": "TOP LINE CARAMEL MENTA S AZ",
              "Price": "1.30",
              "Units": 3,
              "Sales": 3.21,
              "GM": 1.63
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77920966,
              "Name": "PM Next FF 10",
              "Price": "1.33",
              "Units": 13,
              "Sales": 16.13,
              "GM": 1.58
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BAT",
              "EAN": 77910660,
              "Name": "Richmond KS 20und",
              "Price": "1.50",
              "Units": 11,
              "Sales": 14,
              "GM": 1.57
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7798011661169,
              "Name": "TPS Top Lamp Auto H3 12v 55w U",
              "Price": "4.50",
              "Units": 1,
              "Sales": 3.72,
              "GM": 1.49
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "NESTLE",
              "EAN": 84194107,
              "Name": "IMS: ChupaChups Sorp 12 gr",
              "Price": "2.00",
              "Units": 5,
              "Sales": 8.25,
              "GM": 1.43
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "ARCOR",
              "EAN": 7790040939004,
              "Name": "REX 3 QUESO CREM & CIBOULET",
              "Price": "1.90",
              "Units": 2,
              "Sales": 3.14,
              "GM": 1.39
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "MARS",
              "EAN": 7896423424874,
              "Name": "MARS Snickers 52g",
              "Price": "3.30",
              "Units": 1,
              "Sales": 2.73,
              "GM": 1.38
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "ARCOR",
              "EAN": 77923271,
              "Name": "MENTHOPLUS AQUA CEREZA NAR",
              "Price": "1.00",
              "Units": 4,
              "Sales": 3.32,
              "GM": 1.36
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77915979,
              "Name": "PM Next Milds Taste 20und",
              "Price": "2.60",
              "Units": 8,
              "Sales": 19.34,
              "GM": 1.35
          },
          {
              "CategoryId": 134,
              "Category": "Almacen",
              "SubcategoryId": 135,
              "Subcategory": "CAFE Y TE",
              "Manufacturer": "QUIMICA ESTRELLA",
              "EAN": 7791445001051,
              "Name": "QE YE CRUZ MALTA MATE COC 25 SAQ",
              "Price": "2.70",
              "Units": 1,
              "Sales": 2.23,
              "GM": 1.32
          },
          {
              "CategoryId": 140,
              "Category": "Limpieza",
              "SubcategoryId": 144,
              "Subcategory": "PAPER/PLASTIC/FOIL",
              "Manufacturer": "PAPELERA DEL PLATA",
              "EAN": 7790250010517,
              "Name": "PDP Higienol Rollo 50mts Und",
              "Price": "1.70",
              "Units": 2,
              "Sales": 2.8,
              "GM": 1.32
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 5042,
              "Name": "Adicional Lechuga",
              "Price": "0.50",
              "Units": 10,
              "Sales": 4.13,
              "GM": 1.25
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 209,
              "Subcategory": "OTHER CUISINE",
              "Manufacturer": "UNILEVER",
              "EAN": 9700,
              "Name": "UNI QUICK SOPA VEGETALES UNID",
              "Price": "1.30",
              "Units": 2,
              "Sales": 2.14,
              "GM": 1.22
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 175,
              "Subcategory": "OTHER GM",
              "Manufacturer": "COCA COLA",
              "EAN": 7401,
              "Name": "Coca Cola Vaso Canje",
              "Price": "2.00",
              "Units": 8,
              "Sales": 13.22,
              "GM": 1.22
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BAT",
              "EAN": 77925695,
              "Name": "Viceroy Fire Box 20",
              "Price": "2.75",
              "Units": 7,
              "Sales": 17.97,
              "GM": 1.2
          },
          {
              "CategoryId": 148,
              "Category": "Salud y Belleza",
              "SubcategoryId": 156,
              "Subcategory": "PRESERVATIVOS",
              "Manufacturer": "BUHL",
              "EAN": 7791014001178,
              "Name": "BUHL Tulipan preserv Frutilla 3u",
              "Price": "3.10",
              "Units": 1,
              "Sales": 2.56,
              "GM": 1.18
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "TOPAES S.A.",
              "EAN": 7798011660803,
              "Name": "TPS Blister Lamparas 5627 2und",
              "Price": "3.70",
              "Units": 1,
              "Sales": 3.06,
              "GM": 1.14
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548761,
              "Name": "DNN VILLA DEL SUR FIZ POMEL 500",
              "Price": "2.80",
              "Units": 1,
              "Sales": 2.32,
              "GM": 1.13
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BAT",
              "EAN": 77910547,
              "Name": "Jockey Club Full FL Soft KS 10und",
              "Price": "1.76",
              "Units": 9,
              "Sales": 14.74,
              "GM": 1.1
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77912930,
              "Name": "PM Next Box 10und",
              "Price": "1.43",
              "Units": 10,
              "Sales": 13.29,
              "GM": 1.02
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 94,
              "Subcategory": "ICE CREAM",
              "Manufacturer": "ARCOR",
              "EAN": 77939678,
              "Name": "Arcor Rocklets Copa 120ml",
              "Price": "3.00",
              "Units": 1,
              "Sales": 2.48,
              "GM": 1.01
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77916273,
              "Name": "PM Le Mans Suave 10und",
              "Price": "1.75",
              "Units": 8,
              "Sales": 13.05,
              "GM": 0.98
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "GEORGALOS",
              "EAN": 7790380011699,
              "Name": "GRG Tokke 2 Banana 25gr",
              "Price": "1.10",
              "Units": 2,
              "Sales": 1.82,
              "GM": 0.98
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 49,
              "Subcategory": "Aguas",
              "Manufacturer": "DANONE",
              "EAN": 7798062548778,
              "Name": "DNN Villa del Sur Fiz Lim 500ml",
              "Price": "2.40",
              "Units": 1,
              "Sales": 1.99,
              "GM": 0.96
          },
          {
              "CategoryId": 44,
              "Category": "Bebidas Embotelladas",
              "SubcategoryId": 45,
              "Subcategory": "Bebidas Carbonatadas",
              "Manufacturer": "COCA COLA",
              "EAN": 9312,
              "Name": "COCA COLA BOTELLA LIGHT 237ML",
              "Price": "2.30",
              "Units": 1,
              "Sales": 1.91,
              "GM": 0.96
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "ARCOR",
              "EAN": 7790040274709,
              "Name": "Aguila Minitorta Menta",
              "Price": "1.30",
              "Units": 2,
              "Sales": 2.14,
              "GM": 0.84
          },
          {
              "CategoryId": 106,
              "Category": "Snacks Salados",
              "SubcategoryId": 113,
              "Subcategory": "Otros Snacks Salados",
              "Manufacturer": "ARCOR",
              "EAN": 7790040942103,
              "Name": "REX 3 CAPRESSE 60GR",
              "Price": "2.00",
              "Units": 1,
              "Sales": 1.65,
              "GM": 0.78
          },
          {
              "CategoryId": 188,
              "Category": "Productos para el Automotor",
              "SubcategoryId": 192,
              "Subcategory": "CAR CARE",
              "Manufacturer": "OSRAM",
              "EAN": 7798011660148,
              "Name": "OSRM Lam Patente Auto 3893 2und",
              "Price": "1.90",
              "Units": 1,
              "Sales": 1.57,
              "GM": 0.73
          },
          {
              "CategoryId": 75,
              "Category": "Lacteos",
              "SubcategoryId": 80,
              "Subcategory": "YOGURT",
              "Manufacturer": "DANONE",
              "EAN": 7791337103023,
              "Name": "DNN Activia Firme Frutilla 195g",
              "Price": "2.00",
              "Units": 1,
              "Sales": 1.65,
              "GM": 0.68
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "BRITISH AMERICAN",
              "EAN": 77910165,
              "Name": "DERBY SUAVE 10UND",
              "Price": "1.77",
              "Units": 3,
              "Sales": 4.94,
              "GM": 0.67
          },
          {
              "CategoryId": 120,
              "Category": "Cereales",
              "SubcategoryId": 123,
              "Subcategory": "ENERGY BARS",
              "Manufacturer": "OTHER",
              "EAN": 7792170110025,
              "Name": "QUAKER BARRA LEMON PIE 25GR",
              "Price": "1.00",
              "Units": 2,
              "Sales": 1.66,
              "GM": 0.66
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77958563,
              "Name": "Arcor Leche 8gr",
              "Price": "0.40",
              "Units": 4,
              "Sales": 1.32,
              "GM": 0.66
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "KRAFT",
              "EAN": 77921604,
              "Name": "KR Milka M Joy Choco Crunchy 26G",
              "Price": "1.60",
              "Units": 3,
              "Sales": 3.96,
              "GM": 0.66
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "FELFORT",
              "EAN": 1136,
              "Name": "FLFR Jackelin Bombon 14gr",
              "Price": "0.60",
              "Units": 4,
              "Sales": 1.98,
              "GM": 0.54
          },
          {
              "CategoryId": 92,
              "Category": "Helados",
              "SubcategoryId": 93,
              "Subcategory": "HELADOS PREMIUM",
              "Manufacturer": "ARCOR",
              "EAN": 7790580399009,
              "Name": "Mr Pops Cream  60ML",
              "Price": "1.50",
              "Units": 1,
              "Sales": 1.24,
              "GM": 0.5
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 57,
              "Subcategory": "Caramelos Duros",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 77917317,
              "Name": "CAD VITA-C FRUTILLA 27GR",
              "Price": "1.20",
              "Units": 1,
              "Sales": 0.99,
              "GM": 0.45
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 59,
              "Subcategory": "Barras sin chocolate",
              "Manufacturer": "ARCOR",
              "EAN": 77940131,
              "Name": "Arcor Turrron 25gr",
              "Price": "0.50",
              "Units": 2,
              "Sales": 0.83,
              "GM": 0.42
          },
          {
              "CategoryId": 115,
              "Category": "Snacks Dulces",
              "SubcategoryId": 116,
              "Subcategory": "Tortas / Pasteles",
              "Manufacturer": "ARCOR",
              "EAN": 7790040274600,
              "Name": "Aguila Minitorta Durazno",
              "Price": "1.30",
              "Units": 1,
              "Sales": 1.07,
              "GM": 0.42
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 58,
              "Subcategory": "Chocolates Barras y Tabletas",
              "Manufacturer": "ARCOR",
              "EAN": 77949233,
              "Name": "COFLER CUBANITO LECHE 16G",
              "Price": "0.90",
              "Units": 1,
              "Sales": 0.74,
              "GM": 0.39
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 7895800446805,
              "Name": "CAD CLORETS CHICLETS 33G",
              "Price": "1.10",
              "Units": 1,
              "Sales": 0.91,
              "GM": 0.39
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 205,
              "Subcategory": "SANDWICHES",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 5041,
              "Name": "Adicional Tomate",
              "Price": "0.50",
              "Units": 2,
              "Sales": 0.82,
              "GM": 0.32
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 62,
              "Subcategory": "Caramelos sueltos",
              "Manufacturer": "GEORGALOS",
              "EAN": 1125,
              "Name": "GRG FLYNN PAFF CARAM BANANA 5GR",
              "Price": "0.20",
              "Units": 3,
              "Sales": 0.51,
              "GM": 0.27
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 62,
              "Subcategory": "Caramelos sueltos",
              "Manufacturer": "GEORGALOS",
              "EAN": 1158,
              "Name": "GRG FLYNN PAFF CARAMEL LIMALIMON",
              "Price": "0.20",
              "Units": 2,
              "Sales": 0.34,
              "GM": 0.24
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 1130,
              "Name": "CAD Bubaloo Ensal Frutas 5.5gr",
              "Price": "0.20",
              "Units": 2,
              "Sales": 0.33,
              "GM": 0.21
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 9,
              "Subcategory": "Cigarrillos Descuento",
              "Manufacturer": "PHILIP MORRIS",
              "EAN": 77971005,
              "Name": "PM Dorados KX 20und",
              "Price": "1.40",
              "Units": 1,
              "Sales": 1.19,
              "GM": 0.14
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 1103,
              "Name": "CAD Bubaloo Tuti Fruti 5.5gr",
              "Price": "0.20",
              "Units": 2,
              "Sales": 0.33,
              "GM": 0.11
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 1102,
              "Name": "CAD Bubaloo Menta 5.5gr",
              "Price": "0.20",
              "Units": 1,
              "Sales": 0.17,
              "GM": 0.06
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 56,
              "Subcategory": "Gomas de Mascar",
              "Manufacturer": "CADBURY ADAMS",
              "EAN": 1100,
              "Name": "CAD Bazooka Horosc Ocul Fru 4g",
              "Price": "0.10",
              "Units": 1,
              "Sales": 0.08,
              "GM": 0.05
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 216,
              "Subcategory": "COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2048,
              "Name": "CAFE AMERICANO SABORIZADO GND",
              "Price": null,
              "Units": 0,
              "Sales": 0,
              "GM": 0
          },
          {
              "CategoryId": 224,
              "Category": "Bebidas fr�as dispensadas",
              "SubcategoryId": 225,
              "Subcategory": "FOUNTAIN-CARBONATED",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2306,
              "Name": "Gaseosa Fast Food Chica Coca",
              "Price": null,
              "Units": 0,
              "Sales": 0,
              "GM": 0
          },
          {
              "CategoryId": 224,
              "Category": "Bebidas fr�as dispensadas",
              "SubcategoryId": 225,
              "Subcategory": "FOUNTAIN-CARBONATED",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2308,
              "Name": "Gaseosa Fast Food Chica Naranja",
              "Price": null,
              "Units": 0,
              "Sales": 0,
              "GM": 0
          },
          {
              "CategoryId": 224,
              "Category": "Bebidas fr�as dispensadas",
              "SubcategoryId": 225,
              "Subcategory": "FOUNTAIN-CARBONATED",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2309,
              "Name": "Gaseosa FastFoodChica Limalimon",
              "Price": null,
              "Units": 0,
              "Sales": 0,
              "GM": 0
          },
          {
              "CategoryId": 198,
              "Category": "Comida",
              "SubcategoryId": 203,
              "Subcategory": "HOT DOGS/GRILL PROD.",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 3028,
              "Name": "Pancho Cantinflas",
              "Price": null,
              "Units": 0,
              "Sales": 0,
              "GM": 0
          },
          {
              "CategoryId": 162,
              "Category": "Mercader�a General",
              "SubcategoryId": 175,
              "Subcategory": "OTHER GM",
              "Manufacturer": "COCA COLA",
              "EAN": 7402,
              "Name": "Coca Cola Plato Canje",
              "Price": "1.75",
              "Units": 9,
              "Sales": 13.02,
              "GM": -0.34
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BRITISH AMERICAN",
              "EAN": 77970008,
              "Name": "Camel HULK Blue Box 20",
              "Price": "4.00",
              "Units": 10,
              "Sales": 33.47,
              "GM": -1.35
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BRITISH AMERICAN",
              "EAN": 77997456,
              "Name": "Camel HULK SC 20",
              "Price": "3.90",
              "Units": 11,
              "Sales": 35.89,
              "GM": -1.45
          },
          {
              "CategoryId": 215,
              "Category": "Bebidas Calientes",
              "SubcategoryId": 216,
              "Subcategory": "COFFEE",
              "Manufacturer": "AMSOUTH FOOD",
              "EAN": 2065,
              "Name": "Cafe Expr Cortado To Go",
              "Price": "3.60",
              "Units": 13,
              "Sales": 38.74,
              "GM": -1.85
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "NESTLE",
              "EAN": 79200231002,
              "Name": "NES WONKA RUNTS 51G",
              "Price": "3.50",
              "Units": 9,
              "Sales": 26.03,
              "GM": -2.21
          },
          {
              "CategoryId": 55,
              "Category": "Golosinas",
              "SubcategoryId": 61,
              "Subcategory": "Novedades de Temporada",
              "Manufacturer": "NESTLE",
              "EAN": 79200230401,
              "Name": "NES WONKA GOBSTOPPER 50.1G",
              "Price": "3.50",
              "Units": 24,
              "Sales": 69.38,
              "GM": -13.46
          },
          {
              "CategoryId": 7,
              "Category": "Cigarrillos",
              "SubcategoryId": 8,
              "Subcategory": "Cigarrillos Premium",
              "Manufacturer": "BRITISH AMERICAN",
              "EAN": 7793677911313,
              "Name": "Lucky Pacote 4 Bx Flyer",
              "Price": "16.00",
              "Units": 38,
              "Sales": 502.4,
              "GM": -26.89
          }
      ];
  });

