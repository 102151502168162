angular.module("prisma")
    .controller("ItemsCtrl", function ($scope, $state, $translate, adminService, $timeout, homeService) {

        //Variables Globales

        var self = this;
        self.selectedItem = null;
        //ITEM LOOKUP
        self.item = null;
        self.itemLookupText = '';
        self.itemsInLookup = [];
        self.selectedItem = null;
        self.loadingLookupItems = false;
        self.lookupItemsNoResults = true;

        self.lstItems = [];

        self.search = "";

        self.order = "";

        var previousLookupText = '';

        $scope.$watch('ct.itemLookupText', function (newVal, oldVal) {
            if (newVal) {

                if (newVal.id) {
                    self.selectedItem = self.itemLookupText;

                    getItem(newVal.id);
                }

                if (newVal && newVal != oldVal && (newVal.length == 4 || (oldVal.length < 3 && newVal.length >= 4)) && newVal != previousLookupText) {
                    previousLookupText = newVal;

                    self.itemsInLookup = [{ id: 0, code: '', name: 'Cargando Items con el texto ' + newVal + '...' }];
                    self.loadingLookupItems = true;
                    var lookupText = { SearchTerm: newVal };

                    homeService.lookups.abmItemsLookup(lookupText)
                        .then(function (items) {
                            self.itemsInLookup = items;
                            self.loadingLookupItems = false;
                            if (items.length > 0)
                                self.lookupItemsNoResults = false;
                            else
                                self.lookupItemsNoResults = true;
                        });
                }
            }

        }, true);

        function getItem(itemId) {
            self.isLoading = true;

            homeService.items.getItem(itemId)
                .then(function (item) {
                    self.lstItems.push(item);
                    self.isLoading = false;
                });
        }

        //Load Data
        function loadItems() {
            self.isLoading = true;
        }

        function init() {
            //loadItems();
        }

        init();
    });




