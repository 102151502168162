angular.module("prisma")
    .controller("pricesCtrl", function ($window, $rootScope, $timeout, $scope, $sce, promotionsService, $stateParams, $state, $filter, ngDialog) {
        //var
        var self = this;
        self.items = [];
        self.isLoading = true;
        self.isLoadingCompetitivePrices = false;
        self.order = "itemCode";
        self.pageNumbers = [];
        self.categoriesInFilter = [];
        self.filter = {
            mediaPageType: "",
            pageNumber: "",
            closingStatus: "",
            categoryName: "",
            hasIssue: ""
        };

        //fixed header
        $('tbody').scroll(function () {
            $('thead').css("left", -$("tbody").scrollLeft()); 
            $('thead th:nth-child(1)').css("left", $("tbody").scrollLeft()); 
            $('tbody td:nth-child(1)').css("left", $("tbody").scrollLeft()); 
        });
         
        //download

        self.downloadExcel = function () {
            //traigo chats
            self.downloading = true;
            let promotionItemIds = [];
            _.forOwn(_.groupBy(self.items, "promotionItemId"), function (value, key) {
                promotionItemIds.push(key);
            });

            promotionsService.items.loadItemsChats(promotionItemIds, true)
                .then(function (chats) {
                    var html = '<style>.text{mso-number-format:"\@";/*force text*/}</style><table width="100%"><thead><tr>';

                    html += '<th>Pagina</th>';
                    html += '<th>Tipo</th>';
                    html += '<th>Articulo</th>';
                    html += '<th>Descripcion Promo</th>';
                    html += '<th>Categoria</th>';
                    html += '<th>Precio Normal</th>';
                    html += '<th>Precio Minimo Alcance</th>';
                    html += '<th>Precio Vigente</th>';
                    html += '<th>Tipo de PV</th>';
                    html += '<th>Motivo</th>';
                    html += '<th>Desde</th>';
                    html += '<th>Hasta</th>';
                    html += '<th>Precio Promocional</th>';
                    html += '<th>Precio Promo Anterior</th>';
                    html += '<th>Mercado Promedio</th>';
                    html += '<th>Info. Mercado</th>';
                    html += '<th>IC Interno</th>';
                    html += '<th>IC Externo</th>';
                    html += '<th>IC Interno Actual</th>';
                    html += '<th>IC Externo Actual</th>';
                    html += '<th>Precio Sugerido</th>';
                    html += '<th>Issue</th>';
                    html += '<th>Aprobacion</th>';
                    html += '<th>Nuevo Precio Promocional</th>';
                    html += '<th>Var %</th>';
                    html += '<th>Margen Promocion %</th>';
                    html += '<th>Margen Promocion % 2</th>';
                    html += '<th>Pronostico de Venta $</th>';
                    html += '<th>Precio Bonus</th>';
                    html += '<th>Puntos Bonus</th>';
                    html += '<th>Comentarios</th>';
                    html += '</tr></thead><tbody>';

                    angular.forEach(self.items, function (item) {
                        let currentChats = chats.filter(function (c) { return c.promotionItemId == item.promotionItemId; });
                        html += '<tr><td>' + item.pageNumber +
                            '</td><td>' + self.getPageName(item.mediaPageType) +
                            '</td><td>' + item.itemCode +
                            '</td><td>' + item.itemName +
                            '</td><td>' + item.categoryName +
                            '</td><td>' + item.normalPrice +
                            '</td><td>' + item.minPrice +
                            '</td><td>' + item.actualPrice +
                            '</td><td>' + item.actualPriceType +
                            '</td><td>' + item.actualPriceReason +
                            '</td><td>' + item.actualPriceValidFrom +
                            '</td><td>' + item.actualPriceValidTo +
                            '</td><td>' + item.promotionalPrice +
                            '</td><td>' + item.prevPromotionalPrice + ' ' + item.prevPromotionalAction + ' ' + item.promotionalActionValidity +
                            '</td><td>' + item.marketPrice +
                            '</td><td>' + getMarketInfo(item.priceSurveys) +
                            '</td><td>' + item.internalIc +
                            '</td><td>' + item.externalIc +
                            '</td><td>' + item.actualInternalIc +
                            '</td><td>' + item.actualExternalIc +
                            '</td><td>' + item.suggestedPrice + '</td>';
                        item.hasIssue ? html += '<td>X</td>' : html += '<td></td>';
                        html += '<td>' + self.getStatusName(item.closingStatus) +
                            '</td><td>' + item.newPromotionalPrice +
                            '</td><td>' + item.varP +
                            '</td><td>' + item.promotionMargin +
                            '</td><td>' + item.promotionMargin2 +
                            '</td><td>' + item.forecast +
                            '</td><td>' + item.bonusPrice +
                            '</td><td>' + item.bonusPoints +
                            '</td><td>' + currentChats.map(function (c) { return ian.format("{0}, {1}: {2}", c.username, c.sendDate, c.message); }).join('|') +
                            '</td></tr>';

                    });

                    html += '</tbody></table>';

                    var exportHref = ExportToExcel(html, 'Pagina1');
                    $timeout(function () {
                        location.href = exportHref;
                    }, 100); // trigger download

                    self.downloading = false;
                }, function () { self.downloading = false; });


        }

        function ExportToExcel(tableHtml, worksheetName) {
            var uri = 'data:application/vnd.ms-excel;base64,',
                template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
                base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
                format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

            var ctx = { worksheet: worksheetName, table: tableHtml },
                href = uri + base64(format(template, ctx));
            return href;
        }

        function getMarketInfo(priceSurveys) {
            let rv = '';
            angular.forEach(priceSurveys, function (ps) {
                rv += ps.observedDate + '-' +
                    ps.competitor + '-' +
                    ps.price + '-';
                ps.isPromotion ? rv += 'Oferta | ' : rv += ' | ';
            });
            return rv;
        }

        //modals

        self.openInfo = function () {
            ngDialog.open({
                template: 'infoDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            });
        }

        self.openChat = function (promotionItemId, pageId) {
            var newScope = $scope.$new();
            newScope.isLoadingChats = true;
            promotionsService.items.loadItemChats(promotionItemId, true)
                .then(function (chats) {
                    newScope.chats = chats;
                    newScope.isLoadingChats = false;
                });

            newScope.send = function () {
                newScope.sending = true;
                if (self.newMessage != '') {
                    var newChat = {
                        id: 0,
                        promotionItemId: promotionItemId,
                        promotionMediaPageId: pageId,
                        message: self.newMessage,
                        inPriceTask: true
                    };
                    promotionsService.items.saveChat(newChat)
                        .then(function (isOk) {
                            self.newMessage = '';
                            promotionsService.items.loadItemChats(promotionItemId, true)
                                .then(function (chats) {
                                    newScope.chats = chats;
                                    newScope.sending = false;
                                });
                        }, function (status) {
                            if (status == 502) {
                                swal("El proceso continua...", "El proceso de envio se encuentra demorado..")
                            }
                            else {
                                newScope.sending = false;
                                swal("Error de envio", "", "error");
                            }
                        });
                }


            }

            ngDialog.open({
                template: 'chatDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: newScope
            });
        }

        self.openSurveysDialog = function (priceSurveys) {
            if (priceSurveys && priceSurveys.length > 0) {
                var newScope = $scope.$new();
                newScope.surveys = priceSurveys;

                ngDialog.open({
                    template: 'surveysDialog',
                    className: 'ngdialog-theme-default ngdialog-theme-custom',
                    scope: newScope
                });
            }

        }
        self.openSuggestionDetailsDialog = function (sugInt, sugExt) {
            if (sugInt || sugExt) {
                var newScope = $scope.$new();
                newScope.sugInternal = sugInt;
                newScope.sugExternal = sugExt;
                ngDialog.open({
                    template: 'suggestionDetailsDialog',
                    className: 'ngdialog-theme-default ngdialog-theme-custom',
                    scope: newScope
                });
            }

        }

        //filters
        self.filterStatus = function (status) {
            self.filter.closingStatus = status;
        }

        self.filterPageType = function (type) {
            self.filter.mediaPageType = type;
        }

        self.filterPageNumber = function (num) {
            self.filter.pageNumber = num;
        }

        self.filterByCategory = function (cat) {
            self.filter.categoryName = cat;
        }

        self.filterIssue = function (hasIssue) {
            self.filter.hasIssue = hasIssue;
        }

        //functions
        self.getStatusName = function (status) {
            if (status == 0)
                return 'Sin Modificar';
            if (status == 1)
                return 'Aprobado';
            if (status == 2)
                return 'Rechazado'
            if (status == 3)
                return 'Modificado'
            return '';
        }

        self.changeAllStatus = function (status) {
            angular.forEach($filter('filter')(self.items, self.filter), function (item) {
                item.closingStatus = status;
            });
        }

        self.changePage = function () {
            if (self.selectedPage.id == -1)
                self.selectedPage = null;
        }

        //campos calculados 
        var previous = [];

        var updatePrevious = function (newPrevious) {
            angular.copy(newPrevious, previous);
        };


        $scope.$watch('ct.items', function (newVal, oldVal) {
            if (newVal !== oldVal) {
                for (var i = 0; i < newVal.length; i++) {

                    if (angular.equals(newVal[i], previous[i])) continue;

                    var index = i;
                }

                calculateFields(index, true);


            }
        }, true);

        function calculateFields(index, update) {
            if (self.items[index] && (self.items[index].newPromotionalPrice || self.items[index].bonusPrice)) {
                var bonusOrPromotionalPrice = self.items[index].bonusPrice ? self.items[index].bonusPrice : self.items[index].newPromotionalPrice;
                self.items[index].forecast = self.items[index].forecastU * bonusOrPromotionalPrice;
                self.items[index].varP = self.items[index].actualPrice ? (bonusOrPromotionalPrice / self.items[index].actualPrice) - 1 : null;
                self.items[index].promotionMargin = self.items[index].internalCost ? (bonusOrPromotionalPrice / (1.18) - self.items[index].internalCost) / (bonusOrPromotionalPrice / (1.18)) : null;
                self.items[index].promotionMargin2 = self.items[index].newCost ? (self.items[index].forecast - (self.items[index].forecastU * (self.items[index].newCost * (1.18)))) / self.items[index].forecast : null;
                self.items[index].hasIssue = getIssueStatus(self.items[index].suggestedPrice, bonusOrPromotionalPrice);
                if (self.items[index].normalPrice) {
                    self.items[index].actualInternalIc = (bonusOrPromotionalPrice - self.items[index].normalPrice) / self.items[index].normalPrice;
                    self.items[index].internalIc = self.items[index].suggestedPrice? (self.items[index].suggestedPrice - self.items[index].normalPrice) / self.items[index].normalPrice : null;
                }
                if (self.items[index].marketPrice) {
                    self.items[index].actualExternalIc = (bonusOrPromotionalPrice - self.items[index].marketPrice) / self.items[index].marketPrice;
                    self.items[index].externalIc = self.items[index].suggestedPrice ? (self.items[index].suggestedPrice - self.items[index].marketPrice) / self.items[index].marketPrice : null;
                }
            }
            if (update)
                updatePrevious(self.items);
        }

        function getIssueStatus(suggestedPrice, bonusOrPromotionalPrice) {
            if (suggestedPrice) {
                let delta = ((bonusOrPromotionalPrice / suggestedPrice) - 1);
                if (delta > 0.01 || delta < -0.1) return true;
            }
            return false;
        }

        self.isBonusCoexistence = function (item) {
            return !((item.bonusPrice && !item.bonusPoints) || (!item.bonusPrice && item.bonusPoints));
        }

        self.validatePrices = function (item) {
            if (!item.newPromotionalPrice && !item.bonusPrice)
                return false;
            if (item.newPromotionalPrice && item.bonusPrice && item.bonusPrice > item.newPromotionalPrice)
                return false;
            return true;
        }

        self.getPageName = function (param) {
            switch (param) {
                case 1: return 'Carátula';
                case 2: return 'Contracarátula';
                case 3: return 'Página';
            }
        }

        self.getTitleForPrices = function (item) {
            if (!item.newPromotionalPrice && !item.bonusPrice)
                return 'Debe cargar precio promocional o bonus';
            if (item.newPromotionalPrice && item.bonusPrice && item.bonusPrice > item.newPromotionalPrice)
                return 'El precio bonus no puede superar al promocional';
        }

        self.onChangeStatus = function (status, item) {
            if (item.newPromotionalPrice != item.promotionalPrice && status == 1)
                status = 3;//modificado
            item.closingStatus = status;
        }

        self.onChangePromotionalPrice = function (i) {
            if (i.newPromotionalPrice != i.promotionalPrice && i.closingStatus != 2)
                i.closingStatus = 3;
            else if (i.newPromotionalPrice == i.promotionalPrice && i.closingStatus != 2)
                i.closingStatus = 1;
        }

        self.save = function () {
            self.isLoading = true;
            if (self.canSave()) {
                promotionsService.promotions.savePricingClose(self.items)
                    .then(function (items) {
                        self.isLoading = false;
                        swal('Exito', 'Cambios guardados', 'success');
                        //$state.go('promotions.myTasks');
                    }, function (status) {
                        if (status == 502) {
                            swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                        }
                        else {
                            self.isLoading = false;
                            swal("Error", "Hubo un error al guardar", "error");
                        }
                    });
            } else {
                self.isLoading = false;
                swal("No se puede guardar", "Por favor, revise las alertas", "error");
            }
        }

        self.canSave = function () {
            var canSave = true;
            for (var i = 0; i < self.items.length; i++) {

                if (!self.isBonusCoexistence(self.items[i])) {
                    canSave = false;
                    break;
                }
                if (!self.validatePrices(self.items[i])) {
                    canSave = false;
                    break;
                }
            }

            return canSave;
        }

        self.publish = function () {
            self.isLoading = true;
            if (self.canSave()) {
                promotionsService.tasks.publishTask($stateParams.taskId)
                    .then(function (task) {
                        self.isLoading = false;
                        swal('Tarea Publicada!', 'La tarea se ha publicado exitosamente', 'success');
                        $state.go('promotions.myTasks');
                    });
            }
            else {
                self.isLoading = false;
                swal("No se puede publicar", "Por favor, revise las alertas", "error");
            }
        }

        self.changeCategory = function () {
            if (self.selectedCategory.categoryId === -1)
                self.selectedCategory = null;
        }

        self.getPrices = function () {
            self.isLoading = true;
            let categoryId = self.selectedCategory ? self.selectedCategory.categoryId : 0;
            let pageId = self.selectedPage ? self.selectedPage.id : 0;
            promotionsService.promotions.getPromotionForPricingClose($stateParams.promotionId, categoryId, pageId)
                .then(function (response) {
                    response.forEach(function (item) { item.newPromotionalPrice = item.promotionalPrice; });
                    self.items = response;
                    updatePrevious(self.items);
                    _.forOwn(_.groupBy(response, "pageNumber"), function (value, key) {
                        self.pageNumbers.push(key);
                    });
                    _.forOwn(_.groupBy(response, "categoryName"), function (value, key) {
                        self.categoriesInFilter.push(key);
                    });
                    self.isLoading = false;
                    self.isLoadingCompetitivePrices = true;
                    var promotionItemsIds = [];
                    var promotionMechanicItemsIds = [];
                    var promotionItems = self.items.filter(function (x) { return x.mechanicItemId == null });
                    var promotionMechanicItems = self.items.filter(function (x) { return x.mechanicItemId != null });
                    _.forOwn(_.groupBy(promotionItems, "promotionItemId"), function (value, key) {
                        promotionItemsIds.push(key);
                    });
                    _.forOwn(_.groupBy(promotionMechanicItems, "mechanicItemId"), function (value, key) {
                        promotionMechanicItemsIds.push(key);
                    });
                    var promotionItemsParamDto = {
                        promotionId: $stateParams.promotionId,
                        categoryId: categoryId,
                        promotionItemsIds: promotionItemsIds,
                        promotionMechanicItemsIds: promotionMechanicItemsIds
                    }
                    promotionsService.items.getCompetitivePrices(promotionItemsParamDto)
                        .then(function (response) {
                            angular.forEach(self.items, function (i) {
                                angular.forEach(response, function (x) {
                                    if ((x.promotionItemId && i.promotionItemId == x.promotionItemId) || (x.promotionMechanicItemId && i.mechanicItemId == x.promotionMechanicItemId)) {
                                        i.suggestedForInternal = x.suggestedPriceForInternal;
                                        i.suggestedForExternal = x.suggestedPriceForExternal;
                                        if (i.suggestedForInternal && i.suggestedForExternal)
                                            i.suggestedPrice = Math.min(i.suggestedForInternal, i.suggestedForExternal);
                                        else i.suggestedPrice = i.suggestedForInternal ? i.suggestedForInternal : i.suggestedForExternal;

                                        if (x.priceSurveys) {
                                            let priceSurveys = x.priceSurveys.map(function (ps) { return ps.price; });
                                            i.marketPrice = $rootScope.arrayAverage(priceSurveys);
                                            i.priceSurveys = x.priceSurveys;
                                        }
                                    }
                                });
                            });
                            angular.forEach(self.items, function (i, index) { calculateFields(index, false) });
                            updatePrevious(self.items);
                            self.isLoadingCompetitivePrices = false;
                        });
                });
        }

        function init() {
            promotionsService.promotions.getPromotionDataForPricingClose($stateParams.promotionId)
                .then(function (response) {
                    response.promotionValidFrom = new Date(response.promotionValidFrom).toISOString().slice(0, 10);
                    response.promotionValidTo = new Date(response.promotionValidTo).toISOString().slice(0, 10);
                    self.promotionData = response;
                    self.categories = self.promotionData.categories;
                    self.categories.push({ categoryId: -1, categoryName: " - " });
                    self.promotionData.pages.push({ id: -1 });
                    self.isLoading = false;
                });
            promotionsService.tasks.startTask($stateParams.taskId);


        }

        init();

    });