(function () {
    'use strict';
     
    angular
      .module('prisma')
      .config(config);

    function config($httpProvider) {
        $httpProvider.defaults.timeout = 0;
        $httpProvider.interceptors.push('authInterceptorService');

    }

})();
