angular.module("prisma")
 .controller("StoresCtrl", function ($scope, $state, $translate, adminService, $timeout) {

     //Variables Globales

     var self = this;

     //TRADUCCIONES JS
     var enableDisableSwalTitle = "";
     var updatedStores = "";
     var errorWhenSavingTheStore = "";
    

     translate = function () {

         //Llamar por cada traduccion
         $translate("EnableDisableSwalTitle")
            .then(function (translation) {
                enableDisableSwalTitle = translation;
                return translation;
            });
         $translate("UpdatedStores")
            .then(function (translation) {
                updatedStores = translation;
                return translation;
            });
         $translate("ErrorWhenSavingTheStore")
            .then(function (translation) {
                errorWhenSavingTheStore = translation;
                return translation;
            });

     }
     translate();




     self.stores = [];

     self.newStore = { id: null, name: '', code: '', isExcluded: false };

     self.search = "";

     self.filter = "";

     //Funciones

     self.addNew = function () {
         $state.go('admin.editStores', { storeId: 0 });
         //self.newStore.id = 0;
     }

     self.editStore = function (store) {
         $state.go('admin.editStores', { storeId: store.id });
         //self.newStore = store;
     }

     self.disableStore = function (id, excluded, storeCode) {

         var excludedText = excluded ? 'Habilitar' : 'Deshabilitar';

         swal({
             title: "Esta seguro que desea " + excludedText + " " + enableDisableSwalTitle +  "?",
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: "Continuar",
             cancelButtonText: "Cancelar",
             showLoaderOnConfirm: true,
             closeOnConfirm: false,
             closeOnCancel: true
         },
       function (isConfirm) {

           if (isConfirm) {
               adminService.stores.doesStoreCodeExists(storeCode, id).then(function (exists) {
                   if (exists == true)
                       swal("Error", "Ya existe una tienda con el mismo codigo, primero debe deshabilitarla antes de habilitar esta otra", "error");
                   else {
                       adminService.stores.deleteStore(id).then(function () {
                           swal("", updatedStores, "success");
                           loadStores();
                       }, function (status) {
                           if (status == 502) {
                               swal("El proceso continua...");
                           }
                           else {
                               swal("Error", errorWhenSavingTheStore, "error");
                           }
                       });
                   }
               });
           }
       });
     }

     self.toggleNewStore = function () {
         self.newStore.isExcluded = !self.newStore.isExcluded;

         if (self.newStore.isExcludedLiteral == "Activa") {
             self.newStore.isExcludedLiteral = "Deshabilitada";
         }
         else {
             self.newStore.isExcludedLiteral = "Activa";
         }

     }

     self.cancel = function () {
         self.newStore = { id: null, name: '', code: '', isExcluded: false };
         loadStores();
     }

     self.filterStatus = function (status) {
         if (status == "Activa") {
             self.filter = "Activa";
         }
         else if (status == "Deshabilitada") {
             self.filter = "Deshabilitada";
         }
         else
             self.filter = "";
     }


     //Load Data
     function loadStores() {
         self.isLoading = true;
         adminService.stores.getStores(true)
             .then(function (stores) {
                 self.stores = stores;
                 self.isLoading = false;
             });
     }

     function init() {

         loadStores();

     }

     init();
 });




