angular.module("prisma")
    .controller("MarkdownRulesCreationCtrl", function ($rootScope, $state, $timeout, $scope, markdownService) {

      var self = this;
      var dic = {};
      var numberColWidth = 100;
      var editableColWidth = 140;
      var lastAdded = null;
      var columnDefs = null;

      self.newRule = {};

      self.GetNewRule = function () {
          return {
              selectedTypologies: [self.typologies[0], self.typologies[1], self.typologies[2], self.typologies[3], self.typologies[4]],
              selectedPriorizeBy: self.priorizeBys[0],
              selectedPlans: [],
              selectedPlanNotIncluded: {},
              minUnits: 1,
              validFrom: self.getDefaultDate()
          };
      }

      self.getDefaultDate = function() {
          var dat = new Date();
          dat.setDate(dat.getDate() + 1);
          return dat;
      }

      self.selectedBrand = null;

      self.writtenRule = null;

      self.getPlanTypes = function () {
          if ($rootScope.maxAgingLevel == 12 ) {
              if ($rootScope.erp.Implementation == 'CencosudChile') {
                  return [
                      { id: 10, name: 'Inactivos' },
                      { id: 8, name: 'Descatalogados (sin bloqueo)' },
                      { id: 9, name: 'Descatalogados (con bloqueo)' },
                      { id: 12, name: 'Aging Fase 1' },
                      { id: 13, name: 'Aging Fase 2' },
                      { id: 14, name: 'Aging Fase 3' },
                      { id: 15, name: 'Aging Fase 4' },
                      { id: 16, name: 'Aging Fase 5' },
                      { id: 17, name: 'Aging Fase 6' },
                      { id: 18, name: 'Aging Fase 7' },
                      { id: 25, name: 'Aging Fase 8' },
                      { id: 26, name: 'Aging Fase 9' },
                      { id: 27, name: 'Aging Fase 10' },
                      { id: 19, name: 'Sobrestock' },
                      { id: 20, name: 'Sobrestock critico' },
                      { id: 21, name: 'Devolucion' },
                      { id: 22, name: 'Carga Manual' },
                      { id: 24, name: 'Pronostico de salida de stock' },
                  ]
              }
              else {
                  return [
                      { id: 10, name: 'Inactivos' },
                      { id: 12, name: 'Aging Fase 1' },
                      { id: 13, name: 'Aging Fase 2' },
                      { id: 14, name: 'Aging Fase 3' },
                      { id: 15, name: 'Aging Fase 4' },
                      { id: 16, name: 'Aging Fase 5' },
                      { id: 17, name: 'Aging Fase 6' },
                      { id: 18, name: 'Aging Fase 7' },
                      { id: 25, name: 'Aging Fase 8' },
                      { id: 26, name: 'Aging Fase 9' },
                      { id: 27, name: 'Aging Fase 10' },
                      { id: 28, name: 'Aging Fase 11' },
                      { id: 29, name: 'Aging Fase 12' },
                      { id: 19, name: 'Sobrestock' },
                      { id: 20, name: 'Sobrestock critico' },
                      { id: 21, name: 'Devolucion' },
                      { id: 22, name: 'Carga Manual' },
                      { id: 24, name: 'Pronostico de salida de stock' },
                  ]
              }
          }
          else { //Para el resto es 7, sino agregar otro if.
              return [
                  { id: 10, name: 'Inactivos' },
                  { id: 12, name: 'Aging Fase 1' },
                  { id: 13, name: 'Aging Fase 2' },
                  { id: 14, name: 'Aging Fase 3' },
                  { id: 15, name: 'Aging Fase 4' },
                  { id: 16, name: 'Aging Fase 5' },
                  { id: 17, name: 'Aging Fase 6' },
                  { id: 18, name: 'Aging Fase 7' },
                  { id: 19, name: 'Sobrestock' },
                  { id: 20, name: 'Sobrestock critico' },
                  { id: 21, name: 'Devolucion' },
                  { id: 22, name: 'Carga Manual' },
                  { id: 24, name: 'Pronostico de salida de stock' },
              ]
          }
      }

        self.planTypes = self.getPlanTypes();
        self.planTypesNotIncluded = self.getPlanTypes();


      self.typologies = [
          { id: 1, name: 'Tipologia 1' },
          { id: 2, name: 'Tipologia 2' },
          { id: 3, name: 'Tipologia 3' },
          { id: 4, name: 'Tipologia 4' },
          { id: 5, name: 'Tipologia 5' },
      ]

      self.priorizeBys = [
          { id: 1, name: 'Stock $' },
          { id: 2, name: 'Stock U' },
          { id: 3, name: 'DDS' },
      ]

      self.gridOptions = null;

      self.back = function () {
          $state.go('markdowns.rulesSelection')
      }

      function validate() {
          var error = '';

          if (!self.newRule.validFrom)
              error += 'La fecha ingresada no es valida\n';

          if (!self.newRule.selectedPriorizeBy)
              error += 'Seleccione un item para priorizar\n';

          if (self.newRule.selectedTypologies.length == 0 && self.newRule.selectedPlans.length == 0)
              error += 'Debe seleccionar al menos un plan o tipologia.\n';


          return error;
      }

      $scope.$watch('ct.newRule', function (newValue, oldValue) {
          console.log('newValue', newValue);

          if (newValue.selectedTypologies.length > 0 || newValue.selectedPlans.length > 0) {

              var plans = '';
              for (var i = 0; i < newValue.selectedPlans.length; i++) {
                  if (i == newValue.selectedPlans.length - 1) {
                      plans += newValue.selectedPlans[i].name
                  } else {
                      plans += newValue.selectedPlans[i].name + ', ';
                  }
              }
              var typologies = '';
              for (var i = 0; i < newValue.selectedTypologies.length; i++) {
                  if (i == newValue.selectedTypologies.length - 1) {
                      typologies += newValue.selectedTypologies[i].name
                  } else {
                      typologies += newValue.selectedTypologies[i].name + ', ';
                  }
              }

              self.writtenRule = "REGLA DE SELECCI&Oacute;N: \n";
              self.writtenRule += "Cuando un producto es ";

              if (plans)
                  self.writtenRule += plans + ' ';

              if (newValue.selectedPlanNotIncluded != null && newValue.selectedPlanNotIncluded.name) {
                  var planNotIncluded = newValue.selectedPlanNotIncluded.name
                  self.writtenRule += '( y no es ' + planNotIncluded + ') ';
              }

              if (plans && typologies)
                  self.writtenRule += 'y ';

              if (typologies)
                  self.writtenRule += typologies + ' ';


              self.writtenRule += "ingresa al plan seg&uacute;n su stock (";

              if (newValue.useDistributionCenterStock) {
                  self.writtenRule += "con CD";
              } else {
                  self.writtenRule += "sin CD";
              }

              if (newValue.minUnits < 1 || !newValue.minUnits)
                  newValue.minUnits = 1;

              self.writtenRule += ') con sus unidades m&iacute;nimas (' + newValue.minUnits + ') ';

              if (newValue.selectedPriorizeBy)
                  self.writtenRule += 'priorizado por su ' + newValue.selectedPriorizeBy.name;


          } else {
              self.writtenRule = null;
          }
      }, true);

      self.save = function () {

          var errors = validate();
          if (errors != '') {
              console.log('errors', errors);
              swal("Errores de validacion", errors, "warning");
              return;
          }

          swal({
              title: "Esta seguro?",
              text: 'Esta seguro que desea guardar los cambios?',
              type: "info",
              showCancelButton: true,
              confirmButtonColor: "#1AB394",
              confirmButtonText: "Continuar",
              cancelButtonText: "Cancelar",
              showLoaderOnConfirm: true,
              closeOnConfirm: false,
              closeOnCancel: true
          },
              function () {

                  var paretoSectionLevels = [];
                  for (var i = 0; i < self.newRule.selectedTypologies.length; i++) {
                      paretoSectionLevels.push(self.newRule.selectedTypologies[i].id)
                  }

                  var data = {
                      name: self.writtenRule,
                      priorizeBy: self.newRule.selectedPriorizeBy.id,
                      planTypes: self.newRule.selectedPlans,
                      planTypeNotIncluded: (self.newRule.selectedPlanNotIncluded != null && self.newRule.selectedPlanNotIncluded.id ? self.newRule.selectedPlanNotIncluded.id : null),
                      paretoSectionLevels: paretoSectionLevels,
                      useDistributionCenterStock: self.newRule.useDistributionCenterStock,
                      minUnits: self.newRule.minUnits,
                      validFrom: self.newRule.validFrom.toISOString()
                  }
                  

                  console.log('newRule', self.newRule);
                  console.log('data', data);

                  markdownService.rules.saveRule(data).then(function () {
                      swal("Confirmado", "Se guardo la regla de liquidacion", "success");
                      $state.go('markdowns.rulesSelection')

                  }).catch(function (fallback) {
                      swal("Error", "Hubo un error al querer guardar los cambios", "error");
                  });
              });
      }



      function loadData() {
          self.newRule = self.GetNewRule();
      }

      function init() {

          loadData();
      }

      init();



  });

