angular.module("prisma")
    .controller("promotionalWorkflowsCtrl", function ($scope, $timeout, promotionsService, ngDialog, $filter) {
        var self = this;

        self.workflows = [];

        self.isLoading = true;

        self.promotionMediaTypes = promotionsService.promotionMediaTypes;

        self.delete = function (workflowId, workflowName) {

            swal({
                title: 'Esta seguro que desea borrar el proceso ' + workflowName + ' ?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        
                        promotionsService.workflows.deleteWorkflow(workflowId)
                            .then(function (isOk) {
                                swal('Proceso Borrado!', 'El proceso ' + workflowName + ' se ha borrado exitosamente', 'success');
                                loadData();
                            }, function (status) {
                                if (status == 502) {
                                    swal("El proceso continua...", "El proceso de borrado continua. Puede revisar el estado en unos minutos.")
                                }
                                else {
                                    swal("Error", "Hubo un error al borrar este proceso", "error");
                                }
                            });
                    }
                });
        }

        self.getMediaType = function (mediaTypeId) {
            var lavel = "";
            angular.forEach(self.promotionMediaTypes, function (mt) {
                if (mt.id == mediaTypeId)
                    rv = mt.label;

            });
            return rv;
        }

        self.openCloningDialog = function (workflowToCloneId) {
            var newScope = $scope.$new();
            newScope.newWorkflow = { Id: 0, companyId: 0, name: '', description: '', promotionMediaType: 0, tasks: [] };
            newScope.promotionMediaTypes = self.promotionMediaTypes;
            newScope.promotionTypeChanged = function () {
                if (newScope.newWorkflow.promotionType == 'Otras') {
                    newScope.promotionMediaTypes = self.promotionMediaTypes;
                    newScope.workflow.promotionMediaType = 10;
                }
                else { //Externa, Saco la variable Interna y externa del tipo de medio.
                    newScope.promotionMediaTypes.filter(function (mt) { return mt.id != 6 && mt.id != 10; });
                    self.workflow.promotionMediaType = null;
                }
            }
            newScope.clone = function () {
                newScope.cloning = true;
                promotionsService.workflows.cloneWorkflow(newScope.newWorkflow, workflowToCloneId)
                    .then(function (wf) {
                        newScope.cloning = false;
                        self.workflows.push(wf);
                        swal('Proceso Clonado!', 'El proceso ' + wf.name + ' se ha clonado exitosamente', 'success');
                    }, function (status) {
                        if (status == 502) {
                            swal("El proceso continua...", "El proceso de clonado continua. Puede revisar el estado en unos minutos.")
                        }
                        else {
                            newScope.cloning = false;
                            swal("Error", "Hubo un error al clonar este proceso", "error");
                        }
                    });
            }

            ngDialog.open({
                template: 'cloningDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: newScope
            });
        }

        function loadData() {
            self.promotionMediaTypes = promotionsService.promotionMediaTypes;
            promotionsService.workflows.getWorkflows(false)
                .then(function (workflows) {
                    self.workflows = workflows;

                    self.isLoading = false;
                });
        }

        function init() {
            loadData();
        }

        init();
    });