'use strinct';
(function () {
    angular.module('prisma').component('planogramPrintHeader', {
        templateUrl: '/app/spaces/planogram/print/planogram-print-header.view.html',
        bindings: {
            model: '<'
        },
        controller: 'planogramPrintHeaderController',
        controllerAs: '$ctrl',
        bindToController: true
    }).controller('planogramPrintHeaderController', ['$log', planogramPrintHeaderController]);

    // ReSharper disable once InconsistentNaming
    function planogramPrintHeaderController($log) {
        //$log.debug('planogramPrintHeaderController::ctor');
        var self = this;
        // self.model = null;

        /**
         * Devuelve verdadero si el modelo es un objeto, falso en caso contrario.
         * @public
         * @returns Verdadero si el modelo es un objeto, falso en caso contrario.
         */
        self.hasModel = function hasModel() {
            var ret = angular.isObject(self.model);
            return ret;
        }
    }
})();