
angular.module("prisma")
    .controller("PriceCompetitivePositionReportCtrl", function ($state, $rootScope, $timeout, priceService, adminService) {
        var self = this;
        const pointsGraphValue = 10;//el eje 'y' de los puntos del grafico va desde 0% a 70%, siendo cada $1 = 10%. El porcentage correspondiente a $0 es 35%
        self.weeklyData = [];
        self.selectedPriceLists = [];

        self.colors = ['red', 'green', 'orange', 'blue', 'black'];

        self.filters = {
            selectedPriceLists: [],
            selectedBrands: [],
            selectedItemId: null
        };

        self.showLogos = true;
        self.selectedPriceListChart = null;

        self.validTo = new Date();
        self.validFrom = new Date();
        self.validFrom.setDate(self.validFrom.getDate() - 7);

        self.chartData = [];

        //calcula la posicion en el eje 'y' del grafico en porcentaje
        self.getPercentageDeltaForCompetitor = function (competitorPriceLists, priceListId) {
            let priceList = competitorPriceLists.find(function (x) { return x.priceListId == priceListId; });
            if (priceList)
                return convertDeltaToPercentageInChart(priceList.priceDelta) + '%';

        }

        self.priceListIsInData = function (competitorPriceLists, priceListId) {
            return competitorPriceLists.map(function (x) { return x.priceListId; }).includes(priceListId);
        }

        self.getData = function (priceList) {
            return self.chartData.filter(function (item) {
                return self.priceListIsInData(item.competitivePriceListPrices, priceList.id);
            });
        }

        self.toggleInfo = function (e, priceList, competitor) {
            let m = competitor.competitivePriceListPrices.find(function (x) { return x.priceListId == priceList.id; });
            if (m) {
                m.showInfo = !m.showInfo;
                if (m.showInfo) {
                    self.activePriceList = priceList;
                    self.activeCompetitor = competitor;
                    self.activeCompetitorPriceList = m;
                }
                else {
                    self.activePriceList = null;
                    self.activeCompetitor = null;
                    self.activeCompetitorPriceList = null;

                }
            }
            else {
                self.activePriceList = null;
                self.activeCompetitor = null;
                self.activeCompetitorPriceList = null;

            }
        }

        self.isShowInfo = function () {
            return angular.isObject(self.activeCompetitorPriceList) && self.activeCompetitorPriceList.showInfo;
        }

        self.getCompetitorPrice = function (type) {
            var rv = '';
            switch (type) {
                case 'price': rv = '$' + self.activeCompetitorPriceList.priceAvg.toFixed(2);
                    break;
                case 'delta': rv = '$' + self.activeCompetitorPriceList.priceDelta.toFixed(2);
                    break;
                case 'deltaP': var perc = self.activeCompetitorPriceList.priceDeltaPercentage * 100;
                    rv = perc.toFixed(2) + '%';
                    break;
            }
            return rv;

        }

        self.getShowInfoX = function () {
            var rv = self.getXaxisForCompetitor(self.activePriceList.x, self.activeCompetitor.x);
            return rv;
        }
        self.getShowInfoY = function () {
            var rv = self.getPercentageDeltaForCompetitor(self.activeCompetitor.competitivePriceListPrices, self.activePriceList.id)
            return rv;
        }

        self.getShowInfoTextX = function () {
            return self.showLogos ? 25 : 0; 
        }

        function convertDeltaToPercentageInChart(delta) {

            let chartPercentageDelta = delta * pointsGraphValue;//diferencia con el eje 'y' en $0 (este corresponde al precio de la compania)
            let chartPercentage = 35 - chartPercentageDelta;//resto y no sumo para cambiar el signo...en el grafico, la ubicacion de los puntos en por porcentaje es 0% top y 70% bottom
            return chartPercentage;
        }

        self.getXaxisForCompetitor = function (priceListXaxis, competitorXaxis) {
            return competitorXaxis + (priceListXaxis - 60);//60 es la mitad de lo que ocupan los 5 competidores en el grafico, asi queda centrado
        }

        self.applyFilters = function () {
            if (self.filters.selectedPriceLists.length === 0 || self.filters.selectedBrands.length === 0 || !self.filters.selectedItemId) {
                swal('Complete todos los campos', '', 'error');
                return;
            }
            self.isLoading = true;          

            var selectedPriceListsIds = self.filters.selectedPriceLists.map(function (x) { return x.id; });
            var selectedBrandsIds = self.filters.selectedBrands.map(function (x) { return x.id; });
           
            var filters = {
                selectedPriceListsIds: selectedPriceListsIds,
                selectedBrandsIds: selectedBrandsIds,
                selectedItemId: self.filters.selectedItemId
            };

            priceService.reports.getCompetitivePositionReport(filters).then(function (data) {
                self.selectedPriceLists = self.filters.selectedPriceLists;
                self.chartData = data;
                              

                //seteo posiciones 'x', showInfo y colores
                let xAxis = 0;
                angular.forEach(self.chartData, function (x, index) {
                    x.color = self.colors[index];

                    angular.forEach(x.competitivePriceListPrices, function (y) {
                        y.showInfo = false;
                    });

                    x.x = xAxis;
                    xAxis += 30;
                });
                self.isLoading = false;
                console.log(data);


            });
        }

        self.viewPriceListInfo = function (priceList) {
            self.isLoading = true;

            var selectedBrandsIds = self.filters.selectedBrands.map(function (x) { return x.id; });
            var selectedBrandsNames = self.filters.selectedBrands.map(function (x) { return x.name; });
            var filters = {
                selectedPriceListsIds: [priceList.id],
                selectedBrandsIds: selectedBrandsIds,
                selectedItemId: self.filters.selectedItemId
            };
            priceService.reports.getCompetitivePositionByPriceList(filters)
                .then(function (data) {

                    self.selectedPriceListChart = priceList;

                    var distinctPrices = [];
                    _.forOwn(_.groupBy(data, "price"), function (value, key) {
                        distinctPrices.push(key);
                    });

                    var distincBrands = [];
                    _.forOwn(_.groupBy(data, "brandName"), function (value, key) {
                        distincBrands.push(key);
                    });

                    var axes = ['x'];
                    var brandsArrays = [];

                    selectedBrandsNames.forEach(function (name) {
                        brandsArrays.push([name]);
                    });

                    distinctPrices.forEach(function (price) {
                        axes.push(price);
                        self.filters.selectedBrands.forEach(function (brand) {
                            var brandPrice = data.find(function (x) {
                                return x.brandId === brand.id && x.price == price;
                            });
                            var brandArray = brandsArrays.find(function (brandArray) {
                                return brandArray[0] == brand.name;
                            });

                            if (brandPrice) brandArray.push(brandPrice.storesCount);                              
                            else brandArray.push(0);                           

                        });
                    });

                    brandsArrays.splice(0, 0, axes);

                    //var brandsArrays = [
                    //    ['x', 19.00, 19.10, 19.20, 19.30, 19.40, 19.50, 19.60, 19.70, 19.80, 19.90, 20.00, 20.00, 20.10, 20.20, 20.30, 20.40, 20.50, 20.60, 20.70, 20.80, 20.90, 21.00, 21.10, 21.20, 21.30, 21.40, 21.50, 21.60, 21.70, 21.80, 21.90, 22.00, 22.10, 22.20, 22.30, 22.40, 22.50, 22.60, 22.70, 22.80, 22.90, 23.00],
                    //    ['OxxoGas', 0, 0, 0, 0, 0, 29, 0, 0, 0, 6, 0, 1, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    //    ['PEMEX', 1, 2, 2, 2, 3, 3, 1, 6, 6, 0, 0, 0, 4, 1, 1, 1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1],
                    //    ['SHELL', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 1, 0, 0, 0, 0, 6, 0, 0, 0, 0, 6, 0, 12, 0, 0, 0, 0, 0, 0, 0, 2, 0, 2, 0, 0, 0, 1]
                    //];

                    var chart = c3.generate({
                        bindto: '#chart',
                        data: {
                            x: 'x',
                            columns: brandsArrays,
                            colors: {
                                'OxxoGas': '#EA2129',
                                'Shell': '#FFD504',
                                'ExxonMobil': '#247EC0',
                                'British Pretroleum': '#96CB01',
                                'Chevron': '#591DB1',
                            },
                            bar: {
                                width: {
                                    ratio: 0.01
                                }
                            },
                            type: 'bar',
                            groups: [
                                distincBrands//cada elemento es un brand
                            ]
                        },
                        grid: {
                            y: {
                                lines: [{ value: 0 }]
                            }
                        }
                    });

                    self.isLoading = false;
                });

        }

        self.onChangePriceListsFilter = function () {
            if (self.filters.selectedPriceLists.length > 8) {
                swal('Limite Excedido', 'Puede seleccionar un maximo de 8 zonas', 'error');
                $timeout(function () {
                    self.filters.selectedPriceLists.pop();
                }, 100);

            }
            //seteo la posicion x de cada priceList
            var xOffset = 200;
            angular.forEach(self.filters.selectedPriceLists, function (x) {
                x.x = xOffset;
                xOffset += 300;
            });

        }

        self.onChangeBrandsFilter = function () {
            if (self.filters.selectedBrands.length > 8) {
                swal('Limite Excedido', 'Puede seleccionar un maximo de 8 competidores', 'error');
                $timeout(function () {
                    self.filters.selectedBrands.pop();
                }, 100);
            }
        }

        self.loadData = function () {

            adminService.items.getItemsByCategory(0).then(function (items) {
                self.items = items;
            });
            adminService.priceLists.getPriceLists().then(function (priceLists) {
                self.priceLists = _.orderBy(priceLists, ['name'], ['asc']);
            });

            //self.competitors son los brands
            adminService.brands.getBrandsCombo(false).then(function (competitors) {
                self.competitors = _.orderBy(competitors, ['name'], ['asc']);              

            });
        }

        function init() {

            //load data
            self.loadData();
        }

        init();

    });

