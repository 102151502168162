"use strict";

angular.module("prisma")
    .controller("GoalsCtrl", function ($scope, homeService, $rootScope, $q, $stateParams) {
        var self = this;
        var numberColWidth = 130;

        self.sameStores = true;
        self.type = $stateParams.type;
        self.goals = [];
        ////self.categoryGroups = null;
        self.selectedCategoryGroup = null;
        self.columnDefs = [
            {
                headerName: "Informaci&oacute;n General", //"General Information", 
                children: [
                    {
                        headerName: "Categoria", //"Category / Pricing Zone / Store",
                        headerTooltip: "",
                        field: "hierarchyName",
                        width: numberColWidth + 50,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: {
                            renderer: 'group',
                            checkbox: false,
                            innerRenderer: function (params) {
                                //console.log(params);
                                if (params.data.children && params.data.children.length > 0)
                                    return '<a ng-click="ct.changeCategory(\'' + params.data.path + '/' + params.value + '\')">' + params.value + '</a>';

                                return params.value;
                            }
                        }
                        //template: '<a ng-bind="data.hierarchyName" ng-click="ct.changeCategory(data.hierarchyName)"></a>'
                    },
                ],
            },
            {
                headerName: "Ventas vs Plan", //"Sales vs Plan", 
                children: [
                    {
                        headerName: "Ventas YTD", //"Sales YTD",
                        field: "sales",
                        columnGroupShow: 'open',
                        filter: 'number',
                        width: numberColWidth,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.bigMoneyRenderer
                    },
                    {
                        headerName: "Plan YTD",
                        headerTooltip: "",
                        field: "salesGoal",
                        filter: 'number',
                        columnGroupShow: 'open',
                        width: numberColWidth,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.bigMoneyRenderer
                    },
                    {
                        headerName: "GAP",
                        headerTooltip: "",
                        sort: 'asc',
                        field: "salesGoalGap",
                        filter: 'number',
                        width: numberColWidth,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.bigMoneyRenderer,
                        cellStyle: function (params) { return params.value < 0 ? { color: 'red' } : { color: 'green' }; }
                    },
                    {
                        headerName: "GAP %",
                        headerTooltip: "",
                        field: "salesGoalGapP",
                        filter: 'number',
                        width: numberColWidth,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.percentageRendererNoDecimals,
                        cellStyle: function (params) { return params.value < 0 ? { color: 'red' } : { color: 'green' }; }
                    },
                ]
            },
            {
                headerName: "Margen $ vs Plan", //"Margin $ vs Plan", 
                children: [
                    {
                        headerName: "Margen $ YTD", //"Margin $ YTD", 
                        field: "margin",
                        columnGroupShow: 'open',
                        filter: 'number',
                        width: numberColWidth + 40,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.bigMoneyRenderer
                    },
                    {
                        headerName: "Plan YTD",
                        headerTooltip: "",
                        field: "marginGoal",
                        filter: 'number',
                        columnGroupShow: 'open',
                        width: numberColWidth,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.bigMoneyRenderer
                    },
                    {
                        headerName: "GAP",
                        headerTooltip: "",
                        field: "marginGoalGap",
                        filter: 'number',
                        width: numberColWidth,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.bigMoneyRenderer,
                        cellStyle: function (params) { return params.value < 0 ? { color: 'red' } : { color: 'green' }; }
                    },
                    {
                        headerName: "GAP %",
                        headerTooltip: "",
                        field: "marginGoalGapP",
                        filter: 'number',
                        width: numberColWidth,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.percentageRendererNoDecimals,
                        cellStyle: function (params) { return params.value < 0 ? { color: 'red' } : { color: 'green' }; }
                    },
                ]
            },
            {
                headerName: "Unidades vs Plan", //"Units vs Plan", 
                children: [
                    {
                        headerName: "Unidades YTD", //"Units YTD", 
                        field: "units",
                        filter: 'number',
                        columnGroupShow: 'open',
                        width: numberColWidth + 20,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.bigIntRenderer
                    },
                    {
                        headerName: "Plan YTD",
                        headerTooltip: "",
                        field: "unitsGoal",
                        filter: 'number',
                        columnGroupShow: 'open',
                        width: numberColWidth,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.bigIntRenderer
                    },
                    {
                        headerName: "GAP",
                        headerTooltip: "",
                        field: "unitsGoalGap",
                        filter: 'number',
                        width: numberColWidth,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.bigIntRenderer,
                        cellStyle: function (params) { return params.value < 0 ? { color: 'red' } : { color: 'green' }; }
                    },
                    {
                        headerName: "GAP %",
                        headerTooltip: "",
                        field: "unitsGoalGapP",
                        filter: 'number',
                        width: numberColWidth,
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.percentageRendererNoDecimals,
                        cellStyle: function (params) { return params.value < 0 ? { color: 'red' } : { color: 'green' }; }
                    },
                ]
            },
            //{
            //    headerName: "Costos vs Plan",
            //    children: [
            //          {
            //              headerName: "Costos YTD",
            //              headerTooltip: "",
            //              field: "cost",
            //              filter: 'number',
            //              width: numberColWidth,
            //              cellClass: $rootScope.getClassForCell,
            //              cellRenderer: $rootScope.bigMoneyRenderer
            //          },
            //          {
            //              headerName: "Plan YTD",
            //              headerTooltip: "",
            //              field: "costGoal",
            //              filter: 'number',
            //              width: numberColWidth,
            //              columnGroupShow: 'open',
            //              cellClass: $rootScope.getClassForCell,
            //              cellRenderer: $rootScope.bigMoneyRenderer
            //          },
            //          {
            //              headerName: "GAP",
            //              headerTooltip: "",
            //              field: "costGoalGap",
            //              filter: 'number',
            //              width: numberColWidth,
            //              columnGroupShow: 'open',
            //              cellClass: $rootScope.getClassForCell,
            //              cellRenderer: $rootScope.bigMoneyRenderer,
            //              cellStyle: function (params) { return params.value < 0 ? { color: 'red' } : { color: 'green' }; }
            //          },
            //          {
            //              headerName: "GAP %",
            //              headerTooltip: "",
            //              field: "costGoalGapP",
            //              filter: 'number',
            //              width: numberColWidth,
            //              cellClass: $rootScope.getClassForCell,
            //              cellRenderer: $rootScope.percentageRendererNoDecimals,
            //              cellStyle: function (params) { return params.value < 0 ? { color: 'red' } : { color: 'green' }; }
            //          },
            //    ]
            //},
        ]

        self.gridOptions = {
            columnDefs: self.columnDefs,
            enableColResize: true,
            enableSorting: true,
            enableFilter: true,
            groupHideGroupColumns: true,
            groupUseEntireRow: false,
            groupSelectsChildren: true,
            rowHeight: 30,
            rowSelection: "multiple",
            rowDeselection: true,
            showToolPanel: false,// window.innerWidth > 1500,
            suppressRowClickSelection: true,
            suppressCellSelection: true,
            singleClickEdit: true,
            icons: {
                columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                filter: '<i class="fa fa-filter"/>',
                sortAscending: '<i class="fa fa-long-arrow-down"/>',
                sortDescending: '<i class="fa fa-long-arrow-up"/>',
                groupExpanded: '<i class="fa fa-minus-square-o"/>',
                groupContracted: '<i class="fa fa-plus-square-o"/>',
                columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
            },
            suppressColumnMoveAnimation: $rootScope.suppressColumnMoveAnimation(),
            enableRangeSelection: false,
            angularCompileRows: true,
            isExternalFilterPresent: isExternalFilterPresent,
            doesExternalFilterPass: doesExternalFilterPass,
            getNodeChildDetails: getNodeChildDetails,
            rowGroupOpened: rowGroupOpened

        }

        function getNodeChildDetails(rowItem) {
            //console.log(rowItem);
            if (rowItem.children && rowItem.children.length > 0) {
                return {
                    group: true,
                    expanded: false,
                    // provide ag-Grid with the children of this group
                    children: rowItem.children,
                    // the key is used by the default group cellRenderer
                    key: rowItem.hierarchyId
                };
            }

            //else if (rowItem.level == 4) {
            //    var deferred = $q.defer();

            //    homeService.goals.getPriceListsByCategory(rowItem.hierarchyId).then(function (data) {
            //        deferred.resolve({
            //                            group: true,
            //                            expanded: false,
            //                            // provide ag-Grid with the children of this group
            //                            children: data,
            //                            // the key is used by the default group cellRenderer
            //                            key: rowItem.hierarchyId
            //                        });
            //    });

            //    return deferred.promise;
            //}

            return null;
        }

        function rowGroupOpened(rowItem) {
            //console.log('rowGroupOpened');
            //console.log(rowItem);
        }

        self.filterByCategoryGroup = function () {
            self.gridOptions.api.onFilterChanged();
        }

        function isExternalFilterPresent() {
            return self.selectedCategoryGroup != null;
        }

        function doesExternalFilterPass(node) {
            return node.data.categoryGroupId == self.selectedCategoryGroup.id;
        }

        self.selectedPath = '';

        self.changeCategory = function (cat) {
            console.log('cat', cat);
            self.selectedPath = cat;
            self.updateChart(varName, false);
        }

        var varName, varGoalName = null;
        self.updateChart = function (varNameP, isFirstLoad) {
            var v1 = varNameP;
            var v2 = varNameP + 'Goal';

            if (isFirstLoad) {
                renderGoalChart(v1, v2);
            }
            else {

                var data = {
                    unload: [varName, varGoalName],
                    json: getFilteredData(),
                    keys: {
                        x: 'hierarchyName',
                        value: [v1, v2],
                    },
                    type: 'bar',
                };

                data.types = {};
                data.types[v2] = "spline";

                self.chart.load(data);

            }

            varName = v1;
            varGoalName = v2;
        }

        function renderGoalChart(varName, varGoalName) {

            var template = {
                bindto: '#goalschart',
                data: {
                    json: getFilteredData(),
                    keys: {
                        x: 'hierarchyName',
                        value: [varName, varGoalName],
                    },
                    type: 'bar',
                },
                axis: {
                    x: {
                        type: 'category'
                    },
                    y: {
                        tick: {
                            format: d3.format(",.0f")
                        }
                    }

                }
            };

            template.data.types = {};
            template.data.types[varGoalName] = 'spline';

            self.chart = c3.generate(template);
        }

        function getGoals(goals, callback) {

            _.each(goals, function (goal) {
                //console.log('goal == self.selectedPath', goal.path + ' === ' + self.selectedPath);
                if (goal.path == self.selectedPath) {
                    callback(goal)
                    return;
                }

                if (goal.children && goal.children.length > 0)
                    getGoals(goal.children, callback);
            })

        }

        function getFilteredData() {
            var rv = [];
            //console.log('self.goals', self.goals);

            //console.log('selectedcategory', self.selectedPath);   
            getGoals(self.goals, function (goal) {
                //console.log('goal to add', goal);
                rv.push(goal);
            })
            console.log('parsedData', rv);
            return rv;
        }

        $scope.$watch('ct.sameStores', function (newVal, oldVar) {
            //console.log('newval', newVal);
            loadGoals(newVal);
        });

        function setPath(goals) {
            //La version comentada arriba es mucho mas simple y funciona para N niveles de children, pero al ser
            //recursiva y trabajar con mas de 35k de registros tarda mucho.
            //Esta version, si bien es limitada, es mucho mas rapida.
            //Solo trabaja con 5 niveles de Children

            angular.forEach(goals, function (goal1) {
                goal1.path = "";
                angular.forEach(goal1.children, function (goal2) {
                    goal2.path = goal1.path + "/" + goal1.hierarchyName;
                    angular.forEach(goal2.children, function (goal3) {
                        goal3.path = goal2.path + "/" + goal2.hierarchyName;
                        angular.forEach(goal3.children, function (goal4) {
                            goal4.path = goal3.path + "/" + goal3.hierarchyName;
                            angular.forEach(goal4.children, function (goal5) {
                                goal5.path = goal4.path + "/" + goal4.hierarchyName;

                            })
                        })
                    })
                })
            })
        }

        function setGap(goal) {
            goal.costGoalGap = goal.cost - goal.costGoal;
            goal.unitsGoalGap = goal.units - goal.unitsGoal;
            goal.salesGoalGap = goal.sales - goal.salesGoal;
            goal.marginGoalGap = goal.margin - goal.marginGoal;
            goal.costGoalGapP = goal.costGoal ? (goal.cost / goal.costGoal) - 1 : null;
            goal.unitsGoalGapP = goal.unitsGoal ? (goal.units / goal.unitsGoal) - 1 : null;
            goal.salesGoalGapP = goal.salesGoal ? (goal.sales / goal.salesGoal) - 1 : null;
            goal.marginGoalGapP = goal.marginGoal ? (goal.margin / goal.marginGoal) - 1 : null;


        }

        function setGaps(goals) {
            if (!goals) return;
            angular.forEach(goals, function (goal) {
                setGap(goal);
                setGaps(goal.children);
            });
        }

        function loadGoals(withSameStores) {
            if (self.type == 'category') {
                homeService.goals.getGoalsByCategory(withSameStores)
                    .then(function (dtos) {
                        setPath(dtos);
                        setGaps(dtos);
                        console.log('data', dtos);

                        self.goals = dtos;

                        self.gridOptions.api.setRowData(self.goals);

                        self.updateChart('sales', true);
                    });
            }

            if (self.type == 'store') {
                homeService.goals.getGoalsByStore(withSameStores)
                    .then(function (dtos) {
                        setPath(dtos);
                        setGaps(dtos);

                        console.log('data', dtos);

                        self.goals = dtos;

                        self.gridOptions.api.setRowData(self.goals);

                        self.updateChart('sales', true);
                    });
            }
        }

        function init() {

            //loadGoals(true);


        }

        init();
    });
