(function () {
    'use strict';

    angular.module('prisma')
      .filter('percentage', ['$filter', function ($filter) {
          return function (input, decimals) {
              if (input)
                  return $filter('number')(input * 100, decimals) + '%';
              else
                  return null;
          };
      }])
      .constant("companyJson", window.companyJson)
      .run(runBlock);




    /** @ngInject */
    function runBlock($log, $rootScope, $state, $stateParams, $window, $filter, authService, homeService, adminService, companyJson, $translate) {

        //Session
        authService.fillAuthData();


        //TODO: genero un objeto por default para que el dashboard que estamos usando en la home tenga datos para renderizarse. Cuando tengamos el dashboard final hay que eliminar esto
        //$rootScope.selectedParetoItem = JSON.parse();

        $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams) {
            // track the state the user wants to go to; authorization service needs this
            $rootScope.toState = toState;
            $rootScope.toStateParams = toStateParams;
            // if the principal is resolved, do an authorization check immediately. otherwise,
            // it'll be done when the state it resolved.
            //if (principal.isIdentityResolved()) authorization.authorize();
        });

        $rootScope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
            $rootScope.currState = to;
            $rootScope.prevState = from;
        });

        $rootScope.goBack = function () {
            var goto = $rootScope.prevState
                && !$rootScope.prevState.abstract
                && $rootScope.prevState != $rootScope.currState
                ? $rootScope.prevState : "home.dashboard";

            $state.go(goto);
        }

        $rootScope.toTitleCase = function (str) {
            return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
        }

        //Estoy hardcodeando el companyId. Despues tendria que sacarse del user que se loguea.
        $rootScope.companyId = 1;
        $rootScope.allDataDate = '';
        $rootScope.logoNavbar = '';
        $rootScope.logoLogin = '';
        $rootScope.domain = '';
        $rootScope.navigation = companyJson.Navigation;
        $rootScope.decisionsConfig = companyJson.AssortmentDecisions.Grid;
        $rootScope.navigation = companyJson.Navigation;
        $rootScope.erp = companyJson.Erp;
        $rootScope.maxPlanType = companyJson.MarkdownAging ? companyJson.MarkdownAging.MaxPlanType : 29;
        $rootScope.maxAgingLevel = companyJson.MarkdownAging ? companyJson.MarkdownAging.Level : 7;

        $rootScope.hasPermission = function (permission) {
            return authService.hasPermission(permission);
        }

        $rootScope.shortWeekDayNames = [];
        $rootScope.shortMonthNames = [];
        $rootScope.weekDayNames = [];
        $rootScope.monthNames = [];
        $translate(["SundayS", "MondayS", "TuesdayS", "WednesdayS", "ThursdayS", "FridayS", "SaturdayS", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "JanuaryS", "FebruaryS", "MarchS", "AprilS", "MayS", "JuneS", "JulyS", "AugustS", "SeptemberS", "OctoberS", "NovemberS", "DecemberS", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"])
            .then(function (translations) {
                $rootScope.shortWeekDayNames = [translations.SundayS, translations.MondayS, translations.TuesdayS, translations.WednesdayS, translations.ThursdayS, translations.FridayS, translations.SaturdayS];

                $rootScope.shortMonthNames = [translations.JanuaryS, translations.FebruaryS, translations.MarchS, translations.AprilS
                    , translations.MayS, translations.JuneS, translations.JulyS, translations.AugustS, translations.SeptemberS, translations.OctoberS, translations.NovemberS, translations.DecemberS];

                $rootScope.weekDayNames = [translations.Sunday, translations.Monday, translations.Tuesday, translations.Wednesday, translations.Thursday, translations.Friday, translations.Saturday];

                $rootScope.monthNames = [translations.January, translations.February, translations.March, translations.April
                    , translations.May, translations.June, translations.July, translations.August, translations.September, translations.October, translations.November, translations.December];

                var d3Locale = {};
                if ($translate.use() == 'en') {
                    d3Locale = d3.locale({
                        "decimal": ",",
                        "thousands": ".",
                        "grouping": [3],
                        "currency": ["$", ""],
                        "dateTime": "%a %b %e %X %Y",
                        "date": "%m/%d/%Y",
                        "time": "%H:%M:%S",
                        "periods": ["AM", "PM"],
                        "days": $rootScope.weekDayNames,
                        "shortDays": $rootScope.shortWeekDayNames,
                        "months": $rootScope.monthNames,
                        "shortMonths": $rootScope.shortMonthNames
                    });
                }
                else {
                    //D3 Configuration Formats
                    d3Locale = d3.locale({
                        "decimal": ".",
                        "thousands": ",",
                        "grouping": [3],
                        "currency": ["$", ""],
                        "dateTime": "%a %b %e %X %Y",
                        "date": "%m/%d/%Y",
                        "time": "%H:%M:%S",
                        "periods": ["AM", "PM"],
                        "days": $rootScope.weekDayNames,
                        "shortDays": $rootScope.shortWeekDayNames,
                        "months": $rootScope.monthNames,
                        "shortMonths": $rootScope.shortMonthNames
                    });
                }
                d3.time.format = d3Locale.timeFormat;

            });

        
        $translate([])
            .then(function (translations) {
                
            });

        /*
            RENDERERES Y FUNCIONES COMUNES PARA AG GRID
        */

        $rootScope.toPercentage = function (number) {
            number = number * 100;

            if (number.toString().indexOf('.') > 0)
                return number.toFixed(1) + " %";
            else
                return number.toFixed(0) + " %";
        }

        $rootScope.toMoneyNoDecimals = function (number) {
            return '$ ' + $filter('number')(number, 0);
        }

        $rootScope.getClassForImpactCell = function (params) {
            var cls = [];

            if (params.value < 0) {
                cls.push("text-danger");
            }
            else {
                cls.push("text-ok");
            }

            var baseClass = $rootScope.getClassForCell(params);
            for (var i = 0; i < baseClass.length; i++) {
                cls.push(baseClass[0]);
            }

            return cls;
        }

        $rootScope.getClassForImpactCellInverse = function (params) {
            var cls = [];

            if (params.value > 0) {
                cls.push("text-danger");
            }
            else {
                cls.push("text-ok");
            }

            var baseClass = $rootScope.getClassForCell(params);
            for (var i = 0; i < baseClass.length; i++) {
                cls.push(baseClass[0]);
            }

            return cls;
        }

        $rootScope.getClassForMarginCell = function (params) {
            var cls = [];

            if (params.data.minMargin != null && params.data.minMargin != 0 && params.data.maxMargin != null && params.data.maxMargin != 0 && (params.value < params.data.minMargin || params.value > params.data.maxMargin)) {
                cls.push("text-danger");
            }

            var baseClass = $rootScope.getClassForCell(params);
            for (var i = 0; i < baseClass.length; i++) {
                cls.push(baseClass[0]);
            }

            return cls;
        }

        $rootScope.getClassForWeightedIndexCell = function (params) {
            var cls = [];

            if (Math.round((params.data.indexSalesWeight + params.data.indexUnitsWeight + params.data.indexMarginWeight + params.data.indexMarketSalesWeight + params.data.indexMarketUnitsWeight) * 100) / 100 != 1) {
                cls.push("text-danger");
            }

            var baseClass = $rootScope.getClassForCell(params);
            for (var i = 0; i < baseClass.length; i++) {
                cls.push(baseClass[0]);
            }

            return cls;
        }

        $rootScope.getClassForHierarchyPCell = function (params) {
            var cls = [];

            if (params.value > 1) {
                cls.push("text-danger");
            }
            cls.push("text-right");

            return cls;
        }

        $rootScope.getClassForHierarchyCell = function (params) {
            var cls = [];

            if (params.value > 0) {
                cls.push("text-danger");
            }

            cls.push("text-right");

            return cls;
        }

        $rootScope.WeightedIndexPercentageEditor = function (params) {

            var editing = false;

            var eCell = document.createElement('div');
            eCell.style = "width:100% !important;height:100% !important";
            eCell.className = "input-group";
            var eLabel = params.value != null ? document.createTextNode($rootScope.percentageRendererNoDecimals(params)) : document.createTextNode('');
            eCell.appendChild(eLabel);

            var eInput = document.createElement("input");
            eInput.type = "number";
            eInput.step = "0.01";
            eInput.className = "form-control";
            var eSpan = document.createElement("span");
            eSpan.className = "input-group-addon";
            eSpan.style = "margin:0px !important; height: 100% !important; border: 0px; line-height: 100%; font-size:12px !important";
            eSpan.innerText = "%";


            eCell.addEventListener('click', function () {
                if (!editing) {
                    eCell.removeChild(eLabel);
                    eCell.appendChild(eInput);
                    eCell.appendChild(eSpan);
                    eInput.focus();
                    eInput.value = params.value ? params.value * 100 : null;
                    editing = true;
                }
            });

            eInput.addEventListener('blur', function () {
                if (editing) {
                    editing = false;
                    eCell.removeChild(eInput);
                    eCell.removeChild(eSpan);
                    eCell.appendChild(eLabel);
                }
            });

            eInput.addEventListener('change', function () {
                if (editing) {
                    editing = false;
                    var oldValue = params.value;
                    var newValue = eInput.value / 100;
                    params.value = newValue;
                    params.data[params.column.colId] = newValue;
                    eLabel.nodeValue = $rootScope.percentageRendererNoDecimals(params);
                    eCell.removeChild(eInput);
                    eCell.removeChild(eSpan);
                    eCell.appendChild(eLabel);

                    if (params.node != null && params.node.children != null) {
                        var changedRows = [];
                        var colIds = [];
                        colIds.push(params.column.colId);
                        params.node.children.forEach(function (node) {
                            if (node.children) {
                                node.children.forEach(function (node) {
                                    //if (node.data[params.column.colId] == oldValue && node.data.indexSalesWeight == node.parent.data.indexSalesWeight && node.data.indexUnitsWeight == node.parent.data.indexUnitsWeight && node.data.indexMarginWeight == node.parent.data.indexMarginWeight && node.data.indexMarketSalesWeight == node.parent.data.indexMarketSalesWeight && node.data.indexMarketUnitsWeight == node.parent.data.indexMarketUnitsWeight) {
                                        node.data[params.column.colId] = params.value;
                                        node.data[params.column.colId] = params.value;
                                        node.data.dirty = true;
                                        changedRows.push(node);
                                    //}
                                });
                            }
                            //if (node.data[params.column.colId] == oldValue && node.data.indexSalesWeight == node.parent.data.indexSalesWeight && node.data.indexUnitsWeight == node.parent.data.indexUnitsWeight && node.data.indexMarginWeight == node.parent.data.indexMarginWeight && node.data.indexMarketSalesWeight == node.parent.data.indexMarketSalesWeight && node.data.indexMarketUnitsWeight == node.parent.data.indexMarketUnitsWeight) {
                                node.data[params.column.colId] = params.value;
                                node.data.dirty = true;
                                changedRows.push(node);
                            //}
                        });
                        params.api.refreshCells(changedRows, colIds);
                        params.api.recomputeAggregates();

                    }
                    params.data.dirty = true;
                    params.api.recomputeAggregates();
                }
            });

            return eCell;
        }

        $rootScope.getClassForCell = function (params) {

            var cls = [];

            if (params.colDef.filter === "number") {
                cls.push("text-right");
            }
            else if (params.colDef.filter === "input" || params.colDef.filter === "select")
                cls.push("text-center");
            else
                cls.push("text-left");

            cls.push('ag-cell-padding');
            return cls;
        }

        $rootScope.backgroundNumberClass = function (prams) {
            var cls = [];

            cls.push("text-right");
            cls.push("backgroundInput");

            return cls;
        },

        $rootScope.suppressColumnMoveAnimation = function () {
            var isFirefox = typeof InstallTrigger !== 'undefined';
            // At least Safari 3+: "[object HTMLElementConstructor]"
            var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
            // Internet Explorer 6-11
            var isIE = /*@cc_on!@*/
            false || !!document.documentMode;

            return isFirefox || isSafari || isIE;
        }

        $rootScope.integerRenderer = function (params) {
            if (params.value == null || params.value == 0)
                return null;

            return $filter('number')(params.value, 0);
        };

        $rootScope.integerRendererWithZero = function (params) {
            if (params.value == null)
                return null;

            return $filter('number')(params.value, 0);
        };

        $rootScope.decimalRenderer = function (params) {
            if (params.value == null || params.value == 0)
                return null;

            return $filter('number')(params.value, 2);
        };

        $rootScope.dateRenderer = function (params) {
            if (params.value == null)
                return "";

            return new Date(params.value).toISOString().slice(0, 10);
        }

        $rootScope.dateTimeRenderer = function (params) {
            if (params.value == null)
                return "";

            return new Date(params.value).toISOString().replace('T', ' ').slice(0, 19);
        }

        $rootScope.emptyRenderer = function (params) {
            return "";
        }

        $rootScope.moneyRenderer = function (params) {
            if (params.value == null || params.value == 0 || params.value == '')
                return null;

            return "$ " + $filter('number')(params.value, 2);
        };

        $rootScope.moneyRendererWithZeros = function (params) {
            if (params.value == null || params.value == '')
                return null;

            return "$ " + $filter('number')(params.value, 2);
        };

        $rootScope.bigMoneyRenderer = function (params) {
            if (params.value == null || params.value == 0 || params.value == '')
                return '';

            var value = ian.abbreviateNumberThousand(params.value, 2, '$');

            return value;
        }

        $rootScope.greaterThan = function (params) {
            if (!params.value) {
                return '';
            }

            return "> " + $filter('number')(params.value, 2);
        }

        $rootScope.greaterThanNoDecimals = function (params) {
            if (!params.value) {
                return '';
            }
            var value = $rootScope.integerRenderer(params);


            return "> " + value;
        }

        $rootScope.bigIntRenderer = function (params) {
            if (params.value == null || params.value == 0 || params.value == '')
                return '';

            var value = ian.abbreviateNumberThousand(params.value, 2, '');

            return value;
        }

        $rootScope.moneyRendererNoDecimals = function (params) {
            var value = $rootScope.integerRenderer(params);
            if (value == null || value == 0 || value == '')
                return null;

            return "$ " + value;
        };

        $rootScope.percentageRenderer = function (params) {
            if (params.value == null || params.value == 0 || params.value == '')
                return null;

            var value = params.value * 100; //TODO: ver si existe una manera mejor de formatear un porcentaje. Como regla general los porcentajes se deben guardar en la base como decimales 0.5 = 50%

            return value.toFixed(1) + " %";
        }

        $rootScope.percentageRendererDirect = function (params) {
            if (params.value == null || params.value == 0 || params.value == '')
                return null;

            var value = params.value;

            return value.toFixed(1) + " %";
        }

        $rootScope.percentageRendererWithZero = function (params) {
            if (params.value == null || params.value === '')
                return null;

            var value = params.value * 100; //TODO: ver si existe una manera mejor de formatear un porcentaje. Como regla general los porcentajes se deben guardar en la base como decimales 0.5 = 50%

            return value.toFixed(1) + " %";
        }

        $rootScope.percentageRenderer2Decimals = function (params) {
            if (params.value == null || params.value == 0 || params.value == '')
                return null;

            var value = params.value * 100; //TODO: ver si existe una manera mejor de formatear un porcentaje. Como regla general los porcentajes se deben guardar en la base como decimales 0.5 = 50%

            return value.toFixed(2) + " %";
        }

        $rootScope.percentageRendererPP = function (params) {
            if (params.value == null || params.value == 0 || params.value == '')
                return null;

            var value = params.value * 100; //TODO: ver si existe una manera mejor de formatear un porcentaje. Como regla general los porcentajes se deben guardar en la base como decimales 0.5 = 50%

            return value.toFixed(1) + " PP";
        }

        $rootScope.percentageRendererNoDecimals = function (params) {
            if (params.value == null || params.value == 0 || params.value == '')
                return '';

            var value = params.value * 100;

            return value.toFixed(0) + " %";
        }

        $rootScope.checkboxEditor = function (onChangeCallback) {


            return function (params) {
                var eInput = document.createElement("input");
                eInput.type = "checkbox";
                eInput.checked = params.value;

                eInput.addEventListener("change", function (event) {
                    var changedRows = [];
                    var colIds = [];
                    colIds.push(params.column.colId);
                    params.value = eInput.checked;
                    params.data[params.column.colId] = eInput.checked;

                    //Excepto para la grilla de precios
                    if (params.colDef.field != 'priceId') {
                        if (params.node != null && params.node.children != null) {
                            params.node.children.forEach(function (node) {
                                if (node.children) {
                                    node.children.forEach(function (node) {
                                        node.data[params.column.colId] = eInput.checked;
                                        node.data.dirty = true;
                                        changedRows.push(node);
                                    });
                                }
                                node.data[params.column.colId] = eInput.checked;
                                node.data.dirty = true;
                                changedRows.push(node);
                            });
                            params.api.refreshCells(changedRows, colIds);
                        }
                        params.data.dirty = true;
                    }


                    if (onChangeCallback != undefined) {
                        onChangeCallback(event, params);
                    }
                });

                return eInput;
            }
        }



        $rootScope.percentageEditor = function (params) {

            var editing = false;

            var eCell = document.createElement('div');
            eCell.style = "width:100% !important;height:100% !important";
            eCell.className = "input-group";
            var eLabel = params.value != null && params.value != 0 ? document.createTextNode($rootScope.percentageRendererNoDecimals(params)) : document.createTextNode('');
            eCell.appendChild(eLabel);

            var eInput = document.createElement("input");
            eInput.type = "number";
            eInput.step = "0.01";
            eInput.className = "form-control";
            var eSpan = document.createElement("span");
            eSpan.className = "input-group-addon";
            eSpan.style = "margin:0px !important; height: 100% !important; border: 0px; line-height: 100%; font-size:12px !important";
            eSpan.innerText = "%";


            eCell.addEventListener('click', function () {
                if (!editing) {
                    eCell.removeChild(eLabel);
                    eCell.appendChild(eInput);
                    eCell.appendChild(eSpan);
                    eInput.focus();
                    eInput.value = params.value ? params.value * 100 : null;
                    editing = true;
                }
            });

            eInput.addEventListener('blur', function () {
                if (editing) {
                    editing = false;
                    eCell.removeChild(eInput);
                    eCell.removeChild(eSpan);
                    eCell.appendChild(eLabel);
                }
            });

            eInput.addEventListener('change', function () {
                if (editing) {
                    editing = false;
                    var newValue = eInput.value / 100;
                    params.value = newValue;
                    params.data[params.column.colId] = newValue;
                    eLabel.nodeValue = $rootScope.percentageRendererNoDecimals(params);
                    eCell.removeChild(eInput);
                    eCell.removeChild(eSpan);
                    eCell.appendChild(eLabel);

                    if (params.node != null && params.node.children != null) {
                        var changedRows = [];
                        var colIds = [];
                        colIds.push(params.column.colId);
                        params.node.children.forEach(function (node) {
                            if (node.children) {
                                node.children.forEach(function (node) {
                                    node.data[params.column.colId] = params.value;
                                    node.data.dirty = true;
                                    changedRows.push(node);
                                });
                            }
                            node.data[params.column.colId] = params.value;
                            node.data.dirty = true;
                            changedRows.push(node);
                        });
                        params.api.refreshCells(changedRows, colIds);
                        params.api.recomputeAggregates();

                    }
                    params.data.dirty = true;

                }
            });

            return eCell;
        }

        $rootScope.percentageEditorWithDecimal = function (params) {

            var editing = false;

            var eCell = document.createElement('div');
            eCell.style = "width:100% !important;height:100% !important";
            eCell.className = "input-group";
            var eLabel = params.value != null && params.value != 0 ? document.createTextNode($rootScope.percentageRenderer(params)) : document.createTextNode('');
            eCell.appendChild(eLabel);

            var eInput = document.createElement("input");
            eInput.type = "number";
            eInput.step = "0.01";
            eInput.className = "form-control";
            var eSpan = document.createElement("span");
            eSpan.className = "input-group-addon";
            eSpan.style = "margin:0px !important; height: 100% !important; border: 0px; line-height: 100%; font-size:12px !important";
            eSpan.innerText = "%";


            eCell.addEventListener('click', function () {
                if (!editing) {
                    eCell.removeChild(eLabel);
                    eCell.appendChild(eInput);
                    eCell.appendChild(eSpan);
                    eInput.focus();
                    eInput.value = params.value ? params.value * 100 : null;
                    editing = true;
                }
            });

            eInput.addEventListener('blur', function () {
                if (editing) {
                    editing = false;
                    eCell.removeChild(eInput);
                    eCell.removeChild(eSpan);
                    eCell.appendChild(eLabel);
                }
            });

            eInput.addEventListener('change', function () {
                if (editing) {
                    editing = false;
                    var newValue = eInput.value / 100;
                    params.value = newValue;
                    params.data[params.column.colId] = newValue;
                    eLabel.nodeValue = $rootScope.percentageRenderer(params);
                    eCell.removeChild(eInput);
                    eCell.removeChild(eSpan);
                    eCell.appendChild(eLabel);

                    if (params.node != null && params.node.children != null) {
                        var changedRows = [];
                        var colIds = [];
                        colIds.push(params.column.colId);
                        params.node.children.forEach(function (node) {
                            if (node.children) {
                                node.children.forEach(function (node) {
                                    node.data[params.column.colId] = params.value;
                                    node.data.dirty = true;
                                    changedRows.push(node);
                                });
                            }
                            node.data[params.column.colId] = params.value;
                            node.data.dirty = true;
                            changedRows.push(node);
                        });
                        params.api.refreshCells(changedRows, colIds);
                        params.api.recomputeAggregates();

                    }
                    params.data.dirty = true;

                }
            });

            return eCell;
        }

        $rootScope.percentageEditorWithDecimalAndZero = function (params) {

            var editing = false;

            var eCell = document.createElement('div');
            eCell.style = "width:100% !important;height:100% !important";
            eCell.className = "input-group";
            var eLabel = params.value != null ? document.createTextNode($rootScope.percentageRendererWithZero(params)) : document.createTextNode('');
            eCell.appendChild(eLabel);

            var eInput = document.createElement("input");
            eInput.type = "number";
            eInput.step = "0.01";
            eInput.className = "form-control";
            var eSpan = document.createElement("span");
            eSpan.className = "input-group-addon";
            eSpan.style = "margin:0px !important; height: 100% !important; border: 0px; line-height: 100%; font-size:12px !important";
            eSpan.innerText = "%";


            eCell.addEventListener('click', function () {
                if (!editing) {
                    eCell.removeChild(eLabel);
                    eCell.appendChild(eInput);
                    eCell.appendChild(eSpan);
                    eInput.focus();
                    eInput.value = params.value ? params.value * 100 : null;
                    editing = true;
                }
            });

            eInput.addEventListener('blur', function () {
                if (editing) {
                    editing = false;
                    eCell.removeChild(eInput);
                    eCell.removeChild(eSpan);
                    eCell.appendChild(eLabel);
                }
            });

            eInput.addEventListener('change', function () {
                if (editing) {
                    editing = false;
                    var newValue = eInput.value / 100;
                    params.value = newValue;
                    params.data[params.column.colId] = newValue;
                    eLabel.nodeValue = $rootScope.percentageRendererWithZero(params);
                    eCell.removeChild(eInput);
                    eCell.removeChild(eSpan);
                    eCell.appendChild(eLabel);

                    if (params.node != null && params.node.children != null) {
                        var changedRows = [];
                        var colIds = [];
                        colIds.push(params.column.colId);
                        params.node.children.forEach(function (node) {
                            if (node.children) {
                                node.children.forEach(function (node) {
                                    node.data[params.column.colId] = params.value;
                                    node.data.dirty = true;
                                    changedRows.push(node);
                                });
                            }
                            node.data[params.column.colId] = params.value;
                            node.data.dirty = true;
                            changedRows.push(node);
                        });
                        params.api.refreshCells(changedRows, colIds);
                        params.api.recomputeAggregates();

                    }
                    params.data.dirty = true;

                }
            });

            return eCell;
        }


        $rootScope.percentageEditorWith2Decimals = function (params) {

            var editing = false;

            var eCell = document.createElement('div');
            eCell.style = "width:100% !important;height:100% !important";
            eCell.className = "input-group";
            var eLabel = params.value != null && params.value != 0 ? document.createTextNode($rootScope.percentageRenderer2Decimals(params)) : document.createTextNode('');
            eCell.appendChild(eLabel);

            var eInput = document.createElement("input");
            eInput.type = "number";
            eInput.step = "0.01";
            eInput.className = "form-control";
            var eSpan = document.createElement("span");
            eSpan.className = "input-group-addon";
            eSpan.style = "margin:0px !important; height: 100% !important; border: 0px; line-height: 100%; font-size:12px !important";
            eSpan.innerText = "%";


            eCell.addEventListener('click', function () {
                if (!editing) {
                    eCell.removeChild(eLabel);
                    eCell.appendChild(eInput);
                    eCell.appendChild(eSpan);
                    eInput.focus();
                    eInput.value = params.value ? params.value * 100 : null;
                    editing = true;
                }
            });

            eInput.addEventListener('blur', function () {
                if (editing) {
                    editing = false;
                    eCell.removeChild(eInput);
                    eCell.removeChild(eSpan);
                    eCell.appendChild(eLabel);
                }
            });

            eInput.addEventListener('change', function () {
                if (editing) {
                    editing = false;
                    var newValue = eInput.value / 100;
                    params.value = newValue;
                    params.data[params.column.colId] = newValue;
                    eLabel.nodeValue = $rootScope.percentageRenderer2Decimals(params);
                    eCell.removeChild(eInput);
                    eCell.removeChild(eSpan);
                    eCell.appendChild(eLabel);

                    if (params.node != null && params.node.children != null) {
                        var changedRows = [];
                        var colIds = [];
                        colIds.push(params.column.colId);
                        params.node.children.forEach(function (node) {
                            if (node.children) {
                                node.children.forEach(function (node) {
                                    node.data[params.column.colId] = params.value;
                                    node.data.dirty = true;
                                    changedRows.push(node);
                                });
                            }
                            node.data[params.column.colId] = params.value;
                            node.data.dirty = true;
                            changedRows.push(node);
                        });
                        params.api.refreshCells(changedRows, colIds);
                        params.api.recomputeAggregates();

                    }
                    params.data.dirty = true;

                }
            });

            return eCell;
        }

        $rootScope.moneyEditor = function (params) {

            var editing = false;

            var eCell = document.createElement('div');
            eCell.style = "width:100% !important;height:100% !important";
            eCell.className = "input-group";
            var eLabel = params.value != null ? document.createTextNode($rootScope.moneyRenderer(params)) : document.createTextNode('');
            var eIcon = document.createElement("i");
            eIcon.className = "fa fa-exclamation-triangle";
            eCell.appendChild(eIcon);
            //eCell.appendChild(eLabel);

            var eInput = document.createElement("input");
            eInput.type = "number";
            eInput.step = "0.01";
            eInput.className = "form-control";
            var eSpan = document.createElement("span");
            eSpan.className = "input-group-addon";
            eSpan.style = "margin:0px !important; height: 100% !important; border: 0px; line-height: 100%; font-size:12px !important";
            eSpan.innerText = "$";


            eCell.addEventListener('click', function () {
                if (!editing) {
                    eCell.removeChild(eLabel);
                    eCell.appendChild(eSpan);
                    eCell.appendChild(eInput);
                    eInput.focus();
                    eInput.value = params.value;
                    editing = true;
                }
            });

            eInput.addEventListener('blur', function () {
                if (editing) {
                    editing = false;
                    eCell.removeChild(eSpan);
                    eCell.removeChild(eInput);
                    eCell.appendChild(eLabel);
                }
            });

            eInput.addEventListener('change', function () {
                if (editing) {
                    editing = false;
                    params.value = eInput.value;
                    params.data[params.column.colId] = eInput.value;
                    params.data.dirty = true;
                    eLabel.nodeValue = $rootScope.moneyRenderer(params);
                    eCell.removeChild(eSpan);
                    eCell.removeChild(eInput);
                    eCell.appendChild(eLabel);

                    if (params.node != null && params.node.children != null) {
                        var changedRows = [];
                        var colIds = [];
                        colIds.push(params.column.colId);
                        params.node.children.forEach(function (node) {
                            if (node.children) {
                                node.children.forEach(function (node) {
                                    node.data[params.column.colId] = params.value;
                                    node.data.dirty = true;
                                    changedRows.push(node);
                                });
                            }
                            node.data[params.column.colId] = params.value;
                            node.data.dirty = true;
                            changedRows.push(node);
                        });
                        params.api.refreshCells(changedRows, colIds);
                    }
                }
            });

            return eCell;
        }

        $rootScope.integerEditor = function (params) {

            var editing = false;

            var eCell = document.createElement('div');
            eCell.style = "width:100% !important;height:100% !important";
            eCell.className = "input-group";
            var eLabel = params.value != null && params.value != 0 ? document.createTextNode($rootScope.integerRenderer(params)) : document.createTextNode('');
            eCell.appendChild(eLabel);

            var eInput = document.createElement("input");
            eInput.type = "number";
            eInput.step = "1";
            eInput.className = "form-control";

            eCell.addEventListener('click', function () {
                if (!editing) {
                    eCell.removeChild(eLabel);
                    eCell.appendChild(eInput);
                    eInput.focus();
                    eInput.value = params.value;
                    editing = true;
                }
            });

            eInput.addEventListener('blur', function () {
                if (editing) {
                    editing = false;
                    eCell.removeChild(eInput);
                    eCell.appendChild(eLabel);
                }
            });

            eInput.addEventListener('change', function () {
                if (editing) {
                    editing = false;
                    params.value = eInput.value;
                    params.data[params.column.colId] = eInput.value;
                    eLabel.nodeValue = $rootScope.integerRenderer(params);
                    eCell.removeChild(eInput);
                    eCell.appendChild(eLabel);

                    if (params.node != null && params.node.children != null) {
                        var changedRows = [];
                        var colIds = [];
                        colIds.push(params.column.colId);
                        params.node.children.forEach(function (node) {
                            if (node.children) {
                                node.children.forEach(function (node) {
                                    node.data[params.column.colId] = params.value;
                                    node.data.dirty = true;
                                    changedRows.push(node);
                                });
                            }
                            node.data[params.column.colId] = params.value;
                            node.data.dirty = true;
                            changedRows.push(node);
                        });
                        params.api.refreshCells(changedRows, colIds);
                    }
                    params.data.dirty = true;

                }
            });

            return eCell;
        }

        $rootScope.decimalEditor = function (params) {

            var editing = false;

            var eCell = document.createElement('div');
            eCell.style = "width:100% !important;height:100% !important";
            eCell.className = "input-group";
            var eLabel = params.value != null && params.value != 0 ? document.createTextNode($rootScope.decimalRenderer(params)) : document.createTextNode('');
            eCell.appendChild(eLabel);

            var eInput = document.createElement("input");
            eInput.type = "number";
            eInput.step = "1";
            eInput.className = "form-control";

            eCell.addEventListener('click', function () {
                if (!editing) {
                    eCell.removeChild(eLabel);
                    eCell.appendChild(eInput);
                    eInput.focus();
                    eInput.value = params.value;
                    editing = true;
                }
            });

            eInput.addEventListener('blur', function () {
                if (editing) {
                    editing = false;
                    eCell.removeChild(eInput);
                    eCell.appendChild(eLabel);
                }
            });

            eInput.addEventListener('change', function () {
                if (editing) {
                    editing = false;
                    params.value = eInput.value;
                    params.data[params.column.colId] = eInput.value;
                    eLabel.nodeValue = $rootScope.decimalRenderer(params);
                    eCell.removeChild(eInput);
                    eCell.appendChild(eLabel);

                    if (params.node != null && params.node.children != null) {
                        var changedRows = [];
                        var colIds = [];
                        colIds.push(params.column.colId);
                        params.node.children.forEach(function (node) {
                            if (node.children) {
                                node.children.forEach(function (node) {
                                    node.data[params.column.colId] = params.value;
                                    node.data.dirty = true;
                                    changedRows.push(node);
                                });
                            }
                            node.data[params.column.colId] = params.value;
                            node.data.dirty = true;
                            changedRows.push(node);
                        });
                        params.api.refreshCells(changedRows, colIds);
                    }
                    params.data.dirty = true;

                }
            });

            return eCell;
        }

        $rootScope.dateEditor = function (params) {

            var editing = false;

            var eCell = document.createElement('div');
            eCell.style = "width:100%;height:100%;"
            var eLabel = document.createTextNode($rootScope.dateRenderer(params));
            eCell.appendChild(eLabel);

            var eInput = document.createElement("input");
            eInput.style = "width:135px;"
            eInput.type = "date";

            eCell.addEventListener('click', function () {
                if (!editing) {
                    if (params.data.newValidFrom == null && params.data.newPrice == null)
                        swal("Error", "No puede editar las fechas manualmente sin antes cargar un precio nuevo.", "error");
                    else {
                        eCell.removeChild(eLabel);
                        eCell.appendChild(eInput);
                        eInput.focus();
                        eInput.value = params.value;
                        editing = true;
                    }
                }
            });

            eInput.addEventListener('blur', function () {
                if (editing) {
                    editing = false;
                    eCell.removeChild(eInput);
                    eCell.appendChild(eLabel);
                }
            });

            eInput.addEventListener('change', function () {
                if (editing) {
                    editing = false;
                    var newValue = eInput.value;
                    params.data[params.colDef.field] = newValue;
                    params.data["dirty"] = true;
                    params.value = newValue;
                    eLabel.nodeValue = $rootScope.dateRenderer(params);
                    eCell.removeChild(eInput);
                    eCell.appendChild(eLabel);

                    if (params.node != null && params.node.children != null) {
                        var changedRows = [];
                        var colIds = [];
                        colIds.push(params.column.colId);
                        params.node.children.forEach(function (node) {
                            if (node.children) {
                                node.children.forEach(function (node) {
                                    node.data[params.column.colId] = params.value;
                                    node.data.dirty = true;
                                    changedRows.push(node);
                                });
                            }
                            node.data[params.column.colId] = params.value;
                            node.data.dirty = true;
                            changedRows.push(node);
                        });
                        params.api.refreshCells(changedRows, colIds);
                    }
                }
            });

            return eCell;
        }

        $rootScope.trimLeft0 = function (param) {

            if (param.value != null && param.value.length > 0) {
                return param.value.replace(/^0+/, '');
            }
            else return param.value;
        }

        $rootScope.removeDuplicates = function (arr, prop) {
            var newArray = [];
            var lookup = {};
            for (var i in arr) {
                if (i != 'equals')
                    lookup[arr[i][prop]] = arr[i];
            }
            for (i in lookup) {
                newArray.push(lookup[i]);
            }
            return newArray;
        }

        $rootScope.translate = function (translationKey) {
            $translate(translationKey).then(function (translation) {
                return translation;
            });
        }

        //$rootScope.convertUTCDateToLocalDate = function (date) {
        //    var newDate = new Date(date);
        //    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
        //    return newDate;
        //}

        $rootScope.convertUTCDateToLocalDate = function (dateStr) {
            return new Date(moment(new Date(dateStr.replace("Z", ""))).add(companyJson.TimeZone.Gmt, 'hours'));
        }

        $rootScope.arrayAverage = function (array) {
            let avg = null;
            if (Array.isArray(array) && array.length > 0) {
                array.forEach(function (element) {
                    if (isNaN(element))
                        return null;
                });
                let sum = array.reduce(function (total, num) {
                    return total + num;
                });
                avg = sum / array.length;
            }
            return avg;
        }

        function getDataDate() {
            homeService.dashboards.getAllDataDate($translate.preferredLanguage())
            .then(function (stringDate) {
                $rootScope.allDataDate = stringDate;
            });
        }

        function getLogosAndDomain() {
            homeService.dashboards.getLogosAndDomain()
                .then(function (logosAndDomain) {
                    $rootScope.logoNavbar = logosAndDomain.logoNavbar;
                    $rootScope.logoLogin = logosAndDomain.logoLogin;
                    $rootScope.domain = logosAndDomain.domain;
                });
        }

        getDataDate();
        getLogosAndDomain();

        //config toastr
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": true,
            "positionClass": "toast-top-center",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "5000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }

        $log.debug('runBlock end');
    }
})();
