angular.module("prisma")
    .controller("layoutsAssignmentCtrl", function ($scope, $rootScope, $timeout, $state, $stateParams, promotionsService, homeService, authService, adminService, serverService, Upload, ngDialog) {
        var self = this;

        self.isLayoutEditable = false;
        self.selectedPage = null;
        self.canEditPages = authService.hasPermission('promotion_edit_layout_pages');

        self.promotionMediaPageTypes = promotionsService.promotionMediaPageTypes;

        self.promotion = {
            id: 0,
            name: '',
            pages: []
        };

        self.categoriesCaptains = [];
        self.categoriesSecondaries = [];

        self.layouts = [];

        self.layout = {
            id: 0,
            companyId: 0,
            name: '',
            description: '',
            rows: 0,
            columns: 0,
            backgroundImageUrl: '',
            details: []
        };

        self.layoutPreviewGrid = [];

        self.isValidating = false;
        self.isValidated = false;

        var categories = [];

        self.selectedPages = [];

        self.dataForSelectedPages = {
            mainCategoryId: 0,
            secondaryCategoriesIds: [],
            promotionMediaPageLayoutId: null
        };

        self.categoriesGroupEditMode = false;


        function addToSelectedPages(page) {
            if (self.selectedPage)
                self.selectedPages.push(self.selectedPage);
            if (self.selectedPages.includes(page)) {
                var index = self.selectedPages.indexOf(page);
                self.selectedPages.splice(index, 1);
            }
            else
                self.selectedPages.push(page);
            self.selectedPage = null;
        }

        self.isSelected = function (page) {
            return (self.selectedPage && page.pageNumber == self.selectedPage.pageNumber)
                || self.selectedPages.includes(page);
        }

        self.selectPage = function (e, page) {
            if (e.ctrlKey)
                addToSelectedPages(page);
            else {
                self.selectedPages = [];
                if (self.selectedPage == null || self.selectedPage.pageNumber != page.pageNumber) {

                    self.selectedPage = page;

                    angular.forEach(self.selectedPage.secondaryCategories, function (cat) {
                        self.selectedPage.secondaryCategoriesIds.push(cat.categoryId);
                    });

                    self.captainCategoryChanged();
                    self.secondaryCategoryChanged();

                    if (self.selectedPage.promotionMediaPageLayoutId != 0) {
                        if (self.layout == null || self.layout.id != self.selectedPage.promotionMediaPageLayoutId) {
                            self.showPreview(self.selectedPage.promotionMediaPageLayoutId);
                        }
                    }
                }
            }

        }

        self.captainCategoryChanged = function () {
            var mainCategoryId = 0;

            if (self.selectedPage)
                mainCategoryId = self.selectedPage.mainCategoryId;
            else if (self.selectedPages.length > 0)
                mainCategoryId = self.dataForSelectedPages.mainCategoryId;

            if (!mainCategoryId) return;

            self.categoriesSecondaries = [];

            angular.forEach(categories, function (category) {

                if (category.categoryId != mainCategoryId)
                    self.categoriesSecondaries.push(category);
            });
        }

        self.secondaryCategoryChanged = function () {
            var secondaryCategoriesIds = null;

            if (self.selectedPage)
                secondaryCategoriesIds = self.selectedPage.secondaryCategoriesIds;
            else if (self.selectedPages.length > 0)
                secondaryCategoriesIds = self.dataForSelectedPages.secondaryCategoriesIds;

            if (!secondaryCategoriesIds) return;

            self.categoriesCaptains = [];
            angular.forEach(categories, function (category) {
                var exist = false;
                angular.forEach(secondaryCategoriesIds, function (secondaryCategoryId) {
                    if (secondaryCategoryId == category.categoryId)
                        exist = true;

                });
                if (!exist)
                    self.categoriesCaptains.push(category);
            });
        }

        self.showPreview = function (layoutId) {

            for (i = 0; i < self.layouts.length; i++) {
                if (self.layouts[i].id == layoutId) {
                    self.layout = self.layouts[i];

                    $rootScope.$broadcast('refreshLayoutTable', {});
                    break;
                }
            }
        }

        self.getPageNumberAndType = function () {
            var rv = '';
            if (self.selectedPage)
                rv = 'Pagina ' + self.selectedPage.pageNumber + ' - ' + self.promotionMediaPageTypes[self.selectedPage.promotionMediaPageType - 1].label;
            else {
                rv = 'Paginas ';
                var labels = self.selectedPages.map(function (p) {
                    return p.pageNumber + ' - ' + self.promotionMediaPageTypes[p.promotionMediaPageType - 1].label;
                });
                rv += labels.join(', ');
            }
            return rv;
        }

        self.validate = function () {
            var result = true;

            /*
            if (!$scope.layoutForm.layoutName.$valid || !$scope.layoutForm.layoutRows.$valid || !$scope.layoutForm.layoutColumns.$valid) {
                result = false;
                self.isValidated = false;
            }
            */
            return result;
        }

        self.save = function () {

            self.isValidating = true;
            var text = "";
            //self.layout.details = [];

            if (self.promotion != null && self.validate()) {
                if ($stateParams.promotionId != '0' && self.isLayoutmodified()) {
                    text = 'Se perdera todo lo cargado en la página al modificarla.'
                }
                swal({
                    title: 'Esta seguro que desea guardar las asignaciones y comentarios?',
                    text: text,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: "Continuar",
                    cancelButtonText: "Cancelar",
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: true
                },
                    function (isConfirm) {
                        if (isConfirm) {

                            promotionsService.promotions.savePromotionMediaPages(self.promotion)
                                .then(function (promotion) {
                                    swal('Asignaciones Guardadas!', 'Las páginas de la promoción ' + self.promotion.name + ' se han guardado exitosamente', 'success');

                                    self.promotion = promotion;
                                    createPages();
                                    self.updateChangeDetector();

                                }, function (status) {
                                    if (status == 502) {
                                        swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                                    }
                                    else {
                                        swal("Error", "Hubo un error al guardar", "error");
                                    }
                                });
                        }
                    });
            }
        }


        self.validateCategories = function () {
            for (var i = 0; i < self.promotion.pages.length; i++) {
                if (self.promotion.pages[i].mainCategoryId == 0)
                    return false;
            }
            return true;
        }

        self.publish = function () {
            var text = 'Al hacerlo cerrará la tarea "asignar layout"';

            if (!self.validateCategories())
                swal("Error", "Asigne una categoria capitana por pagina", "error");
            else {
                if ($stateParams.promotionId != '0' && self.isLayoutmodified()) {
                    text = 'Se perdera todo lo cargado en la página al modificarla, al hacerlo cerrará la tarea "asignar layout"';
                }
                swal({
                    title: 'Está seguro que desea publicar?',
                    text: text,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: "Continuar",
                    cancelButtonText: "Cancelar",
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: true
                },
                    function (isConfirm) {
                        if (isConfirm) {
                            if (self.canPublish()) {
                                //guardo antes de publicar
                                promotionsService.promotions.savePromotionMediaPages(self.promotion)
                                    .then(function (promotion) {
                                        self.promotion = promotion;
                                        createPages();
                                        self.updateChangeDetector();
                                        //una vez guardado publico la tarea
                                        promotionsService.tasks.publishTask($stateParams.taskId)
                                            .then(function (task) {
                                                swal('Tarea Publicada!', 'La tarea se ha publicado exitosamente', 'success');

                                                $state.go('promotions.myTasks');
                                            });

                                    }, function (status) {
                                        if (status == 502) {
                                            swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                                        }
                                        else {
                                            swal("Error", "Hubo un error al guardar", "error");
                                        }
                                    });

                            } else {
                                $timeout(function () {
                                    swal("Error", "Debe asignar un layout a todas las páginas", "error");
                                }, 100);
                            }
                        }
                    });
            }
        }

        self.canPublish = function () {
            if ($stateParams.taskId && parseInt($stateParams.taskId) > 0) {
                for (var i = 0; i < self.promotion.pages.length; i++) {
                    if (self.promotion.pages[i].promotionMediaPageLayoutId == 0 || self.promotion.pages[i].promotionMediaPageLayoutId == null)
                        return false;
                }
                return true;
            } else return false;
        }

        self.addPage = function () {
            swal({
                title: "Agregar paginas",
                text: 'Indique la cantidad de paginas que desea agregar. Recuerde guardar los cambios previamente.',
                type: 'input',
                showCancelButton: true,
                closeOnConfirm: false,
                animation: "slide-from-top"
            }, function (numberOfPages) {
                if (numberOfPages > 0) {
                    var shouldCompleteAllPages = false;
                    angular.forEach(self.promotion.pages, function (page) {
                        if (page.promotionMediaPageLayoutId == null)
                            shouldCompleteAllPages = true;
                    });

                    if (shouldCompleteAllPages) {
                        swal("Error", "Debe Agregar y Guardar un layout para cada pagina.", "error");
                    }
                    else {
                        promotionsService.layouts.addPage(numberOfPages, self.promotion.id)
                            .then(function () {
                                loadPromotion();
                                swal('Paginas Agregadas!', 'Se han agregado las nuevas paginas exitosamente', 'success');
                            }, function (status) {
                                if (status == 502) {
                                    swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                                }
                                else {
                                    swal("Error", "Hubo un error al guardar", "error");
                                }
                            });
                    }
                }
                else {
                    swal("Error", "Debe ser un numero positivo", "error");
                }
            });
        }

        self.deletePage = function (mediaPageId) {
            swal({
                title: 'Esta seguro que desea eliminar esta pagina?',
                text: 'Se eliminaran todos los items asociados a ella.',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        promotionsService.layouts.deletePage(mediaPageId, self.promotion.id)
                            .then(function () {
                                loadPromotion();
                                swal('Pagina Eliminada!', '', 'success');
                            }, function (status) {
                                if (status == 502) {
                                    swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                                }
                                else {
                                    swal("Error", "Hubo un error al eliminar la pagina", "error");
                                }
                            });
                    }
                });
        }

        self.deleteCategoryGroup = function (groupId, index) {
            swal({
                title: 'Esta seguro que desea eliminar este grupo de categoria?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        promotionsService.layouts.deleteCategoriesGroup(groupId)
                            .then(function () {
                                swal('Grupo de categoria eliminado!', '', 'success');
                                self.categoriesGroups.splice(index, 1);
                            }, function (status) {
                                if (status == 502) {
                                    swal("El proceso continua...", "El proceso de borrado continua. Puede revisar el estado en unos minutos.")
                                }
                                else {
                                    swal("Error", "Hubo un error al eliminar el grupo de categoria", "error");
                                }
                            });
                    }
                });
        }

        self.openChat = function (pageId) {
        //busca la pagina y abre el dialog
                angular.forEach(self.promotion.pages, function (p, index) {
                        if (p.id === pageId) {

                            self.indexOfPageOnChat = index;
                        }

                    });


            ngDialog.open({                
                template: 'chatDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            });
        }

        //Funcion que valida si hubo algun cambio en el layout por cada pagina de la promocion.
        self.isLayoutmodified = function () {
            var rv = false;
            for (var i = 0; i < self.promotion.pages.length; i++)
            {
                if (self.promotion.pages[i].promotionMediaPageLayoutId != self.promotion.pages[i].initPromotionMediaPageLayoutId) {
                    rv = true;
                }
            }
            return rv;
        }

        //Actualiza el campo que detecta si hubo un cambio en el promotionMediaPageLayoutId.
        //Campo a actualizar: initPromotionMediaPageLayoutId.
        self.updateChangeDetector = function () {
            for (var i = 0; i < self.promotion.pages.length; i++)
            {
                self.promotion.pages[i].initPromotionMediaPageLayoutId = self.promotion.pages[i].promotionMediaPageLayoutId;
            }
            //Actualiza la pagina seleccionada.
            if (self.selectedPage != null) {
                self.selectedPage = self.promotion.pages.find(function (x) {
                    return x.id == self.selectedPage.id;
                })
            }
        }


        function createPages() {
            for (var i = 0; i < self.promotion.numberOfPages; i++) {
                var exist = false;
                angular.forEach(self.promotion.pages, function (page) {
                    if (page.pageNumber == i + 1)
                        exist = true;

                });
                if (!exist) {
                    var page = {
                        promotionId: self.promotion.id,
                        promotionMediaPageLayoutId: 0,
                        pageNumber: i + 1,
                        promotionMediaPageType: i == 0 ? 1 : (i + 1 == self.promotion.numberOfPages ? 2 : 3),
                        mainCategoryId: 0,
                        secondaryCategoriesIds: []
                    };

                    self.promotion.pages.splice(i, 0, page);
                }
            }
        }

        function loadPromotion() {
            if ($stateParams.promotionId != '0') {

                promotionsService.promotions.getPromotionPages($stateParams.promotionId)
                    .then(function (promotion) {
                        if (promotion != null) {
                            self.promotion = promotion;
                            createPages();
                            self.updateChangeDetector();
                        }
                    });
            }
        }

        self.openCategoriesGroupDialog = function (selectionMode) {

            if (selectionMode == undefined || selectionMode == null)
                selectionMode = false;

            var newScope = $scope.$new();
            self.newGroup = {
                name: "",
                categories: []
            };

            newScope.isSelection = selectionMode;
            self.categoriesGroupEditMode = false;
            newScope.isLoading = true;

            function getCategoriesGroups() {
            promotionsService.layouts.getCategoriesGroups()
                .then(function (response) {
                    self.categoriesGroups = response;
                    newScope.isLoading = false;
                    });
            }

            getCategoriesGroups();

            newScope.toggleMode = function () {
                self.categoriesGroupEditMode = !self.categoriesGroupEditMode;
                self.newGroup = {
                    name: "",
                    categories: []
                };
            }

            newScope.addNewCategoriesGroup = function () {
                if (self.newGroup.categories.some(function (c) { return c.isMain; })) {
                    newScope.isLoading = true;
                    promotionsService.layouts.saveCategoriesGroup(self.newGroup)
                        .then(function (newGroup) {
                            getCategoriesGroups();
                            newScope.toggleMode();
                        });
                }
                else swal('Alguna Categoria debe ser capitana', 'Click en una estrella para definir', 'warning');
            }

            self.onCategoriesOfGroupChanged = function () {

                angular.forEach(self.newGroup.categories, function (cat, index) {
                    if (cat.level === 3) {
                        var childs = self.allCategories.filter(function (x) {
                            return x.parentId === cat.id && !self.newGroup.categories.find(function (y) {
                                return y.id === x.id;
                            });
                        });
                        self.newGroup.categories.splice(index, 1);
                        childs.forEach(function (x) {
                            self.newGroup.categories.push(x);
                        });
                    }

                });
            }

            newScope.assignCategoriesGroup = function (group) {
                var mainCat = group.categories.find(function (x) { return x.id === group.mainCategoryId; });
                group.categories.splice(group.categories.indexOf(mainCat), 1);//el resto son secundarias
                if (mainCat) {
                    if (self.selectedPage) {
                        self.selectedPage.mainCategoryId = mainCat.id;
                        self.selectedPage.secondaryCategoriesIds = group.categories.map(function (x) { return x.id; });
                    }
                    else if (self.selectedPages && self.selectedPages.length > 0) {
                        self.dataForSelectedPages.mainCategoryId = mainCat.id;
                        self.dataForSelectedPages.secondaryCategoriesIds = group.categories.map(function (x) { return x.id; });
                    }
                    newScope.closeDialog();
                }
            }

            newScope.editCategoriesGroup = function (group) {
                newScope.toggleMode();
                self.newGroup.name = group.name;
                self.newGroup.id = group.id;
                self.newGroup.mainCategoryId = group.mainCategoryId;
                angular.forEach(self.allCategories, function (cat) {
                    angular.forEach(group.categories, function (catOfGroup) {
                        if (cat.id === self.newGroup.mainCategoryId)
                            cat.isMain = true;
                        if (catOfGroup.id === cat.id)
                            self.newGroup.categories.push(cat);
                    });
                });
            }

            newScope.setCategoryCaptainInGroup = function (category) {
                angular.forEach(self.newGroup.categories, function (c) {
                    if (c.id === category.id) {
                        c.isMain = !c.isMain;
                        self.newGroup.mainCategoryId = c.id;
                    }
                    else
                        c.isMain = false;
                });
            }


            newScope.closeDialog = function () {
                ngDialog.close();
            }

            ngDialog.open({
                template: 'categoriesGroupDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: newScope
            });
        }

        self.openPromotionsExplorer = function () {

            var newScope = $scope.$new();

            self.promotionId = null;
            self.promotionPageNumber = null;
            newScope.isLoading = false;

            newScope.getOtherPromotionData = function () {
                newScope.isLoading = true;
                promotionsService.promotions.getPromotionPageData(self.promotionId, self.promotionPageNumber)
                    .then(function (pageData) {
                        if (self.selectedPage) {
                            self.selectedPage.promotionMediaPageLayoutId = pageData.promotionMediaPageLayoutId;
                            self.selectedPage.mainCategoryId = pageData.mainCategoryId;
                            self.selectedPage.secondaryCategoriesIds = pageData.secondaryCategoriesIds;
                        }
                        else {//multiples paginas
                            self.dataForSelectedPages.promotionMediaPageLayoutId = pageData.promotionMediaPageLayoutId;
                            self.dataForSelectedPages.mainCategoryId = pageData.mainCategoryId;
                            self.dataForSelectedPages.secondaryCategoriesIds = pageData.secondaryCategoriesIds;
                        }
                   
                        newScope.isLoading = false;
                        ngDialog.close();
                    });
            }

            newScope.closeDialog = function () {
                ngDialog.close();
            }

            ngDialog.open({
                template: 'promotionsExplorerDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: newScope
            });
        }

        self.applyForSelectedPages = function () {
            var page;
            angular.forEach(self.promotion.pages, function (p) {
                if (self.selectedPages.find(function (x) { return x.id === p.id; })) {
                    p.promotionMediaPageLayoutId = self.dataForSelectedPages.promotionMediaPageLayoutId;
                    p.mainCategoryId = self.dataForSelectedPages.mainCategoryId;
                    p.secondaryCategoriesIds = self.dataForSelectedPages.secondaryCategoriesIds;
                }
            });
            self.selectedPages = [];

        }

        function init() {


            homeService.categories.getLayoutCategories()
                .then(function (response) {
                    angular.forEach(response, function (category) {
                        categories.push({
                            categoryId: category.id,
                            displayName: category.name
                        });

                    });

                    self.categoriesCaptains = categories;
                    self.categoriesSecondaries = categories;

                    promotionsService.layouts.getLayouts(false)
                        .then(function (layouts) {
                            self.layouts = layouts;

                            loadPromotion();
                        });
                });

            adminService.categories.getCategoriesWithLevels([3, 4])
                .then(function (response) {
                    self.allCategories = response;
                    angular.forEach(self.allCategories, function (x) {
                        x.displayName = x.level == 4 ? 'CAT | ' + x.name : x.name;
                    });
                });

        }

        init();
    });