
angular.module("prisma")
  .controller("CompetitorsPricesReportCtrl", function ($state, $rootScope, priceService) {
      var self = this;

      var toDate = new Date();
      toDate.setTime(toDate.getTime() + 86400000); //A partir de ma�ana
      self.toDate = toDate;

      var fromDate = new Date();
      fromDate.setTime(fromDate.getTime() - 7 * 86400000); //Ultimos 7 dias
      self.fromDate = fromDate;

      self.data = [];

      function datediff(first, second) {
          // Take the difference between the dates and divide by milliseconds per day.
          // Round to nearest whole number to deal with DST.
          return Math.round((second - first) / (1000 * 60 * 60 * 24));
      }

      self.exportToExcel = function () {

          //No se puede exportar mas de 30 dias
          if (datediff(self.fromDate, self.toDate) > 31) {
              swal("No se pueden exportar mas de 31 dias", '', "error");
          }
          else {
              swal({
                  title: 'Exportar',
                  text: "Desea exportar estos registros a un archivo excel?.",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#1AB394",
                  confirmButtonText: "Continuar",
                  cancelButtonText: "Cancelar",
                  showLoaderOnConfirm: true,
                  closeOnConfirm: false,
                  closeOnCancel: true
              },
                  function (isConfirm) {

                      if (!isConfirm) return;

                      var fromDate = convertDate(self.fromDate);
                      var toDate = convertDate(self.toDate);

                      priceService.reports.getCompetitorsPricesExcel(fromDate, toDate).then(function (url) {
                          //window.open(url);
                          var downloadFrame = document.createElement("iframe");
                          downloadFrame.setAttribute('src', url);
                          downloadFrame.setAttribute('class', "screenReaderText");
                          document.body.appendChild(downloadFrame);

                          swal("Exportados correctamente", '', "success");
                      });
                  });
          }
      }

      function convertDate(inputFormat) {
          function pad(s) { return (s < 10) ? '0' + s : s; }
          var d = new Date(inputFormat);
          return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
      }

      function init() {
          
      }

      init();

  });

