"use strict";

angular.module("prisma")
    .service("assortmentReportService", function assortmentReportService($rootScope, $q, httpService) {
        var self = this;

        self.saveTemplate = function (template) {

            return httpService.post('assortment/assortmentReport/saveTemplate', template);
        }

        self.getTemplates = function () {
            return httpService.get('assortment/assortmentReport/getTemplates');
        }

        self.deleteTemplate = function (id) {
            return httpService.post('assortment/assortmentReport/deleteTemplate', id);
        }

        self.loadReportData = function (template, useNewClusterizer) {
            return httpService.post('assortment/assortmentReport/loadReportData', template);
        }

        self.exportToExcel = function (report) {
            return httpService.post('assortment/assortmentReport/exportToExcel', report);
        }

        self.getLastExecutedReport = function () {
            return httpService.get('assortment/assortmentReport/getLastExecutedReport');
        }

        self.openReport = function (dto) {
            return httpService.post('assortment/assortmentReport/openReport', dto);
        }
        
        self.downloadRulesReport = function (ruleType) {
            return httpService.get('/assortment/assortmentReport/downloadRulesReport/' + ruleType);
        }  

        self.downloadListingSuggestionsReport = function (dto) {
            return httpService.post('/assortment/assortmentReport/downloadListingSuggestionsReport/', dto);
        } 

    });