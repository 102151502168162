angular.module("prisma")
    .controller("AssortmentOutputChileCtrl", function ($scope, $rootScope, $stateParams, $filter, assortmentService) {
        var self = this;
        self.gridData = null;


        self.exportData = function () {

            swal({
                title: "Esta seguro?",
                text: 'Esta seguro que desea exportar la informacion y pasar su estado a informado?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        var params = {
                            skipHeader: false,
                            skipFooters: false,
                            skipGroups: false,
                            allColumns: true,
                            onlySelected: false,
                            suppressQuotes: false,
                            fileName: 'ExportDatosSurtido' + (new Date()).toISOString().replace(/[^0-9]/g, "").substring(0, 12) + '.csv',
                            columnSeparator: ','
                        };

                        self.gridOptions.api.exportDataAsCsv(params);

                        assortmentService.output.changeOutputStatus().then(function () {

                            swal("Confirmado", "Se exporto correctamente", "success");
                            loadData();
                        },
                            function () {
                                swal("Error", "Ha ocurrido un error", "error");
                            });
                    }
                });
        }

        //grid config
        function setColumnDefinitions() {
            columnDefs = [
                {
                    headerName: 'Datos',
                    children: [
                        {
                            headerName: "Tipo",
                            field: "isListed",
                            width: 60,
                            suppressMenu: true,
                            suppressSorting: true,
                        },
                        {
                            headerName: "Surtido",
                            field: "clusterCode",
                            width: 180,
                            suppressMenu: true,
                            suppressSorting: true,
                        },
                        {
                            headerName: "Nombre Surtido",
                            field: "clusterName",
                            width: 220,
                            suppressMenu: true,
                            suppressSorting: true,
                        },
                        {
                            headerName: "Tienda",
                            field: "store",
                            width: 100,
                            suppressMenu: true,
                            suppressSorting: true,
                        },
                        {
                            headerName: "Categoria Jerarquia",
                            field: "subCategoryName",
                            width: 180,
                            suppressMenu: true,
                            suppressSorting: true,
                        },
                        {
                            headerName: "Categoria Nielsen",
                            field: "nielsenCategory",
                            width: 180,
                            suppressMenu: true,
                            suppressSorting: true,
                        },
                        {
                            headerName: "Codigo SAP",
                            field: "subCategoryCode",
                            width: 150,
                            suppressMenu: true,
                            suppressSorting: true,
                        },
                        {
                            headerName: "Ean",
                            field: "ean",
                            width: 165,
                            suppressMenu: true,
                            suppressSorting: true,
                        },
                        {
                            headerName: "Descripcion",
                            field: "itemName",
                            width: 255,
                            suppressMenu: true,
                            suppressSorting: true,
                        },
                        {
                            headerName: "Marca",
                            field: "itemBrand",
                            width: 105,
                            suppressMenu: true,
                            suppressSorting: true,
                        },
                        {
                            headerName: "Fabricante",
                            field: "itemManufacturer",
                            width: 105,
                            suppressMenu: true,
                            suppressSorting: true,
                        },
                       ]
                }]
        }
        function setGridOptions() {

            setColumnDefinitions();

            self.gridOptions = {
                columnDefs: columnDefs,
                enableColResize: true,
                enableSorting: true,
                enableFilter: true,
                groupHideGroupColumns: true,
                groupUseEntireRow: false,
                groupSelectsChildren: false,
                rowHeight: 30,
                rowSelection: "multiple",
                rowDeselection: true,
                showToolPanel: false,// window.innerWidth > 1500,
                suppressRowClickSelection: true,
                suppressCellSelection: true,
                singleClickEdit: true,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                suppressColumnMoveAnimation: $rootScope.suppressColumnMoveAnimation(),
                enableRangeSelection: false,
                angularCompileRows: true,
                angularCompileHeaders: true,
            }
        }

        function loadData() {
            assortmentService.output.getEndosedDataCencosudChile().then(function (data) {
                self.gridData = data;

                self.gridOptions.api.setRowData(self.gridData);
                self.gridOptions.api.refreshView();
            });
        }
        function init() {
            loadData();

            setGridOptions();
        }

        init();
    });
