"use strict";

angular.module("prisma")
    .service("catColor", function () {
        var self = this;

        var palette = {};

        self.getSubcategoryColor = function (catColor, subCat) {
            var color = tinycolor(catColor);
            var colorName = color.toHexString();
            var paletteCatColor = palette[colorName];
            if (!paletteCatColor) {
                palette[colorName] = { byName: {}, all: [] };
                paletteCatColor = palette[colorName];
            }
            if (subCat) {
                if (!paletteCatColor.byName[subCat]) {

                    var newColor = null;
                    if (paletteCatColor.all.length === 0) {
                        newColor = color.lighten(2).toHexString();
                    } else {
                        var lastColorName = paletteCatColor.all[paletteCatColor.all.length - 1];
                        newColor = tinycolor(lastColorName).lighten(2).toHexString();
                    }

                    palette[colorName].byName[subCat] = newColor;
                    palette[colorName].all.push(newColor);
                }
            }

            return paletteCatColor.byName[subCat];
        }
    });



