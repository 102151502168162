'use strinct';
(function () {
    angular.module('prisma')
        .directive('planogramLevelPeg', ['$log', planogramLevelPegDirective])
        .controller('planogramLevelPegController', ['$log', '$element', 'DnDService', 'SvgService', planogramLevelPegController]);


    function planogramLevelPegDirective($log) {
        // $log.debug('planogramLevelPegDirective::ctor');

        function planogramLevelPegDirectiveLink($scope, $element, $attr) {
            $element.addClass('peg');
        }

        return {
            link: planogramLevelPegDirectiveLink,
            restrict: 'A',
            selector: '[planogram-level-peg]',
            templateUrl: '/app/spaces/planogram/planogram-level-peg.view.html',
            scope: {
                model: '<',
                last: '<'
            },
            require: {
                planogram: '^^planogram',
                column: '^^planogramColumn',
                module: '^^planogramModulePeg'
            },
            controller: 'planogramLevelPegController',
            controllerAs: '$ctrl',
            bindToController: true
        }
    }

    function planogramLevelPegController($log, $element, dnDService, svgService) {
        // $log.debug('planogramLevelPegController::ctor');
        var self = this;

        // self.itemsGroup = [];
        self.isOver = false;
        self.dropIndicatorX = 0;
        self.dropIndicatorY = 0;
        self.overTarget = null;

        self.$onInit = function $onInit() {
            // $log.debug('planogramLevelPegController::$onInit');
            // self.unregister = self.module.register(self);
        };

        self.$onDestroy = function () {
            // $log.debug('planogramLevelPegController::$onDestroy');
            // self.unregister();
            delete self.itemsGroup;
        };

        self.register = function (itemGroup) {
            //$log.debug('planogramLevelPegController::register %o', itemGroup);
            self.itemsGroup.push(level);
            return function unregister() {
                //$log.debug('planogramLevelPegController::unregister %o', itemGroup);
                var id = itemGroup.getId();
                self.itemsGroup = self.itemsGroup.filter(function (item) {
                    return item.getId() !== id;
                });
            }
        };

        self.getId = function getId() {
            return self.model.id;
        };

        self.getModel = function getModel() {
            // return angular.copy(self.model);
            return self.model.$$original;
        };

        /**
         * Devuelve verdadero si se tiene que mostrar el indicador de drop.
         * @public
         * @returns Verdadero si se tiene que mostrar el indicador de drop, falso en caso contrario.
         */
        self.showDropIndicator = function showDropIndicator() {
            return self.isOver;
            // && DnDService.isOnDrag();
        };

        /**
         * Configura el itemGroup sobre el cual se esta pasnado el mouse para mostrar el indicador de posici�n.
         * @param {any} data itemGroup
         */
        self.setOver = function setOver(data) {
            // $log.debug('planogramLevelPegDirective::setOver\n\tdata: %o', data);
            self.isOver = true;
            if (angular.isObject(data)
                && angular.isObject(data.model)) {
                self.dropIndicatorX = data.model.x;
                if (data.place === 'after') {
                    self.dropIndicatorX += data.model.width;
                }
            } else {
                self.dropIndicatorX = 0;
            }

            self.overTarget = data.model;
        }

        self.removeOver = function removeOver() {
            // $log.debug('planogramLevelPegDirective::removeOver');
            self.isOver = false;
            self.dropIndicatorX = 0;
            self.overTarget = null;
        }

        self.getModule = function getModule() {
            return self.module;
        }

        self.onClickHandler = function onClickHandler($event) {
            $event.preventDefault();
            $event.stopPropagation();

            // $log.debug('planogramLevelPegController::onClickHandler %o', $event);
            if (!dnDService.isOnDrag()) {
                var $svg = self.planogram.getSvg();
                var point = svgService.eventToPoint($svg, $element, $event);
                var place = svgService.getPlace(point);
                self.planogram.raiseOnClick({
                    $event: $event,
                    planogram: self.planogram.getModel(),
                    column: self.column.getModel(),
                    module: self.module.getModel(),
                    level: self.getModel(),
                    point: point,
                    place: place
                });
            }
        };

        function onDragOverAndEnterHandler($event) {
            // $log.debug('planogramLevelPegDirective::onDragOvenAndEnterHandler %o', $event);
            if (dnDService.isOnDrag()) {
                var $svg = self.planogram.getSvg();
                var point = svgService.eventToPoint($svg, $element, $event);
                var place = svgService.getPlace(point);
                var model = null;
                var item = null;
                var len = self.model.itemsGroup.length;
                if (len > 0) {
                    model = self.model.itemsGroup[len - 1];
                    item = model.$$original;
                    place = 'after';
                }

                self.setOver({
                    $event: $event,
                    model: model,
                    item: item,
                    point: point,
                    place: place
                });
            }
        };

        self.onDragEnterHandler = onDragOverAndEnterHandler;
        self.onDragOverHandler = onDragOverAndEnterHandler;

        self.onDragLeaveHandler = function onDragLeaveHandler($event) {
            // $log.debug('planogramLevelPegDirective::onDragLeaveHandler %o', $event);
            if (dnDService.isOnDrag()) {
                self.removeOver();
            }
        };

        self.onDropHandler = function onDropHandler($event) {
            //$log.debug('planogramLevelPegController::onDropHandler %o', $event);
            if (dnDService.isOnDrag()) {
                var $svg = self.planogram.getSvg();
                var point = svgService.eventToPoint($svg, $element, $event);
                var ocupied = self.model.itemsGroup.reduce(function (acumulator, item) {
                    return acumulator + item.width;
                }, 0);
                var place = 'after'; // svgService.getPlace(point);
                self.planogram.raiseOnDrop({
                    $event: $event,
                    planogram: self.planogram.getModel(),
                    column: self.column.getModel(),
                    module: self.module.getModel(),
                    level: self.getModel(),
                    point: point,
                    place: place
                });
                dnDService.setData(null);
                dnDService.setDirective(null);
                dnDService.setOffset(null);
                self.removeOver();
            }
        };
    }
})();