"use strict";

angular.module("prisma")
  .controller("NotificationsCtrl", function ($scope, httpService) {
      var self = this;
      self.section = "Inicio";
      self.name = "Notificaciones";

      $scope.unsecured = function () {
          httpService.get('test/unsecured').then(function (data) {
              $scope.result = data;
          });
      }
      $scope.authenticated = function () {
          httpService.get('test/authenticated').then(function (data) {
              $scope.result = data;
          });
      }
      $scope.roleAdmin = function () {
          httpService.get('test/role-admin').then(function (data) {
              $scope.result = data;
          });
      }
      $scope.roleRoot = function () {
          httpService.get('test/role-root').then(function (data) {
              $scope.result = data;
          });
      }
      $scope.claim = function () {
          httpService.get('test/claim').then(function (data) {
              $scope.result = data;
          });
      }

      $scope.info = function () {
          httpService.get('test/info').then(function (data) {
              $scope.userClaims = data;
          });
      }
      
      $scope.exception = function () {
          httpService.get('test/exception').then(function (data) {
              
          });
      }
  });
