angular.module("prisma")
    .controller("PriceRoundingsCtrl", function ($scope, priceService, companyJson, $translate) {
        var self = this;

        self.setupData = companyJson;

        self.priceRoundings = [];

        self.priceTypes = [];

        self.priceRoundingTypes = [];

        self.getDecimalsName = function (decimals) {
            return self.decimalNames.filter(function (decimal) {
                return (decimal.decimals === decimals);
            })[0].name;
        }

        self.isLoading = true;

        self.roundNumber = function (numberToRound) {
            if (isNaN(numberToRound)) {
                alert(translations.YouMustEnterValidNumber);
            }
            else {
                priceService.priceRoundings.getRoundedNumber(self.priceTypeToTest, numberToRound).then(function (roundedNumber) {
                    self.roundedNumber = roundedNumber;
                });
            }


        }

        self.deletePriceRounding = function (id) {

            swal({
                title: translations.DeleteTheRuleSwal,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: translations.DeleteSwal,
                cancelButtonText: translations.Cancel,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        priceService.priceRoundings.deletePriceRounding(id)
                            .then(function (isOk) {
                                for (var i = 0; i < self.priceRoundings.length; i++) {
                                    if (self.priceRoundings[i].id == id) {
                                        self.priceRoundings.splice([i], 1);
                                    }
                                }
                                swal(translations.ErasedRuleSwal, translations.HasBeenErasedCorrectlySwal, 'success');

                            }, function (status) {
                                if (status == 502) {
                                    swal(translations.TheProcessContinues, translations.TheProcessOfContinuousDeletionSwal)
                                }
                                else {
                                    swal(translations.ErrorTitleSwal, translations.ErrorWhenDeletingTheRule, "error");
                                }
                            });
                    }

                });
        }

        function loadData() {

            priceService.priceRoundings.getPriceRoundings()
                .then(function (priceRoundings) {
                    self.priceRoundings = priceRoundings;
                    self.isLoading = false;
                });
        }

        var translations = null;

        function init() {

            priceService.getRoundingDecimalNames().then(
                function (decimalNames) {
                    self.decimalNames = decimalNames;
                });

            priceService.getPriceTypes().then(
                function (priceTypes) {
                    self.priceTypes = priceTypes;
                });

            priceService.getPriceRoundingTypes().then(
                function (priceRoundingTypes) {
                    self.priceRoundingTypes = priceRoundingTypes;
                });


            $translate(["YouMustEnterValidNumber", "DeleteTheRuleSwal", "DeleteSwal", "Cancel", "ErasedRuleSwal", "HasBeenErasedCorrectlySwal", "TheProcessContinues", "TheProcessOfContinuousDeletionSwal", "ErrorTitleSwal", "ErrorWhenDeletingTheRule"])
                .then(function (all) {
                    translations = all;
                });

            loadData();
        }

        init();
    });