angular.module("prisma")
  .controller("AbTestingReportsCtrl", function ( $stateParams, priceService) {

      var self = this;

      self.abTestingId = $stateParams.abTestingId;
      self.selectedType = 'spline';



      self.updateCharts = function () {

          if (self.salesChartData == null)
              return;
          self.salesChart = c3.generate(getChartConfig('#bechmarkSalesChart', self.salesChartData));

          if (self.marginChartData == null)
              return;
          self.marginChart = c3.generate(getChartConfig('#bechmarkMarginChart', self.marginChartData));

          if (self.unitsChartData == null)
              return;
          self.unitsChart = c3.generate(getChartConfig('#bechmarkUnitsChart', self.unitsChartData));
      }

      function getChartConfig(chartId, data) {

          return {
              bindto: chartId,
              data: {
                  x: 'x',
                  columns: data,
                  type: 'spline',
              },
              axis: {
                  x: {
                      type: 'timeseries',
                      tick: {
                          format: '%Y-%m-%d',
                      }
                  },
                  y: {
                      tick: {
                          format: d3.format("s")
                      }
                  }
              },
              tooltip: {
                  format: {
                      title: function (d) {
                          var toFormat = '%e %B %Y';
                          if (self.timerange == 'year')
                              toFormat = '%e %B %Y';

                          var format = d3.time.format(toFormat);
                          return format(d);
                      },
                      value: function (value, ratio, id) {
                          var format = d3.format(',.3s');
                          return format(value);
                      }
                  }
              }
          }
      }

      function parseChartData(chartData, chart) {
          var data = [];
          var names = [];
          var dates = ['x'];
          var series = [];

          //obtengo names
          var name = '';
          angular.forEach(chartData, function (d) {
              if (d.name !== name) {
                  names.push(d.name);
                  name = d.name;
              }
          });


          //obtengo dates
          var date = '';
          name = chartData[0].name;
          angular.forEach(chartData, function (d) {
              var currDate = d.transactionYear + '-' + d.transactionMonth + '-' + d.transactionDay;
              if (d.name == name) {
                  if (currDate !== date) {
                      dates.push(currDate);
                      date = currDate;
                  }
              }
          });

          //armo las series
          var serie = [];
          name = '';
          angular.forEach(names, function (n) {
              angular.forEach(chartData, function (d) {
                  if (d.name !== name) {
                      if (serie.length > 0)
                          series.push(serie);

                      serie = [];
                      serie.push(d.name);
                      serie.push(d[chart]);
                      name = d.name;
                  }
                  else {
                      serie.push(d[chart]);
                  }
              });
          });

          data.push(dates);
          angular.forEach(series, function (s) {
              data.push(s);
          });

          return data;
      }


      self.updateChartType = function (type) {
          self.selectedType = type;
          self.salesChart.transform(type);
          self.marginChart.transform(type);
          self.unitsChart.transform(type);
      }


      function loadData() {
          console.log($stateParams);

          priceService.abTesting.getDailySales(self.abTestingId).then(function (chartData) {
              console.log(chartData);

              if (chartData && chartData.length > 0) {
                  self.salesChartData = parseChartData(chartData, 'sales');
                  self.marginChartData = parseChartData(chartData, 'margin');
                  self.unitsChartData = parseChartData(chartData, 'units');

                  self.updateCharts();
              }

          }, function (err) {
              console.error(err);
          })
      }


      function init() {

          //load data
          loadData();
      }


      init();


  })