angular.module("prisma")
 .controller("editPromotionRuleCtrl", function ($scope, $state, $stateParams, promotionsService, ngDialog) {

     var self = this;

     self.isLoading = true;
     self.ruleParams = null;
     self.rule = null;
     self.selectedCategoriesGroup = null;
     self.selectedCategories = [];
     self.competitors = [];

     self.getSelectedCategoriesNames = function () {
         return self.selectedCategories.map(function (x) { return x.categoryName }).join('|');
     }

     self.openCategoriesDialog = function () {

         var newScope = $scope.$new();
         var lastCategoriesGroup = self.selectedCategoriesGroup;
         var lastCategories = self.selectedCategories;

         newScope.categoriesGroups = self.ruleParams.categories.filter(function (x) { return x.level == 3 });

         if (self.selectedCategoriesGroup)
             newScope.categories = self.ruleParams.categories.filter(function (x) { return x.level == 4 && x.parentId == self.selectedCategoriesGroup.categoryId });
         else
             newScope.categories = self.ruleParams.categories.filter(function (x) { return x.level == 4 });

         newScope.setCategories = function () {
             newScope.categories = self.ruleParams.categories.filter(function (x) { return x.level == 4 && x.parentId == self.selectedCategoriesGroup.categoryId });
         } 

         newScope.accept = function () {
             ngDialog.close();
         }

         newScope.cancel = function () {  
             self.selectedCategoriesGroup = lastCategoriesGroup;
             self.selectedCategories = lastCategories;
             ngDialog.close();
         }
      

         ngDialog.open({
             template: 'categoriesDialog',
             className: 'ngdialog-theme-default ngdialog-theme-custom',
             scope: newScope
         });
     }

     self.canSave = function () {
         var can = true;

         if (!self.rule) {
             can = false;
         }
         else {
             if (self.rule.id != 0)
                 can = false;
             if (!self.selectedCategoriesGroup)
                 can = false;
             if (!self.rule.comparesTo)
                 can = false;
             if (!self.rule.comparisonType)
                 can = false;
             if (!self.rule.percentage && self.rule.comparisonType != "Equals")
                 can = false;
             if (self.rule.comparesTo == 'SpecificCompetitor' && !self.rule.competitorId)
                 can = false;
             if (self.rule.isExternal && !self.rule.competitorGroupId)
                 can = false;
             if (self.rule.comparesTo == 'CompetitionMode' && (!self.rule.modeRepeatTimes || !self.rule.modeType))
                 can = false;
             if (!self.rule.brandId)
                 can = false;
             if (self.rule.comparisonType == "Equals")
                 self.rule.percentage = 0;
         }

         return can;
     }

     self.save = function () {
         //seteo el total de categorias al dto antes de guardar
         if (self.selectedCategories.length > 0)
             self.rule.categoriesIds = self.selectedCategories.map(function (x) { return x.categoryId });
         else
             self.rule.categoriesIds = self.ruleParams.categories.filter(function (x) { return x.level == 4 && x.parentId == self.selectedCategoriesGroup.categoryId }).map(function (x) { return x.categoryId });

         swal({
             title: 'Guardar Regla',
             text: 'Se generará una regla por cada categoría.',
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: "Aceptar",
             cancelButtonText: "Cancelar",
             showLoaderOnConfirm: true,
             closeOnConfirm: true,
             closeOnCancel: true
         }, function (isConfirm) {
             if (isConfirm) {
                 //si no completo los campos significa todos
                 if (self.rule.mediaTypes.length == 0)
                     self.rule.mediaTypes = self.ruleParams.mediaTypes;
                 if (self.rule.pageTypes.length == 0)
                     self.rule.pageTypes = self.ruleParams.pageTypes;
                 if (self.rule.itemTypes.length == 0)
                     self.rule.itemTypes = self.ruleParams.itemTypes;

                 promotionsService.rules.getExistingRules(self.rule).then(function (existingRules) {
                     if (existingRules && existingRules.length > 0) {

                       swal('No se puede crear la Regla'
                                 , ian.format('Está intentando crear una regla que ya existe.')
                                 , 'error');                       

                     }
                     else {
                            self.saving = true;
                            doSave();
                             
                         
                     }
                 });
             }
         });
        
     }

     self.onChangeRuleType = function () {
         self.rule.compareTo = null;
         self.rule.percentage = null;
         self.rule.comparisonType = null;
         self.rule.competitor = null;
         self.rule.competitorId = null;
         self.rule.competitorGroup = null;
         self.rule.competitorGroupId = null;
         self.rule.modeRepeatTimes = null;
     }

     self.getCompareToDescription = function (compareTo) {
         //internas
         if (compareTo == 'NetworkMinimal') return 'Minimo Cadena';
         if (compareTo == 'Normal') return 'Normal ZB';
         if (compareTo == 'Active') return 'Vigente ZB';
         if (compareTo == 'NormalAverage') return 'Promedio Normal';
         if (compareTo == 'ActiveAverage') return 'Promedio Vigente';

         //externas
         if (compareTo == 'CompetitionLowest') return 'Competencia mas baja';
         if (compareTo == 'CompetitionAverage') return 'Competencia Promedio';
         if (compareTo == 'SpecificCompetitor') return 'Competidor Especifico';
         if (compareTo == 'CompetitionMode') return 'Moda';
     }
     
     self.getModeTypeDescription = function (type) {
         switch (type) {
             case 'HighestMode': return 'Precio mas Repetido';
                 break;
             case 'MinimumPrice': return 'Precio mas Bajo';
                 break;
             case 'AveragePrice': return 'Precio Promedio';
                 break;
             case 'MaximumPrice': return 'Precio mas Alto';
                 break;
         }

     }

     //$scope.$watch('ct.rule.category', function (newVal, oldVal) {
     //    if (newVal) {
     //        self.rule.category = newVal;
     //        self.rule.categoryId = newVal.categoryId;
     //        self.rule.categoryName = newVal.categoryName;
     //    }
     //});

     $scope.$watch('ct.rule.competitor', function (newVal, oldVal) {
         if (newVal) {
             self.rule.competitor = newVal;
             self.rule.competitorId = newVal.id;
         }
     });
     $scope.$watch('ct.rule.competitorGroup', function (newVal, oldVal) {
         if (newVal) {
             self.rule.competitorGroup = newVal;
             self.rule.competitorGroupId = newVal.id;
             self.competitors = self.ruleParams.competitors.filter(function (c) {
                 return c.competitorsGroupsIds.includes(newVal.id);
             });
         }
     });

     self.mediaTypeOnChange = function () {
         self.mediaTypes = self.rule.mediaTypes.join(',');
     }
     self.pageTypeOnChange = function () {
         self.pageTypes = self.rule.pageTypes.join(',');
     }
     self.itemTypeOnChange = function () {
         self.itemTypes = self.rule.itemTypes.join(',');
     }

     self.cleanInput = function (){
         self.rule.percentage = null;
     }

     function doSave() {
         promotionsService.rules.saveRule(self.rule).then(function (savedRule) {
             swal('Regla Guardada', 'La regla fue guardada con exito.', 'success');
             self.saving = false;
             $state.go('promotions.rules');
         });
     }

     function init() {
         promotionsService.rules.getRuleParams().then(function (ruleParams) {
             self.ruleParams = ruleParams;
             self.competitors = ruleParams.competitors;

             if ($stateParams.promotionRuleId > 0) {
                 promotionsService.rules.getRule($stateParams.promotionRuleId).then(function (rule) {

                     promotionsService.rules.extendRules([rule]);

                     self.rule = rule;

                     self.mediaTypes = rule.mediaTypes.join(',');
                     self.pageTypes = rule.pageTypes.join(',');
                     self.itemTypes = rule.itemTypes.join(',');

                     //select category
                     angular.forEach(self.ruleParams.categories, function (cat) {
                         if (cat.categoryId == self.rule.categoryId)
                             self.rule.category = cat;
                     });

                     //select competitor
                     if (self.rule.competitorId) {
                         angular.forEach(self.ruleParams.competitors, function (comp) {
                             if (comp.id == self.rule.competitorId)
                                 self.rule.competitor = comp;
                         });
                     }
                     //select competitorGroup
                     if (self.rule.competitorGroupId) {
                         angular.forEach(self.ruleParams.competitorGroups, function (g) {
                             if (g.id == self.rule.competitorGroupId)
                                 self.rule.competitorGroup = g;
                         });
                     }

                     //select modeType
                     self.rule.modeType = self.rule.modeTypeDescription;
                    

                     self.isLoading = false;
                 });
             }
             else {
                 self.isLoading = false;

                 self.rule = {
                     id: 0,
                     isExternal: false,
                     mediaTypes: [],
                     pageTypes: [],
                     itemTypes: []
                 };


                 promotionsService.rules.extendRules([self.rule]);

             }


         });


     }

     init();

 });