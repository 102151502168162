angular.module("prisma")
    .controller("spacesTicketsTypesCtrl", function ($scope, $state, spaceService, $translate) {

   //Varibles
   var self = this;
   var pageLoaded = false;

   var TICKET_TYPES = {
       TODOS: 0,
       PLANOGRAM: 1,
       ITEM: 2
   }

   self.ticketTypes = [];
   self.search = "";
   self.order = "";


   //Load Data
   function loadTypesTickets() {

     spaceService.tickets.getTicketTypes()
       .then(function (ticketTypes) {
         console.log('ticketTypes', ticketTypes);
         self.ticketTypes = ticketTypes;
         pageLoaded = true;
       })
   }

   self.addNew = function () {
     $state.go('spaces.editTicketType', {
       ticketTypeId: 0,
       //name: '',
       //type: null,
       //needsDescription: false
     });
   }

   self.editTicketType = function (ticketType) {
     //console.log('ticketType', ticketType)
     $state.go('spaces.editTicketType', {
       ticketTypeId: ticketType.id,
       //name: ticketType.name,
       //type: ticketType.type,
       //needsDescription: ticketType.needsDescription
     });
   }

   self.typesValue = function (numType) {
       return numType ? numType == 1 ? translations.Planogram : "Item" : translations.All
   }

   var translations = null;

   function init() {

       $translate(["Planogram", "All"])
           .then(function (all) {
               translations = all;
           });

     loadTypesTickets();
   }

   init();
 });