angular.module("prisma")
    .filter('onlyNewsCompetitors', function () {
        return function (competitors) {
            var out = [];

            angular.forEach(competitors, function (competitor) {
                if (competitor.code == competitor.externalCode)
                    out.push(competitor);
            });
            return out;
        };
    })
    .filter('onlyWithoutGroup', function () {
        return function (competitors, noGroups) {
            var out = [];
            //console.log('competitors', competitors);
            //console.log('self.noGroups', noGroups);
            angular.forEach(competitors, function (competitor) {
                angular.forEach(noGroups, function (noGroup) {
                    //console.log('noGroup.id', noGroup.id, 'competitor.id', competitor.id);
                    if (noGroup.id == competitor.id) {
                        out.push(competitor);
                    }
                });
            });
            return out;
        };
    })
    .controller("CompetitorsCtrl", function ($state, $scope, $timeout, $rootScope, priceService, adminService, ngDialog, $filter, $translate, companyJson) {
        var self = this;

        self.selectedCompetitorGrp = null;

        self.groupOfCompetitorsLst = [];

        self.competitorGroup = {};

        self.competitorsWithoutGroup = [];

        self.competitorsLst = [];

        self.competitor = {};

        self.priceLists = [];

        self.lstBrands = [];

        self.lstPlaces = [];

        self.lstMarkets = [];

        self.showMarkets = false;

        self.search = "";

        self.isBusy = false;

        self.isLoading = true;

        self.onlyNews = false;

        self.singlePriceLists = companyJson.Competitors.SinglePriceLists == true ? true : false;

        self.onlyNewsChange = function () {
            runFilters();
        }

        self.onlyWithoutGroup = false;

        self.onlyWithoutGroupChange = function () {
            runFilters();
        }

        //Funciones
        self.showGroupEditor = function (groupData) {
            self.isNew = groupData ? false : true;

            // Crea una copia del grupo para desconectarlo de la vista.
            groupData = angular.copy(groupData || {});
            self.competitorGroup = groupData;

            if (self.competitorGroup.parentId)
                self.selectedCompetitorGrp = self.getParent(self.competitorGroup.parentId);
            else {
                self.selectedCompetitorGrp = null;
            }

            $scope.groupOfCompetitorsLst = self.groupOfCompetitorsLst;

            ngDialog.open({
                template: 'groupEditorDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                data: groupData,
                scope: $scope
            });
        }

        // 2018-04-28 - DAE - Se cambio el nombre a la funci�n isNew por isNewFn 
        // para evitar la colisi�n con la propiedad isNew del controlador.
        self.isNewFn = function (competitor) {
            return competitor.code === competitor.externalCode || !competitor.code;
        }

        function loadMarkets() {
            adminService.priceLists.getMarkets().then(function (markets) {
                self.lstMarkets = markets;
                self.showMarkets = true;
            });
        }

        self.getParent = function (id) {
            for (var i = 0; i < self.groupOfCompetitorsLst.length; i++) {
                if (self.groupOfCompetitorsLst[i].id === id) {
                    return self.groupOfCompetitorsLst[i];
                }
            }
        }

        self.showCompetitorEditor = function (competitorData) {
            self.isNew = competitorData ? false : true;

            // Crea una copia del competidor para desconectarlo de la vista.
            competitorData = angular.copy(competitorData || {});
            self.competitor = competitorData;
            $scope.priceLists = self.priceLists;

            ngDialog.open({
                template: 'competitor',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                data: competitorData,
                scope: $scope
            });
        }

        self.competitorGroups = [];

        self.getTitle = function () {
            var rv;
            if (!self.competitor.code)
                rv = translations.Competitor;
            else
                rv = translations.EditCompetitor;

            return rv;
        }

        self.saveCompetitor = function () {

            if (!self.competitor.code || !self.competitor.name) { // || !self.competitor.brandId (NO TODOS TIENEN LOS BRANDS, NO DEBER�A SER OBLIGATORIO)
                swal(translations.ValidationSwalTitle, translations.ValidationSwalSubtitle, "error");
            }
            else {
                priceService.competitors.saveCompetitor(self.competitor)
                    .then(function (competitor) {
                        if (self.isNew) {
                            self.competitorsLst.push(angular.copy(competitor));
                        } else {
                            // TODO: Actualizar competidores.
                            var idx = competitorIndexOf(competitor.id);
                            if (idx > -1) {
                                self.competitorsLst[idx] = competitor;
                            }

                            // Actualiza los competidores en los grupos.
                            var len1 = self.groupOfCompetitorsLst.length;
                            for (var i1 = 0; i1 < len1; i1++) {
                                var competitorGroup = self.groupOfCompetitorsLst[i1];
                                var len2 = competitorGroup.children.length;
                                for (var i2 = 0; i2 < len2; i2++) {
                                    var competitorGroupChild = competitorGroup.children[i2];
                                    var len3 = competitorGroupChild.children.length;
                                    for (var i3 = 0; i3 < len3; i3++) {
                                        if (competitorGroupChild.children[i3].id === competitor.id) {
                                            competitorGroupChild.children[i3] = competitor;
                                        }
                                    }
                                }
                            }
                        }
                    }).finally(function () {
                        ngDialog.close();
                    });
            }
        }

        self.saveCompetitorGroup = function () {
            if (self.isNew) {
                if (!self.competitorGroup.code || !self.competitorGroup.name) {
                    swal(translations.ValidationSwalTitle, translations.SaveCompetitorValidationSwalSubtitle, "error");
                } else {
                    if (self.selectedCompetitorGrp == null) {//no tiene padre , guardo normal

                        priceService.competitors.saveCompetitorsTree(self.competitorGroup)
                            .then(function (competitorGroup) {
                                //inserto como un grupo
                                self.groupOfCompetitorsLst.push(angular.copy(competitorGroup));
                            }).finally(function () {
                                ngDialog.close();
                            });
                    } else { // quiero guardar un hijo de self.selectedCompetitorGrp
                        self.competitorGroup.parentId = self.selectedCompetitorGrp.id;
                        priceService.competitors.saveCompetitorsTree(self.competitorGroup)
                            .then(function(competitorGroup) {
                                // Busca el parent.
                                var idx = competitorGroupIndexOf(competitorGroup.parentId);
                                if (idx > -1) {
                                    // inserto como un subgrupo
                                    self.groupOfCompetitorsLst[idx].children.push(angular.copy(competitorGroup));
                                }
                            }).finally(function() {
                                ngDialog.close();
                            });
                    }
                }
            } else {// es edicion
                if (self.selectedCompetitorGrp == null) {//es padre
                    priceService.competitors.saveCompetitorsTree(self.competitorGroup)
                        .then(function (competitorGroup) {
                            // Busca el elemento.
                            var idx = competitorGroupIndexOf(competitorGroup.id);
                            if (idx > -1) {
                                self.groupOfCompetitorsLst[idx] = competitorGroup;
                            }
                        }).finally(function () {
                            ngDialog.close();
                        });
                }
                else {//es children
                    self.competitorGroup.parentId = self.selectedCompetitorGrp.id;
                    priceService.competitors.saveCompetitorsTree(self.competitorGroup)
                        .then(function (competitorGroup) {
                            // Busca el elemento.
                            var idx = competitorGroupIndexOf(competitorGroup.parentId);
                            if (idx > -1) {
                                var parent = self.groupOfCompetitorsLst[idx];
                                var idx2 = competitorGroupChildrenIndexOf(parent.children, competitorGroup.id);
                                if (idx2 > -1) {
                                    parent.children[idx2] = competitorGroup;
                                }
                            }
                        }).finally(function () {
                            ngDialog.close();
                        });
                }
            } // TODO faltaria hacer que un grupo se mueva a otro grupo sin necesidad de recargar la pagina 
        }

        /**
         * Devuelve el indice del competidor dentro del array.
         * @param {int} id Identificador del competidor buscado.
         */
        function competitorIndexOf(id) {
            var ret;
            var i = 0;
            var len = self.competitorsLst.length;
            while (i < len
                && self.competitorsLst[i].id !== id) {
                i++;
            }

            if (i < len && self.competitorsLst[i].id === id) {
                ret = i;
            } else {
                ret = -1;
            }

            return ret;
        }


        /**
         * Devuelve el indice del grupo competidor dentro del array.
         * @param {int} id Identificador del grupo competidor buscado.
         */
        function competitorGroupIndexOf(id) {
            var ret;
            var i = 0;
            var len = self.groupOfCompetitorsLst.length;
            while (i < len
                && self.groupOfCompetitorsLst[i].id !== id) {
                i++;
            }

            if (i < len && self.groupOfCompetitorsLst[i].id === id) {
                ret = i;
            } else {
                ret = -1;
            }

            return ret;
        }

        function competitorGroupChildrenIndexOf(children, id) {
            var ret;
            var i = 0;
            var len = children.length;
            while (i < len
                && children[i].id !== id) {
                i++;
            }

            if (i < len && children[i].id === id) {
                ret = i;
            } else {
                ret = -1;
            }

            return ret;
        }

        self.saveCompetitorTreeRelations = function () {
            self.isBusy = true;
            priceService.competitors.saveCompetitorsTreeRelations(self.groupOfCompetitorsLstOriginal)
                .then(function (isOk) {
                    swal(translations.SaveCompetitorTreeRelationsSwal, '', 'success');
                    loadCompetitorsWithoutGroups()
                    self.isBusy = false;
                }, function (status) {
                    if (status == 502) {
                        swal(translations.TheProcessContinues, translations.TheProcessOfContinuousStorageSwal);
                        self.isBusy = false;
                    }
                    else {
                        swal(translations.ErrorTitleSwal, translations.ThereWasAnErrorInSavingRelationshipsSwal, "error");
                        self.isBusy = false;
                    }
                });

        }

        self.deleteCompetitor = function (id) {

            swal({
                title: translations.DisableCompetitorSwalTitle,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: translations.ToDisable,
                cancelButtonText: translations.Cancel,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        priceService.competitors.deleteCompetitor(id)
                            .then(function (isOk) {
                                var pos = null
                                for (i = 0; i < self.competitorsLst.length; i++) {
                                    if (self.competitorsLst[i].id == id) {
                                        pos = i;
                                        break;
                                    }
                                }

                                //self.competitorsLst.splice(pos, 1);
                                swal(translations.CompetitorDisabledSwalTitle, translations.CompetitorDisabledSwalSubtitle, 'success');

                            }, function (status) {
                                if (status == 502) {
                                    swal(translations.TheProcessContinues, translations.TheProcessOfDisablingContinuesSwal)
                                }
                                else {
                                    swal(translations.ErrorTitleSwal, translations.ThereWasAnErrorDisablingCompetitorSwal, "error");
                                }
                            });

                    }

                });
        }

        self.itemDroppedInCompetitorsList = function (index, item, external, type) {
            return false;
        }

        self.itemDroppedInCompetitorGroupList = function (index, item, external, type, subgroup) {
            console.log('index', index);
            console.log('item', item);
            console.log('external', external);
            console.log('type', type);
            console.log('subgroup', subgroup);

            var isValid = true;

            //agrego esta propiedad para el dto tree
            item.competitorId = item.id;

            //si ya existe en el grupo aborto el drop
            angular.forEach(subgroup.children, function (child) {
                if (child.competitorId == item.competitorId) {
                    swal(translations.TheCompetitorExistsInTheGroupSwalTitle, translations.TheCompetitorExistsInTheGroupSwalSubtitle, 'warning');
                    isValid = false;
                }
            });



            if (!isValid)
                return false;
            else {
                var added = false;
                for (var i = 0; i < self.groupOfCompetitorsLstOriginal.length && !added; i++) {
                    var root = self.groupOfCompetitorsLstOriginal[i];
                    //console.log('root', root);
                    for (var j = 0; j < root.children.length && !added; j++) {
                        var subgroupOriginal = root.children[j];
                        //console.log('subgroupOriginal', subgroupOriginal);
                        if (subgroupOriginal.competitorGroupId == subgroup.competitorGroupId) {
                            subgroupOriginal.children.push(item);
                            added = true;
                        }
                    }
                }

                return item;
            }
        };

        self.removeCompetitorFromGroup = function (competitorName, group, index, competitorId) {
            swal({
                title: translations.YouAreSureToRemoveTheCompetitorSwal + competitorName + translations.FromTheGroupSwal + group.name + '?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: translations.RemoveSwal,
                cancelButtonText: translations.Cancel,
                showLoaderOnConfirm: true,
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        var deleted = false;

                        for (var i = 0; i < self.groupOfCompetitorsLstOriginal.length && !deleted; i++) {
                            var root = self.groupOfCompetitorsLstOriginal[i];
                            for (var j = 0; j < root.children.length && !deleted; j++) {
                                var subgroup = root.children[j];
                                for (var z = 0; z < subgroup.children.length && !deleted; z++) {
                                    var competitor = subgroup.children[z];
                                    if (competitor.id == competitorId && subgroup.code == group.code) {
                                        subgroup.children.splice(z, 1);
                                        deleted = true;
                                    }
                                }
                            }
                        }

                        group.children.splice(index, 1);
                        $scope.$apply();
                    }

                });
        }


        self.filterCompetitors = function (items) {
            self.competitorsLst = items;
            self.competitorsLstOriginal = angular.copy(items);
            //console.log('self.competitorsLst', self.competitorsLst);

            self.groupOfCompetitorsLst = angular.copy(self.groupOfCompetitorsLstOriginal);
            filterNodes(self.groupOfCompetitorsLstOriginal, self.groupOfCompetitorsLst, self.competitorsLst);
        }

        //source y dest tienen que ser copias uno del otro
        function filterNodes(source, dest, search) {
            if (!source || source.length == 0) return false;
            try {

                var idsToRemove = [];
                var anyOfThisAreValid = false;
                for (var i = 0; i < source.length; i++) {
                    var anyChildrenValid = false;
                    anyChildrenValid |= filterNodes(source[i].children, dest[i].children, search);



                    var valid = nodePassFilter(source[i], search);
                    if (!anyChildrenValid && !valid) {
                        idsToRemove.push(source[i].competitorId);
                    } else if (valid) {
                        anyOfThisAreValid = true;
                    } else if (anyChildrenValid) {
                        anyOfThisAreValid = true;
                    }
                }

                for (var i = 0; i < idsToRemove.length; i++) {
                    for (var j = 0; j < dest.length; j++) {
                        if (dest[j].competitorId == idsToRemove[i]) {
                            dest.splice(j, 1);
                            break;
                        }
                    }
                }

            } catch (e) {
                console.error(e);
                console.log('source', source);
                console.log('dest', dest);
            }

            return anyOfThisAreValid;
        }


        function nodePassFilter(node, filtered) {
            //Si quiero ocultar las ramas vacias, remover esto
            if (node.competitorId == 0) return true;
            for (var i = 0; i < filtered.length; i++) {
                if (node.competitorId == filtered[i].id)
                    return true;
            }

            return false;
        }

        self.getCompetitorCount = function (group) {
            if (!group || group.length == 0) return 0;

            if (group.competitorId != 0)
                return 1;
            else {
                var count = 0;
                for (var i = 0; i < group.children.length; i++) {
                    count += self.getCompetitorCount(group.children[i]);
                }
                return count;
            }

        }

        var timeout = null;
        self.onlyMarkers = false;
        self.onNewCompetitorText = function (text, onlyMarkers) {
            self.search = text;
            self.onlyMarkers = onlyMarkers;
            if (text.length <= 4) return;

            if (timeout != null)
                $timeout.cancel(timeout);

            timeout = $timeout(function () {
                runFilters();
                timeout = null;
            }, 1000)



        }

        function runFilters() {
            self.competitorsLst = $filter('filter')(self.competitorsLstOriginal, self.search);
            if (self.onlyMarkers) {
                self.competitorsLst = $filter('filter')(self.competitorsLst, { isMarker: true });
            }
            if (self.onlyNews) {
                self.competitorsLst = $filter('onlyNewsCompetitors')(self.competitorsLst);
            }
            if (self.onlyWithoutGroup) {
                self.competitorsLst = $filter('onlyWithoutGroup')(self.competitorsLst, self.competitorsWithoutGroup);

            }
            self.groupOfCompetitorsLst = angular.copy(self.groupOfCompetitorsLstOriginal);
            filterNodes(self.groupOfCompetitorsLstOriginal, self.groupOfCompetitorsLst, self.competitorsLst);
        }

        function loadCompetitorsWithoutGroups() {
            priceService.competitors.getCompetitorsWithoutGroup().then(function (competitors) {
                console.log('without group', competitors);
                self.competitorsWithoutGroup = competitors;
            });
        }

        function loadData() {


            loadCompetitorsWithoutGroups();


            priceService.competitors.getCompetitorsTree()
                .then(function (nodes) {
                    //console.log('nodes', nodes);

                    nodes.sort(function (a, b) {
                        return a.name > b.name;
                    });

                    for (var i = 0; i < nodes.length; i++) {
                        nodes[i].children.sort(function (a, b) {
                            return a.name > b.name;
                        })

                        for (var j = 0; j < nodes[i].children.length; j++) {
                            //console.log('nodes[i].children[j]', nodes[i].children[j]);
                            nodes[i].children[j].children.sort(function (a, b) {
                                return a.code > b.code;
                            });
                        }
                    }

                    //console.log('sorted', nodes);
                    self.groupOfCompetitorsLst = nodes;
                    self.groupOfCompetitorsLstOriginal = angular.copy(nodes);
                    self.isLoading = false;
                });
            //priceService.competitors.competitors()
            //   .then(function (competitors) {
            //       self.competitorsLst = competitors;

            //       //self.isloading = false;
            //   });

            //TODO: Cargar Grupos

            priceService.pricelists.getPriceLists()
                .then(function (responseList) {
                    self.priceLists = responseList;
                });

            //traigo los brands.
            adminService.brands.getBrandsCombo(false)
                .then(function (brands) {
                    self.lstBrands = brands;
                });

            //traigo las Places.
            adminService.places.getPlacesCombo()
                .then(function (places) {
                    self.lstPlaces = places;
                });
            if (companyJson.Erp && companyJson.Erp.Implementation && companyJson.Erp.Implementation == "CencosudPeru") {
                //traigo los Markets.
                loadMarkets();
            }
        }

        var translations = null;

        function init() {
            $translate(["Competitor", "EditCompetitor", "ValidationSwalTitle", "ValidationSwalSubtitle", "ErrorTitleSwal", "SaveCompetitorValidationSwalSubtitle", "SaveCompetitorTreeRelationsSwal", "TheProcessContinues", "TheProcessOfContinuousStorageSwal", "ThereWasAnErrorInSavingRelationshipsSwal", "DisableCompetitorSwalTitle", "ToDisable", "Cancel", "CompetitorDisabledSwalTitle", "CompetitorDisabledSwalSubtitle", "TheProcessOfDisablingContinuesSwal", "ThereWasAnErrorDisablingCompetitorSwal", "TheCompetitorExistsInTheGroupSwalTitle", "TheCompetitorExistsInTheGroupSwalSubtitle", "YouAreSureToRemoveTheCompetitorSwal", "FromTheGroupSwal", "RemoveSwal", "Cancel", "Place", "Pr_competitors_selectPlace" ])
                .then(function (all) {
                    translations = all;
                });

            loadData();
        }

        init();


        self.models = {
            selected: null,
            lists: { "A": [], "B": [] }
        };

        // Generate initial model
        for (var i = 1; i <= 3; ++i) {
            self.models.lists.A.push({ label: "Item A" + i });
            self.models.lists.B.push({ label: "Item B" + i });
        }




    });
