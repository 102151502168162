angular.module("prisma")
    .controller("editPromotionCtrl", function ($scope, $filter, $state, $rootScope, $stateParams, promotionsService, adminService, ngDialog, authService) {
        var self = this;
        self.isCopy = JSON.parse($stateParams.copy) == true;
        //TODO: estos parámetros se deberian usar luego para cambiar el status de la tarea
        //Ver si usamos $stateParams que se pierden con un refresh o incluimos los params en la Url
        console.log($stateParams.promotionType);
        console.log($stateParams.promotionId);
        console.log($stateParams.taskId);

        self.hasEditSpecialPermissions = authService.hasPermission('promotion_superUser');
        self.readOnly = $stateParams.readOnly == "true";
        self.promotion = null;
        self.workflows = [];
        self.campaigns = [];
        self.stores = [];
        self.storeGroups = [];
        self.campaign = null;
        self.workflow = null;
        self.holidays = [];
        self.ganttdata = [];
        self.storesDialogDisabled = false;
        self.allowCombinationGroups = false;
        var colors = promotionsService.colors;

        self.promotionType = $stateParams.promotionType;

        self.storeGroup = { id: 0, name: '', stores: [] };

        self.promotionMediaTypes = promotionsService.promotionMediaTypes;

        loadData();

        self.ganttOptions = {
            columns: [],
            columnsHeaders: { 'model.name': 'Tarea' },
            columnsClasses: { 'model.name': 'gantt-column-name' },
            columnMagnet: '1 day',
            currentDate: 'line',
            currentDateValue: moment(new Date()),
            timeFramesWorkingMode: 'hidden',
            timeFramesNonWorkingMode: 'hidden',
            timeFrames: {
                'day': {
                    start: moment('8:00', 'HH:mm'),
                    end: moment('20:00', 'HH:mm'),
                    color: '#ACFFA3',
                    working: true,
                    default: true
                },
                'noon': {
                    start: moment('12:00', 'HH:mm'),
                    end: moment('13:30', 'HH:mm'),
                    working: false,
                    default: true
                },
                'closed': {
                    working: false,
                    default: true
                },
                'weekend': {
                    working: false
                },
                'holiday': {
                    working: false,
                    color: 'red',
                    classes: ['gantt-timeframe-holiday']
                },
                'startPromotion': {
                    working: true,
                    color: 'green',
                    classes: ['gantt-timeframe-holiday']
                }
            },
            dateFrames: {
                'weekend': {
                    evaluator: function (date) {
                        return date.isoWeekday() === 6 || date.isoWeekday() === 7;
                    },
                    targets: ['weekend']
                },
                'startPromotion': {
                    evaluator: function (date) {
                        return date != null && self.promotion.validFrom != null ? moment(date).isSame(moment(self.promotion.validFrom)) : false;
                    },
                    targets: ['startPromotion']
                },
            },
            api: function (api) {
                // API Object is used to control methods and events from angular-gantt.
                self.api = api;

                api.core.on.ready($scope, function () {
                    if (api.tasks.on.moveBegin) {
                        api.tasks.on.moveEnd($scope, function (task) {
                            taskMoved(task);
                        });

                        api.tasks.on.resizeEnd($scope, function (task) {
                            taskMoved(task);
                        });
                    }
                })
            }
        };

        function taskMoved(movedTask) {

            angular.forEach(self.promotion.tasks, function (task) {

                if (movedTask.row.model.id == task.name) {
                    task.plannedStartDate = movedTask.row.from;
                    task.plannedEndDate = movedTask.row.to;
                }
            });
        }

        function loadStoreGroups() {
            promotionsService.stores.getStoreGroups()
                .then(function (storeGroups) {
                    self.storeGroups = storeGroups;

                    //selecciono el grupo de tiendas de la promocion, si es que tiene.
                    if (self.promotion.promotionStoreGroupId != null) {
                        angular.forEach(self.storeGroups, function (sg) {
                            if (self.promotion.promotionStoreGroupId === sg.id) {
                                self.selectStoreGroup(sg);
                            }
                        });
                    }
                });
        }

        function loadStores() {
            promotionsService.stores.getStores()
                .then(function (stores) {
                    self.stores = stores;

                    angular.forEach(self.promotion.stores, function (promotionStore) {
                        angular.forEach(self.stores, function (store) {
                            if (promotionStore.id == store.id)
                                store.selected = true;
                        });
                    });

                    loadStoreGroups();

                });
        }

        function getWorkflowsForPromotion(workflows) {
            //Filtro los workflows, segun el tipo de promocion.
            var rv = [];

            angular.forEach(workflows, function (wf) {
                if (wf.promotionType == $stateParams.promotionType)
                    rv.push(wf);
            });

            return rv;
        }

        function loadData() {
            promotionsService.workflows.getWorkflows(true)
                .then(function (workflows) {
                    self.workflows = getWorkflowsForPromotion(workflows);


                    promotionsService.campaigns.getCampaigns()
                        .then(function (campaigns) {
                            self.campaigns = campaigns;

                            promotionsService.holidays.getHolidays()
                                .then(function (holidays) {
                                    self.holidays = holidays;

                                    promotionsService.stores.getBrands()
                                        .then(function (brands) {
                                            self.brands = brands;
                                            if (brands.length > 0)
                                                self.filterStores.brandName = brands[0].name;
                                            self.filterStores.brandId = brands[0].id;

                                            if ($stateParams.promotionId != '0') {
                                                promotionsService.promotions.getPromotion($stateParams.promotionId)
                                                    .then(function (promotion) {
                                                        promotion.validFrom = new Date(promotion.validFrom);
                                                        promotion.validTo = new Date(promotion.validTo);

                                                        //si la promocion ya existe actualizo los identifiers para connstruir las dependencias
                                                        angular.forEach(promotion.tasks, function (task) {
                                                            task.identifier = task.name;
                                                            task.previousIdentifier = findPreviousIdentifier(task.promotionTaskPreviousId, promotion.tasks);
                                                        });

                                                        self.promotion = promotion;

                                                        self.storesCount = self.promotion.stores.length;

                                                        setCampaign(false);

                                                        loadStores();
                                                       
                                                    });
                                            }
                                            else {
                                                self.promotion = { Id: 0, companyId: $rootScope.companyId, name: '', description: '', promotionCampaignId: 0, promotionWorkflowId: 0, tasks: [], stores: [], promotionType: $stateParams.promotionType };

                                                loadStores();
                                                
                                            }
                                        });
                                });
                        });
                });
        }

        function setCampaign(changeValidity) {
            angular.forEach(self.campaigns, function (campaign) {
                if (self.promotion.promotionCampaignId == campaign.id) {
                    self.campaign = campaign;

                    if (changeValidity) {
                        self.promotion.validFrom = new Date(campaign.validFrom);
                        self.promotion.validTo = new Date(campaign.validTo);
                    }
                }
            });
        }

        self.promotionCampaignChanged = function () {
            setCampaign(true);
        }



        self.openStoresDialog = function () {
           
            ngDialog.open({
                template: 'storesDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                //data: task,
                scope: $scope
            });
        }

        self.storesCount = 0;

        self.selectStore = function (store) {

            if (!self.storesDialogDisabled) {

                store.selected = !store.selected;

                if (store.selected)
                    self.storesCount++;
                else
                    self.storesCount--;
            }
        }

        self.selectStoreGroup = function (storeGroup) {

            self.promotion.promotionStoreGroupId = null;

            var brandOfStoreGroup;
            angular.forEach(self.brands, function (brand) {

                    //para los inconsistentes casos en que las tiendas no tienen brand el workaround es:
                    //matchiar el nombre de la tienda con el nombre del brand
                if (storeGroup.stores[0].brand == brand.name || storeGroup.stores[0].name.indexOf(brand.name) > -1) {
                        brandOfStoreGroup = brand;
                    }
            });

            self.changeStoresFilter(brandOfStoreGroup);

            if (!self.allowCombinationGroups) {//combinacion off
                storeGroup.isSelected = storeGroup.isSelected ? false : true;

                if (storeGroup.isSelected) {
                    angular.forEach(self.storeGroups, function (sg) {
                        if (storeGroup.id != sg.id) {
                            sg.isSelected = false;
                        }
                    });

                    self.promotion.promotionStoreGroupId = storeGroup.id;
                }
                self.unselectAll(); //deselecciono todas las tiendas.
            }

            self.storesDialogDisabled = storeGroup.isSelected; //deshabilito el pop up si quedo un grupo seleccionado

            //seleccion de tiendas
            if (storeGroup.isSelected || self.allowCombinationGroups) {
                angular.forEach(self.stores, function (store) {

                    angular.forEach(storeGroup.stores, function (storeInGroup) {
                        if (storeInGroup.id == store.id) {

                            if (!store.selected) {
                                self.storesCount++;
                            }
                            store.selected = true;
                        }
                    });
                });
            }
        }

        self.changeSwith = function () {
            self.showCombinationGroups = !self.showCombinationGroups;
            self.unselectAll();
        }

        self.unselectAll = function () {
            angular.forEach(self.stores, function (store) {
                if (store.selected) {
                    store.selected = false;
                    self.storesCount--;
                }
            });
        }

        self.save = function () {
            if (self.promotion != null) {
                let today = new Date(new Date().setHours(0, 0, 0, 0));
                if (self.promotion.validFrom < today && !self.hasEditSpecialPermissions) {
                    swal("Error", "Debe seleccionar una campaña vigente o iniciar la promocion con fecha mayor o igual a hoy.", "error");
                }
                else {
                    var title = self.isCopy ? 'Esta seguro que desea copiar la Acción Promocional?'
                        : 'Esta seguro que desea guardar la Acción Promocional?';
                    var text = self.isCopy ? 'No olvide modificar la vigencia de ser necesario' : '';
                    swal({
                        title: title,
                        text: text,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#1AB394",
                        confirmButtonText: "Continuar",
                        cancelButtonText: "Cancelar",
                        showLoaderOnConfirm: true,
                        closeOnConfirm: false,
                        closeOnCancel: true
                    },
                        function (isConfirm) {
                            if (isConfirm) {
                                //teniendo el alcance definido, seteo el brand
                                for (var i = 0; i < self.brands.length; i++) {
                                    if (self.promotion.stores[0].name.indexOf(self.brands[i].name) > -1) {
                                        self.promotion.brandId = self.brands[i].id;
                                        self.promotion.brandName = self.brands[i].name;
                                        break;
                                    }
                                }

                                //Fix Enter en comentarios dentro de un TEXTAREA, para bajar los excels se rompe.
                                self.promotion.description ? self.promotion.description.replace(/(\r\n|\n|\r)/gm, '') : '';

                                if (!self.isCopy) {
                                    promotionsService.promotions.savePromotion(self.promotion)
                                        .then(function (promotion) {
                                            swal('Accion Promocional Guardada!', 'La accion promocional ' + self.promotion.name + ' se ha guardado exitosamente', 'success');

                                            $state.go('promotions.promotions');

                                            //promotion.validFrom = new Date(promotion.validFrom);
                                            //promotion.validTo = new Date(promotion.validTo);

                                            //self.promotion = promotion;
                                            //redrawGantt();

                                        }, function (status) {
                                            if (status == 502) {
                                                swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                                            }
                                            else {
                                                swal("Error", "Hubo un error al guardar esta accion promocional", "error");
                                            }
                                        });
                                }
                                else {
                                    promotionsService.promotions.copyPromotion(self.promotion)
                                        .then(function (promotion) {
                                            swal('Accion Promocional Copiada!', 'La accion promocional ' + self.promotion.name + ' se ha copiado exitosamente', 'success');

                                            $state.go('promotions.promotions');                                            

                                        }, function (status) {
                                            if (status == 502) {
                                                swal("El proceso continua...", "El proceso de copiado continua. Puede revisar el estado en unos minutos.")
                                            }
                                            else {
                                                swal("Error", "Hubo un error al copiar esta accion promocional", "error");
                                            }
                                        });
                                }
                            }


                        });
                }

            }
        }

        self.saveStores = function (close) {
            self.promotion.stores = [];

            angular.forEach(self.stores, function (store) {
                if (store.selected) {
                    self.promotion.stores.push(store);
                }
            });

            self.storesCount = self.promotion.stores.length;

            if (close)
                ngDialog.close();
        }

        self.deleteGroup = function (storeGroup) {
            swal({
                title: 'Esta seguro que desea Eliminar el grupo ' + storeGroup.name + '?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        promotionsService.stores.deleteGroup(storeGroup.id)
                            .then(function (promotion) {
                                self.newStoreGroup();
                                    loadStoreGroups();
                                    swal('Grupo Eliminado!', 'El grupo ' + storeGroup.name + ' se ha eliminado exitosamente', 'success');
                            },
                                function (status) {
                                    if (status == 502) {
                                        swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                                    }
                                    else {
                                        loadStoreGroups();
                                        swal("Error", "Hubo un error al eliminar este grupo", "error");
                                    }
                                });
                    }
                });
        }

        self.filterStores = { brandId: 0, brandName: '' };
        self.changeStoresFilter = function (brand) {
            self.filterStores.brandName = brand.name;
            self.filterStores.brandId = brand.id;
        }

        self.filterStoresByBrand = function (store) {
            if (store.brandId) {//en Peru se detectaron stores con brandId null en el maestro de tiendas! en colombia no.
                return store.brandId == self.filterStores.brandId;
            }
            return store.name.indexOf(self.filterStores.brandName) > -1;
        }

        self.newStoreGroup = function () {
            self.storeGroup = { id: 0, name: '', stores: [] };

            self.unselectAll();
        }

        self.saveStoresAndGroup = function () {
            if (self.storeGroup.name == '' || self.storesCount == 0) {
                swal("Error", "Para guardar este grupo de tiendas debes completar su nombre y seleccionar al menos 1 tienda", "error");
            }
            else {
                swal({
                    title: 'Esta seguro que desea guardar este Grupo de Tiendas?',
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: "Continuar",
                    cancelButtonText: "Cancelar",
                    showLoaderOnConfirm: true,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                    function (isConfirm) {
                        if (isConfirm) {

                            //Primero asocio las tiendas a la promo
                            self.saveStores(false);

                            self.storeGroup.stores = [];

                            //Despues asocio las tiendas al grupo
                            angular.forEach(self.stores, function (store) {
                                if (store.selected)
                                    self.storeGroup.stores.push(store);
                            });

                            promotionsService.stores.saveStoreGroup(self.storeGroup)
                                .then(function (storeGroup) {
                                    swal('Grupo de Tiendas Guardado!', 'El Grupo de Tiendas ' + self.storeGroup.name + ' se ha guardado exitosamente', 'success');

                                    if (self.storeGroup.id == 0) {
                                        self.storeGroups.push(storeGroup);
                                    }

                                    self.storeGroup = storeGroup;
                                    self.newStoreGroup();

                                }, function (status) {
                                    if (status == 502) {
                                        swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                                    }
                                    else {
                                        swal("Error", "Hubo un error al guardar este grupo de tiendas", "error");
                                    }
                                });
                        }

                    });
            }
        }

        self.closeStoresDialog = function () {

            ngDialog.close();
        }

        $scope.$watch('ct.promotion.promotionWorkflowId', function (newVal, oldVal) {
            if (newVal) {

                buildPromotionTasksFromWorkflow();

                redrawGantt();
            }
        }, true);

        function buildPromotionTasksFromWorkflow() {

            var promotionOverflowDate = false;

            angular.forEach(self.workflows, function (workflow) {
                if (self.promotion.promotionWorkflowId == workflow.id) {
                    self.workflow = workflow;
                    self.promotion.promotionMediaType = workflow.promotionMediaType;
                }
            });

            if (self.promotion.Id === 0 || self.isCopy) {

                //Limpio las tareas de la promoción.
                self.promotion.tasks = [];

                //Copio las tareas del Workflow Template a la Promocion
                angular.forEach(self.workflow.tasks, function (task) {

                    var promotionTask = {
                        promotionId: self.promotion.id,
                        promotionWorkflowId: self.workflow.id,
                        promotionTaskId: task.id, //DB: ahora es el workflowTaskId no es mas el taskId
                        promotionTaskPreviousId: task.promotionTaskPreviousId,
                        previousIdentifier: findPreviousIdentifier(task.promotionTaskPreviousId, self.workflow.tasks),
                        identifier: task.name,
                        name: task.name,
                        groupName: task.groupName,
                        durationInDays: task.durationInDays,
                        startFromDay: task.startFromDay,
                        userArea: task.userArea,
                        isRequired: task.isRequired,
                        isStartMilestone: task.isStartMilestone,
                        plannedStartDate: null,
                        plannedEndDate: null
                    };

                    if (promotionTask.promotionTaskPreviousId == null && self.promotion.validFrom.addDays(promotionTask.durationInDays) < new Date())
                        promotionOverflowDate = true;

                    self.promotion.tasks.push(promotionTask);
                });

                if (promotionOverflowDate)
                    swal("Alerta!", "Hay tareas que deberían haber comenzado antes de la fecha de inicio de esta promo. Tener en cuenta que estas tareas ya apareceran vencidas.", "warning");
            }
            else {
                var promotionOverflowDate = false;

                //si la promocion ya existe actualizo los identifiers para connstruir las dependencias
                angular.forEach(self.promotion.tasks, function (task) {
                    task.identifier = task.name;
                    task.previousIdentifier = findPreviousIdentifier(task.promotionTaskPreviousId, self.promotion.tasks);

                    if (task.promotionTaskPreviousId == null && self.promotion.validFrom.addDays(task.durationInDays) < new Date())
                        promotionOverflowDate = true;
                });

                if (promotionOverflowDate)
                    swal("Alerta!", "Hay tareas que deberían haber comenzado antes de la fecha de inicio de esta promo. Tener en cuenta que estas tareas ya apareceran vencidas.", "warning");

            }
        }

        //recibe el listado para poder operar sobre workflowTasks o promotionTasks indistintamente
        function findPreviousIdentifier(taskId, allTasks) {
            var rv = null;
            if (taskId != null) {
                angular.forEach(allTasks, function (task) {
                    if (task.id == taskId) {
                        rv = task.name;
                    }
                });
            }

            return rv;
        }

        var validFromReverted = false;
        $scope.$watch('ct.promotion.validFrom', function (newVal, oldVal) {

            if (validFromReverted) {
                validFromReverted = false;
                return;
            }

            var hasError = false;


            if (newVal) {

                //Validar que el desde sea anterior al hasta
                if (moment(new Date(newVal.getUTCFullYear(), newVal.getUTCMonth(), newVal.getUTCDate())).isAfter(moment(self.promotion.validTo), 'day')) {
                    swal("Error", "La fecha Desde debe ser menor a la fecha hasta", "error");
                    hasError = true;
                }

                //Validar que el desde sea mayor o igual al hasta
                if (moment(new Date(newVal.getUTCFullYear(), newVal.getUTCMonth(), newVal.getUTCDate())).isBefore(moment(self.campaign.validFrom), 'day')) {
                    swal("Error", "La fecha Desde debe ser mayor o igual a la fecha de inicio de la campaña: " + new Date(self.campaign.validFrom), "error");
                    hasError = true;
                }

            }
            else
                hasError = true;

            if (!hasError && (self.promotion.Id === 0 || self.hasEditSpecialPermissions || self.isCopy)) {
                buildPromotionTasksFromWorkflow();
                redrawGantt();
            }
            else {
                validFromReverted = true;
                if (oldVal)
                    self.promotion.validFrom = new Date(oldVal);
            }

        }, true);

        var validToReverted = false;
        $scope.$watch('ct.promotion.validTo', function (newVal, oldVal) {

            if (validToReverted) {
                validToReverted = false;
                return;
            }

            var hasError = false;

            if (newVal) {
                //Validar que el desde sea anterior al hasta
                if (moment(newVal).isBefore(moment(self.promotion.validFrom), 'day')) {
                    swal("Error", "La fecha Hasta debe ser mayor a la fecha desde", "error");
                    hasError = true;
                }

                //Validar que el desde sea mayor o igual al hasta
                if (moment(newVal).isAfter(moment(self.campaign.validTo), 'day')) {
                    swal("Error", "La fecha Hasta debe ser menor o igual a la fecha de fin de la campaña: " + new Date(self.campaign.validTo), "error");
                    hasError = true;
                }
            }
            else
                hasError = true;

            if (!hasError)
                redrawGantt();
            else {
                validToReverted = true;
                if (oldVal)
                    self.promotion.validTo = new Date(oldVal);
            }

        }, true);


        function redrawGanttOLD() {
            var ganttdataitems = [];

            var prevGroupName = '';
            var groupCount = 0;
            var groupId = 1000;
            var group = { id: groupId, name: '', children: [] };

            //Empiezo detectando el hito de entrada en vigencia de la promoción para calcular el start date
            var promotionTaskPreviousId = 0;

            var startDate = moment(self.promotion.validFrom);

            angular.forEach(self.promotion.tasks, function (task, i) {
                if (task.isStartMilestone) {
                    promotionTaskPreviousId = task.promotionTaskPreviousId;
                }
            });

            var durationInDays = 0;

            for (var i = 0; i < self.promotion.tasks.length; i++) {
                angular.forEach(self.promotion.tasks, function (task, i) {
                    if (task.promotionTaskId == promotionTaskPreviousId) {

                        durationInDays += (task.startFromDay + task.durationInDays);

                        promotionTaskPreviousId = task.promotionTaskPreviousId;
                    }
                });
            }

            //Quito Feriados
            var startDateOffset = 0;
            for (var d = 1; d < durationInDays; d++) {
                angular.forEach(self.holidays, function (holiday) {
                    var holidayMoment = moment(holiday.date);
                    if (holidayMoment.isoWeekday() < 6 && moment(startDate).subtract(d, 'days').isSame(holidayMoment)) {
                        startDateOffset++;
                    }
                });
            }

            //agrego el offset del feriado a la duracion
            durationInDays += startDateOffset;

            //agrego los findes de semana a la duracion
            var weeks = parseInt(durationInDays / 7);
            durationInDays = durationInDays + (weeks * 2);

            startDate = moment(startDate).subtract(durationInDays + 1, 'days');

            if (startDate.isoWeekday() === 6) {
                startDate = moment(startDate).subtract(1, 'days');
            }
            else if (startDate.isoWeekday() === 7) {
                startDate = moment(startDate).subtract(2, 'days');
            }

            //TODO: quitar feriados que no caigan sabado o domingo
            startDate = checkWeekends(startDate);
            var endDate = startDate;

            //Paso 1: Recorro todos los tasks para crear los items de los groupnames
            angular.forEach($filter('orderBy')(self.promotion.tasks, 'promotionTaskId', false), function (task, i) {

                if (prevGroupName != '' && prevGroupName != task.groupName) {
                    var groupCopy = {};
                    ganttdataitems.push(angular.copy(group, groupCopy));
                    groupCount++;
                    group.children = [];
                    groupId++;
                }

                group.id = groupId;
                group.name = task.groupName;
                group.children.push(task.name);

                if (task.promotionTaskPreviousId != null) {

                    angular.forEach(ganttdataitems, function (ganttitem, i) {
                        if (ganttitem.id == task.promotionTaskPreviousId && ganttitem.tasks.length > 0) {
                            var ganttitemTask = ganttitem.tasks[0];

                            startDate = moment(endDate).add(task.startFromDay, 'days');
                            startDate = checkWeekends(startDate);

                            endDate = moment(startDate).add(task.durationInDays, 'days');
                        }
                    });
                }
                else {
                    endDate = moment(startDate).add(task.durationInDays + task.startFromDay, 'days');
                }

                //Recorro desde el startDate al endDate para ver si hay feriados o fines de semana en el medio
                var endDateOffset = 0;
                for (var d = 1; d < task.durationInDays; d++) {
                    angular.forEach(self.holidays, function (holiday) {
                        var holidayMoment = moment(holiday.date);
                        if (holidayMoment.isoWeekday() < 6 && moment(startDate).add(d, 'days').isSame(holidayMoment)) {
                            endDateOffset++;
                        }
                    });
                }

                for (var d = 1; d < task.durationInDays; d++) {
                    if (moment(startDate).add(d, 'days').isoWeekday() === 6 || moment(startDate).add(d, 'days').isoWeekday() === 7) {
                        endDateOffset++;
                    }
                }

                endDate = moment(endDate).add(endDateOffset, 'days');

                //Copio las tareas del Workflow Template a la Promocion
                //Si el task ya tiene una fecha de Inicio y Fin entonces no calculo nada
                if (task.plannedStartDate != null) {
                    startDate = task.plannedStartDate;
                    endDate = task.plannedEndDate;
                }
                else {
                    task.plannedStartDate = startDate;
                    task.plannedEndDate = endDate;
                }

                if (groupCount > colors.length) {
                    groupCount = 0;
                }

                var taskFrom = null;
                var taskTo = null;

                var ganttItem = null;

                if (task.isStartMilestone) {
                    taskFrom = moment(self.promotion.validFrom);
                    taskTo = moment(self.promotion.validTo);
                }
                else {
                    taskFrom = moment(startDate);
                    taskTo = moment(endDate);
                }

                ganttItem = {
                    id: task.promotionTaskId,
                    name: task.name,
                    tasks: [{ name: task.name, from: taskFrom, to: taskTo, color: colors[groupCount] }],
                };

                if (task.promotionTaskPreviousId != null) {
                    //TODO: esto no está funcando
                    ganttItem.tasks[0].dependencies = [{ from: task.promotionTaskPreviousId }];
                }

                ganttdataitems.push(ganttItem);

                prevGroupName = task.groupName;
            });

            ganttdataitems.push(group);

            self.ganttdata = ganttdataitems;
        }

        function weekendDays(date1, date2) {
            var d1 = new Date(date1);
            var d2 = new Date(date2);
            var weekendDays = 0;

            while (d1 < d2) {
                var day = d1.getDay();
                isWeekend = (day == 6) || (day == 0);
                if (isWeekend)
                    weekendDays++;

                d1.setDate(d1.getDate() + 1);
            }
            return weekendDays;
        }


        function redrawGantt() {
            var ganttdataitems = [];

            var groupId = 1000;
            var group = { id: groupId, name: '', children: [] };

            //tomo como fecha de partida el validFrom de la promoción. Desde acá comienzo el retroplanning.
            var promotionValidFrom = moment(self.promotion.validFrom);

            //calculo la cantidad de dias desde la primer tarea hasta la ultima, teniendo en cuenta feriados y fines de semana. 
            var durationInDays = calculateDurationInDays(self.promotion.tasks, promotionValidFrom);

            var initDate = moment(promotionValidFrom).subtract(durationInDays, 'days');

            //if (initDate.isoWeekday() === 6) {
            //    initDate = moment(initDate).subtract(1, 'days');
            //}
            //else if (initDate.isoWeekday() === 7) {
            //    initDate = moment(initDate).subtract(2, 'days');
            //}

            //Paso 1: Recorro todos los tasks para crear los items de los groupnames

            var itemsLeft = [];
            angular.forEach(self.promotion.tasks, function (task) { itemsLeft.push(task) });
            var currentGroups = [];
            while (self.promotion.tasks.length != countGanttTasks(ganttdataitems)) {

                //angular.forEach($filter('orderBy')(self.promotion.tasks, 'promotionTaskId', false), function (task, i) {
                //angular.forEach(itemsLeft, function (task, i) {
                angular.forEach($filter('orderBy')(itemsLeft, 'promotionTaskId', false), function (task, i) {

                    var currentGroup = null;
                    angular.forEach(ganttdataitems, function (gdi) {
                        if (gdi.name == task.groupName && gdi.children)
                            currentGroup = gdi;
                    });

                    //si no existia el grupo lo creo
                    if (currentGroup == null) {
                        currentGroup = { id: groupId, name: task.groupName, children: [] };
                        ganttdataitems.push(currentGroup);

                        currentGroups.push(currentGroup.name);
                        groupId++;
                    }

                    //agrego la tarea actual como hija del grupo
                    if (currentGroup.children.indexOf(task.name) == -1)
                        currentGroup.children.push(task.name);

                    //defino el color para el grupo
                    var color = currentGroups.indexOf(task.groupName);

                    //calculo las fechas de la tarea
                    var startDate = null;
                    var endDate = null;

                    if (task.isStartMilestone) {//hito tal como lo seteo el usuario
                        startDate = moment(self.promotion.validFrom);
                        endDate = moment(self.promotion.validTo);
                    }
                    else if (task.plannedStartDate != null) {
                        //Si el task ya tiene una fecha de Inicio y Fin entonces no calculo nada
                        startDate = task.plannedStartDate;
                        endDate = task.plannedEndDate;
                    }
                    else {
                        //Sino calculo las fechas de inicio y fin para la tarea con la logica del retroplanning
                        //Sino calculo las fechas de inicio y fin para la tarea con la logica del retroplanning
                        if (task.promotionTaskPreviousId != null) {

                            angular.forEach(ganttdataitems, function (ganttitem, i) {
                                if (ganttitem.id == task.previousIdentifier && ganttitem.tasks.length > 0) {
                                    var ganttitemTask = ganttitem.tasks[0];

                                    startDate = moment(ganttitemTask.to);//comienza cuando termina la anterior

                                    //aseguro que comienze un dia habil
                                    var dateUpdated = false;
                                    var newStartDate = startDate;
                                    do {
                                        dateUpdated = false;
                                        angular.forEach(self.holidays, function (holiday) {
                                            var holidayMoment = moment(holiday.date);
                                            if (holidayMoment.isoWeekday() < 6 && moment(newStartDate).isSame(holidayMoment)) {
                                                newStartDate.add(1, 'day');
                                                dateUpdated = true;
                                            }
                                        });

                                        if (moment(startDate).isoWeekday() === 6) {
                                            newStartDate.add(2, 'day');
                                            dateUpdated = true;
                                        }
                                        if (moment(startDate).isoWeekday() === 7) {
                                            newStartDate.add(1, 'day');
                                            dateUpdated = true;
                                        }

                                    } while (dateUpdated)

                                    startDate = newStartDate;

                                    //agrego el offset
                                    startDate = startDate.add(task.startFromDay, 'days');

                                    endDate = moment(startDate).add(task.durationInDays, 'days');
                                }
                            });
                        }
                        else {
                            startDate = moment(initDate).add(task.startFromDay, 'days');
                            endDate = moment(startDate).add(task.durationInDays, 'days');
                        }

                        //Recorro la vigencia para detectar los dias no laborales y extender el endDate
                        if (startDate != null && endDate != null) {
                            var newEndDate = endDate;//posibles finales de la tarea
                            var startOfOffset = moment(startDate).subtract(task.startFromDay, 'days');//fecha de inicio del rango a evaluar
                            var remainingOffset = task.durationInDays + task.startFromDay;//dias de rango a evaluar
                            do {
                                var holidays = 0;
                                for (var d = 0; d < remainingOffset; d++) {
                                    angular.forEach(self.holidays, function (holiday) {
                                        var holidayMoment = moment(holiday.date);
                                        if (holidayMoment.isoWeekday() < 6 && moment(startOfOffset).add(d, 'days').isSame(holidayMoment)) {
                                            holidays++;
                                        }
                                    });
                                }
                                weekendTotalDays = weekendDays(startOfOffset, newEndDate);

                                //nuevo rango...
                                remainingOffset = holidays + weekendTotalDays;
                                startOfOffset = new Date(newEndDate);
                                newEndDate = newEndDate.add(remainingOffset, 'days');

                            } while (remainingOffset > 0);

                            endDate = newEndDate;
                        }
                    }


                    var ganttItem = null;

                    //si logre calcular las fechas para el task en esta iteracion la agrego.
                    if (startDate != null && endDate != null) {

                        //uso las fechas calculadas
                        task.plannedStartDate = startDate;
                        task.plannedEndDate = endDate;

                        ganttItem = {
                            id: task.identifier,
                            name: task.name,
                            tasks: [{ name: task.name, from: startDate, to: endDate, color: colors[color] }],
                        };

                        ganttdataitems.push(ganttItem);
                        itemsLeft.splice(itemsLeft.indexOf(task), 1);
                    }


                });

            }

            //ganttdataitems.push(group);

            self.ganttdata = [];
            self.ganttdata = ganttdataitems;
        }

        function calculateDurationInDays(tasks, promotionValidFrom) {

            var durationInDays = 0;

            //Empiezo detectando el hito de entrada en vigencia para recorrer esa rama hasta la primer tarea
            var previousIdentifier = 0;
            angular.forEach(tasks, function (task, i) {
                if (task.isStartMilestone) {
                    previousIdentifier = task.previousIdentifier;
                }
            });

            //Recorro la rama del hito hasta el final y voy acumulando los dias.
            var milestoneBranchFirstTask = null;
            for (var i = 0; i < tasks.length; i++) {
                angular.forEach(tasks, function (task, i) {
                    if (task.identifier == previousIdentifier) {
                        durationInDays += (task.startFromDay + task.durationInDays);
                        previousIdentifier = task.previousIdentifier;
                        if (previousIdentifier == null) {
                            //llegue a la primer tarea de la rama del hito
                            milestoneBranchFirstTask = task;
                        }
                    }
                });
            }

            //Busco si hay otras ramas ademas de la del hito
            var firstTaskOfOtherBranches = [];
            angular.forEach(tasks, function (task, i) {
                if (task.previousIdentifier == null) { //que sea tarea inicial
                    if (task.identifier != milestoneBranchFirstTask.identifier) { //que no sea de la rama del milestone
                        firstTaskOfOtherBranches.push(task);
                    }
                }
            });

            if (firstTaskOfOtherBranches.length > 0) {

                //recorro las tareas iniciales delas otras ramas a ver si tienen algun offset negativo mayor a la del milestone
                var greatestNegativeOffset = 0;
                angular.forEach(tasks, function (task, i) {
                    //solo me interesan los negativos porque pueden correrme la fecha de incio
                    if (task.startFromDay < 0 && task.startFromDay < greatestNegativeOffset)
                        greatestNegativeOffset = task.startFromDay;
                });

                //aplico el offset mas grande
                var otherBranchesNegativeOffset = 0;
                if (greatestNegativeOffset < 0) {
                    otherBranchesNegativeOffset = greatestNegativeOffset;

                    //descuento el offset del milestoneBranchFirstTask porque ya esta contabilizado.
                    if (milestoneBranchFirstTask.startFromDay < 0)
                        otherBranchesNegativeOffset -= milestoneBranchFirstTask;
                }

                durationInDays -= otherBranchesNegativeOffset;
            }

            //////////////////////////DIAS NO LABORALES//////////////////////////
            var startDate = new Date(promotionValidFrom).addDays(-(durationInDays));
            var startDateOffset = 0;

            //Feriados
            var holidayOffset = 0;
            for (var d = 1; d <= durationInDays; d++) {
                angular.forEach(self.holidays, function (holiday) {
                    var holidayMoment = moment(holiday.date);
                    if (holidayMoment.isoWeekday() < 6 && moment(promotionValidFrom).subtract(d, 'days').isSame(holidayMoment)) {
                        holidayOffset++;
                    }
                });
            }

            //agrego el offset del feriado
            startDateOffset += holidayOffset;

            //Fines de semana
            var weekendTotalDays = weekendDays(startDate, new Date(promotionValidFrom));//fines de semana desde el posible comienzo hasta que empieze la promo

            //agrego al offset los findes de semana
            startDateOffset += weekendTotalDays;//feriados mas fines de semanas

            //con startDateOffset (duracion mas feriados mas fines de semanas) ahora tengo una nueva fecha de comienzo...
            var newStartDate = new Date(promotionValidFrom).addDays(-(durationInDays + startDateOffset));
            //verifico si la extencion de dias no contiene mas dias no laborales
            //al agregar mas dias de duracion podemos acumular otros fines de semana/feriados!
            var remainingOffset = startDateOffset;
            do {
                var holidays = 0;
                for (var d = 1; d <= remainingOffset; d++) {
                    angular.forEach(self.holidays, function (holiday) {
                        var holidayMoment = moment(holiday.date);
                        if (holidayMoment.isoWeekday() < 6 && moment(startDate).subtract(d, 'days').isSame(holidayMoment)) {
                            holidays++;
                        }
                    });
                }
                weekendTotalDays = weekendDays(newStartDate, startDate);
                remainingOffset = holidays + weekendTotalDays;
                startDateOffset += remainingOffset;
                //nueva fecha de comienzo
                startDate = newStartDate;
                newStartDate = newStartDate.addDays(-(remainingOffset));


            } while (remainingOffset > 0);

            durationInDays += startDateOffset;

            return durationInDays;

        }

        function checkWeekends(date) {
            //Si el startDate es sábado o domingo lo muevo
            if (date.isoWeekday() === 6) {
                date = moment(date).add(2, 'days');
            }
            else if (date.isoWeekday() === 7) {
                date = moment(date).add(1, 'days');
            }

            return date;
        }

        function countGanttTasks(ganttdataitems) {
            var count = 0;
            angular.forEach(ganttdataitems, function (ganttitem, i) {
                //cuenta solo tasks sin tomar en cuenta los grupos
                if (ganttitem.tasks && !ganttitem.children) {
                    count++;
                }
            });

            return count;
        }


        //Refactor a terminar del retroplanning
        var durationInDays = 0;
        var ganttGroups = [];

        function redrawGanttTBD() {

            currentGroups = [];

            //Empiezo detectando el hito de entrada en vigencia para recorrer toda la rama hacia atras
            var milestoneTask = getMilestoneTask(self.promotion.tasks);

            //Armo la rama del hito (pero sin el hito)
            var milestoneBranchTasks = getMilestoneBranch(self.promotion.tasks, milestoneTask);

            //Inserto la tarea del hito
            milestoneTask.plannedStartDate = moment(self.promotion.validFrom);
            milestoneTask.plannedEndDate = moment(self.promotion.validTo);
            addTaskToGantt(milestoneTask);

            //Comienzo el retroplanning
            var endDate = null;
            var startDate = null;
            var prevOffset = 0;
            angular.forEach(milestoneBranchTasks, function (task) {

                if (endDate == null) {
                    //para el primero
                    endDate = bypassWeekends(moment(self.promotion.validFrom).subtract(1, 'days'));
                }
                else {
                    //para el resto
                    endDate = bypassWeekends(startDate.subtract(1 + prevOffset, 'days'));
                }


                //calculo el startdate
                startDate = bypassWeekends(endDate.subtract(task.durationInDays, 'days'));

                //TODO: agregar offsets

                //setteo las fechas
                task.plannedStartDate = startDate;
                task.plannedEndDate = endDate;

                //acumulo la duracion
                durationInDays += task.durationInDays;

                addTaskToGantt(task);

            });
        }

        function getMilestoneTask(tasks) {
            var rv = null;
            angular.forEach(tasks, function (task, i) {
                if (task.isStartMilestone) {
                    rv = task;
                }
            });

            return rv;
        }

        function getMilestoneBranch(tasks, milestoneTask) {

            var rv = [];

            var previousIdentifier = milestoneTask.previousIdentifier;
            while (previousIdentifier != null) {
                var task = getTaskByIdentifier(tasks, previousIdentifier);
                previousIdentifier = task.previousIdentifier;
                rv.push(task);
            }

            return rv;
        }

        function getTaskByIdentifier(tasks, identifier) {
            var rv = null;
            angular.forEach(tasks, function (task) {
                if (task.identifier == identifier)
                    rv = task;
            });

            return rv;
        }

        function addTaskToGantt(task) {

            addTaskToGroup(task);

            if (task.plannedStartDate != null && task.plannedEndDate != null) {

                //defino el color para el grupo
                var color = ganttGroups.indexOf(task.groupName);

                var ganttItem = {
                    id: task.identifier,
                    name: task.name,
                    tasks: [{ name: task.name, from: task.plannedStartDate, to: task.plannedEndDate, color: colors[color] }],
                };

                self.ganttdata.push(ganttItem);


            }
        }

        function addTaskToGroup(task) {

            //busco si ya existe
            var currentGroup = null;
            angular.forEach(self.ganttdata, function (gdi) {
                if (gdi.name == task.groupName && gdi.children)
                    currentGroup = gdi;
            });

            //si no existia el grupo lo creo
            if (currentGroup == null) {
                currentGroup = { id: task.groupName, name: task.groupName, children: [] };
                self.ganttdata.push(currentGroup);
                ganttGroups.push(currentGroup.name);
            }

            //agrego la tarea actual como hija del grupo
            if (currentGroup.children.indexOf(task.name) == -1)
                currentGroup.children.push(task.name);

        }

        function bypassWeekends(endDate) {
            var rv = moment(endDate);

            //si el endDate es sábado o domingo lo muevo
            if (endDate.isoWeekday() === 7) {
                rv = moment(endDate).subtract(2, 'days');
                durationInDays += 2;
            }
            else if (endDate.isoWeekday() === 6) {
                rv = moment(endDate).subtract(1, 'days');
                durationInDays += 1;
            }

            return rv;
        }

        Date.prototype.addDays = function (days) {
            var dat = new Date(this.valueOf());
            dat.setDate(dat.getDate() + days);
            return dat;
        }

    });