"use strict";

angular.module("prisma")

    .controller("ItemDashboardCtrl", function ($scope, $rootScope, $stateParams, $translate, homeService) {
        var self = this;

        self.datasets = null;
        self.priceSurveys = null;
        self.showPrices = false;
        self.price = 0;
        self.inventory = { days: '2', gmroi: '' };

        self.timerange = null;

        self.updateChart = function (timerange) {
            if (self.datasets == null || self.datasets == '')
                return;

            self.timerange = timerange;

            self.chart = c3.generate({
                bindto: '#saleschart',
                data: {
                    json: (timerange == 'week' ? self.datasets.lastWeekXValues : timerange == 'month' ? self.datasets.lastMonthXValues : self.datasets.lastYearXValues),
                    keys: {
                        x: 'dateString',
                        value: ['sales', 'units', 'margin', 'price'],
                    },
                    axes: {
                        sales: 'y',
                        units: 'y',
                        margin: 'y',
                        price: 'y2'
                    },
                    type: 'bar',
                    types: {
                        price: 'line',
                    },
                    colors: {
                        sales: '#1ab394',
                        units: '#1c84c6',
                        margin: '#f8ac59',
                        price: '#ED5565'
                    }
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            fit: true,
                            format: function (x) {
                                if (self.timerange == 'week') {
                                    return $rootScope.shortWeekDayNames[x.getDay()];
                                } else if (self.timerange == 'month') {
                                    return x.getDate() + '-' + $rootScope.shortMonthNames[(x.getMonth())];
                                } else if (self.timerange == 'year') {
                                    return x.getFullYear() + '-' + (x.getMonth() + 1);
                                }
                            }
                        }
                    },
                    y: {
                        tick: {
                            format: d3.format("s")
                        }
                    },
                    y2: {
                        show: true,
                        label: 'Precio en $',
                        tick: {
                            format: d3.format('.1f')
                        },
                        min: 0
                    }
                },
                tooltip: {
                    format: {
                        title: function (d) {
                            var toFormat = '%B %d, %Y';
                            if (self.timerange == 'year')
                                toFormat = '%B %Y';

                            var format = d3.time.format(toFormat);
                            return format(d);
                        },
                        value: function (value, ratio, id) {
                            var format = id === 'price' ? d3.format(',.1f') : d3.format(',.0f');
                            return format(value);
                        }
                    }
                }
            });
        }

        function loadData() {
            if ($stateParams.itemId) {

                homeService.dashboards.getItemDashboard($stateParams.itemId, $stateParams.storeId ? $stateParams.storeId : null, $stateParams.clusterId && $stateParams.clusterId != 0 ? $stateParams.clusterId : null, $stateParams.priceListId ? $stateParams.priceListId : null)
                .then(function (result) {

                    self.datasets = result;

                    self.updateChart('year');

                    self.updateKPIs();

                    self.getInventory();

                    self.priceSurveys = result.priceSurveys;
                    if (result.priceSurveys != null && result.priceSurveys != '' && result.priceSurveys.length > 0)
                    {
                        self.showPrices = true;
                    }
                });
            }
        }

        self.updateKPIs = function ()
        {
            if (self.datasets == null || self.datasets == '')
                return;

            var l = self.datasets.lastYearXValues.length;
            if (l > 1)
            {
                self.lastDaySales = self.datasets.lastYearXValues[l - 1].sales;
                var lastDaySalesChange = (self.datasets.lastYearXValues[l - 1].sales / self.datasets.lastYearXValues[l - 2].sales - 1).toFixed(4);
                self.lastDaySalesChange = lastDaySalesChange;
                self.lastDaySalesChangeIcon = lastDaySalesChange < 0 ? 'fa fa-level-down' : (lastDaySalesChange > 0 ? 'fa fa-level-up' : 'fa fa-minus');

                self.lastDayUnits = self.datasets.lastYearXValues[l - 1].units;
                var lastDayUnitsChange = (self.datasets.lastYearXValues[l - 1].units / self.datasets.lastYearXValues[l - 2].units - 1).toFixed(4);
                self.lastDayUnitsChange = lastDayUnitsChange;
                self.lastDayUnitsChangeIcon = lastDayUnitsChange < 0 ? 'fa fa-level-down' : (lastDayUnitsChange > 0 ? 'fa fa-level-up' : 'fa fa-minus');

                self.lastDayMargin = self.datasets.lastYearXValues[l - 1].margin;
                var lastDayMarginChange = (self.datasets.lastYearXValues[l - 1].margin / self.datasets.lastYearXValues[l - 2].margin - 1).toFixed(4);
                self.lastDayMarginChange = lastDayMarginChange;
                self.lastDayMarginChangeIcon = lastDayMarginChange < 0 ? 'fa fa-level-down' : (lastDayMarginChange > 0 ? 'fa fa-level-up' : 'fa fa-minus');

                self.price = self.datasets.lastYearXValues[l - 1].price;
                self.lastDayPrice = self.price;
                
                var lastDayPriceChange = (self.datasets.lastYearXValues[l - 1].price / self.datasets.lastYearXValues[l - 2].price - 1).toFixed(4);
                self.lastDayPriceChange = lastDayPriceChange;
                self.lastDayPriceChangeIcon = lastDayPriceChange < 0 ? 'fa fa-level-down' : (lastDayPriceChange > 0 ? 'fa fa-level-up' : 'fa fa-minus');
            }
            
        }

        self.getInventory = function()
        {
            homeService.dashboards.getInventory($stateParams.itemId, $stateParams.storeId ? $stateParams.storeId : null, $stateParams.clusterId && $stateParams.clusterId != 0 ? $stateParams.clusterId : null, $stateParams.priceListId ? $stateParams.priceListId : null).then(function (inventory) {
                self.inventory = inventory;
            });
        }

        function init() {

            loadData();
        }

        init();
    });

