angular.module("prisma")
 .controller("forecastImporterCtrl", function ($scope, $state, $stateParams, $filter, $window, $timeout, promotionsService, serverService, authService, Upload) {
     var self = this;

     self.isLoading = true;
     self.forecastItems = [];
     self.currentStep = 1;
     self.canPublish = true;
     self.canPublishForecastManual = authService.hasPermission('promotion_publish_ForecastManual');

     self.gridOptions = {
         columnDefs: [
         {
             headerName: 'Severidad',
             width: 100,
             pinned: 'left',
             field: 'severity',
             suppressMenu: true,
             suppressSorting: true,
             filter: false,
             rowGroupIndex: 0,
             cellRenderer: function (params) {
                 var severityName = '';
                 switch (params.value) {
                     case 1: { severityName = '<i style="color: #6aa4ff;" class="fa fa-info-circle" aria-hidden="true"></i> Info'; break; }
                     case 2: { severityName = '<i style="color: #e4e400;" class="fa fa-stop-circle" aria-hidden="true"></i> Warn'; break; }
                     case 3:
                         {
                             if (params.node.data != null && params.node.data.errorType == 6 && !self.showCategorySwal) {
                                 self.addCategoryConfirm();
                                 self.showCategorySwal = true;
                             }
                             severityName = '<i style="color: #ea2323;" class="fa fa-exclamation-circle" aria-hidden="true"></i> Error'; break;
                         }
                 }
                 return severityName;
             }
         },
         {
             headerName: 'Tipo',
             width: 100,
             pinned: 'left',
             field: 'errorType',
             suppressMenu: true,
             suppressSorting: true,
             filter: false,
             cellRenderer: function (params) {
                 var errorName = '';
                 switch (params.value) {
                     case 1: { errorName = "Campo Nulo"; break; }
                     case 2: { errorName = "Sin Dato en BD"; break; }
                     case 3: { errorName = "Formato Incorrecto"; break; }
                     case 4: { errorName = "Sin Archivo"; break; }
                     case 5: { errorName = "Error UpSert"; break; }
                     case 6: { errorName = "Inconsistencia"; break; }
                     case 7: { errorName = "Calidad"; break; }
                     case 8: { errorName = "Subida de Archivos"; break; }
                 }

                 return errorName;
             }
         },
         {
             headerName: 'Fila',
             width: 50,
             pinned: 'left',
             field: 'row',
             suppressMenu: true,
             suppressSorting: true,
             filter: false,
         },
         {
             headerName: 'Categoria',
             width: 300,
             rowGroupIndex: 1,
             pinned: 'left',
             field: 'category',
             suppressMenu: true,
             suppressSorting: true,
             filter: false,
         },
         {
             headerName: 'Descripcion',
             width: 500,
             pinned: 'left',
             field: 'description',
             suppressMenu: true,
             suppressSorting: true,
             filter: false,
         },
         ],
         enableColResize: true,
         enableSorting: true,
         enableFilter: false,
         rowHeight: 35,
         suppressCellSelection: true,
         groupUseEntireRow: true,
         groupSuppressAutoColumn: false,
         groupDefaultExpanded: 0,
         icons: {
             columnRemoveFromGroup: '<i class="fa fa-remove"/>',
             filter: '<i class="fa fa-filter"/>',
             sortAscending: '<i class="fa fa-long-arrow-down"/>',
             sortDescending: '<i class="fa fa-long-arrow-up"/>',
             groupExpanded: '<i class="fa fa-minus-square-o"/>',
             groupContracted: '<i class="fa fa-plus-square-o"/>',
             columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
             columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
         },
         angularCompileRows: true,
     }
     
     self.showNextStep = function () {
         if (self.currentStep > 0 && self.currentStep < 4) {
             self.currentStep++;
             loadResults([]);
         }
     }

     self.downloadExcel = function () {
         var html = '<table width="100%"><thead><tr><th>ID Promocion</th><th>Nombre Promocion</th><th>Pagina</th><th>Posicion</th><th>PromotionItemId</th><th>Codigo SAP</th><th>Descripcion SAP</th><th>Tipo</th><th>UMV</th><th>Forecast</th></tr></thead><tbody>';

         angular.forEach($filter('orderBy')(self.forecastItems, ['pageNumber', 'itemId'], false), function (item) {

             html += '<tr><td>' + item.promotionId + '</td><td>' + item.promotionName + '</td><td>' + item.pageNumber + '</td><td>' + item.itemPosition + '</td><td>' + item.promotionItemId + '</td><td>' + (item.itemCode != null ? item.itemCode.replace(/^0+/, '') : '') + '</td><td>' + (item.itemName != null ? item.itemName : '') + '</td><td>' + (item.promotionItemType) + '</td><td>' + (item.salesUnitOfMeasure != null ? item.salesUnitOfMeasure : '') + '</td><td>' + item.forecast + '</td></tr>';

         });

         html += '</tbody></table>';

         var exportHref = ian.ExportToExcel(html, 'Forecasts');
         $timeout(function () {
             location.href = exportHref;
             self.showNextStep();
         }, 100); // trigger download
     }

     self.uploadFile = function (files) {
         if (files && files.length > 0) {
             self.isBusy = true;
             var url = ian.urlCombine(serverService.getApiUrl(), '/promotion/items/uploadForecast', $stateParams.promotionId);
             Upload.upload({
                 url: url,
                 data: { file: files[0] }
             }).then(function (response) {
                 var importerResults = response.data;
                 processImporterResults(importerResults);
                 if (!anyError(importerResults)) {
                     self.finishedUpload = true;
                 }
             });
         }
     }

     self.validateUploadedFile = function () {
         promotionsService.items.validateForecasts($stateParams.promotionId)
                .then(function (results) {
                    processImporterResults(results);
                    if (!anyError(results)) {
                        self.finishedValidation = true;
                    }
                });

     }

     self.reUploadFile = function () {
         self.currentStep = 2;
         loadResults([]);
         self.files = null;
         self.finishedUpload = false;
         self.finishedValidation = false;
         self.finishedUpdate = false;
     }

     self.updateForecast = function () {
         promotionsService.items.updateForecasts($stateParams.promotionId)
                .then(function (results) {
                    processImporterResults(results);
                    if (!anyError(results)) {
                        self.finishedUpdate = true;
                    }
                });

     }

     self.finish = function () {
         self.currentStep = 1;
         loadResults([]);

         self.finishedUpload = false;
         self.finishedValidation = false;
         self.finishedUpdate = false;
     }

     self.publish = function () {
         if ($stateParams.taskId) {
             promotionsService.tasks.publishTask($stateParams.taskId)
                 .then(function (isOk) {
                     swal('Tarea Publicada!', 'La tarea se ha publicado exitosamente', 'success');

                     $state.go('promotions.myTasks');
                 });
         } else {
             $timeout(function () {
                 swal("Error", "no se pudo publicar la tarea", "error");
             }, 100);
         }
     }

     function processImporterResults(importerResults) {
         self.isBusy = false;
         self.isBusy2 = false;
         self.isBusy3 = false;
         self.isBusy4 = false;

         if (importerResults.length > 0) {
             loadResults(importerResults);
         }
         if (anyError(importerResults)) {
             self.error = true;
         }
         else
             self.error = false;
     }

     function loadResults(results) {
         self.gridOptions.api.setRowData(results);
         self.gridOptions.api.refreshView();

         self.gridVisible = results.length > 0 ? true : false;
     }

     function anyError(importerResults) {
         var error = false;
         for (var i = 0; i < importerResults.length; i++) {
             if (importerResults[i].severity === 3)
                 error = true;
         }

         return error;
     }


     function init() {
         if (!$stateParams.promotionId || $stateParams.promotionId == "0") {
             swal('No se puede cargar la página', 'No se recibió el Id de la Promoción', 'error');
         }
         else {
             promotionsService.items.getItemsForecast($stateParams.promotionId)
                 .then(function (dto) {
                     self.forecastItems = dto.items;
                     self.isLoading = false;

                 }, function () { self.isLoading = false; });

         }
     }

     init();
 });