angular.module("prisma")
    .controller("CostsCtrl", function ($scope, $state, priceService, $timeout, $translate) {

     //Variable globales

     var self = this;

     self.costs = [];

     //Funciones
     self.generateSuggestions = function () {

         swal({
             title: translations.GenerateSuggestionsSwal,
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: translations.ContinueSwal,
             cancelButtonText: translations.CancelSwal,
             showLoaderOnConfirm: true,
             closeOnConfirm: false,
             closeOnCancel: true
         },
      function (isConfirm) {
          if (isConfirm) {

              priceService.costs.saveAndGenerateSuggestions()
               .then(function (isOk) {
                   swal(translations.SuggestionsGenerated, translations.SuggestionsGeneratedSubtitle, "success");
                   loadData();
               }, function (status) {
                   if (status == 502) {
                       swal(translations.TheProcessContinues, translations.TheSuggestionsContinueToBeGeneratedSwal)
                   }
                   else {
                       swal(translations.ErrorTitleSwal, translations.AnErrorOccurredGeneratingSuggestions, "error");
                       loadData();
                   }
               });
          }
      });
     }

     self.masiveUpdate = function()
     {
         $state.go('admin.importer', { "importerType": "OxxoCosts" });
     }

     function loadData() {
         priceService.costs.getCurrentCosts()
        .then(function (costs) {
            self.costs = costs;
        });
     }

     var translations = null;

     function init() {

         $translate(["GenerateSuggestionsSwal", "ContinueSwal", "CancelSwal", "SuggestionsGenerated", "SuggestionsGeneratedSubtitle", "TheProcessContinues"
             , "TheSuggestionsContinueToBeGeneratedSwal", "ErrorTitleSwal", "AnErrorOccurredGeneratingSuggestions"])
             .then(function (all) {
                 translations = all;
             });

         loadData();
     }

     init();

 });




