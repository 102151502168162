
angular.module("prisma")
  .controller("PriceMapReportCtrl", function ($state, $rootScope, priceService) {
      var self = this;

      self.price = {
          priceId: 0
      };

      function init() {
      }

      init();

  });

