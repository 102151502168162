angular.module("prisma")
    .controller("VolumeMeasurementCtrl", function ($state, $rootScope, adminService, priceService, $translate) {

        
        var self = this;
        self.productGroup = [];
        self.dateFrom = new Date();
        self.months = { '1': 'Ene', '2': 'Feb', '3': 'Mar', '4': 'Abr', '5': 'May', '6': 'Jun', '7': 'Jul', '8': 'Ago', '9': 'Set', '10': 'Oct', '11': 'Nov', '12': 'Dic' };;
        self.unitsProductsCompetitors = [];
        self.unitsProducts = [];

        self.setDates = function () {
            //seteo un a�o antes
            var year = self.dateFrom.getFullYear();
            self.year = year - 1;
            self.dateFrom = new Date(self.year, self.month , 1);
        }

        self.items = [{}];

        self.openCalendar = function (e) {
            self.isOpen = !self.isOpen;
        };
        self.loadData = function () {

            if (self.selectedItem && self.selectedPriceList) {

                self.unitsProducts = [];
                getVolumeMeasurement(self.selectedItem.id, self.selectedPriceList.id, function (data) {

                    self.data = data;
                    self.updateChart();

                    if (self.selectedBrands && self.selectedBrands.length > 0) {
                        self.unitsProductsCompetitors = [];
                        self.isLoadingChartCompetitors = true;
                        getVolumeMeasurementCompetitors(self.selectedItem.id, self.selectedPriceList.id, self.selectedBrands, function (dataCompetitors) {

                            self.dataCompetitors = dataCompetitors;
                            self.updateChartCompetitors();
                            self.isLoadingChartCompetitors = false;

                        });

                    } else {
                        //actualizo sin datos para competidores
                        self.isLoadingChartCompetitors = false;
                        self.dataCompetitors = [];
                        self.updateChartCompetitors();

                    }
               
                });
            }
        }
        self.changeItemSelected = function () {
            self.isLoadingChart = false;
            self.isLoadingChartCompetitors = false;
        }

        self.changePriceListSelected= function () {
            self.isLoadingChart = false;
            self.isLoadingChartCompetitors = false;
        }
        self.loadItems = function (categoryId) {

            adminService.items.getItemsByCategory(0).then(function (items) {
                self.items = items;
            });

            self.selectedItem = self.items[1];

            adminService.priceLists.getPriceLists().then(function (markets) {
                self.markets = markets;
            });

            adminService.brands.getBrandsCombo(false).then(function (competitors) {
                //harcoded
                for (i = 0; i < competitors.length; i++) {
                    if (competitors[i].name == "OxxoGas") {
                        competitors.splice(i, 1);
                    }
                }
                self.competitors = competitors;
            });
     
        }
        self.updateChart = function () {
            c3.generate({
                bindto: "#volumeMeasurementChart",
                data: {
                    x: 'x',
                    "columns": self.data,
                    empty: { label: { text: "Sin datos" } },
                    type: 'bar',
                    groups: [
                        self.productGroup
                    ],
                    types: {
                        GAP: 'line',
                    },
                    axes: {
                        'GAP': 'y2'
                    },
                    colors: {
                        'GAP': '#3e3e3e',
                        'Regular': '#48c2a9',
                        'Premium': '#777777'
                    },
                    labels: {
                        format: function (v, id, i, j) {

                            if (id && (id == "Premium" || id == "Regular")) {
                                v = self.unitsProducts[(id == "Premium" ? 1 : 0)][i + 1];
                            } else {
                                if (id == "GAP")
                                    v = "";
                            }
                            return v;

                        }
                    }
                },
                axis: {
                    x: {
                        type: 'category',
                        tick: {
                            rotate: 0,
                            multiline: false
                        },

                    },
                    y2: {
                        show: true,
                        tick: {
                            format: d3.format('.0f%')
                        },
                        min: -10,
                        max: 50
                    }
                },
                tooltip: {
                    format: {
                        title: function (index) {
                            if (self.data[0]) {
                                return self.dataCompetitors[0][index + 1];
                            }
                        },

                        value: function (value, ratio, id) {
                            var newValue = value + '%';
                            return newValue;
                        }
                    }
                }

            });
        }

        self.updateChartCompetitors = function () {
            c3.generate({
                bindto: "#volumeMeasurementChartCompetitors" ,
                data: {
                    x: 'x',
                    "columns": self.dataCompetitors,
                    empty: { label: { text: "Sin datos" } },
                    type: 'bar',
                    groups: [
                        self.productGroup 
                    ],
                    types: {
                        GAP: 'line',
                    },
                    axes: {
                        'GAP': 'y2'
                    },
                    colors: {
                        'GAP': '#3e3e3e',
                        'Regular': '#48c2a9',
                        'Premium': '#666666'
                    },
                    labels: {
                        format: function (v, id, i, j) {
                            
                            if (id && (id == "Premium" || id == "Regular")) {
                                v = self.unitsProductsCompetitors[(id == "Premium" ? 1 : 0)][i + 1];
                            } else {
                                if (id == "GAP")
                                    v = "";
                            }
                            return v;
                            
                        }
                    }
                },
                axis: {
                    x: {
                        type: 'category',
                        tick: {
                            rotate: 0,
                            multiline: false
                        }
                    },
                    y2: {
                        show: true,
                        tick: {
                            format: d3.format('.0f%')
                        },
                        min: -10,
                        max : 60
                    },
                   
                },
                tooltip: {
                    format: {
                        title: function (index) {
                            if (self.dataCompetitors[0]) {
                                return self.dataCompetitors[0][index + 1];
                            }
                        },
                    
                        value: function (value, ratio, id) {
                            var newValue =  value + '%';
                            return newValue;
                        }
                    }
                }
            
            });
        }

        self.isLoadingChart = false;
        self.isLoadingChartCompetitors = false;
        function getVolumeMeasurementCompetitors(compare, priceListId, brands, callback) {
            self.isLoadingChartCompetitors= true;

            var brandsIds = [];
            angular.forEach(brands, function (brand) {
                brandsIds.push(brand.id);
            });
            /*var s = this;
            s.callback = callback;   */
            priceService.reports.getVolumeMeasurementCompetitors(compare, priceListId, brandsIds).then(function (items) {
                self.isLoadingChartCompetitors = false;
                if (items == null || items == '') {
                    callback([]);
                }
                parseData(compare, items, true, callback);
            });
        }

        function getVolumeMeasurement(compare, priceListId, callback) {
            self.isLoadingChart = true;
            /*var s = this;
            s.callback = callback;*/
            priceService.reports.getVolumeMeasurement(compare, priceListId).then(function (items) {
                self.isLoadingChart = false;
                if (items == null || items == '') {
                    callback([]);
                }
                parseData(compare,items, false, callback);
            });
        }

        
        function parseData(compare,items, competitorsChart, callback ) {
            var valueToPush = new Array();
            //serie para el eje x, distinct de fechas
            var datesArray = items.reduce(function (a, d) {
                if (a.indexOf(d.transactionDateString) === -1) {
                    a.push(d.transactionDateString);
                }
                return a;
            }, ['x']);

            var productsNames = [{}];
            var productGroup = [];

            var prodOne = [];
            var prodTwo = [];
            //harcoded
            if (compare == 0) {
                prodOne.push('Regular');
                prodTwo.push('Premium');
                productsNames = [{ id: 1, name: "Regular" }, { id: 2, name: "Infinia" }];
                productGroup = ['Regular', 'Premium']
            }
            else {
                prodOne.push('Regular');
                prodTwo.push('Premium');
                productsNames = [{ id: 4, name: "Regular" }, { id: 5, name: "Premium" }];
                productGroup = ['Regular', 'Premium']
            }
            if (competitorsChart) {
                //para los labels sobre las barras
                self.unitsProductsCompetitors.push(['Regular']);
                self.unitsProductsCompetitors.push(['Premium']);
                //
            } else {
                self.unitsProducts.push(['Regular']);
                self.unitsProducts.push(['Premium']);
            }
            
            self.productGroup = productGroup;
            var pricesGAP = ['GAP'];
            for (i = 1; i < datesArray.length; i++) {

                var total = 0;
                var units = [];
                var auxPrices = [];
                var minorPrice = undefined;
                productsNames.forEach(function (prod) {

                    var productDate = items.find(cd => {
                        return cd.transactionDateString == datesArray[i] && cd.itemId == prod.id;
                    })
                    if (productDate) {
                        //guardo las unidades vendidas por dia por prod para luego sacar el %
                        units.push(productDate.units);
                        total += productDate.units;
                        //precios
                        if (minorPrice && minorPrice > productDate.price) {
                            minorPrice = productDate.price;
                        }
                        if (!minorPrice)
                            minorPrice = productDate.price;

                        auxPrices.push(productDate.price);
                    }
                });
                var gap = Math.abs(auxPrices[0] - auxPrices[1]) * 100 / minorPrice;

                pricesGAP.push(gap.toFixed(2));
                prodOne.push((units[0] * 100 / total).toFixed(2));
                prodTwo.push((units[1] * 100 / total).toFixed(2));
                //para labels, guardo las unidades
                if (competitorsChart) {
                    self.unitsProductsCompetitors[0].push(Math.floor(units[0] / 1000))
                    self.unitsProductsCompetitors[1].push(Math.floor(units[1] / 1000));
                } else {
                    self.unitsProducts[0].push(Math.floor(units[0] / 1000))
                    self.unitsProducts[1].push(Math.floor(units[1] / 1000));
                }
            }
            valueToPush[0] = datesArray;
            valueToPush[1] = prodOne;
            valueToPush[2] = prodTwo;
            valueToPush[3] = pricesGAP;
            callback(valueToPush);
            
        }
        function init() {

            $translate(["GeneralInformation", "DateFrom", "DateFromTooltip", "StoreCode", "Store", "ChangesSent", "ChangesSentTooltip"
                , "NAtooltip", "Atooltip", "Limit", "LimitTooltip", "Detour", "PriceTypes", "Regular", "Competitive", "Promotion", "Discount"
                , "Action", "Decrease", "Increase", "Pr_volumeMeasurement_validationDate"])
                .then(function (all) {
                    translations = all;
                    self.setDates();
                    self.loadItems(0);

                });

        }
        init();
    });
