angular.module("prisma")

    .controller("spacesLayoutsCtrl", function ($scope, promotionsService, $timeout, authService, ngDialog, spaceService, adminService, $translate) {

        //Varibles
        var self = this;

        self.search = "";
        self.order = "";
        self.filter = {
            cluster: undefined,
        };
        self.textClusters = "";
        self.clusters = null;

        self.newLayout = generateNewLayout();

        //Funciones

        self.hasPermissionToEdit = authService.hasPermission('spaces_edit_layout');

        self.setFilterCluster = function (cluster) {
            self.filter.cluster = cluster.name;
            self.textClusters = cluster.name;
        }

        self.layoutToCloneCode = '';
        self.layoutToCloneName = '';

        self.openLayoutCloneDialog = function (layoutId, layoutCode, layoutName) {
            if (layoutId) {

                self.layoutToCloneCode = layoutCode;
                self.layoutToCloneName = layoutName;

                self.newLayout = generateNewLayout();
                self.newLayout.copyFromLayoutId = layoutId;

                ngDialog.open({
                    template: 'layoutEditDialog',
                    className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-500',
                    scope: $scope
                });

            }
        }

        function generateNewLayout() {
            return {
                id: 0,
                name: '',
                code: '',
                clusterId: null,
                stores: [],
                copyFromLayoutId: null
            };
        }

        self.showEditLayoutModal = function (layout) {
            if (layout == null) {
                self.newLayout = generateNewLayout();
            }
            else {
                self.newLayout = angular.copy(layout);
            }

            ngDialog.open({
                template: 'layoutEditDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-500',
                scope: $scope
            });
        }

        function isValidFieldNum(field) {
            if (field == undefined || field == null || field == '')
                return false;

            return !isNaN(parseFloat(field));
        }

        function isValidField(field) {
            if (field == undefined || field == null || field == '')
                return false;

            return true;
        }

        self.saveNewEditLayout = function () {

            if (!(isValidField(self.newLayout.name) && isValidField(self.newLayout.code) && self.newLayout.stores.length > 0)) {
                swal(translations.ValidationSwalTitle, translations.ValidationSwalSubtitle, 'error');
                return;
            }
            var data = {
                id: self.newLayout.id,
                name: self.newLayout.name,
                code: self.newLayout.code,
                stores: self.newLayout.stores,
                copyFromLayoutId: self.newLayout.copyFromLayoutId
            }

            spaceService.layouts.saveLayout(data).then(function (res) {
                self.layouts = res;
                swal(translations.SavedLayoutSwal, translations.SavedLayoutSwalSubtitle, 'success');
            })

            ngDialog.close();
        }

        self.deleteLayout = function (indexLayout, idLayout) {
            self.layouts.splice(indexLayout, 1);
            spaceService.layouts.deleteLayout(idLayout).then(function (bool) {
                if (bool) {
                    swal('Exitoso', 'Se borro correctamente', 'success');
                }
                else {
                    swal('Error', 'No se borro correctamente', 'error');
                }
            })               
        }

        function loadData() {

            //LG: Por ahora comento los clusters ya que el Layout se va a asociar a un conjunto de Tiendas
            /*
            adminService.clusters.getClusters().then(function (res) {
                self.clusters = res;
            });
            */

            spaceService.layouts.getLayouts().then(function (result) {
                self.layouts = result;
            });

            spaceService.stores.getStores(false).then(function (stores) {
                self.stores = stores;
            });
        }

        var translations = null;

        function init() {
            $translate(["ValidationSwalTitle", "ValidationSwalSubtitle", "SavedLayoutSwal", "SavedLayoutSwalSubtitle"])
                .then(function (all) {
                    translations = all;
                });

            loadData();
        }

        init();
    });