angular.module("prisma")
    .controller("editPriceRoundingsCtrl", function ($scope, $timeout, $stateParams, $state, priceService, adminService, ngDialog, $translate) {

        var self = this;
        self.priceRounding = {};

        self.priceRoundings = [];
        self.unmodifiedPoints = [];

        self.isNew = false;
        self.selectedPriceType = null;
        self.showDd = false;
        self.inputNewTermination = false;
        self.newPointValue = null;

        self.allArticlesGroup = [];
        self.newRoundingAdded = false;

        self.priceLists = [];

        self.title = "";

        //Funciones


        self.savePriceRoundings = function (priceRoundings) {

            swal({
                title: translations.SaveTheRoundingRulesSwal,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        //agrego la ultima regla a self.priceRoundings si no se agrego aun
                        if (self.isNew) {
                            self.priceRoundings.push(self.priceRounding);
                        }
                        if (priceRoundings.length > 0) {

                            var canSaveResult = canSave();

                            if (canSaveResult == "success") {

                                angular.forEach(self.priceRoundings, function (pr) {
                                    //pongo en el array las categorias seleccionadas
                                    var selectedCategories = [];
                                    if (pr.categories.length > 0) {
                                        selectedCategories = _.map(pr.categories, function (x) {
                                            return {
                                                id: x,
                                                priceRoundingId: pr.id,
                                                level: 5,
                                                exclude: false
                                            }
                                        });

                                        pr.categories = selectedCategories;
                                    }
                                    //pongo en el array las categorias excluidas
                                    else if (pr.exarticlesGroup.length > 0) {
                                        selectedCategories = _.map(pr.exarticlesGroup, function (x) {
                                            return {
                                                id: x,
                                                priceRoundingId: pr.id,
                                                level: 5,
                                                exclude: true
                                            }
                                        });

                                        pr.categories = selectedCategories;
                                    }

                                    //pongo en el array las pricelists seleccionadas
                                    var selectedPriceLists = [];
                                    if (pr.priceLists.length > 0) {
                                        selectedPriceLists = _.map(pr.priceLists, function (x) {
                                            return {
                                                priceListId: x,
                                                priceRoundingId: pr.id,
                                                exclude: false
                                            }
                                        });

                                        pr.priceLists = selectedPriceLists;
                                    }
                                    //pongo en el array las priceLists excluidas
                                    else if (pr.expricelists.length > 0) {
                                        selectedPriceLists = _.map(pr.expricelists, function (x) {
                                            return {
                                                priceListId: x,
                                                priceRoundingId: pr.id,
                                                exclude: true
                                            }
                                        });

                                        pr.priceLists = selectedPriceLists;
                                    }
                                });

                                priceService.priceRoundings.savePriceRoundings(self.priceRoundings)
                                    .then(function (priceRoundings) {
                                        swal(translations.SavedRoundingRulesSwal, '', 'success');
                                        self.priceRoundings = priceRoundings;
                                        $state.go('prices.priceRoundings');

                                    }, function (status) {
                                        if (status == 502) {
                                            swal(translations.TheProcessContinues, translations.TheProcessOfContinuousStorageSwal)
                                        }
                                        else {
                                            if (self.isNew)
                                                self.priceRoundings.pop();//quito la ultima porque no se guardo
                                            swal(translations.ErrorTitleSwal, translations.ErrorWhenSavingTheseRoundingRules, "error");
                                        }
                                    });
                            } else {
                                if (self.isNew)
                                    self.priceRoundings.pop();//quito la ultima porque no se guardo

                                $timeout(function () {
                                    swal(translations.ErrorTitleSwal, canSaveResult, "error");
                                }, 100);

                            }
                        }

                    }
                });

        }

        function canSave() {
            /*
            if (self.priceRoundings[0].from != 0 || self.priceRoundings[self.priceRoundings.length - 1].to != null)
                return "la lista de rangos debe comenzar en 0 y terminar en infinito (vacio)";

            for (var i = 0; i < self.priceRoundings.length - 1; i++) {
                if (self.priceRoundings[i].to != self.priceRoundings[i + 1].from)
                    return "verifique que el 'hasta' de un rango coincida con el 'desde' del siguiente";
            }
            */

            return "success";

        }

        self.addNewPriceRounding = function () {

            if (!self.newRoundingAdded) {

                self.newRoundingAdded = true;

                self.priceRounding = {
                    id: 0,
                    priceType: $stateParams.priceTypeId,
                    from: 0,
                    to: 10,
                    roundingTolerance: 0,
                    roundingToleranceP: 0,
                    roundingDecimals: 0,
                    pointsStr: "",
                    priceRoundingType: 1,
                    priceRoundingMidPointType: 0,
                    points: [],
                    allPriceListsChecked: true,
                    priceLists: [],
                    expricelists: [],
                    allArticlesGroupChecked: true,
                    articlesGroup: [],
                    exarticlesGroup: [],
                    categories: []
                };

                self.isNew = true;
            }
            else {
                swal(translations.WarningSwal, translations.YouMustSaveSwal, "warning");
            }

            /*
            self.priceRoundings.push(angular.copy(self.newPriceRounding));
            self.newPriceRounding.from = 0;
            self.newPriceRounding.to = 10;
            self.newPriceRounding.roundingTolerance = 0;
            self.newPriceRounding.roundingToleranceP = 0;
            self.newPriceRounding.roundingDecimals = 0;
            self.newPriceRounding.pointsStr = "";
            self.newPriceRounding.priceRoundingType = 1;
            self.newPriceRounding.priceRoundingMidPointType = 1;
            self.newPriceRounding.points = [];
            */
        }

        self.changeType = function () {
            if (self.selectedPriceType != null)
                $stateParams.priceTypeId = self.selectedPriceType.id;

            loadPriceRoundings();
        }

        self.showInputNewPrice = function () {
            self.isNew = true;
        }

        self.setRoundingTolerance = function (pr) {
            pr.roundingTolerance = pr.roundingToleranceP && pr.roundingToleranceP != null ? pr.roundingToleranceP / 100 : null;
        }

        //points

        self.showPointsEditor = function (pr) {

            self.priceRounding = pr;
            self.unmodifiedPoints = angular.copy(pr.points);

            ngDialog.open({
                template: 'points',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                data: points,
                width: 487,
                showClose: false,
                closeByEscape: false,
                closeByDocument: false,
                scope: $scope

            });
        }

        self.showInputNewTermination = function () {
            self.inputNewTermination = true;
        }

        self.deleteTermination = function (id, point) {
            for (i = 0; i < self.priceRounding.points.length; i++) {

                if (self.priceRounding.points[i].id == id && self.priceRounding.points[i].point == point)
                    self.priceRounding.points.splice([i], 1);
            }
        }

        self.cancelDialog = function () {
            self.priceRounding.points = self.unmodifiedPoints;
            ngDialog.close();
        }

        self.addNewTermination = function () {

            var errorMessage = '';

            //Validamos que las terminaciones sean acorde a la cantidad de decimales ingresados en el redondeo
            if (self.newPointValue != undefined && self.newPointValue >= 0) {

                if (self.priceRounding.roundingDecimals > 0 && self.newPointValue >= 1) {
                    errorMessage = translations.RoundingEndingValidation1;
                }
                else if (self.priceRounding.roundingDecimals == -1 && self.newPointValue >= 100) {
                    errorMessage = translations.RoundingEndingValidation2;
                }
                else if (self.priceRounding.roundingDecimals == -2 && self.newPointValue >= 1000) {
                    errorMessage = translations.RoundingEndingValidation3;
                }
                else if (self.priceRounding.roundingDecimals == -3 && self.newPointValue >= 10000) {
                    errorMessage = translations.RoundingEndingValidation4;
                }
                else if (self.priceRounding.roundingDecimals == -4 && self.newPointValue >= 100000) {
                    errorMessage = translations.RoundingEndingValidation5;
                }

                if (self.priceRounding.roundingDecimals >= 0 && decimalPlaces(self.newPointValue) > self.priceRounding.roundingDecimals) {
                    errorMessage += translations.RoundingEndingValidation6;
                }

                if (errorMessage == '' && self.priceRounding.to != null && self.newPointValue > self.priceRounding.to) {
                    errorMessage += translations.RoundingEndingValidation7 + self.priceRounding.to;
                }

                angular.forEach(self.priceRounding.points, function (p) {
                    if (p.point == self.newPointValue && errorMessage == '') {
                        errorMessage += translations.RoundingEndingValidation8;
                    }
                });

                if (errorMessage == '') {
                    self.priceRounding.points.push({ id: 0, point: self.newPointValue });
                    self.newPointValue = null;
                    self.inputNewTermination = false;
                }
            }
            else {
                errorMessage = translations.RoundingEndingValidation9;
            }

            if (errorMessage != '') {
                swal(translations.ErrorTitleSwal, errorMessage, "error");
            }
        }

        function decimalPlaces(num) {
            var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
            if (!match) { return 0; }
            return Math.max(
                0,
                // Number of digits right of decimal point.
                (match[1] ? match[1].length : 0)
                // Adjust for scientific notation.
                - (match[2] ? +match[2] : 0));
        }

        self.saveTermination = function () {

            self.priceRounding.pointsStr = "";

            angular.forEach(self.priceRounding.points, function (p) {
                self.priceRounding.pointsStr += ' | ' + p.point;
            });

            ngDialog.close();
        }

        function getCategories() {
            adminService.categories.getSubCategories()
                .then(function (c) {
                    self.articlesGroup = c;
                })
        }

        function getPriceLists() {
            priceService.rules.getPriceRulePriceLists().then(function (priceLists) {
                self.priceLists = angular.copy(priceLists);
            });
        }

        self.selectAllArticlesGroupForRule = function (priceRounding) {
            priceRounding.articlesGroup = [];
            priceRounding.exarticlesGroup = [];
            priceRounding.categories = [];
        }

        self.selectAllPriceListsForRule = function (priceRounding) {
            if (self.allPriceListsChecked) {
                priceRounding.priceLists = angular.copy(self.priceLists);
                priceRounding.expricelists = [];
            }
            else {
                priceRounding.priceLists = [];
                priceRounding.expricelists = [];
            }
        }

        //init
        loadPriceRoundings = function () {
            self.showDd = false;
            if ($stateParams.priceTypeId != -1) {//uso -1 en vez de 0 porque 0 es un tipo de precio
                priceService.priceRoundings.getPriceRoundingsByType($stateParams.priceTypeId)
                    .then(function (priceRoundings) {
                        self.priceRoundings = priceRoundings;
                        if (self.priceRoundings.length > 0) {
                            self.title = translations.EditRoundingRuleTitle;
                            //self.newPriceRounding.priceType = $stateParams.priceTypeId;

                            angular.forEach(self.priceRoundings, function (p) {
                                //por defecto checkeado
                                p.allArticlesGroupChecked = true;
                                p.allPriceListsChecked = true;

                                //si de la lista de categorias alguna es no excluida destildo el check
                                angular.forEach(p.categories, function (c) {
                                    if (c.exclude == false) {
                                        p.allArticlesGroupChecked = false;
                                    }
                                });

                                var ids = [];
                                var exids = [];
                                p.categories.forEach(function (el) {
                                    if (el.exclude == false) {
                                        ids = ids.concat(el.id);
                                    }
                                });

                                p.categories.forEach(function (el) {
                                    if (el.exclude == true) {
                                        exids = exids.concat(el.id);
                                    }
                                });

                                p.categories = ids;
                                p.exarticlesGroup = exids;

                                //si de la lista de priceLists alguna es no excluida destildo el check
                                angular.forEach(p.priceLists, function (pl) {
                                    if (pl.exclude == false) {
                                        p.allPriceListsChecked = false;
                                    }
                                });

                                var plids = [];
                                var plexids = [];
                                p.priceLists.forEach(function (el) {
                                    if (el.exclude == false) {
                                        plids = plids.concat(el.priceListId);
                                    }
                                });

                                p.priceLists.forEach(function (el) {
                                    if (el.exclude == true) {
                                        plexids = plexids.concat(el.priceListId);
                                    }
                                });

                                p.priceLists = plids;
                                p.expricelists = plexids;
                            });
                        }
                        else {
                            self.isNew = true;
                            self.title = translations.NewRoundingRuleTitle;
                            self.addNewPriceRounding();
                            //self.newPriceRounding.priceType = $stateParams.priceTypeId;
                        }
                    });

                for (var i = 0; i < self.types.length; i++) {
                    if (self.types[i].id == $stateParams.priceTypeId)
                        self.selectedPriceType = self.types[i];

                }

            }
            else {
                self.title = translations.NewRoundingRuleTitle;
                self.showDd = true;
            }

            getCategories();
            getPriceLists();
        }


        var translations = null;

        function init() {

            priceService.getRoundingDecimalNames().then(
                function (decimalNames) {
                    self.decimalNames = decimalNames;
                });


            $translate(["SaveTheRoundingRulesSwal", "ContinueSwal", "CancelSwal", "SavedRoundingRulesSwal", "TheProcessContinues", "TheProcessOfContinuousStorageSwal", "ErrorTitleSwal", "ErrorWhenSavingTheseRoundingRules", "WarningSwal", "YouMustSaveSwal", "RoundingEndingValidation1", "RoundingEndingValidation2", "RoundingEndingValidation3", "RoundingEndingValidation4", "RoundingEndingValidation5", "RoundingEndingValidation6", "RoundingEndingValidation7", "RoundingEndingValidation8", "RoundingEndingValidation9", "EditRoundingRuleTitle", "NewRoundingRuleTitle", "Markdown", "Promotion"
                , "Competitive", "Regular", "RoundingStandard", "PricePoints", "Terminations", "ToNearest", "ToDown", "ToUp"])
                .then(function (all) {
                    translations = all;

                    self.types = [
                        //{
                        //    id: 0,
                        //    value: "Vigente"
                        //},
                        //{
                        //    id: 1,
                        //    value: "Excepcion"
                        //},
                        {
                            id: 2,
                            value: translations.Markdown
                        },
                        //{
                        //    id: 3,
                        //    value: "Bonus"
                        //},
                        {
                            id: 4,
                            value: translations.Promotion
                        },
                        {
                            id: 5,
                            value: translations.Competitive
                        },
                        {
                            id: 6,
                            value: translations.Regular
                        }
                    ];

                    self.priceRoundingTypes = [
                        {
                            id: 1,
                            value: translations.RoundingStandard
                        },
                        {
                            id: 2,
                            value: translations.PricePoints
                        },
                        {
                            id: 3,
                            value: translations.Terminations
                        }
                    ];

                    self.priceRoundingMidPointTypes = [
                        {
                            id: 0,
                            value: translations.ToNearest
                        },
                        {
                            id: 1,
                            value: translations.ToDown
                        },
                        {
                            id: 2,
                            value: translations.ToUp
                        }
                    ];

                    loadPriceRoundings();
                });


        }

        init();

    });