angular.module("prisma")
    .controller("UsersCtrl", function ($scope, adminService, authService, $translate, $timeout, $stateParams, $filter, ngDialog) {
        var self = this;

        var self = this;

        self.readOnly = authService.hasPermission('admin_security_readOnly');

        //TRADUCCIONES JS
        var assignedStoresSwalTitle = "";
        var assignedStoresSwalSubTitle = "";


        translate = function () {

            //Llamar por cada traduccion
            $translate("AssignedStoresSwalTitle")
                .then(function (translation) {
                    assignedStoresSwalTitle = translation;
                    return translation;
                });
            $translate("AssignedStoresSwalSubTitle")
                .then(function (translation) {
                    assignedStoresSwalSubTitle = translation;
                    return translation;
                });
        }

        translate();


        self.isLoading = true;
        self.isEdit = false;
        self.users = [];
        self.roles = [];
        self.stores = [];
        self.categories = [];
        self.search = '';
        self.searchStore = '';
        self.searchCategory = '';


        self.showChangeRoleDialog = function (user) {

            self.editUser = user;

            ngDialog.open({
                template: 'changeRoleDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            });
        }

        self.showChangePasswordDialog = function (user) {

            self.editUser = user;

            ngDialog.open({
                template: 'changePasswordDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            });
        }

        self.showAssignStoresDialog = function (user) {

            self.editUser = user;

            //marco las tiendas que el usuario ya tiene
            angular.forEach(self.stores, function (store) {
                store.selected = self.userHasStore(self.editUser, store);
            });

            self.searchStore = '';

            ngDialog.open({
                template: 'assignStoresDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            });
        }

        self.showEditUserDialog = function (user) {
            if (user) {
                self.isEdit = true;
                self.editUser = user;
            }
            else {
                self.isEdit = false;
                self.editUser = {
                    email: '',
                    firstname: '',
                    lastname: '',
                    password: '',
                    passwordConfirm: ''
                };
            }

            ngDialog.open({
                template: 'editUserDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            });
        }

        self.showAssignCategoriesDialog = function (user) {

            self.editUser = user;

            //marco las categorias que el usuario ya tiene
            angular.forEach(self.categories, function (cat) {
                cat.selected = self.userHasCategory(self.editUser, cat);
            });

            self.searchCategory = '';

            ngDialog.open({
                template: 'assignCategoriesDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom ngdialog-categories',
                scope: $scope
            });
        }

        self.userHasStore = function (user, store) {

            var hasStore = false;

            angular.forEach(user.stores, function (userStoreId) {
                if (store.id == userStoreId)
                    hasStore = true;
            });

            return hasStore;
        }

        self.userHasCategory = function (user, cat) {

            var hasCat = false;

            angular.forEach(user.categories, function (userCatId) {
                if (cat.id == userCatId)
                    hasCat = true;
            });

            return hasCat;
        }

        self.disableUser = function (user) {
            swal({
                title: "Ingrese una justificación",
                text: "Debe tener 10 caracteres como mínimo",
                type: "input",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (inputValue) {
                    if (inputValue === false) return;
                    if (inputValue.trim().length > 9) {
                        swal({
                            title: ian.format('Esta seguro que desea {0} a {1}? ', user.role == 'Disabled' ? 'Habilitar' : 'Deshabilitar', user.email),
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: user.role == 'Disabled' ? 'Habilitar' : 'Deshabilitar',
                            cancelButtonText: "Cancelar",
                            showLoaderOnConfirm: true,
                            closeOnConfirm: false,
                            closeOnCancel: true
                        },
                            function (isConfirm) {
                                if (isConfirm) {

                                    var disableUserDto = {
                                        userId: user.id,
                                        username: user.email,
                                        observations: inputValue
                                    }

                                    adminService.security.disableUser(disableUserDto).then(function () {
                                        ngDialog.close();

                                        self.isLoading = true;
                                        adminService.security.getUsers().then(function (users) {
                                            self.users = users;
                                            self.isLoading = false;
                                        });

                                        swal(
                                            ian.format('Usuario {0}', user.role == 'Disabled' ? 'Habilitado' : 'Deshabilitado'),
                                            ian.format('se ha {0} correctamente a {1}', user.role == 'Disabled' ? 'habilitado' : 'deshabilitado', user.email),
                                            'success');
                                    });

                                }
                            });
                    }
                    else swal.showInputError('Minimo 10 caracteres');

                });
        }

        self.changeUserRole = function (newRole) {
            swal({
                title: "Ingrese una justificación",
                text: "Debe tener 10 caracteres como mínimo",
                type: "input",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (inputValue) {
                    if (inputValue === false) return;
                    if (inputValue.trim().length > 9) {
                        swal({
                            title: "¿Confirma los cambios?",
                            text: "Se van cambiar el Rol del usuario",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#1AB394",
                            confirmButtonText: "Continuar",
                            cancelButtonText: "Cancelar",
                            showLoaderOnConfirm: true,
                            closeOnConfirm: false,
                            closeOnCancel: true
                        },
                            function (isConfirm) {
                                if (isConfirm) {

                                    var changeRoleDto = {
                                        userId: self.editUser.id,
                                        oldRole: self.editUser.role,
                                        newRole: newRole.name,
                                        Observations: inputValue
                                    }

                                    adminService.security.changeUserRole(changeRoleDto).then(function () {
                                        self.editUser.role = newRole.name;
                                        self.newRole = null;
                                        ngDialog.close();
                                        swal('Se han guardado los cambios', 'Se cambio exitosamente el rol del usuario', 'success');
                                    });

                                }
                            });
                    }
                    else swal.showInputError('Minimo 10 caracteres');

                });
          


        }

        self.resetUserPassword = function (newPassword) {

            swal({
                title: "Ingrese una justificación",
                text: "Debe tener 10 caracteres como mínimo",
                type: "input",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (inputValue) {
                    if (inputValue === false) return;
                    if (inputValue.trim().length > 9) {
                        swal({
                            title: "¿Confirma los cambios?",
                            text: "Se van resetear el password del usuario",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#1AB394",
                            confirmButtonText: "Continuar",
                            cancelButtonText: "Cancelar",
                            showLoaderOnConfirm: true,
                            closeOnConfirm: false,
                            closeOnCancel: true
                        },
                            function (isConfirm) {
                                if (isConfirm) {

                                    var resetUserPasswordDto = {
                                        userId: self.editUser.id,
                                        newPassword: newPassword,
                                        observations: inputValue
                                    }

                                    adminService.security.resetUserPassword(resetUserPasswordDto).then(function () {
                                        ngDialog.close();
                                        swal('Password Reseteado', 'Se reseteo correctamente el password del usuario', 'success');
                                    });

                                }
                            });
                    }
                    else swal.showInputError('Minimo 10 caracteres');

                });
         



        }

        self.assignStoresToUser = function () {
            swal({
                title: "Ingrese una justificación",
                text: "Debe tener 10 caracteres como mínimo",
                type: "input",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (inputValue) {
                    if (inputValue === false) return;
                    if (inputValue.trim().length > 9) {
                        swal({
                            title: "¿Confirma los cambios?",
                            text: "Se van a guardar los cambios en las tiendas del usuario",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#1AB394",
                            confirmButtonText: "Continuar",
                            cancelButtonText: "Cancelar",
                            showLoaderOnConfirm: true,
                            closeOnConfirm: false,
                            closeOnCancel: true
                        },
                            function (isConfirm) {
                                if (isConfirm) {

                                    var assignStoresToUserDto = {
                                        userId: self.editUser.id,
                                        stores: [],
                                        Observations: inputValue
                                    }

                                    angular.forEach(self.stores, function (store) {
                                        if (store.selected)
                                            assignStoresToUserDto.stores.push(store.id);
                                    });

                                    adminService.security.assignStoresToUser(assignStoresToUserDto).then(function () {
                                        ngDialog.close();

                                        self.editUser.stores = [];
                                        angular.forEach(self.stores, function (store) {
                                            if (store.selected)
                                                self.editUser.stores.push(store.id);
                                        });

                                        swal(assignedStoresSwalTitle, assignedStoresSwalSubTitle, 'success');
                                    });
                                }
                            });
                    }
                    else swal.showInputError('Minimo 10 caracteres');

                });
           

        }

        self.assignCategoriesToUser = function () {
            swal({
                title: "Ingrese una justificación",
                text: "Debe tener 10 caracteres como mínimo",
                type: "input",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (inputValue) {
                    if (inputValue === false) return;
                    if (inputValue.trim().length > 9) {
                        swal({
                            title: "¿Confirma los cambios?",
                            text: "Se van cambiar las categorias del usuario",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#1AB394",
                            confirmButtonText: "Continuar",
                            cancelButtonText: "Cancelar",
                            showLoaderOnConfirm: true,
                            closeOnConfirm: false,
                            closeOnCancel: true
                        },
                            function (isConfirm) {
                                if (isConfirm) {

                                    var assignCategoriesToUserDto = {
                                        userId: self.editUser.id,
                                        categories: [],
                                        Observations: inputValue
                                    }

                                    angular.forEach(self.categories, function (cat) {
                                        if (cat.selected)
                                            assignCategoriesToUserDto.categories.push(cat.id);
                                    });

                                    adminService.security.assignCategoriesToUser(assignCategoriesToUserDto).then(function () {
                                        ngDialog.close();

                                        self.editUser.categories = [];
                                        angular.forEach(self.categories, function (cat) {
                                            if (cat.selected)
                                                self.editUser.categories.push(cat.id);
                                        });

                                        swal('Categorias Asignadas', 'Se asignaron correctamente las categorias al usuario', 'success');
                                    });

                                }
                            });
                    }
                    else swal.showInputError('Minimo 10 caracteres');

                });


        }

        self.saveUser = function () {
            if (!self.isEdit) {
                if (self.editUser.password != self.editUser.passwordConfirm) {
                    swal('Datos Invalidos', 'La contraseña no coincide con la confirmacion', 'error');
                    return;
                }

                if (!self.editUser.email || self.editUser.email.indexOf('@') == -1) {
                    swal('Datos Invalidos', 'El email es invalido', 'error');
                    return;
                }

                if (!self.editUser.password.match(/[0-9]/g) || !self.editUser.password.match(/[a-zA-Z]/g)) {
                    swal('Error', 'La contraseña debe tener al menos un numero y una letra', 'error');
                    return;
                }

                if (self.editUser.password.length < 6) {
                    swal('Error', 'La contraseña debe tener al menos 6 caracteres', 'error');
                    return;
                }


                swal({
                    title: "Ingrese una justificación",
                    text: "Debe tener 10 caracteres como mínimo",
                    type: "input",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: "Continuar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: false,
                    closeOnCancel: true
                },
                    function (inputValue) {
                        if (inputValue === false) return;
                        if (inputValue.trim().length > 9) {
                            self.observations = inputValue;
                            doSave();
                        }
                        else swal.showInputError('Minimo 10 caracteres');

                    });
            } else doSave();

        }

        function doSave() {
            swal({
                title: "¿Confirma los cambios?",
                text: self.isEdit ? "Se van los cambios al usuario" : "Se va a crear un nuevo usuario",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        self.editUser.observations = self.observations;
                        adminService.security.saveUser(self.editUser).then(function () {
                            ngDialog.close();

                            if (self.isEdit)
                                swal('Cambios Guardados', 'Se guardaron correctamente los datos del usuario', 'success');
                            else
                                swal('Usuario Creado', 'Se ha creado el nuevo usuario en el sistema', 'success');

                            //reload
                            init();

                        });

                    }
                });
        }

        self.selectAllStores = function () {
            var filteredStores = $filter('filter')(self.stores, self.searchStore);

            var doSelect = undefined;
            angular.forEach(filteredStores, function (store) {
                if (doSelect == undefined)
                    doSelect = !store.selected;

                store.selected = doSelect;
            });
        }

        self.selectAllCategories = function () {
            var filteredCategories = $filter('filter')(self.categories, self.searchCategory);

            var doSelect = undefined;
            angular.forEach(filteredCategories, function (cat) {
                if (doSelect == undefined)
                    doSelect = !cat.selected;

                cat.selected = doSelect;
            });
        }

        function init() {
            adminService.security.getRoles().then(function (roles) {
                self.roles = roles;

                adminService.security.getStores().then(function (stores) {
                    self.stores = stores;

                    adminService.security.getCategories().then(function (categories) {
                        self.categories = categories;

                        adminService.security.getUsers().then(function (users) {
                            self.users = users;
                            self.isLoading = false;
                        });
                    });


                });

            });

        }

        init();
    });