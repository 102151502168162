angular.module('prisma').component('planogramShelfComponent', {
    templateUrl: 'app/spaces/planograms/components/planogramShelf.html',
    controller: planogramShelfComponentController,
    bindings: {
        planogram: '=',
        column: '=',
        renderpiechartsparent: '=',
        selecteditem: '=',
        filtereditems: '=',
        selectedlevelparent: "=",
        selectedplanogramparent: "=",
        sales: '=',
        showloadquantity: '=',
        showregularprice: '=',
        showexhibitiondays: '=',
        showdaysinstock: '=',
        selectedbrand: '=',
        selectedmanufacturer: '=',
        selectedtagfilter: '=',
        heatmapenabled: '=',
        groupmapenabled: '=',
        showdoor: '=',
        autostack: '=',
        hideitems: '=',
        showlevelmeasurebuttons: '=',
        countplanogramitems: '=',
        shownewticketmodal: '=',
        zoom: '=',
    }
});


function planogramShelfComponentController($scope, $state, $timeout, $element, $stateParams, $translate, authService, spaceService) {
    var self = this;
    self.search = "";
    self.categoryFilter = '!null';
    self.selectedStatus = 'None';
    self.showGlobesId = 0;
    self.planogramId = $stateParams.planogramId;

    var $firstUiView = $element.parents('[ui-view]:first');
    self.editPlanogramCtrl = $firstUiView.controller();

    var onGlobalClick = function (e) {
        if (angular.isDefined(e.toElement)
            && angular.isDefined(e.toElement.className)
            && e.toElement.className.indexOf('planogramLevel') > -1
            && e.toElement.className !== 'dropDown-button'
            && self.showGlobesId !== null
            && self.showGlobesId !== undefined
            && self.showGlobesId !== 0) {
            self.showGlobesId = 0;
        }
    };

    $("body").on("click", onGlobalClick);

    $scope.$on("$destroy", function () {
        $("body").off("click", onGlobalClick);

    });

    self.hasPermissionToEdit = authService.hasPermission('spaces_edit_planograms');
    self.selectedGlobeItem = null;
    self.selectedGlobeLevel = null;

    $scope.$watch('$ctrl.planogram', function (newVal, oldVal) {
        onSizeChanged();
    }, true);

    $scope.$watch('$ctrl.zoom', function (newVal, oldVal) {
        onSizeChanged();
    }, true)

    $scope.$watch('$ctrl.column', function (newVal, oldVal) {
        onSizeChanged();
    }, true);

    self.isEdit = function () {
        return $state.current.url.contains('edit');
    }

    self.levelClick = function (level) {
    }

    function renderSizes() {
        angular.forEach(self.planogram.levels, function (level) {
            angular.forEach(level.items, function (item) {
                self.calcItemSize(item, level, self.planogram);
            });
        });
    }
    self.calcItemSize = function (item, level, planogram) {
        item.widthPx = self.calcItemWidth(item, level, planogram);
        item.heightPx = self.calcItemHeight(item, level, planogram);
        item.depthPx = self.calcItemDepth(item, level, planogram);
    }

    self.calcItemWidth = function (item, level, planogram) {

        var levelWidthPx = self.calcLevelWidth(level, planogram);

        var widthPx = ((item.selectedUnitOfMeasure.width) * levelWidthPx) / planogram.width;

        return widthPx;
    }

    self.calcItemHeight = function (item, level) {

        var heightPx = item.selectedUnitOfMeasure.height * item.widthPx / item.selectedUnitOfMeasure.width;

        return heightPx;
    }

    self.calcItemDepth = function (item, level) {

        var depthPx = item.selectedUnitOfMeasure.depth * item.widthPx / item.selectedUnitOfMeasure.width;

        return depthPx;
    }

    self.calcLevelWidth = function (level, planogram) {

        var widthPx = 0;

        var leveldiv = document.getElementById('columnOrder_' + self.column.order + 'moduleOrder_' + planogram.order + 'planogramLevel_' + level.levelNumber);

        if (leveldiv) {
            widthPx = leveldiv.clientWidth;
        } else {
            documentDiv = document.getElementById('columnOrder_' + self.column.order + 'moduleOrder_' + planogram.order)
            if (documentDiv) widthPx.clientWidth;
        }

        return widthPx;
    }

    self.getItemColor = function (item) {
        if (self.heatmapenabled) {
            return item.fill;
        } else if (self.groupmapenabled) {
            return item.fill;
        }
        return 'none';
    }

    self.selectItem = function (item) {
        self.selecteditemId = null;
        if (item == null || (self.selecteditem && item.id == self.selecteditem.id)) {
            //deselect
            self.selecteditem = null;
            self.filtereditems = [];
            self.search = '';

            //Intervencion para pasar al editPlanogram
            self.editPlanogramCtrl.setActiveShelf(null);

        }
        else {
            self.selecteditem = item;
            self.selectedbrand = '!null';
            self.selectedmanufacturer = '!null';
            self.categoryfilter = '!null';
            self.selectedstatus = '!null';
            self.selectedtagfilter = null;

            self.filtereditems = [];
            self.filtereditems.push(item);

            console.log('self.selectedItem', self.selecteditem)
            findItem(function (planogram, level, _item) {
                if (_item.id == item.id) {
                    self.selectedPlanogram = planogram;
                    //self.selectedplanogramparent = planogram;
                    self.selectedLevel = level;
                    self.selectedlevelparent = level;
                }
            });

            


            //resalto el item en la lista
            //self.search = item.name;

            //renderizo los charts
            self.renderpiechartsparent([item]);

            //}

            //Intervencion para pasar al editPlanogram
            self.editPlanogramCtrl.setActiveShelf(self);
        }
    }

    self.getLevelJustification = function (level) {
        //LG: Pedido por cliente de justificar el espaciado
        
        var usedWidth = self.getUsedWidth(level, self.planogram);

        if (usedWidth / self.planogram.width > 0.8)
            return 'space-around';

        return 'flex-start';
    }

    function findItem(callbackFunction) {
        //angular.forEach(self.planograms, function (planogram) {
        angular.forEach(self.planogram.levels, function (level) {
            angular.forEach(level.items, function (item) {
                callbackFunction(self.planogram, level, item);
            });
        });
        //});

    }

    self.itemDroppedInLevel = function (index, item, UOM, external, type, level, planogram) {

        if (item.planogramLevelId != level.id) {
            //console.log('DROPPED - LEVEL CHANGE');

            if (!UOM.height || !UOM.width || !UOM.depth) {
                swal(translations.WithoutSize, translations.WithoutSizeSubtitle, 'warning');
                return false;
            }
            if ((UOM.height * (item.stackAbove ? item.stackAbove : 1)) > level.height) {
                swal(translations.InsufficientSpaceSwal, translations.InsufficientHeightSpaceSubtitleSwal, 'warning');
                return false;
            }

            var usedWidth = self.getUsedWidth(level, planogram);

            //if (usedWidth + UOM.width + (level.itemPadding * 2) > planogram.width) {
            if ((usedWidth + (UOM.width * (item.facings ? item.facings : 1))) > planogram.width) {
                swal(translations.InsufficientSpaceSwal, translations.InsufficientWidthSpaceSubtitleSwal, 'warning');
                return false;
            }
            //bug -> level.category is wrong
            //if (level.categoryId != null && item.categoryId != level.categoryId) {
            //    swal(incorrectCategorySwal, incorrectCategorySubtitleSwal + level.categoryName + '', 'warning');
            //    return false;
            //}

            if (level.categoryId != null && item.categoryId != level.categoryId) {
                swal(translations.IncorrectCategorySwal, translations.IncorrectCategorySubtitleSwal + level.categoryName + '', 'warning');
                return false;
            }

            self.stateChange = true;
            item.planogramLevelId = level.id;
            item.id = guid();
            item.uniqueId = guid();
            if (!item.facings) item.facings = 1;
            item.selectedUnitOfMeasureId = UOM.id;
            item.selectedUnitOfMeasure = UOM;
            //item.height = UOM.height;
            //item.width = UOM.width;
            //item.depth = UOM.depth;
            if (self.autostack) {
                item.stackBehind = getItemStack(item, level, planogram, 'behind');
                item.stackAbove = getItemStack(item, level, planogram, 'above');
                if (!item.stackBehind || !item.stackAbove)
                    return false;
                item.stackTotal = calcLoadQuantity(item, level, planogram);
               
            } else {
                item.stackAbove = item.stackAbove ? item.stackAbove : 1;
                item.stackBehind = item.stackBehind ? item.stackBehind : 1;
                item.stackTotal = calcLoadQuantity(item, level, planogram);
            }
            item.order = index;

        } else {

            //console.log('DROPPED');
            self.stateChange = true;
            item.order = index;
        }

        item.textTooltipItemOrientation = translations.ShowProfileItem;

        //order items
        //angular.forEach(self.planogram, function (_planogram) {
        angular.forEach(self.planogram.levels, function (_level) {
            if (level.id == _level.id) {
                let _items = _level.items
                _items.sort(function (a, b) {
                    if (a.order > b.order) {
                        return 1;
                    }
                    if (a.order < b.order) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                });
                angular.forEach(_level.items, function (_item, i) {
                    _item.order = i;
                    console.log(_item.order, _item.name);
                })
            }

        });
        //})

        return item;
        //$scope.$apply();
    };

    self.itemInsertedInLevel = function (index, item, external, type, level, planogram) {
        //console.log('INSERTED');

        self.stateChange = true;
        self.calcItemSize(item, level, planogram);
        self.countplanogramitems();
        $timeout(function () {
            groupItem(index, item, level, planogram);
        }, 20)

    }

    self.itemMovedFromLevel = function (index, level, item) {
        //level.items.splice($index, 1)

        var shouldRemove = true;

        if (shouldRemove) {
            _.remove(level.items, function (i) {
                return i.$$hashKey === item.$$hashKey;
            });
        }

    }

    function groupItem(index, item, level, planogram) {
        //console.log('gropItem', level.items[index - 1], level.items[index + 1], index, item,level)
        var indexRemove = -1
        var sumFacings = 0;
        if (level.items[index - 1] && item.uniqueId == level.items[index - 1].uniqueId) return

        if (level.items[index + 1] && item.uniqueId == level.items[index + 1].uniqueId) return

        if (level.items[index - 1] && item.itemId == level.items[index - 1].itemId) {
            sumFacings = level.items[index - 1].facings + item.facings;
            indexRemove = index - 1;
        }
        if (level.items[index + 1] && item.itemId == level.items[index + 1].itemId) {
            sumFacings = level.items[index + 1].facings + item.facings;
            indexRemove = index + 1;
        }
        $timeout(function () {
            if (indexRemove >= 0 && sumFacings) {
                item.facings = sumFacings;
                //issue #92 TATA Uruguay
                //si recalcule facings tengo que recalcular stackTotal
                item.stackTotal = calcLoadQuantity(item, level, planogram);
                level.items.splice(indexRemove, 1);
            }
        }, 40);


    }

    self.getUsedWidth = function (level, planogram)
    {
        var usedWidth = 0;
        angular.forEach(level.items, function (item) {
            //usedWidth += ((item.selectedUnitOfMeasure.width + (level.itemPadding * 2)) * item.facings);
            usedWidth += (item.selectedUnitOfMeasure.width * item.facings);
        });

        //sumo el padding
        //usedWidth += level.items.length * level.itemPadding * 2;

        level.usedWidth = usedWidth;
        return usedWidth;
    }

    self.setUsedHeight = function (level, planogram) {
        level.minHeight = 0;
        angular.forEach(level.items,
            function (item) {
                if ((item.selectedUnitOfMeasure.height * item.stackAbove) >
                    level.minHeight)
                    level.minHeight = (item.selectedUnitOfMeasure.height *
                        item.stackAbove);
            });
    }

    self.createNewArray = function (num) {
        //console.log('numero into getNumber', num)
        if (num) {
            return new Array(num);
        } else {
            return new Array(1);
        }
    }

    self.showBubles = function (item, level) {
        if (item.id == self.showGlobesId) {
            self.showGlobesId = 0;
            self.selectedGlobeItem = null;
            self.selectedGlobeLevel = null;
        } else {
            self.showGlobesId = item.id;
            self.selectedGlobeItem = item;
            self.selectedGlobeLevel = level;
        }
    }
    //self.goToTickets = function (planogram, item) {
    //    //console.log('item', item);
    //    $state.go('spaces.tickets', { planogramLevelItemId: item.id, layoutObjectId: 0 });
    //}


    self.showIconMinus = function (item) {
        return item.facings > 1 ? true : false
    }

    self.hasLayDownItem = function (item) {
        return angular.isDefined(item.layDownItem) && item.layDownItem > 0;
    }

    //COMENTO LAS FUNCIONES DE LAS BURBUJAS PORQUE VAN A PASAR AL EDITPLANOGRAM

    //self.addFrontItem = function (item, level, planogram, toAdd) {
    //    var usedWidth = self.getUsedWidth(level, planogram);

    //    if (toAdd) {
    //        //if (usedWidth + item.selectedUnitOfMeasure.width + (level.itemPadding * 2) > planogram.width) {
    //        if (usedWidth + item.selectedUnitOfMeasure.width > planogram.width) {
    //            swal(translations.InsufficientSpaceSwal, translations.InsufficientWidthSpaceSubtitleSwal, 'warning');
    //            return;
    //        }
    //    }

    //    if (toAdd) {
    //        item.facings += 1
    //    } else {
    //        if (item.facings == 1) {
    //            return
    //        } else {
    //            item.facings -= 1
    //        }

    //    }

    //    item.stackTotal = calcLoadQuantity(item, level, planogram);

    //    self.stateChange = true;
    //    //return true;
    //}

    //self.addItemBehind = function (item, level, planogram, toAdd) {

    //    var usedDepth = item.selectedUnitOfMeasure.depth * item.stackBehind;

    //    if (toAdd) {
    //        if (usedDepth + item.selectedUnitOfMeasure.depth > planogram.depth) {
    //            swal(translations.InsufficientSpaceSwal, translations.InsufficientWidthSpaceSubtitleSwal, 'warning');
    //            return;
    //        }
    //    }

    //    if (toAdd) {
    //        item.stackBehind += 1
    //    } else {
    //        if (item.stackBehind == 1) {
    //            return
    //        } else {
    //            item.stackBehind -= 1
    //        }

    //    }

    //    item.stackTotal = calcLoadQuantity(item, level, planogram);

    //    self.stateChange = true;
    //    //return true;
    //}

    //self.addItemAbove = function (item, level, planogram, toAdd) {

    //    if (toAdd) {
    //        //agrega un item al tope de la pila

    //        swal({
    //            title: translations.AddLayDownItemSwal,
    //            type: "warning",
    //            showCancelButton: true,
    //            confirmButtonColor: "#DD6B55",
    //            confirmButtonText: translations.Laydown,
    //            cancelButtonText: translations.Standup,
    //            showLoaderOnConfirm: false,
    //            closeOnConfirm: false,
    //            closeOnCancel: false
    //        },
    //            function (isLayDown) {
    //                //calculo la altura actual considerando acostados y parados
    //                var layDownItemsHeight = item.layDownItem ? item.layDownItem * item.selectedUnitOfMeasure.depth : 0;
    //                var usedHeight = (item.selectedUnitOfMeasure.height * item.stackAbove) + layDownItemsHeight;
    //                var heightRequested = isLayDown ? usedHeight + item.selectedUnitOfMeasure.depth : usedHeight + item.selectedUnitOfMeasure.height;

    //                if (heightRequested > level.height) {
    //                    //no hay espacio
    //                    swal(translations.InsufficientSpaceSwal, translations.InsufficientHeightSpaceSubtitleSwal, 'warning');
    //                }
    //                else {
    //                    swal("Se agrego el item", "Agregado", 'success');

    //                    if (isLayDown) {
    //                        item.layDownItem = angular.isDefined(item.layDownItem) ? (item.layDownItem + 1) : 1;
    //                        $scope.$apply();
    //                    } else {
    //                        item.stackAbove += 1;
    //                        $scope.$apply();
    //                    }
    //                }
    //            });

    //    } else {
    //        //quita el ultimo item de la pila

    //        if (item.stackAbove == 1) {
    //            if (angular.isDefined(item.layDownItem) && item.layDownItem > 0) {
    //                item.layDownItem -= 1;
    //            }
    //        } else if (item.stackAbove > 0){
    //            item.stackAbove -= 1;
    //        }
    //    }

    //    item.stackTotal = calcLoadQuantity(item, level, planogram);
    //    self.stateChange = true;
    //}

    //self.rotateItem = function (item, level, planogram) {
    //    if (item.rotation) {
    //        item.rotation = undefined;
    //    }
    //    else {
    //        item.rotation = 90;
    //    }

    //}

    //self.changeFront = function (item, level, planogram) {
    //    self.stateChange = true;
    //    if (!item.showMode) {
    //        item.showMode = 'S';
    //        item.textTooltipItemOrientation = translations.ShowItemAbove;
    //    } else if (item.showMode == 'S') {
    //        item.showMode = 'T';
    //        item.textTooltipItemOrientation = translations.ShowItemInfront;
    //    } else if (item.showMode == 'T') {
    //        item.showMode = undefined;
    //        item.orientation = 0;
    //        item.textTooltipItemOrientation = translations.ShowProfileItem;
    //    }
    //}

    //self.itemToFront = function (item, level, planogram) {
    //    self.stateChange = true;
    //    item.showMode = undefined;
    //    item.orientation = 0;
    //}

    //self.itemToSide = function (item, level, planogram) {
    //    self.stateChange = true;
    //    item.showMode = 'S';
    //}

    //self.itemToTop = function (item, level, planogram) {
    //    self.stateChange = true;
    //    item.showMode = 'T';
    //}

    //self.deleteItem = function (item, level) {
    //    swal({
    //        title: translations.SureDeleteItemSwal + ' ' + item.name + '?',
    //        type: "warning",
    //        showCancelButton: true,
    //        confirmButtonColor: "#DD6B55",
    //        confirmButtonText: translations.RemoveSwal,
    //        cancelButtonText: translations.CancelSwal,
    //        showLoaderOnConfirm: true,
    //        closeOnConfirm: true,
    //        closeOnCancel: true
    //    },
    //        function (isConfirm) {
    //            if (isConfirm) {
    //                level.items.splice(level.items.indexOf(item), 1);
    //                self.countplanogramitems();
    //                self.stateChange = true;
    //                $scope.$apply();
    //            }

    //        });
    //}

    function calcLoadQuantity(item, level, planogram) {
        if (item == null) return;
        if (level == null) return;
        if (planogram == null) return

        return Math.round(item.stackBehind * item.stackAbove * item.facings);

    }

    function getItemStack(item, level, planogram, direction) {
        if (item == null) return;
        if (item.selectedUnitOfMeasure == null) return;
        if (level == null) return;
        if (planogram == null) return
        //de frente sin rot
        var alto = item.selectedUnitOfMeasure.height;
        var ancho = item.selectedUnitOfMeasure.width;
        var profundidad = item.selectedUnitOfMeasure.depth;
        // de frente rot 90
        if (!item.showMode && item.rotation === 90) {
            alto = item.selectedUnitOfMeasure.width;
            ancho = item.selectedUnitOfMeasure.height;
            profundidad = item.selectedUnitOfMeasure.depth;
        }
        //lateral sin rot
        if (!item.showMode === 'S' && !item.rotation) {
            alto = item.selectedUnitOfMeasure.selectedUnitOfMeasure.height;
            ancho = item.selectedUnitOfMeasure.depth;
            profundidad = item.selectedUnitOfMeasure.width;
        }
        //lateral rot 90
        if (!item.showMode === 'S' && item.rotation === 90) {
            alto = item.selectedUnitOfMeasure.depth;
            ancho = item.selectedUnitOfMeasure.heigh;
            profundidad = item.selectedUnitOfMeasure.width;
        }
        //superior sin rot
        if (!item.showMode === 'T' && !item.rotation) {
            alto = item.selectedUnitOfMeasure.height;
            ancho = item.selectedUnitOfMeasure.width;
            profundidad = item.selectedUnitOfMeasure.depth;
        }
        //superior rot 90
        if (!item.showMode === 'T' && item.rotation === 90) {
            alto = item.selectedUnitOfMeasure.depth;
            ancho = item.selectedUnitOfMeasure.width;
            profundidad = item.selectedUnitOfMeasure.height;
        }

        if (direction == 'behind') {
            if (planogram.depth < profundidad) {//issue #86 tata uruguay
                swal(translations.InsufficientSpaceSwal, translations.InsufficientDepthSpaceSubtitleSwal, 'warning');
                return;
            }
            return Math.floor(planogram.depth / profundidad);
        }

        if (direction == 'above') {
            return Math.floor(level.height / alto)
        }
    }


    function calculateSales() {
        angular.forEach(self.planograms, function (planogram) {
            angular.forEach(planogram.module.levels, function (level) {
                angular.forEach(level.items, function (item) {
                    for (var i = 0; i < self.sales.length; i++) {
                        //console.log('self.sales[i].itemId', self.sales[i].itemId, 'item.id', item.id, self.sales[i].itemId == item.itemId);
                        //console.log('self.sales[i].storeId', self.sales[i].storeId, 'self.selectedStore.id', self.selectedStore.id, self.sales[i].storeId == self.selectedStore.id);
                        if (self.selectedStore && self.sales[i].itemId == item.itemId
                            && self.sales[i].storeId == self.selectedStore.id) {
                            item.salesInStore = self.sales[i].sales;
                            item.marginInStore = self.sales[i].margin;
                            item.unitsInStore = self.sales[i].units;
                            item.priceInStore = self.sales[i].price;
                            item.gmroiInStore = self.sales[i].gmroi;
                            item.stockBreaksInStore = self.sales[i].stockBreaks;
                            item.stockInStore = self.sales[i].inventoryUnits;
                            //console.log('item', item);
                        }
                    }

                    item.gmros = item.margin / item.selectedUnitOfMeasure.width * item.selectedUnitOfMeasure.depth / 10000;

                });
            });
        });

    }

    function guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    self.editHeightLevel = function (level, bool) {
        if (bool) {
            ++level.height
        } else {
            if (!level.minHeight) {
                    self.setUsedHeight(level, self.planogram);
            }
            if (level.height - 1 < Math.ceil(level.minHeight)) {
                swal(translations.HeightNotAllowed, translations.HeightNotAllowedSubtitle + Math.ceil(level.minHeight), 'warning');
                return
            } else {
                --level.height
            }
        }
        var suma = 0
        angular.forEach(self.planogram.levels, function (level, i) {
            //console.log('level', level, i)
            level.levelNumber = i + 1;
            suma += level.height
        })
        self.planogram.height = suma
    }

    self.deleteLevel = function (level) {
        console.log('deleteLevel', level)
        if (level.items.length) {
            swal(translations.LevelWithItems, translations.LevelWithItemsSubtitle, 'warning');
            return
        }
        var i = -1
        angular.forEach(self.planogram.levels, function (levelPlanogram, index) {
            if (level.levelNumber == levelPlanogram.levelNumber) {
                i = index
            }
        })
        if (i != -1) {
            self.planogram.levels.splice(i, 1);
        }
        var suma = 0
        angular.forEach(self.planogram.levels, function (level, i) {
            //console.log('level', level, i)
            level.levelNumber = i + 1;
            suma += level.height
        })
        self.planogram.height = suma
    }

    self.getItemStyles = function (item, isHideItems) {

        //Analizo la coleccion de FilteredItems que viene del control padre
        var applyOpacity = true;
        if (self.filtereditems && self.filtereditems.length > 0) {
            angular.forEach(self.filtereditems, function (filteredItem) {
                if (applyOpacity && item.itemId == filteredItem.itemId) {
                    applyOpacity = false;
                }
            });
        }
        else {
            if (self.selectedbrand != '!null'
                || self.selectedmanufacturer != '!null'
                || self.selectedtagfilter != null)
                applyOpacity = true;
            else
                applyOpacity = false;
        }

        var styles = {
            'background-color': self.getItemColor(item),
            'opacity': isHideItems ? '0.3' : !applyOpacity ? '1' : '.14',
        }

        return styles;
    }

    self.getItemDuplicateStyles = function (item, level, planogram) {

        var paddingPx = level.paddingItemsPx;

        var styles = {
            'width': item.widthPx + 'px',
            'height': item.heightPx + 'px',
            //'margin-left': paddingPx + 'px',
            //'margin-right': paddingPx + 'px'
        }

        if (item.showMode === 'T') {
            styles.height = item.depthPx + 'px';
        }

        if (item.showMode === 'S') {
            styles.width = item.depthPx + 'px';
        }

        if (item.rotation) {
            styles['transform'] = 'rotate(90deg)';
            styles['transform-origin'] = 'bottom right';
            //styles['margin-left'] = -item.widthPx + 'px';
        }

        if (self.heatmapenabled || self.groupmapenabled) {
            styles['opacity'] = item.opacity;
        }

        return styles;
    }

    self.getListItemStyles = function (item, level, planogram) {
        var paddingPx = level.paddingItemsPx;
        var styles = {
            //'margin-left': paddingPx + 'px',
            //'margin-right': paddingPx + 'px'
        }

        if (item.rotation) {
            styles['width'] = item.heightPx + 'px'
        }
        else {
            //styles['width'] = (item.widthPx + (2 * paddingPx)) * item.facings + 'px';
            styles['width'] = item.widthPx * item.facings + 'px';
            styles['background-color'] = 'transparent';
            styles['height'] = level.heightPx + 'px';
        }

        return styles;
    }

    function getPaddingItems(level, planogram) {
        var pxMax = self.calcLevelWidth(level, planogram)
        var paddingItemsPx = cmToPX(level.itemPadding, planogram.width, pxMax);
        return paddingItemsPx
    }

    function getLevelHeightPX(level, module) {
        //var withModuleMax = self.calcLevelWidth(level, module)
        //var heightPx = cmToPX(level.height, module.width, withModuleMax);
        var heightPx = level.height * module.heightPx / module.height;
        return heightPx;
    }

    function getTotalHeightPX(planogram) {

        var widthPx = 0;

        var planogramdiv = document.getElementById('columnOrder_' + self.column.order + 'moduleOrder_' + planogram.order);

        if (planogramdiv) {
            planogram.widthPx = planogramdiv.clientWidth;
        }
        var heightPx = (planogram.height * planogram.widthPx / planogram.width) + (planogram.levels.length * 7);
        return heightPx;
    }

    function cmToPX(cm, cmMax, PXMax) {
        var widthPx = ((cm) * PXMax) / cmMax;
        return widthPx;
    }

    function calWidthFurniture(furniture, planogram) {
        var percentageWidth = furniture.width / planogram.width * 100;
        return percentageWidth;
    }

    function onSizeChanged() {

        self.planogram.heightPx = getTotalHeightPX(self.planogram)

        angular.forEach(self.planogram.planogramFurnitures, function (_planogramFurniture) {
            _planogramFurniture.percentageWidth = calWidthFurniture(_planogramFurniture.furniture, self.planogram)
        });

        angular.forEach(self.planogram.levels, function (_level) {
            _level.heightPx = getLevelHeightPX(_level, self.planogram)
            _level.paddingItemsPx = getPaddingItems(_level, self.planogram)
        });

        renderSizes()

    }

    function initEvents() {

        angular.element(window).on("resize.doResize", _.throttle(function () {

            $scope.$apply(function () {
                onSizeChanged();
            });
        }, 500));

        $scope.$on("$destroy", function () {
            angular.element(window).off("resize.doResize"); //remove the handler added earlier
        });
    }

    self.updateItemData = function () {
        //Recorro cada item de cada nivel en el planograma.
        angular.forEach(self.planogram.levels, function (level) {
            angular.forEach(level.items, function (item) {

                //1. inicializo el valor del tooltip con la traduccion.
                item.textTooltipItemOrientation = translations.ShowProfileItem;
            });
        });
    }

    var translations = null;

    function init() {
        $translate(["InsufficientSpaceSwal", "InsufficientHeightSpaceSubtitleSwal", "InsufficientWidthSpaceSubtitleSwal", "InsufficientDepthSpaceSubtitleSwal", "IncorrectCategorySwal", "IncorrectCategorySubtitleSwal", "CancelSwal", "SureDeleteItemSwal", "RemoveSwal", "WithoutSize", "WithoutSizeSubtitle", "AddLayDownItemSwal", "ShowProfileItem", "ShowItemInfront", "ShowItemAbove", "Accept", "Cancel", "HeightNotAllowed", "HeightNotAllowedSubtitle", "LevelWithItems", "LevelWithItemsSubtitle", "Laydown", "Standup"])
            .then(function (all) {
                translations = all;

                self.updateItemData();

                initEvents();

                onSizeChanged();

                if (self.countplanogramitems) self.countplanogramitems();
            });
    }

    $timeout(function () {
        init();
    }, 25);

}


// Issue 275
// Link: https://stackoverflow.com/a/24963384
angular.module("prisma").directive('img', function ($q, $window, imageService) {
    function setImage(element) {
        var w = element.width();
        var h = element.height();
        // using 20 here because it seems even a missing image will have ~18px width 
        // after this error function has been called
        if (w <= 20) { w = 150; }
        if (h <= 20) { h = 150; }

        // var url = 'https://prismablob.blob.core.windows.net/cencosudperu/no-image.gif';
        // var url = 'http://placehold.it/' + w + 'x' + h + '/293846/a7b1c2&text=Prisma';
        var url = '//placehold.it/' + w + 'x' + h + '/293846/a7b1c2&text=Prisma';

        // var url = 'https://via.placeholder.com/' + w + 'x' + h + '/ffffff/000000&text=ITEM';
        element.prop('src', url);
        //element.css('border', 'double 3px #cccccc');

    }

    return {
        restrict: 'E',
        link: function (scope, element, attrs) {
            attrs.$observe('ngSrc', function (value) {
                /*
                var deferred = $q.defer();
                var image = new Image();
                var $image = angular.element(image);
                $image.on('error', function () {
                    deferred.resolve(false);
                    setImage(element);
                });
                $image.on('load', function () {
                    deferred.resolve(true);
                });
                $image.prop('src', value);
                return deferred.promise;
                */
                return imageService.load(value)
                    .then(function () {
                        return true;
                    })
                    .catch(function () {
                        setImage(element);
                        return false;
                    });
            });
            angular.element($window).on('resize', function () {
                var src = element.prop('src');
                if (src.indexOf('placehold.it') > -1) {
                    setImage(element);
                }
            });
        }
    };
});
