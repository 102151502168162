'use strinct';
(function () {
    angular.module('prisma').factory('imageService', ['$log', '$q', imageService]);

    function imageService($log, $q) {
        //$log.debug('imageService::ctor');
        var cache = {};

        function loadFn(imageUrl) {
            // Fix issue performance planograma.
            // $log.debug('imageService::load imageUrl: %o', imageUrl);

            // Si la referencia no esta almacenada intenta cargar la imagen de manera controlada.
            if (!cache[imageUrl]) {
                var deferred = $q.defer();
                var image = new Image();
                var $image = angular.element(image);
                $image.on('load', function imageServiceLoadOnLoad() {
                    // Fix issue performance planograma.
                    // $log.debug('imageService::load (image::load)');
                    deferred.resolve();
                    delete cache[imageUrl].img;
                }).on('error', function imageServiceLoadOnError() {
                    // Fix issue performance planograma.
                    // $log.debug('imageService::load (image::error)');
                    deferred.reject();
                    delete cache[imageUrl].img;
                });
                $image.prop('src', imageUrl);
                cache[imageUrl] = {
                    promise: deferred.promise,
                    img: $image
                };
            }

            return cache[imageUrl].promise;
        }

        return {
            load: loadFn
        }
    }
})();