angular.module('prisma').component('photoAuditComponent', {
    templateUrl: 'app/spaces/planograms/components/photoAudit.html',
    controller: photoAuditController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});
function photoAuditController($scope, $stateParams, spaceService, $translate, ngDialog) {
        var self = this;
        self.file
        self.imgWidth
        self.imgheight
        self.textContent = 'No files selected';
        self.selectedStore = {}
        self.storePhotoAudit = {
            planogramId: $stateParams.planogramId
        }


        $scope.noWrapSlides = false;
        $scope.active = 0;
        $scope.slides = [];
        var currIndex = 0;

        $scope.addSlide = function (newSlide) {
            console.log('newslide', newSlide)
            $scope.slides.push({
                image: newSlide.uriPhoto,
                text: newSlide.storeName,
                id: currIndex++,
            });
        };


        $scope.uploadFile = function (files) {

            console.log('$scope.uploadFile', files)

            self.file = files[0]
            //self.level.file = files[0]

            updateImageDisplay(files)


        };


        var preview = document.querySelector('.preview');


        function updateImageDisplay(files) {

            while (preview.firstChild) {
                preview.removeChild(preview.firstChild);
            }

            var formData = new FormData();

            formData.append('photo', files[0]);

            spaceService.planograms.savePhotoAudit(formData).then(function (uri) {
                console.log('saveLayoutLevel', uri)
                self.photoUri = uri

                self.storePhotoAudit.uriPhoto = uri

                var curFiles = files;
                if (curFiles.length === 0) {
                    self.textContent = 'No files currently selected for upload';
                } else {
                    var list = document.createElement('ol');
                    preview.appendChild(list);
                    for (var i = 0; i < curFiles.length; i++) {
                        var listItem = document.createElement('li');
                        var para = document.createElement('p');
                        if (validFileType(curFiles[i])) {
                            para.textContent = translations.FileName + curFiles[i].name + translations.FileSize + returnFileSize(curFiles[i].size) + '.';
                            var image = document.createElement('img');
                            image.src = window.URL.createObjectURL(curFiles[i]);
                            image.onload = function () {
                                self.imgWidth = image.naturalWidth
                                self.imgheight = image.naturalHeight
                            }

                            image.style.height = '150px'

                            listItem.appendChild(image);
                            listItem.appendChild(para);

                        } else {
                            para.textContent = translations.FileName + curFiles[i].name + translations.NotValidFileType;
                            listItem.appendChild(para);
                        }

                        list.appendChild(listItem);
                    }
                }

            });


        }

        var fileTypes = [
            'image/jpeg',
            'image/pjpeg',
            'image/png'
        ]

        function validFileType(file) {
            for (var i = 0; i < fileTypes.length; i++) {
                if (file.type === fileTypes[i]) {
                    return true;
                }
            }

            return false;
        }

        function returnFileSize(number) {
            if (number < 1024) {
                return number + 'bytes';
            } else if (number > 1024 && number < 1048576) {
                return (number / 1024).toFixed(1) + 'KB';
            } else if (number > 1048576) {
                return (number / 1048576).toFixed(1) + 'MB';
            }
        }

        self.storeClicked = function (store) {
            self.selectedStore = store
            //console.log('store', store)
            self.storePhotoAudit.storeId = store.id
        }

        function isValidField(field) {
            if (field == undefined || field == null || field == '')
                return false;

            return true
        }

        function validar() {
            return isValidField(self.storePhotoAudit.storeId) &&
                isValidField(self.storePhotoAudit.planogramId) &&
                isValidField(self.storePhotoAudit.uriPhoto);
        }

        self.savePhotoAudit = function () {
            if (!validar()) {
                swal(translations.ValidationSwalTitle, translations.ValidationSwalSubtitle, 'error');
                return;
            }

            spaceService.planograms.savePlanogramStorePhotoAudi(self.storePhotoAudit).then(function (res) {

                self.cancel();

                self.photoAudits = res;
                $scope.slides = [];
                angular.forEach(self.photoAudits, function (_photoAudit) {
                    angular.forEach(self.stores, function (_store) {
                        if (_store.id == _photoAudit.storeId) {
                            _photoAudit.storeName = _store.name
                        }
                    })
                    $scope.addSlide(_photoAudit);
                })
                console.log('slide', $scope.slides)
                //self.showUploadPhotoAudit = false
                //self.close({ $value: self.level });
            });
        }

        self.confirm = function () {
            self.close({ $value: self.photoAudits });
        }


        self.cancel = function () {
            self.dismiss({ $value: 'cancel' });
        }

        var translations = null;

        function init() {

            $translate(["FileName", "FileSize", "NotValidFileType", "ValidationSwalTitle", "ValidationSwalSubtitle"])
                .then(function (all) {
                    translations = all;
                });

            spaceService.planograms.getPlanogramInfo($stateParams.planogramId).then(function (planogram) {

                
                console.log('foto auditoria', planogram);
                //console.log('stores', stores);
                self.stores = planogram.stores;

                spaceService.planograms.getPhotoAudit($stateParams.planogramId).then(function (photoAudits) {
                    self.photoAudits = photoAudits;
                    angular.forEach(self.photoAudits, function (_photoAudit) {
                        angular.forEach(self.stores, function (_store) {
                            if (_store.id == _photoAudit.storeId) {
                                _photoAudit.storeName = _store.name
                            }
                        })
                        $scope.addSlide(_photoAudit);
                    })
                    //console.log('photoAudits', self.photoAudits);
                });
            });
        }

        init()
    }
