var _controller = function (componentsService, $scope, $element, $attrs, $q, $timeout, $translate) {
    var self = this;

    //console.log('ctrl', self);

    self.updateCharts = function () {

        if (self.pricesChartData == null)
            return;

        //console.log('self.pricesChartData', self.pricesChartData);
        $timeout(function () {
            //self.pricesChartData[1].push(null);
            //self.pricesChartData[1].push(null);
            //self.pricesChartData[1].push(null);
            //self.pricesChartData[1].push(null);
            //self.pricesChartData[1].push(null);
            //self.pricesChartData[1].push(null);
            self.pricesChart = c3.generate(getChartConfig('#priceEvolutionChart_' + self.id, self.pricesChartData));
            self.unitsChart = c3.generate(getUnitsChartConfig('#unitsEvolutionChart_' + self.id, self.unitsChartData));
        });
    }




    function getChartConfig(chartId, data) {
        //console.log('chartid', chartId);
        return {
            bindto: chartId,
            size: {
                height: 210,
            },
            data: {
                x: 'x',
                columns: data,
                type: 'spline',
                axes: axes,
            },
            color: {
                pattern: ['#0063C2']
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: '%m-%d',
                    }
                },
                y: {
                    tick: {
                        format: d3.format(".2f")
                    }
                },
                //y2: {
                //    show: false
                //}
            },
            tooltip: {
                format: {
                    title: function (d) {
                        var toFormat = '%e %B %Y';
                        if (self.timerange == 'year')
                            toFormat = '%e %B %Y';

                        var format = d3.time.format(toFormat);
                        return format(d);
                    },
                    value: function (value, ratio, id) {
                        //console.log('value', value, 'ratio', ratio, 'id', id);
                        //console.log('this', this);
                        var format;
                        if (id == translations.Price) {
                            format = d3.format('$,.2f');

                        } else {
                            format = d3.format(',.2f');
                        }
                        return format(value);
                    }
                }
            }
        }
    }
    function getUnitsChartConfig(chartId, data) {
        //console.log('chartid', chartId);
        return {
            bindto: chartId,
            size: {
                height: 210,
            },
            data: {
                x: 'x',
                columns: data,
                type: 'spline',
                axes: axes2,
            },
            color: {
                pattern: ['#1AB394']
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: '%m-%d',
                    }
                },
                y: {
                    tick: {
                        format: d3.format(".0f")
                    }
                },
            },
            tooltip: {
                format: {
                    title: function (d) {
                        var toFormat = '%e %B %Y';
                        if (self.timerange == 'year')
                            toFormat = '%e %B %Y';

                        var format = d3.time.format(toFormat);
                        return format(d);
                    },
                    value: function (value, ratio, id) {
                        //console.log('value', value, 'ratio', ratio, 'id', id);
                        //console.log('this', this);
                        var format = d3.format(',.2f');
                   
                        return format(value);
                    }
                }
            }
        }
    }



    self.updateChartType = function (type) {
        self.selectedType = type;
        self.pricesChart.transform(type);
    }



    function parseChartData(chartData, chart) {
        if (chartData == undefined || chartData.length == 0) return [];
        var data = [];
        var competitors = [];
        var dates = ['x'];
        var series = [];

        //obtengo competitors
        //var competitor = '';
        angular.forEach(chartData, function (d) {
            if (competitors.indexOf(d.competitor) == -1) {
                competitors.push(d.competitor);
                //competitor = d.competitor;
            }
        });


        //obtengo dates
        var date = '';
        angular.forEach(chartData, function (d) {
            var tempdate = new Date(d.observedDate);
            var currDate = tempdate.getFullYear() + '-' + (tempdate.getMonth() + 1) + '-' + tempdate.getDate();
            if (dates.indexOf(currDate) == -1) {
                dates.push(currDate);
            }
        });

        function competitorInSerie(competitor) {
            for (var i = 0; i < series.length; i++) {
                if (series[i][0] == competitor)
                    return true;
            }
            return false;
        }

        //armo las series
        var serie = [];
        angular.forEach(chartData, function (d) {
            if (!competitorInSerie(d.competitor)) {

                serie = [];
                serie.push(d.competitor);
                serie.push(d[chart]);
                competitor = d.competitor;
                series.push(serie);
            }
            else {
                serie.push(d[chart]);
            }
        });
        data.push(dates);
        angular.forEach(series, function (s) {
            data.push(s);
        });

        return data;
    }
    function parseUnitsChartData(chartData, chart) {
        if (chartData == undefined || chartData.length == 0) return [];
        var data = [];
        var volumes = [];
        var dates = ['x'];
        var series = [];

        //obtengo volumenes
        angular.forEach(chartData, function (d) {
            if (volumes.indexOf(d.volume) == -1) {
                volumes.push(d.volume);
            }
        });

        //obtengo dates
        var date = '';
        angular.forEach(chartData, function (d) {
            var tempdate = new Date(d.date);
            var currDate = tempdate.getFullYear() + '-' + (tempdate.getMonth() + 1) + '-' + tempdate.getDate();
            if (dates.indexOf(currDate) == -1) {
                dates.push(currDate);
            }
        });

        function volumesInSerie(volume) {
            for (var i = 0; i < series.length; i++) {
                if (series[i][0] == volume)
                    return true;
            }
            return false;
        }

        //armo las series
        var serie = [];
        serie.push(translations.UnitSales);

        angular.forEach(chartData, function (d) {
            if (!volumesInSerie(d.volume / 1000)) {
                serie.push(d.volume / 1000);
            }
        });
        series.push(serie);
        data.push(dates);
        angular.forEach(series, function (s) {
            data.push(s);
        });

        return data;
    }
    
    var translations = null;
    var axes = {};
    var axes2 = {};

    function init() {

        $translate(["Price", "UnitSales"])
            .then(function (all) {
                translations = all;
                
                axes[translations.Price] = 'y';
                axes2[translations.UnitSales] = 'y';

                var priceHistory = mix();
                var volumesChart = getUnitsChart();

                self.pricesChartData = parseChartData(priceHistory, 'price');
                self.unitsChartData = parseUnitsChartData(volumesChart, 'units');
                //console.log('dataparsed', self.pricesChartData);  
                //self.pricesChartData = parseChartData(pricesChartData, 'price');

                self.updateCharts();
            });
    }


    //Esta funcion se encarga de mezclar los priceHistory con los unitsSales
    //Basicamente, todos los registros tienen que contar con TODAS las fechas de muestreo
    // Si una serie no tiene valor en una fecha determinada, le asigna como valor un null
    function mix() {
        var priceHistory = angular.copy(self.data.priceHistory);

        if (priceHistory) {

            for (var i = 0; i < priceHistory.length; i++) {
                priceHistory[i].competitor = translations.Price;
                priceHistory[i].price = priceHistory[i].price; //ian.abbreviateNumberThousand(priceHistory[i].price, 2, '');

                //var dateExists = false;
                //for (var j = 0; j < volumes.length; j++) {
                //    if (volumes[j].date == priceHistory.observedDate) {
                //        dateExists = true;
                //        break;
                //    }
                //}

                //if (!dateExists) {
                //    volumes.push({
                //        date: priceHistory[i].observedDate,
                //        competitor: translations.UnitSales,
                //        volume: null
                //    })
                //}
            }

            //for (var i = 0; i < volumes.length; i++) {
            //    var units = volumes[i];
            //    var dateExists = false;

            //    for (var j = 0; j < priceHistory.length; j++) {
            //        if (priceHistory[j].observedDate == units.date) {
            //            dateExists = true;
            //            break;
            //        }
            //    }
            //    if (!dateExists) {
            //        priceHistory.push({
            //            observedDate: volumes[i].date,
            //            price: null,
            //            competitor: translations.Price
            //        })
            //    }
            //}


            priceHistory.sort(function (a, b) {
                return a.observedDate < b.observedDate;
            });
            //volumes.sort(function (a, b) {
            //    return a.date < b.date;
            //});

            //for (var i = 0; i < volumes.length; i++) {
            //    var units = volumes[i];
            //    priceHistory.push({
            //        competitor: translations.UnitSales,
            //        observedDate: units.date,
            //        price: units.volume
            //    });
            //}
            console.log('new price history', priceHistory);


        }

        return priceHistory;

    }

    function getUnitsChart() {
        var volumes = angular.copy(self.data.unitsData.volumes);
        //volumes[i].volume = translations.UnitSales;

        if (volumes) {

            volumes.sort(function (a, b) {
                return a.date < b.date;
            });

            
            console.log('new volumes', volumes);


        }

        return volumes;

    }


    init();

}

angular.module('prisma').component('priceEvolutionComponent', {
    templateUrl: 'app/shared_logic/components/priceEvolution/priceEvolution.component.html',
    bindings: {
        data: '=',
        id: '='
        //ngModel:'='
    },

    controller: _controller
});