'use strinct';
(function () {
    angular.module('prisma').component('planogramPrintPage', {
        bindings: {
            planogram: '<',
            showHeader: '<',
            showPrintWayDirection: '<',
            showStores: '<',
            stores: '<',
            storesOrderBy: '<',
            showFooter: '<',
            showPageInfo: '<',
            isLastPage: '<',
            columnSpaceBetween: '<',
            showDoor: '<',
            showItemSketch: '<',
            showGroupSketch: '<',
            showLabelInfo: '<',
            showLabel1: '<',
            showLabel2: '<',
            showOverlay: '<',
            showColumnId: '<',
            moduleSpaceBetween: '<',
            levelSpaceBetween: '<',
            itemSpaceBetween: '<',
            shelfHeight: '<',
            label1: '<',
            label2: '<',
            labelInfo: '<',
            labelHeight: '<',
            raiseOnRenderReady: '&onrenderReady',
            width: '<'
        },
        bindToController: true,
        controller: 'planogramPageController',
        controllerAs: '$ctrl',

        templateUrl: '/app/spaces/planogram/print/planogram-print-page.view.html',
        transclude: {
            'header': '?pageHeader',
            'body': '?pageBody',
            'footer': '?pageFooter'
        }
    }).controller('planogramPageController', ['$log', planogramPrintPageController]);

    // ReSharper disable once InconsistentNaming
    function planogramPrintPageController($log) {
        //$log.debug('planogramPrintPageController::ctor');
        var self = this;
        self.rightToLeft = false;

        self.$onInit = function () {
            //$log.debug('planogramPrintPageController::$onInit');
        };

        self.$onDestroy = function () {
            //$log.debug('planogramPrintPageController::$onDestroy');
        };
    }
})();