angular.module("prisma")
    .controller("editInternalPromotionCtrl", function ($scope, $filter, $state, $window, $timeout, $rootScope, $stateParams, ngDialog, Upload, promotionsService, adminService, serverService, authService) {
        var self = this;

        self.isCopy = JSON.parse($stateParams.copy) == true;
        self.readOnly = $stateParams.readOnly == "true";
        self.promotion = null;
        self.promotionItems = [];
        self.promotionPriceFileUrl = null;
        self.campaigns = [];
        self.stores = [];
        self.storeGroups = [];
        self.campaign = null;
        self.newComment = "";
        self.filterStores = { brandId: 0, brandName: '' };
        self.canTakePromotion = false;
        //var colors = promotionsService.colors;
        self.hasPermissionToTakeInternalPromotion = authService.hasPermission('promotion_take_internalPromotion');
        self.hasPermissionToCreateInternalPromotion = authService.hasPermission('promotion_create_internalPromotion');
        self.hasPermissionOfSuperUser = authService.hasPermission('promotion_superUser');
        self.storeGroup = { id: 0, name: '', stores: [] };
        self.storesDialogDisabled = false;
        self.allowCombinationGroups = false;


        self.editItem = function (item) {
            if (item.promotionMechanicId != null)
                self.goToEditMechanic(item);
            else
                self.goToEditItem(item);
        }

        self.goToLoadItem = function () {
            $state.go('promotions.editItem', { promotionMediaPageId: $stateParams.promotionMediaPageId, position: -2, promotionId: $stateParams.promotionId, promotionItemType: 1 });
        }
        self.goToEditItem = function (item) {
            $state.go('promotions.editItem', { promotionMediaPageId: $stateParams.promotionMediaPageId, position: -2, promotionItemId: item.id, promotionId: $stateParams.promotionId, promotionItemType: 1, salesUnitOfMeasureId: item.saleUnitOfMeasureId });
        }

        self.goToLoadMechanic = function () {
            $state.go('promotions.editMechanic', { promotionMediaPageId: $stateParams.promotionMediaPageId, position: -2, promotionId: $stateParams.promotionId, promotionItemType: 1 });
        }
        self.goToEditMechanic = function (item) {
            $state.go('promotions.editMechanic', { promotionMediaPageId: $stateParams.promotionMediaPageId, position: -2, promotionItemId: item.id, promotionMechanicId: item.promotionMechanicId, promotionId: $stateParams.promotionId, promotionItemType: 1 });
        }

        self.deleteItem = function (item) {

            swal({
                title: 'Esta seguro que desea eliminar el item?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        promotionsService.items.deletePromotionItem(item.id)
                            .then(function (isOk) {
                                self.promotionItems.splice(self.promotionItems.indexOf(item), 1);
                                updateItemList();
                                swal('Item Eliminado!', 'El item ha sido removido con exito.', 'success');

                            }, function (status) {
                                if (status == 502) {
                                    swal("El proceso continua...", "El proceso de borrado continua. Puede revisar el estado en unos minutos.")
                                }
                                else {
                                    swal("Error", "Hubo un error al borrar este item", "error");
                                }
                            });
                    }
                });


        }

        self.takePromotion = function () {
            if (self.canTakePromotion) {
                promotionsService.internalPromotions.takeInternalPromotion($stateParams.promotionId)
                    .then(function () {
                        swal("La Promocion fue tomada con exito!", "", "success");
                        self.canTakePromotion = false;
                        $timeout(function () {
                            loadData();
                        }, 1000);
                    });
            }
        }

        self.addComment = function () {
            if (self.newComment != "") {
                var chatDto = { sendDate: new Date(), username: "", promotionMediaPageId: $stateParams.promotionMediaPageId, message: self.newComment.replace(/(\r\n|\n|\r)/gm, '') };
                promotionsService.internalPromotions.commentInternalPromotion(chatDto)
                    .then(function (resp) {
                        self.promotion.pages[0].chats.push(resp);
                        self.newComment = "";
                    });
            }

        }

        self.promotionCampaignChanged = function () {
            setCampaign(true);
        }

        self.save = function () {
            if (self.promotion != null) {
                if (self.promotion.stores.length > 0) {
                    let today = new Date(new Date().setHours(0, 0, 0, 0));
                    if (!self.promotion.promotionCampaignId || (self.promotion.validFrom < today && !self.hasPermissionOfSuperUser)) {
                        swal("Error", "Debe seleccionar una campaña vigente o iniciar la promocion con fecha mayor o igual a hoy.", "error");
                    }
                    else {
                        var title = self.isCopy ? 'Esta seguro que desea copiar la Acción Promocional?'
                            : 'Esta seguro que desea guardar la Acción Promocional?';
                        swal({
                            title: title,
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#1AB394",
                            confirmButtonText: "Continuar",
                            cancelButtonText: "Cancelar",
                            showLoaderOnConfirm: true,
                            closeOnConfirm: false,
                            closeOnCancel: true
                        },
                            function (isConfirm) {
                                if (isConfirm) {
                                    //teniendo el alcance definido, seteo el brand
                                    for (var i = 0; i < self.brands.length; i++) {
                                        if (self.promotion.stores[0].name.indexOf(self.brands[i].name) > -1) {
                                            self.promotion.brandId = self.brands[i].id;
                                            self.promotion.brandName = self.brands[i].name;
                                            break;
                                        }
                                    }

                                    //Fix Enter en comentarios dentro de un TEXTAREA, para bajar los excels se rompe.
                                    self.promotion.description ? self.promotion.description.replace(/(\r\n|\n|\r)/gm, '') : '';

                                    //Error User Alexandra 
                                    var stringvalidFrom = moment(self.promotion.validFrom).toDate().toLocaleDateString('en-US');
                                    self.promotion.validFrom = moment(stringvalidFrom).toDate();

                                    var stringvalidTo = moment(self.promotion.validTo).toDate().toLocaleDateString('en-US');
                                    self.promotion.validTo = moment(stringvalidTo).toDate();

                                    if (!self.isCopy) {
                                        promotionsService.internalPromotions.saveInternalPromotion(self.promotion)
                                            .then(function (promotion) {
                                                swal('Accion Promocional Guardada!', 'La accion promocional ' + self.promotion.name + ' se ha guardado exitosamente', 'success');

                                                promotion.validFrom = moment(promotion.validFrom.split('T')[0]).toDate();
                                                promotion.validTo = moment(promotion.validTo.split('T')[0]).toDate();
                                                //promotion.validFrom = new Date(promotion.validFrom.replace("Z", ""));
                                                //promotion.validTo = new Date(promotion.validTo.replace("Z", ""));

                                                self.promotion = promotion;
                                                $stateParams.promotionMediaPageId = self.promotion.pages[0].id;
                                                $stateParams.promotionId = self.promotion.id;
                                                self.isNew = false;

                                            }, function (status) {
                                                if (status == 502) {
                                                    swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                                                }
                                                else {
                                                    swal("Error", "Hubo un error al guardar esta accion promocional", "error");
                                                }
                                            });
                                    }
                                    else {
                                        promotionsService.promotions.copyPromotion(self.promotion)
                                            .then(function (promotion) {
                                                swal('Accion Promocional Copiada!', 'La accion promocional ' + self.promotion.name + ' se ha copiado exitosamente', 'success');

                                                $state.go('promotions.promotions');

                                            }, function (status) {
                                                if (status == 502) {
                                                    swal("El proceso continua...", "El proceso de copiado continua. Puede revisar el estado en unos minutos.")
                                                }
                                                else {
                                                    swal("Error", "Hubo un error al copiar esta accion promocional", "error");
                                                }
                                            });
                                    }
                                }

                            });
                    }

                }
                else
                    swal("Error", "Debe seleccionar al menos una tienda para poder guardar.", "error");
            }
        }

        self.inform = function () {
            if (self.isCreatorUser) {
                swal({
                    title: 'Informar Promocion',
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: "Continuar",
                    cancelButtonText: "Cancelar",
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        promotionsService.internalPromotions.informNewInternalPromotion($stateParams.promotionId)
                            .then(function (informed) {
                                swal("Se ha informado con éxito a Promociones", "", "success");
                            }, function (status) {
                                if (status == 502) {
                                    swal("El proceso continua...", "Puede revisar el estado en unos minutos.")
                                }
                                else {
                                    swal("Error", "Hubo un error al informar esta accion promocional", "error");
                                }
                            });
                    }
                });
            }

        }

        self.publish = function () {
            if (!self.promotion.publishedDate || self.hasPermissionOfSuperUser) //Si ya se publico no podes volver a publicarla a menos que seas superUser
            {
                swal({
                    title: 'Publicar Promocion',
                    type: "warning",
                    text: "Esto preparara los precios promocionales para enviarlos al ERP, Si se publica mas de una vez, se sobreescribiran los precios promocionales que aun no se hayan informado.",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: "Continuar",
                    cancelButtonText: "Cancelar",
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        promotionsService.internalPromotions.publishInternalPromotion(self.promotion.id)
                            .then(function (lastPriceFileUrl) {
                                self.promotionPriceFileUrl = lastPriceFileUrl;
                                swal("Se ha publicado exitosamente!", "", "success");
                                self.canReject = false;
                            }, function (status) {
                                if (status == 502) {
                                    swal("El proceso continua...", "Puede revisar el estado en unos minutos.")
                                }
                                else {
                                    swal("Error", "Hubo un error al publicar esta accion promocional", "error");
                                }
                            });
                    }
                });
            }
        }

        self.showOverlaps = function (overlaps) {

            self.currentOverlaps = overlaps;

            ngDialog.open({
                template: 'overlapDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            });
        }

        //Masive Load
        self.batchLoad = function () {

            self.currentStep = 1;
            self.gridOptions = {
                columnDefs: [
                    {
                        headerName: 'Severidad',
                        width: 100,
                        pinned: 'left',
                        field: 'severity',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                        rowGroupIndex: 0,
                        cellRenderer: function (params) {
                            var severityName = '';
                            switch (params.value) {
                                case 'Information': { severityName = '<i style="color: #6aa4ff;" class="fa fa-info-circle" aria-hidden="true"></i> Info'; break; }
                                case 'Warning': { severityName = '<i style="color: #e4e400;" class="fa fa-stop-circle" aria-hidden="true"></i> Warn'; break; }
                                case 'Error': { severityName = '<i style="color: #ea2323;" class="fa fa-exclamation-circle" aria-hidden="true"></i> Error'; break; }
                            }
                            return severityName;
                        }
                    },
                    {
                        headerName: 'Fila',
                        width: 50,
                        pinned: 'left',
                        field: 'row',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                    },
                    {
                        headerName: 'Categoria',
                        width: 150,
                        rowGroupIndex: 1,
                        pinned: 'left',
                        field: 'category',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                    },
                    {
                        headerName: 'Descripcion',
                        width: 350,
                        pinned: 'left',
                        field: 'description',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                    },
                ],
                enableColResize: true,
                enableSorting: true,
                enableFilter: false,
                rowHeight: 35,
                suppressHorizontalScroll: false,
                suppressCellSelection: true,
                groupUseEntireRow: true,
                groupSuppressAutoColumn: false,
                groupDefaultExpanded: 0,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                angularCompileRows: true,
            }

            self.showNextStep = function () {
                if (self.currentStep > 0 && self.currentStep < 4) {
                    self.currentStep++;
                    loadResults([]);
                }
            }

            self.downloadExcel = function () {
                var html = '<style>.text{mso-number-format:"\@";/*force text*/}</style><table width="100%"><thead><tr>';

                //header
                html += '<th>Promocion ID (' + self.promotion.id + ')</th>';
                html += '<th>Articulo</th>';
                html += '<th>UMV</th>';
                html += '<th>Descripcion SAP</th>';
                html += '<th>Precio</th>';
                html += '<th>Precio Bonus</th>';
                html += '<th>Puntos Bonus</th>';
                html += '<th>Precio Normal</th>';
                html += '</tr></thead><tbody>';

                updateItemList();

                angular.forEach(self.promotionItems, function (item) {
                    html += buildRow(item);
                });

                html += '</tbody></table>';

                var exportHref = ExportToExcel(html, 'Pagina1');
                $timeout(function () {
                    location.href = exportHref;
                    self.showNextStep();
                }, 100); // trigger download

            }

            function buildRow(item) {

                var html = '';

                if (item != null && item.promotionMechanicId == null) { //Solo los que son items, elimino las mecanicas del download
                    html += '<tr><td>' + $stateParams.promotionId +
                        '</td><td class="text">' + item.code.replace(/^0+/, '') +
                        '</td><td>' + (item.uom || '') +
                        '</td><td>' + (item.itemName || '') +
                        '</td><td>' + (item.promotionalPrice || '') +
                        '</td><td>' + (item.loyaltyPrice || '') +
                        '</td><td>' + (item.loyaltyPoints || '') +
                        '</td><td>' + (item.normalPromotionalPrice || '') +
                        '</td></tr>';
                }

                return html;
            }

            self.uploadFile = function (files) {
                if (files && files.length > 0) {
                    self.isBusy = true;
                    var url = serverService.getApiUrl() + '/promotion/items/uploadItemsBatchFile/' + $stateParams.promotionMediaPageId;
                    Upload.upload({
                        url: url,
                        data: { file: files[0] }
                    }).then(function (response) {
                        var importerResults = response.data;
                        processImporterResults(importerResults);
                        if (!anyError(importerResults)) {
                            self.finishedUpload = true;
                        }
                    });
                }
            }

            self.validateUploadedFile = function () {
                self.isBusy3 = true;
                promotionsService.items.validateItemsBatchFileInternal($stateParams.promotionMediaPageId)
                    .then(function (dto) {
                        processImporterResults(dto);
                        if (!anyError(dto)) {
                            self.finishedValidation = true;
                        }
                    });

            }

            self.reUploadFile = function () {
                self.currentStep = 2;
                loadResults([]);
                self.files = null;
                self.finishedUpload = false;
                self.finishedValidation = false;
                self.finishedUpdate = false;
            }

            self.updateItems = function () {
                self.isBusy4 = true;
                promotionsService.items.updateItemsInBatchInternal($stateParams.promotionMediaPageId)
                    .then(function (dto) {
                        processImporterResults(dto);
                        if (!anyError(dto)) {
                            self.finishedUpdate = true;
                            loadItems();
                        }
                    });

            }

            self.finish = function () {
                self.currentStep = 1;
                loadResults([]);

                self.finishedUpload = false;
                self.finishedValidation = false;
                self.finishedUpdate = false;

                ngDialog.close();

                loadPromotion();
            }

            function processImporterResults(importerResults) {
                self.isBusy = false;
                self.isBusy2 = false;
                self.isBusy3 = false;
                self.isBusy4 = false;

                if (importerResults.length > 0) {
                    loadResults(importerResults);
                }
                if (anyError(importerResults)) {
                    self.error = true;
                }
                else
                    self.error = false;
            }

            function loadResults(results) {
                self.gridOptions.api.setRowData(results);
                self.gridOptions.api.refreshView();

                self.gridVisible = results.length > 0 ? true : false;
            }

            function anyError(importerResults) {
                var error = false;
                for (var i = 0; i < importerResults.length; i++) {
                    if (importerResults[i].severity === 'Error')
                        error = true;
                }

                return error;
            }

            ngDialog.open({
                template: 'batchLoadDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                controller: function () {
                    return self;
                },
                controllerAs: 'ct',
            });
        }

        function ExportToExcel(tableHtml, worksheetName) {
            var uri = 'data:application/vnd.ms-excel;base64,',
                template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
                base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
                format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

            var ctx = { worksheet: worksheetName, table: tableHtml },
                href = uri + base64(format(template, ctx));
            return href;
        }

        //Stores
        self.openStoresDialog = function () {
            ngDialog.open({
                template: 'storesDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                //data: task,
                scope: $scope
            });
        }

        self.storesCount = 0;

        self.selectStore = function (store) {

            if (!self.storesDialogDisabled) {

                store.selected = !store.selected;

                if (store.selected)
                    self.storesCount++;
                else
                    self.storesCount--;
            }
        }

        self.selectStoreGroup = function (storeGroup) {

            self.promotion.promotionStoreGroupId = null;

            var brandOfStoreGroup;
            angular.forEach(self.brands, function (brand) {

                //para los inconsistentes casos en que las tiendas no tienen brand el workaround es:
                //matchiar el nombre de la tienda con el nombre del brand
                if (storeGroup.stores[0].brand == brand.name || storeGroup.stores[0].name.indexOf(brand.name) > -1) {
                    brandOfStoreGroup = brand;
                }
            });

            self.changeStoresFilter(brandOfStoreGroup);

            if (!self.allowCombinationGroups) {//combinacion off
                storeGroup.isSelected = storeGroup.isSelected ? false : true;

                if (storeGroup.isSelected) {
                    angular.forEach(self.storeGroups, function (sg) {
                        if (storeGroup.id != sg.id) {
                            sg.isSelected = false;
                        }
                    });

                    self.promotion.promotionStoreGroupId = storeGroup.id;
                }
                self.unselectAll(); //deselecciono todas las tiendas.
            }

            self.storesDialogDisabled = storeGroup.isSelected; //deshabilito el pop up si quedo un grupo seleccionado

            //seleccion de tiendas
            if (storeGroup.isSelected || self.allowCombinationGroups) {
                angular.forEach(self.stores, function (store) {

                    angular.forEach(storeGroup.stores, function (storeInGroup) {
                        if (storeInGroup.id == store.id) {

                            if (!store.selected) {
                                self.storesCount++;
                            }
                            store.selected = true;
                        }
                    });
                });
            }
        }

        self.changeSwith = function () {
            self.showCombinationGroups = !self.showCombinationGroups;
            self.unselectAll();
        }

        self.unselectAll = function () {
            angular.forEach(self.stores, function (store) {
                if (store.selected) {
                    store.selected = false;
                    self.storesCount--;
                }
            });
        }

        self.saveStores = function (close) {
            self.promotion.stores = [];

            angular.forEach(self.stores, function (store) {
                if (store.selected) {
                     self.promotion.stores.push(store);
                }
            });

            self.storesCount = self.promotion.stores.length;

            if (close)
                ngDialog.close();
        }

        self.reject = function () {

            swal({
                title: 'Accion irreversible',
                text: 'Rechazar esta acción promocional?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        promotionsService.promotions.rejectPromotion($stateParams.promotionId)
                            .then(function (isOk) {
                                swal('Promocion Rechazada!', '', 'success');
                                $state.go('promotions.promotions');
                            }, function (status) {
                                if (status == 502) {
                                    swal("El proceso continua...", "El proceso de rechazo continua. Puede revisar el estado en unos minutos.")
                                }
                                else {
                                    swal("Error", "Hubo un error al rechazar esta promocion", "error");
                                }
                            });
                    }
                });
        }

        self.deleteGroup = function (storeGroup) {
            swal({
                title: 'Esta seguro que desea Eliminar el grupo ' + storeGroup.name + '?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        promotionsService.stores.deleteGroup(storeGroup.id)
                            .then(function (promotion) {
                                self.newStoreGroup();
                                    loadStoreGroups();
                                    swal('Grupo Eliminado!', 'El grupo ' + storeGroup.name + ' se ha eliminado exitosamente', 'success');
                            },
                                function (status) {
                                    if (status == 502) {
                                        swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                                    }
                                    else {
                                        loadStoreGroups();
                                        swal("Error", "Hubo un error al eliminar este grupo", "error");
                                    }
                                });
                    }
                });
        }

        self.changeStoresFilter = function (brand) {
            self.filterStores.brandId = brand.id;
            self.filterStores.brandName = brand.name;
        }

        self.filterStoresByBrand = function (store) {
            if (store.brandId) {//en Peru se detectaron stores con brandId null en el maestro de tiendas! en colombia no.
                return store.brandId == self.filterStores.brandId;
            }
            return store.name.indexOf(self.filterStores.brandName) > -1;
        }


        self.newStoreGroup = function () {
            self.storeGroup = { id: 0, name: '', stores: [] };

            self.unselectAll();
        }

        self.saveStoresAndGroup = function () {
            if (self.storeGroup.name == '' || self.storesCount == 0) {
                swal("Error", "Para guardar este grupo de tiendas debes completar su nombre y seleccionar al menos 1 tienda", "error");
            }
            else {
                swal({
                    title: 'Esta seguro que desea guardar este Grupo de Tiendas?',
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: "Continuar",
                    cancelButtonText: "Cancelar",
                    showLoaderOnConfirm: true,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                    function (isConfirm) {
                        if (isConfirm) {

                            //Primero asocio las tiendas a la promo
                            self.saveStores(false);

                            self.storeGroup.stores = [];

                            //Despues asocio las tiendas al grupo
                            angular.forEach(self.stores, function (store) {
                                if (store.selected)
                                    self.storeGroup.stores.push(store);
                            });

                            promotionsService.stores.saveStoreGroup(self.storeGroup)
                                .then(function (storeGroup) {
                                    swal('Grupo de Tiendas Guardado!', 'El Grupo de Tiendas ' + self.storeGroup.name + ' se ha guardado exitosamente', 'success');

                                    if (self.storeGroup.id == 0) {
                                        self.storeGroups.push(storeGroup);
                                    }
                                    self.storeGroup = storeGroup;
                                    self.newStoreGroup();
                                        
                                }, function (status) {
                                    if (status == 502) {
                                        swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                                    }
                                    else {
                                        swal("Error", "Hubo un error al guardar este grupo de tiendas", "error");
                                    }
                                });
                        }

                    });
            }
        }

        self.closeStoresDialog = function () {

            ngDialog.close();
        }


        var validFromReverted = false;
        $scope.$watch('ct.promotion.validFrom', function (newVal, oldVal) {

            if (validFromReverted) {
                validFromReverted = false;
                return;
            }

            var hasError = false;


            if (newVal) {

                //Validar que el desde sea anterior al hasta
                if (moment(new Date(newVal.getUTCFullYear(), newVal.getUTCMonth(), newVal.getUTCDate())).isAfter(moment(self.promotion.validTo), 'day')) {
                    swal("Error", "La fecha Desde debe ser menor a la fecha hasta", "error");
                    hasError = true;
                }

                //Validar que el desde sea mayor o igual al hasta
                if (moment(new Date(newVal.getUTCFullYear(), newVal.getUTCMonth(), newVal.getUTCDate())).isBefore(moment(self.campaign.validFrom), 'day')) {
                    swal("Error", "La fecha Desde debe ser mayor o igual a la fecha de inicio de la campaña: " + new Date(self.campaign.validFrom), "error");
                    hasError = true;
                }

            }

        }, true);

        var validToReverted = false;
        $scope.$watch('ct.promotion.validTo', function (newVal, oldVal) {

            if (validToReverted) {
                validToReverted = false;
                return;
            }

            var hasError = false;

            if (newVal) {
                //Validar que el desde sea anterior al hasta
                if (moment(newVal).isBefore(moment(self.promotion.validFrom), 'day')) {
                    swal("Error", "La fecha Hasta debe ser mayor a la fecha desde", "error");
                    hasError = true;
                }

                //Validar que el desde sea mayor o igual al hasta
                if (moment(newVal).isAfter(moment(self.campaign.validTo), 'day')) {
                    swal("Error", "La fecha Hasta debe ser menor o igual a la fecha de fin de la campaña: " + new Date(self.campaign.validTo), "error");
                    hasError = true;
                }
            }

        }, true);

        function setCampaign(changeValidity) {
            angular.forEach(self.campaigns, function (campaign) {
                if (self.promotion.promotionCampaignId == campaign.id) {
                    self.campaign = campaign;

                    if (changeValidity) {
                        self.promotion.validFrom = new Date(campaign.validFrom);
                        self.promotion.validTo = new Date(campaign.validTo);
                    }
                }
            });
        }

       function loadStoreGroups() {
            promotionsService.stores.getStoreGroups()
                .then(function (storeGroups) {
                    self.storeGroups = storeGroups;

                    //selecciono el grupo de tiendas de la promocion, si es que tiene.
                    if (self.promotion.promotionStoreGroupId != null) {
                        angular.forEach(self.storeGroups, function (sg) {
                            if (self.promotion.promotionStoreGroupId === sg.id) {
                                self.selectStoreGroup(sg);
                            }
                        });
                    }
                });
        }

        function loadStores() {
            promotionsService.stores.getStores()
                .then(function (stores) {
                    self.stores = stores;

                    angular.forEach(self.promotion.stores, function (promotionStore) {
                        angular.forEach(self.stores, function (store) {
                            if (promotionStore.id == store.id)
                                store.selected = true;
                        });
                    });

                    loadStoreGroups();

                });
        }

        function loadItems() {
            self.isLoadingItems = true;
            self.promotionItems = [];
            promotionsService.internalPromotions.getPromotionItems($stateParams.promotionMediaPageId)
                .then(function (items) {
                    self.promotionItems = items;
                    self.itemList = [];
                    self.itemCounter = items.length > 50 ? 50 : items.length;
                    updateItemList();
                    self.isLoadingItems = false;
                }, function (status) {
                    if (status == 502) {
                        swal("El proceso continua...", "Los items de esta promocion se encuentran demorados.")
                    }
                    else {
                        swal("Error", "Error al cargar los items de esta promocion", "error");
                        self.isLoadingItems = false;
                    }


                });
        }

        //actualiza los items de la mecanica segun la lista visible
        function updateItemList() {
            angular.forEach(self.itemList, function (item) {
                angular.forEach(self.promotionItems, function (it) {
                    if (item.id == it.id)
                        it = item;
                });
            });
            self.itemList = self.promotionItems.slice(0, self.itemCounter);
        }

        self.getPromotionOverlaps = function () {
            if (self.overlaps) {
                if (self.overlaps.length) {
                    self.showOverlaps(self.overlaps);
                }
                else {
                    swal("No hay solapamientos.", "", "info");
                }
            }
            else {
                self.loadingOverlaps = true;
                promotionsService.internalPromotions.getOverlaps($stateParams.promotionId)
                    .then(function (overlaps) {
                        self.overlaps = overlaps;
                        self.loadingOverlaps = false;
                        if (self.overlaps.length) {
                            self.showOverlaps(self.overlaps);
                        }
                        else {
                            swal("No hay solapamientos.", "", "info");
                        }
                    });
            }
        }

        self.viewMore = function () {
            self.itemCounter += 50;
            updateItemList();
        }

        function loadData() {
            promotionsService.stores.getBrands()
                .then(function (brands) {
                    self.brands = brands;
                    if (brands.length > 0) {
                        self.filterStores.brandName = brands[0].name;
                        self.filterStores.brandId = brands[0].id;
                    }
                });
            promotionsService.campaigns.getCampaigns()
                .then(function (campaigns) {
                    self.campaigns = campaigns;
                    if ($stateParams.rejected == 'true') {
                        promotionsService.internalPromotions.getRejectedPromotion($stateParams.promotionId)
                            .then(function (promotion) {
                                promotion.validFrom = moment(promotion.validFrom.split('T')[0]).toDate();
                                promotion.validTo = moment(promotion.validTo.split('T')[0]).toDate();
                                //promotion.validFrom = new Date(promotion.validFrom.replace("Z", ""));
                                //promotion.validTo = new Date(promotion.validTo.replace("Z", ""));
                                self.promotion = promotion;
                                self.promotionPriceFileUrl = promotion.lastPriceFileUrl;
                                self.storesCount = self.promotion.stores.length;
                                setCampaign(false);
                                loadStores();
                            });

                        loadItems();
                    }
                    else if ($stateParams.promotionId != '0') {
                        self.isNew = false;
                        promotionsService.internalPromotions.getInternalPromotion($stateParams.promotionId)
                            .then(function (promotion) {
                                promotion.validFrom = moment(promotion.validFrom.split('T')[0]).toDate();
                                promotion.validTo = moment(promotion.validTo.split('T')[0]).toDate();
                                //promotion.validFrom = new Date(promotion.validFrom.replace("Z", ""));
                                //promotion.validTo = new Date(promotion.validTo.replace("Z", ""));
                                self.promotion = promotion;
                                self.promotionPriceFileUrl = promotion.lastPriceFileUrl;
                                self.isCreatorUser = authService.authentication.userName == self.promotion.createdUserName;
                                self.canPublish = self.hasPermissionOfSuperUser || (self.hasPermissionToTakeInternalPromotion && self.promotion.informed);

                                if (!self.promotion.taken && self.canPublish)
                                    self.canTakePromotion = true;

                                self.canReject = self.hasPermissionToTakeInternalPromotion && self.promotion.status == 'Planeada' && self.promotion.publishedDate == null;

                                self.storesCount = self.promotion.stores.length;

                                setCampaign(false);

                                loadStores();
                            });

                        loadItems();
                    }
                    else {
                        self.isNew = true;
                        self.promotion = { id: 0, companyId: $rootScope.companyId, name: '', description: '', promotionCampaignId: 0, stores: [], pages: [{ chats: [] }], promotionType: 2 }; //Creo promo vacia
                        self.isCreatorUser = true;
                        self.canPublish = false;
                        loadStores();

                    }
                });

        }

        loadData();

    });