'use strinct';
(function () {
    angular.module('prisma').component('planogramPrintWayDirection', {
        templateUrl: '/app/spaces/planogram/print/planogram-print-way-direction.view.html',
        bindings: {
            rightToLeft: '<'
        },
        controller: 'planogramPrintWayController',
        controllerAs: '$ctrl',
        bindToController: true
    }).controller('planogramPrintWayController', ['$log', planogramPrintWayController]);

    // ReSharper disable once InconsistentNaming
    function planogramPrintWayController($log) {
        //$log.debug('planogramPrintWayController::ctor');
        var self = this;
        self.rightToLeft = false;

        self.$onInit = function () {
            //$log.debug('planogramController::$onInit');
        };

        self.$onDestroy = function () {
            //$log.debug('planogramController::$onDestroy');
        };

        self.$onChanges = function $onChanges(changesObj) {
            //$log.debug('planogramController::$onChanges %o', changesObj);
            if (changesObj.rightToLeft) {
                self.rightToLeft = Boolean(changesObj.rightToLeft.currentValue);
            }
        }
    }
})();