angular.module("prisma")
    .controller("MarkdownRulesCategoryCtrl", function ($rootScope, $state, $timeout, $interval, $filter, markdownService, adminService, ngDialog, $scope) {

        var self = this;
        var dic = {};
        var numberColWidth = 100;
        var editableColWidth = 140;
        var lastAdded = null;
        var columnDefs = null;
        self.selectedBrand = null;
        self.companyCode = $rootScope.erp.Implementation;
        self.isLoadingQuickLoad = false;

        self.discountTypes = [
            { id: 1, name: "%" },
            { id: 2, name: "$" },
            //{ id: 3, name: "$-%" },
        ]

        self.objectiveTypes = [
            { id: 1, name: "Reduccion de stock" },
            { id: 2, name: "Dias de stock" },

        ]

        
        self.goToRules = function (id, categoryGroup, category, subcategory) {

            categoryGroup = categoryGroup.replace(/ /g, "-").replace(/\//g, "_");
            category = category.replace(/ /g, "-").replace(/\//g, "_");
            subcategory = subcategory.replace(/ /g, "-").replace(/\//g, "_");

            var encodedcategoryGroup = categoryGroup != 'null' ? encodeURIComponent(categoryGroup) : '';
            var encodedcategory = category != 'null' ? encodeURIComponent(category) : '';
            var encodedsubcategory = subcategory != 'null' ? encodeURIComponent(subcategory) : '';

            $state.go('prices.rules', { categoryId: id, categoryGroup: encodedcategoryGroup, category: encodedcategory, subcategory: encodedsubcategory });
        }

        function hasChildrenEnabled(data) {
            if (data.details) {
                for (var i = 0; i < data.details.length; i++) {
                    if (data.details[i].isEnabled)
                        return true;
                }
            }
            return false;
        }

        function validate() {
            var error = '';

            let forecasteds = [];
            self.gridOptions.api.forEachNode(function (node) {
                let data = node.data;

                if (data.details && data.details.length >= 1) {
                    for (let i = 0; i < data.details.length; i++) {
                        if (data.details[i].isEnabled && data.details[i].isEnabled == true && data.details[i].planType == 24) {
                            let stagesCount = 0;
                            data.details[i].stages.forEach(function (stage) {
                                if (stage.discount)
                                    stagesCount++;
                            });
                            console.log('forecasted:');
                            console.log(data);
                            forecasteds.push({ categoryId: data.categoryId, stages: stagesCount });
                        }
                    }
                } 
            });
            forecasteds.containCategory = function (categoryId) {
                for (let i = 0; i < forecasteds.length; i++) {
                    if (forecasteds[i].categoryId == categoryId)
                        return true;
                }
                return false;
            }
            forecasteds.containCategoryStages = function (categoryId, stages) {
                for (let i = 0; i < forecasteds.length; i++) {
                    if (forecasteds[i].categoryId == categoryId && forecasteds[i].stages >= stages)
                        return true;
                }
                return false;
            }


            self.gridOptions.api.forEachNode(function (node) {
                var data = node.data;

               
                if (hasChildrenEnabled(data) && !forecasteds.containCategory(data.categoryId)) {
                    error += "\nDebe ingresar 'Pronostico de salida de stock' en " + data.categoryName;
                } else {
                    //data.details[i].isEnabled && data.details[i].isEnabled == true && data.details[i].planType == 24
                    if (forecasteds.containCategory(data.categoryId) && data.details && data.details.length >= 1) {
                        for (let i = 0; i < data.details.length; i++) {
                            if (data.details[i].isEnabled && data.details[i].isEnabled == true && data.details[i].planType != 24) {
                                let countStages = 0;
                                data.details[i].stages.forEach(function (stage) {
                                    if (stage.discount)
                                        countStages++;
                                });
                                if (!forecasteds.containCategoryStages(data.categoryId, countStages)) {
                                    error += "\n'Pronostico de salida de stock' debe tener las mismas etapas que " + data.categoryName;
                                    return false;
                                }
                            }
                        }
                    }
                }

                if (data.isEnabled && isPlanRow(data)) {

                    //if (!data.investment) {
                    //    error += '\nLa inversion no puede ser nula en ' + data.categoryName;
                    //}

                    //if (!data.marginMin) {
                    //    error += '\nEl margen minimo no puede ser nulo en ' + data.categoryName;
                    //}

                    if (!data.optionalValue && isOptionalRow(data)) {
                        error += "\nDebe ingresar un valor en el campo 'Mayor' en " + data.categoryName;
                    }

                    if (!data.discountType && hasDiscountType(data)) {
                        error += "\nDebe seleccionar un tipo de descuento en " + data.categoryName;
                    }

                    if ((data.objectiveDds == null || data.objectiveDds <= 0) && (data.objectiveVentasUn == null || data.objectiveVentasUn <= 0) && data.planType != 24) {
                        error += "\nDebe ingresar un valor válido para el objetivo en " + data.categoryName;
                    }

                    var discount = 0;
                    data.stages.forEach(function (stage) {
                        if (stage.discount)
                            discount += stage.discount
                    });

                    if (discount == 0) {
                        error += "\nDebe ingresar un % de descuento al menos la Etapa 1 del Plan en " + data.categoryName;
                    }
                   
                }
                if (hasDetails(data) && hasChildrenEnabled(data)) {

                    if (data.investment == null || (!isNaN(data.investment) && data.investment == 0) ) { 
                    
                        error += '\nLa inversion no puede ser nula en ' + data.categoryName;
                    }
                }

            });
            return error;
        }

        self.save = function () {

            var errors = validate();
            if (errors != '') {
                console.log('errors', errors);
                swal("Errores de validacion", errors, "warning");
                return;
            }
            self.selectedBrandsToSave = [self.selectedBrand.brandId];
            ngDialog.open({
                template: 'selectBrandsDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            }).closePromise.then(function (data) {
                swal({
                    title: "Esta seguro que desea guardar los cambios?",
                    text: 'Se eliminaran todas las sugerencias anteriores y las liquidaciones pendientes de validación en Prisma',
                    type: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: "Continuar",
                    cancelButtonText: "Cancelar",
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: true
                },
                    function () {
                        var itemsToSave = [];
                        var _investment = null;
                        var _marginmin = null;
                        var _minStockLiquidable = null;
                      
                        self.gridOptions.api.forEachNode(function (node) {
                            let data = node.data;
                            if (data.details && data.details.length >= 1) {

                                for (let i = 0; i < data.details.length; i++) {
                                    //ISSUE CP 934: al servicio mando TODOS los detail ya que él chequea isEnabled. 
                                    //De esta manera si se destildan todos los details de una categoria estos SI van al servicio y la regla de esa categoria queda eliminada
                                    //if (data.details[i].isEnabled && data.details[i].isEnabled == true) {                                  
                                        let auxNode = angular.copy(data.details[i]);
                                        auxNode.investment = data.investment;
                                        auxNode.marginmin = data.marginMin;
                                        auxNode.minStockLiquidable = data.minStockLiquidable;
                                        auxNode.categoryId = data.categoryId;
                                        itemsToSave.push(auxNode);
                                    //}

                                }
                            
                            }
                            /*let parentId;
                            if (!isPlanRow(node.data)) {
                                parentId = node.data.parentId;   
                                _investment = node.data.investment;
                                _marginmin = node.data.marginMin;
                                _minStockLiquidable = node.data.minStockLiquidable;
                            }

                            if (isPlanRow(node.data) && parentId && node.data.categoryId == parentId) {
                                let auxNode = angular.copy(node.data);
                                auxNode.investment = _investment;
                                auxNode.marginMin = _marginmin;
                                auxNode.minStockLiquidable = _minStockLiquidable;
                                itemsToSave.push(auxNode);
                            }
                            */
                        });

                        if (itemsToSave.length > 0) {
                            console.log('itemsToSave', itemsToSave);

                            var dto = {
                                PlanDetailDtos: itemsToSave,
                                BrandIds: self.selectedBrandsToSave || [self.selectedBrand.brandId]
                            };

                            //markdownService.rules.saveCategoryRules(itemsToSave, self.selectedBrand.brandId).then(function () {
                            markdownService.rules.saveCategoryRules(dto).then(function () {
                                swal("Confirmado", "Se guardaron los cambios seleccionados", "success");
                                self.gridOptions.api.forEachNode(function (node) {
                                    node.data.dirty = false;
                                });
                                loadGridData(self.selectedBrand.brandId);
                            }).catch(function (fallback) {
                                swal("Error", "Hubo un error al querer guardar los cambios", "error");
                            });
                        }
                        else {
                            $timeout(function () {
                                swal("Aviso", "No se detecto ningun cambio", "warning");
                            }, 1000);
                        }
                    });
            });


           
        }

        self.addBrandToSave = function (brandId) {
            if (self.selectedBrandsToSave.includes(brandId))
                self.selectedBrandsToSave.splice(self.selectedBrandsToSave.indexOf(brandId), 1);
            else
                self.selectedBrandsToSave.push(brandId);
        }

        self.closeDialog = function () {
            ngDialog.close();
        }

        function iconRenderer(params) {
            return '<a title="Click aqui para ingresar a la categoria" ng-click="ct.goToRules(' + params.data.id + ')"><i class="fa fa-arrow-circle-o-right" style="padding: 5px; font-size:16px"></i></a>';
        }

        //grid config
        function setColumnDefinitions() {

            var hName = self.companyCode != 'CencosudChile' ? "Secci&oacute;n / Categor&iacute;a" : "Gerencia Comercial / Secci&oacute;n";
            columnDefs = [
                {
                    headerName: hName,
                    children: [
                        {
                            headerName: "Activo",
                            width: 50,
                            pinned: 'left',
                            field: 'isEnabled',
                            suppressMenu: true,
                            suppressSorting: true,
                            cellClass: $rootScope.getClassForCell,
                            filter: 'input',
                            cellRenderer: function (params){                               
                                    //console.log('params', params);
                                    if (params.data.level == undefined) {
                                        var checkbox = document.createElement('input');
                                        checkbox.type = 'checkbox';
                                        checkbox.checked = params.data.isEnabled;

                                        checkbox.onclick = function (value) {
                                            //console.log('value', value.target.checked);

                                            //$timeout(function () {
                                            params.data.isEnabled = value.target.checked;
                                            if (params.data.id == null) {
                                                params.data.id = 0;
                                            }
                                            params.api.refreshCells([], []);
                                            params.api.recomputeAggregates();
                                            //})
                                            params.data.dirty = true;
                                            //console.log(params.data); 
                                        }
                                        return checkbox;



                                }
                                    return '';
                            }

                        },
                        {
                            headerName: hName,
                            width: numberColWidth + 180,
                            pinned: 'left',
                            field: 'categoryName',
                            suppressMenu: true,
                            suppressSorting: true,
                            filter: false,
                            cellRenderer: {
                                renderer: 'group',
                                checkbox: false,
                                innerRenderer: function (params) {
                                    var name = params.value + (params.node.group && params.node.children != null ? ' (' + params.node.children.length + ')' : '');

                                    //Pregunto por el pais
                                    if (self.companyCode == 'CencosudChile') {
                                        if (params.data.level == 3)
                                            //name = params.data.categoryParentName + ' / ' + name;
                                            var div = document.createElement('div');

                                        var anchor = document.createElement('a');
                                        //anchor.title = 'click para ingresar a la categoria';
                                        anchor.style = 'padding: 5px';
                                        anchor.innerHTML = name


                                        return anchor;
                                    }
                                    //No es Chile
                                    else {
                                        if (params.data.level == 3) {
                                            name = params.data.categoryParentName + ' / ' + name;
                                        }
                                        var div = document.createElement('div');

                                        var anchor = document.createElement('a');
                                        //anchor.title = 'click para ingresar a la categoria';
                                        anchor.style = 'padding: 5px';
                                        anchor.innerHTML = name


                                        return anchor;
                                    }

                                    //if (params.data.level != undefined) {
                                    //    return anchor;
                                    //} else {
                                    //    var checkbox = '<input ng-model="" type="checkbox">';
                                    //    //checkbox.value = "value";

                                    //    return checkbox + anchor.outerHTML;
                                    //}


                                    //anchor.addEventListener('click', function () {
                                    //    var categoryGroup = '';
                                    //    var category = '';
                                    //    var subcategory = '';
                                    //    if (!params.node.parent || params.node.parent == null) {
                                    //        categoryGroup = params.value;
                                    //    }
                                    //    else if (params.node.parent != null && params.node.children != null) {
                                    //        categoryGroup = params.node.parent.data.name;
                                    //        category = params.value
                                    //    }
                                    //    else if (params.node.parent != null && params.node.children == null) {
                                    //        categoryGroup = params.node.parent.parent.data.name;
                                    //        category = params.node.parent.data.name
                                    //        subcategory = params.value
                                    //    }
                                    //    self.goToRules(params.data.id, categoryGroup, category, subcategory);
                                    //});
                                }
                            }
                        },

                        {
                            headerName: "Param DDS",
                            width: 100,
                            pinned: 'left',
                            field: 'optionalValue',
                            headerTooltip: "Permite ingresar la cantidad de Dias de Stock a partir de la cual un producto se considera como Sobrestock o Sobrestock Critico",
                            onlyPlanRow: true,
                            suppressMenu: true,
                            suppressSorting: true,
                            cellClass: $rootScope.getClassForCell,
                            isOptionalColumn: true,
                            filter: 'input',
                            cellRenderer: editor('greaterThanNoDecimals')

                        },
                        
                    ]

                },
                {
                    headerName: self.selectedBrand ? self.selectedBrand.brand : '',
                    //headerName: "Gu&iacute;as de Margen",
                    
                    children: [
                        {
                            headerName: "Mín. Stock Liquidable",
                            field: "minStockLiquidable",
                            headerTooltip: "",
                            onlyCategory4: true,
                            appliesOnChildren: false,
                            filter: 'number',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 100,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: editor('percentage')
                        },
                        {
                            headerName: "Margen Min",
                            field: "marginMin",
                            headerTooltip: "",
                            onlyCategory4: true,
                            appliesOnChildren: false,
                            filter: 'number',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 100,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: editor('percentage')
                        },
                        {
                            headerName: "Inversi&oacute;n",
                            field: "investment",
                            headerTooltip: "",
                            onlyCategory4: true,
                            appliesOnChildren: false,
                            filter: 'number',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 100,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: editor('money')
                        }]
                },
                {
                    headerName: "Etapas",

                    children: [
                        {
                            headerName: "Precio / Dto",
                            field: "discountTypeString",
                            headerTooltip: "",
                            filter: 'number',
                            isDiscountTypeColumn: true,
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 100,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: dropDownEditor(self.discountTypes, "discountTypeString", "discountType", onDiscountTypeChange)
                        },
                        {
                            headerName: "Etapa 1",
                            field: "stages.0.discount",
                            filter: 'number',
                            headerTooltip: "",
                            //onlyPlanRow: true,
                            appliesOnChildren: true,
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 65,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: editor('percentage')
                        },
                        {
                            headerName: "Etapa 2",
                            dependencyField: 'stages.0.discount',
                            field: "stages.1.discount",
                            headerTooltip: "",
                            //onlyPlanRow: true,
                            appliesOnChildren: true,
                            filter: 'number',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: editor('percentage')
                        },
                        {
                            headerName: "Etapa 3",
                            dependencyField: 'stages.1.discount',
                            field: "stages.2.discount",
                            filter: 'number',
                            headerTooltip: "",
                            //onlyPlanRow: true,
                            appliesOnChildren: true,
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: editor('percentage')
                        },
                        {
                            headerName: "Etapa 4",
                            dependencyField: 'stages.2.discount',
                            field: "stages.3.discount",
                            headerTooltip: "",
                            filter: 'number',
                            //onlyPlanRow: true,
                            appliesOnChildren: true,
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: editor('percentage')
                        },
                        {
                            headerName: "Etapa 5",
                            dependencyField: 'stages.3.discount',
                            field: "stages.4.discount",
                            headerTooltip: "",
                            filter: 'number',
                            suppressMenu: true,
                            //onlyPlanRow: true,
                            appliesOnChildren: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: editor('percentage')
                        },
                        {
                            headerName: "Vig. Indet.",
                            field: "validToUndetermined",
                            headerTooltip: "",
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            filter: 'input',
                            cellRenderer: checkboxEditor()//$rootScope.checkboxEditor
                        }
                    ]
                },
                {
                    headerName: "Objetivos",
                    children: [
                        {
                            headerName: "Reduccion De Stock (%)", //ex objetivo
                            field: "objectiveVentasUn",
                            //isObjectiveColumn: true,
                            appliesOnChildren: true,
                            headerTooltip: "",
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 150,
                            cellClass: $rootScope.getClassForCell,
                            filter: 'number',
                            cellRenderer: editor('percentage', onObjReduccionDeStockChange)
                            //cellRenderer: dropDownEditor(self.objectiveTypes, "objectiveTypeString", "objectiveType")
                        },
                        {
                            headerName: "DDS",//ex valor
                            field: "objectiveDds",
                            //isObjectiveColumn: true,
                            appliesOnChildren: true,
                            headerTooltip: "",
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 120,
                            cellClass: $rootScope.getClassForCell,
                            filter: 'input',
                            cellRenderer: editor('number', onObjDdsChange)
                        }
                    ]
                }
            ]
        }

        //Grid Renderer
        function onObjReduccionDeStockChange(params, newValue) {
            if (newValue)
                params.data.objectiveDds = null;
        }

        function onObjDdsChange(params, newValue) {
            if (newValue)
                params.data.objectiveVentasUn = null;
        }

        function setGridOptions() {

            setColumnDefinitions();

            self.gridOptions = {
                columnDefs: columnDefs,
                enableColResize: true,
                enableSorting: false,
                enableFilter: false,
                groupUseEntireRow: false,
                rowSelection: "multiple",
                suppressCellSelection: true,
                groupSelectsChildren: true,
                groupSuppressAutoColumn: false, // or undefined
                groupColumnDef: null, // or undefined
                singleClickEdit: true,
                rowHeight: 35,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                enableRangeSelection: false,
                angularCompileRows: true,
                getNodeChildDetails: getNodeChildDetails,
                getRowStyle: function (params) {
                    //console.log('params', params);
                    if (params.data.isEnabled == false) {
                        return { background: 'lightgrey' }
                    }
                },
                doesExternalFilterPass: doesExternalFilterPass,
                isExternalFilterPresent: isExternalFilterPresent
            }
        }

        function getNodeChildDetails(rowItem) {
            let chile = self.companyCode == 'CencosudChile';

            if (rowItem.details && rowItem.details.length > 0) {
                return {
                    group: true,
                    expanded: false,
                    // provide ag-Grid with the children of this group
                    children: rowItem.details,
                    // the key is used by the default group cellRenderer
                    key: (chile ? rowItem.categoryd : rowItem.id)
                };
            }
            //  SI CHILE
            if (chile) {

                if (rowItem.categories && rowItem.categories.length > 0) {
                    return {
                        group: true,
                        expanded: false,
                        // provide ag-Grid with the children of this group
                        children: rowItem.categories,
                        // the key is used by the default group cellRenderer
                        key: rowItem.categoryd
                    };
                }
                if (rowItem.sections && rowItem.sections.length > 0) {
                    return {
                        group: true,
                        expanded: false,
                        // provide ag-Grid with the children of this group
                        children: rowItem.sections,
                        // the key is used by the default group cellRenderer
                        key: rowItem.categoryIid
                    };
                } else {
                    return null;
                }

            } else {

                if (rowItem.subcategories && rowItem.subcategories.length > 0) {
                    return {
                        group: true,
                        expanded: false,
                        // provide ag-Grid with the children of this group
                        children: rowItem.subcategories,
                        // the key is used by the default group cellRenderer
                        key: rowItem.id
                    };
                } else {
                    return null;
                }
            }
            
        }

        function onDiscountTypeChange(params, newValue) {
            console.log('params', params);
            console.log('newValue', newValue);

            if (newValue == '$') {

            } else {
                params.data.validToUndetermined = false;
            }
            params.api.recomputeAggregates();

        }

        //grid filter
        //self.onFilterChanged = function (value) {
        //    self.gridOptions.api.setQuickFilter(value);
        //}

        //Borro todas las columnas que dependan de mi. Lo hago en cascada y recursivo para barrer todo
        function clearDependencyColumn(params, column) {
            //debugger;
            var columns = params.api.columnController.allColumns;
            for (var i = 0; i < columns.length; i++) {
                if (columns[i].colDef.dependencyField == column) {
                    clearDependencyColumn(params, columns[i].colId);
                    setField(params.data, columns[i].colId, null);
                }
            }

        }

        function clearAlternativeColumn(params, colIds, changedRows, node) {
            if (params.value) {

                if (params.colDef.field == 'objectiveVentasUn') {
                    setField(params.data, 'objectiveDds', null);
                    if (node.children) {
                        node.children.forEach(function (x) {
                            setField(x.data, 'objectiveDds', null); 
                        });
                    }
                }
                    

                if (params.colDef.field == 'objectiveDds') {
                    setField(params.data, 'objectiveVentasUn', null);
                    if (node.children) {
                        node.children.forEach(function (x) {
                            setField(x.data, 'objectiveVentasUn', null);
                        });
                    }
                }
                    

                //var column = params.column.colId;
                //var alternativeColumn = column.indexOf('Amount') !== -1 ? column.replace('Amount', '') : (column + 'Amount');

                //node.data[alternativeColumn] = null;
                //colIds.push(alternativeColumn);
                //changedRows.push(node);
            }
        }

        function clearColumn(params, colIds, changedRows, node) {
            if (!doesColumnApply(params.column.colDef, params.data)) return;

            if (!getField(params.data, params.column.colId)) {
                clearDependencyColumn(params, params.column.colId);
            }
            clearAlternativeColumn(params, colIds, changedRows, node);
        }


        self.brandClick = function (brand) {

            if (self.selectedBrand == null) {
                changeBrand(brand);
                return;
            }

            if (self.selectedBrand.brandId == brand.brandId) {
                return;
            }

            var hasChanges = false;
            self.gridOptions.api.forEachNode(function (node) {
                if (node.data.dirty) {
                    hasChanges = true;
                }
            });

            if (hasChanges) {
                swal({
                    title: "Tiene cambios pendientes sin guardar",
                    text: 'Si cambia de Marca, se perderan los cambios que haya hecho para ' +
                    self.selectedBrand.brand + '.',
                    type: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Descartar",
                    cancelButtonText: "Cancelar",
                    showLoaderOnConfirm: true,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                    function () {
                        changeBrand(brand);
                    });
            }
            else
                changeBrand(brand);
        }
      
        self.externalFilterChanged = function () {
            self.gridOptions.api.onFilterChanged();
        }     

        function isExternalFilterPresent() {
            return self.search != null && self.search != '';
        }

        function doesExternalFilterPass(node) {
            if (node.parent.data &&
                node.parent.parent &&
                node.parent.parent.data &&
                (node.parent.data.categoryName.toLowerCase().indexOf(self.search.toLowerCase()) != -1
                || node.parent.data.categoryParentName.toLowerCase().indexOf(self.search.toLowerCase()) != -1
                || node.parent.parent.data.categoryName.toLowerCase().indexOf(self.search.toLowerCase()) != -1
                || node.parent.parent.data.categoryParentName.toLowerCase().indexOf(self.search.toLowerCase()) != -1))
                return true;

            return false;
        }        

        function changeBrand(brand) {

            self.selectedBrand = brand;



            loadGridData(brand.brandId);

        }

        function loadGridData(brandId) {
            setColumnDefinitions();

            if (self.gridOptions.api && typeof self.gridOptions.api.showLoadingOverlay == 'function')
                self.gridOptions.api.showLoadingOverlay();

            markdownService.rules.getRulesCategories(brandId)
                .then(function (summary) {
                    console.log('summary', summary);
                    self.summary = summary;
                    self.gridOptions.api.setRowData(summary);
                    console.log('colu', columnDefs);
                    self.gridOptions.api.setColumnDefs(columnDefs);
                })
        }

        self.quickLoadEnabled = false;    


        self.doQuickLoad = function () {

            self.selectedCategories2 = [];
            self.selectedCategories3 = [];
            self.selectedCategories4 = [];
            self.selectedCategories5 = [];
            let chile = self.companyCode == 'CencosudChile';
            self.isLoadingQuickLoad = true;
            markdownService.rules.getRulesCategories(self.selectedBrand.brandId)
                .then(function (summary) {
                   //asegura un summary, TO DO! implementar una manera mas optima de obtener un auxCategory
                    self.summary = summary;

                    var auxCategory = null;
                    if (chile) {
                        var auxCategory = angular.copy(self.summary.shift().sections.shift().categories.shift());
                    } else {

                        auxCategory = angular.copy(self.summary.shift().subcategories.shift());
                    }

                    auxCategory.categoryName = 'Categoria';
                    auxCategory.investment = null;
                    auxCategory.marginMin = null;
                    auxCategory.minStockLiquidable = null;

                    angular.forEach(auxCategory.details, function (d) {
                        d.discountType = 0;
                        d.discountTypeString = "";
                        d.forecastSellOut = null;
                        d.isEnabled = false;
                        d.objectiveDds = null;
                        d.objectiveVentasUn = null;
                        d.validToUndetermined = false;
                        d.optionalValue = null;
                        angular.forEach(d.stages, function (s) {
                            s.discount = null;
                        });

                    });

                    self.quickLoad = {
                        categories: [],//cat seleccionadas
                        categorySettings: auxCategory
                    };

                    self.quickLoadEnabled = !self.quickLoadEnabled;

                    self.isLoadingQuickLoad = false;
                });

        }

        self.applyQuickLoad = function () {
            self.quickLoadEnabled = false;

            // 1. Define el alcance de categorias de la carga al nivel mas bajo de la jerarquia del negocio 
            if (self.companyCode == 'CencosudChile') {
                angular.forEach(self.selectedCategories3, function (cat3) {
                    self.selectedCategories4 = self.selectedCategories4.concat(self.categories4.filter(function (cat4) {
                        return cat4.parentId === cat3.id;
                    }));
                });

                angular.forEach(self.selectedCategories4, function (cat4) {
                    self.selectedCategories5 = self.selectedCategories5.concat(self.categories5.filter(function (cat5) {
                        return cat5.parentId === cat4.id;
                    }));
                });

                self.quickLoad.categories = self.selectedCategories5;
            }
            else {
                angular.forEach(self.selectedCategories2, function (cat2) {
                    self.selectedCategories3 = self.selectedCategories3.concat(self.categories3.filter(function (cat3) {
                        return cat3.parentId === cat2.id;
                    }));
                });

                angular.forEach(self.selectedCategories3, function (cat3) {
                    self.selectedCategories4 = self.selectedCategories4.concat(self.categories4.filter(function (cat4) {
                        return cat4.parentId === cat3.id;
                    }));
                });

                self.quickLoad.categories = self.selectedCategories4;
            }

            //2. Aplicacion a la grilla
            var changedRows = [];
            angular.forEach(self.quickLoad.categories, function (cat) {
                self.gridOptions.api.forEachNode(function (node) {
                    if (node.data.categoryId === cat.id && node.data.details) {                                    
                        node.data.minStockLiquidable = self.quickLoad.categorySettings.minStockLiquidable ? self.quickLoad.categorySettings.minStockLiquidable / 100 : null;
                        node.data.marginMin = self.quickLoad.categorySettings.marginMin ? self.quickLoad.categorySettings.marginMin / 100 : null;
                        node.data.investment = self.quickLoad.categorySettings.investment;
                        angular.forEach(self.quickLoad.categorySettings.details, function (detail) {
                            var nodeDetail = node.data.details.find(function (d) {
                                return d.planType === detail.planType;
                            });
                            nodeDetail.updatedByQuickLoad = true;
                            nodeDetail.isEnabled = detail.isEnabled;
                            nodeDetail.optionalValue = detail.optionalValue;
                            nodeDetail.discountType = detail.discountType ? parseInt(detail.discountType) : 0;
                            if (detail.discountType == '1')
                                nodeDetail.discountTypeString = '%';
                            else if (nodeDetail.discountType == '2')
                                nodeDetail.discountTypeString = '$';
                            else nodeDetail.discountTypeString = '';
                            nodeDetail.validToUndetermined = detail.validToUndetermined;
                            nodeDetail.objectiveVentasUn = detail.objectiveVentasUn ? detail.objectiveVentasUn / 100 : null;
                            nodeDetail.objectiveDds = nodeDetail.objectiveVentasUn ? null : detail.objectiveDds;
                            angular.forEach(detail.stages, function (stage) {
                                var nodeDetailStage = nodeDetail.stages.find(function (s) {
                                    return s.stageId === stage.stageId;
                                });
                                nodeDetailStage.discount = stage.discount ? stage.discount / 100 : null;
                            });
                        });
                        node.data.dirty = true;
                        changedRows.push(node);
                    }
                });
            });

            self.gridOptions.api.refreshRows(changedRows);
            $timeout(function () {
                self.gridOptions.api.refreshView();
            }, 100);
        
        }

        

        function loadBrands() {
            markdownService.rules.getBrands().then(function (brands) {
                self.brands = brands;

                changeBrand(brands[0]);
            })
        }


        function loadCategories() {

            var arr = (self.companyCode == "CencosudChile" ? [2, 3, 4, 5] : [2, 3, 4]);

            adminService.categories.getCategoriesWithLevels(arr)
                .then(function (categories) {
                    
                    self.categories2 = categories.filter(function (x) { return x.level === 2; });
                    self.categories3 = categories.filter(function (x) { return x.level === 3; });
                    self.categories4 = categories.filter(function (x) { return x.level === 4; });
                    if(self.companyCode == "CencosudChile")
                        self.categories5 = categories.filter(function (x) { return x.level === 5; });
            });
        }

        function loadData() {

            loadBrands();
            loadCategories();
        }

        function init() {

            self.gridOptions = null;
            self.summary = null;
            self.competitorGroups = [];

            //init columnDefs and gridOptions
            setGridOptions();
            loadData();
        }

        init();







        ///////////////////////////////// START EDITORS ////////////////////////////////////////

        function checkboxEditor(onChangeCallback) {
            return function (params) {
                if (params.data.discountType == 1 || !params.data.isEnabled) {// para el tipo de descuento % no aplica vigencia indeterminada
                    return '';
                }
                var eInput = document.createElement("input");
                eInput.type = "checkbox";
                eInput.checked = params.value;

                eInput.addEventListener("change", function (event) {
                    var changedRows = [];
                    var colIds = [];
                    colIds.push(params.column.colId);
                    params.value = eInput.checked;
                    params.data[params.column.colId] = eInput.checked;

                    //Excepto para la grilla de precios
                    if (params.colDef.field != 'priceId') {
                        if (params.node != null && params.node.children != null) {
                            params.node.children.forEach(function (node) {
                                if (node.children) {
                                    node.children.forEach(function (node) {
                                        node.data[params.column.colId] = eInput.checked;
                                        node.data.dirty = true;
                                        changedRows.push(node);
                                    });
                                }
                                node.data[params.column.colId] = eInput.checked;
                                node.data.dirty = true;
                                changedRows.push(node);
                            });
                            params.api.refreshCells(changedRows, colIds);
                        }
                        params.data.dirty = true;
                    }


                    if (onChangeCallback != undefined) {
                        onChangeCallback(event, params);
                    }
                });
                //console.log('eInput', eInput);
                return eInput;
            }
        }



        //maximo, 5 campos
        function setField(data, field, value) {
            //debugger;
            if (field.indexOf('.') < 0) {
                data[field] = value;
            }
            else {
                // otherwise it is a deep value, so need to dig for it
                var fields = field.split('.');
                console.log(fields);
                try {

                    if (fields.length == 2) {
                        data[fields[0]][fields[1]] = value;

                    } else if (fields.length == 3) {
                        data[fields[0]][fields[1]][fields[2]] = value;

                    } else if (fields.length == 4) {
                        data[fields[0]][fields[1]][fields[2][fields[3]]] = value;

                    } else if (fields.length == 5) {
                        data[fields[0]][fields[1]][fields[2]][fields[3]][fields[4]] = value;

                    }

                } catch (e) {
                    //?
                    //console.error('Error parseando el setField. Error: ', e.message, data, field, value);
                    return;
                }
            }
        }

        //maximo, 5 campos
        function getField(data, field) {
            if (!field) return field;
            //debugger;
            if (field.indexOf('.') < 0) {
                return data[field];
            }
            else {
                // otherwise it is a deep value, so need to dig for it
                var fields = field.split('.');

                try {
                    if (fields.length == 2) {
                        return data[fields[0]][fields[1]];

                    } else if (fields.length == 3) {
                        return data[fields[0]][fields[1]][fields[2]];

                    } else if (fields.length == 4) {
                        return data[fields[0]][fields[1]][fields[2][fields[3]]];

                    } else if (fields.length == 5) {
                        return data[fields[0]][fields[1]][fields[2]][fields[3]][fields[4]];

                    }
                } catch (e) {
                    return null;
                }


            }
        }

        function hasDiscountType(data) {
            return data.planType != 24;
        }

        function hasObjective(data) {
            return data.planType != 24;
        }

        function isPlanRow(data) {
            return  data.details == undefined;
        }

        function hasDetails(data) {
            return (data.details != undefined && data.details.length != 0);
        }

        function isOptionalRow(data) {
            return data.planType == 19 || data.planType == 20 || data.planType == 21;
        }

        function doesColumnApply(colDef, data) {

            if (colDef.onlyCategory4 && !hasDetails(data)) {
                //console.log('no puedo editar esto a este nivel');
                return false;
            }
            else if (colDef.onlyPlanRow && !isPlanRow(data)) {
                //console.log('no puedo editar esto a este nivel');
                return false;
            }

            else if (colDef.isOptionalColumn && !isOptionalRow(data)) {
                //console.log('no puedo editar esto a este nivel');
                return false;
            }
            else if (colDef.isDiscountTypeColumn && !hasDiscountType(data)) {
                //console.log('no puedo editar esto a este nivel');
                return false;
            }
            else if (colDef.isObjectiveColumn && !hasObjective(data)) {
                //console.log('no puedo editar esto a este nivel');
                return false;
            }
            else if (isPlanRow(data)
                //&& data.isEnabled
                && colDef.dependencyField
                && !getField(data, colDef.dependencyField)) {
                //console.log('dependencia faltante');
                return false;
            }

            return true;
        }
        //issue_cp_586
        function renderMarginMin(params) {
           
            if (params.value == null || params.value === '')
                return '';

            var value = params.value * 100;

            return value.toFixed(0) + " %";
           
        }

        function renderMoney(params) {
            
            if (params.value == null  || params.value == '')
                return '';

            return "$ " + $filter('number')(params.value, 2);
        };
        function integerRendererWithoutZero(params) {
            if (params.value == null || (!isNaN(params.value) && params.value <= 0))
                return '';

            return $filter('number')(params.value, 0);
        };
        function editor(type, onChangeCallback) {
            return function innerEditor(params) {

                if (!doesColumnApply(params.column.colDef, params.data)
                    && params.column.colDef.field != 'marginMin' // parche para no agregar otro campo al colDef
                    && params.column.colDef.field != 'investment' // parche para no agregar otro campo al colDef
                ) {
                    return '';
                }

                var rendererFunction;
                var innerText;
                if (type == 'money') {
                    rendererFunction = renderMoney;
                    innerText = '$';
                }
                else if (type == 'greaterThanNoDecimals') {
                    rendererFunction = $rootScope.greaterThanNoDecimals;
                    innerText = '>';

                } else if (type == 'percentage') {
                    if (params.column.colDef.field == 'marginMin') {
                        rendererFunction = renderMarginMin;
                    } else {

                        rendererFunction = $rootScope.percentageRendererNoDecimals;
                    }
                    
                    innerText = '%';
                } else if (type == 'number') {
                    rendererFunction = integerRendererWithoutZero
                    innerText = '';
                }
                else {
                    rendererFunction = function (value) { return value };
                    innerText = '';

                }



                var editing = false;

                var eCell = document.createElement('div');
                eCell.style = "width:100% !important;height:100% !important";
                eCell.className = "input-group";

                var eLabel = document.createTextNode(''); 
                if (params.value != null) {
                    eLabel = document.createTextNode(rendererFunction(params));
                    /*if (params.column.colDef.field == 'investment' ) {
                        eLabel = document.createTextNode(rendererFunction(params));
                    } else {
                        eLabel = document.createTextNode(rendererFunction(params));
                    }*/
                } 
                //var eLabel = params.value != null ? document.createTextNode(rendererFunction(params)) : document.createTextNode('');
                eCell.appendChild(eLabel);

                var eSpan = document.createElement("span");
                eSpan.className = "input-group-addon";
                eSpan.style = "margin:0px !important; height: 100% !important; border: 0px; line-height: 100%; font-size:12px !important";
                eSpan.innerText = innerText;

                var eInput = document.createElement("input");
                eInput.type = "number";
                //eInput.step = "1";
                eInput.className = "form-control";

                eCell.addEventListener('click', function () {
                    if (!editing) {
                        if (doesColumnApply(params.column.colDef, params.data)) {
                            eCell.removeChild(eLabel);
                            if (type == 'greaterThanNoDecimals') {
                                if (params.data.isEnabled) {
                                    eCell.appendChild(eSpan);
                                    eCell.appendChild(eInput);
                                }
                            } else {
                                if (params.data.isEnabled
                                    || params.column.colDef.field != 'marginMin'
                                    || params.column.colDef.field != 'investment') {
                                    eCell.appendChild(eInput);
                                    eCell.appendChild(eSpan);
                                }
                            }

                            eInput.focus();

                            if (params.data.isEnabled
                                && params.column.colDef.field != 'marginMin'
                                && params.column.colDef.field != 'investment') {
                                if (type == 'percentage') {
                                    eInput.value = params.value ? params.value * 100 : null;
                                } else {
                                    eInput.value = params.value;
                                }
                            }

                            editing = true;
                        }
                    }
                });

                eInput.addEventListener('blur', function () {
                    if (editing) {
                        editing = false;
                        eCell.removeChild(eSpan);
                        eCell.removeChild(eInput);
                        eCell.appendChild(eLabel);
                    }
                });

                eInput.addEventListener('change', function () {
                    if (editing) {
                        editing = false;

                        eCell.removeChild(eSpan);
                        eCell.removeChild(eInput);
                        eCell.appendChild(eLabel);
                        console.log('einput.value', eInput.value);

                        if (eInput.value > 100 && type == 'percentage')
                            return;


                        var newValue = eInput.value;

                        if (type == 'percentage') {
                            var newValue = eInput.value / 100;
                        }
                        var oldValue = params.value;
                        if (!isNaN(newValue)) {
                            params.value = newValue;
                        } else { newValue = params.value;}
                        
                        setField(params.data, params.column.colId, newValue);
                        //params.data[params.column.colId] = newValue;
                        eLabel.nodeValue = rendererFunction(params);
                        //console.log('params.data', params.data);

                        var colIds = [];
                        var changedRows = [];

                        clearColumn(params, colIds, changedRows, params.node);

                        if (params.node != null && params.node.children != null) {
                            colIds.push(params.column.colId);
                            params.node.children.forEach(function (node) {
                                if (node.children && params.column.colDef.appliesOnChildren) {

                                    node.children.forEach(function (node) {
                                        if (doesColumnApply(params.column.colDef, node.data)) {

                                            setField(node.data, params.column.colId, params.value);
                                            node.data.dirty = true;
                                            changedRows.push(node);

                                            clearColumn(params, colIds, changedRows, node);
                                        }
                                    });
                                }
                                if (params.column.colDef.appliesOnChildren || doesColumnApply(params.column.colDef, node.data)) {
                                    setField(node.data, params.column.colId, params.value);
                                    node.data.dirty = true;
                                    changedRows.push(node);

                                    clearColumn(params, colIds, changedRows, node);
                                }

                            });
                        }

                        if (onChangeCallback != undefined) {
                            onChangeCallback(params, newValue);
                        }


                        params.api.refreshCells(changedRows, colIds);
                        params.api.recomputeAggregates();
                        params.data.dirty = true;
                    }
                });

                return eCell;
            }

        }

        function dropDownEditor(source, field, fieldId, onChangeCallback) {
            return function (params) {

                if (!doesColumnApply(params.column.colDef, params.data))
                    return '';

                var editing = false;

                var eCell = document.createElement('div');

                var eLabel = null

                if (params.value != null && params.value != "")
                    eLabel = document.createTextNode(params.value);
                else
                    eLabel = document.createTextNode("------------");

                eCell.appendChild(eLabel);

                var eSelect = document.createElement("select");
                eSelect.className = "form-control";
                eSelect.style = "margin:0px !important; border: 0px; line-height: 100%; font-size:12px !important";

                for (var i = 0; i < source.length; i++) {
                    var eOption = document.createElement("option");
                    eOption.setAttribute("value", source[i].id);
                    eOption.innerHTML = source[i].name;
                    eSelect.appendChild(eOption);
                }

                eSelect.value = params.data[fieldId];


                eCell.addEventListener('click', function () {
                    if (!editing) {
                        eCell.removeChild(eLabel);
                        eCell.appendChild(eSelect);
                        eSelect.focus();
                        editing = true;
                    }
                });

                eSelect.addEventListener('blur', function () {
                    if (editing) {
                        editing = false;
                        eCell.removeChild(eSelect);
                        eCell.appendChild(eLabel);
                    }
                });

                eSelect.addEventListener('change', function () {
                    if (editing) {

                        var colIds = [];
                        colIds.push(params.column.colId);
                        var changedRows = [];
                        changedRows.push(params.node);

                        editing = false;
                        var newValue = '';

                        for (var i = 0; i < source.length; i++) {
                            if (source[i].id == eSelect.value) {
                                newValue = source[i].name;
                                params.data[field] = newValue;
                                params.data[fieldId] = eSelect.value;
                                //Children
                                if (params.node != null && params.node.children != null) {
                                    params.node.children.forEach(function (node) {
                                        if (node.children) {
                                            node.children.forEach(function (node) {
                                                node.data[field] = newValue;
                                                node.data[fieldId] = eSelect.value;
                                                node.data.dirty = true;
                                                changedRows.push(node);
                                            });
                                        }
                                        node.data[field] = newValue;
                                        node.data[fieldId] = eSelect.value;
                                        node.data.dirty = true;
                                        changedRows.push(node);
                                    });
                                }
                                break;
                            }
                        }


                        params.data.dirty = true;

                        params.api.refreshCells(changedRows, colIds);

                        eLabel.nodeValue = newValue;
                        eCell.removeChild(eSelect);
                        eCell.appendChild(eLabel);

                        if (onChangeCallback != undefined) {
                            onChangeCallback(params, newValue);
                        }

                    }
                });

                return eCell;
            }

        }

        ///////////////////////////////// END EDITORS //////////////////////////////////////////
    });

