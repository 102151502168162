angular.module("prisma")
 .controller("BIReportCtrl", function ($scope, $rootScope, $state, $stateParams, promotionsService, $timeout, ngDialog, authService) {

     var self = this;

     self.hasPermissionToDownLoad = authService.hasPermission('promotion_bi_report');

     self.isLoading = false;

     self.openStatusDialog = function () {
         var newScope = $scope.$new();
         newScope.generateReport = function (planned) {
             self.isLoading = true;
             promotionsService.reports.buildIBIReport(planned)
             .then(function () {
                 init();
                 },function (status) {
                     if (status == 502) {
                         swal("El proceso continua...", "El proceso esta demorado, no intente generar otro reporte por unos minutos.")
                     }
                     else {
                         swal("Error", "El reporte no se generará, inténtelo nuevamente", "error");
                     }
                 });

                 ngDialog.close();
         }

         ngDialog.open({
             template: 'statusDialog',
             className: 'ngdialog-theme-default ngdialog-theme-custom',
             scope: newScope
         });
     }

     function init() {
         self.isLoading = true;
         promotionsService.reports.getLastBIReports()
         .then(function (reportList) {
             self.reportList = reportList;
             self.isLoading = false;
         });
         
     }

     init();
 });