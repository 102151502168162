angular.module("prisma")
 .controller("editPromotionalWorkflowCtrl", function ($scope, $state, $stateParams, $timeout, $filter, promotionsService, ngDialog, ganttUtils, moment) {
     var self = this;
     self.api = null;

     self.startPromotionDate = null;
     self.timespans = [];
     self.canEditTask = true;

     var startDateOffset = new Date(new Date().toDateString());
     startDateOffset = startDateOffset.setTime(startDateOffset.getTime() - (86400000));

     self.ganttOptions = {
         columns: [],
         columnsHeaders: { 'model.name': 'Tarea' },
         columnsClasses: { 'model.name': 'gantt-column-name' },
         fromDate: moment(startDateOffset),
         currentDate: 'line',
         currentDateValue: moment(startDateOffset),
         api: function (api) {
             // API Object is used to control methods and events from angular-gantt.
             self.api = api;
         },
         timeFrames: {
             'day': {
                 start: moment('8:00', 'HH:mm'),
                 end: moment('20:00', 'HH:mm'),
                 color: '#ACFFA3',
                 working: true,
                 default: true
             },
             'noon': {
                 start: moment('12:00', 'HH:mm'),
                 end: moment('13:30', 'HH:mm'),
                 working: false,
                 default: true
             },
             'closed': {
                 working: false,
                 default: true
             },
             'weekend': {
                 working: false
             },
             'holiday': {
                 working: false,
                 color: 'red',
                 classes: ['gantt-timeframe-holiday']
             },
             'startPromotion': {
                 working: true,
                 color: 'green',
                 classes: ['gantt-timeframe-holiday']
             }
         },
         dateFrames: {
             'weekend': {
                 evaluator: function (date) {
                     return date.isoWeekday() === 6 || date.isoWeekday() === 7;
                 },
                 targets: ['weekend']
             },
             'startPromotion': {
                 evaluator: function (date) {
                     return true;
                     //return date != null && self.startPromotionDate != null ? date.getTime() === self.startPromotionDate.getTime() : false;
                 },
                 targets: ['startPromotion']
             },
         },
         timeFramesWorkingMode: 'hidden',
         timeFramesNonWorkingMode: 'visible',
         dependencies: {
             enabled: true,
             conflictChecker: true
         }
     };

     self.ganttdata = [];

     self.previousTasks = [];

     self.tasks = [];

     var colors = promotionsService.colors;

     self.allPromotionMediaTypes = promotionsService.promotionMediaTypes;
     self.promotionMediaTypes = self.allPromotionMediaTypes;

     self.userAreas = promotionsService.userAreas;

     self.promotionTypeChanged = function () {
         if (self.workflow.promotionType == 'Interna') {
             self.promotionMediaTypes = self.allPromotionMediaTypes;
             self.workflow.promotionMediaType = 6; //Promo Interna
         }
         else if (self.workflow.promotionType == 'Otras') {
             self.promotionMediaTypes = self.allPromotionMediaTypes;
             self.workflow.promotionMediaType = 10; //Otras Promo(se quito)
         }
         else { //Externa, Saco la variable Interna y externa del tipo de medio.
             var rv = [];
             //issue CencosudPeru #123 Pide: "Reincorporar internas al crear un proceso promocional"
             angular.forEach(self.allPromotionMediaTypes, function (mt) {
                 if (mt.id != 10)
                     rv.push(mt);
             });

             self.promotionMediaTypes = rv;

             self.workflow.promotionMediaType = null;
         }
     }

     //Este es el objeto para Agregar o Editar Tareas
     self.workflowtask = null;

     function loadWorkflow() {
         if ($stateParams.workflowId != '0') {
             promotionsService.workflows.getWorkflow($stateParams.workflowId)
                .then(function (workflow) {
                    workflow.validFrom = new Date(workflow.validFrom);
                    workflow.validTo = new Date(workflow.validTo);
                    self.workflow = workflow;

                    //set identifiers
                    angular.forEach(self.workflow.tasks, function (task) {
                        task.identifier = task.name;
                    });

                    redrawGantt();
                });
         }
         else {
             self.workflow = { Id: 0, companyId: 0, name: '', description: '', promotionMediaType: 0, tasks: [] };
         }
     }

     function loadTasks() {
         promotionsService.tasks.getTasks()
                       .then(function (tasks) {
                          // self.tasks = tasks;// TODO descomentar cuando todas las tareas tengan funcionalidad
                           angular.forEach(tasks, function (t) {
                               if (t.name != "Reporte Impacto Preliminar"
                                   && t.name != "Carga de costos de publicación"
                                   && t.name != "Alerta de problemas de stocks"
                                   && t.name != "1° Control y Exhibición en tienda"
                                   && t.name != "2° Control y Exhibición en tienda"
                                   && t.name != "3° Control y Exhibición en tienda")
                                    self.tasks.push(t);
                               });
                       });
     }

     function isWorkflowInUse() {
         if ($stateParams.workflowId != '0') {
             promotionsService.workflows.isWorkflowInUse($stateParams.workflowId)
                .then(function (isInUse) {
                    if (isInUse)
                        self.canEditTask = false;
                });
         }
     }


     //Esta función se llama cuando se cambia el Tipo de Tarea en el Dialog de Nueva tarea
     self.taskChanged = function () {
         angular.forEach(self.tasks, function (task) {
             if (self.workflowtask.promotionTaskId == task.id) {
                 let oldTask = angular.copy(self.workflowtask);
                 self.workflowtask.userArea = task.userArea;
                 self.workflowtask.groupName = task.groupName;
                 self.workflowtask.name = task.name;
                 self.workflowtask.identifier = task.name;
                 self.workflowtask.isRequired = task.isRequired;
                 self.workflowtask.navigateToState = task.navigateToState;
                 if (task.isStartMilestone)
                     self.workflowtask.durationInDays = 1;
                 self.workflowtask.isStartMilestone = task.isStartMilestone;
                 if (self.workflowtask.added)
                    updateDependencies(oldTask, self.workflowtask);
             }
         });
     }

     self.closeEditDialog = function () {
         ngDialog.close();
     }

     $scope.handleEditTask = function (rowModel) {
         angular.forEach(self.workflow.tasks, function (task) {
             if (task.identifier == rowModel.id) {
                 self.editTask(task);
             }
         });
     };

     $scope.handleRemoveTask = function (rowModel) {

         if (self.canEditTask) {
             //Busco en la colección e identifico la tarea a eliminar
             var taskToRemove = null;
             var indexInWorckflowTasks = -1;

             angular.forEach(self.workflow.tasks, function (task, i) {
                 if (task.name == rowModel.id) {
                     taskToRemove = task;
                     indexInWorckflowTasks = i;
                 }
             });

             var hasDependencies = taskHasDependencies(taskToRemove);

             swal({
                 title: 'Esta seguro que desea eliminar esta tarea del Proceso Promocional?' + (hasDependencies ? ' Tiene Dependencias que serán eliminadas' : ''),
                 type: "warning",
                 showCancelButton: true,
                 confirmButtonColor: "#1AB394",
                 confirmButtonText: "Continuar",
                 cancelButtonText: "Cancelar",
                 showLoaderOnConfirm: true,
                 closeOnConfirm: false,
                 closeOnCancel: true
             },
                function (isConfirm) {
                    if (isConfirm) {

                        if (taskToRemove != null) {
                            if (hasDependencies) {

                                //Elimino las dependencias
                                deleteDependencies(taskToRemove);
                            }
                            //Borro de la coleccion
                            self.workflow.tasks.splice(indexInWorckflowTasks, 1);

                            promotionsService.workflows.removeTask(taskToRemove)
                                .then(function (workflow) {
                                    redrawGantt();

                                    swal('Tarea Eliminada!', 'La tarea ' + rowModel.name + ' se ha eliminado exitosamente', 'success');

                                }, function (status) {
                                    if (status == 502) {
                                        swal("El proceso continua...", "Puede revisar el estado en unos minutos.")
                                    }
                                    else {
                                        swal("Error", "Hubo un error al eliminar esta tarea", "error");
                                    }
                                });
                        }
                        else {
                            $timeout(function () {
                                swal('Error!', 'No se ha podido eliminar la tarea' + rowModel.name, 'error');
                            }, 100);
                        }
                    }
                });
         }
         else {
             swal("Error", "No se puede eliminar una tarea, porque el workflow ya fué asignado a una promocion", "error");
         }
     };

     function taskHasDependencies(taskToRemove) {

         var rv = false;
         if (taskToRemove != null) {
             angular.forEach(self.workflow.tasks, function (task, i) {
                 if (task.promotionTaskPreviousId == taskToRemove.id || task.previousIdentifier == taskToRemove.previousIdentifier)
                     rv = true;
             });
         }

         return rv;
     }

     function deleteDependencies(taskToRemove) {

         if (taskToRemove != null) {
             angular.forEach(self.workflow.tasks, function (task, i) {
                 if (task.promotionTaskPreviousId == taskToRemove.id || task.previousIdentifier == taskToRemove.identifier) {
                     task.promotionTaskPreviousId = null;
                     task.previousIdentifier = null;
                 }
             });
         }
     }

     function updateDependencies(taskWithDependences, taskUpdated) {

         if (taskWithDependences != null && taskUpdated != null) {
             angular.forEach(self.workflow.tasks, function (task, i) {
                 if (task.promotionTaskPreviousId == taskWithDependences.id || task.previousIdentifier == taskWithDependences.identifier) {
                     task.promotionTaskPreviousId = taskUpdated.id;
                     task.previousIdentifier = taskUpdated.identifier;
                 }
             });
         }
     }

     function redrawGanttOLD() {
         var ganttdataitems = [];

         var prevGroupName = '';
         var groupCount = 0;
         var groupId = 1000;
         var group = { id: groupId, name: '', children: [] };

         var startDate = moment(new Date().toDateString());
         var initDate = startDate;
         var endDate = moment(new Date().toDateString());

         //Paso 1: Recorro todos los tasks para crear los items de los groupnames
         angular.forEach($filter('orderBy')(self.workflow.tasks, 'promotionTaskId', false), function (task, i) {
             if (prevGroupName != '' && prevGroupName != task.groupName) {
                 var groupCopy = {};
                 ganttdataitems.push(angular.copy(group, groupCopy));

                 groupCount++;
                 group.children = [];
                 groupId++;
             }

             group.id = groupId;
             group.name = task.groupName;
             group.children.push(task.name);

             if (task.promotionTaskPreviousId != null) {

                 angular.forEach(ganttdataitems, function (ganttitem, i) {
                     if (ganttitem.id == task.promotionTaskPreviousId && ganttitem.tasks.length > 0) {
                         var ganttitemTask = ganttitem.tasks[0];

                         startDate = moment(endDate).add(task.startFromDay, 'days');
                         endDate = moment(startDate).add(task.durationInDays, 'days');
                     }
                 });
             }
             else {
                 startDate = moment(initDate).add(task.startFromDay, 'days');
                 endDate = moment(startDate).add(task.durationInDays + task.startFromDay, 'days');
             }

             if (groupCount > colors.length) {
                 groupCount = 0;
             }


             var ganttItem = {
                 //id: task.promotionTaskId,
                 id: ganttdataitems.length + 1, //task.promotionTaskId,
                 name: task.name,
                 content: '<a title="Click para editar Tarea" ng-click="scope.handleEditTask(row.model)">{{row.model.name}}</a> <i class="fa fa-remove" ng-click="scope.handleRemoveTask(row.model)"></i>',
                 tasks: [{ name: task.name, from: startDate, to: endDate, color: colors[groupCount], progress: { percent: 0, color: '#3C8CF8' } }],
             };

             if (task.promotionTaskPreviousId != null) {
                 //TODO: esto no está funcando
                 ganttItem.tasks[0].dependencies = [{ from: task.promotionTaskPreviousId }];
             }

             ganttdataitems.push(ganttItem);

             prevGroupName = task.groupName;

             if (task.isStartMilestone) {
                 self.startPromotionDate = startDate;
             }
         });

         ganttdataitems.push(group);
         //workflow.children.push(group.name);
         //ganttdataitems.push(workflow);

         self.ganttdata = ganttdataitems;

         /*
         self.ganttdata = [
                        // Order is optional. If not specified it will be assigned automatically
                        {
                            name: 'Milestones', height: '3em', sortable: false, drawTask: false, classes: 'gantt-row-milestone', color: '#45607D', tasks: [
                               // Dates can be specified as string, timestamp or javascript date object. The data attribute can be used to attach a custom object
                               { name: 'Kickoff', color: '#93C47D', from: '2013-10-07T09:00:00', to: '2013-10-07T10:00:00', data: 'Can contain any custom data or object' },
                               { name: 'Concept approval', color: '#93C47D', from: new Date(2013, 9, 18, 18, 0, 0), to: new Date(2013, 9, 18, 18, 0, 0), est: new Date(2013, 9, 16, 7, 0, 0), lct: new Date(2013, 9, 19, 0, 0, 0) },
                               { name: 'Development finished', color: '#93C47D', from: new Date(2013, 10, 15, 18, 0, 0), to: new Date(2013, 10, 15, 18, 0, 0) },
                               { name: 'Shop is running', color: '#93C47D', from: new Date(2013, 10, 22, 12, 0, 0), to: new Date(2013, 10, 22, 12, 0, 0) },
                               { name: 'Go-live', color: '#93C47D', from: new Date(2013, 10, 29, 16, 0, 0), to: new Date(2013, 10, 29, 16, 0, 0) }
                            ], data: 'Can contain any custom data or object'
                        },
                        {
                            name: 'Status meetings', tasks: [
                               { name: 'Demo #1', color: '#9FC5F8', from: new Date(2013, 9, 25, 15, 0, 0), to: new Date(2013, 9, 25, 18, 30, 0) },
                               { name: 'Demo #2', color: '#9FC5F8', from: new Date(2013, 10, 1, 15, 0, 0), to: new Date(2013, 10, 1, 18, 0, 0) },
                               { name: 'Demo #3', color: '#9FC5F8', from: new Date(2013, 10, 8, 15, 0, 0), to: new Date(2013, 10, 8, 18, 0, 0) },
                               { name: 'Demo #4', color: '#9FC5F8', from: new Date(2013, 10, 15, 15, 0, 0), to: new Date(2013, 10, 15, 18, 0, 0) },
                               { name: 'Demo #5', color: '#9FC5F8', from: new Date(2013, 10, 24, 9, 0, 0), to: new Date(2013, 10, 24, 10, 0, 0) }
                            ]
                        },
                        {
                            name: 'Kickoff', movable: { allowResizing: false }, tasks: [
                               {
                                   name: 'Day 1', color: '#9FC5F8', from: new Date(2013, 9, 7, 9, 0, 0), to: new Date(2013, 9, 7, 17, 0, 0),
                                   progress: { percent: 100, color: '#3C8CF8' }, movable: false
                               },
                               {
                                   name: 'Day 2', color: '#9FC5F8', from: new Date(2013, 9, 8, 9, 0, 0), to: new Date(2013, 9, 8, 17, 0, 0),
                                   progress: { percent: 100, color: '#3C8CF8' }
                               },
                               {
                                   name: 'Day 3', color: '#9FC5F8', from: new Date(2013, 9, 9, 8, 30, 0), to: new Date(2013, 9, 9, 12, 0, 0),
                                   progress: { percent: 100, color: '#3C8CF8' }
                               }
                            ]
                        },
                        {
                            name: 'Create concept', tasks: [
                               {
                                   name: 'Create concept', priority: 20, content: '<i class="fa fa-cog" ng-click="scope.handleTaskIconClick(task.model)"></i> {{task.model.name}}', color: '#F1C232', from: new Date(2013, 9, 10, 8, 0, 0), to: new Date(2013, 9, 16, 18, 0, 0), est: new Date(2013, 9, 8, 8, 0, 0), lct: new Date(2013, 9, 18, 20, 0, 0),
                                   progress: 100
                               }
                            ]
                        },
                        {
                            name: 'Finalize concept', tasks: [
                               {
                                   id: 'Finalize concept', name: 'Finalize concept', priority: 10, color: '#F1C232', from: new Date(2013, 9, 17, 8, 0, 0), to: new Date(2013, 9, 18, 18, 0, 0),
                                   progress: 100
                               }
                            ]
                        },
                        { name: 'Development', children: ['Sprint 1', 'Sprint 2', 'Sprint 3', 'Sprint 4'], content: '<i class="fa fa-file-code-o" ng-click="scope.handleRowIconClick(row.model)"></i> {{row.model.name}}' },
                        {
                            name: 'Sprint 1', tooltips: false, tasks: [
                               {
                                   id: 'Product list view', name: 'Product list view', color: '#F1C232', from: new Date(2013, 9, 21, 8, 0, 0), to: new Date(2013, 9, 25, 15, 0, 0),
                                   progress: 25, dependencies: [{ to: 'Order basket' }, { from: 'Finalize concept' }]
                               }
                            ]
                        },
                        {
                            name: 'Sprint 2', tasks: [
                               {
                                   id: 'Order basket', name: 'Order basket', color: '#F1C232', from: new Date(2013, 9, 28, 8, 0, 0), to: new Date(2013, 10, 1, 15, 0, 0),
                                   dependencies: { to: 'Checkout' }
                               }
                            ]
                        },
                        {
                            name: 'Sprint 3', tasks: [
                               {
                                   id: 'Checkout', name: 'Checkout', color: '#F1C232', from: new Date(2013, 10, 4, 8, 0, 0), to: new Date(2013, 10, 8, 15, 0, 0),
                                   dependencies: { to: 'Login & Signup & Admin Views' }
                               }
                            ]
                        },
                        {
                            name: 'Sprint 4', tasks: [
                               {
                                   id: 'Login & Signup & Admin Views', name: 'Login & Signup & Admin Views', color: '#F1C232', from: new Date(2013, 10, 11, 8, 0, 0), to: new Date(2013, 10, 15, 15, 0, 0),
                                   dependencies: [{ to: 'HW' }, { to: 'SW / DNS/ Backups' }]
                               }
                            ]
                        },
                        { name: 'Hosting' },
                        {
                            name: 'Setup', tasks: [
                               { id: 'HW', name: 'HW', color: '#F1C232', from: new Date(2013, 10, 18, 8, 0, 0), to: new Date(2013, 10, 18, 12, 0, 0) }
                            ]
                        },
                        {
                            name: 'Config', tasks: [
                               { id: 'SW / DNS/ Backups', name: 'SW / DNS/ Backups', color: '#F1C232', from: new Date(2013, 10, 18, 12, 0, 0), to: new Date(2013, 10, 21, 18, 0, 0) }
                            ]
                        },
                        { name: 'Server', parent: 'Hosting', children: ['Setup', 'Config'] },
                        {
                            name: 'Deployment', parent: 'Hosting', tasks: [
                               { name: 'Depl. & Final testing', color: '#F1C232', from: new Date(2013, 10, 21, 8, 0, 0), to: new Date(2013, 10, 22, 12, 0, 0), 'classes': 'gantt-task-deployment' }
                            ]
                        },
                        {
                            name: 'Workshop', tasks: [
                               { name: 'On-side education', color: '#F1C232', from: new Date(2013, 10, 24, 9, 0, 0), to: new Date(2013, 10, 25, 15, 0, 0) }
                            ]
                        },
                        {
                            name: 'Content', tasks: [
                               { name: 'Supervise content creation', color: '#F1C232', from: new Date(2013, 10, 26, 9, 0, 0), to: new Date(2013, 10, 29, 16, 0, 0) }
                            ]
                        },
                        {
                            name: 'Documentation', tasks: [
                               { name: 'Technical/User documentation', color: '#F1C232', from: new Date(2013, 10, 26, 8, 0, 0), to: new Date(2013, 10, 28, 18, 0, 0) }
                            ]
                        }
         ];
         
         */
     }

     function redrawGantt() {
         var ganttdataitems = [];

         var prevGroupName = '';
         var groupId = 1000;
         var group = { id: groupId, name: '', children: [] };

         var initDate = moment(new Date().toDateString());

         var itemsLeft = angular.copy(self.workflow.tasks);

         var currentGroups = [];

         //recurrente hasta que complete las tareas del gantt
         while (self.workflow.tasks.length != countGanttTasks(ganttdataitems)) {

             angular.forEach(itemsLeft, function (task, i) {

                 var currentGroup = null;
                 angular.forEach(ganttdataitems, function (gdi) {
                     if (gdi.name == task.groupName && gdi.children)
                         currentGroup = gdi;
                 });

                 //si no existia el grupo lo creo
                 if (currentGroup == null) {
                     currentGroup = { id: groupId, name: task.groupName, children: [] };
                     ganttdataitems.push(currentGroup);

                     currentGroups.push(currentGroup.name);
                     groupId++;
                 }

                 //agrego la tarea actual como hija del grupo
                 if (currentGroup.children.indexOf(task.name) == -1)
                     currentGroup.children.push(task.name);

                 //defino el color para el grupo
                 var color = currentGroups.indexOf(task.groupName);

                 //calculo las fechas de la tarea
                 var startDate = null;
                 var endDate = null;

                 if (task.previousIdentifier != null) {

                     angular.forEach(ganttdataitems, function (ganttitem, i) {
                         if (ganttitem.id == task.previousIdentifier && ganttitem.tasks.length > 0) {
                             var ganttitemTask = ganttitem.tasks[0];

                             startDate = moment(ganttitemTask.to).add(task.startFromDay, 'days');
                             endDate = moment(startDate).add(task.durationInDays, 'days');
                         }
                     });
                 }
                 else {
                     startDate = moment(initDate).add(task.startFromDay, 'days');
                     endDate = moment(startDate).add(task.durationInDays + task.startFromDay, 'days');
                 }


                 var ganttItem = {
                     id: task.identifier,
                     name: task.name,
                     content: '<a title="Click para editar Tarea" ng-click="scope.handleEditTask(row.model)">{{row.model.name}}</a> <i class="fa fa-remove" ng-click="scope.handleRemoveTask(row.model)"></i>',
                     tasks: [{ name: task.name, from: startDate, to: endDate, color: colors[color], progress: { percent: 0, color: '#3C8CF8' } }],
                 };

                 if (task.promotionTaskPreviousId != null) {
                     //TODO: esto no está funcando
                     ganttItem.tasks[0].dependencies = [{ from: task.promotionTaskPreviousId }];
                 }

                 if (task.isStartMilestone) {
                     self.startPromotionDate = startDate;
                 }

                 if (ganttItem.tasks[0].from != null && ganttItem.tasks[0].to != null) {
                     ganttdataitems.push(ganttItem);
                     itemsLeft.splice(itemsLeft.indexOf(task), 1);
                 }
             });
         }



         self.ganttdata = ganttdataitems;

         /*
         self.ganttdata = [
                        // Order is optional. If not specified it will be assigned automatically
                        {
                            name: 'Milestones', height: '3em', sortable: false, drawTask: false, classes: 'gantt-row-milestone', color: '#45607D', tasks: [
                               // Dates can be specified as string, timestamp or javascript date object. The data attribute can be used to attach a custom object
                               { name: 'Kickoff', color: '#93C47D', from: '2013-10-07T09:00:00', to: '2013-10-07T10:00:00', data: 'Can contain any custom data or object' },
                               { name: 'Concept approval', color: '#93C47D', from: new Date(2013, 9, 18, 18, 0, 0), to: new Date(2013, 9, 18, 18, 0, 0), est: new Date(2013, 9, 16, 7, 0, 0), lct: new Date(2013, 9, 19, 0, 0, 0) },
                               { name: 'Development finished', color: '#93C47D', from: new Date(2013, 10, 15, 18, 0, 0), to: new Date(2013, 10, 15, 18, 0, 0) },
                               { name: 'Shop is running', color: '#93C47D', from: new Date(2013, 10, 22, 12, 0, 0), to: new Date(2013, 10, 22, 12, 0, 0) },
                               { name: 'Go-live', color: '#93C47D', from: new Date(2013, 10, 29, 16, 0, 0), to: new Date(2013, 10, 29, 16, 0, 0) }
                            ], data: 'Can contain any custom data or object'
                        },
                        {
                            name: 'Status meetings', tasks: [
                               { name: 'Demo #1', color: '#9FC5F8', from: new Date(2013, 9, 25, 15, 0, 0), to: new Date(2013, 9, 25, 18, 30, 0) },
                               { name: 'Demo #2', color: '#9FC5F8', from: new Date(2013, 10, 1, 15, 0, 0), to: new Date(2013, 10, 1, 18, 0, 0) },
                               { name: 'Demo #3', color: '#9FC5F8', from: new Date(2013, 10, 8, 15, 0, 0), to: new Date(2013, 10, 8, 18, 0, 0) },
                               { name: 'Demo #4', color: '#9FC5F8', from: new Date(2013, 10, 15, 15, 0, 0), to: new Date(2013, 10, 15, 18, 0, 0) },
                               { name: 'Demo #5', color: '#9FC5F8', from: new Date(2013, 10, 24, 9, 0, 0), to: new Date(2013, 10, 24, 10, 0, 0) }
                            ]
                        },
                        {
                            name: 'Kickoff', movable: { allowResizing: false }, tasks: [
                               {
                                   name: 'Day 1', color: '#9FC5F8', from: new Date(2013, 9, 7, 9, 0, 0), to: new Date(2013, 9, 7, 17, 0, 0),
                                   progress: { percent: 100, color: '#3C8CF8' }, movable: false
                               },
                               {
                                   name: 'Day 2', color: '#9FC5F8', from: new Date(2013, 9, 8, 9, 0, 0), to: new Date(2013, 9, 8, 17, 0, 0),
                                   progress: { percent: 100, color: '#3C8CF8' }
                               },
                               {
                                   name: 'Day 3', color: '#9FC5F8', from: new Date(2013, 9, 9, 8, 30, 0), to: new Date(2013, 9, 9, 12, 0, 0),
                                   progress: { percent: 100, color: '#3C8CF8' }
                               }
                            ]
                        },
                        {
                            name: 'Create concept', tasks: [
                               {
                                   name: 'Create concept', priority: 20, content: '<i class="fa fa-cog" ng-click="scope.handleTaskIconClick(task.model)"></i> {{task.model.name}}', color: '#F1C232', from: new Date(2013, 9, 10, 8, 0, 0), to: new Date(2013, 9, 16, 18, 0, 0), est: new Date(2013, 9, 8, 8, 0, 0), lct: new Date(2013, 9, 18, 20, 0, 0),
                                   progress: 100
                               }
                            ]
                        },
                        {
                            name: 'Finalize concept', tasks: [
                               {
                                   id: 'Finalize concept', name: 'Finalize concept', priority: 10, color: '#F1C232', from: new Date(2013, 9, 17, 8, 0, 0), to: new Date(2013, 9, 18, 18, 0, 0),
                                   progress: 100
                               }
                            ]
                        },
                        { name: 'Development', children: ['Sprint 1', 'Sprint 2', 'Sprint 3', 'Sprint 4'], content: '<i class="fa fa-file-code-o" ng-click="scope.handleRowIconClick(row.model)"></i> {{row.model.name}}' },
                        {
                            name: 'Sprint 1', tooltips: false, tasks: [
                               {
                                   id: 'Product list view', name: 'Product list view', color: '#F1C232', from: new Date(2013, 9, 21, 8, 0, 0), to: new Date(2013, 9, 25, 15, 0, 0),
                                   progress: 25, dependencies: [{ to: 'Order basket' }, { from: 'Finalize concept' }]
                               }
                            ]
                        },
                        {
                            name: 'Sprint 2', tasks: [
                               {
                                   id: 'Order basket', name: 'Order basket', color: '#F1C232', from: new Date(2013, 9, 28, 8, 0, 0), to: new Date(2013, 10, 1, 15, 0, 0),
                                   dependencies: { to: 'Checkout' }
                               }
                            ]
                        },
                        {
                            name: 'Sprint 3', tasks: [
                               {
                                   id: 'Checkout', name: 'Checkout', color: '#F1C232', from: new Date(2013, 10, 4, 8, 0, 0), to: new Date(2013, 10, 8, 15, 0, 0),
                                   dependencies: { to: 'Login & Signup & Admin Views' }
                               }
                            ]
                        },
                        {
                            name: 'Sprint 4', tasks: [
                               {
                                   id: 'Login & Signup & Admin Views', name: 'Login & Signup & Admin Views', color: '#F1C232', from: new Date(2013, 10, 11, 8, 0, 0), to: new Date(2013, 10, 15, 15, 0, 0),
                                   dependencies: [{ to: 'HW' }, { to: 'SW / DNS/ Backups' }]
                               }
                            ]
                        },
                        { name: 'Hosting' },
                        {
                            name: 'Setup', tasks: [
                               { id: 'HW', name: 'HW', color: '#F1C232', from: new Date(2013, 10, 18, 8, 0, 0), to: new Date(2013, 10, 18, 12, 0, 0) }
                            ]
                        },
                        {
                            name: 'Config', tasks: [
                               { id: 'SW / DNS/ Backups', name: 'SW / DNS/ Backups', color: '#F1C232', from: new Date(2013, 10, 18, 12, 0, 0), to: new Date(2013, 10, 21, 18, 0, 0) }
                            ]
                        },
                        { name: 'Server', parent: 'Hosting', children: ['Setup', 'Config'] },
                        {
                            name: 'Deployment', parent: 'Hosting', tasks: [
                               { name: 'Depl. & Final testing', color: '#F1C232', from: new Date(2013, 10, 21, 8, 0, 0), to: new Date(2013, 10, 22, 12, 0, 0), 'classes': 'gantt-task-deployment' }
                            ]
                        },
                        {
                            name: 'Workshop', tasks: [
                               { name: 'On-side education', color: '#F1C232', from: new Date(2013, 10, 24, 9, 0, 0), to: new Date(2013, 10, 25, 15, 0, 0) }
                            ]
                        },
                        {
                            name: 'Content', tasks: [
                               { name: 'Supervise content creation', color: '#F1C232', from: new Date(2013, 10, 26, 9, 0, 0), to: new Date(2013, 10, 29, 16, 0, 0) }
                            ]
                        },
                        {
                            name: 'Documentation', tasks: [
                               { name: 'Technical/User documentation', color: '#F1C232', from: new Date(2013, 10, 26, 8, 0, 0), to: new Date(2013, 10, 28, 18, 0, 0) }
                            ]
                        }
         ];
         
         */
     }

     function countGanttTasks(ganttdataitems) {
         var count = 0;
         angular.forEach(ganttdataitems, function (ganttitem, i) {
             //cuenta solo tasks sin tomar en cuenta los grupos
             if (ganttitem.tasks && !ganttitem.children) {
                 count++;
             }
         });

         return count;
     }

     //Esta funcion revisa si ya esta cargada la tarea HITO, sino no deja guardar el proceso.
     self.hasStartMilestoneTask = function () {
         var hasStartMilestoneTask = false;

         if (self.workflow && self.workflow.tasks) {
             angular.forEach(self.workflow.tasks, function (task) {
                 if (task.isStartMilestone)
                     hasStartMilestoneTask = true;
             });
         }

         return hasStartMilestoneTask;
     }

     self.addTask = function () {
         if (!self.workflowtask.added) {

             var index = 1;
             angular.forEach(self.workflow.tasks, function (task) { if (task.promotionTaskId == self.workflowtask.promotionTaskId) index++; });
             if (index > 1) {
                 self.workflowtask.name += ian.format("({0})", index);
             }
             //setea un task.identifier para diferenciar tareas que procedan de un mismo promotionTaskId
             self.workflowtask.identifier = self.workflowtask.name; //ian.format("{0}-{1}", self.workflowtask.promotionTaskId, index);


             self.workflowtask.added = true;
             self.workflow.tasks.push(self.workflowtask);
         }

         self.hasStartMilestoneTask();

         redrawGantt();

         //Cierro el Dialog
         ngDialog.close();
     }

     self.editTask = function (workflowtask) {

         //Limpio las tareas previas porque las vuelvo a generar.
         self.previousTasks = [];

         if (workflowtask == null) {
             self.workflowtask = {
                 durationInDays: 0,
                 userArea: '',
                 startFromDay: 0,
                 id: 0,
                 promotionWorkflowId: 0,
                 promotionTaskId: 0,
                 name: '',
                 groupName: '',
                 promotionTaskPreviousId: null,
                 isRequired: false,
                 added: false,
                 identifier: null,
                 previousIdentifier: null
             }
         }
         else {
             self.workflowtask = workflowtask;
         }

         var childTasks = [];

         angular.forEach(self.workflow.tasks, function (task) {

             //Agrego a previousTasks este tipo de tarea
             if (self.workflowtask.identifier != task.identifier) {
                 self.previousTasks.push({ id: task.promotionTaskId, name: task.name, identifier: task.identifier });
             }

             //Agrego a la coleccion de childtasks las tareas cuyo padre es la tarea que estoy editando para quitarlas luego del combo de previous tasks (una tarea no puede ser padre e hija de una misma tarea)
             if (task.previousIdentifier != null && self.workflowtask.identifier != null && self.workflowtask.identifier == task.previousIdentifier) {
                 childTasks.push(task);
             }
         });

         //Quito las childtasks de las previoustasks
         angular.forEach(childTasks, function (childTask) {
             angular.forEach(self.previousTasks, function (previousTask, i) {
                 if (childTask.identifier == previousTask.identifier)
                     self.previousTasks.splice(i, 1);
             });
         });

         ngDialog.open({
             template: 'editTaskDialog',
             className: 'ngdialog-theme-default ngdialog-theme-custom',
             //data: task,
             scope: $scope
         });
     }

     self.save = function () {

         if (self.workflow.promotionMediaType != null && self.workflow.promotionType != null) {

             if (self.workflow != null) {
                 swal({
                     title: 'Esta seguro que desea guardar el Proceso Promocional?',
                     type: "warning",
                     showCancelButton: true,
                     confirmButtonColor: "#1AB394",
                     confirmButtonText: "Continuar",
                     cancelButtonText: "Cancelar",
                     showLoaderOnConfirm: true,
                     closeOnConfirm: false,
                     closeOnCancel: true
                 },
                function (isConfirm) {
                    if (isConfirm) {
                        promotionsService.workflows.saveWorkflow(self.workflow)
                            .then(function (workflow) {
                                swal('Proceso promocional Guardado!', 'El proceso promocional ' + self.workflow.name + ' se ha guardado exitosamente', 'success');
                                self.workflow = workflow;
                                console.log(self.workflow.id);
                            }, function (status) {
                                if (status == 502) {
                                    swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                                }
                                else {
                                    swal("Error", "Hubo un error al guardar este proceso promocional", "error");
                                }
                            });
                    }

                });
             }
         }
         else
             swal("Error", "Debe completar el tipo de medio y tipo de promocion.", "error");

     }

     function init() {
         loadWorkflow();
         loadTasks();
         isWorkflowInUse();
     }


     init();

 });