var _controller = function ($scope, $attrs, $filter, $timeout, $rootScope, $translate, priceService, adminService) {
    var self = this;

    self.dateTo = new Date();
    self.dateFrom = new Date(); 
    self.dateFrom.setYear(2019);


    self.items = [];

    self.updateChart = function () {
        c3.generate({
            bindto: "#priceEvolutionChart_" + self.priceId,

            padding: {
                top: 30,
                right: 50,
                bottom: 60,
                left: 50,
            },

            data: {
                x: 'x',
                xFormat: '%d-%m', // parse format
                "columns": self.data,
                empty: { label: { text: "Sin datos" } },
                colors: {
                    'YPF': '#0070b8',
                    'AXION': '#bb166f',
                    'SHELL': '#ed1b23',
                    'OIL': '#ffd800',
                    'BLANCA': '#cccccc',
                    'REFINOR': '#66bb69',
                    'PETROBRAS': '#008b5a'
                }
            },
            axis: {
                x: {
                    type: 'category',
                    tick: {
                        rotate: 0,
                        format: '%d-%m'
                    }
                },
                y: {
                    label: translations.PriceInS,
                    tick: {
                        format: d3.format(".2f")
                    }
                }
            },
            tooltip: {
                format: {
                    value: function (value, ratio, id) {
                        var newValue = value;
                        return newValue;
                    }
                }
            },
            line: {
                connectNull: true
            }
        });
    }

    self.isLoadingChart = false;

    function getPricesEvolution(itemId, priceListId, dateFrom, dateTo) {
        self.isLoadingChart = true;

            priceService.evolutionReport.getOxxoData(itemId, priceListId, dateFrom.toISOString(), dateTo.toISOString()).then(function (items) {
            self.isLoadingChart = false;
            if (items == null || items == '') {
                self.data = items;
                self.updateChart();
                return;
            }

            var valueToPush = new Array();
            var uniqueArray = items.reduce(function (a, d) {
                if (a.indexOf(d.observedDateString) === -1) {
                    a.push("" + d.observedDateString + "");
                }
                return a;
            }, ['x']);

            var competitorsNamesObj = [];
            var competitorsNames = items.reduce(function (a, d) {

                var p = (competitorsNamesObj.find ? competitorsNamesObj.find(cd => {
                    return cd.observedDateString == d.observedDateString && cd.tradeName == d.tradeName;
                }) : undefined);
                if (!p) {
                    competitorsNamesObj.push({ 'observedDateString': d.observedDateString, "tradeName": d.tradeName, 'price': d.price });
                }
                if (a.indexOf(d.tradeName) === -1) {
                    a.push(d.tradeName);
                }

                return a;
            }, []);

            var i = 1;
            var keepPrice = { competitorName: '', price: 0 };
            // var firstPrice = { competitorName: '', price: 0, count: 0, flag: false };
            var firstPrices = [];

            competitorsNames.forEach(function (c) {
                competitors = [];
                competitors.push(c);

                for (k = 1; k < uniqueArray.length; k++) {
                    var p2 = competitorsNamesObj.find(cd => {
                        return cd.observedDateString == uniqueArray[k] && cd.tradeName == c;
                    })
                    if (p2) {
                        competitors.push(p2.price);
                        keepPrice = { competitorName: c, price: p2.price };

                        var index = firstPrices.findIndex(fp => fp.competitorName == c && !fp.flag);

                        if (index != -1) {
                            var count = firstPrices[index].count;
                            firstPrices.splice(index, 1, { competitorName: c, price: p2.price, count: count, flag: true });
                        }

                    }
                    else { //mantengo precio anterior 
                        if (keepPrice.competitorName == c)
                            competitors.push(keepPrice.price);
                        else {
                            var foundNullPrice = false;
                            for (j = 0; j < firstPrices.length; j++) {
                                if (firstPrices[j].competitorName == c) {
                                    //sirve para hacer el fill despues
                                    firstPrices[j].count++;
                                    foundNullPrice = true;
                                }
                            }
                            if (!foundNullPrice) {
                                firstPrices.push({ competitorName: c, count: 1 });
                            }
                            competitors.push(null);
                        }

                    }
                }
                //fill nulls
                var fillBlankValues = firstPrices.find(cp => { return cp.competitorName == c });
                if (fillBlankValues)
                    competitors.fill(fillBlankValues.price, 1, fillBlankValues.count + 1);

                valueToPush[i] = competitors;
                i++;
            });

            valueToPush[0] = uniqueArray;
            self.data = valueToPush;

            self.updateChart();

        });
    }

    function init() {

        $translate(["GeneralInformation", "DateFrom", "DateFromTooltip", "StoreCode", "Store", "ChangesSent", "ChangesSentTooltip"
            , "NAtooltip", "Atooltip", "Limit", "LimitTooltip", "Detour", "PriceTypes", "Regular", "Competitive", "Promotion", "Discount"
            , "Action", "Decrease", "Increase", "Pr_volumeMeasurement_validationDate"])
            .then(function (all) {
                translations = all;

                getPricesEvolution(self.itemId, self.priceListId, self.dateFrom, self.dateTo);

            });
    }

    init();

}

angular.module('prisma').component('priceEvolutionReport', {
    templateUrl: 'app/shared_logic/components/priceEvolutionReport/priceEvolutionReport.component.html',
    bindings: {
        priceId: '<',
        itemId: '<',
        priceListId: '<'
    },

    controller: _controller
});


