"use strict";

angular.module("prisma")
    .service("homeService", function homeService($rootScope, httpService) {
        var self = this;

        self.dashboards = {
            getItemDashboard: function (itemId, storeId, clusterId, priceListId) {
                return httpService.get('/home/itemDashboard/' + itemId + '/' + storeId + '/' + clusterId + '/' + priceListId);
            },
            getHomeDashboard: function (sameStores) {
                return httpService.get('/home/homeDashboard/'+ sameStores);
            },
            getHeatMapByState: function (timeType,categoryId,sameStores) {
                return httpService.get('/home/heatMapByState/' + timeType + '/' + categoryId + '/' + sameStores);
            },
            getHeatMapByStateMargin: function (categoryId) {
                return httpService.get('/home/heatMapByStateMargin/' + categoryId);
            },
            getYtdComparison: function (sameStores) {
                return httpService.get('/home/dashboard-ytd-comparison/' + sameStores);
            },
            getInventory: function (itemId, storeId, clusterId, priceListId) {
                return httpService.get('/home/itemInventory/' + itemId + '/' + storeId + '/' + clusterId + '/' + priceListId);
            },
            getCountry: function () {
                return httpService.get('home/getCountry');
            },
            getAllDataDate: function (lang) {
                return httpService.get('home/getAllDataDate/' + lang);
            },
            getNotification: function (sameStores) {
                return httpService.get('home/getNotification/' + sameStores);
            },
            getLogosAndDomain: function () {
                return httpService.get('home/getLogosAndDomain');
            }
        }

        self.company = {
            getCompanyConfig: function () {
                return httpService.get('home/getCompanyConfig');
            }
        }

        self.categoryTreeMap = {
            getCategoryTreeMap: function (categoryId) {
                return httpService.get('/categoryTreeMap/' + categoryId);
            }
        };

        self.dst = {
            getCategoryBubbles: function (categoryGroupId) {
                return httpService.get('home/dst-bubbles/' + categoryGroupId);
            },
            getStates: function () {
                return httpService.get('home/get-states');
            },
            getBubbles: function (filter, timeType, type,sameStores) {
                return httpService.get('home/dst-bubbles/' + filter + '/' + timeType + '/' + type + '/' + sameStores);
            },
        };

        self.categories = {
            getCategoryGroups: function () {
                return httpService.get('home/categories/categoryGroups');
            },
            getLayoutCategories: function () {
                return httpService.get('home/categories/layoutCategories');
            },
            getFullCategories: function () {
                return httpService.get('home/categories');
            }
        };

        self.categoryBCGMatrix = {
            getCategoryBCGMatrix: function () {
                return httpService.get('/categoryBCGMatrix');
            }
        };

        self.benchmark = {
            getBenchmark: function (parameters) {
                return httpService.post('home/benchmark', parameters);
            },
            getBenchmarkValues: function () {
                return httpService.get('home/benchmark-values');
            }
        };

        self.goals = {
            getGoalsByCategory: function (sameStores) {
                return httpService.get('/home/goals-by-category/' + sameStores);
            },
            getGoalsByStore: function (sameStores) {
                return httpService.get('/home/goals-by-store/' + sameStores);
            },
            getPriceListsByCategory: function (categoryId) {
                return httpService.get('/home/price-lists-by-category/' + categoryId);
            }
        };

        self.scorecard = {
            getScorecard: function (brandId, year) {
                return httpService.get('/home/scorecard/' + brandId + '/' + year);
            },
            getBrands: function () {
                return httpService.get('/home/scorecard/brands');
            },
        }

        self.lookups = {
            itemsLookup: function (lookupParameter) {
                return httpService.post('/home/lookups/itemsLookup', lookupParameter);
            },
            itemsLookupByCategory: function (lookupParameter, categoryId) {
                return httpService.post('/home/lookups/itemsLookup/' + categoryId, lookupParameter);
            },
            abmItemsLookup: function (search) {
                return httpService.post('/home/lookups/searchItem', search);
            },
            storesLookup: function (search) {
                return httpService.post('/home/lookups/searchStore', search);
            }
        }

        self.items = {
            getItem: function (itemId) {
                return httpService.get('/home/items/getAbmItem/' + itemId);
            },
            saveItem: function (item) {
                return httpService.post('/home/items/saveAbmItem', item);
            },
            getCategories: function () {
                return httpService.get('/home/items/getCategories');
            },
            getSubcategories: function (categoryId) {
                return httpService.get('/home/items/getSubCategories/' + categoryId);
            },
            getTypes: function (itemId) {
                return httpService.get('/home/items/getTypes');
            },
            getCums: function (itemId) {
                return httpService.get('/home/items/getCums/' + itemId);
            },
            isItemEanRepeat: function (ENA) {
                return httpService.get('home/items/isItemEanRepeat/' + ENA )
            }
        }

        self.kpis = {
            getKpiDashboardYTD: function (itemId, priceListId, sameStores, type) {
                return httpService.get('/home/kpi/kpi-ytd-comparison/' + itemId + '/' + priceListId + '/' + sameStores + '/' + type);
            },
            getItemDashboardItemCluster: function(categoryId, itemId, clusterId, sameStores) {

                return httpService.get('/home/kpi/cluster-dashboard/' + $rootScope.companyId + '/' + categoryId + '/' + itemId + '/' + clusterId + '/' + sameStores);
            },
        }
    });



