angular.module("prisma")
    .controller("PricelistsCtrl", function ($scope, $state, priceService, adminService, $timeout, $translate) {

     //Variable globales

     var self = this;
     self.companyCode = companyJson.Erp.Implementation;
     self.order = self.companyCode == 'LaRebaja' ? 'departament' : 'name';
     self.pricelists = [];

     function loadPriceLists() {
         priceService.pricelists.getAllPricelists()
        .then(function (pricelists) {
            self.pricelists = pricelists;
        });
         adminService.priceLists.getStorePriceLists()
      .then(function (storePricelists) {
          self.storePriceLists = storePricelists;
      });
     }

     //Funciones

     function getStorePriceListNames(priceListId) {
         let storeNames = [];
         self.storePriceLists.forEach(function (spl) {
             if (spl.priceListId == priceListId)
                 storeNames.push(spl.storeName);
         });
         return storeNames;
     }

     self.disablePriceList = function (id, excluded) {
         var excludedText = excluded ? translations.EnableSwal : translations.ToDisableSwal;
         var storeNames = getStorePriceListNames(id);
         swal({
             title: translations.AreYouSureSwalTitle + excludedText + translations.TheZoneSwal + "?",
             text: storeNames.length > 0 ? translations.WarningStoresSwal + storeNames.join(', ') + translations.BelongZoneSwall : "",
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: translations.ContinueSwal,
             cancelButtonText: translations.CancelSwal,
             showLoaderOnConfirm: true,
             closeOnConfirm: false,
             closeOnCancel: true
         },
       function (isConfirm) {

           if (isConfirm) {

               priceService.pricelists.deletePricelist(id).then(function () {
                   swal("", translations.UpdatedZonesSwal, "success");
                   loadPriceLists();
               }, function (status) {
                   if (status == 502) {
                       swal(translations.TheProcessContinuesSwal);
                   }
                   else {
                       swal(translations.ErrorTitleSwal, translations.ErrorWhenDisablingTheZoneSwal, "error");
                   }
               });
           }
       });
     }

     self.editPricelist = function (id) {
         $state.go('prices.editPricelist', { priceListId: id });
     }

     self.addNew = function () {
         $state.go('prices.editPricelist', { priceListId: 0 });
     }

     var translations = null;

     function init() {

         $translate(["AreYouSureSwalTitle", "EnableSwal", "ToDisableSwal", "TheZoneSwal", "WarningStoresSwal", "BelongZoneSwall", "CancelSwal", "ContinueSwal", "UpdatedZonesSwal", "TheProcessContinuesSwal", "ErrorTitleSwal", "ErrorWhenDisablingTheZoneSwal"])
             .then(function (all) {
                 translations = all;
             });

         loadPriceLists();
     }

     init();

 });




