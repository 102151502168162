'use strict';
angular.module("prisma")
    .factory('authInterceptorService', function ($q, $injector, $location, localStorageService) {

        var authInterceptorServiceFactory = {};

        var _request = function (config) {


            config.timeout = 600000;

            config.headers = config.headers || {};

            var authData = localStorageService.get('authorizationData');
            if (authData) {
                config.headers.Authorization = 'Bearer ' + authData.token;
            }

            return config;
        }

        var _responseError = function (rejection) {

            if (rejection.status === 401) {

                var authService = $injector.get('authService');

                if (rejection.data && rejection.data.sessionLimit) {
                    //Si es x limite de sesiones le ofreco retomar
                    authService.prompSessionLimit();
                }
                else if (rejection.data && rejection.data.wasKicked) {
                    authService.logOut();
                    $location.path('/login');
                }
                else {
                    //UNAUTHENTICATED Session Expired (go to URL)
                    authService.promptSessionExpired();
                }

            }
            else if (rejection.status === 403) {
                console.warn("No tienes los suficientes permisos para realizar esta acción");
            }

            return $q.reject(rejection);
        }

        authInterceptorServiceFactory.request = _request;
        authInterceptorServiceFactory.responseError = _responseError;


        return authInterceptorServiceFactory;
    });