
angular.module("prisma")
    .controller("PriceChangesReportCtrl", function ($state, $rootScope, priceService, $translate) {
      var self = this;

      var numberColWidth = 100;

      var columnDefs = null;

      var toDate = new Date();
      toDate.setTime(toDate.getTime() + 86400000); //A partir de ma�ana
      self.toDate = toDate;

      var fromDate = new Date();
      fromDate.setTime(fromDate.getTime() - 3 * 86400000); //Ultimos 3 dias
      self.fromDate = fromDate;
      
      function setColumnDefinitions() {
          columnDefs = [
              {
                  headerName: translations.GeneralInformation,
                  children: [
                  {
                          headerName: translations.DateFrom,
                      field: "newValidFrom",
                      headerTooltip: translations.DateFromTooltip,
                      width: numberColWidth + 10,
                      filter: 'date',
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.dateRenderer
                  },
                  {
                      headerName: translations.StoreCode,
                      field: "storeCode",
                      width: numberColWidth,
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: translations.Store,
                      field: "storeName",
                      width: numberColWidth + 100,
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: translations.ChangesSent,
                      field: "totalChanges",
                      headerTooltip: translations.ChangesSentTooltip,
                      width: numberColWidth + 40,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "NA",
                      field: "totalNotApplicableChanges",
                      headerTooltip: translations.NAtooltip,
                      width: 60,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "A",
                      field: "totalApplicableChanges",
                      headerTooltip: translations.Atooltip,
                      width: 60,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: function (params) {
                          var value = params.value;

                          return "<b>" + value + "</b>";
                      }
                  },
                  {
                      headerName: translations.Limit,
                      field: "storeLimit",
                      headerTooltip: translations.LimitTooltip,
                      width: 60,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: function (params) {
                          var value = params.value;
                          if (value < 0)
                              return ' <span class="text-danger">'  + value + ' </span>' ;
                          else
                              return value;
                      }
      },
      {
          headerName: translations.Detour,
          field: "gapWithLimitPercentage",
          width: numberColWidth,
          filter: 'number',
          cellClass: function (params) {
              var value = params.data.gapWithLimit;
              if(value < 0 )
                  return "text-danger";
              else if(value <= 50)
                  return "text-warning";
              else
                  return "colorGreen";
          },
          cellRenderer: $rootScope.percentageRendererNoDecimals
      }
  ]
              },
              {
                  headerName: translations.PriceTypes,
                  children: [
                {
                          headerName: translations.Regular,
                    field: "totalApplicableChangesForRegular",
                    width: numberColWidth,
                    filter: 'number',
                    cellClass: $rootScope.getClassForCell
                },
                 {
                     headerName: translations.Competitive,
                     field: "totalApplicableChangesForCompetition",
                     width: numberColWidth + 10,
                     filter: 'number',
                     cellClass: $rootScope.getClassForCell
                 },
                 {
                     headerName: translations.Promotion,
                     field: "totalApplicableChangesForPromotion",
                     width: numberColWidth,
                     filter: 'number',
                     cellClass: $rootScope.getClassForCell
                 },
                  {
                      headerName: translations.Discount,
                      field: "totalApplicableChangesForMarkdown",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  }]
              },
              {
                  headerName: translations.Action,
                  children: [
                  {
                          headerName: translations.Decrease,
                      field: "decreases",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: translations.Increase,
                      field: "increases",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  }]
              }
];
}

self.gridOptions = {
    columnDefs: columnDefs,
    enableColResize: true,
    enableSorting: true,
    enableFilter: false,
    groupHeaders: false,
    suppressCellSelection: true,
    rowHeight: 35,
    groupHideGroupColumns: true,
    icons: {
        columnRemoveFromGroup: '<i class="fa fa-remove"/>',
        filter: '<i class="fa fa-filter"/>',
        sortAscending: '<i class="fa fa-long-arrow-down"/>',
        sortDescending: '<i class="fa fa-long-arrow-up"/>',
        groupExpanded: '<i class="fa fa-minus-square-o"/>',
        groupContracted: '<i class="fa fa-plus-square-o"/>',
        columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
        columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
    },
    angularCompileRows: true
}

self.exportToExcel = function () {
    var params = {
        skipHeader: false,
        skipFooters: false,
        skipGroups: false,
        allColumns: true,
        onlySelected: false,
        suppressQuotes: false,
        fileName: 'PriceChangesReport.xls',
        columnSeparator: ','
    };

    self.gridOptions.api.exportDataAsCsv(params);
}

function setGridOptions() {

    self.gridOptions = {
        columnDefs: columnDefs,
        enableColResize: true,
        enableSorting: true,
        enableFilter: false,
        groupHeaders: false,
        suppressCellSelection: true,
        rowHeight: 35,
        groupHideGroupColumns: true,
        icons: {
            columnRemoveFromGroup: '<i class="fa fa-remove"/>',
            filter: '<i class="fa fa-filter"/>',
            sortAscending: '<i class="fa fa-long-arrow-down"/>',
            sortDescending: '<i class="fa fa-long-arrow-up"/>',
            groupExpanded: '<i class="fa fa-minus-square-o"/>',
            groupContracted: '<i class="fa fa-plus-square-o"/>',
            columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
            columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
        }
    }
}

self.loadData = function () {

    var fromDate = convertDate(self.fromDate);
    var toDate = convertDate(self.toDate);

    priceService.priceOutput.getPriceOutputLimits(true, fromDate, toDate).then(function (rows) {
        self.gridOptions.api.setRowData(rows);
    });
}

function convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat);
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
}

function init() {
    //init columnDefs and gridOptions
    setGridOptions();

    $translate(["GeneralInformation", "DateFrom", "DateFromTooltip", "StoreCode", "Store", "ChangesSent", "ChangesSentTooltip"
        , "NAtooltip", "Atooltip", "Limit", "LimitTooltip", "Detour", "PriceTypes", "Regular", "Competitive", "Promotion", "Discount"
        , "Action", "Decrease", "Increase"])
        .then(function (all) {
            translations = all;

            setColumnDefinitions();

            self.gridOptions.api.setColumnDefs(columnDefs);
        });

    //load data
    self.loadData();
}

init();

});

