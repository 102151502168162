"use strict";

angular.module("prisma")
    .service("promotionsService", function promotionsService($rootScope, httpService, serverService, $http) {
        var self = this;

        self.stores = {
            getStores: function () {
                return httpService.get('/promotion/promotions/stores');
            },
            getStoreGroups: function () {
                return httpService.get('/promotion/promotions/storeGroups');
            },
            saveStoreGroup: function (storeGroup) {
                return httpService.post('/promotion/promotions/saveStoreGroup', storeGroup);
            },
            deleteGroup: function (storeGroupId) {
                return httpService.post('/promotion/promotions/deleteStoreGroup', storeGroupId);
            },
            getStore: function (storeId) {
                return httpService.get('/promotion/promotions/store/' + storeId);
            },
            getUserStores: function () {
                return httpService.get('/promotion/promotions/userStores');
            },
            getBrands: function () {
                return httpService.get('/promotion/promotions/brands');
            }
        }

        self.holidays = {
            getHolidays: function () {
                return httpService.get('/promotion/promotions/holidays');
            }
        }

        self.promotions = {
            getPromotions: function (history) {
                return httpService.get('/promotion/promotions/promotions/' + history);
            },
            getPromotionMediaPagesIds: function () {
                return httpService.get('/promotion/promotions/getPromotionMediaPagesIds/');
            },
            getPromotionsByAdvancedSearch: function (searchCriterions) {
                return httpService.post('/promotion/promotions/promotionsByAdvancedSearch', searchCriterions);
            },
            getPromotionsByReportFilters: function (filters) {
                return httpService.post('/promotion/promotions/promotionsByReportFilters', filters);
            },
            getPromotionsOverlapping: function () {
                return httpService.get('/promotion/promotions/promotionsOverlapping');
            },
            getRejectedPromotions: function () {
                return httpService.get('/promotion/promotions/rejectedPromotions');
            },
            getHistoricPromotions: function (dateRange) {
                return httpService.post('/promotion/promotions/historicPromotions', dateRange);
            },
            buildModifiedPromotions: function (dateRange, promotionId) {
                return httpService.post('/promotion/promotions/buildModifiedPromotions/' + promotionId, dateRange);
            },
            getPromotionsCalendar: function () {
                return httpService.get('/promotion/promotions/promotionsCalendar');
            },
            getPromotion: function (promotionId) {
                return httpService.get('/promotion/promotions/promotion/' + promotionId);
            },
            getPromotionForPricingClose: function (promotionId, categoryId, pageId) {
                return httpService.get('/promotion/promotions/promotionsPricingClose/' + promotionId + '/' + categoryId + '/' + pageId);
            },
            getPromotionDataForPricingClose: function (promotionId) {
                return httpService.get('/promotion/promotions/promotionDataPricingClose/' + promotionId);
            },
            savePricingClose: function (closePrices) {
                return httpService.post('/promotion/promotions/savePricingClose', closePrices);
            },
            deletePromotion: function (promotionId) {
                return httpService.post('/promotion/promotions/delete', promotionId);
            },
            rejectPromotion: function (promotionId) {
                return httpService.post('/promotion/promotions/reject', promotionId);
            },
            savePromotion: function (promotion) {
                return httpService.post('/promotion/promotions/save', promotion);
            },
            copyPromotion: function (promotion) {
                return httpService.post('/promotion/promotions/copy', promotion);
            },
            getPromotionPages: function (promotionId) {
                return httpService.get('/promotion/promotions/promotionPages/' + promotionId);
            },
            getPromotionPagesItems: function (promotionId) {
                return httpService.get('/promotion/promotions/promotionPagesItems/' + promotionId);
            },
            savePromotionMediaPages: function (promotion) {
                return httpService.post('/promotion/promotions/promotionMediaPages/save', promotion);
            },
            getPromotionMediaPage: function (promotionMediaPageId) {
                return httpService.get('/promotion/promotions/promotionPage/' + promotionMediaPageId);
            },
            getPromotionData: function (promotionId, promotionMediaPageId, position) {
                return httpService.get('/promotion/promotions/getPromotionData/' + promotionId + '/' + promotionMediaPageId + '/' + position);
            },
            informPromotion: function (promotionId, taskId) {
                return httpService.get('/promotion/promotions/informPromotion/' + promotionId + '/' + taskId);
            },
            comuicatePromotion: function (promotionId, taskId) {
                return httpService.get('/promotion/promotions/comunicatePromotion/' + promotionId + '/' + taskId);
            },
            getPromotionPageData: function (promotionId, promotionPageNumber) {
                return httpService.get('/promotion/promotions/getPromotionPageData/' + promotionId + '/' + promotionPageNumber);
            }
        }

        self.internalPromotions = {
            saveInternalPromotion: function (promotion) {
                return httpService.post('/promotion/promotions/saveInternal', promotion);
            },
            getInternalPromotion: function (promotionId) {
                return httpService.get('/promotion/promotions/getInternalPromotion/' + promotionId);
            },
            getRejectedPromotion: function (promotionId) {
                return httpService.get('/promotion/promotions/getRejectedPromotion/' + promotionId);
            },
            getPromotionItems: function (promotionMediaPageId) {
                return httpService.get('/promotion/promotions/promotionItems/' + promotionMediaPageId);
            },
            takeInternalPromotion: function (promotionId) {
                return httpService.get('/promotion/promotions/takeInternalPromotion/' + promotionId);
            },
            commentInternalPromotion: function (chat) {
                return httpService.post('/promotion/promotions/commentInternalPromotion', chat);
            },
            informNewInternalPromotion: function (promotionId) {
                return httpService.get('/promotion/promotions/informNewInternalPromotion/' + promotionId);
            },
            publishInternalPromotion: function (promotionId) {
                return httpService.get('/promotion/promotions/publishInternalPromotion/' + promotionId);
            },
            getOverlaps: function (promotionId) {
                return httpService.get('/promotion/promotions/getPromotionOverlaps/' + promotionId);
            },
            getPromotionMediaPageId: function (promotionId) {
                return httpService.get('/promotion/promotions/getPromotionMediaPageId/' + promotionId);
            }
        }

        self.rules = {

            getRuleParams: function () {
                return httpService.get('/promotion/promotions/ruleParams');
            },
            getRules: function () {
                return httpService.get('/promotion/promotions/rules');
            },
            getRulesFiltered: function (filters) {
                return httpService.post('/promotion/promotions/rules/filtered', filters);
            },
            getRule: function (ruleId) {
                return httpService.get('/promotion/promotions/rule/' + ruleId);
            },
            getRuleByCategory: function (categoryId) {
                return httpService.get('/promotion/promotions/rule/byCategory/' + categoryId);
            },
            getRulesByCategory: function (categoryId) {
                return httpService.get('/promotion/promotions/rules/byCategory/' + categoryId);
            },
            getExistingRules: function (rule) {
                return httpService.post('/promotion/promotions/rules/existingRules', rule);
            },
            saveRule: function (rule) {
                return httpService.post('/promotion/promotions/saveRule', rule);
            },
            deleteRule: function (ruleId) {
                return httpService.post('/promotion/promotions/deleteRule/' + ruleId);
            },
            extendRules: function (rules) {
                angular.forEach(rules, function (rule) {

                    rule.getCompareToDescription = function () {
                        //internas
                        if (rule.comparesTo == 'NetworkMinimal') return 'Minimo Cadena';
                        if (rule.comparesTo == 'Normal') return 'Normal ZB';
                        if (rule.comparesTo == 'Active') return 'Vigente ZB';
                        if (rule.comparesTo == 'NormalAverage') return 'Promedio Normal';
                        if (rule.comparesTo == 'ActiveAverage') return 'Promedio Vigente';

                        //externas
                        if (rule.comparesTo == 'CompetitionLowest') return 'Competencia mas baja';
                        if (rule.comparesTo == 'CompetitionAverage') return 'Competencia Promedio'; 
                        if (rule.comparesTo == 'SpecificCompetitor') return 'Competidor Especifico';
                        if (rule.comparesTo == 'CompetitorGroup') return 'Grupo de Competidores';
                        if (rule.comparesTo == 'CompetitionMode') return 'Moda';
                    }

                    rule.toString = function (htmlVersion) {
                        var d = null;
                        if (rule) {
                            var mediaTypes = rule.mediaTypes.join(',');
                            var pageTypes = rule.pageTypes.join(',');
                            var itemTypes = rule.itemTypes.join(',');

                            var text = 'Cuando un producto de {0} participe de {1} en {2} como {3}  su precio promocional debe ser {4} {5} que {6}';
                            var htmlText = 'Cuando un producto de <b>{0}</b> participe de <b>{1}</b> en <b>{2}</b> como <b>{3}</b> su precio promocional debe ser <b>{4}</b> <b>{5}</b> que <b>{6}</b>';

                            d = ian.format(htmlVersion ? htmlText : text
                             , rule.categoryName
                             , rule.mediaTypes
                             , rule.pageTypes
                             , rule.itemTypes
                             , rule.percentage ? rule.percentage + '%' : ''
                             , rule.comparisonType == 'Equals' ? ' igual' : rule.comparisonType == 'Less' ? ' mas bajo' : ' mas alto'
                             , rule.getCompareToDescription());
                        }
                        return d;
                    }
                });
            }
        }

        self.campaigns = {
            getCampaigns: function () {
                return httpService.get('/promotion/campaigns/campaigns');
            },
            getCampaign: function (campaignId) {
                return httpService.get('/promotion/campaigns/campaign/' + campaignId);
            },
            deleteCampaign: function (campaignId) {
                return httpService.post('/promotion/campaigns/delete', campaignId);
            },
            saveCampaign: function (campaign) {
                return httpService.post('/promotion/campaigns/save', campaign);
            }
        }

        self.layouts = {
            getLayouts: function (deleted, assigned) {
                return httpService.get('/promotion/layouts/layouts/' + deleted + '/' + assigned);
            },
            getLayout: function (layoutId) {
                return httpService.get('/promotion/layouts/layout/' + layoutId);
            },
            deleteLayout: function (layoutId) {
                return httpService.post('/promotion/layouts/delete', layoutId);
            },
            saveLayout: function (layout) {
                return httpService.post('/promotion/layouts/save', layout);
            },
            uploadFile: function (file, companyId) {
                var fd = new FormData();
                fd.append("file", file);

                return httpService.post('/promotion/layouts/uploadFile/' + companyId, fd, {
                    headers: { 'Content-Type': undefined },
                    transformRequest: angular.identity,
                    data: fd,
                });
            },
            addPage: function (numberOfPages, promotionId) {
                return httpService.get('/promotion/layouts/addPage/' + numberOfPages + '/' + promotionId);
            },
            deletePage: function (mediaPageIdToDelete, promotionId) {
                return httpService.get('/promotion/layouts/deletePage/' + mediaPageIdToDelete + '/' + promotionId);
            },
            getCategoriesGroups: function () {
                return httpService.get('/promotion/layouts/getCategoriesGroups');
            },
            saveCategoriesGroup: function (group) {
                return httpService.post('/promotion/layouts/saveCategoriesGroup', group);
            },
            deleteCategoriesGroup: function (groupId) {
                return httpService.post('/promotion/layouts/deleteCategoriesGroup', groupId);
            },
        }

        self.items = {
            getItemInfo: function (promotionId, itemId, promotionItemId, saleUnitOfMeasureId) {
                return httpService.get('/promotion/items/getItemInfo/' + promotionId + '/' + itemId + '/' + promotionItemId + '/' + saleUnitOfMeasureId);
            },
            getItemDailyInventory: function (promotionId, itemId, promotionItemId) {
                return httpService.get('/promotion/items/getItemDailyInventory/' + promotionId + '/' + itemId + '/' + promotionItemId);
            },
            getItemOverlaps: function (promotionId, itemId, salesUnitOfMeasureId, promotionMediaPageId, position) {
                return httpService.get('/promotion/items/getItemOverlaps/' + promotionId + '/' + itemId + '/' + salesUnitOfMeasureId + '/' + promotionMediaPageId + '/' + position);
            },
            savePromotionItem: function (item) {
                return httpService.post('/promotion/items/save', item);
            },
            savePromotionVariantItem: function (itemId, promotionItemId, salesUnitOfMeasureId) {
                return httpService.get('/promotion/items/saveVariant/' + itemId + '/' + promotionItemId + '/' + salesUnitOfMeasureId);
            },
            saveReservedItem: function (promotionPageId, position, isHighlighted, description) {
                return httpService.get('/promotion/items/saveReservedItem/' + promotionPageId + '/' + position + '/' + isHighlighted + '/' + description);
            },
            saveReview: function (item) {
                return httpService.post('promotion/items/saveReview', item);
            },
            deletePromotionItem: function (promotionItemId) {
                return httpService.post('/promotion/items/delete', promotionItemId);
            },
            deleteItemChat: function (chatId) {
                return httpService.get('/promotion/items/deleteChat/' + chatId);
            },
            loadItemChats: function (promotionItemId, inPriceTask) {
                return httpService.get('/promotion/items/loadItemChats/' + promotionItemId + '/' + inPriceTask);
            },
            loadItemsChats: function (promotionItemIds, inPriceTask) {
                return httpService.post('/promotion/items/loadItemsChats/' + inPriceTask, promotionItemIds);
            },
            saveChat: function (chat) {
                return httpService.post('/promotion/items/saveChat', chat);
            },
            getItemsForecast: function (promotionId) {
                return httpService.get('/promotion/items/forecast/' + promotionId);
            },
            validateForecasts: function (promotionId) {
                return httpService.post('/promotion/items/validateForecast/' + promotionId);
            },
            updateForecasts: function (promotionId) {
                return httpService.post('/promotion/items/updateForecast/' + promotionId);
            },
            validateItemsBatchFile: function (promotionPageId) {
                return httpService.post('/promotion/items/validateItemsBatchFile/' + promotionPageId);
            },
            updateItemsInBatch: function (promotionPageId) {
                return httpService.post('/promotion/items/updateItemsInBatch/' + promotionPageId);
            },
            getVariants: function (itemId) {
                return httpService.get('/promotion/items/getVariantItems/' + itemId);
            },
            getPromotionVariants: function (promotionItemId) {
                return httpService.get('/promotion/items/getPromotionVariantItems/' + promotionItemId);
            },
            swapPositions: function (promotionMediaPageId, itemFrom, itemTo) {
                return httpService.get('/promotion/items/swapPositions/' + promotionMediaPageId + '/' + itemFrom + '/' + itemTo);
            },
            publishItem: function (promotionMediaPageId, itemId, positionTo, positionToIsHighlighted) {
                return httpService.get('/promotion/items/publishItem/' + promotionMediaPageId + '/' + itemId + '/' + positionTo + '/' + positionToIsHighlighted);
            },
            getPromotionItemStores: function (promotionId, page) {
                return httpService.get('/promotion/items/getPromotionItemStores/' + promotionId + '/' + page);
            },
            validateItemExhibitionsBatchFile: function (promotionPageId) {
                return httpService.post('/promotion/items/validateItemExhibitionsBatchFile/' + promotionPageId);
            },
            updateItemExhibitionsInBatch: function (promotionPageId) {
                return httpService.post('/promotion/items/updateItemExhibitionsInBatch/' + promotionPageId);
            },
            validateItemsBatchFileInternal: function (promotionPageId) {
                return httpService.post('/promotion/items/validateItemsBatchFileInternal/' + promotionPageId);
            },
            updateItemsInBatchInternal: function (promotionPageId) {
                return httpService.post('/promotion/items/updateItemsInBatchInternal/' + promotionPageId);
            },
            validateChatsBatchFile: function (guid, promotionId) {
                return httpService.post('/promotion/items/validateChatsBatchFile/' + guid + '/' + promotionId);
            },
            updateChatsInBatch: function (guid, promotionId) {
                return httpService.post('/promotion/items/updateChatsInBatch/' + guid + '/' + promotionId);
            },
            getLastPromotionItem: function (flag, itemId) {
                return httpService.get('/promotion/items/getLastPromotionItem/' + flag + '/' + itemId);
            },
            getCompetitivePrice: function (promotionMediaPageId, promotionItemType, itemId, saleUnitOfMeasureId) {
                return httpService.get('/promotion/items/getCompetitivePrice/' + promotionMediaPageId + '/' + promotionItemType + '/' + itemId + '/' + saleUnitOfMeasureId);
            },
            getForecast: function (itemId, promotionMediaPageId, isHighlighted) {
                return httpService.get('/promotion/items/getForecast/' + itemId + '/' + promotionMediaPageId + '/' + isHighlighted);
            },
            getCompetitivePrices: function (promotionItemsParamDto) {
                return httpService.post('/promotion/items/getCompetitivePrices', promotionItemsParamDto);
            }
        }

        self.mechanics = {
            getMechanicCategories: function () {
                return httpService.get('/promotion/mechanics/getMechanicCategories');
            },
            getMechanicVendors: function () {
                return httpService.get('/promotion/mechanics/getMechanicVendors');
            },
            getMechanicItems: function () {
                return httpService.get('/promotion/mechanics/getMechanicItems/');
            },
            getPromotionInfo: function (promotionId) {
                return httpService.get('/promotion/mechanics/getPromotionInfo/' + promotionId);
            },
            getPromotionStores: function (promotionId) {
                return httpService.get('/promotion/mechanics/getPromotionStores/' + promotionId);
            },
            saveMechanic: function (mechanic) {
                return httpService.post('/promotion/mechanics/savePromotionMechanic', mechanic);
            },
            savePanguiCode: function (mechanicId, panguiCode) {
                return httpService.get('/promotion/mechanics/savePanguiCode/' + mechanicId + '/' + panguiCode);
            },
            saveReview: function (mechanic) {
                return httpService.post('/promotion/mechanics/saveReview', mechanic);
            },
            getItemForMechanic: function (itemId, umvCode, promotionId) {
                return httpService.get('/promotion/mechanics/getItemForMechanic/' + itemId + '/' + umvCode + '/' + promotionId);
            },
            getItemsForMechanic: function (massiveLoadParam) {
                return httpService.post('/promotion/mechanics/getItemsForMechanic', massiveLoadParam);
            },
            getMechanicInfo: function (promotionId, promotionMechanicId) {
                return httpService.get('/promotion/mechanics/getMechanicInfo/' + promotionId + '/' + promotionMechanicId);
            },
            deleteMechanicItemOrCategory: function (itemOrCategoryId, isCategory, promotionId) {
                return httpService.get('/promotion/mechanics/deleteMechanicItemOrCategory/' + itemOrCategoryId + '/' + isCategory + '/' + promotionId);
            },
            deleteAllSelection: function (mechanicId, isExtra, promotionId) {
                return httpService.get('/promotion/mechanics/deleteAllSelection/' + mechanicId + '/' + isExtra + '/' + promotionId);
            },
            saveChat: function (chat) {
                return httpService.post('/promotion/mechanics/saveChat', chat);
            },
            validateItemsBatchFile: function (guid, pageId) {
                return httpService.post('/promotion/mechanics/validateItemsBatchFile/' + guid + '/' + pageId);
            },
            updateItemsInBatch: function (guid, promotionId) {
                return httpService.post('/promotion/mechanics/updateItemsInBatch/' + guid + '/' + promotionId);
            },
            getMechanicOverlaps: function (overlaps) {
                return httpService.post('/promotion/mechanics/getMechanicOverlaps', overlaps);
            }

        }

        self.workflows = {
            getWorkflows: function (loadTasks) {
                return httpService.get('/promotion/workflows/workflows/' + loadTasks);
            },
            getWorkflow: function (workflowId) {
                return httpService.get('/promotion/workflows/workflow/' + workflowId);
            },
            deleteWorkflow: function (workflowId) {
                return httpService.post('/promotion/workflows/delete', workflowId);
            },
            saveWorkflow: function (workflow) {
                return httpService.post('/promotion/workflows/save', workflow);
            },
            cloneWorkflow: function (workflow, workflowToCloneId) {
                return httpService.post('/promotion/workflows/clone/' + workflowToCloneId,  workflow);
            },
            removeTask: function (taskToRemove) {
                return httpService.post('/promotion/workflows/removeTask', taskToRemove);
            },
            isWorkflowInUse: function (workflowId) {
                return httpService.get('/promotion/workflows/isWorkflowInUse/' + workflowId);
            }
        }

        self.tasks = {
            getTasks: function () {
                return httpService.get('/promotion/tasks/tasks');
            },
            getTask: function (taskId) {
                return httpService.get('/promotion/tasks/task/' + taskId);
            },
            getTaskName: function (taskId) {
                return httpService.get('/promotion/tasks/getTaskName/' + taskId);
            },
            getUserTasks: function (promotion) {
                return httpService.get('/promotion/tasks/userTasks');
            },
            getTasksByPromotion: function (promotionId) {
                return httpService.get('/promotion/tasks/getTasksByPromotion/' + promotionId);
            },
            publishTask: function (taskId, mediaPageId) {
                return httpService.post('/promotion/tasks/publish/' + taskId + '/' + mediaPageId);
            },
            startTask: function (taskId) {
                return httpService.post('/promotion/tasks/start/' + taskId);
            },
            canStartTask: function (taskId) {
                return httpService.post('/promotion/tasks/canStartTask/' + taskId);
            },
            takeIssuesSnapshot: function (taskId) {
                return httpService.post('/promotion/tasks/takeIssuesSnapshot/' + taskId);
            },
            writePromotionPrices: function (promotionId) {
                return httpService.get('/promotion/tasks/writePromotionPrices/' + promotionId);
            },
            canPublishActivation: function (promotionId) {
                return httpService.get('/promotion/tasks/canPublishActivation/' + promotionId);
            },
            getTaskIssues: function (taskId) {
                return httpService.post('/promotion/tasks/getTaskIssues/' + taskId);
            },
            checkTasksStartOrEnd: function () {
                return httpService.get('/promotion/tasks/checkTasksStartOrEnd');
            }
        }

        self.communications = {
            getCommunication: function (promotionId) {
                return httpService.get('/promotion/promotions/communication/' + promotionId);
            },
            getCommunications: function () {
                return httpService.get('/promotion/promotions/communications');
            },
            getHistoricCommunications: function (dateRange) {
                return httpService.post('/promotion/promotions/communications-historic', dateRange);
            },
            setCommunicationFile: function (paramsDto) {
                return httpService.post('/promotion/promotions/setCommunicationFile', paramsDto);
            },
            notificateAllPromotionsToStores: function () {
                return httpService.get('/promotion/promotions/notificateAllPromotionsToStores');
            },
        }

        self.controlPanel = {
            getPromotionsStatus: function () {
                return httpService.get('/promotion/promotions/promotionsStatus');
            }
        }

        self.reports = {
            buildItemTasksReport: function (promotionId) {
                return httpService.get('/promotion/reports/itemTasks/' + promotionId);
            },
            buildStoreItemTasksReport: function (promotionId) {
                return httpService.get('/promotion/reports/storeItemTasks/' + promotionId);
            },
            buildItemStoreReport: function (promotionId) {
                return httpService.get('/promotion/reports/itemStore/' + promotionId);
            },
            buildItemBarCodesReport: function (promotionId) {
                return httpService.get('/promotion/reports/itemBarCodes/' + promotionId);
            },
            buildCommentsReport: function (promotionId) {
                return httpService.get('/promotion/reports/comments/' + promotionId);
            },
            buildIBIReport: function (planned) {
                return httpService.get('/promotion/reports/BI/' + planned);
            },
            getLastBIReports: function () {
                return httpService.get('/promotion/reports/lastBIReports');
            },
            buildOverlappingReport: function (promotionId) {
                return httpService.get('/promotion/reports/overlapping/' + promotionId);
            },
            buildMarkdownItemsReport: function (markdownReportDto) {
                return httpService.post('/promotion/reports/markdownItems', markdownReportDto);
            },
            buildPricesReport: function (promotionId) {
                return httpService.get('/promotion/reports/prices/' + promotionId);
            },
            buildPriceSurveyReport: function (promotionId) {
                return httpService.get('/promotion/reports/priceSurvey/' + promotionId);
            },
            buildPromotionReport: function (dto) {
                return httpService.post('/promotion/reports/buildPromotionReport/', dto);
            }
        }

        self.colors = ['#1ab394', '#1c84c6', '#f8ac59', '#ED5565', '#23c6c8', '#a7acaf', '#8fe6d4', '#fbd4a9', '#c2c2c2', '#a2c1d5', '#1ab394', '#1c84c6', '#f8ac59', '#ED5565', '#23c6c8', '#676a6c', '#8fe6d4', '#fbd4a9', '#c2c2c2', '#a2c1d5', '#1ab394', '#1c84c6', '#f8ac59', '#ED5565', '#23c6c8', '#676a6c', '#8fe6d4', '#fbd4a9', '#c2c2c2', '#a2c1d5'];

        self.promotionMediaTypes = [
               {
                   id: 1,
                   label: 'Encarte',
               },
               {
                   id: 2,
                   label: 'Suplemento',
               },
               {
                   id: 3,
                   label: 'Desplegable',
               },
               {
                   id: 4,
                   label: 'Volante',
               },
               {
                   id: 5,
                   label: 'Televisiva',
               },
               {
                   id: 6,
                   label: 'Interna',
               },
               //{
               //    id: 7,
               //    label: 'Comunicacion',
               //},
               {
                   id: 8,
                   label: 'Diptico',
               },
               {
                   id: 9,
                   label: 'AvisoDePrensa',
               }
               //{
               //    id: 10,
               //    label: 'Otras',
               //}
        ];

        self.userRoles = ['Abastecimiento', 'Jefe de Promociones', 'Analista Pricing', 'BI', 'Category', 'Comercial', 'Control de Gestion', 'Demand Planning', 'Jefe de Pricing', 'Desarrollo', 'Gerente Tienda', 'Jefe Comercial', 'Marketing', 'Operaciones', 'Pricing', 'Promociones', 'Analista Seg. y Ctrol.', 'Analista Comercial', 'Analista de Promociones', 'Asistente Tienda', 'Banco Cencosud', 'Responsable carteler�a'];

        //cuando se asigna un responsable a una tarea de un Wle asignamos un area agrupadora de roles
        self.userAreas = ['Abastecimiento', 'Banco Cencosud', 'Category', 'Comercial', 'Comercial - Perecibles', 'Finanzas', 'Administracion de espacios', 'Desarrollo comercial', 'Visual merchandising', 'Promociones Ejecucion', 'Promociones Seguimiento', 'Promociones Reporting', 'Operaciones Centralizado', 'BI', 'E-Commerce', 'Demand Planning', 'Marketing', 'Operaciones', 'Pricing'];

        self.getUserRolesByArea = function (userArea) {
            var userRoles = [];
            switch (userArea) {
                case "Comercial":
                    userRoles.push("Comercial");
                    userRoles.push("Jefe Comercial");
                    userRoles.push("Analista Comercial");
                    break;
                case "Comercial - Perecibles":
                    userRoles.push("Comercial - Perecibles");
                    break;
                case "Banco Cencosud":
                    userRoles.push("Banco Cencosud");
                    break;
                case "Abastecimiento":
                    userRoles.push("Abastecimiento");
                    break;
                case "Finanzas":
                    userRoles.push("Control de Gestion");
                    break;
                case "Category":
                    userRoles.push("Category");
                    userRoles.push("Jefe Category");
                    break;
                case "Operaciones":
                    userRoles.push("Gerente Tienda");
                    userRoles.push("Asistente Tienda");
                    break;
                case "Administracion de espacios":
                    userRoles.push("Admin. Espacios");
                    break;
                case "Marketing":
                    userRoles.push("Marketing");
                    break;
                case "Desarrollo comercial":
                    userRoles.push("Desarrollo");
                    break;
                case "Visual merchandising":
                    userRoles.push("Visual");
                    break;
                case "Pricing":
                    userRoles.push("Jefe de pricing");
                    userRoles.push("Analista Pricing");
                    break;
                case "Promociones":
                    userRoles.Add("Jefe de Promociones");
                    userRoles.Add("Analista de Promociones");
                    userRoles.Add("Jefe Seg. y Ctrol.");
                    userRoles.Add("Analista Seg. y Ctrol.");
                    userRoles.Add("Jefe Reporting");
                    userRoles.Add("Analista Reporting");
                    break;
                case "Promociones Ejecucion":
                    userRoles.push("Jefe de Promociones");
                    userRoles.push("Analista de Promociones");
                    break;
                case "Promociones Seguimiento":
                    userRoles.push("Jefe Seg. y Ctrol.");
                    userRoles.push("Analista Seg. y Ctrol.");
                    break;
                case "Promociones Reporting":
                    userRoles.push("Jefe Reporting");
                    userRoles.push("Analista Reporting");
                    break;
                case "Operaciones Centralizado":
                    userRoles.push("Responsable carteler�a");
                    break;
                case "BI":
                    userRoles.push("BI");
                    break;
                case "Demand Planning":
                    userRoles.push("Demand Planning");
                    break;
                case "E - Commerce":
                    userRoles.push("E - Commerce");
                    break;
            }
            return userRoles;
        }

        self.getUserAreasByRole = function (userRole) {
            var userAreas = [];
            switch (userRole) {
                case "Comercial":
                    userAreas.push("Comercial");
                    break;
                case "Jefe Comercial":
                    userAreas.push("Comercial");
                    break;
                case "Analista Comercial":
                    userAreas.push("Comercial");
                    break;
                case "Comercial - Perecibles":
                    userAreas.push("Comercial - Perecibles");
                    break;
                case "Banco Cencosud":
                    userAreas.push("Banco Cencosud");
                    break;
                case "Abastecimiento":
                    userAreas.push("Abastecimiento");
                    break;
                case "Control de Gestion":
                    userAreas.push("Finanzas");
                    break;
                case "Category":
                    userAreas.push("Category");
                    break;
                case "Jefe Category":
                    userAreas.push("Category");
                    break;
                case "Gerente Tienda":
                    userAreas.push("Operaciones");
                    break;
                case "Asistente Tienda":
                    userAreas.push("Operaciones");
                    break;
                case "Admin. Espacios":
                    userAreas.push("Administracion de espacios");
                    break;
                case "Marketing":
                    userAreas.push("Marketing");
                    break;
                case "Desarrollo":
                    userAreas.push("Desarrollo Comercial");
                    break;
                case "Visual":
                    userAreas.push("Visual Merchandising");
                    break;
                case "Jefe de Pricing":
                    userAreas.push("Pricing");
                    break;
                case "Analista Pricing":
                    userAreas.push("Pricing");
                    break;
                case "Jefe de Promociones":
                    userAreas.push("Promociones");
                    userAreas.push("Promociones Ejecucion");
                    break;
                case "Analista de Promociones":
                    userAreas.push("Promociones");
                    userAreas.push("Promociones Ejecucion");
                    break;
                case "Jefe Seg. y Ctrol":
                    userAreas.push("Promociones");
                    userAreas.push("Promociones Seguimiento");
                    break;
                case "Analista Seg. y Ctrol.":
                    userAreas.push("Promociones");
                    userAreas.push("Promociones Seguimiento");
                    break;
                case "Jefe Reporting":
                    userAreas.push("Promociones");
                    userAreas.push("Promociones Reporting");
                    break;
                case "Analista Reporting":
                    userAreas.push("Promociones");
                    userAreas.push("Promociones Reporting");
                    break;
                case "Responsable carteler�a":
                    userAreas.push("Operaciones Centralizado");
                    break;
                case "BI":
                    userAreas.push("BI");
                    break;
                case "Demand Planning":
                    userAreas.push("Demand Planning");
                    break;
                case "E - Commerce":
                    userAreas.push("E - Commerce");
                    break;
                default:
                    userAreas.push("Todos");
                    break
            }
            return userAreas;
        }

        self.promotionMediaPageTypes = [
               {
                   id: 1,
                   label: 'Caratula',
               },
               {
                   id: 2,
                   label: 'Contracaratula',
               },
               {
                   id: 3,
                   label: 'Pagina',
               }
        ];

        self.promotionMechanicTypes = [
               {
                   id: 1,
                   label: 'AxB',
               },
               {
                   id: 2,
                   label: 'Coleccionable',
               },
               {
                   id: 3,
                   label: 'Compra A+ $ Lleva B',
               },
               {
                   id: 4,
                   label: 'Compra A y lleva B Gratis',
               },
               {
                   id: 5,
                   label: 'Compra x$ en A y lleva B Gratis',
               },
               {
                   id: 6,
                   label: 'Cupon de Descuento',
               },
               {
                   id: 7,
                   label: 'Cuponera de Descuento',
               },
               {
                   id: 8,
                   label: 'x de Descuento',
               },
               {
                   id: 9,
                   label: 'x% de Descuento por Dia',
               },
               {
                   id: 10,
                   label: 'Pack Virtual',
               },
               {
                   id: 11,
                   label: 'Mensaje',
               },
               {
                   id: 12,
                   label: 'x Interes a Y Cuotas',
               },
               {
                   id: 13,
                   label: 'Compra A y lleva B con x de descuento',
               },
               {
                   id: 14,
                   label: 'Electro Fans',
               },
               {
                   id: 15,
                   label: 'Descuento por Hora',
               },
               {
                   id: 16,
                   label: 'Precios Multiples',
               },
               {
                   id: 17,
                   label: 'Mega Promo',
               },
               {
                   id: 18,
                   label: 'Desc en Vales de Compra',
               },
               //{
               //     id: 19,
               //     label: 'Couponing',
               // },
                {
                    id: 20,
                    label: 'Precio-Producto Masivo',
                }
        ];

        self.promotionMechanicAnnouncementTypes = [
            //{
            //    id: 0,
            //    label: 'No posee',
            //},
            {
                id: 1,
                label: 'Foto precio',
            },
            {
                id: 2,
                label: 'Cuarto de pagina',
            },
            {
                id: 3,
                label: 'Tercio de pagina',
            },
            {
                id: 4,
                label: 'Media pagina',
            },
            {
                id: 5,
                label: 'Pagina',
            },
            {
                id: 6,
                label: 'Caratula',
            },
            {
                id: 7,
                label: 'Contra Caratula',
            }
        ];

        self.promotionMechanicPaymentTypes = [

                //{
                //    id: 0,
                //    label: 'No posee', 
                //},
                {
                    id: 1,
                    label: 'Cobro (factura)',
                },
               {
                   id: 2,
                   label: 'Acuerdo comercial',
               },
               //{
               //    id: 3,
               //    label: 'Uso propio',
               //},
               //{
               //    id: 4,
               //    label: 'Importado',
               //},
               {
                   id: 5,
                   label: 'Cedido',
               }
        ];

        self.promotionExhibitionType = [
            { value: 1, name: ' CABECERAS ALTAS' },
            { value: 2, name: 'CABECERAS TOCADOR' },
            { value: 3, name: 'CABECERAS PASILLO' },
            { value: 4, name: 'CABECERAS LICORES' },
            { value: 5, name: 'CABECERAS DE FRIO' },
            { value: 6, name: 'LATERALES' },
            { value: 7, name: 'EXHIBIDORES DE PIE' },
            { value: 8, name: 'VISICOOLER GRANDES' },
            { value: 9, name: 'VISICOOLER CHICOS' },
            { value: 10, name: 'FAST LINE' },
            { value: 11, name: 'VENTA CRUZADA - GANCHOS' },
            { value: 12, name: 'VENTA CRUZADA - DISPENSADOR' },
            { value: 13, name: 'VENTA CRUZADA - S/COOLER' },
            { value: 14, name: 'MINI CABECERAS' },
            { value: 15, name: 'MESA DE CARGA' },
            { value: 16, name: 'ISLA' },
            { value: 17, name: 'COLUMNA' },
            { value: 18, name: 'FILA UNICA' },
            { value: 19, name: 'FILA UNICA ZONA FRONTAL' },
            { value: 20, name: 'BTL' }
        ];

        self.calculateTariff = function (brand, announcementType) {
            var tariff = 0;
            if (brand == "Wong") {
                switch (announcementType) {
                    case 1: tariff = 6000;
                        break;
                    case 2: tariff = 10000;
                        break;
                    case 3: tariff = 18000;
                        break;
                    case 4: tariff = 20000;
                        break;
                    case 5: tariff = 40000;
                        break;
                    case 6: tariff = 50000;
                        break;
                    case 7: tariff = 45000;
                        break;
                }
            }
            if (brand == "Metro") {
                switch (announcementType) {
                    case 1: tariff = 5000;
                        break;
                    case 2: tariff = 8000;
                        break;
                    case 3: tariff = 10000;
                        break;
                    case 4: tariff = 15000;
                        break;
                    case 5: tariff = 30000;
                        break;
                    case 6: tariff = 40000;
                        break;
                    case 7: tariff = 35000;
                        break;
                }
            }
            return tariff;
        }

        self.getIssueName = function (value) {
            var rv = "";
            switch (value) {
                case 1: rv = "Ubicaciones sin art asignado";
                    break;
                case 2: rv = "Ubicaciones sin foto o con foto no oficial";
                    break;
                case 3: rv = "Art�culos con al menos 1 tienda con menos de x DDS";
                    break;
                case 4: rv = "Pron�stico fuera de rango sugerido";
                    break;
                case 5: rv = "Art�culos x encima del sugerido o por debajo del 15%";
                    break;
                case 6: rv = "Art�culos no catalogados en todas las tiendas activas definidas";
                    break;
                case 7: rv = "Art�culo sin precio promocional";
                    break;
            }
            return rv;
        }

    });