var _controller = function ($scope, $attrs, $filter, $timeout, $rootScope, $translate, priceService, homeService, adminService) {
    var self = this;
    self.companyCode = companyJson.Erp.Implementation;
    self.timerange = 'week';
    self.hideBoxes = false;
    self.compareYtd = null;

    self.sameStores = true;
    self.type = '3';

    self.allPriceList = [{}];
    self.items = [{}];
    self.stores = [];
    self.brands = [];
    self.prices = [];
    self.volumes = []; 
    self.volumesAnnual = []; 
    self.selectedPriceZone = null;
    self.selectedStore = null;
    self.selectedPriceZoneName = '';
    self.itemName = "";
    self.itemCode = "";
    self.selectedCompetitorGroups = [];
    self.selectedBrands = [];
    self.competitorGroupsFilteredByParent = [];
    self.onlyMarkers = false;
    self.brandCriteria = false;
    self.competitorCriteria = true;

    self.changeItemSelected = function () {
        //console.log('item changed', self.selectedItem);
    }

    self.changeCompetitorGroup = function () {
        loadCompetitorPrices();
    }

    self.changeTimeRange = function (timerange) {
        self.timerange = timerange;
        //self.updateChart();
    }

    function loadCompetitorPrices() {
        //Cargo data de los competidores si es tengo algun competitor group seleccionado
        if (self.selectedCompetitorGroups.length > 0) {

            var competitorGroupIds = [];
            self.selectedCompetitorGroups.forEach(function (group) {
                competitorGroupIds.push(group.id);
            });

            var parameters = {
                itemId: self.price.itemId,
                priceListId: self.price.priceListId,
                type: self.timerange,
                onlyMarkers: self.onlyMarkers,
                competitorGroupIds: competitorGroupIds
            };

            priceService.decisions.getKPIDashboardCompetitorsPrices(parameters).then(function (competitorPrices) {
                self.competitorPrices = competitorPrices;
                if (competitorPrices.length > 0) {
                    self.updateChart();
                }
            });
        }
        else {
            self.competitorPrices = [];
            self.updateChart();
        }

    }

    self.changeCompetitorGroupParents = function () {

        if (self.price.competitorGroupParents != null) {

            self.competitorGroupsFilteredByParent = [];
            angular.forEach(self.competitorGroups, function (competitorGroup) {
                angular.forEach(self.price.competitorGroupParents, function (competitorGroupParent) {
                    if (competitorGroup.parentId == competitorGroupParent.id)
                        self.competitorGroupsFilteredByParent.push(competitorGroup);
                })
            });

            //self.competitorGroupsFilteredByParent = ($filter('filter')(self.competitorGroups, { parentId: self.price.competitorGroupParents.id }));
        }
        else {
            self.selectedCompetitorGroups = [];
            self.competitorGroupsFilteredByParent = self.competitorGroups;
            self.price.competitorGroup = null;
        }
    }

    function loadCompetitorGroups() {

        var priceListId = 0
        if (self.selectedPriceZone != undefined)
            priceListId = self.selectedPriceZone.id;

        priceService.competitors.getCompetitorGroupsByPriceList(priceListId).then(function (compGroups) {
            self.competitorGroups = angular.copy(compGroups);
            self.competitorGroupsFilteredByParent = angular.copy(compGroups);
        })
    }

    function loadCompetitorGroupsParents() {
        var priceListId = 0
        if (self.selectedPriceZone != undefined)
            priceListId = self.selectedPriceZone.id;
        priceService.competitors.getCompetitorGroupsParentByPriceList(priceListId).then(function (compGroupsParents) {
            self.competitorGroupsParents = compGroupsParents;
        })
    }

    function loadItems(categoryId) {
        adminService.items.getItemsByCategory(categoryId).then(function (items) {
            self.items = items;

            for (var i = 0; i < items.length; i++) {
                if (items[i].id == self.price.itemId) {
                    self.selectedItem = items[i];
                }
            }

            if (self.selectedItem == undefined) {
                self.selectedItem = items[0];
            }
        })
    }

    function loadStores() {
        adminService.stores.getStoresCombo().then(function (stores) {
            stores.unshift({id: -1, name: ' --- ', code: ' --- '});
            self.stores = stores;
        });
    }

    function loadBrands() {
        adminService.brands.getBrands().then(function (brands) {
            self.brands = brands;
        });
    }

    function loadPriceLists() {

        priceService.pricelists.getPriceLists().then(function (priceList) {
            self.allPriceList = priceList;

            var selected = false;
            for (i = 0; i < self.allPriceList.length; i++) {
                if (self.allPriceList[i].id == self.price.priceListId) {
                    selected = true;
                    self.setFilterPriceZone(self.allPriceList[i]);
                }
            }

            if (selected == false) {
                self.setFilterPriceZone(self.allPriceList[0]);
            }
        });
    }

    self.setFilterPriceZone = function (priceZone) {
        if (self.selectedPriceZone == priceZone) return;
        self.selectedPriceZone = priceZone;

        if (priceZone != null) {
            self.selectedPriceZoneName = priceZone.name;
        }
        else
            self.selectedPriceZoneName = '';

        //changePriceUsed();

        //loadCompetitorGroupPrices();
        loadCompetitorGroupsParents();
        loadCompetitorGroups();

    }

    self.sameStoreChanges = function (value) {
        //console.log('value', value);
        loadData(value);
    }

    self.timeTypeChange = function () {
        loadBoxData(self.sameStores, self.type);

        //loadData(self.sameStores);
    }

    self.getVsPrevious = function () {
        if (self.type == '2') {
            return translations.VsPrevYear;
        }

        if (self.type == '1') {
            var rv = 'vs ';

            var year = moment().format('YYYY');
            var month = moment().add(-2, 'month').format('MM');

            rv += $rootScope.monthNames[month - 1] + ' ' + year;

            return rv;
        };

        if (self.type == '3') {
            var rv = 'vs ';
            
            var year = moment().add(-1, 'year').format('YYYY');
            var month = moment().add(-1, 'month').format('MM');

            rv += $rootScope.monthNames[month - 1] + ' ' + year;

            return rv;
        }
    }

    //boxes
    self.getMainBoxTitle = function (box) {
        var rv = "";
        if(translations)
            switch (box) {
                case "sales": rv = translations.SALES; break;
                case "units": rv = translations.UNITS; break;
                case "cost": rv = translations.COST; break;
                case "margin": rv = translations.MARGIN; break;
            }

        return rv;
    }
    self.getBoxCompareValue = function (box) {
        if (box && self.compareYtd) {
            var value = $filter('percentage')(self.compareYtd[box + 'PyDelta'], 1);

            return self.compareYtd[box + 'PyDelta'] != 0 ? value : 'N/A';
        }
    }
    self.getBoxCompareTooltip = function (box) {
        if (box && self.compareYtd) {

            var value = null;
            var filter = box === 'units' ? $filter('number') : $filter('currency');

            if (self.boxesCompareTo === 'Month') {
                value = box === 'units' ? filter(self.compareMonth[box + 'Py'], 0) : filter(self.compareMonth[box + 'Py'], '$', 0);
            }
            else {
                value = box === 'units' ? filter(self.compareYtd[box + 'Py'], 0) : filter(self.compareYtd[box + 'Py'], '$', 0);
            }

            return value;
        }
    }

    self.getMainBoxValueGoal = function (box) {
        if (box && self.compareYtd) {

            var value = null;
            var filter = box === 'units' ? $filter('abbreviate') : $filter('abbreviate');
            var prefix = box === 'units' ? null : "$";
            var decimals = box === 'units' ? 0 : 2;

            //if (self.boxesCompareTo === 'Month') {
            //    value = filter(self.compareMonth[box], decimals, prefix);
            //}
            //else if (self.boxesCompareTo === 'Goal') {
            filter = $filter('percentage');
            value = filter(self.compareYtd[box + 'GoalGapP'], 2);
            //}
            //else {
            //    value = filter(self.compareYtd[box + 'Ytd'], decimals, prefix);
            //}

            return self.compareYtd[box + 'GoalGapP'] != 0 ? value : 'N/A';
        }
    }
    self.getMainBoxTooltip = function (box) {
        if (box && self.compareYtd) {

            var value = null;
            var filter = box === 'units' ? $filter('number') : $filter('currency');

            if (self.boxesCompareTo === 'Month') {
                value = box === 'units' ? filter(self.compareMonth[box], 0) : filter(self.compareMonth[box], '$', 0);
            }
            else {
                value = box === 'units' ? filter(self.compareYtd[box + 'Ytd'], 0) : filter(self.compareYtd[box + 'Ytd'], '$', 0);
            }

            return value;
        }
    }
    self.isMainBoxGoalSignPositive = function (box) {
        if (box && self.compareYtd) {

            return self.compareYtd[box + 'GoalGapP'] > 0;
        }
    }

    self.getMainBoxValueYTD = function (box) {
        if (box && self.compareYtd) {

            var value = null;
            var filter = box === 'units' ? $filter('abbreviate') : $filter('abbreviate');
            var prefix = box === 'units' ? null : "$";
            var decimals = box === 'units' ? 0 : 2;

            value = filter(self.compareYtd[box + 'Ytd'], decimals, prefix);
        }

        return value;
    }
    self.getMainBoxValueAnual = function (box) {
        if (box && self.compareYtd) {

            var value = null;
            var filter = box === 'units' ? $filter('abbreviate') : $filter('abbreviate');
            var prefix = box === 'units' ? null : "$";
            var decimals = box === 'units' ? 0 : 2;

            value = filter(self.compareYtd[box + 'GoalTotal'], decimals, prefix);

            return self.compareYtd[box + 'GoalTotal'] != 0 ? value : 'N/A';
        }
    }
    self.getMainBoxAnualTooltip = function (box) {
        if (box && self.compareYtd) {

            var value = null;
            var filter = box === 'units' ? $filter('number') : $filter('currency');

            if (self.boxesCompareTo === 'Month') {
                value = box === 'units' ? filter(self.compareMonth[box + 'GoalTotal'], 0) : filter(self.compareMonth[box + 'GoalTotal'], '$', 0);
            }
            else {
                value = box === 'units' ? filter(self.compareYtd[box + 'GoalTotal'], 0) : filter(self.compareYtd[box + 'GoalTotal'], '$', 0);
            }

        }
        return value;
    }

    self.getActualYtdValue = function (box) {
        if (self.compareYtd != null) {
            var value = null;
            var filter = box === 'units' ? $filter('abbreviate') : $filter('abbreviate');

            value = box === 'units' ? filter(self.compareYtd[box + 'Goal'], 0) : filter(self.compareYtd[box + 'Goal'], '$', 0);

            return value;
        }
    }
    self.getActualYtdTooltipValue = function (box) {
        if (self.compareYtd != null) {
            var value = null;
            var filter = box === 'units' ? $filter('number') : $filter('currency');

            value = box === 'units' ? filter(self.compareYtd[box + 'Goal'], 0) : filter(self.compareYtd[box + 'Goal'], '$', 0);

            return value;
        }
    }

    self.getProjectedYtdValue = function (box) {
        if (self.compareYtd != null) {
            var value = null;
            var filter = box === 'units' ? $filter('abbreviate') : $filter('abbreviate');

            value = box === 'units' ? filter((self.compareYtd[box + 'Projected'] + self.compareYtd[box + 'Ytd']), 0) : filter((self.compareYtd[box + 'Projected'] + self.compareYtd[box + 'Ytd']), '$', 0);

            return self.compareYtd[box + 'Projected'] != 0 ? value : 'N/A';
        }
    }
    self.getProjectedYtdTooltipValue = function (box) {
        if (self.compareYtd != null) {
            var value = null;
            var filter = box === 'units' ? $filter('number') : $filter('currency');

            value = box === 'units' ? filter((self.compareYtd[box + 'Projected'] + self.compareYtd[box + 'Ytd']), 0) : filter((self.compareYtd[box + 'Projected'] + self.compareYtd[box + 'Ytd']), '$', 0);

            return value;
        }
    }
    self.projectedPositive = function (box) {
        if (box && self.compareYtd) {
            var projected = self.compareYtd[box + 'Projected'] + self.compareYtd[box + 'Ytd'];
            var annual = self.compareYtd[box + 'GoalTotal'];

            if (box != 'cost')
                return projected >= annual;
            else
                return projected < annual;
        }
    }

    self.getBoxCompareIcon = function (box) {

        if (box && self.compareYtd) {

            var delta = null;
            var cls = '';

            delta = self.compareYtd[box + 'PyDelta'];

            if (box != 'cost') {
                if (!delta)
                    cls = '';
                else if (delta > 0.005)
                    cls = 'fa fa-level-up text-navy';
                else if (delta < -0.005)
                    cls = 'fa fa-level-down text-danger';
                else
                    cls = 'fa fa-anchor';
            }
            else {
                if (!delta)
                    cls = '';
                else if (delta > 0.005)
                    cls = 'fa fa-level-up text-danger';
                else if (delta < -0.005)
                    cls = 'fa fa-level-down text-navy';
                else
                    cls = 'fa fa-anchor';
            }


            return cls;
        }
    }
    self.isBoxSignPositive = function (box) {
        if (box && self.compareYtd) {

            var value = null;

            value = self.compareYtd[box + 'PyDelta'];

            if (box == 'cost') {
                if (value <= 0)
                    return true
                else
                    return false;
            }
            else {
                if (value > 0)
                    return true
                else
                    return false;
            }
        }

    }

    self.toggleLegend = function (chart) {
        if (!chart.legend.visible) {
            chart.legend.show();
            chart.legend.visible = true;
        }
        else {
            chart.legend.hide();
            chart.legend.visible = false;
        }
    }
    self.updateBoxes = function (compareTo) {

        if (compareTo === 'Month') {
            findMonthToCompare(self.selectedMonth);
        }

        self.boxesCompareTo = compareTo;

        if (compareTo === 'Goal')
            renderGoalCharts();

    }


    function renderGoalCharts() {

        var boxes = ['sales', 'margin', 'units', 'cost'];

        angular.forEach(boxes, function (box) {

            var chart = c3.generate(getGoalChartTemplate(box));


            setTimeout(function () {
                chart.load({
                    columns: [['objetivo', self.compareYtd[box + 'Goal']]],
                });
            }, 500);

            setTimeout(function () {
                chart.load({
                    columns: [['actual', self.compareYtd[box + 'Ytd']],
                    ['proyectado', self.compareYtd[box + 'Projected']]
                    ],
                });
                chart.groups([['proyectado', 'actual']]);
            }, 1500);

            self[box + 'GoalChart'] = chart;
        });
    }
    function getGoalChartTemplate(box) {

        var template = {
            bindto: ian.format('#{0}GoalChart', box),
            data: {
                columns: [],
                type: 'bar',
                groups: [],
                colors: {
                    actual: '#1f77b4',
                    proyectado: '#aec7e8',
                    objetivo: '#1ab394',
                },
                order: null
            },
            size: {
                height: 65,
            },
            bar: {
                width: 5
            },
            axis: {
                rotated: true,
                x: { show: false },
                y: { show: false }
            },
            tooltip: {
                show: true,
                format: {
                    title: function (x) { return 'vs Plan'; },
                    value: function (value, ratio, id) {
                        return box === 'units' ? $filter('abbreviate')(value, 0) : $filter('abbreviate')(value, '$', 0);
                    }
                }
            },
            legend: {
                show: false
            }
        };

        return template;
    }


    function findMonthToCompare(month) {

        var rv = null;
        angular.forEach(self.compareYtd.vsPriorYearByMonth, function (item) {
            if (item.month == month && item.year == self.compareYtd.currentYear) {
                rv = item;
            }
        });

        self.compareMonth = rv;

        return rv;
    }
    self.isLoadingChart = false;
    function loadChartData() {
        self.isLoadingChart = true;
        //Traigo data para el chart
        priceService.decisions.getPriceDashboardItemPriceList(self.price.subCategoryId, self.price.itemId, self.price.priceListId, self.sameStores)
            .then(function (prices) {
                self.prices = prices;
                self.isLoadingChart = false;

                self.priceDashboard = self.prices;

                self.updateChart();
            });
    }

    self.competitorPrices = [];


    self.updateChart = function () {

        if (!self.prices || self.prices == null || self.prices == '')
            return;

        var xValues = [];

        if (self.timerange == 'week')
            xValues = self.prices.lastWeekXValues;
        else if (self.timerange == 'month')
            xValues = self.prices.lastMonthXValues;
        else
            xValues = self.prices.lastYearXValues;

        var series = ['sales', 'units', 'margin', 'price'];
        var axes = {
            sales: 'y',
            units: 'y',
            margin: 'y',
            price: 'y2'
        };

        var chartTypes = {
            price: 'line'
        };

        var chartColors = {
            sales: '#1ab394',
            units: '#1c84c6',
            margin: '#f8ac59',
            price: '#ED5565'
        };

        var extraColors = [];

        if (self.competitorPrices.length > 0) {
            self.selectedCompetitorGroups.forEach(function (group) {
                series.push(group.name);
                axes[group.name] = 'y2';
                chartTypes[group.name] = 'line';

                self.competitorPrices.forEach(function (competitorPrice) {
                    if (competitorPrice.competitorGroupId == group.id) {

                        var newCompetitorPrice = {
                            dateString: competitorPrice.dateString,
                            dataType: 'CompetitorsPrice'
                        };
                        newCompetitorPrice[group.name] = competitorPrice.price;
                        xValues.push(newCompetitorPrice);

                    }
                });
            });
        }

        self.chart = c3.generate({
            bindto: '#saleschart_' + self.price.priceId,
            data: {
                json: xValues,
                keys: {
                    x: 'dateString',
                    value: series,
                },
                axes: axes,
                type: 'bar',
                types: chartTypes,
                //colors: chartColors
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        fit: true,
                        format: function (x) {
                            if (self.timerange == 'week') {
                                return $rootScope.shortWeekDayNames[x.getDay()];
                            } else if (self.timerange == 'month') {
                                return x.getDate() + '-' + $rootScope.shortMonthNames[(x.getMonth())];
                            } else if (self.timerange == 'year') {
                                return x.getFullYear() + '-' + (x.getMonth() + 1);
                            }
                        }
                    }
                },
                y: {
                    tick: {
                        format: d3.format("s")
                    }
                },
                y2: {
                    show: true,
                    label: 'Precio en $',
                    tick: {
                        format: d3.format('.1f')
                    },
                    min: 0
                }
            },
            tooltip: {
                format: {
                    title: function (d) {
                        var toFormat = '%B %d, %Y';
                        if (self.timerange == 'year')
                            toFormat = '%B %Y';

                        var format = d3.time.format(toFormat);
                        return format(d);
                    },
                    value: function (value, ratio, id) {
                        var format = id === 'sales' || id === 'margin' || id === 'units' ? d3.format(',.0f') : d3.format(',.1f');
                        return format(value);
                    }
                }
            }
        });
    }

    function loadData() {

        var categoryId = 0;
        if (self.price && self.price.item && self.price.subCategoryId)
            categoryId = self.price.subCategoryId;

        loadItems(categoryId);

        loadStores();

        loadBrands();

        loadPriceLists();

        loadChartData(self.sameStores, self.type);

        //traigo data para los boxes
        loadBoxData(self.sameStores, self.type);

        getVolumeMeasurement(function (data) {
            self.volumes = data;
            self.updateChartVolume();
        });

        getVolumeMeasurementAnnual(function (data) {
            self.volumesAnnual = data;
            self.updateChartVolumeAnnual();
        });

    }

    self.isLoadingKPIs = false;
    function loadBoxData(withSameStores, type) {
        self.isLoadingKPIs = true;
        priceService.decisions.getKpiDashboardYTD(self.price.itemId, self.price.priceListId, withSameStores, type)
            .then(function (dashboardComparisonDto) {
                self.isLoadingKPIs = false;
                self.compareYtd = dashboardComparisonDto;
                self.updateBoxes('GoalTotal');
            });
    }

    var translations = null;
    function init() {

        
        $translate(["SALES", "UNITS", "MARGIN", "COST", "VsPrevYear"])
            .then(function (all) {
                translations = all;

                loadData();
            });
    }

    init();

    self.updateChartVolume = function () {
        c3.generate({
            bindto: "#volumeMeasurementChart_" + self.price.priceId,
            data: {
                x: 'x',
                "columns": self.volumes,
                empty: { label: { text: "Sin datos" } },
                type: 'bar',
                colors: {
                    'units': '#FDC200',
                }
            },
            axis: {
                x: {
                    type: 'category',
                    //tick: {
                    //    rotate: 0,
                    //    multiline: false,
                    //    width: '1px',

                    //},

                },
  
            },
            tooltip: {
                format: {
                    title: function (index) {
                        if (self.volumes[0]) {
                            return self.volumes[0][index + 1];
                        }
                    },
                    value: function (value, ratio, id) {
                        var newValue =  value ;
                        return newValue;
                    }
                }
            }

        });
    }
    self.updateChartVolumeAnnual = function () {
        c3.generate({
            bindto: "#volumeMeasurementAnnualChart_" + self.price.priceId,
            data: {
                x: 'x',
                "columns": self.volumesAnnual,
                //    ['x', self.volumesAnnual],
               //     ['units', self.volumesAnnual[1]],
                 //   ['units2', self.volumesAnnual[2]]
                //],
                empty: { label: { text: "Sin datos" } },
                type: 'bar',
                types: { units : 'bar', units2: 'area',},
                colors: {
                    'units': '#FDC200',
                    'units2': 'gray',
                }
            },
            axis: {
                x: {
                    type: 'category',
                },

            },
            tooltip: {
                format: {
                    title: function (index) {
                        if (self.volumes[0]) {
                            return self.volumes[0][index + 1];
                        }
                    },
                    value: function (value, ratio, id) {
                        var newValue = value;
                        return newValue;
                    }
                }
            }

        });
    }

    function getVolumeMeasurement(callback) {
        self.isLoadingChart = true;
        priceService.decisions.getVolumeMeasurement_OXXO(self.price.itemId,0).then(function (volumes) {
            self.isLoadingChart = false;
            if (volumes == null || volumes== '') {
                callback([]);
            }
           
            parseData(volumes, callback);
            
        });
    }
    function getVolumeMeasurementAnnual(callback) {
        self.isLoadingChart = true;
        priceService.decisions.getVolumeMeasurementAnnual_OXXO(self.price.itemId, 0).then(function (volumesAnnual) {
            self.isLoadingChart = false;
            if (volumesAnnual == null || volumesAnnual == '') {
                callback([]);
            }

            parseDataAnnual(volumesAnnual, callback);

        });
    }
    function parseData(volumes, callback) {
        var valueToPush = new Array();
        //serie para el eje x, distinct de fechas
        var datesArray = volumes.reduce(function (a, d) {
            if (a.indexOf(d.dayMonth) === -1) {
                a.push(d.dayMonth);
            }
            return a;
        }, ['x']);

        var units = [];
        units.push('units');
        for (i = 1; i < datesArray.length; i++) {
     
            var volumeDate = volumes.find(cd => {
                return cd.dayMonth == datesArray[i];
            })
            if (volumeDate) {
                units.push(volumeDate.totalUnits);
            }
         
        }
        valueToPush[0] = datesArray;
        valueToPush[1] = units;
        callback(valueToPush);
    }
    function parseDataAnnual(volumesAnnual, callback) {
        var valueToPush = new Array();
        //serie para el eje x, distinct de fechas
        var datesArray = volumesAnnual.reduce(function (a, d) {
            if (a.indexOf(d.monthYear) === -1) {
                a.push(d.monthYear);
            }
            return a;
        }, ['x']);

        var units = [];
        units.push('units');
        var units2 = [];
        units2.push('units2');
        for (i = 1; i < datesArray.length; i++) {

            var volumeDate = volumesAnnual.find(cd => {
                return cd.monthYear == datesArray[i];
            })
            if (volumeDate) {
                units.push(volumeDate.totalUnits);
                var newVal = (volumeDate.totalUnits + 5000);
                units2.push(newVal);
            }

        }
        valueToPush[0] = datesArray;
        valueToPush[1] = units;
        valueToPush[2] = units2;
        callback(valueToPush);
    }
}

angular.module('prisma').component('kpiItemView', {
    templateUrl: 'app/shared_logic/components/kpiItemview/kpiItemview.component.html',
    bindings: {
        price: '<'
    },

    controller: _controller
});


