'use strinct';
(function () {
    angular.module('prisma')
        .directive('planogramColumn', ['$log', planogramColumnDirective])
        .controller('planogramColumnController', ['$log', 'DnDService', planogramColumnController]);

    function planogramColumnDirective($log) {
        // $log.debug('planogramColumnDirective::ctor');
        return {
            restrict: 'A',
            selector: '[planogram-column]',
            templateUrl: '/app/spaces/planogram/planogram-column.view.html',
            scope: {
                model: '<'
            },
            require: {
                planogram: '^^planogram'
            },
            controllerAs: '$ctrl',
            controller: 'planogramColumnController',
            bindToController: true
        };
    }

    function planogramColumnController($log, dnDService) {
        // $log.debug('planogramColumnController::ctor');
        var self = this;
        self.$onInit = function $onInit() {
            // $log.debug('planogramColumnController::$onInit');
            // self.unregister = self.planogram.register(self);
        };

        self.$onDestroy = function $onDestroy() {
            // $log.debug('planogramColumnController::$onDestroy');
            // self.unregister();
        };

        self.register = function registerModule(module) {
            $log.debug('planogramColumnController::register %o', module);
            self.modules.push(module);
            return function unregister() {
                $log.debug('planogramColumnController::unregister %o', module);
                var id = module.getId();
                self.modules = self.modules.filter(function (item) {
                    return item.getId() !== id;
                });
            };
        };

        self.getId = function getId() {
            return self.model.id;
        };

        self.onClickHandler = function onClickHandler($event) {
            $log.debug('planogramColumnController::onClickHandler %o', $event);
            $event.preventDefault();
            $event.stopPropagation();
            if (!dnDService.isOnDrag()) {
                $event.stopPropagation();
                self.planogram.raiseOnClick({
                    $event: $event,
                    planogram: self.planogram.getModel(),
                    column: self.getModel()
                });
            }
        }

        self.getModel = function getModel() {
            // return angular.copy(self.model);
            return self.model.$$original;
        };

        self.showColumnId = function showColumnId() {
            var ret = self.planogram.showColumnId;
            return ret;
        }

        self.editOrder = function () {
            swal({
                title: "Nuevo Orden",
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                inputPlaceholder: "orden"
            }, function (inputValue) {
                if (inputValue === false) return false;
                var planogram = self.planogram.model.$$original;
                if (isNaN(inputValue) || inputValue < 1 || inputValue > planogram.columns.length) {
                    swal.showInputError("Invalido");
                    return false
                }
                if (inputValue == self.model.$$original.order)
                {
                    swal.showInputError("ya tiene ese orden");
                    return false
                }

                var oldOrder = self.model.$$original.order;

                var thisColumn = planogram.columns.find(function (c) {
                    return c.order == oldOrder;
                });

                var otherColumn = planogram.columns.find(function (c) {
                    return c.order == parseInt(inputValue);
                });

                thisColumn.order = parseInt(inputValue);
                otherColumn.order = oldOrder;

                self.planogram.calculate(self.planogram.data);


                swal("Orden Cambiado", "Se cambio el " + oldOrder + " por el " + inputValue, "success");
            });
        }
    }
})();