angular.module("prisma")
    .controller("AssortmentCategorySummaryCtrl", function ($rootScope, $state, $stateParams, $timeout, $interval, $filter, assortmentService, authService, companyJson, $translate) {

        var self = this;
        var dic = {};
        var numberColWidth = 100;
        var editableColWidth = 140;
        var lastAdded = null;
        var columnDefs = null;

        self.hasPermissionToEndorse = authService.hasPermission('assortment_endorse');

        self.paretos = [];
        self.clusterFilteringText = "Filtrar por Cluster";
        self.allClusters = [{ id: 0, code: 'Todos', name: 'Todos', companyId: 1, }];
        self.selectedClusterId = null;
        self.hideSpaces = companyJson.AssortmentDecisions && companyJson.AssortmentDecisions.Grid && companyJson.AssortmentDecisions.Grid.HideSpaces;

        self.pareto = null;
        self.gridOptions = null;
        self.categoryLevel = 3;

        self.chartViewAll = function (viewMore) {
            self.maxBarsToDisplay = viewMore ? 150 : 10;
            var data = buildChartData(self.paretos);
            renderBarChart(data);
        }

        self.chartChangeDimension = function (newDimension) {
            if (newDimension != dimension) {
                dimension = newDimension;
                var data = buildChartData(self.paretos);
                renderBarChart(data);
            }
        }

        //grid config
        function setColumnDefinitions() {
            columnDefs = [
                {
                    headerName: translations.CategoryCluster,
                    width: numberColWidth + 200,
                    pinned: 'left',
                    field: 'name',
                    suppressMenu: true,
                    suppressSorting: true,
                    filter: false,
                    cellRenderer: {
                        renderer: 'group',
                        checkbox: false,
                        innerRenderer: function (params) {
                            var name = params.value + (params.node.group && params.node.children != null ? ' (' + params.node.children.length + ')' : '');

                            var allowCategoryNavigation = companyJson.AssortmentDecisions.SupportAnalysisFromCategoryLevel < 5;

                            var canNavigate = params.data.categoryId != 0 && params.data.subcategoryId == 0 && !allowCategoryNavigation ? false : true;

                            if (params.data.categoryId != null && params.data.categoryId != 0 && canNavigate) {

                                var categoryGroup = "'" + params.data.categoryGroup + "'";
                                var category = "'" + params.data.category + "'";
                                var subcategory = "'" + params.data.subcategory + "'";
                                var clusterId = self.selectedClusterId != null ? self.selectedClusterId : params.data.clusterId;

                                var anchor = '<a style="padding: 5px;" title="Click para ingresar a la categoria" ng-click="ct.goToDecisions(' + params.data.categoryId + ',' + params.data.subcategoryId + ',' + categoryGroup + ',' + category + ',' + subcategory + ',' + clusterId + ',' + false + ')">' + name + '</a>';
                                var endorse = true;
                                var endorseLink = '';
                                if (endorse && (params.data.delistingPending > 0 || params.data.listingPending > 0))
                                    endorseLink = '<a style="margin-left:4px;" ng-show="ct.hasPermissionToEndorse" title="Ir a validar" ng-click="ct.goToDecisions(' + params.data.categoryId + ',' + params.data.subcategoryId + ',' + categoryGroup + ',' + category + ',' + subcategory + ',' + clusterId + ',' + true + ')"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>';

                                return endorseLink + anchor;
                            }
                            else
                                return name;
                        }
                    }
                },
                {
                    headerName: translations.CategoryCluster,
                    width: numberColWidth + 200,
                    pinned: 'left',
                    field: 'name',
                    hide: true,
                    suppressMenu: true,
                    suppressSorting: true,
                    filter: false,
                    cellRenderer: {
                        renderer: 'group',
                        checkbox: false,
                        innerRenderer: function (params) {
                            return params.value;
                        }
                    }
                },
                {
                    headerName: "SKUs",
                    field: "totalActiveSKUs",
                    filter: 'number',
                    headerTooltip: translations.SKUsTooltip,
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell
                },
                {
                    headerName: "% SKUs",
                    columnGroupShow: 'open',
                    field: "skUsShare",
                    filter: 'number',
                    headerTooltip: "Share de SKUs",
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: $rootScope.percentageRenderer
                },
                {
                    headerName: translations.Suggestions,
                    children: [
                        {
                            headerName: "Bajas",
                            field: "delistingSuggestions",
                            filter: 'number',
                            headerTooltip: translations.AmountSuggestions,
                            width: 50,
                            suppressMenu: true,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.integerRenderer
                        },
                        {
                            headerName: translations.HighF,
                            field: "listingSuggestions",
                            filter: 'number',
                            headerTooltip: translations.NumberHighSuggestions,
                            width: 50,
                            suppressMenu: true,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.integerRenderer
                        },
                        {
                            headerName: translations.Impact,
                            field: "impact",
                            filter: 'number',
                            headerTooltip: translations.ImpactInDolar,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForImpactCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        //{
                        //    headerName: "Final",
                        //    columnGroupShow: 'open',
                        //    valueGetter: 'data.activeSKUs - data.delistingSuggestions + data.listingSuggestions',
                        //    filter: 'number',
                        //    headerTooltip: "Cantidad de SKUs activos",
                        //    width: numberColWidth,
                        //    cellClass: $rootScope.getClassForCell
                        //},
                    ]
                },
                {
                    headerName: translations.Pending,
                    children: [
                        {
                            headerName: translations.Falls,
                            field: "delistingPending",
                            filter: 'number',
                            headerTooltip: translations.PendindFalls,
                            width: 50,
                            suppressMenu: true,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.integerRenderer
                        },
                        {
                            headerName: translations.HighF,
                            field: "listingPending",
                            filter: 'number',
                            headerTooltip: translations.HighSlopes,
                            width: 50,
                            suppressMenu: true,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.integerRenderer
                        },
                        {
                            headerName: translations.Impact,
                            field: "pendingImpact",
                            filter: 'number',
                            headerTooltip: translations.ImpactByDecisionsByCluster,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForImpactCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        }
                    ]
                },
                {
                    headerName: translations.InternalPerformance,
                    children: [
                        {
                            headerName: translations.SalesInDollars,
                            field: "sales",
                            filter: 'number',
                            headerTooltip: translations.InternalPerformanceTooltip,
                            width: numberColWidth + 10,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: translations.SalePercentage,
                            field: "salesShare",
                            filter: 'number',
                            width: numberColWidth + 35,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererNoDecimals
                        },
                        {
                            headerName: translations.SalesUn,
                            columnGroupShow: 'open',
                            field: "units",
                            filter: 'number',
                            headerTooltip: translations.SaleUnTooltip,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.integerRenderer
                        },
                        {
                            headerName: translations.UnitsPercentage,
                            columnGroupShow: 'open',
                            field: "unitsShare",
                            filter: 'number',
                            headerTooltip: translations.ShareOfSalesInUnits,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererNoDecimals
                        },
                        {
                            headerName: translations.MarginInDollars,
                            columnGroupShow: 'open',
                            field: "margin",
                            filter: 'number',
                            headerTooltip: "",
                            width: numberColWidth + 10,
                            cellClass: $rootScope.getClassForImpactCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: translations.PercentageMargin,
                            columnGroupShow: 'open',
                            field: "marginShare",
                            filter: 'number',
                            headerTooltip: translations.MarginShare,
                            width: numberColWidth + 25,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererNoDecimals
                        },
                        {
                            headerName: translations.PercentageMargin2,
                            columnGroupShow: 'open',
                            field: "marginP",
                            filter: 'number',
                            headerTooltip: "",
                            width: numberColWidth + 25,
                            cellClass: $rootScope.getClassForImpactCell,
                            cellRenderer: $rootScope.percentageRendererNoDecimals
                        },
                        //{
                        //    headerName: "Margen 2 $",
                        //    field: "margin2",
                        //    filter: 'number',
                        //    headerTooltip: "Margen 2",
                        //    width: numberColWidth + 15,
                        //    cellClass: $rootScope.getClassForCell,
                        //    cellRenderer: $rootScope.moneyRendererNoDecimals
                        //},
                        //{
                        //    headerName: "% Margen 2 $",
                        //    field: "margin2Share",
                        //    columnGroupShow: 'open',
                        //    headerTooltip: "Share de Margen 2 $",
                        //    filter: 'number',
                        //    width: numberColWidth + 25,
                        //    cellClass: $rootScope.getClassForCell,
                        //    cellRenderer: $rootScope.percentageRenderer
                        //},
                        {
                            headerName: translations.AveragePrice,
                            columnGroupShow: 'open',
                            field: "price",
                            filter: 'number',
                            headerTooltip: "",
                            width: numberColWidth + 30,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRenderer
                        },
                        {
                            headerName: translations.SaleXsku,
                            columnGroupShow: 'open',
                            field: "hurdleRate",
                            filter: 'number',
                            headerTooltip: "",
                            width: numberColWidth + 10,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: translations.Rotation,
                            columnGroupShow: 'open',
                            field: "turnsC",
                            filter: 'number',
                            headerTooltip: "",
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.decimalRenderer
                        }]
                },
                {
                    headerName: translations.PerformanceMarket,
                    children: [
                        {
                            headerName: translations.Coverage,
                            field: "coverage",
                            filter: 'number',
                            headerTooltip: translations.AssortmentCoverage,
                            width: numberColWidth + 65,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererNoDecimals
                        },
                        {
                            headerName: translations.SalesInDollars,
                            columnGroupShow: 'open',
                            field: "marketSales",
                            filter: 'number',
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: translations.MarketSalePercentage,
                            columnGroupShow: 'open',
                            field: "marketSalesShare",
                            filter: 'number',
                            headerTooltip: translations.ShareOfSalesInTheMarket,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererNoDecimals
                        },
                        {
                            headerName: translations.GAPSale,
                            columnGroupShow: 'open',
                            field: "salesGap",
                            filter: 'number',
                            headerTooltip: translations.GAPSaleTooltip,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForImpactCell,
                            cellRenderer: $rootScope.percentageRendererPP
                        },
                        {
                            headerName: translations.SalesUn,
                            columnGroupShow: 'open',
                            field: "marketUnits",
                            filter: 'number',
                            headerTooltip: translations.SaleInUnitsTooltip2,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.integerRenderer
                        },
                        {
                            headerName: translations.MarketUnitsPercentage,
                            columnGroupShow: 'open',
                            field: "marketUnitsShare",
                            filter: 'number',
                            headerTooltip: translations.MarketUnitsPercentageTooltip,
                            width: numberColWidth + 20,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererNoDecimals
                        },
                        {
                            headerName: translations.GAPUnits,
                            columnGroupShow: 'open',
                            field: "unitsGap",
                            filter: 'number',
                            headerTooltip: translations.GAPUnitsTooltip,
                            width: numberColWidth + 25,
                            cellClass: $rootScope.getClassForImpactCell,
                            cellRenderer: $rootScope.percentageRendererPP
                        },
                        {
                            headerName: translations.AveragePrice,
                            columnGroupShow: 'open',
                            field: "marketPrice",
                            filter: 'number',
                            headerTooltip: "",
                            width: numberColWidth + 45,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRenderer
                        }]
                },
                {
                    headerName: translations.Inventory,
                    children: [
                        {
                            headerName: "Stock",
                            field: "inventory",
                            filter: 'number',
                            headerTooltip: translations.InventoryInDolar,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: "% Stock.",
                            columnGroupShow: 'open',
                            field: "inventoryShare",
                            filter: 'number',
                            headerTooltip: translations.ShareOfInventoryValue,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererNoDecimals
                        },
                        {
                            headerName: "Stock Un",
                            columnGroupShow: 'open',
                            field: "inventoryUnits",
                            filter: 'number',
                            headerTooltip: translations.InventoryInUnits,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.integerRenderer
                        },
                        {
                            headerName: "GMROI",
                            columnGroupShow: 'open',
                            field: "gmroi",
                            filter: 'number',
                            headerTooltip: translations.GMROITooltip,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRenderer
                        }
                    ]
                },
                {
                    headerName: translations.Spaces,
                    children: [
                        {
                            headerName: translations.Mlinear,
                            field: "linearSpace",
                            filter: 'number',
                            headerTooltip: translations.MlinearTooltip,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.decimalRenderer,
                            hide: self.hideSpaces
                        },
                        {
                            headerName: translations.Facings,
                            field: "spaceFronts",
                            filter: 'number',
                            headerTooltip: translations.ProductFronts,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.decimalRenderer,
                            hide: self.hideSpaces
                        },
                        {
                            headerName: "GMROS",
                            columnGroupShow: 'open',
                            field: "gmros",
                            filter: 'number',
                            headerTooltip: translations.GMROSTooltip,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRenderer,
                            hide: self.hideSpaces
                        },
                        {
                            headerName: translations.PercentageSpace,
                            columnGroupShow: 'open',
                            field: "spaceShare",
                            filter: 'number',
                            headerTooltip: translations.PercentageSpaceTooltip,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererNoDecimals,
                            hide: self.hideSpaces
                        }

                    ]
                },
                {
                    headerName: translations.Reviews,
                    children: [
                        {
                            headerName: translations.Revised,
                            width: numberColWidth,
                            field: 'isChecked',
                            cellClass: getClassForRevision,
                            cellRenderer: revisionNeededRenderer
                        },
                        {
                            headerName: translations.LastRevision,
                            width: numberColWidth + 25,
                            field: 'rangeReviewLastRevision',
                            cellClass: self.getClassForCell,
                            cellRenderer: lastRevisionRenderer
                        }
                    ]
                }
            ]
        }

        function setGridOptions() {

            self.gridOptions = {
                columnDefs: columnDefs,
                enableColResize: true,
                enableSorting: true,
                enableFilter: false,
                groupHeaders: false,
                suppressCellSelection: true,
                rowHeight: 35,
                groupHideGroupColumns: true,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                angularCompileRows: true,
                getNodeChildDetails: getNodeChildDetails,
                rowGroupOpened: rowGroupOpened,
                enableFilter: true,
                doesExternalFilterPass: doesExternalFilterPass,
                isExternalFilterPresent: isExternalFilterPresent
            }
        }

        self.goToDecisions = function (categoryId, subcategoryId, categoryGroup, category, subcategory, clusterId, isEndorse) {

            categoryGroup = categoryGroup.replace(/ /g, "-").replace(/\//g, "_");
            category = category.replace(/ /g, "-").replace(/\//g, "_");
            subcategory = subcategory.replace(/ /g, "-").replace(/\//g, "_");

            var encodedcategoryGroup = categoryGroup != 'null' ? encodeURIComponent(categoryGroup) : '';
            var encodedcategory = category != 'null' ? encodeURIComponent(category) : '';
            var encodedsubcategory = subcategory != 'null' ? encodeURIComponent(subcategory) : '';

            $state.go('assortment.decisions', { categoryId: categoryId, subcategoryId: subcategoryId, categoryGroup: encodedcategoryGroup, category: encodedcategory, subcategory: encodedsubcategory, clusterId: clusterId, isEndorse: isEndorse });
        }

        self.exportToExcel = function () {
            var params = {
                skipHeader: false,
                skipFooters: false,
                skipGroups: false,
                allColumns: true,
                onlySelected: false,
                suppressQuotes: false,
                fileName: 'Prisma-CategorySummary.xls',
                columnSeparator: ','
            };

            self.gridOptions.api.exportDataAsCsv(params);
        }

        function rowGroupOpened(rowItem) {
            console.log(rowItem);
        }

        function getNodeChildDetails(rowItem) {
            //console.log(rowItem);
            if (rowItem.subcategories && rowItem.subcategories.length > 0) {
                return {
                    group: true,
                    expanded: false,
                    // provide ag-Grid with the children of this group
                    children: rowItem.subcategories,
                    // the key is used by the default group cellRenderer
                    key: rowItem.subcategory != null ? rowItem.subcategory : ''
                };
            }
            else {
                return null;
            }
        }


        function getClassForRevision(params) {
            var cls = [];

            if (params.data.isChecked == false) {
                cls.push("text-danger");
                cls.push("b");
            }
            else {
                cls.push("text-ok");
            }

            var baseClass = $rootScope.getClassForCell(params);
            for (var i = 0; i < baseClass.length; i++) {
                cls.push(baseClass[0]);
            }

            return cls;
        }
        function revisionNeededRenderer(params) {
            if (params.data.clusterId == 0 && (params.data.categoryId != 0 || params.data.subcategoryId != 0)) {
                //Si es el agrupador de categorias se fija que todos esten revisados para poner revisada la categoria
                if (params.data.categoryId != 0 && params.data.subcategoryId == 0 && params.data.subcategories.length > 0) {
                    for (var i = 0; i < params.data.subcategories.length; i++) {
                        if (params.data.subcategories[i].isChecked == false)
                            return "NO";
                    }

                    params.data.isChecked = true;
                    return "SI";
                }
                if (params.value)
                    return "SI";
                else
                    return "NO";
            }
            return "";
        }
        function lastRevisionRenderer(params) {
            if (params.data.categoryId != 0 && params.data.subcategoryId == 0) {
                return $rootScope.dateRenderer(params);
            }
            return "";

        }



        self.externalFilterChanged = function () {
            self.gridOptions.api.onFilterChanged();
        }


        function isExternalFilterPresent() {
            return self.search != null && self.search != '';
        }

        function doesExternalFilterPass(node) {
            if (node.data.categoryGroup.toLowerCase().indexOf(self.search.toLowerCase()) != -1
                || node.data.category.toLowerCase().indexOf(self.search.toLowerCase()) != -1
                || node.data.subcategory.toLowerCase().indexOf(self.search.toLowerCase()) != -1)
                return true;

            return false;
        }


        //grid filter
        self.dropDrowncluster = { isopen: false };
        self.filterByCluster = function (cluster) {

            if (cluster.name == 'Todos') {
                self.selectedClusterId = null;
                self.clusterFilteringText = "Filtrar por Cluster";
                self.gridOptions.api.setQuickFilter();
            }
            else {
                self.selectedClusterId = cluster.id;
                self.gridOptions.api.setQuickFilter(cluster.code);
                self.clusterFilteringText = "Cluster: " + cluster.name;
            }
        }

        function loadData() {
            self.categoryLevel = companyJson.Category.Levels;

            assortmentService.decisions.getCategorySummary(self.categoryLevel)
                .then(function (items) {

                    self.paretos = items;
                    self.gridOptions.api.setRowData(self.paretos);
                    //self.gridOptions.api.sizeColumnsToFit();

                    var data = buildChartData(self.paretos);
                    renderBarChart(data);
                });
        }
        function loadClusters() {

            assortmentService.clusters.getClusters().then(function (clusters) {
                self.allClusters = self.allClusters.concat(clusters);
            });
        }

        self.maxBarsToDisplay = 10;
        var dimension = "units";

        function buildChartData(paretos) {
            var flare = { name: 'flare', children: [] };

            var barCount = 0;
            var otherSize = 0;

            var orderedParetos = _.orderBy(paretos, [dimension], 'desc');

            angular.forEach(orderedParetos, function (paretoCategoryGroup) {

                barCount++;

                if (barCount < self.maxBarsToDisplay) {
                    var categoryGroup = { name: paretoCategoryGroup.name, size: paretoCategoryGroup[dimension], children: [] };
                    flare.children.push(categoryGroup);

                    angular.forEach(paretoCategoryGroup.subcategories, function (paretoCategory) {
                        var category = { name: paretoCategory.name, size: paretoCategory[dimension], children: [] };
                        categoryGroup.children.push(category);

                        angular.forEach(paretoCategory.subcategories, function (paretoSubcategory) {
                            var subcategory = { name: paretoSubcategory.name, size: paretoSubcategory[dimension], children: [] };
                            category.children.push(subcategory);

                            angular.forEach(paretoSubcategory.subcategories, function (paretoCluster) {
                                var cluster = { name: paretoCluster.name, size: paretoCluster[dimension], children: [] };
                                subcategory.children.push(cluster);
                            });
                        });
                    });
                }
                else {
                    otherSize += paretoCategoryGroup[dimension]
                }
            });

            if (otherSize > 0) {
                var others = { name: "Otras", size: otherSize, children: [] };
                flare.children.push(others);
            }

            return flare;
        }

        function renderBarChart(data) {
            var m = [80, 160, 0, 160], // top right bottom left
                w = 1000, // - m[1] - m[3], // width
                h = 570, // - m[0] - m[2], // height
                x = d3.scale.linear().range([0, w - 100]),
                y = 15, // bar height
                z = d3.scale.ordinal().range(["steelblue", "#aaa"]); // bar color

            var hierarchy = d3.layout.partition()
                .value(function (d) { return d.size; });

            var xAxis = d3.svg.axis()
                .scale(x)
                .orient("top");

            d3.select("div.svg-container").remove();

            var svg = d3.select("div#bars")
                .append("div")
                .classed("svg-container", true) //container class to make it responsive
                .append("svg")
                //responsive SVG needs these 2 attributes and no width and height attr
                .attr("preserveAspectRatio", "xMinYMin meet")
                .attr("viewBox", "0 0 " + w + " " + h)
                //class to make it responsive
                .classed("svg-content-responsive", true)
                //.attr("width", w + m[1] + m[3])
                //.attr("height", h + m[0] + m[2])
                .append("svg:g")
                .attr("transform", "translate(" + 140 + "," + 20 + ")");

            svg.append("svg:rect")
                .attr("class", "background")
                .attr("width", w)
                .attr("height", h)
                .on("click", up);

            svg.append("svg:g")
                .attr("class", "x axis");

            svg.append("svg:g")
                .attr("class", "y axis")
                .append("svg:line")
                .attr("y1", "100%");

            //fix to show all
            if (self.maxBarsToDisplay > 10)
                d3.select("div.svg-container").style("padding-bottom", "58%");
            else
                d3.select("div.svg-container").style("padding-bottom", "22%");


            //d3.json("flare.json", function (root) {
            //    hierarchy.nodes(root);
            //    x.domain([0, root.value]).nice();
            //    down(root, 0);
            //});

            hierarchy.nodes(data);
            x.domain([0, data.value]).nice();
            down(data, 0);

            function down(d, i) {
                if (!d.children || this.__transition__) return;
                var duration = d3.event && d3.event.altKey ? 7500 : 750,
                    delay = duration / d.children.length;

                // Mark any currently-displayed bars as exiting.
                var exit = svg.selectAll(".enter").attr("class", "exit");

                // Entering nodes immediately obscure the clicked-on bar, so hide it.
                exit.selectAll("rect").filter(function (p) { return p === d; })
                    .style("fill-opacity", 1e-6);

                // Enter the new bars for the clicked-on data.
                // Per above, entering bars are immediately visible.
                var enter = bar(d)
                    .attr("transform", stack(i))
                    .style("opacity", 1);

                // Have the text fade-in, even though the bars are visible.
                // Color the bars as parents; they will fade to children if appropriate.
                enter.select("text").style("fill-opacity", 1e-6);
                enter.select("rect").style("fill", z(true));

                // Update the x-scale domain.
                x.domain([0, d3.max(d.children, function (d) { return d.value; })]).nice();

                // Update the x-axis.
                svg.selectAll(".x.axis").transition()
                    .duration(duration)
                    .call(xAxis);

                // Transition entering bars to their new position.
                var enterTransition = enter.transition()
                    .duration(duration)
                    .delay(function (d, i) { return i * delay; })
                    .attr("transform", function (d, i) { return "translate(0," + y * i * 1.2 + ")"; });

                // Transition entering text.
                enterTransition.select("text").style("fill-opacity", 1);

                // Transition entering rects to the new x-scale.
                enterTransition.select("rect")
                    .attr("width", function (d) { return x(d.value); })
                    .style("fill", function (d) { return z(!!d.children); });

                // Transition exiting bars to fade out.
                var exitTransition = exit.transition()
                    .duration(duration)
                    .style("opacity", 1e-6)
                    .remove();

                // Transition exiting bars to the new x-scale.
                exitTransition.selectAll("rect").attr("width", function (d) { return x(d.value); });

                // Rebind the current node to the background.
                svg.select(".background").data([d]).transition().duration(duration * 2); d.index = i;
            }

            function up(d) {
                if (!d.parent || this.__transition__) return;
                var duration = d3.event && d3.event.altKey ? 7500 : 750,
                    delay = duration / d.children.length;

                // Mark any currently-displayed bars as exiting.
                var exit = svg.selectAll(".enter").attr("class", "exit");

                // Enter the new bars for the clicked-on data's parent.
                var enter = bar(d.parent)
                    .attr("transform", function (d, i) { return "translate(0," + y * i * 1.2 + ")"; })
                    .style("opacity", 1e-6);

                // Color the bars as appropriate.
                // Exiting nodes will obscure the parent bar, so hide it.
                enter.select("rect")
                    .style("fill", function (d) { return z(!!d.children); })
                    .filter(function (p) { return p === d; })
                    .style("fill-opacity", 1e-6);

                // Update the x-scale domain.
                x.domain([0, d3.max(d.parent.children, function (d) { return d.value; })]).nice();

                // Update the x-axis.
                svg.selectAll(".x.axis").transition()
                    .duration(duration * 2)
                    .call(xAxis);

                // Transition entering bars to fade in over the full duration.
                var enterTransition = enter.transition()
                    .duration(duration * 2)
                    .style("opacity", 1);

                // Transition entering rects to the new x-scale.
                // When the entering parent rect is done, make it visible!
                enterTransition.select("rect")
                    .attr("width", function (d) { return x(d.value); })
                    .each("end", function (p) { if (p === d) d3.select(this).style("fill-opacity", null); });

                // Transition exiting bars to the parent's position.
                var exitTransition = exit.selectAll("g").transition()
                    .duration(duration)
                    .delay(function (d, i) { return i * delay; })
                    .attr("transform", stack(d.index));

                // Transition exiting text to fade out.
                exitTransition.select("text")
                    .style("fill-opacity", 1e-6);

                // Transition exiting rects to the new scale and fade to parent color.
                exitTransition.select("rect")
                    .attr("width", function (d) { return x(d.value); })
                    .style("fill", z(true));

                // Remove exiting nodes when the last child has finished transitioning.
                exit.transition().duration(duration * 2).remove();

                // Rebind the current parent to the background.
                svg.select(".background").data([d.parent]).transition().duration(duration * 2);
            }

            // Creates a set of bars for the given data node, at the specified index.
            function bar(d) {
                var bar = svg.insert("svg:g", ".y.axis")
                    .attr("class", "enter")
                    .attr("transform", "translate(0,5)")
                    .selectAll("g")
                    .data(d.children)
                    .enter().append("svg:g")
                    .style("cursor", function (d) { return !d.children ? null : "pointer"; })
                    .on("click", down);

                bar.append("svg:text")
                    .attr("x", -6)
                    .attr("y", y / 2)
                    .attr("dy", ".35em")
                    .attr("text-anchor", "end")
                    .text(function (d) { return d.name; });

                bar.append("svg:rect")
                    .attr("width", function (d) { return x(d.value); })
                    .attr("height", y);

                return bar;
            }

            // A stateful closure for stacking bars horizontally.
            function stack(i) {
                var x0 = 0;
                return function (d) {
                    var tx = "translate(" + x0 + "," + y * i * 1.2 + ")";
                    x0 += x(d.value);
                    return tx;
                };
            }
        }

        var translations = null;

        function init() {

            //init columnDefs and gridOptions
            setGridOptions();

            $translate(["CategoryCluster", "SKUsTooltip", "Suggestions", "AmountSuggestions", "HighF", "NumberHighSuggestions"
                , "Impact", "ImpactInDolar", "Pending", "Falls", "PendindFalls", "HighSlopes", "ImpactByDecisionsByCluster"
                , "InternalPerformance", "InternalPerformanceTooltip", "SalesInDollars", "SalePercentage", "SalesUn"
                , "SaleUnTooltip", "UnitsPercentage", "ShareOfSalesInUnits", "MarginInDollars", "PercentageMargin", "MarginShare"
                , "PercentageMargin2", "AveragePrice", "SaleXsku", "Rotation", "PerformanceMarket", "Coverage", "AssortmentCoverage"
                , "MarketSalePercentage", "ShareOfSalesInTheMarket", "GAPSale", "GAPSaleTooltip", "SaleInUnitsTooltip2", "MarketUnitsPercentage"
                , "MarketUnitsPercentageTooltip", "GAPUnits", "GAPUnitsTooltip", "Inventory", "InventoryInDolar", "ShareOfInventoryValue"
                , "GMROITooltip", "Spaces", "Mlinear", "MlinearTooltip", "Facings", "ProductFronts", "GMROSTooltip", "PercentageSpace"
                , "PercentageSpaceTooltip", "Reviews", "Revised", "LastRevision"])
                .then(function (all) {
                    translations = all;

                    setColumnDefinitions();

                    self.gridOptions.api.setColumnDefs(columnDefs);
                });

            loadClusters();

            //load data
            loadData();
        }

        init();
    });

