angular.module("prisma")
    .controller("AssortmentClusterHierarchyCtrl", function ($rootScope, $state, $stateParams, $timeout, $filter, assortmentService, authService, companyJson) {

        var self = this;
        var numberColWidth = 100;
        var editableColWidth = 140;
        var columnDefs = null;
        self.hasPermission = authService.hasPermission('assortment_clusterHierarchy');

        self.paretos = [];
        self.pareto = null;
        self.gridOptions = null;
        self.categoryLevel = self.categoryLevel = companyJson.Category.Levels;


        //grid config
        function setColumnDefinitions() {
            columnDefs = [
                {
                    headerName: 'Categoria / Cluster',
                    width: numberColWidth + 200,
                    pinned: 'left',
                    field: 'name',
                    suppressMenu: true,
                    suppressSorting: true,
                    filter: false,
                    cellRenderer: {
                        renderer: 'group',
                        checkbox: false,
                        innerRenderer: function (params) {
                            var name = params.value + (params.node.group && params.node.children != null ? ' (' + params.node.children.length + ')' : '');

                            if (params.node.data.majorId || params.node.group) //Cluster Menores o Categoria
                                return '<span>' + name + '</span>';
                            else //Cluster Mayor
                                return '<span><b>' + name + '</b></span>';

                        }
                    }
                },
                {
                    headerName: "SKUs",
                    field: "activeSKUs",
                    filter: 'number',
                    headerTooltip: "Cantidad de SKUs activos",
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell
                },
                {
                    headerName: "Dif % Vs Mayor",
                    field: "diffWithMajorP",
                    filter: 'number',
                    headerTooltip: "Diferencia en % vs Cluster Mayor",
                    width: numberColWidth + 100,
                    cellClass: $rootScope.getClassForHierarchyPCell,
                    cellRenderer: $rootScope.percentageRenderer
                },
                {
                    headerName: "Dif # Vs Mayor",
                    field: "diffWithMajor",
                    filter: 'number',
                    headerTooltip: "Diferencia en Cantidad vs Cluster Mayor",
                    width: numberColWidth + 100,
                    cellClass: $rootScope.getClassForHierarchyCell,
                },
                {
                    headerName: "Dif % Vs Anterior",
                    field: "diffWithPreviousP",
                    filter: 'number',
                    headerTooltip: "Diferencia en % vs Cluster Mayor",
                    width: numberColWidth + 100,
                    cellClass: $rootScope.getClassForHierarchyPCell,
                    cellRenderer: $rootScope.percentageRenderer
                },
                {
                    headerName: "Dif # Vs Anterior",
                    field: "diffWithPrevious",
                    filter: 'number',
                    headerTooltip: "Diferencia en Cantidad vs Cluster Mayor",
                    width: numberColWidth + 100,
                    cellClass: $rootScope.getClassForHierarchyCell,
                },
                {
                    headerName: "% SKUs",
                    columnGroupShow: 'open',
                    field: "skUsShare",
                    filter: 'number',
                    headerTooltip: "Share de SKUs",
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: $rootScope.percentageRenderer
                }
            ]
        }

        function setGridOptions() {

            setColumnDefinitions();

            self.gridOptions = {
                columnDefs: columnDefs,
                enableColResize: true,
                enableSorting: true,
                enableFilter: false,
                groupHeaders: false,
                suppressCellSelection: true,
                rowHeight: 35,
                groupHideGroupColumns: true,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                angularCompileRows: true,
                getNodeChildDetails: getNodeChildDetails,
                //rowGroupOpened: rowGroupOpened,
                enableFilter: true,
            }
        }

        self.exportToExcel = function () {
            var params = {
                skipHeader: false,
                skipFooters: false,
                skipGroups: false,
                allColumns: true,
                onlySelected: false,
                suppressQuotes: false,
                fileName: 'Prisma-CategorySummary.xls',
                columnSeparator: ','
            };

            self.gridOptions.api.exportDataAsCsv(params);
        }

        //function rowGroupOpened(rowItem) {
        //    console.log(rowItem);
        //}

        function getNodeChildDetails(rowItem) {
            //console.log(rowItem);
            if (rowItem.subcategories && rowItem.subcategories.length > 0) {
                return {
                    group: true,
                    expanded: false,
                    // provide ag-Grid with the children of this group
                    children: rowItem.subcategories,
                    // the key is used by the default group cellRenderer
                    key: rowItem.subcategory != null ? rowItem.subcategory : ''
                };
            }
            else {
                return null;
            }
        }

        function loadData() {

            assortmentService.reports.getClusterHierarchy(self.categoryLevel)
                .then(function (items) {
                    self.paretos = items;
                    self.gridOptions.api.setRowData(self.paretos);
                });
        }

        function init() {

            //init columnDefs and gridOptions
            setGridOptions();

            //load data
            loadData();
        }

        init();
    });

