'use strinct';
(function () {
    angular.module('prisma')
        .directive('planogramModulePeg', ['$log', planogramModulePegDirective])
        .controller('planogramModulePegController', ['$log', 'DnDService', planogramModulePegController]);

    function planogramModulePegDirective($log) {
        // $log.debug('planogramModulePegDirective::ctor');
        return {
            restrict: 'A',
            selector: '[planogram-module-peg]',
            templateUrl: '/app/spaces/planogram/planogram-module-peg.view.html',
            scope: {
                model: '<'
            },
            require: {
                planogram: '^^planogram',
                column: '^^planogramColumn'
            },
            controller: 'planogramModulePegController',
            controllerAs: '$ctrl',
            bindToController: true
        }
    }

    function planogramModulePegController($log, dnDService) {
        // $log.debug('planogramModulePegController::ctor');
        var self = this;
        self.levels = [];

        self.$onInit = function () {
            // $log.debug('planogramModulePegController::$onInit');
            // self.unregister = self.column.register(self);
        }

        self.$onDestroy = function () {
            // $log.debug('planogramModulePegController::$onDestroy');
            // self.unregister();
            delete self.levels;
        }

        self.register = function (level) {
            //$log.debug('planogramModulePegController::register %o', level);
            self.levels.push(level);
            return function unregister() {
                //$log.debug('planogramModulePegController::unregister %o', level);
                var id = level.getId();
                self.levels = self.levels.filter(function (item) {
                    return item.getId() !== id;
                });
            }
        }

        self.getId = function getId() {
            return self.model.id;
        };

        self.getModel = function getModel() {
            // return angular.copy(self.model);
            return self.model.$$original;
        };

        self.getTransform = function getTransform() {
            var ret = 'translate( 0 0 )';
            return ret;
        };

        self.getModuleHeight = function getModuleHeight() {
            var ret = self.model.height;
            return ret;
        };

        self.hasGrid = function hasGrid() {
            var ret = self.model.gridWidth > 0
                && self.model.gridHeight > 0;
            return ret;
        }

        self.onClickHandler = function onClickHandler($event) {
            //$log.debug('planogramModulePegController::onClickHandler %o', $event);
            $event.preventDefault();
            $event.stopPropagation();

            if (!dnDService.isOnDrag()) {
                $event.stopPropagation();
                self.planogram.raiseOnClick({
                    $event: $event,
                    planogram: self.planogram.getModel(),
                    column: self.column.getModel(),
                    module: self.getModel()
                });
            }
        }
    }
})();