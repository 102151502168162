angular.module("prisma")
    .controller("CreatePlanogramCtrl", function ($scope, $stateParams, $state, $translate, $timeout, spaceService, adminService, authService, Upload, serverService, DnDService, ngDialog) {
        //Varibles
        var self = this;
        self.spaces_edit_planograms = authService.hasPermission('spaces_edit_planograms');
        self.planogram = null;
        self.oldPlanogram = null;//almacena el planograma orignial cuando se selecciona un modulo
        self.isLoading = true;
        self.isBusy = false;
        self.categories = [];
        self.selectedCategories = [];
        self.subCategories = [];
        self.stores = [];
        self.showElements = true;
        self.showDoor = true;
        self.selectedPlanogram = null;
        self.files = null;
        self.isEditPlanogram = true;
        self.showMassiveLoad = false;
        self.showlevelmeasurebuttons = false;

        /*KRC*/
        //Habilita o desHabilita StackedWithPrev
        self.__columnStackedWithPrev_enabled = true;

        //button Collapsed
        self.isNewLevelPlanogramCollapsed = false;
        self.isSizesPlanogramPegCollapsed = false;
        self.isDimensionsCollapsed = false;


        self.spaceModuleType = {
            1: 'Shelf',
            2: 'Peg',
            3: 'Refrigerator'
        }
        self.stateChange = true;
        self.showLevelButtonAction = false;
        function generateNewPlanogram() {
            return {
                id: 0,
                name: '',
                code: '',
                stores: [],
                subCategories: [],
                orientationRightToLeft: true,
                width: 1,
                height: 1,
                depth: 100,
                columns: [
                    {
                        order: 1,
                        width: 1,
                        modules: []
                    }
                ],
            };
        }

        self.onCategorySelected = function (category) {
            self.subCategories = [];
            angular.forEach(self.selectedCategories, function (category) {
                angular.forEach(category.subcategories, function (subcategory) {
                    self.subCategories.push(subcategory);
                });
            });
        }

        self.onEnterStoreCodes = function () {
            var separators = [' ', '\n'];
            var delimitedCodes = self.storeCodes.split(new RegExp(separators.join('|'), 'g'));
            var trimedCodes = delimitedCodes.map(function (s) { return s.trim(); });

            angular.forEach(self.stores, function (store) {
                if (trimedCodes.includes(store.code) && !self.planogram.stores.map(function (s) { return s.code; }).includes(store.code))
                    self.planogram.stores.push(store);
            });
            self.showMassiveLoad = false;
            self.storeCodes = '';
        }

        function isValidField(field) {
            if (field == undefined || field == null || field == '')
                return false;

            return true;
        }

        self.saveNewEditPlanogram = function () {

            //Validamos que tenga todos los datos
            if (!isValidField(self.planogram.name)
                || !isValidField(self.planogram.code)
                || self.planogram.stores.length == 0
                || self.planogram.subCategories.length == 0
                || self.planogram.columns.length == 0
                || self.planogram.columns[0].modules.length == 0) {
                self.isSaving = false;

                setTimeout(function () {
                    swal(translations.ValidationSwalTitle, translations.ValidationToSaveAplanogram, 'error');
                }, 20)
                self.isBusy = false;
                return;
            }


            swal({
                title: translations.SavePlanogramSwalTitle,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        if (!self.isSaving) {
                            self.isSaving = true;
                            self.isBusy = true;

                            var data = {}
                            if (self.isEditPlanogram) {
                                data = self.planogram;
                            } else {
                                data = {
                                    id: $stateParams.planogramId,
                                    name: self.planogram.name,
                                    code: self.planogram.code,
                                    width: self.planogram.width,
                                    depth: self.planogram.depth,
                                    height: self.planogram.height,
                                    stores: self.planogram.stores,
                                    //clusters: self.planogram.clusters,
                                    subCategories: self.planogram.subCategories,
                                    //discriminator: self.planogram.discriminator,
                                    //clusterId: self.planogram.cluster ? self.planogram.cluster.id : null,
                                    category: self.planogram.category,
                                    categoryName: self.planogram.category ? self.planogram.category.name : null,
                                    categoryId: self.planogram.category ? self.planogram.category.id : null,
                                    orientationRightToLeft: self.planogram.orientationRightToLeft,
                                    columns: self.planogram.columns,
                                }
                            }

                            spaceService.planograms.savePlanogram(data).then(function (res) {
                                self.isBusy = false;
                                pageLoaded = true;
                                self.goBack();
                                swal(translations.SavedPlanogramSwal, translations.SavedPlanogramaSwalSubtitle, 'success');

                            }).catch(function (error) {
                                swal(translations.ErrorTitleSwal, translations.ErrorWhenSaveThePlanogram, 'error');
                            });

                            self.isSaving = false;

                            self.stateChange = false;
                        }
                    }
                });
        }

        self.showAssignStoresDialog = function () {

            //marco las tiendas que el usuario ya tiene
            angular.forEach(self.stores, function (store) {
                angular.forEach(self.layout.stores, function (layoutStore) {
                    if (store.id == layoutStore.id)
                        store.selected = true;
                    //store.selected = self.layout.stores.indexOf(store.id) != -1 ? true : false;

                })
            });

            self.searchStore = '';
        }

        self.goBack = function () {
            if ($stateParams.planogramId == 0)
                $state.go('spaces.planograms');
            else $state.go('spaces.editPlanogram', {
                planogramId: $stateParams.planogramId,
                itemId: '0',
                clusterId: '0'
            });


        }

        $scope.$on('$stateChangeStart', function (event) {
            //console.log('$stateChangeStart', event)
            if (self.stateChange) {
                var answer = confirm(translations.AlertWillLoseChanges)
                if (!answer) {
                    event.preventDefault();
                }
            }
        });




        self.changeZoom = function (increase) {
            if (increase)
                self.zoom += 20
            else if (self.zoom >= 105)
                self.zoom -= 20;
        }

        self.centerZoom = function () {
            self.zoom += 5;

            $timeout(() => self.zoom = 100, 0);

        }

        self.setZoom = function (percentage) {
            self.zoom = percentage;
        }

        //COMPONENTE VIEJO
        self.showListElements = function () {
            self.showElements = true;
            self.models.selected = null;
        }

        //COMPONENTE VIEJO
        self.changeWidth = 0;
        self.setModuleWidth = function (changeWidth) {
            if (changeWidth < (Math.ceil(self.models.selected.minWidth) + 2)) {
                self.changeWidth = (Math.ceil(self.models.selected.minWidth) + 2);
                swal(translations.WidthNotAllowed, translations.WidthNotAllowedSubtitle + (Math.ceil(self.models.selected.minWidth) + 2), 'warning');
                self.models.selected.width = (Math.ceil(self.models.selected.minWidth) + 2);
            } else {
                self.models.selected.width = changeWidth;
            }
        }

        self.newLevel = {
            order: 'up'
        }

        //CAMBIAR EL MODELS.SELECTED
        self.addLevelToNewPlanogram = function () {
            if (self.newLevel.height) {
                if (self.newLevel.order == 'up') {
                    self.models.selected.levels.push({
                        categoryId: null,
                        categoryName: "",
                        height: self.newLevel.height,
                        id: 0,
                        itemPadding: 1,
                        items: [],
                        //levelNumber: self.newLevel.levelNumber,
                    });

                } else {
                    self.models.selected.levels.unshift({
                        categoryId: null,
                        categoryName: "",
                        height: self.newLevel.height,
                        id: 0,
                        itemPadding: 1,
                        items: [],
                        //levelNumber: self.newLevel.levelNumber,
                    });

                }

                self.newLevel.height = "";
                var suma = 0
                angular.forEach(self.models.selected.levels, function (level, i) {
                    level.levelNumber = i + 1;
                    suma += level.height
                })
                self.models.selected.height = suma;
                self.planogram.columns[0].modules[0] = self.models.selected;
                self.viewAllModules();
            }
        };

        //TODO hacer version nuevo componente
        self.copyModule = function () {
            //1- Copia la columna con su modulo
            var newColumn = self.planogram.columns[0];
            self.planogram = angular.copy(self.oldPlanogram);

            //2- Agrega la columna nueva al final
            newColumn.modules[0].id = 0;
            newColumn.modules[0].levels.forEach(function (x) {
                x.items = [];
                x.id = 0;
                x.moduleId = 0;
            });

            newColumn.order = self.planogram.columns.length + 1;
            newColumn.id = 0;

            self.planogram.columns.push(newColumn);

            updateSizePlanogram(self.planogram);

            //3- Fuerza una nueva referencia del planograma para que se detecte el cambio en los componentes.
            self.planogram = angular.copy(self.planogram);

            //4- Cambia lasvariables para cambiar el modo vista
            self.models.selected = null;
            self.showElements = true;
            self.showlevelmeasurebuttons = false;

        }


        self.deleteModule = function () {
            //1- Elimina la columna con su modulo
            var deletedColumn = self.planogram.columns[0];

            if (deletedColumn.modules[0].levels.some(function (x) { return x.items.length; })) {
                swal('Modulo con Items'
                    , 'no se puede eliminar un modulo que contiene items'
                    , 'warning');
                return;
            }

            self.planogram = angular.copy(self.oldPlanogram);

            for (var i = 0; i < self.planogram.columns.length; i++) {
                if (self.planogram.columns[i].order === deletedColumn.order) {
                    self.planogram.columns.splice(i, 1);
                }
            }

            //2- Reorganiza el orden de columnas si es que no se elimino la ultima
            if (self.planogram.columns.length) {

                var nextColumn;
                var i = 1;
                do {

                    nextColumn = self.planogram.columns.find(function (x) {
                        return x.order === deletedColumn.order + i;
                    });

                    if (nextColumn)
                        nextColumn.order--;

                    i++;

                } while (nextColumn);
            }
            else {
                self.planogram.columns = [
                    {
                        order: 1,
                        width: 1,
                        modules: []
                    }
                ];
            }

            updateSizePlanogram(self.planogram);

            //3- Fuerza una nueva referencia del planograma para que se detecte el cambio en los componentes.
            self.planogram = angular.copy(self.planogram);

            //4- Cambia lasvariables para cambiar el modo vista
            self.models.selected = null;
            self.showElements = true;
            self.showlevelmeasurebuttons = false;


        }
        self.showModulesOrder = false;
        self.sortModules = function sortModules() {
            self.showModulesOrder = !self.showModulesOrder;
            self.planogram = angular.copy(self.planogram);
        }

        /**
        * 1- self.planogram vuelve a su origen.
        * 2- aplica los cambios en niveles.
        * 3- Fuerza una nueva referencia del planograma para que se detecte el cambio en los componentes.
        * 4- cambia las variables para cambiar el modo vista.
        */
        self.viewAllModules = function viewAllModules() {

            //1- self.planogram vuelve a su origen
            var changedColumn = self.planogram.columns[0];
            self.planogram = angular.copy(self.oldPlanogram);

            //2- aplica los cambios en niveles

            for (var i = 0; i < self.planogram.columns.length; i++) {
                if (self.planogram.columns[i].order === changedColumn.order) {
                    self.planogram.columns[i] = angular.copy(changedColumn);
                }
            }

            //3- Fuerza una nueva referencia del planograma para que se detecte el cambio en los componentes.
            self.planogram = angular.copy(self.planogram);

            //4- cambia lasvariables para cambiar el modo vista
            self.models.selected = null;
            self.showElements = true;
            self.showlevelmeasurebuttons = false;
        }

        self.getSelectedColumn = function () {
            var selectedColumn = null;
            angular.forEach(self.planogram.columns, function (column, _indexColumn) {
                angular.forEach(column.modules, function (module, _indexModule) {
                    if (module.isSelected) {
                        selectedColumn = column;
                    }
                });

            });

            return selectedColumn;
        }

        self.getSelectedModule = function () {
            var selectedModule = null;

            angular.forEach(self.planogram.columns, function (column, _indexColumn) {
                angular.forEach(column.modules, function (module, _indexModule) {
                    if (module.isSelected) {
                        selectedModule = module;
                    }
                });

            });

            return selectedModule;
        }

        //COMPONENTE VIEJO
        self.showLevelMeasureButtons = function () {
            if (self.selectedPlanogram)
                return true;

            return false;
        }

        //Load Data
        function loadData() {
            Promise.all([
                spaceService.stores.getStores(false).then(function (stores) {
                    self.stores = stores;
                })
                ,
                adminService.categories.getCategoriesWithSubcategories().then(function (categories) {
                    self.categories = categories;
                })
            ]).then(function (result) {

                if (self.isEditPlanogram) {
                    initEditSession();
                }


                if ($stateParams.copyFromPlanogramId !== '0' && !self.isImporting()) {
                    spaceService.planograms.getColumnsFromPlanogram($stateParams.copyFromPlanogramId).then(function (columns) {
                        self.planogram.columns = columns;
                        self.isLoading = false;
                        self.zoom = self.showPlanogramComponentSvg() ? 800 : 100;
                    });
                }
                else {
                    $timeout(function () {
                        self.isLoading = false;
                        self.zoom = self.showPlanogramComponentSvg() ? 800 : 100;
                    }, 0);
                }

            })
        }

        var translations = null;

        function initEditSession() {

            //cargo las categorias que trae el planograma
            var uniqueParents = _.uniq(_.map(self.planogram.subCategories, 'parent'));
            angular.forEach(uniqueParents, function (scParentName) {
                var filteredCategories = self.categories.filter(function (x) {
                    return x.name === scParentName;
                });

                angular.forEach(filteredCategories, function (cat) {
                    self.selectedCategories.push(cat);
                    self.onCategorySelected(cat);
                });

            });

            self.originalCategoryIds = _.uniq(_.map(self.selectedCategories, 'id'));
            self.originalSubcategoryIds = _.uniq(_.map(self.planogram.subCategories, 'id'));
            self.originalStoreIds = _.uniq(_.map(self.planogram.stores, 'id'));

            $scope.$watch('ct.selectedCategories', function (newVal, oldVal) {
                if (newVal.length < oldVal.length) {
                    if (_.difference(self.originalCategoryIds, _.uniq(_.map(newVal, 'id'))) > 0) {
                        swal(translations.ActionNotAllowed, translations.ActionNotAllowedSubtitle, 'error');
                        self.selectedCategories = oldVal;
                        self.onCategorySelected();
                    }
                }
            });
            $scope.$watch('ct.planogram.subCategories', function (newVal, oldVal) {
                if (newVal.length < oldVal.length) {
                    if (_.difference(self.originalSubcategoryIds, _.uniq(_.map(newVal, 'id'))) > 0) {
                        swal(translations.ActionNotAllowed, translations.ActionNotAllowedSubtitle, 'error');
                        self.planogram.subCategories = oldVal;
                    }
                }
            });

            //$scope.$watch('ct.models.selected', function (newVal, oldVal) {
            //    if (newVal != oldVal) {

            //        if (newVal == null) {
            //            self.selectedPlanogram = null;
            //            self.centerZoom();
            //        }
            //        else {

            //            self.selectedPlanogram = self.planogram;
            //            self.setZoom(100 * self.planogram.columns.length);
            //        }

            //    }

            //});
        }



        function init() {
            self.isEditPlanogram = $stateParams.planogramId !== '0' && !self.isImporting();
            $translate(["ModalTitleCreatePlanogram", "ModalTitleEditPlanogram", "SavePlanogramSwalTitle", "ValidationSwalTitle"
                , "ValidationSwalSubtitle", "SavedPlanogramSwal", "SavedPlanogramaSwalSubtitle", "CancelSwal", "ContinueSwal", "AlertWillLoseChanges",
                "ValidationToSaveAplanogram", "ErrorTitleSwal", "ErrorWhenSaveThePlanogram", "WidthNotAllowed", "WidthNotAllowedSubtitle", "ActionNotAllowed",
                "ActionNotAllowedSubtitle", "CanNotDeleteStores", "Shelf", "Refrigerator", "IndividualLoad", "MassiveLoad", "EnterCodesSeparatedByComma", "Apply"])
                .then(function (all) {
                    translations = all;

                    self.models = {
                        selected: null,
                        templates: [
                            {
                                type: 'Shelf',
                                name: translations.Shelf,
                                imageUrl: '/assets/images/spaces/templates/planogram.png',
                                isRefrigerator: false
                            },
                            {
                                type: 'Shelf',
                                name: translations.Refrigerator,
                                imageUrl: '/assets/images/spaces/templates/heladera.png',
                                isRefrigerator: true
                            },
                            {
                                type: 'Peg',
                                name: 'Ganchera',
                                imageUrl: '/assets/images/spaces/templates/gancheras.jpg',
                                isRefrigerator: false
                            }
                        ],
                    };

                });


            if (!self.isImporting()
                && angular.isDefined($stateParams.planogramId)
                && $stateParams.planogramId !== '0') {
                spaceService.planograms.getPlanogramInfo($stateParams.planogramId).then(function (results) {
                    self.planogram = results;
                    angular.forEach(self.planogram.columns,
                        function (column) {
                            angular.forEach(column.modules,
                                function (module) {
                                    module.minWidth = 0;
                                    angular.forEach(module.levels,
                                        function (level) {
                                            level.minHeight = 0;
                                            level.usedWidth = 0;
                                            angular.forEach(level.items,
                                                function (item) {
                                                    level.usedWidth += (item.selectedUnitOfMeasure.width * item.facings
                                                    );
                                                    if ((item.selectedUnitOfMeasure.height * item.stackAbove) >
                                                        level.minHeight)
                                                        level.minHeight = (item.selectedUnitOfMeasure.height *
                                                            item.stackAbove);
                                                });
                                            if (level.usedWidth > module.minWidth) module.minWidth = level.usedWidth;

                                        });
                                    //module.type = self.spaceModuleType[module.type];
                                });
                        });

                    loadData();
                });
            } else {
                self.planogram = generateNewPlanogram();
                loadData();
            }
        }

        /**
         * Devuelve verdadero si hay que mostrar el componente SVG del planograma, falso en caso contrario.
         * Verifica que no se este cargando la pagina y el nombre del estado de la aplicación.
         * @returns Verdadero si hay que mostrar el componente SVG del planograma, falso en caso contrario.
         */
        self.showPlanogramComponentSvg = function showPlanogramComponentSvg() {
            var ret = !self.isLoading
                && ($state.current.name === 'spaces.createPlanogram')
                && self.planogram && self.planogram.columns.length;
            return ret;
        }

        /**
         * Devuelve verdadero si hay que mostrar el componente HTML del planograma, falso en caso contrario.
         * Verifica que no se este cargando la pagina y el nombre del estado de la aplicación.
         * @returns Verdadero si hay que mostrar el componente HTML del planograma, falso en caso contrario.
         */
        self.showPlanogramComponentHtml = function showPlanogramComponentHtml() {
            var ret = !self.isLoading
                && ($state.current.name === 'spaces.createPlanogramOld')
                && self.planogram && self.planogram.columns.length;
            return ret;
        }

        /**
         * Devuelve verdadero si se esta en el proceso para importación de un planograma, falso en caso contrario.
         * @returns Verdadero si se esta en el proceso para importación de un planograma, falso en caso contrario.
         */
        self.isImporting = function isImporting() {
            var ret = $state.current.name === 'spaces.importPlanogram';
            return ret;
        }

        self.saveImportPlanogram = function saveImportPlanogram() {
            //Validamos que tenga todos los datos
            if (self.planogram.stores.length === 0
                || self.planogram.subCategories.length === 0) {
                self.isSaving = false;
                setTimeout(function () {
                    // TODO: Crear los textos para la validación de la importación.
                    swal(translations.ValidationSwalTitle, translations.ValidationToSaveAplanogram, 'error');
                },
                    20);
                self.isBusy = false;
                return;
            }
            swal({
                title: translations.SavePlanogramSwalTitle,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        if (!self.isSaving) {
                            self.isSaving = true;
                            self.isBusy = true;
                            var url = ian.urlCombine(serverService.getApiUrl(), '/space/planogram/import-pln');
                            Upload.upload({
                                url: url,
                                data: {
                                    files: self.files,
                                    categories: self.selectedCategories.map(function (item) {
                                        return item.id;
                                    }),
                                    subCategories: self.planogram.subCategories.map(function (item) {
                                        return item.id;
                                    }),
                                    stores: self.planogram.stores.map(function (item) {
                                        return item.id;
                                    })
                                }
                            }).then(function (res) {
                                //self.planograms = res;
                                self.planogram = generateNewPlanogram();
                                self.isBusy = false;
                                pageLoaded = true;
                                // self.goBack();

                                var type;
                                var message = translations.SavedPlanogramaSwalSubtitle;
                                var id = res.data.id;
                                var warnings = res.data.warnings;
                                var missingItems = res.data.missingItems;
                                var missingDimensions = res.data.missingDimensions;
                                var warningMessages = [];
                                var saveMessageCallback = function () {
                                    $state.go('spaces.editPlanogram',
                                        {
                                            planogramId: id,
                                            itemId: 0,
                                            clusterId: 0
                                        });
                                    self.stateChange = false;
                                }

                                if (warnings.length) {
                                    if (missingItems.length) {
                                        missingItems = _.uniq(missingItems);
                                        warningMessages.push('Faltan ' + missingItems.length + ' productos.');
                                    }

                                    if (missingDimensions.length) {
                                        missingDimensions = _.uniq(missingDimensions);
                                        warningMessages.push('Falta configurar las dimensiones de ' + missingDimensions.length + ' productos');
                                    }

                                    swal.close();
                                    ngDialog.open({
                                        template: 'importPlnExceptionDialog',
                                        className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-450',
                                        closeByEscape: false,
                                        closeByDocument: false,
                                        closeByNavigation: false,
                                        controller: ['$scope', function ($dialogScope) {
                                            $dialogScope.title = translations.SavedPlanogramSwal;
                                            $dialogScope.message = message;
                                            $dialogScope.warningMessages = warningMessages;
                                            $dialogScope.missingItems = missingItems;
                                            $dialogScope.missingDimensions = missingDimensions;
                                            $dialogScope.closeDialog = function () {
                                                $dialogScope.closeThisDialog('');
                                                saveMessageCallback();
                                            };

                                            //$scope.download = function () {

                                            //    var element = document.createElement('a');
                                            //    var content = buildFileContent($scope.url, $scope.message, $scope.stackTrace)
                                            //    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
                                            //    element.setAttribute('download', getFileName());

                                            //    element.style.display = 'none';
                                            //    document.body.appendChild(element);

                                            //    element.click();

                                            //    document.body.removeChild(element);
                                            //}
                                        }]
                                    });

                                } else {
                                    swal({
                                        title: translations.SavedPlanogramSwal,
                                        text: message,
                                        type: 'success'
                                    }, saveMessageCallback);
                                }
                            }).catch(function (err) {
                                var message = translations.ErrorWhenSaveThePlanogram;
                                if (err && err.data && angular.isString(err.data)) {
                                    message += '\n' + err.data;
                                }

                                swal(translations.ErrorTitleSwal,
                                    message,
                                    'error');
                                self.isBusy = false;
                            }).finally(function () {
                                self.isSaving = false;
                            });
                        }
                    }
                });
        }
        //TODO cambiar models.selected
        self.planogramOnClickHandler = function planogramOnClickHandler($event, planogram, column, module) {
            console.log('CreatePlanogramCtrl::planogramOnClickHandler\n\t$event: %o\n\tplanogram: %o\n\tcolumn: %o\n\tmodule: %o',
                $event,
                planogram,
                column,
                module);
            if (self.showlevelmeasurebuttons)
                return;

            var selectedPlanogram = angular.copy(planogram);;
            selectedPlanogram.columns = [column];
            selectedPlanogram.columns[0].modules = [module];

            self.oldPlanogram = angular.copy(self.planogram);
            self.planogram = angular.copy(selectedPlanogram);

            self.models.selected = selectedPlanogram.columns[0].modules[0];

            self.showElements = false;
            self.showlevelmeasurebuttons = true;
        }

        self.onDropHandler = function onDropHandler($event) {
            var data = DnDService.getData();
            console.log("CreatePlanogramCtrl::onDropHandler\n\t$event: %o\n\tdata: %o", $event, data);
            self.newModule = {
                id: 0,
                type: data.type,
                isRefrigerator: data.isRefrigerator,
                width: 0,
                height: 0,
                depth: 0,
                order: 0,
                numLevels: 1,
                levels: []
            };
            self.saveNewModuleSuccessful = false;
            ngDialog.open({
                template: 'modulesDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            }).closePromise.then(function (data) {
                if (!self.saveNewModuleSuccessful)
                    return false;

                createLevels(self.newModule);

                if (self.planogram.columns
                    && self.planogram.columns.length === 1
                    && self.planogram.columns[0].modules.length === 0) {
                    self.planogram.columns[0].modules.push(self.newModule);
                }
                else {
                    var newColumn = {
                        modules: [self.newModule],
                        width: self.newModule.width,
                        order: self.planogram.columns.length + 1
                    };
                    self.planogram.columns.push(newColumn)
                }
                updateSizePlanogram(self.planogram);
                self.planogram = angular.copy(self.planogram);
                return true;
            });


        }

        //TODO: esta funcion no deberia crear los niveles
        function updateSizePlanogram(planogram) {
            planogram.width = 0;
            planogram.height = 0;
            planogram.depth = 0;
            angular.forEach(planogram.columns, function (column) {
                column.width = 0;
                column.height = 0;
                column.depth = 0;
                angular.forEach(column.modules, function (module) {
                    if (module.width > column.width) column.width = module.width;
                    column.height += module.height;
                    if (module.depth > column.depth) column.depth = module.depth;
                })
                if (!column.width) column.width = 1;
                planogram.width += column.width;
                if (column.height > planogram.height) planogram.height = column.height;
                if (column.depth > planogram.depth) planogram.depth = column.depth;
            })

            calcWidthPercentage(planogram);

        }

        function calcWidthPercentage(planogram) {
            angular.forEach(planogram.columns, function (column) {
                //calculo el ancho de cada columna
                column.widthPercentage = column.width / planogram.width * 100;
                //calculo el ancho de cada modulo
                angular.forEach(column.modules, function (_module) {
                    _module.widthPercent = _module.width / column.width * 100;
                })
            })
        }

        self.addNewModule = function () {

            if (self.newModule.numLevels <= 0 || self.newModule.width <= 0 || self.newModule.height <= 0 || self.newModule.depth <= 0) {
                self.validation = true;
            }
            else {
                self.saveNewModuleSuccessful = true;
                ngDialog.close();
            }
        }

        function createLevels(module) {
            module.levels = [];
            var heightForLevel = module.height / module.numLevels;
            for (var i = 0; i < module.numLevels; i++) {
                module.levels.push({
                    categoryId: null,
                    categoryName: "",
                    height: heightForLevel,
                    id: 0,
                    itemPadding: 1,
                    items: [],
                    levelNumber: i + 1,
                })
            }
        }

        /**
        * Incremente o reduce la altura de un nivel
        * Esta funcion es llamada solo cuando el planograma esta en modo edicion por lo que siempre tendras un unico modulo
        */
        self.planogramOnChangeLevelHeight = function planogramOnChangeLevelHeight($event, planogram, column, module, level, action) {

            //trae al nivel por numero ya que si es nuevo no sirve buscar por id
            var levelLocal = self.planogram.columns[0].modules[0].levels.find(function (l) {
                return l.levelNumber === level.levelNumber;
            });

            if (action === 'increase') {
                levelLocal.height += 1;
                self.planogram.columns[0].modules[0].height += 1;
            }
            else if (action === 'reduce') {
                var highestItemHeight = getHighestItemHeight(levelLocal);
                if (highestItemHeight > levelLocal.height - 1) {
                    swal('altura no permitida'
                        , 'altura del nivel no puede ser menor que la altura del productos mas alto del nivel, altura minimo permitido: ' + highestItemHeight
                        , 'warning');
                }
                else {
                    levelLocal.height -= 1;
                    self.planogram.columns[0].modules[0].height -= 1;
                }
            }

            //Fuerza una nueva referencia del planograma para que se detecte el cambio en los componentes.
            self.planogram = angular.copy(self.planogram);
        }

        function getHighestItemHeight(level) {
            return level.items.reduce(function (maxHeight, item) {
                var currentHneight = item.height * item.stackAbove;
                if (currentHneight > maxHeight)
                    return currentHneight;
                else return maxHeight;
            }, 0);
        }

        /**
        * Borra un nivel
        * Esta funcion es llamada solo cuando el planograma esta en modo edicion por lo que siempre tendras un unico modulo
        */
        self.planogramOnDeleteLevel = function planogramOnDeleteLevel($event, planogram, column, module, level) {
            //$log.debug('EditPlanogramCtrl::planogramOnDeleteLevel\n\t$event: %o\n\tplanogram: %o\n\tcolumn: %o\n\tmodule: %o\n\tlevel: %o',
            //$event,
            //planogram,
            //column,
            //module,
            //level);


            //trae al nivel por numero ya que si es nuevo no sirve buscar por id
            var levelLocal = self.planogram.columns[0].modules[0].levels.find(function (l) {
                return l.levelNumber === level.levelNumber;
            });

            //no puedo borrar un nivel si tiene items
            if (levelLocal.items.length) {
                swal('Nivel con Items'
                    , 'no se puede eliminar un nivel que contiene items'
                    , 'warning');
            }
            else {
                var levelIndex = self.planogram.columns[0].modules[0].levels.indexOf(levelLocal);
                self.planogram.columns[0].modules[0].levels.splice(levelIndex, 1);
                //Fuerza una nueva referencia del planograma para que se detecte el cambio en los componentes.
                self.planogram = angular.copy(self.planogram);
            }


        }

        self.applyPlanogramDimensionsChanges = function () {

            //ganchera tiene un solo nivel y es permitido modificar el alto
            if (self.models.selected.type != 'Shelf')
                self.models.selected.levels[0].height = self.models.selected.height;

            self.planogram.columns[0].modules[0] = self.models.selected;//se agregaron niveles, se cambiaron dimensiones

            // Fuerza una nueva referencia del planograma para que se detecte el cambio en los componentes.
            self.planogram = angular.copy(self.planogram);

        }

        /**
        * Devuelve la columna de la colección de columnas del planograma buscandola por el id de la columnas de referencia o null.
        * @private
        * @param {any} planogram Planograma donde buscar.
        * @param {any} value Columna de referencia.
        * @returns La columna de la colección de columnas del planograma buscandola por el id de la columnas de referencia o null.
        */
        self.findColumn = function findColumn(planogram, value) {
            var ret = null;
            if (angular.isObject(planogram) && angular.isObject(value)) {
                ret = self.find(planogram.columns, value, self.compareToById);
            }

            return ret;
        }

        /**
         * Devuelve el módulo de la colección de modulos de la columna buscandolo por el id del módulo de referencia o null.
         * @private
         * @param {any} column Columna donde buscar.
         * @param {any} value
         * @returns El módulo de la colección de modulos de la columna buscandolo por el id del módulo de referencia o null.
         */
        self.findModule = function findModule(column, value) {
            var ret = null;
            if (angular.isObject(column) && angular.isObject(value)) {
                ret = self.find(column.modules, value, self.compareToById);
            }

            return ret;
        }

        /**
        * Devuelve el estante de la colección de estantes del módulo buscandolo por el id del módulo de referencia o null.
        * @private
        * @param {any} column Columna donde buscar.
        * @param {any} value
        * @returns El módulo de la colección de modulos de la columna buscandolo por el id del módulo de referencia o null.
        */
        self.findLevel = function findLevel(module, value) {
            var ret = null;
            if (angular.isObject(module) && angular.isObject(value)) {
                ret = self.find(module.levels, value, self.compareToById);
            }

            return ret;
        }

        /**
         * Devuelve verdadero si el id del elemento a es igual al id del elemento b, falso en caso contrario.
         * @param {any} a Elemento a.
         * @param {any} b Elemento b
         * @returns Verdadero si el id del elemento a es igual al id del elemento b, falso en caso contrario.
         */
        self.compareToById = function (a, b) {
            var ret = angular.isObject(a)
                && angular.isDefined(a.id)
                && angular.isObject(b)
                && angular.isDefined(b.id)
                && a.id === b.id;
            return ret;
        };

        /**
        * Devuelve el elemento buscado en la colección dada o null en caso de no encotrarlo.
        * @private
        * @param {any} collection Colección de elementos.
        * @param {any} value Referencia.
        * @param {any} compareFn Función de comparación.
        * @returns El elemento buscado en la colección dada o null en caso de no encotrarlo.
        */
        self.find = function find(collection, value, compareFn) {
            var ret = null;
            if (angular.isArray(collection) && angular.isObject(value) && angular.isFunction(compareFn)) {
                var len = collection.length;
                var idx = 0;
                while (idx < len && !compareFn(collection[idx], value)) {
                    idx += 1;
                }

                if (idx < len && compareFn(collection[idx], value)) {
                    ret = collection[idx];
                }
            }

            return ret;
        }

        init();
    })