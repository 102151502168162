angular.module("prisma")
  .controller("AssortmentOutputCtrl", function ($scope, $rootScope, $stateParams, $filter, assortmentService) {
      var self = this;
      self.gridData = null;


      self.exportData = function () {

          swal({
              title: "Esta seguro?",
              text: 'Esta seguro que desea exportar la informacion y pasar su estado a informado?',
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#1AB394",
              confirmButtonText: "Continuar",
              cancelButtonText: "Cancelar",
              showLoaderOnConfirm: true,
              closeOnConfirm: true,
              closeOnCancel: true
          },
           function (isConfirm) {
               if (isConfirm) {
                   var params = {
                       skipHeader: false,
                       skipFooters: false,
                       skipGroups: false,
                       allColumns: true,
                       onlySelected: false,
                       suppressQuotes: false,
                       fileName: 'ExportDatosSurtido' + (new Date()).toISOString().replace(/[^0-9]/g, "").substring(0, 12) + '.csv',
                       columnSeparator: ','
                   };

                   self.gridOptions.api.exportDataAsCsv(params);

                   assortmentService.output.changeOutputStatus().then(function () {

                       swal("Confirmado", "Se exporto correctamente", "success");
                       loadData();
                   },
                   function () {
                       swal("Error", "Ha ocurrido un error", "error");
                   });
               }
           });
      }

      //grid config
      function setColumnDefinitions() {
          columnDefs = [
          {
              headerName: 'Datos Item',
              children: [
              {
                  headerName: "Tipo",
                  field: "status",
                  width: 50,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              },
              {
                  headerName: "Fabricante",
                  field: "manufacturer",
                  width: 120,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              },
              {
                  headerName: "Marca",
                  field: "brand",
                  width: 120,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              },
              {
                  headerName: "Ean",
                  field: "ean",
                  width: 100,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              },
               {
                   headerName: "Descripcion",
                   field: "itemName",
                   width: 155,
                   suppressMenu: true,
                   suppressSorting: true,
                   cellClass: $rootScope.getClassForCell,
               }]
          },
          {
              headerName: 'Datos Internos',
              children: [
              {
                  headerName: "Categoria",
                  field: "category",
                  width: 120,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              },
              {
                  headerName: "SubCategoria",
                  field: "subCategory",
                  width: 120,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              },
              {
                  headerName: "Material",
                  field: "codMaterial",
                  width: 100,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              },
              {
                  headerName: "Cluster",
                  field: "clusterCode",
                  width: 80,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              },
              {
                  headerName: "Tienda",
                  field: "storeCode",
                  width: 80,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              },
              {
                  headerName: "Impacto",
                  field: "impact",
                  width: 80,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              }]
          },
          {
              headerName: 'Datos Externos',
              children: [
                {
                    headerName: "Categoria",
                    field: "categoryNielsen",
                    width: 120,
                    suppressMenu: true,
                    suppressSorting: true,
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: function (params) {
                        if (params.data.status == "BAJA")
                            return "";
                        else
                            return params.data.categoryNielsen;
                    }
                },
                {
                    headerName: "SubCategoria",
                    id: "select",
                    field: "subcategoryNielsen",
                    width: 120,
                    suppressMenu: true,
                    suppressSorting: true,
                    cellClass: $rootScope.getClassForCell,
                },
                {
                    headerName: "SubCategoria 2",
                    id: "select",
                    field: "subcategoryNielsen2",
                    width: 100,
                    suppressMenu: true,
                    suppressSorting: true,
                    cellClass: $rootScope.getClassForCell,
                },
                {
                    headerName: "SubCategoria 3",
                    id: "select",
                    field: "subcategoryNielsen3",
                    width: 100,
                    suppressMenu: true,
                    suppressSorting: true,
                    cellClass: $rootScope.getClassForCell,
                }]
          }
          ]
      }
      function setGridOptions() {

          setColumnDefinitions();

          self.gridOptions = {
              columnDefs: columnDefs,
              enableColResize: true,
              enableSorting: true,
              enableFilter: true,
              groupHideGroupColumns: true,
              groupUseEntireRow: false,
              groupSelectsChildren: false,
              rowHeight: 30,
              rowSelection: "multiple",
              rowDeselection: true,
              showToolPanel: false,// window.innerWidth > 1500,
              suppressRowClickSelection: true,
              suppressCellSelection: true,
              singleClickEdit: true,
              icons: {
                  columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                  filter: '<i class="fa fa-filter"/>',
                  sortAscending: '<i class="fa fa-long-arrow-down"/>',
                  sortDescending: '<i class="fa fa-long-arrow-up"/>',
                  groupExpanded: '<i class="fa fa-minus-square-o"/>',
                  groupContracted: '<i class="fa fa-plus-square-o"/>',
                  columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                  columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
              },
              suppressColumnMoveAnimation: $rootScope.suppressColumnMoveAnimation(),
              enableRangeSelection: false,
              angularCompileRows: true,
              angularCompileHeaders: true,
          }
      }

      function loadData() {
          assortmentService.output.getEndosedData().then(function (data) {
              self.gridData = data;

              self.gridOptions.api.setRowData(self.gridData);
              self.gridOptions.api.refreshView();
          });
      }
      function init() {
          loadData();

          setGridOptions();
      }

      init();
  });
