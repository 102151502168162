'use strinct';
(function () {
    angular.module('prisma')
        .service('DnDService', ['$log', dnDService]);
    var id = 0;

    function dnDService($log) {
        this.$log = $log;
        this.id = ++id;
        //this.$log.debug('DnDService::ctor id: %o', this.id);
    }

    dnDService.prototype.setData = function setData(value) {
        //this.$log.debug('DnDService::setData id: %o, value: %o', this.id, value);
        this.data = value;
        return this;
    }

    dnDService.prototype.getData = function getData() {
        return this.data;
    }

    dnDService.prototype.setDirective = function setDirective(value) {
        //this.$log.debug('DnDService::setDirective id: %o, value: %o', this.id, value);
        this.directive = value;
        return this;
    }

    dnDService.prototype.getDirective = function setDirective() {
        return this.directive;
    }

    /**
     * Devuelve verdadero si se esta arrastrando un elemento, falso en caso contrario.
     * @returns Verdadero si se esta arrastrando un elemento, falso en caso contrario.
     */
    dnDService.prototype.isOnDrag = function isOnDnD() {
        var ret = angular.isDefined(this.onDrag) && this.onDrag;
        return ret;
    }

    /**
     * Setea si se esta arrastrando o no un elemento.
     * @param {any} value Verdadero si se esta arrastrando, falso en caso contrario.
     * @returns La referencia del servicio DnDService.
     */
    dnDService.prototype.setOnDrag = function setOnDrag(value) {
        //this.$log.debug('DnDService::setOnDrag id: %o, value: %o', this.id, value);
        this.onDrag = value;
        return this;
    }

    /**
     * Setea el elemento que se esta arrastrando.
     * @param {any} value Elemento que se esta arrastrando.
     * @returns La referencia del servicio DnDService.
     */
    dnDService.prototype.setDraggeable = function setDraggeable(value) {
        //this.$log.debug('DnDService::setDraggeable id: %o, value: %o', this.id, value);
        this.draggeable = value;
        return this;
    }

    /**
     * Devuelve la referencia del elemento que se esta arrastrando.
     * @returns La referencia del elemento que se esta arrastrando.
     */
    dnDService.prototype.getDraggeable = function getDraggeable() {
        return this.draggeable;
    }

    /**
   * Asigna el offset al inicar el drag & drop del elemento que se esta arrastrando.
   * @returns La referencia del elemento que se esta arrastrando.
   */
    dnDService.prototype.setOffset = function setOffset(value) {
        this.offset = value;
        return this;
    }

    /**
    * Devuelve el offset al inicar el drag & drop del elemento que se esta arrastrando.
    * @returns El offset al inicar el drag & drop del elemento que se esta arrastrando.
    */
    dnDService.prototype.getOffset = function getOffset() {
        return this.offset;
    }
    
})();