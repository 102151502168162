angular.module("prisma")
    .controller("ItemsByAssortmentCtrl", function ($scope,spaceService, $translate, $timeout, $window) {
        var self = this;
        self.isLoading = false;

        self.downloadCsv = function (activesItems) {
            self.isLoading = true;
            spaceService.reports.buildItemsByAssortmentReport(activesItems)
                .then(function (url) {
                    self.isLoading = false;
                    swal({
                        title: "Reporte Generado!",
                        text: 'Haga click <a href="' + url + '"><u style = "color: #1AB394;"><b style = "color: #1AB394;">AQUÍ</b></u></a> para descargar el archivo',
                        confirmButtonText: "Cerrar",
                        confirmButtonColor: "#c2c2c2",
                        html: true
                    });
            }).catch(function (error) {
                swal('Error','hubo un error','error');
            });;           
        }

        function loadLastReports() {
            spaceService.reports.getLastReports()
                .then(function (reports) {
                    self.generatedFiles = reports;
                });
        }

        self.downloadFile = function (url) {
            $window.open(url);
        }

        function init() {
            loadLastReports();
        }

        init();
    });

