"use strict";

var controller = function($rootScope,httpService) {
    var self = this;

    self.categoryLookup = {
        getCategories: function (companyName, level) {
            return httpService.get('/components/categoryLookup/get-categories/' + companyName + '/' + (level ? level : ''));
        }
    }

    self.competitorsLookup = {
        getCompetitors: function (searchText) {
            var all = false;
            if (searchText == '') {
                all = true;
                searchText = 'a'; //No le gusta cuando le mando un vacio...
            }
            return httpService.get('/components/competitorLookup/get-competitors/' + searchText + '/' + all);
        }
    }
}

angular.module("prisma")
    .service("componentsService", controller);