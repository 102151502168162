angular.module("prisma")

.service('httpService', function httpService($http, $q, $log, serverService, errorService) {
    var self = this;

    self.post = function (url, params) {

      var deferred = $q.defer();

      var finalUrl = ian.urlCombine(serverService.getApiUrl(), url);

      $http.post(finalUrl, params)
          .success(function (data, status, headers, config) {
            deferred.resolve(data);
          })
          .error(function (data, status, headers, config) {
            errorService.handle(data, status, headers, config, finalUrl);
            var rv = status == '502' ? status : data;
            deferred.reject(rv);
          });

      return deferred.promise;
    }

    self.testPost = function (url, params) {

      var deferred = $q.defer();

      var finalUrl = ian.urlCombine(serverService.getApiUrl(), url);

      $http.post(finalUrl, params, {
        withCredentials: true,
        headers: { 'Content-Type': undefined },
        //transformRequest: angular.identity
      }).success(function (data, status, headers, config) {
        deferred.resolve(data);
      }).error(function (data, status, headers, config) {
        errorService.handle(data, status, headers, config, finalUrl);
        var rv = status == '502' ? status : data;
        deferred.reject(rv);
      });


      return deferred.promise;
    }

    self.delete = function (url) {

        var deferred = $q.defer();

        var finalUrl = ian.urlCombine(serverService.getApiUrl(), url);

        $http.delete(finalUrl)
            .success(function (data, status, headers, config) {
                deferred.resolve(data);
            })
            .error(function (data, status, headers, config) {
                errorService.handle(data, status, headers, config, finalUrl);
                var rv = status == '502' ? status : data;
                deferred.reject(rv);
            });

        return deferred.promise;
    }

    self.get = function (url) {

        var deferred = $q.defer();

        var finalUrl = ian.urlCombine(serverService.getApiUrl(), url);

        $http.get(finalUrl)
            .success(function (data, status, headers, config) {
                deferred.resolve(data);
            })
            .error(function (data, status, headers, config) {
                errorService.handle(data, status, headers, config, finalUrl);
                var rv = status == '502' ? status : data;
                deferred.reject(rv);
            });

        return deferred.promise;
    }

});
