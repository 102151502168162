angular.module("prisma")
    .controller("GeneralKpisCtrl", function ($scope, priceService, adminService, $translate, $timeout, $window) {
        var self = this;
        self.selectedName = "";
        self.lastSelectedName = "";
        self.reportTypes = [];

        self.isLoading = false;
        self.reportData = [];
        self.priceTypeId = 5; //Por default se usa el Tipo de Precio de Competencia

        $scope.oneAtATime = true;

        $scope.groups = [
            {
                title: 'Dynamic Group Header - 1',
                content: 'Dynamic Group Body - 1'
            },
            {
                title: 'Dynamic Group Header - 2',
                content: 'Dynamic Group Body - 2'
            }
        ];

        $scope.items = ['Item 1', 'Item 2', 'Item 3'];

        $scope.addItem = function () {
            var newItemNo = $scope.items.length + 1;
            $scope.items.push('Item ' + newItemNo);
        };

        $scope.status = {
            isCustomHeaderOpen: false,
            isFirstOpen: true,
            isFirstDisabled: false
        };

        self.filters = {
            priceLists: [],
            categories: [],
            competitorGroups: [],
            brands: []
        }

        self.applyFilters = function () {
            self.closeFilters();

            //loadReportData();
        }

        self.order = 'gmros';

        self.isMarker = true;

        
        self.lastDrilldownReportType = self.reportTypes[0];

        self.drillDownFunnelStep = 0;

        self.drillDown = function (itemId, itemName) {

            self.drillDownFunnelStep++;
            self.lastDrillownItemd = itemId;

            if (self.selectedReportType.id == 1) //Zonas
            {
                self.filters.priceLists = [];
                self.filters.priceLists.push({ id: itemId });


                if (self.drillDownFunnelStep == 1) {
                    self.selectedReportType = self.reportTypes[1];
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[0]);
                }
                else if (self.drillDownFunnelStep == 2) {
                    self.selectedReportType = self.reportTypes[2];
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[0]);
                }

                loadReportData();
            }
            else if (self.selectedReportType.id == 2) //Competidor
            {
                self.filters.competitorGroups = [];
                self.filters.competitorGroups.push({ id: itemId });

                if (self.drillDownFunnelStep == 1) {
                    self.selectedReportType = self.reportTypes[0];
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[1]);
                }
                else if (self.drillDownFunnelStep == 2) {
                    self.selectedReportType = self.reportTypes[2];
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[1]);
                }

                loadReportData();
            }

            self.lastSelectedName = angular.copy(self.selectedName);

            if (self.selectedName == "")
                self.selectedName = itemName;
            else
                self.selectedName = self.selectedName + " / " + itemName
        }

        self.drillUp = function () {

            if (self.selectedReportType.id == 1) //Zonas
            {
                if (self.drillDownFunnelStep == 1) {
                    self.filters.competitorGroups = [];
                    self.selectedReportType = self.reportTypes[1];
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[0]);
                    self.drillDownFunnelStep--;
                    self.selectedName = "";
                }

                loadReportData();
            }
            else if (self.selectedReportType.id == 2) //Competidor
            {
                if (self.drillDownFunnelStep == 1) {
                    self.filters.priceLists = [];
                    self.selectedReportType = self.reportTypes[0];
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[1]);
                    self.drillDownFunnelStep--;
                    self.selectedName = "";
                }

                loadReportData();
            }
            else if (self.selectedReportType.id == 3) //Categoria
            {
                if (self.lastDrilldownReportType.id == 1) { //VENGO DE ZONA
                    self.filters.priceLists = [];
                    self.selectedReportType = self.reportTypes[0];
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[1]);
                    self.drillDownFunnelStep--;
                }
                else if (self.lastDrilldownReportType.id == 2) //VENGO DE COMPETIDOR
                {
                    self.filters.competitorGroups = [];
                    self.selectedReportType = self.reportTypes[1];
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[0]);
                    self.drillDownFunnelStep--;
                }

                loadReportData();
            }

            if (self.drillDownFunnelStep == 1)
                self.selectedName = self.lastSelectedName;
        }

        self.getIcColor = function (ic) {
            if (ic != null) {
                if (ic < 0) //Negativo
                {
                    //(243, 102, 70)
                    var bgColor = 'rgba(243, 102, 70, ' + (ic.toFixed(2) / 5 * -1) + ')';
                    return { "background-color": bgColor };
                }
                else if (ic > 0) //Positivo
                {
                    var bgColor = 'rgba(103, 193, 154, ' + (ic.toFixed(2) / 5) + ')';
                    return { "background-color": bgColor };
                }

                else {
                    return { "background-color": '#FFC98E' }; //Igual a cero
                }
            }
            else {
                return "";
            }

            //ng - class="{'WarningNumber' : item.competitiveIndex <= -0.05, 'WarningNumber-1' : item.competitiveIndex <= -0.06}"
        }


        self.changeReportType = function(group, acordionStatus)
        {
            self.selectedReportType = group;
            self.reportTypeChanged();
            acordionStatus.open = false;
        }

        self.reportTypeChanged = function () {
            if (self.selectedReportType) {

                self.drillDownFunnelStep = 0;

                self.filters.competitorGroups = [];
                self.filters.priceLists = [];

                self.selectedName = "";

                loadReportData();
            }
        }

        self.selectedReportType = self.reportTypes[0];
        self.reportTypeChanged();

        self.pricelists = [];
        function loadPricelists() {
            priceService.pricelists.getPriceLists()
                .then(function (pricelists) {
                    self.pricelists = pricelists
                });
        }

        self.categories = [];
        function loadCategories() {
            adminService.categories.getCategories().then(function (categories) {
                self.categories = categories;
            })
        }

        self.tags = [];
        function loadTags() {
            adminService.tags.getTags()
                .then(function (tags) {
                    self.tags = tags;
                });
        }

        self.brands = [];
        function loadBrands() {
            adminService.items.getItemBrands()
                .then(function (brands) {
                    self.brands = brands;
                });
        }

        self.competitorGroups = [];
        function loadCompetitorGroups() {
            priceService.competitors.getCompetitorGroups()
                .then(function (groups) {
                    self.competitorGroups = groups;
                });
        }

        function loadReportData() {

            //var filteredPriceLists = [];
            //angular.forEach(self.filters.priceLists, function (item) {
            //    filteredPriceLists.push(item.id);
            //});

            //var filteredCategories = [];
            //angular.forEach(self.filters.categories, function (item) {
            //    filteredCategories.push(item.id);
            //});

            //var filteredCompetitorGroups = [];
            //angular.forEach(self.filters.competitorGroups, function (item) {
            //    filteredCompetitorGroups.push(item.id);
            //});

            //var params = {
            //    reportType: self.selectedReportType.id,
            //    priceTypeId: self.priceTypeId,
            //    isMarker: self.isMarker,
            //    priceSurveyTypes: [],
            //    priceListIds: filteredPriceLists,
            //    sensitivityIds: [],
            //    competitorGroupIds: filteredCompetitorGroups,
            //    categoryIds: filteredCategories,
            //    tagIds: []
            //};

            //self.isLoading = true;
            //priceService.reports.getCompetitiveIndex(params).then(function (data) {
            //    self.isLoading = false;

            //    self.reportData = data;
            //});
        }

        //Filtros

        self.showFilters = false;

        self.toggleFilters = function () {

            self.showFilters = !self.showFilters;
        }

        self.getFilterClass = function () {
            if (self.showFilters == true)
                return 'move-right';
            else
                return '';
        }

        function closeFilterWhenClickingOutside(event) {

            var clickedElement = event.target;
            if (!clickedElement) return;

            var elementClasses = clickedElement.classList;
            var clickedOnFilterDiv = elementClasses.contains('isFilter')
                || elementClasses.contains('chosen-choice')
                || elementClasses.contains('chosen-choices')
                || elementClasses.contains('search-field')
                || elementClasses.contains('default')
                || elementClasses.contains('chosen-container')
                || elementClasses.contains('chosen-container')
            if (!clickedOnFilterDiv) {
                self.closeFilters();
            }

        }

        self.closeFilters = function () {
            $timeout(function () {
                self.showFilters = false;
                $window.onclick = null;
            });

        }

        //Filtro collapse

        self.isNavCollapsed = true;
        self.isCollapsedHorizontal = false;
        self.isCollapsed = true;
        self.isZoneCollapsed = true;
        self.isTagCollapsed = true;
        self.isClusterCollapsed = true;
       
        function loadData() {
            loadPricelists();
            loadTags();
            loadCategories();
            loadBrands();
            loadCompetitorGroups();
        }

        var translations = null;

        function init() {
            $translate(["Sp_generalKpis_Category", "Sp_generalKpis_planogram", "Sp_generalKpis_store"])
                .then(function (all) {
                    translations = all;

                    self.reportTypes = [
                        {
                            id: 1,
                            name: translation.Sp_generalKpis_Category,
                        },
                        {
                            id: 2,
                            name: translation.Sp_generalKpis_planogram,
                        },
                        {
                            id: 3,
                            name: translation.Sp_generalKpis_store,
                        },
                        //{
                        //    id: 4,
                        //    name: "Subcategoria",
                        //}
                    ];


                });

            //loadData();

            //Datos harcoded para lademo
            self.reportData = [
                { category: 'Candies', gmros: 4, gmroi: 1.63, hurdleRate: 1507, avgPrice: 587, spacing: 0.63 },
                { category: 'Non Alcoholic Beverages', gmros: 2.79, gmroi: 2.76, hurdleRate: 1314, avgPrice: 223, spacing: 0.71 },
                { category: 'Warehouse', gmros: 3.45, gmroi: 0.26, hurdleRate: 702, avgPrice: 178, spacing: 0.61 },
                { category: 'General Merchandise', gmros: 5, gmroi: 3.03, hurdleRate: 3172, avgPrice: 116, spacing: -0.71 },
                { category: 'Snacks', gmros: 1.61, gmroi: 2.69, hurdleRate: 3359, avgPrice: 337, spacing: -0.7 },
            ]

        }

        init();
    });

