angular.module("prisma")
    .controller("PricesRulesCategoryCtrl", function ($rootScope, $state, $timeout, $translate, $interval, $filter, priceService, companyJson, serverService, Upload, ngDialog) {

        var self = this;
        var dic = {};
        var numberColWidth = 100;
        var editableColWidth = 140;
        var lastAdded = null;
        var columnDefs = null;

        self.gridOptions = null;
        self.summary = null;
        self.summaryFiltered = [];
        self.summaryToUse = [];

        self.competitorGroups = [];
        self.categoryMinLevel = companyJson.Category.Levels;
        self.companyCode = companyJson.Erp.Implementation;

        var hideAmountParameters = true;

        self.copiedSummary = null;
        self.onSearchKeyUp = function () {
            //console.log('self.search', self.search);
            if (!self.search) {
                if (self.copiedSummary)
                    copyValuesFromFilteredToComplete(self.copiedSummary, self.summary);
                self.gridOptions.api.setRowData(self.summary);
            } else {
                self.copiedSummary = angular.copy(self.summary);
                filterSummary(self.summary, self.copiedSummary, self.search.toLowerCase());
                self.gridOptions.api.setRowData(self.copiedSummary);

            }
        }

        function copyValuesFromFilteredToComplete(filtered, completed) {
            if (filtered.length == 0 || completed.length == 0) return;
            for (var j = 0; j < completed.length; j++) {
                for (var i = 0; i < filtered.length; i++) {
                    if (completed[j].id == filtered[i].id) {
                        //console.log('copying', filtered[i].name);
                        copyValues(filtered[i], completed[j]);
                        copyValuesFromFilteredToComplete(filtered[i].subcategories, completed[j].subcategories);
                    }
                }
            }
        }

        function copyValues(src, dst) {
            dst.allowMaxVariation = src.allowMaxVariation
            dst.allowMaxViolation = src.allowMaxViolation
            dst.allowMinVariation = src.allowMinVariation
            dst.allowMinViolation = src.allowMinViolation
            dst.code = src.code
            dst.goToTargetMargin = src.goToTargetMargin
            dst.id = src.id
            dst.keepMargin = src.keepMargin
            dst.keepMarginAmount = src.keepMarginAmount
            dst.level = src.level
            dst.mainCompetitorGroup = src.mainCompetitorGroup
            dst.mainCompetitorGroupId =
                src.mainCompetitorGroupId
            dst.maxMargin =
                src.maxMargin
            dst.maxMarginAmount =
                src.maxMarginAmount
            dst.maxVariation =
                src.maxVariation
            dst.maxVariationAmount =
                src.maxVariationAmount
            dst.minMargin =
                src.minMargin
            dst.minMarginAmount =
                src.minMarginAmount
            dst.minVariation =
                src.minVariation
            dst.minVariationAmount =
                src.minVariationAmount
            dst.name =
                src.name
            dst.parentId =
                src.parentId
            dst.significantVariation =
                src.significantVariation
            dst.significantVariationInMargin =
                src.significantVariationInMargin
            dst.targetMargin =
                src.targetMargin
            dst.targetMarginAmount =
                src.targetMarginAmount
        }

        //source y dest tienen que ser copias uno del otro
        function filterSummary(source, dest, search) {
            if (!source || source.length == 0) return false;
            var idsToRemove = [];
            var anyOfThisAreValid = false;
            for (var i = 0; i < source.length; i++) {
                //if (source[i].id == 11) {
                //    var a = 1;
                //    debugger;
                //}
                var anyChildrenValid = false;

                anyChildrenValid |= filterSummary(source[i].subcategories, dest[i].subcategories, search);

                if (!anyChildrenValid && source[i].name.toLowerCase().indexOf(search) == -1) {
                    //console.log('removing ', source[i].name);

                    idsToRemove.push(source[i].id);
                } else if (source[i].name.toLowerCase().indexOf(search) != -1) {
                    anyOfThisAreValid = true;
                } else if (anyChildrenValid) {
                    anyOfThisAreValid = true;
                }
            }


            for (var i = 0; i < idsToRemove.length; i++) {
                for (var j = 0; j < dest.length; j++) {
                    if (dest[j].id == idsToRemove[i]) {
                        dest.splice(j, 1);
                        break;
                    }

                }
            }

            return anyOfThisAreValid;

        }


        self.goToRules = function (id, categoryGroup, category, subcategory) {

            categoryGroup = categoryGroup.replace(/ /g, "-").replace(/\//g, "_");
            category = category.replace(/ /g, "-").replace(/\//g, "_");
            subcategory = subcategory.replace(/ /g, "-").replace(/\//g, "_");

            var encodedcategoryGroup = categoryGroup != 'null' ? encodeURIComponent(categoryGroup) : '';
            var encodedcategory = category != 'null' ? encodeURIComponent(category) : '';
            var encodedsubcategory = subcategory != 'null' ? encodeURIComponent(subcategory) : '';

            $state.go('prices.rules', { categoryId: id, categoryGroup: encodedcategoryGroup, category: encodedcategory, subcategory: encodedsubcategory });
        }

        self.save = function () {
            swal({
                title: translations.DiscardConfirmTitle,
                text: translations.AreYouSureSwalSubtitle,
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function () {
                    var itemsToSave = [];
                    self.gridOptions.api.forEachNode(function (node) {
                        if (node.data.dirty) {
                            itemsToSave.push(node.data);
                        }
                    });

                    if (itemsToSave.length > 0) {
                        var categories = JSON.stringify(itemsToSave);
                        priceService.rules.saveCategoryRules(categories).then(function () {
                            swal(translations.ConfirmedSwal, translations.ConfirmedSwalSubtitle, "success");
                        }).catch(function (fallback) {
                            swal(translations.ErrorTitleSwal, translations.ErrorSwalSubtitle, "error");
                        });
                    }
                    else {
                        setTimeout(function () {
                            swal(translations.WarningSwal, translations.NoChangeWasDetectedSwal, "warning");
                        }, 1000);
                    }
                });
        }

        function iconRenderer(params) {
            return '<a title="Click aqui para ingresar a la categoria" ng-click="ct.goToRules(' + params.data.id + ')"><i class="fa fa-arrow-circle-o-right" style="padding: 5px; font-size:16px"></i></a>';
        }



        //grid config
        function setColumnDefinitions() {


            columnDefs = [
                {
                    headerName: translations.Category,
                    width: numberColWidth + 200,
                    pinned: 'left',
                    field: 'name',
                    suppressMenu: true,
                    suppressSorting: true,
                    filter: false,
                    cellRenderer: {
                        renderer: 'group',
                        checkbox: false,
                        innerRenderer: function (params) {
                            var name = params.value + (params.node.group && params.node.children != null ? ' (' + params.node.children.length + ')' : '');


                            if (!params.node.parent || params.node.parent == null) {
                                if (self.companyCode == 'LaRebaja')
                                    name = (params.node.data.parentName ? (params.node.data.parentName + ' >> ') : '') + name;

                                return name;
                            }

                            var anchor = document.createElement('a');
                            anchor.title = translations.ClickHereViewCategory;
                            anchor.style = 'padding: 5px';
                            anchor.innerHTML = name

                            anchor.addEventListener('click', function () {
                                var categoryGroup = '';
                                var category = '';
                                var subcategory = '';
                                //issue 686 cp al filtrar si los hijos no coinciden con el termino de busqueda
                                //se suprimen por lo que se interpreta como una subcategoria ya que no tiene hijos
                                //if (!params.node.parent || params.node.parent == null) {
                                //    categoryGroup = params.value;
                                //}
                                //else if (params.node.parent != null && params.node.children != null) {
                                //    categoryGroup = params.node.parent.data.name;
                                //    category = params.value
                                //}
                                //else if (params.node.parent != null && params.node.children == null) {
                                //    categoryGroup = params.node.parent.parent.data.name;//aca pincha!
                                //    category = params.node.parent.data.name
                                //    subcategory = params.value
                                //}
                                if (params.node.data.level === 5) {
                                    categoryGroup = params.node.parent.parent.data.name;
                                    category = params.node.parent.data.name;
                                    subcategory = params.value;
                                }
                                else {
                                    categoryGroup = params.node.parent.data.name;
                                    category = params.value;
                                }

                                self.goToRules(params.data.id, categoryGroup, category, subcategory);
                            });

                            return anchor;
                        }
                    }
                },
                {
                    headerName: translations.MarginGuidelines,

                    children: [
                        {
                            headerName: translations.TargetMarginP,
                            field: "targetMargin",
                            headerTooltip: "",
                            filter: 'number',
                            //hide: true, //TODO: hacer que sea por company json
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 100,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: percentageEditorWithDecimalAndZero
                        },
                        {
                            headerName: translations.TargetMargin,
                            field: "targetMarginAmount",
                            hide: hideAmountParameters,
                            headerTooltip: "",
                            filter: 'number',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 100,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: moneyEditorWithZeros
                        },
                        {
                            headerName: "Min %",
                            field: "minMargin",
                            headerTooltip: "",
                            filter: 'number',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: percentageEditorWithDecimalAndZero
                        },
                        {
                            headerName: "Min $",
                            field: "minMarginAmount",
                            hide: hideAmountParameters,
                            headerTooltip: "",
                            filter: 'number',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: moneyEditorWithZeros
                        },
                        {
                            headerName: "Max %",
                            field: "maxMargin",
                            headerTooltip: "",
                            filter: 'number',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: percentageEditorWithDecimalAndZero
                        },
                        {
                            headerName: "Max $",
                            field: "maxMarginAmount",
                            hide: hideAmountParameters,
                            headerTooltip: "",
                            filter: 'number',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: moneyEditorWithZeros
                        },
                        {
                            headerName: translations.AllowMin,
                            field: "allowMinViolation",
                            headerTooltip: translations.AllowMinTooltip,
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            filter: 'input',
                            cellRenderer: $rootScope.checkboxEditor()
                        },
                        {
                            headerName: translations.AllowMax,
                            field: "allowMaxViolation",
                            headerTooltip: translations.AllowMaxTooltip,
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            filter: 'input',
                            cellRenderer: $rootScope.checkboxEditor()
                        },
                        {
                            headerName: translations.GoToTarget,
                            field: "goToTargetMargin",
                            headerTooltip: translations.GoToTargetTooltip,
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 60,
                            cellClass: $rootScope.getClassForCell,
                            filter: 'input',
                            cellRenderer: self.checkboxEditorMarginGuides
                        },
                        {
                            headerName: translations.KeepMargin,
                            field: "keepMargin",
                            headerTooltip: translations.KeepMarginTooltip,
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 75,
                            cellClass: $rootScope.getClassForCell,
                            filter: 'input',
                            cellRenderer: self.checkboxEditorMarginGuides
                        }]
                },
                {
                    headerName: translations.PriceVariation,

                    children: [
                        {
                            headerName: translations.LowPercentage,
                            field: "minVariation",
                            filter: 'number',
                            headerTooltip: "",
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: percentageEditorWithDecimalAndZero
                        },
                        {
                            headerName: "Min $",
                            field: "minVariationAmount",
                            hide: hideAmountParameters,
                            headerTooltip: "",
                            filter: 'number',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: moneyEditorWithZeros
                        },
                        {
                            headerName: translations.RisePercentage,
                            field: "maxVariation",
                            filter: 'number',
                            headerTooltip: "",
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: percentageEditorWithDecimalAndZero
                        },
                        {
                            headerName: "Max $",
                            field: "maxVariationAmount",
                            hide: hideAmountParameters,
                            headerTooltip: "",
                            filter: 'number',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: moneyEditorWithZeros
                        },
                        {
                            headerName: translations.AllowMin,
                            field: "allowMinVariation",
                            headerTooltip: translations.GenerateSuggestionsVarTooltipMin,
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            filter: 'input',
                            cellRenderer: $rootScope.checkboxEditor()
                        },
                        {
                            headerName: translations.AllowMax,
                            field: "allowMaxVariation",
                            headerTooltip: translations.GenerateSuggestionsVarTooltipMax,
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 80,
                            cellClass: $rootScope.getClassForCell,
                            filter: 'input',
                            cellRenderer: $rootScope.checkboxEditor()
                        },
                        {
                            headerName: translations.SignVar,
                            field: "significantVariation",
                            headerTooltip: translations.SignVarTooltip,
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 70,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: percentageEditorWithDecimalAndZero
                        },
                        {
                            headerName: translations.SignVarMargin,
                            field: "significantVariationInMargin",
                            headerTooltip: translations.SignVarMarginTooltip,
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 130,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: moneyEditorWithZeros
                        }]
                },
                {
                    headerName: translations.Competitors,
                    children: [
                        {
                            headerName: translations.MainCompetitor,
                            field: "mainCompetitorGroup",
                            headerTooltip: "",
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 120,
                            cellClass: $rootScope.getClassForCell,
                            filter: 'input',
                            cellRenderer: dropDownDecisionEditor
                        }
                    ]
                }
            ]
        }

        //Grid Renderer
        self.checkboxEditorMarginGuides = function (params) {
            var eInput = document.createElement("input");
            eInput.type = "checkbox";
            eInput.checked = params.value;

            eInput.addEventListener("change", function (event) {
                params.value = eInput.checked;
                params.data[params.column.colId] = eInput.checked;

                if (params.colDef.field == 'goToTargetMargin') { //Si toque Ir a Obj, destildo Mantener
                    params.node.data.keepMargin = false;
                }
                else if (params.colDef.field == 'keepMargin') { //Si toque Mantener, destildo Ir a Obj
                    params.node.data.goToTargetMargin = false;
                }


                if (params.node != null && params.node.children != null) {
                    params.node.children.forEach(function (node) {
                        if (node.children) {
                            node.children.forEach(function (node) {
                                node.data[params.column.colId] = eInput.checked;

                                if (params.colDef.field == 'goToTargetMargin') { //Si toque Ir a Obj, destildo Mantener
                                    node.data.keepMargin = false;
                                }
                                else if (params.colDef.field == 'keepMargin') { //Si toque Mantener, destildo Ir a Obj
                                    node.data.goToTargetMargin = false;
                                }

                            });
                        }
                        node.data[params.column.colId] = eInput.checked;

                        if (params.colDef.field == 'goToTargetMargin') { //Si toque Ir a Obj, destildo Mantener
                            node.data.keepMargin = false;
                        }
                        else if (params.colDef.field == 'keepMargin') { //Si toque Mantener, destildo Ir a Obj
                            node.data.goToTargetMargin = false;
                        }

                    });
                }

                params.data.dirty = true;

                params.api.refreshView();
            });

            return eInput;
        }
        function dropDownDecisionEditor(params) {

            var editing = false;

            var eCell = document.createElement('div');

            var eLabel = null

            if (params.value != null && params.value != "")
                eLabel = document.createTextNode(params.value);
            else
                eLabel = document.createTextNode("------------");

            eCell.appendChild(eLabel);

            var eSelect = document.createElement("select");
            eSelect.className = "form-control";
            eSelect.style = "margin:0px !important; border: 0px; line-height: 100%; font-size:12px !important";

            for (var i = 0; i < self.competitorGroups.length; i++) {
                var eOption = document.createElement("option");
                eOption.setAttribute("value", self.competitorGroups[i].id);
                eOption.innerHTML = self.competitorGroups[i].name;
                eSelect.appendChild(eOption);
            }

            //agrego la opcion vacia
            var eOptionEmpty = document.createElement("option");
            eOptionEmpty.setAttribute("value", null);
            eOptionEmpty.innerHTML = "---"
            eSelect.appendChild(eOptionEmpty);

            eSelect.value = params.data.mainCompetitorGroupId;


            eCell.addEventListener('click', function () {
                if (!editing) {
                    eCell.removeChild(eLabel);
                    eCell.appendChild(eSelect);
                    eSelect.focus();
                    editing = true;
                }
            });

            eSelect.addEventListener('blur', function () {
                if (editing) {
                    editing = false;
                    eCell.removeChild(eSelect);
                    eCell.appendChild(eLabel);
                }
            });

            eSelect.addEventListener('change', function () {
                if (editing) {

                    var colIds = [];
                    colIds.push(params.column.colId);
                    var changedRows = [];
                    changedRows.push(params.node);

                    editing = false;
                    var newValue = '';

                    for (var i = 0; i < self.competitorGroups.length; i++) {
                        if (self.competitorGroups[i].id == eSelect.value || eSelect.value == 'null') {
                            newValue = eSelect.value == 'null' ? '' : self.competitorGroups[i].name;
                            params.data['mainCompetitorGroup'] = newValue;
                            params.data['mainCompetitorGroupId'] = eSelect.value == 'null' ? null : eSelect.value;
                            //Children
                            if (params.node != null && params.node.children != null) {
                                params.node.children.forEach(function (node) {
                                    if (node.children) {
                                        node.children.forEach(function (node) {
                                            node.data['mainCompetitorGroup'] = newValue;
                                            node.data['mainCompetitorGroupId'] = eSelect.value == 'null' ? null : eSelect.value;
                                            node.data.dirty = true;
                                            changedRows.push(node);
                                        });
                                    }
                                    node.data['mainCompetitorGroup'] = newValue;
                                    node.data['mainCompetitorGroupId'] = eSelect.value == 'null' ? null : eSelect.value;
                                    node.data.dirty = true;
                                    changedRows.push(node);
                                });
                            }
                            break;
                        }
                    }


                    params.data.dirty = true;

                    params.api.refreshCells(changedRows, colIds);

                    eLabel.nodeValue = newValue;
                    eCell.removeChild(eSelect);
                    eCell.appendChild(eLabel);
                }
            });

            return eCell;
        }

        function setGridOptions() {


            self.gridOptions = {
                //columnDefs: columnDefs,
                enableColResize: true,
                enableSorting: false,
                enableFilter: false,
                groupUseEntireRow: false,
                rowSelection: "multiple",
                suppressCellSelection: true,
                groupSelectsChildren: true,
                groupSuppressAutoColumn: false, // or undefined
                groupColumnDef: null, // or undefined
                singleClickEdit: true,
                rowHeight: 35,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                enableRangeSelection: false,
                angularCompileRows: true,
                getNodeChildDetails: getNodeChildDetails,
            }
        }

        function getNodeChildDetails(rowItem) {
            if (rowItem.subcategories && rowItem.subcategories.length > 0) {
                return {
                    group: true,
                    expanded: false,
                    // provide ag-Grid with the children of this group
                    children: rowItem.subcategories,
                    // the key is used by the default group cellRenderer
                    key: rowItem.id
                };
            } else {
                return null;
            }
        }

        //grid filter
        self.onFilterChanged = function (value) {
            self.gridOptions.api.setQuickFilter(value);
        }

        function clearAlternativeColumn(params, colIds, changedRows, node) {
            if (params.value) {

                var column = params.column.colId;
                var alternativeColumn = column.indexOf('Amount') !== -1 ? column.replace('Amount', '') : (column + 'Amount');
                if (column == 'significantVariation') {
                    alternativeColumn = 'significantVariationInMargin';
                } else if (column == 'significantVariationInMargin') {
                    alternativeColumn = 'significantVariation';
                }

                node.data[alternativeColumn] = null;
                colIds.push(alternativeColumn);
                changedRows.push(node);
            }
        }

        function percentageEditorWithDecimalAndZero(params) {

            var editing = false;

            var eCell = document.createElement('div');
            eCell.style = "width:100% !important;height:100% !important";
            eCell.className = "input-group";
            var eLabel = params.value != null ? document.createTextNode($rootScope.percentageRendererWithZero(params)) : document.createTextNode('');
            eCell.appendChild(eLabel);

            var eInput = document.createElement("input");
            eInput.type = "number";
            eInput.step = "0.01";
            eInput.className = "form-control";
            var eSpan = document.createElement("span");
            eSpan.className = "input-group-addon";
            eSpan.style = "margin:0px !important; height: 100% !important; border: 0px; line-height: 100%; font-size:12px !important";
            eSpan.innerText = "%";


            eCell.addEventListener('click', function () {
                if (!editing) {
                    eCell.removeChild(eLabel);
                    eCell.appendChild(eInput);
                    eCell.appendChild(eSpan);
                    eInput.focus();
                    eInput.value = params.value ? params.value * 100 : null;
                    editing = true;
                }
            });

            eInput.addEventListener('blur', function () {
                if (editing) {
                    editing = false;
                    eCell.removeChild(eInput);
                    eCell.removeChild(eSpan);
                    eCell.appendChild(eLabel);
                }
            });

            eInput.addEventListener('change', function () {
                if (editing) {
                    editing = false;
                    var newValue = undefined;
                    if (eInput.value != '')
                        newValue = eInput.value / 100;

                    params.value = newValue;
                    params.data[params.column.colId] = newValue;
                    eLabel.nodeValue = $rootScope.percentageRendererWithZero(params);
                    eCell.removeChild(eInput);
                    eCell.removeChild(eSpan);
                    eCell.appendChild(eLabel);

                    var colIds = [];
                    var changedRows = [];

                    clearAlternativeColumn(params, colIds, changedRows, params.node);

                    if (params.node != null && params.node.children != null) {
                        colIds.push(params.column.colId);
                        params.node.children.forEach(function (node) {
                            if (node.children) {
                                node.children.forEach(function (node) {
                                    node.data[params.column.colId] = params.value;
                                    node.data.dirty = true;
                                    changedRows.push(node);

                                    clearAlternativeColumn(params, colIds, changedRows, node);
                                });
                            }
                            node.data[params.column.colId] = params.value;
                            node.data.dirty = true;
                            changedRows.push(node);

                            clearAlternativeColumn(params, colIds, changedRows, node);
                        });
                    }
                    console.log('params', params);

                    params.api.refreshCells(changedRows, colIds);
                    params.api.recomputeAggregates();
                    params.data.dirty = true;

                }
            });

            return eCell;
        }

        function moneyEditorWithZeros(params) {

            var editing = false;

            var eCell = document.createElement('div');
            eCell.style = "width:100% !important;height:100% !important";
            eCell.className = "input-group";
            var eLabel = params.value ? document.createTextNode($rootScope.moneyRendererWithZeros(params)) : document.createTextNode('');
            eCell.appendChild(eLabel);

            var eInput = document.createElement("input");
            eInput.type = "number";
            eInput.step = "0.01";
            eInput.className = "form-control";
            var eSpan = document.createElement("span");
            eSpan.className = "input-group-addon";
            eSpan.style = "margin:0px !important; height: 100% !important; border: 0px; line-height: 100%; font-size:12px !important";
            eSpan.innerText = "$";

            eCell.addEventListener('click', function () {
                if (!editing) {
                    eCell.removeChild(eLabel);
                    eCell.appendChild(eSpan);
                    eCell.appendChild(eInput);
                    eInput.focus();
                    eInput.value = params.value;
                    editing = true;
                }
            });

            eInput.addEventListener('blur', function () {
                if (editing) {
                    editing = false;
                    eCell.removeChild(eSpan);
                    eCell.removeChild(eInput);
                    eCell.appendChild(eLabel);
                }
            });

            eInput.addEventListener('change', function () {
                if (editing) {
                    editing = false;
                    params.value = eInput.value;
                    params.data[params.column.colId] = eInput.value;
                    eLabel.nodeValue = $rootScope.moneyRendererWithZeros(params);
                    eCell.removeChild(eSpan);
                    eCell.removeChild(eInput);
                    eCell.appendChild(eLabel);

                    var colIds = [];
                    var changedRows = [];

                    clearAlternativeColumn(params, colIds, changedRows, params.node);

                    if (params.node != null && params.node.children != null) {

                        colIds.push(params.column.colId);
                        params.node.children.forEach(function (node) {
                            if (node.children) {
                                node.children.forEach(function (node) {
                                    node.data[params.column.colId] = params.value;
                                    node.data.dirty = true;
                                    changedRows.push(node);

                                    clearAlternativeColumn(params, colIds, changedRows, node);
                                });
                            }
                            node.data[params.column.colId] = params.value;
                            node.data.dirty = true;
                            changedRows.push(node);

                            clearAlternativeColumn(params, colIds, changedRows, node);
                        });
                    }

                    params.api.refreshCells(changedRows, colIds);
                    params.api.recomputeAggregates();
                    params.data.dirty = true;
                }
            });

            return eCell;
        }

        function loadData() {

            priceService.competitors.getCompetitorGroups()
                .then(function (groups) {

                    self.competitorGroups = groups;
                })


            priceService.rules.getCategories(self.categoryMinLevel)
                .then(function (summary) {
                    console.log('summary', summary);
                    self.summary = summary;
                    self.gridOptions.api.setRowData(summary);
                });
        }

        //importador de reglas para cencosud peru
        self.openImporterDialog = function () {

            self.currentStep = 2;
            self.gridOptions2 = {
                columnDefs: [
                    {
                        headerName: 'Severidad',
                        width: 100,
                        pinned: 'left',
                        field: 'severity',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                        rowGroupIndex: 0,
                        cellRenderer: function (params) {
                            var severityName = '';
                            switch (params.value) {
                                case 'Information': { severityName = '<i style="color: #6aa4ff;" class="fa fa-info-circle" aria-hidden="true"></i> Info'; break; }
                                case 'Warning': { severityName = '<i style="color: #e4e400;" class="fa fa-stop-circle" aria-hidden="true"></i> Warn'; break; }
                                case 'Error': { severityName = '<i style="color: #ea2323;" class="fa fa-exclamation-circle" aria-hidden="true"></i> Error'; break; }
                            }
                            return severityName;
                        }
                    },
                    {
                        headerName: 'Fila',
                        width: 50,
                        pinned: 'left',
                        field: 'row',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                    },
                    {
                        headerName: 'Categoria',
                        width: 150,
                        rowGroupIndex: 1,
                        pinned: 'left',
                        field: 'category',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                    },
                    {
                        headerName: 'Descripcion',
                        width: 350,
                        pinned: 'left',
                        field: 'description',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                    },
                ],
                enableColResize: true,
                enableSorting: true,
                enableFilter: false,
                rowHeight: 35,
                suppressHorizontalScroll: false,
                suppressCellSelection: true,
                groupUseEntireRow: true,
                groupSuppressAutoColumn: false,
                groupDefaultExpanded: 0,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                angularCompileRows: true,
            }

            ngDialog.open({
                template: 'importerDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                controller: function () {
                    return self;
                },
                controllerAs: 'ct',
            });
        }

        self.showNextStep = function () {
            if (self.currentStep > 0 && self.currentStep < 4) {
                self.currentStep++;
                loadResults([]);
            }
        }

        self.uploadFile = function (files) {
            if (files && files.length > 0) {
                self.isBusy = true;
                var url = serverService.getApiUrl() + '/price/rules/uploadBatchFile';
                Upload.upload({
                    url: url,
                    data: { file: files[0] }
                }).then(function (response) {
                    var importerResults = response.data;
                    processImporterResults(importerResults);
                    if (!anyError(importerResults)) {
                        self.finishedUpload = true;
                    }
                });
            }
        }

        self.validateUploadedFile = function () {
            self.isBusy3 = true;
            priceService.rules.validateRulesInBatchFile()
                .then(function (dto) {
                    processImporterResults(dto);
                    if (!anyError(dto)) {
                        self.finishedValidation = true;
                    }
                });

        }

        self.reUploadFile = function () {
            self.currentStep = 2;
            loadResults([]);
            self.files = null;
            self.finishedUpload = false;
            self.finishedValidation = false;
            self.finishedUpdate = false;
        }

        self.updateItems = function () {
            self.isBusy4 = true;
            priceService.rules.updateRulesInBatchFile()
                .then(function (dto) {
                    processImporterResults(dto);
                    if (!anyError(dto)) {
                        self.finishedUpdate = true;
                    }
                });

        }

        self.finish = function () {
            self.currentStep = 1;
            loadResults([]);

            self.finishedUpload = false;
            self.finishedValidation = false;
            self.finishedUpdate = false;

            ngDialog.close();
        }

        function processImporterResults(importerResults) {
            self.isBusy = false;
            self.isBusy2 = false;
            self.isBusy3 = false;
            self.isBusy4 = false;

            if (importerResults.length > 0) {
                loadResults(importerResults);
            }
            if (anyError(importerResults)) {
                self.error = true;
            }
            else
                self.error = false;
        }

        function loadResults(results) {
            self.gridOptions2.api.setRowData(results);
            self.gridOptions2.api.refreshView();

            self.gridVisible = results.length > 0 ? true : false;
        }

        function anyError(importerResults) {
            var error = false;
            for (var i = 0; i < importerResults.length; i++) {
                if (importerResults[i].severity === 'Error')
                    error = true;
            }

            return error;
        }

        var translations = null;
        function init() {

            setGridOptions();

            $translate(["Category", "Competitors", "ClickHereViewCategory", "MarginGuidelines", "GenerateSuggestionsVarTooltipMin", "AllowMin", "AllowMax", "GenerateSuggestionsVarTooltipMax", "SignVar", "SignVarTooltip", "SignVarMargin", "SignVarMarginTooltip"
                , "MainCompetitor", "TargetMarginP", "TargetMargin", "AllowMinTooltip", "AllowMaxTooltip", "GoToTarget", "GoToTargetTooltip"
                , "KeepMargin", "KeepMarginTooltip", "PriceChanges", "DiscardConfirmTitle", "AreYouSureSwalSubtitle", "ContinueSwal", "CancelSwal"
                , "ConfirmedSwal", "ConfirmedSwalSubtitle", "ErrorTitleSwal", "ErrorSwalSubtitle", "WarningSwal", "NoChangeWasDetectedSwal", "PriceVariation", "LowPercentage", "RisePercentage"])
                .then(function (all) {
                    translations = all;

                    setColumnDefinitions();

                    self.gridOptions.api.setColumnDefs(columnDefs);
                });

            hideAmountParameters = companyJson.PriceRulesCategory.Grid.HideAmountParameters;

            //console.log('gridoptions', self.gridOptions);


            loadData();

            //init columnDefs and gridOptions


        }

        init();
    });

