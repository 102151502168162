angular.module("prisma")
.service('serverService', function serverService() {

    var self = this;

  

    self.getApiUrl = function () {

        return "/api";

    }

    self.getRootUrl = function () {

        return "/";
    }

    self.$get = function () {
        return self;
    };

})