"use strict";

angular.module("prisma")
    .service("inventoryService", function homeService($rootScope, httpService) {
        var self = this;

        self.inventory = {
            getStockAnalysis: function () {
                return httpService.get('/stockAnalysis/' + $rootScope.companyId);
            }
        }

    });



