angular.module("prisma")
    .controller("DecisionsCtrl", function ($scope, $rootScope, $timeout, $interval, $state, $stateParams, $filter, priceService, adminService, ngDialog, companyJson, $translate) {

        var dic = {};
        var numberColWidth = 100;
        var editableColWidth = 140;
        var lastAdded = null;
        var columnDefs = null;
        var changePriceNewImpact = 0;
        var selectedFilterForText = '';
        var self = this;

        self.hidePricesCopiedFromOthers = false;
        self.hideCurrentPrices = false;
        showAverageCost = false;

        showAverageCost = companyJson.PriceDecisions.Grid.ShowAverageCost; //Mostrar u ocultar AverageCosts

        $timeout(function () {
            self.gridOptions.columnApi.setColumnVisible('averageCost', showAverageCost);
            self.gridOptions.columnApi.setColumnVisible('grossMarginAverage', showAverageCost);
        }, 0);

        self.hidePricesCopiedFromOthers = companyJson.PriceDecisions.Grid.HidePricesCopiedFromOthers;
        self.hideCurrentPrices = companyJson.PriceDecisions.Grid.HideCurrentPrices;

        self.busy = false;
        self.gridOptions = null;
        self.isEndorse = null;
        self.allPrices = null;
        self.title = null;
        self.pricelists = [{ id: 0, name: '' }];
        self.priceTypes = [];
        self.loadingPriceSurveys = true;
        self.selectedFilterForPriceList = "";
        self.selectedFilterForPriceType = '';
        self.isEndorse = $stateParams.isEndorse ? JSON.parse($stateParams.isEndorse) : false;
        self.isNewFilter = $stateParams.new ? JSON.parse($stateParams.new) : false;
        self.selectedFilterForStatus = "";
        self.selectedFilterForIssues = '';
        self.selectedFilterForAction = '';
        self.selectedFilterForSensitivities = "";
        self.tags = [];
        self.itemSensitivities = [];
        self.suggestedImpact = 0;
        self.suggestedImpactShare = 0; //Impacto sobre total margen de la categoria
        self.newImpact = 0;
        self.newImpactShare = 0; //Impacto sobre total margen de la categoria
        self.numberOfNewPrices = 0;
        self.numberOfSuggestions = 0;
        self.numberOfSuggestionsAccepted = 0;
        self.percentageOfAcceptedSuggestions = 0;
        self.categoryMargin = 0;
        self.salesWithCurrentPrices = 0;
        self.salesWithNewPrices = 0;
        self.salesWithSuggestedPrices = 0;
        self.averagePriceChanges = 0;
        self.averagePriceNewVsSuggestedChanges = 0;
        self.priceDashboard = null;
        self.timerange = 'week';

        self.toggleToolPanel = function () {
            var showing = self.gridOptions.api.isToolPanelShowing();
            self.gridOptions.api.showToolPanel(!showing);
        }

        self.markAsReviewed = function (priceType, priceTypeName) {
            var alertMessage = '';
            if (priceType == 0)
                alertMessage = translations.PricesOfThisCategoryAsRevised;
            else if (priceType == 5)
                alertMessage = translations.CompetitionPricesOfThisCategoryAsRevised;
            else if (priceType == 6)
                alertMessage = translations.NormalPricesOfThisCategoryAsRevised;

            var swalConfig = {
                title: translations.DiscardConfirmTitle,
                text: alertMessage,
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            };

            swal(swalConfig, function () {
                /* Issue 554 
                //1. Save changes
                var result = saveNewPrices(false);

                if (result && typeof result.then == 'function') { //si se hace el save la respuesta es un promise

                    result.then(function () {

                        //2. Mark as reviewed                        
                        markAsReviewed(priceType);

                    },
                        function () {
                            swal(translations.ErrorTitleSwal, translations.ErrorSwalSubtitle, "error");
                        }).catch(function (fallback) {
                            console.log(fallback);
                        });
                }
                else {

                    //2. Mark as reviewed                        
                    markAsReviewed(priceType);
                }*/

                markAsReviewed(priceType);
            });
        }

        self.markAsEndorsed = function () {
            var swalConfig = {
                title: translations.DiscardConfirmTitle,
                text: translations.WantToNotifyEndorsement,
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.Cancelar,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            };

            swal(swalConfig, function () {

                priceService.decisions.markAsEndorsed($stateParams.categoryId, $stateParams.categoryGroup, $stateParams.category)
                    .then(function () {
                        swal(translations.ConfirmedSwal, translations.TheEndorsementWasNotified, "success");
                    },
                    function () {
                        swal(translations.ErrorTitleSwal, translations.ErrorWhenWantingNotifyEndorsement, "error");
                    })
                    .catch(function (fallback) {
                        console.log(fallback);
                    });
            });
        }

        self.accept = function () {

            var swalConfig = {
                title: translations.DiscardConfirmTitle,
                text: translations.AreYouSureSwalSubtitle,
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            }

            if (self.gridOptions.api.getSelectedNodes().length > 0) {

                swal(swalConfig, function () {

                    var result = saveNewPrices(true);

                    if (result && typeof result.then == 'function') { //if response is a promise
                        result.then(function () {
                            swal(translations.ConfirmedSwal, translations.ConfirmedSwalSubtitle, "success");
                            loadData();
                        },
                            function () {
                                swal(translations.ErrorTitleSwal, translations.ErrorSwalSubtitle, "error");
                            })
                            .catch(function (fallback) {
                                console.log(fallback);
                            });
                    }
                });
            }
        }

        self.ignore = function () {
            if (self.gridOptions.api.getSelectedNodes().length > 0) {

                swal({
                    title: translations.AreYouSureSwal,
                    text: translations.IgnoreSelectedSuggestions,
                    type: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: translations.ContinueSwal,
                    cancelButtonText: translations.CancelSwal,
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: true
                },
                    function () {
                        var itemsToIgnore = [];

                        //recorro los items seleccionados
                        angular.forEach(self.gridOptions.api.getSelectedNodes(), function (node, key) {
                            var item = node.data;

                            //si tiene sugerencia o nuevo precio lo agrego
                            if (item.priceSuggestionId || (item.newPrice != null && item.newPrice != 0)) {
                                itemsToIgnore.push(item);
                            }

                        });

                        var newPricesToIgnore = getNewPricesToSaveOrEndorse(itemsToIgnore, true);

                        if (itemsToIgnore.length > 0) {
                            priceService.decisions.ignoreSuggestions(newPricesToIgnore).then(function () {
                                swal(translations.ConfirmedSwal, translations.ConfirmedIgnoreSelectedSuggestions, "success");
                                loadData();

                            },
                                function () {
                                    swal(translations.ErrorTitleSwal, translations.ErrorWhenWantingIgnoreSuggestions, "error");
                                })
                                .catch(function (fallback) {
                                    console.log(fallback);
                                });
                        }
                        else {
                            $timeout(function () {
                                swal(translations.Attention, translations.NoChangesWereRecorded, "error");
                            }, 500);
                        }
                    });
            }
        }

        self.endorse = function () {
            if (self.gridOptions.api.getSelectedNodes().length > 0) {

                swal({
                    title: translations.DiscardConfirmTitle,
                    text: translations.EndorseTheSelectedPrices,
                    type: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: translations.ContinueSwal,
                    cancelButtonText: translations.CancelSwal,
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: true
                },
                    function () {
                        var itemsToEndorse = [];

                        //recorro los items seleccionados
                        angular.forEach(self.gridOptions.api.getSelectedNodes(), function (node, key) {
                            var item = node.data;

                            //si el status es current o new
                            if (item.status == 0 || item.status == 1) {
                                //y tiene precio o fechas nuevas
                                if ((item.newPrice && (item.newPrice != item._Price || item.priceListIsBase)) //el precio tiene que ser distinto a excepcion de la zona base donde puede ser igual pero debe guardarse el cambio. (esto es porque en la zona base se da el caso en que el precio actual es igual al sugerido y por ende al nuevo)
                                    || (item.newValidFrom && item.newValidFrom != item.validFrom)
                                    || (item.newValidFrom && item.newValidFrom != item.validFrom)) {
                                    itemsToEndorse.push(item);
                                }
                            }

                        });

                        var newPricesToEndorse = getNewPricesToSaveOrEndorse(itemsToEndorse, true);

                        if (itemsToEndorse.length > 0) {
                            priceService.decisions.endorsePrices(newPricesToEndorse).then(function () {
                                swal(translations.ConfirmedSwal, translations.ConfirmedEndorseTheSelectedPrices, "success");
                                loadData();

                            },
                                function () {
                                    swal(translations.ErrorTitleSwal, translations.ErrorWhenWantingEndorsePrices, "error");
                                }).catch(function (fallback) {
                                    console.log(fallback);
                                });
                        }
                        else {
                            $timeout(function () {
                                swal(translations.Attention, translations.NoChangesWereRecorded, "error");
                            }, 500);
                        }
                    });
            }
        }

        self.reject = function () {
            if (self.gridOptions.api.getSelectedNodes().length > 0) {
                swal({
                    title: translations.RejectionComment,
                    text: translations.RejectionCommentSubtitle,
                    type: "input",
                    showCancelButton: true,
                    closeOnConfirm: false,
                    animation: "slide-from-top",
                    //inputPlaceholder: ""
                },
                    function (inputValue) {
                        if (inputValue === false)
                            return false;

                        var pricesToReject = [];

                        // rechazo solo los seleccionados.
                        angular.forEach(self.gridOptions.api.getSelectedNodes(), function (node, key) {
                            var item = node.data;
                            pricesToReject.push(item);
                        });

                        if (pricesToReject.length > 0) {
                            // Guardar cambios

                            var currentUrl = window.location.href;
                            priceService.decisions.rejectEndorse(pricesToReject, inputValue, currentUrl)
                                .then(function (resp) {
                                    swal(translations.RejectedItems, translations.RejectedItemsSubtitle, "success");
                                    loadData();
                                }, function () {
                                    swal(translations.ErrorTitleSwal, translations.ErrorSwalSubtitle, "error");
                                }).catch(function (fallback) {
                                    console.log(fallback);
                                });
                        }
                    });
            }
        }

        self.exportToExcel = function () {
            var params = {
                skipHeader: false,
                skipFooters: false,
                skipGroups: false,
                allColumns: true,
                onlySelected: false,
                suppressQuotes: false,
                fileName: self.category + '.xls',
                columnSeparator: ','
            };

            self.gridOptions.api.exportDataAsCsv(params);
        }

        self.openChatDialog = function (priceId) {
            var newScope = $scope.$new();
            newScope.showLoader = true;

            priceService.decisions.getChatForItem(priceId).then(function (data) {
                newScope.showLoader = false;

                newScope.currentDate = new Date();
                newScope.chats = [];
                newScope.isSaving = false;
                newScope.newChat = {
                    id: 0,
                    priceId: priceId,
                    message: "",
                    pictureUrl: "defaultUser.jpg", //TODO: Reemplazar por el current userPicture
                    sendDate: new Date()
                };

                if (data && data.length > 0)
                    newScope.chats = data;

                newScope.saveMessage = function () {
                    newScope.isSaving = true;
                    priceService.decisions.saveChatForItem(newScope.newChat).then(function (resp) {
                        if (resp != null) {
                            newScope.chats.push(resp);
                            newScope.newChat.message = "";
                            newScope.isSaving = false;
                        }
                        else {
                            newScope.isSaving = true;
                        }
                    });
                }
            });

            ngDialog.open({
                template: 'chatDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-300',
                scope: newScope
            });
        }

        self.openTagsDialog = function (itemTags, itemId) {

            var tagGroups = _.groupBy(self.tags, 'group');
            angular.forEach(tagGroups, function (tg) {
                tg.selectedTags = [];
                tg.itemId = itemId
            });

            var newScope = $scope.$new();
            newScope.tagGroups = tagGroups;
            newScope.selectedItemId = itemId;

            //Load existing tags
            if (itemTags != null && itemTags != 'null') {
                var existingTagIds = itemTags.split(',');

                for (var i = 0; i < existingTagIds.length; i++) {
                    angular.forEach(newScope.tagGroups, function (tg) {
                        if (_.find(tg, function (t) {
                            return t.id == existingTagIds[i].trim();
                        }) != null) {
                            tg.selectedTags.push(parseInt(existingTagIds[i]));
                        }
                    });
                }
            }

            //SaveTags
            newScope.saveTags = function () {
                var tagIdsToSave = [];

                angular.forEach(newScope.tagGroups, function (tg) {
                    angular.forEach(tg.selectedTags, function (tagId) {
                        tagIdsToSave.push(tagId);
                    })
                });

                adminService.items.saveItemTags(tagIdsToSave, newScope.selectedItemId).then(function (resp) {
                    if (resp) {
                        ngDialog.close();
                        loadData();
                    }
                    else {
                        //Todo: Ocurrio un error, Pedir que se guarden nuevamnete.
                    }
                });
            }

            ngDialog.open({
                template: 'tagDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-height-500 tagsDialog',
                backdrop: 'static',
                keyboard: false,
                scope: newScope
            });
        };

        //TODO: TERMINAR DE HACER ESTO, CALCULA EL MARGEN EN % o $ PARA TODO EL GRUPO DE PRODUCTOS SELECCIONADOS.
        self.modifyMarginProductGroup = function (value) {
            var isPrice = value == '$';

            var totalGrossMarginAbs = 0;
            var totalSales = 0;

            if (self.gridOptions.api.getSelectedNodes().length > 0) {

                //1. Calculo el total de GrossMarginAbs

                angular.forEach(self.gridOptions.api.getSelectedNodes(), function (node, key) {
                    totalGrossMarginAbs += node.data.newGrossMarginAbs != null ? node.data.newGrossMarginAbs * node.data.units : node.data.grossMarginAbs * node.data.units;

                    var cost = node.data.newCost != null ? node.data.newCost : node.data.cost;
                    var tax = node.data.newTax != null ? node.data.newTax : node.data.tax;
                    var taxRate = node.data.newTaxRate != null ? node.data.newTaxRate : node.data.taxRate;

                    totalSales += ((node.data._Price - tax) / (1 + taxRate) + tax) * node.data.units;
                });

                var currentGrossMargin = totalGrossMarginAbs / totalSales;

                swal({
                    title: translations.ModifyMarginIn + value,
                    text: translations.ModifyMarginSubtitle + (isPrice ? $rootScope.toMoneyNoDecimals(totalGrossMarginAbs) : $rootScope.toPercentage(currentGrossMargin)) + translations.EnterNewMarginValue,
                    type: "input",
                    showCancelButton: true,
                    closeOnConfirm: false,
                    animation: "slide-from-top",
                    inputPlaceholder: translations.ValueIn + value
                },
                    function (inputValue) {
                        if (inputValue === false)
                            return false;
                        if (inputValue === "") {
                            swal.showInputError(translations.YouMustEnterValue);
                            return false
                        }
                        inputValue = inputValue.replace(',', '.');
                        if (isNaN(inputValue)) {
                            swal.showInputError(translations.YouMustEnterNumericValue);
                            return false
                        }


                        //1. Calculo el total de GrossMarginAbs
                        if (totalGrossMarginAbs != 0 && totalSales != 0) {

                            var newGrossMarginAbs = 0;
                            if (!isPrice) {
                                newGrossMarginAbs = totalSales * (inputValue / 100);
                            }
                            else {
                                newGrossMarginAbs = inputValue
                            }

                            angular.forEach(self.gridOptions.api.getSelectedNodes(), function (node, key) {
                                //Solo modifico los que tienen ventas
                                if (node.data.units != null && node.data.units != 0) {
                                    var share = node.data.newGrossMarginAbs != null ? (node.data.newGrossMarginAbs * node.data.units) / totalGrossMarginAbs : (node.data.grossMarginAbs * node.data.units) / totalGrossMarginAbs;

                                    var cost = node.data.newCost != null ? node.data.newCost : node.data.cost;
                                    var tax = node.data.newTax != null ? node.data.newTax : node.data.tax;
                                    var taxRate = node.data.newTaxRate != null ? node.data.newTaxRate : node.data.taxRate;

                                    var newPrice = ((newGrossMarginAbs * share / node.data.units) + cost) * (1 + taxRate) + tax;
                                    changePrice(newPrice, node);
                                }
                            });
                        }

                        swal(translations.MarginUpdated, translations.TheMarginWasUpdatedCorrectly1 + (isPrice ? value + inputValue : inputValue + value) + translations.TheMarginWasUpdatedCorrectly2, "success");

                    });
            }
            else {
                swal(translations.ErrorTitleSwal, translations.SelectAtLeastOnePriceToContinue, "error");
            }
        }

        self.modifyMargin = function (value) {
            swal({
                title: translations.ModifyMarginIn + value,
                text: translations.EnterNewMarginValueforSelectedProducts,
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                animation: "slide-from-top",
                inputPlaceholder: translations.ValueIn + value
            },
                function (inputValue) {
                    if (inputValue === false)
                        return false;
                    if (inputValue === "") {
                        swal.showInputError(translations.YouMustEnterValue);
                        return false
                    }
                    inputValue = inputValue.replace(',', '.');
                    if (isNaN(inputValue)) {
                        swal.showInputError(translations.YouMustEnterNumericValue);
                        return false
                    }

                    var targetMargin = inputValue / 100; //Que ingresen el valor en % y lo pasamos a decimal.

                    angular.forEach(self.gridOptions.api.getSelectedNodes(), function (node, key) {

                        if (targetMargin != 1) {
                            var cost = node.data.newCost != null ? node.data.newCost : node.data.cost;
                            var tax = node.data.newTax != null ? node.data.newTax : node.data.tax;
                            var taxRate = node.data.newTaxRate != null ? node.data.newTaxRate : node.data.taxRate;

                            var newPrice = (((cost + tax) / (1 - targetMargin)) - tax) * (1 + taxRate) + tax;
                            changePrice(newPrice, node);
                        }
                    });

                    swal(translations.MarginUpdated, translations.TheMarginWasUpdatedCorrectly1 + inputValue + value + translations.TheMarginWasUpdatedCorrectly2, "success");

                });
        }

        self.filterByStatus = function (status, filterText) {
            if (self.selectedFilterForStatus != status) {
                self.selectedFilterForStatus = status;
                loadData();
                //externalFilterChanged();
            }
        }

        self.filterByIssue = function (issue) {
            if (self.selectedFilterForIssues != issue) {
                self.selectedFilterForIssues = issue;
                externalFilterChanged();
            }
        }

        self.filterByAction = function (action) {
            if (self.selectedFilterForAction != action) {
                self.selectedFilterForAction = action;
                externalFilterChanged();
            }
        }

        self.filterByPriceType = function (priceType) {
            if (self.selectedFilterForPriceType != priceType) {

                self.selectedFilterForPriceType = priceType;

                //self.updateChart(self.timerange);

                externalFilterChanged();
            }
        }

        self.filterByPricelist = function (priceList) {
            if (self.selectedFilterForPriceList != priceList) {

                self.selectedFilterForPriceList = priceList;

                //self.updateChart(self.timerange);

                externalFilterChanged();
            }
        }

        self.filterByItemSensitivity = function (sensitivity) {
            if (self.selectedFilterForSensitivities != sensitivity) {

                self.selectedFilterForSensitivities = sensitivity;

                //self.updateChart(self.timerange);

                externalFilterChanged();
            }
        }


        self.filterByText = function () {
            var eInput = document.querySelector('#searchGridInput');
            if (eInput) {

                if ((eInput.value.length > 2 && selectedFilterForText != eInput.value) || (eInput.value == '' && selectedFilterForText != '')) {
                    selectedFilterForText = eInput.value;
                    externalFilterChanged();
                }
            }
        }

        self.dateEditor = function (params) {
            if (self.isEndorse) {
                return $rootScope.dateRenderer(params);
            }
            else {
                return $rootScope.dateEditor(params);
            }
        }

        self.updateDates = function (isValidFrom) {
            swal({
                title: translations.UpdateDates,
                text: isValidFrom ? translations.UpdateDatesSubtitleOp1 : translations.UpdateDatesSubtitleOp2,
                type: 'input',
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (inputValue) {

                    if (inputValue === false)
                        return false;

                    var timestamp = Date.parse(inputValue)

                    if (isNaN(timestamp) == false) {
                        var d = new Date(timestamp);

                        //TODO: Ver como Ponerle un DatePicker al Sweet Alert

                        angular.forEach(self.gridOptions.api.getSelectedNodes(), function (node, key) {

                            if (node.data.newPrice != null && node.data.newPrice != '' && node.data.newPrice != 0) {
                                if (isValidFrom)
                                    node.data.newValidFrom = d;
                                else
                                    node.data.newValidTo = d;
                                node.data.dirty = true;

                                self.gridOptions.api.refreshRows([node]);
                            }
                        });
                    }


                });
        }

        self.showItemInDashboard = function (itemId, priceListId) {
            $state.go('home.itemDashboard', { 'itemId': itemId, 'priceListId': priceListId });
        }

        /*
        self.updateChart = function (timerange) {

            self.timerange = timerange;

            if (self.priceDashboard == null || self.priceDashboard == '')
                return;

            var lastWeekXValues = [];
            var lastMonthXValues = [];
            var lastYearXValues = [];
            var xValues = [];
            var lastXValues = [];

            if (timerange == 'week')
                xValues = self.priceDashboard.lastWeekXValues;
            else if (timerange == 'month')
                xValues = self.priceDashboard.lastMonthXValues;
            else
                xValues = self.priceDashboard.lastYearXValues;

            var lastDateString = null;
            var lastXValue = null;

            var selectedPriceListId = 0;
            var priceList = _.filter(self.pricelists, function (o) {
                return o.name == self.selectedFilterForPriceList;
            });

            if (priceList.length > 0)
                selectedPriceListId = priceList[0].id;

            xValues.forEach(function (xValue) {

                if (lastDateString != xValue.dateString) {
                    if (lastXValue != null) {
                        lastXValue.price = lastXValue.units != 0 ? Math.round(lastXValue.sales / lastXValue.units * 100) / 100 : 0;
                        lastXValues.push(lastXValue);
                    }

                    lastXValue = { dateString: xValue.dateString, sales: 0, units: 0, margin: 0, price: 0 };
                }

                if (selectedPriceListId == 0 || (xValue.priceListId == selectedPriceListId)) {
                    lastXValue.sales += xValue.sales;
                    lastXValue.units += xValue.units;
                    lastXValue.margin += xValue.margin;
                }

                lastDateString = xValue.dateString;
            });

            if (lastXValue != null) {
                lastXValue.price = lastXValue.units != 0 ? Math.round(lastXValue.sales / lastXValue.units * 100) / 100 : 0;
                lastXValues.push(lastXValue);
            }


            if (timerange == 'week')
                lastWeekXValues = lastXValues;
            else if (timerange == 'month')
                lastMonthXValues = lastXValues;
            else
                lastYearXValues = lastXValues;

            self.chart = c3.generate({
                bindto: '#saleschart',
                data: {
                    json: (timerange == 'week' ? lastWeekXValues : timerange == 'month' ? lastMonthXValues : lastYearXValues),
                    keys: {
                        x: 'dateString',
                        value: ['sales', 'units', 'margin', 'price'],
                    },
                    axes: {
                        sales: 'y',
                        units: 'y',
                        margin: 'y',
                        price: 'y2'
                    },
                    type: 'bar',
                    types: {
                        price: 'line',
                    },
                    colors: {
                        sales: '#1ab394',
                        units: '#1c84c6',
                        margin: '#f8ac59',
                        price: '#ED5565'
                    }
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            fit: true,
                            format: function (x) {
                                if (self.timerange == 'week') {
                                    return $rootScope.shortWeekDayNames[x.getDay()];
                                } else if (self.timerange == 'month') {
                                    return x.getDate() + '-' + $rootScope.shortMonthNames[(x.getMonth())];
                                } else if (self.timerange == 'year') {
                                    return x.getFullYear() + '-' + (x.getMonth() + 1);
                                }
                            }
                        }
                    },
                    y: {
                        tick: {
                            format: d3.format("s")
                        }
                    },
                    y2: {
                        show: true,
                        label: 'Precio en $',
                        tick: {
                            format: d3.format('.1f')
                        },
                        min: 0
                    }
                },
                tooltip: {
                    format: {
                        title: function (d) {
                            var toFormat = '%B %d, %Y';
                            if (self.timerange == 'year')
                                toFormat = '%B %Y';

                            var format = d3.time.format(toFormat);
                            return format(d);
                        },
                        value: function (value, ratio, id) {
                            var format = id === 'price' ? d3.format(',.1f') : d3.format(',.0f');
                            return format(value);
                        }
                    }
                }
            });
        }
        */

        self.searchGridInputKeyPressed = function (e) {
            if (e.which === 13) { //Enter keycode
                self.filterByText();
            }
        }

        self.showOverlaps = function (overlaps) {
            self.currentOverlaps = overlaps;

            if (self.currentOverlaps.length > 0) {
                ngDialog.open({
                    template: 'overlapDialog',
                    className: 'ngdialog-theme-default ngdialog-theme-custom',
                    //data: task,
                    scope: $scope
                });
            }
        }

        function startProgress() {
            self.busy = 0;
            return $interval(function () { self.busy += 0.05; }, 250, 16);
        }

        function stopProgress(interval) {
            $interval.cancel(interval);
            self.busy = 0.95;
            $timeout(function () { self.busy = false; }, 500);
        }

        function setColumnDefinitions() {
            columnDefs = [
                {
                    headerName: translations.GeneralInformation,
                    children: [
                        {
                            id: "select",
                            field: "priceId",
                            pinned: 'left',
                            colIndex: 0,
                            width: 25,
                            suppressMenu: true,
                            suppressSorting: true,
                            filter: false,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.emptyRenderer,
                            checkboxSelection: true,
                            headerCellRenderer: function (params) {

                                if (self.gridOptions.columnApi.getRowGroupColumns().length > 0)
                                    return null;

                                var eInput = document.createElement("input");
                                eInput.type = "checkbox";
                                eInput.title = "Seleccionar todos";
                                eInput.addEventListener("change", function (event) {
                                    selectAll(this.checked);
                                });
                                return eInput;
                            },
                        },
                        {
                            headerName: translations.Subcategory,
                            field: "subCategory",
                            headerTooltip: "",
                            pinned: 'left',
                            width: numberColWidth + 50,
                            columnGroupShow: 'open',
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: translations.Code,
                            field: "code",
                            headerTooltip: "",
                            pinned: 'left',
                            width: 150,
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "UM",
                            field: "salesUnitOfMeasure",
                            headerTooltip: translations.UnitOfMeasureOfSale,
                            pinned: 'left',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: 40,
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "EAN",
                            field: "ean",
                            headerTooltip: "",
                            columnGroupShow: 'open',
                            pinned: 'left',
                            width: 160,
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: translations.Description,
                            field: "description",
                            headerTooltip: "",
                            pinned: 'left',
                            width: numberColWidth + 250,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: {
                                renderer: 'group',
                                checkbox: false,
                                innerRenderer: function (params) {
                                    var name = params.value + (params.node.group && params.node.children != null ? ' (' + params.node.children.length + ')' : '');
                                    var desc = '';

                                    var tagIds = "'" + params.data.tagIds + "'";
                                    tags = ' <a style="padding: 2px" ng-click="ct.openTagsDialog(' + tagIds + ',' + params.data.itemId + ')" title="' + translations.ClickHereToLoadTags + ' "><i class="fa fa-tags"></i> </a> ';

                                    var lastChat = params.data.observations != null && params.data.chatsQuantity != 0 ? '(' + params.data.chatsQuantity + ') ' + params.data.observations : params.data.chatsQuantity != 0 ? '(' + params.data.chatsQuantity + ') ' : '';
                                    var chat = '<a style="padding: 2px" ng-click="ct.openChatDialog(' + params.data.priceId + ')" title="' + lastChat + '"><i class="fa fa-comments-o"></i> </a>';
                                    //Click aqui para ver las conversaciones asociadas a esta decision

                                    //if (params.data.observations != null) {
                                    //    desc += ' <i class="fa fa-info-circle" title="' + params.data.observations + '"></i>&#32;';
                                    //}

                                    //Si tiene una regla de Zona muestro esa. Si tiene las 2 aparece la de Zona.
                                    if (params.data.parentRuleZone != null && params.data.parentRuleZone != '') {
                                        desc += ' <i class="fa fa-child text-warning" title="' + params.data.parentRuleZone + '"></i>&#32;';
                                    }

                                    if (params.data.parentRuleItem != null && params.data.parentRuleItem != '') {
                                        desc += ' <i class="fa fa-child" title="' + params.data.parentRuleItem + '"></i>&#32;';
                                    }

                                    desc += params.value + (params.node.group && params.node.children != null ? ' (' + params.node.children.length + ')' : '');
                                    desc = '<a style="cursor: default;">' + desc + '</a>';
                                    //desc = '<a title="Ir al dashboard para este producto" ng-click="ct.showItemInDashboard(' + params.data.itemId + ',' + params.data.priceListId + ')">' + desc + '</a>'

                                    var newLabel = '';
                                    if (params.data.priceIsFirst == true)
                                        newLabel = ' <span class="label label-warning" style="margin-top: 5px; margin-right: 5px; padding: 2px 2px; padding-right: 3px;">NUEVO</span>';

                                    var sensitivityLabel = '';
                                    if (params.data.sensitivities)
                                        sensitivityLabel = ' <span class="label label-success">' + params.data.sensitivities + '</span>';

                                    return tags + chat + desc + newLabel + sensitivityLabel;
                                }
                            }
                        },
                        {
                            headerName: translations.Area,
                            field: "priceListCode",
                            headerTooltip: "",
                            tooltipField: "priceList",
                            pinned: 'left',
                            width: 150,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: function (params) {
                                return params.data.priceListCode + "|" + params.data.priceList;
                            }
                        },
                        {
                            headerName: "TP", 
                            field: "priceTypeName",
                            headerTooltip: translations.TypeOfPrice,
                            pinned: 'left',
                            width: 90,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer:
                                function (params) {
                                    var rv;
                                    if (params.data.priceType == 5) {
                                        rv = '<span style="cursor: pointer;" ng-click="ct.openRegularPrice(' + params.data.itemId + ',' + params.data.salesUnitOfMeasureId + ',' + params.data.priceListId + ')">' + self.priceTypes[params.data.priceType + 1] + '</span>';
                                    }
                                    else {
                                        rv = self.priceTypes[params.data.priceType + 1];
                                    }
                                    return rv;
                            }
                        },
                        {
                            headerName: translations.Brand,
                            field: "brand",
                            headerTooltip: "",
                            pinned: 'left',
                            width: numberColWidth,
                            columnGroupShow: 'open',
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: translations.Maker,
                            field: "manufacturer",
                            headerTooltip: "",
                            pinned: 'left',
                            width: numberColWidth,
                            columnGroupShow: 'open',
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: translations.Tags,
                            columnGroupShow: 'open',
                            field: "tags",
                            pinned: 'left',
                            width: 200,
                            cellClass: $rootScope.getClassForCell
                        }]
                },
                {
                    headerName: translations.NewPrice,
                    children: [
                        {
                            headerName: translations.Present,
                            field: "_Price",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: function (params) {
                                if (params.value == null || params.value == 0 || params.value == '')
                                    return null;

                                if (self.hidePricesCopiedFromOthers && params.data.copiedFromPriceId != null && params.data.priceType == 5)
                                    return null;

                                return "$ " + $filter('number')(params.value, 2);
                            }
                        },
                        {
                            headerName: translations.SuggestedS,
                            field: "suggestedPrice",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.backgroundNumberClass,
                            cellRenderer: $rootScope.moneyRenderer
                        },
                        {
                            headerName: translations.NewSingular,
                            field: "newPrice",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: moneyEditor
                        },
                        {
                            headerName: "Var %",
                            field: "priceDelta",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForImpactCell,
                            cellRenderer: function (params) {
                                var eCell = document.createElement('div');
                                eCell.className = "input-group";
                                eCell.style = "width:100% !important;height:100% !important";

                                if (IsVariationOutOfGuidelines(params.data)) {
                                    var eIcon = document.createElement('i');
                                    eIcon.className = "fa fa-exclamation-triangle text-danger mr10";
                                    eIcon.title = ian.format( translations.ThePriceVariationLimitHasBeenExceeded, $filter('percentage')(params.data.minVariation), $filter('percentage')(params.data.maxVariation));
                                    eCell.appendChild(eIcon);
                                }

                                var eSpan = document.createElement("span");
                                eSpan.textContent = params.value != null ? $rootScope.percentageRenderer(params) : '';
                                eCell.appendChild(eSpan);

                                return eCell;
                            }
                        },
                        {
                            headerName: "Var $",
                            field: "priceDeltaAmount",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForImpactCell,
                            cellRenderer: function (params) {
                                var eCell = document.createElement('div');
                                eCell.className = "input-group";
                                eCell.style = "width:100% !important;height:100% !important";

                                if (IsVariationAmountOutOfGuidelines(params.data)) {
                                    var eIcon = document.createElement('i');
                                    eIcon.className = "fa fa-exclamation-triangle text-danger mr10";
                                    eIcon.title = ian.format(translations.ThePriceVariationLimitHasBeenExceeded, $filter('currency')(params.data.minVariationAmount), $filter('currency')(params.data.maxVariationAmount));
                                    eCell.appendChild(eIcon);
                                }

                                var eSpan = document.createElement("span");
                                eSpan.textContent = params.value != null ? $rootScope.moneyRenderer(params) : '';
                                eCell.appendChild(eSpan);

                                return eCell;
                            }
                        }]
                },
                {
                    headerName: translations.CurrentPrice1,
                    hide: self.hideCurrentPrices,
                    children: [
                        {
                            headerName: translations.Current,
                            field: "currentPrice",
                            width: numberColWidth,
                            hide: self.hideCurrentPrices,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer:
                            function (params) {
                                return '<span style="cursor: pointer;" ng-click="ct.openCurrentPriceModal(' + params.data.itemId + ',' + params.data.salesUnitOfMeasureId + ',' + params.data.priceListId + ')">' + ((params.value != null && params.value != 0 && params.value != '') ? ("$ " + $filter('number')(params.value, 2)) : "") + '&nbsp;&nbsp;  <i class="fa fa-tasks" aria-hidden="true"></i>' + '</span>';
                            }
                        },
                        {
                            headerName: "TP Vig.",
                            field: "currentPriceType",
                            headerTooltip: translations.TypeOfPriceForce,
                            width: numberColWidth,
                            hide: self.hideCurrentPrices,
                            width: 90,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer:
                            function (params) {
                                if (params.value != null)
                                    return self.priceTypes[params.value + 1];
                                else
                                    return "*";
                            }
                        },
                        {
                            headerName: translations.From,
                            field: "currentPriceValidFrom",
                            width: numberColWidth,
                            hide: self.hideCurrentPrices,
                            filter: 'date',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer:
                            function (params) {
                                if (params.data.currentPriceValidFrom == null)
                                    return "*";

                                if (params.value == null)
                                    return "";

                                return new Date(params.value).toISOString().slice(0, 10);
                            }
                        },
                        {
                            headerName: translations.To,
                            field: "currentPriceValidTo",
                            width: numberColWidth,
                            hide: self.hideCurrentPrices,
                            filter: 'date',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: function (params) {
                                if (params.data.currentPrice == null)
                                    return "*";

                                if (params.value == null)
                                    return "";

                                return new Date(params.value).toISOString().slice(0, 10);
                            }
                        }
                        //{
                        //    headerName: "Motivo",
                        //    field: "reason",
                        //    width: numberColWidth,
                        //    hide: self.hideCurrentPrices,
                        //    filter: 'date',
                        //    cellClass: $rootScope.getClassForCell,

                        //}
                    ]
                },
                {
                    headerName: translations.Cost,
                    children: [
                        {
                            headerName: translations.Original,
                            field: "costWithTaxes",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRenderer
                        },
                        {   //SOLO PARA 
                            headerName: translations.Average,
                            field: "averageCost",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRenderer
                        },
                        {
                            headerName: translations.LastCost,
                            field: "newCostWithTaxes",
                            width: numberColWidth + 15,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRenderer
                        },
                        {
                            headerName: "Var %",
                            field: "costDelta",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRenderer
                        }]
                },
                {
                    headerName: translations.CompetitivenessIndex,
                    children: [
                        {
                            headerName: translations.Competitor,
                            field: "competitorsPrice",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRenderer
                        },
                        {
                            headerName: "IC",
                            field: "competitorsPriceVsCurrent",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForImpactCellInverse,
                            cellRenderer: $rootScope.percentageRenderer
                        },
                        {
                            headerName: translations.SuggestedCI,
                            field: "competitorsPriceVsSuggested",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForImpactCellInverse,
                            cellRenderer: $rootScope.percentageRenderer
                        },
                        {
                            headerName: translations.NewCI,
                            field: "competitorsPriceVsNew",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForImpactCellInverse,
                            cellRenderer: $rootScope.percentageRenderer
                        }]
                },
                {
                    headerName: translations.Sale,
                    children: [
                        {
                            headerName: translations.Volume,
                            field: "units",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.integerRenderer
                        },
                        {
                            headerName: translations.SalesInDollars,
                            field: "sales",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: translations.NewSale,
                            field: "newSales",
                            width: numberColWidth + 10,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        }
                    ]
                },
                {
                    headerName: translations.Impact,
                    children: [
                        {
                            headerName: translations.SuggestedS,
                            field: "suggestedImpact",
                            width: numberColWidth,
                            hide: self.isEndorse,
                            filter: 'number',
                            cellClass: $rootScope.getClassForImpactCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: translations.NewSingular,
                            field: "newImpact",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForImpactCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        }]
                },
                {
                    headerName: translations.Margin,
                    children: [
                        {
                            headerName: translations.Present,
                            field: "grossMargin",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForMarginCell,
                            cellRenderer: $rootScope.percentageRendererWithZero
                        },
                        {
                            //SOLO COLOMBIA
                            headerName: translations.CurrentAverage,
                            field: "grossMarginAverage",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForMarginCell,
                            cellRenderer: $rootScope.percentageRendererWithZero
                        },
                        {
                            headerName: translations.SuggestedS,
                            field: "suggestedGrossMargin",
                            width: numberColWidth,
                            hide: self.isEndorse,
                            filter: 'number',
                            cellClass: $rootScope.getClassForMarginCell,
                            cellRenderer: $rootScope.percentageRenderer
                        },
                        {
                            headerName: translations.SuggestedAverage,
                            field: "suggestedGrossMarginAvg",
                            width: numberColWidth,
                            hide: self.isEndorse,
                            filter: 'number',
                            cellClass: $rootScope.getClassForMarginCell,
                            cellRenderer: $rootScope.percentageRenderer
                        },
                        {
                            headerName: translations.PercentajeNew,
                            field: "newGrossMargin",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForMarginCell,
                            //cellRenderer: $rootScope.percentageRendererNoDecimals,
                            cellRenderer: function (params) {
                                var eCell = document.createElement('div');
                                eCell.className = "input-group";
                                eCell.style = "width:100% !important;height:100% !important";

                                if (IsMarginOutOfGuidelines(params.data)) {
                                    var eIcon = document.createElement('i');
                                    eIcon.className = "fa fa-exclamation-triangle text-danger mr10";

                                    if (params.data.minMargin != null && (params.data.newGrossMargin < params.data.minMargin || params.data.newGrossMargin > params.data.maxMargin)) {
                                        eIcon.title = ian.format(translations.TheMarginsLimitHasBeenExceeded, $filter('percentage')(params.data.minMargin), $filter('percentage')(params.data.maxMargin));
                                    }
                                    else if (params.data.minMarginAmount != null && (params.data.newGrossMarginAbs < params.data.minMarginAmount || params.data.newGrossMarginAbs > params.data.maxMarginAmount)) {
                                        eIcon.title = ian.format(translations.TheMarginsLimitHasBeenExceeded, $filter('currency')(params.data.minMarginAmount), $filter('currency')(params.data.maxMarginAmount));
                                    }


                                    eCell.appendChild(eIcon);
                                }

                                var eSpan = document.createElement("span");
                                eSpan.textContent = params.value != null ? $rootScope.percentageRendererWithZero(params) : '';
                                eCell.appendChild(eSpan);

                                return eCell;
                            }

                        },
                        {
                            headerName: translations.DolarNew,
                            field: "newGrossMarginAbs",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForMarginCell,
                            //cellRenderer: $rootScope.percentageRendererNoDecimals,
                            cellRenderer: function (params) {
                                var eCell = document.createElement('div');
                                eCell.className = "input-group";
                                eCell.style = "width:100% !important;height:100% !important";

                                if (IsMarginAmountOutOfGuidelines(params.data)) {
                                    var eIcon = document.createElement('i');
                                    eIcon.className = "fa fa-exclamation-triangle text-danger mr10";

                                    if (params.data.minMargin != null && (params.data.newGrossMargin < params.data.minMargin || params.data.newGrossMargin > params.data.maxMargin)) {
                                        eIcon.title = ian.format(translations.TheMarginsLimitHasBeenExceeded, $filter('percentage')(params.data.minMargin), $filter('percentage')(params.data.maxMargin));
                                    }
                                    else if (params.data.minMarginAmount != null && (params.data.newGrossMarginAbs < params.data.minMarginAmount || params.data.newGrossMarginAbs > params.data.maxMarginAmount)) {
                                        eIcon.title = ian.format(translations.TheMarginsLimitHasBeenExceeded, $filter('currency')(params.data.minMarginAmount), $filter('currency')(params.data.maxMarginAmount));
                                    }


                                    eCell.appendChild(eIcon);
                                }

                                var eSpan = document.createElement("span");
                                eSpan.textContent = params.value != null ? $rootScope.moneyRenderer(params) : '';
                                eCell.appendChild(eSpan);

                                return eCell;
                            }

                        },
                        {
                            headerName: translations.Target,
                            field: "targetMargin",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererWithZero
                        },
                        {
                            headerName: translations.Minimum,
                            field: "minMargin",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererWithZero
                        },
                        {
                            headerName: translations.Maximum,
                            field: "maxMargin",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.percentageRendererWithZero
                        }]
                },
                {
                    headerName: translations.ValidityDates,
                    children: [
                        {
                            headerName: translations.From,
                            field: "validFrom",
                            width: numberColWidth,
                            filter: 'text',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.dateRenderer
                        },
                        {
                            headerName: translations.To,
                            field: "validTo",
                            width: numberColWidth,
                            filter: 'text',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.dateRenderer
                        },
                        {
                            headerName: translations.FromN,
                            field: "newValidFrom",
                            width: editableColWidth,
                            filter: 'text',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: self.dateEditor
                        },
                        {
                            headerName: translations.ToN,
                            field: "newValidTo",
                            width: editableColWidth,
                            filter: 'text',
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: self.dateEditor
                        },
                        {
                            headerName: translations.OverlapsAbbreviation,
                            width: numberColWidth,
                            field: "overlappingPromotions",
                            cellRenderer: function (params) {

                                if (params.data.overlappingPromotions && params.data.overlappingPromotions.length > 0) {
                                    var eCell = document.createElement('div');
                                    eCell.className = "input-group";
                                    eCell.style = "width:100% !important;height:100% !important";

                                    var eIcon = document.createElement('i');
                                    eIcon.className = "fa fa-exclamation-triangle text-danger mr10 backgroundInput";
                                    eIcon.title = translations.ThisPriceHasAnOverlapWithAPromotion;
                                    eCell.appendChild(eIcon);

                                    return eCell;
                                }


                                return '';
                            }
                        }
                    ]
                }
            ]
        }

        function setGridOptions() {

            

            self.gridOptions = {
                columnDefs: columnDefs,
                enableColResize: true,
                enableSorting: true,
                enableFilter: true,
                groupHeaders: true,
                groupKeys: undefined, //set as string of keys eg ["region","country"],
                groupHideGroupColumns: true,
                //groupUseEntireRow: false,
                groupSelectsChildren: false,
                rowHeight: 30,
                rowSelection: "multiple", // one of ['single','multiple'], leave blank for no selection
                singleClickEdit: true,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                suppressColumnMoveAnimation: $rootScope.suppressColumnMoveAnimation,
                suppressCellSelection: true,
                suppressRowClickSelection: true,
                enableRangeSelection: false,
                onRowSelected: rowSelected,
                onCellClicked: cellClicked,
                angularCompileRows: true,
                isExternalFilterPresent: isExternalFilterPresent,
                doesExternalFilterPass: doesExternalFilterPass,
                getNodeChildDetails: getNodeChildDetails
            }
        }

        function getNodeChildDetails(rowItem) {
            if (rowItem.children && rowItem.children.length > 0) {
                return {
                    group: true,
                    expanded: false,
                    // provide ag-Grid with the children of this group
                    children: rowItem.children,
                    // the key is used by the default group cellRenderer
                    key: rowItem.priceId
                };
            }
            else {
                return null;
            }
        }

        function cellClicked(cell) {
            if (cell.colDef.field == "suggestedPrice") {
                if (cell.data != null && cell.data.suggestedPrice != null)
                    showSuggestionModal(cell.data);
            }
            else if (cell.colDef.field == "competitorsPrice") {
                if (cell.data != null)
                    $state.go('prices.priceMap', { itemId: cell.data.itemId, priceListId: cell.data.priceListId });
            }
            else if (cell.colDef.field == "overlappingPromotions") {
                if (cell.data != null)
                    self.showOverlaps(cell.data.overlappingPromotions);
            }
        }

        function externalFilterChanged() {
            self.gridOptions.api.onFilterChanged();
        }

        function containsAllTags(splittedText, tags) {
            if (splittedText.length == 1)
                return tags.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1;
            else {
                var rv = true;
                _.forEach(splittedText, function (value) {
                    var containsTag = _.includes(tags.toLowerCase().trim(), value.toLowerCase().trim());
                    if (!containsTag)
                        rv = false;
                });

                return rv;
            }
        }

        function rowSelected(event) {
            if (event.node.selected) {
                var data = event.node.data;

                if (data.suggestedPrice) { //Si tengo precio sugerido
                    if (!data.newPrice)    //Y no tengo un nuevo precio, le pongo el sugerido al seleccionarlo
                        changePrice(data.suggestedPrice, event.node);
                }
            }
            else {
                if (!event.node.data.hasNewPriceFromDb) {
                    //si no tenia nuevo precio anterior lo blanqueo
                    changePrice(null, event.node, true);
                }
                else if (event.node.data.originalNewPrice) {
                    //si tenia nuevo precio anterior vuelvo a ese precio
                    changePrice(event.node.data.originalNewPrice, event.node, true);
                }
                else if (event.node.data.suggestedPrice) {
                    self.numberOfSuggestionsAccepted--;
                    self.percentageOfAcceptedSuggestions = self.numberOfSuggestionsAccepted > 0 ? Math.round((self.numberOfSuggestionsAccepted / self.numberOfSuggestions) * 10000) / 10000 : 0;
                }
            }
        }

        function selectAll(checked) {
            self.gridOptions.api.forEachNodeAfterFilter(function (node) {
                node.setSelected(checked);
            });
        }

        function moneyEditor(params) {
            if (self.isEndorse) {
                return $rootScope.moneyRenderer(params);
            }
            else {
                var editing = false;

                var eCell = document.createElement('div');
                eCell.style = "width:100% !important;height:100% !important";
                eCell.className = "input-group";
                var eLabel = params.value != null ? document.createTextNode($rootScope.moneyRenderer(params)) : document.createTextNode('');
                eCell.appendChild(eLabel);

                var eInput = document.createElement("input");
                eInput.type = "number";
                eInput.step = "0.01";
                eInput.className = "form-control";
                var eSpan = document.createElement("span");
                eSpan.className = "input-group-addon";
                eSpan.style = "margin:0px !important; height: 100% !important; border: 0px; line-height: 100%; font-size:12px !important";
                eSpan.innerText = "$";


                eCell.addEventListener('click', function () {
                    if (!editing) {
                        eCell.removeChild(eLabel);
                        eCell.appendChild(eSpan);
                        eCell.appendChild(eInput);
                        eInput.focus();
                        eInput.value = params.value;
                        editing = true;
                    }
                });

                eInput.addEventListener('blur', function () {
                    if (editing) {
                        editing = false;
                        eCell.removeChild(eSpan);
                        eCell.removeChild(eInput);
                        eCell.appendChild(eLabel);
                    }
                });

                eInput.addEventListener('change', function () {
                    if (editing) {
                        editing = false;
                        if (eInput.value <= 0)
                            eInput.value = '';

                        params.value = eInput.value;

                        changePrice(params.value, params.node);

                        eLabel.nodeValue = $rootScope.moneyRenderer(params);
                        eCell.removeChild(eSpan);
                        eCell.removeChild(eInput);
                        eCell.appendChild(eLabel);
                    }
                });

                return eCell;
            }
        }

        function marginGuidelinesAlert(message) {
            changePriceAfterGuidelineCheck(changePriceNewPrice, changePriceRowNode);

            //swal({
            //    title: "Esta seguro?",
            //    text: message,
            //    type: "warning",
            //    showCancelButton: true,
            //    confirmButtonColor: "#1AB394",
            //    confirmButtonText: "Continuar",
            //    cancelButtonText: "Cancelar",
            //    closeOnConfirm: false,
            //    closeOnCancel: false
            //},
            //  function (isConfirm) {
            //      if (isConfirm) {
            //          swal("Confirmado", "Se cambio el precio (requerira endoso)", "success");
            //          changePriceAfterGuidelineCheck(changePriceNewPrice, changePriceRowNode);
            //      } else {
            //          swal("Cancelado", "Se conservo el precio anterior", "error");
            //          self.gridOptions.api.refreshRows([changePriceRowNode]);
            //      }
            //  });
        }

        function showSuggestionModal(data) {

            data.priceSurveys = [];
            data.code = data.code.replace(/^0+/, '');

            data.priceSurveyTypes = ['', translations.Basket, 'EXTRA', 'PRICEINDEX', translations.Promotion, translations.Own, 'WEB'];

            var newScope = $scope.$new();
            newScope.navigateToPriceMap = function (itemId, priceListId) {
                $state.go('prices.priceMap', { itemId: itemId, priceListId: priceListId });
                ngDialog.close();
            }

            ngDialog.open({
                template: 'suggestionDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                data: data,
                scope: newScope
            });

            priceService.pricesurveys.onlyActive(data.itemId, data.priceListId, false)
                .then(function (priceSurveys) {
                    loadingPriceSurveys = false;
                    data.priceSurveys = priceSurveys;
                });
        }

        self.showCurrentPriceLoader = false;

        self.openCurrentPriceModal = function (itemId, salesUnitOfMeasureId, priceListId) {

            var newScope = $scope.$new();

            self.showCurrentPriceLoader = true;
            ngDialog.open({
                template: 'currentPriceDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-900',
                scope: newScope
            });

            priceService.decisions.getCurrentPrices(itemId, salesUnitOfMeasureId, priceListId)
                .then(function (prices) {
                    self.currentPrices = prices;
                    self.showCurrentPriceLoader = false;

                });
        }

        self.openRegularPrice = function (itemId, salesUnitOfMeasureId, priceListId) {

            var newScope = $scope.$new();
            newScope.regularPrice = 0;
            newScope.showRegularPrice = true;

            priceService.decisions.getRegularPrice(itemId, salesUnitOfMeasureId, priceListId)
                .then(function (price) {
                    newScope.regularPrice = price;
                    newScope.showRegularPrice = false;
                });

            ngDialog.open({
                template: 'regularPriceDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-300',
                scope: newScope
            });
        }

        function getNewPricesToSaveOrEndorse(itemsToSave, endorse) {

            var newPrices = [];

            if (itemsToSave.length > 0) {
                //Como usamos este mismo control para endosar precios tenemos que identificar que acci�n estamos llevando a cabo para pasarsela al objeto que se va a guardar
                //2 = Endoso, 1 = Guardado Regular
                var priceDecisionAction = endorse ? 2 : 1;

                newPrices = _.map(itemsToSave, function (x) {
                    return {
                        priceId: x.priceId,
                        itemId: x.itemId,
                        salesUnitOfMeasureId: x.salesUnitOfMeasureId,
                        priceListId: x.priceListId,
                        priceType: x.priceType,
                        _Price: x._Price,
                        validFrom: x.validFrom,
                        validTo: x.validTo,
                        newPrice: x.newPrice,
                        newValidFrom: x.newValidFrom,
                        newValidTo: x.newValidTo,
                        status: x.status,
                        suggestedPrice: x.suggestedPrice,
                        priceSuggestionId: x.priceSuggestionId,
                        priceDecisionAction: priceDecisionAction,
                        minMargin: x.minMargin,
                        maxMargin: x.maxMargin,
                        minVariation: x.minVariation,
                        maxVariation: x.maxVariation,
                        significantVariation: x.significantVariation,
                        significantVariationInMargin: x.significantVariationInMargin,
                        parentRuleItemId: x.parentRuleItemId,
                        parentRuleZoneId: x.parentRuleZoneId,
                        mainItemId: x.mainItemId,
                        priceListIsBase: x.priceListIsBase,
                        priceIsFirst: x.priceIsFirst,
                        mainPriceListId: x.mainPriceListId,
                        children: _.map(x.children, function (child) {
                            return {
                                priceId: child.priceId,
                                itemId: child.itemId,
                                salesUnitOfMeasureId: child.salesUnitOfMeasureId,
                                priceListId: child.priceListId,
                                _Price: child._Price,
                                validFrom: child.validFrom,
                                validTo: child.validTo,
                                newPrice: child.newPrice,
                                newValidFrom: child.newValidFrom,
                                newValidTo: child.newValidTo,
                                status: child.status,
                                suggestedPrice: child.suggestedPrice,
                                priceSuggestionId: child.priceSuggestionId,
                                priceDecisionAction: child.priceDecisionAction,
                                minMargin: child.minMargin,
                                maxMargin: child.maxMargin,
                                minVariation: child.minVariation,
                                maxVariation: child.maxVariation,
                                significantVariation: child.significantVariation,
                                significantVariationInMargin: child.significantVariationInMargin,
                                parentRuleItemId: child.parentRuleItemId,
                                parentRuleZoneId: child.parentRuleZoneId,
                                mainItemId: child.mainItemId,
                                priceListIsBase: child.priceListIsBase,
                                priceIsFirst: child.priceIsFirst,
                                mainPriceListId: child.mainPriceListId
                            }
                        })
                    }
                });
            }

            return newPrices;
        }

        function saveNewPrices(alertIfNochangesToSave) {
            var itemsToSave = [];

            //Agregamos los items seleccionados que tengan un Nuevo Precio distinto al precio actual o un 
            angular.forEach(self.gridOptions.api.getSelectedNodes(), function (node, key) {
                var item = node.data;

                if (item.dirty) {
                    //tiene que haber un cambio de precio 
                    //o bien ser un precio de zona base donde la sugerencia siempre es igual al precio actual
                    //o bien ser un precio de tipo competencia, el cual tendr� una extension de fechas al marcar la categor�a como revisada.
                    if ((item.newPrice && (item.newPrice != item._Price || item.priceListIsBase || item.priceType == 5)) 
                        || (item.newValidFrom && item.newValidFrom != item.validFrom)
                        || (item.newValidFrom && item.newValidFrom != item.validFrom)) {
                        itemsToSave.push(item);
                    }
                }
            });

            var newPrices = getNewPricesToSaveOrEndorse(itemsToSave, false);

            if (itemsToSave.length > 0) {
                return priceService.decisions.saveNewPrices($stateParams.categoryId, newPrices);
            }
            else if (alertIfNochangesToSave) {
                $timeout(function () {
                    swal(translations.Attention, translations.NoChangesWereRecorded, "warning");
                }, 500);
            }
        }

        function markAsReviewed(priceType) {

            var subCategory = $stateParams.subcategory != '' ? $stateParams.subcategory : ' ';

            priceService.decisions.markAsReviewed($stateParams.categoryId, $stateParams.categoryGroup, $stateParams.category, subCategory, priceType, true)
                .then(function () {
                    swal(translations.ConfirmedSwal, translations.ConfirmedSwalSubtitle, "success");
                    loadData();
                },
                function () {
                    swal(translations.ErrorTitleSwal, translations.ErrorWhenWantingToMarkThisCategoryAsRevised, "error");
                }).catch(function (fallback) {
                    console.log(fallback);
                });
        }

        function isExternalFilterPresent() {
            return self.selectedFilterForStatus != translations.All || self.selectedFilterForPriceList != translations.Allf || self.selectedFilterForSensitivities != translations.Allf || selectedFilterForText != '' || self.selectedFilterForPriceType != translations.All || self.selectedFilterForIssues != translations.Allf || self.selectedFilterForAction != translations.Allf;
        }

        function showParentBecauseOfChildStatus(rowNode) {
            var filter = false;

            if (rowNode.data.children && rowNode.data.children.length > 0) {

                for (i = 0; i < rowNode.data.children.length; i++) {
                    var childNode = rowNode.data.children[i];

                    switch (self.selectedFilterForStatus) {
                        case translations.ForEndorsement: filter = (childNode.status == 1); break;
                        case translations.Endorsed: filter = (childNode.status == 2); break;
                        case translations.New: filter = (childNode.newPrice != null); break;
                        case translations.Suggested: filter = (childNode.suggestedPrice != null && childNode.priceSuggestionStatus == 0); break;
                        default: filter = true; break;
                    }

                    //Si el resultado fue True, dejo de evaluar. Si fue false sigo evaluando el resto de los hijos
                    if (filter)
                        break;
                }
            }

            return filter;
        }

        var rowNodeDataComparative;//variable auxiliar para comparar dentro de la funcion doesExternalFilterPass

        function doesExternalFilterPass(node) {

            var filter1 = true;
            var filter2 = true;
            var filter3 = true;
            var filter4 = true;
            var filter5 = true;
            var filter6 = true;
            var filter7 = true;

            var rowNode = node;
            if (node.parent != null)
                rowNode = node.parent;

            filter1 = (rowNode.data.priceList == self.selectedFilterForPriceList || self.selectedFilterForPriceList == translations.Allf);

            switch (self.selectedFilterForStatus) {
                case translations.ForEndorsement: filter2 = (rowNode.data.status == 1 && rowNode.data.readyToEndorse); break;
                case translations.Endorsed: filter2 = (rowNode.data.status == 2); break;
                case translations.New: filter2 = (rowNode.data.newPrice != null); break;
                case translations.Suggested: filter2 = (rowNode.data.suggestedPrice != null && rowNode.data.priceSuggestionStatus == 0); break;
                default: filter2 = true; break;
            }

            //Si el padre no cumple con las condiciones de arriba, entonces chequeo si los hijos las cumplen
            //TODO: por ahora esto se hace con el status. Chequear si lo tenemos que hacer tambien con los otros Filtros.
            if (!filter2) {
                filter2 = showParentBecauseOfChildStatus(rowNode);
            }

            var splittedText = selectedFilterForText.split(';');
            filter3 = (selectedFilterForText == ''
                || (rowNode.data.code != null && rowNode.data.code.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1)
                || (rowNode.data.description != null && rowNode.data.description.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1)
                || (rowNode.data.brand != null && rowNode.data.brand.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1)
                || (rowNode.data.manufacturer != null && rowNode.data.manufacturer.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1))
                || (rowNode.data.tags != null && containsAllTags(splittedText, rowNode.data.tags));

            if (!filter3) {
                var children = rowNode.data.children;
                angular.forEach(children, function (c) {
                    if (selectedFilterForText == ''
                        || (c.code != null && c.code.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1)
                        || (c.description != null && c.description.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1)
                        || (c.brand != null && c.brand.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1)
                        || (c.manufacturer != null && c.manufacturer.toLowerCase().indexOf(selectedFilterForText.toLowerCase()) != -1)
                        || (c.tags != null && containsAllTags(splittedText, c.tags)))
                    {
                        filter3 = true;
                    }
                });
            }

            filter4 = (self.priceTypes[rowNode.data.priceType + 1] == self.selectedFilterForPriceType || self.selectedFilterForPriceType == translations.All);

            /*
            if (rowNode.data.itemCode == '466522') {
                console.log(rowNode.data.significantVariation);
                console.log(rowNode.data.significantVariationInMargin);
                console.log(Math.abs(rowNode.data.newImpact));
            }
            */

            rowNodeDataComparative = rowNode.data;
            if (rowNodeDataComparative.newPrice == null && rowNodeDataComparative.suggestedPrice != null)
                rowNodeDataComparative = simulateSuggestionAcceptance(rowNodeDataComparative); //simula cambio de precio para calcular los datos necesarios para pasar por FilterForIssues y  FilterForAction  

            switch (self.selectedFilterForIssues) {
                case translations.NewPrices: filter5 = (rowNode.data.priceIsFirst == true); break;
                case translations.StrongFalls: filter5 = (rowNodeDataComparative.newPrice != null && rowNode.data.minVariation != null && rowNode.data.minVariation != 0 && rowNodeDataComparative.priceDelta < rowNode.data.minVariation); break;
                case translations.StrongRises: filter5 = (rowNodeDataComparative.newPrice != null && rowNode.data.maxVariation != null && rowNode.data.maxVariation != 0 && rowNodeDataComparative.priceDelta > rowNode.data.maxVariation); break;
                case translations.DepressedMargins: filter5 = (rowNodeDataComparative.newPrice != null && rowNode.data.minMargin != null && rowNode.data.minMargin != 0 && rowNodeDataComparative.newGrossMargin < rowNode.data.minMargin); break;
                case translations.HighMargins: filter5 = (rowNodeDataComparative.newPrice != null && rowNode.data.maxMargin != null && rowNode.data.maxMargin != 0 && rowNodeDataComparative.newGrossMargin > rowNode.data.maxMargin); break;
                case translations.NegativeMargins: filter5 = (rowNodeDataComparative.newGrossMargin != null && rowNodeDataComparative.newGrossMargin < 0); break;
                case translations.LittleSignificantChanges: filter5 = ((rowNodeDataComparative.newPrice != null && rowNode.data.significantVariation != null && rowNode.data.significantVariation != 0 && Math.abs(rowNodeDataComparative.priceDelta) < rowNode.data.significantVariation)
                    || (rowNodeDataComparative.newPrice != null && rowNode.data.significantVariationInMargin != null && rowNode.data.significantVariationInMargin != 0 && Math.abs(rowNodeDataComparative.newImpact) < rowNode.data.significantVariationInMargin)); break;
                case translations.SuggestedNotRespected: filter5 = (rowNodeDataComparative.newPrice != null && rowNodeDataComparative.newPrice != 0 && rowNode.data.suggestedPrice != null && rowNode.data.suggestedPrice != 0 && rowNode.data.suggestedPrice != rowNodeDataComparative.newPrice); break;
                case translations.Overlaps: filter5 = (rowNode.data.overlappingPromotions && rowNode.data.overlappingPromotions.length > 0); break;

                default: filter5 = true; break;
            }

            switch (self.selectedFilterForAction) {
                case translations.Falls: filter6 = (rowNodeDataComparative.newPrice != null && rowNodeDataComparative.newPrice != 0 && rowNodeDataComparative.newPrice < rowNode.data._Price); break;
                case translations.Rises: filter6 = (rowNodeDataComparative.newPrice != null && rowNodeDataComparative.newPrice != 0 && rowNodeDataComparative.newPrice > rowNode.data._Price); break;
                default: filter6 = true; break;
            }

            //Filter by sensitivities
            filter7 = (rowNode.data.sensitivities && rowNode.data.sensitivities.toLowerCase() == self.selectedFilterForSensitivities.toLowerCase() || self.selectedFilterForSensitivities == translations.Allf || (self.selectedFilterForSensitivities == translations.Any && !rowNode.data.sensitivities));

            return filter1 && filter2 && filter3 && filter4 && filter5 && filter6 && filter7;
        }

        function externalFilterChanged() {
            self.gridOptions.api.onFilterChanged();
        }

        function changePrice(newPrice, rowNode, revert) {

            var isRevert = revert ? true : false;

            if (rowNode.data._Price != null) {
                if (newPrice != null && newPrice != '' && newPrice != 0) {
                    //guardo el precio anterior para no incrementar el conta=dor de cambios si es una modificacion del mismo precio
                    var oldNewPrice = rowNode.data.newPrice;

                    //si viene con un newPrice de la BBDD y lo estoy cambiando lo guardo en originalNewPrice para poder hacer un posible revert
                    if (rowNode.data.hasNewPriceFromDb && rowNode.data.newPrice && !rowNode.data.originalNewPrice) {
                        rowNode.data.originalNewPrice = rowNode.data.newPrice
                    }

                    //Chequeo que est� cumpliendo con los lineamientos de margen
                    var cost = rowNode.data.newCost != null ? rowNode.data.newCost : rowNode.data.cost;
                    var tax = rowNode.data.newTax != null ? rowNode.data.newTax : rowNode.data.tax;
                    var taxRate = rowNode.data.newTaxRate != null ? rowNode.data.newTaxRate : rowNode.data.taxRate;
                    rowNode.data.newGrossMarginAbs = (cost + tax) != 0 ? (((newPrice - tax) / (1 + taxRate)) - cost) : 0;

                    //Actualizo variables relacionadas al nuevo precio
                    rowNode.data.newGrossMargin = (cost + tax) != 0 ? (((newPrice - tax) / (1 + taxRate)) - cost) / (((newPrice - tax) / (1 + taxRate)) + tax) : null;
                    changePriceNewImpact = (rowNode.data.newGrossMarginAbs - rowNode.data.grossMarginAbs) * rowNode.data.units;
                    rowNode.data.newPrice = newPrice;
                    rowNode.data.priceDelta = (newPrice / rowNode.data._Price) - 1;
                    rowNode.data.priceDeltaAmount = newPrice - rowNode.data._Price;
                    rowNode.data.competitorsPriceVsNew = rowNode.data.competitorsPrice != 0 && rowNode.data.competitorsPrice != null ? (newPrice / rowNode.data.competitorsPrice) - 1 : 0;
                    rowNode.data.newSales = newPrice * rowNode.data.units;

                    //Si la sugerencia tiene fechas de vigencia y el campo de nuevas fechas est� en nulo, entonces seteo las fechas de la sugerencia
                    if (rowNode.data.newValidFrom == null && rowNode.data.priceSuggestionValidFrom != null)
                        rowNode.data.newValidFrom = rowNode.data.priceSuggestionValidFrom;

                    if (rowNode.data.newValidTo == null && rowNode.data.priceSuggestionValidTo != null)
                        rowNode.data.newValidTo = rowNode.data.priceSuggestionValidTo;

                    //Chequeo si esta fuera de Guidelines
                    var outOfGuidelines = false;
                    if (rowNode.data._Price != null) {
                        if (newPrice != null && newPrice != '') {
                            if (rowNode.data.minMargin != 0 && rowNode.data.newGrossMargin < rowNode.data.minMargin) {
                                outOfGuidelines = true;
                                //marginGuidelinesAlert("El nuevo margen: " + $rootScope.toPercentage(rowNode.data.newGrossMargin) + " es menor al margen minimo: " + $rootScope.toPercentage(rowNode.data.minMargin));
                            }
                            if (rowNode.data.maxMargin != 0 && rowNode.data.newGrossMargin > rowNode.data.maxMargin) {
                                outOfGuidelines = true;
                                //marginGuidelinesAlert("El nuevo margen: " + $rootScope.toPercentage(rowNode.data.newGrossMargin) + " es mayor al margen maximo: " + $rootScope.toPercentage(rowNode.data.maxMargin));
                            }
                        }
                    }
                    rowNode.data.outOfGuidelines = true;

                    //Hago esto para evitar sumarlo 2 veces
                    if (rowNode.data.newPrice != null && rowNode.data.newPrice != '' && rowNode.data.newPrice != 0) {
                        if (oldNewPrice == null)
                            self.numberOfNewPrices++;

                        self.salesWithNewPrices += (newPrice - rowNode.data._Price) * rowNode.data.units;
                    }
                    else {
                        self.salesWithNewPrices += (newPrice - rowNode.data.newPrice) * rowNode.data.units;
                    }

                    self.averagePriceChanges = Math.round((self.salesWithNewPrices / self.salesWithCurrentPrices - 1) * 10000) / 10000;
                    self.averagePriceNewVsSuggestedChanges = Math.round((self.salesWithNewPrices / self.salesWithSuggestedPrices - 1) * 10000) / 10000;

                    rowNode.data.dirty = isRevert ? false : true;

                    //Cambios en los KPIs del Header
                    self.newImpact += changePriceNewImpact - rowNode.data.newImpact;
                    self.newImpactShare = self.newImpact / self.categoryMargin; //Impacto sobre total margen de la categoria

                    self.percentageOfChanges = 0;
                    self.percentageOfChangesVsSuggested = 0;

                    rowNode.data.newImpact = changePriceNewImpact;

                    if (!rowNode.selected && !isRevert)
                        rowNode.setSelected(true);
                }
                else {
                    //Hago esto para evitar restarlo 2 veces
                    if (rowNode.data.newPrice != null && rowNode.data.newPrice != '') {
                        self.numberOfNewPrices--;

                        self.salesWithNewPrices -= (rowNode.data.newPrice - rowNode.data._Price) * rowNode.data.units;

                        self.averagePriceChanges = Math.round((self.salesWithNewPrices / self.salesWithCurrentPrices - 1) * 10000) / 10000;
                        self.averagePriceNewVsSuggestedChanges = Math.round((self.salesWithNewPrices / self.salesWithSuggestedPrices - 1) * 10000) / 10000;
                    }

                    //Cambios en los KPIs del Header
                    self.newImpact -= rowNode.data.newImpact;
                    self.newImpactShare = self.newImpact / self.categoryMargin; //Impacto sobre total margen de la categoria

                    self.percentageOfChanges = 0;
                    self.percentageOfChangesVsSuggested = 0;

                    rowNode.data.newPrice = null;
                    rowNode.data.priceDelta = null;
                    rowNode.data.priceDeltaAmount = null;
                    rowNode.data.newImpact = null;
                    rowNode.data.newGrossMargin = null;
                    rowNode.data.competitorsPriceVsNew = null;
                    rowNode.data.newValidFrom = null;
                    rowNode.data.newValidTo = null;
                }

                if (rowNode.data.suggestedPrice != null) {
                    if (newPrice == rowNode.data.suggestedPrice) {
                        self.numberOfSuggestionsAccepted++;
                    }
                    else if (!rowNode.selected) {
                        self.numberOfSuggestionsAccepted--;
                    }
                    self.percentageOfAcceptedSuggestions = self.numberOfSuggestionsAccepted > 0 ? Math.round((self.numberOfSuggestionsAccepted / self.numberOfSuggestions) * 10000) / 10000 : 0;
                }

                self.gridOptions.api.refreshRows([rowNode]);
            }
        }

        //recibe un rowNode.data y retorna un objeto con datos calculados simulando una aceptacion de sugerido. Sirve para obtener valores de campos que se calculan en la funcion changePrice
        function simulateSuggestionAcceptance(rowNodeData) {
            let rv = {};
            if (rowNodeData._Price != null && rowNodeData.suggestedPrice != null && rowNodeData.suggestedPrice != '' && rowNodeData.suggestedPrice  != 0) {

                var cost = rowNodeData.newCost != null ? rowNodeData.newCost : rowNodeData.cost;
                var tax = rowNodeData.newTax != null ? rowNodeData.newTax : rowNodeData.tax;
                var taxRate = rowNodeData.newTaxRate != null ? rowNodeData.newTaxRate : rowNodeData.taxRate;

                rv.newGrossMarginAbs = (cost + tax) != 0 ? (((rowNodeData.suggestedPrice - tax) / (1 + taxRate)) - cost) : 0;
                rv.newGrossMargin = (cost + tax) != 0 ? (((rowNodeData.suggestedPrice - tax) / (1 + taxRate)) - cost) / (((rowNodeData.suggestedPrice - tax) / (1 + taxRate)) + tax) : null;
                rv.newImpact = (rowNodeData.newGrossMarginAbs - rowNodeData.grossMarginAbs) * rowNodeData.units;
                rv.newPrice = rowNodeData.suggestedPrice;
                rv.priceDelta = (rowNodeData.suggestedPrice / rowNodeData._Price) - 1;
                rv.priceDeltaAmount = rowNodeData.suggestedPrice - rowNodeData._Price;
                rv.newSales = rowNodeData.suggestedPrice * rowNodeData.units;

            }
            return rv;
        }

        function IsVariationOutOfGuidelines(data) {

            var outOfGuidelines = false;

            if (data.newPrice) {
                if (data.minVariation != null && data.priceDelta < data.minVariation) {
                    outOfGuidelines = true;
                }
                if (data.maxVariation != null && data.priceDelta > data.maxVariation) {
                    outOfGuidelines = true;
                }
            }

            return outOfGuidelines;
        }

        function IsVariationAmountOutOfGuidelines(data) {

            var outOfGuidelines = false;

            if (data.newPrice) {
                if (data.minVariationAmount != null && data.priceDeltaAmount < data.minVariationAmount) {
                    outOfGuidelines = true;
                }

                if (data.maxVariationAmount != null && data.priceDeltaAmount > data.maxVariationAmount) {
                    outOfGuidelines = true;
                }
            }

            return outOfGuidelines;
        }

        function IsMarginOutOfGuidelines(data) {

            var outOfGuidelines = false;

            if (data.newPrice) {
                if (data.minMargin != null && data.newGrossMargin < data.minMargin) {
                    outOfGuidelines = true;
                }

                if (data.maxMargin != null && data.newGrossMargin > data.maxMargin) {
                    outOfGuidelines = true;
                }
            }

            return outOfGuidelines;
        }

        function IsMarginAmountOutOfGuidelines(data) {

            var outOfGuidelines = false;

            if (data.newPrice) {

                if (data.minMarginAmount != null && data.newGrossMarginAbs < data.minMarginAmount) {
                    outOfGuidelines = true;
                }

                if (data.maxMarginAmount != null && data.newGrossMarginAbs > data.maxMarginAmount) {
                    outOfGuidelines = true;
                }
            }

            return outOfGuidelines;
        }

        function resetKPIs() {
            changePriceNewImpact = 0;
            self.numberOfNewPrices = 0;
            self.averagePriceChanges = 0;
            self.averagePriceNewVsSuggestedChanges = 0;
            self.newImpact = 0;
            self.newImpactShare = 0;
            self.percentageOfChanges = 0;
            self.percentageOfChangesVsSuggested = 0;
            self.numberOfSuggestionsAccepted = 0;
            self.suggestedImpact = 0;
            self.categoryMargin = 0;
            self.salesWithCurrentPrices = 0;
            self.salesWithSuggestedPrices = 0;
            self.numberOfSuggestions = 0;

            self.salesWithNewPrices = 0;

            self.suggestedImpactShare = 0;

        }

        function loadData() {

            if (self.gridOptions.api)
                self.gridOptions.api.showLoadingOverlay();

            resetKPIs();

            /*
            var oldSelected = [];
            if (self.gridOptions.api != null)
                oldSelected = self.gridOptions.api.getSelectedNodes();
            */
            var selectedStatus = 0; //0 = All, 1 = New / Suggested, 2 = ToEndorse / Endorsed

            switch (self.selectedFilterForStatus) {
                case translations.New: selectedStatus = 1; break; //New 
                case translations.Suggested: selectedStatus = 2; break; //Suggested
                default: selectedStatus = 0; break; //All
            }

            priceService.decisions.getPrices($stateParams.categoryId, self.isEndorse, selectedStatus)
                .then(function (prices) {

                    self.allPrices = prices;
                    self.gridOptions.api.setRowData(prices);

                    if (prices.length > 0) {

                        externalFilterChanged();

                        self.category = prices[0].category;

                        //suggestedImpact
                        self.gridOptions.api.forEachNode(function (node) {

                            //agrego algunos extra al dto
                            node.data.hasNewPriceFromDb = node.data.newPrice ? true : false;
                            node.data.outOfGuidelines = false;

                            //Sumarizo
                            self.suggestedImpact += node.data.suggestedImpact;
                            self.categoryMargin += node.data.grossMarginAbs * node.data.units;
                            self.salesWithCurrentPrices += node.data._Price * node.data.units;

                            if (node.data.newPrice > 0) {
                                self.salesWithNewPrices += node.data.newPrice * node.data.units;
                                self.numberOfNewPrices++;
                            }
                            else
                                self.salesWithNewPrices += node.data._Price * node.data.units;

                            if (node.data.suggestedPrice > 0)
                                self.salesWithSuggestedPrices += node.data.suggestedPrice * node.data.units;
                            else
                                self.salesWithSuggestedPrices += node.data._Price * node.data.units;

                            if (node.data.suggestedPrice > 0)
                                self.numberOfSuggestions++;

                            self.newImpact += node.data.newImpact;

                            /*
                            //TODO: Esto es para recuperar los items seleccionados si es que se vuelve a cargar la data??
                            for (var i = 0; i < oldSelected.length; i++) {
                                if (oldSelected[i].data.itemId == node.data.itemId && oldSelected[i].data.priceListId == node.data.priceListId) {
                                    node.setSelected(true);
                                }
                            }
                            */
                        });

                        self.newImpactShare = self.newImpact / self.categoryMargin; //Impacto sobre total margen de la categoria
                        self.suggestedImpactShare = self.suggestedImpact / self.categoryMargin;

                        self.averagePriceChanges = Math.round((self.salesWithNewPrices / self.salesWithCurrentPrices - 1) * 10000) / 10000;
                        self.averagePriceNewVsSuggestedChanges = Math.round((self.salesWithNewPrices / self.salesWithSuggestedPrices - 1) * 100) / 100;
                    }

                    //loadChartData();
                });
        }

        function loadPricelists() {
            priceService.pricelists.getPriceLists()
                .then(function (pricelists) {
                    for (var i = 0; i < pricelists.length; i++) {
                        self.pricelists.push(pricelists[i])
                    }
                });
        }

        function loadTags() {
            adminService.tags.getTags()
                .then(function (tags) {
                    self.tags = tags;
                });
        }
        function loadSensitivities() {
            priceService.itemSensitivities.getItemSensitivities()
                .then(function (sensitivities) {
                    self.itemSensitivities = sensitivities;
                    self.itemSensitivities.push({ code: '', name: translations.Allf });
                    self.itemSensitivities.push({ code: '', name: translations.Any });
                });
        }

        function getDataFromCategorySummary() {
            //Seteo el titulo tipo Breadcrumb
            var categoryGroup = decodeURIComponent($stateParams.categoryGroup.replace(/-/g, " ").replace(/_/g, "\/"));
            var category = decodeURIComponent($stateParams.category.replace(/-/g, " ").replace(/_/g, "\/"));
            var subcategory = decodeURIComponent($stateParams.subcategory.replace(/-/g, " ").replace(/_/g, "\/"));

            self.title = categoryGroup
            if (category != '') {
                self.title += ' / ' + category;
                if (subcategory != '') {
                    self.title += ' / ' + subcategory;
                }
            }
        }

        /*
        function loadChartData() {
            //Traigo data para el chart
            priceService.decisions.getPriceDashboard($stateParams.categoryId)
                .then(function (priceDashboard) {
                    self.priceDashboard = priceDashboard;

                    self.updateChart(self.timerange);
                });
        }
        */

        var translations = null;

        function init() {

            //init columnDefs and gridOptions
            setGridOptions();

            priceService.getPriceTypes().then(
                function (priceTypes) {
                    self.priceTypes = priceTypes;
                });


            $translate(["PricesOfThisCategoryAsRevised", "CompetitionPricesOfThisCategoryAsRevised", "NormalPricesOfThisCategoryAsRevised", "DiscardConfirmTitle", "ContinueSwal ", "CancelSwal", "ErrorTitleSwal", "ErrorSwalSubtitle"
                , "WantToNotifyEndorsement", "ConfirmedSwal", "TheEndorsementWasNotified", "ErrorWhenWantingNotifyEndorsement", "AreYouSureSwalSubtitle", "AreYouSureSwal", "ConfirmedSwalSubtitle", "IgnoreSelectedSuggestions", "ConfirmedIgnoreSelectedSuggestions"
                , "ErrorWhenWantingIgnoreSuggestions", "NoChangesWereRecorded", "EndorseTheSelectedPrices", "ConfirmedEndorseTheSelectedPrices", "ErrorWhenWantingEndorsePrices", "RejectionComment", "RejectionCommentSubtitle", "RejectedItems"
                , "RejectedItemsSubtitle", "ModifyMarginIn", "ModifyMarginSubtitle", "EnterNewMarginValue", "YouMustEnterValue ", "YouMustEnterNumericValue", "MarginUpdated", "TheMarginWasUpdatedCorrectly1", "TheMarginWasUpdatedCorrectly2", "SelectAtLeastOnePriceToContinue"
                , "EnterNewMarginValueforSelectedProducts", "GeneralInformation", "Subcategory", "Code", "Description", "Area", "Brand", "Maker", "Tags", "NewPrice", "Present", "SuggestedS", "NewSingular", "CurrentPrice1", "Current", "From", "To", "Cost", "Original", "Average", "LastCost"
                , "CompetitivenessIndex", "Competitor", "SuggestedCI", "NewCI", "Sale", "Volume", "SalesInDollars", "NewSale", "Impact", "SuggestedS", "NewSingular", "Margin", "Present", "CurrentAverage", "SuggestedS", "PercentajeNew", "DolarNew", "Target", "Minimum", "Maximum"
                , "ValidityDates", "FromN", "ToN", "OverlapsAbbreviation", "Attention", "ErrorWhenWantingToMarkThisCategoryAsRevised", "UnitOfMeasureOfSale", "TypeOfPrice", "TypeOfPriceForce", "ValueIn", "UpdateDates", "UpdateDatesSubtitleOp1", "UpdateDatesSubtitleOp2", "Suggested"
                , "New", "Endorsed", "ForEndorsement", "All", "Allf","Any","Falls", "Rises", "NewPrices", "StrongFalls", "StrongRises", "DepressedMargins", "HighMargins", "NegativeMargins", "LittleSignificantChanges", "SuggestedNotRespected", "Overlaps", "ClickHereToLoadTags", "ThePriceVariationLimitHasBeenExceeded"
                , "TheMarginsLimitHasBeenExceeded", "ThisPriceHasAnOverlapWithAPromotion", "Basket", "Promotion", "Own", "SuggestedAverage", "Sensitivity"])
                .then(function (all) {
                    translations = all;
                    self.pricelists = [{ id: 0, name: translations.Allf }];
                    self.selectedFilterForPriceList = translations.Allf;
                    self.selectedFilterForPriceType = translations.All;
                    self.selectedFilterForIssues = translations.Allf;
                    self.selectedFilterForAction = translations.Allf;
                    self.selectedFilterForSensitivities = translations.Allf;
                    self.selectedFilterForStatus = self.isEndorse ? translations.ForEndorsement : self.isNewFilter ? translations.New : translations.Suggested;
                    setColumnDefinitions();
                    self.gridOptions.api.setColumnDefs(columnDefs);

                    //Load data for title and endorsement
                    getDataFromCategorySummary();
                    loadPricelists();
                    loadTags();
                    loadSensitivities();

                    //load data
                    loadData();
                });
        }

        init();

    });