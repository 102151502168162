'use strinct';
(function () {
    angular.module('prisma').component('planogramPrintFooter', {
        templateUrl: '/app/spaces/planogram/print/planogram-print-footer.view.html',
        bindings: {
            page: '<',
            pageTotal: '<',
            showPageInfo:'<'
        },
        controller: 'planogramPrintFooterController',
        controllerAs: '$ctrl',
        bindToController: true
    }).controller('planogramPrintFooterController', ['$log', planogramPrintFooterController]);

    // ReSharper disable once InconsistentNaming
    function planogramPrintFooterController($log) {
        //$log.debug('planogramPrintFooterController::ctor');
        var self = this;
        self.page = 1;
        self.pageTotal = 1;
        self.showPageInfo = false;

        /**
         * Devuelve una cadena con la fecha y hora formateada como día/mes/año.
         */
        self.getFechaHora = function getFechaHora() {
            return moment().format('DD/MM/YYYY hh:mma');
        };
    }
})();