'use strinct';
(function () {
    angular.module('prisma')
        .service('PlanogramHelper', ['$log', PlanogramHelperService]);

    function PlanogramHelperService($log) {
        this.$log = $log;
        //this.$log.debug('PlanogramHelperService::ctor');
    }

    /**
     * Devuelve el ancho calculado para el grupo de items.
     * @private
     * @param {any} item Referencia del item.
     * @returns El ancho calculado para el grupo de items.
     */
    PlanogramHelperService.prototype.getItemWidth = function getItemWidth(value) {
        var ret;
        return ret;
    }

    PlanogramHelperService.prototype.getItemGeometry = function getItemGeometry(value) {
        // Calcula los tamaños de los items según los tipos de rotaciones.

        // Items acostados.
        var layDownItem = {
            width: value.width,
            height: value.depth,
            depth: value.height,
            position: {
                width: value.width,
                height: value.depth,
                depth: value.height
            }
        };

        // Items apilados
        var stackAboveItem = {
            width: value.width,
            height: value.height,
            depth: value.depth,
            position: {
                width: value.width,
                height: value.height,
                depth: value.depth
            }
        };

        var width;
        var depth;
        var stackBehind = value.stackBehind || 1;
        var stackAboveAux = value.stackAbove || 1;
        var layDownItemAux = value.layDownItem || 0;
        var hasStack = stackAboveAux > 1;
        var hasLayDown = layDownItemAux > 0;
        var orientation = value.orientation || 1;

        switch (orientation) {
            case 1 /* Sin rotación */:
                // Apilados        
                // Sin cambios.

                // Acostados
                // Sin cambios.
                break;

            case 2 /* Rotado 90 grados */:
                // Apilados
                stackAboveItem.position = {
                    width: stackAboveItem.height,
                    height: stackAboveItem.width
                };

                // Acostados
                layDownItem.position = {
                    width: layDownItem.height,
                    height: layDownItem.width
                };
                break;

            case 3 /* Rotado 180 grados */:
                // Apilados        
                // Sin cambios.

                // Acostados
                // Sin cambios.
                break;

            case 4 /* Rotado 270 grados */:
                // Apilados    
                stackAboveItem.position = {
                    width: stackAboveItem.height,
                    height: stackAboveItem.width
                };

                // Acostados
                layDownItem.position = {
                    width: layDownItem.height,
                    height: layDownItem.width
                };
                break;
        }

        if (hasStack && hasLayDown) {
            width = Math.max(layDownItem.position.width, stackAboveItem.position.width) * value.facings;
            depth = Math.max(layDownItem.position.depth, stackAboveItem.position.depth) * stackBehind;
        } else if (hasStack && !hasLayDown) {
            width = stackAboveItem.position.width * value.facings;
            depth = stackAboveItem.position.depth * stackBehind;
        } else if (!hasStack && hasLayDown) {
            width = layDownItem.position.width * value.facings;
            depth = layDownItem.position.depth * stackBehind;
        } else {
            width = stackAboveItem.position.width * value.facings;
            depth = stackAboveItem.position.depth * stackBehind;
        }

        var height = stackAboveItem.position.height * value.stackAbove
            + layDownItem.position.height * value.layDownItem;

        // Calcula el tamaño del grupo de items.
        var ret = {
            width: width,
            height: height,
            depth: depth,
            stackAboveItem: stackAboveItem,
            layDownItem: layDownItem,
        }

        return ret;
    }

})();