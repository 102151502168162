var _controller = function ($scope, $attrs, $filter, $timeout, $rootScope, $translate, homeService, adminService) {
    var self = this;

    self.item = self.params.itemForModal;
    self.clusterId = self.params.selectedClusterForModal;

    self.timerange = 'week';
    self.hideBoxes = false;
    self.compareYtd = null;

    self.sameStores = true;
    self.type = '3';

    self.changeTimeRange = function (timerange) {
        self.timerange = timerange;
        self.updateChart();
    }


    self.sameStoreChanges = function (value) {
        //console.log('value', value);
        loadData(value);
    }

    self.timeTypeChange = function () {
        loadBoxData(self.sameStores, self.type);

        //loadData(self.sameStores);
    }

    self.getVsPrevious = function () {
        if (self.type == '2') {
            return translations.VsPrevYear;
        }

        if (self.type == '1') {
            var rv = 'vs ';

            var currentDate = new Date();
            var month = new Date(currentDate.setMonth(currentDate.getMonth() - 1)).getMonth();
            var year = new Date(currentDate.setMonth(currentDate.getMonth() - 1)).getFullYear();

            rv += $rootScope.monthNames[month - 1] + ' ' + year;

            return rv;
        };

        if (self.type == '3') {
            var rv = 'vs ';

            var currentDate = new Date();
            var month = new Date(currentDate.setMonth(currentDate.getMonth() - 12)).getMonth();
            var year = new Date(currentDate.setMonth(currentDate.getMonth() - 12)).getFullYear();

            rv += $rootScope.monthNames[month - 1] + ' ' + year;

            return rv;
        }
    }

    //boxes
    self.getMainBoxTitle = function (box) {
        var rv = "";
        switch (box) {
            case "sales": rv = translations.SALES; break;
            case "units": rv = translations.UNITS; break;
            case "cost": rv = translations.COST; break;
            case "margin": rv = translations.MARGIN; break;
        }

        return rv;
    }
    self.getBoxCompareValue = function (box) {
        if (box && self.compareYtd) {
            var value = $filter('percentage')(self.compareYtd[box + 'PyDelta'], 1);

            return self.compareYtd[box + 'PyDelta'] != 0 ? value : 'N/A';
        }
    }
    self.getBoxCompareTooltip = function (box) {
        if (box && self.compareYtd) {

            var value = null;
            var filter = box === 'units' ? $filter('number') : $filter('currency');

            if (self.boxesCompareTo === 'Month') {
                value = box === 'units' ? filter(self.compareMonth[box + 'Py'], 0) : filter(self.compareMonth[box + 'Py'], '$', 0);
            }
            else {
                value = box === 'units' ? filter(self.compareYtd[box + 'Py'], 0) : filter(self.compareYtd[box + 'Py'], '$', 0);
            }

            return value;
        }
    }

    self.getMainBoxValueGoal = function (box) {
        if (box && self.compareYtd) {

            var value = null;
            var filter = box === 'units' ? $filter('abbreviate') : $filter('abbreviate');
            var prefix = box === 'units' ? null : "$";
            var decimals = box === 'units' ? 0 : 2;

            //if (self.boxesCompareTo === 'Month') {
            //    value = filter(self.compareMonth[box], decimals, prefix);
            //}
            //else if (self.boxesCompareTo === 'Goal') {
            filter = $filter('percentage');
            value = filter(self.compareYtd[box + 'GoalGapP'], 2);
            //}
            //else {
            //    value = filter(self.compareYtd[box + 'Ytd'], decimals, prefix);
            //}

            return self.compareYtd[box + 'GoalGapP'] != 0 ? value : 'N/A';
        }
    }
    self.getMainBoxTooltip = function (box) {
        if (box && self.compareYtd) {

            var value = null;
            var filter = box === 'units' ? $filter('number') : $filter('currency');

            if (self.boxesCompareTo === 'Month') {
                value = box === 'units' ? filter(self.compareMonth[box], 0) : filter(self.compareMonth[box], '$', 0);
            }
            else {
                value = box === 'units' ? filter(self.compareYtd[box + 'Ytd'], 0) : filter(self.compareYtd[box + 'Ytd'], '$', 0);
            }

            return value;
        }
    }
    self.isMainBoxGoalSignPositive = function (box) {
        if (box && self.compareYtd) {

            return self.compareYtd[box + 'GoalGapP'] > 0;
        }
    }

    self.getMainBoxValueYTD = function (box) {
        if (box && self.compareYtd) {

            var value = null;
            var filter = box === 'units' ? $filter('abbreviate') : $filter('abbreviate');
            var prefix = box === 'units' ? null : "$";
            var decimals = box === 'units' ? 0 : 2;

            value = filter(self.compareYtd[box + 'Ytd'], decimals, prefix);
        }

        return value;
    }
    self.getMainBoxValueAnual = function (box) {
        if (box && self.compareYtd) {

            var value = null;
            var filter = box === 'units' ? $filter('abbreviate') : $filter('abbreviate');
            var prefix = box === 'units' ? null : "$";
            var decimals = box === 'units' ? 0 : 2;

            value = filter(self.compareYtd[box + 'GoalTotal'], decimals, prefix);

            return self.compareYtd[box + 'GoalTotal'] != 0 ? value : 'N/A';
        }
    }
    self.getMainBoxAnualTooltip = function (box) {
        if (box && self.compareYtd) {

            var value = null;
            var filter = box === 'units' ? $filter('number') : $filter('currency');

            if (self.boxesCompareTo === 'Month') {
                value = box === 'units' ? filter(self.compareMonth[box + 'GoalTotal'], 0) : filter(self.compareMonth[box + 'GoalTotal'], '$', 0);
            }
            else {
                value = box === 'units' ? filter(self.compareYtd[box + 'GoalTotal'], 0) : filter(self.compareYtd[box + 'GoalTotal'], '$', 0);
            }

        }
        return value;
    }

    self.getActualYtdValue = function (box) {
        if (self.compareYtd != null) {
            var value = null;
            var filter = box === 'units' ? $filter('abbreviate') : $filter('abbreviate');

            value = box === 'units' ? filter(self.compareYtd[box + 'Goal'], 0) : filter(self.compareYtd[box + 'Goal'], '$', 0);

            return value;
        }
    }
    self.getActualYtdTooltipValue = function (box) {
        if (self.compareYtd != null) {
            var value = null;
            var filter = box === 'units' ? $filter('number') : $filter('currency');

            value = box === 'units' ? filter(self.compareYtd[box + 'Goal'], 0) : filter(self.compareYtd[box + 'Goal'], '$', 0);

            return value;
        }
    }

    self.getProjectedYtdValue = function (box) {
        if (self.compareYtd != null) {
            var value = null;
            var filter = box === 'units' ? $filter('abbreviate') : $filter('abbreviate');

            value = box === 'units' ? filter((self.compareYtd[box + 'Projected'] + self.compareYtd[box + 'Ytd']), 0) : filter((self.compareYtd[box + 'Projected'] + self.compareYtd[box + 'Ytd']), '$', 0);

            return self.compareYtd[box + 'Projected'] != 0 ? value : 'N/A';
        }
    }
    self.getProjectedYtdTooltipValue = function (box) {
        if (self.compareYtd != null) {
            var value = null;
            var filter = box === 'units' ? $filter('number') : $filter('currency');

            value = box === 'units' ? filter((self.compareYtd[box + 'Projected'] + self.compareYtd[box + 'Ytd']), 0) : filter((self.compareYtd[box + 'Projected'] + self.compareYtd[box + 'Ytd']), '$', 0);

            return value;
        }
    }
    self.projectedPositive = function (box) {
        if (box && self.compareYtd) {
            var projected = self.compareYtd[box + 'Projected'] + self.compareYtd[box + 'Ytd'];
            var annual = self.compareYtd[box + 'GoalTotal'];

            if (box != 'cost')
                return projected >= annual;
            else
                return projected < annual;
        }
    }

    self.getBoxCompareIcon = function (box) {

        if (box && self.compareYtd) {

            var delta = null;
            var cls = '';

            delta = self.compareYtd[box + 'PyDelta'];

            if (box != 'cost') {
                if (!delta)
                    cls = '';
                else if (delta > 0.005)
                    cls = 'fa fa-level-up text-navy';
                else if (delta < -0.005)
                    cls = 'fa fa-level-down text-danger';
                else
                    cls = 'fa fa-anchor';
            }
            else {
                if (!delta)
                    cls = '';
                else if (delta > 0.005)
                    cls = 'fa fa-level-up text-danger';
                else if (delta < -0.005)
                    cls = 'fa fa-level-down text-navy';
                else
                    cls = 'fa fa-anchor';
            }


            return cls;
        }
    }
    self.isBoxSignPositive = function (box) {
        if (box && self.compareYtd) {

            var value = null;

            value = self.compareYtd[box + 'PyDelta'];

            if (box == 'cost') {
                if (value <= 0)
                    return true
                else
                    return false;
            }
            else {
                if (value > 0)
                    return true
                else
                    return false;
            }
        }

    }

    self.toggleLegend = function (chart) {
        if (!chart.legend.visible) {
            chart.legend.show();
            chart.legend.visible = true;
        }
        else {
            chart.legend.hide();
            chart.legend.visible = false;
        }
    }
    self.updateBoxes = function (compareTo) {

        if (compareTo === 'Month') {
            findMonthToCompare(self.selectedMonth);
        }

        self.boxesCompareTo = compareTo;

        if (compareTo === 'Goal')
            renderGoalCharts();

    }


    function renderGoalCharts() {

        var boxes = ['sales', 'margin', 'units', 'cost'];

        angular.forEach(boxes, function (box) {

            var chart = c3.generate(getGoalChartTemplate(box));


            setTimeout(function () {
                chart.load({
                    columns: [['objetivo', self.compareYtd[box + 'Goal']]],
                });
            }, 500);

            setTimeout(function () {
                chart.load({
                    columns: [['actual', self.compareYtd[box + 'Ytd']],
                    ['proyectado', self.compareYtd[box + 'Projected']]
                    ],
                });
                chart.groups([['proyectado', 'actual']]);
            }, 1500);

            self[box + 'GoalChart'] = chart;
        });
    }
    function getGoalChartTemplate(box) {

        var template = {
            bindto: ian.format('#{0}GoalChart', box),
            data: {
                columns: [],
                type: 'bar',
                groups: [],
                colors: {
                    actual: '#1f77b4',
                    proyectado: '#aec7e8',
                    objetivo: '#1ab394',
                },
                order: null
            },
            size: {
                height: 65,
            },
            bar: {
                width: 5
            },
            axis: {
                rotated: true,
                x: { show: false },
                y: { show: false }
            },
            tooltip: {
                show: true,
                format: {
                    title: function (x) { return 'vs Plan'; },
                    value: function (value, ratio, id) {
                        return box === 'units' ? $filter('abbreviate')(value, 0) : $filter('abbreviate')(value, '$', 0);
                    }
                }
            },
            legend: {
                show: false
            }
        };

        return template;
    }


    function findMonthToCompare(month) {

        var rv = null;
        angular.forEach(self.compareYtd.vsPriorYearByMonth, function (item) {
            if (item.month == month && item.year == self.compareYtd.currentYear) {
                rv = item;
            }
        });

        self.compareMonth = rv;

        return rv;
    }
    self.isLoadingChart = false;
    function loadChartData() {
        self.isLoadingChart = true;
        //Traigo data para el chart
        homeService.kpis.getItemDashboardItemCluster(self.item.categoryId, self.item.id, self.clusterId, self.sameStores)
            .then(function (prices) {
                self.prices = prices;
                self.isLoadingChart = false;

                self.priceDashboard = self.prices;

                self.updateChart();
            });
    }

    self.updateChart = function () {

        if (!self.prices || self.prices == null || self.prices == '')
            return;

        var xValues = [];

        if (self.timerange == 'week')
            xValues = self.prices.lastWeekXValues;
        else if (self.timerange == 'month')
            xValues = self.prices.lastMonthXValues;
        else
            xValues = self.prices.lastYearXValues;

        var series = ['sales', 'units', 'margin', 'price'];
        var axes = {
            sales: 'y',
            units: 'y',
            margin: 'y',
            price: 'y2'
        };

        var chartTypes = {
            price: 'line'
        };

        var chartColors = {
            sales: '#1ab394',
            units: '#1c84c6',
            margin: '#f8ac59',
            price: '#ED5565'
        };

        var extraColors = [];

        self.chart = c3.generate({
            bindto: '#saleschart_' + self.item.id,
            data: {
                json: xValues,
                keys: {
                    x: 'dateString',
                    value: series,
                },
                axes: axes,
                type: 'bar',
                types: chartTypes,
                //colors: chartColors
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        fit: true,
                        format: function (x) {
                            if (self.timerange == 'week') {
                                return $rootScope.shortWeekDayNames[x.getDay()];
                            } else if (self.timerange == 'month') {
                                return x.getDate() + '-' + $rootScope.shortMonthNames[(x.getMonth())];
                            } else if (self.timerange == 'year') {
                                return x.getFullYear() + '-' + (x.getMonth() + 1);
                            }
                        }
                    }
                },
                y: {
                    tick: {
                        format: d3.format("s")
                    }
                },
                y2: {
                    show: true,
                    label: 'Precio en $',
                    tick: {
                        format: d3.format('.1f')
                    },
                    min: 0
                }
            },
            tooltip: {
                format: {
                    title: function (d) {
                        var toFormat = '%B %d, %Y';
                        if (self.timerange == 'year')
                            toFormat = '%B %Y';

                        var format = d3.time.format(toFormat);
                        return format(d);
                    },
                    value: function (value, ratio, id) {
                        var format = id === 'sales' || id === 'margin' || id === 'units' ? d3.format(',.0f') : d3.format(',.1f');
                        return format(value);
                    }
                }
            }
        });
    }

    function loadData() {

        var categoryId = 0;
        if (self.item && self.item.categoryId)
            categoryId = self.item.categoryId;

        loadChartData(self.sameStores, self.type);

        //traigo data para los boxes
        loadBoxData(self.sameStores, self.type);
    }

    self.isLoadingKPIs = false;
    function loadBoxData(withSameStores, type) {
        self.isLoadingKPIs = true;
        homeService.kpis.getKpiDashboardYTD(self.item.id, self.clusterId, withSameStores, type)
            .then(function (dashboardComparisonDto) {
                self.isLoadingKPIs = false;
                self.compareYtd = dashboardComparisonDto;
                self.updateBoxes('GoalTotal');
            });
    }

    var translations = null;
    function init() {


        $translate(["SALES", "UNITS", "MARGIN", "COST", "VsPrevYear"])
            .then(function (all) {
                translations = all;

                loadData();
            });
    }

    init();


}

angular.module('prisma').component('kpiItemViewDefault', {
    templateUrl: 'app/shared_logic/components/kpiItemViewDefault/kpiItemviewdefault.component.html',
    bindings: {
        params: '='
    },

    controller: _controller
});


