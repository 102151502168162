var _controller = function ($scope, $attrs, $filter, $timeout, $rootScope, priceService, homeService) {
    var self = this;
    console.log('self', self);
    self.url = self.resolve.params.url;
}

angular.module('prisma').component('displayPhoto', {
    templateUrl: 'app/shared_logic/components/displayPhoto/displayPhoto.component.html',
    bindings: {
        resolve:'='
    },

    controller: _controller
});


