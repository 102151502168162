var _controller = function (componentsService, $scope, $translate, $element, $attrs, $q, $timeout) {
    var ctrl = this;
    ctrl.searchText = '';
    ctrl.noResults = true;
    ctrl.loadingItems = false;
    ctrl.onlyMarkers = false;

    //console.log('ctrl', ctrl);
    ctrl.lastSearch = undefined;


    ctrl.onlyMarkersChange = function () {
        //console.log('onlyMarkers', ctrl.onlyMarkers);

        ctrl.onTextChange(ctrl.searchText, ctrl.onlyMarkers)
    }

    ctrl.onKeyup = function (evt) {
        //console.log('evt', evt);
        //console.log('ctrl.searchText', ctrl.searchText);
        ctrl.noResults = true;

        if ([4,5].includes(ctrl.searchText.length) && ctrl.lastSearch != ctrl.searchText) {
            ctrl.loadingItems = true;

            componentsService.competitorsLookup.getCompetitors(ctrl.searchText)
                .then(function (items) {
                    ctrl.noResults = items.length == 0;
                    ctrl.loadingItems = false;

                    ctrl.lastSearch = angular.copy(ctrl.searchText);
                    ctrl.onNewItems(items);
                });
        }

        ctrl.onTextChange(ctrl.searchText, ctrl.onlyMarkers);


        //if (evt.keyCode == 13) {
        //    if (ctrl.lastSearch != ctrl.searchText) {
        //        ctrl.lastSearch = angular.copy(ctrl.searchText);
        //        ctrl.getItems(ctrl.searchText);
        //    }
        //}
    }

    //ctrl.getItems = function (text) {
    //    //console.log(text);
    //    //if (text.length < 4)
    //        //return;
    //    componentsService.competitorsLookup.getCompetitors(text)
    //        .then(function (items) {
    //            ctrl.onNewItems(items);
    //        });
    //}
}

angular.module('prisma').component('competitorLookupComponent', {
    templateUrl: 'app/shared_logic/components/competitorsLookup/competitorsLookup.component.html',
    bindings: {
        //level: '=',
        onNewItems: '=',
        onTextChange: '='
        //ngModel:'='
    },

    controller: _controller
});