angular.module("prisma")
    .controller("promotionsCtrl", function ($rootScope, $scope, $window, $timeout, $stateParams, $state, promotionsService, authService, ngDialog) {
        var self = this;

        self.promotions = [];
        self.distinctCampaigns = ['Todas'];
        self.distinctMediaTypes = ['Todos'];
        self.distinctStatus = ['Todas ', 'Activas y Planeadas', 'Activa', 'Historica', 'Planeada'];
        self.distinctPromotionTypes = ['Todos', 'Externa', 'Interna', 'Otras'];
        self.isLoading = true;
        self.hasPermissionOfSuperUser = authService.hasPermission('promotion_superUser');
        self.hasPermissionOfExternal = authService.hasPermission('promotion_edit_externalPromotion');
        self.hasPermissionOfOther = authService.hasPermission('promotion_edit_otherPromotion');
        self.hasPermissionOfInternal = authService.hasPermission('promotion_edit_internalPromotion');
        self.search = "";
        self.selectedFilterForCampaign = 'Todas';
        self.selectedFilterForMediaType = 'Todos';
        self.selectedFilterForPromotionType = 'Todos';
        self.selectedFilterForStatus = 'Activas y Planeadas';
        self.selectedFilterForAsigned = 'Todas';
        self.selectedFilterForComunicated = 'Todas';
        self.selectedFilterForCreated = 'Todos';
        self.selectedFilterForModif = 'Todos';
        self.searchId = '';
        self.hideSearchId = true;
        self.pageNumber = 2;
        self.pageSize = 50;
        self.optionalPromotionId;

        self.promotionMediaIds = [];
        pageLoaded = false;


        $(window).scroll(function () {
            if ($(window).scrollTop() + $(window).height() > ($(document).height() * 0.95)) {
                if (pageLoaded) {
                    $timeout(function () {
                        self.pageNumber++;
                    }, 0)
                }
            }
        });


        self.promotionMediaTypes = promotionsService.promotionMediaTypes;

        self.setFiltersInLocalStorage = function () {
            var filters = {
                'comunicada': self.selectedFilterForComunicated,
                'asignada': self.selectedFilterForAsigned,
                'ultimaModificacion': self.selectedFilterForModif,
                'creadaPor': self.selectedFilterForCreated,
                'campaña': self.selectedFilterForCampaign,
                'tipoDeMedio': self.selectedFilterForMediaType,
                'tipoDePromocion': self.selectedFilterForPromotionType,
                'estado': self.selectedFilterForStatus,
                'buscador': self.search
            };
            localStorage.setItem('filtros', JSON.stringify(filters));
        }

        self.delete = function (promotionId, promotionName) {

            swal({
                title: 'Esta seguro que desea borrar la acción promocional ' + promotionName + ' ?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {


                        promotionsService.promotions.deletePromotion(promotionId)
                            .then(function (isOk) {
                                swal('Promocion Borrada!', 'La promocion ' + promotionName + ' se ha borrado exitosamente', 'success');
                                loadData();
                            }, function (status) {
                                if (status == 502) {
                                    swal("El proceso continua...", "El proceso de borrado continua. Puede revisar el estado en unos minutos.")
                                }
                                else {
                                    swal("Error", "Hubo un error al borrar esta promocion", "error");
                                }
                            });
                    }
                });
        }

        //Filters
        self.myFilter = function (p) {
            return self.searchId != ''
                || (
                    (
                        ((p.promotionCampaignName.toLowerCase().indexOf(self.selectedFilterForCampaign.toLocaleLowerCase()) > -1 || self.selectedFilterForCampaign == "Todas")) &&
                        ((p.promotionMediaTypeName.toLowerCase().indexOf(self.selectedFilterForMediaType.toLocaleLowerCase()) > -1 || self.selectedFilterForMediaType == "Todos")) &&
                        ((p.promotionType.toLowerCase().indexOf(self.selectedFilterForPromotionType.toLocaleLowerCase()) > -1 || self.selectedFilterForPromotionType == "Todos")) &&
                        ((self.selectedFilterForStatus.toLocaleLowerCase().indexOf(p.status.toLowerCase()) > -1 || self.selectedFilterForStatus == "Todas")) &&
                        (((p.createdUserName != null && ((p.createdUserName.indexOf(self.selectedFilterForCreated)) > -1)) || (self.selectedFilterForCreated == "Todos"))) &&
                        (((p.lastModifiedUserName != null && ((p.lastModifiedUserName.indexOf(self.selectedFilterForModif)) > -1)) || (self.selectedFilterForModif == "Todos"))) &&
                        ((((p.taken != null && p.taken) == (self.selectedFilterForAsigned)) || self.selectedFilterForAsigned == "Todas")) &&
                        ((((p.publishedDate != null) == (self.selectedFilterForComunicated)) || self.selectedFilterForComunicated == "Todas"))
                    )
                    &&
                    (self.search.length == 0 || (
                        (p.name && p.name.toLowerCase().indexOf(self.search.toLowerCase()) > -1)
                        || (p.promotionCampaignName && p.promotionCampaignName.toLowerCase().indexOf(self.search.toLowerCase()) > -1)
                        || (p.promotionMediaTypeName && p.promotionMediaTypeName.toLowerCase().indexOf(self.search.toLowerCase()) > -1)
                        || (p.promotionType && p.promotionType.toLowerCase().indexOf(self.search.toLowerCase()) > -1)
                        || (p.status && p.status.toLowerCase().indexOf(self.search.toLowerCase()) > -1)
                        || (p.id == self.search)
                    ))
                );
     };

     self.searchById = function (p) {
         return (self.searchId == '' || p.id == self.searchId);
     };

     self.filterByCampaign = function (campaign) {
         if (campaign == 'Todas')
             self.selectedFilterForCampaign = 'Todas';
         else if (self.selectedFilterForCampaign != campaign) {
             self.selectedFilterForCampaign = campaign;
         }
     }
     self.filterByMediaType = function (type) {
         if (type == 'Todos')
             self.selectedFilterForMediaType = 'Todos';
         else if (self.selectedFilterForMediaType != type) {
             self.selectedFilterForMediaType = type;
         }
     }
     self.filterByPromotionType = function (type) {
         if (type == 'Todos')
             self.selectedFilterForPromotionType = 'Todos';
         else if (self.selectedFilterForPromotionType != type) {
             self.selectedFilterForPromotionType = type;
         }
     }
     self.filterByStatus = function (status) {
         if (status == 'Todas ')
             self.selectedFilterForStatus = 'Todas';
         else if (self.selectedFilterForStatus != status) {
             self.selectedFilterForStatus = status;
         }
     }

     self.filterAsigned = function (asigned) {
         if (asigned == 'Todas ')
             self.selectedFilterForAsigned= 'Todas';
         else if (self.selectedFilterForAsigned != asigned) {
             self.selectedFilterForAsigned = asigned;
         }
     }

     self.filterComunicated = function (comunicated) {
         if (comunicated == 'Todas ')
             self.selectedFilterForComunicated = 'Todas';
         else if (self.selectedFilterForComunicated != comunicated){
             self.selectedFilterForComunicated = comunicated;
         }
     }

     self.filterForCreated = function (created) {
         if (created == 'Todos ')
             self.selectedFilterForCreated = 'Todos';
         else if (self.selectedFilterForCreated != created) {
             self.selectedFilterForCreated = created;
         }
     }

     self.filterForModif = function (modif) {
         if (modif == 'Todos ')
             self.selectedFilterForModif = 'Todos';
         else if (self.selectedFilterForModif != modif) {
             self.selectedFilterForModif = modif;
         }
     }

     self.newPromotionPopup = function () {

         var newScope = $scope.$new();

         newScope.goTo = function (promotionType) {
             self.setFiltersInLocalStorage();

             if (promotionType == "Interna")
                 $state.go('promotions.editInternalPromotion', { promotionId: 0, promotionMediaPageId: 0 });
             else
                 $state.go('promotions.editPromotion', { promotionId: 0, promotionType: promotionType });
             ngDialog.close();
         }

         ngDialog.open({
             template: 'promotionTypeDialog',
             className: 'ngdialog-theme-default ngdialog-theme-custom',
             scope: newScope
         });
        }

        self.goToPromotionSearch = function () {
            $state.go('promotions.promotionSearch');
        }

        self.goToInternal = function (promotion, copy) {
            self.isLoading = true;
            promotionsService.internalPromotions.getPromotionMediaPageId(promotion.id)
                .then(function (pageId) {
                    $state.go('promotions.editInternalPromotion', { promotionId: promotion.id, promotionMediaPageId: pageId, copy: copy });
                });
        }

     self.rejectedPromotionsPopup = function () {               
         self.loadingRejectedPromotions = true;
         promotionsService.promotions.getRejectedPromotions()
           .then(function (promotions) {
               self.rejectedPromotions = promotions;
               self.loadingRejectedPromotions = false;
           });
         ngDialog.open({
             template: 'rejectedPromotionsDialog',
             className: 'ngdialog-theme-default ngdialog-theme-custom',
             scope: $scope
         });
     }

     self.openHistoricDialog = function () {
         var newScope = $scope.$new();
         var today = new Date();
         self.fromDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate() - 1);
         self.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);

         newScope.validateDateRange = function () {
             let rv = true;
             if (self.toDate >= today)
                 rv = false;
             if (self.fromDate < new Date(self.toDate.getFullYear(), self.toDate.getMonth() - 1, self.toDate.getDate()))
                 rv = false;
             return rv;
         }

         newScope.getHistoricPromotions = function () {
             if (!newScope.validateDateRange()) {
                 swal("Rango invalido", "La fecha desde debe ser menor a hoy y a su vez el rango entre fechas no puede excederse de un mes.", "error");
                 return;
             }

             self.isLoading = true;
             ngDialog.close();

             promotionsService.promotions.getHistoricPromotions({ fromDate: self.fromDate, toDate: self.toDate })
                 .then(function (promotions) {
                     angular.forEach(promotions, function (x) {
                         if (!self.promotions.find(function (y) { return x.id == y.id }))
                            self.promotions.push(x);
                     });
                     self.filterByStatus('Historica');
                     swal('Filtrado por historicas', '', 'success');
                     self.isLoading = false;
                 }, function (status) {
                     if (status == 502) {
                         swal("El proceso está demorado...", '')
                     }
                     else {
                         swal("Error", "Hubo un error al buscar promociones historicas", "error");
                     }
                 });
         }

         ngDialog.open({
             template: 'historicDialog',
             className: 'ngdialog-theme-default ngdialog-theme-custom',
             scope: newScope
         });
    }

    self.openChangeControl = function () {
        var newScope = $scope.$new();

        newScope.getChangeControlPromotions = function () {
            ngDialog.close();
            if ((self.fromDate != null && self.toDate != null) || self.optionalPromotionId != null) {
                self.isLoading = true;
                promotionsService.promotions.buildModifiedPromotions({ fromDateNullable: self.fromDate, toDateNullable: self.toDate }, self.optionalPromotionId)
                    .then(function (link) {
                        self.isLoading = false;
                        window.open(link);
                    });
            }
            else {
                swal("Error", "Debe colocar al menos un rango de fecha o el id de la promocion.", "error");
            }
           
         }

         ngDialog.open({
             template: 'changeControlDialog',
             className: 'ngdialog-theme-default ngdialog-theme-custom',
             scope: newScope
         });
    }

    function getFilters() {
        if (localStorage.getItem('filtros') != null) {
            var filtersAux = localStorage.getItem('filtros');
            var filters = JSON.parse(filtersAux);

            self.selectedFilterForComunicated = filters.comunicada;
            self.selectedFilterForAsigned = filters.asignada;
            self.selectedFilterForModif = filters.ultimaModificacion;
            self.selectedFilterForCreated = filters.creadaPor;
            self.selectedFilterForCampaign = filters.campaña;
            self.selectedFilterForMediaType = filters.tipoDeMedio
            self.selectedFilterForPromotionType = filters.tipoDePromocion
            self.selectedFilterForStatus = filters.estado;
            self.search = filters.buscador;
        }
        localStorage.removeItem("filtros");
    }

        self.downloadPrices = function (id) {
            promotionsService.reports.buildPricesReport(id)
                .then(function (link) {
                    self.link = link;
                    self.showSwalWithLink(self.link);
                });
        }

        self.showSwalWithLink = function (link) {
            swal({
                title: "Reporte Generado!",
                text: 'Haga click <a href="' + link + '"><u style = "color: #1AB394;"><b style = "color: #1AB394;">AQUÍ</b></u></a> para descargar el archivo',
                confirmButtonText: "Cerrar",
                confirmButtonColor: "#c2c2c2",
                html: true
            });
        }

     function loadData() {
         promotionsService.promotions.getPromotions(false)
            .then(function (promotions) {
                self.promotions = promotions;
                console.log(promotions);

                self.promotions.forEach(function (p) {
                    p.createdDate = $rootScope.convertUTCDateToLocalDate(p.createdDate);
                });

                let creators = promotions.map(function (p) {
                    return { value: p.createdUserName };
                });

                creators = creators.filter(function (p) {
                    return p.value != null;
                });

                self.createdUserNames = $rootScope.removeDuplicates(creators, 'value');

                let modif = promotions.map(function (p) {
                    return { value: p.lastModifiedUserName };
                });

                modif = modif.filter(function (p) {
                    return p.value != null;
                });

                self.modifUserNames = $rootScope.removeDuplicates(modif, 'value');


                _.forOwn(_.groupBy(promotions, "promotionCampaignName"), function (value, key) {
                    self.distinctCampaigns.push(key);
                });

                _.forOwn(_.groupBy(promotions, "promotionMediaTypeName"), function (value, key) {
                    self.distinctMediaTypes.push(key);
                });

                self.isLoading = false;
                pageLoaded = true;
             });

     }

     function init() {
         getFilters();
         loadData();
     }
        function afterinit() {
            promotionsService.promotions.getPromotionMediaPagesIds().then(function (mediaPagesIds) {
                self.promotionMediaIds = mediaPagesIds;
            })
        }
        init();
        afterinit();
        self.searchMediaPageIdByPromotionId = function (promotionId) {
            for (var i = 0; i < self.promotionMediaIds.length; i++) {
                if (self.promotionMediaIds[i].promotionId == promotionId) {
                    return self.promotionMediaIds[i].promotionMediaPageId;
                }
            }

        }

 });