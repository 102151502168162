angular.module("prisma")
    .controller("EditMasterItemCtrl", ["$window", "$scope", "$stateParams", "$translate", "$state", "homeService", "serverService", "authService", "Upload", "ngDialog",  function ($window, $scope, $stateParams, $translate, $state, homeService, serverService, authService, Upload, ngDialog) {

        //Variables Globales
        var self = this;
        self.stateChange = false;

        self.hasPermissionToEdit = authService.hasPermission('item_admin');

        self.categories = [];
        self.subcategories = [];
        self.types = [];
        self.selectedCategory = null;
        self.selectedTypeId = -1;

        self.loadingVerifyItemEan = false;
        self.resultsVerifyEan = false;
        self.isVerify = false;
        self.unitOfMeasureSelected = null;

        self.item = {
            id: 0,
            code: "",
            name: "",
            ean: "",
            brand: "",
            manufacturer: "",
            distributor: "",
            height: null,
            width: null,
            depth: null,
            weight: null,
            imageUrl: "",
            imagePlanogramFront: null,
            imagePlanogramLeft: null,
            imagePlanogramTop: null,
            isExcluded: false,
            category: { id: 0, name: "", code: "" },
            unitOfMeasures: [{
                id: 0,
                itemId: 0,
                code: "",
                itemCode: 0,
                height: null,
                width: null,
                depth: null,
                weight: null,
                isBaseUnitOfMeasure: true,
                conversion: 1,
                imagePlanogramFront: null,
                imagePlanogramLeft: null,
                imagePlanogramTop: null
            }],
            type: { id: 0, code: "", name: "" }
        }

        function generateUnitOfMeasure() {
            return {
                id: 0,
                itemId: 0,
                code: "",
                itemCode: 0,
                height: null,
                width: null,
                depth: null,
                weight: null,
                isBaseUnitOfMeasure: false,
                conversion: 1,
                imagePlanogramFront: null,
                imagePlanogramLeft: null,
                imagePlanogramTop: null
            }
        }

        self.back = function () {
            $window.history.back();
        }

        self.onCategorySelected = function (category) {
            self.selectedCategory = category;
            self.loadSubcategories()
        }

        self.togleExclude = function () {
            self.item.isExcluded = !self.item.isExcluded;
        }

        function isValidField(field) {
            if (field == undefined || field == null || field == '')
                return false;

            return true;
        }

        self.save = function () {

            //Set Selected Type
            angular.forEach(self.types, function (t) {
                if (t.id == self.selectedTypeId)
                    self.item.type = t;
            });

            if (!isValidField(self.item.code)
                || !isValidField(self.item.name)
                //|| !isValidField(self.item.ean)
                //|| !isValidField(self.item.brand)
                //|| !isValidField(self.item.manufacturer)
                //|| !isValidField(self.item.distributor)
                || !isValidField(self.item.category.code)
                || !isValidField(self.item.type.code))
            {
                swal(translations.ValidationSwalTitle, translations.ValidationSwalSubtitle, 'error');
                return;
            }
            var isValiddUOM = false
            var allItemUoms = [];
            self.uomRepeated = "";
            angular.forEach(self.item.unitOfMeasures, function (_uom) {

                if (allItemUoms.indexOf(_uom.code) == -1)
                    allItemUoms.push(_uom.code);
                else {
                    self.uomRepeated = _uom.code;
                }

                if ( !isValidField(_uom.code)
                    || !isValidField(_uom.height)
                    || !isValidField(_uom.width)
                    || !isValidField(_uom.depth)
                    //|| !isValidField(_uom.conversion)
                    //|| !isValidField(_uom.weight)
                    ) {
                    swal(translations.ValidationSwalTitle, translations.ValidationSwalSubtitle, 'error');
                    isValiddUOM = true
                    return;
                }
                if (self.uomRepeated != "") {
                    swal(translations.ErrorTitleSwal, translations.ValidationSwalUomRepeted, 'error');
                    isValiddUOM = true
                    return;
                }
            })

            if (self.item.code != null && !isValiddUOM) {

                swal({
                    title: translations.SaveItemSwall,
                    text: translations.SaveItemSwallSubtitle,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: translations.ContinueSwal,
                    cancelButtonText: translations.Cancel,
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: true
                },
                    function (isConfirm) {

                        if (!isConfirm) return;

                        //Save Item
                        homeService.items.saveItem(self.item)
                            .then(function () {
                                swal(translations.ItemSavedProperly, "", "success");
                                self.cancel();
                            });
                    }
                );
            }

            self.stateChange = false;
        }

        self.cancel = function () {
            $state.go('admin.items');
        }

        self.onBlur = function () {
            
            if (self.item.ean && self.item.ean.length > 4) {
                self.loadingVerifyItemEan = true;
                self.isVerify = false;
                homeService.items.isItemEanRepeat(self.item.ean)
                    .then(function (result) {
                        self.loadingVerifyItemEan = false;
                        self.resultsVerifyEan = result;
                        self.isVerify = true;
                        result ? self.item.code = '' : self.item.code = self.item.ean;
                    })
            }
        }

        self.loadSubcategories = function () {
            homeService.items.getSubcategories(self.selectedCategory.id)
                .then(function (subcategories) {
                    self.subcategories = subcategories;
                });
        }

        self.addUnitOfMeasure = function () {
            self.item.unitOfMeasures.push(generateUnitOfMeasure());
            //self.unitOfMeasureSelected = null;
        }

        self.deleteUnitOfMeasure = function (uom, index) {
            self.item.unitOfMeasures.splice(index, 1)
        }

        self.togleBaseUnitOfMeasure = function (uom) {
            angular.forEach(self.item.unitOfMeasures, function (_uom) {
                _uom.isBaseUnitOfMeasure = false;
            })

            uom.isBaseUnitOfMeasure = true;
        }
        
        self.uploadFiles = function (files, front, errFiles) {
            if (errFiles && errFiles.length > 0) {
                var allowedSize = null;
                var largeFiles = '';

                angular.forEach(errFiles, function (file) {
                    if (file.$error == 'maxSize') {
                        if (allowedSize == null)
                            allowedSize = file.$errorParam;
                        if (largeFiles != '')
                            largeFiles += ', ';
                        largeFiles += ian.format(' {0} ({1}KB)', file.name, (file.size / 1024).toFixed(0));
                    }
                });

                swal('Peso de imagenes excedido', ian.format('El peso maximo para cada imagen es {0}. Las siguientes imagenes exceden el peso permitido: {1}', allowedSize, largeFiles), 'error');
            }
            else if (files && files.length) {
                var filesToBig = '';
                for (i = 0; i < files.length; i++) {
                    if (files[i].size > 153600) {
                        filesToBig = 'El peso de cada imagen no debe superar los 150KB';
                        break;
                    };
                }

                if (filesToBig != '') {
                    swal('Peso de imagenes excedido', filesToBig, 'error');
                }
                else {

                    self.isBusy = true;
                    var photoName = self.item.ean + front + self.unitOfMeasureSelected.code + Math.random();

                    var url = ian.urlCombine(serverService.getApiUrl(), '/admin/items/uploadFile/' + photoName);
                    Upload.upload({
                        url: url,
                        data: { files: files }
                    }).then(function (response) {
                        var uploadResults = response.data;
                        processResults(uploadResults, front);
                        if (!anyError(uploadResults))
                            self.filesUploaded = true;
                    });
                }
            }
        }

        function processResults(uploadResults, front) {
            self.isBusy = false;
            if (uploadResults.length > 0) {
                loadResults(uploadResults, front);
            }
            if (anyError(uploadResults)) {
                self.error = true;
            }
            else {
                self.filesUploaded = true;
                self.error = false;
                self.files = [];
            }
        }
        function anyError(uploadResults) {
            var error = false;
            for (var i = 0; i < uploadResults.length; i++) {
                if (uploadResults[i].severity === 3)
                    error = true;
            }

            return error;
        }

        function loadResults(results, front) {
            if (front == 'Front') self.unitOfMeasureSelected.imagePlanogramFront = results[0]; 

            if (front == 'Left') self.unitOfMeasureSelected.imagePlanogramLeft = results[0]; 

            if (front == 'Top') self.unitOfMeasureSelected.imagePlanogramTop = results[0]; 
          
        }



        //Dialog upload photos
    
        self.showUploadPhotosModal = function (unitOfMeasure) {
            self.unitOfMeasureSelected = unitOfMeasure;
            //self.unitOfMeasureSelected.imagePlanogramFront == null;
            ngDialog.open({
                template: 'modal-uploadPhotos',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-500',
                scope: $scope
            });
        }

        self.okLoadPhoto = function () {
            ngDialog.close();
        }

        //button Collapsed
        $scope.isNavCollapsed = true;
        $scope.isCollapsed = false;
        $scope.isCollapsedHorizontal = false;

        $scope.isBasicInformationCollapsed = false;
        $scope.isGeneralInformationCollapsed = false;
        $scope.isMeasurementsCollapsed = false;
        

        //Load Data
        function loadItem() {
            self.isLoading = true;

            homeService.items.getItem($stateParams.itemId)
                .then(function (item) {
                    self.item = item;
                    self.selectedTypeId = self.item.type.id;
                    angular.forEach(self.item.unitOfMeasures, function (_uom) {
                        self.item.baseUnitOfMeasureId == _uom.id ? _uom.isBaseUnitOfMeasure = true : _uom.isBaseUnitOfMeasure = false;
                    })
                    
                    self.isLoading = false;
                });
        }

        function loadCategories() {
            homeService.items.getCategories()
                .then(function (categories) {
                    self.categories = categories;
                });
        }
        function loadTypes() {
            homeService.items.getTypes()
                .then(function (types) {
                    self.types = types;
                });
        }

        $scope.$on('$stateChangeStart', function (event) {
            if (self.stateChange) {
                var answer = confirm(translations.AlertWillLoseChanges)
                if (!answer) {
                    event.preventDefault();
                }
            }
        });

        var translations = null;

        function init() {
            $translate(["ValidationSwalTitle", "ValidationSwalSubtitle", "SaveItemSwall", "SaveItemSwallSubtitle", "Cancel", "ContinueSwal", "ItemSavedProperly", "AlertWillLoseChanges", "ValidationSwalUomRepeted", "ErrorTitleSwal" ])
                .then(function (all) {
                    translations = all;
                });

            loadCategories();
            loadTypes();

            if ($stateParams.itemId != 0) {
                loadItem();
            }
        }

        init();
    }]);




