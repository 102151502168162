angular.module("prisma")
    .controller("HeatMapCtrl", function ($state, $stateParams, $scope, $rootScope, $filter, $http, $translate, homeService) {
        var self = this;
        var geojson = null;
        var heatMap = null;
        var legend = L.control({ position: 'bottomright' });
        var country = '';
        var initialFeature = null;
        var initialLayer = null;

        var lastProvince = '';
        var layers = [];
        self.sameStores = true;
        self.isZoomAplied = false;
        self.moneySymbol = '';
        self.metadata = { title: '', houses: 0, population: 0, sales: 0, units: 0, margin: 0, salesDelta: 0, unitsDelta: 0, marginDelta: 0 }
        self.emptyData = { sales: undefined, units: undefined, margin: undefined, salesDelta: undefined, unitsDelta: undefined, marginDelta: undefined }
        self.heatType = 'salesDelta';
        self.selectedCategory = {};
        self.timeType = '1';
        self.highlightedLayer;


        $scope.$watch('ct.sameStores', function (newVal, oldVar) {
            //console.log('newval', newVal);
            self.updateTime(newVal)
        });

        self.onCategorySelected = function (item) {
            //console.log('categoria', item);
            self.selectedCategory = item;
            self.updateTime(self.sameStores);
        }

        self.reload = function () {
            $state.go('home.heatMap', {}, { reload: true });
        }

        self.updateTime = function (withSameStores) {
            
            homeService.dashboards.getHeatMapByState(self.timeType, self.selectedCategory.id, withSameStores)
                .then(function (data) {

                    console.log('data', data);
                    statesData = data;

                    _.assign(self.metadata, data);
                    if (data) {

                        dealWithSubCategories(statesData.subcategories);

                    }
                    else
                        cleanLayers();
                    self.updateHeat();
                });
        }

        function cleanLayers() {
            var data = { houses: 0, population: 0, sales: undefined, units: undefined, margin: undefined, salesDelta: undefined, unitsDelta: undefined, marginDelta: undefined }

            _.each(layers, function (layerItem) {
                _.assign(layerItem.feature.properties, data);
            })
            _.assign(self.metadata, data);
        }

        function dealWithSubCategories(sub) {

            _.each(sub, function (subItem) {

                //Me fijo que el layer highlighted se refresque-
                if (self.highlightedLayer && self.highlightedLayer.feature.properties.category.toLowerCase() == subItem.category.toLowerCase()
                    && self.highlightedLayer.feature.properties.entityType == subItem.entityType
                    && self.highlightedLayer.feature.properties.subcategory.toLowerCase() == subItem.subcategory.toLowerCase()) {
                    _.assign(self.metadata, subItem);
                }
                _.each(layers, function (layerItem) {
                    if (subItem.category == layerItem.feature.properties.category &&
                        subItem.entityType == layerItem.feature.properties.entityType &&
                        layerItem.feature.properties.subcategory && subItem.subcategory &&
                        layerItem.feature.properties.subcategory.toLowerCase() == subItem.subcategory.toLowerCase()) {
                        _.assign(layerItem.feature.properties, subItem);
                    }
                })

                if (subItem.subcategories.length > 0) {
                    dealWithSubCategories(subItem.subcategories)
                }
            })
        }


        self.updateHeat = function () {
            console.log(layers);
            _.each(layers, function (layer) {
                layer.setStyle({
                    fillColor: getColor(layer.feature.properties[self.heatType])
                });
            })
        }


        //Leyenda
        legend.onAdd = function (map) {

            var div = L.DomUtil.create('div', 'info legend'),
                //grades = [0.31, 0.20, 0.10, 0.01, -0.1, -0.2, -0.3, -0.31],
                labels = [],
                prefix, from;

            labels.push(
                '<i style="background:' + getColor(0.31) + '"></i> ' + translations.GreaterThan + ' ' + $rootScope.toPercentage(0.31)
            );

            labels.push(
                '<i style="background:' + getColor(0.25) + '"></i> ' + translations.Between + ' ' + $rootScope.toPercentage(0.20) + ' y ' + $rootScope.toPercentage(0.30)
            );

            labels.push(
                '<i style="background:' + getColor(0.15) + '"></i> ' + translations.Between + ' ' + $rootScope.toPercentage(0.1) + ' y ' + $rootScope.toPercentage(0.19)
            );

            labels.push(
                '<i style="background:' + getColor(0.05) + '"></i> ' + translations.Between + ' ' + $rootScope.toPercentage(0.01) + ' y ' + $rootScope.toPercentage(0.09)
            );

            labels.push(
                '<i style="background:' + getColor(-0.05) + '"></i> ' + translations.Between + ' ' + $rootScope.toPercentage(0) + ' y ' + $rootScope.toPercentage(-0.09)
            );

            labels.push(
                '<i style="background:' + getColor(-0.15) + '"></i> ' + translations.Between + ' ' + $rootScope.toPercentage(-0.1) + ' y ' + $rootScope.toPercentage(-0.19)
            );

            labels.push(
                '<i style="background:' + getColor(-0.25) + '"></i> ' + translations.Between + ' ' + $rootScope.toPercentage(-0.2) + ' y ' + $rootScope.toPercentage(-0.30)
            );

            labels.push(
                '<i style="background:' + getColor(-0.31) + '"></i> ' + translations.LessThan + ' ' + $rootScope.toPercentage(-0.31)
            );

            div.innerHTML = labels.join('<br>');
            return div;

        };


        function loadProvincias() {
            $http.get('/app/home/heatMap/' + country + '/provincias.json').success(function (geoJsonData) {
                geojson = L.geoJson(geoJsonData, {
                    onEachFeature: loadProvincia,
                    style: getStyle
                }).addTo(heatMap);
            });
        }

        function loadProvincia(feature, layer) {
            feature.properties.category = layer.feature.properties.provincia;

            var provincias = _.filter(statesData.subcategories, function (o) {
                if (o.category != null)
                    return o.category.toLowerCase() == feature.properties.category.toLowerCase();
                else
                    return false;
            });

            var data = provincias.length > 0 ? provincias[0] : null;

            if (data != null) {
                //console.log('data', data);

                _.assign(feature.properties, data);
                _.assign(layer.feature.properties, data);
                //console.log('layer', layer);

            } else {
                _.assign(feature.properties, self.emptyData);
                _.assign(layer.feature.properties, self.emptyData);
            }

            layer.setStyle({
                fillColor: getColor(feature.properties[self.heatType])
            });

            layer.on({
                mouseover: highlightFeature,
                mouseout: resetHighlight,
                click: zoomToFeature
            });

            layers.push(layer);
        }

        function getColor(d) {
            //console.log(d);
            return d > 0.3 ? '#38b550' : //mas de 30
                d > 0.2 ? '#62bb46' : // entre 20 y 30
                    d > 0.1 ? '#7bc470' ://entre 10 y 20
                        d > 0 ? '#a4cf57' : //entre 0 y 10
                            d < -0.3 ? '#da203d' : // menos de -30
                                d < -0.2 ? '#f04e29' : // entre -20 y -30
                                    d < -0.1 ? '#f99d1c' : // entre -10 y - 20
                                        d <= 0 ? '#ffd400' : // entre 0 y -10
                                            '#CCCCCC'; // null o undefined
        }

        function getStyle(feature) {

            return {
                fillOpacity: 0.9,
                color: 'white',
                weight: 1.5,
                opacity: 0.8,
                dashArray: '3'
            };
        }

        function highlightFeature(e) {
            var layer = e.target.feature ? e.target : $(this).data('layer');
            self.highlightedLayer = layer;

            self.metadata.entityType = layer.feature.properties.entityType;
            if (layer.feature.properties.provincia == undefined) {
                self.metadata.title = layer.feature.properties.category + ' - ' + layer.feature.properties.subcategory;
                self.metadata.population = layer.feature.properties.TOTAL_VARONES + layer.feature.properties.TOTAL_MUJERES;
                self.metadata.houses = layer.feature.properties.TOTAL_VIVIENDAS;
            }
            else {
                self.metadata.title = layer.feature.properties.category;
            }

            //console.log(layer.feature.properties);    
            _.assign(self.metadata, layer.feature.properties);

            layer.setStyle({
                weight: 5,
                dashArray: '',
                fillOpacity: 0.7
            });

            if (!L.Browser.ie && !L.Browser.opera) {
                layer.bringToFront();
            }

            $scope.$apply();

        }

        function resetHighlight(e) {
            var layer = e.target.feature ? e.target : $(this).data('layer');
            //var layer = e.target;
            geojson.resetStyle(layer);

            layer.setStyle({
                fillColor: getColor(layer.feature.properties[self.heatType]),
            });
        }

        function zoomToFeature(e) {
            self.isZoomAplied = true;
            var layer = e.target.feature ? e.target : $(this).data('layer');
            heatMap.fitBounds(layer.getBounds());
            loadDepartamentos(layer.feature);
            layer.off({ mouseover: highlightFeature });
            layer.remove();
        }

        function zoomToDepartamento(e) {
            var layer = e.target.feature ? e.target : $(this).data('layer');
            heatMap.fitBounds(layer.getBounds());

            loadStores(layer.feature);
        }

        function loadStores(feature) {

            statesData.subcategories.forEach(function (category) {

                category.subcategories.forEach(function (subcategory) {

                    subcategory.subcategories.forEach(function (store) {

                        if (store.lat != 0 && store.long != 0 && store.lat != null && store.long != null) {

                            if (feature.properties.category.toLowerCase() == category.category.toLowerCase() && feature.properties.subcategory.toLowerCase() == subcategory.subcategory.toLowerCase()) {
                                //if (feature.properties.category.toLowerCase() == category.category.toLowerCase() && feature.properties.subcategory.toLowerCase() == subcategory.subcategory.toLowerCase()) {
                                //console.log('showing store',store);
                                var competitorIcon = L.icon({
                                    iconUrl: 'assets/images/competitors/' + store.icon,

                                    iconSize: [24, 24], // size of the icon
                                    iconAnchor: [0, 0], // point of the icon which will correspond to marker's location
                                    popupAnchor: [12, 6] // point from which the popup should open relative to the iconAnchor
                                });


                                var html = '<table class="table"><tbody><tr><td colspan="3">' + '<b>' + store.name + '</b></td></tr><tr><td>' + translations.SALES + '</td><td class="text-right">' + $filter('currency')(store.sales, "$ ", 0)
                                    + '</td><td class="stat-percent font-bold ' + (store.salesDelta < 0 ? 'text-danger' : (store.salesDelta > 0 ? 'text-navy' : 'text-muted')) + '">'
                                    + $rootScope.toPercentage(store.salesDelta) + '<i class="' + (store.salesDelta < 0 ? 'fa fa-level-down' : (store.salesDelta > 0 ? 'fa fa-level-up' : 'fa fa-minus')) + '"></i></td></tr>'
                                    + '<tr><td>' + translations.UNITS + '</td><td class="text-right">' + $filter('number')(store.units, 0)
                                    + '</td><td class="stat-percent font-bold ' + (store.unitsDelta < 0 ? 'text-danger' : (store.unitsDelta > 0 ? 'text-navy' : 'text-muted')) + '">'
                                    + $rootScope.toPercentage(store.unitsDelta) + '<i class="' + (store.unitsDelta < 0 ? 'fa fa-level-down' : (store.unitsDelta > 0 ? 'fa fa-level-up' : 'fa fa-minus')) + '"></i></td></tr>'
                                    + '<tr><td>' + translations.MARGIN + '</td><td class="text-right">' + $filter('currency')(store.margin, "$ ", 0)
                                    + '</td><td class="stat-percent font-bold ' + (store.marginDelta < 0 ? 'text-danger' : (store.marginDelta > 0 ? 'text-navy' : 'text-muted')) + '">'
                                    + $rootScope.toPercentage(store.marginDelta) + '<i class="' + (store.marginDelta < 0 ? 'fa fa-level-down' : (store.marginDelta > 0 ? 'fa fa-level-up' : 'fa fa-minus')) + '"></i></td></tr></tbody></table>';

                                var marker = L.marker([store.lat, store.long], { icon: competitorIcon }).addTo(heatMap)
                                    .bindPopup(html);

                                //marker.valueOf()._icon.style.backgroundColor = getColor(store.marginDelta);
                            }
                        }
                    });

                });

            });
        }

        function loadDepartamento(feature, layer) {

            layer.feature.properties.PROVINCIA = layer.feature.properties.PROVINCIA == undefined ? lastProvince : layer.feature.properties.PROVINCIA;
            feature.properties.category = layer.feature.properties.PROVINCIA;
            feature.properties.subcategory = layer.feature.properties.DEPARTAMTO;


            var provincias = _.filter(statesData.subcategories, function (o) {

                if (o.category != null)
                    return o.category.toLowerCase() == feature.properties.category.toLowerCase();
                else
                    return false;
            });
            var provincia = provincias.length > 0 ? provincias[0] : null;
            if (provincia != null) {
                var departamentos = _.filter(provincia.subcategories, function (o) {
                    if (o.subcategory) {
                        return o.subcategory.toLowerCase() == feature.properties.subcategory.toLowerCase();
                    }
                });

                var data = departamentos.length > 0 ? departamentos[0] : null;

                if (data != null) {
                    _.assign(feature.properties, data);
                    _.assign(layer.feature.properties, data);

                } else {
                    //console.log('emptydata');
                    _.assign(feature.properties, self.emptyData);
                    _.assign(layer.feature.properties, self.emptyData);
                }
            }

            layer.setStyle({
                fillColor: getColor(feature.properties[self.heatType])
            });

            layer.on({
                mouseover: highlightFeature,
                mouseout: resetHighlight,
                click: zoomToDepartamento
            });
            //console.log(layer);
            layers.push(layer);

        }

        var isDepartamentosLoaded = false;
        function loadDepartamentos(feature) {

            lastProvince = feature.properties.provincia;
            if (country == "Peru") {
                if (!isDepartamentosLoaded) {
                    $http.get('/app/home/heatMap/peru/provincias/departamentos.json').success(function (geoJsonData) {
                        L.geoJson(geoJsonData, {
                            onEachFeature: loadDepartamento,
                            style: getStyle
                        }).addTo(heatMap);
                    });
                    isDepartamentosLoaded = true;
                }
            }
            if (country == "Colombia") {
                if (!isDepartamentosLoaded) {
                    $http.get('/app/home/heatMap/colombia/provincias/departamentos.json').success(function (geoJsonData) {
                        L.geoJson(geoJsonData, {
                            onEachFeature: loadDepartamento,
                            style: getStyle
                        }).addTo(heatMap);
                    });
                    isDepartamentosLoaded = true;
                }
            }
            else if (country == "Argentina") {
                $http.get('/app/home/heatMap/argentina/provincias/' + feature.properties.provincia.toUpperCase() + '.json').success(function (geoJsonData) {
                    L.geoJson(geoJsonData, {
                        onEachFeature: loadDepartamento,
                        style: getStyle
                    }).addTo(heatMap);
                });
            }
            else if (country == "Mexico") {

                for (var i = 1; i <= feature.properties.size; i++) {

                    var url = ('00' + feature.properties.index).slice(-2);
                    //var url = ('00' + "01").slice(-2);
                    url += ('00' + i).slice(-3);

                    $http.get('/app/home/heatMap/mexico/provincias/' + url + '.json').success(function (geoJsonData) {
                        L.geoJson(geoJsonData, {
                            onEachFeature: loadDepartamento,
                            style: getStyle
                        }).addTo(heatMap);
                    });
                }
            }
            else if (country == "Chile") {
                $http.get('/app/home/heatMap/chile/provincias/' + feature.properties.provincia.toLowerCase() + '.json').success(function (geoJsonData) {
                    L.geoJson(geoJsonData, {
                        onEachFeature: loadDepartamento,
                        style: getStyle
                    }).addTo(heatMap);
                });
            }
        }

        var statesData = null;
        function loadData() {
            homeService.dashboards.getHeatMapByState(self.timeType)
                .then(function (data) {
                    //console.log('data', data);
                    statesData = data;

                    _.assign(self.metadata, data);

                    loadProvincias();
                });
        }

        var translations = null;

        function init() {

            $translate(["GreaterThan", "Between", "LessThan", "UNITS", "SALES", "MARGIN"])
                .then(function (all) {

                    translations = all;
                    self.isZoomAplied = false;
                    homeService.dashboards.getCountry()
                        .then(function (c) {
                            country = c;
                            //country = 'Colombia';
                            //country = 'Peru';
                            //country = 'Argentina';

                            self.metadata.title = country;

                            if (country == "Peru") {
                                heatMap = L.map('heatMap').setView([-8.8822898, -73.7699384], 5); //Perú
                                self.moneySymbol = '$';
                            }
                            if (country == "Colombia") {
                                heatMap = L.map('heatMap').setView([1.8822898, -73.7699384], 5); //Colombia
                                self.moneySymbol = '$';
                            }
                            else if (country == "Argentina") {
                                heatMap = L.map('heatMap').setView([-38.005, -61.0739], 4); //Argentina
                                self.moneySymbol = '$';
                            }
                            else if (country == "Mexico") {
                                heatMap = L.map('heatMap').setView([21.695501, -101.666045], 5); //Mexico
                                self.moneySymbol = '$';
                            }
                            else if (country == "Chile") {
                                heatMap = L.map('heatMap').setView([-33.4718999, -70.9100212], 5); //Chile
                                self.moneySymbol = '$';
                            }
                            L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                                subdomains: ['a', 'b', 'c']
                            }).addTo(heatMap);

                            legend.addTo(heatMap);


                            loadData();
                        });
                });
        }
        init();
    });
