
angular.module("prisma")
  .controller("InformedPricesReportCtrl", function ($state, $rootScope, priceService) {
      var self = this;

      var numberColWidth = 100;

      var columnDefs = null;

      var toDate = new Date();
      toDate.setTime(toDate.getTime() + 86400000); //A partir de ma�ana
      self.toDate = toDate;

      var fromDate = new Date();
      fromDate.setTime(fromDate.getTime() - 3 * 86400000); //Ultimos 3 dias
      self.fromDate = fromDate;

      self.onlyNotImplemented = false;

      self.data = [];

      self.onlyNotImplementedChange = function () {
          if (self.data.length > 0) {
              if (!self.onlyNotImplemented) {
                  self.gridOptions.api.setRowData(self.data)
              } else {
                  var temp = [];
                  angular.forEach(self.data, function (row) {
                      if (row.implementedDate == null)
                          temp.push(row);
                  });
                  self.gridOptions.api.setRowData(temp);
              }
          }
      }

      function setColumnDefinitions() {
          columnDefs = [
                  {
                      headerName: "Informed",
                      field: "informedDate",
                      width: numberColWidth + 60,
                      filter: 'date',
                      cellClass: function (params) {
                          var cls = $rootScope.getClassForCell(params);
                          if (params.data.implementedDate == null) {
                              cls.push("invalid-implemented")
                          }
                          return cls;
                      },
                      cellRenderer: $rootScope.dateTimeRenderer
                  },
                    {
                        headerName: "Implemented",
                        field: "implementedDate",
                        width: numberColWidth + 60,
                        filter: 'date',
                        cellClass: $rootScope.getClassForCell,
                        cellRenderer: $rootScope.dateTimeRenderer
                    },
                  {
                      headerName: "Zone Code",
                      field: "priceListCode",
                      headerTooltip: "Price zone code",
                      width: numberColWidth + 10,
                      cellClass: $rootScope.getClassForCell,
                  },
                  {
                      headerName: "Zone",
                      field: "priceList",
                      width: numberColWidth + 100,
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "Station",
                      field: "storeCode",
                      width: numberColWidth + 10,
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "Item",
                      field: "itemCode",
                      width: numberColWidth + 10,
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "UOM",
                      field: "salesUnitOfMeasureCode",
                      headerTooltip: "Sales unit code of sale",
                      width: numberColWidth + 40,
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "Previous price",
                      field: "_Price",
                      width: numberColWidth + 20,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.moneyRenderer,
                  },
                  {
                      headerName: "New price",
                      field: "newPrice",
                      width: numberColWidth + 20,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.moneyRenderer,
                  },
                  {
                      headerName: "Effective date",
                      field: "newValidFrom",
                      width: numberColWidth + 60,
                      filter: 'date',
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.dateTimeRenderer
                  },
          ]
      }

      self.gridOptions = {
          columnDefs: columnDefs,
          enableColResize: true,
          enableSorting: true,
          enableFilter: false,
          groupHeaders: false,
          suppressCellSelection: true,
          rowHeight: 35,
          groupHideGroupColumns: true,
          icons: {
              columnRemoveFromGroup: '<i class="fa fa-remove"/>',
              filter: '<i class="fa fa-filter"/>',
              sortAscending: '<i class="fa fa-long-arrow-down"/>',
              sortDescending: '<i class="fa fa-long-arrow-up"/>',
              groupExpanded: '<i class="fa fa-minus-square-o"/>',
              groupContracted: '<i class="fa fa-plus-square-o"/>',
              columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
              columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
          },
          angularCompileRows: true
      }

      self.exportToExcel = function () {
          swal({
              title: 'Exportar',
              text: "Desea exportar estos registros a un archivo excel?.",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#1AB394",
              confirmButtonText: "Continuar",
              cancelButtonText: "Cancelar",
              showLoaderOnConfirm: true,
              closeOnConfirm: false,
              closeOnCancel: true
          },
        function (isConfirm) {

            if (!isConfirm) return;

            priceService.reports.getInformedPricesExcel(self.lastFromDate, self.lastToDate).then(function (url) {
                //console.log('data', rows);
                //self.gridOptions.api.setRowData(rows);
                window.open(url);
                //console.log('excel url', rows);
                swal("Exportados correctamente", '', "success");
            });
        });

          //priceService.reports.getInformedPricesExcel(self.lastFromDate, self.lastToDate).then(function (url) {
          //    //console.log('data', rows);
          //    //self.gridOptions.api.setRowData(rows);
          //    window.open(url);
          //    //console.log('excel url', rows);
          //});
      }

      function setGridOptions() {

          setColumnDefinitions();

          self.gridOptions = {
              columnDefs: columnDefs,
              enableColResize: true,
              enableSorting: true,
              enableFilter: false,
              groupHeaders: false,
              suppressCellSelection: true,
              rowHeight: 35,
              groupHideGroupColumns: true,
              icons: {
                  columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                  filter: '<i class="fa fa-filter"/>',
                  sortAscending: '<i class="fa fa-long-arrow-down"/>',
                  sortDescending: '<i class="fa fa-long-arrow-up"/>',
                  groupExpanded: '<i class="fa fa-minus-square-o"/>',
                  groupContracted: '<i class="fa fa-plus-square-o"/>',
                  columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                  columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
              }
          }
      }

      self.loadData = function () {

          var fromDate = convertDate(self.fromDate);
          var toDate = convertDate(self.toDate);

          self.lastFromDate = angular.copy(fromDate);
          self.lastToDate = angular.copy(toDate);

          priceService.reports.getInformedPrices(fromDate, toDate).then(function (rows) {
              //console.log('data', rows);
              self.data = rows;
              self.gridOptions.api.setRowData(rows);
          });
      }

      function convertDate(inputFormat) {
          function pad(s) { return (s < 10) ? '0' + s : s; }
          var d = new Date(inputFormat);
          return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
      }

      function init() {
          //init columnDefs and gridOptions
          setGridOptions();

          //load data
          self.loadData();
      }

      init();

  });

