/***********************************************
******** Iantech Javascript Framework **********
***********************************************/

var ian = ian || {};

var defaultDiacriticsRemovalMap = [
      { 'base': "A", 'letters': /[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g },
      { 'base': "AA", 'letters': /[\uA732]/g },
      { 'base': "AE", 'letters': /[\u00C6\u01FC\u01E2]/g },
      { 'base': "AO", 'letters': /[\uA734]/g },
      { 'base': "AU", 'letters': /[\uA736]/g },
      { 'base': "AV", 'letters': /[\uA738\uA73A]/g },
      { 'base': "AY", 'letters': /[\uA73C]/g },
      { 'base': "B", 'letters': /[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g },
      { 'base': "C", 'letters': /[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g },
      { 'base': "D", 'letters': /[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g },
      { 'base': "DZ", 'letters': /[\u01F1\u01C4]/g },
      { 'base': "Dz", 'letters': /[\u01F2\u01C5]/g },
      { 'base': "E", 'letters': /[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g },
      { 'base': "F", 'letters': /[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g },
      { 'base': "G", 'letters': /[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g },
      { 'base': "H", 'letters': /[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g },
      { 'base': "I", 'letters': /[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g },
      { 'base': "J", 'letters': /[\u004A\u24BF\uFF2A\u0134\u0248]/g },
      { 'base': "K", 'letters': /[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g },
      { 'base': "L", 'letters': /[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g },
      { 'base': "LJ", 'letters': /[\u01C7]/g },
      { 'base': "Lj", 'letters': /[\u01C8]/g },
      { 'base': "M", 'letters': /[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g },
      { 'base': "N", 'letters': /[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g },
      { 'base': "NJ", 'letters': /[\u01CA]/g },
      { 'base': "Nj", 'letters': /[\u01CB]/g },
      { 'base': "O", 'letters': /[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g },
      { 'base': "OI", 'letters': /[\u01A2]/g },
      { 'base': "OO", 'letters': /[\uA74E]/g },
      { 'base': "OU", 'letters': /[\u0222]/g },
      { 'base': "P", 'letters': /[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g },
      { 'base': "Q", 'letters': /[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g },
      { 'base': "R", 'letters': /[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g },
      { 'base': "S", 'letters': /[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g },
      { 'base': "T", 'letters': /[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g },
      { 'base': "TZ", 'letters': /[\uA728]/g },
      { 'base': "U", 'letters': /[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g },
      { 'base': "V", 'letters': /[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g },
      { 'base': "VY", 'letters': /[\uA760]/g },
      { 'base': "W", 'letters': /[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g },
      { 'base': "X", 'letters': /[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g },
      { 'base': "Y", 'letters': /[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g },
      { 'base': "Z", 'letters': /[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g },
      { 'base': "a", 'letters': /[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g },
      { 'base': "aa", 'letters': /[\uA733]/g },
      { 'base': "ae", 'letters': /[\u00E6\u01FD\u01E3]/g },
      { 'base': "ao", 'letters': /[\uA735]/g },
      { 'base': "au", 'letters': /[\uA737]/g },
      { 'base': "av", 'letters': /[\uA739\uA73B]/g },
      { 'base': "ay", 'letters': /[\uA73D]/g },
      { 'base': "b", 'letters': /[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g },
      { 'base': "c", 'letters': /[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g },
      { 'base': "d", 'letters': /[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g },
      { 'base': "dz", 'letters': /[\u01F3\u01C6]/g },
      { 'base': "e", 'letters': /[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g },
      { 'base': "f", 'letters': /[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g },
      { 'base': "g", 'letters': /[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g },
      { 'base': "h", 'letters': /[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g },
      { 'base': "hv", 'letters': /[\u0195]/g },
      { 'base': "i", 'letters': /[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g },
      { 'base': "j", 'letters': /[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g },
      { 'base': "k", 'letters': /[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g },
      { 'base': "l", 'letters': /[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g },
      { 'base': "lj", 'letters': /[\u01C9]/g },
      { 'base': "m", 'letters': /[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g },
      { 'base': "n", 'letters': /[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g },
      { 'base': "nj", 'letters': /[\u01CC]/g },
      { 'base': "o", 'letters': /[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g },
      { 'base': "oi", 'letters': /[\u01A3]/g },
      { 'base': "ou", 'letters': /[\u0223]/g },
      { 'base': "oo", 'letters': /[\uA74F]/g },
      { 'base': "p", 'letters': /[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g },
      { 'base': "q", 'letters': /[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g },
      { 'base': "r", 'letters': /[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g },
      { 'base': "s", 'letters': /[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g },
      { 'base': "t", 'letters': /[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g },
      { 'base': "tz", 'letters': /[\uA729]/g },
      { 'base': "u", 'letters': /[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g },
      { 'base': "v", 'letters': /[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g },
      { 'base': "vy", 'letters': /[\uA761]/g },
      { 'base': "w", 'letters': /[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g },
      { 'base': "x", 'letters': /[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g },
      { 'base': "y", 'letters': /[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g },
      { 'base': "z", 'letters': /[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g }
];

//string formating accepting any param quantity
//usage: ian.format('Just use {0} in the {1}', 'placeholders', 'string');
ian.format = function (format) {
    var args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != "undefined" ? args[number] : match;
    });
};

ian.getCookie = function getCookie(name) {
    if (document.cookie.length > 0) {
        c_start = document.cookie.indexOf(name + "=");
        if (c_start != -1) {
            c_start = c_start + name.length + 1;
            c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = document.cookie.length;
            }
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return "";
}

ian.setCookie = function (name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

ian.escapeRegex = function (str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

ian.replaceAll = function (find, replace, str) {
    return str.replace(new RegExp(ian.escapeRegex(find), "g"), replace);
}

ian.combine = function (separator) {
    //combine
    var args = Array.prototype.slice.call(arguments, 1);
    var argArray = [];
    for (var i = 0; i < args.length; i++) {
        argArray.push(args[i]);
    }

    return argArray.join(separator);
}

ian.urlCombine = function () {
    var separator = "/";

    //fix arguments
    for (var i = 0; i < arguments.length; i++) {
        var arg = arguments[i];
        if (arg.startsWith(separator)) {
            arg = arg.replace(separator, ""); //remove starting slash
        }
        if (arg.endsWith(separator)) {
            arg = arg.replace(new RegExp(separator + "$"), ""); //remove ending slash
        }
        arguments[i] = arg;
    }

    Array.prototype.unshift.call(arguments, separator);
    return ian.combine.apply(null, arguments);
}

ian.pathCombine = function () {
    var separator = "\\";

    //fix arguments
    for (var i = 0; i < arguments.length; i++) {
        var arg = arguments[i];
        if (arg.startsWith(separator)) {
            arg = arg.replace(separator, ""); //remove starting slash
        }
        if (arg.endsWith(separator)) {
            arg = arg.replace(new RegExp(separator + "$"), ""); //remove ending slash
        }
        arguments[i] = arg;
    }

    Array.prototype.unshift.call(arguments, separator);
    return ian.combine.apply(null, arguments);
}

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
ian.getRandomArbitrary = function (min, max) {
    return Math.random() * (max - min) + min;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive)
 * Using Math.round() will give you a non-uniform distribution!
 */
ian.getRandomInt = function (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

ian.abbreviateNumber = function (num, precision, prefix) {
    var str,
    suffix = '';
    var rv = 0;
    num = parseFloat(num);
    precision = precision || 2;
    var isNegative = num < 0;
    num = Math.abs(num);

    var factor = Math.pow(10, precision);

    if (num < 1000) {
        str = num;
    } else if (num < 1000000) {
        str = Math.floor(num / (1000 / factor)) / factor;
        suffix = 'K';
    } else if (num < 1000000000) {
        str = Math.floor(num / (1000000 / factor)) / factor;
        suffix = 'M';
    } else if (num < 1000000000000) {
        str = Math.floor(num / (1000000000 / factor)) / factor;
        suffix = 'B';
    } else if (num < 1000000000000000) {
        str = Math.floor(num / (1000000000000 / factor)) / factor;
        suffix = 'T';
    }

    if (isNegative)
        str = parseFloat(str) * -1;

    if (prefix)
        rv = prefix + str + suffix;
    else
        rv = str + suffix;



    return rv;
}

ian.abbreviateNumberThousand = function (num, precision, prefix) {
    var str,
    suffix = '';
    var rv = 0;

    precision = precision || 2;
    var isNegative = num < 0;
    num = Math.abs(num);

    var factor = Math.pow(10, precision);

    if (num < 1000) {
        str = num;
    } else  {
        str = Math.floor(num / (1000 / factor)) / factor;

        suffix = 'K';
    } 

    if (isNegative)
        str = parseFloat(str) * -1;

    var hasDecimals = num % 1 != 0;
    if (Math.abs(parseFloat(str)) > 99 && hasDecimals)
        str = str.toFixed(0);
    else
        str = str.toFixed(precision);

    if (prefix)
        rv = prefix + str + suffix;
    else
        rv = str + suffix;



    return rv;
}

ian.imageExists = function (image_url) {

    var http = new XMLHttpRequest();

    http.open('HEAD', image_url, false);
    http.send();

    return http.status != 404;

}

ian.ExportToExcel = function (tableHtml, worksheetName) {
    var uri = 'data:application/vnd.ms-excel;base64,',
       template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
       base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))); },
       format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

    var ctx = { worksheet: worksheetName, table: tableHtml },
        href = uri + base64(format(template, ctx));
    return href;
}

ian.removeDuplicates = function (myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
}


/*** Extensions and Polyfills ***/

if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, "startsWith", {
        enumerable: false,
        configurable: false,
        writable: false,
        value: function (searchString, position) {
            position = position || 0;
            return this.lastIndexOf(searchString, position) === position;
        }
    });
}

if (!String.prototype.endsWith) {
    Object.defineProperty(String.prototype, "endsWith", {
        value: function (searchString, position) {
            var subjectString = this.toString();
            if (position === undefined || position > subjectString.length) {
                position = subjectString.length;
            }
            position -= searchString.length;
            var lastIndex = subjectString.indexOf(searchString, position);
            return lastIndex !== -1 && lastIndex === position;
        }
    });
}

if (!Array.prototype.find) {
    Array.prototype.find = function (predicate) {
        if (this == null) {
            throw new TypeError("Array.prototype.find called on null or undefined");
        }
        if (typeof predicate !== "function") {
            throw new TypeError("predicate must be a function");
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return value;
            }
        }
        return undefined;
    };
}

if (!String.prototype.replaceAll) {
    Object.defineProperty(String.prototype, "replaceAll", {
        value: function (find, replace) {
            var str = this.toString();
            return str.replace(new RegExp(ian.escapeRegex(find), "g"), replace);
        }
    });
}

if (!String.prototype.removeDiacritics) {
    Object.defineProperty(String.prototype, "removeDiacritics", {
        value: function (find, replace) {
            var str = this.toString();
            for (var i = 0; i < defaultDiacriticsRemovalMap.length; i++) {
                str = str.replace(defaultDiacriticsRemovalMap[i].letters, defaultDiacriticsRemovalMap[i].base);
            }

            return str;
        }
    });
}

String.prototype.toUnicode = function () {
    return this.replace(/./g, function (c) { return ("\\u00" + c.charCodeAt(0).toString(16)) });
};

function toUnicode(txt) {

    return txt.replace(/./g, function (c) { return ("\u0000" + c.charCodeAt(0).toString(16)) });
};

var GSM2Unicode = {
    0x00: "\u0040",    // COMMERCIAL AT
    0x01: "\u00A3",    // POUND SIGN
    0x02: "\u0024",    // DOLLAR SIGN
    0x03: "\u00A5",    // YEN SIGN
    0x04: "\u00E8",    // LATIN SMALL LETTER E WITH GRAVE
    0x05: "\u00E9",    // LATIN SMALL LETTER E WITH ACUTE
    0x06: "\u00F9",    // LATIN SMALL LETTER U WITH GRAVE
    0x07: "\u00EC",    // LATIN SMALL LETTER I WITH GRAVE
    0x08: "\u00F2",    // LATIN SMALL LETTER O WITH GRAVE
    0x09: "\u00E7",    // LATIN SMALL LETTER C WITH CEDILLA
    0x0A: "\u000A",    // LINE FEED
    0x0B: "\u00D8",    // LATIN CAPITAL LETTER O WITH STROKE
    0x0C: "\u00F8",    // LATIN SMALL LETTER O WITH STROKE
    0x0D: "\u000D",    // CARRIAGE RETURN
    0x0E: "\u00C5",    // LATIN CAPITAL LETTER A WITH RING ABOVE
    0x0F: "\u00E5",    // LATIN SMALL LETTER A WITH RING ABOVE
    0x10: "\u0394",    // GREEK CAPITAL LETTER DELTA
    0x11: "\u005F",    // LOW LINE
    0x12: "\u03A6",    // GREEK CAPITAL LETTER PHI
    0x13: "\u0393",    // GREEK CAPITAL LETTER GAMMA
    0x14: "\u039B",    // GREEK CAPITAL LETTER LAMDA
    0x15: "\u03A9",    // GREEK CAPITAL LETTER OMEGA
    0x16: "\u03A0",    // GREEK CAPITAL LETTER PI
    0x17: "\u03A8",    // GREEK CAPITAL LETTER PSI
    0x18: "\u03A3",    // GREEK CAPITAL LETTER SIGMA
    0x19: "\u0398",    // GREEK CAPITAL LETTER THETA
    0x1A: "\u039E",    // GREEK CAPITAL LETTER XI
    0x1B: "\u00A0",    // ESCAPE TO EXTENSION TABLE
    0x1C: "\u00C6",    // LATIN CAPITAL LETTER AE
    0x1D: "\u00E6",    // LATIN SMALL LETTER AE
    0x1E: "\u00DF",    // LATIN SMALL LETTER SHARP S (German)
    0x1F: "\u00C9",    // LATIN CAPITAL LETTER E WITH ACUTE
    0x20: "\u0020",    // SPACE
    0x21: "\u0021",    // EXCLAMATION MARK
    0x22: "\u0022",    // QUOTATION MARK
    0x23: "\u0023",    // NUMBER SIGN
    0x24: "\u00A4",    // CURRENCY SIGN
    0x25: "\u0025",    // PERCENT SIGN
    0x26: "\u0026",    // AMPERSAND
    0x27: "\u0027",    // APOSTROPHE
    0x28: "\u0028",    // LEFT PARENTHESIS
    0x29: "\u0029",    // RIGHT PARENTHESIS
    0x2A: "\u002A",    // ASTERISK
    0x2B: "\u002B",    // PLUS SIGN
    0x2C: "\u002C",    // COMMA
    0x2D: "\u002D",    // HYPHEN-MINUS
    0x2E: "\u002E",    // FULL STOP
    0x2F: "\u002F",    // SOLIDUS
    0x30: "\u0030",    // DIGIT ZERO
    0x31: "\u0031",    // DIGIT ONE
    0x32: "\u0032",    // DIGIT TWO
    0x33: "\u0033",    // DIGIT THREE
    0x34: "\u0034",    // DIGIT FOUR
    0x35: "\u0035",    // DIGIT FIVE
    0x36: "\u0036",    // DIGIT SIX
    0x37: "\u0037",    // DIGIT SEVEN
    0x38: "\u0038",    // DIGIT EIGHT
    0x39: "\u0039",    // DIGIT NINE
    0x3A: "\u003A",    // COLON
    0x3B: "\u003B",    // SEMICOLON
    0x3C: "\u003C",    // LESS-THAN SIGN
    0x3D: "\u003D",    // EQUALS SIGN
    0x3E: "\u003E",    // GREATER-THAN SIGN
    0x3F: "\u003F",    // QUESTION MARK
    0x40: "\u00A1",    // INVERTED EXCLAMATION MARK
    0x41: "\u0041",    // LATIN CAPITAL LETTER A
    0x42: "\u0042",    // LATIN CAPITAL LETTER B
    0x43: "\u0043",    // LATIN CAPITAL LETTER C
    0x44: "\u0044",    // LATIN CAPITAL LETTER D
    0x45: "\u0045",    // LATIN CAPITAL LETTER E
    0x46: "\u0046",    // LATIN CAPITAL LETTER F
    0x47: "\u0047",    // LATIN CAPITAL LETTER G
    0x48: "\u0048",    // LATIN CAPITAL LETTER H
    0x49: "\u0049",    // LATIN CAPITAL LETTER I
    0x4A: "\u004A",    // LATIN CAPITAL LETTER J
    0x4B: "\u004B",    // LATIN CAPITAL LETTER K
    0x4C: "\u004C",    // LATIN CAPITAL LETTER L
    0x4D: "\u004D",    // LATIN CAPITAL LETTER M
    0x4E: "\u004E",    // LATIN CAPITAL LETTER N
    0x4F: "\u004F",    // LATIN CAPITAL LETTER O
    0x50: "\u0050",    // LATIN CAPITAL LETTER P
    0x51: "\u0051",    // LATIN CAPITAL LETTER Q
    0x52: "\u0052",    // LATIN CAPITAL LETTER R
    0x53: "\u0053",    // LATIN CAPITAL LETTER S
    0x54: "\u0054",    // LATIN CAPITAL LETTER T
    0x55: "\u0055",    // LATIN CAPITAL LETTER U
    0x56: "\u0056",    // LATIN CAPITAL LETTER V
    0x57: "\u0057",    // LATIN CAPITAL LETTER W
    0x58: "\u0058",    // LATIN CAPITAL LETTER X
    0x59: "\u0059",    // LATIN CAPITAL LETTER Y
    0x5A: "\u005A",    // LATIN CAPITAL LETTER Z
    0x5B: "\u00C4",    // LATIN CAPITAL LETTER A WITH DIAERESIS
    0x5C: "\u00D6",    // LATIN CAPITAL LETTER O WITH DIAERESIS
    0x5D: "\u00D1",    // LATIN CAPITAL LETTER N WITH TILDE
    0x5E: "\u00DC",    // LATIN CAPITAL LETTER U WITH DIAERESIS
    0x5F: "\u00A7",    // SECTION SIGN
    0x60: "\u00BF",    // INVERTED QUESTION MARK
    0x61: "\u0061",    // LATIN SMALL LETTER A
    0x62: "\u0062",    // LATIN SMALL LETTER B
    0x63: "\u0063",    // LATIN SMALL LETTER C
    0x64: "\u0064",    // LATIN SMALL LETTER D
    0x65: "\u0065",    // LATIN SMALL LETTER E
    0x66: "\u0066",    // LATIN SMALL LETTER F
    0x67: "\u0067",    // LATIN SMALL LETTER G
    0x68: "\u0068",    // LATIN SMALL LETTER H
    0x69: "\u0069",    // LATIN SMALL LETTER I
    0x6A: "\u006A",    // LATIN SMALL LETTER J
    0x6B: "\u006B",    // LATIN SMALL LETTER K
    0x6C: "\u006C",    // LATIN SMALL LETTER L
    0x6D: "\u006D",    // LATIN SMALL LETTER M
    0x6E: "\u006E",    // LATIN SMALL LETTER N
    0x6F: "\u006F",    // LATIN SMALL LETTER O
    0x70: "\u0070",    // LATIN SMALL LETTER P
    0x71: "\u0071",    // LATIN SMALL LETTER Q
    0x72: "\u0072",    // LATIN SMALL LETTER R
    0x73: "\u0073",    // LATIN SMALL LETTER S
    0x74: "\u0074",    // LATIN SMALL LETTER T
    0x75: "\u0075",    // LATIN SMALL LETTER U
    0x76: "\u0076",    // LATIN SMALL LETTER V
    0x77: "\u0077",    // LATIN SMALL LETTER W
    0x78: "\u0078",    // LATIN SMALL LETTER X
    0x79: "\u0079",    // LATIN SMALL LETTER Y
    0x7A: "\u007A",    // LATIN SMALL LETTER Z
    0x7B: "\u00E4",    // LATIN SMALL LETTER A WITH DIAERESIS
    0x7C: "\u00F6",    // LATIN SMALL LETTER O WITH DIAERESIS
    0x7D: "\u00F1",    // LATIN SMALL LETTER N WITH TILDE
    0x7E: "\u00FC",    // LATIN SMALL LETTER U WITH DIAERESIS
    0x7F: "\u00E0"     // LATIN SMALL LETTER A WITH GRAVE
};
var Latinise = {};
Latinise.latin_map = { "á": "\u00e1" };
String.prototype.latinise = function () { return this.replace(/./g, function (a) { return Latinise.latin_map[a] || a }) };
String.prototype.latinize = String.prototype.latinise;
String.prototype.isLatin = function () { return this == this.latinise() }

