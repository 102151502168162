angular.module("prisma")
 .controller("EditPromotionLayoutCtrl", function ($scope, $rootScope, $state, $timeout, $stateParams, promotionsService, homeService, authService, serverService, Upload) {
     var self = this;

     self.layoutPreviewGrid = [];
     self.isLayoutEditable = true;

     self.isLoading = true;

     self.isValidating = false;
     self.isValidated = false;
     self.isRecreated = false;

     self.showImage = 'prisma-test.jpg';

     self.files = [];
     self.filesUploaded = false;
     self.isBusy = false;
     self.error = false;

     self.blockedForSave = function () {
         return !self.isRecreated;
     }

     self.recreateTable = function () {
         if (self.layout.rows > 0 && self.layout.columns > 0) {
             swal({
                 title: 'Está seguro que desea actualizar el Layout?',
                 text: 'Al hacerlo perderá el Layout diseñado con anterioridad',
                 type: 'warning',
                 showCancelButton: true,
                 confirmButtonColor: "#1AB394",
                 confirmButtonText: "Continuar",
                 cancelButtonText: "Cancelar",
                 showLoaderOnConfirm: true, //Si no tiene esta property en true y el timeout antes del swal, no se actualiza la variable self.isRecreated
                 closeOnConfirm: false,
                 closeOnCancel: true
             },
                  function (isConfirm) {
                      if (isConfirm) {
                          self.isRecreated = true;
                          self.blockedForSave();
                          $rootScope.$broadcast('recreateLayoutTable', {});

                          $timeout(function () { 
                              swal('Vista Preliminar Actualizada!', 'Se ha actualizado la vista preliminar exitosamente', 'success');
                          }, 100);
                      }
                  });
         }
         else {
             swal("Error!", "El Layout debe tener al menos 1 fila y 1 columna!", "error");
         }
     }

     if ($stateParams.layoutId != '0') {
         promotionsService.layouts.getLayout($stateParams.layoutId)
            .then(function (layout) {

                self.layout = layout;

                self.isLoading = false;

                $rootScope.$broadcast('refreshLayoutTable', {});
            });
     }
     else {
         self.isLoading = false;
         self.layout = {
             id: 0,
             companyId: 0,
             name: '',
             description: '',
             rows: 0,
             columns: 0,
             backgroundImageUrl: '',
             details: []
         };
         self.isLoading = false;
     }

     $scope.$watch('ct.layout.rows', function (newVal, oldVal) {
         if (newVal != oldVal)
             self.isRecreated = false;
     }, true);

     $scope.$watch('ct.layout.columns', function (newVal, oldVal) {
         if (newVal != oldVal)
             self.isRecreated = false;
     }, true);

     self.uploadFiles = function () {
         var files = self.files;
         if (files && files.length) {
             self.isBusy = true;

             //var url = ian.urlCombine(serverService.getApiUrl(), '/promotion/layouts/uploadFile', authService.authentication.companyId);             
             /*
             Upload.upload({
                 url: url,
                 data: { files: files }
             })
             */
             /*
             promotionsService.layouts.uploadFile(files[0], authService.authentication.companyId)
             .then(function (response) {
                 console.log('hecho');
                 //uploadResults(response.data);
                 //if (!anyError(response.data))
                 //    self.filesUploaded = true;
             }, function (status) {
                 console.log(status);
             });
             */
         }
     }

     self.loadResults = function (results) {
         //self.gridOptions.api.setRowData(results);
         //self.gridOptions.api.refreshView();

         //self.gridVisible = results.length > 0 ? true : false;
     }

     self.uploadResults = function (results) {
         /*
         self.isBusy = false;

         if (results.length > 0) {
             loadResults(results);
         }
         if (anyError(results)) {
             self.error = true;
         }
         else
             self.error = false;

         */
     }

     self.anyError = function (results) {
         var error = false;
         for (var i = 0; i < results.length; i++) {
             if (results[i].severity === 3)
                 error = true;
         }

         return error;
     }

     self.validate = function () {
         var result = true;

         if (!$scope.layoutForm.layoutName.$valid || !$scope.layoutForm.layoutRows.$valid || !$scope.layoutForm.layoutColumns.$valid) {
             result = false;
             self.isValidated = false;
         }

         return result;
     }

     self.save = function () {
         self.isValidating = true;
         var position = 0;

         self.layout.details = [];

         for (i = 0; i < self.layoutPreviewGrid.length; i++) {
             if (self.layoutPreviewGrid[i].position != null)
                 position++;

             var detail = {
                 id: 0,
                 promotionMediaPageLayoutId: self.layout.id,
                 rowspan: self.layoutPreviewGrid[i].rowspan,
                 colspan: self.layoutPreviewGrid[i].colspan,
                 rowNumber: self.layoutPreviewGrid[i].rowNumber,
                 columnNumber: self.layoutPreviewGrid[i].colNumber,
                 position: self.layoutPreviewGrid[i].position == null ? null : position,
                 isHighlighted: self.layoutPreviewGrid[i].isHighlighted,
                 imageUrl: self.layoutPreviewGrid[i].imageUrl
             };
             self.layout.details.push(detail);
         }
         self.layout.backgroundImageUrl = self.files.length > 0 ? self.files[0].name : '';

         if (self.layout != null && self.validate()) {
             swal({
                 title: 'Esta seguro que desea guardar el layout?',
                 type: "warning",
                 showCancelButton: true,
                 confirmButtonColor: "#1AB394",
                 confirmButtonText: "Continuar",
                 cancelButtonText: "Cancelar",
                 showLoaderOnConfirm: true,
                 closeOnConfirm: false,
                 closeOnCancel: true
             },
              function (isConfirm) {
                  if (isConfirm) {
                      promotionsService.layouts.saveLayout(self.layout)
                       .then(function (layout) {
                           swal('Layout Guardado!', 'El layout ' + self.layout.name + ' se ha guardado exitosamente', 'success');

                           self.layout = layout;

                       }, function (status) {
                           if (status == 502) {
                               swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                           }
                           else {
                               swal("Error", "Hubo un error al guardar este layout", "error");
                           }
                       });
                  }
              });
         }
     }

     function init() {

     }

     init();
 });