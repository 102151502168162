angular.module("prisma")
  .controller("StockAnalysisCtrl", function ($rootScope, $state, $stateParams, $timeout, $interval, $filter, inventoryService) {

      var self = this;
      var numberColWidth = 100;
      var groupColWidth = 30;
      var columnDefs = null;

      self.inventoryData = [];
      self.gridOptions = null;

      //grid config
      function setColumnDefinitions() {
          columnDefs = [
          {

              headerName: 'Tienda',
              pinned: 'left',
              field: 'store',
              width: groupColWidth,
              rowGroupIndex: 0,
              hide: true
          },
          {

              headerName: 'Sector',
              pinned: 'left',
              field: 'categoryGroup',
              width: groupColWidth,
              rowGroupIndex: 1,
              hide: true
          },
          {

              headerName: 'Categoria',
              pinned: 'left',
              field: 'category',
              width: groupColWidth,
              rowGroupIndex: 2,
              hide: true
          },
        {

            headerName: 'SubCategoria',
            pinned: 'left',
            field: 'subcategory',
            width: groupColWidth,
            rowGroupIndex: 3,
            hide: true
        },
        {
            headerName: 'SKU',
            children: [
          {

              headerName: 'Codigo',
              field: 'itemCode',
              columnGroupShow: 'open',
              width: groupColWidth + 10,
          },
              {

                  headerName: 'Descripcion',
                  field: 'item',
                  width: numberColWidth + 100,
              },
              {

                  headerName: 'Lead Time',
                  field: 'vendorLeadTime',
                  columnGroupShow: 'open',
                  aggFunc: 'max',
                  width: numberColWidth + 30,
              },
            ]
        },
          {
              headerName: 'Performance',
              children: [
              {
                  headerName: "Venta $",
                  field: "sales",
                  filter: 'number',
                  headerTooltip: "Venta en $ (promedio por mes por tienda Same Store)",
                  width: numberColWidth + 10,
                  aggFunc: 'sum',
                  cellClass: $rootScope.getClassForCell,
                  cellRenderer: $rootScope.moneyRendererNoDecimals
              },
                {
                    headerName: "Unidades",
                    columnGroupShow: 'open',
                    field: "units",
                    filter: 'number',
                    aggFunc: 'sum',
                    headerTooltip: "Venta en Unidades (promedio por mes por tienda Same Store)",
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: $rootScope.decimalRenderer
                },
              {
                  headerName: "Margen $",
                  columnGroupShow: 'open',
                  field: "margin",
                  filter: 'number',
                  headerTooltip: "",
                  aggFunc: 'sum',
                  width: numberColWidth,
                  cellClass: $rootScope.getClassForCell,
                  cellRenderer: $rootScope.moneyRendererNoDecimals
              },
              {
                  headerName: "CMV",
                  columnGroupShow: 'open',
                  field: "cost",
                  filter: 'number',
                  headerTooltip: "",
                  aggFunc: 'sum',
                  width: numberColWidth,
                  cellClass: $rootScope.getClassForCell,
                  cellRenderer: $rootScope.moneyRendererNoDecimals
              },
              ]
          },
      {
          headerName: 'Inventario',
          children: [
              {
                  headerName: "Inventario",
                  field: "inventory",
                  filter: 'number',
                  headerTooltip: "Inventario en $",
                  width: numberColWidth + 10,
                  aggFunc: 'sum',
                  cellClass: $rootScope.getClassForCell,
                  cellRenderer: $rootScope.moneyRendererNoDecimals
              },
            {
                headerName: "Inv. Unidades",
                columnGroupShow: 'open',
                field: "inventoryUnits",
                filter: 'number',
                aggFunc: 'sum',
                headerTooltip: "Inventario en Unidades",
                width: numberColWidth,
                cellClass: $rootScope.getClassForCell,
                cellRenderer: $rootScope.integerRenderer
            },
              {
                  headerName: "Dias de Inventario",
                  field: "inventoryDays",
                  filter: 'number',
                  valueGetter: inventoryDaysGetter,
                  headerTooltip: "Inventario en dias",
                  width: numberColWidth,
                  aggFunc: 'sum',
                  cellClass: $rootScope.getClassForCell,
                  cellRenderer: $rootScope.integerRenderer
              },
          {
              headerName: "Inv. Teorico",
              field: "inventoryTheory",
              filter: 'number',
              valueGetter: inventoryTheoryGetter,
              headerTooltip: "Inventario Teorico",
              width: numberColWidth,
              aggFunc: 'sum',
              cellClass: $rootScope.getClassForCell,
              cellRenderer: $rootScope.moneyRendererNoDecimals
          },
          {
              headerName: "Delta Inv $",
              field: "deltaInventory",
              filter: 'number',
              sort: 'desc',
              valueGetter: deltaInventoryGetter,
              headerTooltip: "Diferencia entre inventario teorico e inventario real en $",
              width: numberColWidth,
              aggFunc: 'sum',
              cellClass: $rootScope.getClassForImpactCell,
              cellRenderer: $rootScope.moneyRendererNoDecimals
          },
          {
              headerName: "Inv. Teorico Un.",
              field: "inventoryUnitsTheory",
              filter: 'number',
              valueGetter: inventoryUnitsTheoryGetter,
              headerTooltip: "Inventario Teorico en Unidades",
              width: numberColWidth,
              aggFunc: 'sum',
              cellClass: $rootScope.getClassForCell,
              cellRenderer: $rootScope.moneyRendererNoDecimals
          },
                    {
                        headerName: "Delta Inv Un.",
                        field: "deltaInventoryUnits",
                        filter: 'number',
                        valueGetter: deltaInventoryUnitsGetter,
                        headerTooltip: "Diferencia entre inventario teorico e inventario real en unidades",
                        width: numberColWidth,
                        aggFunc: 'sum',
                        cellClass: $rootScope.getClassForImpactCell,
                        cellRenderer: $rootScope.integerRenderer
                    },

              {
                  headerName: "GMROI",
                  columnGroupShow: 'open',
                  field: "gmroi",
                  filter: 'number',
                  headerTooltip: "Margen $ / valor del inventario (cuantos $ de Margen por cada $ invertido en inventario)",
                  width: numberColWidth,
                  cellClass: $rootScope.getClassForCell,
                  cellRenderer: $rootScope.moneyRenderer
              }
          ]
      },

          ]
      }

      function inventoryDaysGetter(params) {
          return params.data.inventoryUnits / params.data.units;
      }

      function inventoryTheoryGetter(params) {
          return params.data.cost * params.data.vendorLeadTime;
      }

      function inventoryUnitsTheoryGetter(params) {
          return params.data.units * params.data.vendorLeadTime;
      }

      function deltaInventoryGetter(params) {
          return params.data.inventory - inventoryTheoryGetter(params);
      }

      function deltaInventoryUnitsGetter(params) {
          return params.data.inventoryUnits - inventoryUnitsTheoryGetter(params);
      }

      function setGridOptions() {

          setColumnDefinitions();

          self.gridOptions = {
              columnDefs: columnDefs,
              enableColResize: true,
              enableSorting: true,
              enableFilter: true,
              groupHeaders: true,
              suppressCellSelection: true,
              rowHeight: 35,
              icons: {
                  columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                  filter: '<i class="fa fa-filter"/>',
                  sortAscending: '<i class="fa fa-long-arrow-down"/>',
                  sortDescending: '<i class="fa fa-long-arrow-up"/>',
                  groupExpanded: '<i class="fa fa-minus-square-o"/>',
                  groupContracted: '<i class="fa fa-plus-square-o"/>',
                  columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                  columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
              }
          }
      }

      self.exportToExcel = function () {
          var params = {
              skipHeader: false,
              skipFooters: false,
              skipGroups: false,
              allColumns: true,
              onlySelected: false,
              suppressQuotes: false,
              fileName: self.categoryGroup + '.xls',
              columnSeparator: ','
          };

          self.gridOptions.api.exportDataAsCsv(params);
      }

      function loadData() {
          inventoryService.inventory.getStockAnalysis()
              .then(function (items) {

                  self.gridOptions.api.setRowData(items);
                  self.gridOptions.api.sizeColumnsToFit();

              });
      }

      function init() {

          //init columnDefs and gridOptions
          setGridOptions();

          //load data
          loadData();
      }

      init();
  });

