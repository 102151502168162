

angular.module("prisma")

    .controller("MarketBoardCtrl", function ($scope, $rootScope, $translate, homeService, $filter, companyJson) {
        var self = this;


        var chart1 = c3.generate({
            bindto: '#productPriceEvolutionChart',

            padding: {
                top: 30,
                right: 50,
                bottom: 60,
                left: 50,
            },
            transition: {
                duration: 1000
            },
            data: {
                    xs: {
                        'NaftaSuper': 'x1',
                        'GasoilRegular': 'x1',
                        'NaftaPremium': 'x1',
                        'GasoilPremium': 'x1',
                    },
                    columns: [
                        //Eje X, cantidad de puntos sobre el eje X
                        ['x1', '2018-12-05', '2018-12-06', '2018-12-07', '2018-12-08', '2018-12-09', '2018-12-10', '2018-12-11', '2018-12-12', '2018-12-13', '2018-12-14', '2018-12-15', '2018-12-16', '2018-12-17', '2018-12-18', '2018-12-19' ],
                        //['x1', 5,6,7,8,9,10,11,12,13,14,15,16,17,18,19 ],

                        //Marcas con sus precios (tienen que tener la misma cantidad)
                        ['NaftaSuper', 36.7, 36.7, 37.2, 37.2, 37.2, 37.2, 37.7, 37.7, 37.7, 37.7, 38.2, 38.2, 38.2, 38.2, 38.7],
                        ['GasoilRegular', 32.7, 32.7, 33.2, 33.2, 33.2, 33.2, 33.7, 33.7, 33.7, 33.7, 34.2, 34.2, 34.2, 34.2, 34.7],
                        ['NaftaPremium', 42.7, 42.7, 43.2, 43.2, 43.2, 43.2, 43.7, 43.7, 43.7, 43.7, 44.2, 44.2, 44.2, 44.2, 44.7],
                        ['GasoilPremium', 39.6, 39.6, 40.1, 40.1, 40.1, 40.1, 40.6, 40.6, 40.6, 40.6, 41.1, 41.1, 41.1, 41.1, 41.6]
                    ],
                    type: 'spline',
                    colors: {
                        NaftaSuper: '#F35549',
                        GasoilRegular: '#7460EE',
                        NaftaPremium: '#26C6DA',
                        GasoilPremium: '#3B97E8'
                    }
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: '%y-%m-%d'
                    }
                },
                y: {
                    tick: {
                        format: d3.format('.1f')
                    }
                }
            }
        });

        var chart2 = c3.generate({
            bindto: '#exchangeRateEvolutionChart',
            padding: {
                top: 30,
                right: 50,
                bottom: 30,
                left: 50,
            },
            transition: {
                duration: 2000
            },
            data: {
                xs: {
                    'Dolar': 'x1',
                },
                columns: [
                    //Eje X, cantidad de puntos sobre el eje X
                    ['x1', '2018-12-05', '2018-12-06', '2018-12-07', '2018-12-08', '2018-12-09', '2018-12-10', '2018-12-11', '2018-12-12', '2018-12-13', '2018-12-14', '2018-12-15', '2018-12-16', '2018-12-17', '2018-12-18', '2018-12-19'],

                    //Marcas con sus precios (tienen que tener la misma cantidad)
                    ['Dolar', 36.9, 36.9, 37.4, 37.4, 37.4, 37.4, 37.9, 37.9, 37.9, 37.9, 38.4, 38.4, 38.4, 38.4, 38.9],
                ],
                type: 'spline',
                colors: {
                    Dolar: '#1AB394',
                },

            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: '%y-%m-%d'
                    }
                },
                y: {
                    tick: {
                        format: d3.format('.1f')
                    }
                }
            }
        });

        var chart3 = c3.generate({
            bindto: '#brendOilEvolutionChart',
            transition: {
                duration: 1000
            },
            padding: {
                top: 30,
                right: 50,
                bottom: 30,
                left: 50,
            },
            data: {
                xs: {
                    'BrentOil': 'x1',
                },
                columns: [
                    //Eje X, cantidad de puntos sobre el eje X
                    ['x1', '2018-12-05', '2018-12-06', '2018-12-07', '2018-12-08', '2018-12-09', '2018-12-10', '2018-12-11', '2018-12-12', '2018-12-13', '2018-12-14', '2018-12-15', '2018-12-16', '2018-12-17', '2018-12-18', '2018-12-19'],
                    //['x1', 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],

                    //Marcas con sus precios (tienen que tener la misma cantidad)
                    ['BrentOil', 79.0, 79.0, 79.5, 79.5, 79.5, 79.5, 80.0, 80.0, 80.0, 80.0, 80.5, 80.5, 80.5, 80.5, 81.0],
                ],
                type: 'spline',
                colors: {
                    BrentOil: '#787A7C',
                },

            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: '%y-%m-%d'
                    }
                },
                y: {
                    tick: {
                        format: d3.format('.1f')
                    }
                }
            }
        });


        function init() {

        }

        init();
    });

