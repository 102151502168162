angular.module("prisma")
    .controller("PricesRulesCtrl", function ($rootScope, $scope, $filter, $window, $timeout, $stateParams, $translate, priceService, serverService, Upload, companyJson, queueService, ngDialog) {

        var self = this;

        //General Variables
        self.companyCode = $rootScope.erp.Implementation;

        self.itemsRule = [];
        self.priceRulesTable = [];
        self.itemsInLookup = [];
        self.itemLookupText = '';

        self.priceRuleTypeNames = [];

        self.filter = {
            priceRuleTypeName: ''
        };

        self.filterByPriceRuleTypeName = function (type) {
            self.filter.priceRuleTypeName = type;
        }

        self.order = "validFrom";

        self.genericItems = [];

        self.isItemIncludeDisabled = false;
        self.includeAllItems = false;
        self.excludeAllItems = false;
        var columnDefs = null;
        self.gridOptions = null;

        self.priceRuleTypeLabelColors = ['label-danger', 'label-navy', 'label-warning', 'label-primary'];

        var previousLookupText = '';

        self.defaultPriceSurveyTypes = null;

        self.competitorGroupsParents = null;
        self.competitorGroups = null;
        self.competitorGroupsFilteredByParent = null;
        self.competitors = null;
        self.selectedRuleId = null;
        self.excludedItemId = null;
        self.existingRules = null;
        self.totalConflicts = 0;
        self.isLoadingRules = true;

        self.priceTypes = [];
        self.pricelists = [];
        self.vendors = [];
        self.categories = [];
        self.articlesGroup = [];
        self.allArticlesGroup = [];
        self.tags = [];
        self.itemSensitivities = [];
        self.zonePricelist = [];

        self.rules = null;
        self.dropDrownNewSuggestionRule = false;

        self.allVendorsChecked = true;
        self.allArticlesGroupChecked = true;
        self.allPriceListsChecked = true;
        self.allCategoriesChecked = true;

        self.isEditingCompetitiveRule = false;
        self.isEditingMarginRule = false;
        self.isEditingParentRule = false;
        self.isEditingZoneRule = false;
        self.isEditing = false;

        $translate(["NewSuggestionRule", "NewParentRule", "Competitive", "Margin", "Products", "PriceZone", "Minimum", "Average", "Maximum", "Mode"
            , "LowestModePrice", "AverageModePrice", "HighestModePrice", "HighestRepetitions", "LowestPrice", "AveragePrice", "HighestPrice"
            , "Promotion", "Basket", "Price", "PriceVariation", "SwalReviewCategoryPriceRules", "Continue", "Cancel", "Delete"
            , "RequestQueued", "RequestQueuedInfo", "TheProcessContinues", "TheProcessContinuesReviewCategoryInfo", "AnErrorOccurredReviewingCategory"
            , "AreYouSureGeneratePriceSuggestions", "TheProcessContinuesGeneratePriceSuggestionsInfo", "AnErrorOccurredGeneratingSuggestions", "AreYouSureDeletePriceRule"
            , "DeletedRule", "DeletedRuleInfo", "TheProcessContinuesInfo", "AnErrorDeletingRule", "CompetitiveWrittenRule1", "IfMoreThanOneMode", "IfMoreThanOneModeWithSameReps", "OnlyCompetitorsInSamePriceZone", "SamePrice", "DifferenceOf"
            , "AreYouSureSwal", "of", "AreYouSureSwalSubtitle"])
            .then(function (all) {
                translations = all;

                self.dropDrownNewSuggestionRuleText = translations.NewSuggestionRule;
                self.dropDrownNewParentRuleText = translations.NewParentRule;
                self.suggestionRuleTypes = [{ id: 1, name: translations.Competitive }, { id: 2, name: translations.Margin }];
                self.parentRuleTypes = [{ id: 3, name: translations.Products }, { id: 4, name: translations.PriceZone }];
                self.calculationTypes = [{ id: 0, name: translations.Minimum }, { id: 1, name: translations.Average }, { id: 2, name: translations.Maximum }, { id: 3, name: translations.Mode }];
                self.modeFallbacks = [{ id: 0, name: translations.LowestModePrice }, { id: 1, name: translations.AveragePrice }, { id: 2, name: translations.HighestPrice }];
                self.modeTypes = [{ id: 0, name: translations.HighestRepetitions }, { id: 1, name: translations.LowestPrice }, { id: 2, name: translations.AveragePrice }, { id: 3, name: translations.HighestPrice }];

                /*
                self.priceSurveyTypes = [{ "priceRuleId": 0, "priceSurveyType": 1, "priceSurveyTypeName": translations.Basket, "exclude": 0 },
                { "priceRuleId": 0, "priceSurveyType": 2, "priceSurveyTypeName": "Extra", "exclude": 0 },
                { "priceRuleId": 0, "priceSurveyType": 3, "priceSurveyTypeName": "PriceIndex", "exclude": 0 },
                { "priceRuleId": 0, "priceSurveyType": 4, "priceSurveyTypeName": translations.Promotion, "exclude": 0 },
                { "priceRuleId": 0, "priceSurveyType": 6, "priceSurveyTypeName": "Web", "exclude": 0 }];
                */

                self.relationshipTypes = [{ id: 0, name: translations.Price }, { id: 1, name: translations.Margin }, { id: 2, name: translations.PriceVariation }];
            });

        self.dropDrownNewSuggestionRuleText = '';
        self.dropDrownNewParentRuleText = '';
        self.suggestionRuleTypes = [];
        self.parentRuleTypes = [];
        self.calculationTypes = [];
        self.modeFallbacks = [];
        self.modeTypes = [];

        self.priceSurveyTypes = [];

        self.relationshipTypes = [];

        self.excludedSubCategoryIds = [];
        self.excludedTags = [];
        self.parents = [];
        self.includedItemProperties = "";
        self.excludedItemProperties = "";
        self.includedItems = [];
        self.excludedItems = [];
        self.itemproperties = { priceRuleId: 0, value: "", exclude: false };
        self.exitemproperties = { priceRuleId: 0, value: "", exclude: true };

        self.showGenericItem = false;

        //General Functions
        self.cancel = function () {
            self.isEditing = false;
            self.setAsGenericParentRule = false;
            self.isEditingCompetitiveRule = false;
            self.isEditingMarginRule = false;
            self.isEditingParentRule = false;
            self.isEditingZoneRule = false;
            self.dropDrownNewSuggestionRule = false;

            self.allVendorsChecked = true;
            self.allArticlesGroupChecked = true;
            self.allPriceListsChecked = true;
            self.allCategoriesChecked = true;

            self.totalConflicts = 0;
            self.itemsRule = [];

            setItemSensitivities(self.itemSensitivities);
            setItemTags(self.tags);
            self.excludedItemId = null;
            self.competitors = null;
            self.zonePricelist = [];
            self.genericItems = [];
            self.existingCompetitiveRules = [];
            self.existingParentRules = [];
            self.excludedSubCategoryIds = [];
            self.includedItems = [];
            self.excludedItems = [];
            self.itemsInLookup = [];
            self.itemLookupText = '';
            var previousLookupText = '';
            self.includedItemProperties = "";
            self.excludedItemProperties = "";
            self.itemproperties = { priceRuleId: 0, value: "", exclude: false };
            self.exitemproperties = { priceRuleId: 0, value: "", exclude: true };
            self.gridOptions.api.forEachNode(function (n) {
                n.data.includeItem = false;
                n.data.excludeItem = false;
            });

            newCompetitiveRule();
            newMarginRule();
            newParentRule();
            newZoneRule();

            self.gridOptions.api.refreshView();

        }

        self.newRule = function (type) {
            self.isEditingCompetitiveRule = false;
            self.isEditingMarginRule = false;
            self.isEditingParentRule = false;
            self.isEditingZoneRule = false;

            switch (type.id) {
                case 1:
                    self.isEditingCompetitiveRule = true;
                    loadCompetitorGroupsParents();
                    loadCompetitorGroups();
                    break;
                case 2:
                    self.isEditingMarginRule = true;
                    break;
                case 3:
                    self.isEditingParentRule = true;
                    break;
                case 4:
                    self.isEditingZoneRule = true;
                    break;
            }
        }

        self.reviewCategory = function (suggestionsType) {

            swal({
                title: translations.SwalReviewCategoryPriceRules,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.Cancel,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        priceService.suggestion.reviewCategory($stateParams.categoryId, suggestionsType)
                            .then(function (suggestions) {
                                //swal("Revision de Categoria!", "Ha terminado el proceso de revision de categoria exitosamente. Se generaron " + suggestions + " sugerencias para esta categoria", "success");
                                swal(translations.RequestQueued, translations.RequestQueuedInfo, "success");

                                //actualizo las tareas pendientes.
                                queueService.queuedTasks.getTop5();
                            }, function (status) {
                                if (status == 502 || status == null) {
                                    swal(translations.TheProcessContinues, translations.TheProcessContinuesReviewCategoryInfo);
                                }
                                else {
                                    swal(translations.ErrorTitleSwal, translations.AnErrorOccurredReviewingCategory, "error");
                                }
                            });
                    }
                });
        }

        self.generateSuggestions = function () {

            swal({
                title: translations.AreYouSureGeneratePriceSuggestions,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.Cancel,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        priceService.suggestion.generateSuggestions($stateParams.categoryId)
                            .then(function (suggestions) {
                                //swal("Generacion de Sugerencias!", "El proceso termino exitosamente. Se generaron " + suggestions + " sugerencias para esta categoria", "success");
                                swal(translations.RequestQueued, translations.RequestQueuedInfo, "success");

                                //actualizo las tareas pendientes.
                                queueService.queuedTasks.getTop5();

                            }, function (status) {
                                console.log(status);
                                if (status == 502) {
                                    swal(translations.TheProcessContinues, translations.TheProcessContinuesGeneratePriceSuggestionsInfo);
                                }
                                else {
                                    swal(translations.ErrorTitleSwal, translations.AnErrorOccurredGeneratingSuggestions, "error");
                                }
                            });
                    }
                });
        }

        function getEditingRuleName() {
            var rv = '';
            if (self.isEditingCompetitiveRule)
                rv = replaceAccents(self.competitiveRule.writtenRule);
            else if (self.isEditingMarginRule)
                rv = replaceAccents(self.marginRule.writtenRule);
            else if (self.isEditingParentRule)
                rv = replaceAccents(self.parentRule.writtenRule);
            else if (self.isEditingZoneRule)
                rv = replaceAccents(self.zoneRule.writtenRule);

            return rv;
        }
        function replaceAccents(str) {
            str = str.replace("&aacute;", "a");
            str = str.replace("&eacute;", "e");
            str = str.replace("&iacute;", "i");
            str = str.replace("&oacute;", "o");
            str = str.replace("&uacute;", "u");

            return str;
        }

        self.saveRule = function (rule, type) {

            if (type == "Competitive")
                self.createCompetitorRule();
            else if (type == "Margin")
                self.createMarginRule();
            else if (type == "Parent")
                self.createParentRule();
            else if (type == "Zone")
                self.createZoneRule();
        };
        self.canSaveCompetitiveRule = function () {

            if (self.competitiveRule.writtenRule != null && self.totalConflicts == 0 && self.rules.length > 0 &&
                self.competitiveRule.pricelists != null && self.competitiveRule.pricelists.length > 0 &&
                self.competitiveRule.categories != null && self.competitiveRule.categories.length > 0 &&
                self.competitiveRule.priceSurveyTypes != null && self.competitiveRule.priceSurveyTypes.length > 0 &&
                (self.competitiveRule.calculationType != 3 ||
                    (self.competitiveRule.calculationType == 3 &&
                        (self.competitiveRule.modeType != null && self.competitiveRule.modeType == 0 && self.competitiveRule.modeFallback != null) || //Si es moda que mas se repite, que tenga el fallback
                        (self.competitiveRule.modeType != null && self.competitiveRule.modeType != 0) //Si es moda Maxima o minima o mismo precio, no necesita el fallback
                    )) &&
                (self.competitiveRule.competitorGroupParent != null || self.competitiveRule.competitorGroup != null || self.competitiveRule.competitor != null))
                return true;

            return false;
        };
        self.canSaveMarginRule = function () {
            if (self.marginRule.writtenRule != null && self.totalConflicts == 0 && self.rules.length > 0 &&
                self.marginRule.pricelists != null && self.marginRule.pricelists.length > 0 &&
                self.marginRule.categories != null && self.marginRule.categories.length > 0)
                return true;

            return false;
        };
        self.canSaveParentRule = function () {
            if (self.parentRule.writtenRule != null && self.parentRule.parent != null && self.totalConflicts == 0 && self.rules.length > 0 &&
                self.parentRule.pricelists != null && self.parentRule.pricelists.length > 0 &&
                self.parentRule.categories != null && self.parentRule.categories.length > 0)
                return true;

            return false;
        };
        self.canSaveZoneRule = function () {
            if (self.zoneRule.writtenRule != null && self.totalConflicts == 0 && self.rules.length > 0 &&
                self.zoneRule.pricelists != null && self.zoneRule.pricelists.length > 0 &&
                self.zoneRule.categories != null && self.zoneRule.categories.length > 0)
                return true;

            return false;
        };

        self.deleteRuleFromTable = function (priceRuleId) {
            swal({
                title: translations.AreYouSureDeletePriceRule,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: translations.Delete,
                cancelButtonText: translations.Cancel,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        priceService.rules.deletePriceRule(priceRuleId)
                            .then(function () {
                                swal(translations.DeletedRule, translations.DeletedRuleInfo, 'success');
                                reload(); //Reload Grid

                            }, function (status) {
                                if (status == 502) {
                                    swal(translations.TheProcessContinues, translations.TheProcessContinuesInfo)
                                }
                                else {
                                    swal(translations.ErrorTitleSwal, translations.AnErrorDeletingRule, "error");
                                }
                            });
                    }
                });
        }

        function checkIfIsCurrentRule(nodeData) {
            var rv = false;
            if (self.isEditingMarginRule && self.marginRule && self.marginRule.id == nodeData.marginRuleId) {
                rv = true;
            }
            else if (self.isEditingCompetitiveRule && self.competitiveRule && self.competitiveRule.id == nodeData.competitiveRuleId) {
                rv = true;
            }
            else if (self.isEditingParentRule && self.parentRule && self.parentRule.id == nodeData.parentRuleZoneId) {
                rv = true;
            }
            else if (self.isEditingZoneRule && self.zoneRule && self.zoneRule.id == nodeData.parentRuleZoneId) {
                rv = true;
            }
            return rv;
        }

        self.mapItemExcludeToGrid = function () {
            self.gridOptions.api.forEachNode(function (node) {
                angular.forEach(self.itemsRule, function (itemRule) {
                    if (node.data.itemId == itemRule.itemId) {
                        if (itemRule.exclude == false) {
                            node.data.includeItem = true;
                            node.data.excludeItem = false;
                            self.includeItem(node.data, true);
                        }
                        else if (itemRule.exclude == true){
                            node.data.excludeItem = true;
                            node.data.includeItem = false;
                            self.includeItem(node.data, false);
                        }
                    }
                })
            })
        }

        self.initRuleEdition = function (priceRule) {
            self.isEditing = true;
            self.isEditingCompetitiveRule = false;
            self.isEditingMarginRule = false;
            self.isEditingParentRule = false;
            self.isEditingZoneRule = false;

            loadCompetitorGroupsParents();
            loadCompetitorGroups();

            priceService.rules.getPriceRule(priceRule.priceRuleId, priceRule.priceRuleType)
                .then(function (dto) {

                    self.itemsRule = dto.items;
                    self.mapItemExcludeToGrid();

                    if (dto.categories && dto.categories.length > 0) {
                        self.allCategoriesChecked = false;
                    }
                    if (dto.articlesGroup && dto.articlesGroup.length > 0) {
                        self.allArticlesGroupChecked = false;
                    }
                    if (dto.priceLists && dto.priceLists.length > 0) {
                        self.allPriceListsChecked = false;
                    }
                    if (dto.vendors && dto.vendors.length > 0) {
                        self.allVendorsChecked = false;
                    }
                    switch (priceRule.priceRuleType) {
                        case 1:
                            //mapeo el id
                            self.marginRule.id = dto.id;

                            //mapeo el writtenRule
                            self.marginRule.name = dto.name;

                            //mapeo select simples
                            if (dto.keepMargin == true) {
                                self.marginRule.keepMarginType = 1;
                                self.marginRule.marginRuleType = 2;
                            }
                            else if (dto.keepMarginAmount == true) {
                                self.marginRule.keepMarginType = 2;
                                self.marginRule.marginRuleType = 2;
                            }
                            else if (dto.marginAmount) {
                                self.marginRule.marginType = 2;
                                self.marginRule.marginRuleType = 1;
                                self.marginRule.marginAmount = dto.marginAmount;
                            }
                            else {
                                self.marginRule.marginType = 1;
                                self.marginRule.marginRuleType = 1;
                                self.marginRule.margin = dto.margin * 100;
                            }
                            //mapeo categorias
                            self.marginRule.categories = [];
                            angular.forEach(dto.categories, function (dtoCat) {
                                var cat = self.categories.find(function (c) { return c.categoryId == dtoCat.categoryId });
                                if (cat) {
                                    self.marginRule.categories.push(cat);
                                }
                            });
                            $("#form_fieldCategories").trigger("chosen:updated");
                            //mapeo grupo de categorias
                            self.marginRule.articlesGroup = [];
                            angular.forEach(dto.articlesGroup, function (dtoArtGrp) {
                                var artGrp = self.articlesGroup.find(function (ag) { return ag.articleGroupId == dtoArtGrp.articleGroupId && dtoArtGrp.exclude == false });
                                if (artGrp != null) {
                                    self.marginRule.articlesGroup.push(artGrp);
                                }
                            });
                            $("#form_fieldArticlesGroup").trigger("chosen:updated");
                            //mapeo priceslists
                            self.marginRule.pricelists = [];
                            angular.forEach(dto.priceLists, function (pListDto) {
                                var pl = self.pricelists.find(function (p) { return p.priceListId == pListDto.priceListId && pListDto.exclude == false});
                                if (pl) {
                                    self.marginRule.pricelists.push(pl);
                                }
                            });
                            $("#form_fieldPriceLists").trigger("chosen:updated");
                            //mapeo vendors
                            self.marginRule.vendors = [];
                            angular.forEach(dto.vendors, function (vDto) {
                                var vendor = self.vendors.find(function (v) { return v.Id == vDto.Id && vDto.exclude == false});
                                if (vendor) {
                                    self.marginRule.vendors.push(vendor);
                                }
                            });
                            $("#form_fieldVendors").trigger("chosen:updated");
                            //mapeo itemSensitivities
                            self.marginRule.itemSensitivities = [];
                            angular.forEach(dto.itemSensitivities, function (iSDto) {
                                var itemSensitivities = self.itemSensitivitiesToInclude.find(function (item) { return item.itemSensitivityId == iSDto.itemSensitivityId && iSDto.exclude == false });
                                if (itemSensitivities) {
                                    self.marginRule.itemSensitivities.push(itemSensitivities);
                                }
                            });
                            $("#form_fieldItemSensitivities").trigger("chosen:updated");
                            //mapeo tags
                            self.marginRule.tags = [];
                            angular.forEach(dto.tags, function (tDto) {
                                var tag = self.itemTagsToInclude.find(function (t) { return t.tagId == tDto.tagId && tDto.exclude == false});
                                if (tag) {
                                    self.marginRule.tags.push(tag);
                                }
                            });
                            $("#form_fieldTags").trigger("chosen:updated");

                            //mapeo itemProperties
                            angular.forEach(dto.itemProperties, function (iDto) {
                                if (iDto && iDto.exclude == false) {
                                    self.itemproperties.value = iDto.value;
                                    self.includeItemProperty(self.marginRule);
                                }
                            });

                            //mapeo exArticleGroup
                            self.marginRule.exarticlesGroup = [];
                            angular.forEach(dto.articlesGroup, function (dtoArtGrp) {
                                var exArtGrp = self.articlesGroup.find(function (eAg) { return eAg.articleGroupId == dtoArtGrp.articleGroupId && dtoArtGrp.exclude == true });
                                if (exArtGrp != null) {
                                    self.marginRule.exarticlesGroup.push(exArtGrp);
                                    self.allArticlesGroupChecked = true;
                                }
                            });
                            $("#form_fieldExArticlesGroup").trigger("chosen:updated");
                            
                            //mapeo exPricesLists
                            self.marginRule.expricelists = [];
                            angular.forEach(dto.priceLists, function (pListDto) {
                                var exPl = self.pricelists.find(function (p) { return p.priceListId == pListDto.priceListId && pListDto.exclude == true });
                                if (exPl) {
                                    self.marginRule.expricelists.push(exPl);
                                }
                            });
                            if (self.marginRule.expricelists.length > 0) {
                                self.allPriceListsChecked = true;
                            }
                            $("#form_fieldExPriceLists").trigger("chosen:updated");
                            //mapeo exVendors
                            self.marginRule.exvendors = [];
                            angular.forEach(dto.vendors, function (vDto) {
                                var exVendor = self.vendors.find(function (v) { return v.Id == vDto.Id && vDto.exclude == true });
                                if (exVendor) {
                                    self.marginRule.exvendors.push(exVendor);
                                }
                            });
                            if (self.marginRule.exvendors.length > 0) {
                                self.allVendorsChecked = true;
                            }
                            $("#form_fieldExVendors").trigger("chosen:updated");
                            //mapeo exItemSensitivities
                            self.marginRule.exitemSensitivities = [];
                            angular.forEach(dto.itemSensitivities, function (eIsDto) {
                                var exItemSensitivities = self.itemSensitivitiesToExclude.find(function (item) { return item.itemSensitivityId == eIsDto.itemSensitivityId && eIsDto.exclude == true});
                                if (exItemSensitivities) {
                                    self.marginRule.exitemSensitivities.push(exItemSensitivities);
                                }
                            });
                            $("#form_fieldExItemSensitivities").trigger("chosen:updated");
                            //mapeo exTags
                            self.marginRule.extags = [];
                            angular.forEach(dto.tags, function (exTagDto) {
                                var exTags = self.itemTagsToExclude.find(function (exTag) { return exTag.tagId == exTagDto.tagId && exTagDto.exclude == true });
                                if (exTags) {
                                    self.marginRule.extags.push(exTags);
                                }
                            });
                            $("#form_fieldExTags").trigger("chosen:updated");

                            //mapeo exItemProperties
                            angular.forEach(dto.itemProperties, function (iDto) {
                                if (iDto && iDto.exclude == true) {
                                    self.exitemproperties.value = iDto.value;
                                    self.excludeItemProperty(self.marginRule);
                                }
                            });
                            break;
                        case 2:
                            //mapeo el id
                            self.competitiveRule.id = dto.id;

                            //mapeo el writtenRule
                            self.competitiveRule.writtenRule = dto.name;

                            //mapeo select simples
                            if (dto.competitorId) {
                                priceService.competitors.competitors().then(function (competitors) {
                                    self.competitors = competitors;
                                    if (self.competitors) {
                                        var competitor = self.competitors.find(function (c) { return c.id == dto.competitorId });
                                        self.competitiveRule.competitor = competitor;
                                    }
                                });
                            }
                            else if (dto.competitorGroupId) {
                                if (self.competitorGroupsParents) {
                                    var competitorGroupParent = self.competitorGroupsParents.find(function (cgp) { return cgp.id == dto.competitorGroupId });
                                }
                                if (self.competitorGroupsFilteredByParent) {
                                    var competitorGroup = self.competitorGroupsFilteredByParent.find(function (cgfp) { return cgfp.id == dto.competitorGroupId });
                                }
                                if (competitorGroupParent) {
                                    self.competitiveRule.competitorGroupParent = competitorGroupParent;
                                }
                                if (competitorGroup) {
                                    self.competitiveRule.competitorGroupParent = self.competitorGroupsParents.find(function (cgp) { return cgp.id == competitorGroup.parentId });
                                    self.competitiveRule.competitorGroup = competitorGroup;
                                }
                            }
                            //mapeo fuentes de monitoreo
                            if (dto.priceSurveyTypes) {
                                self.competitiveRule.priceSurveyTypes = dto.priceSurveyTypes;
                            }

                            //mapeo diferencia en
                            if (dto.percentageDifference1) {
                                self.competitiveRule.differenceType = '1';
                                self.competitiveRule.competitiveDifference = dto.percentageDifference1 * 100;
                            }
                            else if (dto.priceDifference1) {
                                self.competitiveRule.differenceType = '2';
                                self.competitiveRule.competitiveDifference = dto.priceDifference1;
                            }
                            //mapeo usar y modeType
                            if (dto.calculationType != null) {
                                self.competitiveRule.calculationType = dto.calculationType;
                                if (dto.calculationType == 3) {
                                    if (dto.modeType != null) {
                                        self.competitiveRule.modeType = dto.modeType;
                                    }
                                    if (dto.modeFallback != null) {
                                        self.competitiveRule.modeFallback = dto.modeFallback;
                                    }
                                }
                            }
                            //mapeo Considerar solo competidores en la misma zona de precios
                            if (dto.onlyCompetitorsWithSamePriceList != null) {
                                self.competitiveRule.onlyCompetitorsWithSamePriceList = dto.onlyCompetitorsWithSamePriceList;
                            }
                            
                            //mapeo categorias
                            self.competitiveRule.categories = [];
                            angular.forEach(dto.categories, function (dtoCat) {
                                var cat = self.categories.find(function (c) { return c.categoryId == dtoCat.categoryId });
                                if (cat) {
                                    self.competitiveRule.categories.push(cat);
                                }
                            });
                            $("#form_fieldCategories").trigger("chosen:updated");

                            //mapeo grupo de categorias
                            self.competitiveRule.articlesGroup = [];
                            angular.forEach(dto.articlesGroup, function (dtoArtGrp) {
                                var artGrp = self.articlesGroup.find(function (ag) { return ag.articleGroupId == dtoArtGrp.articleGroupId && dtoArtGrp.exclude == false });
                                if (artGrp != null) {
                                    self.competitiveRule.articlesGroup.push(artGrp);
                                }
                            });
                            $("#form_fieldArticlesGroup").trigger("chosen:updated");

                            //mapeo priceslists
                            self.competitiveRule.pricelists = [];
                            angular.forEach(dto.priceLists, function (pListDto) {
                                var pl = self.pricelists.find(function (p) { return p.priceListId == pListDto.priceListId && pListDto.exclude == false });
                                if (pl) {
                                    self.competitiveRule.pricelists.push(pl);
                                }
                            });
                            $("#form_fieldPriceLists").trigger("chosen:updated");
                            //mapeo vendors
                            self.competitiveRule.vendors = [];
                            angular.forEach(dto.vendors, function (vDto) {
                                var vendor = self.vendors.find(function (v) { return v.Id == vDto.Id && vDto.exclude == false });
                                if (vendor) {
                                    self.competitiveRule.vendors.push(vendor);
                                }
                            });
                            $("#form_fieldVendors").trigger("chosen:updated");
                            //mapeo itemSensitivities
                            self.competitiveRule.itemSensitivities = [];
                            angular.forEach(dto.itemSensitivities, function (iSDto) {
                                var itemSensitivities = self.itemSensitivitiesToInclude.find(function (item) { return item.itemSensitivityId == iSDto.itemSensitivityId && iSDto.exclude == false });
                                if (itemSensitivities) {
                                    self.competitiveRule.itemSensitivities.push(itemSensitivities);
                                }
                            });
                            $("#form_fieldItemSensitivities").trigger("chosen:updated");
                            //mapeo tags
                            self.competitiveRule.tags = [];
                            angular.forEach(dto.tags, function (tDto) {
                                var tag = self.itemTagsToInclude.find(function (t) { return t.tagId == tDto.tagId && tDto.exclude == false });
                                if (tag) {
                                    self.competitiveRule.tags.push(tag);
                                }
                            });
                            $("#form_fieldTags").trigger("chosen:updated");

                            //mapeo itemProperties
                            angular.forEach(dto.itemProperties, function (iDto) {
                                if (iDto && iDto.exclude == false) {
                                    self.itemproperties.value = iDto.value;
                                    self.includeItemProperty(self.competitiveRule);
                                }
                            });

                            //mapeo exArticleGroup
                            self.competitiveRule.exarticlesGroup = [];
                            angular.forEach(dto.articlesGroup, function (dtoArtGrp) {
                                var exArtGrp = self.articlesGroup.find(function (eAg) { return eAg.articleGroupId == dtoArtGrp.articleGroupId && dtoArtGrp.exclude == true });
                                if (exArtGrp != null) {
                                    self.competitiveRule.exarticlesGroup.push(exArtGrp);
                                    self.allArticlesGroupChecked = true;
                                }
                            });
                            $("#form_fieldExArticlesGroup").trigger("chosen:updated");

                            //mapeo exPricesLists
                            self.competitiveRule.expricelists = [];
                            angular.forEach(dto.priceLists, function (pListDto) {
                                var exPl = self.pricelists.find(function (p) { return p.priceListId == pListDto.priceListId && pListDto.exclude == true });
                                if (exPl) {
                                    self.competitiveRule.expricelists.push(exPl);
                                }
                            });
                            if (self.competitiveRule.expricelists.length > 0) {
                                self.allPriceListsChecked = true;
                            }
                            $("#form_fieldExPriceLists").trigger("chosen:updated");
                            //mapeo exVendors
                            self.competitiveRule.exvendors = [];
                            angular.forEach(dto.vendors, function (vDto) {
                                var exVendor = self.vendors.find(function (v) { return v.Id == vDto.Id && vDto.exclude == true });
                                if (exVendor) {
                                    self.competitiveRule.exvendors.push(exVendor);
                                }
                            });
                            if (self.competitiveRule.exvendors.length > 0) {
                                self.allVendorsChecked = true;
                            }
                            $("#form_fieldExVendors").trigger("chosen:updated");
                            //mapeo exItemSensitivities
                            self.competitiveRule.exitemSensitivities = [];
                            angular.forEach(dto.itemSensitivities, function (eIsDto) {
                                var exItemSensitivities = self.itemSensitivitiesToExclude.find(function (item) { return item.itemSensitivityId == eIsDto.itemSensitivityId && eIsDto.exclude == true });
                                if (exItemSensitivities) {
                                    self.competitiveRule.exitemSensitivities.push(exItemSensitivities);
                                }
                            });
                            $("#form_fieldExItemSensitivities").trigger("chosen:updated");
                            //mapeo exTags
                            self.competitiveRule.extags = [];
                            angular.forEach(dto.tags, function (exTagDto) {
                                var exTags = self.itemTagsToExclude.find(function (exTag) { return exTag.tagId == exTagDto.tagId && exTagDto.exclude == true });
                                if (exTags) {
                                    self.competitiveRule.extags.push(exTags);
                                }
                            });
                            $("#form_fieldExTags").trigger("chosen:updated");

                            //mapeo exItemProperties
                            angular.forEach(dto.itemProperties, function (iDto) {
                                if (iDto && iDto.exclude == true) {
                                    self.exitemproperties.value = iDto.value;
                                    self.excludeItemProperty(self.competitiveRule);
                                }
                            });
                            break;
                        case 3:
                            //mapeo el id
                            self.parentRule.id = dto.id;

                            //mapeo el writtenRule
                            self.parentRule.writtenRule = dto.name;

                            //mapeo select simples
                            if (dto.mainItemId != null && dto.mainItemUmvId) {
                                priceService.lookups.itemLookupByMainItemId(dto.mainItemId, dto.mainItemUmvId).then(function (item) {
                                    self.itemLookupOnItemSelect(item);
                                });
                            }

                            if (dto.relationshipType != null) {
                                var relationshipType = self.relationshipTypes.find(function (rst) { return rst.id == dto.relationshipType });
                                self.parentRule.relationshipType = relationshipType;
                            }
                            if (dto.percentageDifference2 != null) {
                                self.parentRule.differenceType = "1";
                                self.parentRule.difference = dto.percentageDifference2 * 100;
                            }
                            else if (dto.priceDifference2 != null) {
                                self.parentRule.differenceType = "2";
                                self.parentRule.difference = dto.priceDifference2;
                            }

                            //mapeo categorias
                            self.parentRule.categories = [];
                            angular.forEach(dto.categories, function (dtoCat) {
                                var cat = self.categories.find(function (c) { return c.categoryId == dtoCat.categoryId });
                                if (cat) {
                                    self.parentRule.categories.push(cat);
                                }
                            });
                            $("#form_fieldCategories").trigger("chosen:updated");

                            //mapeo grupo de categorias
                            self.parentRule.articlesGroup = [];
                            angular.forEach(dto.articlesGroup, function (dtoArtGrp) {
                                var artGrp = self.articlesGroup.find(function (ag) { return ag.articleGroupId == dtoArtGrp.articleGroupId && dtoArtGrp.exclude == false });
                                if (artGrp != null) {
                                    self.parentRule.articlesGroup.push(artGrp);
                                }
                            });
                            $("#form_fieldArticlesGroup").trigger("chosen:updated");

                            //mapeo priceslists
                            self.parentRule.pricelists = [];
                            angular.forEach(dto.priceLists, function (pListDto) {
                                var pl = self.pricelists.find(function (p) { return p.priceListId == pListDto.priceListId && pListDto.exclude == false });
                                if (pl) {
                                    self.parentRule.pricelists.push(pl);
                                }
                            });
                            $("#form_fieldPriceLists").trigger("chosen:updated");
                            //mapeo vendors
                            self.parentRule.vendors = [];
                            angular.forEach(dto.vendors, function (vDto) {
                                var vendor = self.vendors.find(function (v) { return v.Id == vDto.Id && vDto.exclude == false });
                                if (vendor) {
                                    self.parentRule.vendors.push(vendor);
                                }
                            });
                            $("#form_fieldVendors").trigger("chosen:updated");
                            //mapeo itemSensitivities
                            self.parentRule.itemSensitivities = [];
                            angular.forEach(dto.itemSensitivities, function (iSDto) {
                                var itemSensitivities = self.itemSensitivitiesToInclude.find(function (item) { return item.itemSensitivityId == iSDto.itemSensitivityId && iSDto.exclude == false });
                                if (itemSensitivities) {
                                    self.parentRule.itemSensitivities.push(itemSensitivities);
                                }
                            });
                            $("#form_fieldItemSensitivities").trigger("chosen:updated");
                            //mapeo tags
                            self.parentRule.tags = [];
                            angular.forEach(dto.tags, function (tDto) {
                                var tag = self.itemTagsToInclude.find(function (t) { return t.tagId == tDto.tagId && tDto.exclude == false });
                                if (tag) {
                                    self.parentRule.tags.push(tag);
                                }
                            });
                            $("#form_fieldTags").trigger("chosen:updated");

                            //mapeo itemProperties
                            angular.forEach(dto.itemProperties, function (iDto) {
                                if (iDto && iDto.exclude == false) {
                                    self.itemproperties.value = iDto.value;
                                    self.includeItemProperty(self.parentRule);
                                }
                            });

                            //mapeo exArticleGroup
                            self.parentRule.exarticlesGroup = [];
                            angular.forEach(dto.articlesGroup, function (dtoArtGrp) {
                                var exArtGrp = self.articlesGroup.find(function (eAg) { return eAg.articleGroupId == dtoArtGrp.articleGroupId && dtoArtGrp.exclude == true });
                                if (exArtGrp != null) {
                                    self.parentRule.exarticlesGroup.push(exArtGrp);
                                    self.allArticlesGroupChecked = true;
                                }
                            });
                            $("#form_fieldExArticlesGroup").trigger("chosen:updated");

                            //mapeo exPricesLists
                            self.parentRule.expricelists = [];
                            angular.forEach(dto.priceLists, function (pListDto) {
                                var exPl = self.pricelists.find(function (p) { return p.priceListId == pListDto.priceListId && pListDto.exclude == true });
                                if (exPl) {
                                    self.parentRule.expricelists.push(exPl);
                                }
                            });
                            if (self.parentRule.expricelists.length > 0) {
                                self.allPriceListsChecked = true;
                            }
                            $("#form_fieldExPriceLists").trigger("chosen:updated");
                            //mapeo exVendors
                            self.parentRule.exvendors = [];
                            angular.forEach(dto.vendors, function (vDto) {
                                var exVendor = self.vendors.find(function (v) { return v.Id == vDto.Id && vDto.exclude == true });
                                if (exVendor) {
                                    self.parentRule.exvendors.push(exVendor);
                                }
                            });
                            if (self.parentRule.exvendors.length > 0) {
                                self.allVendorsChecked = true;
                            }
                            $("#form_fieldExVendors").trigger("chosen:updated");
                            //mapeo exItemSensitivities
                            self.parentRule.exitemSensitivities = [];
                            angular.forEach(dto.itemSensitivities, function (eIsDto) {
                                var exItemSensitivities = self.itemSensitivitiesToExclude.find(function (item) { return item.itemSensitivityId == eIsDto.itemSensitivityId && eIsDto.exclude == true });
                                if (exItemSensitivities) {
                                    self.parentRule.exitemSensitivities.push(exItemSensitivities);
                                }
                            });
                            $("#form_fieldExItemSensitivities").trigger("chosen:updated");
                            //mapeo exTags
                            self.parentRule.extags = [];
                            angular.forEach(dto.tags, function (exTagDto) {
                                var exTags = self.itemTagsToExclude.find(function (exTag) { return exTag.tagId == exTagDto.tagId && exTagDto.exclude == true });
                                if (exTags) {
                                    self.parentRule.extags.push(exTags);
                                }
                            });
                            $("#form_fieldExTags").trigger("chosen:updated");

                            //mapeo exItemProperties
                            angular.forEach(dto.itemProperties, function (iDto) {
                                if (iDto && iDto.exclude == true) {
                                    self.exitemproperties.value = iDto.value;
                                    self.excludeItemProperty(self.parentRule);
                                }
                            });

                            break;
                        case 4:
                            //mapeo el id
                            self.zoneRule.id = dto.id;

                            //mapeo el writtenRule
                            self.zoneRule.writtenRule = dto.name;

                            //mapeo select simples
                            if (dto.mainPriceListId != null) {
                                var mainPriceList = self.pricelists.find(function (pl) { return pl.priceListId == dto.mainPriceListId });
                                self.zoneRule.zone = mainPriceList;
                            }

                            if (dto.relationshipType != null) {
                                var relationshipType = self.relationshipTypes.find(function (rst) { return rst.id == dto.relationshipType });
                                self.zoneRule.relationshipType = relationshipType;
                            }
                            if (dto.percentageDifference2 != null) {
                                self.zoneRule.differenceType = "1";
                                self.zoneRule.difference = dto.percentageDifference2 * 100;
                            }
                            else if (dto.priceDifference2 != null) {
                                self.zoneRule.differenceType = "2";
                                self.zoneRule.difference = dto.priceDifference2;
                            }

                            //mapeo categorias
                            self.zoneRule.categories = [];
                            angular.forEach(dto.categories, function (dtoCat) {
                                var cat = self.categories.find(function (c) { return c.categoryId == dtoCat.categoryId });
                                if (cat) {
                                    self.zoneRule.categories.push(cat);
                                }
                            });
                            $("#form_fieldCategories").trigger("chosen:updated");

                            //mapeo grupo de categorias
                            self.zoneRule.articlesGroup = [];
                            angular.forEach(dto.articlesGroup, function (dtoArtGrp) {
                                var artGrp = self.articlesGroup.find(function (ag) { return ag.articleGroupId == dtoArtGrp.articleGroupId && dtoArtGrp.exclude == false });
                                if (artGrp != null) {
                                    self.zoneRule.articlesGroup.push(artGrp);
                                }
                            });
                            $("#form_fieldArticlesGroup").trigger("chosen:updated");

                            //mapeo priceslists
                            self.zoneRule.pricelists = [];
                            angular.forEach(dto.priceLists, function (pListDto) {
                                var pl = self.zonePricelist.find(function (p) { return p.priceListId == pListDto.priceListId && pListDto.exclude == false });
                                if (pl) {
                                    self.zoneRule.pricelists.push(pl);
                                }
                            });
                            $("#form_fieldPriceLists").trigger("chosen:updated");
                            //mapeo vendors
                            self.zoneRule.vendors = [];
                            angular.forEach(dto.vendors, function (vDto) {
                                var vendor = self.vendors.find(function (v) { return v.Id == vDto.Id && vDto.exclude == false });
                                if (vendor) {
                                    self.zoneRule.vendors.push(vendor);
                                }
                            });
                            $("#form_fieldVendors").trigger("chosen:updated");
                            //mapeo itemSensitivities
                            self.zoneRule.itemSensitivities = [];
                            angular.forEach(dto.itemSensitivities, function (iSDto) {
                                var itemSensitivities = self.itemSensitivitiesToInclude.find(function (item) { return item.itemSensitivityId == iSDto.itemSensitivityId && iSDto.exclude == false });
                                if (itemSensitivities) {
                                    self.zoneRule.itemSensitivities.push(itemSensitivities);
                                }
                            });
                            $("#form_fieldItemSensitivities").trigger("chosen:updated");
                            //mapeo tags
                            self.zoneRule.tags = [];
                            angular.forEach(dto.tags, function (tDto) {
                                var tag = self.itemTagsToInclude.find(function (t) { return t.tagId == tDto.tagId && tDto.exclude == false });
                                if (tag) {
                                    self.zoneRule.tags.push(tag);
                                }
                            });
                            $("#form_fieldTags").trigger("chosen:updated");

                            //mapeo itemProperties
                            angular.forEach(dto.itemProperties, function (iDto) {
                                if (iDto && iDto.exclude == false) {
                                    self.itemproperties.value = iDto.value;
                                    self.includeItemProperty(self.zoneRule);
                                }
                            });

                            //mapeo exArticleGroup
                            self.zoneRule.exarticlesGroup = [];
                            angular.forEach(dto.articlesGroup, function (dtoArtGrp) {
                                var exArtGrp = self.articlesGroup.find(function (eAg) { return eAg.articleGroupId == dtoArtGrp.articleGroupId && dtoArtGrp.exclude == true });
                                if (exArtGrp != null) {
                                    self.zoneRule.exarticlesGroup.push(exArtGrp);
                                    self.allArticlesGroupChecked = true;
                                }
                            });
                            $("#form_fieldExArticlesGroup").trigger("chosen:updated");

                            //mapeo exPricesLists
                            self.zoneRule.expricelists = [];
                            angular.forEach(dto.priceLists, function (pListDto) {
                                var exPl = self.zonePricelist.find(function (p) { return p.priceListId == pListDto.priceListId && pListDto.exclude == true });
                                if (exPl) {
                                    self.zoneRule.expricelists.push(exPl);
                                }
                            });
                            if (self.zoneRule.expricelists.length > 0) {
                                self.allPriceListsChecked = true;
                            }
                            $("#form_fieldExPriceLists").trigger("chosen:updated");
                            //mapeo exVendors
                            self.zoneRule.exvendors = [];
                            angular.forEach(dto.vendors, function (vDto) {
                                var exVendor = self.vendors.find(function (v) { return v.Id == vDto.Id && vDto.exclude == true });
                                if (exVendor) {
                                    self.zoneRule.exvendors.push(exVendor);
                                }
                            });
                            if (self.zoneRule.exvendors.length > 0) {
                                self.allVendorsChecked = true;
                            }
                            $("#form_fieldExVendors").trigger("chosen:updated");
                            //mapeo exItemSensitivities
                            self.zoneRule.exitemSensitivities = [];
                            angular.forEach(dto.itemSensitivities, function (eIsDto) {
                                var exItemSensitivities = self.itemSensitivitiesToExclude.find(function (item) { return item.itemSensitivityId == eIsDto.itemSensitivityId && eIsDto.exclude == true });
                                if (exItemSensitivities) {
                                    self.zoneRule.exitemSensitivities.push(exItemSensitivities);
                                }
                            });
                            $("#form_fieldExItemSensitivities").trigger("chosen:updated");
                            //mapeo exTags
                            self.zoneRule.extags = [];
                            angular.forEach(dto.tags, function (exTagDto) {
                                var exTags = self.itemTagsToExclude.find(function (exTag) { return exTag.tagId == exTagDto.tagId && exTagDto.exclude == true });
                                if (exTags) {
                                    self.zoneRule.extags.push(exTags);
                                }
                            });
                            $("#form_fieldExTags").trigger("chosen:updated");

                            //mapeo exItemProperties
                            angular.forEach(dto.itemProperties, function (iDto) {
                                if (iDto && iDto.exclude == true) {
                                    self.exitemproperties.value = iDto.value;
                                    self.excludeItemProperty(self.zoneRule);
                                }
                            });
                            break;
                    }
                });
            
            switch (priceRule.priceRuleType) {
                case 1:
                    self.isEditingMarginRule = true;
                    break;
                case 2:
                    self.isEditingCompetitiveRule = true;
                    break;
                case 3:
                    self.isEditingParentRule = true;
                    break;
                case 4:
                    self.isEditingZoneRule = true;
                    break;
            }
        }

        self.selectAllPriceListsForRule = function (rule) {
            if (self.allPriceListsChecked) {
                rule.pricelists = angular.copy(self.pricelists);
                rule.expricelists = [];
            }
            else {
                rule.pricelists = [];
                rule.expricelists = [];
            }
        }
        self.selectAllVendorsForRule = function (rule) {
            if (self.allVendorsChecked) {
                rule.vendors = angular.copy(self.vendors);
                rule.exvendors = [];
            }
            else {
                rule.vendors = [];
                rule.exvendors = [];
            }
        }
        self.selectAllCategoriesForRule = function (rule) {
            if (self.allCategoriesChecked) {
                rule.categories = angular.copy(self.categories);
                rule.excategories = [];
                self.selectAllArticlesGroupForRule(rule);
            }
            else {
                rule.categories = [];
                rule.excategories = [];
            }
        }
        self.selectAllArticlesGroupForRule = function (rule) {
            if (self.allArticlesGroupChecked) {
                rule.articlesGroup = angular.copy(self.allArticlesGroup);
                rule.exarticlesGroup = [];
            }
            else {
                rule.articlesGroup = [];
                rule.exarticlesGroup = [];
            }
        }
        self.addItemsToInclude = function (rule) {

            var itemsToInclude = [];
            if (!self.isItemIncludeDisabled) {
                for (var i = 0; i < self.includedItems.length; i++) { //Agrego los items a Incluir
                    itemsToInclude.push({ itemId: self.includedItems[i].itemId, exclude: false, umvId: self.includedItems[i].umvId })
                }
            }

            for (var i = 0; i < self.excludedItems.length; i++) { //Agrego los items a Excluir
                itemsToInclude.push({ itemId: self.excludedItems[i].itemId, exclude: true, umvId: self.excludedItems[i].umvId })
            }

            rule.items = itemsToInclude;
        };
        self.addExcluded = function (rule) {
            //Agregar PriceLists Excluidas
            for (var i = 0; i < rule.expricelists.length; i++) {
                for (var j = 0; j < rule.pricelists.length; j++) {
                    if (rule.expricelists[i].priceListId == rule.pricelists[j].priceListId)
                        rule.pricelists[j].exclude = true;
                }
            }

            //Agregar Vendors Excluidos
            for (var i = 0; i < rule.exvendors.length; i++) {
                for (var j = 0; j < rule.vendors.length; j++) {
                    if (rule.exvendors[i].vendorId == rule.vendors[j].vendorId)
                        rule.vendors[j].exclude = true;
                }
            }

            //Agregar Grupos de articulos Excluidos
            for (var i = 0; i < rule.exarticlesGroup.length; i++) {
                for (var j = 0; j < rule.articlesGroup.length; j++) {
                    if (rule.exarticlesGroup[i].articleGroupId == rule.articlesGroup[j].articleGroupId)
                        rule.articlesGroup[j].exclude = true;
                }
            }

            //Agregar ItemSensitivities Excluidas
            if (rule.itemSensitivities.length == 0 && rule.exitemSensitivities.length > 0) {
                _.forEach(rule.exitemSensitivities, function (es, key) {
                    rule.itemSensitivities.push(es);
                });
            }

            //Agregar Tags Excluidos
            if (rule.tags.length == 0 && rule.extags.length > 0) {
                _.forEach(rule.extags, function (et, key) {
                    rule.tags.push(et);
                });
            }

            //Agregar ItemProperties Excluidos
            if (rule.exitemproperties.length > 0) {
                _.forEach(rule.exitemproperties, function (eip, key) {
                    rule.itemproperties.push(eip);
                });
            }

            //Agregar Categorias Excluidas
            for (var i = 0; i < rule.excategories.length; i++) {
                for (var j = rule.categories.length - 1; j >= 0; j--) {
                    if (rule.excategories[i].categoryId == rule.categories[j].categoryId)
                        rule.categories.splice(j, 1);
                }
            }

            self.addItemsToInclude(rule);
        }
        self.includeItemProperty = function (rule) {
            if (self.itemproperties.value != '') {
                rule.itemproperties.push(angular.copy(self.itemproperties));
                if (self.includedItemProperties == "")
                    self.includedItemProperties += self.itemproperties.value;
                else
                    self.includedItemProperties += ", " + self.itemproperties.value;
                self.itemproperties.value = "";
            }
        }
        self.excludeItemProperty = function (rule) {
            if (self.exitemproperties.value != '') {
                rule.exitemproperties.push(angular.copy(self.exitemproperties));
                if (self.excludedItemProperties == "")
                    self.excludedItemProperties += self.exitemproperties.value;
                else
                    self.excludedItemProperties += ", " + self.exitemproperties.value;
                self.exitemproperties.value = "";
            }
        }
        self.deleteItemProperties = function (rule, isExclude) {
            if (isExclude) {
                self.excludedItemProperties = '';
                rule.exitemproperties = [];
            }
            else {
                self.includedItemProperties = '';
                rule.itemproperties = [];
            }
        }

        self.categoryChanged = function (rule) {
            self.articlesGroup = getArticleGroupsForCategories(rule.categories);
            rule.articlesGroup = angular.copy(self.articlesGroup);
            self.allArticlesGroupChecked = true;
        }

        getArticleGroupsForCategories = function (categories) {
            var rv = [];

            angular.forEach(categories, function (c) {
                angular.forEach(self.allArticlesGroup, function (ga) {
                    if (ga.articleGroupParentId == c.categoryId)
                        rv.push(ga);
                });
            });

            return rv;
        }

        self.existingCompetitiveRules = [];
        self.existingRuleSelectedCompetitive = function (rule) {
            if (rule != null) {
                self.competitiveRule.id = rule.id;
                self.competitiveRule.writtenRule = translations.RULE + rule.name;
                self.competitiveRule.competitorGroup = 0;
                self.competitiveRule.competitorGroupParent = 0
            }
            else {
                self.competitiveRule.id = null;
                self.competitiveRule.writtenRule = null;
                self.competitiveRule.competitorGroup = null;
                self.competitiveRule.competitorGroupParent = null;
            }
        }

        $scope.$watch('ct.competitiveRule', function (newVal, oldVal) {

            if (self.competitiveRule != null) {
                self.priceTypes = [];
                self.priceTypes.push('Competition');
                self.totalConflicts = 0;


                self.competitorGroup = newVal.competitorGroup;
                self.competitorGroupParent = newVal.competitorGroupParent;

                self.competitiveRule.writtenRule = null;

                if (self.competitiveRule.priceSurveyTypes.length > 0) {

                    if (self.competitiveRule.calculationType != null || self.competitiveRule.competitor != null) {

                        if (self.competitiveRule.competitor != null) {

                            //Blanqueo los campos de grupo de competidores si seleccione un competidor especifico.
                            self.competitiveRule.onlyCompetitorsWithSamePriceList = false;
                            self.competitiveRule.calculationType = null;
                            self.competitiveRule.modeFallback = null;
                            self.competitiveRule.modeType = null;
                        }

                        if (self.competitiveRule.calculationType != 3) {
                            self.competitiveRule.modeFallback = null;
                            self.competitiveRule.modeType = null;
                        }

                        var priceSurveyTypesText = ' (';
                        angular.forEach(self.competitiveRule.priceSurveyTypes, function (type) {
                            if (priceSurveyTypesText.length > 2)
                                priceSurveyTypesText += ', ';
                            priceSurveyTypesText += type.priceSurveyTypeName;
                        });
                        priceSurveyTypesText += ')';

                        self.competitiveRule.writtenRule = translations.CompetitiveWrittenRule1 + ' ';

                        if (self.competitiveRule.competitor != null) {
                            self.competitiveRule.writtenRule += translations.of + ' ' + self.competitiveRule.competitor.name + priceSurveyTypesText;
                        }
                        else {
                            if (self.calculationTypes[self.competitiveRule.calculationType]) {
                                self.competitiveRule.writtenRule += self.calculationTypes[self.competitiveRule.calculationType].name + ' ' + translations.of + ' ';
                            }

                            var compGrp = self.competitorGroup != null ? self.competitorGroup : self.competitorGroupParent;
                            if (compGrp) {
                                self.competitiveRule.writtenRule += ' ' + compGrp.name + priceSurveyTypesText;
                            }

                            if (self.competitiveRule.calculationType == 3 && self.competitiveRule.modeType != null) {
                                self.competitiveRule.writtenRule += '. ' + translations.IfMoreThanOneMode + ' ' + self.modeTypes[self.competitiveRule.modeType].name;

                                if (self.competitiveRule.modeType == 0 && self.competitiveRule.modeFallback != null) {
                                    self.competitiveRule.writtenRule += ', ' + translations.IfMoreThanOneModeWithSameReps + ' ' + self.modeFallbacks[self.competitiveRule.modeFallback].name;
                                }
                            }

                            if (self.competitiveRule.onlyCompetitorsWithSamePriceList) {
                                self.competitiveRule.writtenRule += ' ' + translations.OnlyCompetitorsInSamePriceZone;
                            }
                        }

                        var difType = null;

                        if (self.competitiveRule.differenceType != null) {

                            if (self.competitiveRule.competitiveDifference && self.competitiveRule.competitiveDifference != 0) {
                                self.competitiveRule.writtenRule += '. ' + translations.DifferenceOf + ' ';
                                if (self.competitiveRule.differenceType == 1)
                                    self.competitiveRule.writtenRule += self.competitiveRule.competitiveDifference + ' %';
                                else if (self.competitiveRule.differenceType == 2)
                                    self.competitiveRule.writtenRule += '$ ' + self.competitiveRule.competitiveDifference;
                            }
                            else {
                                self.competitiveRule.writtenRule += '. ' + translations.SamePrice;
                            }
                        }
                        else {
                            self.competitiveRule.writtenRule += '. ' + translations.SamePrice;
                        }
                    }
                }

                self.isItemIncludeDisabled = false;
                //Se aplico un ambito de aplicacion
                if (self.competitiveRule.itemSensitivities.length > 0 || self.competitiveRule.tags.length > 0 || self.competitiveRule.itemproperties.length > 0 ||
                    self.competitiveRule.exitemSensitivities.length > 0 || self.competitiveRule.extags.length > 0 || self.competitiveRule.exitemproperties.length > 0) {
                    //TODO: Recorrer los items tildados y deschequearlos, tambien borrarlos de Items.
                    self.isItemIncludeDisabled = true;
                }

                self.gridOptions.api.onFilterChanged();
                self.gridOptions.api.refreshView();
            }
        }, true);

        self.changeCompetitorGroupParents = function () {

            if (self.competitiveRule.competitorGroupParent != null) {

                self.competitorGroupsFilteredByParent = ($filter('filter')(self.competitorGroups, { parentId: self.competitiveRule.competitorGroupParent.id }));
            }
            else {
                self.competitorGroupsFilteredByParent = self.competitorGroups;
                self.competitiveRule.competitorGroup = null;
            }
        }
        self.changeCompetitorGroup = function () {
            if (self.competitiveRule.competitorGroup != null)
                loadCompetitors(self.competitiveRule.competitorGroup.id);
            else {
                self.competitiveRule.competitor = null;
                self.competitors = null;
            }
        }
        self.createCompetitorRule = function () {
            var text = translations.AreYouSureSwalSubtitle;
            if (self.isEditing) {
                text += "\n Se borraran las sugerencias asociadas. Debera generar nuevas sugerencias";
            }
            swal({
                title: translations.AreYouSureSwal,
                text: text,
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.Cancel,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function () {
                    var competitorId = self.competitiveRule.competitor != null ? self.competitiveRule.competitor.id : null;
                    //var calculationType = self.competitiveRule.calculationType != null ? self.competitiveRule.calculationType.id : null;
                    //var differenceType = self.competitiveRule.differenceType != null ? self.competitiveRule.differenceType : null;
                    var compDiference = self.competitiveRule.differenceType != null && self.competitiveRule.differenceType == '1' ? self.competitiveRule.competitiveDifference / 100 : self.competitiveRule.competitiveDifference;

                    self.addExcluded(self.competitiveRule);

                    var shouldIncludeItems = (self.competitiveRule.exitemproperties.length == 0 && self.competitiveRule.itemproperties.length == 0 &&
                        self.competitiveRule.exitemSensitivities.length == 0 && self.competitiveRule.itemSensitivities.length == 0 &&
                        self.competitiveRule.extags.length == 0 && self.competitiveRule.tags.length == 0)
                    self.competitiveRule.items = shouldIncludeItems ? self.competitiveRule.items : []; //Solo incluyo los items si no hay ningun ambito de aplicacion agregado

                    var articlesGroupToExclude = [];
                    if (self.allArticlesGroupChecked) {
                        angular.forEach(self.competitiveRule.articlesGroup, function (ag) {
                            if (ag.exclude)
                                articlesGroupToExclude.push(ag);
                        });
                    }

                    var vendorsToExclude = [];
                    if (self.allVendorsChecked) {
                        angular.forEach(self.competitiveRule.vendors, function (v) {
                            if (v.exclude)
                                vendorsToExclude.push(v);
                        });
                    }

                    var priceRuleCompetitiveDto = {
                        id: self.competitiveRule.id,
                        companyId: $rootScope.companyId,
                        name: self.competitiveRule.writtenRule,
                        modeType: self.competitiveRule.modeType,
                        modeFallback: self.competitiveRule.modeFallback,
                        competitorGroupId: competitorId != null ? null : (self.competitiveRule.competitorGroup != null ? self.competitiveRule.competitorGroup.id : self.competitiveRule.competitorGroupParent.id),
                        competitorId: competitorId,
                        onlyCompetitorsWithSamePriceList: self.competitiveRule.onlyCompetitorsWithSamePriceList,
                        calculationType: self.competitiveRule.calculationType,
                        percentageDifference1: self.competitiveRule.differenceType == '1' ? compDiference : null,
                        priceDifference1: self.competitiveRule.differenceType == '2' ? compDiference : null,
                        considerModeCount: self.competitiveRule.considerModeCount,
                        priceLists: self.competitiveRule.pricelists,
                        vendors: self.allVendorsChecked ? vendorsToExclude : self.competitiveRule.vendors, //Si estan todos tildados, le mando los excluidos/vacio y el check en allVendors en true
                        allVendorsChecked: self.allVendorsChecked,
                        articlesGroup: self.allArticlesGroupChecked ? articlesGroupToExclude : self.competitiveRule.articlesGroup, //Si estan todos tildados, le mando los excluidos/vacio y el check en allArticlesGroup en true
                        allArticlesGroupChecked: self.allArticlesGroupChecked,
                        priceSurveyTypes: self.competitiveRule.priceSurveyTypes,
                        itemSensitivities: self.competitiveRule.itemSensitivities,
                        tags: self.competitiveRule.tags,
                        categories: self.competitiveRule.categories,
                        itemProperties: self.competitiveRule.itemproperties,
                        items: self.competitiveRule.items,
                    }

                    priceService.rules.savePriceRuleCompetitive(priceRuleCompetitiveDto).then(function () {

                        self.cancel();
                        reload();
                        swal(translations.ConfirmedSwal, translations.ConfirmedSwalSubtitle, "success");

                    }).catch(function (fallback) {
                        console.log(fallback);
                        setTimeout(function () { swal(translations.ErrorTitleSwal, translations.ErrorSwalSubtitle, "error"); }, 100);
                    });
                });
        }
        // End Competitive Rule

        $scope.$watch('ct.marginRule', function (newVal, oldVal) {
            if (self.marginRule && (self.marginRule.marginRuleType == 1 || self.marginRule.marginRuleType == 2)) {
                self.priceTypes = [];
                self.priceTypes.push('Regular');
                self.totalConflicts = 0;

                self.marginRule.writtenRule = null;

                if (self.marginRule.marginRuleType == 1) {
                    if (self.marginRule.margin != null)
                        self.marginRule.writtenRule = "Margen de " + self.marginRule.margin + "%";
                    else if (self.marginRule.marginAmount != null)
                        self.marginRule.writtenRule = "Margen de $ " + self.marginRule.marginAmount;
                }
                else if (self.marginRule.marginRuleType == 2) {
                    if (self.marginRule.keepMarginType == 1)
                        self.marginRule.writtenRule = translations.KeepSameMarginPercentage;
                    else if (self.marginRule.keepMarginType == 2)
                        self.marginRule.writtenRule = translations.KeepSameMargin$;
                }
            }

            self.isItemIncludeDisabled = false;
            //Se aplico un ambito de aplicacion
            if (self.marginRule.itemSensitivities.length > 0 || self.marginRule.tags.length > 0 || self.marginRule.itemproperties.length > 0 ||
                self.marginRule.exitemSensitivities.length > 0 || self.marginRule.extags.length > 0 || self.marginRule.exitemproperties.length > 0) {
                //TODO: Recorrer los items tildados y deschequearlos, tambien borrarlos de Items.
                {
                    self.isItemIncludeDisabled = true;
                }
            }

            self.gridOptions.api.onFilterChanged();
            self.gridOptions.api.refreshView();

        }, true);

        self.createMarginRule = function () {
            var text = translations.AreYouSureSwalSubtitle;
            if (self.isEditing) {
                text += "\n Se borraran las sugerencias asociadas. Debera generar nuevas sugerencias";
            }
            swal({
                title: translations.AreYouSureSwal,
                text: text,
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.Cancel,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function () {

                    self.addExcluded(self.marginRule);

                    var shouldIncludeItems = (self.marginRule.exitemproperties.length == 0 && self.marginRule.itemproperties.length == 0 &&
                        self.marginRule.exitemSensitivities.length == 0 && self.marginRule.itemSensitivities.length == 0 &&
                        self.marginRule.extags.length == 0 && self.marginRule.tags.length == 0)
                    self.marginRule.items = shouldIncludeItems ? self.marginRule.items : []; //Solo incluyo los items si no hay ningun ambito de aplicacion agregado
                    var articlesGroupToExclude = [];
                    if (self.allArticlesGroupChecked) {
                        angular.forEach(self.marginRule.articlesGroup, function (ag) {
                            if (ag.exclude)
                                articlesGroupToExclude.push(ag);
                        });
                    }

                    var vendorsToExclude = [];
                    if (self.allVendorsChecked) {
                        angular.forEach(self.marginRule.vendors, function (v) {
                            if (v.exclude)
                                vendorsToExclude.push(v);
                        });
                    }

                    var priceRuleMarginDto = {
                        id: self.marginRule.id,
                        companyId: $rootScope.companyId,
                        name: self.marginRule.writtenRule,
                        margin: self.marginRule.margin != null ? self.marginRule.margin / 100 : null,
                        marginAmount: self.marginRule.marginAmount,
                        keepMargin: self.marginRule.keepMarginType == 1 ? true : null,
                        keepMarginAmount: self.marginRule.keepMarginType == 2 ? true : null,
                        priceLists: self.marginRule.pricelists,
                        vendors: self.allVendorsChecked ? vendorsToExclude : self.marginRule.vendors,
                        allVendorsChecked: self.allVendorsChecked,
                        articlesGroup: self.allArticlesGroupChecked ? articlesGroupToExclude : self.marginRule.articlesGroup, //Si estan todos tildados, le mando vacio y el check en allArticlesGroup en true
                        allArticlesGroupChecked: self.allArticlesGroupChecked,
                        itemSensitivities: self.marginRule.itemSensitivities,
                        tags: self.marginRule.tags,
                        categories: self.marginRule.categories,
                        itemProperties: self.marginRule.itemproperties,
                        items: self.marginRule.items,
                    }

                    priceService.rules.savePriceRuleMargin(priceRuleMarginDto).then(function () {

                        self.cancel();
                        reload();
                        swal(translations.ConfirmedSwal, translations.ConfirmedSwalSubtitle, "success");

                    }).catch(function (fallback) {
                        console.log(fallback);
                        setTimeout(function () { swal(translations.ErrorTitleSwal, translations.ErrorSwalSubtitle, "error"); }, 100);
                    });

                });
        }
        //End Margin Rule
        self.existingParentRules = [];
        self.existingRuleSelectedParent = function (rule) {
            if (rule != null) {
                self.parentRule.id = rule.id;
                self.parentRule.writtenRule = rule.name;
            }
            else {
                self.parentRule.id = null;
                self.parentRule.writtenRule = null;
                self.parentRule.parent = null;
            }
        }
        $scope.$watch('ct.parentRule', function (newVal, oldVal) {

            if (self.parentRule.id == null) {
                self.priceTypes = [];
                self.priceTypes.push('Regular');
                self.priceTypes.push('Competition');

                if (self.parentRule.parent != null) {

                    //Seteo el Id que tengo que ocultar de la grilla y refresco el filtro
                    self.excludedItemId = self.parentRule.parent.id;
                    self.totalConflicts = 0;

                    if (self.parentRule.relationshipType != null) {

                        self.parentRule.writtenRule = translations.RULE + self.parentRule.relationshipType.name;

                        if (self.parentRule.difference == 0 || self.parentRule.difference == null) {
                            self.parentRule.writtenRule += " igual a ";
                        }
                        else {

                            var difType = null;
                            if (self.parentRule.differenceType == 1)
                                difType = (self.parentRule.difference < 0 ? ' menos ' : ' mas ') + self.parentRule.difference + '%';
                            else
                                difType = (self.parentRule.difference < 0 ? ' menos ' : ' mas ') + '$ ' + self.parentRule.difference;

                            self.parentRule.writtenRule += difType + ' que el ' + self.parentRule.relationshipType.name + ' de ';
                        }

                        self.parentRule.writtenRule += self.parentRule.parent.name + ' (' + self.parentRule.parent.code + ')';
                    }
                }
            }

            self.isItemIncludeDisabled = false;
            //Se aplico un ambito de aplicacion
            if (self.parentRule.itemSensitivities.length > 0 || self.parentRule.tags.length > 0 || self.parentRule.itemproperties.length > 0 ||
                self.parentRule.exitemSensitivities.length > 0 || self.parentRule.extags.length > 0 || self.parentRule.exitemproperties.length > 0) {
                //TODO: Recorrer los items tildados y deschequearlos, tambien borrarlos de Items.
                self.isItemIncludeDisabled = true;
            }

            self.gridOptions.api.onFilterChanged();
            self.gridOptions.api.refreshView();

        }, true);

        self.setAsGenericParentRule = false;
         var existingGenericRule = null;
         $scope.$watch('ct.setAsGenericParentRule', function (newVal, oldVal) {

             if (newVal != oldVal) {

                 if (self.itemLookupText && self.itemLookupText.genericItemId != null) {

                     if (!newVal)
                         self.parentRule.genericItemId = null;
                     else {
                         //Chequeo de que no haya otra regla con mismo generico
                         existingGenericRule = null;

                         self.priceRulesTable.forEach(function (rule) {

                             if (existingGenericRule == null && rule.genericItemId != null && rule.genericItemId == self.itemLookupText.genericItemId) {
                                 existingGenericRule = rule;
                             }
                         });

                         self.parentRule.genericItemId = self.itemLookupText.genericItemId;
                     }
                 }
             }
        });

        var itemLookupTextPrefix = '';
        self.itemLookupNoResults = false;
        self.itemLookupLoading = false;

        self.itemLookupOnKeydown = function (evt) {
            //console.log('evt', evt);
            if (evt.keyCode == 13 &&
                self.itemLookupText == '') {
                self.itemLookupOnItemSelect({ id: 0 });
            }
            else if (self.itemLookupText.length >= 3
                && itemLookupTextPrefix != self.itemLookupText) {
                itemLookupTextPrefix = self.itemLookupText;

                self.itemLookupLoading = true;
                var lookupParameter = { searchTerm: self.itemLookupText, categoryIds: self.categoryIds, onlyMainItems: true };

                priceService.lookups.itemsLookupByCategory(lookupParameter, $stateParams.categoryId)
                    .then(function (items) {
                        self.itemLookupLoading = false;
                        self.itemsInLookup = items;
                    });
            }
        }

        self.itemLookupOnItemSelect = function (item) {
            if (item == undefined) return;
            self.itemLookupText = item.name;

            self.parentRule.parent = item;
            self.parentRule.mainItemId = self.parentRule.parent.id;
            self.parentRule.mainItemUmvId = self.parentRule.parent.saleUnitOfMeasureId;

            if (item && item.genericItemId != null) {
                self.setAsGenericParentRule = true;
            }
        }

        /*
        $scope.$watch('ct.itemLookupText', function (newVal, oldVal) {
            if (newVal && oldVal) {

                if (newVal.id) {
                    self.parentRule.parent = self.itemLookupText;
                    self.parentRule.mainItemId = self.parentRule.parent.id;
                    self.parentRule.mainItemUmvId = self.parentRule.parent.saleUnitOfMeasureId;

                    if (self.itemLookupText && self.itemLookupText.genericItemId != null) {
                        self.setAsGenericParentRule = true;
                    }

                }
                else if (newVal && newVal != oldVal && (newVal.length >= 4 || oldVal.length > 3) && newVal != previousLookupText) {
                    previousLookupText = newVal;
                    self.parentRule.parent = null;
                    self.itemsInLookup = [{ id: 0, code: '', name: 'Cargando Items con el texto ' + newVal + '...' }];
                    var lookupParameter = { searchTerm: newVal, categoryIds: self.categoryIds, onlyMainItems: true };
                    priceService.lookups.itemsLookupByCategory(lookupParameter, $stateParams.categoryId)
                        .then(function (items) {
                            self.itemsInLookup = items;
                        });
                }
            }

        }, true);
        */

        self.createParentRule = function () {
            var dynamicTitle = translations.AreYouSureSwal;
            var dynamicText = translations.AreYouSureSwalSubtitle;
            var dynamicType = 'info';
            if (self.isEditing) {
                dynamicText += "\n Se borraran las sugerencias asociadas. Debera generar nuevas sugerencias";
            }

            if (existingGenericRule != null && self.setAsGenericParentRule == true) { //Si ya existe una regla y es generico, no lo debe dejar guardar con el checkbox tildado.
                swal(translations.ErrorTitleSwal, translations.TheRuleSwal + existingGenericRule.priceRuleDescription + translations.AssociatedWithGenericSwal + self.itemLookupText.generalParentCode + translations.ThereCanOnlyBe1RulePerGenericSwal, 'error');
                self.itemLookupText = '';
            }
            else {

                if (self.includedItems.length == 0 &&
                    self.parentRule.exitemproperties.length == 0 && self.parentRule.itemproperties.length == 0 &&
                    self.parentRule.exitemSensitivities.length == 0 && self.parentRule.itemSensitivities.length == 0 &&
                    self.parentRule.extags.length == 0 && self.parentRule.tags.length == 0
                ) {
                    swal(translations.NoApplicationScopeOrItemsWereAddedSwal, translations.NoApplicationScopeOrItemsWereAddedSwalSubtitle, "error");
                }
                else {
                    swal({
                        title: dynamicTitle,
                        text: dynamicText,
                        type: dynamicType,
                        showCancelButton: true,
                        confirmButtonColor: "#1AB394",
                        confirmButtonText: translations.ContinueSwal,
                        cancelButtonText: translations.CancelSwal,
                        showLoaderOnConfirm: true,
                        closeOnConfirm: false,
                        closeOnCancel: true
                    },
                        function () {
                            var parent = self.parentRule.parent != null ? self.parentRule.parent.id : null;
                            var relationshipType = self.parentRule.relationshipType != null ? self.parentRule.relationshipType.id : null;
                            var differenceType = self.parentRule.differenceType != null ? self.parentRule.differenceType : null;
                            var compDiference = differenceType != null && differenceType == '1' ? self.parentRule.difference / 100 : self.parentRule.difference;

                            self.addExcluded(self.parentRule);

                            var shouldIncludeItems = (self.parentRule.exitemproperties.length == 0 && self.parentRule.itemproperties.length == 0 &&
                                self.parentRule.exitemSensitivities.length == 0 && self.parentRule.itemSensitivities.length == 0 &&
                                self.parentRule.extags.length == 0 && self.parentRule.tags.length == 0)
                            self.parentRule.items = shouldIncludeItems ? self.parentRule.items : []; //Solo incluyo los items si no hay ningun ambito de aplicacion agregado

                            var articlesGroupToExclude = [];
                            if (self.allArticlesGroupChecked) {
                                angular.forEach(self.parentRule.articlesGroup, function (ag) {
                                    if (ag.exclude)
                                        articlesGroupToExclude.push(ag);
                                });
                            }

                            var vendorsToExclude = [];
                            if (self.allVendorsChecked) {
                                angular.forEach(self.parentRule.vendors, function (v) {
                                    if (v.exclude)
                                        vendorsToExclude.push(v);
                                });
                            }

                            var priceRuleParentDto = {
                                id: self.parentRule.id,
                                companyId: $rootScope.companyId,
                                name: self.parentRule.writtenRule,
                                parent: self.parentRule.parent,
                                genericItemId: self.parentRule.genericItemId,
                                mainItemId: self.parentRule.parent != null ? self.parentRule.parent.id : null,
                                mainItemUmvId: self.parentRule.parent != null ? self.parentRule.parent.saleUnitOfMeasureId : null,
                                relationshipType: relationshipType,
                                percentageDifference2: differenceType == '1' ? compDiference : null,
                                priceDifference2: differenceType == '2' ? compDiference : null,
                                competitiveDifference: compDiference,
                                priceLists: self.parentRule.pricelists,
                                vendors: self.allVendorsChecked ? vendorsToExclude : self.parentRule.vendors,
                                allVendorsChecked: self.allVendorsChecked,
                                articlesGroup: self.allArticlesGroupChecked ? articlesGroupToExclude : self.parentRule.articlesGroup, //Si estan todos tildados, le mando vacio y el check en allArticlesGroup en true
                                allArticlesGroupChecked: self.allArticlesGroupChecked,
                                itemSensitivities: self.parentRule.itemSensitivities,
                                tags: self.parentRule.tags,
                                categories: self.parentRule.categories,
                                itemProperties: self.parentRule.itemproperties,
                                items: self.parentRule.items,
                            }

                            priceService.rules.savePriceRuleParent(priceRuleParentDto).then(function () {

                                self.cancel();
                                reload();
                                setTimeout(function () { swal(translations.ConfirmedSwal, translations.ConfirmedSwalSubtitle, "success"); }, 100);
                            }).catch(function (fallback) {
                                console.log(fallback);
                                setTimeout(function () { swal(translations.ErrorTitleSwal, translations.ErrorSwalSubtitle, "error"); }, 100);
                            });
                        });
                }
            }
        }
        // End Parent Rule


        self.excludeSelectedPriceList = function (priceListId) {
            self.zonePricelist = []
            self.zonePricelist.length = 0;

            self.zonePricelist = angular.copy(self.pricelists);

            if (self.zoneRule.zone != null)
                _.remove(self.zonePricelist, { priceListId: priceListId });

            self.zoneRule.pricelists = self.zonePricelist;
        }
        $scope.$watch('ct.zoneRule', function (newVal, oldVal) {

            if (self.zoneRule.id == null) {
                self.priceTypes = [];
                self.priceTypes.push('Regular');

                if (self.zoneRule.zone != null) {
                    self.totalConflicts = 0;

                    if (oldVal.zone == null || newVal.zone.priceListId != oldVal.zone.priceListId)
                        self.excludeSelectedPriceList(self.zoneRule.zone.priceListId);

                    if (self.zoneRule.relationshipType != null) {
                        self.zoneRule.writtenRule = translations.RULE + self.zoneRule.relationshipType.name;

                        if (self.zoneRule.difference == 0) {
                            self.zoneRule.writtenRule += " igual a zona ";
                        }
                        else {

                            var difType = null;
                            if (self.zoneRule.differenceType == 1)
                                difType = (self.zoneRule.difference < 0 ? ' menos ' : ' mas ') + self.zoneRule.difference + '%';
                            else
                                difType = (self.zoneRule.difference < 0 ? ' menos ' : ' mas ') + '$ ' + self.zoneRule.difference;

                            self.zoneRule.writtenRule += difType + ' que el ' + self.zoneRule.relationshipType.name + ' de zona ';
                        }

                        self.zoneRule.writtenRule += self.zoneRule.zone.priceListName + ' (' + self.zoneRule.zone.priceListCode + ')';
                    }
                }
            }

            self.isItemIncludeDisabled = false;
            //Se aplico un ambito de aplicacion
            if (self.zoneRule.itemSensitivities.length > 0 || self.zoneRule.tags.length > 0 || self.zoneRule.itemproperties.length > 0 ||
                self.zoneRule.exitemSensitivities.length > 0 || self.zoneRule.extags.length > 0 || self.zoneRule.exitemproperties.length > 0) {
                //TODO: Recorrer los items tildados y deschequearlos, tambien borrarlos de Items.
                self.isItemIncludeDisabled = true;
            }

            self.gridOptions.api.onFilterChanged();
            self.gridOptions.api.refreshView();

        }, true);
        self.createZoneRule = function () {
            var text = translations.AreYouSureSwalSubtitle;
            if (self.isEditing) {
                text += "\n Se borraran las sugerencias asociadas. Debera generar nuevas sugerencias";
            }
            swal({
                title: translations.AreYouSureSwal,
                text: text,
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function () {
                    var relationshipType = self.zoneRule.relationshipType != null ? self.zoneRule.relationshipType.id : null;
                    var differenceType = self.zoneRule.differenceType != null ? self.zoneRule.differenceType : null;
                    var compDiference = differenceType != null && differenceType == '1' ? self.zoneRule.difference / 100 : self.zoneRule.difference;

                    self.addExcluded(self.zoneRule);

                    var shouldIncludeItems = (self.zoneRule.exitemproperties.length == 0 && self.zoneRule.itemproperties.length == 0 &&
                        self.zoneRule.exitemSensitivities.length == 0 && self.zoneRule.itemSensitivities.length == 0 &&
                        self.zoneRule.extags.length == 0 && self.zoneRule.tags.length == 0)
                    self.zoneRule.items = shouldIncludeItems ? self.zoneRule.items : []; //Solo incluyo los items si no hay ningun ambito de aplicacion agregado

                    var articlesGroupToExclude = [];
                    if (self.allArticlesGroupChecked) {
                        angular.forEach(self.zoneRule.articlesGroup, function (ag) {
                            if (ag.exclude)
                                articlesGroupToExclude.push(ag);
                        });
                    }

                    var vendorsToExclude = [];
                    if (self.allVendorsChecked) {
                        angular.forEach(self.zoneRule.vendors, function (v) {
                            if (v.exclude)
                                vendorsToExclude.push(v);
                        });
                    }

                    var priceRuleZoneDto = {
                        id: self.zoneRule.id,
                        companyId: $rootScope.companyId,
                        name: self.zoneRule.writtenRule,
                        mainPriceListId: self.zoneRule.zone.priceListId,
                        relationshipType: relationshipType,
                        percentageDifference2: differenceType == '1' ? compDiference : null,
                        priceDifference2: differenceType == '2' ? compDiference : null,
                        competitiveDifference: compDiference,
                        priceLists: self.zoneRule.pricelists,
                        vendors: self.allVendorsChecked ? vendorsToExclude : self.zoneRule.vendors,
                        allVendorsChecked: self.allVendorsChecked,
                        articlesGroup: self.allArticlesGroupChecked ? articlesGroupToExclude : self.zoneRule.articlesGroup, //Si estan todos tildados, le mando vacio y el check en allArticlesGroup en true
                        allArticlesGroupChecked: self.allArticlesGroupChecked,
                        itemSensitivities: self.zoneRule.itemSensitivities,
                        tags: self.zoneRule.tags,
                        categories: self.zoneRule.categories,
                        itemProperties: self.zoneRule.itemproperties,
                        items: self.zoneRule.items,
                    }

                    priceService.rules.savePriceRuleZone(priceRuleZoneDto).then(function () {

                        self.cancel();
                        reload();
                        setTimeout(function () { swal(translations.ConfirmedSwal, translations.ConfirmedSwalSubtitle, "success"); }, 100);
                    }).catch(function (fallback) {
                        console.log(fallback);
                        setTimeout(function () { swal(translations.ErrorTitleSwal, translations.ErrorSwalSubtitle, "error"); }, 100);
                    });
                });
        }
        // End Zone Rule


        //Grid
        self.includeItem = function (row, includeAction) {
            if (self.isEditingCompetitiveRule || self.isEditingMarginRule || self.isEditingParentRule || self.isEditingZoneRule) {
                var itemId = row.itemId;
                var umv = row.salesUnitOfMeasureId;
                var includeChk = row.includeItem;
                var excludeChk = row.excludeItem;

                var itemWithUmv = { itemId: itemId, umvId: umv };

                var indexInc = objectIndexOf(self.includedItems, itemWithUmv);
                var indexExc = objectIndexOf(self.excludedItems, itemWithUmv);

                if (includeAction == true) {
                    if (includeChk) //Si lo seleccione
                    {
                        row.excludeItem = false; //Deselecciono el excluir

                        //Si est�, lo saco del listado de excluidos(si esta) y lo agrego al de incluidos
                        if (indexExc > -1)
                            self.excludedItems.splice(indexExc, 1);
                        if (indexInc == -1)
                            self.includedItems.push(itemWithUmv);
                    }
                    else { //Si lo deseleccione
                        if (indexInc > -1)
                            self.includedItems.splice(indexInc, 1); //Lo saco del listado de incluidos
                    }
                }
                else if (includeAction == false) {
                    if (excludeChk)  //Si lo seleccione
                    {

                        row.includeItem = false; //Deselecciono el incluir

                        //Lo saco del listado de incluidos(si esta) y lo agrego al de excluidos
                        if (indexInc > -1)
                            self.includedItems.splice(indexInc, 1);
                        if (indexExc == -1)
                            self.excludedItems.push(itemWithUmv);
                    }
                    else {//Si lo deseleccione
                        if (indexExc > -1)
                            self.excludedItems.splice(indexExc, 1);
                    }
                }
            }
        }
        function setColumnDefinitions() {
            columnDefs = [
                {
                    headerName: translations.ActionsAgGrid,//"Acciones",
                    children: [
                        {
                            headerName: translations.Include,
                            field: "includeItem",
                            pinned: 'left',
                            suppressMenu: true,
                            suppressSorting: true,
                            filter: 'input',
                            width: 50,
                            cellClass: $rootScope.getClassForCell,
                            checkbox: true,
                            cellRenderer: function (params) {
                                if (params.node.group) {
                                    if (self.isItemIncludeDisabled)
                                        return '';

                                    var eInput = document.createElement("input");
                                    eInput.type = "checkbox";
                                    eInput.checked = params.value;

                                    eInput.addEventListener("change", function (event) {
                                        var changedRows = [];
                                        params.value = eInput.checked;
                                        params.data[params.column.colId] = eInput.checked;

                                        if (params.colDef.field == 'includeItem') { //Si toque incluir
                                            self.includeItem(params.data, true);
                                            params.data.excludeItem = false;
                                            changedRows.push(params.node);


                                            //if (params.node != null && params.node.children != null) {
                                            //    params.node.children.forEach(function (node) {
                                            //        node.data.dirty = true;
                                            //        changedRows.push(node);
                                            //    });
                                            //}

                                            params.api.refreshCells(changedRows);
                                        }
                                        self.gridOptions.api.onFilterChanged();
                                    });

                                    return eInput;
                                }
                                else
                                    return '';
                            },
                        },
                        {
                            headerName: translations.Exclude,
                            field: "excludeItem",
                            suppressMenu: true,
                            suppressSorting: true,
                            filter: 'input',
                            width: 55,
                            pinned: 'left',
                            checkbox: true,
                            cellRenderer: function (params) {
                                if (params.node.group) {
                                    var eInput = document.createElement("input");
                                    eInput.type = "checkbox";
                                    eInput.checked = params.value;

                                    eInput.addEventListener("change", function (event) {
                                        var changedRows = [];
                                        params.value = eInput.checked;
                                        params.data[params.column.colId] = eInput.checked;

                                        if (params.colDef.field == 'excludeItem') { //Si toque incluir
                                            self.includeItem(params.data, false);
                                            params.data.includeItem = false;
                                            changedRows.push(params.node);


                                            //if (params.node != null && params.node.children != null) {
                                            //    params.node.children.forEach(function (node) {
                                            //        node.data.dirty = true;
                                            //        changedRows.push(node);
                                            //    });
                                            //}

                                            params.api.refreshCells(changedRows);
                                        }

                                        self.totalConflicts = 0;
                                        self.gridOptions.api.onFilterChanged();
                                    });


                                    return eInput;
                                }
                                else
                                    return '';
                            }
                        },
                    ]
                },
                {
                    headerName: translations.GeneralInformation,
                    children: [
                        {
                            headerName: translations.Subcategory,
                            width: 150,
                            columnGroupShow: 'open',
                            field: "subCategory",
                        },
                        {
                            headerName: translations.GroupOfArticles,
                            width: 150,
                            columnGroupShow: 'open',
                            field: "alternativeCategoryName",
                        },
                        {
                            headerName: translations.Code,
                            field: "code",
                            width: 160,
                            cellRenderer: {
                                renderer: 'group',
                                checkbox: false,
                                innerRenderer: function (params) {
                                    var count = '';
                                    if (params.node.group)
                                        count = " (" + params.node.childrenAfterFilter.length + ") "

                                    return params.value.replace(/^0+/, '') + count;
                                }
                            }
                        },
                        {
                            headerName: 'UM',
                            width: 50,
                            suppressMenu: true,
                            field: 'salesUnitOfMeasure',
                        },
                        {
                            headerName: translations.Description,
                            width: 300,
                            field: 'description'
                        },
                        {
                            headerName: translations.PriceZone,
                            field: "priceList",
                            width: 165,
                        },
                        {
                            headerName: translations.Supplier,
                            field: "vendorName",
                            width: 165,
                        },
                        {
                            headerName: translations.TypeOfPrice,
                            field: "priceTypeName",
                            width: 165,
                            cellRenderer: function (params) {
                                if (params.node.group)
                                    return '';

                                return params.value;
                            }
                        },
                        {
                            headerName: translations.Brand,
                            columnGroupShow: 'open',
                            field: "brand",
                            width: 150,
                        },
                        {
                            headerName: translations.Maker,
                            columnGroupShow: 'open',
                            field: "manufacturer",
                            width: 150,
                        },
                        {
                            headerName: translations.Tags,
                            columnGroupShow: 'open',
                            field: "tags",
                            width: 200,
                            cellRenderer: function (params) {
                                if (params.node.group)
                                    return params.node.children[0].data.tags;

                                return params.value;
                            }
                        },
                        {
                            headerName: translations.Sensitivities,
                            columnGroupShow: 'open',
                            field: "sensitivities",
                            width: 200,
                        }
                    ]
                },
                {
                    headerName: translations.PricesAndCosts,
                    children: [
                        {
                            headerName: translations.CurrentPrice,
                            field: "_Price",
                            width: 150,
                            filter: 'number',
                            cellRenderer: $rootScope.decimalRenderer,
                        },
                        {
                            headerName: translations.CurrentCost,
                            field: "cost",
                            columnGroupShow: 'open',
                            width: 120,
                            filter: 'number',
                            cellRenderer: $rootScope.decimalRenderer,
                        },
                        {
                            headerName: translations.PercentajeMargin,
                            //field: "grossMarginAbs",
                            field: "grossMargin",
                            columnGroupShow: 'open',
                            width: 100,
                            filter: 'number',
                            cellRenderer: $rootScope.percentageRenderer,
                        },                      
                        {
                            headerName: translations.SalesUn,
                            field: "units",
                            columnGroupShow: 'open',
                            width: 100,
                            filter: 'number',
                            cellRenderer: $rootScope.decimalRenderer,
                        },
                    ]
                },
                {
                    headerName: translations.Rules,
                    children: [
                        {
                            headerName: translations.CompetitiveF,
                            field: "competitiveRule",
                            cellStyle: { 'white-space': 'normal' },
                            cellRenderer: function (params) {

                                var cell = params.value != undefined ? params.value : '';
                                var conflicts = 0;

                                var itemWithUmv = { itemId: params.data.itemId, umvId: params.data.salesUnitOfMeasureId };
                                var indexExc = objectIndexOf(self.excludedItems, itemWithUmv);
                                var isItemExcluded = indexExc > -1;

                                if (!isItemExcluded) {
                                    if (params.node.group && self.isEditingCompetitiveRule) {
                                        angular.forEach(params.node.childrenAfterFilter, function (n) {
                                            if (n.data.competitiveRule != undefined && n.data.competitiveRule != null) {
                                                conflicts++;
                                                cell = '<span title="Aplique filtros para corregirlos."> Hay ' + conflicts + ' Conflictos</span> <i class="fa fa-warning text-warning pull-left mt12"></i>';
                                            }
                                        });
                                    }
                                }

                                return cell;
                            }
                        },
                        {
                            headerName: translations.Margin,
                            field: "marginRule",
                            cellStyle: { 'white-space': 'normal' },
                            cellRenderer: function (params) {

                                var cell = params.value != undefined ? params.value : '';
                                var conflicts = 0;

                                var itemWithUmv = { itemId: params.data.itemId, umvId: params.data.salesUnitOfMeasureId };
                                var indexExc = objectIndexOf(self.excludedItems, itemWithUmv);
                                var isItemExcluded = indexExc > -1;

                                if (!isItemExcluded) {
                                    if (params.node.group && self.isEditingMarginRule) {
                                        angular.forEach(params.node.childrenAfterFilter, function (n) {
                                            if (n.data.marginRule != undefined && n.data.marginRule != null) {
                                                conflicts++;
                                                cell = '<span title="Aplique filtros para corregirlos."> Hay ' + conflicts + ' Conflictos</span> <i class="fa fa-warning text-warning pull-left mt12"></i>';
                                            }
                                        });
                                    }
                                }

                                return cell;
                            }
                        },
                        {
                            headerName: translations.RelationshipItems,
                            field: "parentRuleItem",
                            cellStyle: { 'white-space': 'normal' },
                            cellRenderer: function (params) {

                                var cell = params.value != undefined ? params.value : '';
                                var conflicts = 0;

                                var itemWithUmv = { itemId: params.data.itemId, umvId: params.data.salesUnitOfMeasureId };
                                var indexExc = objectIndexOf(self.excludedItems, itemWithUmv);
                                var isItemExcluded = indexExc > -1;

                                if (!isItemExcluded) {
                                    if (params.node.group && self.isEditingParentRule) {
                                        angular.forEach(params.node.childrenAfterFilter, function (n) {
                                            if (n.data.parentRuleItem != undefined && n.data.parentRuleItem != null) {
                                                conflicts++;
                                                cell = '<span title="Aplique filtros para corregirlos."> Hay ' + conflicts + ' Conflictos</span> <i class="fa fa-warning text-warning pull-left mt12"></i>';
                                            }
                                        });
                                    }
                                }

                                return cell;
                            }
                        },
                        {
                            headerName: translations.KinshipZones,
                            field: "parentRuleZone",
                            cellStyle: { 'white-space': 'normal' },
                            cellRenderer: function (params) {

                                var cell = params.value != undefined ? params.value : '';
                                var conflicts = 0;

                                var itemWithUmv = { itemId: params.data.itemId, umvId: params.data.salesUnitOfMeasureId };
                                var indexExc = objectIndexOf(self.excludedItems, itemWithUmv);
                                var isItemExcluded = indexExc > -1;

                                if (!isItemExcluded) {
                                    if (params.node.group && self.isEditingZoneRule) {
                                        angular.forEach(params.node.childrenAfterFilter, function (n) {
                                            if (n.data.parentRuleZone != undefined && n.data.parentRuleZone != null) {
                                                conflicts++;
                                                cell = '<span title="Aplique filtros para corregirlos."> Hay ' + conflicts + ' Conflictos</span> <i class="fa fa-warning text-warning pull-left mt12"></i>';
                                            }
                                        });
                                    }
                                }

                                return cell;
                            }
                        }
                    ]
                }
            ];
        }
        function setGridOptions() {
            self.gridOptions = {
                //columnDefs: columnDefs,
                enableColResize: true,
                enableSorting: true,
                enableFilter: true,
                //groupHeaders: true,
                rowHeight: 40,
                groupHideGroupColumns: true,
                groupUseEntireRow: false,
                groupSelectsChildren: false,
                rowSelection: "multiple", // one of ['single','multiple'], leave blank for no selection
                rowDeselection: true,
                showToolPanel: false,// window.innerWidth > 1500,
                suppressRowClickSelection: true,
                getNodeChildDetails: getNodeChildDetails,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                angularCompileRows: true,
                isExternalFilterPresent: isExternalFilterPresent,
                doesExternalFilterPass: doesExternalFilterPass,
            };
        }
        self.exportToExcel = function () {
            var params = {
                skipHeader: false,
                skipFooters: false,
                skipGroups: false,
                allColumns: false,
                onlySelected: false,
                suppressQuotes: false,
                fileName: getEditingRuleName() + '.xls',
                columnSeparator: ','
            };

            self.gridOptions.api.exportDataAsCsv(params);
        }

        self.exportPriceRulesTable = function () {

            console.log(self.priceRulesTable);
            var html = '<style>.text{mso-number-format:"\@";/*force text*/}</style><table width="100%"><thead><tr>';

            //header
            html += '<th>Aceptacion</th>';
            html += '<th>Tipo</th>';
            html += '<th>Descripcion</th>';
            html += '<th>Desde</th>';
            html += '<th>Subcategorias</th>';
            html += '<th>Items</th>';
            html += '<th>Tipos de precio</th>';
            html += '<th>Proveedores</th>';
            html += '<th>Zonas de Competencia</th>';
            html += '<th>Sensibilidades</th>';
            html += '<th>Etiquetas</th>';
            html += '<th>Propiedades</th>';
            html += '</tr></thead><tbody>';

            angular.forEach(self.priceRulesTable, function (pr) {
                if (pr != null) {
                    html += buildRow(pr);
                }
            });    

            html += '</tbody></table>';

            var exportHref = ExportToExcel(html, 'Pagina1');
            $timeout(function () {
                location.href = exportHref;
            }, 100); // trigger download
        }

        function buildRow(priceRule) {

            var html = '';

            if (priceRule != null) {
                html += '<tr><td>' + removeDiacritics(priceRule.acceptance) +
                    '</td><td>' + removeDiacritics(priceRule.priceRuleTypeName) +
                    '</td><td>' + removeDiacritics(priceRule.priceRuleDescription) +
                    '</td><td>' + getDateToString(priceRule.validFrom) +
                    '</td><td>' + removeDiacritics(priceRule.categories.map(function (c) { return c.categoryName; }).join(',')) +
                    '</td><td>' + removeDiacritics(priceRule.items.map(function (i) { return i.itemCode + " - " + i.itemName; }).join(',')) +
                    '</td><td>' + removeDiacritics(priceRule.priceTypes.join(',')) +
                    '</td><td>' + removeDiacritics(priceRule.vendors.map(function (v) { return v.vendorName; }).join(',')) +
                    '</td><td>' + removeDiacritics(priceRule.priceLists.map(function (pl) { return pl.priceListName; }).join(',')) +
                    '</td><td>' + removeDiacritics(priceRule.itemSensitivities.map(function (i) { return i.itemSensitivityName; }).join(',')) +
                    '</td><td>' + removeDiacritics(priceRule.tags.map(function (t) { return t.tagName; }).join(',')) +
                    '</td><td>' + removeDiacritics(priceRule.itemProperties.join(',')) +
                    '</td></tr>';
            }

            return html;
        }

        function ExportToExcel(tableHtml, worksheetName) {
            var uri = 'data:application/vnd.ms-excel;base64,',
                template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
                base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
                format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

            var ctx = { worksheet: worksheetName, table: tableHtml },
                href = uri + base64(format(template, ctx));
            return href;
        }

        function getDateToString(dateOld) {
            if (dateOld != null && dateOld != "") {
                var aux = new Date(dateOld);
                var year = aux.getFullYear();
                var month = aux.getMonth() + 1;
                var day = aux.getDate();
                var newDate = year + "-" + month + "-" + day;
                return newDate;
            }
            else {
                return "";
            }
        }

        function removeDiacritics(str) {
            if (str && typeof(str) == 'string') {

                var defaultDiacriticsRemovalMap = [
                    { 'base': 'A', 'letters': /[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g },
                    { 'base': 'AA', 'letters': /[\uA732]/g },
                    { 'base': 'AE', 'letters': /[\u00C6\u01FC\u01E2]/g },
                    { 'base': 'AO', 'letters': /[\uA734]/g },
                    { 'base': 'AU', 'letters': /[\uA736]/g },
                    { 'base': 'AV', 'letters': /[\uA738\uA73A]/g },
                    { 'base': 'AY', 'letters': /[\uA73C]/g },
                    { 'base': 'B', 'letters': /[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g },
                    { 'base': 'C', 'letters': /[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g },
                    { 'base': 'D', 'letters': /[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g },
                    { 'base': 'DZ', 'letters': /[\u01F1\u01C4]/g },
                    { 'base': 'Dz', 'letters': /[\u01F2\u01C5]/g },
                    { 'base': 'E', 'letters': /[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g },
                    { 'base': 'F', 'letters': /[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g },
                    { 'base': 'G', 'letters': /[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g },
                    { 'base': 'H', 'letters': /[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g },
                    { 'base': 'I', 'letters': /[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g },
                    { 'base': 'J', 'letters': /[\u004A\u24BF\uFF2A\u0134\u0248]/g },
                    { 'base': 'K', 'letters': /[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g },
                    { 'base': 'L', 'letters': /[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g },
                    { 'base': 'LJ', 'letters': /[\u01C7]/g },
                    { 'base': 'Lj', 'letters': /[\u01C8]/g },
                    { 'base': 'M', 'letters': /[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g },
                    { 'base': 'N', 'letters': /[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g },
                    { 'base': 'NJ', 'letters': /[\u01CA]/g },
                    { 'base': 'Nj', 'letters': /[\u01CB]/g },
                    { 'base': 'O', 'letters': /[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g },
                    { 'base': 'OI', 'letters': /[\u01A2]/g },
                    { 'base': 'OO', 'letters': /[\uA74E]/g },
                    { 'base': 'OU', 'letters': /[\u0222]/g },
                    { 'base': 'P', 'letters': /[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g },
                    { 'base': 'Q', 'letters': /[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g },
                    { 'base': 'R', 'letters': /[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g },
                    { 'base': 'S', 'letters': /[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g },
                    { 'base': 'T', 'letters': /[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g },
                    { 'base': 'TZ', 'letters': /[\uA728]/g },
                    { 'base': 'U', 'letters': /[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g },
                    { 'base': 'V', 'letters': /[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g },
                    { 'base': 'VY', 'letters': /[\uA760]/g },
                    { 'base': 'W', 'letters': /[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g },
                    { 'base': 'X', 'letters': /[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g },
                    { 'base': 'Y', 'letters': /[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g },
                    { 'base': 'Z', 'letters': /[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g },
                    { 'base': 'a', 'letters': /[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g },
                    { 'base': 'aa', 'letters': /[\uA733]/g },
                    { 'base': 'ae', 'letters': /[\u00E6\u01FD\u01E3]/g },
                    { 'base': 'ao', 'letters': /[\uA735]/g },
                    { 'base': 'au', 'letters': /[\uA737]/g },
                    { 'base': 'av', 'letters': /[\uA739\uA73B]/g },
                    { 'base': 'ay', 'letters': /[\uA73D]/g },
                    { 'base': 'b', 'letters': /[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g },
                    { 'base': 'c', 'letters': /[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g },
                    { 'base': 'd', 'letters': /[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g },
                    { 'base': 'dz', 'letters': /[\u01F3\u01C6]/g },
                    { 'base': 'e', 'letters': /[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g },
                    { 'base': 'f', 'letters': /[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g },
                    { 'base': 'g', 'letters': /[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g },
                    { 'base': 'h', 'letters': /[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g },
                    { 'base': 'hv', 'letters': /[\u0195]/g },
                    { 'base': 'i', 'letters': /[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g },
                    { 'base': 'j', 'letters': /[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g },
                    { 'base': 'k', 'letters': /[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g },
                    { 'base': 'l', 'letters': /[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g },
                    { 'base': 'lj', 'letters': /[\u01C9]/g },
                    { 'base': 'm', 'letters': /[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g },
                    { 'base': 'n', 'letters': /[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g },
                    { 'base': 'nj', 'letters': /[\u01CC]/g },
                    { 'base': 'o', 'letters': /[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g },
                    { 'base': 'oi', 'letters': /[\u01A3]/g },
                    { 'base': 'ou', 'letters': /[\u0223]/g },
                    { 'base': 'oo', 'letters': /[\uA74F]/g },
                    { 'base': 'p', 'letters': /[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g },
                    { 'base': 'q', 'letters': /[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g },
                    { 'base': 'r', 'letters': /[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g },
                    { 'base': 's', 'letters': /[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g },
                    { 'base': 't', 'letters': /[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g },
                    { 'base': 'tz', 'letters': /[\uA729]/g },
                    { 'base': 'u', 'letters': /[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g },
                    { 'base': 'v', 'letters': /[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g },
                    { 'base': 'vy', 'letters': /[\uA761]/g },
                    { 'base': 'w', 'letters': /[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g },
                    { 'base': 'x', 'letters': /[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g },
                    { 'base': 'y', 'letters': /[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g },
                    { 'base': 'z', 'letters': /[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g }
                ];

                for (var i = 0; i < defaultDiacriticsRemovalMap.length; i++) {
                    str = str.replace(defaultDiacriticsRemovalMap[i].letters, defaultDiacriticsRemovalMap[i].base);
                }
            }
            else {
                str = "";
            }

        return str.trim();

    }

        self.includeSelectAll = function () {
            self.includeAllItems = !self.includeAllItems; //Invierto a lo que estaba.
            self.excludeAllItems = false; //Va siempre a false.


            self.gridOptions.api.forEachNodeAfterFilter(function (node) {
                node.data.includeItem = self.includeAllItems;
                self.includeItem(node.data, true);
            });

            self.gridOptions.api.refreshView();
        }
        self.excludeSelectAll = function () {
            self.excludeAllItems = !self.excludeAllItems; //Invierto a lo que estaba.
            self.includeAllItems = false; //Va siempre a false.


            self.gridOptions.api.forEachNodeAfterFilter(function (node) {
                node.data.excludeItem = self.excludeAllItems;
                self.includeItem(node.data, false);
            });

            self.gridOptions.api.refreshView();
        }

        //GRID FILTERS
        function isExternalFilterPresent() {
            // if excludedItem is selected or excluding SubCategory is selected, then we are filtering
            return self.priceTypes != [] || (self.filter && self.filter != '');
        }
        function doesExternalFilterPass(node) {
            var priceType = self.priceTypes.indexOf(node.data.priceTypeName) > -1;

            var priceList = (self.competitiveRule.writtenRule && isInSelectedPricelist(node.data.priceListCode, self.competitiveRule.pricelists, self.competitiveRule.expricelists)) ||
                (self.marginRule.writtenRule && isInSelectedPricelist(node.data.priceListCode, self.marginRule.pricelists, self.marginRule.expricelists)) ||
                (self.parentRule.writtenRule && isInSelectedPricelist(node.data.priceListCode, self.parentRule.pricelists, self.parentRule.expricelists)) ||
                (self.zoneRule.writtenRule && isInSelectedPricelist(node.data.priceListCode, self.zoneRule.pricelists, self.zoneRule.expricelists))

            var vendor = (self.competitiveRule.writtenRule && isInSelectedVendor(node.data.vendorCode, self.competitiveRule.vendors, self.competitiveRule.exvendors)) ||
                (self.marginRule.writtenRule && isInSelectedVendor(node.data.vendorCode, self.marginRule.vendors, self.marginRule.exvendors)) ||
                (self.parentRule.writtenRule && isInSelectedVendor(node.data.vendorCode, self.parentRule.vendors, self.parentRule.exvendors)) ||
                (self.zoneRule.writtenRule && isInSelectedVendor(node.data.vendorCode, self.zoneRule.vendors, self.zoneRule.exvendors))


            var subCategory = (self.competitiveRule.writtenRule && isInSelectedCategory(node.data.subCategory, self.competitiveRule.categories, self.competitiveRule.excategories)) ||
                (self.marginRule.writtenRule && isInSelectedCategory(node.data.subCategory, self.marginRule.categories, self.marginRule.excategories)) ||
                (self.parentRule.writtenRule && isInSelectedCategory(node.data.subCategory, self.parentRule.categories, self.parentRule.excategories)) ||
                (self.zoneRule.writtenRule && isInSelectedCategory(node.data.subCategory, self.zoneRule.categories, self.zoneRule.excategories))

            var articlesGroup = (self.competitiveRule.writtenRule && isInSelectedAlternativeCategory(node.data.alternativeCategoryCode, self.competitiveRule.articlesGroup, self.competitiveRule.exarticlesGroup)) ||
                (self.marginRule.writtenRule && isInSelectedAlternativeCategory(node.data.alternativeCategoryCode, self.marginRule.articlesGroup, self.marginRule.exarticlesGroup)) ||
                (self.parentRule.writtenRule && isInSelectedAlternativeCategory(node.data.alternativeCategoryCode, self.parentRule.articlesGroup, self.parentRule.exarticlesGroup)) ||
                (self.zoneRule.writtenRule && isInSelectedAlternativeCategory(node.data.alternativeCategoryCode, self.zoneRule.articlesGroup, self.zoneRule.exarticlesGroup))

            var sensitivities = (self.competitiveRule.writtenRule && isInSelectedSensitivity(node.data.sensitivities, self.competitiveRule.itemSensitivities, self.competitiveRule.exitemSensitivities)) ||
                (self.marginRule.writtenRule && isInSelectedSensitivity(node.data.sensitivities, self.marginRule.itemSensitivities, self.marginRule.exitemSensitivities)) ||
                (self.parentRule.writtenRule && isInSelectedSensitivity(node.data.sensitivities, self.parentRule.itemSensitivities, self.parentRule.exitemSensitivities)) ||
                (self.zoneRule.writtenRule && isInSelectedSensitivity(node.data.sensitivities, self.zoneRule.itemSensitivities, self.zoneRule.exitemSensitivities))

            var tags = (self.competitiveRule.writtenRule && isInSelectedTag(node.data.tags, self.competitiveRule.tags, self.competitiveRule.extags)) ||
                (self.marginRule.writtenRule && isInSelectedTag(node.data.tags, self.marginRule.tags, self.marginRule.extags)) ||
                (self.parentRule.writtenRule && isInSelectedTag(node.data.tags, self.parentRule.tags, self.parentRule.extags)) ||
                (self.zoneRule.writtenRule && isInSelectedTag(node.data.tags, self.zoneRule.tags, self.zoneRule.extags))

            var itemProperties = (self.competitiveRule.writtenRule && isInSelectedItemProperties(node.data, self.competitiveRule.itemproperties, self.competitiveRule.exitemproperties)) ||
                (self.marginRule.writtenRule && isInSelectedItemProperties(node.data, self.marginRule.itemproperties, self.marginRule.exitemproperties)) ||
                (self.parentRule.writtenRule && isInSelectedItemProperties(node.data, self.parentRule.itemproperties, self.parentRule.exitemproperties)) ||
                (self.zoneRule.writtenRule && isInSelectedItemProperties(node.data, self.zoneRule.itemproperties, self.zoneRule.exitemproperties))

            var itemWithUmv = { itemId: node.data.itemId, umvId: node.data.salesUnitOfMeasureId };
            var indexExc = objectIndexOf(self.excludedItems, itemWithUmv);
            var excludedItem = indexExc > -1;

            var isParentItem = !(self.parentRule.writtenRule && node.data.itemId == self.parentRule.mainItemId && node.data.salesUnitOfMeasureId == self.parentRule.mainItemUmvId);

            if (priceType && priceList && vendor && subCategory && articlesGroup && sensitivities && tags && itemProperties && isParentItem) {

                var includedItemsInRule = self.includedItems.length > 0;
                var isIncludedAndIncludedInOtherRule = false; //Si el item que estoy incluyendo, esta excluido
                angular.forEach(self.includedItems, function (i) {
                    if (i.itemId == node.data.itemId && i.umvId == node.data.salesUnitOfMeasureId && !checkIfIsCurrentRule(node.data))
                        isIncludedAndIncludedInOtherRule = true;
                });

                //Se suman los conflictos (Por regla editada)
                if (self.isEditingCompetitiveRule && node.data.competitiveRule != undefined && node.data.competitiveRule != null) {
                    if (!excludedItem && (isIncludedAndIncludedInOtherRule || !includedItemsInRule)) //Si en la grilla, el item no esta excluido Y (el item SE INCLUYO aproposito o si hay otro item Incluido) es un conflicto
                        self.totalConflicts++;
                    else if (includedItemsInRule)
                        self.totalConflicts = 0;
                }
                else if (self.isEditingMarginRule && node.data.marginRule != undefined && node.data.marginRule != null) {
                    if (!excludedItem && (isIncludedAndIncludedInOtherRule || !includedItemsInRule)) //Si en la grilla, el item no esta excluido Y (el item SE INCLUYO aproposito o si hay otro item Incluido) es un conflicto
                        self.totalConflicts++;
                    else if (includedItemsInRule)
                        self.totalConflicts = 0;
                }
                else if (self.isEditingParentRule && node.data.parentRuleItem != undefined && node.data.parentRuleItem != null) {
                    if (!excludedItem && (isIncludedAndIncludedInOtherRule || !includedItemsInRule)) //Si en la grilla, el item no esta excluido Y (el item SE INCLUYO aproposito o si hay otro item Incluido) es un conflicto
                        self.totalConflicts++;
                    else if (includedItemsInRule)
                        self.totalConflicts = 0;
                }
                else if (self.isEditingZoneRule && node.data.parentRuleZone != undefined && node.data.parentRuleZone != null) {
                    if (!excludedItem && (isIncludedAndIncludedInOtherRule || !includedItemsInRule)) //Si en la grilla, el item no esta excluido Y (el item SE INCLUYO aproposito o si hay otro item Incluido) es un conflicto
                        self.totalConflicts++;
                    else if (includedItemsInRule)
                        self.totalConflicts = 0;
                }

                return true;
            }
            else
                return false;

            return true;
        }

        function addQuickFilterListener() {
            var eInput = document.querySelector('#searchGridInput');
            eInput.addEventListener("input", function () {
                var text = eInput.value;
                self.gridOptions.api.setQuickFilter(text);
            });
        }

        //GRID CHILDS
        function getNodeChildDetails(rowItem) {
            if (rowItem.children && rowItem.children.length > 0) {
                return {
                    group: true,
                    expanded: false,
                    // provide ag-Grid with the children of this group
                    children: rowItem.children,
                    // the key is used by the default group cellRenderer
                    //key: rowItem.subCategory
                };
            }
            else {
                return null;
            }
        }
        //Helper
        function objectIndexOf(arr, obj) {
            for (var i = 0; i < arr.length; i++) {
                if (angular.equals(arr[i], obj)) {
                    return i;
                }
            };
            return -1;
        }
        function isInSelectedPricelist(priceList, priceListList, expriceListList) {
            var rv = false;

            if (self.allPriceListsChecked) {
                if (expriceListList.length == 0) {
                    if (self.isEditingZoneRule) //Si es regla de zonas, reviso cada una porque tengo que excluir la que seleccione
                    {
                        angular.forEach(priceListList, function (pl) {
                            if (pl.priceListCode == priceList)
                                rv = true;
                        });
                    }
                    else
                        rv = true;
                }
                else {
                    rv = true
                    angular.forEach(expriceListList, function (pl) {
                        if (pl.priceListCode == priceList)
                            rv = false;
                    });
                }
            }
            else {
                angular.forEach(priceListList, function (pl) {
                    if (pl.priceListCode == priceList)
                        rv = true;
                });
            }

            return rv;
        }
        function isInSelectedVendor(vendor, vendorList, exvendorList) {
            var rv = false;

            if (self.allVendorsChecked) {
                if (exvendorList.length == 0)
                    rv = true
                else {
                    rv = true
                    angular.forEach(exvendorList, function (v) {
                        if (v.vendorCode == vendor)
                            rv = false;
                    });
                }
            }
            else {
                angular.forEach(vendorList, function (v) {
                    if (v.vendorCode == vendor)
                        rv = true;
                });
            }

            return rv;
        }
        function isInSelectedCategory(category, categoryList, excategoryList) {
            var rv = false;

            if (self.allCategoriesChecked) {
                if (excategoryList.length == 0)
                    rv = true
                else {
                    rv = true
                    angular.forEach(excategoryList, function (c) {
                        if (c.categoryName == category)
                            rv = false;
                    });
                }
            }
            else {
                angular.forEach(categoryList, function (c) {
                    if (c.categoryName == category)
                        rv = true;
                });
            }

            return rv;
        }
        function isInSelectedAlternativeCategory(alternativeCategoryCode, alternativeCategoryList, exalternativeCategoryList) {
            var rv = false;

            if (alternativeCategoryCode)
                alternativeCategoryCode = alternativeCategoryCode.trim();

            if (self.allArticlesGroupChecked) {
                if (exalternativeCategoryList.length == 0)
                    rv = true
                else {
                    rv = true
                    angular.forEach(exalternativeCategoryList, function (c) {
                        if (c.articleGroupCode == alternativeCategoryCode)
                            rv = false;
                    });
                }
            }
            else {
                angular.forEach(alternativeCategoryList, function (c) {
                    if (c.articleGroupCode == alternativeCategoryCode)
                        rv = true;
                });
            }

            return rv;
        }
        function isInSelectedSensitivity(sensitivity, sensitivities, exsensitivities) {
            var rv = false;

            if (exsensitivities.length == 0 && sensitivities.length == 0) //Sin seleccionar sensivilidades
                rv = true
            else if (exsensitivities.length == 0 && sensitivities.length != 0) { //Sensivilidades a Incluir
                angular.forEach(sensitivities, function (s) {
                    if (sensitivity != null && sensitivity != undefined && sensitivity.toLowerCase().indexOf(s.itemSensitivityName.toLowerCase()) > -1)
                        rv = true
                });
            }
            else if (exsensitivities.length != 0 && sensitivities.length == 0) { //Sensivilidades a Excluir
                rv = true
                angular.forEach(exsensitivities, function (s) {
                    if (sensitivity != null && sensitivity != undefined && sensitivity.toLowerCase().indexOf(s.itemSensitivityName.toLowerCase()) > -1)
                        rv = false
                });
            }

            return rv;
        }
        function isInSelectedTag(tag, tags, extags) {
            var rv = false;

            if (extags.length == 0 && tags.length == 0) //Sin filtro de Tags
                rv = true
            else if (extags.length == 0 && tags.length != 0) { //Tags a Incluir
                if (tag != null && tag != undefined) {
                    angular.forEach(tags, function (t) {
                        var splittedTags = tag.split(",");
                        angular.forEach(splittedTags, function (st) {
                            if (st.toLowerCase().trim().indexOf(t.tagName.toLowerCase()) > -1)
                                rv = true
                        });
                    });
                }
            }
            else if (extags.length != 0 && tags.length == 0) { //Tags a Excluir
                rv = true
                if (tag != null && tag != undefined) {
                    angular.forEach(extags, function (t) {
                        var splittedTags = tag.split(",");
                        angular.forEach(splittedTags, function (st) {
                            if (st.toLowerCase().trim().indexOf(t.tagName.toLowerCase()) > -1)
                                rv = false
                        });
                    });
                }
            }

            return rv;
        }
        function isInSelectedItemProperties(data, itemProperties, exitemProperties) {
            var rv = false;

            if (exitemProperties.length == 0 && itemProperties.length == 0) //Sin seleccionar ItemProperties
                rv = true
            else if (exitemProperties.length != 0 && itemProperties.length == 0) { //ItemProperties a Excluir
                rv = true
                angular.forEach(exitemProperties, function (p) {
                    if ((data.manufacturer && data.manufacturer.toLowerCase().indexOf(p.value.toLowerCase()) > -1) || (data.brand && data.brand.toLowerCase().indexOf(p.value.toLowerCase()) > -1) || (data.description && data.description.toLowerCase().indexOf(p.value.toLowerCase()) > -1))
                        rv = false
                });
            }
            else if (exitemProperties.length == 0 && itemProperties.length != 0) { //ItemProperties a Incluir
                angular.forEach(itemProperties, function (p) {
                    if ((data.manufacturer && data.manufacturer.toLowerCase().indexOf(p.value.toLowerCase()) > -1) || (data.brand && data.brand.toLowerCase().indexOf(p.value.toLowerCase()) > -1) || (data.description && data.description.toLowerCase().indexOf(p.value.toLowerCase()) > -1))
                        rv = true
                });
            }

            return rv;
        }

        //Ambito de Aplicacion Sensibilidades y Tags
        function setItemSensitivities(sensitivities) {
            self.itemSensitivitiesToInclude = angular.copy(sensitivities);
            self.itemSensitivitiesToExclude = angular.copy(sensitivities);

            //Seteo las sensivilidades como excluidas
            _.forEach(self.itemSensitivitiesToExclude, function (s, key) {
                s.exclude = true;
            });
        }
        function setItemTags(tags) {
            self.itemTagsToInclude = angular.copy(tags);
            self.itemTagsToExclude = angular.copy(tags);

            //Seteo los tags como excluidas
            _.forEach(self.itemTagsToExclude, function (t, key) {
                t.exclude = true;
            });
        }

        //Get data from services
        function loadRules() {
            priceService.rules.getItemRules($stateParams.categoryId).then(function (rules) {

                addQuickFilterListener();
                self.rules = rules;
                self.gridOptions.api.setRowData(self.rules);
                self.mapItemExcludeToGrid();
                self.gridOptions.api.refreshView();

                self.parents = [];

                for (var i = 0; i < rules.length; i++) {
                    if (rules[i].itemId != 0) {
                        var parent = { id: rules[i].itemId, name: rules[i].description, code: rules[i].code };
                        self.parents.push(parent);
                    }
                }

                self.excludedItemId = null;
                self.gridOptions.api.onFilterChanged();
                //self.gridOptions.api.sizeColumnsToFit();

            });
        }
        function loadExistingRules() {
            priceService.rules.getExistingRules($stateParams.categoryId).then(function (rules) {
                self.existingRules = rules;
                for (i = 0; i < rules.length; i++) {
                    if (rules[i].type == 2)
                        self.existingCompetitiveRules.push(rules[i]);
                    else if (rules[i].type == 3) {
                        self.existingParentRules.push(rules[i]);
                    }
                }
            });
        }

        function loadParents() {
            priceService.rules.getParents().then(function (parentItems) {
                for (var i = 0; i < parentItems.length; i++) {
                    for (var j = 0; j < self.parents.length; j++) {
                        if (self.parents[j].id == parentItems[i].id) {
                            var index = self.parents.indexOf(self.parents[j]);
                            if (index > -1)
                                self.parents.splice(index, 1);
                        }
                    }
                }
            });
        }

        self.isLoadingCompetitorGroupParents = false;
        function loadCompetitorGroupsParents() {
            self.isLoadingCompetitorGroupParents = true;
            priceService.competitors.getCompetitorGroupsParent().then(function (compGroupsParents) {
                self.competitorGroupsParents = compGroupsParents;
                self.isLoadingCompetitorGroupParents = false;
            })
        }

        self.isLoadingCompetitorGroups = false;
        function loadCompetitorGroups() {
            self.isLoadingCompetitorGroups = true;
            priceService.competitors.getCompetitorGroups().then(function (compGroups) {
                self.competitorGroups = angular.copy(compGroups);
                self.competitorGroupsFilteredByParent = angular.copy(compGroups);
                self.isLoadingCompetitorGroups = false;
            })
        }

        self.isLoadingCompetitors = false;
        function loadCompetitors(compGroupId) {
            self.isLoadingCompetitors = true;
            priceService.competitors.getCompetitors(compGroupId).then(function (competitors) {
                self.competitors = competitors;
                self.isLoadingCompetitors = false;
            })
        }

        function loadPriceLists() {
            if (self.pricelists.length == 0) {
                priceService.rules.getPriceRulePriceLists().then(function (priceLists) {
                    self.pricelists = angular.copy(priceLists);
                    self.competitiveRule.pricelists = angular.copy(priceLists);
                    self.marginRule.pricelists = angular.copy(priceLists);
                    self.parentRule.pricelists = angular.copy(priceLists);
                    self.zoneRule.pricelists = angular.copy(priceLists);
                    self.zonePricelist = angular.copy(priceLists);
                });
            }
            else {
                self.competitiveRule.pricelists = angular.copy(self.pricelists);
                self.marginRule.pricelists = angular.copy(self.pricelists);
                self.parentRule.pricelists = angular.copy(self.pricelists);
                self.zoneRule.pricelists = angular.copy(self.pricelists);
                self.zonePricelist = angular.copy(self.pricelists);
            }
        }
        function loadVendors() {
            if (self.vendors.length == 0) {
                priceService.rules.getPriceRuleVendors($stateParams.categoryId).then(function (vendors) {
                    self.vendors = angular.copy(vendors);
                    self.competitiveRule.vendors = angular.copy(vendors);
                    self.marginRule.vendors = angular.copy(vendors);
                    self.parentRule.vendors = angular.copy(vendors);
                    self.zoneRule.vendors = angular.copy(vendors);
                });
            }
            else {
                self.competitiveRule.vendors = angular.copy(self.vendors);
                self.marginRule.vendors = angular.copy(self.vendors);
                self.parentRule.vendors = angular.copy(self.vendors);
                self.zoneRule.vendors = angular.copy(self.vendors);
            }
        }
        function loadCategories() {
            if (self.categories.length == 0) {
                priceService.rules.getSubCategoriesById($stateParams.categoryId).then(function (categories) {
                    self.categories = angular.copy(categories);
                    self.parentRule.categories = angular.copy(categories);
                    self.competitiveRule.categories = angular.copy(categories);
                    self.marginRule.categories = angular.copy(categories);
                    self.zoneRule.categories = angular.copy(categories);
                });
            }
            else {
                self.parentRule.categories = angular.copy(self.categories);
                self.competitiveRule.categories = angular.copy(self.categories);
                self.marginRule.categories = angular.copy(self.categories);
                self.zoneRule.categories = angular.copy(self.categories);
            }
        }
        function loadArticlesGroup() {
            if (self.allArticlesGroup.length == 0) {
                priceService.rules.getArticlesGroup($stateParams.categoryId).then(function (articlesGroup) {
                    self.allArticlesGroup = angular.copy(articlesGroup);
                    self.articlesGroup = angular.copy(articlesGroup);
                    self.parentRule.articlesGroup = angular.copy(articlesGroup);
                    self.competitiveRule.articlesGroup = angular.copy(articlesGroup);
                    self.marginRule.articlesGroup = angular.copy(articlesGroup);
                    self.zoneRule.articlesGroup = angular.copy(articlesGroup);
                });
            }
            else {
                self.articlesGroup = angular.copy(self.allArticlesGroup);
                self.parentRule.articlesGroup = angular.copy(self.allArticlesGroup);
                self.competitiveRule.articlesGroup = angular.copy(self.allArticlesGroup);
                self.marginRule.articlesGroup = angular.copy(self.allArticlesGroup);
                self.zoneRule.articlesGroup = angular.copy(self.allArticlesGroup);
            }
        }
        function loadTags() {
            if (self.tags.length == 0) {
                priceService.rules.getPriceRuleTags(parseInt($stateParams.categoryId)).then(function (tags) {
                    self.tags = tags;
                    setItemTags(self.tags);
                });
            }
            else {
                setItemTags(self.tags);
            }
        }
        function loadItemSensitivities() {
            if (self.itemSensitivities.length == 0) {
                priceService.rules.getItemSensitivities().then(function (sensitivities) {
                    self.itemSensitivities = sensitivities;
                    setItemSensitivities(self.itemSensitivities);
                });
            }
            else {
                setItemSensitivities(self.itemSensitivities);
            }
        }

        //Seteo el titulo tipo Breadcrumb
        function getDataFromCategorySummary() {
            var categoryGroup = decodeURIComponent($stateParams.categoryGroup.replace(/-/g, " ").replace(/_/g, "\/"));
            var category = decodeURIComponent($stateParams.category.replace(/-/g, " ").replace(/_/g, "\/"));
            var subcategory = decodeURIComponent($stateParams.subcategory.replace(/-/g, " ").replace(/_/g, "\/"));

            self.title = categoryGroup
            if (category != '') {
                self.title += ' / ' + category;
                if (subcategory != '') {
                    self.title += ' / ' + subcategory;
                }
            }
        }

        //Trae las reglas asociadas a la categoria o sub categorias con la que se entro para la primer pantalla.
        function getPriceRulesForCategory() {
            priceService.rules.getPriceRulesForCategory($stateParams.categoryId)
                .then(function (priceRules) {
                    self.priceRulesTable = priceRules;
                    self.isLoadingRules = false;
                });
        }

        function reload() {
            self.totalConflicts = 0;
            self.isLoadingRules = true;
            getPriceRulesForCategory();
            self.rules = [];
            loadExistingRules();
            loadRules();

            //Hace Update
            if (self.categories.length != 0) {
                loadCategories();
                loadArticlesGroup();
                loadPriceLists();
                loadVendors();
            }
        }

        function newCompetitiveRule() {
            self.competitiveRule = {
                id: null,
                competitorGroupParent: null,
                competitorGroup: null,
                competitor: null,
                onlyCompetitorsWithSamePriceList: true,
                considerModeCount: null,
                calculationType: null,
                modeType: null,
                modeFallback: null,
                differenceType: null, //1 = %  2 = $
                percentageDifference1: null,
                priceDifference1: null,
                writtenRule: null,
                pricelists: [],
                vendors: [],
                articlesGroup: [],
                priceSurveyTypes: [],
                itemSensitivities: [],
                categories: [],
                tags: [],
                itemproperties: [],
                items: [],
                expricelists: [],
                exvendors: [],
                exarticlesGroup: [],
                excategories: [],
                extags: [],
                exitemSensitivities: [],
                exitemproperties: [],
            }

            //Hace Update
            if (self.categories.length != 0) {
                loadCategories();
                loadArticlesGroup();
                loadPriceLists();
                loadVendors();
            }

            self.competitiveRule.priceSurveyTypes = companyJson.PriceRules.CompetitiveRule.DefaultPriceSurveyTypes;
        }

        self.marginRuleTypeChanged = function () {
            self.marginRule.margin = null;
            self.marginRule.marginAmount = null;
            self.marginRule.marginType = 1;

            if (self.marginRule.marginRuleType == 1) {
                self.marginRule.keepMarginType = null;
            }
            else {
                self.marginRule.keepMarginType = 1;
            }
        }

        self.marginTypeChanged = function () {
            self.marginRule.margin = null;
            self.marginRule.marginAmount = null;
        }

        function newMarginRule() {
            self.marginRule = {
                id: null,
                marginType: 1, //% o $ del Margen Objetivo
                margin: null, //Valor de Margen Objetivo en %
                marginAmount: null, //Valor de Margen Objetivo en $
                marginRuleType: 1,
                writtenRule: null,
                pricelists: [],
                vendors: [],
                articlesGroup: [],
                itemSensitivities: [],
                categories: [],
                tags: [],
                itemproperties: [],
                items: [],
                expricelists: [],
                exvendors: [],
                exarticlesGroup: [],
                excategories: [],
                extags: [],
                exitemSensitivities: [],
                exitemproperties: [],
            }

            //Hace Update
            if (self.categories.length != 0) {
                loadCategories();
                loadArticlesGroup();
                loadPriceLists();
                loadVendors();
            }
        }

        function newParentRule() {
            self.parentRule = {
                id: null,
                parent: null,
                mainItemId: null,
                mainItemUmvId: null,
                relationshipType: null, //Price = 0;  Margin = 1;  DeltaPrice = 2;
                differenceType: null,  // Margin = 1;  Price = 2;
                percentageDifference2: null,
                priceDifference2: null,
                difference: 0,
                writtenRule: null,
                genericItemId: null,
                itemId: null,
                pricelists: [],
                vendors: [],
                articlesGroup: [],
                itemSensitivities: [],
                categories: [],
                tags: [],
                itemproperties: [],
                items: [],
                expricelists: [],
                exvendors: [],
                exarticlesGroup: [],
                excategories: [],
                extags: [],
                exitemSensitivities: [],
                exitemproperties: [],
            }

            //Hace Update
            if (self.categories.length != 0) {
                loadCategories();
                loadArticlesGroup();
                loadPriceLists();
                loadVendors();
            }
        }

        function newZoneRule() {
            self.zoneRule = {
                id: null,
                zone: null,
                relationshipType: null, //Price = 0;  Margin = 1;  DeltaPrice = 2;
                differenceType: null,
                percentageDifference2: null,
                priceDifference2: null,
                difference: 0,
                writtenRule: null,
                pricelists: [],
                vendors: [],
                articlesGroup: [],
                itemSensitivities: [],
                categories: [],
                tags: [],
                itemproperties: [],
                items: [],
                expricelists: [],
                exvendors: [],
                exarticlesGroup: [],
                excategories: [],
                extags: [],
                exitemSensitivities: [],
                exitemproperties: [],
            }

            //Hace Update
            if (self.categories.length != 0) {
                loadCategories();
                loadArticlesGroup();
                loadPriceLists();
                loadVendors();
            }
        }

        self.openTagsDialog = function (include) {
            self.includeTagsDialog = include;
            self.groupTagsDialog = [];
            self.tagsDialog = [];
            self.itemTagsToIncludeDialog = [];
            self.distinctGroupTags = [];
            _.forOwn(_.groupBy(self.itemTagsToInclude, "group"), function (value, key) {
                self.distinctGroupTags.push(key);
            }); 

            self.onChangeGroupTagsDialog = function () {
                self.itemTagsToIncludeDialog = self.itemTagsToInclude.filter(function (t) {
                    return self.groupTagsDialog.some(function (g) {
                        return g == t.group;
                    });
                });
            }

            self.applyDialog = function () {
                if (include) {
                    var anyTags = false;
                    self.groupTagsDialog.forEach(function (g) {
                        self.tagsDialog.forEach(function (t) {
                            if (t.group == g) {
                                anyTags = true;
                            }
                        });
                        if (!anyTags) {
                            self.itemTagsToIncludeDialog.forEach(function (itemTag) {
                                if (itemTag.group == g) {
                                    self.tagsDialog.push(itemTag);
                                }
                            });
                        }
                        anyTags = false;
                    });

                    self.tagsDialog.forEach(function (x) {
                        x.exclude = false;
                    });
                    self.marginRule.tags = self.marginRule ? self.tagsDialog : [];
                    self.parentRule.tags = self.parentRule ? self.tagsDialog : [];
                    self.zoneRule.tags = self.zoneRule ? self.tagsDialog : [];
                    self.competitiveRule.tags = self.competitiveRule ? self.tagsDialog : [];
                }
                else {
                    var anyTags = false;
                    self.groupTagsDialog.forEach(function (g) {
                        self.tagsDialog.forEach(function (t) {
                            if (t.group == g) {
                                anyTags = true;
                            }
                        });
                        if (!anyTags) {
                            self.itemTagsToIncludeDialog.forEach(function (itemTag) {
                                if (itemTag.group == g) {
                                    self.tagsDialog.push(itemTag);
                                }
                            });
                        }
                        anyTags = false;
                    });

                    self.tagsDialog.forEach(function (x) {
                        x.exclude = true;
                    });
                    self.marginRule.extags = self.marginRule ? self.tagsDialog : [];
                    self.parentRule.extags = self.parentRule ? self.tagsDialog : [];
                    self.zoneRule.extags = self.zoneRule ? self.tagsDialog : [];
                    self.competitiveRule.extags = self.competitiveRule ? self.tagsDialog : [];

                }
                ngDialog.close();
            }

            if (include) {
                if (self.marginRule) {
                    self.groupTagsDialog = self.marginRule.tags.map(function (x) {
                        return x.group;
                    });
                    self.tagsDialog = self.marginRule.tags;
                }
                else if (self.parentRule) {
                    self.groupTagsDialog = self.parentRule.tags.map(function (x) {
                        return x.group;
                    });
                    self.tagsDialog = self.parentRule.tags;
                }
                else if (self.zoneRule) {
                    self.groupTagsDialog = self.zoneRule.tags.map(function (x) {
                        return x.group;
                    });
                    self.tagsDialog = self.zoneRule.tags;
                }
                else if (self.competitiveRule) {
                    self.groupTagsDialog = self.competitiveRule.tags.map(function (x) {
                        return x.group;
                    });
                    self.tagsDialog = self.competitiveRule.tags;
                }
            }
            else {
                if (self.marginRule) {
                    self.groupTagsDialog = self.marginRule.extags.map(function (x) {
                        return x.group;
                    });
                    self.tagsDialog = self.marginRule.extags;
                }
                else if (self.parentRule) {
                    self.groupTagsDialog = self.parentRule.extags.map(function (x) {
                        return x.group;
                    });
                    self.tagsDialog = self.parentRule.extags;
                }
                else if (self.zoneRule) {
                    self.groupTagsDialog = self.zoneRule.extags.map(function (x) {
                        return x.group;
                    });
                    self.tagsDialog = self.zoneRule.extags;
                }
                else if (self.competitiveRule) {
                    self.groupTagsDialog = self.competitiveRule.extags.map(function (x) {
                        return x.group;
                    });
                    self.tagsDialog = self.competitiveRule.extags;
                }
            }
            self.onChangeGroupTagsDialog();

            ngDialog.open({
                template: 'tagsDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-height-500 custom-width-1124',
                backdrop: 'static',
                keyboard: false,
                closeByDocument: false,
                scope: $scope
            });
        }

        function init() {

            setGridOptions();

            priceService.getPriceSurveyTypes().then(
                function (priceSurveyTypes) {
                    self.priceSurveyTypes = priceSurveyTypes;
                });

            $translate(["NewSuggestionRule", "NewParentRule", "Competitive", "Margin", "Products", "PriceZone", "Minimum", "Average", "Maximum", "Mode"
                , "LowestModePrice", "AverageModePrice", "HighestModePrice", "HighestRepetitions", "LowestPrice", "AveragePrice", "HighestPrice"
                , "Promotion", "Basket", "Price", "PriceVariation", "SwalReviewCategoryPriceRules", "Continue", "Cancel", "Delete"
                , "RequestQueued", "RequestQueuedInfo", "TheProcessContinues", "TheProcessContinuesReviewCategoryInfo", "AnErrorOccurredReviewingCategory"
                , "AreYouSureGeneratePriceSuggestions", "TheProcessContinuesGeneratePriceSuggestionsInfo", "AnErrorOccurredGeneratingSuggestions", "AreYouSureDeletePriceRule"
                , "DeletedRule", "DeletedRuleInfo", "TheProcessContinuesInfo", "AnErrorDeletingRule", "CompetitiveWrittenRule1", "IfMoreThanOneMode", "IfMoreThanOneModeWithSameReps", "OnlyCompetitorsInSamePriceZone", "SamePrice", "DifferenceOf"
                , "AreYouSureSwal", "of", "AreYouSureSwalSubtitle", "ErrorTitleSwal", "ConfirmedSwal", "ConfirmedSwalSubtitle", "ErrorSwalSubtitle"
                , "KeepSameMarginPercentage", "KeepSameMargin$", "TheRuleSwal", "AssociatedWithGenericSwal", "ThereCanOnlyBe1RulePerGenericSwal"
                , "NoApplicationScopeOrItemsWereAddedSwal", "NoApplicationScopeOrItemsWereAddedSwalSubtitle", "ContinueSwal", "ActionsAgGrid", "Include", "Exclude"
                , "GeneralInformation", "Subcategory", "GroupOfArticles", "Code", "Description", "PriceZone", "Supplier", "TypeOfPrice", "Brand", "Maker", "Tags", "Sensitivities", "PricesAndCosts", "CurrentPrice", "CurrentCost", "PercentajeMargin", "SalesUn"
                , "Rules", "CompetitiveF", "Margin", "RelationshipItems", "KinshipZones", "RULE"])
                .then(function (all) {
                    translations = all;

                    self.dropDrownNewSuggestionRuleText = translations.NewSuggestionRule;
                    self.dropDrownNewParentRuleText = translations.NewParentRule;
                    self.suggestionRuleTypes = [{ id: 1, name: translations.Competitive }, { id: 2, name: translations.Margin }];
                    self.parentRuleTypes = [{ id: 3, name: translations.Products }, { id: 4, name: translations.PriceZone }];
                    self.calculationTypes = [{ id: 0, name: translations.Minimum }, { id: 1, name: translations.Average }, { id: 2, name: translations.Maximum }, { id: 3, name: translations.Mode }];
                    self.modeFallbacks = [{ id: 0, name: translations.LowestModePrice }, { id: 1, name: translations.AveragePrice }, { id: 2, name: translations.HighestPrice }];
                    self.modeTypes = [{ id: 0, name: translations.HighestRepetitions }, { id: 1, name: translations.LowestPrice }, { id: 2, name: translations.AveragePrice }, { id: 3, name: translations.HighestPrice }];

                    self.relationshipTypes = [{ id: 0, name: translations.Price }, { id: 1, name: translations.Margin }, { id: 2, name: translations.PriceVariation }];

                    setColumnDefinitions();

                    self.gridOptions.api.setColumnDefs(columnDefs);
                });

            //load data First Time
            getDataFromCategorySummary();
            getPriceRulesForCategory();
            loadRules();
            loadExistingRules();
            loadParents();

            //load data once
            loadCategories();
            loadPriceLists();
            loadVendors();
            loadArticlesGroup();
            loadItemSensitivities();
            loadTags();

            //Inicializo los Tipos de Reglas en Blanco
            newCompetitiveRule();
            newMarginRule();
            newParentRule();
            newZoneRule();

            self.showGenericItem = companyJson.PriceRules.ParentRuleItem.ShowGenericItem;
        }

        init();
    });
