angular.module("prisma")
    .controller("AssortmentRulesCtrl", function ($scope, $rootScope, $stateParams, $filter, $timeout, assortmentService, queueService, $translate) {

        var self = this;
        var columnDefs = null;

        self.isEditingListingRule = false;
        self.isEditingDelistingRule = false;

      //general
      self.isSaving = false;
      self.selectedCategory = $stateParams.category;
      self.categories = [];
      self.clusters = [];
      self.tags = [];
      self.excludedTags = [];
      self.allClustersChecked = true;
      self.allTagsChecked = true;
      self.allCategoriesChecked = true;
      self.includedItemProperties = "";
      self.excludedItemProperties = "";
      self.itemproperties = { assortmentRuleId: 0, value: "", exclude: false };
      self.exitemproperties = { assortmentRuleId: 0, value: "", exclude: true };
      self.brandCoverages = [];
      self.isCoverageRule = false;
      self.itemManufacturers = [];
      self.itemBrands = [];
      self.selectedParetoFilterItemBrands = [];
      self.selectedParetoFilterItemManufacturers = [];
      self.selectedParetoFilterItemTags = [];

        self.cancel = function () {
            self.isEditingListingRule = false;
            self.isEditingDelistingRule = false;
            self.gridOptions.api.onFilterChanged();

            self.listingRule = {
                writtenRule: "",
                categories: angular.copy(self.categories),
                excategories: [],
                tags: [],
                extags: [],
                itemProperties: [],
                exitemProperties: [],
                clusters: [],
                exclusters: [],
                conditions: []
            }
            self.delistingRule = {
                writtenRule: "",
                categories: angular.copy(self.categories),
                excategories: [],
                tags: [],
                extags: [],
                itemProperties: [],
                exitemProperties: [],
                clusters: [],
                exclusters: [],
                conditions: []
            }

          self.pendingPerformanceVariable = angular.copy(self.performanceVariable);
          self.pendingDelistingPerformanceVariable = angular.copy(self.delistingPerformanceVariable);
          self.listingCondition.condition = '';
          self.delistingCondition.condition = '';
          self.includedItemProperties = "";
          self.excludedItemProperties = "";
          self.excludedTags = [];
          self.allClustersChecked = true;
          self.allTagsChecked = true;
          self.allCategoriesChecked = true;
          self.listingRule.tags = [];
          self.delistingRule.tags = [];
          self.listingRule.clusters = self.clusters;
          self.delistingRule.clusters = self.clusters;
          self.isCoverageRule = false;
          self.listingCondition.performanceVariable = null;

          var listingParetoItemFilters = { paretoFilterItemBrand: [], paretoFilterItemManufacturer: [], paretoFilterTag: [] };
          var delistingParetoItemFilters = { paretoFilterItemBrand: [], paretoFilterItemManufacturer: [], paretoFilterTag: [] };
          self.selectedParetoFilterItemBrands = [];
          self.selectedParetoFilterItemManufacturers = [];
          self.selectedParetoFilterItemTags = [];
      }
      self.generateSuggestions = function () {

            swal({
                title: translations.GenerateSuggestionsSwal,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        var isListing = self.isEditingListingRule ? true : (self.isEditingDelistingRule ? false : null);

                        $timeout(function () {
                            swal(translations.TheProcessContinues, translations.TheSuggestionsContinueToBeGeneratedAssortment)
                        }, 1000);

                        assortmentService.suggestion.generateSuggestions(isListing, $stateParams.categoryId)
                            .then(function (isOk) {
                                swal(translations.YourRequestToGenerateSuggestionsHasBeenPasted, translations.RequestQueuedInfo, "success");

                                //actualizo las tareas pendientes.
                                //queueService.queuedTasks.getTop5();

                            }, function (status) {
                                swal(translations.ErrorTitleSwal, translations.ErrorWhenGeneratingSuggestionsForThisCategorySwal, "error");
                            });
                    }
                });
        }
        self.includeItemProperty = function (rule) {
            rule.itemProperties.push(angular.copy(self.itemproperties));
            if (self.includedItemProperties == "" && self.itemproperties.value != '')
                self.includedItemProperties += self.itemproperties.value;
            else if (self.itemproperties.value != '')
                self.includedItemProperties += ", " + self.itemproperties.value;

            self.itemproperties.value = "";
        }
        self.excludeItemProperty = function (rule) {
            rule.itemProperties.push(angular.copy(self.exitemproperties));
            if (self.excludedItemProperties == "" && self.exitemproperties.value != '')
                self.excludedItemProperties += self.exitemproperties.value;
            else if (self.exitemproperties.value != '')
                self.excludedItemProperties += ", " + self.exitemproperties.value;

            self.exitemproperties.value = "";
        }
        self.addExcluded = function (rule) {

            //Agregar Clusters Excluidos
            for (var i = 0; i < rule.exclusters.length; i++) {
                for (var j = 0; j < rule.clusters.length; j++) {
                    if (rule.exclusters[i].clusterId == rule.clusters[j].clusterId)
                        rule.clusters[j].exclude = true;
                }
            }

            //Agregar Tags Excluidos
            for (var i = 0; i < rule.extags.length; i++) {
                rule.extags[i].exclude = true;
                rule.tags.push(rule.extags[i]);
            }

            ////Agregar Categorias Excluidas
            //for (var i = 0; i < rule.excategories.length; i++) {
            //    for (var j = 0; j < rule.categories.length; j++) {
            //        if (rule.excategories[i].categoryId == rule.categories[j].categoryId)
            //            rule.categories[j].exclude = true;
            //    }
            //}

        }
        self.selectAllClustersForRule = function (rule) {
            if (self.allClustersChecked)
                rule.clusters = self.clusters;
            else
                rule.clusters = [];
        }
        self.selectAllTagsForRule = function (rule) {
            //Si tildo todos no pongo ninguno para no filtrar, si lo destildo, tambi�n saco todos para que incluya o excluya unicamente.
            if (self.allTagsChecked) {
                rule.tags = [];
                rule.extags = [];
            }
            else {
                rule.tags = [];
                rule.extags = [];
            }
        }
        self.selectAllCategoriesForRule = function (rule) {
            if (self.allCategoriesChecked)
                rule.categories = self.categories;
            else
                rule.categories = [];
        }
        self.deleteRule = function (ruleId) {
            swal({
                title: translations.RemoveRuleSwal,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        assortmentService.rules.deleteRule(ruleId)
                            .then(function () {
                                swal(translations.RuleEliminatedSwal, translations.TheRuleHasBeenSuccessfullyRemovedSwal, "success");
                                loadRules();
                            },
                            function errorCallback(response) {
                                swal(translations.ErrorTitleSwal, translations.ErrorWhenDeletingTheRule, "error");
                                console.log(response);
                            });
                    }
                });
        }

        //LISTING
        self.listingRule = {
            writtenRule: "",
            categories: [],
            excategories: [],
            tags: [],
            extags: [],
            //items: [],
            itemProperties: [],
            exitemProperties: [],
            clusters: [],
            exclusters: [],
            conditions: []
        }
        
          
      self.pendingPerformanceVariable = angular.copy(self.performanceVariable);
      self.listingCondition = {
          condition: '', // (0 First)  (1 Or)  (2 And)
          filterCondition: 'menor', // (< 1)  (> 2)  (= 3)
          performanceVariable: null,
          performanceVariableValue: null
      }
      self.leftSignVisible = false;
      self.leftSignText = '$';
      self.rightSignVisible = true;
      self.rightSignText = '%';
      self.isSpaceListingRule = false;


      $scope.$watch('ct.listingRule', function (newVal, oldVal) {

          if (self.listingRule.conditions.length > 0) {
              var writtenRule = "<strong>REGLA: </strong> Alta de productos";

              _.forEach(self.listingRule.conditions, function (c, key) {
                  writtenRule += ian.format(' <strong>{0}</strong> donde {1} sea {2} ', c.condition, c.performanceVariable.label, c.filterCondition);

                  if (c.filterCondition == 'igual') {
                      if (c.performanceVariable.label == 'Espacios') { //Regla de espacios:
                          writtenRule = writtenRule.substring(0, writtenRule.indexOf("Espacios")) + "Se cumpla el lineamiento de espacios";
                      }
                      else if (c.performanceVariable.label == 'Cobertura') { //Regla de cobertura:
                          self.isCoverageRule = true;

                          writtenRule += 'a ' + c.performanceVariableValue + c.performanceVariable.sign.text;
                          //writtenRule = writtenRule.substring(0, writtenRule.indexOf("Cobertura")) + "Se cumpla la cobertura por cadena";
                      }
                      else if (c.performanceVariable.sign.text == '$')
                          writtenRule += 'a ' + c.performanceVariable.sign.text + c.performanceVariableValue;
                      else
                          writtenRule += 'a ' + c.performanceVariableValue + c.performanceVariable.sign.text;
                  }
                  else {
                      if (c.performanceVariable.label == 'Espacios') { //Regla de espacios:
                          writtenRule = writtenRule.substring(0, writtenRule.indexOf("Espacios")) + "Se cumpla el lineamiento de espacios";
                      }
                      else if (c.performanceVariable.label == 'Cobertura') { //Regla de cobertura:
                          self.isCoverageRule = true;

                          writtenRule += 'que ' + c.performanceVariableValue + c.performanceVariable.sign.text;
                          //writtenRule = writtenRule.substring(0, writtenRule.indexOf("Cobertura")) + "Se cumpla la cobertura por cadena";
                      }
                      else if (c.performanceVariable.sign.text == '$')
                          writtenRule += 'que ' + c.performanceVariable.sign.text + c.performanceVariableValue;
                      else
                          writtenRule += 'que ' + c.performanceVariableValue + c.performanceVariable.sign.text;
                  }
              });

              self.listingRule.writtenRule = writtenRule;
          }

      }, true);
      self.newListingRule = function () {
          self.isEditingListingRule = true;
          self.isEditingDelistingRule = false;
          self.gridOptions.api.onFilterChanged();
      }
      self.shouldShowListing = function (performanceVariable) {
          if (performanceVariable != null) {
              show = true;

              _.forEach(self.listingRule.conditions, function (c, key) {
                  if (c.performanceVariable.id == performanceVariable.id)
                      show = false;
              });
              return show;
          }
      }
      self.changePerformanceVariable = function () {
          var s = self.listingCondition.performanceVariable;

          self.isSpaceListingRule = false;
          self.isCoverageRule = false;

          if (s.label == "Espacios")
              self.isSpaceListingRule = true;
          if (s.label == "Cobertura")
              self.isCoverageRule = true;

          if (s.sign.position === 'left') {
              self.rightSignVisible = false;
              self.leftSignVisible = true;
              self.leftSignText = s.sign.text;
          }
          else {
              self.leftSignVisible = false;
              self.rightSignVisible = true;
              self.rightSignText = s.sign.text;
          }
      }
      self.addListingCondition = function () {

          //add condition to rule
          self.listingRule.conditions.push(angular.copy(self.listingCondition));

          //remove used variable 
          _.remove(self.pendingPerformanceVariable, function (variable) {
              return variable.id == self.listingCondition.performanceVariable.id
          });

          //clear conditions
          self.listingCondition.performanceVariable = null;
          self.listingCondition.performanceVariableValue = null;
          self.listingCondition.filterCondition = 'mayor'; //Inicializo en Mayor para que ya tenga uno seleccionado
          if (self.listingRule.conditions.length == 1) //Inicializo en Y para que ya tengan uno seleccionado.
              self.listingCondition.condition = 'Y';
      }
      self.canSaveListingRule = function () {
          var canSave = false;

          if (self.listingRule.conditions.length > 0) {
              if (self.listingRule.clusters.length > 0 && self.listingRule.categories.length > 0) {
                  canSave = true;
              }
          }

          return canSave;
      }
      self.saveListingRule = function () {

          swal({
              title: translations.ConfirmationCreateThisRule,
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#1AB394",
              confirmButtonText: translations.ContinueSwal,
              cancelButtonText: translations.CancelSwal,
              showLoaderOnConfirm: true,
              closeOnConfirm: false,
              closeOnCancel: true
          },
          function (isConfirm) {
              if (isConfirm) {

                  if (!self.isSaving) {

                      self.isSaving = true;

                      self.addExcluded(self.listingRule);

                      var listingConditionsDto = [];
                      _.forEach(self.listingRule.conditions, function (c, key) {
                          var conditionDto = {
                              assortmentRuleId: 0,
                              condition: c.condition == "" ? 0 : c.condition == "O" ? 1 : 2,
                              filterCondition: c.filterCondition == 'menor' ? 1 : c.filterCondition == "mayor" ? 2 : 3,
                              performanceVariable: c.performanceVariable.id,
                              performanceVariableValue: c.performanceVariable.sign.text == '-' ? 0 : c.performanceVariable.sign.text == '%' ? c.performanceVariableValue / 100 : c.performanceVariableValue
                          };

                          listingConditionsDto.push(conditionDto);
                      });

                      listingParetoItemFilters = {
                          paretoFilterItemBrand: self.selectedParetoFilterItemBrands
                          , paretoFilterItemManufacturer: self.selectedParetoFilterItemManufacturers
                          , paretoFilterTag: self.selectedParetoFilterItemTags
                      }

                      var assortmentRuleListingDto = {
                          id: null,
                          name: self.listingRule.writtenRule,
                          companyId: $rootScope.companyId,
                          categories: self.listingRule.categories,
                          clusters: self.listingRule.clusters,
                          tags: self.listingRule.tags,
                          itemProperties: self.listingRule.itemProperties,
                          assortmentRuleConditions: listingConditionsDto,
                          paretoItemFilters: listingParetoItemFilters
                      }

                      assortmentService.rules.saveListingRule(assortmentRuleListingDto)
                      .then(function (savedOk) {
                          self.cancel();
                          loadRules();
                          self.isSaving = false;
                          swal(translations.SavedRule, translations.SavedRuleSubtitle, "success");
                      }, function (fallback) {
                          swal(translations.ErrorTitleSwal, translations.ErrorWhenSavingTheRule, "error");
                          self.isSaving = false;
                      });
                  }
              }
          });
      }

      //DE-LISTING
      self.delistingRule = {
          writtenRule: "",
          categories: [],
          excategories: [],
          tags: [],
          extags: [],
          //items: [],
          itemProperties: [],
          exitemProperties: [],
          clusters: [],
          exclusters: [],
          conditions: []
      }
      
      self.pendingDelistingPerformanceVariable = angular.copy(self.delistingPerformanceVariable);
      self.delistingCondition = {
          condition: '', // (0 First)  (1 Or)  (2 And)
          filterCondition: 'menor', // (< 1)  (> 2)  (= 3)
          performanceVariable: null,
          performanceVariableValue: null
      }
      self.leftSignDelistingVisible = false;
      self.leftSignDelistingText = '$';
      self.rightSignDelistingVisible = true;
      self.rightSignDelistingText = '%';
      self.isSpaceDelistingRule = false;
      $scope.$watch('ct.delistingRule', function (newVal, oldVal) {

          if (self.delistingRule.conditions.length > 0) {
              var writtenRule = "<strong>REGLA: </strong> Baja de productos";

              _.forEach(self.delistingRule.conditions, function (c, key) {
                  writtenRule += ian.format(' <strong>{0}</strong> donde {1} sea {2} ', c.condition, c.performanceVariable.label, c.filterCondition);

                  if (c.filterCondition == 'igual') {
                      if (c.performanceVariable.label == 'Espacios') { //Regla de espacios:
                          writtenRule = writtenRule.substring(0, writtenRule.indexOf("Espacios")) + "Se cumpla el lineamiento de espacios";
                      }
                      else if (c.performanceVariable.sign.text == '$')
                          writtenRule += 'a ' + c.performanceVariable.sign.text + c.performanceVariableValue;
                      else
                          writtenRule += 'a ' + c.performanceVariableValue + c.performanceVariable.sign.text;
                  }
                  else {
                      if (c.performanceVariable.label == 'Espacios') { //Regla de espacios:
                          writtenRule = writtenRule.substring(0, writtenRule.indexOf("Espacios")) + "Se cumpla el lineamiento de espacios";
                      }
                      else if (c.performanceVariable.sign.text == '$')
                          writtenRule += 'que ' + c.performanceVariable.sign.text + c.performanceVariableValue;
                      else
                          writtenRule += 'que ' + c.performanceVariableValue + c.performanceVariable.sign.text;
                  }
              });

              self.delistingRule.writtenRule = writtenRule;
          }

      }, true);
      self.newDelistingRule = function () {
          self.isEditingListingRule = false;
          self.isEditingDelistingRule = true;
          self.gridOptions.api.onFilterChanged();
      }
      self.shouldShowDelisting = function (performanceVariable) {
          if (performanceVariable != null) {
              show = true;

              _.forEach(self.delistingRule.conditions, function (c, key) {
                  if (c.delistingPerformanceVariable.id == delistingPerformanceVariable.id)
                      show = false;
              });
              return show;
          }
      }
      self.changeDelistingPerformanceVariable = function () {
          var s = self.delistingCondition.performanceVariable;

          self.isSpaceListingRule = false;
          self.isCoverageRule = false;

          if (s.label == "Espacios")
              self.isSpaceListingRule = true;
          if (s.label == "Cobertura")
              self.isCoverageRule = true;

          if (s.sign.position === 'left') {
              self.rightSignDelistingVisible = false;
              self.leftSignDelistingVisible = true;
              self.leftSignDelistingText = s.sign.text;
          }
          else {
              self.leftSignDelistingVisible = false;
              self.rightSignDelistingVisible = true;
              self.rightSignDelistingText = s.sign.text;
          }
      }
      self.addDelistingCondition = function () {

          //add condition to rule
          self.delistingRule.conditions.push(angular.copy(self.delistingCondition));

          //remove used variable
          _.remove(self.pendingDelistingPerformanceVariable, function (variable) {
              return variable.id == self.delistingCondition.performanceVariable.id
          });

          //clear conditions
          self.delistingCondition.performanceVariable = null;
          self.delistingCondition.performanceVariableValue = null;
          self.delistingCondition.filterCondition = 'menor'; //Inicializo en Menor para que ya tenga uno seleccionado
          if (self.delistingRule.conditions.length == 1) //Inicializo en Y para que ya tengan uno seleccionado.
              self.delistingCondition.condition = 'Y';
      }
      self.canSaveDelistingRule = function () {
          var canSave = false;

          if (self.delistingRule.conditions.length > 0) {
              if (self.delistingRule.clusters.length > 0 && self.delistingRule.categories.length > 0) {
                  canSave = true;
              }
          }

          return canSave;
      }
      self.saveDelistingRule = function () {

          swal({
              title: translations.ConfirmationCreateThisRule,
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#1AB394",
              confirmButtonText: translations.ContinueSwal,
              cancelButtonText: translations.CancelSwal,
              showLoaderOnConfirm: true,
              closeOnConfirm: false,
              closeOnCancel: true
          },
         function (isConfirm) {
             if (isConfirm) {

                 if (!self.isSaving) {

                     self.isSaving = true;

                     self.addExcluded(self.delistingRule);

                     //_.forEach(self.categories, function (c, key) {
                     //    if (c.categoryId == $stateParams.categoryId)
                     //        self.delistingRule.categories.push(angular.copy(c));
                     //});

                     var delistingConditionsDto = [];
                     _.forEach(self.delistingRule.conditions, function (c, key) {
                         var conditionDto = {
                             assortmentRuleId: 0,
                             condition: c.condition == "" ? 0 : c.condition == "O" ? 1 : 2,
                             filterCondition: c.filterCondition == 'menor' ? 1 : c.filterCondition == "mayor" ? 2 : 3,
                             performanceVariable: c.performanceVariable.id,
                             performanceVariableValue: c.performanceVariable.sign.text == '-' ? 0 : c.performanceVariable.sign.text == '%' ? c.performanceVariableValue / 100 : c.performanceVariableValue
                         };

                         delistingConditionsDto.push(conditionDto);
                     });

                     delistingParetoItemFilters = {
                         paretoFilterItemBrand: self.selectedParetoFilterItemBrands
                         , paretoFilterItemManufacturer: self.selectedParetoFilterItemManufacturers
                         , paretoFilterTag: self.selectedParetoFilterItemTags
                     }

                     var assortmentRuleDelistingDto = {
                         id: null,
                         name: self.delistingRule.writtenRule,
                         companyId: $rootScope.companyId,
                         categories: self.delistingRule.categories,
                         clusters: self.delistingRule.clusters,
                         tags: self.delistingRule.tags,
                         itemProperties: self.delistingRule.itemProperties,
                         assortmentRuleConditions: delistingConditionsDto,
                         paretoItemFilters: delistingParetoItemFilters
                     }

                     assortmentService.rules.saveDelistingRule(assortmentRuleDelistingDto)
                     .then(function (savedOk) {
                         if (savedOk) {
                             self.cancel();
                             loadRules();
                             swal(translations.SavedRule, translations.SavedRuleSubtitle, "success");
                             self.isSaving = false;
                         }
                     },
                     function () {
                         swal(translations.ErrorTitleSwal, translations.ErrorWhenSavingTheRule, "error");
                         self.isSaving = false;
                     });
                 }
             }
         });
      }

      //grid
      function setColumnDefinitions() {
      columnDefs = [
              {
              headerName: translations.Associations,
                  children: [
                  {
                          headerName: translations.Categories,
                      field: "categories",
                      cellRenderer: function (params) {
                          return params.value ? params.value : "";
                      },
                      width: 200,
                  },
                  {
                      headerName: translations.Clusters,
                      field: "clusters",
                      cellRenderer: function (params) {
                          return params.value ? params.value : "";
                      },
                      width: 200,
                      columnGroupShow: 'open',
                  },
                  {
                      headerName: translations.Tags,
                      field: "tags",
                      cellRenderer: function (params) {
                          return params.value ? params.value : "";
                      },
                      width: 100,
                      columnGroupShow: 'open',
                  },
                  {
                      headerName: translations.Values,
                      field: "itemProperties",
                      cellRenderer: function (params) {
                          return params.value ? params.value : "";
                      },
                      width: 100,
                      columnGroupShow: 'open',
                  }]
          },
              {
                  headerName: "Filtros",
                  children: [
                      {
                          headerName: "Marcas",
                          field: "paretoFilterItemBrand",
                          cellRenderer: function (params) {
                              return params.value ? params.value : "";
                          },
                          width: 200,
                          columnGroupShow: 'open',
                      },
                      {
                          headerName: "Fabricantes",
                          field: "paretoFilterItemManufacturer",
                          cellRenderer: function (params) {
                              return params.value ? params.value : "";
                          },
                          width: 200,
                          columnGroupShow: 'open',
                      },
                      {
                          headerName: "Etiquetas",
                          field: "paretoFilterItemTags",
                          cellRenderer: function (params) {
                              return params.value ? params.value : "";
                          },
                          width: 100,
                      }]
              },
              {
                  headerName: "Regla",
                  children: [
                      {
                          headerName: translations.Delete,
                          field: "id",
                          cellRenderer: function (params) {
                              if (params.node.data.isParent) { //Si la regla de categoria es padre
                                  if (params.node.data.categoryId != $stateParams.categoryId) //Si no es la categoria padre no la puede borrar
                                      return '<div title="No se puede eliminar una regla de categoria desde aqui, ya que puede afectar a otras sub-categorias asociadas" class="text-center"><i class="fa fa-male" aria-hidden="true"></i></div></a>';
                                  else //Si es la padre, si la puede borrar
                                      return '<div class="text-center"><a ng-click="ct.deleteRule(' + params.value + ')"><i class="fa fa-trash" aria-hidden="true"></i></div></a>';
                              }
                              return '<div class="text-center"><a ng-click="ct.deleteRule(' + params.value + ')"><i class="fa fa-trash" aria-hidden="true"></i></div></a>';
                          },
                          width: 55
                      },
                      {
                          headerName: translations.Type,
                          field: "type",
                          cellRenderer: function (params) {
                              var cls = ian.format("label label-sm {0}", params.value === 2 ? "label-primary" : "label-danger");
                              var elem = ian.format('<div {0} class="{1}">{2}</div>', params.value === 2 ? 'style="padding: 3px 13.5px;"' : '', cls, params.value === 2 ? "Alta" : "Baja");
                              return elem;
                          },
                          width: 40
                      },
                      {
                          headerName: translations.Description,
                          field: "name",
                          width: 400,
                          cellStyle: { 'white-space': 'normal' }
                      }
                  ]
              }
          ];
      }

      function setGridOptions() {
      self.gridOptions = {
          columnDefs: columnDefs,
          enableColResize: true,
          enableSorting: false,
          enableFilter: false,
          groupUseEntireRow: false,
          rowSelection: "multiple",
          groupSelectsChildren: true,
          suppressCellSelection: true,
          groupSuppressAutoColumn: false, // or undefined
          showToolPanel: false,// window.innerWidth > 1500,
          groupColumnDef: null, // or undefined
          singleClickEdit: true,
          rowHeight: 35,
          icons: {
              columnRemoveFromGroup: '<i class="fa fa-remove"/>',
              filter: '<i class="fa fa-filter"/>',
              sortAscending: '<i class="fa fa-long-arrow-down"/>',
              sortDescending: '<i class="fa fa-long-arrow-up"/>',
              groupExpanded: '<i class="fa fa-minus-square-o"/>',
              groupContracted: '<i class="fa fa-plus-square-o"/>',
              columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
              columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
          },
          enableRangeSelection: false,
          angularCompileRows: true,
          isExternalFilterPresent: isExternalFilterPresent,
          doesExternalFilterPass: doesExternalFilterPass,
          onGridSizeChanged: function (params) {
              self.gridOptions.api.sizeColumnsToFit();
          }
          };
      }

      self.toggleToolPanel = function () {
          var showing = self.gridOptions.api.isToolPanelShowing();
          self.gridOptions.api.showToolPanel(!showing);
      }
      self.onFilterChanged = function (value) {
          self.gridOptions.api.setQuickFilter(value);
      }

      //Grid Filters
      var getRuleFilter = function () {
          return self.isEditingDelistingRule && !self.isEditingListingRule ? 1 : !self.isEditingDelistingRule && self.isEditingListingRule ? 2 : 0;
      }
      function isExternalFilterPresent() {
          return self.isEditingListingRule || self.isEditingDelistingRule;
      }
      function doesExternalFilterPass(node) {
          return node.data.type == getRuleFilter();  //1 baja, 2 alta
      }

      function getDataFromCategorySummary() {
          //Seteo el titulo tipo Breadcrumb
          var categoryGroup = decodeURIComponent($stateParams.categoryGroup.replace(/-/g, " ").replace(/_/g, "\/"));
          var category = decodeURIComponent($stateParams.category.replace(/-/g, " ").replace(/_/g, "\/"));
          var subcategory = decodeURIComponent($stateParams.subcategory.replace(/-/g, " ").replace(/_/g, "\/"));

          self.title = categoryGroup
          if (category != '') {
              self.title += ' / ' + category;
              if (subcategory != '') {
                  self.title += ' / ' + subcategory;
              }
          }
      }
      function loadClusters() {
          assortmentService.rules.getClustersByCategory($stateParams.categoryId)
             .then(function (clusters) {
                 self.clusters = clusters;
                 self.listingRule.clusters = clusters;
                 self.delistingRule.clusters = clusters;
             });
      }
      function loadCategories() {
          assortmentService.rules.getCategoriesForRules($stateParams.categoryId)
             .then(function (categories) {
                 self.categories = categories;
                 self.listingRule.categories = categories;
                 self.delistingRule.categories = categories;
             });
      }
      function loadTags() {
          assortmentService.rules.getTags()
             .then(function (tags) {
                 self.tags = tags;
             });
      }
      function loadRules() {

          assortmentService.rules.getRules($stateParams.categoryId).then(function (rules) {

              self.gridOptions.api.setRowData(rules);
              self.gridOptions.api.sizeColumnsToFit();
          });
      }
      function loadBrandCoverages() {
          assortmentService.categoryRoleStrategyCoverages.getCoveragesForCategory($stateParams.categoryId)
             .then(function (coverages) {
                 self.brandCoverages = coverages;
             });
      }
      function loadCategoryItemBrands() {
          assortmentService.rules.getItemBrands($stateParams.categoryId)
              .then(function (itemBrands) {
                  self.itemBrands = itemBrands;
              });
      }
      function loadCategoryItemManufacturers() {
          assortmentService.rules.getItemManufacturers($stateParams.categoryId)
              .then(function (itemManufacturers) {
                  self.itemManufacturers = itemManufacturers;
              });
      }
      self.performanceVariable = [];

      var translations = null;

      function init() {

          setGridOptions();

          $translate(["GenerateSuggestionsSwal", "ContinueSwal", "CancelSwal", "YourRequestToGenerateSuggestionsHasBeenPasted", "RequestQueuedInfo", "TheProcessContinues", "ErrorTitleSwal", "ErrorWhenGeneratingSuggestionsForThisCategorySwal"
              , "TheSuggestionsContinueToBeGeneratedAssortment", "RemoveRuleSwal", "RuleEliminatedSwal", "TheRuleHasBeenSuccessfullyRemovedSwal", "ErrorWhenDeletingTheRule", "ConfirmationCreateThisRule", "SavedRule", "SavedRuleSubtitle"
              , "ErrorWhenSavingTheRule", "Associations", "Categories", "Clusters", "Tags", "Values", "Rule", "Delete", "Type", "Description", "Spaces", "MarketSales", "MarketUnits", "MarketPrice", "SalesRankingMarket", "ShareOfSalesMarket"
              , "ShareOfUnitsMarket", "AccumulatedSalesMarket", "Coverage", "Sales", "Units", "Margin", "Margin2", "Price", "WeightedIndex2", "SalesRanking", "UnitsRanking", "MarginRanking", "SalesShare", "ShareOfUnits", "ShareOfMargen"
              , "PercentajeMargin", "Margin2Percentage", "ShareOfMargen2", "Presence", "AccumulatedSales", "AccumulatedUnits", "AccumulatedMargin", "AccumulatedWeightedIndex"])
              .then(function (all) {
                  translations = all;
                  self.performanceVariable = [
                      {
                          id: 12,
                          label: translations.Spaces,
                          sign: {
                              position: 'right',
                              text: '-',
                              desc: '-'
                          }
                      },
                      {
                          id: 100,
                          label: translations.MarketSales,
                          sign: {
                              position: 'left',
                              text: '$',
                              desc: 'ej: $2500'
                          }
                      },
                      {
                          id: 101,
                          label: translations.MarketUnits,
                          sign: {
                              position: 'right',
                              text: 'un',
                              desc: 'ej: 10un'
                          }
                      },
                      {
                          id: 102,
                          label: translations.MarketPrice,
                          sign: {
                              position: 'left',
                              text: '$',
                              desc: 'ej: $10'
                          }
                      },
                      {
                          id: 103,
                          label: translations.SalesRankingMarket,
                          sign: {
                              position: 'left',
                              text: '',
                              desc: 'ej: 1'
                          }
                      },
                      {
                          id: 150,
                          label: translations.ShareOfSalesMarket,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 25%'
                          }
                      },
                      {
                          id: 151,
                          label: translations.ShareOfUnitsMarket,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 10%'
                          }
                      },
                      {
                          id: 153,
                          label: translations.AccumulatedSalesMarket,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 10%'
                          }
                      },
                      {
                          id: 154,
                          label: translations.Coverage,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 10%'
                          }
                      },
                  ];
                  self.delistingPerformanceVariable = [
                      {
                          id: 1,
                          label: translations.Sales,
                          sign: {
                              position: 'left',
                              text: '$',
                              desc: 'ej: $500'
                          }
                      },
                      {
                          id: 2,
                          label: translations.Units,
                          sign: {
                              position: 'right',
                              text: 'un',
                              desc: 'ej: 5un'
                          }
                      },
                      {
                          id: 3,
                          label: translations.Margin,
                          sign: {
                              position: 'left',
                              text: '$',
                              desc: 'ej: $500'
                          }
                      },
                      {
                          id: 13,
                          label: translations.Margin2,
                          sign: {
                              position: 'left',
                              text: '$',
                              desc: 'ej: $500'
                          }
                      },
                      {
                          id: 4,
                          label: translations.Price,
                          sign: {
                              position: 'right',
                              text: '$',
                              desc: 'ej: $3'
                          }
                      },
                      {
                          id: 5,
                          label: translations.WeightedIndex2,
                          sign: {
                              position: 'left',
                              text: '',
                              desc: 'ej: 5'
                          }
                      },
                      //{
                      //    id: 6,
                      //    label: 'GMROS',
                      //    sign: {
                      //        position: 'right',
                      //        text: '',
                      //        desc: 'ej: 0.55'
                      //    }
                      //},
                      {
                          id: 7,
                          label: 'GMROI',
                          sign: {
                              position: 'right',
                              text: '',
                              desc: 'ej: 0.55'
                          }
                      },
                      //{
                      //    id: 8,
                      //    label: 'GMROL',
                      //    sign: {
                      //        position: 'right',
                      //        text: '',
                      //        desc: 'ej: 0.55'
                      //    }
                      //},
                      {
                          id: 9,
                          label: translations.SalesRanking,
                          sign: {
                              position: 'right',
                              text: '',
                              desc: 'ej: 4'
                          }
                      },
                      {
                          id: 10,
                          label: translations.UnitsRanking,
                          sign: {
                              position: 'right',
                              text: '',
                              desc: 'ej: 30'
                          }
                      },
                      {
                          id: 11,
                          label: translations.MarginRanking,
                          sign: {
                              position: 'right',
                              text: '',
                              desc: 'ej: 10'
                          }
                      },
                      {
                          id: 12,
                          label: translations.Spaces,
                          sign: {
                              position: 'right',
                              text: '-',
                              desc: '-'
                          }
                      },
                      {
                          id: 50,
                          label: translations.SalesShare,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 5%'
                          }
                      },
                      {
                          id: 51,
                          label: translations.ShareOfUnits,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 6%'
                          }
                      },
                      {
                          id: 52,
                          label: translations.ShareOfMargen,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 9%'
                          }
                      },
                      {
                          id: 53,
                          label: translations.PercentajeMargin,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 31%'
                          }
                      },
                      {
                          id: 60,
                          label: translations.Margin2Percentage,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 32%'
                          }
                      },
                      {
                          id: 61,
                          label: translations.ShareOfMargen2,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 9%'
                          }
                      },
                      //{
                      //    id: 54,
                      //    label: 'Share de Index',
                      //    sign: {
                      //        position: 'right',
                      //        text: '%',
                      //        desc: 'ej: 3%'
                      //    }
                      //},
                      {
                          id: 55,
                          label: translations.Presence,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 10%'
                          }
                      },
                      {
                          id: 56,
                          label: translations.AccumulatedSales,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 10%'
                          }
                      },
                      {
                          id: 57,
                          label: translations.AccumulatedUnits,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 10%'
                          }
                      },
                      {
                          id: 58,
                          label: translations.AccumulatedMargin,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 10%'
                          }
                      },
                      {
                          id: 59,
                          label: translations.AccumulatedWeightedIndex,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 10%'
                          }
                      },
                      {
                          id: 100,
                          label: translations.MarketSales,
                          sign: {
                              position: 'left',
                              text: '$',
                              desc: 'ej: $2500'
                          }
                      },
                      {
                          id: 101,
                          label: translations.MarketUnits,
                          sign: {
                              position: 'right',
                              text: 'un',
                              desc: 'ej: 10un'
                          }
                      },
                      {
                          id: 102,
                          label: translations.MarketPrice,
                          sign: {
                              position: 'left',
                              text: '$',
                              desc: 'ej: $10'
                          }
                      },
                      {
                          id: 103,
                          label: translations.SalesRankingMarket,
                          sign: {
                              position: 'left',
                              text: '',
                              desc: 'ej: 1'
                          }
                      },
                      {
                          id: 150,
                          label: translations.ShareOfSalesMarket,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 25%'
                          }
                      },
                      {
                          id: 151,
                          label: translations.ShareOfUnitsMarket,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 10%'
                          }
                      },
                      {
                          id: 153,
                          label: translations.AccumulatedSalesMarket,
                          sign: {
                              position: 'right',
                              text: '%',
                              desc: 'ej: 10%'
                          }
                      },
                  ]
                  self.pendingPerformanceVariable = angular.copy(self.performanceVariable);
                  self.pendingDelistingPerformanceVariable = angular.copy(self.delistingPerformanceVariable);

                  setColumnDefinitions();
                  self.gridOptions.api.setColumnDefs(columnDefs);

              });

          loadBrandCoverages();
          loadCategories();
          loadClusters();
          loadTags();
          loadCategoryItemBrands();
          loadCategoryItemManufacturers();
          getDataFromCategorySummary();

          //load data
          loadRules();
      }

      init();
  });
