angular.module("prisma")
 .controller("controlPanelCtrl", function ($scope, $sce, promotionsService) {
     var self = this;

     //Variables globales

     self.isLoading = true;

     self.textCampaign = "";
     self.textTypeOfpromotion = "";

     self.filter = {
         promotionCampaignId: undefined,
         promotionMediaTypeId: undefined,

         layOutsAssignmentStatus: undefined,
         initialChargeStatus: undefined,
         partUploadForReview1Status: undefined,
         commercialReview1Status: undefined,
         partUploadForReview2Status: undefined,
         commercialReview2Status: undefined,
         closingPriceStatus: undefined,
         partUploadForReview3Status: undefined,
         finalValidationStatus: undefined
     }

     self.getTemplateForPopover = function (property) {
         var html = document.getElementById('myPopoverTemplate.html').innerHTML;

         html = html.replace("PROPERTY", property);

         return html;
     }

     self.isOpenPopover = true;

     self.promotionStatusList = [];

     self.campaignsList = [];

     self.typeOfPromotionList = [];

     //Funciones
     self.setFilterCampaign = function (campaign) {
         self.filter.promotionCampaignId = campaign.id;
         self.textCampaign = campaign.name;
     }

     self.setFilterPromotion = function (mediaType) {
         self.filter.promotionMediaTypeId = mediaType.id;
         self.textTypeOfpromotion = mediaType.label;
     }

     self.getColor = function (status) {
         rv = ""

         if (status == "OK")
             rv = "#1AB394";

         else if (status == "Advertencia")
             rv = "#F8AC59";

         else if (status == "En falta")
             rv = "#ED5565";
         else if(status == "Pendiente")
             rv = "#FFFFFF";

         else
             rv = "#f5f5f5";

         return rv;
     }

     self.getFilterColor = function (prop) {
         rv = ""

         var status = self.filter[prop];

         if (status == "OK")
             rv = "#1AB394";

         else if (status == "Advertencia")
             rv = "#F8AC59";

         else if (status == "En falta")
             rv = "#ED5565";

         else
             rv = "inherit";

         return rv;
     }

     self.popover = {
         template: 'myPopoverTemplate.html',

         isOpen: false,

         prop: null,

         open: function (prop) {
             self.popover.prop = prop;
         }
     };

     self.setFilter = function (status) {

         self.filter[self.popover.prop] = status;

         $('.popover').remove();

     }

     function init() {

                //self.isLoading = false;

         promotionsService.controlPanel.getPromotionsStatus()
            .then(function (responseList) {
                console.log('responseList', responseList);
                self.isLoading = false;
                self.promotionStatusList = responseList;
            });

         promotionsService.campaigns.getCampaigns()
            .then(function (responseCampaings) {
                console.log('responseCampaings', responseCampaings);
                self.campaignsList = responseCampaings;
            });

         self.typeOfPromotionList = promotionsService.promotionMediaTypes;
         self.typeOfPromotionList.splice(5, 1);//quito inernas del filtro
     }


     init();


 });

