var _controller = function (componentsService, $scope, $element, $attrs, $q, $timeout) {
    var ctrl = this;
    if (ctrl.ngModel == undefined)
        ctrl.ngModel = '';
    //console.log('ctrl', ctrl);


    ctrl.onKeydown = function (evt) {
        //console.log('evt', evt);
        if (evt.keyCode == 13 &&
            ctrl.ngModel == '') {
            ctrl.onItemSelect({id:0});
        }
    }

    ctrl.getItems = function (text) {
        //console.log(text);
        if (text.length <= 3)
            return;

        return componentsService.categoryLookup.getCategories(text,ctrl.level)
            .then(function (items) {
                return items;
            });
    }

    ctrl.onItemSelect = function (item) {
        if (item == undefined) return;
        ctrl.ngModel = item.name;
        //console.log('onitemselect', item);
        ctrl.onSelect(item);

        if (ctrl.clearOnSelect)
            ctrl.ngModel = null;
    }

}

angular.module('prisma').component('categoryLookupComponent', {
    templateUrl: 'app/shared_logic/components/categoryLookup/categoryLookup.component.html',
    bindings: {
        level: '=',
        onSelect: '=',
        clearOnSelect: '=',
        proptabindex: '=',
        //ngModel:'='
    },

    controller: _controller
});