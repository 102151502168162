
angular.module("prisma")
  .controller("DailyMarginReportCtrl", function ($state, $rootScope, priceService) {
      var self = this;

      var numberColWidth = 100;

      var columnDefs = null;

      function setColumnDefinitions() {
          columnDefs = [
                  {
                  headerName: "Zone Code",
                      field: "code",
                      width: 60,
                      cellClass: $rootScope.getClassForCell,
                  },
                    {
                        headerName: "Zone",
                        field: "name",
                        width: numberColWidth,
                        cellClass: $rootScope.getClassForCell,
                    },
                  {
                      headerName: "Date",
                      field: "currentDate",
                      width: numberColWidth,
                      filter: 'date',
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.dateTimeRenderer
                  },
                  {
                      headerName: "Product",
                      field: "item",
                      width: numberColWidth,
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "Station",
                      field: "storeCode",
                      width: numberColWidth + 10,
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "Public price",
                      field: "_Price",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "Accounting Price",
                      field: "priceWOTaxes",
                      filter: 'number',
                      width: numberColWidth,
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "Total Cost without VAT",
                      field: "cost",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "Accounting margin",
                      field: "margin",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "Maximum Margin",
                      field: "maxMarginAmount",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "Accounting margin / Maximum Margin",
                      field: "marginVsMaxMargin",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.percentageRenderer
                  },
                  {
                      headerName: "Main Competitor",
                      field: "competitor",
                      width: numberColWidth,
                      cellClass: $rootScope.getClassForCell,
                  },
                  {
                      headerName: "Main Competitor Price",
                      field: "competitorsPrice",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "IC vs Main Competitor",
                      field: "competitiveIndex",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.percentageRenderer
                  },
                  {
                      headerName: "Average Market Price",
                      field: "marketAveragePrice",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "IC vs Average Market Price",
                      field: "marketCompetitiveIndex",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell,
                      cellRenderer: $rootScope.percentageRenderer
                  },
          ]
      }

      self.gridOptions = {
          columnDefs: columnDefs,
          enableColResize: true,
          enableSorting: true,
          enableFilter: false,
          groupHeaders: false,
          suppressCellSelection: true,
          rowHeight: 35,
          groupHideGroupColumns: true,
          icons: {
              columnRemoveFromGroup: '<i class="fa fa-remove"/>',
              filter: '<i class="fa fa-filter"/>',
              sortAscending: '<i class="fa fa-long-arrow-down"/>',
              sortDescending: '<i class="fa fa-long-arrow-up"/>',
              groupExpanded: '<i class="fa fa-minus-square-o"/>',
              groupContracted: '<i class="fa fa-plus-square-o"/>',
              columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
              columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
          },
          angularCompileRows: true
      }

      self.exportToExcel = function () {
          swal({
              title: 'Exportar',
              text: "Desea exportar estos registros a un archivo excel?.",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#1AB394",
              confirmButtonText: "Continuar",
              cancelButtonText: "Cancelar",
              showLoaderOnConfirm: true,
              closeOnConfirm: false,
              closeOnCancel: true
          },
        function (isConfirm) {

            if (!isConfirm) return;

            priceService.reports.getPricesDailyMarginExcel().then(function (url) {
                window.open(url);
                swal("Exportados correctamente", '', "success");
            });
        });
      }

      function setGridOptions() {

          setColumnDefinitions();

          self.gridOptions = {
              columnDefs: columnDefs,
              enableColResize: true,
              enableSorting: true,
              enableFilter: false,
              groupHeaders: false,
              suppressCellSelection: true,
              rowHeight: 35,
              groupHideGroupColumns: true,
              icons: {
                  columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                  filter: '<i class="fa fa-filter"/>',
                  sortAscending: '<i class="fa fa-long-arrow-down"/>',
                  sortDescending: '<i class="fa fa-long-arrow-up"/>',
                  groupExpanded: '<i class="fa fa-minus-square-o"/>',
                  groupContracted: '<i class="fa fa-plus-square-o"/>',
                  columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                  columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
              }
          }
      }

      self.loadData = function () {

          priceService.reports.getPricesDailyMargin().then(function (rows) {
              self.gridOptions.api.setRowData(rows);
          });
      }

      function init() {
          //init columnDefs and gridOptions
          setGridOptions();

          //load data
          self.loadData();
      }

      init();

  });

