"use strict";

angular.module("prisma")
    .service("markdownService", function (httpService) {
        var self = this;

        self.rules = {
            getRulesCategories: function (brandId) {
                return httpService.get('/MarkdownRules/GetCategoryRules/' + brandId);
            },
            saveCategoryRules: function (dto) {
                return httpService.post('/MarkdownRules/saveCategoryRules', dto);
            },
            getBrands: function () {
                return httpService.get('/MarkdownRules/getBrands/');
            },
            getRules: function () {
                return httpService.get('/MarkdownRules/getRules/');
            },
            saveRule: function (rule) {
                return httpService.post('/MarkdownRules/saveRule', rule);
            },
            updateRules: function (rules) {
                return httpService.post('/MarkdownRules/updateRule', rules);
            },
            updateRulesAndGenerateSuggestions: function (reevaluateCurrentPlan, rules) {
                return httpService.post('/MarkdownRules/UpdateRulesAndGenerateSuggestions/' + reevaluateCurrentPlan, rules);
            },
            deleteRule: function (ruleId) {
                return httpService.post('/MarkdownRules/deleteRule', ruleId);
            },
        }

        self.decisions = {
            getStoresInMarkdown: function (categoryId, type) {
                return httpService.get('/MarkdownCategorySummary/GetStoresInMarkdown/' + categoryId + '/' + type);
            },
            getCategoriesForDecisions: function (categoryId) {
                return httpService.get('/MarkdownCategorySummary/GetCategoriesForDecisions/' + categoryId);
            },
            getCategorySummary: function () {
                return httpService.get('/MarkdownCategorySummary/GetCategorySummary/');
            },
            GetMarkdownSuggestions: function (categoryIds, type, pageNumber, pageSize, storeId, stage, suggestion, stock, status) {
                return httpService.get('/MarkdownCategorySummary/GetMarkdownSuggestions/' + categoryIds + '/' + type
                    + '/' + pageNumber + '/' + pageSize + '/' + storeId + '/' + stage + '/' + suggestion + '/' + stock + '/' + status);
            },
            acceptSuggestions: function (suggestions) {
                return httpService.post('/MarkdownCategorySummary/AcceptSuggestions', suggestions);
            },
            endose: function (suggestions) {
                return httpService.post('/MarkdownCategorySummary/Endose', suggestions);
            },
            validate: function (suggestions) {
                return httpService.post('/MarkdownCategorySummary/Validate', suggestions);
            },
            ignoreSuggestions: function (suggestions) {
                return httpService.post('/MarkdownCategorySummary/ignoreSuggestions', suggestions);
            },
            reject: function (suggestions,reason) {
                return httpService.post('/MarkdownCategorySummary/reject/' + reason, suggestions);
            },
            reviewCategorySuggestions: function (categoryIds) {
                return httpService.post('/MarkdownCategorySummary/ReviewCategorySuggestions', categoryIds);
            },
            reviewCategoryEndorse: function (categoryIds) {
                return httpService.post('/MarkdownCategorySummary/reviewCategoryEndorse', categoryIds);
            },
            reviewCategoryValidate: function (categoryIds) {
                return httpService.post('/MarkdownCategorySummary/reviewCategoryValidate', categoryIds);
            },
            writeMarkdownPrices: function () {
                return httpService.get('/MarkdownCategorySummary/writeMarkdownPrices');
            },
            getMotivoSugerencia: function (markdownItemId, markdownPlanType) {
                return httpService.get('/MarkdownCategorySummary/getMotivoSugerencia/' + markdownItemId + '/' + markdownPlanType);
            },
            ignoreAll: function () {
                return httpService.get('/MarkdownCategorySummary/ignoreAll/');
            }
        }

        self.activation = {
            getLastOutputReports: function () {
                //return httpService.get('/price/priceoutput/getLastOutputReports');
                return httpService.get('/MarkdownActivation/getLastOutputReports');
            },

            getReasonsFilter: function (markdownPlanType) {
                //return httpService.get('/price/priceoutput/getReasonsFilter/' + priceType);
                return httpService.get('/MarkdownActivation/getReasonsFilter/' + markdownPlanType);
            },

            activateMarkdowns: function (mardownsToInform) {
                //return httpService.post('/price/priceoutput/informToErp', pricesToInform);
                return httpService.post('/MarkdownActivation/activateMarkdowns', mardownsToInform);
            },
            downloadLastOutput: function () {
                return httpService.get('/MarkdownActivation/downloadLastOutput');
            },
            getMarkdownsToInformFiltered: function (filters) {
                //return httpService.post('/price/priceoutput/getStorePricesToInformFiltered', filters);
                return httpService.post('/MarkdownActivation/getMarkdownsToInformFiltered', filters);
            }
        }

    });