angular.module("prisma")
 .controller("reviewTradeAgreementsCtrl", function ($scope, $rootScope, $state, $stateParams, promotionsService, $timeout, ngDialog, authService) {

     var self = this;

     self.task = null;
     self.link = '';

     self.canPublishTradeAgreements = authService.hasPermission('promotion_publish_RevisionAcuerdosComerciales');

     self.publish = function () {
         if ($stateParams.taskId) {
             promotionsService.tasks.publishTask($stateParams.taskId)
                 .then(function (isOk) {
                     swal('Tarea Publicada!', 'La tarea se ha publicado exitosamente', 'success');

                     $state.go('promotions.myTasks');
                 });
         } else {
             $timeout(function () {
                 swal("Error", "no se pudo publicar la tarea", "error");
             }, 100);
         }
     }

     self.generateAndDownload = function () {
         promotionsService.reports.buildItemTasksReport(self.task.promotionId)
                 .then(function (link) {
                     self.link = link;
                     self.showSwalWithLink();
                 });
     }

     self.showSwalWithLink = function () {
         swal({
             title: "Reporte Generado!",
             text: 'Haga click <a href="' + link + '"><u style = "color: #1AB394;"><b style = "color: #1AB394;">AQUÍ</b></u></a> para descargar el archivo',
             confirmButtonText: "Cerrar",
             confirmButtonColor: "#c2c2c2",
             html: true
         });
     }

     function init() {
         promotionsService.tasks.startTask($stateParams.taskId);
         promotionsService.tasks.getTask($stateParams.taskId)
         .then(function (task) {
             self.task = task;
         });

     }

     init();
 });