angular.module("prisma")
 .controller("ClusterCtrl", function ($scope, adminService, $timeout) {
     var self = this;

     //Variables
     self.newCluster = { id: null, name: '', code: '', isExcluded: false, stores: [], regionId: 0, brandId: 0 };
     self.stores = [];
     self.clusters = [];
     self.regions = [];
     self.brands = [];
     self.showStores = false;
     self.showAllStores = false;
     self.isValidCode = true;


     //filtros
     self.searchCluster = "";
     self.order = "";
     self.filter = "";

     //Functions 
     self.toggleNewCluster = function () {
         self.newCluster.isExcluded = !self.newCluster.isExcluded;
     }
     self.disableCluster = function (clusterId) {
         adminService.clusters.deleteCluster(clusterId).then(function () {
             loadClusters();
         }), function error() {
             console.log("Error al deshabilitar el cluster");
         };
     }
     self.editCluster = function (cluster) {
         self.newCluster = cluster;
         if (cluster.stores.length > 0)
             self.showStores = true;
         else
             self.showStores = false;
     }
     self.addStores = function () {
         if (self.newCluster.id == 0) {
             //Listo todas las stores
             self.showStores = false;
             self.showAllStores = true;

         }
         else if (self.newCluster.id > 0) {
             //Tildo todas las stores de este cluster y listo todas
             self.showStores = false;
             self.showAllStores = true;

             var includedStores = [];
             _.forEach(self.newCluster.stores, function (s, key) {
                 includedStores.push(s.id);
             });

             _.forEach(self.stores, function (s, key) {

                 if (_.some(includedStores, function (o) { return o == s.id; }))
                     s.includedInCluster = true;
             });
         }
     }
     self.search = function (item) {
         if (!item.isExcluded) {
             return true;
         }
         return false;
     };

     self.save = function () {

         swal({
             title: "Esta seguro?",
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: "Continuar",
             cancelButtonText: "Cancelar",
             showLoaderOnConfirm: true,
             closeOnConfirm: false,
             closeOnCancel: true
         },
             function (isConfirm) {
                 if (isConfirm) {
                     if (!self.isValidCode) {
                         $timeout(function () {
                             swal('Ingrese un codigo valido', '', 'error');
                         }, 100);
                         
                         return;
                     }
                     ////Agrego las stores asociadas si toque el boton de editar tiendas
                     //if (self.showAllStores) {
                     //    self.newCluster.stores = [];
                     //    _.forEach(self.stores, function (s, key) {
                     //        if (s.includedInCluster)
                     //            self.newCluster.stores.push(angular.copy(s));
                     //    });
                     //}

                     //Llamo al servicio para guardar
                     adminService.clusters.saveCluster(self.newCluster).then(function (response) {
                         console.log(response);
                         swal("Confirmado", "Se guardaron los cambios seleccionados", "success");

                         //Reseteo las variables y vuelvo a cargar los clusters
                         self.cancel();
                         loadClusters();
                     }), function error(error) {
                         console.log(error);

                         swal("Error", "Ocurrio un error al guardar", "error");
                     };
                 }
        });
     }
     self.cancel = function () {
         //Reset variables
         self.newCluster = { id: null, name: '', code: '', isExcluded: false, stores: [] };
         self.showStores = false;
         self.showAllStores = false;

         _.forEach(self.stores, function (s, key) { s.includedInCluster = false; });
     }
     self.addNew = function () {
         self.newCluster.id = 0;
     }

     self.filterStatus = function (status) {
         if (status == "Activo") {
             self.filter = "Activo";
         }
         else if (status == "Deshabilitado") {
             self.filter = "Deshabilitado";
         }
         else
             self.filter = "";
     }

     self.validateClusterCode = function () {
         self.validatingClusterCode = true;
         adminService.clusters.validateClusterCode(self.newCluster.code).then(function (response) {
             self.validatingClusterCode = false;
             self.isValidCode = !response;
         });
     }

     //Load Data
     function loadClusters() {

         adminService.clusters.getClusters().then(function (clusters) {
             self.clusters = clusters;
         });
     }
     function loadStores() {
         adminService.stores.getStores(false).then(function (stores) {
             self.stores = stores;
         });
     }

     function loadRegions() {
         adminService.clusters.getRegions()
             .then(function (regions) {
             self.regions = regions;
         });
     }

     function loadBrands() {
         adminService.clusters.getBrands()
             .then(function (brands) {
                 self.brands = brands;

                 if (self.brands.length == 1)
                     self.newCluster.brandId = brands[0].genericId;
             });
     } 

     function init() {

         loadClusters();
         loadStores();
         loadRegions();
         loadBrands();
     }

     init();
 });