angular.module("prisma")
    .controller("AssortmentClusterCrossingCtrl", function ($rootScope, $scope, $state, assortmentService, adminService) {

        var self = this;
        self.categories = [];
        self.selectedCategoriesIds = [];
        self.selectedSubategoriesIds = [];
        self.clusters = [];
        self.sourceClusterId = null;
        self.targetClusterId = null;
        self.ruleTypes = [
            { value: 1, name: 'Venta' },
            { value: 2, name: 'Unidades' },
            { value: 3, name: 'Margen' },
            { value: 4, name: 'Participacion Venta' },
            { value: 5, name: 'Participacion Unidades' },
            { value: 6, name: 'Participacion Margen' }
        ];
        self.comparationTypes = [
            { value: 1, label: '≤' },//menor o igual
            { value: 2, label: '≥' },//mayor o igual
            { value: 3, label: '=' }
        ];
        self.comparationIdx = 0;
        self.rule = { type: 1, comparation: 1, value: null };
        self.rules = [];
        self.writtenRule = '';
        self.crossingResults = [];

        self.changeComparation = function () {
            if (self.comparationIdx === self.comparationTypes.length - 1)
                self.comparationIdx = 0;
            else self.comparationIdx++;
        }

        self.addRule = function () {
            if (!validateRule()) {
                swal('Regla invalida', '', 'error');
                return;
            }

            self.rule.comparation = self.comparationTypes[self.comparationIdx].value;
            console.log(self.rule);
            self.rules.push(self.rule);
            self.rule = { type: 1, comparation: 1, value: null };
            writeRule();
        }

        self.getResults = function () {
            if (!validateParams()) {
                swal('Error', 'Complete todos los campos.', 'error');
                return;
            }
            var dto = {
                sourceClusterId: self.sourceClusterId,
                targetClusterId: self.targetClusterId,
                categoriesIds: self.selectedCategoriesIds,
                subcategoriesIds: self.selectedSubategoriesIds,
                rules: self.rules
            };
            self.isComparing = true;
            assortmentService.decisions.getClusterCrossing(dto)
                .then(function (response) {
                    console.log(response);
                    response.forEach(function (x) {
                        x.add = false;
                    });
                    self.crossingResults = response;
                    self.isComparing = false;
                },
                function (error) {
                    console.log(error);
                    swal("Error", "Ha ocurrido un error", "error");
                    self.isComparing = false;
                });
        }

        self.save = function () {
            if (!self.crossingResults.some(function (x) { return x.add; })) {
                swal('Debe incorporar al menos un item.', '', 'warning');
                return;
            }

            swal({
                title: 'Atencion',
                text: 'Se tomaran decisiones de alta para el cluster revision.',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        self.isSaving = true;
                        var req = self.crossingResults.filter(function (x) { return x.add; });
                        assortmentService.decisions.saveClusterCrossing(req)
                            .then(function (response) {
                                console.log(response);
                                self.crossingResults = [];
                                swal("Confirmado", "Se guardaron los cambios seleccionados", "success");
                                self.isSaving = false;
                            },
                            function (error) {
                                console.log(error);
                                swal("Error", "Ha ocurrido un error", "error");
                                self.isSaving = false;
                            });
                    }
                });
        }

        function validateRule() {
            return self.rule.value != null;
        }

        function validateParams() {
            return (self.selectedCategoriesIds.length > 0 || self.selectedSubategoriesIds.length > 0)
                && self.targetClusterId && self.sourceClusterId
                && self.rules.length > 0;
        }

        function writeRule() {
            var writtenRule = "<strong>REGLA: </strong> Productos del cluster de referencia";

            _.forEach(self.rules, function (r, index) {
                var type = self.ruleTypes.find(function (t) { return t.value === r.type; });
                var comparation = r.comparation === 3 ? 'igual' : r.comparation === 1 ? 'menor o igual' : 'mayor o igual';
                var value = '';
                switch (type.value) {
                    case 1: value = '$' + r.value; break;
                    case 2: value = r.value; break;
                    case 3: value = '$' + r.value; break;
                    case 4: value = r.value + '%'; break;
                    case 5: value = r.value + '%'; break;
                    case 6: value = r.value + '%'; break;
                }

                writtenRule += ian.format(
                    ' <strong>{0}</strong> donde {1} sea {2} a {3}'
                    , index ? 'Y' : '', type.name, comparation, value);

            });

            self.writtenRule = writtenRule;
        }

        function init() {
            adminService.categories.getCategoriesWithLevels([5, 4])
                .then(function (categories) {
                    self.categories = categories;
                });

            adminService.clusters.getClusters()
                .then(function (clusters) {
                    self.clusters = clusters;
                });

        }

        init();
    });

