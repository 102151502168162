angular.module("prisma")
    .controller("EditPlanogramCtrl", function ($state, $scope, $timeout, $rootScope, $window, $stateParams, $translate, $filter, $uibModal, $element, authService, priceService, spaceService, ngDialog, adminService, assortmentService, $log, $q, imageService, DnDService, $document) {
        var self = this;
        var TICKET_TYPES = {
            PLANOGRAM: 1,
            ITEM: 2
        }

        var onGlobalMouseUp = function (e) {
            if (angular.isDefined(e.toElement)
                && angular.isDefined(e.toElement.className)
                && e.toElement.className.toString().indexOf('filter-button') === -1
                && $(event.target).closest('.filter-element').length === 0) {
                self.closeFilters();
            }
        };

        $("body").on("mouseup", onGlobalMouseUp);

        $scope.$on("$destroy", function () {
            $("body").off("mouseup", onGlobalMouseUp);
            $document.off('keydown', onGlobalKeydown);

        });

        // Referencia del timmer para poder cancelar 
        // la actualización de la referencia del modelo del planograma.
        // Para evitar el parpadedo de la pantalla por el redibujo del planograma.
        self.forceUpdateModelCancel = null;

        self.labelInfoExp = '';

        self.activePlanogram = null;
        self.selectedColumn = null;
        self.selectedModule = null;
        self.selectedLevel = null;
        self.selectedItem = null;
        self.activeShelf = null;
        self.setActiveShelf = function setActiveShelf(shelf) {
            self.activeShelf = shelf;
        }

        self.hasPermissionToEndorse = authService.hasPermission('spaces_endorse_planograms');
        self.hasPermissionToInform = authService.hasPermission('spaces_inform_planograms');
        self.hasPermissionToEdit = authService.hasPermission('spaces_edit_planograms');

        self.showDimensions = false;
        self.isLoading = false;
        self.isLoadingListItems = false;
        self.isLoadingPlanogram = false;
        self.showChipsFilter = false;
        self.itemList = [];

        self.model = {
            planograms: []
        };
        self.planogramItems = [];
        self.heatMapEnabled = false;
        self.heatMapVariable = 'Off';
        self.search = "";
        self.categoryFilter = '!null';
        self.selectedBrand = '!null';
        self.selectedManufacturer = '!null';
        self.selectedStatus = '!null';
        self.selectedTagFilter = null;
        self.filteredItems = [];
        self.editItem = null;
        self.newTicket = GenerateNewTicket();
        self.translationLow = "";
        self.translationMedium = "";
        self.translationHeightF = "";
        self.selectedPlanogram = null;
        self.typesTickets = [
            'Planograma',
            'Item'
        ];

        self.selectedTag = 'None';
        self.textSelectedTag = "None";
        self.textSelectedStatus = "None";
        self.display = 'block';
        self.textClusters = "";
        self.clusters = [];
        self.categories = null;
        self.subCategories = null;
        self.tagsSelections = [];
        self.subcategoriesSelections = [];
        self.allTags = [];

        self.brandsFilter = [];
        self.brandSelection = [];

        self.manufacturersFilter = [];
        self.manufacturerSelection = [];

        self.showLoadQuantity = false;
        self.showExhibitionDays = false;
        self.showDaysInStock = false;
        self.showRegularPrice = false;
        self.analyzeBy = 'cluster';

        self.thereIsRefrigerator = false;
        self.thereIsPeg = false;
        self.showDoor = false;
        self.autoAlign = false;
        self.autoStack = true;
        self.stateChange = false;
        self.percentageSales = 0 + '%';
        self.percentageSpaces = 0 + '%';
        self.tooltipSpaced = '';
        self.showlevelmeasurebuttons = false;

        self.editLevelMeasure = function () {
            self.showlevelmeasurebuttons = !self.showlevelmeasurebuttons;
        }


        // Visual tipo Nielsen por bloque.
        self.groupMapVariable = '';
        self.groupMapVariableValue = '';
        self.groupMapEnabled = false;
        self.groupMapList = [];
        self.groupMap = {};
        self.groupMapTags = [];
        self.groupTags = [];
        self.spaceModuleType = {
            1: 'Shelf',
            2: 'Peg',
            3: 'Refrigerator'
        }
        self.logoNavbar = $rootScope.logoNavbar;
        self.zoom = 800;
        self.changeZoom = function (bool) {
            bool ? self.zoom += 50 : self.zoom -= 50;
        }

        self.colors = [
            //'e6194b', //Este color ya está en el mapa de calor
            //'3cb44b', //Este color ya está en el mapa de calor
            //'f58231', //Este color ya está en el mapa de calor

            '000000',
            '000066',
            '00cc00',
            '660000',
            '66ff99',
            'ccff33',
            'ff0000',
            '66ffff',
            'ff0066',
            'ff6600',
            '66ccff',
            'ff6666',
            '660066',
            '336699',
            '99ccff',
            '663300',
            'ff00ff',
            '0000cc',
            '99ff99',
            'ff9900',
            'ff9966',
            'ff3399',
            'ffff66',
            'ffff00',
            'cc9900',
            'cc00cc',
            '3333cc',
            '993333',
            '999933',
            'ccff99',
            '99cc66'

        ];
        var translations = null;


        $scope.oneAtATime = true;

        $scope.groups = [
            {
                title: 'Dynamic Group Header - 1',
                content: 'Dynamic Group Body - 1'
            },
            {
                title: 'Dynamic Group Header - 2',
                content: 'Dynamic Group Body - 2'
            }
        ];

        $scope.items = ['Item 1', 'Item 2', 'Item 3'];

        $scope.addItem = function () {
            var newItemNo = $scope.items.length + 1;
            $scope.items.push('Item ' + newItemNo);
        };

        $scope.status = {
            isCustomHeaderOpen: false,
            isFirstOpen: true,
            isFirstDisabled: false
        };

        //Filtro collapse
        self.isNavCollapsed = true;
        self.isCollapsedHorizontal = false;
        self.isCollapsed = true;
        self.isAnalyzeByCollapsed = false;
        self.isSelectedClusterCollapsed = false;
        self.isSelectedStoreCollapsed = false;
        self.isGroupByCollapsed = false;
        self.isBrandCollapsed = false;
        self.isTagsCollapsed = false;
        self.isHeatMapCollapsed = false;
        self.isStatusCollapsed = false;

        self.itemTry = [
            {
                "id": 2,
                "planogramLevelId": 2,
                "itemId": 107,
                "order": 0,
                "facings": 3,
                "orientation": 0,
                "ticketCount": 0,
                "status": null,
                "categoryId": 112,
                "categoryName": "TV LED y Smart TV",
                "name": "TELEVISOR 32 \" SMART TV 32J4300",
                "imageUrl": "assets/images/items/ElectroDemo/168919.png",
                "width": 74.54,
                "height": 44.2,
                "depth": 6.9,
                "tags": [],
                "brand": "Samsung",
                "stackTotal": null,
                "position": [10, 5],
            }, {
                "id": 3,
                "planogramLevelId": 2,
                "itemId": 108,
                "order": 1,
                "facings": 1,
                "orientation": 0,
                "ticketCount": 0,
                "status": null,
                "categoryId": 112,
                "categoryName": "TV LED y Smart TV",
                "name": "TELEVISOR 32 \" SMART TV 32LH575B",
                "imageUrl": "assets/images/items/ElectroDemo/171815.png",
                "width": 73.4,
                "height": 47.4,
                "depth": 17.2,
                "tags": [],
                "brand": "Lg",
                "stackTotal": null,
                "position": [50, 50]
            }, {
                "id": 4,
                "planogramLevelId": 3,
                "itemId": 105,
                "order": 0,
                "facings": 1,
                "orientation": 0,
                "ticketCount": 0,
                "status": null,
                "categoryId": 112,
                "categoryName": "TV LED y Smart TV",
                "name": "TELEVISOR 32 \" SMART TV 32PHG5102",
                "imageUrl": "assets/images/items/ElectroDemo/172641.png",
                "width": 73.25,
                "height": 42.96,
                "depth": 8.68,
                "tags": [],
                "brand": "Philips",
                "stackTotal": null,
                "position": [30, 70]
            }
        ]

        self.selectSpaceObject = function (spaceObject) {
            self.selectedSpaceObject = spaceObject;
        }

        self.newItem = false;
        self.planogramByColums = [];

        self.componentMousedown = function (item, event) {
            self.stateChange = true;
            //if (level.polygonContainer.length == 0 || level.polygonContainer[0].points == undefined) return;
            mouseDown = true;

            // create a clone of the currentTarget element
            cloned = event.currentTarget.cloneNode(true);
            //Le pongo las medidas correctas al planograma
            //self.calcPlanogramSize(self.selectedSpaceObject);

            //resize clone
            cloned.style.width = '50px';
            cloned.style.height = '50px';
            cloned.style.opacity = '.5';
            cloned.querySelector('#item_' + item.itemId).style.display = 'none'
            //div.innerHTML = "Hello";

            var parent = document.getElementById('auxPlanogram');
            parent.style.top = event.clientY + 'px';
            parent.style.left = event.clientX + 'px';

            parent.appendChild(cloned);
            window.addEventListener('mouseup', onMouseUp);

            document.onmousemove = function (oPssEvt2) {
                var oMsEvent2 = oPssEvt2 || /* IE */ window.event;
                parent.style.top = oMsEvent2.clientY + 'px';
                parent.style.left = oMsEvent2.clientX + 'px';
                //console.log('onmousemove', oMsEvent2)

                //if (isValidPosition(cloned)) {
                //    cloned.className = cloned.className.replace('forbidden', '');
                //} else {
                //    if (!_.includes(cloned.className, 'forbidden')) {
                //        cloned.className += ' forbidden';
                //    }
                //}
            };
        }

        function onMouseUp(event) {
            //Solo ejecuto si viene de un mouse down previo
            if (!mouseDown) return;
            //console.log('up', event);
            //Le saco el evento, por las dudas
            window.removeEventListener('mouseup', onMouseUp);
            //Dejo de arrastrar
            document.onmousemove = null;

            //Guardo las posicion para usarlos luego
            var positionPlanogram = document.getElementById('drawing').getBoundingClientRect();
            var positionElement = cloned.getBoundingClientRect();

            //valido la posicion del elemento, si se encuenta dentro del planograma!
            var validPosition = isValidPosition(positionPlanogram, positionElement);

            //Remuevo el planograma del canvas creado
            var parent = document.getElementById('auxPlanogram');
            parent.removeChild(cloned);

            ////Si es una ubicacion no permitida, no lo agrego
            ////if (_.includes(cloned.className, "forbidden"))
            //if (!validPosition) {
            //    //console.log('no over');
            //    return;
            //}


            var componentClone = angular.copy(self.selectedSpaceObject);
            componentClone.id = guid();

            //solo si la posicion es valida lo agrego
            if (validPosition) {
                self.itemTry.push({
                    isNew: true,
                    //tempId: level.levelObjects.length + 1,
                    id: componentClone.itemId,
                    spaceObjectId: componentClone.id,
                    depth: componentClone.depth,
                    width: componentClone.width,
                    height: componentClone.height,
                    points: componentClone.points,
                    pointsPx: componentClone.pointsPx,
                    rotation: componentClone.rotation ? componentClone.rotation : 0,
                    name: componentClone.name,
                    imageUrl: componentClone.imageUrl,
                    categoryId: componentClone.categoryId,
                    isPlanogram: componentClone.isPlanogram,
                    categoryColor: componentClone.categoryColor,
                    position: [positionElement.x - positionPlanogram.x, positionElement.y - positionPlanogram.y]
                });
                self.newItem = true;
            }

            ////console.log('clone', componentClone);
            $scope.$apply();

        }

        function isValidPosition(positionPlanogram, positionElement) {
            if (positionElement.x > positionPlanogram.x
                && positionElement.y > positionPlanogram.y
                && positionElement.x + positionElement.width < positionPlanogram.x + positionPlanogram.width
                && positionElement.y + positionElement.height < positionPlanogram.y + positionPlanogram.height) {
                return true
            }
            return false
        }



        function mapNumber(value, inMin, inMax, outMin, outMax) {
            var inRange = inMax - inMin;
            var outRange = outMax - outMin;
            return (value - inMin) * outRange / inRange + outMin;
        }

        function calculateStackTotalForPlanogram(itemId) {
            let rv = 0;
            loopThroughAll(function (planogram, column, module, level, item) {
                if (item.itemId == itemId)
                    rv += self.calculateLoadQuantity(item);
            }, self.until.items);

            //update stack total for planogram
            loopThroughAll(function (planogram, column, module, level, item) {
                if (item.itemId == itemId)
                    item.stackTotal = rv;
            }, self.until.items);

            return rv;
        }

        self.setItemLabel = function (labelType) {
            var showInfo = false;
            self.labelInfoClassExp = '';
            if (labelType === 'loadQuantity') {
                showInfo = self.showLoadQuantity = !self.showLoadQuantity;
                self.showExhibitionDays = false;
                self.showDaysInStock = false;
                self.showRegularPrice = false;
                self.labelInfoExp = 'stackTotal';
            } else if (labelType === 'exhibitionDays') {
                showInfo = self.showExhibitionDays = !self.showExhibitionDays;
                //if (showInfo) {
                //    loopThroughAll(function (planogram, column, module, level, item) {
                //        self.calcExhibitionDays(item);
                //    }, self.until.items);
                //}

                self.showLoadQuantity = false;
                self.showDaysInStock = false;
                self.showRegularPrice = false;
                self.labelInfoExp = 'exhibitionDays | number:1';
            } else if (labelType === 'daysInStock') {
                showInfo = self.showDaysInStock = !self.showDaysInStock;
                //if (showInfo) {
                //    loopThroughAll(function (planogram, column, module, level, item) {
                //        self.calcDaysInStock(item);
                //    }, self.until.items);
                //}

                self.showLoadQuantity = false;
                self.showExhibitionDays = false;
                self.showRegularPrice = false;
                self.labelInfoExp = 'daysInStock | number: 1';
                self.labelInfoClassExp = '(daysInStock <= 3) ? "label-danger" : ""';
            } else if (labelType === 'regularPrice') {
                showInfo = self.showRegularPrice = !self.showRegularPrice;
                self.showExhibitionDays = false;
                self.showDaysInStock = false;
                self.showLoadQuantity = false;
                self.labelInfoExp = '"$ " + ( regularPrice | number:1 ) ';
            } else {
                showInfo = false;
                self.showExhibitionDays = false;
                self.showDaysInStock = false;
                self.showLoadQuantity = false;
                self.showRegularPrice = false;
                self.labelInfoExp = 'itemCode';
            }

            self.showInfo = showInfo;
            self.forceUpdateModel();
        }

                /**         
        TATA URUGUAY
        dias de exhibición de un articulo = capacidad total / (ventas / 30)
        dias de stock de un articulo = stock actual / (ventas / 30)
        siendo:
        capacidad total = facings + apilados arriba + apilados atras
        ventas =  la suma de las unidades vendidas en promedio mensual de los ultimos 12 meses que tiene el item en las tiendas del planograma
        stock actual = la suma de todo el stock actual que tiene un item en las tiendas del planograma
         */

          /**
         * Calcula los dias de exhibicion de un item en el planograma
         * @param {number} itemId Id del item
         */
        self.calcExhibitionDays = function (itemId) {
            loopThroughAll(function (planogram, column, module, level, item) {
                if (item.itemId == itemId)
                    item.exhibitionDays = item.stackTotal / ((item.units | 0) / 30);
            }, self.until.items);

        }

          /**
         * Calcula los dias de stock de un item en el planograma
         * @param {number} item item
         */
        self.calcDaysInStock = function (item) {
            // item.daysInStock = item.storeInfo ? item.storeInfo.currentInventoryUnits / (item.storeInfo.units / 30) : 0;
            item.daysInStock = item.currentInventoryUnits ? item.currentInventoryUnits / (item.units / 30) : 0;
            return item.daysInStock
        }

        self.getTotalFacings = function getTotalFacings(item) {
            let rv = 0;
            if (item) {
                loopThroughAll(function (planogram, column, module, level, i) {
                    if (item.itemId == i.itemId)
                        rv += i.facings;
                }, self.until.items);
            }
            return rv;
        }

        self.memoAllItemPlanogram = {}

        self.filter = {

        }

        //autocomplete collapse

        self.isRulesCollapsed = false;
        self.isCompleteCollapsed = true;
        self.isOrderCollapsed = true;
        self.isRestrictionCollapsed = true;
        self.isApplyCollapsed = true;
        self.isResultsCollapsed = true;

        //item for autocomplete
        self.itemsForAutocomplete = [];
        self.listedItems = [];
        self.leftToRight = true;
        self.topToBottom = true;
        self.ordenation = [];
        self.selectOrder = null;
        self.rules = [];
        self.selectRule = null;
        self.sortBySize = null;
        self.asc = true;


        self.brandsSelections = [];

        self.restrictions = [];
        self.selectRestriction = null;
        self.maxFront = 0;
        self.minFront = 0;
        self.frequency = 0;

        self.clusterIds = []

        self.activeAssortmentFilter = true;


        //Funciones


        self.selectedStore = null;
        $scope.$watch('ct.selectedStore', function (newVal, oldVal) {

            if (newVal != oldVal) {
                resetSales();
                calculateSales();
            }
        });

        //$scope.$watch('ct.planograms.levels.items', function (newVal, oldVal) {
        //    console.log('$scope.$watch', newVal, oldVal, newVal != oldVal)
        //    if (newVal != oldVal) self.stateChange = true;
        //}, true)


        self.selectedCluster = {};
        $scope.$watch('ct.selectedCluster', function (newVal, oldVal) {
            if (newVal == oldVal)
                return;

            // calulate Sales in view edit
            if (newVal != undefined && self.itemList != null) {

                calculateSales();
            }
        });

        var onGlobalKeydown = function (e) {
            //si hay seleccion y no esta en foco un input
            if (self.selectedItem && !angular.element(e.target).is(":input")) {
                //flecha derecha
                if (e.keyCode === 39) {
                    // self.addFrontItem(self.activeShelf.selecteditem, self.activeShelf.selectedLevel, self.activeShelf.planogram, true);
                    self.wrapperAddFrontItem(true);
                }

                //flecha izquierda
                if (e.keyCode === 37) {
                    // self.addFrontItem(self.activeShelf.selecteditem, self.activeShelf.selectedLevel, self.activeShelf.planogram, false);
                    self.wrapperAddFrontItem(false);
                }

                //flecha arriba
                if (e.keyCode === 38) {
                    // self.addItemAbove(self.activeShelf.selecteditem, self.activeShelf.selectedLevel, self.activeShelf.planogram, true, false);
                    self.wrapperAddItemAbove(true);
                }

                //flecha abajo
                if (e.keyCode === 40) {
                    // self.addItemAbove(self.activeShelf.selecteditem, self.activeShelf.selectedLevel, self.activeShelf.planogram, false);
                    self.wrapperAddItemAbove(false);
                }

                //flecha delete
                if (e.keyCode === 46) {
                    // self.deleteItem(self.activeShelf.selecteditem, self.activeShelf.selectedLevel);
                    self.wrapperDeleteItem()
                }

                $scope.$apply();

                return false
            }

        }

        //acciones bindeadas del teclado
        $document.on('keydown', onGlobalKeydown);

        $scope.$on('$stateChangeStart', function (event) {
            if (self.isEdit() && self.hasPlanogramChanged()) {
                var answer = confirm(translations.AlertWillLoseChanges)
                if (!answer) {
                    event.preventDefault();
                }
            }
        });

        function resetSales() {

        }

        self.until = {
            planograms: 1,
            columns: 2,
            modules: 3,
            levels: 4,
            items: 5
        }

        function loopThroughAll(callbackFunction, until) {
            return new Promise(function loopThroughAllPromise(resolve, reject) {
                if (self.until[until]) {
                    reject('err - argument until is difened as: ' + until);
                }

                angular.forEach(self.model.planograms, function loopThroughAllForEachPlanograms(planogram) {
                    if (until === self.until.planograms) {
                        return callbackFunction(planogram);
                    }

                    angular.forEach(planogram.columns,
                        function loopThroughAllForEachColumns(column) {
                            if (until === self.until.columns) {
                                return callbackFunction(planogram, column);
                            }

                            angular.forEach(column.modules,
                                function loopThroughAllForEachModules(module) {
                                    if (until === self.until.modules) {
                                        return callbackFunction(planogram, column, module);
                                    }

                                    angular.forEach(module.levels,
                                        function loopThroughAllForEachLevels(level) {
                                            if (until === self.until.levels) {
                                                return callbackFunction(planogram, column, module, level);
                                            }

                                            angular.forEach(level.items,
                                                function loopThroughAllForEachItems(item) {
                                                    if (until === self.until.items) {
                                                        return callbackFunction(planogram, column, module, level, item);
                                                    }

                                                });
                                        });
                                });
                        });
                });

                resolve('success');
            });
        }

        function calculateSales() {

            angular.forEach(self.model.planograms, function calculateSalesForEachPlanograms(planogram) {

                planogram.units = 0; planogram.sales = 0; planogram.margin = 0; planogram.inventoryUnits = 0;
                planogram.storesInfo = { units: 0, sales: 0, margin: 0, inventoryUnits: 0 }

                angular.forEach(planogram.columns,
                    function calculateSalesForEachColumns(column) {
                        angular.forEach(column.modules,
                            function calculateSalesForEachModules(module) {
                                angular.forEach(module.levels,
                                    function calculateSalesForEachLevels(level) {
                                        angular.forEach(level.items,
                                            function calculateSalesForEachItems(item) {
                                                // TODO: Cargar la lista de items en un objeto dicionario y acceder por clave.
                                                // TODO: Ej: _itemDict[ item.itemId ]
                                                angular.forEach(self.itemList,
                                                    function (_itemList) {
                                                        if (_itemList.itemId == item.itemId) {
                                                            planogram.units += _itemList.units
                                                            planogram.sales += _itemList.sales
                                                            planogram.margin += _itemList.margin
                                                            planogram.inventoryUnits += _itemList.inventoryUnits
                                                            planogram.inventory += _itemList.inventory

                                                            //Copio los datos del Item desde la lista de la izquierda a los items que estan en el Planograma
                                                            item.units = _itemList.units
                                                            item.sales = _itemList.sales
                                                            item.margin = _itemList.margin
                                                            item.regularPrice = _itemList.regularPrice;
                                                            item.price = _itemList.regularPrice;
                                                            item.name = _itemList.name;
                                                            item.code = _itemList.code;
                                                            item.ean = _itemList.ean;
                                                            item.createdDate = _itemList.createdDate;
                                                            item.brand = _itemList.brand;
                                                            item.manufacturer = _itemList.manufacturer;
                                                            item.width = _itemList.width;
                                                            item.height = _itemList.height;
                                                            item.depth = _itemList.depth;
                                                            item.categoryName = _itemList.categoryName;
                                                            item.tags = _itemList.tags;
                                                            item.currentInventoryUnits = _itemList.currentInventoryUnits;
                                                            item.currentInventory = _itemList.currentInventory;
                                                            item.gmroi = item.currentInventory === 0 ? 0 : item.margin / item.currentInventory;
                                                            item.gmros = item.margin / (item.width * item.facings * item.stackAbove);
                                                            //item.inventoryUnits = _itemList.inventoryUnits; DB: no deberiamos tener el stock a nivel item tmb?

                                                            self.calcExhibitionDays(item.itemId);
                                                            self.calcDaysInStock(item);


                                                            // TODO: Cargar la lista de sotres en un objeto dicionario y acceder por clave.
                                                            // TODO: Ej: _itemStores[ item.itemId ].storesDict[ self.selectedStore.id ]
                                                            angular.forEach(_itemList.storesInfo,
                                                                function calculateSalesForEachStoreInfo(itemStore) {
                                                                    if (self.selectedStore &&
                                                                        self.selectedStore.id == itemStore.storeId) {
                                                                        item.storeInfo = itemStore;
                                                                        planogram.storesInfo.units += itemStore.units
                                                                        planogram.storesInfo.sales += itemStore.sales
                                                                        planogram.storesInfo.margin += itemStore.margin
                                                                        planogram.storesInfo.inventoryUnits += itemStore
                                                                            .inventoryUnits
                                                                    }
                                                                });
                                                            //item.gmros = item.margin / item.width * item.depth / 10000;   
                                                        }
                                                    });
                                            });
                                    });
                            });
                    });
            });

            // Fuerza una nueva referencia del planograma para que se detecte el cambio en los componentes.
            self.forceUpdateModel();
        }

        self.goToViewMode = function () {
            $state.go('spaces.viewPlanogram', { planogramId: self.planogramId });

        }

        self.goToEditMode = function () {
            $state.go('spaces.editPlanogram', { planogramId: self.planogramId });
        }

        self.isEdit = function () {
            return $state.current.url.contains('edit');
        }

        self.setAnalyzeBy = function (analyzeBy) {
            self.analyzeBy = analyzeBy
        }

        self.itemDroppedInItemsList = function (index, item, external, type) {
            return false;
        }

        self.dndDragend = function (index, item, event, dropEffect) {
            if (dropEffect === 'none') {
                //self.selectSpaceObject(item)
                //self.componentMousedown(item, event)
            }

            if (dropEffect === 'copy') {
                self.FilterByAssortments(self.currentAssortmenFilter)
            }
            calculateSales();
            return false;
        }

        self.selectPlanogram = function (planogram) {
            if (self.selectedPlanogram == null || self.selectedPlanogram.id !== planogram.id) {
                self.selectedPlanogram = planogram;
                self.updateItemData(planogram);
            } else {
                self.selectedPlanogram = null;
            }
        }

        self.getStatusDescription = function (status) {
            var rv = '';

            switch (status) {
                case 0: rv = translations.Sp_editPlanogram_new; break;
                case 1: rv = translations.Sp_editPlanogram_pendingApproval; break;
                case 2: rv = translations.Sp_editPlanogram_approved; break;
                case 3: rv = translations.Sp_editPlanogram_informed; break;
            }

            return rv;
        }

        
         /**
         * COMPONENTE VIEJO, USAR self.getLevelUsedWidth        
         */
        self.getUsedWidth = function (level, planogram) {
            var usedWidth = 0;
            var itemWidth = 0;
            angular.forEach(level.items, function (item) {
                itemWidth = item.selectedUnitOfMeasure.width;
                if ([2, 4].indexOf(item.orientation) != -1 ) 
                    itemWidth = item.selectedUnitOfMeasure.height;
                
                usedWidth += (itemWidth * item.facings);
                //usedWidth += ((item.width + (level.itemPadding * 2)) * item.facings);
            });

            //sumo el padding
            //usedWidth += level.items.length * level.itemPadding * 2;

            return usedWidth;
        }

        self.setShowDimensions = function () {
            self.showDimensions = !self.showDimensions;

            $timeout(function () {
                renderSizes();
            }, 25);


        }

        //ACA ARRANCA LO DE LAS BURBUJAS
        function calcLoadQuantity(item, level, planogram) {
            if (item == null) return;
            if (level == null) return;
            if (planogram == null) return

            return Math.round(item.stackBehind * item.stackAbove * item.facings);
        }

        self.showIconMinus = function (item) {
            if (item != null)
                return item.facings > 1 ? true : false
            else
                return false;
        }

        self.updateItemData = function (planogram) {
            //Recorro cada item de cada nivel en el planograma.
            angular.forEach(planogram.levels, function (level) {
                angular.forEach(level.items, function (item) {

                    //1. inicializo el valor del tooltip con la traduccion.
                    item.textTooltipItemOrientation = translations.ShowProfileItem;
                });
            });
        }

        self.addFrontItem = function (item, level, planogram, toAdd) {            

            if (toAdd) {
                var usedWidth = self.getLevelUsedWidth(level);
                var itemWidth = self.getItemWidth(item, true);
                //if (usedWidth + item.selectedUnitOfMeasure.width + (level.itemPadding * 2) > planogram.width) {
                if (usedWidth + itemWidth > planogram.width) {
                    swal(translations.InsufficientSpaceSwal, translations.InsufficientWidthSpaceSubtitleSwal, 'warning');
                    return;
                }
          
                item.facings += 1;
            } else {
                if (item.facings === 1) {
                    return;
                } else {
                    item.facings -= 1;
                }

            }

            //item.stackTotal = calcLoadQuantity(item, level, planogram);

            self.stateChange = true;
            //return true;
        }

        self.addItemBehind = function (item, level, planogram, toAdd) {

            var usedDepth = item.selectedUnitOfMeasure.depth * item.stackBehind;

            if (toAdd) {
                if (usedDepth + item.selectedUnitOfMeasure.depth > planogram.depth) {
                    swal(translations.InsufficientSpaceSwal, translations.InsufficientWidthSpaceSubtitleSwal, 'warning');
                    return;
                }
            }

            if (toAdd) {
                item.stackBehind += 1
            } else {
                if (item.stackBehind == 1) {
                    return
                } else {
                    item.stackBehind -= 1
                }

            }

            item.stackTotal = calcLoadQuantity(item, level, planogram);

            self.stateChange = true;
            //return true;
        }

        self.addItemAbove = function (item, level, planogram, toAdd, askForLaydown) {

            if (toAdd) {
                //agrega un item al tope de la pila

                if (askForLaydown === undefined) {
                    askForLaydown = true;
                }

                if (askForLaydown) {
                    swal({
                        title: translations.AddLayDownItemSwal,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: translations.Laydown,
                        cancelButtonText: translations.Standup,
                        showLoaderOnConfirm: false,
                        closeOnConfirm: false,
                        closeOnCancel: false
                    },
                        function (isLayDown) {
                            addItemAbove(item, level, isLayDown, askForLaydown);
                        });}
                else {
                    addItemAbove(item, level, false, askForLaydown);
                }
            } else {
                //quita el ultimo item de la pila

                if (item.stackAbove === 1) {
                    if (angular.isDefined(item.layDownItem) && item.layDownItem > 0) {
                        item.layDownItem -= 1;
                    }
                } else if (item.stackAbove > 0) {
                    item.stackAbove -= 1;
                }
            }

            item.stackTotal = calcLoadQuantity(item, level, planogram);
            self.stateChange = true;
            self.forceUpdateModel();
        }

        function addItemAbove(item, level, isLayDown, askForLaydown) {
            if (askForLaydown) {
                //Busca la nueva referencia al item seleccioado ya que esta funcion esta en un callback
                // Busca el planograma en la colección de planogramas locales.
                var planogram = self.findPlanogram(self.activePlanogram);

                // Busca la columna dentro de la colección de columnas del planograma local.
                var column = self.findColumn(planogram, self.selectedColumn);

                // Busca el módulo dentro de la colección de columnas del planograma local.
                module = self.findFurniture(column, self.selectedModule);

                level = self.findLevel(module, self.selectedLevel);

                // Busca el item dentro de la colección de items del estante local.
                item = self.findItem(level, self.selectedItem);
            }

            //calculo la altura actual considerando acostados y parados, y que orientacion tiene.
            var usedHeight = self.getItemHeight(item);
            var heightRequested = 0;
            if (isLayDown) {
                heightRequested = usedHeight + ([2, 4].indexOf(item.orientation) != -1 ? item.selectedUnitOfMeasure.width : item.selectedUnitOfMeasure.depth);
            }
            else {
                heightRequested = usedHeight + ([2, 4].indexOf(item.orientation) != -1 ? item.selectedUnitOfMeasure.width : item.selectedUnitOfMeasure.height);
            }

            if (heightRequested > level.height) {
                //no hay espacio
                swal(translations.InsufficientSpaceSwal, translations.InsufficientHeightSpaceSubtitleSwal, 'warning');
            }
            else {
                if (askForLaydown)
                    swal(translations.TheItemWasAdded, translations.Aggregate, 'success');

                if (isLayDown) {
                    item.layDownItem = angular.isDefined(item.layDownItem) ? (item.layDownItem + 1) : 1;
                    $scope.$apply();
                    self.forceUpdateModel();
                } else {
                    item.stackAbove += 1;
                    $scope.$apply();
                    self.forceUpdateModel();
                }
            }
        }

        self.rotateItem = function (item, level, planogram) {
            if (item.rotation) {
                item.rotation = undefined;
            }
            else {
                item.rotation = 90;
            }
        }

        self.changeFront = function (item, level, module, column, planogram) {
            self.stateChange = true;

            var itemAux = angular.copy(item);           

            if (!itemAux.orientation) {
                itemAux.orientation = 1;
            }

            itemAux.orientation += 1;

            if (itemAux.orientation > 4) {
                itemAux.orientation = 1;
            }

            var usedWidth = self.getLevelUsedWidth(level);
            var requiredWidth = self.getItemWidth(itemAux);
            var itemWidth = self.getItemWidth(item);

            var requiredHeight = self.getItemHeight(itemAux);

            if ((((usedWidth - itemWidth) + requiredWidth) > module.width)
                || requiredHeight > level.height) {
                swal(translations.InsufficientSpaceSwal,
                    translations.InsufficientWidthSpaceSubtitleSwal,
                    'warning');
                return false;
            }
            else {
                item.orientation = itemAux.orientation;
                return true;
            }
        }

        self.itemToFront = function (item, level, planogram) {
            self.stateChange = true;
            item.showMode = undefined;
            item.orientation = 0;
        }

        self.itemToSide = function (item, level, planogram) {
            self.stateChange = true;
            item.showMode = 'S';
        }

        self.itemToTop = function (item, level, planogram) {
            self.stateChange = true;
            item.showMode = 'T';
        }

        self.deleteItem = function (item, level) {
            var itemLocal = item;
            var levelLocal = level;
            swal({
                title: translations.SureDeleteItemSwal + ' ' + item.name + '?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: translations.RemoveSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function deleteItemConfirmCallback(isConfirm) {
                    if (isConfirm) {
                        $scope.$apply(function deleteItemScopeApply() {
                            var idx = levelLocal.items.indexOf(itemLocal);
                            levelLocal.items.splice(idx, 1);

                            self.countPlanogramItems();
                            self.stateChange = true;

                            // Deselecciona.
                            self.activePlanogram = null;
                            self.selectedColumn = null;
                            self.selectedModule = null;
                            self.selectedLevel = null;
                            self.selectedItem = null;
                            self.selectedItemId = 0;
                            self.filtereditems =[];
                            self.renderPieChartsParent(null);


                            // Actualiza la carga total del item en el planograma
                            calculateStackTotalForPlanogram(item.itemId);

                            // Actualiza los días de exibición del item en el planograma.
                            self.calcExhibitionDays(item.itemId);

                            self.forceUpdateModel();
                        });
                    }
                });
        }

        self.goToTickets = function (planogram, item) {
            //console.log('item', item);
            $state.go('spaces.tickets', { planogramLevelItemId: item.id, layoutObjectId: 0 });
        }

        //ACA TERMINA LO DE LAS BURBUJAS
        self.setShowProducts = function () {
            self.showProducts = !self.showProducts;
            $timeout(function () {
                renderSizes();
            }, 25);

            self.showProducts ? self.buttonTextProducts = translations.HideProducts : self.buttonTextProducts = translations.ShowProducts

        }

        self.setShowInformationPanel = function () {
            self.showInformationPanel = !self.showInformationPanel;
            $timeout(function () {
                renderSizes();
            }, 25);

            self.showInformationPanel ? self.buttonTextInformationPanel = translations.HideInformation : self.buttonTextInformationPanel = translations.ShowInformation
        }

        self.showLevelEditor = function (planogram) {

            self.editLevelsPlanogram = Object.assign({}, planogram);
            self.newLevel = {
                order: 'up'
            }

            var suma = 0
            angular.forEach(self.editLevelsPlanogram.levels, function (level, i) {
                level.levelNumber = i + 1;
                suma += level.height
            })
            self.editLevelsPlanogram.height = suma

            ngDialog.open({
                template: 'levelEditorDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-400',
                scope: $scope
            });
        }

        self.addLevelToPlanogram = function (planogram) {

            ngDialog.close();
        }

        self.addNewLevelToNewPlanogram = function () {
            if (self.newLevel.height) {
                if (self.newLevel.order == 'up') {
                    self.editLevelsPlanogram.levels.push({
                        categoryId: null,
                        categoryName: "",
                        height: self.newLevel.height,
                        id: 0,
                        itemPadding: 1,
                        items: [],
                        //levelNumber: self.newLevel.levelNumber,
                    })
                } else {
                    self.editLevelsPlanogram.levels.unshift({
                        categoryId: null,
                        categoryName: "",
                        height: self.newLevel.height,
                        id: 0,
                        itemPadding: 1,
                        items: [],
                        //levelNumber: self.newLevel.levelNumber,
                    })
                }

                var suma = 0
                angular.forEach(self.editLevelsPlanogram.levels, function (level, i) {
                    level.levelNumber = i + 1;
                    suma += level.height
                })
                self.editLevelsPlanogram.height = suma
            }
        }

        self.editHeightLevel = function (level, bool) {
            if (bool) {
                ++level.height
            } else {
                --level.height
            }
            var suma = 0
            angular.forEach(self.editLevelsPlanogram.levels, function (level, i) {
                level.levelNumber = i + 1;
                suma += level.height
            })
            self.editLevelsPlanogram.height = suma
        }

        self.deleteLevel = function (level) {
            var levels = []
            var i = -1
            angular.forEach(self.editLevelsPlanogram.levels, function (levelPlanogram, index) {
                if (level.levelNumber == levelPlanogram.levelNumber) {
                    i = index
                }
            })
            if (i != -1) {
                self.editLevelsPlanogram.levels.splice(i, 1);
            }
            var suma = 0
            angular.forEach(self.editLevelsPlanogram.levels, function (level, i) {
                level.levelNumber = i + 1;
                suma += level.height
            })
            self.editLevelsPlanogram.height = suma
        }

        self.calHeigtLevel = function (level) {
            var domElement = document.getElementById("containerEditPlanogram");
            var domElementSize = domElement.getBoundingClientRect()
            var height = level.height * domElementSize.width / Number(self.editLevelsPlanogram.width);

            return height;
        }

        self.calcItemWidth = function (item, level, planogram) {
            var levelWidthPx = self.calcLevelWidth(level);

            var widthPx = ((item.selectedUnitOfMeasure.width) * levelWidthPx) / planogram.width;

            return widthPx;
        }

        self.calcItemHeight = function (item, level) {

            var heightPx = item.selectedUnitOfMeasure.height * item.widthPx / item.selectedUnitOfMeasure.width;

            return heightPx;
        }

        self.calcItemDepth = function (item, level) {

            var depthPx = item.selectedUnitOfMeasure.depth * item.widthPx / item.selectedUnitOfMeasure.width;

            return depthPx;
        }

        self.calcItemSize = function (item, level, planogram) {
            item.widthPx = self.calcItemWidth(item, level, planogram);
            item.heightPx = self.calcItemHeight(item, level, planogram);
            item.depthPx = self.calcItemDepth(item, level, planogram);
        }

        self.calcLevelWidth = function (level) {

            var widthPx = 0;

            var leveldiv = document.getElementById('planogramLevel_' + level.id);

            if (leveldiv) {
                widthPx = leveldiv.clientWidth;
            }


            return widthPx;
        }

        self.doFilterApply = function (item) {

            if (self.selectedItemId && item.itemId !== self.selectedItemId) {
                return false;
            }

            if (self.selectedItem && item.itemId !== self.selectedItem.itemId) {
                return false;
            }

            if (self.search) {
                var searchAux = self.search.toLowerCase();
                if (item.name.toLowerCase().indexOf(searchAux) === -1
                    && item.code.toLowerCase().indexOf(searchAux) === -1
                    && item.ean.toLowerCase().indexOf(searchAux) === -1) {
                    return false;
                }
            }

            // TODO: Localizar o pasar a un Enum selectedStatus.
            if (self.selectedStatus === 'Nuevos' && !item.isNew) {
                return false;
            }

            if (self.selectedStatus === 'Delistados' && !item.isListed) {
                return false;
            }

            if (self.selectedStatus === 'Quiebre de Stock' && !item.isOutOfStock) {
                return false;
            }

            if (self.selectedBrand !== "!null"
                && (!item.brand || (item.brand && item.brand.toLowerCase() !== self.selectedBrand.toLowerCase()))) {
                return false;
            }

            if (self.selectedManufacturer !== "!null"
                && (!item.manufacturer ||
                    (item.manufacturer && item.manufacturer.toLowerCase() !== self.selectedManufacturer.toLowerCase()))) {
                return false;
            }

            if (self.categoryFilter !== '!null' && item.categoryId !== self.categoryFilter) {
                return false;
            }

            //Tags
            if (self.selectedTagFilter && self.selectedTagFilter.id) {
                var matchedTag = false;
                angular.forEach(item.tags, function (_tag) {

                    if (_tag.id === self.selectedTagFilter.id) {
                        matchedTag = true;
                    }
                });

                return matchedTag;

            }

            return true;
        }

        self.setHeatMap = function (displayName, variable) {
            console.log('setHEatMap', displayName, variable)
            self.heatMapVariable = displayName;
            self.heatMapVariableValue = variable;

            self.heatMapEnabled = variable === '' ? false : true;

            if (self.heatMapEnabled) {
                // Apaga el map de bloque.
                self.setGroupMap('', '');

                // Calcula los colores.
                var min = 9999999999999999;
                var max = 0;
                loopThroughAll(function (planogram, column, module, level, item) {
                    var value = item[self.heatMapVariableValue];
                    if (value > max) {
                        max = value;
                    }

                    if (value < min) {
                        min = value;
                    }
                }, self.until.items).then(function () {
                    self.getRanking();
                });

            } else {
                self.resetFillColor();
            }

            self.closeFilters();

            // Fuerza una nueva referencia del planograma para que se detecte el cambio en los componentes.
            self.forceUpdateModel();
        }

        // Visual tipo Nielsen por bloque.
        self.setGroupMap = function (displayName, variable) {
            //$log.debug('setGroupMap displayName: %o variable: %o', displayName, variable);
            self.groupMapVariable = displayName;
            self.groupMapVariableValue = variable;

            // Determina si esta habilitado o no el filtro.
            self.groupMapEnabled = variable === '' ? false : true;

            // Calula los grupos
            if (self.groupMapEnabled) {
                // apaga el mapa de calor.
                self.setHeatMap('', '');
                self.groupMap = {};
                var espacioLinealTotal = 0.0;
                var ventasTotales = 0.0;

                if (self.groupMapVariableValue.indexOf('tag:') > -1) {
                    var groupMapVariableValue = self.groupMapVariableValue.replace('tag:', '');

                    // Filtra los tags por el grupo,
                    self.groupMapTags = self.allTags.filter(function (tag) {
                        return tag.group === groupMapVariableValue;
                    });
                } else {
                    self.groupMapTags = [];
                }

                // Obtiene el planograma y calcula el area.
                // TODO: Verificar que el planograma traiga los valores de ancho y alto.
                var planogram = self.selectedPlanogram;
                var totalLinearSpace = 0;

                // Recorre todos los items de todos los niveles del planograma.
                loopThroughAll(function (planogram, column, module, level, item) {
                    //var itemFull = self.getItem(item.itemId);
                    var blockKey = self.getGroupMapBlockKey(item, self.groupMapVariableValue, self.groupMapTags);

                    if (angular.isDefined(blockKey) && !angular.isDefined(self.groupMap[blockKey])) {
                        var name = self.getGroupMapName(item, self.groupMapVariableValue, self.groupMapTags);
                        self.groupMap[blockKey] = {
                            color: 'FFFFFF',
                            name: name,
                            shareVentas: 0.0,
                            shareEspacios: 0.0,
                            share: 0.0,
                            espaciosLineal: 0.0,
                            ventasTotales: 0.0,
                            orden: 0
                        };
                    }

                    var group = self.groupMap[blockKey];
                    var espaciosLineal = self.getLinearSpace(item);
                    group.espaciosLineal += espaciosLineal;
                    totalLinearSpace += espaciosLineal;

                    // TODO: Ver propiedad.
                    group.ventasTotales += item.sales || 0.0;

                    espacioLinealTotal += espaciosLineal;
                    ventasTotales += item.sales || 0.0;
                }, self.until.items);

                var groupMapAux = [];
                var keys = Object.keys(self.groupMap);
                angular.forEach(keys, function (key, idx) {
                    var group = self.groupMap[key];

                    // Asigna un color al grupo.
                    group.color = self.colors[idx];

                    // Para calcular el share de espacio = espacio lineal del item /  (ancho x alto del planograma)
                    // Share de espacio de un grupo = suma de espacios de productos en ese grupo / suma de espacios totales
                    group.shareEspacios = group.espaciosLineal / totalLinearSpace;

                    // Share de venta de un grupo = suma de ventas de productos en ese grupo / suma de ventas total.
                    group.shareVentas = group.ventasTotales / ventasTotales;

                    // La divisi?n entre Share de venta / Share de espacio. Si da un numero > 1 entonces est? sobreespaciado. Menor a 1 est? subespaciado.
                    group.share = group.shareVentas / group.shareEspacios;

                    // Agrega al array auxiliar.
                    groupMapAux.push(group);
                });

                // Ordena por shareVentas de mayor a menor y lo invierte.
                self.groupMapList = groupMapAux.sort(function (a, b) {
                    var ret;
                    if (a.shareVentas > b.shareVentas) {
                        ret = 1;
                    } else if (a.shareVentas < b.shareVentas) {
                        ret = -1;
                    } else {
                        // a must be equal to b
                        ret = 0;
                    }

                    return ret;
                }).reverse();

                // Recorre todos los items de todos los niveles del planograma.
                loopThroughAll(function (planogram, column, module, level, item) {
                    var itemFull = self.getItem(item.itemId);
                    var blockKey = self.getGroupMapBlockKey(itemFull, self.groupMapVariableValue, self.groupMapTags);
                    var fill = '#' + self.groupMap[blockKey].color;
                    item.fill = fill;
                    item.opacity = 0.2;
                }, self.until.items);

            } else {
                self.groupMapList = [];
                self.groupMapTags = [];
                self.resetFillColor();
            }

            self.closeFilters();

            // Fuerza una nueva referencia del planograma para que se detecte el cambio en los componentes.
            self.forceUpdateModel();
        };

        self.resetFillColor = function () {
            loopThroughAll(function (planogram, column, module, level, item) {
                item.fill = 'none';
                item.opacity = 1;
            }, self.until.items);
        };

        self.getItem = function (itemId) {
            var idx = self.itemList.findIndex(function (item) {
                return item.itemId === itemId;
            });

            var ret = self.itemList[idx];
            return ret;
        }

        self.getGroupMapBlockKey = function (item, groupMapVariableValue, tagsValues) {
            var ret;
            if (groupMapVariableValue.indexOf('tag:') > -1) {
                groupMapVariableValue = groupMapVariableValue.replace('tag:', '');
                tagsValues = tagsValues || self.allTags.filter(function (tag) {
                    return tag.id === groupMapVariableValue;
                });
                var idx = tagsValues.findIndex(function (tagVal) {
                    return item.tags.findIndex(function (tag) {
                        return tag.id === tagVal.id;
                    }) > -1;
                });

                if (idx > -1) {
                    ret = tagsValues[idx].id;
                } else {
                    ret = translations.NotInformed;
                }
            } else {
                ret = String(item[groupMapVariableValue]).toLowerCase().trim();
            }

            return ret;
        }

        self.getBlockMapStyle = function (item) {
            return {
                'background-color': '#' + item.color
            };
        };

        /**
         * Devuelve el nombre de un grupo.
         * @param {any} item
         */
        self.getGroupMapName = function (item, groupMapVariableValue, tagsValues) {
            var ret;
            var idx;
            if (groupMapVariableValue.indexOf('tag:') > -1) {
                groupMapVariableValue = groupMapVariableValue.replace('tag:', '');
                tagsValues = tagsValues || self.allTags.filter(function (tag) {
                    return tag.group === groupMapVariableValue;
                });
                idx = tagsValues.findIndex(function (tagVal) {
                    return item.tags.findIndex(function (tag) {
                        return tag.id === tagVal.id;
                    }) > -1;
                });

                if (idx > -1) {
                    ret = tagsValues[idx].name.trim();
                } else {
                    ret = translations.NotInformed;
                }
            } else if (groupMapVariableValue === 'categoryId') {
                ret = item.categoryName.trim();
            } else {
                ret = String(item[groupMapVariableValue]).trim();
            }

            return ret;
        }

        /**
         * Devuelve el espacio lineal de un item.
         * @param {any} item
         */
        self.getLinearSpace = function (item) {
            return item.selectedUnitOfMeasure.width * item.facings;
            // Calcula el espacio lineal de cada item.
            // - Si la orientacion del item(propiedad Orientation de  SpacePlanogramLevelItems)  es = 0, 1, 3 u 11 entonces espacio lineal = Width del item X los facings(numero de frentes). 
            // - Si la orientacion del item es 2 o 4 => height del item X facings
            // - Si la orientacion del item es 12, 21 o 22 => depth del item x facings
            var ret;
            if ([0, 1, 3, 11].indexOf(item.orientation) > -1) {
                ret = item.width * item.facings;
            } else if ([2, 4].indexOf(item.orientation) > -1) {
                ret = item.height * item.facings;
            } else if ([12, 21, 22].indexOf(item.orientation) > -1) {
                ret = item.depth * item.facings;
            } else {
                ret = 0.0;
            }
            //console.log(item.name + ' = ' + ret + ' ' + item.facings, item);
            return ret;
        };


        self.getBlockMap = function () {
            return self.groupMapList;
        }

        self.openChat = function (item) {
            //console.log('openchat', item);
            self.editItem = item;

            if (!self.editItem.chats)
                self.editItem.chats = [];

            ngDialog.open({
                template: 'itemChatDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            });
        }

        self.addChatToItem = function (newChat) {
            self.editItem.chats.push({
                user: authService.authentication.userName,
                message: newChat,
                date: new Date()
            });

            self.newChat = '';
        }

        self.closeChat = function () {
            ngDialog.close();
        }

        self.getMaxHeight = function () {
            var domElement = document.getElementsByClassName("testClass");
            var max = 0;
            angular.forEach(domElement, function (e) {
                var rect = e.getBoundingClientRect();
                if (max < rect.height)
                    max = rect.height;
            })
            //console.log('max', max);
            return max + 30;
        }

        self.getSelectedPlanogramBorder = function (planogram) {
            if (self.selectedPlanogram == null || self.isEdit())
                return 0;

            if (self.selectedPlanogram.id == planogram.id)
                return 1;
            return 0;
        }

        self.setItemLayoutForPlanogram = function (planogram, justifyContent) {
            planogram.justifyContent = justifyContent;
        }

        self.countPlanogramItems = function () {
            var count = 0;
            if (self.selectedPlanogram != null) {

                loopThroughAll(function countPlanogramItemsLoopThroughAll(planogram, column, module, level, item) {
                    count += 1;
                }, self.until.items).then(function () {
                    self.selectedPlanogram.totalItem = count;
                });
            }

            return count;
        }

        self.getItemsTotal = function (items, variable) {
            var rv = 0;
            angular.forEach(items, function (item) {
                rv += item[variable];
            });
            return rv;
        }

        self.filterByStatus = function (status) {

            clearFilteredItems();

            self.selectedStatus = status;

            filterBy();

        }

        //Animacion cuando cierra la topbar, si no hay filtros seleccionados
        self.closeSelectedFilters = function () {
            if (self.selectedBrand == '!null' && self.selectedManufacturer == '!null' && self.selectedTagFilter == null) {
                return 'closeFilters';
            }
        }
        //Borra todos los filtros seleccionados
        self.cleanAllFilter = function () {
            self.selectedBrand = '!null';
            self.selectedManufacturer = '!null';
            self.selectedTagFilter = null;

            clearFilteredItems();

            $timeout(function () {
                self.showChipsFilter = false;
            }, 200);


        }

        self.clearSelectedFilter = function (filter) {

            clearFilteredItems();

            if (filter === 'brand') {
                self.selectedBrand = '!null';
            }

            if (filter === 'manufacturer') {
                self.selectedManufacturer = '!null';
            }

            if (filter === 'tag') {
                self.selectedTagFilter = null;
            }


            if (self.selectedBrand == '!null' && self.selectedManufacturer == '!null' && self.selectedTagFilter == null) {
                $timeout(function () {
                    self.showChipsFilter = false;
                }, 200);
            }


            filterBy();
        }

        function filterBy() {

            //get filtered items
            loopThroughAll(
                function filterByLoopThroughAll(planogram, column, module, level, item) {
                    if (self.doFilterApply(item)) {
                        self.filteredItems.push(item);
                    }
                },
                self.until.items)
                .then(function filterByThen() {

                    // console.log(self.filteredItems);
                    //$log.debug('EditPlanogramCtrl::filterBy filteredItems: %o', self.filteredItems);

                    self.closeFilters();

                    //render charts
                    // renderPieCharts(null);
                    $timeout(1).then(function () {
                        self.renderPieChartsParent(null);
                        self.updateModel();
                    });
                });
        }

        self.filterByBrand = function (brand) {

            self.showChipsFilter = true;

            clearFilteredItems();

            self.selectedBrand = brand;

            filterBy();
        }

        self.filterByManufacturer = function (manufacturer) {

            self.showChipsFilter = true;

            clearFilteredItems();

            self.selectedManufacturer = manufacturer;

            filterBy();
        }

        self.filterByTag = function (tag) {

            self.showChipsFilter = true;

            clearFilteredItems();

            self.selectedTagFilter = tag;

            filterBy();
        }

        self.showNewTicketModal = function (item) {
            self.newTicket = GenerateNewTicket();

            self.editItem = item;

            if (!self.editItem.tickets)
                self.editItem.tickets = [];

            ngDialog.open({
                template: 'ticketNewDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-500',
                scope: $scope
            });
        }

        self.openTicketDelist = function (tipo) { // TODO: A ESTE LO LLAMAS VOS DESDE EL OTRO POPUP, TOCANDO CUALQUIERA DE LOS 2 BOTONES (PARAMETRO TIPO = 'retirar' o 'liquidar'
            self.newTicket = GenerateNewTicket();
            var ticketType = {};
            angular.forEach(self.ticketTypes, function (type) {

                if (type.id == 8 && tipo == 'liquidar')
                    ticketType = type;
                if (type.id == 10 && tipo == 'retirar')
                    ticketType = type11;
            })
            self.newTicket.title = ticketType;
            self.newTicket.description = "Se pide " + tipo + " el item ";
            self.newTicket.priority = "Alta";


            ngDialog.open({
                template: 'ticketNewDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-500',
                scope: $scope
            });
        }

        self.showDelistPopup = function (item) { //PASO 1, CREAR EL SCRIPT DEL POPUP NUEVO, QUE TENGA 2 BOTONES QUE LLAMEN A ct.openTicketDelist('retirar') o ct.openTicketDelist('liquidar') 1: Liquidar Producto; 2: Pedir Retiro 
            if (item.tag == 'Delistado') {

                if (!item.tickets)
                    item.tickets = [];
                self.editItem = item;

                ngDialog.open({
                    template: 'modal-tag-delistado',
                    className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-500',
                    scope: $scope
                });
            }
        }

        self.savePlanogram = function (status) {

            var swaltitle = translations.SavePlanogramSwalTitle;

            if (!self.hasPermissionToEdit) {
                swal(translations.ErrorTitleSwal, translations.InsufficientPermissionsToEdit, "error");
                return;
            }

            if (status == 1) {
                if (!self.hasPermissionToEdit) {
                    swal(translations.ErrorTitleSwal, translations.InsufficientPermissionsToEditPlanogram, "error");
                    return;
                }

                swaltitle = translations.FinishThePlanogram;
            }
            else if (status == 2) {
                if (!self.hasPermissionToEndorse) {
                    swal(translations.ErrorTitleSwal, translations.InsufficientPermitsToApprove, "error");
                    return;
                }

                swaltitle = translations.YouWantToApproveThePlanogram;
            }
            else if (status == 3) {
                if (!self.hasPermissionToInform) {
                    swal(translations.ErrorTitleSwal, translations.InsufficientPermissionsToReport, "error");
                    return;
                }
                swaltitle = translations.CommunicateThePlanogram;
            }

            swal({
                title: swaltitle,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        if (!self.isSaving) {

                            self.isSaving = true;
                            //self.polygonContainer[0].points = pixelsToPercentage(self.polygonContainer[0].pointsPx);

                            var planogramDto = {
                                id: self.selectedPlanogram.id,
                                companyId: self.selectedPlanogram.companyId,
                                name: self.selectedPlanogram.name,
                                code: self.selectedPlanogram.code,
                                categoryId: self.selectedPlanogram.categoryId,
                                categoryName: self.selectedPlanogram.categoryName,
                                orientationRightToLeft: self.selectedPlanogram.orientationRightToLeft,
                                width: self.selectedPlanogram.width,
                                height: self.selectedPlanogram.height,
                                depth: self.selectedPlanogram.depth,
                                clusters: self.selectedPlanogram.clusters,
                                //subCategories: self.selectedPlanogram.subCategories,
                                columns: self.selectedPlanogram.columns,
                                stores: self.selectedPlanogram.stores,
                            }

                            var data;

                            //if (self.showPlanogramComponentSvg()) {
                            //    data = angular.copy(self.activePlanogram);
                            //} else {
                            //    data = angular.copy(self.selectedPlanogram);
                            //}

                            //FIX calculo totalItems
                            self.countPlanogramItems();

                            //se prepara el modelo y se borran las propiedades que no estan en el modelo para enviar al servidor
                            data = angular.copy(self.selectedPlanogram);

                            data.inventory = data.inventory || null;

                            delete data.subCategories;
                            delete data.storesInfo;
                            delete data.units;
                            delete data.sales;
                            delete data.margin;
                            delete data.inventoryUnits;
                            delete data.inventory;

                            angular.forEach(data.columns, function (column) {
                                delete column.selected;
                                delete column.height;
                                delete column.depth;
                                delete column.widthPercentage;
                                angular.forEach(column.modules,
                                    function (module) {
                                        delete module.selected;
                                        delete module.widthPercent;
                                        delete module.heightPx;
                                        delete module.widthPx;
                                        angular.forEach(module.levels,
                                            function (level) {
                                                delete level.selected;
                                                delete level.heightPx;
                                                delete level.paddingItemsPx;
                                                delete level.usedWidth;
                                                angular.forEach(level.items,
                                                    function (item) {
                                                        if (angular.isNumber(item.positionX)) {
                                                            item.positionX = item.positionX.toFixed(4);
                                                        }

                                                        if (angular.isNumber(item.positionY)) {
                                                            item.positionY = item.positionY.toFixed(4);
                                                        }

                                                        delete item.id;
                                                        item.itemCode = item.itemCode || item.code;
                                                        item.itemEan = item.itemEan || item.ean;
                                                        item.ticketCount = item.ticketCount || 0;
                                                        delete item.filtered;
                                                        delete item.widthPx;
                                                        delete item.heightPx;
                                                        delete item.depthPx;
                                                        delete item.textTooltipItemOrientation;
                                                        delete item.isListed;
                                                        delete item.isOutOfStock;
                                                        delete item.isNewItem;
                                                        delete item.unitOfMeasures;
                                                        delete item.storesInfo;
                                                        delete item.showByAssortment;
                                                        delete item.code;
                                                        delete item.ean;
                                                        delete item.imageUrl;
                                                        delete item.weight;
                                                        delete item.baseUnitOfMeasureId;
                                                        delete item.createdDate;
                                                        delete item.monthsForNewItem;
                                                        delete item.sales;
                                                        delete item.units;
                                                        delete item.margin;
                                                        delete item.price;
                                                        delete item.daysOutOfStock;
                                                        delete item.inventory;
                                                        delete item.inventoryUnits;
                                                        delete item.currentInventory;
                                                        delete item.currentInventoryUnits;
                                                        delete item.inventoryDays;
                                                        delete item.gmros;
                                                        delete item.gmroi;
                                                        delete item.regularPrice;
                                                        delete item.uniqueId;
                                                        delete item.exhibitionDays;
                                                        delete item.selected;
                                                        delete item.parentCategoryId;
                                                        delete item.parentCategoryName;

                                                    });
                                            });
                                    });
                            });

                        
                              
                            spaceService.planograms.editPlanogram(data, status).then(function (res) {
                                self.stateChange = false;

                                self.selectedPlanogram.status = status;

                                swal(translations.SavedPlanogramSwal,
                                    translations.SavedPlanogramaSwalSubtitle,
                                    'success');
                                        
                                self.isSaving = false;

                                //creo una copia fresca para la deteccion de cambios
                                makeCopyForChangeDetection();
                               
                                }, function () {

                                    swal(translations.ErrorTitleSwal, translations.AnErrorHasOccurred, "error");
                                    self.isSaving = false;
                                });                           
                        }
                    }
                    else {

                    }
                });
        }

        function isValidField(field) {
            if (field == undefined || field == null || field == '')
                return false;

            return true;
        }

        self.saveNewTicket = function () {
            if (!isValidField(self.newTicket.description) && !isValidField(self.newTicket.priority)) {
                swal(translations.ValidationSwalTitle, translations.ValidationSwalSubtitle, 'error');
                return;
            }

            var dataTicket = {
                ticketTypeId: self.newTicket.title.id,
                priority: self.newTicket.priority.id,
                description: self.newTicket.description,
                planogramLevelItemId: self.editItem.id
            }
            spaceService.tickets.saveTicket(dataTicket).then(function (res) {
                $timeout(function () {
                    self.editItem.ticketCount++;
                });
                ngDialog.close();
                swal(translations.SavedTicketSwal, translations.SavedTicketSwalSubtitle, 'success');

            });
        }

        self.filterBySubCategory = function (categoryId) {
            self.categoryFilter = categoryId;
        }

        self.selectedItemList = function (item) {
            self.selectedItem = null;
            if (item == null || (self.selectedItemId && item.id === self.selectedItemId)) {
                //deselect
                if (self.isEdit()) {
                    self.selectedItemId = null;
                }
            }
            else {
                //select
                if (self.isEdit()) {
                    self.selectedItemId = item.itemId;
                }
            }
        }

        function clearFilteredItems() {
            //deselect item
            self.activePlanogram = null;
            self.selectedColumn = null;
            self.selectedModule = null;
            self.selectedLevel = null;

            self.selectedItemId = 0;
            self.selectedItem = null;


            //Clear filtered items
            self.filteredItems = [];
        }

        self.selectItem = function (item) {

            clearFilteredItems();

            //Cuando selecciono un producto, saco el resto de los filtros.
            self.categoryFilter = '!null';
            self.selectedBrand = '!null';
            self.selectedManufacturer = '!null';
            self.selectedStatus = '!null';
            self.selectedTagFilter = null;

            self.selectedItemId = item.itemId;
            self.selectedItem = item;

            filterBy();
        }


        self.selectTags = function (tag) {
            self.selectedTag = tag;
            self.textSelectedTag = tag;
        }

        self.selectStatus = function (status) {
            self.selectedStatus = status;
            self.textSelectedStatus = status;

            //self.FilterByExhibition(status);
        }

        self.countItemActive = 0;
        self.countItemWithoutExhibition = 0;
        self.countItemDelist = 0;
        self.calcNumItems = function () {
            self.countItemActive = 0;
            self.countItemWithoutExhibition = 0;
            self.countItemWithExhibition = 0;
            self.countItemDelist = 0;
            self.countItemNew = 0;
            var memoItemsIdWithoutExhibition = [];
            //var memoItemsIdDelist = [];

            angular.forEach(self.itemList, function (_item) {
                if (_item.isNewItem) self.countItemNew++

                if (_item.isListed) {
                    self.countItemActive++
                    self.countItemWithoutExhibition++

                    loopThroughAll(function (planogram, column, module, level, item) {
                        if (item.itemId == _item.itemId && memoItemsIdWithoutExhibition.indexOf(item.itemId) == -1) {
                            self.countItemWithoutExhibition--;
                            self.countItemWithExhibition++;
                            memoItemsIdWithoutExhibition.push(item.itemId);
                        }
                    }, self.until.items);

                } else {
                    //LG: Antes considerabamos un Delistado cuando estaba exhibido y su status era delistado
                    //LG: Ahora no tomamos en cuenta si está o no está exhibido.
                    self.countItemDelist++;
                    //memoItemsIdDelist.push(item.itemId);
                    /*
                    loopThroughAll(function (planogram, column, module, level, item) {
                        if (item.itemId == _item.itemId && memoItemsIdDelist.indexOf(item.itemId) == -1) {
                            self.countItemDelist++;
                            memoItemsIdDelist.push(item.itemId);
                        }
                    }, self.until.items);
                    */
                }
            });
        }

        self.productStatus = ''
        self.currentAssortmenFilter = '';

        self.showNoProductsImage = function () {
            if (translations == null
                || (self.productStatus == translations.Actives && self.countItemActive == 0)
                || (self.productStatus == translations.WithoutExhibition && self.countItemWithoutExhibition == 0)
                || (self.productStatus == translations.WithExhibition && self.countItemWithExhibition == 0)
                || (self.productStatus == translations.Delist && self.countItemDelist == 0)
                || (self.productStatus == translations.New && self.countItemNew == 0)
            )
                return true;
            else
                return false;

        }

        self.FilterByAssortments = function (status, accordionStatus) {
            if (accordionStatus != null)
                accordionStatus.isCustomHeaderOpen = false;

            self.currentAssortmenFilter = status;
            self.calcNumItems()
            if (status === translations.Actives) {
                angular.forEach(self.itemList, function (_item) {
                    _item.showByAssortment = false;
                    self.productStatus = translations.Actives;
                    if (_item.isListed) {
                        _item.showByAssortment = true;
                    }
                });
            } else if (status === translations.WithoutExhibition) {
                angular.forEach(self.itemList, function (_item) {
                    _item.showByAssortment = false;
                    if (_item.isListed) {
                        _item.showByAssortment = true;
                        self.productStatus = translations.WithoutExhibition;
                        loopThroughAll(function (planogram, column, module, level, item) {
                            if (item.itemId == _item.itemId) {
                                _item.showByAssortment = false;
                            }
                        }, self.until.items)
                    }
                });
            } else if (status === translations.WithExhibition) {
                angular.forEach(self.itemList, function (_item) {
                    _item.showByAssortment = false;
                    self.productStatus = translations.WithExhibition;
                    if (_item.isListed) {
                        loopThroughAll(function (planogram, column, module, level, item) {
                            if (item.itemId == _item.itemId) {
                                _item.showByAssortment = true;
                            }
                        }, self.until.items)
                    }
                });
            } else if (status === translations.Delist) {
                angular.forEach(self.itemList, function (_item) {
                    _item.showByAssortment = false;
                    self.productStatus = translations.Delist;
                    if (!_item.isListed) {
                        _item.showByAssortment = true;
                        //LG: antes se mostraban como Delistados aquellos items delistados que ademas estaban en el Planograma.
                        //LG: Comentamos la condicion de que esten en el planograma ya que la vamos a pasar a un Filtro de Estado.
                        /*
                        loopThroughAll(function (planogram, level, item) {
                            if (item.itemId == _item.itemId) {
                                _item.showByAssortment = true;
                            }
                        }, self.until.items);
                        */
                    }
                });
            }
            else if (status === translations.New) {
                angular.forEach(self.itemList, function (_item) {
                    _item.showByAssortment = false;
                    self.productStatus = translations.New;
                    if (_item.isNewItem) {
                        _item.showByAssortment = true;
                    }
                });
            }
            else {
                self.activeAssortmentFilter = false;
                angular.forEach(self.itemList, function (_item) {
                    _item.showByAssortment = false;
                })
            }

            // Actualiza el modelo.
            self.updateModel();
        }

        self.getFilterColor = function () {
            var tag = angular.copy(self.selectedTag);
            var rv = ""

            if (tag == "Premium")
                rv = "#BB3385";

            else if (tag == "Regional")
                rv = "#CC6C4C";

            else if (tag == "Ninguno")
                rv = "#686B6D";

            return rv;
        }

        self.getInfoTitle = function () {
            var label = '';
            if (self.selectedItem) {
                if (self.selectedItem.ean)
                    return ((self.selectedItem.ean || self.selectedItem.itemEan) + '\n' + self.selectedItem.name);
                else
                    return ((self.selectedItem.code || self.selectedItem.itemCode) + '\n' + self.selectedItem.name);
            }

            if (self.selectedBrand != '!null') {
                label += translations.Brand + ': ' + self.selectedBrand;
            }

            if (self.selectedManufacturer !== '!null') {
                if (label !== '') {
                    label += ', ';
                }

                label += translations.Manufacturer + ': ' + self.selectedManufacturer;
            }

            if (self.selectedTagFilter) {
                if (label !== '') {
                    label += ', ';
                }

                label += self.selectedTagFilter.group + ': ' + self.selectedTagFilter.name;
            }

            if (self.filterByTextValue) {
                if (label !== '')
                    label += ', ';

                label += 'Busqueda: ' + self.filterByTextValue;
            }

            return label;
        }

        self.filterItemListByTextSearch = function () {
            return function (item) {
                if (item
                    && (!self.search
                        || item.name.toLowerCase().indexOf(self.search.toLowerCase()) !== -1
                        || (item.code && item.code.toLowerCase().indexOf(self.search.toLowerCase()) !== -1)
                        || (item.ean && item.ean.toLowerCase().indexOf(self.search.toLowerCase()) !== -1))
                    && (self.categoryFilter === '!null' || item.categoryId === self.categoryFilter)
                    && (self.selectedBrand === '!null' || item.brand === self.selectedBrand)
                    && item.showByAssortment === self.activeAssortmentFilter)
                    return true;

                return false;
            };
        }

        self.onSearchTextChange = function () {
            var filterFunc = self.filterItemListByTextSearch();

            self.filteredItems = [];
            angular.forEach(self.itemList, function (item) {
                if (filterFunc(item)) {
                    self.filteredItems.push(item);
                }
            });

            this.updateModel();
        }

        self.filterByText = function (event) {
            var search = self.filterByTextValue + event.key;

            self.search = search;

            //get filtered items
            var filteredItems = [];
            loopThroughAll(function (planogram, column, module, level, item) {
                if (self.doFilterApply(item)) {
                    filteredItems.push(item);
                }
            }, self.until.items);

            //render charts
            // renderPieCharts(null);
            $timeout(1).then(function () {
                self.renderPieChartsParent(null);
            });

            this.updateModel();
        }

        self.rankingHeatMap = [];
        self.getRanking = function () {

            var rv = [];

            if (self.heatMapEnabled) {
                var allItems = [];
                var total = 0;
                self.rankCount = 0;
                loopThroughAll(function (planogram, column, module, level, item) {
                    allItems.push(item);
                    total += item[self.heatMapVariableValue];
                },
                    self.until.items)
                    .then(function (response) {
                        rv = allItems.sort(function (a, b) {
                            if (self.heatMapVariableValue === 'price'
                                || self.heatMapVariableValue === 'currentInventoryUnits') {
                                return a[self.heatMapVariableValue] - b[self.heatMapVariableValue];
                            } else {
                                return b[self.heatMapVariableValue] - a[self.heatMapVariableValue];
                            }
                        });
                        var accumulatedFrequency = 0;
                        var accumulatedPercentage = 0;
                        angular.forEach(rv,
                            function (_item, i) {
                                accumulatedFrequency += _item[self.heatMapVariableValue];
                                _item.AccumulatedFrequency = accumulatedFrequency;
                                _item.percentage = (_item[self.heatMapVariableValue] / total);
                                _item.AccumulatedPercentage = accumulatedPercentage;
                                accumulatedPercentage += _item.percentage;
                                _item.rankCount = i + 1;

                                var fill;
                                if (_item.AccumulatedPercentage <= 0.50) {
                                    fill = '#009900';
                                } else if (_item.AccumulatedPercentage <= 0.90) {
                                    fill = '#ffcc00';
                                } else if (_item.AccumulatedPercentage <= 1) {
                                    fill = '#ff1a1a';
                                }

                                if (!_item[self.heatMapVariableValue]) {
                                    fill = '#ff0000';
                                }

                                _item.fill = fill;
                                _item.opacity = 0.2;
                            });

                        self.rankingHeatMap = rv;

                        self.updateModel();
                    });
            }

        }

        /**
         * Actualiza los datos filtrados en el modelo.
         */
        self.updateModel = function updateModel() {

            var cache = self.filteredItems.reduce(function (ret, item) {
                var key = String(item.itemId);
                ret[key] = true;
                return ret;
            }, {});
            // Apaga los items filtrados.
            angular.forEach(self.model.planograms,
                function updateModelForEachPlanograms(planogramLocal) {
                    angular.forEach(planogramLocal.columns,
                        function updateModelForEachColumns(columnLocal) {
                            columnLocal.selected = false;
                            angular.forEach(columnLocal.modules,
                                function updateModelForEachModules(moduleLocal) {
                                    moduleLocal.selected = false;
                                    angular.forEach(moduleLocal.levels,
                                        function updateModelForEachLevels(levelLocal) {
                                            levelLocal.selected = false;
                                            angular.forEach(levelLocal.items,
                                                function updateModelForEachItems(itemLocal) {
                                                    var key = String(itemLocal.itemId);
                                                    var filtered = angular.isDefined(cache[key]);
                                                    itemLocal.filtered = filtered;
                                                });
                                        });
                                });
                        });
                });

            self.forceUpdateModel();
        }

        self.getRankCount = function () {
            return ++self.rankCount;
        }

        self.renderPieChartsParent = renderPieCharts;

        function renderPieCharts(itemsGroup) {

            var espaciosLinealGroup = 0;
            var espacioLinealTotal = 0;
            var vantasGroup = 0;
            var ventasTotales = 0;

            var label = self.getInfoTitle();

            angular.forEach(itemsGroup, function (item) {
                var espaciosLineal = self.getLinearSpace(item);
                espaciosLinealGroup += espaciosLineal;
                vantasGroup += item.sales | 0;
            });

            loopThroughAll(function renderPieChartsLoopThroughAll(planogram, column, module, level, item) {
                var espaciosLineal = self.getLinearSpace(item);
                espacioLinealTotal += espaciosLineal;
                ventasTotales += item.sales | 0;
            },
                self.until.items);

            // Para calcular el share de espacio = espacio lineal del item /  (ancho x alto del planograma)
            // Share de espacio de un grupo = suma de espacios de productos en ese grupo / suma de espacios totales
            var shareSpace = espaciosLinealGroup / (espacioLinealTotal | 1);

            // Share de venta de un grupo = suma de ventas de productos en ese grupo / suma de ventas total.
            var shareSales = vantasGroup / (ventasTotales | 1);

            // La divisi?n entre Share de venta / Share de espacio. Si da un numero > 1 entonces est? sobreespaciado. Menor a 1 est? subespaciado.
            self.spaceResult = shareSales > shareSpace ? translations.Subspaced : translations.Overpaced;
            self.spaceResultIcon = shareSales > shareSpace ? 'up' : 'down';
            self.spaceResultColor = shareSales > shareSpace ? 'danger' : 'primary';

            var itemSales = vantasGroup;
            var totalSales = ventasTotales | 1;
            var itemSpace = espaciosLinealGroup;
            var totalSpace = espacioLinealTotal;

            self.tooltipSpaced = translations.Sp_editPlanogram_spaced + (((shareSpace / shareSales) - 1) * 100).toFixed(0) + ' %';

            self.getPercentageSales = function () {

                self.percentageSales = ((itemSales / totalSales) * 100).toFixed(2) + '%';

                self.getAnimationSales();

                return {
                    'width': + (itemSales / totalSales) * 100 + '%'
                };

            }

            self.getPercentageSpace = function () {

                self.percentageSpaces = ((itemSpace / totalSpace) * 100).toFixed(2) + '%'

                self.getAnimationSpace();

                return {
                    'width': + (itemSpace / totalSpace) * 100 + '%'
                };
            }

            self.getAnimationSales = function () {
                var animationSales = document.querySelectorAll(".percentage-graphic-sales");

                var elem = 0;
                while (elem < animationSales.length) {
                    animationSales[elem].style.width = '0%';
                    animationSales[elem].style.transition = "width 0.2s ease-in-out 0.2s";
                    animationSales[elem].style.width = self.percentageSales;
                    elem++;
                };

            }

            self.getAnimationSpace = function () {
                var animationSpace = document.querySelectorAll(".percentage-graphic-space");

                var elem = 0;
                while (elem < animationSpace.length) {
                    animationSpace[elem].style.width = '0%';
                    animationSpace[elem].style.transition = "width 0.2s ease-in-out 0.2s";
                    animationSpace[elem].style.width = self.percentageSpaces;
                    elem++;
                };

            }


            if (self.analyzeBy == 'cluster') {
                //var shareSales = self.getPlanogramTotal('sales') / self.getItemsTotal(items, 'sales');
                //var shareSpace = self.selectedPlanogram.width / self.getItemsTotal(items, 'width');

            } else {
                //var shareSales = self.getPlanogramTotal('salesInStore') / self.getItemsTotal(items, 'salesInStore');
                //var shareSpace = self.selectedPlanogram.width / self.getItemsTotal(items, 'width');

            }

            //Share Ventas
            var chart = c3.generate({
                bindto: '#saleschart',
                size: {
                    width: 230
                },
                title: {
                    text: translations.SalesShare
                },
                legend: {
                    show: false
                },
                data: {
                    columns: [
                        [label || (itemsGroup && itemsGroup.length > 0 ? itemsGroup[0].name : ''), itemSales],
                        [translations.SalesRestProducts, totalSales - itemSales],
                    ],
                    type: 'pie'
                }
            });

            //Share Espacio
            var chart = c3.generate({
                bindto: '#spacechart',
                size: {
                    width: 230
                },
                title: {
                    text: translations.SpaceShare
                },
                legend: {
                    show: false
                },
                data: {
                    columns: [
                        [label || (itemsGroup && itemsGroup.length > 0 ? itemsGroup[0].name : ''), itemSpace],
                        [translations.SpaceRestProcucts, totalSpace - itemSpace],
                    ],
                    type: 'pie'
                }
            });


        }

        self.getPlanogramWidth = function (planogram) {
            var totalWidth = 0;
            angular.forEach(self.model.planograms,
                function getPlanogramWidthForEachPlanograms(p) {
                    totalWidth += p.width;
                });

            return planogram.width * 100 / totalWidth;
        }

        function GenerateNewTicket() {
            return {
                id: 0,
                status: 'Nuevo',
                title: '',
                description: '',
                type: 'Item',
                priority: '',
                created: new Date(),
                update: new Date(),
                user: authService.authentication.userName,
                comments: [],
                watch: true
            };
        }


        self.storeClicked = function (store) {
            self.selectedStore = store;
        }

        self.clusterClicked = function (cluster) {
            self.selectedCluster = cluster;
        }

        self.getItemStyles = function (item, isHideItems) {
            var styles = {
                'color': item.fill
            }

            return styles;
        }

        //autocomplete function
        self.clearItemPlanograma = function () {
            swal({
                title: translations.SureCleanPlanogramSwal,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: translations.Yes,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        self.executeClearItemPlanograma();
                        $scope.$apply();
                        // Fuerza una nueva referencia del planograma para que se detecte el cambio.
                        self.forceUpdateModel();
                    }

                });
        }

        self.executeClearItemPlanograma = function () {
            loopThroughAll(function (planogram, column, module, level, item) {
                level.items = []
            }, self.until.levels);
        }

        self.setSelectCluster = function (cluster) {
            //self.filter.cluster = cluster.name;
            self.textClusters = cluster.name;
        }

        function filterBySubCategory(element) {
            return element.categoryId === subCategory.id;
        }

        self.showAddRuleModal = function () {

            ngDialog.open({
                template: 'addRuleDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-500',
                scope: $scope
            });
        }

        self.okAddRuleModal = function (typeRule) {
            if (typeRule === "assignFronts") {
                self.rules.push({
                    type: typeRule,
                    minFront: self.minFront,
                    maxFront: self.maxFront,
                    frequency: self.frequency
                });
            }

            if (typeRule === "assignFrontsGMROI") {
                self.rules.push({
                    type: typeRule,
                    minFront: self.minFrontGMROI,
                    maxFront: self.maxFrontGMROI,
                    GMROI: self.ratioGMROI
                });
            }

            if (typeRule === "assignFrontsGMROS") {
                self.rules.push({
                    type: typeRule,
                    minFront: self.minFrontGMROS,
                    maxFront: self.maxFrontGMROS,
                    GMROS: self.ratioGMROS
                });
            }

            if (typeRule === "percentageSpace") {

                var criterioValues = [];

                switch (self.percentageSpaceType) {
                    case 'marcas':
                        criterioValues.push(self.rulePercentageSpaceBrandSelection);
                        break;
                    case 'fabricantes':
                        criterioValues.push(self.rulePercentageSpaceManufacturerSelection);
                        break;
                    case 'tags':
                        angular.forEach(self.rulePercentageSpaceTagsSelections, function (tag) {
                            criterioValues.push(tag);
                        });
                        break;
                    default:
                        break;
                }

                self.rules.push({
                    type: typeRule,
                    percentageSpaceType: self.percentageSpaceType,
                    criterioValues: criterioValues,
                    percentage: self.rulePercentageSpaceValue
                });
            }

            ngDialog.close();
        }
        self.getCriterioRuleDescription = function (rule) {
            switch (rule.percentageSpaceType) {
                case 'marcas':
                    return 'Marca';
                case 'fabricantes':
                    return 'Fabricante';
                case 'tags':
                    return 'Etiquetas';
                default: break;
            }
        }

        self.getCriterioRuleValueDescriptions = function (rule) {
            switch (rule.percentageSpaceType) {
                case 'marcas':
                    return rule.criterioValues[0];
                case 'fabricantes':
                    return rule.criterioValues[0];
                case 'tags':
                    var value = '';
                    for (var i = 0, l = rule.criterioValues.length; i < l; i++) {
                        value += rule.criterioValues[i].name + '; ';
                    }

                    return value;
                default: break;
            }
        }

        self.deleteRule = function (typeRule) {
            var foundRule = self.rules.find(function (e) {
                return e.type === typeRule.type;
            });

            if (!foundRule) return;

            if (typeRule.type === "percentageSpace") {
                angular.forEach(self.listedItems, function (item) {
                    item.facings = null;
                    item.facingsMax = null;
                    item.facingsPlanogram = null;
                });
            }

            var index = self.rules.indexOf(foundRule);

            self.rules.splice(index, 1);
        }

        self.selectHorizontalDirection = function (direction) {
            self.leftToRight = direction;
        }

        self.selectVerticalDirection = function (direction) {
            self.topToBottom = direction;
        }

        self.showAddOrderModal = function () {

            ngDialog.open({
                template: 'addOrderDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-500',
                scope: $scope
            });
        }

        self.okAddOrderModal = function (typeOrder) {
            if (typeOrder === 'size') {
                self.ordenation.push({
                    type: self.sortBySize,
                    asc: self.asc,
                    typeDesc: self.sortBySizeDesc
                });
            } else {
                self.ordenation.push({
                    type: typeOrder,
                    asc: self.asc,
                    typeDesc: self.selectOrderDesc
                });
            }

            self.asc = true;
            self.sortBySize = null;
            self.selectOrder = null;
            self.selectOrderDesc = null;
            self.sortBySizeDesc = null;
            ngDialog.close();
        }

        self.deleteOrder = function (typeOrder) {
            var foundOrder = self.ordenation.find(function (e) {
                return e.type === typeOrder.type;
            });

            if (!foundOrder) return;

            var index = self.ordenation.indexOf(foundOrder);

            self.ordenation.splice(index, 1);
        }

        self.showAddRestrictionModal = function () {

            ngDialog.open({
                template: 'addRestrictionDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-500',
                scope: $scope
            });
        }

        self.okAddRestrictionModal = function (typeRestriction) {

            var isExists;
            if (typeRestriction === "tags") {
                isExists = false;
                angular.forEach(self.restrictions,
                    function (restriction) {
                        if (restriction.type === "tags") {
                            restriction.tags = self.tagsSelections;
                            isExists = true;
                        }
                    });
                if (!isExists) {
                    self.restrictions.push({
                        type: typeRestriction,
                        tags: self.tagsSelections
                    });
                }
            }

            if (typeRestriction === "subcategories") {
                isExists = false;
                angular.forEach(self.restrictions,
                    function (restriction) {
                        if (restriction.type === "subcategories") {
                            restriction.subcategories = self.subcategoriesSelections;
                            isExists = true;
                        }
                    });
                if (!isExists) {
                    self.restrictions.push({
                        type: typeRestriction,
                        subcategories: self.subcategoriesSelections
                    });
                }
            }

            if (typeRestriction === "fabricantes") {
                isExists = false;
                angular.forEach(self.restrictions,
                    function (restriction) {
                        if (restriction.type === "fabricantes") {
                            restriction.manufacturers = self.manufacturerSelection;
                            isExists = true;
                        }
                    });
                if (!isExists) {
                    self.restrictions.push({
                        type: typeRestriction,
                        manufacturers: self.manufacturerSelection
                    });
                }
            }

            if (typeRestriction === "marcas") {
                isExists = false;
                angular.forEach(self.restrictions,
                    function (restriction) {
                        if (restriction.type === "marcas") {
                            restriction.brands = self.brandSelection;
                            isExists = true;
                        }
                    });
                if (!isExists) {
                    self.restrictions.push({
                        type: typeRestriction,
                        brands: self.brandSelection
                    });
                }
            }

            angular.forEach(self.restrictions, function (restriction) {
                restriction.getDescription = function () {
                    var values = null;

                    if (restriction.brands) {
                        values = restriction.brands;
                    }

                    if (restriction.manufacturers) {
                        values = restriction.manufacturers;
                    }

                    if (restriction.tags) {
                        values = restriction.tags;
                    }

                    if (restriction.subcategories) {
                        values = restriction.subcategories;
                    }

                    // return `${restriction.type}: ${values.join(',')}`;
                    return restriction.type + ':' + values.join(',');
                }
            });

            self.actualizarListadoItemsByRestrictions();

            ngDialog.close();
        }

        self.getRestrictionValues = function (restriction) {
            var values = null;

            if (restriction.brands)
                values = restriction.brands;
            if (restriction.manufacturers)
                values = restriction.manufacturers;
            if (restriction.tags)
                values = restriction.tags;
            if (restriction.subcategories)
                values = restriction.subcategories;

            return values.join(',');
        }

        self.deleteRestriction = function (restrictionType) {

            var foundRestriction = self.restrictions.find(function (e) {
                return e.type === restrictionType.type;
            });

            if (!foundRestriction) return;

            var index = self.restrictions.indexOf(foundRestriction);

            switch (restrictionType.type) {
                case "tags":
                    foundRestriction.tags = [];
                    self.tagsSelections = [];
                    break;
                case "subcategories":
                    foundRestriction.subcategories = [];
                    self.subcategoriesSelections = [];
                    break;

                case "fabricantes":
                    foundRestriction.manufacturers = [];
                    self.manufacturerSelection = [];
                    break;

                case "marcas":
                    foundRestriction.brands = [];
                    self.brandSelection = [];
                    break;

                default:
                    break
            }
            self.restrictions.splice(index, 1);
            self.actualizarListadoItemsByRestrictions();
        }

        self.actualizarListadoItemsByRestrictions = function () {

            self.itemsForAutocomplete = self.filterItemListForAutocomplete();

        }

        function rulesPromise(_items, _rules) {
            return new Promise(function (resolve, reject) {
                if (_rules.length > 0) {

                    angular.forEach(_rules, function (rule) {
                        if (rule.type == "assignFronts") {
                            resolve(applyRuleAssignFronts(_items, rule.minFront, rule.maxFront, rule.frequency))
                        }

                        if (rule.type == "assignFrontsGMROI") {
                            resolve(applyRuleAssignFrontsGMROI(_items, rule.minFront, rule.maxFront, rule.GMROI))
                        }

                        if (rule.type == "assignFrontsGMROS") {
                            resolve(applyRuleAssignFrontsGMROS(_items, rule.minFront, rule.maxFront, rule.GMROS))
                        }

                        if (rule.type == "percentageSpace") {
                            resolve(applyRulePercentageSpace(_items, rule));
                        }
                        //if (rule.type == "tags") {
                        //_items = _items.filter(function (element) {
                        //  console.log('into filterByTags', element, restriction.tags)
                        //  var result = false
                        //  angular.forEach(element.tags, function (tag) {
                        //    angular.forEach(restriction.tags, function (tagRestriction) {
                        //      if (tag.id == tagRestriction.id) {
                        //        result = true
                        //      }
                        //    })
                        //  })
                        //  return result
                        //})
                        //_item = applyRestrictionFilterTags(_items, restriction.tags)
                        //}
                    })
                    //console.log('items before resolve', _items)
                    //resolve(_items)
                } else {
                    resolve(_items)
                    //reject('error')
                }

            })
        }

        function applyRuleAssignFronts(items, minFront, maxFront, frequency) {
            var depthPlanogram = self.selectedPlanogram.depth
            angular.forEach(items, function (item) {
                if (item.units && self.selectedCluster.totalStoreInCluster) {
                    item.numItems = frequency * item.units / self.selectedCluster.totalStoreInCluster;
                    item.numItemsForClusters = frequency * item.units;
                    item.itemPerFront = Math.floor(depthPlanogram / item.depth);
                    item.numFrontAutocomplete = Math.round(item.numItems / item.itemPerFront);
                    item.facings = item.numFrontAutocomplete > maxFront ? maxFront : item.numFrontAutocomplete < minFront ? minFront : item.numFrontAutocomplete;
                } else {
                    item.facings = minFront;
                }
            });

            return items
        }

        function applyRuleAssignFrontsGMROI(items, minFront, maxFront, GMROI) {
            var depthPlanogram = self.selectedPlanogram.depth
            angular.forEach(items, function (item) {
                if (item.gmroi === GMROI) {
                    item.itemPerFront = Math.floor(depthPlanogram / item.depth);
                    item.numFrontAutocomplete = Math.round(item.numItems / item.itemPerFront);
                    item.numFront = item.numFrontAutocomplete > maxFront ? maxFront : item.numFrontAutocomplete < minFront ? minFront : item.numFrontAutocomplete;
                    item.facings = item.numFront;
                } else {
                    item.facings = minFront;
                }
            });

            return items
        }

        function applyRuleAssignFrontsGMROS(items, minFront, maxFront, GMROS) {
            var depthPlanogram = self.selectedPlanogram.depth
            angular.forEach(items, function (item) {
                if (item.gmros === GMROS) {
                    item.itemPerFront = Math.floor(depthPlanogram / item.depth);
                    item.numFrontAutocomplete = Math.round(item.numItems / item.itemPerFront);
                    item.numFront = item.numFrontAutocomplete > maxFront ? maxFront : item.numFrontAutocomplete < minFront ? minFront : item.numFrontAutocomplete;
                    item.facings = item.numFront;
                } else {
                    item.facings = minFront;
                }
            });

            return items
        }

        function applyRulePercentageSpace(items, rule) {


            var depthPlanogram = self.selectedPlanogram.depth;

            angular.forEach(items, function (item) {

                var maxNumItemsLevel = Math.floor((self.selectedPlanogram.width ? self.selectedPlanogram.width : 0) / (item.width + (self.selectedPlanogram.levels[0].itemPadding * 2))); // x frentes por nivel

                var maxNumItems = Math.floor(maxNumItemsLevel * self.selectedPlanogram.levels.length)  //x frentes por planograha al 100%
                var facingsMaxPlanogram = Math.floor(maxNumItems * parseInt(self.rulePercentageSpaceValue) / 100);  //X% en todo el planograma

                switch (rule.percentageSpaceType) {
                    case 'marcas':
                        if (item.brand == rule.criterioValues[0]) {
                            //item.numFront = percentageItems;
                            //item.facings = percentageItems;
                            item.facingsMax = maxNumItemsLevel;
                            item.facingsPlanogram = facingsMaxPlanogram;
                        }
                        break;
                    case 'fabricantes':
                        if (item.manufacturer == rule.criterioValues[0]) {
                            //item.numFront = percentageItems;
                            //item.facings = percentageItems;
                            item.facingsMax = maxNumItemsLevel;
                            item.facingsPlanogram = facingsMaxPlanogram;
                        }
                        break;
                    case 'tags':
                        angular.forEach(rule.criterioValues, function (tag) {
                            if (!item.tags) return;
                            if (item.tags.length == 0) return;

                            var foundTag = item.tags.find(function (t) {
                                return t.id === tag.id;
                            });

                            if (foundTag) {
                                //item.numFront = percentageItems;
                                //item.facings = percentageItems;
                                item.facingsMax = maxNumItemsLevel;
                                item.facingsPlanogram = facingsMaxPlanogram;
                            }
                        });

                        break;
                    default:
                        break;
                }
            });

            return items
        }

        function applyRestrictionFilterTags(items, tags) {

            function filterByTags(element) {
                var result = false
                angular.forEach(element.tags, function (tag) {
                    angular.forEach(tags, function (tagRestriction) {
                        if (tag.id == tagRestriction.id) {
                            result = true
                        }
                    })
                })
                return result
            }

            items = items.filter(filterByTags)
            return items
        }


        var arrayItemBig = []

        function insertItemInLevel(items, index, i_level, planogram, i_column, i_module, verticalDirection, horizontalDirection) {
            if (items.length === 0) {
                return planogram
            } else {
                //[firstItem, ...rest] = items;
                var rest = items.slice();
                var firstItem = rest.shift()
                var column = planogram.columns[i_column];
                var module = column.modules[i_module];
                var level = module.levels[i_level];
                var usedWidth = self.getLevelUsedWidth(level);
                var itemWidth = self.getItemWidth(firstItem);
                var itemHeight = self.getItemHeight(firstItem);

                if (itemHeight > level.height) {
                    arrayItemBig.push(firstItem)
                    return insertItemInLevel(rest, index, i_level, planogram, i_column, i_module, verticalDirection, horizontalDirection);

                } else if (usedWidth + ((itemWidth + (level.itemPadding * 2)) * firstItem.facings) > module.width) {
                    if (verticalDirection) {
                        if (i_level == 0) {
                            if (i_column == planogram.columns - 1) {
                                return planogram;
                            } else {
                                return insertItemInLevel(items, resetIndex = 0, i_level = module.levels.length - 1, planogram, ++i_column, i_module, verticalDirection, horizontalDirection);
                            }
                        } else {
                            var resetIndex = 0
                            return insertItemInLevel(items, resetIndex, --i_level, planogram, i_column, i_module, verticalDirection, horizontalDirection);
                        }
                    } else {
                        if (i_level == module.levels.length - 1) {
                            if (i_column == planogram.columns - 1) {
                                return planogram;
                            } else {
                                return insertItemInLevel(items, resetIndex = 0, i_level = 0, planogram, ++i_column, i_module, verticalDirection, horizontalDirection);
                            }
                        } else {
                            var resetIndex = 0
                            return insertItemInLevel(items, resetIndex, ++i_level, planogram, i_column, i_module, verticalDirection, horizontalDirection);
                        }
                    }

                } else {
                    firstItem.planogramLevelId = level.id;
                    firstItem.id = guid();
                    firstItem.order = index;
                    firstItem.stackBehind = 1;
                    firstItem.stackAbove = 1;
                    firstItem.stackTotal = self.calculateLoadQuantity(firstItem);

                    self.calcItemSize(firstItem, level, module);

                    if (horizontalDirection) {
                        level.items.unshift(firstItem)
                    } else {
                        level.items.push(firstItem)
                    }

                    return insertItemInLevel(rest, horizontalDirection ? ++index : --index, i_level, planogram, i_column, i_module, verticalDirection, horizontalDirection)

                }

            }
        }

        function MultiCriteriaSorting(items, criteria, asc, criteria1, asc1) {
            //console.log('into MultiCriteriaSorting', items, criteria, asc, criteria1, asc1)
            var conditionLeft;
            var conditionRight;
            var conditionLeft1;
            var conditionRight1;

            items.sort(function (a, b) {
                if (criteria === 'area') {
                    conditionLeft = a.height * a.width
                    conditionRight = b.height * b.width
                } else if (criteria) {
                    conditionLeft = a[criteria]
                    conditionRight = b[criteria]
                } else {
                    return
                }

                if (criteria1 === 'area') {
                    conditionLeft1 = a.height * a.width;
                    conditionRight1 = b.height * b.width;
                } else if (criteria1) {
                    conditionLeft1 = a[criteria1];
                    conditionRight1 = b[criteria1];
                } else {
                    //return
                }

                if (conditionLeft == conditionRight) {
                    //return 0;
                    if (asc1) {
                        return conditionLeft1 - conditionRight1;
                    } else {
                        return conditionRight1 - conditionLeft1;
                    }
                }

                if (asc) {
                    return conditionLeft - conditionRight
                } else {
                    return conditionRight - conditionLeft
                }

            })

        }

        self.selectSortBySize = function (measure) {
            self.sortBySize = measure;
        }

        self.hasRules = function () {
            return angular.isDefined(self.rules) && self.rules.length > 0;
        }

        self.generateAutocomplete = function () {
            swal({
                title: translations.SureGenerateAutocompletePlanogramSwal,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: translations.Yes,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        self.runAutocompleteEngine();
                    }

                });
        }

        self.executeGenerateAutocomplete = function () {

            var planogram = self.selectedPlanogram;
            var items = self.itemsForAutocomplete;
            var i_level = 0
            var index = 0
            var verticalDirection = self.topToBottom
            var horizontalDirection = self.leftToRight

            loopThroughAll(function (planogram, column, module, level, item) {

                if (module.levels.length < 1) {
                    swal(translations.WithoutLevelsSwal, translations.WithoutLevelsSubtitleSwal, 'warning');
                    return
                }

                if (verticalDirection) {
                    i_level = module.levels.length - 1
                }
            }, self.until.modules);

            if (self.ordenation.length > 0) {

                MultiCriteriaSorting(items, self.ordenation[0].type, self.ordenation[0].asc, self.ordenation[1] ? self.ordenation[1].type : null, self.ordenation[1] ? self.ordenation[1].asc : null)
            }

            rulesPromise(items, self.rules)
                .then(function (result) {

                    function calcLevels(items) {
                        var copia = items.slice();

                        for (var i = 0; i < items.length; i++) {
                            var item = items[i];
                            if (!item.facingsPlanogram) continue;

                            var div = item.facingsPlanogram / item.facingsMax;
                            var mod = item.facingsPlanogram % item.facingsMax;

                            var found = copia.find(function (e) { return e.itemId = item.itemId; });

                            if (!found) continue;
                            var index = copia.indexOf(found);

                            if (div > 1) {
                                item.facings = item.facingsMax;
                            }
                            else if (div < 1) {
                                item.facings = 1;
                            }

                            for (var j = 1; j < Math.floor(div); j++) {
                                var newItem = angular.copy(item);
                                newItem.facings = item.facingsMax;
                                copia.splice(++index, 0, newItem);
                            }
                            if (mod > 0) {
                                var newItem = angular.copy(item);
                                newItem.facings = mod;
                                copia.splice(++index, 0, newItem);
                            }
                        }

                        return copia;
                    }

                    var result = calcLevels(result);

                    insertItemInLevel(result, index, /*i_level*/ 0, planogram, 0, 0, verticalDirection, horizontalDirection)
                    self.stateChange = true;
                    $scope.$apply();

                })
                .catch(function (err) {
                    console.log('catch in rulesPromise', err);
                })

        }

        var planogramRemainingSpace = 0;
        //presets (borrar luego)
        self.ordenation = [{ type: "height", asc: false, typeDesc: "Alto" }];
        //self.restrictions = [{ type: "marcas", brands: ['COCA COLA'] }];
        //end presets

        self.rules = [{ type: "assignFronts", minFront: 2, maxFront: 4, targetExhibitionDays: 1, frequency: 0 }];
        self.runAutocompleteEngine = function () {

            //limpio el planograma
            self.executeClearItemPlanograma();

            //validations for selected strategy
            if (self.rules[0].type == "assignFronts") {
                if (!self.rules[0].minFront) {
                    alert('Falta completar el campo: Mínimo de Frentes');
                    return;
                }
                if (!self.rules[0].maxFront) {
                    alert('Falta completar el campo: Máximo de Frentes');
                    return;
                }
            }
            if (self.rules[0].type == "exhibitionDays") {
                if (!self.rules[0].targetExhibitionDays) {
                    alert('Falta completar el campo: Días de Exhibición objetivo.');
                    return;
                }
                if (!self.rules[0].maxFront) {
                    alert('Falta completar el campo: Máximo de Frentes');
                    return;
                }
            }

            //selected setup
            var planogram = self.selectedPlanogram;
            var verticalDirection = self.topToBottom;
            var horizontalDirection = self.leftToRight;

            var minFacings = self.rules[0].minFront;
            var maxFacings = self.rules[0].maxFront;
            var targetExhibitionDays = self.rules[0].targetExhibitionDays;

            var ruleDefinition = {
                type: self.rules[0].type,
                facings: self.rules[0].minFront,
                targetExhibitionDays: self.rules[0].targetExhibitionDays
            }

            //filtering: los filtros ya se aplicaron automaticamente
            var items = self.filterItemListForAutocomplete();

            //sorting
            if (self.ordenation.length > 0) {
                MultiCriteriaSorting(items, self.ordenation[0].type, self.ordenation[0].asc, self.ordenation[1] ? self.ordenation[1].type : null, self.ordenation[1] ? self.ordenation[1].asc : null)
            }

            //espacio remanente utilizable
            var totalPlanogramSpace = planogram.columns.reduce((totalWidth, col) => totalWidth += (col.width * col.modules[0].levels.length), 0);
            planogramRemainingSpace = totalPlanogramSpace;

            //acumulado de ventas del planograma
            var totalSales = 0;

            var usedItems = [];
            var excludedItems = [];


            //Realiza el autocompletado segun la estrategia de días de exhibicion
            if (self.rules[0].type == "exhibitionDays") {

                //único round completando en el orden definido por el usuario y la cantidad de dias de exhibicion que corresponda.
                angular.forEach(items, function (item) {

                    var currItemSpace = tryAddItemToPlanogram(item, ruleDefinition);

                    //si se agregó el item descuento el espacio.
                    if (currItemSpace > 0) {
                        usedItems.push(item);
                        totalSales += item.sales;
                        planogramRemainingSpace -= currItemSpace;
                    }
                    else {
                        excludedItems.push(item);
                    }
                });
            }

            //Realiza el autocompletado segun la estrategia de frentes minimos y maximos 
            if (self.rules[0].type == "assignFronts") {


                //aplano todos los levels a recorrer
                var orderedLevels = planogram.columns.reduce((colLevels, col) => colLevels.concat(col.modules.reduce((modLevels, mod) => modLevels.concat(mod.levels), [])), []);

                if (self.topToBottom)
                    orderedLevels = orderedLevels.reverse();


                //primer round completando minimo de frentes
                angular.forEach(items, function (item) {

                    var currItemSpace = tryAddItemToPlanogram(item, ruleDefinition);

                    //si se agregó el item descuento el espacio.
                    if (currItemSpace > 0) {
                        usedItems.push(item);
                        totalSales += item.sales;
                        planogramRemainingSpace -= currItemSpace;
                    }
                    else {
                        excludedItems.push(item);
                    }
                });

                //distribuyo el espacio remanente asignando frentes adicionales a los mejores items segun variable seleccionada
                angular.forEach(usedItems, function (item) {
                    item.planogramSalesShare = item.sales / totalSales;
                    var extraFacings = Math.round((planogramRemainingSpace * item.planogramSalesShare) / item.selectedUnitOfMeasure.width);
                    item.facings += extraFacings;

                    //hago el cap con los frentes maximos
                    if (extraFacings > maxFacings)
                        item.facings = maxFacings;

                    console.debug(item.facings, item.name);
                });

                console.debug(`Espacio utilizado: ${totalPlanogramSpace - planogramRemainingSpace} de ${totalPlanogramSpace}`)

                //libero el planograma y reseteo el espacio remanente
                self.executeClearItemPlanograma();
                planogramRemainingSpace = totalPlanogramSpace;

                //segundo round completando con los frentes finales
                angular.forEach(usedItems, function (item) {

                    ruleDefinition.facings = item.facings;
                    var currItemSpace = tryAddItemToPlanogram(item, ruleDefinition);

                    //si se agregó el item descuento el espacio.
                    if (currItemSpace > 0) {
                        planogramRemainingSpace -= currItemSpace;
                    }
                    else {
                        excludedItems.push(item);
                    }
                });

                console.debug(`Espacio utilizado: ${totalPlanogramSpace - planogramRemainingSpace} de ${totalPlanogramSpace}`)

                //tercer round tratando de acomodar los excluidos
                angular.forEach(excludedItems, function (item) {

                    ruleDefinition.facings = item.facings;
                    var currItemSpace = tryAddItemToPlanogram(item, ruleDefinition);

                    //si se agregó el item descuento el espacio.
                    if (currItemSpace > 0) {
                        planogramRemainingSpace -= currItemSpace;
                    }
                });
            }


            console.debug(`Espacio utilizado: ${totalPlanogramSpace - planogramRemainingSpace} de ${totalPlanogramSpace}`)
            console.log(excludedItems);

            self.autocompleteTotalItems = items.length;
            self.autocompleteExcludedItems = excludedItems;
            self.autocompleteUsedItems = usedItems;

            $scope.$apply();

            // Fuerza una nueva referencia del planograma para que se detecte el cambio.
            self.forceUpdateModel();
        }


        function tryAddItemToPlanogram(item, ruleDef) {

            //si el item no entra en el planograma devuelvo -1
            var itemUsedSpace = -1;

            if (planogramRemainingSpace > 0) {

                angular.forEach(self.selectedPlanogram.columns, function (column) {
                    angular.forEach(column.modules, function (module) {
                        angular.forEach(module.levels, function (level, ix) {

                            //si todavia no coloque el item y entra en el estante
                            if (itemUsedSpace === -1 && tryAddItemToLevel(module, level, item, ruleDef)) {

                                //si entro acutalizo el espacio usado por el item
                                itemUsedSpace = item.selectedUnitOfMeasure.width * item.facings;
                            }
                        });
                    })
                });

            }

            return itemUsedSpace;
        }

        function tryAddItemToLevel(module, level, item, rule) {
            var itemAdded = false;
            var itemHeight = self.getItemHeight(item);
            var itemWidth = self.getItemWidth(item, true);

            //primero evalúo que entra bien en el alto del estante
            if (level.height >= itemHeight) {

                var facings = rule.facings;
                var stackBehind = 1;

                if (rule.type == "exhibitionDays") {
                    //Si la estrategia es dias de exhibicion

                    //DDE = (Fronts * StackBehind) / (Units / 30)
                    //Fronts = (DDE * (Units / 30)) / StackBehind

                    //cuantos entran hacia atras
                    stackBehind = Math.floor(module.depth / item.selectedUnitOfMeasure.depth);

                    facings = Math.ceil((rule.targetExhibitionDays * (item.units / 30)) / stackBehind);
                }

                var levelRemainingSpace = module.width - self.getLevelUsedWidth(level);
                if (levelRemainingSpace >= itemWidth * facings) {
                    //entra bien de ancho con la cantidad de frentes definidos
                    itemAdded = true;

                    //actualizo el item para agregarlo al estante
                    item.planogramLevelId = level.id;
                    item.id = guid();
                    item.order = level.length + 1;
                    item.facings = facings;
                    item.stackBehind = stackBehind;
                    item.stackAbove = 1;
                    item.stackTotal = self.calculateLoadQuantity(item);

                    //coloco el item en el estante
                    if (!self.leftToRight) {
                        level.items.unshift(item)
                    } else {
                        level.items.push(item)
                    }
                }
            }

            return itemAdded;
        }

        self.filterItemListForAutocomplete = function () {

            return self.listedItems.filter(function (item) {

                var passesSubcategoriesFilter = self.restrictions.filter((x) => x.type == 'subcategories').length > 0 ? false : true;
                angular.forEach(self.restrictions.filter((x) => x.type == 'subcategories').map((x) => x.subcategories).flat(), function (subcategoryFilter) {
                    if (item.categoryId == subcategoryFilter.id) {
                        passesSubcategoriesFilter = true;
                    }
                });

                var passesTagsFilter = self.restrictions.filter((x) => x.type == 'tags').length > 0 ? false : true;
                angular.forEach(self.restrictions.filter((x) => x.type == 'tags').map((x) => x.tags).flat(), function (tagFilter) {
                    angular.forEach(item.tags, function (tag) {
                        if (tag.id == tagFilter.id) {
                            passesTagsFilter = true;
                        }
                    })
                });

                var passesBrandFilter = self.restrictions.filter((x) => x.type == 'marcas').length > 0 ? false : true;
                angular.forEach(self.restrictions.filter((x) => x.type == 'marcas').map((x) => x.brands).flat(), function (brandFilter) {
                    if (item.brand && item.brand.trim() === brandFilter.trim()) {
                        passesBrandFilter = true;
                    }
                });

                var passesManufacturerFilter = self.restrictions.filter((x) => x.type == 'fabricantes').length > 0 ? false : true;
                angular.forEach(self.restrictions.filter((x) => x.type == 'fabricantes').map((x) => x.manufacturers).flat(), function (manufacturerFilter) {
                    if (item.brand && item.brand.trim() === manufacturerFilter.trim()) {
                        passesManufacturerFilter = true;
                    }
                });


                return item.isListed && passesTagsFilter && passesSubcategoriesFilter && passesManufacturerFilter && passesBrandFilter;
            });

        }

        //end autocomplete function

        self.getUniqueItemsCount = function (planogram) {

            var allItems = [];
            angular.forEach(planogram.columns, function (column) {
                angular.forEach(column.modules, function (module) {
                    angular.forEach(module.levels, function (level) {
                        angular.forEach(level.items, function (item) {
                            allItems.push(item);
                        });
                    });
                })
            });

            var groupedItems = _(allItems)
                .groupBy('code')
                .map((items, code) => ({ code, count: items.length }))
                .value();

            return groupedItems.length;
        }

        self.showPhotoAudit = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                component: 'photoAuditComponent',
                windowClass: 'app-modal-window',
                resolve: {
                    modalInfo: function () {
                        return {
                            dataSource: 'dataSource'
                        };
                    }
                }
            });
        }

        self.goItem = function (itemId) {
            $state.go('admin.editItem', { itemId: itemId });
        }

        self.showKpisModal = function (itemForModal, selectedClusterForModal) {

            var newScope = $scope.$new();
            newScope.params = { itemForModal: itemForModal, selectedClusterForModal: selectedClusterForModal.id };

            ngDialog.open({
                template: 'kpisDialog',
                className: 'ngdialog ngdialog-theme-default custom-width-950',
                scope: newScope
            });
        }


        self.exportToExcel = function () {
            spaceService.planograms.buildPlanogramItemsReport($stateParams.planogramId).then(function (reportUrl) {
                $window.open(reportUrl, '_blank');

                //var downloadFrame = document.createElement("iframe");
                //downloadFrame.setAttribute('src', reportUrl);
                //downloadFrame.setAttribute('class', "screenReaderText");
                //document.body.appendChild(downloadFrame);
            });
        }

        self.printPlanogram = function () {

            swal({
                title: translations.EnterFurnitureToPrint,
                text: translations.EnterFurnitureToPrintSubtitle,
                type: "input",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (inputValue) {
                    if (inputValue === false) return;

                    var planogramToPrint = angular.copy(self.model.planograms[0]);

                    //saco todos los espacios
                    inputValue = inputValue.replace(/\s/g, '');

                    if (inputValue) {
                        var totalColumns = self.model.planograms[0].columns.length;

                        if (isNaN(inputValue) && inputValue.indexOf('-') != -1) {

                            //Si ingreso un rango
                            var from = parseInt(inputValue.split('-')[0]);
                            var to = parseInt(inputValue.split('-')[1]);

                            if (isNaN(from) || from <= 0 || from > totalColumns
                                || isNaN(to) || to <= 0 || to > totalColumns
                                || inputValue.split('-').length != 2) {
                                swal.showInputError(translations.InvalidRank);
                                return;
                            }

                            var finalColumns = [];
                            for (var col = 1; col <= planogramToPrint.columns.length; col++) {
                                if (col >= from && col <= to) {
                                    planogramToPrint.columns[col - 1].overwriteId = col;
                                    finalColumns.push(planogramToPrint.columns[col - 1]);
                                }
                            }

                            //solo imprimo las columnas seleccionadas
                            planogramToPrint.columns = finalColumns;

                        }
                        else if (parseInt(inputValue) > 0 && parseInt(inputValue) <= totalColumns) {

                            //Si ingresa solo un numero
                            var number = parseInt(inputValue);

                            //solo imprimo las columnas seleccionadas                           
                            planogramToPrint.columns = [self.model.planograms[0].columns[number - 1]];
                            planogramToPrint.columns[0].overwriteId = number;
                        }
                        else {
                            swal.showInputError(translations.InvalidRankSubtitle);
                            return;
                        }

                    }


                    if (planogramToPrint) {

                        swal({
                            title: translations.ConfigurationFinished,
                            text: translations.AllReadyToPrintThePlanogram,
                            type: "success",
                            confirmButtonText: translations.Print,
                        }, function () {


                            self.exportToPdf(planogramToPrint);
                        });

                    }

                });

        }


        self.exportToPdf = function (planogram) {

            var currentSelectedPlanogram = self.selectedPlanogram;
            self.selectedPlanogram = planogram;

            // Recorre todos los items de todos los niveles del planograma.
            // var width = planogram.width;

            // Inicia una promesa vacia.
            var promises = [$q.when()];
            var planogramHeight = 0;
            var planogramWidth = 0;

            // TODO: Usar la url de publicación del sistema.
            // var imageBaseUrl = '//prisma-tatauruguay.iantech.net/';
            var imageBaseUrl = '';

            angular.forEach(planogram.columns, function (column) {
                planogramWidth += column.width;
                var columnHeight = 0;
                angular.forEach(column.modules, function (module) {
                    var moduleHeight = 0;
                    angular.forEach(module.levels, function (level) {
                        moduleHeight += level.height;
                        angular.forEach(level.items, function (item) {
                            //        //var itemFull = self.getItem(item.itemId);
                            //        //item.label1 = itemFull.price ? '$ ' + itemFull.price.toFixed(2) : '';
                            //        //item.label2 = itemFull.code;
                            //        item.label1 = item.storeInfo && item.storeInfo.price ? '$ ' + item.storeInfo.price.toFixed(2) : '';
                            //        item.label2 = item.selectedUnitOfMeasure.itemCode;

                            // Se pre cargan las images del planograma.
                            var promise;
                            if (angular.isString(item.selectedUnitOfMeasure.imagePlanogramFront)) {
                                promise = imageService.load(imageBaseUrl + item.selectedUnitOfMeasure.imagePlanogramFront);
                                promises.push(promise);
                            }

                            if (angular.isString(item.selectedUnitOfMeasure.imagePlanogramLeft)) {
                                promise = imageService.load(imageBaseUrl + item.selectedUnitOfMeasure.imagePlanogramLeft);
                                promises.push(promise);
                            }

                            if (angular.isString(item.selectedUnitOfMeasure.imagePlanogramTop)) {
                                promise = imageService.load(imageBaseUrl + item.selectedUnitOfMeasure.imagePlanogramTop);
                                promises.push(promise);
                            }
                        });
                    });
                    columnHeight += moduleHeight;
                });
                planogramHeight = Math.max(planogramHeight, columnHeight);
            });

            self.isTall = planogramWidth < planogramHeight;
            self.planogramByColums = self.splitPlanogramByColums(self.selectedPlanogram);

            // Obtiene los estilos del planograma.
            var styles = self.getPlanogramStyles();

            // Espera que se precarguen las imagenes antes de activar el render del planograma.
            $q.all(promises).finally(function onFinally() {
                self.prepareExport = true;
                $timeout(500)
                    .then(function () {
                        //$q.all(promises.map(function () {
                        //    return $timeout(150);
                        //})).finally(function onFinally() {
                        try {
                            /*
                            // TODO: Verificar cross-browser.
                            // TODO: Ver solución alternativa cargando un .css especifico.
                            var filterFn = Array.prototype.filter;
                            var mapFn = Array.prototype.map;
                            var links = filterFn.call(document.styleSheets,
                                function (style) {
                                    return style.href &&
                                        (style.href.indexOf('app/index.css') > -1 ||
                                            style.href.indexOf('dist/css/app-') > -1);
                                });
                            var rules = filterFn.call(links[0].cssRules,
                                function (rule) {
                                    return rule.selectorText && /^planogram/.test(rule.selectorText);
                                });
                            var cssTexts = mapFn.call(rules,
                                function (rule) {
                                    return rule.cssText;
                                });
                            var styles = cssTexts.join('\n');
                            */
                            // var styles = self.getPlanogramStyles();

                            var $pdf = $element.find('.pdf').clone();
                            // var $pdf = HtmlHelper.cleanUpHtml($element.find('.pdf').clone());
                            var title = $pdf.find('planogram-print-header:first').text();
                            var html = $pdf.html();
                            var w = window.open();
                            w.document.write('<html>' +
                                '<head><title>' +
                                title +
                                '</title><style type="text/css" media="all">' +
                                styles +
                                '</style></head>' +
                                '<body>' +
                                html +
                                '</body>' +
                                '</html>');
                            w.document.close();
                            w.focus();
                            w.print();

                            $timeout(function onRenderTimeout() {
                                // Vuelve a la ventana original.
                                window.focus();
                                $timeout(function () {
                                    //w.close();
                                    self.prepareExport = false;

                                    //vuelvo a seleccionar el planograma original
                                    self.selectedPlanogram = currentSelectedPlanogram;
                                    self.planogramByColums = [];
                                });
                            });
                        } catch (ex) {
                            $log.error('editPlanogramController::exportToPdf ex: %o', ex);
                            throw ex;
                        }
                        //});
                    });
            });
        }

        /**
         * Devuelve una cadena con los estilos del planograma.
         * @private
         * @returns Una cadena con los estilos del planograma.
         */
        self.getPlanogramStyles = function getPlanogramStyles() {
            // TODO: Verificar cross-browser.
            // TODO: Ver solución alternativa cargando un .css especifico.
            // TODO: Ver performance.
            var rules = [];
            angular.forEach(document.styleSheets,
                function (link) {
                    // Fix: error con las extensiones de chrome.
                    try {
                        if (angular.isDefined(link.cssRules)) {
                            angular.forEach(link.cssRules,
                                function (rule) {
                                    if (angular.isString(rule.selectorText) && /^planogram/.test(rule.selectorText)) {
                                        rules.push(rule);
                                    }
                                });
                        }
                    } catch (ex) {
                        // Nothing to do.
                    }
                });

            var cssTexts = rules.map(function (rule) {
                return rule.cssText || '';
            });
            var ret = cssTexts.join('\n');
            return ret;
        }

        //Filtros

        self.showFilters = false;

        self.toggleFilters = function () {

            self.searchInfoPlanograms();
            self.showFilters = !self.showFilters;

            //if (self.showFilters) {
            //    $timeout(function () {

            //        $window.onclick = function (event) {
            //            closeFilterWhenClickingOutside(event);
            //        }
            //    }, 100);
            //} else {
            //    $window.onclick = null;
            //}

        }

        self.getFilterClass = function () {
            if (self.showFilters == true)
                return 'move-right';
            else
                return '';
        }

        self.getUnitsColor = function (selectedItem) {
            if (selectedItem && selectedItem.storeInfo) {
                if ((selectedItem.facings * selectedItem.stackAbove) > selectedItem.storeInfo.units)
                    return 'redColor';
                else if (selectedItem.stackTotal > selectedItem.storeInfo.units)
                    return 'orangeColor';
                else
                    return +'';
            }
        }

        self.getUnitsTextInfoStore = function (selectedItem) {
            if (selectedItem && selectedItem.storeInfo) {
                if ((selectedItem.facings * selectedItem.stackAbove) > selectedItem.storeInfo.units)
                    return translations.TooltipForStoreUnits;
                else if (selectedItem.stackTotal > selectedItem.storeInfo.units)
                    return translations.TooltipForStoreUnits2;
                else
                    return '';
            }
        }

        self.getUnitsText = function (selectedItem) {
            if (selectedItem) {
                if ((selectedItem.facings * selectedItem.stackAbove) > selectedItem.units)
                    return translations.TooltipForStoreUnits;
                else if (selectedItem.stackTotal > selectedItem.units)
                    return translations.TooltipForStoreUnits2;
                else
                    return '';
            }
        }

        function closeFilterWhenClickingOutside(event) {

            var clickedElement = event.target;
            if (!clickedElement) return;

            var elementClasses = clickedElement.classList;
            var clickedOnFilterDiv = elementClasses.contains('isFilter')
                || elementClasses.contains('chosen-choice')
                || elementClasses.contains('chosen-choices')
                || elementClasses.contains('search-field')
                || elementClasses.contains('default')
                || elementClasses.contains('chosen-container')
                || elementClasses.contains('chosen-container')
            if (!clickedOnFilterDiv) {
                self.closeFilters();
            }

        }

        self.closeFilters = function () {
            $timeout(function () {
                self.showFilters = false;
                $window.onclick = null;
            });

        }

        self.toggleSenseOfOrientation = function (planogram) {
            planogram.orientationRightToLeft = !planogram.orientationRightToLeft;
        }

        function loadData() {
            self.analyzeBy = self.isEdit() ? 'cluster' : 'store'

            /*
            spaceService.tags.getTags().then(function (tags) {
                self.allTags = tags;
                self.allTags.push({ id: 0, group: translations.Allf, name: '!null', displayName: translations.Allf });
                // Se agrupan los tags para el filtro de Grupo.
                var group = {};
                angular.forEach(tags,
                    function (item) {
                        if (!angular.isDefined(group[item.group])) {
                            group[item.group] = item.group;
                            self.groupTags.push({ name: item.group });
                        }
                    });
            });
            */

        }

        self.searchInfoPlanograms = function () {
            //console.log('searchInfoPlanograms')
            angular.forEach(self.model.planograms, function (planogram) {
                angular.forEach(planogram.clusters, function (cluster) {
                    self.clusterIds.push(cluster.id);
                })
                angular.forEach(planogram.columns, function (column) {
                    angular.forEach(column.modules, function (module) {
                        angular.forEach(module.levels, function (level) {
                            angular.forEach(level.items, function (item) {
                                item.uniqueId = guid();
                                var exists = false;
                                angular.forEach(self.brands, function (brand) {
                                    if (item.brand && brand.name.toLowerCase() == item.brand.toLowerCase())
                                        exists = true;
                                })
                                if (item.brand && !exists)
                                    self.brands.push({ name: item.brand, displayName: item.brand });

                                angular.forEach(item.tags, function (tagItem) {
                                    var existesTag = false
                                    angular.forEach(self.tags, function (_tag) {
                                        if (tagItem.name.toLowerCase() == _tag.name.toLowerCase()) {
                                            existesTag = true;
                                        }
                                    })
                                    if (!existesTag) {
                                        self.tags.push({ name: tagItem.name, displayName: tagItem.name })
                                    }

                                })


                                switch (item.orientation) {
                                    case 11: item.rotation = undefined; item.showMode = 'T'; break;
                                    case 21: item.rotation = undefined; item.showMode = 'S'; break;
                                    case 12: item.rotation = 90; item.showMode = 'T'; break;
                                    case 22: item.rotation = 90; item.showMode = 'S'; break;
                                    default: break;
                                }
                            })

                            //ordenar item en level por propiedad order, 

                            //Armar memo de todos los item existentes en el planograma
                            angular.forEach(level.items, function (item) {
                                if (self.memoAllItemPlanogram[item.itemId]) {
                                    //console.log('existe self.memoAllItemPlanogram[item.itemId]', item.itemId)
                                    self.memoAllItemPlanogram[item.itemId].push({
                                        idCopy: item.id,
                                        order: item.order
                                    })
                                } else {
                                    self.memoAllItemPlanogram[item.itemId] = []
                                    self.memoAllItemPlanogram[item.itemId].push({
                                        idCopy: item.id,
                                        order: item.order
                                    })
                                }

                            })

                        })

                    })
                })

            })


        }

        function isTagAdded(tag) {

            for (var i = 0; i < self.allTags.length; i++) {
                if (self.allTags[i].id === tag.id) {
                    return true;
                }
            }

            return false;
        }

        function loadDataPlanograms(planogramId) {
            self.isLoading = true;
            self.isLoadingListItems = false;
            self.isLoadingPlanogram = false;
            spaceService.planograms.getPlanogramInfo(planogramId).then(function (results) {
                console.log('result', results)
                results.subCategories.unshift({ id: null, name: translations.Allf });

                //self.generateDataGrid(results);

                self.model.planograms.push(results);

                angular.forEach(self.model.planograms,
                    function (planogram) {
                        angular.forEach(planogram.columns,
                            function (column) {
                                angular.forEach(column.modules,
                                    function (module) {
                                        module.type = module.type;
                                        if (module.isRefrigerator) self.thereIsRefrigerator = true;
                                        if (module.type == 'Peg') self.thereIsPeg = true;
                                    });
                            });
                    });

                self.selectedPlanogram = self.model.planograms[0];

                //if (self.selectedPlanogram.clusters.length) self.selectedCluster = self.selectedPlanogram.clusters[0];
                //if (self.selectedPlanogram.stores.length) self.selectedStore = self.selectedPlanogram.stores[0];

                self.brands = [{ name: '!null', displayName: translations.Allf }];
                self.tags = [{ name: '!null', displayName: translations.Allf }];

                self.searchInfoPlanograms();

                self.isLoadingPlanogram = false;
            });

            spaceService.planograms.getSpaceItemsForPlanogram(planogramId).then(function (results) {
                self.itemList = [];
                //filtar items por subcategorias
                angular.forEach(self.model.planograms,
                    function (_planogram) {
                        if (!_planogram.subCategories.length)
                            self.itemList = results;

                        angular.forEach(_planogram.subCategories,
                            function (_subCategory) {
                                angular.forEach(results,
                                    function (_item) {
                                        if (_subCategory.id === _item.categoryId) {

                                            //Agrego la etiqueta si no existe en la coleccion de Tags
                                            if (_item.tags && _item.tags.length > 0) {
                                                for (i = 0; i < _item.tags.length; i++) {
                                                    if (!isTagAdded(_item.tags[i])) {
                                                        self.allTags.push({
                                                            id: _item.tags[i].id,
                                                            group: _item.tags[i].group,
                                                            name: _item.tags[i].name
                                                        });
                                                    }
                                                }
                                            }

                                            self.itemList.push(_item);
                                        }
                                    });
                            });
                    });

                //mapeo propiedades de items de itemList que los items de los planogramas necesitan           
                loopThroughAll(function (planogram, column, module, level, item) {
                    angular.forEach(self.itemList, function (_item) {
                        if (item.itemId == _item.itemId) {
                            item.currentInventoryUnits = _item.currentInventoryUnits;
                            item.units = _item.units;
                        }
                    })
                }, self.until.items)

                calculateSales();

                self.FilterByAssortments(self.currentAssortmenFilter);

                self.allBrandsPlanogram = [{ name: '!null', displayName: translations.Allf }];

                angular.forEach(self.itemList, function (item) {
                    //item.showByAssortment = false;

                    //agregar propiedad oritentation = 0 (Default) para todos los items de itemsList si no esta definido
                    if (!item.orientation) item.orientation = 0;

                    var exists = false;
                    angular.forEach(self.allBrandsPlanogram, function (brand) {
                        if (item.brand && brand.name.toLowerCase() == item.brand.toLowerCase())
                            exists = true;
                    })
                    if (item.brand && !exists) {
                        self.allBrandsPlanogram.push({ name: item.brand, displayName: item.brand });
                    }

                    if (self.brandsFilter.indexOf(item.brand) === -1) {
                        self.brandsFilter.push(item.brand);
                    }

                    if (self.manufacturersFilter.indexOf(item.manufacturer) === -1) {
                        self.manufacturersFilter.push(item.manufacturer);
                    }
                })


                var listedItems = self.itemList.slice().filter(function (item) {
                    return item.isListed === true;
                });

                // Se agrega el valor de la propiedad ya que es un proceso automatico y no se le indica cual es la unidad de medida.
                angular.forEach(listedItems, function (_item) {
                    angular.forEach(_item.unitOfMeasures, function (_uom) {
                        if (_uom.height && _uom.width && _uom.depth) {
                            var newItem = angular.copy(_item);
                            //item.planogramLevelId = level.id;
                            newItem.selectedUnitOfMeasureId = _uom.id;
                            newItem.selectedUnitOfMeasure = _uom;
                            self.listedItems.push(newItem);
                        }
                    });
                });


                self.selectedItemId = $stateParams.itemId !== 0 ? $stateParams.itemId : null;

                self.isLoading = false;
                self.isLoadingListItems = false;
                //console.log('itemList', self.itemList)

                $timeout(makeCopyForChangeDetection, 500);
            });
        }

        self.planogramCopy = null;
        function makeCopyForChangeDetection() {
            self.planogramCopy = angular.copy(self.model.planograms[0]);
        }

        self.hasPlanogramChanged = function () {
            var original = getCopyOfSavableElements(self.model.planograms[0]);
            var copy = getCopyOfSavableElements(self.planogramCopy);

            return !angular.equals(original, copy);
        }

        function getCopyOfSavableElements(planogram) {

            var obj1 = {};

            angular.forEach(planogram.columns, function (col) {
                angular.forEach(col.modules, function (mod) {
                    angular.forEach(mod.levels, function (lev) {
                        obj1[`col${col.id}_mod${mod.id}_lev${lev.id}`] = [];
                        angular.forEach(lev.items, function (it) {
                            obj1[`col${col.id}_mod${mod.id}_lev${lev.id}`].push({
                                id: it.id,
                                selectedUnitOfMeasureId: it.selectedUnitOfMeasureId,
                                order: it.order,
                                facings: it.facings,
                                orientation: it.orientation,
                                stackAbove: it.stackAbove,
                                stackBehind: it.stackBehind,
                                ticketCount: it.ticketCount,
                                positionX: it.positionX,
                                positionY: it.positionY
                            });
                        });
                    });
                });
            });

            return obj1;
        }

        function guid() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }
            return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
                s4() + '-' + s4() + s4() + s4();
        }

        function renderSizes() {
            loopThroughAll(function (planogram, column, module, level, item) {
                self.calcItemSize(item, level, planogram);
            }, self.until.items);

        }

        //Filtro collapse

        self.isNavCollapsed = true;
        self.isCollapsedHorizontal = false;
        self.isCollapsed = true;
        self.isInfoPlanogramCollapsed = false;
        self.isInfoAveragePerformanceCollapsed = false;
        self.isInfoStorePerformanceCollapsed = false;
        self.isInfoRankingCollapsed = false;
        self.isInfoRankingHeatMapCollapsed = false;
        self.isAditionalInfoCollapsed = true;
        self.isInfoAveragePerformanceCollapsedProduct = true;
        self.isInfoStorePerformanceCollapsedProduct = true;
        self.isRecommendationCollapsed = false;


        function init() {
            self.isLoading = true;
            $translate(["Allf", "All", "ContinueSwal", "CancelSwal", "SavePlanogramSwalTitle", "SavedPlanogramSwal", "SavedPlanogramaSwalSubtitle", "ValidationSwalTitle",
                "ValidationSwalSubtitle", "SavedTicketSwal", "SavedTicketSwalSubtitle", "SureCleanPlanogramSwal", "Yes", "SureGenerateAutocompletePlanogramSwal", "WithoutLevelsSwal",
                "WithoutLevelsSubtitleSwal", "PlanagramNotFoundSwal", "PlanagramNotFoundSwalSubtitle1", "PlanagramNotFoundSwalSubtitle2", "Subspaced", "Overpaced", "SalesShare", "SpaceShare",
                "SalesRestProducts", "SpaceRestProcucts", "AlertWillLoseChanges", "HideProducts", "ShowProducts", "HideInformation", "ShowInformation", "TooltipForStoreUnits", "TooltipForStoreUnits2",
                "ErrorTitleSwal", "InsufficientPermissionsToEdit", "InsufficientPermissionsToEditPlanogram", "FinishThePlanogram", "InsufficientPermitsToApprove", "YouWantToApproveThePlanogram",
                "InsufficientPermissionsToReport", "CommunicateThePlanogram", "AnErrorHasOccurred", "Low", "Medium", "HeightF", "NotInformed", "Manufacturer", "Brand",
                "InsufficientSpaceSwal", "InsufficientWidthSpaceSubtitleSwal", "AddLayDownItemSwal", "Laydown", "Standup", "ShowItemAbove", "ShowItemInfront", "ShowProfileItem",
                "SureDeleteItemSwal", "RemoveSwal", "CancelSwal", "ShowProfileItem", "TheItemWasAdded", "Aggregate", "Actives", "WithoutExhibition", "WithExhibition", "Delist", "New", "EnterFurnitureToPrint",
                "EnterFurnitureToPrintSubtitle", "InvalidRank", "InvalidRankSubtitle", "ConfigurationFinished", "AllReadyToPrintThePlanogram", "Print", "Sp_editPlanogram_spaced", "Sp_editPlanogram_new",
                "Sp_editPlanogram_pendingApproval", "Sp_editPlanogram_approved", "Sp_editPlanogram_informed", "WithoutSize", "WithoutSizeSubtitle", "IncorrectCategorySwal", "IncorrectCategorySubtitleSwal"])
                .then(function (all) {
                    translations = all;

                    self.translationLow = translations.Low;
                    self.translationMedium = translations.Medium;
                    self.translationHeightF = translations.HeightF;
                    self.productStatus = translations.WithoutExhibition; //LG: Que diferencia hay entre productStatus y currentAssortmenFilter
                    self.currentAssortmenFilter = translations.WithoutExhibition;


                    self.priorityTickets = [
                        { id: 1, name: self.translationLow },
                        { id: 2, name: self.translationMedium },
                        { id: 3, name: self.translationHeightF },
                    ];

                }).then(function () {
                    self.buttonTextProducts = translations.HideProducts;
                    self.buttonTextInformationPanel = translations.HideInformation;
                });

            if (self.isEdit() && !self.hasPermissionToEdit) {
                $state.go('spaces.viewPlanogram', { planogramId: $stateParams.planogramId });
            }

            //Cargo los Tipos de Ticket
            spaceService.tickets.getTicketTypes(TICKET_TYPES.ITEM).then(function (ticketTypes) {
                self.ticketTypes = ticketTypes;
            });


            //loadData();

            if ($stateParams.planogramId != '0') {
                if (self.isEdit() && !self.hasPermissionToEdit) {
                    $state.go('spaces.viewPlanogram', { planogramId: $stateParams.planogramId });
                }

                self.planogramId = $stateParams.planogramId
                loadDataPlanograms($stateParams.planogramId)
            } else if ($stateParams.itemId != '0' && $stateParams.clusterId != '0') {
                spaceService.planograms.getPlanogramId($stateParams.itemId, $stateParams.clusterId).then(function (planogramId) {
                    if (!planogramId) {
                        swal(translations.PlanagramNotFoundSwal, translations.PlanagramNotFoundSwalSubtitle1 + $stateParams.itemId + ', ' + translations.PlanagramNotFoundSwalSubtitle2 + $stateParams.clusterId, 'warning');
                        $state.go('spaces.planograms');
                        return;
                    }

                    if (self.isEdit() && !self.hasPermissionToEdit) {
                        $state.go('spaces.viewPlanogram', { planogramId: $stateParams.planogramId });
                    }

                    self.planogramId = planogramId;
                    loadDataPlanograms(planogramId);

                }).then(function () {
                    var $products = angular.element('.edit-planogram-products');
                });
            }

        }

        self.getFechaHora = function () {
            return moment().format('DD/MM/YYYY hh:mma');
        }

        /**
         * Devuelve el ancho total que ocupan los productos en el estante.
         * @private
         * @param {any} level Referencia del nivel.
         * @returns El ancho total que ocupan los productos en el estante.
         */
        self.getLevelUsedWidth = function (level) {
            var ret = level.items.reduce(function (sum, item) {
                var width = self.getItemWidth(item);
                return sum + width;
            }, 0);
            return ret;
        }

        /**
         * Devuelve el ancho calculado para el grupo de items.
         * @private
         * @param {any} item Referencia del item.
         * @returns El ancho calculado para el grupo de items.
         */
        self.getItemWidth = function getItemWidth(item, notCountFacings) {
            // TODO: Verificar el cálculo con layDown.
            var width = [2, 4].indexOf(item.orientation) != -1 ? item.selectedUnitOfMeasure.height : item.selectedUnitOfMeasure.width;
            var ret = width * (notCountFacings ? 1 : item.facings);
            return ret;
        }

        /**
         * Devuelve el alto calculado para el grupo de items.
         * @private
         * @param {any} item Referencia del item.
         * @returns El alto calculado para el grupo de items.
         */
        self.getItemHeight = function getItemHeight(item) {
            var layDownItemsHeight = item.layDownItem ? item.layDownItem * ([2, 4].indexOf(item.orientation) != -1 ? item.selectedUnitOfMeasure.width : item.selectedUnitOfMeasure.depth) : 0;
            var stackAbove = item.stackAbove ? item.stackAbove : 1;
            var height = [2, 4].indexOf(item.orientation) != -1 ? item.selectedUnitOfMeasure.width : item.selectedUnitOfMeasure.height;
            var ret = (height * stackAbove) + layDownItemsHeight;
            return ret;
        }

        /**
         * Devuelve verdadero si hay que mostrar el componente SVG del planograma, falso en caso contrario.
         * Verifica que no se este cargando la pagina y el nombre del estado de la aplicación.
         * @returns Verdadero si hay que mostrar el componente SVG del planograma, falso en caso contrario.
         */
        self.showPlanogramComponentSvg = function showPlanogramComponentSvg() {
            var ret = !self.isLoading
                && ($state.current.name === 'spaces.editPlanogram'
                    || $state.current.name === 'spaces.viewPlanogram');
            return ret;
        }

        /**
         * Devuelve verdadero si hay que mostrar el componente HTML del planograma, falso en caso contrario.
         * Verifica que no se este cargando la pagina y el nombre del estado de la aplicación.
         * @returns Verdadero si hay que mostrar el componente SVG del planograma, falso en caso contrario.
         */
        self.showPlanogramComponentHtml = function showPlanogramComponentHtml() {
            var ret = !self.isLoading
                && ( $state.current.name === 'spaces.editPlanogramOld'
                || $state.current.name === 'spaces.viewPlanogramOld');
            return ret;
        }

        /**
         * Devuelve el identificador del item arrastrable.
         * @param {any} item Item.
         * @param {any} uom Unidad de medida.
         * @returns El identificador del item arrastrable.
         */
        self.getDraggableId = function getDraggableId(item, uom) {
            var ret = 'item_' + item.id + '-uom_' + uom.code;
            return ret;
        }

        /**
         * Devuelve la imagen para mostrar al arratrar un item al planograma.
         * @param {any} item Item.
         * @param {any} uom Unidad de medida.
         * @returns La imagen para mostrar al arratrar un item al planograma.
         */
        self.getDraggableTarget = function getDraggableTarget(item, uom) {
            var id = self.getDraggableId(item, uom);

            // var ret = 'div:has(#' + id + '):last img';
            var ret = '#dnd_' + id + ' img:first';
            return ret;
        }

        /**
         * Ocurre al arrastrar y soltar un elemento sobre el planograma o al mover un elemento dentro del planograma.
         * @event 
         * @param {any} $event Evento origina.
         * @param {any} planogram Referencia del planograma en el cual ocurrio el evento en el formato interno del planograma.
         * @param {any} column Referencia de la columna en la cual ocurrio el evento en el formato interno del planograma.
         * @param {any} module Referencia del módulo en el cual ocurrio el evento en el formato interno del planograma.
         * @param {any} level Referencia del estante en el cual ocurrio el evento en el formato interno del planograma.
         * @param {any} item Referencia del item en el cual ocurrio el evento en el formato interno del planograma.
         */
        self.planogramOnClickHandler = function planogramOnClickHandler($event, planogram, column, module, level, item) {
            // console.time('planogramOnClickHandler');
            //$log.debug('EditPlanogramCtrl::planogramOnClickHandler\n\t$event: %o\n\tplanogram: %o\n\tcolumn: %o\n\tmodule: %o\n\tlevel: %o\n\titem: %o',
                //$event,
                //planogram,
                //column,
                //module,
                //level,
                //item);

            // Aplica la selección.
            // self.planogramSelect(planogram, column, module, level, item);
            self.planogramSelect(self.selectedPlanogram, column, module, level, item);

            // Fuerza una nueva referencia del planograma para que se detecte el cambio.
            self.forceUpdateModel();

            // console.timeEnd('planogramOnClickHandler');
        }

        /**
         * Aplica la selección del item del planograma.
         * @param {any} planogram Referencia del planograma en el cual ocurrio el evento en el formato interno del planograma.
         * @param {any} column Referencia de la columna en la cual ocurrio el evento en el formato interno del planograma.
         * @param {any} module Referencia del módulo en el cual ocurrio el evento en el formato interno del planograma.
         * @param {any} level Referencia del estante en el cual ocurrio el evento en el formato interno del planograma.
         * @param {any} item Referencia del item en el cual ocurrio el evento en el formato interno del planograma.
         * @param {bool} keepSelected Mantiene el item seleccionado si el id es el mismo al item seleccionado, ver proceso addItemTo.
         */
        self.planogramSelect = function planogramSelect(planogram, column, module, level, item, keepSelected) {
            //$log.debug('EditPlanogramCtrl::planogramSelect\n\tplanogram: %o\n\tcolumn: %o\n\tmodule: %o\n\tlevel: %o\n\titem: %o',
                //planogram,
                //column,
                //module,
                //level,
                //item);

            // Apaga la selección.
            angular.forEach(self.model.planograms,
                function selectionOffForEachPlanograms(planogramLocal) {
                    angular.forEach(planogramLocal.columns,
                        function selectionOffForEachColumns(columnLocal) {
                            columnLocal.selected = false;
                            angular.forEach(columnLocal.modules,
                                function selectionOffForEachModules(moduleLocal) {
                                    moduleLocal.selected = false;
                                    angular.forEach(moduleLocal.levels,
                                        function selectionOffforEachLevels(levelLocal) {
                                            levelLocal.selected = false;
                                            angular.forEach(levelLocal.items,
                                                function selectionOffForEachItems(itemLocal) {
                                                    moduleLocal.selected = false;
                                                    levelLocal.selected = false;
                                                    columnLocal.selected = false;
                                                    itemLocal.selected = false;
                                                });
                                        });
                                });
                        });
                });

            if (angular.isObject(item)) {
                if (angular.isObject(self.selectedItem)) {
                    if (self.selectedItem.id === item.id && !keepSelected) {
                        // Deselecciona.
                        self.activePlanogram = null;
                        self.selectedColumn = null;
                        self.selectedModule = null;
                        self.selectedLevel = null;
                        self.selectedItem = null;
                        self.selectedItemId = 0;
                    } else {
                        self.activePlanogram = planogram;
                        self.selectedColumn = column;
                        self.selectedModule = module;
                        self.selectedLevel = level;
                        self.selectedItem = item;
                        self.selectedItemId = item.itemId;
                    }
                } else {
                    self.activePlanogram = planogram;
                    self.selectedColumn = column;
                    self.selectedModule = module;
                    self.selectedLevel = level;
                    self.selectedItem = item;
                    self.selectedItemId = item.itemId;
                }
            } else {
                // Deselecciona.
                self.activePlanogram = null;
                self.selectedColumn = null;
                self.selectedModule = null;
                self.selectedLevel = null;
                self.selectedItem = null;
                self.selectedItemId = 0;
            }

            if (angular.isObject(self.selectedItem)) {
                angular.forEach(self.model.planograms,
                    function selectionOnForEachPlanograms(planogramLocal) {
                        angular.forEach(planogramLocal.columns,
                            function selectionOnForEachColumns(columnLocal) {
                                angular.forEach(columnLocal.modules,
                                    function selectionOnForEachModules(moduleLocal) {
                                        angular.forEach(moduleLocal.levels,
                                            function selectionOnForEachLevels(levelLocal) {
                                                angular.forEach(levelLocal.items,
                                                    function selectionOnForEachItems(itemLocal) {
                                                        if (self.selectedItem.itemId === itemLocal.itemId) {
                                                            moduleLocal.selected = true;
                                                            levelLocal.selected = true;
                                                            columnLocal.selected = true;
                                                            itemLocal.selected = true;
                                                        }
                                                    });
                                            });
                                    });
                            });
                    });

                var arr = [self.selectedItem];

                // $timeout(1).then(function () {
                self.filteredItems = arr;

                // self.filtereditems.push(item);
                self.renderPieChartsParent(arr);
                // });
            } else {
                // $timeout(1).then(function () {
                self.filtereditems = [];
                self.renderPieChartsParent(null);
                //});
            }
        }

        /**
         * Ocurre al arrastrar y soltar un elemento sobre el planograma o al mover un elemento dentro del planograma.
         * @event 
         * @param {any} $event Evento original.
         * @param {any} point Referencia de lugar donde ocurrio el evento.
         * @param {any} place Referencia de lugar donde se debe ubicar el elemento.
         * @param {any} planogram Referencia del planograma en el cual ocurrio el evento en el formato interno del planograma.
         * @param {any} column Referencia de la columna en la cual ocurrio el evento en el formato interno del planograma.
         * @param {any} module Referencia del módulo en el cual ocurrio el evento en el formato interno del planograma.
         * @param {any} level Referencia del estante en el cual ocurrio el evento en el formato interno del planograma.
         * @param {any} item Referencia del item en el cual ocurrio el evento en el formato interno del planograma.
         */
        self.planogramOnDropHandler = function planogramOnDropHandler($event, point, place, planogram, column, module, level, item) {
            // Obtiene la data que se esta arrastrando del servicio DnD.
            var data = DnDService.getData();
            //$log.debug('EditPlanogramCtrl::planogramOnDropHandler\n\t$event: %o\n\tpoint: %o\n\tplace: %o\n\tplanogram: %o\n\tcolumn: %o\n\tmodule: %o\n\tlevel: %o\n\titem: %o\n\tdrop: %o',
                //$event,
                //point,
                //place,
                //planogram,
                //column,
                //module,
                //level,
                //item,
                //data);

            // Verifica si el elemento que se solto se esta agregando o se esta moviendo.
            var keys = Object.keys(data);
            var isNew = keys.indexOf('item') > -1 && keys.indexOf('uom') > -1;
            if (isNew) {
                var newItem = self.getNewItem(point, self.selectedPlanogram, column, module, level, data);
                self.addItemTo(point, place, self.selectedPlanogram, column, module, level, item, newItem);
            } else {
                // self.moveItemTo(point, place, planogram, column, module, level, item, data);
                self.moveItemTo(point, place, self.selectedPlanogram, column, module, level, item, data);
            }
        }

        self.planogramOnChangeLevelHeight = function planogramOnChangeLevelHeight($event, planogram, column, module, level, action) {
            //$log.debug('EditPlanogramCtrl::planogramOnChangeLevelHeight\n\t$event: %o\n\tplanogram: %o\n\tcolumn: %o\n\tmodule: %o\n\tlevel: %o\n\taction: %o',
                //$event,
                //planogram,
                //column,
                //module,
                //level,
                //action);

            // Busca el planograma en la colección de planogramas locales.
            var planogramLocal = self.findPlanogram(planogram);

            // Busca la columna dentro de la colección de columnas del planograma local.
            var columnLocal = self.findColumn(planogramLocal, column);

            // Busca el módulo dentro de la colección de columnas del planograma local.
            var moduleLocal = self.findFurniture(columnLocal, module);

            // Busca el estante dentro de la colección de estantes del módulo local.
            var levelLocal = self.findLevel(moduleLocal, level);

            //TODO VALIDACIONES
            if (action === 'increase') {
                levelLocal.height += 1;
                moduleLocal.height += 1;
            }
            else if (action === 'reduce') {
                var highestItemHeight = getHighestItemHeight(levelLocal);
                if (highestItemHeight > levelLocal.height - 1) {
                    swal('altura no permitida'
                        , 'altura del nivel no puede ser menor que la altura del productos mas alto del nivel, altura minimo permitido: ' + highestItemHeight
                        , 'warning');
                }
                else {
                    levelLocal.height -= 1;
                    moduleLocal.height -= 1;
                }
            }
            //actualizo el height del planograma.
            planogramLocal.height = getHighestMouduleHeight();

            self.forceUpdateModel();
        }

        function getHighestItemHeight(level) {
            return level.items.reduce(function (maxHeight, item) {
                var currentHeight = item.height * item.stackAbove;
                if (currentHeight > maxHeight)
                    return currentHeight;
                else return maxHeight;
            }, 0);
        } 

        function getHighestMouduleHeight() {
            var maxHeight = 0;
            loopThroughAll(function (planogram, column, module) {
                if (module.height > maxHeight) {
                    maxHeight = module.height;
                }
            }, self.until.modules);

            return maxHeight;
        }     

        self.planogramOnDeleteLevel = function planogramOnDeleteLevel($event, planogram, column, module, level) {
            //$log.debug('EditPlanogramCtrl::planogramOnDeleteLevel\n\t$event: %o\n\tplanogram: %o\n\tcolumn: %o\n\tmodule: %o\n\tlevel: %o',
                //$event,
                //planogram,
                //column,
                //module,
                //level);

            // Busca el planograma en la colección de planogramas locales.
            var planogramLocal = self.findPlanogram(planogram);

            // Busca la columna dentro de la colección de columnas del planograma local.
            var columnLocal = self.findColumn(planogramLocal, column);

            // Busca el módulo dentro de la colección de columnas del planograma local.
            var moduleLocal = self.findFurniture(columnLocal, module);

            // Busca el estante dentro de la colección de estantes del módulo local.
            var levelLocal = self.findLevel(moduleLocal, level);

            //no puedo borrar un nivel si tiene items
            if (levelLocal.items.length) {
                swal('Nivel con Items'
                    , 'no se puede eliminar un nivel que contiene items'
                    , 'warning');
            }
            else {
                var levelIndex = moduleLocal.levels.indexOf(levelLocal);
                moduleLocal.levels.splice(levelIndex, 1);

                //el modulo decrementa la altura del nivel eliminado
                moduleLocal.height -= levelLocal.height;

                //actualizo el height del planograma.
                planogramLocal.height = getHighestMouduleHeight();

                self.forceUpdateModel();
            }

  
        }

        /**
         * Devuelve un nuevo item para agregar al planograma.
         * @param {any} point Referencia de lugar donde ocurrio el evento.
         * @param {any} column Referencia de la columna en la cual ocurrio el evento en el formato interno del planograma.
         * @param {any} module Referencia del módulo en el cual ocurrio el evento en el formato interno del planograma.
         * @param {any} level Referencia del estante en el cual ocurrio el evento en el formato interno del planograma.
         * @param {any} data Referencia del item y de la unidad de medida.
         */
        self.getNewItem = function getNewItem(point, planogram, column, module, level, data) {
            var isPeg = module.type === 'Peg';
        
            var ret = angular.copy(data.item);
            ret.planogramLevelId = level.id;
            //ret.id = guid();
            ret.uniqueId = guid();
            ret.facings = data.item.facings || 1;
            ret.selectedUnitOfMeasureId = data.uom.id;
            ret.selectedUnitOfMeasure = angular.copy(data.uom);
            ret.order = -1;

            if (self.autoStack && !isPeg) {
                ret.stackBehind = self.getItemStack(module, level, ret, 'behind');
                ret.stackAbove = self.getItemStack(module, level, ret, 'above');
                
            } else {
                ret.stackAbove = data.item.stackAbove ? data.item.stackAbove : 1;
                ret.stackBehind = data.item.stackBehind ? data.item.stackBehind : 1;
            }

            // Calcula los días de stock del producto.
            self.calcDaysInStock(ret);

            // Verifica si esta seleccionado.
            if (angular.isObject(self.selectedItem) && self.selectedItem.itemId === ret.itemId) {
                ret.selected = true;
            }

            if (isPeg) {
                if (self.autoAlign) {
                    self.fixPostitionAligningItems(ret, point, level.items);
                }
                else {
                    self.fixPostition(ret, point);
                }
            }

            return ret;
        }

        /**
         * Devuelve la cantidad de maxima de items que se pueden apilar de un itema para un estante.
         * @param {any} module Referencia del planograma.
         * @param {any} level Referencia del estante.
         * @param {any} item Referencia del item.
         * @param {any} direction
         * @param {any} defaultValue
         * @returns La cantidad de maxima de items que se pueden apilar de un itema para un estante.
         */
        self.getItemStack = function getItemStack(module, level, item, direction, defaultValue) {
            var ret = defaultValue;

            if (angular.isObject(module)
                && angular.isObject(level)
                && angular.isObject(item)
                && angular.isObject(item.selectedUnitOfMeasure)) {

                // de frente sin rot
                var alto = item.selectedUnitOfMeasure.height;
                var ancho = item.selectedUnitOfMeasure.width;
                var profundidad = item.selectedUnitOfMeasure.depth;

                // de frente rot 90
                if (!item.showMode && item.rotation === 90) {
                    alto = item.selectedUnitOfMeasure.width;
                    ancho = item.selectedUnitOfMeasure.height;
                    profundidad = item.selectedUnitOfMeasure.depth;
                }

                // lateral sin rot
                if (!item.showMode === 'S' && !item.rotation) {
                    alto = item.selectedUnitOfMeasure.selectedUnitOfMeasure.height;
                    ancho = item.selectedUnitOfMeasure.depth;
                    profundidad = item.selectedUnitOfMeasure.width;
                }
                // lateral rot 90
                if (!item.showMode === 'S' && item.rotation === 90) {
                    alto = item.selectedUnitOfMeasure.depth;
                    ancho = item.selectedUnitOfMeasure.heigh;
                    profundidad = item.selectedUnitOfMeasure.width;
                }

                // superior sin rot
                if (!item.showMode === 'T' && !item.rotation) {
                    alto = item.selectedUnitOfMeasure.height;
                    ancho = item.selectedUnitOfMeasure.width;
                    profundidad = item.selectedUnitOfMeasure.depth;
                }

                // superior rot 90
                if (!item.showMode === 'T' && item.rotation === 90) {
                    alto = item.selectedUnitOfMeasure.depth;
                    ancho = item.selectedUnitOfMeasure.width;
                    profundidad = item.selectedUnitOfMeasure.height;
                }

                if (direction === 'behind') {
                    ret = Math.floor(module.depth / profundidad);
                } else if (direction === 'above') {
                    ret = Math.floor(level.height / alto);
                }
            }

            return ret;
        }

        /**
         * Devuelve la cantidad total de carga de items.
         * @private
         * @param {any} item
         * @returns La cantidad total de carga de items.
         */
        self.calculateLoadQuantity = function calculateLoadQuantity(item) {
            var ret = 0;
            if (angular.isObject(item)) {
                ret = Math.round((item.stackBehind * item.stackAbove * item.facings) * (item.selectedUnitOfMeasure.conversion || 1));
            }

            return ret;
        }

        /**
         * Agrega un item al planograma.
         * Verifica si el item nuevo es igual al item sobre el cual se solto y le incrementa un frente al item actual.
         * Verifica si el item nuevo es igual al item item anterior al item que se solto le incrementa un frente al item anteriror.
         * Verifica si el item nuevo es igual al item item siguiente al item que se solto le incrementa un frente al item siguiente.
         * Agrega el item al estante.
         * @param {any} point Referencia de lugar donde ocurrio el evento.
         * @param {any} place Referencia de lugar donde se debe ubicar el elemento.
         * @param {any} planogram Referencia del planograma.
         * @param {any} column Referencia de la Columna del planograma.
         * @param {any} module Referencia del módulo de la columna.
         * @param {any} level Referencia del estante del módulo,
         * @param {any} item Referencia del item del estante.
         * @param {any} newItem Nuevo item.
         * @returns {boolean} Verdadero si el elemento a agregar es valido y se agrego al planograma.
         */
        self.addItemTo = function addItemTo(point, place, planogram, column, module, level, item, newItem) {
            //$log.debug('EditPlanogramCtrl::addItemTo\n\tpoint: %o\n\tplace: %o\n\tplanogram: %o\n\tcolumn: %o\n\tfurniture: %o\n\tlevel: %o\n\titem: %o\n\tnewItem: %o',
                //point,
                //place,
                //planogram,
                //column,
                //module,
                //level,
                //item,
                //newItem);
            self.stateChange = true;

            // TODO: Contemplar las distintas tipos de logicas segun el tipo de mueble.

            // Busca el planograma en la colección de planogramas locales.
            var planogramLocal = self.findPlanogram(planogram);

            // Busca la columna dentro de la colección de columnas del planograma local.
            var columnLocal = self.findColumn(planogramLocal, column);

            // Busca el módulo dentro de la colección de columnas del planograma local.
            var moduleLocal = self.findFurniture(columnLocal, module);
            var isPeg = moduleLocal.type === 'Peg';

            // Busca el estante dentro de la colección de estantes del módulo local.
            var levelLocal = self.findLevel(moduleLocal, level);

            // Busca el item dentro de la colección de items del estante local.
            var itemLocal = self.findItem(levelLocal, item);

            // Busca el item anterior dentro de la colección de items del estante local.
            //var itemPrevLocal = self.findPrevItem(levelLocal, itemLocal);
            var itemPrevLocal;

            // Busca el item siguiente dentro de la colección de items del estante local.
            //var itemNextLocal = self.findNextItem(levelLocal, itemLocal);
            var itemNextLocal;

            if (itemLocal) {
                itemPrevLocal = self.findPrevItem(levelLocal, itemLocal);
                itemNextLocal = self.findNextItem(levelLocal, itemLocal);
            }
            else if (!isPeg) {
                if (place === 'before') {
                    itemPrevLocal = null;
                    itemNextLocal = levelLocal.items.length ? levelLocal.items[0] : null;
                }
                else if (place === 'after') {
                    var length = levelLocal.items.length;
                    itemPrevLocal = length ? levelLocal.items[length - 1] : null;
                    itemNextLocal = null;
                }
            }

            //si hay agrupacion, newItem mantiene la orientacion del grupo para calcular el espacio
            if (itemLocal && itemLocal.itemId === newItem.itemId && itemLocal.selectedUnitOfMeasureId === newItem.selectedUnitOfMeasureId) {
                newItem.orientation = itemLocal.orientation;
            }
            else if (itemPrevLocal && itemPrevLocal.itemId === newItem.itemId && itemPrevLocal.selectedUnitOfMeasureId === newItem.selectedUnitOfMeasureId) { 
                newItem.orientation = itemPrevLocal.orientation;
            }
            else if (itemNextLocal && itemNextLocal.itemId === newItem.itemId && itemNextLocal.selectedUnitOfMeasureId === newItem.selectedUnitOfMeasureId) {
                newItem.orientation = itemNextLocal.orientation;
            }

            var isValid = self.validateAddOrModifyItemInLevel(planogramLocal, columnLocal, moduleLocal, levelLocal, newItem);
            if (isValid) {
                if (isPeg) {
                    // Agrega al estante.
                    levelLocal.items.push(newItem);
                } else {
                    self.addItemToInternal(place, levelLocal, itemLocal, itemPrevLocal, itemNextLocal, newItem);
                }

                // Mantiene el orden de los items.
                angular.forEach(levelLocal.items,
                    function (value, idx) {
                        value.order = idx;
                    });

                // Selecciona el item agregado.
                self.planogramSelect(planogramLocal, columnLocal, moduleLocal, levelLocal, newItem, true);

                // Actualiza la carga total del item en el planograma
                calculateStackTotalForPlanogram(newItem.itemId);

                // Actualiza los días de exibición del item en el planograma.
                self.calcExhibitionDays(newItem.itemId);

                //Actualiza el groupMap.
                if (self.groupMapEnabled) {
                    self.setGroupMap(self.groupMapVariable, self.groupMapVariableValue);
                }

                // Fuerza una nueva referencia del planograma para que se detecte el cambio.
                self.forceUpdateModel();
            }

            return isValid;
        }

        /**
         * Agrega un item al planograma.
         * Verifica si el item nuevo es igual al item sobre el cual se solto y le incrementa un frente al item actual.
         * Verifica si el item nuevo es igual al item item anterior al item que se solto le incrementa un frente al item anteriror.
         * Verifica si el item nuevo es igual al item item siguiente al item que se solto le incrementa un frente al item siguiente.
         * Agrega el item al estante.
         * @param {any} place Referencia de lugar donde se debe ubicar el elemento.
         * @param {any} level Referencia del estante del módulo,
         * @param {any} item Referencia del item del estante.
         * @param {any} newItem Nuevo item.
         * @returns {boolean} Verdadero si el elemento a agregar es valido y se agrego al planograma.
         */
        self.addItemToInternal = function addItemToInternal(place, level, item, itemPrev, itemNext, newItem) {
            //$log.debug('EditPlanogramCtrl::addItemToInternal\n\tlevel: %o\n\titem: %o\n\tnewItem: %o\n\titemPrev: %o\n\titemNext: %o',
                //level,
                //item,
                //newItem,
                //itemPrev,
                //itemNext);
            self.stateChange = true;
            newItem.planogramLevelId = level.id;

            var idx;

            // Si existe el item local
            if (item && item.itemId === newItem.itemId && item.selectedUnitOfMeasureId === newItem.selectedUnitOfMeasureId) {
                //item.facings += 1;
                item.facings += newItem.facings;
            }
            // Verifica si el elemento anterior es el mismo que se esta agregando.
            else if (itemPrev && itemPrev.itemId === newItem.itemId && itemPrev.selectedUnitOfMeasureId === newItem.selectedUnitOfMeasureId) { 

                //itemPrev.facings += 1;
                itemPrev.facings += newItem.facings;
            }
            // Verifica si el elemento siguiente es el mismo que se esta agregando.
            else if (itemNext && itemNext.itemId === newItem.itemId && itemNext.selectedUnitOfMeasureId === newItem.selectedUnitOfMeasureId) {
                //itemPrev.facings += 1;
                itemNext.facings += newItem.facings;
            } else if (item) {
                // Busca el elemento y lo agrega antes.
                idx = self.findIndex(level.items, item, self.compareToById);
                var newPosition = idx;
                if (place === 'after') {
                    newPosition += 1;
                }

                level.items.splice(newPosition, 0, newItem);
            } else if (place === 'before') {
                // Agrega al principio del estante.
                level.items.splice(0, 0, newItem);
            }
            else if (place === 'after') {
                // Agrega al final del estante.
                level.items.push(newItem);
            }

        }

        /**
         * Mueve un item dentro del planograma.
         * Verifica si el item nuevo es igual al item sobre el cual se solto y le incrementa un frente al item actual.
         * Verifica si el item nuevo es igual al item item anterior al item que se solto le incrementa un frente al item anteriror.
         * Verifica si el item nuevo es igual al item item siguiente al item que se solto le incrementa un frente al item siguiente.
         * Agrega el item al estante.
         * @param {any} point Referencia de lugar donde ocurrio el evento.
         * @param {any} place Referencia de lugar donde se debe ubicar el elemento.
         * @param {any} planogram Referencia del planograma.
         * @param {any} column Referencia de la Columna del planograma.
         * @param {any} module Referencia del módulo de la columna.
         * @param {any} level Referencia del estante del módulo,
         * @param {any} item Referencia del item del estante.
         * @param {any} movedItem Item a mover.
         */
        self.moveItemTo = function moveItemTo(point, place, planogram, column, module, level, item, movedItem) {
            //$log.debug('EditPlanogramCtrl::moveItemTo\n\tpoint: %o\n\tplace: %o\n\tplanogram: %o\n\tcolumn: %o\n\tmodule: %o\n\tlevel: %o\n\titem: %o\n\tmovedItem: %o',
                //point,
                //place,
                //planogram,
                //column,
                //module,
                //level,
                //item,
                //movedItem);
            self.stateChange = true;

            var idx;

            // Busca el planograma en la colección de planogramas locales.
            var planogramLocal = self.findPlanogram(planogram);

            // Busca la columna dentro de la colección de columnas del planograma local.
            var columnLocal = self.findColumn(planogramLocal, column);

            // Busca el módulo dentro de la colección de columnas del planograma local.
            var moduleLocal = self.findFurniture(columnLocal, module);
            var isPeg = moduleLocal.type === 'Peg';

            // Busca el estante dentro de la colección de estantes del módulo local.
            var levelLocal = self.findLevel(moduleLocal, level);

            // Busca el item dentro de la colección de items del estante local.
            var itemLocal = self.findItem(levelLocal, item);

            // Busca el item anterior dentro de la colección de items del estante local.
            //var itemPrevLocal = self.findPrevItem(levelLocal, itemLocal);
            var itemPrevLocal;

            // Busca el item siguiente dentro de la colección de items del estante local.
            //var itemNextLocal = self.findNextItem(levelLocal, itemLocal);
            var itemNextLocal;
            if (!isPeg) {
                if (itemLocal) {//  si existe el item local busca el anterior y el siguiente.
                    itemPrevLocal = self.findPrevItem(levelLocal, itemLocal);
                    itemNextLocal = self.findNextItem(levelLocal, itemLocal);
                }
                else if (place === 'before') {
                    itemPrevLocal = null;
                    itemNextLocal = levelLocal.items.length ? levelLocal.items[0] : null;
                }
                else if (place === 'after') {
                    var length = levelLocal.items.length;
                    itemPrevLocal = length ? levelLocal.items[length - 1] : null;
                    itemNextLocal = null;
                }

                if (itemPrevLocal && itemPrevLocal.id === movedItem.id && itemPrevLocal.selectedUnitOfMeasureId === movedItem.selectedUnitOfMeasureId) {
                    itemPrevLocal = null;
                }

                if (itemNextLocal && itemNextLocal.id === movedItem.id && itemNextLocal.selectedUnitOfMeasureId === movedItem.selectedUnitOfMeasureId) {
                    itemNextLocal = null;
                }
            }

            var levelOld;
            var moduleOld;
            var removeFromLevelOld = false;

            // Busca el nivel donde estaba el item originalmente.
            loopThroughAll(
                function (p, c, m, l, i) {
                    if (self.compareToById(i, movedItem)) {
                        levelOld = l;
                        moduleOld = m;
                    }
                },
                self.until.items);

            if (levelLocal.id !== movedItem.planogramLevelId) {//el estante es distinto.
                if (itemPrevLocal)
                    movedItem.orientation = itemPrevLocal.orientation;
                else if (itemNextLocal)
                    movedItem.orientation = itemNextLocal.orientation;

                var isValid = self.validateAddOrModifyItemInLevel(planogramLocal,
                    columnLocal,
                    moduleLocal,
                    levelLocal,
                    movedItem);

                if (isValid) {
                    removeFromLevelOld = true;
                    if (isPeg) {
                        self.fixPostition(movedItem, point);
                        levelLocal.items.push(movedItem);
                    } else {
                        // Agrega en el nuevo estante.
                        self.addItemToInternal(place, levelLocal, itemLocal, itemPrevLocal, itemNextLocal, movedItem);
                    }
                }

                if (removeFromLevelOld) {
                    // Busca el elemento en el estante.
                    idx = self.findIndex(levelOld.items, movedItem, self.compareToById);
                    if (idx > -1) {
                        // Saca el elemento del estante.
                        levelOld.items.splice(idx, 1);

                        // Mantiene el orden de los items.
                        angular.forEach(levelOld.items,
                            function (item, idx) {
                                item.order = idx;
                            });
                    }
                }
            } else {
                if (isPeg) {
                    idx = self.findIndex(levelLocal.items, movedItem, self.compareToByUniqueId);
                    if (idx > -1) {
                        // Saca el elemento del estante.
                        levelLocal.items.splice(idx, 1);
                    }
                    if (self.autoAlign) {
                        self.fixPostitionAligningItems(movedItem, point, levelLocal.items);
                    }
                    else {
                        self.fixPostition(movedItem, point);
                    }
                    levelLocal.items.push(movedItem);
                } else {
                    // Dentro del mismo estante.
                    // Si existe el item local.
                    if (itemLocal && itemLocal.id === movedItem.id && itemLocal.selectedUnitOfMeasureId === movedItem.selectedUnitOfMeasureId) {
                        // No hace nada se solto en el mismo lugar.
                    } else {
                        // Busca la posción del item en el estante.
                        idx = self.findIndex(levelLocal.items, movedItem, self.compareToById);
                        var idxDrop = self.findIndex(levelLocal.items, itemLocal, self.compareToById);
                        if (idx > -1) {
                            // Saca el elemento del estante.
                            levelLocal.items.splice(idx, 1);
                        }

                        self.addItemToInternal(place, levelLocal, itemLocal, itemPrevLocal, itemNextLocal, movedItem);

                        // Insertar antes del elemento.
                        //if (place === 'before') {
                        //    // Verifica si el elemento anterior es el mismo que se esta agregando.
                        //    if (itemPrevLocal && itemPrevLocal.itemId === movedItem.itemId) {
                        //        itemPrevLocal.facings += movedItem.facings;
                        //    } else if (itemLocal) {
                        //        // Busca el elemento y lo agrega antes.
                        //        levelLocal.items.splice(idxDrop, 0, movedItem);
                        //    } else {
                        //        // Agrega al principio del estante.
                        //        levelLocal.items.splice(0, 0, movedItem);
                        //    }
                        //} else if (place === 'after') {
                        //    // Verifica si el elemento siguiente es el mismo que se esta agregando.
                        //    if (itemNextLocal && itemNextLocal.itemId === movedItem.itemId) {
                        //        // Si es el mismo elemento lo vuelve a agregar en la colección de items.
                        //        if (itemNextLocal.id === movedItem.id) {
                        //            levelLocal.items.splice(idx, 0, movedItem);
                        //        } else {
                        //            itemNextLocal.facings += movedItem.facings;
                        //        }

                        //    } else if (itemLocal) {
                        //        // Busca el elemento y lo agrega despues.
                        //        levelLocal.items.splice(idxDrop + 1, 0, movedItem);
                        //    } else {
                        //        // Agrega al final del estante.
                        //        levelLocal.items.push(movedItem);
                        //    }
                        //}
                    }
                }
            }

            // Mantiene el orden de los items.
            angular.forEach(levelLocal.items,
                function (value, idx) {
                    value.order = idx;
                });

            // Selecciona el item agregado.
            self.planogramSelect(planogramLocal, columnLocal, moduleLocal, levelLocal, movedItem, true);

            // Fuerza una nueva referencia del planograma para que se detecte el cambio.
            self.forceUpdateModel();
        }

        /**
         * Devuelve verdadero si el valor recibido es un número mayor a 0, falso en caso contrario.
         * @private
         * @param {any} value Valor a evaluar.         
         * @returns Verdadero si el valor recibido es un número mayor a 0, falso en caso contrario.
         */
        self.isValidSize = function isValidSize(value) {
            var ret = angular.isNumber(value) && value > 0;
            return ret;
        }

        /**
         * Devuelve verdadero si se puede agregar o mover un item al estante dado.
         * Verifica:
         * - El item o grupo de items tiene suficiente espacio vertical en el estante.
         * - El item o grupo de items tiene suficiente espacio horizontal libre en el estante.
         * - El item o grupo de items tiene la misma categoria que el estante.
         *
         * @param {any} planogram Referencia del planograma.
         * @param {any} column Referencia de la Columna del planograma.
         * @param {any} module Referencia del módulo de la columna.
         * @param {any} level Referencia del estante del módulo,
         * @param {any} item Referencia del item del estante.
         * @returns Verdadero si el item pasa los criterios de aceptación, falso en caso contrario.
         */
        self.validateAddOrModifyItemInLevel = function validateAddOrModifyItemInLevel(planogram, column, module, level, item) {
            var ret = true;
            var isPeg = module.type === 'Peg';

            var uom = item.selectedUnitOfMeasure;
            var validSize = angular.isObject(uom)
                && self.isValidSize(uom.width)
                && self.isValidSize(uom.height)
                && self.isValidSize(uom.depth);

            // Verifica si el item tiene todas las medidas requeridas.
            if (!validSize) {
                swal(translations.WithoutSize,
                    translations.WithoutSizeSubtitle,
                    'warning');
                ret = false;
            }

            // Si no es ganchera verifica si hay espacio en el estante.
            if (!isPeg) {
                // Verifica si hay suficiente espacio en el alto del estante.
                if (ret) {
                    var requiredHeight = self.getItemHeight(item);
                    if (requiredHeight > level.height) {
                        swal(translations.InsufficientSpaceSwal,
                            translations.InsufficientHeightSpaceSubtitleSwal,
                            'warning');
                        ret = false;
                    }
                }

                // Verifica si hay suficiente espacio libre en el ancho del estante.
                if (ret) {
                    var usedWidth = self.getLevelUsedWidth(level);
                    var requiredWidth = self.getItemWidth(item);
                    if ((usedWidth + requiredWidth) > module.width) {
                        swal(translations.InsufficientSpaceSwal,
                            translations.InsufficientWidthSpaceSubtitleSwal,
                            'warning');
                        ret = false;
                    }
                }
            }

            // Valida que la categoria del estante sea la misma que la del producto.
            if (ret) {
                if (level.categoryId != null && item.categoryId !== level.categoryId) {
                    swal(translations.IncorrectCategorySwal,
                        translations.IncorrectCategorySubtitleSwal + level.categoryName + '',
                        'warning');
                    ret = false;
                }
            }

            return ret;
        }

        /**
         * Devuelve el indice del elemento buscado en la colección dada o -1 en caso de no encontrarlo.
         * @private
         * @param {any} collection Colección de elementos.
         * @param {any} value Referencia.
         * @param {any} compareFn Función de comparación.
         * @returns El indice del elemento buscado en la colección dada o -1 en caso de no encontrarlo.
         */
        self.findIndex = function findIndex(collection, value, compareFn) {
            var ret = -1;
            if (angular.isArray(collection) && angular.isObject(value) && angular.isFunction(compareFn)) {
                var len = collection.length;
                var idx = 0;
                while (idx < len && !compareFn(collection[idx], value)) {
                    idx += 1;
                }

                if (idx < len && compareFn(collection[idx], value)) {
                    ret = idx;
                }
            }

            return ret;
        }

        /**
         * Devuelve el elemento buscado en la colección dada o null en caso de no encotrarlo.
         * @private
         * @param {any} collection Colección de elementos.
         * @param {any} value Referencia.
         * @param {any} compareFn Función de comparación.
         * @returns El elemento buscado en la colección dada o null en caso de no encotrarlo.
         */
        self.find = function find(collection, value, compareFn) {
            var ret = null;
            if (angular.isArray(collection) && angular.isObject(value) && angular.isFunction(compareFn)) {
                var len = collection.length;
                var idx = 0;
                while (idx < len && !compareFn(collection[idx], value)) {
                    idx += 1;
                }

                if (idx < len && compareFn(collection[idx], value)) {
                    ret = collection[idx];
                }
            }

            return ret;
        }

        /**
         * Devuelve el planograma de la colección de planogramas buscandolo por el id del planograma de referencia o null.
         * @private
         * @param {any} value Planograma de referencia.
         * @returns El planograma de la colección de planogramas buscandolo por el id del planograma de referencia.
         */
        self.findPlanogram = function findPlanogram(value) {
            var ret = null;
            if (angular.isObject(value)) {
                ret = self.find(self.model.planograms, value, self.compareToById);
            }

            return ret;
        }

        /**
         * Devuelve la columna de la colección de columnas del planograma buscandola por el id de la columnas de referencia o null.
         * @private 
         * @param {any} planogram Planograma donde buscar.
         * @param {any} value Columna de referencia.
         * @returns La columna de la colección de columnas del planograma buscandola por el id de la columnas de referencia o null.
         */
        self.findColumn = function findColumn(planogram, value) {
            var ret = null;
            if (angular.isObject(planogram) && angular.isObject(value)) {
                ret = self.find(planogram.columns, value, self.compareToById);
            }

            return ret;
        }

        /**
         * Devuelve el módulo de la colección de modulos de la columna buscandolo por el id del módulo de referencia o null.
         * @private 
         * @param {any} column Columna donde buscar.
         * @param {any} value
         * @returns El módulo de la colección de modulos de la columna buscandolo por el id del módulo de referencia o null.
         */
        self.findFurniture = function findFurniture(column, value) {
            var ret = null;
            if (angular.isObject(column) && angular.isObject(value)) {
                ret = self.find(column.modules, value, self.compareToById);
            }

            return ret;
        }

        /**
         * Devuelve el estante de la colección de estantes del módulo buscandolo por el id del módulo de referencia o null.
         * @private 
         * @param {any} column Columna donde buscar.
         * @param {any} value
         * @returns El módulo de la colección de modulos de la columna buscandolo por el id del módulo de referencia o null.
         */
        self.findLevel = function findLevel(module, value) {
            var ret = null;
            if (angular.isObject(module) && angular.isObject(value)) {
                ret = self.find(module.levels, value, self.compareToById);
            }

            return ret;
        }

        /**
         * Devuelve el item de la colección de items del estante por el id del estante de referencia o null.
         * @private 
         * @param {any} shelf Estante donde buscar.
         * @param {any} value Referencia.
         * @returns El item de la colección de items del estante por el id del estante de referencia o null.
         */
        self.findItem = function findItem(shelf, value) {
            var ret = null;
            if (angular.isObject(shelf) && angular.isObject(value)) {
                ret = self.find(shelf.items, value, self.compareToById);
            }

            return ret;
        }

        self.findPrevItem = function findPrevItem(shelf, value) {
            var ret = null;
            if (angular.isObject(shelf)) {
                // Indica que se va a tomar el ultimo elemento si hay más de 1 item en la lista.
                var takeFirst = shelf.items.length > 1;
                if (angular.isObject(value)) {
                    // Busca el indice del elemento.
                    var idx = self.findIndex(shelf.items, value, self.compareToById);

                    // Si lo encontro.
                    if (idx > -1) {
                        var newIdx = idx - 1;

                        // Verifica si existe el item anterior.
                        if (newIdx > -1) {
                            ret = shelf.items[newIdx];
                        }

                        takeFirst = false;
                    }
                }

                if (takeFirst) {
                    ret = shelf.items[0];
                }
            }

            return ret;
        }

        /**
         * Devuleve el siguiente item del estante en relación al item enviado.
         * - Null si no se recibio la referencia del estante.
         * - Si existe el item de referencia.
         *  + Busca el item siguiente al item de referencia y si existe el item siguiente lo devuelve, si no existe el item siguiente devuelve null.
         * - Si no existe el item de referencia 
         *  + Si el estante tiene más de un item devuelve el ultimo item.         
         * @param {any} shelf Referencia del estante.
         * @param {any} value Referencia del item.
         * @returns El siguiente item del estante en relación al item enviado.
         */
        self.findNextItem = function findNextItem(shelf, value) {
            var ret = null;
            if (angular.isObject(shelf)) {
                var len = shelf.items.length;

                // Indica que se va a tomar el ultimo elemento si hay más de 1 item en la lista.
                var takeLast = len > 1;
                if (angular.isObject(value)) {
                    // Busca el indice del elemento.
                    var idx = self.findIndex(shelf.items, value, self.compareToById);

                    // Si lo encontro.
                    if (idx > -1) {
                        var newIdx = idx + 1;

                        // Verifica si existe el item siguiente.
                        if (newIdx < len) {
                            ret = shelf.items[newIdx];
                        }

                        takeLast = false;
                    }
                }

                if (takeLast) {
                    ret = shelf.items[len - 1];
                }
            }

            return ret;
        }

        /**
         * Devuelve verdadero si el id del elemento a es igual al id del elemento b, falso en caso contrario.
         * @param {any} a Elemento a.
         * @param {any} b Elemento b
         * @returns Verdadero si el id del elemento a es igual al id del elemento b, falso en caso contrario.
         */
        self.compareToById = function (a, b) {
            var ret = angular.isObject(a)
                && angular.isDefined(a.id)
                && angular.isObject(b)
                && angular.isDefined(b.id)
                && a.id === b.id
                && a.selectedUnitOfMeasureId === b.selectedUnitOfMeasureId;
            return ret;
        };

        self.compareToByUniqueId = function (a, b) {
            var ret = angular.isObject(a)
                && angular.isDefined(a.id)
                && angular.isObject(b)
                && angular.isDefined(b.id)
                && a.uniqueId === b.uniqueId;
            return ret;
        };

        /**
         * Devuelve un array de planogramas con una unica columna.
         * Divide el planograma original por columnas, si el planograma recibido tiene una sola columna devuelve un array vacio.
         * @public
         * @param {any} planogram Referencia del planograma.
         * @returns Un array de planogramas con una unica columna.
         */
        self.splitPlanogramByColums = function splitPlanogramByColums(planogram) {
            var ret = [];
            var hasMultipleColumns = self.hasMultipleColumns(planogram);
            if (hasMultipleColumns) {
                angular.forEach(planogram.columns,
                    function splitPlanogramByColumsForEachColumns(column, idx) {
                        // var newColumn = angular.merge({}, column, { overwriteId: idx + 1 });
                        //var newColumn = angular.merge({}, column);
                        var newColumn = angular.copy(column);
                        newColumn.overwriteId = idx + 1;
                        // var newPlanogram = angular.merge({}, planogram, { columns: [newColumn] });
                        //var newPlanogram = angular.merge({}, planogram);
                        var newPlanogram = angular.copy(planogram);
                        newPlanogram.columns = [newColumn];
                        ret.push(newPlanogram);
                    });
            }

            return ret;
        }

        /**
         * Devuelve verdadero si el planograma tiene más de una columna, falso en caso de que tenga una sola o ninguna.         
         * @public
         * @param {any} planogram Referencia del planograma.
         * @returns Verdadero si el planograma tiene más de una columna, falso en caso de que tenga una sola o ninguna.
         */
        self.hasMultipleColumns = function hasMultipleColumns(planogram) {
            var ret = planogram.columns.length > 1;
            return ret;
        }

        /**
         * Fuerza una nueva referencia del planograma para que se detecte el cambio en los componentes.
         * Utiliza un delay de 10ms para las posibles multiples llamadas de distintos métodos.
         */
        self.forceUpdateModel = function forceUpdateModel() {
            /*
            $timeout.cancel(self.forceUpdateModelCancel);
            self.forceUpdateModelCancel = $timeout(1).then(function () {
                // Fuerza una nueva referencia del planograma para que se detecte el cambio en los componentes.
                self.model.planograms[0] = angular.merge({}, self.model.planograms[0]);
            });
            */

            // Fuerza una nueva referencia del planograma para que se detecte el cambio en los componentes.
            //self.model.planograms[0] = angular.merge({}, self.model.planograms[0]);
            $timeout.cancel(self.forceUpdateModelCancel);
            self.forceUpdateModelCancel = $timeout(function forceUpdateModelOn$timeout() {
                // console.time('forceUpdateModel');
                self.model.planograms[0] = angular.copy(self.model.planograms[0]);
                self.selectedPlanogram = self.model.planograms[0];
                // console.timeEnd('forceUpdateModel');
            });
        }

        /**
         * Devuelve verdadero si hay que mostrar las superposición de color sobre el item, falso en caso contrario.
         * @returns Verdadero si hay que mostrar las superposición de color sobre el item, falso en caso contrario.
         */
        self.showOverlay = function showOverlay() {
            var ret = self.groupMapEnabled
                || self.heatMapEnabled;

            return ret;
        }

        /**
         * Devuelve verdadero si hay que mostrar la barra de herramientas de edición de un item seleccionado en el planograma, falso en caso contrario.
         * @returns Verdadero si hay que mostrar la barra de herramientas de edición de un item seleccionado en el planograma, falso en caso contrario.
         */
        self.showItemActionToolbar = function showItemActionToolbar() {
            var ret = ((self.activeShelf && self.activeShelf.showGlobesId === self.activeShelf.selecteditem.id)
                || self.selectedItem !== null)
                && !self.showlevelmeasurebuttons;
            return ret;
        }

        /**
         * Agrega un frente al item.
         * @param {any} value
         */
        self.wrapperAddFrontItem = function wrapperAddFrontItem(value) {
            //$log.debug('EditPlanogramCtrl::wrapperAddFrontItem value: %o', value);
            var module;
            var level;
            var item;

            if (angular.isObject(self.activeShelf)) {
                module = self.activeShelf.planogram;
                level = self.activeShelf.selectedLevel;
                item = self.activeShelf.selecteditem;
            } else {
                // Busca el planograma en la colección de planogramas locales.
                var planogram = self.findPlanogram(self.activePlanogram);

                // Busca la columna dentro de la colección de columnas del planograma local.
                var column = self.findColumn(planogram, self.selectedColumn);

                // Busca el módulo dentro de la colección de columnas del planograma local.
                module = self.findFurniture(column, self.selectedModule);

                level = self.findLevel(module, self.selectedLevel);

                // Busca el item dentro de la colección de items del estante local.
                item = self.findItem(level, self.selectedItem);
            }

            self.addFrontItem(item, level, module, value);
            self.planogramSelect(planogram, column, module, level, item, true);

            // Actualiza la carga total del item en el planograma
            calculateStackTotalForPlanogram(item.itemId);

            // Actualiza los días de exibición del item en el planograma.
            self.calcExhibitionDays(item.itemId);

            self.forceUpdateModel();
        }

        /**
         * Apila un item.
         * @param {any} value
         */
        self.wrapperAddItemAbove = function wrapperAddItemAbove(value) {
            //$log.debug('EditPlanogramCtrl::wrapperAddItemAbove value: %o', value);
            var module;
            var level;
            var item;

            if (angular.isObject(self.activeShelf)) {
                module = self.activeShelf.planogram;
                level = self.activeShelf.selectedLevel;
                item = self.activeShelf.selecteditem;
            } else {
                // Busca el planograma en la colección de planogramas locales.
                var planogram = self.findPlanogram(self.activePlanogram);

                // Busca la columna dentro de la colección de columnas del planograma local.
                var column = self.findColumn(planogram, self.selectedColumn);

                // Busca el módulo dentro de la colección de columnas del planograma local.
                module = self.findFurniture(column, self.selectedModule);

                level = self.findLevel(module, self.selectedLevel);

                // Busca el item dentro de la colección de items del estante local.
                item = self.findItem(level, self.selectedItem);
            }

            self.addItemAbove(item, level, module, value);
            self.planogramSelect(planogram, column, module, level, item, true);

            // Actualiza la carga total del item en el planograma
            calculateStackTotalForPlanogram(item.itemId);

            // Actualiza los días de exibición del item en el planograma.
            self.calcExhibitionDays(item.itemId);

            self.forceUpdateModel();
        }

        /**
         * Agrega un item detras.
         * @param {any} value
         */
        self.wrapperAddItemBehind = function wrapperAddItemBehind(value) {
            //$log.debug('EditPlanogramCtrl::wrapperAddItemBehind value: %o', value);
            var module;
            var level;
            var item;

            if (angular.isObject(self.activeShelf)) {
                module = self.activeShelf.planogram;
                level = self.activeShelf.selectedLevel;
                item = self.activeShelf.selecteditem;
            } else {
                // Busca el planograma en la colección de planogramas locales.
                var planogram = self.findPlanogram(self.activePlanogram);

                // Busca la columna dentro de la colección de columnas del planograma local.
                var column = self.findColumn(planogram, self.selectedColumn);

                // Busca el módulo dentro de la colección de columnas del planograma local.
                module = self.findFurniture(column, self.selectedModule);

                level = self.findLevel(module, self.selectedLevel);

                // Busca el item dentro de la colección de items del estante local.
                item = self.findItem(level, self.selectedItem);
            }

            self.addItemBehind(item, level, module, value);
            self.planogramSelect(planogram, column, module, level, item, true);

            // Actualiza la carga total del item en el planograma
            calculateStackTotalForPlanogram(item.itemId);

            // Actualiza los días de exibición del item en el planograma.
            self.calcExhibitionDays(item.itemId);

            self.forceUpdateModel();
        }

        /**
         * Cambia la vista del frente del item.
         */
        self.wrapperChangeFront = function wrapperChangeFront() {
            //$log.debug('EditPlanogramCtrl::wrapperChangeFront');
            var module;
            var level;
            var item;
            if (angular.isObject(self.activeShelf)) {
                module = self.activeShelf.planogram;
                level = self.activeShelf.selectedLevel;
                item = self.activeShelf.selecteditem;
            } else {
                // Busca el planograma en la colección de planogramas locales.
                var planogram = self.findPlanogram(self.activePlanogram);

                // Busca la columna dentro de la colección de columnas del planograma local.
                var column = self.findColumn(planogram, self.selectedColumn);

                // Busca el módulo dentro de la colección de columnas del planograma local.
                module = self.findFurniture(column, self.selectedModule);

                level = self.findLevel(module, self.selectedLevel);

                // Busca el item dentro de la colección de items del estante local.
                item = self.findItem(level, self.selectedItem);
            }

            self.changeFront(item, level, module, column, planogram);
            self.planogramSelect(planogram, column, module, level, item, true);
            self.forceUpdateModel();
        }

        /**
         * Va a los tickets.
         */
        self.wrapperGoToTickets = function wrapperGoToTickets() {
            //$log.debug('EditPlanogramCtrl::wrapperGoToTickets');
            var module;
            var level;

            if (angular.isObject(self.activeShelf)) {
                module = self.activeShelf.planogram;
                level = self.activeShelf.selectedLevel;
            } else {
                // Busca el planograma en la colección de planogramas locales.
                var planogram = self.findPlanogram(self.activePlanogram);

                // Busca la columna dentro de la colección de columnas del planograma local.
                var column = self.findColumn(planogram, self.selectedColumn);

                // Busca el módulo dentro de la colección de columnas del planograma local.
                module = self.findFurniture(column, self.selectedModule);

                level = self.findLevel(module, self.selectedLevel);
            }

            self.goToTickets(module, level);
            self.forceUpdateModel();
        }

        /**
         * Muestra los tickets.
         */
        self.wrapperShownewTicketModal = function wrapperShownewTicketModal() {
            //$log.debug('EditPlanogramCtrl::wrapperShownewTicketModal');
            var level;
            if (angular.isObject(self.activeShelf)) {
                level = self.activeShelf.selectedLevel;
            } else {
                // Busca el planograma en la colección de planogramas locales.
                var planogram = self.findPlanogram(self.activePlanogram);

                // Busca la columna dentro de la colección de columnas del planograma local.
                var column = self.findColumn(planogram, self.selectedColumn);

                // Busca el módulo dentro de la colección de columnas del planograma local.
                var module = self.findFurniture(column, self.selectedModule);

                level = self.findLevel(module, self.selectedLevel);

                // Busca el item dentro de la colección de items del estante local.
                item = self.findItem(level, self.selectedItem);
            }

            self.showNewTicketModal(item);
            self.forceUpdateModel();
        }

        /**
         * Borra el item.
         */
        self.wrapperDeleteItem = function wrapperDeleteItem() {
            //$log.debug('EditPlanogramCtrl::wrapperDeleteItem');
            var level;
            var item;

            if (angular.isObject(self.activeShelf)) {
                item = self.activeShelf.selecteditem;
                level = self.activeShelf.selectedLevel;
            } else {
                // Busca el planograma en la colección de planogramas locales.
                var planogram = self.findPlanogram(self.activePlanogram);

                // Busca la columna dentro de la colección de columnas del planograma local.
                var column = self.findColumn(planogram, self.selectedColumn);

                // Busca el módulo dentro de la colección de columnas del planograma local.
                var module = self.findFurniture(column, self.selectedModule);

                level = self.findLevel(module, self.selectedLevel);

                // Busca el item dentro de la colección de items del estante local.
                item = self.findItem(level, self.selectedItem);
            }

            self.deleteItem(item, level);

            //self.planogramSelect(planogram, column, module, level, item, true);

            //self.deleteItem tiene un swal por lo que hace el forceUpdateModel cuando se confirma
        }

        /**
         * Devuelve verdadero si hay que mostrar el botón para eliminar frentes, falso en caso contrario.
         * @returns Verdadero si hay que mostrar el botón para eliminar frentes, falso en caso contrario
         */
        self.showItemActionRemoveFront = function showItemActionRemoveFront() {
            var item = null;
            var ret = false;
            var isEdit = self.isEdit();
            if (isEdit) {
                if (angular.isObject(self.activeShelf)) {
                    item = self.activeShelf.selecteditem;
                } else if (angular.isObject(self.selectedItem)) {
                    // Busca el planograma en la colección de planogramas locales.
                    var planogram = self.findPlanogram(self.activePlanogram);

                    // Busca la columna dentro de la colección de columnas del planograma local.
                    var column = self.findColumn(planogram, self.selectedColumn);

                    // Busca el módulo dentro de la colección de columnas del planograma local.
                    var module = self.findFurniture(column, self.selectedModule);

                    var level = self.findLevel(module, self.selectedLevel);

                    // Busca el item dentro de la colección de items del estante local.
                    item = self.findItem(level, self.selectedItem);
                }

                if (angular.isObject(item)) {
                    ret = self.showIconMinus(item);
                }
            }

            return ret;
        }

        /**
         * Devuelve una cadena con el texto de ayuda para la rotación del item.
         * @returns Una cadena con el texto de ayuda para la rotación del item.
         */
        self.getActiveItemTextTooltipItemOrientation = function getActiveItemTextTooltipItemOrientation() {
            var ret = '';
            var item = null;
            if (angular.isObject(self.activeShelf)) {
                item = self.activeShelf.selecteditem;
            } else if (angular.isObject(self.selectedItem)) {
                // Busca el planograma en la colección de planogramas locales.
                var planogram = self.findPlanogram(self.activePlanogram);

                // Busca la columna dentro de la colección de columnas del planograma local.
                var column = self.findColumn(planogram, self.selectedColumn);

                // Busca el módulo dentro de la colección de columnas del planograma local.
                var module = self.findFurniture(column, self.selectedModule);

                var level = self.findLevel(module, self.selectedLevel);

                // Busca el item dentro de la colección de items del estante local.
                item = self.findItem(level, self.selectedItem);
            }

            if (angular.isObject(item)) {
                ret = item.textTooltipItemOrientation;
            }

            return ret;
        }

        /**
         * Devuelve verdadero si tiene que mostrar el botón para ir a los tickets, falso en caso contrario.
         * @returns Verdadero si tiene que mostrar el botón para ir a los tickets, falso en caso contrario.
         */
        self.showGotoTicketsButton = function showGotoTicketsButton() {
            var ret = false;
            var level;
            var isEdit = self.isEdit();
            if (!isEdit) {
                if (angular.isObject(self.activeShelf)) {
                    level = self.activeShelf.selectedLevel;
                } else {
                    // Busca el planograma en la colección de planogramas locales.
                    var planogram = self.findPlanogram(self.activePlanogram);

                    // Busca la columna dentro de la colección de columnas del planograma local.
                    var column = self.findColumn(planogram, self.selectedColumn);

                    // Busca el módulo dentro de la colección de columnas del planograma local.
                    var module = self.findFurniture(column, self.selectedModule);

                    level = self.findLevel(module, self.selectedLevel);
                }

                ret = angular.isObject(level)
                    && angular.isDefined(level.ticketCount)
                    && level.ticketCount > 0;
            }

            return ret;
        }

        /**
         * Devuelve verdadero si la cantidad de tikets es mayor a 0, falso en caso contrario.
         * @returns Verdadero si la cantidad de tikets es mayor a 0, falso en caso contrario.
         */
        self.showTicketCount = function showTicketCount() {
            var level;
            if (angular.isObject(self.activeShelf)) {
                level = self.activeShelf.selectedLevel;
            } else {
                // Busca el planograma en la colección de planogramas locales.
                var planogram = self.findPlanogram(self.activePlanogram);

                // Busca la columna dentro de la colección de columnas del planograma local.
                var column = self.findColumn(planogram, self.selectedColumn);

                // Busca el módulo dentro de la colección de columnas del planograma local.
                var module = self.findFurniture(column, self.selectedModule);

                level = self.findLevel(module, self.selectedLevel);
            }

            var ret = level.ticketCount > 0;
            return ret;
        }

        /**
         * Devuelve la cantidad de tickets del nivel.
         * @returns Cantidad de tickets del nivel.
         */
        self.getActiveItemTicketCount = function getActiveItemTicketCount() {
            var level;
            if (angular.isObject(self.activeShelf)) {
                level = self.activeShelf.selectedLevel;
            } else {
                // Busca el planograma en la colección de planogramas locales.
                var planogram = self.findPlanogram(self.activePlanogram);

                // Busca la columna dentro de la colección de columnas del planograma local.
                var column = self.findColumn(planogram, self.selectedColumn);

                // Busca el módulo dentro de la colección de columnas del planograma local.
                var module = self.findFurniture(column, self.selectedModule);

                level = self.findLevel(module, self.selectedLevel);
            }

            var ret = level.ticketCount;
            return ret;
        }

        /**
         * Devuelve verdadero si se tiene que mostrar el label de información
         * para cualquiera de las 3 opciones cantidad de carga, días en exibición y días en stock, falso en caso contrario.
         * @returns Verdadero si se tiene que mostrar el label de información, falso en caso contrario.
         */
        self.showLabelInfo = function showLabelInfo() {
            var ret = self.showExhibitionDays
                || self.showDaysInStock
                || self.showLoadQuantity;
            return ret;
        }

        /**
         * Devuelve verdadero cuando hay que mostrar la etiqueta 1 del item en el planograma, falso en caso contrario.
         * @returns Verdadero cuando hay que mostrar la etiqueta 1 del item en el planograma, falso en caso contrario.
         */
        self.showLabel1 = function showLabel1() {
            var ret = self.showRegularPrice;
            return ret;
        }

        /**
         * Devuelve verdadero si se permite el arrastrar de los ítems dentro del planograma, falso en caso contrario.
         * Verifica que no se haya seleccionado ningún ítem y que este en edición.
         * @returns Verdadero si se permite el arrastrar de los ítems dentro del planograma, falso en caso contrario.
         */
        self.allowDrag = function allowDrag() {
            var ret = !self.showItemActionToolbar()
                && self.isEdit()
                && !self.showlevelmeasurebuttons;
            return ret;
        }

          /**
         * Define la posicion del item recibido por parametro segun el punto recibido por parametro y alineando 
         * el item a la altura del item mas aledaño.
         */
        self.fixPostitionAligningItems = function fixPostition(item, point, items) {
            item.positionX = (point.local.x - point.initialOffset.x) / 10;
            if (items.length) {
                let originalPositionY = (point.local.y - point.initialOffset.y) / 10;
                let closestPositionY = items
                    .map(function (i) { return i.positionY })
                    .reduce(function (closestPosition, pos) {
                        let posDiff = pos - originalPositionY;
                        let closestPosDiff = closestPosition - originalPositionY;
                        if (Math.abs(posDiff) < Math.abs(closestPosDiff))
                            return pos;
                        else return closestPosition;
                });

                item.positionY = closestPositionY;
            }
            else item.positionY = (point.local.y - point.initialOffset.y) / 10;
        }

        self.fixPostition = function fixPostition(item, point) {
            // El valor 10 es la constante que se utiliza
            // para "ampliar" los elementos dentro del SVG para que se vean 
            // de forma correcta los detalles de los elementos (strokes, lineas, etc).
            item.positionX = (point.local.x - point.initialOffset.x) / 10;
            item.positionY = (point.local.y - point.initialOffset.y) / 10;
        }

        self.allowDragScroll = function allowDragScroll() {
            var ret = self.showPlanogramComponentSvg();
            //verifico que no halla gancheras
            if (ret) {
                var aux = false;
                loopThroughAll(function (planogram, column, module) {
                    aux = module.type === 'Peg' || aux;
                }, self.until.modules);
                ret = !aux;
            }

            return ret;
        }


        init();
    });


// Issue 289
angular.module("prisma").factory('HtmlHelper', ['$log', HtmlHelper]);

function HtmlHelper($log) {
    //$log.debug('HtmlHelper::ctor');

    function cleanUp(node) {
        var $node = $(node);
        $node.each(function (idx, item) {
            var $item = $(item);
            $item.contents()
                .filter(function (idx1, el) {
                    return el.nodeType === 3 || el.nodeType === 8;
                }).remove();
            cleanUp($item.children());

            var attrs = getAngularAttributes($item);
            $.each(attrs, function (index, attribute) {
                $item.removeAttr(attribute);
            });
            var classes = getAngularClasses($item);
            $.each(classes, function (index, klass) {
                $item.removeClass(klass);
            });
        });

        return $node;
    }

    function getAngularAttributes($node) {
        var attrs = [];
        $.each($node[0].attributes, function (index, attribute) {
            if (/ng-(.*)/.test(attribute.name)) {
                attrs.push(attribute.name);
            }
        });

        return attrs;
    }

    function getAngularClasses($node) {
        return ($node.attr('class') || '').split(' ')
            .filter(function (value) {
                return /ng-(.*)/.test(value);
            });
    }

    return {
        cleanUpHtml: cleanUp,
        getNgAttributes: getAngularAttributes,
        getNgClasses: getAngularClasses
    };
}
