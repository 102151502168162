angular.module("prisma")
    .controller("MissionsCtrl", function ($scope, $state, $translate, adminService, $timeout) {

        var self = this;
        self.search = "";
        self.filter = "";
        self.order = "daysLeft";

        self.missionList = [];
        self.frecuencies = [];
        self.surveyMethods = [];
        self.missionTypes = [];

        self.getProgressBar = function (progress) {
            if (progress != null) {
                return progress + '%';
            }
        }

        self.editMission = function (mission) {
            $state.go('admin.editMissions', { missionId: mission.id });
        }

         self.addNew = function () {
            $state.go('admin.editMissions', { missionId: 0 });
        }


        //CODIGO QUE YA EXISTIA

        //var pageLoaded = false;
        //self.pageNumber = 1;
        //self.pageSize = 30;


        //$(window).scroll(function () {
        //    if ($(window).scrollTop() + $(window).height() == $(document).height()) {
        //        if (pageLoaded) {
        //            $timeout(function () {
        //                self.pageNumber++;
        //            }, 0)
        //        }
        //    }
        //});


        //self.missions = [];

        //self.newStore = { id: null, name: '', code: '', isExcluded: false };

        //self.search = "";

        //self.filter = "";

        //self.addNew = function () {
        //    $state.go('admin.editMissions', { missionId: 0 });
        //    //self.newStore.id = 0;
        //}

        //self.editMission = function (mission) {
        //    $state.go('admin.editMissions', { missionId: mission.id });
        //    //self.newStore = store;
        //}

        function loadMissions() {
            adminService.missions.getMissions()
                .then(function (missions) {
                    self.missionList = missions;
                });
        }

        self.getMissionEnums = function () {
            adminService.missions.getMissionEnums().then(function (enums) {

                self.missionTypes = enums.missionTypes;
                self.surveyMethods = enums.surveyMethods;
                self.frecuencies = enums.frecuencies;

                self.missionTypes.forEach(function (x) {
                    x.name = translations[x.name] || x.name;
                });

                self.surveyMethods.forEach(function (x) {
                    x.name = translations[x.name] || x.name;
                });

                self.frecuencies.forEach(function (x) {
                    x.name = translations[x.name] || x.name;
                });

                loadMissions();

            });
        }

        self.getNameMissionType = function (type) {
            var rv = "";
            angular.forEach(self.missionTypes, function (mT) {
                if (mT.id == type) {
                    rv = mT.name;
                }
            });
            return rv;
        }

        self.getNameSurveyMethods = function (type) {
            var rv = "";
            angular.forEach(self.surveyMethods, function (sM) {
                if (sM.id == type) {
                    rv = sM.name;
                }
            });
            return rv;
        }

        self.getNameFrecuencies = function (type) {
            var rv = "";
            angular.forEach(self.frecuencies, function (f) {
                if (f.id == type) {
                    rv = f.name;
                }
            });
            return rv;
        }

        self.isExpiredMission = function (mission) {
            rv = false;
            if (mission.daysLeft < 0) {
                rv = true;
            }
            return rv;
        }

        self.deleteMission = function (mission) {
            swal({
                title: "Esta seguro que desea eliminar la mision?.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {

                    if (!isConfirm) return;

                    adminService.missions.deleteMission(mission.id).then(function () {
                        swal("Confirmado", "", "success");
                        var index = self.missionList.indexOf(mission);
                        self.missionList.splice(index, 1);
                    }),
                        function () {
                            swal("Error", "", "error");
                        };
                });
        }

        
        var translations = null;

        function init() {
            $translate(["Strip", "Catalog", "OneTime", "Biannual", "Annual", "PriceSurvey",
                "ExhibitionSurvey", "PriceLabelScan", "VoiceCommand", "ManualEntry", "Daily", "Weekly", "Biweekly", "Monthly"])
                .then(function (all) {
                    translations = all;
                });

            self.getMissionEnums();
        }

        init();
    });




