angular.module("prisma")
 .controller("InterfaceCtrl", function ($scope, adminService, $timeout, $window, $stateParams, $filter, $http) {
     var self = this;

     self.isLoading = true;
     self.isLoadingDetails = false;
     self.isLoadingDetailSummary = false;
     self.interfaces = [];
     self.detailSummary = [];
     self.details = [];

     self.filter = {
         batchIdentifier: '',
         importer: '',
         step: ''
     }

     self.search = '';

     self.clearFilter = function () {
         self.filter = {
             batchIdentifier: '',
             importer: '',
             step: ''
         }

     }

     self.downloadFile = function (importerType, fileName) {

         var url = '/api/admin/interfaces/getFile/' + importerType + '/' + fileName;

         //$window.open(url, "_blank");

         $http.get(url).success(function (data, status, headers) {
             ////PopUps.hideLoading()
             //var headers = result.headers()
             //var blob = new Blob([result.data], { type: headers['content-type'] })
             //var windowUrl = (window.URL || window.webkitURL)
             //var downloadUrl = windowUrl.createObjectURL(blob)
             //var anchor = document.createElement("a")
             //anchor.href = downloadUrl
             //var fileNamePattern = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
             //anchor.download = fileNamePattern.exec(headers['content-disposition'])[1]
             //document.body.appendChild(anchor)
             //anchor.click()
             //windowUrl.revokeObjectURL(blob)

             headers = headers();

             var filename = fileName;
             var contentType = headers['content-type'];

             var linkElement = document.createElement('a');
             try {
                 var blob = new Blob([data], { type: contentType });
                 var url = window.URL.createObjectURL(blob);

                 linkElement.setAttribute('href', url);
                 linkElement.setAttribute("download", filename);

                 var clickEvent = new MouseEvent("click", {
                     "view": window,
                     "bubbles": true,
                     "cancelable": false
                 });
                 linkElement.dispatchEvent(clickEvent);
             } catch (ex) {
                 console.log(ex);
             }
         })
     }

     self.dateChanged = function () {
         var date = self.selectedDate;

         self.isLoading = true;
         adminService.interfaces.getByDate(date).then(function (interfaces) {
             self.interfaces = interfaces;
             self.detailSummary = [];
             self.details = [];
             self.isLoading = false;
         });
     }

     self.back = function () {
         self.details = [];
         self.detailSummary = [];
     }

     self.setFilterInterface = function (interfaceName) {
         self.filter.importer = interfaceName;
         self.txtFilterInterface = interfaceName;
     }

     self.setFilterStatus = function (status) {
         self.search = status;
         self.txtFilterStatus = status;
     }

     self.setFilterStep = function (step) {
         self.filter.step = step;
         self.txtFilterStep = step;
     }

     self.getFilteredDuration = function () {
         if (self.interfaces) {
             var list = $filter('filter')(self.interfaces, self.search);
             list = $filter('filter')(list, self.filter.importer);
             list = $filter('filter')(list, self.filter.step);
             var total = 0;
             angular.forEach(list, function (item) {
                 total += item.duration;
             });

             return (total / 60).toFixed(2);
         }

         return '';
     }

     self.getIcon = function (interfaceDto) {
         if (!interfaceDto.success)
             return 'fa-times';

         if (interfaceDto.message.indexOf('WARNING') != -1)
             return 'fa-exclamation-triangle';

         return 'fa-check';
     }

     self.getColor = function (interfaceDto) {
         if (!interfaceDto.success)
             return 'text-danger';

         if (interfaceDto.message.indexOf('WARNING') != -1)
             return 'text-warning';

         return 'text-success';
     }


     self.getIconDetail = function (detail) {
         if (detail.severity == 'Information')
             return 'fa-info-circle';

         if (detail.severity == 'Warning')
             return 'fa-exclamation-triangle';

         if (detail.severity == 'Error')
             return 'fa-times';

     }

     self.getColorDetail = function (detail) {
         if (detail.severity == 'Information')
             return 'text-success';

         if (detail.severity == 'Warning')
             return 'text-warning';

         if (detail.severity == 'Error')
             return 'text-danger';
     }

     self.selectInterface = function (interfaceDto) {

         self.isLoadingDetailSummary = true;
         adminService.interfaces.getDetailSummary(interfaceDto.id).then(function (detailSummary) {
             self.detailSummary = detailSummary;
             self.isLoadingDetailSummary = false;
         });
     }


     self.selectDetailSummary = function (InterfaceLogId) {
         
         self.isLoadingDetails = true;
         adminService.interfaces.getDetails(InterfaceLogId).then(function (details) {
             self.details = details;
             self.isLoadingDetails = false;
         });
     }

     function init() {

         self.isLoading = true;
         if ($stateParams.batchIdentifier) {
             adminService.interfaces.getByBatch($stateParams.batchIdentifier).then(function (interfaces) {
                 self.interfaces = interfaces;
                 self.selectedDate = new Date(interfaces[0].importedDate);
                 self.isLoading = false;
             });
         }
         else {
             adminService.interfaces.getLast().then(function (interfaces) {
                 self.interfaces = interfaces;
                 self.isLoading = false;
             });
         }

         

     }

     init();
 });