
angular.module("prisma")
  .controller("DstCtrl", function ($scope, $rootScope, $timeout, $translate, $interval, $stateParams, serverService, homeService, adminService) {
      var self = this;

      //TRADUCCIONES JS
      self.sameStores = true;
      self.type = $stateParams.type;
      //console.log('self.type', self.type);
      self.toTitleCase = $rootScope.toTitleCase;

      self.dstRunning = false;
      self.dstInited = false;
      self.selectedCategoryGroup = null;
      self.allCategoryGroups = null;
      self.states = null;
      //api
      var root = {};

      var destroyed = false;

      var translations = null;
      $translate(["SALES", "UNITS", "MARGIN", "IsThe", "Store", "Category", "WithGreatestIncrease", "WithGreatestDecrease", "DstPopUpInformativeGraphic", "Family", "SelectFamily", "DstPopUpInformativeGraphicLess"])
                .then(function (all) {
                    translations = all;
                });


      self.getValueText = function () {
          if (self.sizeType == 'units')
              return translations.UNITS;

          if (self.sizeType == 'sales')
              return translations.SALES;

          if (self.sizeType == 'margin')
              return translations.MARGIN;
      }


      self.disableStart = function () {
          if (self.type == 'category') {
              return self.selectedCategoryGroup == null || self.dstRunning;
          }

          if (self.type == 'store') {
              return self.dstRunning;
          }

          return true;
      }

      //Chart Class
      var Chart;

      Chart = function (data) {

          var that = this;

          /* STATE */

          this.data = data;
          this.width = 970;
          this.height = 1200;
          this.center = {
              x: that.width / 2,
              y: that.height / 2
          };
          this.centersBySalesDiff = [ //center 425
              { y: 450 },
              { y: 440 },
              { y: 430 },
              { y: 420 },
              { y: 410 },
              { y: 400 }
          ]
          this.dGravity = 0.1;
          this.damper = 1;
          this.dFriction = 0.9;
          this.svg = null;
          this.force = null;
          this.circles = null;
          this.nodes = [];
          this.changeTickValues = [-0.25, -0.15, -0.05, 0.05, 0.15, 0.25];
          this.tickChangeFormat = d3.format("+%");
          this.groupby = "category";
          this.scaleRadius = d3.scale.pow().exponent(0.5).domain([0, 10000]).range([0.3, 55]);
          this.scaleChange = d3.scale.linear().domain([-0.28, 0.28]).range([680, 240]).clamp(true);
          this.scaleGroup = d3.scale.ordinal().rangePoints([0, 1]);
          this.scaleFillColor = d3.scale.ordinal().domain([-3, -2, -1, 0, 1, 2, 3]).range(["#d84b2a", "#ee9586", "#e4b7b2", "#AAA", "#beccae", "#9caf84", "#7aa25c"]);
          this.scaleStrokeColor = d3.scale.ordinal().domain([-3, -2, -1, 0, 1, 2, 3]).range(["#c72d0a", "#e67761", "#d9a097", "#999", "#a7bb8f", "#7e965d", "#5a8731"]);
          this.currentLayout = null;
          this.currentStep = 0;
          this.runningStory = true;
          this.highestSubcategoryId = 0;
          this.lowestSubcategoryId = 0;

          /* METHODS */

          this.init = function () {


              //Scale bubbles by sales [DB]
              var maxSales = d3.max(that.data, function (d) { return d[self.sizeType]; });
              that.scaleRadius = that.scaleRadius.domain([0, maxSales]);

              //Scale groups add domain
              var allGroups = that.data.map(function (e, i) { return e.category });
              var groups = [allGroups[0]];
              for (var i = 0; i < allGroups.length; i++) {
                  var cur = allGroups[i];
                  if (groups.indexOf(cur) < 0) {
                      groups.push(cur);
                  }
              }
              that.scaleGroup.domain(groups);


              //create nodes
              that.createNodes();

              //scale category
              groups.forEach(function (g, i) {
                  var groupSales = that.nodes.reduce(function (a, b) {

                      if (isNaN(a)) {
                          return (a.original.category == g) ? a.original[self.sizeType] : 0
                              + (b.original.category == g) ? b.original[self.sizeType] : 0;
                      }
                      else {
                          return a + (b.original.category == g) ? b.original[self.sizeType] : 0;
                      }

                  });
              });

              //init chart
              that.createVis();

              //set force
              that.force = d3.layout
                             .force()
                             .nodes(that.nodes)
                             .size([that.width, that.height]);
          }

          this.createNodes = function () {

              var max = 0;
              var min = 0;

              that.data.forEach(function (d, i) {

                  if (d[self.sizeType + 'DeltaPercentage'] < min) {
                      min = d[self.sizeType + 'DeltaPercentage'];
                      that.lowestSubcategoryId = d.subcategoryId;
                  }

                  if (d[self.sizeType + 'DeltaPercentage'] > max) {
                      max = d[self.sizeType + 'DeltaPercentage'];
                      that.highestSubcategoryId = d.subcategoryId;
                  }

                  var hMultiplier = (that.height / that.changeTickValues.length);

                  var node = {
                      id: i,
                      original: d,
                      radius: that.scaleRadius(d[self.sizeType]),
                      value: d[self.sizeType],
                      isNegative: (d[self.sizeType] < 0),
                      changeCategory: that.categorizeChange(d[self.colorType + 'DeltaPercentage']),
                      changeCategorySize: that.categorizeChange(d[self.sizeType + 'DeltaPercentage']),
                      x: that.center.x,
                      y: -(this.changeCategorySize * hMultiplier)
                  };

                  that.nodes.push(node);
              });

              //sort the nodes
              that.nodes.sort(function (a, b) {
                  return Math.abs(b[self.sizeType]) - Math.abs(a[self.sizeType]);
              });

          }

          this.createVis = function () {

              /*MAIN CHART*/

              //svg
              that.svg = d3.select("#vis").append("svg")
                .attr("width", that.width)
                .attr("height", that.height)
                .attr("id", "svg_vis");


              /*OVERLAYS*/

              // total overlay
              d3.select("#deficitCircle circle").remove();
              d3.select("#deficitCircle").append("circle")
                .attr('r', that.scaleRadius(2000000))
                .attr('class', "deficitCircle")
                .attr('cx', 125)
                .attr('cy', 125);
              d3.selectAll("#scaleKey circle").remove()
              d3.select("#scaleKey").append("circle")
                //.attr('r', that.scaleRadius(800000))
                .attr('r', 40.22)
                .attr('class', "scaleKeyCircle")
                .attr('cx', 45)
                .attr('cy', 45);
              d3.select("#scaleKey").append("circle")
                //.attr('r', that.scaleRadius(100000))
                .attr('r', 14.41)
                .attr('class', "scaleKeyCircle")
                .attr('cx', 45)
                .attr('cy', 70);
              d3.select("#scaleKey").append("circle")
                //.attr('r', that.scaleRadius(10000))
                .attr('r', 4.76)
                .attr('class', "scaleKeyCircle")
                .attr('cx', 45)
                .attr('cy', 80);


              //changes overlay
              for (var i = 0; i < this.changeTickValues.length; i++) {
                  d3.select("#changeOverlay").append("div")
                    .html("<p>" + this.tickChangeFormat(this.changeTickValues[i]) + "</p>")
                    .style("top", this.scaleChange(this.changeTickValues[i]) + 'px')
                    .classed('discretionaryTick', true)
                    .classed('discretionaryZeroTick', (this.changeTickValues[i] === 0))
              };
              d3.select("#changeOverlay").append("div")
                .html("<p></p>")
                .style("top", this.scaleChange(0) + 'px')
                .classed('discretionaryTick', true)
                .classed('discretionaryZeroTick', true)
              d3.select("#changeOverlay").append("div")
                .html("<p>+26% or higher</p>")
                .style("top", this.scaleChange(100) + 'px')
                .classed('discretionaryTickLabel', true)
              d3.select("#changeOverlay").append("div")
                .html("<p>&minus;26% or lower</p>")
                .style("top", this.scaleChange(-100) + 'px')
                .classed('discretionaryTickLabel', true)

              //category overlays
              d3.select("#finishDialog #btnOk").on("click", that.finishStory);
          }

          this.labels = function (centers) {
              d3.selectAll(".bb_label").remove();

              if (!destroyed) {

                  //HACK: con esto evito que saltee el primer item del array. No se que genera este comportamiento [DB]
                  var data = centers;
                  data.unshift({});

                  //append as div
                  d3.select("#vis")
                  .data(data).enter().append("div")
                  .text(function (d) { return d.name })
                  .attr("class", "bb_label cat")
                  .attr("data-props", function (d) { return d.h + "h, " + d.w + "w, " + d.x + "x, " + d.y + "y" })
                  .style("width", function (d) { return d.w + "px" })
                  .style("top", function (d) {
                      return Math.floor((d.y - (d.h / 2)) - 130) + 230 + "px";
                  })
                  .style("left", function (d) {
                      return d.x - (d.w / 2) + 260 + "px";
                  })
                  .append("div")
                      .attr("class", "bb_label total")
                      .style("width", function (d) { return d.w + "px" })
                      .text(function (d) { return "$ " + ian.abbreviateNumber(d.total, 1) })

              }

          }

          this.clearLayout = function () {

              //remove all labels
              d3.selectAll(".bb_label").remove();

              //hide all overlays
              d3.selectAll('.overlay').style('display', 'none').style('opacity', 0);


          }

          this.getCenters = function (vname, size) {

              //group and sort
              var data = [];
              //var nodes = _.pluck(that.nodes, 'original');
              var nodes = _.map(that.nodes, 'original');
              nodes = _.groupBy(nodes, function (d) { return d[that.groupby]; });
              for (var name in nodes) {
                  var group = nodes[name];
                  data.push({
                      name: name,
                      items: group,
                      total: _.reduce(group, function (memo, num) { return memo + num[self.sizeType]; }, 0)
                  })
              }
              data = _.sortBy(data, 'total').reverse();

              //corrections
              var xt = 0;
              var yt = 510;

              var rows = [{ cols: 4, h: 210, y: 0 },
                          { cols: 6, h: 150, y: 250 },
                          { cols: 8, h: 150, y: 470 },
                          { cols: 9, h: 100, y: 620 }, //580
                          { cols: 9, h: 100, y: 730 },
                          { cols: 9, h: 100, y: 820 },
                          { cols: 9, h: 100, y: 920 }];

              var i = 0;
              for (var r = 0; r < rows.length; r++) {
                  var row = rows[r];
                  for (var c = 0; c < row.cols; c++) {
                      if (i < data.length) {
                          var d = data[i];
                          var w = Math.floor(size[0] / row.cols);
                          var h = row.h;
                          var x = ((w * (c + 1)) - (w / 2));
                          var y = row.y;

                          d.w = w;
                          d.h = h;
                          d.x = x + xt;
                          d.y = y + yt;

                          i++;
                      }
                  }
              }

              return data;
          }

          this.show_details = function (data, i) {

              var el = d3.select(this)
                  .style("stroke-width", 2)
                  .style("stroke", "black");

              var title = data.original.category;
              var name = data.original.subcategory;
              var sales = ian.abbreviateNumber(data.original[self.sizeType], 1);
              var diff = Math.round(data.original[self.sizeType + 'DeltaPercentage'] * 100);

              var xpos = Number(el.attr('cx')) + 7;
              var ypos = (el.attr('cy') - data.radius - 85);

              d3.select("#details").style('top', ypos + "px").style('left', xpos + "px")
                  .style('display', 'block')
                .classed('plus', (data.original[self.sizeType + 'DeltaPercentage'] > 0))
                .classed('minus', (data.original[self.sizeType + 'DeltaPercentage'] < 0));
              d3.select("#details .name").html(name);
              d3.select("#details .title").text(title);
              d3.select("#details .value").html((self.sizeType == 'units' ? '' : '$') + sales);
              d3.select("#details .change").html(diff > 0 ? ("+" + diff + "%") : (diff + "%"));

          }

          this.hide_details = function (data, i) {
              d3.select(this)
                  .style("stroke-width", 1)
                  .style("stroke", that.getStrokeColor);

              d3.select("#details").style('display', 'none');
          }

          this.getFillColor = function (d) {
              return that.scaleFillColor(d.changeCategory);
          }

          this.getStrokeColor = function (d) {
              return that.scaleStrokeColor(d.changeCategory);
          }

          this.categorizeChange = function (c) {
              if (isNaN(c)) {
                  return 0;
              } else if (c < -0.25) {
                  return -3;
              } else if (c < -0.05) {
                  return -2;
              } else if (c < -0.001) {
                  return -1;
              } else if (c <= 0.001) {
                  return 0;
              } else if (c <= 0.05) {
                  return 1;
              } else if (c <= 0.25) {
                  return 2;
              } else { return 3; }
          }

          this.makeInteractive = function () {
              //activate tabs
              d3.selectAll('.navigation li').on('click', root.onLayoutChange);

              //activate tooltips
              that.circles
                  .on("mouseover", that.show_details)
                  .on("mouseout", that.hide_details);
          }

          that.removeLabels = function () {
              d3.selectAll(".bb_label").remove();
          }


          /* STORY SEQUENCE */

          this.beginStoryOrig = function () {

              var delay = 100 //modify to change delay [DB]

              //total layout
              window.setTimeout(function () { that.next(1000) }, delay * 0);     //overview  
              window.setTimeout(function () { that.next(1000) }, delay * 1);      //create bubbles
              window.setTimeout(function () { that.next(2000) }, delay * 2);      //size legend
              window.setTimeout(function () { that.next(2000) }, delay * 3);      //resize bubbles
              window.setTimeout(function () { that.next(2000) }, delay * 4);      //color legend
              window.setTimeout(function () { that.next(2000) }, delay * 5);      //paint bubbles
              window.setTimeout(function () { that.next(1000) }, delay * 7);      //goto change layout

              //changes layout
              window.setTimeout(function () { that.next(1000) }, delay * 10);      //highlight best
              window.setTimeout(function () { that.next(1000) }, delay * 14);      //highlight worst
              window.setTimeout(function () { that.next(1000) }, delay * 19);      //goto category layout


              //category layout
              window.setTimeout(function () { that.next(1000) }, delay * 22);     //show final dialog
          }

          this.beginStory = function () {

              var duration = 1800; //modify to change speed [DB] 1800
              var delay = duration;


              //total layout
              window.setTimeout(function () { that.next(duration) }, delay * 0);     //overview  
              window.setTimeout(function () { that.next(duration) }, delay * 1);      //create bubbles
              window.setTimeout(function () { that.next(duration) }, delay * 2);      //size legend
              window.setTimeout(function () { that.next(duration) }, delay * 3);      //resize bubbles
              window.setTimeout(function () { that.next(duration) }, delay * 4);      //color legend
              window.setTimeout(function () { that.next(duration) }, delay * 5);      //paint bubbles
              window.setTimeout(function () { that.next(duration) }, delay * 7);      //goto change layout

              //changes layout
              window.setTimeout(function () { that.next(duration) }, delay * 10);      //highlight best
              window.setTimeout(function () { that.next(duration) }, delay * 14);      //highlight worst
              window.setTimeout(function () { that.next(duration) }, delay * 19);      //goto category layout


              //category layout
              window.setTimeout(function () { that.next(1000) }, delay * 22);     //show final dialog
          }

          this.finishStory = function () {

              //animate final dialog exit
              d3.select("#finishDialog")
                 .transition()
                 .duration(1000)
                  .style('top', "-500px");

              //hide all tooltips
              window.setTimeout(function () {
                  d3.selectAll(".tooltip").style("display", "none");
              }, 2500);

              //enable user interaction
              that.makeInteractive();

              //mark story as finished
              that.runningStory = false;
              $scope.$apply(function () {
                  self.dstRunning = false;
              });


          }

          this.next = function (duration) {

              that.currentStep++
              if (that.currentLayout == "total") {
                  switch (that.currentStep) {
                      case 1: that.showOverview(duration); break;
                      case 2: that.createBubbles(duration); break;
                      case 3: that.showSizeLegend(duration); break;
                      case 4: that.resizeBubbles(duration); break;
                      case 5: that.showColourLegend(duration); break;
                      case 6: that.colourBubbles(duration); break;
                      case 7: that.displayLayoutChange(duration); break;
                  }
              }
              if (that.currentLayout == "change") {
                  switch (that.currentStep) {
                      case 1: that.highlightBest(duration); break;
                      case 2: that.highlightWorst(duration); break;
                      case 3: {
                          that.clearChangesEffects(duration, that.displayLayoutCategory);
                          break;
                      }
                  }
              }
              if (that.currentLayout == "category") {
                  switch (that.currentStep) {
                      case 1: that.showFinishDialog(duration); break;
                  }
              }
          }


          //total story
          this.showOverview = function (duration) {
              d3.select('#totalOverlay .overview')
                  .transition(duration)
                      .style('opacity', 1);
          }

          this.showSizeLegend = function (duration) {
              d3.select('#totalOverlay .size-ref')
                  .transition(duration)
                      .style('opacity', 1);
          }

          this.showColourLegend = function (duration) {
              d3.select('#totalOverlay .color-ref')
                  .transition(duration)
                      .style('opacity', 1);
          }

          this.createBubbles = function (duration) {

              that.circles = that.svg.selectAll("circle")
                                     .data(that.nodes, function (d) { return d.id; });
              that.circles.enter()
                          .append("circle")
                          .attr("r", 15)
                          .attr("stroke", "#ccc")
                          .style("fill", "#555")
                          .attr("stroke-width", 1)
                          .attr("id", function (d) { return "bubble_" + d.id; });

              that.force
                .gravity(-0.01)
                .charge(that.defaultCharge)
                .friction(0.9)
                .on("tick", function (e) {
                    that.circles
                      .each(that.sortForLayoutAll(e.alpha))
                      .each(that.buoyancy(e.alpha * 1.45))
                      .attr("cx", function (d) { return d.x; })
                      .attr("cy", function (d) { return d.y; });
                })
                .start();
          }

          this.resizeBubbles = function (duration) {

              that.circles
                  .transition()
                  .duration(duration)
                  .attr("r", function (d) { return d.radius; });
          }

          this.colourBubbles = function (duration) {
              that.circles
                 .transition()
                 .duration(duration)
                      .attr("stroke", that.getStrokeColor)
                      .style("fill", that.getFillColor)
          }

          //changes story
          this.highlightBest = function (duration) {
              that.circles
                 .transition()
                 .duration(duration)
                      .style("opacity", function (d) {
                          if (d.original.subcategoryId == that.highestSubcategoryId) {

                              var number = ian.abbreviateNumber(Math.abs(d.original[self.sizeType + 'Delta']), 1);
                              if (number > 0 && number < 1)
                                  number = parseFloat(number).toPrecision(4);
                              var text = d.original.subcategory + translations.IsThe + (self.type == 'store' ? translations.Store : translations.Category) + translations.WithGreatestIncrease + " (<span class=\"change\">" + $rootScope.toPercentage(number) + "</span>)" + translations.DstPopUpInformativeGraphic;
                              that.showCustomTooltip(text, this, duration);

                              return 1;
                          }
                          else {
                              return .2;
                          }
                      })
                  .style("stroke-width", function (d) { return d.original.subcategoryId == that.highestSubcategoryId ? 2 : 1; })
                  .style("stroke", function (d) { if (d.original.subcategoryId == that.highestSubcategoryId) return "black"; })

              that.circles
                 .transition()
                 .delay(11000)
                 .duration(1000)
                      .style("opacity", 1)
                  .style("stroke-width", 1)
                  .style("stroke", that.getStrokeColor);

              d3.select("#customTooltip")
                 .transition()
                 .delay(11000)
                 .duration(1000)
                      .style("opacity", 0);

          }

          this.highlightWorst = function (duration) {
              that.circles
                 .transition()
                 .duration(duration)
                      .style("opacity", function (d) {
                          if (d.original.subcategoryId == that.lowestSubcategoryId) {

                              var number = ian.abbreviateNumber(Math.abs(d.original[self.sizeType + 'Delta']), 1);
                              if (number > 0 && number < 1)
                                  number = parseFloat(number).toPrecision(4);
                              var text = d.original.subcategory + translations.IsThe + (self.type == 'store' ? translations.Store : translations.Category) + translations.WithGreatestDecrease + " (<span class=\"change\">" + $rootScope.toPercentage(number) + "</span>)" + translations.DstPopUpInformativeGraphicLess;
                              that.showCustomTooltip(text, this, duration);
                              return 1;
                          }
                          else {
                              return .2;
                          }
                      })
                  .style("stroke-width", function (d) { return d.original.subcategoryId == that.lowestSubcategoryId ? 2 : 1; })
                  .style("stroke", function (d) { if (d.original.subcategoryId == that.lowestSubcategoryId) return "black"; });


              that.circles
                 .transition()
                 .delay(10500)
                 .duration(1000)
                      .style("opacity", 1)
                  .style("stroke-width", 1)
                  .style("stroke", that.getStrokeColor);


              d3.select("#customTooltip")
                 .transition()
                 .delay(10500)
                 .duration(1000)
                      .style("opacity", 0);

          }

          this.showCustomTooltip = function (text, elem, duration) {

              var el = d3.select(elem);
              var xpos = Number(el.attr('cx'))
              var ypos = Number(el.attr('cy'))
              //var ypos = (el.attr('cy') - elem.__data__.radius - 100)

              d3.select("#customTooltip .title").html(text);
              d3.select("#customTooltip")
                  .style('top', ypos + "px")
                  .style('left', xpos + "px")
                  .style('opacity', 0)
                  .style('display', 'block')
                  .classed('plus', (elem.__data__.original[self.sizeType + 'DeltaPercentage'] > 0))
                  .classed('minus', (elem.__data__.original[self.sizeType + 'DeltaPercentage'] < 0))
              .transition()
              .duration(duration || 1000)
                  .style('opacity', 1);
          }

          this.clearChangesEffects = function (duration, callback) {
              d3.selectAll("#customTooltip").style('display', 'none');

              that.circles
                  .style("opacity", 1)
                  .style("stroke-width", 1)
                  .style("stroke", that.getStrokeColor);

              callback(duration);
          }

          //category story
          this.showFinishDialog = function (duration) {
              d3.select("#finishDialog")
                  .style('opacity', 0)
                  .style('top', "-1500px")
                  .style('display', 'block')
              .transition()
              .duration(2000)
                  .style('opacity', 1)
                  .style('top', "500px");

          }



          /* LAYOUTS */

          this.displayLayoutTotal = function () {

              //remove fixed labels
              that.removeLabels();

              that.currentStep = 0;
              that.currentLayout = 'total';

              that.clearLayout();

              d3.select('#totalOverlay')
                  .style('display', 'block')
                  .transition()
                      .delay(300)
                      .duration(500)
                  .style('opacity', 1);

              if (that.runningStory) {
                  that.beginStory();
              }
              else {
                  that.force
                    .gravity(-0.01)
                    .charge(that.defaultCharge)
                    .friction(0.9)
                    .on("tick", function (e) {
                        that.circles
                          .each(that.sortForLayoutAll(e.alpha))
                          .each(that.buoyancy(e.alpha * 1.45))
                          .attr("cx", function (d) { return d.x; })
                          .attr("cy", function (d) { return d.y; })

                    })
                    .start();
              }

          }

          this.displayLayoutChange = function () {

              //remove fixed labels
              that.removeLabels();

              //set layout from step 0
              that.currentStep = 0;
              that.currentLayout = 'change';

              //highlight change tab
              d3.selectAll('.navigation li').classed({ 'selected': false });
              d3.select('.navigation li#change').classed({ 'selected': true });

              that.force
                .gravity(0)
                .charge(0)
                .friction(0.2)
                .on("tick", function (e) {
                    that.circles
                      .each(that.sortForLayoutChange(e.alpha))
                      .attr("cx", function (d) { return d.x; })
                      .attr("cy", function (d) { return d.y; });
                })
                .start();

              that.clearLayout();

              d3.select('#changeOverlay')
                  .style('display', 'block')
                  .transition()
                      .delay(300)
                      .duration(500)
                  .style('opacity', 1);
          }

          this.displayLayoutCategory = function () {

              //set layout from step 0
              that.currentStep = 0;
              that.currentLayout = 'category';

              //highlight change tab
              d3.selectAll('.navigation li').classed({ 'selected': false });
              d3.select('.navigation li#category').classed({ 'selected': true });



              var centers = that.getCenters(that.groupby, [that.width, that.height]);

              var foci = {};
              for (var i = 0; i < centers.length; i++) {
                  var c = centers[i];
                  foci[c.name] = {
                      items: c.items,
                      total: c.total,
                      w: c.w,
                      h: c.h,
                      x: c.x,
                      y: c.y
                  }
              }

              that.clearLayout();

              that.force
                  .gravity(0)
                  .charge(1)
                  .friction(0)
                  .on("tick", function (e) {
                      that.circles
                          .each(that.sortForLayoutCategory(foci, e.alpha))
                          .each(that.collide(0.01))
                          .attr("cx", function (d) { return d.x; })
                          .attr("cy", function (d) { return d.y; });
                  })
                  .start();

              that.labels(centers);

              d3.select('#familyOverlay')
                  .style('display', 'block')
                  .transition()
                      .delay(300)
                      .duration(500)
                  .style('opacity', 1);
          }


          /* SORTING */

          this.sortForLayoutAll = function (alpha) {

              return function (d) {
                  var y = d3.scale.linear().domain([-0.5, 0.5]).rangeRound([0, 5]).clamp(true);
                  //console.log('d', d);
                  var range = y(d.original[self.sizeType + 'DeltaPercentage']);
                  //console.log('that', that);
                  //console.log('range', range);
                  var targetY = that.centersBySalesDiff[range].y;
                  var targetX = that.width / 2;

                  if (d.isNegative) {
                      if (d.changeCategorySize > 0) {
                          d.x = -200
                      } else {
                          d.x = 1100
                      }
                  }

                  d.y = d.y + (targetY - d.y) * 0.77 * alpha
                  d.x = d.x + (targetX - d.x) * 0.77 * alpha
              };
          }

          this.sortForLayoutChange = function (alpha) {

              return function (d) {
                  var targetY = that.height / 2;
                  var targetX = 0;

                  if (d.isNegative) {
                      if (d.changeCategorySize > 0) {
                          d.x = -200
                      } else {
                          d.x = 1100
                      }
                      return;
                  }

                  targetY = that.scaleChange(d.original[self.sizeType + 'DeltaPercentage']) + 122;
                  targetX = 100 + that.scaleGroup(d.original.category) * (that.width - 220);
                  if (isNaN(targetY)) { targetY = that.center.y };
                  if (targetY > (that.height - 80)) { targetY = that.height - 80 };
                  if (targetY < 80) { targetY = 80 };


                  d.y = d.y + (targetY - d.y) * Math.sin(Math.PI * (1 - alpha * 10)) * 0.2
                  d.x = d.x + (targetX - d.x) * Math.sin(Math.PI * (1 - alpha * 10)) * 0.1

                  ////fixed out of screen
                  //if (d.x > 800)
                  //    d.x -= 100;
              };
          }

          this.sortForLayoutCategory = function (foci, alpha) {

              return function (d) {
                  var f = foci[d.original[that.groupby]];

                  d.x += (f.x - d.x) * alpha;
                  d.y += (f.y - d.y) * alpha;
              }
          }


          /* FORCES */

          this.defaultCharge = function (d) {
              if (d.radius === 0) {
                  return 0;
              }
              return -Math.pow(d.radius, 2) / 1.15;
          }

          this.buoyancy = function (alpha) {
              var that = this;
              return function (d) {

                  var targetY = that.center.y - (d.changeCategorySize / 3) * that.scaleRadius(10000)
                  d.y = d.y + (targetY - d.y) * (that.dGravity) * alpha * alpha * alpha * 100

              };
          }

          this.collide = function (alpha) {
              var quadtree = d3.geom.quadtree(that.nodes);
              return function (d) {
                  var maxRadius = d3.max(that.nodes, function (d) { d.radius });
                  var padding = 5;
                  var r = d.radius + maxRadius + padding,
                      nx1 = d.x - r,
                      nx2 = d.x + r,
                      ny1 = d.y - r,
                      ny2 = d.y + r;
                  quadtree.visit(function (quad, x1, y1, x2, y2) {
                      if (quad.point && (quad.point !== d)) {
                          var x = d.x - quad.point.x,
                              y = d.y - quad.point.y,
                              l = Math.sqrt(x * x + y * y),
                              r = d.radius + quad.point.radius + padding;
                          if (l < r) {
                              l = (l - r) / l * alpha;
                              d.x -= x *= l;
                              d.y -= y *= l;
                              quad.point.x += x;
                              quad.point.y += y;
                          }
                      }
                      return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
                  });
              };
          }



          return this;
      };

      root.parseData = function (json) {
          console.log('parse data', json);
          if (json.length == 0) {
              self.dstInited = false;
              self.dstRunning = false;
              $scope.$apply();
              return;
          }

          json.forEach(function (d, i) {
              d[self.sizeType] = +d[self.sizeType];
              d['prev' + self.sizeType] = +d['prev' + self.sizeType];
              d[self.sizeType + 'Delta'] = +d[self.sizeType + 'Delta'];
              d[self.sizeType + 'DeltaPercentage'] = +d[self.sizeType + 'DeltaPercentage'];
          });
          root.renderChart(json);

      }
      root.renderChart = function (json) {

          root.chart = new Chart(json);
          root.chart.init();
          root.chart.displayLayoutTotal();

          d3.select('#next').on('click', root.chart.next);

      };
      root.onLayoutChange = function () {

          d3.selectAll('.navigation li').classed({ 'selected': false });
          d3.select(this).classed({ 'selected': true });

          switch (this.id) {
              case 'all': root.chart.displayLayoutTotal(); break;
              case 'change': root.chart.displayLayoutChange(); break;
              case 'category': root.chart.displayLayoutCategory(); break;
          }
      }
      root.start = function () {
          //init chart

          var filter = self.selectedCategoryGroup ? self.selectedCategoryGroup.toString() : self.selectedState.toString();

          homeService.dst.getBubbles(filter, self.timeType, self.type, self.sameStores)
              .then(function (bubbles) {

                  root.parseData(bubbles);
              });

      }

      $scope.$on("$destroy", function () {
          try {
              root.chart.removeLabels();
              d3.select("svg").remove();

              destroyed = true;
          } catch (e) {

          }

      });

      //init chart
      var isFirstLoad = true;
      self.startDst = function () {

          if (!self.selectedState && !self.selectedCategoryGroup)
              return;
          
          if (!isFirstLoad) {
              d3.selectAll('.navigation li').classed({ 'selected': false });
              root.chart.displayLayoutTotal();
          }
          d3.select("svg").remove();
          self.dstRunning = true;
          self.dstInited = true;
          isFirstLoad = false;

          root.start();
      }

      //init Data
      self.loadData = function () {
          if (self.type == 'category') {
              homeService.categories.getCategoryGroups()
                  .then(function (cg) {
                      self.allCategoryGroups = cg;
                  });
          }

          if (self.type == 'store') {
              homeService.dst.getStates()
                .then(function (states) {
                    self.states = states;
                });
          }

      }

      self.changedCategoryGroup = function () {
          d3.selectAll(".bb_label").remove();
      }

      self.loadData();

  });

