angular.module("prisma")
.config(['$provide', function ($provide) {
    $provide.decorator('$state', ['$delegate', '$window',
        function ($delegate, $window) {
            var extended = {
                goNewTab: function (stateName, params) {
                    $window.open(
                        $delegate.href(stateName, params, { absolute: true }), '_blank');
                }
            };
            angular.extend($delegate, extended);
            return $delegate;
        }]);
}]);