angular.module("prisma")
  .controller("MarkdownRulesSelectionCtrl", function ($rootScope, $state, $timeout, $interval, $filter, markdownService) {

      var self = this;
      var dic = {};
      var numberColWidth = 100;
      var editableColWidth = 140;
      var lastAdded = null;
      var columnDefs = null;
      self.validationErrors = '';
      self.orders = [];
      
      self.planTypes = function () {
          if ($rootScope.maxAgingLevel == 12) {
              if ($rootScope.erp.Implementation == 'CencosudChile') {
                  return [
                      { id: 10, name: 'Inactivos' },
                      { id: 8, name: 'Descatalogados (sin bloqueo)' },
                      { id: 9, name: 'Descatalogados (con bloqueo)' },
                      { id: 12, name: 'Aging Fase 1' },
                      { id: 13, name: 'Aging Fase 2' },
                      { id: 14, name: 'Aging Fase 3' },
                      { id: 15, name: 'Aging Fase 4' },
                      { id: 16, name: 'Aging Fase 5' },
                      { id: 17, name: 'Aging Fase 6' },
                      { id: 18, name: 'Aging Fase 7' },
                      { id: 25, name: 'Aging Fase 8' },
                      { id: 26, name: 'Aging Fase 9' },
                      { id: 27, name: 'Aging Fase 10' },
                      { id: 19, name: 'Sobrestock' },
                      { id: 20, name: 'Sobrestock critico' },
                      { id: 21, name: 'Devolucion' },
                      { id: 24, name: 'Pronostico de salida de stock' },
                  ]
              }
              else {
                  return [
                      { id: 10, name: 'Inactivos' },
                      { id: 12, name: 'Aging Fase 1' },
                      { id: 13, name: 'Aging Fase 2' },
                      { id: 14, name: 'Aging Fase 3' },
                      { id: 15, name: 'Aging Fase 4' },
                      { id: 16, name: 'Aging Fase 5' },
                      { id: 17, name: 'Aging Fase 6' },
                      { id: 18, name: 'Aging Fase 7' },
                      { id: 25, name: 'Aging Fase 8' },
                      { id: 26, name: 'Aging Fase 9' },
                      { id: 27, name: 'Aging Fase 10' },
                      { id: 28, name: 'Aging Fase 11' },
                      { id: 29, name: 'Aging Fase 12' },
                      { id: 19, name: 'Sobrestock' },
                      { id: 20, name: 'Sobrestock critico' },
                      { id: 21, name: 'Devolucion' },
                      { id: 24, name: 'Pronostico de salida de stock' },
                  ]
              }
          }
          else { //Para el resto es 7, sino agregar otro if.
              return [
                  { id: 10, name: 'Inactivos' },
                  { id: 12, name: 'Aging Fase 1' },
                  { id: 13, name: 'Aging Fase 2' },
                  { id: 14, name: 'Aging Fase 3' },
                  { id: 15, name: 'Aging Fase 4' },
                  { id: 16, name: 'Aging Fase 5' },
                  { id: 17, name: 'Aging Fase 6' },
                  { id: 18, name: 'Aging Fase 7' },
                  { id: 19, name: 'Sobrestock' },
                  { id: 20, name: 'Sobrestock critico' },
                  { id: 21, name: 'Devolucion' },
                  { id: 24, name: 'Pronostico de salida de stock' },
              ]
          }
      }

      self.typologies = [
        { id: 1, name: 'Tipologia 1' },
        { id: 2, name: 'Tipologia 2' },
        { id: 3, name: 'Tipologia 3' },
        { id: 4, name: 'Tipologia 4' },
        { id: 5, name: 'Tipologia 5' },
      ]

      self.priorizeBy = [
          { id: 1, name: 'Stock $' },
          { id: 2, name: 'Stock U' },
          { id: 3, name: 'DDS' },
      ]

      self.areChanges = function () {
          if (!self.gridOptions.api) return;

          var rv = false;

          self.gridOptions.api.forEachNode(function (node) {
              if (node.data.dirty) {
                  rv = true;
              }
          });

          return rv;
      }


      self.canSave = function () {
          if (!self.gridOptions || !self.gridOptions.api) return false;


          var changes = false;

          var weight = 0;
          var nodesCount = 0;
          var maxOrder = 0;
          var minOrder = 999;
          var orders = [];
          self.gridOptions.api.forEachNode(function (node) {
              if (node.data.dirty) {
                  changes = true;
              }

              /*weight += node.data.weight;
              weight = parseFloat(weight.toFixed(5));
              */
              nodesCount++;

              if (node.data.order) {
                  var item = node.data.order;
                  if (orders[item]) {
                      self.validationErrors = 'No se debe repetir el orden';
                  } else {
                      orders.push(item);
                  }
              }
              if (node.data.order && node.data.order > maxOrder)
                  maxOrder = parseInt(node.data.order);
              if (node.data.order && node.data.order < minOrder)
                  minOrder = parseInt(node.data.order);
          });
          if (orders.length > 0) {
              for (i = minOrder; i < maxOrder; i++) {
                  if (orders[i] != minOrder + i) {
                      self.validationErrors = 'Los ordenes deben ser consecutivos';
                  }
              }
          }
         /* if (weight != 1) {
              self.validationErrors = 'Los pesos deben sumar 100%';
              return false;
          }*/

          //if (!changes) {
          //    self.validationErrors = 'No hay cambios que guardar';
          //    return false;
          //}

          //console.log('maxorder', maxOrder, 'minorder', minOrder);
          //if ((maxOrder - minOrder) != (nodesCount - 1)) {
          //    self.validationErrors = 'Las reglas deben estar ordenadas';
          //    return false;
          //}

          return true;

      }

      self.canGenerateSuggestions = function () {
          if (!self.gridOptions || !self.gridOptions.api) return false;

          var changes = false;

          var weight = 0;
          var nodesCount = 0;
          var maxOrder = 0;
          var minOrder = 999;
         

          self.gridOptions.api.forEachNode(function (node) {
              if (node.data.dirty) {
                  changes = true;
              }

              /*weight += node.data.weight;
              weight = parseFloat(weight.toFixed(5));*/
              //if (node.data.weight == 0) {
              //    weight += 10;
              //}
              nodesCount++;
              if (node.data.order && node.data.order > maxOrder)
                  maxOrder = parseInt(node.data.order);
              if (node.data.order && node.data.order < minOrder)
                  minOrder = parseInt(node.data.order);
          });

       /*   if (weight != 1) {
              //self.validationErrors = 'Los pesos deben sumar 100%';
              return false;
          }*/
          //console.log('maxorder', maxOrder, 'minorder', minOrder);
          //if ((maxOrder - minOrder) != (nodesCount - 1)) {
          //    //self.validationErrors = 'Las reglas deben estar ordenadas';
          //    return false;
          //}


          if (!changes) {
              return true;
          }

          return true;
      }



      function getTypologyByLevel(level) {
          for (var i = 0; i < self.typologies.length; i++) {
              if (self.typologies[i].id == level)
                  return self.typologies[i];
          }
      }

      self.gridOptions = null;

      function validate() {
          var error = '';
          var maxOrder = 0;
          var minOrder = 0;
         
          self.orders = [];
          self.orders.min = function () {
              var min = 10000;
              if (self.orders.length > 0) {
                  for (i = 0; i < self.orders.length; i++) {
                      if (self.orders[i] < min) {
                          min = self.orders[i];
                      }
                  }
              }
              return min;
          }
          self.orders.max = function () {
              var max = 0; //self.orders.length + 1;
              if (self.orders.length > 0) {
                  for (i = 0; i < self.orders.length; i++) {
                      if (self.orders[i] > max) {
                          max = self.orders[i];
                      }
                  }
              }
              return max;
          }
          self.gridOptions.api.forEachNode(function (node) {
             // var data = node.data;
              if (node.data.order) {
                  var item = parseInt(node.data.order);
                  if (self.orders.indexOf(item) == -1) {
                      self.orders.push(item);
                  } else {
                      error = 'No se debe repetir el orden';
                  }
              }
     
          });
          maxOrder = self.orders.max();
          minOrder = self.orders.min();
          if (minOrder != 1 && self.orders.length > 0)
              error = 'El orden debe comenzar por uno (1)';

          var aux = [];
          if (maxOrder != minOrder) {
              for (y= minOrder; y <= maxOrder; y++) {
                  aux.push(y);
              }
          }
          if (self.orders.length > 0) {
              for (i = 0; i < aux.length ; i++) {
                  if (self.orders.indexOf(aux[i]) == -1) {
                      error = 'Los ordenes deben ser consecutivos';
                  }
              }
          }
          return error;
      }


      self.newRule = function () {
          console.log('newrule');
          $state.go('markdowns.rulesCreation');

      }

      self.generateSuggestions = function (reevaluateCurrentPlan) {
          var errors = validate();
          if (errors != '') {
              console.log('errors', errors);
              swal("Errores de validacion", errors, "warning");
              return;
          }

          swal({
              title: "¿Está seguro que desea generar sugerencias?",
              text: 'Se eliminarán todas las sugerencias anteriores y las liquidaciones en proceso de aprobación.',
              type: "info",
              showCancelButton: true,
              confirmButtonColor: "#1AB394",
              confirmButtonText: "Continuar",
              cancelButtonText: "Cancelar",
              showLoaderOnConfirm: true,
              closeOnConfirm: false,
              closeOnCancel: true
          },
          function () {
              var itemsToSave = [];
              self.gridOptions.api.forEachNode(function (node) {
                  if (node.data.dirty) {
                      var auxNode = angular.copy(node.data);

                      //issue cp #632 
                      //el renderer me lo deja enstring empty al borrar un dato por lo que falla el parseo al dto en la api
                     // if (auxNode.weight === null || auxNode.weight === undefined || auxNode.weight === '') auxNode.weight = 0;
                      if (auxNode.order === null || auxNode.order === undefined || auxNode.order === '') auxNode.order = 0;
                     

                      //delete auxNode.stages;
                      itemsToSave.push(auxNode);
                  }
              });

              console.log('itemsToSave', itemsToSave);

              //console.log('categories', categories);
              markdownService.rules.updateRulesAndGenerateSuggestions(reevaluateCurrentPlan, itemsToSave).then(function () {
                  swal("Confirmado", "Sugerencias generadas", "success");
                  self.gridOptions.api.forEachNode(function (node) {
                      node.data.dirty = false;
                  });
              }).catch(function (fallback) {
                  swal("Error", "Hubo un error al querer guardar los cambios", "error");
              });
          });
      }

      self.save = function () {

          var errors = validate();
          if (errors != '') {
              console.log('errors', errors);
              swal("Errores de validacion", errors, "warning");
              return;
          }

          swal({
              title: "¿Está seguro?",
              text: '¿Está seguro que desea guardar los cambios?',
              type: "info",
              showCancelButton: true,
              confirmButtonColor: "#1AB394",
              confirmButtonText: "Continuar",
              cancelButtonText: "Cancelar",
              showLoaderOnConfirm: true,
              closeOnConfirm: false,
              closeOnCancel: true
          },
          function () {
              var itemsToSave = [];
              self.gridOptions.api.forEachNode(function (node) {
                  if (node.data.dirty || true) {
                      var auxNode = angular.copy(node.data);

                      //issue cp #632 
                      //el renderer me lo deja enstring empty al borrar un dato por lo que falla el parseo al dto en la api
                      //if (auxNode.weight === null || auxNode.weight === undefined || auxNode.weight === '') auxNode.weight = 0;
                      if (auxNode.order === null || auxNode.order === undefined || auxNode.order === '') auxNode.order = 0;

                      itemsToSave.push(auxNode);
                  }
              });

              if (itemsToSave.length > 0) {
                  console.log('itemsToSave', itemsToSave);

                  //console.log('categories', categories);
                  markdownService.rules.updateRules(itemsToSave).then(function () {
                      swal("Confirmado", "Se guardaron los cambios seleccionados", "success");
                      self.gridOptions.api.forEachNode(function (node) {
                          node.data.dirty = false;
                      });
                  }).catch(function (fallback) {
                      swal("Error", "Hubo un error al querer guardar los cambios", "error");
                  });
              }
              else {
                  $timeout(function () {
                      swal("Aviso", "No se detecto ningun cambio", "warning");
                  }, 1000);
              }
          });
      }

      function insert(str, index, value) {
          return str.substr(0, index) + value + str.substr(index);
      }

      //grid config
      function setColumnDefinitions() {
          columnDefs = [
          {
              headerName: "Tipo",
              width: 250,
              pinned: 'left',
              field: 'asd',
              suppressMenu: true,
              suppressSorting: true,
              cellClass: $rootScope.getClassForCell,
              filter: 'input',
              cellRenderer: {
                  renderer: 'group',
                  checkbox: false,
                  innerRenderer: function (params) {
                      //TODO
                      //console.log('params', params);
                      var div = document.createElement('div');
                      div.style = 'text-align:left; line-height:20px;';
                      //console.log('div', div);
                      var title = '';
                      for (var i = 0; i < params.data.planTypes.length; i++) {
                          title += params.data.planTypes[i].name + " ";
                      }

                      for (var i = 0; i < params.data.paretoSectionLevels.length; i++) {
                          title += getTypologyByLevel(params.data.paretoSectionLevels[i]).name + " ";
                      }

                      var i = 40;
                      while (i < title.length) {
                          //console.log('name')

                          title = insert(title, i, '<br/>');
                          i += 43;
                      }

                      div.innerHTML = '<strong>' + title + '</strong>';
                      div.innerHTML += '<br />';
                      if (params.data.useDistributionCenterStock)
                          div.innerHTML += 'CON stock de CD';
                      else
                          div.innerHTML += 'SIN stock de CD';

                      return div;
                  }
              }

          },
          {
              headerName: 'Descripci&oacute;n',
              field: "name",
              headerTooltip: "",
              filter: 'number',
              suppressMenu: true,
              suppressSorting: true,
              width: 500,
              cellClass: $rootScope.getClassForCell,
              cellRenderer: {
                  renderer: 'group',
                  checkbox: false,
                  innerRenderer: function (params) {



                      var title = angular.copy(params.data.name);
                      title = title.replace('REGLA DE SELECCI&Oacute;N:', '');
                      var i = 90;
                      while (i < title.length) {
                          //console.log('name')

                          title = insert(title, i, '<br/>');
                          i += 93;
                      }
                      //console.log('name', title)

                      return '<div style="text-align:left; line-height:20px;">' + title + '</div>';
                  }
              }
          },
          {
              headerName: "Desde",
              field: "validFrom",
              headerTooltip: "",
              filter: 'date',
              isDiscountTypeColumn: true,
              suppressMenu: true,
              suppressSorting: true,
              width: 100,
              cellClass: $rootScope.getClassForCell,
              cellRenderer: $rootScope.dateRenderer
          },
          {
              headerName: "Orden",
              field: "order",
              isObjectiveColumn: true,
              headerTooltip: "",
              suppressMenu: true,
              suppressSorting: true,
              width: 120,
              cellClass: $rootScope.getClassForCell,
              filter: 'number',
              cellRenderer: editor('integer')
          },
          {
              headerName: "",
              headerTooltip: "Eliminar",
              field: "age",
              width: 50,
              cellRenderer: function ageCellRendererFunc(params) {
                  params.$scope.deleteClicked = deleteClicked;
                  return "<button class='btn btn-info btn-sm' ng-click='deleteClicked(data)'><i class='fa fa-trash'></i></button>";
              }
          },
          ]
      }

      function deleteClicked(data) {
          swal({
              title: "Esta seguro?",
              text: 'Esta seguro que desea elimiar esta regla?',
              type: "info",
              showCancelButton: true,
              confirmButtonColor: "#1AB394",
              confirmButtonText: "Continuar",
              cancelButtonText: "Cancelar",
              showLoaderOnConfirm: true,
              closeOnConfirm: false,
              closeOnCancel: true
          },
              function () {
                  markdownService.rules.deleteRule(data.id).then(function () {
                      swal("Confirmado", "Se eliminó la regla de liquidacion", "success");
                      loadGridData();

                  }).catch(function (fallback) {
                      swal("Error", "Hubo un error al querer eliminar la regla", "error");
                  });
              }
          );
      }

      //Grid Renderer
      function setGridOptions() {

          setColumnDefinitions();

          self.gridOptions = {
              columnDefs: columnDefs,
              enableColResize: true,
              enableSorting: false,
              enableFilter: false,
              groupUseEntireRow: false,
              rowSelection: "multiple",
              suppressCellSelection: true,
              groupSelectsChildren: true,
              groupSuppressAutoColumn: false, // or undefined
              groupColumnDef: null, // or undefined
              singleClickEdit: true,
              rowHeight: 100,
              icons: {
                  columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                  filter: '<i class="fa fa-filter"/>',
                  sortAscending: '<i class="fa fa-long-arrow-down"/>',
                  sortDescending: '<i class="fa fa-long-arrow-up"/>',
                  groupExpanded: '<i class="fa fa-minus-square-o"/>',
                  groupContracted: '<i class="fa fa-plus-square-o"/>',
                  columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                  columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
              },
              enableRangeSelection: false,
              angularCompileRows: true,
              //getNodeChildDetails: getNodeChildDetails,
              getRowStyle: function (params) {
                  //console.log('params', params);
                  if (params.data.isEnabled == false) {
                      return { background: 'lightgrey' }
                  }
              }
          }
      }


      //grid filter
      self.onFilterChanged = function (value) {
          self.gridOptions.api.setQuickFilter(value);
      }


      function loadGridData() {
          //setColumnDefinitions();


          markdownService.rules.getRules()
            .then(function (rules) {
                console.log('rules', rules);
                self.rules = rules;
                self.gridOptions.api.setRowData(rules);
                //console.log('colu', columnDefs);
                self.gridOptions.api.setColumnDefs(columnDefs);
            })
      }


      function loadData() {

          loadGridData();
      }

      function init() {

          //init columnDefs and gridOptions
          setGridOptions();
          loadData();
      }

      init();




      /// EDITORS

      function editor(type) {
          return function innerEditor(params) {

              var rendererFunction;
              var innerText;
              if (type == 'money') {
                  rendererFunction = $rootScope.moneyRenderer;
                  innerText = '$';
              }
              else if (type == 'greaterThanNoDecimals') {
                  rendererFunction = $rootScope.greaterThanNoDecimals;
                  innerText = '>';

              } else if (type == 'percentage') {
                  rendererFunction = $rootScope.percentageRendererWithZero;
                  innerText = '%';
              }
              else if (type == 'integer') {
                  rendererFunction = $rootScope.integerRenderer;
                  innerText = '';
              }
              else {
                  rendererFunction = function (value) { return value.value };
                  innerText = '';

              }

              var editing = false;

              var eCell = document.createElement('div');
              eCell.style = "width:100% !important;height:100% !important";
              eCell.className = "input-group";
              var eLabel = params.value ? document.createTextNode(rendererFunction(params)) : document.createTextNode('');
              eCell.appendChild(eLabel);

              var eSpan = document.createElement("span");
              eSpan.className = "input-group-addon";
              eSpan.style = "margin:0px !important; height: 100% !important; border: 0px; line-height: 100%; font-size:12px !important";
              eSpan.innerText = innerText;

              var eInput = document.createElement("input");
              eInput.type = "number";
              //eInput.step = "1";
              eInput.className = "form-control";


              eCell.addEventListener('click', function () {
                  if (!editing) {
                      eCell.removeChild(eLabel);
                      if (type == 'greaterThanNoDecimals') {
                          eCell.appendChild(eSpan);
                          eCell.appendChild(eInput);
                      } else {
                          eCell.appendChild(eInput);
                          eCell.appendChild(eSpan);
                      }
                      eInput.focus();
                      if (type == 'percentage') {
                          eInput.value = params.value ? params.value * 100 : null;
                      } else {
                          eInput.value = params.value;
                      }
                      editing = true;
                  }
              });



              eInput.addEventListener('blur', function () {
                  if (editing) {
                      editing = false;
                      eCell.removeChild(eSpan);
                      eCell.removeChild(eInput);
                      eCell.appendChild(eLabel);
                  }
              });

              eInput.addEventListener('change', function () {
                  if (editing) {
                      editing = false;

                      eCell.removeChild(eSpan);
                      eCell.removeChild(eInput);
                      eCell.appendChild(eLabel);

                      if (eInput.value > 100 && type == 'percentage')
                          return;


                      var newValue = eInput.value;

                      if (type == 'percentage') {
                          var newValue = eInput.value / 100;
                      }

                      params.value = newValue;
                      setField(params.data, params.column.colId, newValue);
                      //params.data[params.column.colId] = newValue;
                      eLabel.nodeValue = rendererFunction(params);


                      var colIds = [];
                      var changedRows = [];



                      params.api.refreshCells(changedRows, colIds);
                      params.api.recomputeAggregates();
                      params.data.dirty = true;
                  }
              });

              return eCell;
          }

      }

      function setField(data, field, value) {
          //debugger;
          if (field.indexOf('.') < 0) {
              data[field] = value;
          }
          else {
              // otherwise it is a deep value, so need to dig for it
              var fields = field.split('.');
              try {

                  if (fields.length == 2) {
                      data[fields[0]][fields[1]] = value;

                  } else if (fields.length == 3) {
                      data[fields[0]][fields[1]][fields[2]] = value;

                  } else if (fields.length == 4) {
                      data[fields[0]][fields[1]][fields[2][fields[3]]] = value;

                  } else if (fields.length == 5) {
                      data[fields[0]][fields[1]][fields[2]][fields[3]][fields[4]] = value;

                  }

              } catch (e) {
                  return;
              }
          }
      }

      //maximo, 5 campos
      function getField(data, field) {
          if (!field) return field;
          //debugger;
          if (field.indexOf('.') < 0) {
              return data[field];
          }
          else {
              // otherwise it is a deep value, so need to dig for it
              var fields = field.split('.');

              try {
                  if (fields.length == 2) {
                      return data[fields[0]][fields[1]];

                  } else if (fields.length == 3) {
                      return data[fields[0]][fields[1]][fields[2]];

                  } else if (fields.length == 4) {
                      return data[fields[0]][fields[1]][fields[2][fields[3]]];

                  } else if (fields.length == 5) {
                      return data[fields[0]][fields[1]][fields[2]][fields[3]][fields[4]];

                  }
              } catch (e) {
                  return null;
              }


          }
      }



  });

