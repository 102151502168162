angular.module("prisma")
    .controller("spacesPlanogramsCtrl", function ($scope, $state, $translate, ngDialog, spaceService, adminService) {

        //Varibles
        var self = this;

        var pageLoaded = false;

        self.isLoading = false;

        self.order = '-lastModifiedDate';

        self.planograms = [];
        self.radioOption = 'show';
        self.shelves = []
        self.count = 1;
        self.viewPlanogram = function (planogramId) {
            $state.go('spaces.editPlanogram', { planogramId: planogramId });

        }

        self.shelvesInPlanogram = [];

        self.shelvesInPlanogram = [];
        self.pageModal = 1;

        self.getStatusName = function (status) {
            if (status == 0)
                return translations.NewOrModified;
            else if (status == 1)
                return translations.RequestForApproval;
            else if (status == 2)
                return translations.Approved;
            else if (status == 3)
                return translations.Communicated;
        }

        self.paginationView = function (pag) {
            return self.pageModal == pag ? true : false;
        }

        self.nextPagination = function () {
            if (self.pageModal < 3) {
                ++self.pageModal
            }

        }

        self.privousPagination = function () {
            if (self.pageModal > 1) {
                --self.pageModal
            }
        }

        self.newPlanogram = null;

        function generateNewShelf() {
            return {
                id: 0,
                name: '',
                height: 0,
                width: 0,
                depth: 0,
            };
        }

        self.generateNewShelf = function () {
            self.newShelf = generateNewShelf()
        }

        self.addNewShelf = function () {
            spaceService.shelf.saveShelf(self.newShelf).then(function (shelves) {
                //console.log('shelves', shelves);
                self.shelves = shelves;
                pageLoaded = true;
            });

            self.showShelf = true
            self.newShelf = generateNewShelf()
        }

        self.addShelfToPlanogram = function (shelf, add) {

            if (add) {
                var copyShelf = Object.assign({}, shelf, { order: self.newPlanogram.shelves.length });

                self.newPlanogram.shelves.push(shelf)
                self.newPlanogram.planogramShelf.push({
                    planogramId: 0,
                    shelfId: shelf.id,
                    order: self.newPlanogram.shelves.length,
                })

                self.newPlanogram.height = shelf.height

                self.newPlanogram.depth = shelf.depth
            }

            if (!add) {
                indexShelfDelete = -1;
                angular.forEach(self.newPlanogram.shelves, function (_shelf, i) {
                    if (_shelf.id == shelf.id) {
                        indexShelfDelete = i
                    }
                });
                if (indexShelfDelete >= 0) {
                    self.newPlanogram.shelves.splice(indexShelfDelete, 1)
                    self.newPlanogram.planogramShelf.splice(indexShelfDelete, 1);
                }

                if (!self.newPlanogram.shelves.length) {
                    self.newPlanogram.height = 0

                    self.newPlanogram.depth = 0

                    self.newPlanogram.width = 0

                }


            }

            var totalWidth = 0
            angular.forEach(self.newPlanogram.shelves, function (_shelf) {
                totalWidth += _shelf.width
                self.newPlanogram.width = totalWidth
            });

            self.calHeightLevels()
            //console.log('addShelfToPlanogram', shelf, self.newPlanogram.shelves, self.newPlanogram.planogramShelf)
        }

        function generateNewPlanogram() {
            return {
                id: 0,
                name: '',
                code: '',
                stores: [],
                subCategories: [],
                copyFromPlanogramId: 0,
                orientationRightToLeft: true,
                width: 1,
                height: 1,
                depth: 100,
                columns: [
                    {
                        order: 1,
                        width: 1,
                        modules: []
                    }
                ],
            };
        }

        self.calHeightLevels = function () {
            self.newPlanogram.levels = []
            var calHeightForLevel = self.newPlanogram.height / self.newPlanogram.numLevels
            for (var i = 0; i < self.newPlanogram.numLevels; i++) {
                self.newPlanogram.levels.push({
                    categoryId: null,
                    categoryName: "",
                    height: calHeightForLevel,
                    id: 0,
                    itemPadding: 1,
                    items: [],
                    levelNumber: i + 1,
                })
            }
        }

        self.addLevelToNewPlanogram = function () {
            if (self.newLevel.height) {
                if (self.newLevel.order == 'up') {
                    self.newPlanogram.levels.push({
                        categoryId: null,
                        categoryName: "",
                        height: self.newLevel.height,
                        id: 0,
                        itemPadding: 1,
                        items: [],
                        //levelNumber: self.newLevel.levelNumber,
                    })
                } else {
                    self.newPlanogram.levels.unshift({
                        categoryId: null,
                        categoryName: "",
                        height: self.newLevel.height,
                        id: 0,
                        itemPadding: 1,
                        items: [],
                        //levelNumber: self.newLevel.levelNumber,
                    })
                }

                var suma = 0
                angular.forEach(self.newPlanogram.levels, function (level, i) {
                    level.levelNumber = i + 1;
                    suma += level.height
                })
                self.newPlanogram.height = suma
            }
        }

        self.planogramToCloneCode = '';
        self.planogramToCloneName = '';

        self.openPlanogramCloneDialog = function (planogramId, planogramCode, planogramName) {

            if (planogramId) {

                self.planogramToCloneCode = planogramCode;
                self.planogramToCloneName = planogramName;

                self.newPlanogram = generateNewPlanogram();
                self.newPlanogram.copyFromPlanogramId = planogramId;


                ngDialog.open({
                    template: 'planogramCloneDialog',
                    className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-500',
                    scope: $scope
                });

            }
        }

        self.isCloning = false;

        self.clonePlanogram = function () {
            self.isCloning = true;
            spaceService.planograms.savePlanogram(self.newPlanogram).then(function (res) {
                self.isCloning = false;

                loadPlanograms();

                ngDialog.close();

                swal(translations.SavedPlanogramSwal, translations.SavedPlanogramaSwalSubtitle, 'success');

            }).catch(function (error) {
                swal(translations.ErrorTitleSwal, translations.ErrorWhenSaveThePlanogram, 'error');
            });
        }

        self.deletePlanogram = function (planogramId, planogramCode, planogramName) { 
            swal({
                title: translations.SureDeletePlanogramSwal + planogramCode + ' - ' + planogramName,
                text: translations.SureDeletePlanogramSwalDesc,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        spaceService.planograms.deletePlanogram(planogramId).then(function () {
                            swal(translations.ErasedPlanogram, translations.ErasedPlanogramSubtitle, 'success');

                            //Saco el planograma borrado del Array
                            var indexToDelete = -1;
                            angular.forEach(self.planograms, function (planogram, i) {
                                if (planogram.id == planogramId) {
                                    indexToDelete = i;
                                }
                            });
                            if (indexToDelete >= 0) {
                                self.planograms.splice(indexToDelete, 1);
                            }

                        }).catch(function (error) {
                            swal(translations.ErrorTitleSwal, translations.AccessDenied, 'error');
                        });
                    }
                });
        }

        function isValidField(field) {
            if (field == undefined || field == null || field == '')
                return false;

            return true;
        }

        self.isValid = function () {
            if (self.pageModal == 1) return !(isValidField(self.newPlanogram.name) && isValidField(self.newPlanogram.code) && self.newPlanogram.clusters.length && isValidField(self.newPlanogram.category))
            if (self.pageModal == 2) return !self.newPlanogram.planogramShelf.length
        }

        self.saveNewEditPlanogram = function () {
            swal({
                title: translations.SavePlanogramSwalTitle,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        if (!self.isSaving) {
                            self.isSaving = true;
                            //console.log('newPlanogram', self.newPlanogram);
                            var data = {
                                name: self.newPlanogram.name,
                                code: self.newPlanogram.code,
                                width: self.newPlanogram.width,
                                depth: self.newPlanogram.depth,
                                height: self.newPlanogram.height,
                                isPlanogram: self.newPlanogram.isPlanogram,
                                maximumNumberOfItems: self.newPlanogram.maximumNumberOfItems,
                                planogramType: self.newPlanogram.planogramType,
                                levels: self.newPlanogram.levels,
                                clusters: self.newPlanogram.clusters,
                                subCategories: self.newPlanogram.subCategories,
                                //discriminator: self.newPlanogram.discriminator,
                                //clusterId: self.newPlanogram.cluster ? self.newPlanogram.cluster.id : null,
                                category: self.newPlanogram.category,
                                categoryName: self.newPlanogram.category ? self.newPlanogram.category.name : null,
                                categoryId: self.newPlanogram.category ? self.newPlanogram.category.id : null,
                                //shelves: self.newPlanogram.shelves,
                                planogramShelf: self.newPlanogram.planogramShelf
                            }
                            return

                            if (!(isValidField(self.newPlanogram.name) && isValidField(self.newPlanogram.code) && self.newPlanogram.clusters.length && isValidField(self.newPlanogram.category))) {
                                swal(translations.ValidationSwalTitle, translations.ValidationSwalSubtitle, 'error');
                                return;
                            }

                            spaceService.planograms.savePlanogram(data).then(function (res) {
                                self.planograms = res;
                                pageLoaded = true;
                                swal(translations.SavedPlanogramSwal, translations.SavedPlanogramaSwalSubtitle, 'success');
                                ngDialog.close();
                            })
                            self.isSaving = false;

                        }
                    }
                });
        }

        $scope.$watch('ct.newPlanogram.category', function (newVal, oldVal) {
            if (newVal != oldVal && self.newPlanogram.category.id) self.filterSubCategories(self.newPlanogram.category.id);
        })

        self.filterSubCategories = function (categoryId) {

            self.subCategories = self.allSubCategories.filter(function (subCategory) {
                return subCategory.parentId === categoryId;
            });

        }

        //Load Data
        function loadPlanograms() {
            self.isLoading = true;
            spaceService.planograms.getPlanograms().then(function (planograms) {


                self.planograms = planograms;
                self.isLoading = false;
                pageLoaded = true;
            });

        }


        var translations = null;

        self.stores = [];

        function loadStores() {
            spaceService.stores.getStores(false).then(function (stores) {
                self.stores = stores;
            });
        }

        function init() {

            $translate(["ModalTitleCreatePlanogram", "ModalTitleEditPlanogram", "SavePlanogramSwalTitle", "ValidationSwalTitle"
                , "ValidationSwalSubtitle", "SavedPlanogramSwal", "SavedPlanogramaSwalSubtitle", "CancelSwal", "ContinueSwal", "NewOrModified", "RequestForApproval", "Approved", "Communicated", "SureDeletePlanogramSwal", "SureDeletePlanogramSwalDesc"
                , "ErasedPlanogram", "ErasedPlanogramSubtitle", "ErrorTitleSwal", "AccessDenied"])
                .then(function (all) {
                    translations = all;
                });


            loadPlanograms();

            loadStores();

        }

        init();
    });