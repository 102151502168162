'use strinct';
(function () {
    angular.module('prisma').component('planogramPrintStores', {
        templateUrl: '/app/spaces/planogram/print/planogram-print-stores.view.html',
        bindings: {
            model: '<',
            orderBy: '<'
        },
        controller: 'planogramPrintStoresController',
        controllerAs: '$ctrl',
        bindToController: true
    }).controller('planogramPrintStoresController', ['$log', planogramPrintStoresController]);

    // ReSharper disable once InconsistentNaming
    function planogramPrintStoresController($log) {
        //$log.debug('planogramPrintStoresController::ctor');
        var self = this;
        //self.model = [];
        //self.orderBy = 'code';

        //self.$onChanges = function $onChanges(changesObj) {
        //    $log.debug('planogramPrintStoresController::$onChanges %o', changesObj);
        //    if (changesObj.model && angular.isArray(changesObj.model.currentValue)) {
        //        self.model = changesObj.model.currentValue;
        //    }
        //}

        /**
        * Devuelve verdadero si el modelo es un objeto, falso en caso contrario.
        * @public
        * @returns Verdadero si el modelo es un objeto, falso en caso contrario.
        */
        self.hasModel = function hasModel() {
            var ret = angular.isObject(self.model);
            return ret;
        }
    }
})();