"use strict";

angular.module("prisma")

    .directive("myCarousel", function ($timeout) {
        return {
            link: function (scope, element, args) {
                var init = function () {
                    if (element.length > 0) {
                        $('a[data-slide="prev"]').click(function () {
                            $(element).carousel('prev');
                        });

                        $('a[data-slide="next"]').click(function ele() {
                            $(element).carousel('next');
                        });
                    }
                };

                angular.element(document).ready(function () {
                    //$('#myCarousel').carousel();
                    //$(element).carousel();
                    init();
                });
            }
        };
    });