var _controller = function ($scope, $attrs, $filter, $translate, $timeout, $rootScope, priceService, homeService) {
    var self = this;
    //console.log('suggestions', self);


}

angular.module('prisma').component('marginsAndCosts', {
    templateUrl: 'app/shared_logic/components/marginsAndCosts/marginsAndCosts.component.html',
    bindings: {
        price: '<'
    },

    controller: _controller
});


