angular.module("prisma")
    .controller("BrandsCtrl", function ($scope, $state, $translate, adminService, serverService, $timeout, homeService, ngDialog, Upload) {

        //Variables Globales

        var self = this;
        var translations = null;
        self.search = "";
        self.order = "";

        self.lstBrands = [];

        self.brand = null;          
 
        self.showPopup = function (brand) {       
            self.brand = brand;             

            ngDialog.open({
                template: 'modal-editBrand',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-500',
                scope: $scope
            });
        }


        //Guardo marca nueva o editada
        self.saveBrand = function () {
            if (self.brand.name != '') {
                self.isLoading = true;
                //subo la imagen
                var files = $("#uploadImgInput")[0].files;
                var file = files.length ? files[0] : null;
                var url = ian.urlCombine(serverService.getApiUrl(), '/admin/store/uploadNewBrandImage');
                var data = { file: file };
                Upload.upload({
                    url: url,
                    data: { file: file }
                }).then(function (response) {
                    var isChangedIcon = false;
                    if (response.data != '') {
                        isChangedIcon = true;//si se eligio una img response.data es la url
                        self.brand.icon = response.data;
                    }

                    adminService.brands.saveBrand(self.brand, isChangedIcon)
                        .then(function (brand) {
                            self.brand = brand;
                            self.isLoading = false;
                            ngDialog.close();
                            loadBrands();
                            swal(translations.Adm_brands_savedBrand, translations.Adm_brands_subtitle, 'success');
                        }, function () {
                            swal('Error', 'hubo un error al guardar.', 'error');
                        });
                });
            }
            else {
                swal('Error', 'nombre incompleto', 'error');
            }
        }

        self.selectBrandColor = function (color) {
            //return 'background-color: ' + color;

            if (color)
                return color;
            else
                return "#ffffff";

        }

        //Load Data
        function loadBrands() {
            self.isLoading = true;
            adminService.brands.getBrands()
                .then(function (brands) {
                    self.lstBrands = brands;
                    self.isLoading = false;
                });
            
        }

        function init() {

            $translate(["Adm_brands_savedBrand", "Adm_brands_subtitle"])
                .then(function (all) {
                    translations = all;
                });

            loadBrands();
        }

        init();
    });




