angular.module("prisma")
    .controller("EditMissionsCtrl", function ($scope, $stateParams, $translate, $state, $uibModal, spaceService, adminService, priceService) {
        var self = this;

        self.mission = {};
        self.agentPlaces = [];
        self.selectedBrands = [];//banderas
        self.itemBrands = [];//marcas de los items
        self.selectedRegions = [];
        self.selectedLocalities = [];
        self.selectedPriceZone = [];
        self.selectedRegions = [];
        self.selectedStores = [];
        self.suppliers = [];
        self.selectedGroups = [];
        self.selectedSubgroups = [];
        self.selectedCategories = [];
        self.selectedSubcategories = [];
        self.selectedItemBrands = [];
        self.selectedSuppliers = [];
        self.brands = [];
        self.showRequiredFields = false;
        self.isLoading = true;
        self.isLoadingItems = false;
        self.listAgentCompetitor = [];
        self.subGroupLabel = companyJson.Erp.Implementation == "LaRebaja" ? "Division" : "Subgrupos";
        self.subGroupPlaceHolder = companyJson.Erp.Implementation == "LaRebaja" ? "Seleccione divisiones" : "Seleccione Subgrupos";
        self.groupLabel = companyJson.Erp.Implementation == "LaRebaja" ? "Unidad de Negocio" : "Grupo";
        self.groupPlaceHolder = companyJson.Erp.Implementation == "LaRebaja" ? "Seleccione Unidades de Negocio" : "Seleccione Grupos";
        self.showItemFilters = !(companyJson.MysteryShopper && companyJson.MysteryShopper.HideItemFilters);

        self.filterItems = function (item) {
            //1. si el brand del item matchea (whole word)con alguno de los seleccionados
            var itemInSelectedBrands = !self.selectedItemBrands.length || (item.brand && self.selectedItemBrands.includes(item.brand));

            //2. si el manufacturer del item matchea (whole word) con alguno de los seleccionados
            var itemInSelectedSuppliers = !self.selectedSuppliers.length || (item.manufacturer && self.selectedSuppliers.includes(item.manufacturer));

            //3. si la categoryId del item esta en las seleccionadas
            var itemCategoryInSelection = (!self.selectedSubcategories.length
                && !self.selectedCategories.length
                && !self.selectedSubgroups.length
                && !self.selectedGroups.length)
                || isCategoryInSelection(item.subcategoryId);

            return itemInSelectedBrands && itemInSelectedSuppliers && itemCategoryInSelection;
        }

        function isCategoryInSelection(subcategoryId) {

            if (self.selectedSubcategories.map(function (x) { return x.id; }).includes(subcategoryId))
                return true;
            var subcategory = self.subcategories.find(function (x) { return x.id === subcategoryId; });
            if (subcategory && subcategory.parentId) {
                if (self.selectedCategories.map(function (x) { return x.id; }).includes(subcategory.parentId))
                    return true;
            }
            if (subcategory && subcategory.parentId) {
                var category = self.categories.find(function (x) { return x.id === subcategory.parentId; })
                if (category.parentId) {
                    if (self.selectedSubgroups.map(function (x) { return x.id; }).includes(category.parentId))
                        return true;
                }
            }
            if (category && category.parentId) {
                var subgroup = self.subgroups.find(function (x) { return x.id === category.parentId; })
                if (subgroup.parentId) {
                    if (self.selectedGroups.map(function (x) { return x.id; }).includes(subgroup.parentId))
                        return true;
                }
            }
            return false;

        }

        self.cancel = function () {
            $state.go('admin.missions');
        }

        self.validateRequiredFields = function () {
            var rv = true;
            if (self.mission.missionName && self.mission.missionType
                && self.mission.frecuency
                && self.mission.actionType && self.mission.dueDate
                && self.mission.items && self.mission.items.length && self.agentPlaces.length) {

                angular.forEach(self.agentPlaces, function (ap) {
                    if (!ap.competitorIds || !ap.competitorIds.length || !ap.userName) {
                        rv = false;
                    }
                });
            }
            else {
                rv = false;
            }

            return rv;
        }

        self.validateCompetitors = function () {
            var flag = 0;
            var rv = true;
            angular.forEach(self.competitors, function (c) {
                flag = 0;
                angular.forEach(self.agentPlaces, function (ap) {
                    for (var i = 0; i < ap.competitorIds.length; i++) {
                        if (c.id == ap.competitorIds[i] && flag == 0) {
                            flag = 1;
                        }
                        else if (c.id == ap.competitorIds[i] && flag) {
                            rv = false;
                        }
                    }
                });
            });
            return rv;
        }

        self.validateUsers = function () {
            var rv = true;
            for (var i = 0; i < self.agentPlaces.length; i++) {

                var arr = self.agentPlaces.filter(function (x) {
                    return x.userName == self.agentPlaces[i].userName;
                });
                if (arr.length > 1) {
                    rv = false;
                    break;
                }
            }

            return rv;
        }

        self.validateExpiration = function (frecuency, dueDate) {
            var rv = true;
            switch (frecuency) {
                case 1:
                    return true;
                    break;
                case 2:
                    frecuency = 1;
                    break;
                case 3:
                    frecuency = 7;
                    break;
                case 4:
                    frecuency = 15;
                    break;
                case 5:
                    frecuency = 30;
                    break;
                case 5:
                    frecuency = 181;
                    break;
                case 7:
                    frecuency = 365;
                    break;
            }
            var today = new Date();
            today.setDate(today.getDate() + frecuency);
            var result = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            var auxDueDate = dueDate.getFullYear() + '-' + (dueDate.getMonth() + 1) + '-' + dueDate.getDate();
            if (auxDueDate > result) {
                rv = false;
            }
            return rv;
        }

        self.onChangeUsers = function (AgentPlace) {
            var listFilterByUser = self.listAgentCompetitor.filter(function (x) { return x.userName == AgentPlace.userName });
            var newcompetitorsIds = listFilterByUser.map(function (x) { return x.competitorId })
            AgentPlace.competitorIds = newcompetitorsIds;
        }

        self.getAgentCompetitor = function () {
            adminService.missions.getAgentCompetitors().then(function (agentCompetitor) {
                self.listAgentCompetitor = agentCompetitor;
            });
        }

        self.getUsers = function () {
            adminService.security.getUsers().then(function (users) {
                self.users = users;
            });
        }

        self.getStores = function () {
            adminService.security.getStores().then(function (stores) {
                self.stores = stores;
            });
        }

        self.getCompetitors = function () {
            priceService.competitors.competitors().then(function (competitors) {
                self.competitors = competitors;
            });
        }

        self.getBrands = function () {
            adminService.brands.getBrands().then(function (brands) {
                self.brands = brands;
            });
        }

        self.getMissionEnums = function () {
            adminService.missions.getMissionEnums().then(function (response) {
                self.missionTypes = response.missionTypes;
                self.actionTypes = response.actionTypes;
                self.surveyMethods = response.surveyMethods;
                self.frecuencies = response.frecuencies;

                self.missionTypes.forEach(function (x) {
                    x.name = translations[x.name] || x.name;
                });
                self.actionTypes.forEach(function (x) {
                    x.name = translations[x.name] || x.name;
                });
                self.surveyMethods.forEach(function (x) {
                    x.name = translations[x.name] || x.name;
                });
                self.frecuencies.forEach(function (x) {
                    x.name = translations[x.name] || x.name;
                });
            });
        }

        function buildAgentPlaces() {
            if (!self.mission || !self.mission.agentPlaces || !self.mission.agentPlaces.length)
                return;

            var users = [];
            _.forOwn(_.groupBy(self.mission.agentPlaces, "assignedTo"), function (value, key) {
                users.push(key);
            });
            var competitorIds = [];


            angular.forEach(users, function (u) {
                var thisUserHasValidateLocation = false;
                competitorIds = [];
                angular.forEach(self.mission.agentPlaces, function (ap) {
                    if (ap.assignedTo == u) {
                        competitorIds.push(ap.competitorId);
                        thisUserHasValidateLocation = ap.validateLocation;
                    }
                });

                self.agentPlaces.push({
                    userName: u,
                    competitorIds: competitorIds,
                    validateLocation: thisUserHasValidateLocation
                });
            });
        }

        self.getItems = function () {
            if (self.selectedGroups.length > 0 || self.selectedSubgroups.length > 0
                || self.selectedCategories.length > 0 || self.selectedSubcategories.length > 0) {
                self.isLoadingItems = true;

                var subCategoriesIds = self.getSubcategoriesIds();

                adminService.items.getItemsByCategories(subCategoriesIds).then(function (items) {
                    self.items = items;
                    self.isLoadingItems = false;

                    //distincts brands
                    _.forOwn(_.groupBy(items, "brand"), function (value, key) {
                        if (key && key != 'null')
                            self.itemBrands.push(key);
                    });
                    //distincts manufacturers
                    _.forOwn(_.groupBy(items, "manufacturer"), function (value, key) {
                        if (key && key != 'null')
                            self.suppliers.push(key);
                    });
                });
            }
            else {
                var text = "Seleccione al menos uno de los siguientes filtros: " + self.groupLabel + ", " + self.subGroupLabel + ", " + "Categoria, Subcategoria.";
                swal({
                    title: "Error",
                    text: text,
                    type: "error",
                    confirmButtonClass: "btn-danger",
                    confirmButtonText: "Ok",
                    closeOnConfirm: false
                });
            }
        }

        self.getSubcategoriesIds = function () {
            var subCategoriesIds = [];

            if (self.selectedSubcategories.length > 0) {
                var subCatIds = self.selectedSubcategories.map(function (x) { return x.id });
                subCategoriesIds = subCategoriesIds.concat(subCatIds);
            }
            if (self.selectedCategories.length > 0) {
                var catIds = self.selectedCategories.map(function (x) { return x.id });
                var auxSubcat = self.subcategories.filter(function (x) { return catIds.includes(x.parentId) });
                var subCatIds = auxSubcat.map(function (x) { return x.id });
                subCategoriesIds = subCategoriesIds.concat(subCatIds);
            }
            if (self.selectedSubgroups.length > 0) {
                var subGroupIds = self.selectedSubgroups.map(function (x) { return x.id });
                var auxCats = self.categories.filter(function (x) { return subGroupIds.includes(x.parentId) });
                var auxCatIds = auxCats.map(function (x) { return x.id });
                var auxSubcat = self.subcategories.filter(function (x) { return auxCatIds.includes(x.parentId) });
                var subCatIds = auxSubcat.map(function (x) { return x.id });
                subCategoriesIds = subCategoriesIds.concat(subCatIds);
            }
            if (self.selectedGroups.length > 0) {
                var groupIds = self.selectedGroups.map(function (x) { return x.id });
                auxSubGroup = self.subgroups.filter(function (x) { return groupIds.includes(x.parentId) });
                auxSubGroupIds = auxSubGroup.map(function (x) { return x.id });
                var auxCats = self.categories.filter(function (x) { return auxSubGroupIds.includes(x.parentId) });
                var auxCatIds = auxCats.map(function (x) { return x.id });
                var auxSubcat = self.subcategories.filter(function (x) { return auxCatIds.includes(x.parentId) });
                var subCatIds = auxSubcat.map(function (x) { return x.id });
                subCategoriesIds = subCategoriesIds.concat(subCatIds);
            }

            return subCategoriesIds;
        }

        self.getCategories = function (levels) {
            adminService.categories.getCategoriesWithLevels(levels).then(function (categories) {
                self.subcategories = categories.filter(function (x) { return x.level === 5 });
                self.categories = categories.filter(function (x) { return x.level === 4 });
                self.subgroups = categories.filter(function (x) { return x.level === 3 });
                self.groups = categories.filter(function (x) { return x.level === 2 });
            });
        }

        self.save = function () {            
            if (!self.validateRequiredFields()) {
                swal("Error", "Complete los campos obligatorios.", "error");
                self.showRequiredFields = true;
                return;
            }
            if (!self.validateCompetitors()) {
                swal("Error", "Mas de un usuario tiene el mismo competidor.", "error");
                return;
            }
            if (!self.validateUsers()) {
                swal("Error", "Eliga solo una tupla por usuario.", "error");
                return;
            }
            if (!self.validateExpiration(self.mission.frecuency, self.mission.dueDate)) {
                swal("Error", "La expiracion debe ser menor o igual a la frecuencia a partir de la fecha de creacion de la mision", "error");
                return;
            }

            self.showRequiredFields = false;
            swal({
                title: "Esta seguro que desea guardar los cambios?.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {

                    if (!isConfirm) return;
                    mapAgentPlaces();
                    adminService.missions.saveMission(self.mission).then(function () {
                        swal("Confirmado", "", "success");
                        self.cancel();
                    }),
                        function () {
                            swal("Error", "", "error");
                        };
                });
        }
        

        self.addAgentPlace = function() {
            self.agentPlaces.push({ userName: '', competitorIds: [] });
        }

        self.removeAgentPlace = function (index) {
            self.agentPlaces.splice(index, 1);
        }

        function mapAgentPlaces() {
            self.mission.agentPlaces = [];
            var agentPlace = null;
            angular.forEach(self.agentPlaces, function (ap) {
                angular.forEach(ap.competitorIds, function (id) {
                    agentPlace = {
                        mSMissionId: self.mission.id,
                        competitorId: id,
                        assignedTo: ap.userName,
                        validateLocation: ap.validateLocation
                    };

                    self.mission.agentPlaces.push(agentPlace);
                });
            });
        }

        //button Collapsed
        $scope.isNavCollapsed = true;
        $scope.isCollapsed = false;
        $scope.isCollapsedHorizontal = false;

        $scope.isProgrammingCollapsed = false;
        $scope.isCompetitorsCollapsed = false;
        $scope.isProductsCollapsed = false;
        $scope.isResponsibleCollapsed = false;

        var translations = null;
        function init() {

            $translate(["Strip", "Catalog", "OneTime", "Biannual", "Annual", "PriceSurvey",
                "ExhibitionSurvey", "PriceLabelScan", "VoiceCommand", "ManualEntry", "Daily", "Weekly", "Biweekly", "Monthly"])
                .then(function (all) {
                    translations = all;
                });

            self.getCompetitors();
            self.getUsers();
            self.getStores();
            self.getMissionEnums();
            self.getCategories([2, 3, 4, 5]);
            self.getBrands();
            self.getAgentCompetitor();

            if ($stateParams.missionId != '0') {
                self.isNew = false;
                adminService.missions.getMission($stateParams.missionId).then(function (mission) {
                    self.mission = mission;
                    self.mission.dueDate = new Date(self.mission.dueDate);
                    //selecciono agentPlaces
                    buildAgentPlaces();

                    self.isLoading = false;
                })
            }
            else {
                self.isNew = true;
                self.isLoading = false;
            }

         
            
        };

        init();

    });




