"use strict";

angular.module("prisma")
  .controller("MapV2Ctrl", function ($scope, $timeout) {
      var vm = this;
      vm.name = null;
      vm.getName = function(){
          return vm.name;
      }
      vm.zoomOut = function () {
          g.selectAll(["#states", "#cities"]).remove();
          state = null;

          if (province) {
              g.selectAll("#" + province.id).style('display', null);
          }
          
          var xyz = get_xyz(country);
          zoom(xyz);
      }

      var m_width, width, height, country, province, state, projection, path, svg, g;


      function zoom(xyz) {
          g.transition()
            .duration(750)
            .attr("transform", "translate(" + projection.translate() + ")scale(" + xyz[2] + ")translate(-" + xyz[0] + ",-" + xyz[1] + ")")
            .selectAll(["#countries", "#states", "#cities"])
            .style("stroke-width", 1.0 / xyz[2] + "px")
            .selectAll(".city")
            .attr("d", path.pointRadius(20.0 / xyz[2]));
      }

      function get_xyz(d) {
          var bounds = path.bounds(d);
          var w_scale = (bounds[1][0] - bounds[0][0]) / width;
          var h_scale = (bounds[1][1] - bounds[0][1]) / height;
          var z = .76 / Math.max(w_scale, h_scale);
          var x = (bounds[1][0] + bounds[0][0]) / 2;
          var y = (bounds[1][1] + bounds[0][1]) / 2 + (height / z / 6);
          return [x, y - 50, z];
      }

      function province_clicked(d) {
          g.selectAll(["#states", "#cities"]).remove();
          state = null;

          if (province) {
              g.selectAll("#" + province.id).style('display', null);
          }

          if (d && province !== d) {
              var xyz = get_xyz(d);
              province = d;



              //if (d.id == 'USA' || d.id == 'JPN') {
              d3.json("assets/json/departamentos.topo.json", function (error, us) {

                  var json = topojson.feature(us, us.objects.departamentos);
                  
                  //add metadata
                  for (var i = 0; i < json.features.length; i++) {
                      var f = json.features[i];
                      f.properties.id = i;
                  }

                  g.append("g")
                    .attr("id", "states")
                    .selectAll("path")
                    .data(json.features)
                    .enter()
                    .append("path")
                      .attr("id", function (d) { return d.properties.id; })
                    //.attr("class", "active")
                    .attr("d", path)
                    .on("mouseenter", department_mouseenter)
                    .on("mouseleave", department_mouseleave);

                  zoom(xyz);
                  g.selectAll("#" + d.id).style('display', 'none');
              });
              //} else {
              //    zoom(xyz);
              //}
          } else {
              vm.zoomOut();
          }
      }

      

      function province_mouseenter(d) {
          d3.select(this)
            .attr('fill', 'rgba(20,20,200,.5)');

          $timeout(function () {
              vm.name = d.properties.NAM;
          });
      }
      function province_mouseleave(d) {
          d3.select(this)
            .attr('fill', '');
      }

      function department_mouseenter(d) {
          d3.select(this)
            .attr('fill', 'rgba(20,200,20,.5)');

          $timeout(function () {
              vm.name = d.properties.FNA;
          });
      }
      function department_mouseleave(d) {
          d3.select(this)
            .attr('fill', '');
      }


      function init_old() {

          m_width = $("#map").width();
          width = 938;
          height = 500;

          projection = d3.geo.mercator()
          //.scale(380)
          //.translate([550, -100]);
          //.translate([width / 2, height / 1.5]);

          path = d3.geo.path()
              .projection(projection);

          svg = d3.select("#map").append("svg")
                    .attr("preserveAspectRatio", "xMidYMid")
                    .attr("viewBox", "0 0 " + width + " " + height)
                    .attr("width", m_width)
                    .attr("height", m_width * height / width);

          svg.append("rect")
              .attr("class", "background")
              .attr("width", width)
              .attr("height", height)
              .on("click", province_clicked);

          g = svg.append("g");

          d3.json("assets/json/provincias.topo.json", function (error, us) {
              g.append("g")
                .attr("id", "countries")
                .selectAll("path")
                .data(topojson.feature(us, us.objects.collection).features)
                .enter()
                .append("path")
                .attr("id", function (d) { return d.id; })
                .attr("d", path)
                .on("click", province_clicked);
          });

          $(window).resize(function () {
              var w = $("#map").width();
              svg.attr("width", w);
              svg.attr("height", w * height / width);
          });
      }

      function init() {
          m_width = $("#map").width();
          width = 300;
          height = 600;

          svg = d3.select("#map")
                  .append("svg")
                  .attr("width", width)
                  .attr("height", height);
          

          d3.json("assets/json/provincias.topo.json", function (error, us) {

              var json = topojson.feature(us, us.objects.provincias);

              country = json;

              //add metadata
              for (var i = 0; i < json.features.length; i++) {
                  var f = json.features[i];
                  f.properties.id = i;
              }


              // create a first guess for the projection
              var center = d3.geo.centroid(json)
              var scale = 150;
              var offset = [width / 2, height / 2];

              projection = d3.geo.mercator()
                                 .scale(scale)
                                 .center(center)
                                 .translate(offset);

              // create the path
              path = d3.geo.path().projection(projection);

              // using the path determine the bounds of the current map and use 
              // these to determine better values for the scale and translation
              var bounds = path.bounds(json);
              var hscale = scale * width * 0.8 / (bounds[1][0] - bounds[0][0]);
              var vscale = scale * height * 0.8 / (bounds[1][1] - bounds[0][1]);
              var scale = (hscale < vscale) ? hscale : vscale;
              var offset = [width - (bounds[0][0] + bounds[1][0]) / 2,
                                height - (bounds[0][1] + bounds[1][1]) / 2];

              //offset fine tuning
              //offset[0] = offset[0] - 20;
              offset[1] = offset[1] + 70;


              // new projection
              projection = d3.geo.mercator()
                                 .center(center)
                                 .scale(scale)
                                 .translate(offset);

              path = path.projection(projection);

              // add a rectangle to see the bound of the svg

              svg.append("rect")
                  .attr("class", "background")
                  .attr("width", width)
                  .attr("height", height)
                  .on("click", province_clicked);

              g = svg.append("g");

              g.append("g")
               .attr("id", "countries")
               .selectAll("path")
               .data(json.features)
               .enter()
                   .append("path")
                   .attr("id", function (d) {
                       return d.properties.id;
                   })
                   .attr("d", path)
                   .on("click", province_clicked)
                   .on("mouseenter", province_mouseenter)
                   .on("mouseleave", province_mouseleave);


              var xyz = get_xyz(json)
              zoom(xyz);
          });

      }

      init();
  });
