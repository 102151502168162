
angular.module("prisma")
    .controller("CategorySummaryMarkdownsCtrl", function ($state, $rootScope, markdownService, authService) {
      var self = this;

      self.canIgnoreAll = authService.hasPermission('markdown_decisions_IgnoreAll');
        self.canActivate = authService.hasPermission('markdown_activation_ActivateMarkdowns');
        self.canSendToPrices = authService.hasPermission('markdown_inform_getMarkdownsToInform');
        self.canValidar = authService.hasPermission('markdown_decisions_Validar');

      var numberColWidth = 120;

      var columnDefs = null;

      var decisionsTypes = {
          SUGGESTIONS: 0,
          ENDORSE: 1,
          VALIDATE: 2
          //REVALIDATE: 5
      }

      function setColumnDefinitions() {
          columnDefs = [
              {
                  headerName: 'Categoria',
                  width: numberColWidth + 250,
                  pinned: 'left',
                  field: 'name',
                  suppressMenu: true,
                  suppressSorting: true,
                  filter: false,
                  cellRenderer: {
                      renderer: 'group',
                      checkbox: false,
                      innerRenderer: function (params) {

                          var name = params.value + (params.node.group && params.node.children != null ? ' (' + params.node.children.length + ')' : '');

                          //if (params.data.parentId != null && params.data.parentId != 0) {

                              var categoryGroup = "'" + params.data.parentName + "'";
                              var category = "'" + params.data.name + "'";

                              var anchor = '<a style="padding: 5px;" title="Click para ingresar a la categoria" ng-click="ct.goToDecisions(' + params.data.id + ',' + categoryGroup + ',' + category + ',' + decisionsTypes.SUGGESTIONS + ')">' + name + '</a>';

                              var endorseLink = '';
                              if (params.data.accepted && params.data.accepted.totalSuggestions > 0) {
                                  endorseLink = '<a style="margin-left:4px;" title="Ir a endoso" ng-click="ct.goToDecisions(' + params.data.id + ',' + categoryGroup + ',' + category + ',' + decisionsTypes.ENDORSE + ')"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>';
                                  console.log('endorseLink', endorseLink);
                              }

                              var validateLink = '';
                              if (params.data.endorsed && params.data.endorsed.totalSuggestions > 0)
                                  validateLink = '<a style="margin-left:4px;" title="Ir a validacion" ng-click="ct.goToDecisions(' + params.data.id + ',' + categoryGroup + ',' + category + ',' + decisionsTypes.VALIDATE + ')"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>';

                              if (params.data.validated && params.data.validated.totalSuggestions > 0)
                                  validateLink = '<a style="margin-left:4px;" title="Ir a validacion" ng-click="ct.goToDecisions(' + params.data.id + ',' + categoryGroup + ',' + category + ',' + decisionsTypes.VALIDATE + ')"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>';

                              return endorseLink + validateLink+ anchor;
                          //}
                          //else
                          //    return name;
                      }
                  }
              },
              {
                  headerName: 'Rebajas',
                  children: [
                  {
                      headerName: "Sugerencias",
                      field: "active.totalSuggestions",
                      headerTooltip: "Cantidad de sugerencias.",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell

                  },
                  {
                      headerName: "Impacto",
                      field: "active.impact",
                      headerTooltip: "Muestra el impacto en pesos de la sugerencia.",
                      width: numberColWidth,
                      filter: 'number',
                      cellRenderer: $rootScope.moneyRenderer,
                      cellClass: $rootScope.getClassForImpactCell
                  },
                  {
                      headerName: "Stock",
                      field: "active.units",
                      //headerTooltip: "Cantidad de cambios de precio generados por un cambio en los costos.",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  },
                  ]
              },
              {
                  headerName: 'Nuevos Rebajas',
                  children: [
                  {
                      headerName: "Nuevos",
                      field: "accepted.totalSuggestions",
                      headerTooltip: "Cantidad de sugerencias competitivas.",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell

                  },
                  {
                      headerName: "Impacto",
                      field: "accepted.impact",
                      headerTooltip: "Muestra el impacto en pesos de la sugerencia.",
                      width: numberColWidth,
                      filter: 'number',
                      cellRenderer: $rootScope.moneyRenderer,
                      cellClass: $rootScope.getClassForImpactCell
                  },
                  {
                      headerName: "Stock",
                      field: "accepted.units",
                      //headerTooltip: "Cantidad de cambios de precio generados por un cambio en los costos.",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  }]
              },
              {
                  headerName: 'Rebajas Revisadas',
                  children: [
                  {
                      headerName: "Revisadas",
                      field: "endorsed.totalSuggestions",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "Impacto",
                      field: "endorsed.impact",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForImpactCell,
                      cellRenderer: $rootScope.moneyRenderer
                  },
                  {
                      headerName: "Stock",
                      field: "endorsed.units",
                      //headerTooltip: "Cantidad de cambios de precio generados por un cambio en los costos.",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  }, ]
              },
              {
                  headerName: 'Rebajas Validadas',
                  children: [
                  {
                      headerName: "Validadas",
                      field: "validated.totalSuggestions",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  },
                  {
                      headerName: "Impacto",
                      field: "validated.impact",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForImpactCell,
                      cellRenderer: $rootScope.moneyRenderer
                  },
                  {
                      headerName: "Stock",
                      field: "validated.units",
                      //headerTooltip: "Cantidad de cambios de precio generados por un cambio en los costos.",
                      width: numberColWidth,
                      filter: 'number',
                      cellClass: $rootScope.getClassForCell
                  }]
              }
          ];
      }

      self.gridOptions = {
          columnDefs: columnDefs,
          enableColResize: true,
          enableSorting: true,
          enableFilter: false,
          groupHeaders: false,
          suppressCellSelection: true,
          rowHeight: 35,
          groupHideGroupColumns: true,
          icons: {
              columnRemoveFromGroup: '<i class="fa fa-remove"/>',
              filter: '<i class="fa fa-filter"/>',
              sortAscending: '<i class="fa fa-long-arrow-down"/>',
              sortDescending: '<i class="fa fa-long-arrow-up"/>',
              groupExpanded: '<i class="fa fa-minus-square-o"/>',
              groupContracted: '<i class="fa fa-plus-square-o"/>',
              columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
              columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
          },
          angularCompileRows: true,
          getNodeChildDetails: getNodeChildDetails
      }

      function getNodeChildDetails(rowItem) {
          if (rowItem.subcategories && rowItem.subcategories.length > 0) {
              return {
                  group: true,
                  expanded: false,
                  // provide ag-Grid with the children of this group
                  children: rowItem.subcategories,
                  // the key is used by the default group cellRenderer
                  key: rowItem.id
              };
          }
          else {
              return null;
          }
      }

      self.goToDecisions = function (id, categoryGroup, category,type) {

          categoryGroup = categoryGroup.replace(/ /g, "-").replace(/\//g, "_");
          category = category.replace(/ /g, "-").replace(/\//g, "_");

          var encodedcategoryGroup = categoryGroup != 'null' ? encodeURIComponent(categoryGroup) : '';
          var encodedcategory = category != 'null' ? encodeURIComponent(category) : '';


          $state.go('markdowns.decisions', {
              categoryId: id, categoryGroup: encodedcategoryGroup, category: encodedcategory,
              decisionType: type
          });
      }

      function setGridOptions() {

          setColumnDefinitions();

          self.gridOptions = {
              columnDefs: columnDefs,
              enableColResize: true,
              enableSorting: true,
              enableFilter: false,
              groupHeaders: false,
              suppressCellSelection: true,
              rowHeight: 35,
              groupHideGroupColumns: true,
              icons: {
                  columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                  filter: '<i class="fa fa-filter"/>',
                  sortAscending: '<i class="fa fa-long-arrow-down"/>',
                  sortDescending: '<i class="fa fa-long-arrow-up"/>',
                  groupExpanded: '<i class="fa fa-minus-square-o"/>',
                  groupContracted: '<i class="fa fa-plus-square-o"/>',
                  columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                  columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
              },
              angularCompileRows: true,
              getNodeChildDetails: getNodeChildDetails
          }
      }

      //Filtro Buscador
      self.summary = null;

      self.copiedSummary = null;
      self.onSearchKeyUp = function () {
          //console.log('self.search', self.search);
          if (!self.search) {
              if (self.copiedSummary)
                  copyValuesFromFilteredToComplete(self.copiedSummary, self.summary);
              self.gridOptions.api.setRowData(self.summary);
          } else {
              self.copiedSummary = angular.copy(self.summary);
              filterSummary(self.summary, self.copiedSummary, self.search.toLowerCase());
              self.gridOptions.api.setRowData(self.copiedSummary);

          }
      }

      self.activateMarkdowns = function () {
          $state.go('markdowns.activation');
      }

      self.sendToPrices = function () {
          swal({
              title: "Desea enviar los precios de liquidacion validados al modulo de Precios?",
              text: 'Si lo hace mas de una vez, se sobreescribiran los precios de liquidacion que aun no se hayan informado.',
              type: "info",
              showCancelButton: true,
              confirmButtonColor: "#1AB394",
              confirmButtonText: "Continuar",
              cancelButtonText: "Cancelar",
              showLoaderOnConfirm: true,
              closeOnConfirm: false,
              closeOnCancel: true
          }, function (yes) {
              if (yes) {
                  markdownService.decisions.writeMarkdownPrices()
                      .then(function () {
                          console.log('writeMarkdownPrices');
                          loadData();
                          swal('Precios Publicados!', 'Los precios se han publicado exitosamente', 'success');
                      }, function (status) {
                          if (status == 502) {
                              swal("El proceso continua...", "Se enviara un e-mail a cada usuario de Liquidaciones cuando se reciba la informacion en precios.")
                          }
                          else {
                              swal("Error", "Hubo un error al publicar los precios", "error");
                          }
                      });
              }
          });
      }

      self.ignoreAll = function () {
          swal({
              title: "Esta seguro?",
              text: 'Esta seguro que desea ignorar todos los items pendientes?',
              type: "info",
              showCancelButton: true,
              confirmButtonColor: "#1AB394",
              confirmButtonText: "Continuar",
              cancelButtonText: "Cancelar",
              showLoaderOnConfirm: true,
              closeOnConfirm: false,
              closeOnCancel: true
          },
              function (yes) {
                  if (yes) {
                      markdownService.decisions.ignoreAll()
                          .then(function () {
                              //self.applyFilters();
                              loadData();
                              swal("Confirmado", "Se guardaron los cambios", "success");
                          }, function (error) {
                              swal("Error", "Ha ocurrido un error", "error");
                          });
                  }
              });
      }
    function splitCategories() {
        var arr = [];
        angular.forEach(self.selectedCategories, function (category) {
            arr.push(category.id);
        });
        return arr;
        }
        self.applyingFilters = false;
        self.applyFilters = function () {

            self.pageNumber = 0;
            loadData();
        }
        self.reviewCategoryValidate = function () {
            swal({
                title: "Esta seguro?",
                text: "Esta seguro que desea marcar la categoria como revisada?",
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            }, function (yes) {
                if (yes) {
                    
                    markdownService.decisions.reviewCategoryValidate()
                        .then(function () {
                            self.applyFilters();
                            console.log('reviewCategoryValidate');
                            swal("Confirmado", "Se guardaron los cambios seleccionados", "success");
                        }, function (error) {
                            swal("Error", "Ha ocurrido un error", "error");
                        });
                }
            });
        }

      function copyValuesFromFilteredToComplete(filtered, completed) {
          if (filtered.length == 0 || completed.length == 0) return;
          for (var j = 0; j < completed.length; j++) {
              for (var i = 0; i < filtered.length; i++) {
                  if (completed[j].id == filtered[i].id) {
                      //console.log('copying', filtered[i].name);
                      copyValuesFromFilteredToComplete(filtered[i].subcategories, completed[j].subcategories);
                  }
              }
          }
      }

      //source y dest tienen que ser copias uno del otro
      function filterSummary(source, dest, search) {
          if (!source || source.length == 0) return false;
          var idsToRemove = [];
          var anyOfThisAreValid = false;
          for (var i = 0; i < source.length; i++) {
              var anyChildrenValid = false;

              anyChildrenValid |= filterSummary(source[i].subcategories, dest[i].subcategories, search);

              if (!anyChildrenValid && source[i].name.toLowerCase().indexOf(search) == -1) {

                  idsToRemove.push(source[i].id);
              } else if (source[i].name.toLowerCase().indexOf(search) != -1) {
                  anyOfThisAreValid = true;
              } else if (anyChildrenValid) {
                  anyOfThisAreValid = true;
              }
          }

          for (var i = 0; i < idsToRemove.length; i++) {
              for (var j = 0; j < dest.length; j++) {
                  if (dest[j].id == idsToRemove[i]) {
                      dest.splice(j, 1);
                      break;
                  }
              }
          }

          return anyOfThisAreValid;
      }

      function loadData() {
          markdownService.decisions.getCategorySummary().then(function (items) {
              console.log('items', items);


              self.summary = items;
              self.gridOptions.api.setRowData(items);
              self.gridOptions.api.sizeColumnsToFit();
          });
      }

      function init() {
          //init columnDefs and gridOptions
          setGridOptions();

          //load data
          loadData();
      }

      init();

  });

