"use strict";

angular.module("prisma")
    .service("assortmentService", function assortmentService($rootScope, $q, httpService) {
        var self = this;

        self.getCategorySummaryPromise;
        self.getParetoByCategoryPromise;
        self.lastParetoCategoryId = 0;

        self.decisions = {
          getAssortmentDashboard: function (clusterId) {
                return httpService.get('assortment/decisions/dashboard/' + $rootScope.companyId + '/' + clusterId);
            },
            getCategorySummary: function (categoryLevel) {

                return httpService.get('/assortment/decisions/category-summary/' + $rootScope.companyId + '/' + categoryLevel);
                /*
                if (!self.getCategorySummaryPromise) {
                    self.getCategorySummaryPromise = httpService.get('/assortment/decisions/category-summary/' + $rootScope.companyId);
                }
                return self.getCategorySummaryPromise;
                */
            },
            getParetoByCategory: function (filters) {
            //getParetoByCategory: function (categoryId, forceRefresh) {

                return httpService.post('/assortment/decisions/pareto-category', filters);

                //var fetchDataFromServer = false;
                //if (self.lastParetoCategoryId != categoryId || !self.getParetoByCategoryPromise) {
                //    fetchDataFromServer = true;
                //}
                //if (fetchDataFromServer || forceRefresh) {
                //    self.getParetoByCategoryPromise = httpService.get('/assortment/decisions/pareto-category/' + $rootScope.companyId + '/' + categoryId);
                //}

                //return self.getParetoByCategoryPromise;
            },
            getParetoFiltersByCategory: function (categoryId) {
                return httpService.get('/assortment/decisions/pareto-filters-by-category/' + categoryId);
            },
            getChatForItem: function (itemId, clusterId) {
                return httpService.get('assortment/decisions/getChat/' + itemId + '/' + clusterId);
            },
            getCategoryDetail: function (categoryId) {
                return httpService.get('assortment/decisions/categoryDetail/' + categoryId + "/" + $rootScope.companyId);
            },
            getPresenceDetail: function (itemId, clusterId)
            {
                return httpService.get('assortment/decisions/presence-detail/' + $rootScope.companyId + "/" + itemId + "/" + clusterId);
            },
            getParetoSections: function (categoryId) {
                return httpService.get('assortment/decisions/pareto-sections/' + categoryId);
            },
            getHiddenColumnIndexes: function () {
                return httpService.get('assortment/decisions/getHiddenColumnIndexes/' );
            },
            saveDecisions: function (itemsToSave, isEndorse, categoryId) {
                return httpService.post('assortment/decisions/save-decisions/' + isEndorse + '/' + categoryId, itemsToSave);
            },
            ignoreSuggestions: function (itemsToIgnore) {
                return httpService.post('assortment/decisions/ignore-suggestions', itemsToIgnore);
            },
            rejectEndorse: function (itemIdsToReject, comment, url, categoryId) {
                return httpService.post('assortment/decisions/reject-endorse/' + categoryId, { assortmentIds: itemIdsToReject, reason: comment, url: url });
            },
            saveParetoSections: function (sections) {
                var url = 'assortment/decisions/pareto-sections/save';
                return httpService.post(url, sections);
            },
            saveChatForItem: function (chat) {
                return httpService.post('assortment/decisions/save-chat', chat);
            },
            updateLastRevisionDate: function (categoryId) {
                return httpService.get('assortment/decisions/update-last-revision/' + categoryId);

            },
            getClusterCrossing: function (dto) {
                return httpService.post('assortment/decisions/getClusterCrossing', dto);
            },
            saveClusterCrossing: function (dtos) {
                return httpService.post('assortment/decisions/saveClusterCrossing', dtos);
            },
            saveColumnsToHide: function (columnIndexesToHide) {
                return httpService.get('assortment/decisions/saveColumnsToHide/' + columnIndexesToHide);
            }
        }

        self.clusters = {
            getClusters: function () {
                return httpService.get('/assortment/clusters/getClusters/' );
            },
            getClustersByCategory: function (categoryId) {
                return httpService.get('/assortment/clusters/getClustersByCategory/' + categoryId );
            },
            getClusterCategorySpaces: function (categoryId) {
                return httpService.get('/assortment/clusters/getClusterCategorySpaces/' + categoryId);
            }
        }

        self.categoryRoleStrategyCoverages = {
            getCoverages: function () {
                return httpService.get('/assortment/categoryRoleStrategyCoverages/getCoverages/' + $rootScope.companyId);
            },
            getCoveragesForCategory: function (categoryId) {
                return httpService.get('/assortment/categoryRoleStrategyCoverages/getCoveragesForCategory/' + categoryId);
            },
            getStrategyAndRoles: function() {
                return httpService.get('/assortment/categoryRoleStrategyCoverages/getStrategyAndRoles');
            }
        }

        self.rules = {
            getCategories: function (categoryLevel) {
                return httpService.get('/assortment/rules/category-rules/' + categoryLevel);
            },
            getCategoriesForRules: function (categoryId) {
                return httpService.get('/assortment/rules/category-for-rules/' + $rootScope.companyId + '/' + categoryId);
            },
            getRules: function(categoryId){
                return httpService.get('/assortment/rules/existing-rules/' + $rootScope.companyId + '/' + categoryId);
            },
            getClusters: function () {
                return httpService.get('/assortment/rules/cluster-rules/' + $rootScope.companyId);
            },
            getClustersByCategory: function (categoryId) {
                return httpService.get('/assortment/rules/getClustersByCategory/' + categoryId);
            },
            getTags: function () {
                return httpService.get('/assortment/rules/tag-rules/' + $rootScope.companyId);
            },
            getItemBrands: function (categoryId) {
                return httpService.get('/assortment/rules/getItemBrands/' + categoryId);
            },
            getItemManufacturers: function (categoryId) {
                return httpService.get('/assortment/rules/getItemManufacturers/' + categoryId);
            },
            saveCategoryRules: function (categories, brandId) {
                return httpService.post('/assortment/rules/save-category-rules/' + brandId, categories);
            },
            saveListingRule: function (listingRule) {
                return httpService.post('/assortment/rules/save-listing-rule', listingRule);
            },
            saveDelistingRule: function (delistingRule) {
                return httpService.post('/assortment/rules/save-delisting-rule', delistingRule);
            },
            deleteRule: function (ruleId) {
                return httpService.get('/assortment/rules/deleteRule/' + ruleId);
            },
            saveCustomCategoryMonths: function (customCategoryMonths) {
                return httpService.post('/assortment/rules/saveCustomCategoryMonths', customCategoryMonths);
            }
        }

        self.suggestion = {
            generateSuggestions: function (isListing, categoryId) {
                return httpService.post('/assortment/suggestion/generate-suggestions/' + $rootScope.companyId + "/" + categoryId + "/" + isListing);
            }
        }
        
        self.output = {
            getEndosedData: function () {
                return httpService.post('/assortment/output/getData');
            },
            getEndosedDataCencosudColombia: function () {
                return httpService.post('/assortment/output/getColombiaData');
            },
            getEndosedDataCencosudChile: function () {
                return httpService.post('/assortment/output/getChileData');
            },
            changeOutputStatus: function () {
                return httpService.post('/assortment/output/changeOutputStatus');
            },
            getPrismaEndosedData: function (categoryId) {
            return httpService.post('/assortment/output/getPrismaData/' + categoryId);
        },
        }

        self.summaryReport = {
            getSummaryReport: function (categoryGroupId) {
                return httpService.get('/assortment/summaryReport/getSummaryReport/' + categoryGroupId);
            },
        }

        self.reports = {
            getClusterHierarchy: function (categoryLevel) {
                return httpService.get('/assortment/decisions/getClusterHierarchy/'+ categoryLevel);
            },
            getSalesAndMargin: function (categoryId) {
                return httpService.get('/assortment/assortmentReport/getSalesAndMargin/' + categoryId);
            }
        }

    });



