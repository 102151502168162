angular.module("prisma")
  .controller("LoginCtrl", function ($scope, $location, authService, ngAuthSettings, $rootScope, $state, $http, companyJson) {

      var self = this;

      $scope.welcomeMessage = "";
      $scope.welcomeMessage = companyJson.LoginWelcomeMessage;
      $scope.security = angular.extend({}, { Local: true }, companyJson.Security);

      $scope.userRole = "";
      $scope.message = "";
      $scope.loginData = {
          userName: "",
          password: "",
          useRefreshTokens: false
      };
      self.errorLoginMsj = "";

      self.showLogo = function () {
          if (location.hostname === "localhost" || location.hostname === "127.0.0.1")
              return false;
          return true;
      }

      $scope.login = function () {

          authService.login($scope.loginData).then(function (authentication) {
              $scope.authentication = authentication;
              authService.getUserInfo().then(function () {

                  //redirect donde corresponda
                  if (authentication.redirectUrl) {

                      $location.path(authentication.redirectUrl);
                  }
                  //else if (authService.hasPermission('store_admin') && $scope.loginData.userName != 'root@root.com') {
                  //    $state.go('spaces.viewLayout', { layoutId: 1 });
                  //}

                  else if (authService.hasPermission('promotion_isStore') && authentication.userName != 'root@root.com') {//perfil de tienda no debe ir al dashboard
                      $state.go('promotions.comunication', { taskId: 0 });
                  }
                  else {
                      $state.go('home.dashboard');
                  }

              });
          },
           function (err) {
               if (err) {
                   $scope.message = err.exception ? err.message : err.error_description;
                   self.errorLoginMsj = $scope.message;
               }
               else {
                   $scope.message = "Usuario o Contrase�a incorrectos";
                   self.errorLoginMsj = "Usuario o Password incorrectos";
               }
           });
      };

      $scope.logout = function () {

          authService.logOut()
          $state.go('login');
      };

      $scope.authExternalProvider = function (provider) {

          var redirectUri = location.protocol + '//' + location.host + '/authcomplete.html';

          var externalProviderUrl = ngAuthSettings.apiServiceBaseUri + "Account/ExternalLogin?provider=" + provider
                                                                      + "&response_type=token&client_id=" + ngAuthSettings.clientId
                                                                      + "&redirect_uri=" + redirectUri;
          window.$windowScope = $scope;

          var oauthWindow = window.open(externalProviderUrl, "Authenticate Account", "location=0,status=0,width=600,height=750");
      };

      $scope.authCompletedCB = function (fragment) {

          $scope.$apply(function () {

              if (fragment.haslocalaccount == 'False') {

                  authService.logOut();

                  authService.externalAuthData = {
                      provider: fragment.provider,
                      userName: fragment.external_user_name,
                      externalAccessToken: fragment.external_access_token
                  };

                  $location.path('/associate');

              }
              else {
                  //Obtain access token and redirect to orders
                  var externalData = { provider: fragment.provider, externalAccessToken: fragment.external_access_token };
                  authService.obtainAccessToken(externalData).then(function (response) {

                      $location.path('/orders');

                  },
               function (err) {
                   $scope.message = err.error_description;
               });
              }

          });
      }

      if (authService.authentication.isAuth) {
          if (authService.hasPermission('promotion_isStore') && authService.authentication.userName != 'root@root.com') {//perfil de tienda no debe ir al dashboard
              $state.go('promotions.comunication', { taskId: 0 });
          }
          else {
              $state.go('home.dashboard');
          }
      }

  });
