"use strict";

angular.module("prisma")
    .service("spaceService", function ($rootScope, httpService) {
        var self = this;

        self.stores = {
            getStores: function () {
                return httpService.get('/space/stores/getStores');
            },
        }
        self.layouts = {
            saveLevels: function (data) {
                return httpService.post('/space/layout/save-levels', data);
            },
            uploadLayout: function (imgData) {
                return httpService.post('/space/layout/upload-layout', imgData);
            },
            saveLayoutLevels: function (data) {
                return httpService.testPost('/space/layout/save-layout-levels', data);
            },
            saveLayout: function (dto) {
                return httpService.post('/space/layout/save-layout', dto);
            },
            deleteLayout: function (idLayout) {
                return httpService.post('/space/layout/delete-layout', idLayout);
            },
            getLayouts: function () {
                return httpService.get('/space/layout/get-layouts');
            },
            getLayoutLevels: function (layoutId) {
                return httpService.get('/space/layout/get-layout-levels/' + layoutId);
            },
            getLayout: function (layoutId) {
                return httpService.get('/space/layout/get-layout/' + layoutId);
            },
            getLayoutItemSales: function (layoutId) {
                return httpService.get('/space/layout/get-layout-sales/' + layoutId);
            },
            getLayoutStoreSales: function (layoutId) {
                return httpService.get('/space/layout/get-layout-stores-sales/' + layoutId);
            },
        }

        self.spaceObjects = {
            getSpaceObjects: function (storeIds) {
                return httpService.post('/space/space-object/get-space-objects', storeIds);
            },
            savePlanogram: function (dto) {
                return httpService.post('/space/space-object/save-space-objects', dto);
            },
        }

        self.planograms = {
            getPlanogramId: function (itemId, clusterId) {
                return httpService.get('/space/planogram/get-planogram-id/' + itemId + '/' + clusterId);
            },
            getPlanogramInfo: function (planogramId) {
                return httpService.get('/space/planogram/get-planogram-info/' + planogramId);
            },
            getColumnsFromPlanogram: function (planogramId) {
                return httpService.get('/space/planogram/getColumnsFromPlanogram/' + planogramId);
            },
            savePlanogram: function (dto) {
                return httpService.post('/space/planogram/save-planogram', dto);
            },
            deletePlanogram: function (planogramId) {
                return httpService.post('/space/planogram/delete-planogram', planogramId);
            },
            getPlanograms: function () {
                return httpService.get('/space/planogram/get-planograms/');
            },
            getPlanogramsList: function () {
                return httpService.get('/space/planogram/get-planograms-list/');
            },
            getSpaceItemsForPlanogram: function (planogramId) {
                return httpService.get('/space/planogram/get-space-items-for-planogram/' + planogramId);
            },
            editPlanogram: function (dto, status) {
                return httpService.post('/space/planogram/edit-planogram/' + status , dto);
            },
            getChildCategories: function (categoryId) {
                return httpService.get('/space/planogram/get-child-categories/' + categoryId)
            },
            getPlanogramSales: function (planogramIds) {
                return httpService.post('/space/planogram/get-planogram-sales', planogramIds);
            },
            getStoresForPlanogram: function (planogramId) {
                return httpService.get('/space/planogram/get-stores-for-planogram/' + planogramId)
            },
            getItemsSalesForCluster: function (categoryId, clusterIds) {
                return httpService.post('/space/planogram/get-items-sales-for-cluster/' + categoryId, clusterIds)
            },
            savePhotoAudit: function (data) {
                return httpService.testPost('/space/planogram/save-photo-audit', data);
            },
            savePlanogramStorePhotoAudi: function (data) {
                return httpService.post('/space/planogram/save-planogram-store-photo-audit', data);
            },
            getPhotoAudit: function (planogramId) {
                return httpService.get('/space/planogram/get-photos-audit/' + planogramId)
            },
            buildPlanogramItemsReport: function (planogramId) {
                return httpService.get('/space/planogram/export-planogram-items/' + planogramId)
            },
        }

        self.reports = {
            buildItemsByAssortmentReport: function (activesItems) {
                return httpService.get('/space/reports/itemsByAssortmentReport/' + activesItems);
            },
            getLastReports: function () {
                return httpService.get('/space/reports/getLastReports');
            }
        }
        self.tickets = {
            getPlanogramTickets: function (layoutObjectId) {
                return httpService.get('/space/ticket/get-planogram-tickets/' + layoutObjectId);
            },
            getItemTickets: function (planogramLevelItemId) {
                return httpService.get('/space/ticket/get-item-tickets/' + planogramLevelItemId);
            },
            getTickets: function () {
                return httpService.get('/space/ticket/get-tickets');
            },
            getTicketTypesByType: function (type) {
                return httpService.get('/space/ticket/get-ticket-types-by-type/' + type);
            },
            getTicketTypes: function () {
                return httpService.get('/space/ticket/get-ticket-types/');
            },
            getTicketTypeById: function (id) {
                return httpService.get('/space/ticket/get-ticket-type/' + id);
            },
            saveTicketType: function (ticketType) {
                return httpService.post('/space/ticket/SaveTicketType/', ticketType);
            },
            saveTicket: function (ticket) {
                return httpService.post('/space/ticket/save-ticket', ticket);
            },
            editTicket: function (ticket) {
                return httpService.post('/space/ticket/edit-ticket', ticket);
            },
            addComment: function (comment) {
                return httpService.post('/space/ticket/add-comment', comment);
            },
        }

        self.tags = {
            getTags: function () {
                return httpService.get('/space/tags/getTags/');
            }
        }

        self.assortment = {
            getAssortment: function (categoryId, clusterIds) {
                return httpService.post('/space/assortment/getAssorment/' + categoryId, clusterIds);
            },
        }

    });