angular.module('prisma').component('listStoreComponent', {
    templateUrl: 'app/shared_logic/components/listStoreComponent/listStore.Component.html',
    controller: planogramShelfController,
    bindings: {
        isExcluded: '=',
        seletedstores: '=',
    }
});


function planogramShelfController($scope, $state, $timeout, $stateParams, $translate, authService, spaceService) {
    var self = this;
    self.isFocus = false;
    self.focus = function () {
        self.isFocus = true;
    }
    console.log('planogram in listStores', self)
    self.blur = function () {
        self.isFocus = false;
    }

    function init() {
        spaceService.stores.getStores(false).then(function (stores) {
            self.stores = stores;
        })
    };

    init()
};