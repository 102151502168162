


angular.module("prisma")

  .controller("BenchmarksCtrl", function ($timeout, $scope, $rootScope, homeService, authService) {

      var self = this;
      self.sameStores = true;
      self.showLoader = true;
      self.salesChartData = null;
      self.marginChartData = null;
      self.unitsChartData = null;
      self.selectedType = 'spline';
      self.stores = [];
      self.categories = [];
      self.items = [];
      self.clusters = [];
      self.selectedStores = [];
      self.selectedCategories = [];
      self.selectedItems = [];
      self.selectedClusters = [];

      self.filterByStore = [];
      self.filterByCategory = [];
      self.filterByItem = [];
      self.filterByCluster = [];

      self.updateCharts = function () {

          if (self.salesChartData == null)
              return;
          //console.log(' self.salesChartData', self.salesChartData);
          self.salesChart = c3.generate(getChartConfig('#bechmarkSalesChart', self.salesChartData));

          if (self.marginChartData == null)
              return;
          self.marginChart = c3.generate(getChartConfig('#bechmarkMarginChart', self.marginChartData));

          if (self.unitsChartData == null)
              return;
          self.unitsChart = c3.generate(getChartConfig('#bechmarkUnitsChart', self.unitsChartData));
      }

      $scope.$watch('ct.selectedStores', function (newVal, oldVal) {
          if (newVal.length > 0) {
              self.selectedCategories = [];
              self.selectedItems = [];
              self.selectedClusters = [];
              self.filterByStore = [];
          }
      }, true);

      $scope.$watch('ct.selectedCategories', function (newVal, oldVal) {
          if (newVal.length > 0) {
              self.selectedStores = [];
              self.selectedItems = [];
              self.selectedClusters = [];
              self.filterByCategory = [];
          }
      }, true);

      $scope.$watch('ct.selectedItems', function (newVal, oldVal) {
          if (newVal.length > 0) {
              self.selectedStores = [];
              self.selectedCategories = [];
              self.selectedClusters = [];
              self.filterByItem = [];
          }
      }, true);

      $scope.$watch('ct.selectedClusters', function (newVal, oldVal) {
          if (newVal.length > 0) {
              self.selectedStores = [];
              self.selectedCategories = [];
              self.selectedItems = [];
              self.filterByCluster = [];
          }
      }, true);

      $scope.$watch('ct.filterByCluster', function (newVal, oldVal) {
          if (newVal.length > 0) {
              if (self.selectedClusters.length > 0) {
                  swal("No puede comparar y filtrar por cluster a la vez", "", "warning");
                  self.filterByCluster = [];
              }
          }
      }, true);

      $scope.$watch('ct.filterByItem', function (newVal, oldVal) {
          if (newVal.length > 0) {
              self.selectedItems = [];
          }
      }, true);

      $scope.$watch('ct.filterByStore', function (newVal, oldVal) {
          if (newVal.length > 0) {
              self.selectedStores = [];
          }
      }, true);

      self.compare = function () {

          self.showLoader = true;

          var parameters = {
              companyId: authService.authentication.companyId,
              sameStores: self.sameStores,
              fromDate: self.fromDate,
              toDate: self.toDate,
              storeIdsToCompare: [],
              categoryIdsToCompare: [],
              clusterIdsToCompare: [],
              itemIdsToCompare: [],
              itemIds: [],
              storeIds: [],
              categoryIds: [],
              clusterIds: []
          }

          //Compare
          angular.forEach(self.selectedStores, function (st) {
              parameters.storeIdsToCompare.push(st.id);
          });
          angular.forEach(self.selectedCategories, function (st) {
              parameters.categoryIdsToCompare.push(st.id);
          });
          angular.forEach(self.selectedClusters, function (st) {
              parameters.clusterIdsToCompare.push(st.id);
          });
          angular.forEach(self.selectedItems, function (st) {
              parameters.itemIdsToCompare.push(st.id);
          });

          //Filtros
          angular.forEach(self.filterByStore, function (st) {
              parameters.storeIds.push(st.id);
          });
          angular.forEach(self.filterByCategory, function (st) {
              parameters.categoryIds.push(st.id);
          });
          angular.forEach(self.filterByCluster, function (st) {
              parameters.clusterIds.push(st.id);
          });
          angular.forEach(self.filterByItem, function (st) {
              parameters.itemIds.push(st.id);
          });

          homeService.benchmark.getBenchmark(parameters)
              .then(function (chartData) {

                  //console.log('chartdata', chartData);

                  if (chartData && chartData.length > 0) {
                      self.salesChartData = parseChartData(chartData, 'sales');
                      self.marginChartData = parseChartData(chartData, 'margin');
                      self.unitsChartData = parseChartData(chartData, 'units');

                      self.updateCharts();
                  }

                  self.showLoader = false;
              }), function (error) {
                  self.showLoader = false;
              };
      }

      self.updateChartType = function (type) {
          self.selectedType = type;
          self.salesChart.transform(type);
          self.marginChart.transform(type);
          self.unitsChart.transform(type);
      }

      function getChartConfig(chartId, data) {
          if (chartId != '#bechmarkUnitsChart') {
              return {
                  bindto: chartId,
                  data: {
                      x: 'x',
                      columns: data,
                      type: 'spline',
                  },
                  axis: {
                      x: {
                          type: 'timeseries',
                          tick: {
                              format: '%Y-%m',
                          }
                      },
                      y: {
                          tick: {
                              format: d3.format("s")
                          }
                      }
                  },
                  tooltip: {
                      format: {
                          title: function (d) {
                              if (d) {
                                  var toFormat = '%B %Y';
                                  if (self.timerange == 'year')
                                      toFormat = '%B %Y';

                                  var format = d3.time.format(toFormat);
                                  return format(d);
                              }
                          },
                          value: function (value, ratio, id) {
                              var format = d3.format('$,.3s');
                              return format(value);
                          }
                      }
                  }
              }
          }
          else
          {
              return {
                  bindto: chartId,
                  data: {
                      x: 'x',
                      columns: data,
                      type: 'spline',
                  },
                  axis: {
                      x: {
                          type: 'timeseries',
                          tick: {
                              format: '%Y-%m',
                          }
                      },
                      y: {
                          tick: {
                              format: d3.format("s")
                          }
                      }
                  },
                  tooltip: {
                      format: {
                          title: function (d) {
                              if (d) {
                                  var toFormat = '%B %Y';
                                  if (self.timerange == 'year')
                                      toFormat = '%B %Y';

                                  var format = d3.time.format(toFormat);
                                  return format(d);
                              }
                          },
                          value: function (value, ratio, id) {
                              var format = d3.format(',.3s'); 
                              return format(value);
                          }
                      }
                  }
              }
          }
      }

      function parseChartData(chartData, chart) {
          var data = [];
          var names = [];
          var dates = ['x'];
          var series = [];

          //obtengo names
          var name = '';
          angular.forEach(chartData, function (d) {
              if (d.name !== name) {
                  names.push(d.name);
                  name = d.name;
              }
          });


          //obtengo dates
          var date = '';
          name = chartData[0].name;
          angular.forEach(chartData, function (d) {
              var currDate = d.transactionYear + '-' + d.transactionMonth + '-1';
              if (d.name == name) {
                  if (currDate !== date) {
                      dates.push(currDate);
                      date = currDate;
                  }
              }
          });

          //armo las series
          var serie = [];
          name = '';
          angular.forEach(names, function (n) {
              angular.forEach(chartData, function (d) {
                  if (d.name !== name) {
                      if (serie.length > 0)
                          series.push(serie);

                      serie = [];
                      serie.push(d.name);
                      serie.push(d[chart]);
                      name = d.name;
                  }
                  else {
                      serie.push(d[chart]);
                  }
              });
          });

          data.push(dates);
          angular.forEach(series, function (s) {
              data.push(s);
          });

          return data;
      }

      function loadData() {

          homeService.benchmark.getBenchmarkValues()
              .then(function (values) {

                  self.stores = values.stores;
                  self.categories = values.categories;
                  self.items = values.items;
                  self.clusters = values.clusters;

                  var date = new Date();
                  self.toDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                  self.fromDate = new Date(date.getFullYear(), date.getMonth() - 12, 1);

                  //self.compare();
                  self.showLoader = false;
              });

      }

      function init() {

          //load data
          loadData();
      }

      init();
  });

