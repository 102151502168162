"use strict";

angular.module("prisma")

    .directive("ionRangeSlider", function ionRangeSlider() {
        return {
            restrict: "A",
            scope: {
                rangeOptions: "=",
                onChange: "="
            },
            link: function (scope, elem, attrs) {

                scope.rangeOptions.onFinish = scope.onChange;

                elem.ionRangeSlider(scope.rangeOptions);

            }
        }
    });




