


angular.module("prisma")

  .controller("MapsCtrl", function ($scope, $timeout, NgMap) {
      var vm = this;
      NgMap.getMap().then(function (map) {
          vm.map = map;

          vm.province = null;

          vm.map.data.setStyle(function (feature) {
              return /** @type {google.maps.Data.StyleOptions} */({
                  //fillColor: feature.getProperty('color'),
                  fillColor: vm.getProvinceColor(feature),
                  strokeWeight: 1
              });
          });

          var infowindow = new google.maps.InfoWindow({
              content: "MI BUENOS AIRES QUERIDO!",
              position: { lat: -36.648446, lng: -65.557184 }
          });
          
          //infowindow.open(vm.map);
          
      });

      vm.onClick = function (event) {
          var id = event.feature.getProperty("objectid");

          var name = event.feature.getProperty('provincia');
          var cap = event.feature.getProperty('capital');
          var h = event.feature.getProperty('habitantes');
          var ss = event.feature.getProperty('totalSS');
          
          vm.province = {
              provincia: name,
              capital: cap,
              habitantes: h,
              totalSS: ss,
              ratio: ((ss / h) * 10000).toFixed(2),
          };

      };

      vm.onMouseover = function (event) {
          vm.map.data.revertStyle();
          vm.map.data.overrideStyle(event.feature, { strokeWeight: 2 });
      };

      vm.onMouseout = function (event) {
          vm.map.data.revertStyle();
      };

      vm.getProvinceColor = function (feature) {
          
          //var colors = ["red", "green", "yellow"];
          //var rnd = ian.getRandomInt(0, 2);
          //var color = colors[rnd];

          var h = feature.getProperty('habitantes');
          var ss = feature.getProperty('totalSS');
          var ratio = (ss / h) * 10000;

          var color = ratio > 1.5 ? "red" : ratio < 1 ? "green" : "yellow";

          return color;

      }

  });

