angular.module("prisma")
    .controller("overlappingReportCtrl", function ($scope, $rootScope, $state, $stateParams, promotionsService, $timeout, ngDialog, authService) {

        var self = this;
        self.isLoading = true;
        self.hasPermissionOfSuperUser = authService.hasPermission('promotion_superUser');
        self.hasPermissionToDownLoad = authService.hasPermission('promotion_overlapping_report');
        self.promotions = [];
        self.distinctStatus = ['Activa', 'Planeada'];
        self.distinctPromotionTypes = ['Externa', 'Interna', 'Otras'];
        self.distinctCampaigns = [];
        self.distinctMediaTypes = [];

        self.search = "";

        self.selectedFilterForCampaign = '';
        self.selectedFilterForMediaType = '';
        self.selectedFilterForPromotionType = '';
        self.selectedFilterForStatus = '';

        self.promotionMediaTypes = promotionsService.promotionMediaTypes;


        self.generateReport = function (promotionId) {
            self.isLoading = true;
            promotionsService.reports.buildOverlappingReport(promotionId)
                .then(function (link) {
                    self.isLoading = false;
                    self.showSwalWithLink(link);
                });

            ngDialog.close();
        }

        self.showSwalWithLink = function (link) {
            swal({

                title: "Reporte Generado!",
                text: 'Haga click <a href="' + link + '"><u style = "color: #1AB394;"><b style = "color: #1AB394;">AQUÍ</b></u></a> para descargar el archivo',
                confirmButtonText: "Cerrar",
                confirmButtonColor: "#c2c2c2",
                html: true
            });
        }

        //Filters
        self.myFilter = function (p) {
            return (
                ((p.promotionCampaignName.toLowerCase().indexOf(self.selectedFilterForCampaign.toLocaleLowerCase()) > -1 || self.selectedFilterForCampaign == "Todas") && self.search == '') &&
                ((p.promotionMediaTypeName.toLowerCase().indexOf(self.selectedFilterForMediaType.toLocaleLowerCase()) > -1 || self.selectedFilterForMediaType == "Todos") && self.search == '') &&
                ((p.promotionType.toLowerCase().indexOf(self.selectedFilterForPromotionType.toLocaleLowerCase()) > -1 || self.selectedFilterForPromotionType == "Todos") && self.search == '') &&
                ((p.status.toLowerCase().indexOf(self.selectedFilterForStatus.toLocaleLowerCase()) > -1 || self.selectedFilterForStatus == "Todas") && self.search == '')
            )
                ||
                (self.search.length > 0 && (
                    (p.name && p.name.toLowerCase().indexOf(self.search.toLowerCase()) > -1)
                    || (p.id && p.id.toString().indexOf(self.search) > -1)
                ));
        };

        self.filterByCampaign = function (campaign) {
            if (campaign == '')
                self.selectedFilterForCampaign = '';
            else if (self.selectedFilterForCampaign != campaign) {
                self.selectedFilterForCampaign = campaign;
            }
        }
        self.filterByMediaType = function (type) {
            if (type == '')
                self.selectedFilterForMediaType = '';
            else if (self.selectedFilterForMediaType != type) {
                self.selectedFilterForMediaType = type;
            }
        }
        self.filterByPromotionType = function (type) {
            if (type == '')
                self.selectedFilterForPromotionType = '';
            else if (self.selectedFilterForPromotionType != type) {
                self.selectedFilterForPromotionType = type;
            }
        }
        self.filterByStatus = function (status) {
            if (status == ' ')
                self.selectedFilterForStatus = '';
            else if (self.selectedFilterForStatus != status) {
                self.selectedFilterForStatus = status;
            }
        }

        function init() {
            promotionsService.promotions.getPromotionsOverlapping()
                .then(function (promotions) {
                    self.promotions = promotions;

                    _.forOwn(_.groupBy(promotions, "promotionCampaignName"), function (value, key) {
                        self.distinctCampaigns.push(key);
                    });

                    _.forOwn(_.groupBy(promotions, "promotionMediaTypeName"), function (value, key) {
                        self.distinctMediaTypes.push(key);
                    });

                    self.isLoading = false;


                });

        }

        init();
    });