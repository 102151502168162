angular.module('prisma').component('containerPlanogramComponent', {
    templateUrl: 'app/spaces/planograms/components/containerPlanogram.html',
    controller: containerPlanogramController,
    bindings: {
        planogram: '=',
        iscreate: '=',
        hideitems: '=',
        column: '=',
        renderpiechartsparent: '=',
        selecteditem: '=',
        filtereditems: '=',
        selectedlevelparent: "=",
        selectedplanogramparent: "=",
        sales: '=',
        showloadquantity: '=',
        showexhibitiondays: '=',
        showregularprice: '=',
        showdaysinstock: '=',
        selectedbrand: '=',
        selectedmanufacturer: '=',
        selectedtagfilter: '=',
        heatmapenabled: '=',
        groupmapenabled: '=',
        showdoor: '=',
        autostack: '=',
        countplanogramitems: '=',
        shownewticketmodal: '=',
        showlevelmeasurebuttons: '=',
        zoom: '=',
        moduleselected: "=",
        showelements: "=",
        showdeletebutton: "="
    }
});
function containerPlanogramController($scope, $state, $stateParams, $translate, $timeout, spaceService, ngDialog) {
    var self = this;
    self.containerSize = 2;
    self.showSaveInElements = false;
    self.validation = false;
    self.isFirstInserction = true;


    $scope.$watch('$ctrl.moduleselected', function (model) {
        //console.log('watch moduleselected', model)
        updateSizePlanogram(self.planogram)
    }, true);

    $scope.$watch('$ctrl.showdoor', function (showdoor) {
        self.showDoor = self.showdoor
    })
    

    self.isPlanogramColumnVisible = function (currColumn) {

        if (self.moduleselected) {
            //si hay algun modulo seleccionado, solo renderiza la columna correspondiente
            var showColumn = false;
            angular.forEach(self.planogram.columns, function (column, _indexColumn) {
                angular.forEach(column.modules, function (module, _indexModule) {
                    if (module.isSelected && column.id === currColumn.id) {
                        showColumn = true;
                    }
                });

            });

            return showColumn;
        }

        return true;
    }

    function generateNewModule(module) {
        var newModule
        switch (module.type) {
            case 'Shelf':
                newModule = {
                    type: module.type,
                    dndId: 1,
                    width: 0,
                    depth: 0,
                    height: 0,
                    numLevels: 1,
                    order: 0,
                    levels: [],
                    divisions: 1,
                    isRefrigerator: module.isRefrigerator,
                    imageUrl: module.imageUrl,
                };
                break;
            case 'Peg':
                newModule = {
                    type: 'Peg',
                    dndId: 2,
                    width: 0,
                    depth: 0,
                    height: 0,
                    numLevels: 1,
                    levels: [],
                    divisions: 1,
                    isRefrigerator: true,
                    imageUrl: '/assets/images/spaces/templates/heladera.png'
                };
                break;
            default:
                newModule = {};
        }

        return newModule

    }

    self.addNewModule = function () {

        if (self.newModule.numLevels <= 0 || self.newModule.width <= 0 || self.newModule.height <= 0 || self.newModule.depth <= 0) {
            self.validation = true;
        }
        else {
            //Guada los cambios y blanquea los campos
            //self.newModule = generateNewModule();
            self.insertModule = true;
            ngDialog.close();
        }
    }

    self.insertInPlanogram = 1
    self.insertIn = function (where) {
        self.insertInPlanogram = where
    }


    self.templateDroppedInPlanogramColumn = function (index, newModule, external, type, column, planogram) {
        //self.planogram.module = module;
        self.insertModule = false;
        self.newModule = generateNewModule(newModule)
        ngDialog.open({
            template: 'modulesDialog',
            className: 'ngdialog-theme-default ngdialog-theme-custom',
            scope: $scope
        }).closePromise.then(function (data) {
            if (self.insertModule) {
                // select Item
                self.moduleselected = self.newModule;
                self.calHeightLevels(self.newModule);
                self.showelements = false;
                self.showSaveInElements = false;
                self.selectModule(self.newModule);
                self.templateInsertedInPlanogramColumn(index, self.newModule, external, type, column, planogram);

            }
            return true;
        });
    }

    self.templateInsertedInPlanogramColumn = function (index, module, external, type, column, planogram) {
        //logica donde insertar el nuevo modulo
        //Insert in the same Column = 1
        //Insert in new column on the left = 2
        //Insert in new column on the right = 3

        if (planogram.columns.length > 0)
            self.isFirstInserction = false;

        module.uniqueId = guid()

        var newColumn = {
            order: 1,
            width: 1,
            modules: []
        }
        switch (self.insertInPlanogram) {
            case 1:
                column.modules.splice(index, 0, module);
                self.insertInPlanogram = 3;
                break;
            case 2:
                planogram.columns.unshift(newColumn);
                newColumn.modules.push(module);
                break;
            case 3:
                planogram.columns.push(newColumn);
                newColumn.modules.push(module);
                break;
            default:
            //nothing
        }

        // if (!column.modules.lenght) module.order = 1;

        //asigno el order a column y a module
        angular.forEach(planogram.columns, function (_column, index) {
            _column.order = index + 1;
            angular.forEach(_column.modules, function (_module, index) {
                _module.order = index + 1;
            });
        })
        //$scope.$apply();
        updateSizePlanogram(planogram)
    }

    function calcWidthPercentage(planogram) {
        angular.forEach(planogram.columns, function (column) {
            //calculo el ancho de cada columna
            column.widthPercentage = column.width / planogram.width * 100;
            //calculo el ancho de cada modulo 
            angular.forEach(column.modules, function (_module) {
                _module.widthPercent = _module.width / column.width * 100;
            })
        })
    }

    function updateSizePlanogram(planogram) {
        planogram.width = 0;
        planogram.height = 0;
        planogram.depth = 0;
        angular.forEach(planogram.columns, function (column) {
            column.width = 0;
            column.height = 0;
            column.depth = 0;
            angular.forEach(column.modules, function (module) {
                if (module.width > column.width) column.width = module.width;
                column.height += module.height;
                if (module.depth > column.depth) column.depth = module.depth;
            })
            if (!column.width) column.width = 1;
            planogram.width += column.width;
            if (column.height > planogram.height) planogram.height = column.height;
            if (column.depth > planogram.depth) planogram.depth = column.depth;
        })

        calcWidthPercentage(planogram);
    }

    self.calHeightLevels = function (module) {
        module.levels = []
        var calHeightForLevel = module.height / module.numLevels
        for (var i = 0; i < module.numLevels; i++) {
            module.levels.push({
                categoryId: null,
                categoryName: "",
                height: calHeightForLevel,
                id: 0,
                itemPadding: 1,
                items: [],
                levelNumber: i + 1,
            })
        }
    }

    self.selectModule = function (element) {
        self.showelements = false;

        //Reseteo el isSelected
        angular.forEach(self.planogram.columns, function (column) {
            angular.forEach(column.modules, function (module) {
                module.isSelected = false;
            });
        });

        if (element != "") {
            if (element.type == 'Shelf' || element.type == 'Peg' || element.type == 'Refrigerator') {
                self.showdeletebutton = true;
                element.isSelected = true;
                self.showSaveInElements = false;
                self.moduleselected = element
            }
            else {
                self.showSaveInElements = true;
            }
        }

    }

    self.resizeContainer = function () {
        lenght = self.containerSize;
        if (lenght != null && lenght != undefined && lenght > 0) {
            if (!self.moduleselected) {
                self.planogram.module.columns = [];

                for (var i = 0; i < lenght; i++) {
                    self.planogram.module.columns.push({
                        order: 1,
                        width: 0,
                        childrenModules: [

                        ]
                    });
                }
            }
            else {
                self.moduleselected.columns = [];
                for (var i = 0; i < lenght; i++) {
                    self.moduleselected.columns.push({
                        order: 1,
                        width: 0,
                        childrenModules: [

                        ]
                    });
                }
            }
        }
    }

    self.addColumnToContainer = function (add) {
        if (!self.moduleselected) {
            add ? self.planogram.module.columns.push({
                order: 1,
                width: 0,
                childrenModules: [

                ]
            }) : self.planogram.module.columns.pop();
        } else {
            add ? self.moduleselected.columns.push({
                order: 1,
                width: 0,
                childrenModules: [

                ]
            }) : self.moduleselected.columns.pop();
        }
    }

    self.calcPlanogramHeightPx = function (planogram) {
        //if (!item.type && !item.dndId) {
        //    return 309
        //}
        var domElement = document.getElementById('planogram_' + planogram.id);
        var domElementSize = domElement.getBoundingClientRect()
        var height = planogram.height * domElementSize.width / planogram.width;

        return height;
    }

    self.calcWidthPx = function (container, item) {
        var domContainer = document.getElementById(container.type + '_' + container.dndId);
        var domContainerSize = domContainer.getBoundingClientRect()

        var width = item.width * domContainerSize.width / container.width
        return width;
    }

    function getWidth(component) {
        var totalWidth = 0;
        angular.forEach(component.columns, function (column) {
            var maxWidth = 0
            angular.forEach(column, function (row) {
                if (row.width > maxWidth) maxWidth = row.width;
            })
            totalWidth += maxWidth
        })
        component.width = totalWidth;
        return totalWidth
    }

    self.getClassForTemplate = function (item) {

        if ($scope.models != null && self.moduleselected != null && item != null && self.moduleselected.type == item.type && item.type == 'planogramPeg' || item.type == 'planogramShelf')
            return 'outlineSelectElement';
        else if ($scope.models != null && self.moduleselected != null && item != null && self.moduleselected.type == item.type && item.type == 'container') {
            return 'outline-selectElementContainer';
        }
    }


    function guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    self.until = {
        planograms: 1,
        columns: 2,
        modules: 3,
        levels: 4,
        items: 5
    }

    function loopThroughAll(callbackFunction, until) {
        return new Promise(function (resolve, reject) {
            if (self.until[until]) reject('err - argument until is difened as: ' + until)
            angular.forEach(self.planogram.columns, function (column) {
                if (until == self.until.columns) return callbackFunction(self.planogram, column);
                angular.forEach(column.modules, function (module) {
                    if (until == self.until.modules) return callbackFunction(self.planogram, column, module);
                    angular.forEach(module.levels, function (level) {
                        if (until == self.until.levels) return callbackFunction(self.planogram, column, module, level);
                        angular.forEach(level.items, function (item) {
                            if (until == self.until.items) return callbackFunction(self.planogram, column, module, level, item);
                        });
                    });
                })
            });
            resolve('success');
        });

    }

    var translations = null;

    function init() {

        $translate(["InsertInTheSameColumn", "InsertInNewColumnOnLeft", "InsertInNewColumnOnRight"])
            .then(function (all) {
                translations = all;
                self.modulePositionOptions = [
                    //{ name: translations.InsertInTheSameColumn, valor: 1 },
                    { name: translations.InsertInNewColumnOnLeft, valor: 2 },
                    { name: translations.InsertInNewColumnOnRight, valor: 3 }
                ];

                updateSizePlanogram(self.planogram)
            })

        if (self.planogram.id != 0)
            self.isFirstInserction = false;

    }

    $timeout(function () {
        init();
    }, 25);

    
}