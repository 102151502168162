'use strinct';
(function () {
    angular.module('prisma')
        .directive('ianHold', ['$log', '$parse', '$timeout', ianHoldDirective]);

    /**
     * Directiva ianHold al mantener un elemento seleccionado por X tiempo de dispara un evento.
     * @param {any} $log Servicio de log de angular.
     * @param {any} $parse Servicio de parse de angular.
	 * @param {any} $timeout Servicio de timeout de angular.	 
     */
    function ianHoldDirective($log, $parse, $timeout) {
        // $log.debug('ianHoldDirective::ctor');
        var uid = 0;
        var duid = 0;

        function linkFn(scope, $element, $attr) {
            var localUId = ++duid;
            var methodName = 'ianHoldDirective::link';

            // $log.debug(methodName);
            $element.data('duid', localUId);

            var durationGetter;
            if ($attr.ianHoldDuration) {
                durationGetter = $parse($attr.ianHoldDuration);
            } else {
                durationGetter = function(){
					var ret = 600;
					return ret;
				};
            }
            
            var raiseOnHold;
            if ($attr.ianHold) {
                raiseOnHold = $parse($attr.ianHold);
            } else {
                raiseOnHold = angular.noop;
            }

            var enableGetter;
            if ($attr.ianHoldEnable) {
                enableGetter = $parse($attr.ianHoldEnable);

                $attr.$observe('ianHoldEnable',
                    function () {
                        var enable = enableGetter(scope);
                        if (enable) {
                            enableEvents(true);
                        } else {
                            enableEvents(false);
                        }
                    });
            } else {
                enableGetter = function () {
                    return true;
                };
            }
			
			var timer = null;
			
			function onGlobaEndHandler($event){
				$event.preventDefault();
				$event.stopPropagation();
				$timeout.cancel(timer);
			}
			
			function onGlobaMousedownHandler($event){
				var enable = enableGetter(scope);
				if(enable) {
					var duration = durationGetter(scope);
					$element
							.on('mouseup mouseleave touchend', onGlobaEndHandler);
					$timeout.cancel(timer);
					timer = $timeout(duration).then(function(){
						raiseOnHold(scope, { $event : $event });
					});
				}
			}

            function enableEvents(enable) {
                $element
                    .off('mousedown touchstart mouseup touchend mouseleave mousemove');
                if (enable) {
                    $element
                        .on('mousedown touchstart', onGlobaMousedownHandler);
                }
            }

            var enable = enableGetter(scope);
            enableEvents(enable);

            scope.$on('$destroy',
                function $destroy() {
					enableEvents(false);
                });
        }

        var ret = {
            restrict: 'A',
            link: linkFn
        };
        return ret;
    }
})();