angular.module("prisma")
 .controller("supplyingCtrl", function ($scope, $rootScope, $state, $stateParams, promotionsService, serverService, Upload, authService, $timeout, ngDialog, $filter, $window) {

     var self = this;

     self.task = null;
     self.link = '';
     self.linkStore = '';
     self.linkItem = '';

     self.canPublishSuplying = authService.hasPermission('promotion_publish_Abastecimiento');

     self.publish = function () {
         if ($stateParams.taskId) {
             promotionsService.tasks.publishTask($stateParams.taskId)
                 .then(function (isOk) {
                     swal('Tarea Publicada!', 'La tarea se ha publicado exitosamente', 'success');

                     $state.go('promotions.myTasks');
                 });
         } else {
             $timeout(function () {
                 swal("Error", "no se pudo publicar la tarea", "error");
             }, 100);
         }
     }

     self.openDownloadDialog = function()
     {
         ngDialog.open({
             template: 'downloadDialog',
             className: 'ngdialog-theme-default',
             scope: $scope
         });
     }

     self.generateAndDownloadItems = function () {
         self.isLoading = true;
         var promotionId = (self.task && self.task.promotionId) ? self.task.promotionId : self.selectedPromotion.id;

         promotionsService.reports.buildItemTasksReport(promotionId)

             .then(function (link) {
                 self.linkItem = link;
                 self.showSwalWithLink(link);
                 self.isLoading = false;
             });


     }

     self.generateAndDownloadStores = function () {
         self.isLoading = true;
         var promotionId = (self.task && self.task.promotionId) ? self.task.promotionId : self.selectedPromotion.id;
         promotionsService.reports.buildItemStoreReport(promotionId)
                 .then(function (link) {
                     self.linkStore = link;
                     self.showSwalWithLink(link);
                     self.isLoading = false;
                 });
     }

     self.showSwalWithLink = function (link) {
         swal({
             title: "Reporte Generado!",
             text: 'Haga click <a href="' + link + '"><u style = "color: #1AB394;"><b style = "color: #1AB394;">AQUÍ</b></u></a> para descargar el archivo',
             confirmButtonText: "Cerrar",
             confirmButtonColor: "#c2c2c2",
             html: true
         });
     }

     function ExportToExcel(tableHtml, worksheetName) {
         var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

         var ctx = { worksheet: worksheetName, table: tableHtml },
             href = uri + base64(format(template, ctx));
         return href;
     }

     self.batchLoad = function () {

         self.currentStep = 1;
         self.gridOptions = {
             columnDefs: [
             {
                 headerName: 'Severidad',
                 width: 100,
                 pinned: 'left',
                 field: 'severity',
                 suppressMenu: true,
                 suppressSorting: true,
                 filter: false,
                 rowGroupIndex: 0,
                 cellRenderer: function (params) {
                     var severityName = '';
                     switch (params.value) {
                         case 'Information': { severityName = '<i style="color: #6aa4ff;" class="fa fa-info-circle" aria-hidden="true"></i> Info'; break; }
                         case 'Warning': { severityName = '<i style="color: #e4e400;" class="fa fa-stop-circle" aria-hidden="true"></i> Warn'; break; }
                         case 'Error': { severityName = '<i style="color: #ea2323;" class="fa fa-exclamation-circle" aria-hidden="true"></i> Error'; break; }
                     }
                     return severityName;
                 }
             },
             {
                 headerName: 'Fila',
                 width: 50,
                 pinned: 'left',
                 field: 'row',
                 suppressMenu: true,
                 suppressSorting: true,
                 filter: false,
             },
             {
                 headerName: 'Categoria',
                 width: 150,
                 rowGroupIndex: 1,
                 pinned: 'left',
                 field: 'category',
                 suppressMenu: true,
                 suppressSorting: true,
                 filter: false,
             },
             {
                 headerName: 'Descripcion',
                 width: 350,
                 pinned: 'left',
                 field: 'description',
                 suppressMenu: true,
                 suppressSorting: true,
                 filter: false,
             },
             ],
             enableColResize: true,
             enableSorting: true,
             enableFilter: false,
             rowHeight: 35,
             suppressHorizontalScroll: false,
             suppressCellSelection: true,
             groupUseEntireRow: true,
             groupSuppressAutoColumn: false,
             groupDefaultExpanded: 0,
             icons: {
                 columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                 filter: '<i class="fa fa-filter"/>',
                 sortAscending: '<i class="fa fa-long-arrow-down"/>',
                 sortDescending: '<i class="fa fa-long-arrow-up"/>',
                 groupExpanded: '<i class="fa fa-minus-square-o"/>',
                 groupContracted: '<i class="fa fa-plus-square-o"/>',
                 columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                 columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
             },
             angularCompileRows: true,
         }

         self.showNextStep = function () {
             if (self.currentStep > 0 && self.currentStep < 4) {
                 self.currentStep++;
                 loadResults([]);
             }
         }

         self.downloadExcel = function () {
             var html = '<style>.text{mso-number-format:"\@";/*force text*/}</style><table width="100%"><thead><tr>';

             //header
             html += '<th>Promocion</th>';
             html += '<th>Pagina</th>';
             html += '<th>Posicion</th>';
             html += '<th>Descripcion</th>';
             html += '<th>Material</th>';
             html += '<th>UM</th>';
             html += '<th>Comentarios Anteriores</th>';
             html += '<th>Nuevos Comentarios</th>';

             angular.forEach($filter('orderBy')(self.promotion.pages), function (page) {
                 angular.forEach(page.items, function (item) {
                     if (item.promotionItemType < 4) {//reservados y relacionados no
                         var chats = "";
                         angular.forEach(page.chats, function (chat) {
                             if (item.id == chat.promotionItemId)
                                 chats += chat.message + ' |';
                         });
                         html += buildRow(item, page, chats);
                     }
                });               
                 
             });

             html += '</tbody></table>';

             var exportHref = ExportToExcel(html, 'Pagina1');
             $timeout(function () {
                 location.href = exportHref;
                 self.showNextStep();
             }, 100);
         }

         function buildRow(item, page, chats) {

             var html = '';

             if (item != null) {
                 var code = item.code != null ? item.code.replace(/^0+/, '') : '';
                 var uom = item.uom != null ? item.uom : '';
                 html += '<tr><td>' + self.promotion.name +
                       '</td><td>' + page.pageNumber +
                       '</td><td>' + item.position +
                       '</td><td class="text">' + item.description +
                       '</td><td>' + code +
                       '</td><td>' + uom +
                       '</td><td class="text">' + chats +
                       '</td><td class="text">' + '' +               
                       '</td></tr>';
             }
             return html;
         }

         self.uploadFile = function (files) {
             if (files && files.length > 0) {
                 self.isBusy = true;
                 var url = serverService.getApiUrl() + '/promotion/items/uploadChatsBatchFile';
                 Upload.upload({
                     url: url,
                     data: { file: files[0] }
                 }).then(function (response) {
                     self.guid = response.data;
                     self.isBusy = false;
                     self.finishedUpload = true;
                 });
             }
         }

         self.validateUploadedFile = function () {
             promotionsService.items.validateChatsBatchFile(self.guid, $stateParams.promotionId)
                    .then(function (dto) {
                        processImporterResults(dto);
                        if (!anyError(dto)) {
                            self.finishedValidation = true;
                        }
                    });

         }

         self.reUploadFile = function () {
             self.currentStep = 2;
             loadResults([]);
             self.files = null;
             self.finishedUpload = false;
             self.finishedValidation = false;
             self.finishedUpdate = false;
         }

         self.updateItems = function () {
             promotionsService.items.updateChatsInBatch(self.guid, $stateParams.promotionId)
                    .then(function (dto) {
                        processImporterResults(dto);
                        if (!anyError(dto)) {
                            self.finishedUpdate = true;
                        }
                    });

         }

         self.finish = function () {
             self.currentStep = 1;
             loadResults([]);

             self.finishedUpload = false;
             self.finishedValidation = false;
             self.finishedUpdate = false;

             ngDialog.close();

             loadPromotion();
         }

         function processImporterResults(importerResults) {
             self.isBusy = false;
             self.isBusy2 = false;
             self.isBusy3 = false;
             self.isBusy4 = false;

             if (importerResults.length > 0) {
                 loadResults(importerResults);
             }
             if (anyError(importerResults)) {
                 self.error = true;
             }
             else
                 self.error = false;
         }

         function loadResults(results) {
             self.gridOptions.api.setRowData(results);
             self.gridOptions.api.refreshView();

             self.gridVisible = results.length > 0 ? true : false;
         }

         function anyError(importerResults) {
             var error = false;
             for (var i = 0; i < importerResults.length; i++) {
                 if (importerResults[i].severity === 'Error')
                     error = true;
             }

             return error;
         }

         ngDialog.open({
             template: 'batchLoadDialog',
             className: 'ngdialog-theme-default ngdialog-theme-custom',
             controller: function () {
                 return self;
             },
             controllerAs: 'ct',
         });
     }

     function init() {
         promotionsService.tasks.startTask($stateParams.taskId);
         promotionsService.tasks.getTask($stateParams.taskId)
         .then(function (task) {
                     self.task = task;
         });

         promotionsService.promotions.getPromotionPagesItems($stateParams.promotionId)
         .then(function (promotion) {
             self.promotion = promotion;
         });
         
     }

     init();
 });