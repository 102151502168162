"use strict";

angular.module("prisma")
  .controller("BcgMatrixCtrl", function ($scope, homeService) {
      var self = this;
      self.section = "Inicio";
      self.name = "Matriz BCG";

      // Various accessors that specify the four dimensions of data to visualize.
      function x(d) { return d.marginP; }
      function y(d) { return d.sales; }
      function radius(d) { return d.units; }
      function color(d) { return d.category; }
      function key(d) { return d.subCategory; }

      var margin = { top: 30, right: 20, bottom: 30, left: 80 },
        width = $("div").parent().width() - margin.right - margin.left,
        height = 400 - margin.top - margin.bottom;

      // Create the SVG container and set the origin.
      var svg = d3.select("#bgcmatrixchart").append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
        .append("g")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
          .attr("class", "gRoot");

      // Add the year label; the value is set on transition.
      var label = svg.append("text")
          .attr("class", "year label")
          .attr("text-anchor", "end")
          .attr("y", 0)
          .attr("x", width - 300)
          .text("Cargando...");

      // Add the country label; the value is set on transition.
      var countrylabel = svg.append("text")
          .attr("class", "country label")
          .attr("text-anchor", "end")
          .attr("y", 40)
          .attr("x", width - 300)
          .text(" ");

      var monthNames = [
"Enero", "Febrero", "Marzo",
"Abril", "Mayo", "Junio", "Julio",
"Agosto", "Septiembre", "Octubre",
"Noviembre", "Diciembre"
      ];

      var first_time = true;
      var lastMonth = 0;

      function loadData() {
          homeService.categoryBCGMatrix.getCategoryBCGMatrix()
              .then(function (root) {

                  var data = root.children;

                  // Various scales. These domains make assumptions of data, naturally.
                  var xScale = d3.scale.linear().domain([0, 100]).range([0, width]),
                      yScale = d3.scale.linear().domain([0, root.maxSales]).range([height, 0]),
                      radiusScale = d3.scale.sqrt().domain([0, root.maxUnits]).range([0, 70]),
                      colorScale = d3.scale.category20b();

                  // The x & y axes.
                  var xAxis = d3.svg.axis().orient("bottom").scale(xScale).ticks(12, d3.format(",d")),
                      yAxis = d3.svg.axis().scale(yScale).orient("left");

                  // Add the x-axis.
                  svg.append("g")
                      .attr("class", "x axis")
                      .attr("transform", "translate(0," + height + ")")
                      .call(xAxis);

                  // Add the y-axis.
                  svg.append("g")
                      .attr("class", "y axis")
                      .call(yAxis);

                  // Add an x-axis label.
                  svg.append("text")
                      .attr("class", "x label")
                      .attr("text-anchor", "end")
                      .attr("x", width)
                      .attr("y", height - 6)
                      .text("margen $ (total red)");

                  // Add a y-axis label.
                  svg.append("text")
                      .attr("class", "y label")
                      .attr("text-anchor", "end")
                      .attr("y", 6)
                      .attr("dy", ".75em")
                      .attr("transform", "rotate(-90)")
                      .text("venta en $ (total red)");


                  // A bisector since many nation's data is sparsely-defined.
                  var bisect = d3.bisector(function (d) { return d[0]; });

                  // Add a dot per nation. Initialize the data at 1800, and set the colors.
                  var dot = svg.append("g")
                      .attr("class", "dots")
                    .selectAll(".dot")
                      .data(interpolateData(0))
                    .enter().append("circle")
                      .attr("class", "dot")
                      .style("fill", function (d) { return colorScale(color(d)); })
                      .call(position)
                      .on("mousedow", function (d, i) {

                      })
                      .on("mouseup", function (d, i) {
                          dot.classed("selected", false);
                          d3.select(this).classed("selected", !d3.select(this).classed("selected"));
                          dragit.trajectory.display(d, i, "selected");

                          //TODO: test if has been dragged
                          // Look at the state machine history and find a drag event in it?

                      })
                      .on("mouseenter", function (d, i) {
                          clear_demo();
                          if (dragit.statemachine.current_state == "idle") {
                              dragit.trajectory.display(d, i)
                              dragit.utils.animateTrajectory(dragit.trajectory.display(d, i), dragit.time.current, 1000)
                              countrylabel.text(d.name);
                              dot.style("opacity", .4)
                              d3.select(this).style("opacity", 1)
                              d3.selectAll(".selected").style("opacity", 1)
                          }
                      })
                      .on("mouseleave", function (d, i) {

                          if (dragit.statemachine.current_state == "idle") {
                              countrylabel.text("");
                              dot.style("opacity", 1);
                          }

                          dragit.trajectory.remove(d, i);
                      })
                      .call(dragit.object.activate)

                  // Add a title.
                  dot.append("title")
                      .text(function (d) { return d.name; });

                  // Start a transition that interpolates the data based on year.
                  svg.transition()
                      .duration(30000)
                      .ease("linear");

                  displayYear(0);

                  var sliderValues = new Array();

                  for (var i = 0; i < root.months; i++) {
                      sliderValues.push(getMonthLabel(i));
                  }
                  //Slider
                  $("#ionrange_4").ionRangeSlider({
                      values: sliderValues,
                      type: 'single',
                      hasGrid: true,
                      onChange: function (data) {
                          clear_demo();
                          updateSlider(data.from, 500);
                      },
                      onFinish: function (data) {
                          clear_demo();
                          updateSlider(data.from, 500);
                      },
                      onUpdate: function (data) {
                          clear_demo();
                          updateSlider(data.from, 500);
                      },
                  });

                  var slider = $("#ionrange_4").data("ionRangeSlider");

                  function getMonthLabel(month) {
                      var minDate = new Date(root.minDate);
                      var utcDate = new Date(minDate.getUTCFullYear(), minDate.getUTCMonth(), minDate.getUTCDate());
                      var date = new Date(new Date(utcDate).setMonth(utcDate.getMonth() + month));
                      return monthNames[date.getMonth()] + ' ' + date.getFullYear();
                  }

                  // Positions the dots based on data.
                  function position(dot) {
                      dot.attr("cx", function (d) { return xScale(x(d)); })
                         .attr("cy", function (d) { return yScale(y(d)); })
                         .attr("r", function (d) { return radiusScale(radius(d)); });
                  }

                  // Defines a sort order so that the smallest dots are drawn on top.
                  function order(a, b) {
                      return radius(b) - radius(a);
                  }

                  // Updates the display to show the specified year.
                  function displayYear(month) {
                      dot.data(interpolateData(month), key).call(position).sort(order);
                      label.text(getMonthLabel(month));

                  }

                  // Interpolates the dataset for the given (fractional) year.
                  function interpolateData(month) {
                      return data.map(function (d) {
                          return {
                              name: d.subCategory,
                              category: d.category,
                              sales: interpolateValues(d.sales, month),
                              units: interpolateValues(d.units, month),
                              marginP: interpolateValues(d.marginP, month)
                          };
                      });
                  }

                  // Finds (and possibly interpolates) the value for the specified year.
                  function interpolateValues(values, month) {
                      var i = bisect.left(values, month, 0, values.length - 1),
                          a = values[i];

                      return a[1];
                  }

                  init();

                  function update(v, duration) {
                      slider.update({
                          from: v
                      });
                  }

                  function updateSlider(v, duration) {
                      if (v == lastMonth) return;

                      dragit.time.current = v;
                      displayYear(dragit.time.current);
                      lastMonth = v;
                  }

                  function init() {

                      dragit.init(".gRoot");

                      dragit.time = { min: 0, max: root.months, step: 1, current: 0 }
                      dragit.data = d3.range(data.length).map(function () { return Array(); })

                      for (var mm = 0; mm < root.months; mm++) {

                          interpolateData(mm).filter(function (d, i) {

                              dragit.data[i][mm - dragit.time.min] = [xScale(x(d)), yScale(y(d))];

                          })
                      }

                      dragit.evt.register("update", update);

                      var end_effect = function () {
                          countrylabel.text("");
                          dot.style("opacity", 1)
                      }

                      dragit.evt.register("dragend", end_effect)
                  }

                  function clear_demo() {
                      if (first_time) {
                          svg.transition().duration(0);
                          first_time = false;
                          window.clearInterval(demo_interval);
                          countrylabel.text("");
                          dragit.trajectory.removeAll();
                          d3.selectAll(".dot").style("opacity", 1)
                      }
                  }
                  var demoCount = 0;
                  function play_demo() {

                      if (data.length == 0) return;

                      if (demoCount >= root.months) return;
                      //Muevo el slider
                      // Change slider, by calling it's update method
                      slider.update({
                          from: demoCount
                      });

                      demoCount++;
                  }
                  var demo_interval = null;

                  setTimeout(function () {
                      if (first_time) {
                          play_demo();
                          demo_interval = setInterval(play_demo, 1000);
                      }
                  }, 1000);
              });
      }

      function init() {
          //load data
          loadData();
      }

      init();
  });

