angular.module("prisma")
    .controller("EditPricelistSubsidiesCtrl", function ($scope, $state, $stateParams, priceService, adminService, $timeout, $translate) {

     //Variable globales
     var self = this;
     self.selectedPriceList = '';
     self.priceLists = [];
     self.items = [];

     self.priceListSubsidy = {
         id: 0,
         priceListCode : '',
         priceListId : 0,
         itemId : 0,
         itemCode : '',
         truckCost : 0,
         subsidy: 0,
         isExcluded: false
     }

     self.item = {
         id: 0,
         code: '',
         name: ''
     };

     self.itemSelected = function () {
         angular.forEach(self.items, function (item) {
             if (item.id == self.priceListSubsidy.itemId) {
                 console.log(item);
                 self.priceListSubsidy.itemCode = item.code;
             }
         });
     }

     self.priceListSelected = function () {
         angular.forEach(self.priceLists, function (item) {
             if (item.id == self.priceListSubsidy.priceListId) {
                 console.log(item);
                 self.priceListSubsidy.priceListId = item.id;
                 self.priceListSubsidy.priceListCode = item.code;
             }
         });
     }

     self.save = function () {
         var title = translations.SaveSubsidy;
         swal({
             title: title,
             text: '',
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: translations.Save,
             cancelButtonText: translations.Cancel,
             showLoaderOnConfirm: true,
             closeOnConfirm: false,
             closeOnCancel: true
         },
             function (isConfirm) {
                 if (isConfirm) {
                     priceService.pricelistsSubsidies.savePricelistSubsidy(self.priceListSubsidy)
                         .then(function (status) {
                             swal(translations.SavedSubsidy, translations.SavedSubsidySubtitle, 'success');

                             $state.go('prices.priceListsSubsidies', {reload: true});
                         },
                         function () {
                             swal(translations.ErrorTitleSwal, translations.ErrorToSaveSubsidy, 'error');

                         });
                 }
             });
     }

     function getPricelistSubsidy(id) {

         priceService.pricelistsSubsidies.getPricelistSubsidy(id)
             .then(function (pl) {
                 self.priceListSubsidy = pl;
             });
     }

     function getItems() {
         priceService.costs.getItems()
             .then(function (items) {
                 self.items = items;
             });
     }

     function getPriceLists() {
         priceService.pricelists.getPriceLists()
             .then(function (priceLists) {
                 self.priceLists = priceLists;
             })
     }

     var translations = null;

     function init() {

         $translate(["SaveSubsidy", "Save", "Cancel", "SavedSubsidy", "SavedSubsidySubtitle", "ErrorTitleSwal", "ErrorToSaveSubsidy"])
             .then(function (all) {
                 translations = all;
             });


         getItems();
         getPriceLists();

         var priceListSubsidyId = $stateParams.priceListSubsidyId;
         if (priceListSubsidyId != 0)
             getPricelistSubsidy(priceListSubsidyId);
     }

     init();

 });




