angular.module("prisma")
    .filter('startFrom', function () {
        return function (input, start) {
            start = +start; //parse to int
            if (input != null) {
                return input.slice(start);
            }
        }
    })
    .controller("reportsCtrl", function ($scope, $rootScope, $state, $stateParams, promotionsService, homeService, adminService, serverService, Upload, authService, $timeout, ngDialog, $filter, $window) {

        var self = this;

        self.totalFilteredPromotions = 1;
        self.pageSize = 200;
        self.pageNumber = 0;
        self.isLoading = false;
        self.fromDate = null;
        self.toDate = null;
        self.date = null;
        self.promotionIds = "";
        self.promotionName = "";
        self.promotionCreationUsers = "";
        self.materialCodes = "";
        self.report = 4;
        self.promotionType = 0;
        self.promotionMediaTypes = promotionsService.promotionMediaTypes;
        self.promotions = null;
        self.selectedPromotionMediaType = null;
        self.selectedIdsForPromotionReport = [];
        self.selectedBrand = null;
        self.allPromotionsForReport = false;

        //CATEGORIES
        self.categoryAreas = [];
        self.categoryDepartments = [];
        self.categorySections = [];
        self.categories = [];
        self.subCategories = [];
        self.articleGroups = [];
        self.selectedCategoryArea = null;
        self.selectedCategoryDepartment = null;
        self.selectedCategorySection = null;
        self.selectedCategory = null;
        self.selectedSubCategory = null;
        self.selectedArticleGroup = null;
        self.isLoadingCategoryTree = true;


        self.allReports = [
            { id: 1, name: "Modificación" },
            { id: 2, name: "Promociones masivas" },
            { id: 3, name: "Promociones detalles" },
            { id: 4, name: "Detalle marketing" },
            { id: 5, name: "Promociones vigentes"}
        ];
        self.promotionTypes = [
            { id: 0, name: "Ambas" },
            { id: 1, name: "Externa" },
            { id: 2, name: "Interna" },
        ];
        self.brands = [
            { id: 1, name: "Wong" },
            { id: 2, name: "Metro" },
        ];

        //STORE LOOKUP
        self.storeLookupText = '';
        self.storesInLookup = [];
        self.selectedStore = null;
        self.loadingLookupStores = false;
        self.lookupStoresNoResults = true;
        var previousLookupText = '';

        $scope.$watch('ct.storeLookupText', function (newVal, oldVal) {
            if (newVal) {

                if (newVal.id) {
                    self.selectedStore = self.storeLookupText;
                }

                if (newVal && newVal != oldVal && (newVal.length >= 3 || (oldVal.length < 3 && newVal.length >= 3)) && newVal != previousLookupText) {
                    previousLookupText = newVal;

                    self.storesInLookup = [{ id: 0, code: '', name: 'Cargando Tiendas con el texto ' + newVal + '...' }];
                    self.loadingLookupStores = true;
                    var lookupText = { SearchTerm: newVal };

                    homeService.lookups.storesLookup(lookupText)
                        .then(function (stores) {
                            self.storesInLookup = stores;
                            self.loadingLookupStores = false;
                            if (stores.length > 0)
                                self.lookupStoresNoResults = false;
                            else
                                self.lookupStoresNoResults = true;
                        });
                }
            }

        }, true);

        self.deleteAll = function (deleteReport, deletePromotions) {
            if (self.report == 5) {
                self.date = self.getNextCurrentDay();
            } 
            else {
                self.date = null;
            }
            self.fromDate = null;
            self.toDate = null;
            self.promotionIds = "";
            self.promotionName = "";
            self.promotionCreationUsers = "";
            self.materialCodes = "";
            if (deleteReport) {
                self.report = null;
            }
            if (deletePromotions) {
                self.promotions = "";
            }
            self.promotionType = 0;
            self.selectedPromotionMediaType = null;
            self.allPromotionsForReport = false;
            self.selectedIdsForPromotionReport = [];
            self.storeLookupText = '';
            self.selectedStore = null;
            self.selectedBrand = null;
            self.selectedCategoryArea = null;
            self.selectedCategoryDepartment = null;
            self.selectedCategorySection = null;
            self.selectedCategory = null;
            self.selectedSubCategory = null;
            self.selectedArticleGroup = null;
        }

        self.clearResult = function () {
            self.promotions = null;
            self.allPromotionsForReport = false;
            self.selectedIdsForPromotionReport = [];
        }

        self.run = function () {
            if (self.fromDate || self.date || self.promotionIds.length) {
                if (self.date || self.promotionIds.length || self.maximumDateRange(self.fromDate, self.toDate, 90)) {
                    self.clearResult();
                    self.isLoading = true;
                    var selectedpromotionMediaTypeId = 0;
                    if (self.selectedPromotionMediaType != null) {
                        selectedpromotionMediaTypeId = self.selectedPromotionMediaType.id;
                    }
                    var filters = {
                        ids: self.promotionIds,
                        name: self.promotionName,
                        creationUsers: self.promotionCreationUsers,
                        materialCodes: self.materialCodes,
                        promotionType: self.promotionType,
                        promotionMediaType: selectedpromotionMediaTypeId,
                        validFrom: self.fromDate,
                        validTo: self.toDate,
                        date: self.date,
                        brandId: self.selectedBrand ? self.selectedBrand : 0,
                        storeCode: self.selectedStore ? self.selectedStore.code : '',
                        subCategoryId: self.selectedSubCategory ? self.selectedSubCategory.id : null,
                        articleGroupId: self.selectedArticleGroup ? self.selectedArticleGroup.id : null
                    }
                    promotionsService.promotions.getPromotionsByReportFilters(filters)
                        .then(function (promotions) {
                            self.promotions = promotions;
                            self.totalFilteredPromotions = self.promotions.length;
                            self.isLoading = false;
                            angular.forEach(self.promotions, function (p) {
                                p.isChecked = false;
                            });
                            console.log(self.promotions);
                        });
                }
                else {
                    swal("Error", "El rango maximo de fecha es de 90 dias", "error");
                }
            }
            else {
                swal("Error", "Debe ingresar al menos una fecha desde o un id.", "error");
            }
        }

        self.setPromotionForReport = function (promotion) {
            if (promotion.isChecked && !self.selectedIdsForPromotionReport.includes(promotion.id)) {
                self.selectedIdsForPromotionReport.push(promotion.id);
            }
            else if (!promotion.isChecked && self.selectedIdsForPromotionReport.includes(promotion.id)) {
                var index = self.selectedIdsForPromotionReport.indexOf(promotion.id);
                if (index > -1) {
                    self.selectedIdsForPromotionReport.splice(index, 1);
                }
            }
        }

        self.maximumDateRange = function (validFrom, validTo, rangeDays) {
            var rv = true;
            var maximumDate = self.addDays(validFrom, rangeDays);
            if (validTo == null) {
                validTo = new Date();
            }
            if (validTo > maximumDate) {
                rv = false
            }
            return rv;
        }

        self.addDays = function (date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        }

        self.subtractDays = function (date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() - days);
            return result;
        }

        self.getNextCurrentDay = function () {
            var result = new Date();
            result.setDate(result.getDate() + 1);
            return result;
        }

        self.isValidDateRange = function () {
            var result = true;
            var today = new Date();
            var maximumDate = self.addDays(today, 7);
            var minimumDate = self.subtractDays(today, 7);
            if (self.date > maximumDate || self.date < minimumDate) {
                result = false
            }
            if (!result) {
                self.date = today;
                swal("Error", "El rango de fecha para este reporte es de 1 semana.", "error");
            }
        }

        self.next = function () {
            if (self.allPromotionsForReport) {
                self.allPromotionsForReport = false;
                angular.forEach(self.promotions, function (p) {
                    p.isChecked = false;
                });
            }
            $timeout(function () {
                if (self.pageNumber + 1 < self.numberOfPages())
                    self.pageNumber++;
            }, 0)
        }

        self.previous = function () {
            if (self.allPromotionsForReport) {
                self.allPromotionsForReport = false;
                angular.forEach(self.promotions, function (p) {
                    p.isChecked = false;
                });
            }
            $timeout(function () {
                if (self.pageNumber > 0)
                    self.pageNumber--;
            }, 0)
        }

        self.numberOfPages = function () {
            return Math.ceil(self.totalFilteredPromotions / self.pageSize);
        }

        self.setAllPromotionsForReport = function () {
            var promotionIds = [];
            var promotions = [];
            var rows = document.getElementsByTagName("table")[0].rows;

            angular.forEach(rows, function (r) {
                var cell = r.cells[2];
                var promotionId = cell.innerHTML;
                promotionIds.push(promotionId);
            });

            promotionIds.shift(); // Elimino el header ID

            angular.forEach(self.promotions, function (p) {
                angular.forEach(promotionIds, function (id) {
                    if (p.id == parseInt(id)) {
                        promotions.push(p);
                    }
                });
            });
         
            var doSelect = undefined;
            if (self.allPromotionsForReport) {
                doSelect = true;
            }
            else {
                doSelect = false;
            }
                angular.forEach(promotions, function (promotion) {
                    promotion.isChecked = doSelect;
                    self.setPromotionForReport(promotion);
            });
        }

        self.generateReport = function () {
            var limit = companyJson.Promotions && companyJson.Promotions.PromotionReportLimit ?
                companyJson.Promotions.PromotionReportLimit : 1000;

            if (self.selectedIdsForPromotionReport.length > 0 && self.report != null) {
                if (self.report === 3 && self.selectedIdsForPromotionReport.length > limit) {
                    swal("Error", "No es posible generar el reporte 'Promociones detalle' para mas de " + limit + " ids.", "error");
                    return;
                }
                self.isLoading = true;
                promotionsService.reports.buildPromotionReport({ report: self.report, promotionIds: self.selectedIdsForPromotionReport })
                    .then(function (link) {
                        self.isLoading = false;
                        window.open(link);
                    });
            }
            else {
                swal("Error", "Debe seleccionar al menos un id del listado de promociones y un tipo de reporte.", "error");
            }   
        }

        self.getCategoryTree = function () {
            adminService.categories.getCategoryTree()
                .then(function (categoryTree) {
                    self.categoryTree = categoryTree;
                    self.categoryAreas = categoryTree.filter(function (x) { return x.level == 1 });
                    self.categoryDepartments = categoryTree.filter(function (x) { return x.level == 2 });
                    self.categorySections = categoryTree.filter(function (x) { return x.level == 3 });
                    self.categories = categoryTree.filter(function (x) { return x.level == 4 });
                    self.subCategories = categoryTree.filter(function (x) { return x.level == 5 });
                    self.articleGroups = categoryTree.filter(function (x) { return x.level == 6 });
                    self.isLoadingCategoryTree = false;
                });
        }

        self.setDepartments = function () {
            self.categoryDepartments = self.categoryTree.filter(function (x) { return x.level == 2 && self.selectedCategoryArea && self.selectedCategoryArea.id && x.parentId == self.selectedCategoryArea.id });
        } 
        self.setSections = function () {
            self.categorySections = self.categoryTree.filter(function (x) { return x.level == 3 && self.selectedCategoryDepartment && self.selectedCategoryDepartment.id && x.parentId == self.selectedCategoryDepartment.id });
        } 
        self.setCategories = function () {
            self.categories = self.categoryTree.filter(function (x) { return x.level == 4 && self.selectedCategorySection && self.selectedCategorySection.id && x.parentId == self.selectedCategorySection.id });
        } 
        self.setSubCategories = function () {
            self.subCategories = self.categoryTree.filter(function (x) { return x.level == 5 && self.selectedCategory && self.selectedCategory.id && x.parentId == self.selectedCategory.id });
        } 
        self.setArticleGroups = function () {
            self.articleGroups = self.categoryTree.filter(function (x) { return x.level == 6 && self.selectedSubCategory && self.selectedSubCategory.id && x.parentId == self.selectedSubCategory.id });
        } 

        function init() {
            self.getCategoryTree();
        }

        init();

      
    });