angular.module("prisma")
    .controller("ItemSensitivitiesCtrl", function ($scope, priceService, $translate) {
     var self = this;

     self.itemSensivilities = [];

     self.isLoading = true;

     function loadData() {

         priceService.itemSensitivities.getItemSensitivities() //Servicio (que esta mencionado ariba en el controller), Nombre del objeto ue tiene el srv que queres usar (ItemSensivilities), Despues el Metodo que queres llamar, pasando los parametros q sean necesarios.
            .then(function (sensivilities) { //Entre parentesis, esta lo que devuelve el servicio (En este caso, un listado de itemSensivilities)
                self.itemSensivilities = sensivilities;

                self.isLoading = false;
            });
     }

     self.devolverTextual = function (dias) {
         if (dias == 0)
             return translations.Never;
         else if (dias == 1)
             return translations.Daily;
         else if (dias == 7)
             return translations.Weekly;
         else if (dias == 15)
             return translations.Biweekly;
         else if (dias == 30)
             return translations.Monthly;
         else if (dias == 30)
             return translations.Bimonthly;
         else if (dias == 30)
             return translations.Quarterly;
         else
             return dias + translations.Days1;
     }

     var translations = null;

     function init() {
         $translate(["Never", "Daily", "Weekly", "Biweekly", "Monthly", "Bimonthly", "Quarterly", "Days1"])
             .then(function (all) {
                 translations = all;
             });

         loadData();
     }

     init();
 });

