
angular.module("prisma")
  .controller("PriceWeeklyReportCtrl", function ($state, $rootScope, $timeout, priceService) {
      var self = this;

      self.weeklyData = [];
      self.selectedPriceZone = null;
      self.chartConfigurations = [{
          chartName: 'chart11',
          title: 'Precio Sug PEMEX',
          properties: [
              { display: "Precio", code: "price" },
              { display: "Margen", code: "margin" },
          ],
          defaultProperty: "price",
          styles: {
              height: '220px',
              //width:'200px'
          },
          multipleValues: true,
          secondaryAxisProperty: "margin",
      },
      {
          chartName: 'chart12',
          title: 'Precio prom mdo',
          properties: [
              { display: "Precio", code: "price" },
              { display: "Margen", code: "margin" },
          ],
          defaultProperty: "price",
          styles: {
              height: '220px',
              //width:'200px'
          },
          multipleValues: true,
          secondaryAxisProperty: "margin",
      },
      {
          chartName: 'chart21',
          title: 'Precio OXXO Gas',
          properties: [
              { display: "Precio", code: "price" },
              { display: "Margen", code: "margin" },
          ],
          defaultProperty: "price",
          styles: {
              height: '220px',
              //width:'200px'
          },
          multipleValues: true,
          secondaryAxisProperty: "margin",
      },
      {
          chartName: 'chart22',
          title: 'Precio PETRO 7',
          properties: [
              { display: "Precio", code: "price" },
              { display: "Margen", code: "margin" },
          ],
          defaultProperty: "price",
          styles: {
              height: '220px',
              //width:'200px'
          },
          multipleValues: true,
          secondaryAxisProperty: "margin",
      },
      {
          chartName: 'chart23',
          title: 'Precio resto mdo',
          properties: [
              { display: "Precio", code: "price" },
              { display: "Margen", code: "margin" },
          ],
          defaultProperty: "price",
          styles: {
              height: '220px',
              //width:'200px'
          },
          multipleValues: true,
          secondaryAxisProperty: "margin",
      },
      {
          chartName: 'chart31',
          title: 'Evolucion margen',
          properties: [
              { display: "Magna", code: "Magna" },
              { display: "Premium", code: "Premium" },
              { display: "Diesel", code: "Diesel" },
          ],
          defaultProperty: "margin",
          styles: {
              height: '220px',
              //width:'200px'
          },
          chartType: 'pie'
      }]

      self.getChartConfiguration = function (name) {
          for (var i = 0; i < self.chartConfigurations.length; i++) {
              if (self.chartConfigurations[i].chartName == name)
                  return self.chartConfigurations[i];
          }
          return null;
      }
      //function updateChart() {
      //    self.chart3 = c3.generate(getChartConfig('#chart12', self.salesChartData));
      //}


      self.setFilterPriceZone = function (priceZone) {
          if (self.selectedPriceZone == priceZone) return;
          self.selectedPriceZone = priceZone;

          self.runFilters();
      }

      self.runFilters = function () {
          priceService.reports.getWeeklyReport(self.selectedPriceZone.id).then(function (weeklyData) {
              self.weeklyData = weeklyData;
              console.log('weeklydata', weeklyData);

              //Esto es por el jquery que usa el chart
              $timeout(function () {
                  for (var i = 0; i < weeklyData.length; i++) {
                      //console.log('weeklyData[i]', weeklyData[i]);
                      if (weeklyData[i].series.length == 0) continue;
                      //console.log('ssasas');

                      var chartConfiguration = self.getChartConfiguration(weeklyData[i].code);
                      if (chartConfiguration != null) {
                          if (chartConfiguration.chartType == 'pie') {
                              chartConfiguration.updateChart(weeklyData[i].series, chartConfiguration.properties[0].code);
                          } else {
                              chartConfiguration.updateChart(weeklyData[i].series, 'price');
                          }
                      }
                  }
              });


          });
      }

      self.mustShowChart = function (chartName) {
          for (var i = 0; i < self.weeklyData.length; i++) {
              if (self.weeklyData[i].code == chartName) {
                  //console.log(self.weeklyData[i]);
                  return self.weeklyData[i].series != undefined && self.weeklyData[i].series.length > 0;
              }
          }
          return false;
      }


      self.loadData = function () {

          priceService.pricelists.getPriceLists().then(function (priceLists) {
              console.log('pricelists', priceLists)
              self.priceLists = priceLists;

              self.setFilterPriceZone(priceLists[0]);


          });
      }

      function init() {

          //load data
          self.loadData();
      }

      init();

  });

