(function () {
    'use strinct';
    angular.module('prisma')
        .directive('dragScroll', ['$log', '$parse', '$window', '$document', 'DnDService', dragScrollDirective]);

    /**
     * Directiva drag-scroll
     * @param {any} $log Servicio de log de angular.
     * @param {any} $parse Servicio de parse de angular.
     * @param {any} $window Servicio para acceder al objeto window de angular.
     * @param {any} $document Servicio para acceder al objeto document de angular.
     * @param {DnDService} dnDService Servicio de Drag and Drop.
     */
    function dragScrollDirective($log, $parse, $window, $document, dnDService) {
        //$log.debug('DragToScrollDirective::ctor');

        /**
         * Link del elemento.
         * @param {any} scope Referencia del contexto de la directiva.
         * @param {any} $element Referencia del elemento.
         * @param {any} attrs Referencia de los atributos del elemento.
         */
        function linkFn(scope, $element, attrs) {
            //$log.debug('DragToScrollDirective::link');
            var originalMousePositionX = 0;
            var originalMousePositionY = 0;
            var originalScrollLeft = $element.scrollLeft();
            var originalScrollTop = $element.scrollTop();
            var dragging = false;
            var exp = attrs.dragScroll === '' ? 'true' : attrs.dragScroll;
            var allowDrag = $parse(exp);

            /**
             * Evento global mouse up.
             * @event 
             * @param {any} $event Referencia del evento.
             */
            function onGlobalMouseUpHandler($event) {
                // $element.trigger('mouseup');
                dragging = false;

                // var originalCursor = $element.data('cursor') || 'auto';
                // angular.element('body').css('cursor', originalCursor);
                // $element.css('cursor', 'grab');
                // angular.element($event.target).css('cursor', 'auto');
                angular.element($window)
                    .off('mouseup', onGlobalMouseUpHandler);
                $document.off('mousemove', onGlobalMouseMoveHandler);
            };

            /**
             * Evento global mouse move.
             * @event 
             * @param {any} $event Referencia del evento.
             */
            function onGlobalMouseMoveHandler($event) {
                if (dragging === true) {
                    var left = originalScrollLeft + (originalMousePositionX - $event.pageX);
                    var top = originalScrollTop + (originalMousePositionY - $event.pageY);
                    $element.scrollLeft(left)
                        .scrollTop(top);
                }
            };

            function onGlobalMouseDownHandler($event) {
                $event.preventDefault();
                $event.stopPropagation();

                // Fuerza el mouse up si se dispara en otro copmponente del DOM.
                angular.element($window)
                    .off('mouseup', onGlobalMouseUpHandler);

                $document.off('mousemove', onGlobalMouseMoveHandler);

                if (allowDrag(scope) && !dnDService.isOnDrag()) {
                    dragging = true;
                    originalMousePositionX = $event.pageX;
                    originalMousePositionY = $event.pageY;
                    originalScrollLeft = $element.scrollLeft();
                    originalScrollTop = $element.scrollTop();

                    // var originalCursor = angular.element('body').css('cursor') || 'auto';
                    // $element.data('cursor', originalCursor);
                    // angular.element('body').css('cursor', 'grabbing');
                    // $element.css('cursor', 'grabbing');
                    // angular.element($event.target).css('cursor', 'grabbing');

                    // Fuerza el mouse up si se dispara en otro copmponente del DOM.
                    angular.element($window)
                        .on('mouseup', onGlobalMouseUpHandler);

                    $document.on('mousemove', onGlobalMouseMoveHandler);
                }
            }

            scope.$on('$destroy', function () {
                // Desconecta los eventos.
                $element.off('mousedown touchstart mouseup touchend dragstart mouseleave');
            });

            // conecta los eventos.
            $element.css('cursor', 'grab')
                .on('dragstart',
                    function onDragStart($event) {
                        return false;
                    })
                .on('mousedown', onGlobalMouseDownHandler);
        }

        return {
            link: linkFn
        }
    }
})();