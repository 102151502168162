
angular.module("prisma")
    .controller("MarkdownDecisionsCtrl", function ($state, $scope, $stateParams, $rootScope, markdownService, authService, ngDialog, $filter) {
        var self = this;

        self.canAceptar = authService.hasPermission('markdown_decisions_Aceptar');
        self.canEndosar = authService.hasPermission('markdown_decisions_Endosar');
        self.canValidar = authService.hasPermission('markdown_decisions_Validar');

        self.PrevCategory = $rootScope.erp.Implementation == 'CencosudChile';

        self.expandAll = true;

        self.storeFilteringText = "Filtrar por tienda";
        self.dropDrownStores = { isopen: false };
        self.allStores = [{ id: 0, code: 'Todos', name: 'Todos', companyId: 1, }];

        self.suggestionFilterText = "Filtrar por Sugeridos";
        self.dropdownSuggestionFilter = { isopen: false };
        self.allSuggestionFilter = [{ id: 0, code: 'Todos', name: 'Todos' },
                                    { id: 1, code: 'Sugeridos', name: 'Sugeridos' },
                                    { id: 22, code: 'Manuales', name: 'Manuales' },
                                    { id: 23, code: 'Vencimientos', name: 'Vencimientos' }];

        self.stageFilterText = "Filtrar por Etapa";
        self.dropdownStagesFilter = { isopen: false };
        self.allStagesFilter = [{ id: 0, code: 'Todos', name: 'Todos' },
                                { id: 1, code: 'Etapa01', name: 'Etapa 1' },
                                { id: 2, code: 'Etapa02', name: 'Etapa 2' },
                                { id: 3, code: 'Etapa03', name: 'Etapa 3' },
                                { id: 4, code: 'Etapa04', name: 'Etapa 4' },
                                { id: 5, code: 'Etapa05', name: 'Etapa 5' }];

        self.stockFilterText = "Filtrar por Stock";
        self.dropdownStockFilter = { isopen: false };
        self.allStockFilter = [{ id: 0, code: 'Ninguno', name: 'Ninguno' },
                               { id: 1, code: 'MayorStock', name: 'Mayor Stock $' },
                               { id: 2, code: 'MenorStock', name: 'Menor Stock $' }];

        self.statusFilterText = "Filtrar por Status";
        self.dropdownStatusFilter = { isopen: false };
        self.allStatusFilter = [{ id: 0, code: 'Todos', name: 'Todos' },
                                { id: 1, code: 'Activo', name: 'Activo' },
                                { id: 2, code: 'Inactivo', name: 'Inactivo' }];

        self.editedActionFilterText = "Filtrar por Accion editada";
        self.dropdownEditedActionFilter = { isopen: false };
        self.allEditedActionFilter = [
                                      { id: 1, code: 'Continuar', name: 'Continuar', suggestion: false, handLoad: false },
            { id: 2, code: 'Caducar', name: 'Caducar', suggestion: false, handLoad: true},

            { id: 3, code: 'Nuevo', name: 'Nuevo', suggestion: true, handLoad: true},
                                      //{ id: 4, code: 'Devolver', name: 'Devolver', suggestion: null },
                                      //{ id: 5, code: 'Transferir', name: 'Transferir', suggestion: null },
            { id: 6, code: 'Mantener', name: 'Mantener', suggestion: false, handLoad: false}];

        /*self.allEditedActionFilter = [
                                      { id: 1, code: 'Continuar', name: 'Continuar', suggestion: false },
                                      { id: 2, code: 'Caducar', name: 'Caducar', suggestion: true },
                                      { id: 3, code: 'Nuevo', name: 'Nuevo', suggestion: true },
                                      { id: 4, code: 'Devolver', name: 'Devolver', suggestion: null },
                                      { id: 5, code: 'Transferir', name: 'Transferir', suggestion: null },
                                      { id: 6, code: 'Mantener', name: 'Mantener', suggestion: false }]; */
        self.pageNumber = 0;
        self.pageSize = 500;
        self.selectedStoreId = 0;
        self.selectedSugestionFilterId = 0;
        self.selectedStageFilterId = 0;
        self.selectedStockFilterId = 0;
        self.selectedStatusFilterId = 0;    
        self.selectedStage = 0;
        self.selectedCategoryId = $stateParams.categoryId;
        self.selectedCategories = [{ name: $stateParams.category, id: $stateParams.categoryId }];
        self.selectedEditedActionFilter = null;
        self.showCanal = companyJson.MarkdownDecisions
            && companyJson.MarkdownDecisions.Grid
            && companyJson.MarkdownDecisions.Grid.ShowCanal;

        self.visitedPages = [];

        self.filterByStore = function (store) {

            if (store.name == 'Todos') {
                self.selectedStoreId = 0;
                self.storeFilteringText = "Filtrar por tienda";
                //self.gridOptions.api.setQuickFilter();
            }
            else {
                self.selectedStoreId = store.id;
                //self.gridOptions.api.setQuickFilter(store.code);
                self.storeFilteringText = "Tienda: " + store.name;
            }
        }

        self.filterBySuggestion = function (choise) {

            if (choise.name == 'Todos') {
                self.selectedSugestionFilterId = 0;
                self.suggestionFilterText = "Filtrar por Sugeridos";
                //self.gridOptions.api.setQuickFilter();
            }
            else {
                self.selectedSugestionFilterId = choise.id;
                //self.gridOptions.api.setQuickFilter(store.code);
                self.suggestionFilterText = choise.name;
            }
        }

        self.filterByStage = function (choise) {

            if (choise.name == 'Todos') {
                self.selectedStageFilterId = 0;
                self.stageFilterText = "Filtrar por Etapas";
                //self.gridOptions.api.setQuickFilter();
            }
            else {
                self.selectedStageFilterId = choise.id;
                //self.gridOptions.api.setQuickFilter(store.code);
                self.stageFilterText = choise.name;
            }
        }

        self.filterByStock = function (choise) {

            if (choise.name == 'Ninguno') {
                self.selectedStockFilterId = 0;
                self.stockFilterText = "Filtrar por Stock";
                //self.gridOptions.api.setQuickFilter();
            }
            else {
                self.selectedStockFilterId = choise.id;
                //self.gridOptions.api.setQuickFilter(store.code);
                self.stockFilterText = choise.name;
            }
        }

        self.filterByStatus = function (choise) {

            if (choise.name == 'Todos') {
                self.selectedStatusFilterId = 0;
                self.statusFilterText = "Filtrar por Status";
                //self.gridOptions.api.setQuickFilter();
            }
            else {
                self.selectedStatusFilterId = choise.id;
                //self.gridOptions.api.setQuickFilter(store.code);
                self.statusFilterText = choise.name;
            }
        }

        self.externalFilterChanged = function (id) {
            self.selectedEditedActionFilter = id;
            self.gridOptions.api.onFilterChanged();
        }

        function isExternalFilterPresent() {
            return self.selectedEditedActionFilter != null;
        }

        function doesExternalFilterPass(node) {
            if (node.data.editedAction == self.selectedEditedActionFilter || self.selectedEditedActionFilter == 0)
                return true;

            return false;
        }        

        var decisionsTypes = {
            SUGGESTIONS: 0,
            ENDORSE: 1,
            VALIDATE: 2
            //REVALIDATE: 5
        }
        self.type = $stateParams.decisionType;
        console.log('stateparams', $stateParams);

        var numberColWidth = 120;

        var columnDefs = null;

        self.previousPage = function () {
            console.log("previous");
            if (self.pageNumber == 0)
                return;
            self.pageNumber--;

            loadPageLocally(self.pageNumber);
        }
        self.nextPage = function () {
            //console.log("next");
            if (self.pageNumber + 1 == self.totalPages)
                return;

            self.pageNumber++;
            console.log("visitedPAges", self.visitedPages);
            if (self.visitedPages.indexOf(self.pageNumber) == -1) {
                loadMarkdownSuggestions();
            } else {
                loadPageLocally(self.pageNumber);
            }

        }

        self.applyingFilters = false;
        self.applyFilters = function () {

            self.pageNumber = 0;
            loadMarkdownSuggestions(true);
        }

        self.onCategorySelected = function (category) {
            console.log("category", category);
            for (var i = 0; i < self.selectedCategories.length; i++) {
                if (self.selectedCategories[i].id == category.id)
                    return;
            }
            self.selectedCategories.push(category);
            //self.selectedCategoryId = category.id;
        }

        function splitCategories() {
            var arr = [];
            angular.forEach(self.selectedCategories, function (category) {
                arr.push(category.id);
            });
            return arr;
        }

        self.removeCategory = function (index) {
            self.selectedCategories.splice(index, 1);
        }

        function getSelectedItems() {
            var selected = [];
            self.gridOptions.api.forEachNodeAfterFilter( function (node, key) {
                //console.log(node.data.suggestionId);
                if (node.data.suggestionId > 0)
                    selected.push(node.data);

            });

            return selected;
        }

        function getItemsToSave() {
            var selected = [];
            var count = 0;
            self.gridOptions.api.forEachNodeAfterFilter( function (node, key) {
                //console.log(node.data.suggestionId);
                if (node.selected && node.data.suggestionId > 0 && node.data.currentMarkdownPlanType != 22 && node.data.currentMarkdownPlanType != 23) {
                    selected.push({
                        suggestionId: node.data.suggestionId,
                        suggestedAction: node.data.suggestedAction,
                        takenAction: node.data.editedAction ? node.data.editedAction : node.data.suggestedAction,
                        keepPlanStageId: node.data.keepPlanStageId
                    });
                    count++;
                } else if (node.selected && node.data.currentMarkdownPlanType === 22 || node.data.currentMarkdownPlanType === 23) {
                    selected.push({
                        markdownItemId: node.data.currentMarkdownId,
                        suggestedAction: node.data.suggestedAction,
                        takenAction: node.data.editedAction ? node.data.editedAction : node.data.suggestedAction,
                        keepPlanStageId: node.data.keepPlanStageId
                    });
                    count++;
                }
                
              
            });
            //console.log(count);
            return selected;
        }

        self.acceptSuggestions = function () {

          
            
           /* function containItemToExpireOrContinue() {
                for (var i = 0; i < itemsToSave.length; i++) {

                    var keyNames = Object.keys(itemsToSave[i]);
                   // if (itemsToSave[i].takenAction == 2 || itemsToSave[i].takenAction == 1) {
                        //si existe un MI o se continua una sugerencia, se pueden tomar estas acciones 
                        if ((itemsToSave[i].suggestedAction != itemsToSave[i].takenAction ) && !keyNames["markdownItemId"]) {
                            return true;
                        }
                    //}
                }
                return false;
            };


            if (containItemToExpireOrContinue()) {
                swal({
                    type: "warning",
                    title: "Alerta",
                    text: "No se puede caducar ni continuar un artículo que no está en plan",
                });
                return;
            }
            */
            var itemsToSave = getItemsToSave();
            console.log(itemsToSave);

            if (itemsToSave.length == 0) {
                swal("Debe seleccionar al menos una sugerencia", null, "warning");
                return;
            }
            swal({
                title: "¿Está seguro?",
                text: '¿Está seguro que desea aceptar las sugerencias seleccionadas?',
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (yes) {
                    if (yes) {
                     
                        markdownService.decisions.acceptSuggestions(itemsToSave)
                            .then(function () {
                                self.applyFilters();
                                swal("Confirmado", "Se guardaron los cambios seleccionados", "success");
                            }, function (error) {
                                swal("Error", "Ha ocurrido un error", "error");
                            });
                       
                    }
                });
        }

        self.endose = function () {
            console.log('endose');
            var itemsToSave = getItemsToSave();
            console.log(itemsToSave);

            if (itemsToSave.length == 0) {
                swal("Debe seleccionar al menos una sugerencia", null, "warning");
                return;
            }
            swal({
                title: "¿Está seguro?",
                text: '¿Está seguro que desea endosar las sugerencias seleccionadas?',
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (yes) {
                    if (yes) {
                        markdownService.decisions.endose(itemsToSave)
                            .then(function () {
                                console.log('guardado');
                                self.applyFilters();
                                swal("Confirmado", "Se guardaron los cambios seleccionados", "success");
                            }, function (error) {
                                swal("Error", "Ha ocurrido un error", "error");
                            });
                    }
                });
        }

        self.validate = function () {
            console.log('validate');
            var itemsToSave = getItemsToSave();
            console.log(itemsToSave);

            if (itemsToSave.length == 0) {
                swal("Debe seleccionar al menos una sugerencia", null, "warning");
                return;
            }

            swal({
                title: "¿Está seguro?",
                text: '¿Está seguro que desea Validar las sugerencias seleccionadas?',
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (yes) {
                    if (yes) {
                        markdownService.decisions.validate(itemsToSave)
                            .then(function () {
                                console.log('guardado');
                                self.applyFilters();
                                swal("Confirmado", "Se guardaron los cambios seleccionados", "success");
                            }, function (error) {
                                swal("Error", "Ha ocurrido un error", "error");
                            })
                    }
                });
        }

        self.ignoreSuggestions = function () {

          
            var itemsToSave = getItemsToSave();

            if (itemsToSave.length == 0) {
                swal("Debe seleccionar al menos una sugerencia", null, "warning");
                return;
            }

            swal({
                title: "¿Está seguro?",
                text: '¿Está seguro que desea ignorar las sugerencias seleccionadas?',
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            }, function (yes) {
                if (yes) {



                    markdownService.decisions.ignoreSuggestions(itemsToSave)
                        .then(function () {
                            self.applyFilters();
                            swal("Confirmado", "Se guardaron los cambios seleccionados", "success");
                        }, function (error) {
                            swal("Error", "Ha ocurrido un error", "error");
                        });
                }
            });
        }

        self.reject = function () {
            console.log("reject");
            var itemsToSave = getItemsToSave();

            if (itemsToSave.length == 0) {
                swal("Debe seleccionar al menos una sugerencia", null, "warning");
                return;
            }

            swal({
                title: "¿Está seguro?",
                text: '¿Esta seguro que desea rechazar las sugerencias seleccionadas?',
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            }, function (yes) {
                if (yes) {
                    markdownService.decisions.reject(itemsToSave)
                        .then(function () {
                            self.applyFilters();
                            swal("Confirmado", "Se guardaron los cambios seleccionados", "success");
                            console.log('rechazados');
                        }, function (error) {
                            swal("Error", "Ha ocurrido un error", "error");
                        });
                    }
                });
        }



        

        self.reviewCategoryEndorse = function () {

            swal({
                title: "¿Está seguro?",
                text: '¿Está seguro que desea marcar la categoria como revisada?',
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            }, function (yes) {
                if (yes) {
                    var categoryIds = splitCategories();

                    markdownService.decisions.reviewCategoryEndorse(categoryIds)
                        .then(function () {
                            self.applyFilters();
                            console.log('reviewCategoryEndorse');
                            swal("Confirmado", "Se guardaron los cambios seleccionados", "success");
                        }, function (error) {
                            swal("Error", "Ha ocurrido un error", "error");
                        })
                }
            });
        }

        

        self.volverSeleccionCategoria = function () {
            $state.go('markdowns.categorySummary');
        }

        self.exportToExcel = function () {
            var params = {
                skipHeader: false,
                skipFooters: false,
                skipGroups: false,
                allColumns: true,
                onlySelected: false,
                suppressQuotes: false,
                fileName: self.category + '.xls',
                columnSeparator: ',',
                processCellCallback: renderForExport
                    
            };

            self.gridOptions.api.exportDataAsCsv(params);
        }


        function renderForExport(params) {
            //console.log(params);
            switch (params.column.colDef.headerName) {
                //case "Estado Actual":
                //case "Estado sugerido":
                //    if (!params.value)
                //        return '';
                //    else {
                //        var planType = getPlanType(params.value)
                //        return planType.name;
                //    }
                //    break;

                //case "Devolucion":
                //    if (!params.value)
                //        return '';
                //    else {
                //        return params.value == 21 ? 'Si' : 'No';
                //    }
                //    break;
                //case "Accion editada":
                //    params.value = params.node.data.editedAction || params.node.data.suggestedAction;
                //        break;
                //case "Accion sugerida":
                //    if (!params.value)
                //        return '';
                //    else {
                //        return self.markdownActions.getDescription(params.value);
                //    }
                //    break;

                //case "Precio / Dto":
                //    if (!params.value)
                //        return '';
                //    else {
                //        var discountType = getDiscountTypes(params.value)
                //        return discountType.name;
                //    }
                //    break;

                //case "Descuento":
                //    if (!params.value)
                //        return '';
                //    else {
                //        return $rootScope.percentageRendererNoDecimals(params);
                //    }
                //    break;

                //case "Tipo Vigente":
                //    if (!params.value)
                //        return '';
                //    else {
                //        var priceType = getPriceType(params.value)
                //        return priceType.name;
                //    }
                //    break;

                //case "Desde":
                //    if (!params.node.data.currentPriceValidFrom)
                //        return '';
                //    else {
                //        return $rootScope.dateRenderer(params.node.data.currentPriceValidFrom);
                //    }
                //    break;
                //case "Hasta":
                //    if (!params.node.data.currentPriceValidTo)
                //        return '';
                //    else {
                //        return $rootScope.dateRenderer(params.node.data.currentPriceValidTo);
                //    }
                //    break;
                case "Impacto Nuevo":  
                    if (!params.node.data.newImpact || params.node.data.newImpact == '')
                        return null;

                    return "$ " + $filter('number')(params.node.data.newImpact, 2); 
                    
                    break;
                case "Impacto Sugerido":
                    if (!params.node.data.suggestedImpact || params.node.data.suggestedImpact == '')
                        return null;

                    return "$ " + $filter('number')(params.node.data.suggestedImpact, 2);

                    break;

                //case "Margen Actual":
                //case "Margen Sugerido":
                //case "Margen Nuevo":
                //case "Objetivo Reducción de Stock (%)":
                //    if (!params.value)
                //        return '';
                //    else {
                //        return $rootScope.percentageRenderer2Decimals(params);
                //    }
                //    break;

                case "Var. %":
                    var actualDiscount = params.node.data.actualDiscount || 0;
                    var newDiscount = params.node.data.newDiscount || 0;
                    var value = (newDiscount - actualDiscount) * 100;
                    return value.toFixed(0) + " %";
                    break;

                case "Var %":
                    var originalCost = params.node.data.cost || 0;
                    var lastCost = params.node.data.lastCost || 0;
                    var value = (lastCost - originalCost) * 100;
                    return value.toFixed(1) + " %";
                    break;

                case "Objetivo DDS":
                    if (!params.value)
                        return '';
                    else {
                        return $rootScope.integerRenderer(params);
                    }

                default:
                    return params.value;
            }
        }

        self.expandOrCollapseAll = function () {

            if (self.expandAll) {
                    self.gridOptions.api.forEachNode(function (node) {
                        node.expanded = true;

                });
            }
            else {
                    self.gridOptions.api.forEachNode(function (node) {
                        node.expanded = false;
                });
            }
            self.expandAll = !self.expandAll;
            self.gridOptions.api.onGroupExpandedOrCollapsed();
        }

        function headerClassCell(params) {
            var cls = $rootScope.getClassForCell(params);
            if (params.data.children.length != 0) {
                cls.push("headerCell")
            }
            return cls;
        }

        self.priceTypes = [
            { id: 0, name: 'Vigente' },
            { id: 1, name: 'Excepcion' },
            { id: 2, name: 'Liquidacion' },
            { id: 3, name: 'Bonus' },
            { id: 4, name: 'Promocion' },
            { id: 5, name: 'Competencia' },
            { id: 6, name: 'Normal' }
        ]

        function getPriceType(id) {
            for (var i = 0; i < self.priceTypes.length; i++)
                if (self.priceTypes[i].id == id)
                    return self.priceTypes[i];
        }
        self.markdownActionsExpire = [
            { id: 3, name: 'Nuevo' },
            { id: 6, name: 'Mantener' },
            { id: 2, name: 'Caducar' },
        ];
        self.markdownActions = [
            { id: 1, name: 'Continuar' },
            { id: 2, name: 'Caducar' },
            { id: 3, name: 'Nuevo' },
            { id: 4, name: 'Devolver' },
            { id: 5, name: 'Transferir' },
            { id: 6, name: 'Mantener' },
        ];

        self.markdownActionsExpire.getDescription = function (id) {
            for (var i = 0; i < self.markdownActions.length - 1; i++) {
                if (self.markdownActionsExpire[i].id == id) {
                    return self.markdownActionsExpire[i].name;
                }
            }
            return '';
        };
        self.markdownActions.getDescription = function (id) {
            for (var i = 0; i < self.markdownActions.length; i++)
            {
                if (self.markdownActions[i].id == id) {
                    return self.markdownActions[i].name;
                }
            }
            return '';
        };
        self.markdownActions.getId = function (id) {

        };
        self.suggestionsActions = [
            //{ id: 1, name: 'Continuar' },
            { id: 3, name: 'Nuevo' },
            { id: 4, name: 'Devolver' },
            { id: 5, name: 'Transferir' },
        ];
        self.suggestionsActions.getDescription = function (id) {
            for (var i = 0; i < self.markdownActions.length - 1; i++) {
                if (self.markdownActions[i].id == id) {
                    return self.markdownActions[i].name;
                }
            }
            return '';
        };
        
        self.markdownActionsForEdit = [
            [],
            [
                { id: 1, name: 'Continuar' },//continuar
                { id: 2, name: 'Caducar' },
                { id: 4, name: 'Devolver' },
                { id: 5, name: 'Transferir' }
            ],
            [
                { id: 1, name: 'Continuar' },
                { id: 2, name: 'Caducar' },//caducar
                { id: 4, name: 'Devolver' },
                { id: 5, name: 'Transferir' }
            ],
            [
                { id: 3, name: 'Nuevo' },//Nuevo
                { id: 4, name: 'Devolver' },
                { id: 5, name: 'Transferir' }
            ],
            [
                { id: 1, name: 'Continuar' },
                { id: 2, name: 'Caducar' },
                { id: 4, name: 'Devolver' },//devolver
                { id: 5, name: 'Transferir' }
            ],
            [
                { id: 1, name: 'Continuar' },
                { id: 2, name: 'Caducar' },
                { id: 4, name: 'Devolver' },
                { id: 5, name: 'Transferir' }//transferir
            ]
        ];

        function getAction(id, inPlan) {
            for (var i = 0; i < (inPlan ? self.markdownActions.length : self.suggestionsActions.length); i++)
                if ((inPlan ? self.markdownActions[i].id : self.suggestionsActions[i].id)  == id)
                    return (inPlan ? self.markdownActions[i] : self.suggestionsActions[i]);
        }

        self.discountTypes = [
            { id: 1, name: "%" },
            { id: 2, name: "$" },
            { id: 3, name: "$-%" },
        ]

        function getDiscountTypes(id) {
            for (var i = 0; i < self.discountTypes.length; i++)
                if (self.discountTypes[i].id == id)
                    return self.discountTypes[i];
        }

        self.planTypes = function () {
            if ($rootScope.maxAgingLevel == 12) {
                if ($rootScope.erp.Implementation == 'CencosudChile') {
                    return [
                        { id: 10, name: 'Inactivos' },
                        { id: 8, name: 'Descatalogados (sin bloqueo)' },
                        { id: 9, name: 'Descatalogados (con bloqueo)' },
                        { id: 12, name: 'Aging Fase 1' },
                        { id: 13, name: 'Aging Fase 2' },
                        { id: 14, name: 'Aging Fase 3' },
                        { id: 15, name: 'Aging Fase 4' },
                        { id: 16, name: 'Aging Fase 5' },
                        { id: 17, name: 'Aging Fase 6' },
                        { id: 18, name: 'Aging Fase 7' },
                        { id: 25, name: 'Aging Fase 8' },
                        { id: 26, name: 'Aging Fase 9' },
                        { id: 27, name: 'Aging Fase 10' },
                        { id: 28, name: 'Aging Fase 11' },
                        { id: 29, name: 'Aging Fase 12' },
                        { id: 19, name: 'Sobrestock' },
                        { id: 20, name: 'Sobrestock critico' },
                        { id: 21, name: 'Devolucion' },
                        { id: 22, name: 'Carga Manual' },
                        { id: 24, name: 'Pronostico de salida de stock' },
                    ]
                } else {
                    return [
                        { id: 10, name: 'Inactivos' },
                        { id: 12, name: 'Aging Fase 1' },
                        { id: 13, name: 'Aging Fase 2' },
                        { id: 14, name: 'Aging Fase 3' },
                        { id: 15, name: 'Aging Fase 4' },
                        { id: 16, name: 'Aging Fase 5' },
                        { id: 17, name: 'Aging Fase 6' },
                        { id: 18, name: 'Aging Fase 7' },
                        { id: 25, name: 'Aging Fase 8' },
                        { id: 26, name: 'Aging Fase 9' },
                        { id: 27, name: 'Aging Fase 10' },
                        { id: 28, name: 'Aging Fase 11' },
                        { id: 29, name: 'Aging Fase 12' },
                        { id: 19, name: 'Sobrestock' },
                        { id: 20, name: 'Sobrestock critico' },
                        { id: 21, name: 'Devolucion' },
                        { id: 22, name: 'Carga Manual' },
                        { id: 24, name: 'Pronostico de salida de stock' },
                    ]
                }
            }
            else { //Para el resto es 7, sino agregar otro if.
                return [
                    { id: 10, name: 'Inactivos' },
                    { id: 12, name: 'Aging Fase 1' },
                    { id: 13, name: 'Aging Fase 2' },
                    { id: 14, name: 'Aging Fase 3' },
                    { id: 15, name: 'Aging Fase 4' },
                    { id: 16, name: 'Aging Fase 5' },
                    { id: 17, name: 'Aging Fase 6' },
                    { id: 18, name: 'Aging Fase 7' },
                    { id: 19, name: 'Sobrestock' },
                    { id: 20, name: 'Sobrestock critico' },
                    { id: 21, name: 'Devolucion' },
                    { id: 22, name: 'Carga Manual' },
                    { id: 24, name: 'Pronostico de salida de stock' },
                ]
            }
        }
       

        function getPlanType(id) {
            var pt = self.planTypes()
            for (var i = 0; i < pt.length; i++)
                if (pt[i].id == id)
                    return pt[i];
        }

        function selectAll(checked) {
            self.gridOptions.api.forEachNodeAfterFilter(function (node) {
                node.setSelected(checked);
            });
        }

        function setColumnDefinitions() {
            columnDefs = [
                {
                    headerName: 'Informacion General',
                    children: [

                        {
                            headerName: 'SubCategoría',
                            width: numberColWidth + 100,
                            pinned: 'left',
                            field: 'subCategory',
                            //field: 'subCategory',
                            //suppressMenu: true,
                            //suppressSorting: true,
                            //filter: false,
                            checkboxSelection: true,
                            suppressMenu: false,
                            suppressSorting: true,
                            cellClass: headerClassCell,
                            headerCellRenderer: function selectAllRenderer(params) {
                                var cb = document.createElement('input');
                                cb.setAttribute('type', 'checkbox');

                                var eHeader = document.createElement('label');
                                var eTitle = document.createTextNode(params.colDef.headerName);
                                eHeader.appendChild(cb);
                                eHeader.appendChild(eTitle);

                                cb.addEventListener('change', function (e) {
                                    if ($(this)[0].checked) {
                                        params.api.selectAll();
                                    } else {
                                        params.api.deselectAll();
                                    }
                                });
                                return eHeader;
                            },


                            cellRenderer: {
                                renderer: 'group',
                                checkbox: true,
                                innerRenderer: function (params) {
                                    var name = params.value + (params.node.group && params.node.children != null ? ' (' + params.node.children.length + ')' : '');

                                    return name;
                                }
                            }
                        },
                        {
                            headerName: "SubRubro",
                            headerTooltip: "",
                            hide: !self.PrevCategory, 
                            field: "subRubro",
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "Grupo",
                            headerTooltip: "",
                            hide: !self.PrevCategory, 
                            field: "grupo",
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "Codigo",
                            field: "itemCode",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "UM",
                            field: "unitOfMeasureCode",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "EAN",
                            pinned: 'left',
                            field: "itemEan",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "Descripcion",
                            pinned: 'left',
                            field: "itemName",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth + 200,
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "Tienda",
                            pinned: 'left',
                            field: "storeCode",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: 70,
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "Canal",
                            pinned: 'left',
                            field: "storeCanalCode",
                            hide: !self.showCanal,
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: 70,
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "Status Art",
                            field: "statusArt",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "Marca",
                            field: "brand",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "Fabricante",
                            field: "manufacturer",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "Tags",
                            field: "tags",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell
                        }
                    ]
                },
                {
                    headerName: 'Stock',
                    children: [
                        {
                            headerName: "Stock Q (Tienda)",
                            field: "currentInventoryUnits",
                            //headerTooltip: "Cantidad de sugerencias.",
                            filter: 'text',
                            width: numberColWidth,
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "Decote",
                            field: "decote",
                            filter: 'number',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: decimalRenderer
                        },
                        {
                            headerName: "Stock $ (Tienda)",
                            field: "currentInventory",
                            //headerTooltip: "Cantidad de sugerencias.",
                            filter: 'text',
                            cellRenderer: $rootScope.moneyRenderer,
                            width: numberColWidth,
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "Stock Q (CD)",
                            field: "currentInventoryUnitsInDistributionCenters",
                            //headerTooltip: "Cantidad de sugerencias.",
                            filter: 'text',
                            width: numberColWidth,
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "Stock $ (CD)",
                            field: "currentInventoryInDistributionCenters",
                            //headerTooltip: "Cantidad de sugerencias.",
                            filter: 'text',
                            cellRenderer: $rootScope.moneyRenderer,
                            width: numberColWidth,
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "DDS",
                            field: "inventoryDays",
                            filter: 'text',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: decimalRenderer
                        },
                        {
                            headerName: "DDS (CD)",
                            field: "inventoryDaysWithDistributionCenters",
                            filter: 'text',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: decimalRenderer
                        }
                        
                    ]
                },
                {
                    headerName: 'Clasificacion',
                    children: [
                        {
                            headerName: "Estado Actual",
                            field: "currentMarkdownPlanTypeStr",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            //cellRenderer: function (params) {

                            //    if (!params.data.currentMarkdownPlanType)
                            //        return '';

                            //    var planType = getPlanType(params.data.currentMarkdownPlanType)
                            //    return planType.name;
                            //}
                        },
                        {
                            headerName: "Estado sugerido",
                            field: "suggestedMarkdownPlanTypeStr",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            //cellRenderer: function (params) {

                            //    if (!params.data.suggestedMarkdownPlanType)
                            //        return '';

                            //    var planType = getPlanType(params.data.suggestedMarkdownPlanType)
                            //    return planType.name;
                            //}
                        }, {
                            headerName: "Tipologia",
                            field: "typology",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: function (params) {

                                if (!params.data.typology)
                                    return '';

                                return 'Tipo ' + params.data.typology;
                            }
                        },                        
                        {
                            headerName: "Devolucion",
                            field: "isReturn",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                        }
                    ]
                },
                {
                    headerName: "Plan Actual",
                    children: [
                        {
                            headerName: "Precio / Dto",
                            field: "currentDiscountTypeStr",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            //cellRenderer: function (params) {

                            //    if (!params.data.currentDiscountType)
                            //        return '';

                            //    var discountType = getDiscountTypes(params.data.currentDiscountType)
                            //    return discountType.name;
                            //}
                        }, {
                            headerName: "Descuento",
                            field: "currentDiscountStr",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                        }, {
                            headerName: "Etapa",
                            field: "currentStage",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                        }, {
                            headerName: "Evacuado UN",
                            field: "currentPlanUnitsReduction",
                            //headerTooltip: "Cantidad de sugerencias.",
                            filter: 'number',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                        },
                        {
                            headerName: "Ajustes",
                            field: "currentAdjust",
                            filter: 'number',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                        }, 
                        {
                            headerName: "Avance",
                            field: "currentPlanProgress",
                            //headerTooltip: "Cantidad de sugerencias.",
                            filter: 'number',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: $rootScope.percentageRenderer2Decimals
                        },
                        {
                            headerName: "Recupero decote",
                            field: "recoverDecote",
                            filter: 'number',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: decimalRenderer4
                        }
                    ]
                },
                {
                    headerName: 'Plan Nuevo',
                    children: [
                        {
                            headerName: "Accion sugerida",
                            field: "suggestedActionStr",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            //cellRenderer: function (params) {

                            //    if (!params.data.suggestedAction)
                            //        return '';

                            //    return self.markdownActions.getDescription(params.data.suggestedAction);
                            //   /* var action = getAction(params.data.suggestedAction, params.data.currentMarkdownId); //tiene plan si es distinto de nuevo (3)

                            //    if (action) {
                            //        return action.name;

                            //    } else {
                            //        return ''; //fix
                            //    }
                            //    */
                            //}
                        }, {
                            headerName: "Accion editada",
                            field: "editedActionString",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: function (params) {

                                return dropDownEditor(self.allEditedActionFilter, "editedActionString", "editedAction", params, onEditedActionChange);
                            }
                                
                        }, {
                            headerName: "Precio / Dto",
                            field: "newDiscountTypeStr",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            //cellRenderer: function (params) {

                            //    if (!params.data.newDiscountType)
                            //        return '';

                            //    var discountType = getDiscountTypes(params.data.newDiscountType)
                            //    return discountType.name;
                            //}
                        }, {
                            headerName: "Descuento",
                            field: "newDiscountStr",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            //cellRenderer: $rootScope.percentageRendererNoDecimals
                            cellRenderer: function (params) {
                                var eCell = document.createElement('div');
                                eCell.className = "input-group";
                                eCell.style = "width:100% !important;height:100% !important";

                                if (params.data.currentMarkdownPlanType != 22  && params.data.newMargin < params.data.categoryMinMargin) {
                                    var eIcon = document.createElement('i');
                                    eIcon.className = "fa fa-exclamation-triangle text-danger mr10";
                                    eIcon.title = ian.format("Se ha excedido el limite minimo de margen de la categoria. El limite es: {0}", $filter('percentage')(params.data.categoryMinMargin));
                                    eCell.appendChild(eIcon);
                                }

                                var eSpan = document.createElement("span");
                                eSpan.textContent = params.data.newDiscountStr;
                                eCell.appendChild(eSpan);

                                return eCell;

                            }

                        }, {
                            headerName: "Etapa",
                            field: "newStage",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: function (params) {
                                if (params.data.newStage == 0) return '';
                                return params.data.newStage;
                            }
                        },
                        {
                            headerName: "Var. %",
                            field: "newStageStr",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            //cellRenderer: function (params) {

                            //    var actualDiscount = params.data.actualDiscount ? params.data.actualDiscount : 0;
                            //    var newDiscount = params.data.newDiscount ? params.data.newDiscount : 0;
                            //    var value = (newDiscount - actualDiscount) * 100;
                            //    return value.toFixed(0) + " %";
                            //}
                        },
                    ]
                },
                {
                    headerName: "Precio Vigente",
                    children: [
                        {
                            headerName: "Normal",
                            field: "normalPrice",
                            //headerTooltip: "Cantidad de sugerencias.",
                            filter: 'text',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: $rootScope.moneyRenderer,
                        }, {
                            headerName: "Vigente",
                            field: "currentPrice",
                            //headerTooltip: "Cantidad de sugerencias.",
                            filter: 'text',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: $rootScope.moneyRenderer
                        }, {
                            headerName: "Tipo Vigente",
                            field: "currentPriceTypeStr",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            //cellRenderer: function (params) {
                            //    if (!params.data.currentPriceType)
                            //        return '';

                            //    var priceType = getPriceType(params.data.currentPriceType)
                            //    return priceType.name;
                            //}
                        }, {
                            headerName: "Desde",
                            field: "currentPriceValidFromStr",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell
                        }, {
                            headerName: "Hasta",
                            field: "CurrentPriceValidToStr",
                            //headerTooltip: "Cantidad de sugerencias.",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                        }
                    ]
                },
                {
                    headerName: 'Costo',
                    children: [
                        {
                            headerName: "Original",
                            field: "cost",
                            filter: 'text',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: $rootScope.moneyRenderer,
                        },
                        {
                            headerName: "Ultimo",
                            field: "lastCost",
                            filter: 'text',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: $rootScope.moneyRenderer,
                        },
                        {
                            headerName: "Var %",
                            field: "lastCostStr",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            //cellRenderer: function (params) {
                            //    var originalCost = params.data.cost ? params.data.cost : 0;
                            //    var lastCost = params.data.lastCost ? params.data.lastCost : 0;
                            //    var value = (lastCost - originalCost) * 100;
                            //    return value.toFixed(1) + " %";
                            //},
                        },
                    ]
                },
                {
                    headerName: 'Impacto',
                    children: [                     
                        {
                            headerName: "Impacto Sugerido",
                            field: "suggestedImpact",
                            filter: 'text',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: $rootScope.moneyRenderer,
                        },
                        {
                            headerName: "Impacto Nuevo",
                            field: "newImpact",
                            filter: 'text',
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForImpactCell,
                            cellRenderer: $rootScope.moneyRenderer,
                        }
                    ]
                },
                {
                    headerName: 'Margen',
                    children: [
                        {
                            headerName: "Margen Actual",
                            field: "grossMarginStr",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            //cellRenderer: $rootScope.percentageRenderer2Decimals,
                        },
                        {
                            headerName: "Margen Sugerido",
                            field: "suggestedGrossMarginStr",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            //cellRenderer: $rootScope.percentageRenderer2Decimals,
                        },
                        {
                            headerName: "Margen Nuevo",
                            field: "suggestedGrossMarginStr", 
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            //cellRenderer: $rootScope.percentageRenderer2Decimals,
                        }
                    ]
                },
                {
                    headerName: 'Objetivo',
                    children: [
                        {
                            headerName: "Objetivo Reducción de Stock (%)",
                            field: "objectiveVentasUnStr",
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            //cellRenderer: $rootScope.percentageRenderer2Decimals
                        },
                        {
                            headerName: "Objetivo DDS",
                            field: "objectiveDds",
                            filter: 'text',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: $rootScope.integerRenderer
                        }                      
                    ]
                },
                {
                    headerName: 'Ventas',
                    children: [
                        {
                            headerName: "Volumen",
                            field: "units",
                            //headerTooltip: "Cantidad de sugerencias.",
                            filter: 'text',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: function (params) {
                                return Math.round(params.data.units);
                            }
                        },
                        {
                            headerName: "CMV",
                            field: "salesCost",
                            //headerTooltip: "Cantidad de sugerencias.",
                            filter: 'text',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: $rootScope.moneyRenderer
                        },
                        {
                            headerName: "Venta $",
                            field: "sales",
                            //headerTooltip: "Cantidad de sugerencias.",
                            filter: 'text',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: $rootScope.moneyRenderer,
                        },
                        {
                            headerName: "Venta Nueva $",
                            field: "forecastedSales",
                            //headerTooltip: "Cantidad de sugerencias.",
                            filter: 'text',
                            width: numberColWidth,
                            cellClass: headerClassCell,
                            cellRenderer: $rootScope.moneyRenderer,
                        }
                    ]
                },
                {
                    headerName: 'Otros Datos',
                    children: [
                        {
                            headerName: "Titulo",
                            field: "title",
                            //headerTooltip: "Cantidad de sugerencias.",
                            //filter: 'number',
                            width: numberColWidth,
                            cellClass: headerClassCell
                        },
                        {
                            headerName: "Desde",
                            field: "validFromStr",
                            //headerTooltip: "Cantidad de sugerencias.",
                            filter: 'date',
                            width: numberColWidth,
                            cellClass: headerClassCell
                        }, {
                            headerName: "Hasta",
                            field: "validToStr",
                            //headerTooltip: "Cantidad de sugerencias.",
                            filter: 'date',
                            width: numberColWidth,
                            cellClass: headerClassCell
                        },
                         {
                            headerName: "Solapamiento",
                            width: numberColWidth,
                            headerTooltip: "Solapamiento con Promociones",
                            field: "hasOverlappingStr",
                            cellRenderer: function (params) {

                                if (params.data.overlappingPromotions && params.data.overlappingPromotions.length > 0) {
                                    var eCell = document.createElement('div');
                                    eCell.className = "input-group";
                                    eCell.style = "width:100% !important;height:100% !important";

                                    var eIcon = document.createElement('i');
                                    eIcon.className = "fa fa-exclamation-triangle text-danger mr10 backgroundInput";
                                    eIcon.title = "Este item esta en promocion.";
                                    eCell.appendChild(eIcon);

                                    return eCell;
                                }


                                return '';
                            }
                        }
                    ]
                }
            ];
        }
        
        function onEditedActionChange(params, newValue) {
            console.log('params', params);
            console.log('newValue', newValue);


            for (i = 0; self.markdownActions.length - 1; i++) {
                if (self.markdownActions[i].name == newValue) {
                    params.data.editedAction = self.markdownActions[i].id;
                    return;
                }
            }

        }



        function cellClicked(cell) {

            if (cell.colDef.field == "newDiscount" && cell.data.children.length == 0) {
                if (cell.data != null) {
                    showMotivoSugerenciaModal(cell.data);
                }
            }
            //if (cell.colDef.field == "suggestedPrice") {
            //    if (cell.data != null && cell.data.suggestedPrice != null)
            //        showSuggestionModal(cell.data);
            //}
            //else if (cell.colDef.field == "competitorsPrice") {
            //    if (cell.data != null)
            //        $state.go('prices.priceMap', { itemId: cell.data.itemId, priceListId: cell.data.priceListId });
            //}
            else if (cell.colDef.field == "hasOverlappingStr") {
                if (cell.data != null)
                    self.showOverlaps(cell.data.overlappingPromotions);
            }
        }

        self.motivoSugerencia = {};

        function showMotivoSugerenciaModal(data) {

            

            self.loadingMotivoSugerencia = true;

            //no es vencimiento ni carga manual, entonces traigo por sugerencia
            if (data.currentMarkdownPlanType != 22 && data.currentMarkdownPlanType != 23) {
                markdownService.decisions.getMotivoSugerencia(data.suggestionId, data.suggestedMarkdownPlanType)
                    .then(function (motivoSugerencia) {
                        self.motivoSugerencia = motivoSugerencia;
                        self.loadingMotivoSugerencia = false;
                    });
            } else {
                markdownService.decisions.getMotivoSugerencia(data.currentMarkdownId, data.currentMarkdownPlanType)
                    .then(function (motivoSugerencia) {
                        self.motivoSugerencia = motivoSugerencia;
                        self.loadingMotivoSugerencia = false;
                    });
            }

            ngDialog.open({
                template: 'motivoSugerenciaDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            });
        }

        function setSelected(parentNode, childNode) {
            childNode.data.selected = parentNode.selected;
            childNode.setSelected(parentNode.selected);
        }

        function reDrawSelected() {
            self.gridOptions.api.forEachNode(function (node) {
                angular.forEach(self.summary, function (oldNode) {
                    if (node.data.localId == oldNode.localId) {
                        node.setSelected(oldNode.selected);

                        angular.forEach(node.children, function (child) {

                            angular.forEach(oldNode.children, function (oldNodeChild) {
                                //console.log(child.data.localId, oldNodeChild.localId);
                                if (child.data.localId == oldNodeChild.localId) {
                                            
                                    child.setSelected(oldNodeChild.selected);
                                }

                            })
                        })
                    }
                })

            })
            //angular.forEach(self.gri)
        }

        function rowSelected(event) {
            event.node.data.selected = event.node.selected;
            angular.forEach(event.node.children, function (child) {
                setSelected(event.node, child);
                //child.setSelected( event.node.selected);
            });
            var data = event.node.data;
            if (event.node.selected && data.suggestedAction == 1) {
                    data.newMargin = data.suggestedGrossMargin;
                    data.newImpact = data.newImpact;                                        
            }
            else {
                //data.newMargin = null;
                //data.newImpact = null;
            }
            self.gridOptions.api.refreshRows([event.node]);
        }

        function getNodeChildDetails(rowItem) {
            if (rowItem.children && rowItem.children.length > 0) {
                return {
                    group: true,
                    expanded: false,
                    // provide ag-Grid with the children of this group
                    children: rowItem.children,
                    // the key is used by the default group cellRenderer
                    key: rowItem.suggestionId
                };
            }
            else {
                return null;
            }
        }

        function setGridOptions() {

            setColumnDefinitions();

            self.gridOptions = {
                columnDefs: columnDefs,
                enableColResize: true,
                enableSorting: true,
                enableFilter: true,
                groupHeaders: true,
                groupKeys: undefined, //set as string of keys eg ["region","country"],
                groupHideGroupColumns: true,
                //groupUseEntireRow: false,
                groupSelectsChildren: false,
                rowHeight: 30,
                rowSelection: "multiple", // one of ['single','multiple'], leave blank for no selection
                singleClickEdit: true,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                suppressColumnMoveAnimation: $rootScope.suppressColumnMoveAnimation,
                suppressCellSelection: true,
                suppressRowClickSelection: true,
                enableRangeSelection: false,
                onRowSelected: rowSelected,
                onCellClicked: cellClicked,
                angularCompileRows: true,
                getNodeChildDetails: getNodeChildDetails,
                doesExternalFilterPass: doesExternalFilterPass,
                isExternalFilterPresent: isExternalFilterPresent
            }
        }

        function loadPageLocally(index) {
            console.log("self.summary", self.summary);
            //console.log("index", index);
            var pages = [];
            for (var i = self.pageSize * index; i < self.pageSize * (index + 1) && i < self.summary.length; i++) {
                pages.push(self.summary[i]);
            }
            //console.log("pages", pages);

            self.gridOptions.api.setRowData(pages);
            reDrawSelected();
            //return page;
        }

        self.showOverlaps = function (overlaps) {
            self.currentOverlaps = overlaps;

            if (self.currentOverlaps.length > 0) {
                ngDialog.open({
                    template: 'overlapDialog',
                    className: 'ngdialog-theme-default ngdialog-theme-custom',
                    //data: task,
                    scope: $scope
                });
            }
        }


        var count = 0;
        function loadMarkdownSuggestions(reload) {

            var categories = splitCategories();
            
            if (categories.length == 0) {
                categories = '0';
                swal("Debe seleccionar al menos una categoria", null, "warning");
                return;
            }
            

            if (reload) {
                self.summary = [];
                count = 0;
                self.visitedPages = [];
            }

            var pageIndex = self.pageNumber;
            self.applyingFilters = true;
            markdownService.decisions.GetMarkdownSuggestions(categories, $stateParams.decisionType,
                self.pageNumber, self.pageSize, self.selectedStoreId, self.selectedStageFilterId,
                self.selectedSugestionFilterId, self.selectedStockFilterId, self.selectedStatusFilterId
            ).then(function (items) {
                    //console.log('items', items);
                    self.applyingFilters = false;
                    angular.forEach(items, function (item) {
                        self.totalPages = item.totalPages;
                        if (item.editedAction) 
                            item.editedActionString = getAction(item.editedAction, true).name;
                        //editedActionString para los hijos
                      
                        item.localId = count++;
                        item.selected = false;
                        angular.forEach(item.children, function (child) {
                            child.localId = count++;
                            child.selected = false;
                            if (child.editedAction)
                                child.editedActionString = getAction(child.editedAction, true).name;
                        });
                    });
                    self.summary = self.summary.concat(items);

                    self.gridOptions.api.setRowData(items);
                    //ISSUE CP 807: Re-setea la data para que se actualize el filtro de la grilla
                    self.gridOptions.api.setRowData(items);

                    self.visitedPages.push(pageIndex);
                });
        }

        function loadData() {
            //$stateParams.categoryId puede ser una categoria nivel 3, en ese caso trae los hijos
            markdownService.decisions.getCategoriesForDecisions($stateParams.categoryId)
                .then(function (categoriesLv4) {
                    if (categoriesLv4.length) {
                        self.selectedCategories = [];
                        categoriesLv4.forEach(function (c) {
                            self.selectedCategories.push({ name: c.name, id: c.id });
                        });
                    }

                    //TODO: si agrego categorias, me van a venir mas decisiones por lo tanto mas tiendas
                    //en ese caso se deberia actualizar self.allStores
                    markdownService.decisions.getStoresInMarkdown($stateParams.categoryId, $stateParams.decisionType).then(function (stores) {
                        console.log('stores', stores)

                        self.allStores = self.allStores.concat(stores);
                    });

                    loadMarkdownSuggestions(true);
                });
        }

        function init() {
            //init columnDefs and gridOptions
            setGridOptions();

            //load data
            loadData();
        }

        init();

        function decimalRenderer(params) {
            if (params.value == null)
                return null;

            if (params.value == 0)
                return 0;

            return $filter('number')(params.value, 2);
        }
        function decimalRenderer4(params) {
            if (params.value == null)
                return null;

            if (params.value == 0)
                return 0;

            return $filter('number')(params.value, 4);
        }
        function percentageRenderer2Decimals(params) {
            if (params.value == null || params.value == 0 || params.value == '')
                return null;

            var value = Math.floor(100 * params.value) / 100;

            return value * 100 + " %";
        }
        function dropDownEditor(source, field, fieldId, params, onChangeCallback) {
            
                //if (!doesColumnApply(params.column.colDef, params.data))
                //return '';

                var editing = false;

                var eCell = document.createElement('div');

                var eLabel = null
             
            if (params.value != null && params.value != "")
                eLabel = document.createTextNode(params.value);
            else
                if (fieldId == "editedAction" && params.data[fieldId] && source && source.length >= params.data[fieldId]) {
                    /*var flgSuggestion = source.findIndex(x => x.id == (params.data.editedAction == null ? params.data.suggestedAction : params.data.editedAction)); //(params.data.markdownItemId ? 1 : 2);
                    eLabel = document.createTextNode(source[flgSuggestion].name);*/
                    var label = "------------";
                    if (params.data.suggestedAction && params.data.suggestedAction != params.data.editedAction ) {
                        label = self.markdownActions.getDescription(params.data.editedAction);
                    } else {
                        if (params.data.suggestedAction )
                            label = self.markdownActions.getDescription(params.data.suggestedAction);
                    }
                    eLabel = document.createTextNode(label);
                    
                }
                else
                    if (!params.data.editedAction && params.data.suggestedAction && params.data.suggestedAction == 1) {
                        label = self.markdownActions.getDescription(params.data.suggestedAction);
                        eLabel = document.createTextNode(label);
                    } else {
                        if (!params.data.editedAction && params.data.suggestedAction) {

                            label = self.suggestionsActions.getDescription(params.data.suggestedAction);
                            eLabel = document.createTextNode(label);
                        }
                        else {
                            //cuando la accion sugerida es Caducar pero elijo mantener, seguir con el plan actual
                            if ((params.data.editedAction && params.data.editedAction == 6) && (params.data.suggestedAction && (params.data.suggestedAction == 2 || params.data.suggestedAction == 3))) {
                                label = self.markdownActionsExpire.getDescription(params.data.editedAction);
                                eLabel = document.createTextNode(label);

                            } else {
                                eLabel = document.createTextNode("------------");
                            }
                        }
                    }

                eCell.appendChild(eLabel);

                var eSelect = document.createElement("select");
                eSelect.className = "form-control";
                eSelect.style = "margin:0px !important; border: 0px; line-height: 100%; font-size:12px !important";

            

            var containSuggestedAction = false;
            var attrIdInDropDown = null;
            for (var i = 0; i < source.length; i++) {

                if (params.data.suggestedMarkdownPlanType == 22 ) {
                    if (source[i].handLoad == true) {
                        var eOption = document.createElement("option");
                        eOption.setAttribute("value", source[i].id);
                        eOption.innerHTML = source[i].name;
                        eSelect.appendChild(eOption);
                        attrIdInDropDown = source[i].id;
                        containSuggestedAction = true;
                    }

                } else {
                    if ((params.data.markdownItemId && params.data.suggestedAction != 3 &&
                        !params.data.isHeader && source[i].suggestion == false)
                        || (params.data.isHeader == true && (source[i].suggestion == !params.data.planActive))) {

                        var eOption = document.createElement("option");
                        eOption.setAttribute("value", source[i].id);
                        eOption.innerHTML = source[i].name;
                        eSelect.appendChild(eOption);
                        attrIdInDropDown = source[i].id;

                    }
                }
                    //si no esta en el combo la accion sugerida la agrego
                    if (attrIdInDropDown != null && attrIdInDropDown == params.data.suggestedAction) {
                        containSuggestedAction = true;
                    }
                }
            if (!containSuggestedAction && !params.data.isHeader) {
                
                    var newOption = {};
                    for (var i = 0; i < self.allEditedActionFilter.length; i++) {

                        if (source[i].suggestion == !params.data.planActive ) {
                        //if (self.allEditedActionFilter[i].id == params.data.suggestedAction) {
                            newOption.id = self.allEditedActionFilter[i].id;
                            newOption.name = self.allEditedActionFilter[i].name;

                            var eOption = document.createElement("option");
                            eOption.setAttribute("value", newOption.id);
                            eOption.innerHTML = newOption.name;
                            eSelect.appendChild(eOption);
                        }

                    }
                   /* if (newOption.name != undefined) {
                        var eOption = document.createElement("option");
                        eOption.setAttribute("value", newOption.id);
                        eOption.innerHTML = newOption.name;
                        eSelect.appendChild(eOption);
                    }*/
                }
                eSelect.value = params.data[fieldId];
                eCell.addEventListener('click', function () {
                    if (!editing) {
                        eCell.removeChild(eLabel);
                        eCell.appendChild(eSelect);
                        eSelect.focus();
                        editing = true;
                    }
                });

                eSelect.addEventListener('blur', function () {
                    if (editing) {
                        editing = false;
                        eCell.removeChild(eSelect);
                        eCell.appendChild(eLabel);
                    }
                });

                eSelect.addEventListener('change', function () {
                    if (editing) {

                        var colIds = [];
                        colIds.push(params.column.colId);
                        var changedRows = [];
                        changedRows.push(params.node);

                        editing = false;
                        var newValue = '';

                        for (var i = 0; i < source.length; i++) {
                            if (source[i].id == eSelect.value) {
                                newValue = source[i].name;
                                params.data[field] = newValue;
                                params.data[fieldId] = eSelect.value;
                                //Children
                                if (params.node != null && params.node.children != null) {
                                    params.node.children.forEach(function (node) {
                                        if (node.children) {
                                            node.children.forEach(function (node) {
                                                node.data[field] = newValue;
                                                node.data[fieldId] = eSelect.value;
                                                node.data.dirty = true;
                                                changedRows.push(node);
                                            });
                                        }
                                        node.data[field] = newValue;
                                        node.data[fieldId] = eSelect.value;
                                        node.data.dirty = true;
                                        changedRows.push(node);
                                    });
                                }
                                break;
                            }
                        }


                        params.data.dirty = true;

                        params.api.refreshCells(changedRows, colIds);

                        eLabel.nodeValue = newValue;
                        eCell.removeChild(eSelect);
                        eCell.appendChild(eLabel);

                        if (onChangeCallback != undefined) {
                            onChangeCallback(params, newValue);
                        }

                    }
                });

                return eCell;
            
        }

    });

