angular.module("prisma")
    .controller("ManagementItemsCtrl", function ($scope, $rootScope, $state, $translate, adminService, $timeout, homeService, ngDialog) {

        //Variables Globales

        var self = this;

        self.categories = [];
        self.selectedCategories = [];
        self.subCategories = [];
        self.selectedSubcategories = [];
        self.lstItems = [];
        self.search = "";

        //Nuevos valores masivos para los items
        self.newBrand = "";
        self.newManufacturer = "";
        self.newTags = [];

        //Abm Etiquietas
        self.newGroupTag = "";
        self.newTagValue = "";

        self.isValidCategories = true;
        self.isLoading = false;

        self.brands = [];

        self.manufacturers = [];

        self.tags = [];        

        self.groupTag = [];       


        self.searchItems = function () {
            if (self.selectedCategories.length == 0) {
                swal(translations.ValidationSwalTitle, translations.YouMustSelectAtLeastOneCategory, 'warning');
                self.isValidCategories = false;
            }
            else {
                self.isLoading = true;
                let allSubcategories = self.selectedSubcategories.map(function (x) { return x.id; });
                angular.forEach(self.selectedCategories, function (cat) {
                    //meto todas las subcategories si su id no aparece en parents de selectedsubcats 
                    if (_.find(self.selectedSubcategories, function (sc) {
                        return sc.parentId == cat.id;
                    }) == null) {
                        allSubcategories = allSubcategories.concat(cat.subcategories.map(function (x) { return x.id; }));
                    }
                });
                let param = {
                    subCategories: allSubcategories,
                    search: self.search 
                };

                adminService.items.getItemsForManagement(param)
                    .then(function (response) {
                        self.lstItems = response;
                        self.lstItems.forEach(function (x) {
                            x.selected = true;
                        });
                        self.isLoading = false;
                    }, function () {
                        self.isLoading = false;
                        swal(translations.ErrorTitleSwal, '', 'error');
                    });

            }
        }

        self.onCategorySelected = function (category) {
            self.subCategories = [];
            angular.forEach(self.selectedCategories, function (category) {
                angular.forEach(category.subcategories, function (subcategory) {
                    self.subCategories.push(subcategory);
                });
            });
        }

        self.showNewTagModal = function () {
            ngDialog.open({
                template: 'modal-newTag',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-500',
                scope: $scope
            });
        }

        self.saveNewTag = function () {
            if (self.newGroupTag == "" || self.newTagValue == "") {
                swal(translations.ValidationSwalSubtitle, '', 'error');
                return;
            }
            adminService.tags.saveTag({ group: self.newGroupTag, name: self.newTagValue })
                .then(function (response) {
                    if (response.id) {
                        self.tags.push(response);
                        swal(translations.HasBeenSuccessfullySavedSwal, '', 'success');
                    }
                    else swal(translations.ErrorTitleSwal, '', 'error');
                }, function () {
                    swal(translations.ErrorTitleSwal, '', 'error');
                });
            ngDialog.close();
        }

        self.saveNewGroupTag = function () {
            swal({
                title: translations.NewTagGroup,
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                cancelButtonText: translations.CancelSwal,
            }, function (inputValue) {
                if (inputValue === false) return false;
                if (inputValue === "") {
                    swal.showInputError(translations.ValidationSwalSubtitle);
                    return false
                }
                self.newGroupTag = inputValue;
                self.groupTag.push(inputValue);
                $timeout(function () {
                    swal(translations.HasBeenSuccessfullySavedSwal, "", 'success');
                }, 100);
            });
        }

        self.saveNewBrand = function () {
            swal({
                title: translations.NewBrand,
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                cancelButtonText: translations.CancelSwal,
            }, function (inputValue) {
                if (inputValue === false) return false;
                if (inputValue === "") {
                    swal.showInputError(translations.ValidationSwalSubtitle);
                    return false
                }
                self.brands.push(inputValue);
                $timeout(function () {
                    swal(translations.HasBeenSuccessfullySavedSwal, translations.AsignForPersist, 'success');
                }, 100);
            });
        }

        self.saveNewManufacturer = function () {
            swal({
                title: translations.NewManufacturer,
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                cancelButtonText: translations.CancelSwal,
            }, function (inputValue) {
                if (inputValue === false) return false;
                if (inputValue === "") {
                    swal.showInputError(translations.ValidationSwalSubtitle);
                    return false
                }
                self.manufacturers.push(inputValue);
                $timeout(function () {
                    swal(translations.HasBeenSuccessfullySavedSwal, translations.AsignForPersist, 'success');
                }, 100);
         
            });
        }

        self.save = function () {
            self.isLoading = true;
            let itemsToSave = self.lstItems.filter(function (x) { return x.selected; });
            angular.forEach(itemsToSave, function (i) {
                i.brand = self.newBrand ? self.newBrand : i.brand;
                i.manufacturer = self.newManufacturer ? self.newManufacturer : i.manufacturer;
                i.tags = $rootScope.removeDuplicates(i.tags.concat(self.newTags),'id');
            });
            adminService.items.saveManagementItems(itemsToSave)
                .then(function (response) {
                    //ok
                    swal(translations.HasBeenSuccessfullySavedSwal, '', 'success');
                    self.isLoading = false;
                }, function (status) {
                    if (status == 504) {
                        //demora
                    }
                    else {
                        self.isLoading = false;
                        swal(translations.ErrorTitleSwal, '', 'error');
                    }//error
                });
        }

        //button Collapsed
        $scope.isNavCollapsed = true;
        $scope.isCollapsed = false;
        $scope.isCollapsedHorizontal = false;

        $scope.isFiltersCollapsed = false; 
        $scope.isEditItemsCollapsed = false; 
        $scope.isTableCollapsed = false;

        function loadData() {
            self.loadingData = true;
            Promise.all([
                adminService.categories.getCategoriesWithSubcategories()
                    .then(function (categories) {
                    self.categories = categories;
                    }),
                adminService.items.getManufacturers()
                    .then(function (manufacturers) {
                        self.manufacturers = manufacturers;
                    }),
                adminService.items.getItemBrands()
                    .then(function (itemBrands) {
                        self.brands = itemBrands;
                    }),
                adminService.tags.getTags()
                    .then(function (tags) {
                        self.tags = tags;
                    }),
                adminService.tags.getTagGroups()
                    .then(function (tagGroups) {
                        self.groupTag = tagGroups;
                    })
              
            ])
                .then(function () { self.loadingData = false; })
        }

        var translations = null;

        function init() {
            $translate(["ValidationSwalTitle", "YouMustSelectAtLeastOneCategory", "HasBeenSuccessfullySavedSwal", "ErrorTitleSwal", "ValidationSwalSubtitle"
                , "NewBrand", "NewManufacturer", "AsignForPersist", "CancelSwal","NewTagGroup"])
                .then(function (all) {
                    translations = all;
                });

            loadData();
        }

        init();
    });




