angular.module("prisma")
    .controller("ReportsCtrl", function ($scope, $timeout, $stateParams, $filter, $window, ngDialog, adminService) {
        var self = this;
        self.isLoading = true;
        self.users = [];
        self.roles = [];
        self.selectedTable = '';

        self.showPermissionsToRole = function (role) {
            self.rolePermissions = role.permissions;
            ngDialog.open({
                template: 'perissionsDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            });
        }

        self.downloadExcel = function () {
            switch (self.selectedTable) {
                case 'users': downloadUsersReport();
                    break;
                case 'roles': downloadRolesReport();
                    break;
                case 'permissions':
                    $timeout(function () {
                        location.href = "../../../assets/files/Permisos.xlsx";
                    }, 100);
                    break;
                default:
                    swal('Seleccione un reporte', '', 'warning');
            }
        }

        function downloadUsersReport() {
            var html = '<style>.text{mso-number-format:"\@";/*force text*/}</style><table width="100%"><thead><tr>';

            html += '<th>ID</th>';
            html += '<th>Usuario</th>';
            html += '<th>Nombre</th>';
            html += '<th>Apellido</th>';
            html += '<th>Status</th>';
            html += '<th>Creaci&oacute;n</th>>';
            //html += '<th>&Uacute;ltimo Acceso</th>';
            //html += '<th>ID Rol</th>';
            html += '<th>Rol</th>';
            html += '</tr></thead><tbody>';
        
            angular.forEach(self.users, function (u) {

                html += '<tr><td>' + u.id +
                    '</td><td>' + u.email +
                    '</td><td>' + u.firstname +
                    '</td><td>' + u.lastname +
                    '</td><td>' + (u.role == 'Disabled'? 'Bloqueado' : 'Habilitado') +
                    '</td><td>' + u.createdDate +
                    //'</td><td>' + u.lastLogin +
                    //'</td><td>' + u.rol.Id +
                    '</td><td>' + u.role +
                    '</td></tr>';

            });
            html += '</tbody></table>';

            var exportHref = ExportToExcel(html, 'Pagina1');
            $timeout(function () {
                location.href = exportHref;
            }, 100);
        }

        function downloadRolesReport() {
            var html = '<style>.text{mso-number-format:"\@";/*force text*/}</style><table width="100%"><thead><tr>';

            html += '<th>ID</th>';
            html += '<th>Descripci&oacute;n</th>';
            html += '<th>Permisos</th>';
            html += '</tr></thead><tbody>';

            angular.forEach(self.roles, function (row) {

                html +=
                    '<tr>'+
                    '<td>' + row.id +
                    '</td><td>' + row.name +
                //'</td><td>' + permissionsToString(row.permissions) +
                '</td><td>' + row.permissions.join('|') +
                    '</td></tr>';

            });
            html += '</tbody></table>';

            var exportHref = ExportToExcel(html, 'Pagina1');
            $timeout(function () {
                location.href = exportHref;
            }, 100);
        }

        function permissionsToString(permissions) {
            return permissions.map(function (x) { return x.id + ':' + x.name }).join('|');
        }

        function ExportToExcel(tableHtml, worksheetName) {
            var uri = 'data:application/vnd.ms-excel;base64,',
                template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
                base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
                format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

            var ctx = { worksheet: worksheetName, table: tableHtml },
                href = uri + base64(format(template, ctx));
            return href;
        }

        function loadData() {

            adminService.security.getUsers().then(function (response) {
                self.users = response;

                adminService.security.getRoles().then(function (response) {
                    self.roles = response;
                    self.isLoading = false;

                }, function (status) { self.isLoading = false; });
            }, function (status) { self.isLoading = false; });
        }

        function init() {
            loadData();
        }

        init();
    });