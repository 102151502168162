angular.module("prisma")
    .controller("AssortmentReportsCtrl", function ($rootScope, $scope, $state, $timeout, $interval, ngDialog, $filter, assortmentService, adminService, assortmentReportService, companyJson, $translate) {

        var self = this;
        self.brands = [];
        self.clusters = [];
        self.reportTypes = [{ value: "AssortmentSuggestionsReport", name: "Sugerencia de Altas" }];
        self.selectedReportType = self.reportTypes[0];
        self.selectedBrandId = null;
        self.selectedClusterIds = [];

        self.download = function () {
            if (!self.selectedBrandId || !self.selectedReportType) {
                swal('Seleccione un tipo de reporte y una bandera', '', 'error');
                return;
            }
            self.isLoadingReport = true;
            var dto = {
                reportType: self.selectedReportType.value,
                brandId: self.selectedBrandId,
                clusterIds: self.selectedClusterIds
            };

            assortmentReportService.downloadListingSuggestionsReport(dto)
                .then(function (url) {
                    self.isLoadingReport = false;
                    window.open(url);
                });
        }

        function init() {


            adminService.brands.getBrandsCombo(true)
                .then(function (brands) {
                    self.brands = brands;
                });

            adminService.clusters.getClusters()
                .then(function (clusters) {
                    self.clusters = clusters;
                });
        }

        init();
    });

