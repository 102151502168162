angular.module("prisma")
    .controller("AssortmentOutputColombiaCtrl", function ($scope, $rootScope, $stateParams, $filter, assortmentService) {
        var self = this;
        self.gridData = null;


        self.exportData = function () {

            swal({
                title: "Esta seguro?",
                text: 'Esta seguro que desea exportar la informacion y pasar su estado a informado?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        var params = {
                            skipHeader: false,
                            skipFooters: false,
                            skipGroups: false,
                            allColumns: true,
                            onlySelected: false,
                            suppressQuotes: false,
                            fileName: 'ExportDatosSurtido' + (new Date()).toISOString().replace(/[^0-9]/g, "").substring(0, 12) + '.csv',
                            columnSeparator: ','
                        };

                        self.gridOptions.api.exportDataAsCsv(params);

                        assortmentService.output.changeOutputStatus().then(function () {

                            swal("Confirmado", "Se exporto correctamente", "success");
                            loadData();
                        },
                            function () {
                                swal("Error", "Ha ocurrido un error", "error");
                            });
                    }
                });
        }

        //grid config
        function setColumnDefinitions() {
            columnDefs = [
                {
                    headerName: 'Datos',
                    children: [
                        {
                            headerName: "",
                            field: "",
                            width: 5,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Baja",
                            field: "",
                            width: 20,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Ean",
                            field: "ean",
                            width: 165,
                        },
                        {
                            headerName: "Estructura Comercial",
                            field: "subCategoryCode",
                            width: 180,
                        },
                        {
                            headerName: "Cambio Estructura Comercial",
                            field: "",
                            width: 155,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Descripcion del Producto",
                            field: "itemName",
                            width: 255,
                        },
                        {
                            headerName: "Tipo Articulo",
                            field: "itemTypeCode",
                            width: 150,
                        },
                        {
                            headerName: "Cambio Tipo Articulo",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Categoria",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Articulo Plataforma",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Articulo a domicilio",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Articulo a inventariar",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Articulo Importado",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Marca del articulo",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }

                        },
                        {
                            headerName: "Venta Asistida",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Temporada",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Registro Sanitario",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Fecha Final del Registro Sanitario",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Unidad de medida",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Unidad de Consumo",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Tipo de Value",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Bandera Aplicacion",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Unidades por pack",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Unidad de Medida a Comparar",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Unidad de Consumo a comparar",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Marca",
                            field: "itemBrand",
                            width: 100
                        },
                        {
                            headerName: "Ean",
                            field: "ean",
                            width: 155
                        },
                        {
                            headerName: "Estado",
                            field: "isListed",
                            width: 100
                        },
                        {
                            headerName: "Tipo Surtido",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Mix Surtido (Hipermercado)",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Mix Surtido (Proximidad)",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Surtido Nacional",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Tiendas excluidas",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "fecha inicio",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "fecha fin",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Fraccion Articulo",
                            field: "",
                            width: 55,
                            suppressMenu: true,
                            suppressSorting: true,
                            columnGroupShow: 'open',
                            cellRenderer: function (params) { return ""; }
                        },
                        {
                            headerName: "Tienda",
                            field: "store",
                            width: 400
                        },
                        {
                            headerName: "Tiendas Activas",
                            field: "totalActiveStores",
                            width: 155
                        }]
                }]
        }
        function setGridOptions() {

            setColumnDefinitions();

            self.gridOptions = {
                columnDefs: columnDefs,
                enableColResize: true,
                enableSorting: true,
                enableFilter: true,
                groupHideGroupColumns: true,
                groupUseEntireRow: false,
                groupSelectsChildren: false,
                rowHeight: 30,
                rowSelection: "multiple",
                rowDeselection: true,
                showToolPanel: false,// window.innerWidth > 1500,
                suppressRowClickSelection: true,
                suppressCellSelection: true,
                singleClickEdit: true,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                suppressColumnMoveAnimation: $rootScope.suppressColumnMoveAnimation(),
                enableRangeSelection: false,
                angularCompileRows: true,
                angularCompileHeaders: true,
            }
        }

        function loadData() {
            assortmentService.output.getEndosedDataCencosudColombia().then(function (data) {
                self.gridData = data;

                self.gridOptions.api.setRowData(self.gridData);
                self.gridOptions.api.refreshView();
            });
        }
        function init() {
            loadData();

            setGridOptions();
        }

        init();
    });
