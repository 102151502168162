angular.module('prisma').component('addLevelComponent', {
    templateUrl: 'app/spaces/layouts/addLevel.html',
    controller: addLevelController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});


function addLevelController($scope, $timeout, $translate, spaceService) {
  var self = this;
  self.level = {};
  self.imgWidth;
  self.imgheight;
  self.levelWidthMts;
  self.levelDepthMts;
  self.isBusy = false;

    if (self.resolve.modalInfo.isEdit) {
 
        var floor = self.resolve.modalInfo.floor;
        self.levelWidthMts = floor.width / 100;
        self.levelDepthMts = floor.depth / 100;
        self.level.width = floor.width;
        self.level.depth = floor.depth;
        self.level.totalSpace = floor.totalSpace;
        self.level.sellingAreaSpace = floor.sellingAreaSpace;
    }

  
  //self.calcDepth = function () {
  //  self.level.width = self.levelWidthMts * 100;
  //  self.level.depth = self.level.width * self.imgheight / self.imgWidth
  //  self.level.depth = self.level.depth.toFixed(2);
  //  self.levelDepthMts = self.level.depth / 100;
  //}

  //  self.calcWidth = function () {
  //      self.level.depth = self.levelDepthMts * 100;
  //      self.level.width = self.level.depth * self.imgWidth / self.imgheight
  //      self.level.width = self.level.width.toFixed(2);
  //      self.levelWidthMts = self.level.width / 100;
  //  }

      self.calcDepth = function () {
    
          self.level.depth = self.levelDepthMts * 100;
  }

    self.calcWidth = function () {

        self.level.width = self.levelWidthMts * 100;
    }

    $scope.uploadFile = function (files) {

        $timeout(function () {
            self.level.file = files[0]
        }, 25);


        //adminService.missions.uploadLayout(fromData).then(function (res) { console.log('res', res) })

        //spaceService.layouts.uploadLayout(dto).then(function (res) { console.log(res) })

    };

    var input = document.querySelector('#image_uploads');
    var preview = document.querySelector('.preview');

    input.style.opacity = 0;

    input.addEventListener('change', updateImageDisplay);

    function updateImageDisplay() {
        while (preview.firstChild) {
            preview.removeChild(preview.firstChild);
        }

        var curFiles = input.files;
        if (curFiles.length === 0) {
            var para = document.createElement('p');
            para.textContent = translations.NoFilesSelectedForUpload;
            preview.appendChild(para);
        } else {
            var list = document.createElement('ol');
            preview.appendChild(list);
            for (var i = 0; i < curFiles.length; i++) {
                var listItem = document.createElement('li');
                var para = document.createElement('p');
                if (validFileType(curFiles[i])) {
                    para.textContent = translations.FileName + curFiles[i].name + translations.FileSize + returnFileSize(curFiles[i].size) + '.';
                    var image = document.createElement('img');
                    image.src = window.URL.createObjectURL(curFiles[i]);
                    image.onload = function () {
                        //console.log('image', image.naturalWidth, image.width, image.naturalHeight, image.height)

                        self.imgWidth = image.naturalWidth
                        self.imgheight = image.naturalHeight
                        self.level.depth = self.level.width * image.naturalHeight / image.naturalWidth

                    }

                    image.style.height = '150px'

                    listItem.appendChild(image);
                    listItem.appendChild(para);

                } else {
                    para.textContent = translations.FileName + curFiles[i].name + translations.NotValidFileType;
                    listItem.appendChild(para);
                }

                list.appendChild(listItem);
            }
        }
    }

    var fileTypes = [
        'image/jpeg',
        'image/pjpeg',
        'image/png'
    ]

    function validFileType(file) {
        for (var i = 0; i < fileTypes.length; i++) {
            if (file.type === fileTypes[i]) {
                return true;
            }
        }

        return false;
    }

    function returnFileSize(number) {
        if (number < 1024) {
            return number + 'bytes';
        } else if (number > 1024 && number < 1048576) {
            return (number / 1024).toFixed(1) + 'KB';
        } else if (number > 1048576) {
            return (number / 1048576).toFixed(1) + 'MB';
        }
    }

    self.loadFileLayout = function (file) {

        //spaceService.layouts.uploadLayout(formdata).then(function (res) {


        //swal('imagen del Layout guardado', 'la imagen del layout se ha guardado correctamente', 'success');

        //console.log("exito", res);
        //});
    }




    function isValidField(field) {
        if (field == undefined || field == null || field == '')
            return false;

        return !isNaN(parseFloat(field));
    }

    function validar() {
        return isValidField(self.level.depth) &&
            isValidField(self.level.width) &&
            isValidField(self.level.sellingAreaSpace) &&
            isValidField(self.level.totalSpace);
    }

    self.confirm = function () {

        if (!validar()) {

            //console.log('invalido',self.level);

            swal(translations.ValidationSwalTitleAddLevel, translations.ValidationSwalSubtitleAddLevel, 'error');

            return;
        }
        else if (self.level.sellingAreaSpace > self.level.totalSpace) {
            swal('Error', '', 'error');
        }

        else if (self.level.file) {
            self.isBusy = true;
            var formData = new FormData();

            formData.append('photo', self.level.file);

            spaceService.layouts.saveLayoutLevels(formData).then(function (uri) {
                self.isBusy = false;
                console.log('saveLayoutLevel', uri);
                self.level.photoUri = uri;
                self.close({ $value: self.level });
            });
        }
        else {
            self.close({ $value: self.level });
        }




    }

    self.validateNumbers = function () {
        if (self.level.sellingAreaSpace > self.level.totalSpace)
            return true;
        else
            false
    }



    self.cancel = function () {
        self.dismiss({ $value: 'cancel' });
    }

    var translations = null;

    function init() {

        $translate(["ValidationSwalTitleAddLevel", "ValidationSwalSubtitleAddLevel", "FileName", "FileSize", "NotValidFileType", "NoFilesSelectedForUpload"])
            .then(function (all) {
                translations = all;
            });
    }
    init();
}

