angular.module("prisma")
    .controller("PriceRegionsCtrl", function ($scope, $state, $stateParams, priceService, $timeout) {

        //Variable globales

        var self = this;

        self.regions = [];

        function loadRegions() {
            priceService.regions.getRegions()
                .then(function (regions) {
                    self.regions = regions;
                });
        }

        //Funciones
        self.editRegion = function (id) {
            $state.go('prices.editRegion', { regionId: id });
        }

        self.addNew = function () {
            $state.go('prices.editRegion', { regionId: 0 });
        }

        function init() {
            loadRegions();
        }

        if ($stateParams.reload) {
            loadRegions();
        }

        init();

    });