angular.module("prisma")
    .controller("CompetitiveIndexCtrl", function ($scope, priceService, adminService, $translate, $timeout, $window) {
        var self = this;
        self.selectedName = "";
        self.lastSelectedName = "";

        self.isLoading = true;
        self.reportData = [];
        self.priceTypeId = 5; //Por default se usa el Tipo de Precio de Competencia
        self.showChipsFilter = false;
        self.hideZonesFilter = false;
        self.hideCompetitorsFilter = false;

        self.changePriceType = function (priceTypeId) {
            self.priceTypeId = priceTypeId;

            self.applyFilters();
        }

        $scope.oneAtATime = true;

        $scope.groups = [
            {
                title: 'Dynamic Group Header - 1',
                content: 'Dynamic Group Body - 1'
            },
            {
                title: 'Dynamic Group Header - 2',
                content: 'Dynamic Group Body - 2'
            }
        ];

        $scope.items = ['Item 1', 'Item 2', 'Item 3'];

        $scope.addItem = function () {
            var newItemNo = $scope.items.length + 1;
            $scope.items.push('Item ' + newItemNo);
        };

        $scope.status = {
            isCustomHeaderOpen: false,
            isFirstOpen: true,
            isFirstDisabled: false
        };

        self.filters = {
            priceLists: [],
            categories: [],
            competitorGroups: [],
            brands: [],
            tags: [],
            priceSurveyTypes: []
        }

        self.applyFilters = function () {
            self.closeFilters();

            loadReportData();

            if (self.filters.priceLists.length > 0
                || self.filters.categories.length > 0
                || self.filters.brands.length > 0
                || self.filters.tags.length > 0
                || self.filters.priceSurveyTypes.length > 0
                || self.filters.competitorGroups.length > 0) {
                self.showChipsFilter = true;
            }
            else {
                $timeout(function () {
                    self.showChipsFilter = false;
                }, 200);
            }

        }

        self.order = 'competitiveIndex';

        self.isMarker = true;

        self.reportTypes = [
            {
                id: 1,
                name: "Zona",
            },
            {
                id: 2,
                name: "Competidor",
            },
            {
                id: 3,
                name: "Categoria",
            },
            //{
            //    id: 4,
            //    name: "Subcategoria",
            //}
        ];

        self.clearSelectedFilter = function (type, id) {
            if (type == 'priceList') {
                var removeIndex = -1;
                for (var i = 0; i < self.filters.priceLists.length; i++) {
                    if (self.filters.priceLists[i].id === id) {
                        removeIndex = i;
                    }
                }

                if (removeIndex > -1) {
                    self.filters.priceLists.splice(removeIndex, 1);
                    self.applyFilters();
                }
            }

            if (type == 'category') {
                var removeIndex = -1;
                for (var i = 0; i < self.filters.categories.length; i++) {
                    if (self.filters.categories[i].id === id) {
                        removeIndex = i;
                    }
                }

                if (removeIndex > -1) {
                    self.filters.categories.splice(removeIndex, 1);
                    self.applyFilters();
                }
            }

            if (type == 'brand') {
                var removeIndex = -1;
                for (var i = 0; i < self.filters.brands.length; i++) {
                    if (self.filters.brands[i] === id) {
                        removeIndex = i;
                    }
                }

                if (removeIndex > -1) {
                    self.filters.brands.splice(removeIndex, 1);
                    self.applyFilters();
                }
            }

            if (type == 'tag') {
                var removeIndex = -1;
                for (var i = 0; i < self.filters.tags.length; i++) {
                    if (self.filters.tags[i].id === id) {
                        removeIndex = i;
                    }
                }

                if (removeIndex > -1) {
                    self.filters.tags.splice(removeIndex, 1);
                    self.applyFilters();
                }
            }

            if (type == 'priceSurveyType') {
                var removeIndex = -1;
                for (var i = 0; i < self.filters.priceSurveyTypes.length; i++) {
                    if (self.filters.priceSurveyTypes[i].priceSurveyType === id) {
                        removeIndex = i;
                    }
                }

                if (removeIndex > -1) {
                    self.filters.priceSurveyTypes.splice(removeIndex, 1);
                    self.applyFilters();
                }
            }

            if (type == 'competitorGroups') {
                var removeIndex = -1;
                for (var i = 0; i < self.filters.competitorGroups.length; i++) {
                    if (self.filters.competitorGroups[i].competitorGroups === id) {
                        removeIndex = i;
                    }
                }

                if (removeIndex > -1) {
                    self.filters.competitorGroups.splice(removeIndex, 1);
                    self.applyFilters();
                }
            }
        }

        self.closeSelectedFilters = function () {
            if (self.filters.priceLists.length <= 0
                || self.filters.categories.length <= 0
                || self.filters.brands.length <= 0
                || self.filters.tags.length <= 0
                || self.filters.priceSurveyTypes.length <= 0
                || self.filters.competitorGroups.length <= 0) {
                return 'closeFilters';
            }
        }

        self.cleanAllFilter = function () {
            self.filters.priceLists = [];
            self.filters.categories = [];
            self.filters.brands = [];
            self.filters.tags = [];
            self.filters.priceSurveyTypes = [];
            self.filters.competitorGroups = [];
       

            self.applyFilters();
        }

        self.lastDrilldownReportType = self.reportTypes[0];

        self.drillDownFunnelStep = 0;

        self.drillDown = function (itemId, itemName) {

            self.drillDownFunnelStep++;
            self.lastDrillownItemd = itemId;

            if (self.selectedReportType.id == 1) //Zonas
            {
                self.filters.priceLists = [];
                self.filters.priceLists.push({ id: itemId, name: itemName });
                self.hideZonesFilter = true;

                if (self.drillDownFunnelStep == 1) {
                    self.selectedReportType = self.reportTypes[1]; //Competidores
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[0]);
                }
                else if (self.drillDownFunnelStep == 2) {
                    self.selectedReportType = self.reportTypes[2]; //Categories
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[0]);
                    self.hideCompetitorsFilter = true;
                }

                self.applyFilters();
            }
            else if (self.selectedReportType.id == 2) //Competidor
            {
                self.filters.competitorGroups = [];
                self.filters.competitorGroups.push({ id: itemId, name: itemName });
                self.hideCompetitorsFilter = true;

                if (self.drillDownFunnelStep == 1) {
                    self.selectedReportType = self.reportTypes[0]; //Zonas
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[1]);
                }
                else if (self.drillDownFunnelStep == 2) {
                    self.selectedReportType = self.reportTypes[2];
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[1]);
                    self.hideZonesFilter = true;
                }

                self.applyFilters();
            }

            self.lastSelectedName = angular.copy(self.selectedName);

            if (self.selectedName == "")
                self.selectedName = itemName;
            else
                self.selectedName = self.selectedName + " / " + itemName
        }

        self.drillUp = function () {

            if (self.selectedReportType.id == 1) //Zonas
            {
                if (self.drillDownFunnelStep == 1) {
                    self.filters.competitorGroups = [];
                    self.selectedReportType = self.reportTypes[1];
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[0]);
                    self.drillDownFunnelStep--;
                    self.selectedName = "";
                    self.hideCompetitorsFilter = false;
                }

                self.applyFilters();
            }
            else if (self.selectedReportType.id == 2) //Competidor
            {
                if (self.drillDownFunnelStep == 1) {
                    self.filters.priceLists = [];
                    self.selectedReportType = self.reportTypes[0];
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[1]);
                    self.drillDownFunnelStep--;
                    self.selectedName = "";
                    self.hideZonesFilter = false;
                }

                self.applyFilters();
            }
            else if (self.selectedReportType.id == 3) //Categoria
            {
                if (self.lastDrilldownReportType.id == 1) { //VENGO DE ZONA
                    self.filters.priceLists = [];
                    self.selectedReportType = self.reportTypes[0];
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[1]);
                    self.drillDownFunnelStep--;
                    self.hideZonesFilter = false;
                }
                else if (self.lastDrilldownReportType.id == 2) //VENGO DE COMPETIDOR
                {
                    self.filters.competitorGroups = [];
                    self.selectedReportType = self.reportTypes[1];
                    self.lastDrilldownReportType = angular.copy(self.reportTypes[0]);
                    self.drillDownFunnelStep--;
                    self.hideCompetitorsFilter = false;
                }

                self.applyFilters();
            }

            if (self.drillDownFunnelStep == 1)
                self.selectedName = self.lastSelectedName;
        }

        self.getIcColor = function (ic) {
            if (ic != null) {
                if (ic < 0) //Negativo
                {
                    //(243, 102, 70)
                    var bgColor = 'rgba(243, 102, 70, ' + (ic.toFixed(2) * 10 * -1) + ')';
                    return { "background-color": bgColor };
                }
                else if (ic > 0) //Positivo
                {
                    var bgColor = 'rgba(103, 193, 154, ' + (ic.toFixed(2) * 10) + ')';
                    return { "background-color": bgColor };
                }

                else {
                    return { "background-color": '#FFC98E' }; //Igual a cero
                }
            }
            else {
                return "";
            }

            //ng - class="{'WarningNumber' : item.competitiveIndex <= -0.05, 'WarningNumber-1' : item.competitiveIndex <= -0.06}"
        }


        self.changeReportType = function (group, acordionStatus) {
            self.selectedReportType = group;
            self.reportTypeChanged();
            acordionStatus.open = false;
        }

        self.reportTypeChanged = function () {
            if (self.selectedReportType) {

                self.drillDownFunnelStep = 0;

                self.filters.competitorGroups = [];
                self.filters.priceLists = [];

                self.selectedName = "";

                loadReportData();
            }
        }

        self.selectedReportType = self.reportTypes[0];
        self.reportTypeChanged();

        self.pricelists = [];
        function loadPricelists() {
            priceService.pricelists.getPriceLists()
                .then(function (pricelists) {
                    self.pricelists = pricelists
                });
        }

        self.categories = [];
        function loadCategories() {
            adminService.categories.getCategories().then(function (categories) {
                self.categories = categories;
            })
        }

        self.tags = [];
        function loadTags() {
            adminService.tags.getTags()
                .then(function (tags) {
                    self.tags = tags;
                });
        }

        self.brands = [];
        function loadBrands() {
            adminService.items.getItemBrands()
                .then(function (brands) {
                    self.brands = brands;
                });
        }

        self.competitorGroups = [];
        function loadCompetitorGroups() {
            priceService.competitors.getCompetitorGroups()
                .then(function (groups) {
                    self.competitorGroups = groups;
                });
        }

        function loadReportData() {

            var filteredPriceLists = [];
            angular.forEach(self.filters.priceLists, function (item) {
                filteredPriceLists.push(item.id);
            });

            var filteredCategories = [];
            angular.forEach(self.filters.categories, function (item) {
                filteredCategories.push(item.id);
            });

            var filteredCompetitorGroups = [];
            angular.forEach(self.filters.competitorGroups, function (item) {
                filteredCompetitorGroups.push(item.id);
            });

            var filteredBrands = '';
            angular.forEach(self.filters.brands, function (brand) {
                if (filteredBrands != '')
                    filteredBrands += ',';
                filteredBrands += brand;
            });

            var filteredTags = [];
            angular.forEach(self.filters.tags, function (item) {
                filteredTags.push(item.id);
            });

            var filteredPriceSurveyTypes = [];
            angular.forEach(self.filters.priceSurveyTypes, function (item) {
                filteredPriceSurveyTypes.push(item.priceSurveyType);
            });



            var params = {
                reportType: self.selectedReportType.id,
                priceTypeId: self.priceTypeId,
                isMarker: self.isMarker,
                priceSurveyTypes: filteredPriceSurveyTypes,
                priceListIds: filteredPriceLists,
                sensitivityIds: [],
                competitorGroupIds: filteredCompetitorGroups,
                categoryIds: filteredCategories,
                tagIds: filteredTags,
                brands: filteredBrands
            };

            self.isLoading = true;
            priceService.reports.getCompetitiveIndex(params).then(function (data) {
                self.isLoading = false;

                self.reportData = data;
            });
        }

        //Filtros

        self.showFilters = false;

        self.toggleFilters = function () {

            self.showFilters = !self.showFilters;
        }

        self.getFilterClass = function () {
            if (self.showFilters == true)
                return 'move-right';
            else
                return '';
        }

        function closeFilterWhenClickingOutside(event) {

            var clickedElement = event.target;
            if (!clickedElement) return;

            var elementClasses = clickedElement.classList;
            var clickedOnFilterDiv = elementClasses.contains('isFilter')
                || elementClasses.contains('chosen-choice')
                || elementClasses.contains('chosen-choices')
                || elementClasses.contains('search-field')
                || elementClasses.contains('default')
                || elementClasses.contains('chosen-container')
                || elementClasses.contains('chosen-container')
            if (!clickedOnFilterDiv) {
                self.closeFilters();
            }

        }

        self.closeFilters = function () {
            $timeout(function () {
                self.showFilters = false;
                $window.onclick = null;
            });

        }

        //Filtro collapse

        self.isNavCollapsed = true;
        self.isCollapsedHorizontal = false;
        self.isCollapsed = true;
        self.isCategoryCollapsed = true;
        self.isZoneCollapsed = true;
        self.isBrandCollapsed = true;
        self.isTagCollapsed = true;
        self.isPriceTypeCollapsed = true;
        self.isCompetitorsGroupCollapsed = true;
        self.isTypeSurveyCollapsed = true;



        function loadData() {
            loadPricelists();
            loadTags();
            loadCategories();
            loadBrands();
            loadCompetitorGroups();

            priceService.getPriceSurveyTypes().then(
                function (priceSurveyTypes) {
                    self.priceSurveyTypes = priceSurveyTypes;
                });
        }

        function init() {
            
            loadData();
        }

        init();
    });

