angular.module("prisma")
    .controller("comunicationCtrl", function ($scope, $stateParams, $state, $sce, promotionsService, serverService, ngDialog, Upload, authService, $filter) {
     var self = this;

     //Variables Globales
     self.canUploadFiles = authService.hasPermission('promotion_comunication_uploadFiles');
     self.canDownloadBarcodes = authService.hasPermission('promotion_comunication_downloadBarcodes');
     self.isStore = authService.hasPermission('promotion_isStore');
     self.canNotifyStores = authService.hasPermission('promotion_notifyPromotionsToStore');
     self.storesCode = "";
     self.selectedMarkdownTitle = '';

     self.currDate = Date.now();

     self.search = "";

     self.currentPromotionalActionsList = [];
     self.promtoionAndMarkdownList = [];//lista visible

     self.campaignsList = [];

     self.typeOfPromotionList = [];

     self.filter = {
         id: "",
         mediaType: "",
         markdownPlanTypeStr: "",
         status: "",
         campaign: "",
         promotionType: ""
     };

     self.order = "id";

     self.inputId = "";

     self.inputStartDate = null;

     self.task = null;
     self.linkStore = '';
     self.linkItem = '';
     self.showItem = false;
     self.showStore = false;

    self.isLoading = true;  

     //Funciones

     self.notificateAllPromotionsToStores = function () {
         promotionsService.communications.notificateAllPromotionsToStores()
         .then(function () {
             swal('Promociones notificadas', 'Se ha notificado a las tiendas', 'success');
             loadData();
         }, function (status) {
             if (status == 502) {
                 swal("El proceso continua...", "El proceso de borrado continua. Puede revisar el estado en unos minutos.")
             }
             else {
                 swal("Error", "Hubo un error notificar las promociones.", "error");
             }
         });
     }


     self.filterId = function (id) {
         self.filter.id = id;
         self.inputId = "";
         self.updateVisibleList();
     }

     self.filterMediaType = function (mediaType) {
         self.filter.mediaType = mediaType;
         self.filter.markdownPlanTypeStr = "";
         self.updateVisibleList();
     }

     self.filtermarkdownPlanType = function (markdownPlanType) {
         self.filter.markdownPlanTypeStr = markdownPlanType;
         self.filter.mediaType = "";
         self.updateVisibleList();
     }

     self.filterCampaign = function (campaign) {
         self.filter.campaign = campaign;
         self.updateVisibleList();
     }

     self.filterStatus = function (status) {
         if (status == "planeada") {
             self.filter.status = "planeada";
         }
         else if (status == "historica") {
             self.filter.status = "historica";
         }
         else if (status == "activa") {
             self.filter.status = "activa";
         }
         else if (status == "Activas y Planeadas") {
             self.filter.status = "activaplaneada";
         }
         else
             self.filter.status = "";
         self.updateVisibleList();
     }

     self.filterPromotionType = function (type) {
         if (type == "externa") {
             self.filter.promotionType = "externa";
         }
         else if (type == "interna") {
             self.filter.promotionType = "interna";
         }
         else if (type == "otra") {
             self.filter.promotionType = "otra";
         }
         else
             self.filter.promotionType = "";
         self.updateVisibleList();
     }
     self.getFilterColor = function () {
         var rv = "";

         if (self.filter.status == "planeada")
             rv = "#F8AC59";
         else if (self.filter.status == "historica")
             rv = "#DD6B55";
         else if (self.filter.status == "activa")
             rv = "#1AB394";
         else if (self.filter.status == "activaplaneada")
             rv = "#ccf110";

         return rv;
     }

     self.query = function (row) {
         return (angular.lowercase(row.currentPromotionalActionsList.campaign).indexOf(angular.lowercase(self.search) || '') !== -1 ||
                 angular.lowercase(row.currentPromotionalActionsList.promotionName).indexOf(angular.lowercase(self.search) || '') !== -1 ||
                 angular.lowercase(row.currentPromotionalActionsList.startDate).indexOf(angular.lowercase(self.search) || '') !== -1 ||
                 angular.lowercase(row.currentPromotionalActionsList.finishDate).indexOf(angular.lowercase(self.search) || '') !== -1);
     };

     self.openUploadDownloadDialog = function (promotion, comType, title, icon) {

         self.upDownDialog = {
             title: title,
             icon: icon,
             promotion: promotion,
             comType: comType,
             getDownloadUrl: function () {
                 if (this.comType == 'PromotionErratas')
                     return self.upDownDialog.promotion.erratasUrl;
                 else if (this.comType == 'PromotionDigitalVersion')
                     return self.upDownDialog.promotion.digitalVersionUrl;
                 else if (this.comType == 'PromotionExhibitionGuide')
                     return self.upDownDialog.promotion.exhibitionGuideUrl;
             }
         }

         ngDialog.open({
             template: 'uploadDownloadDialog',
             className: 'ngdialog-theme-default',
             scope: $scope
         });
     }


        self.downloadComments = function (promotion) {
            promotion.isLoadingChats = true;
            promotionsService.reports.buildCommentsReport(promotion.id)
                .then(function (link) {
                    self.linkStore = link;
                    self.showSwalWithLink(link);
                    promotion.isLoadingChats = false;
                });
        }


     self.closeUploadDownloadDialog = function () {
         ngDialog.close();
     }

        self.goToPromotionSearch = function () {
            $state.go('promotions.promotionSearch');
        }

     self.openHistoricDialog = function () {
         var newScope = $scope.$new();
         var today = new Date();
         self.fromDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate() - 1);
         self.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);

         newScope.validateDateRange = function () {
             let rv = true;
             if (self.toDate >= today)
                 rv = false;
             if (self.fromDate < new Date(self.toDate.getFullYear(), self.toDate.getMonth() - 1, self.toDate.getDate()))
                 rv = false;
             return rv;
         }

         newScope.getHistoricPromotions = function () {
             if (!newScope.validateDateRange()) {
                 swal("Rango invalido","La fecha desde debe ser menor a hoy y a su vez el rango entre fechas no puede excederse de un mes.","error");
                 return;
             }

             self.isLoading = true;
             ngDialog.close();
             promotionsService.communications.getHistoricCommunications({ fromDate: self.fromDate, toDate: self.toDate })
                 .then(function (historics) {                  
                     angular.forEach(historics, function (x) {
                         if (!self.currentPromotionalActionsList.find(function (y) { return x.id == y.id }))
                             self.currentPromotionalActionsList.push(x);
                     });
                     self.filterStatus("historica");
                     swal('Filtrado por historicas', '', 'success');
                     self.isLoading = false;
                 }, function (status) {
                     if (status == 502) {
                         swal("El proceso continua...", "");
                     }
                     else {
                         self.isLoading = false;
                         swal("Error", "Hubo un error al consultar historicos", "error");
                     }
                 });
         }

         ngDialog.open({
             template: 'historicDialog',
             className: 'ngdialog-theme-default ngdialog-theme-custom',
             scope: newScope
         });
     }

     self.uploadFile = function (file, errFiles) {

         if (file) {
             var url = ian.urlCombine(serverService.getApiUrl(), '/promotion/promotions/uploadCommunicationFile');           
             self.isUploading = true;
             ///' + self.upDownDialog.promotion.id + '/' + self.upDownDialog.promotion.promotionName + '/ ' + self.upDownDialog.comType
             Upload.upload({
                 url: url,
                 data: { file: file }
             }).then(function (response) {
                 var paramsDto = {
                     promotionId: self.upDownDialog.promotion.id,
                     title: self.upDownDialog.promotion.promotionName,
                     comType: self.upDownDialog.comType,
                     url: response.data
                 }
                 promotionsService.communications.setCommunicationFile(paramsDto)
                     .then(function (response) {                        

                         if (self.upDownDialog.comType == 'PromotionErratas')
                             self.upDownDialog.promotion.erratasUrl = response;
                         else if (self.upDownDialog.comType == 'PromotionDigitalVersion')
                             self.upDownDialog.promotion.digitalVersionUrl = response;
                         else if (self.upDownDialog.comType == 'PromotionExhibitionGuide')
                             self.upDownDialog.promotion.exhibitionGuideUrl = response;

                         swal('Archivo subido con exito', 'Se ha actualizado el archivo de comunicación.', 'success');
                         self.isUploading = false;
                     }, function (response) {
                         self.isUploading = false;
                     });

             }, function () {
                 self.isUploading = false;
             });
         }
         else if (errFiles && errFiles[0]) {
             var file = errFiles[0];

             var allowedSize = file.$errorParam;
             var size = (file.size / 1024).toFixed(0);

             swal('Archivo invalido', ian.format('El archivo {0} pesa {1}KB y el peso máximo permitido es: {2}', file.name, size, allowedSize), 'error');
         }
     }

     self.openDownloadDialog = function (promotion) {
         self.selectedPromotion = promotion;

         if ($stateParams.taskId && $stateParams.taskId != 0) //Si entre por un task
         {
             if (self.showItem || self.showStore) {
                 ngDialog.open({
                     template: 'downloadDialog',
                     className: 'ngdialog-theme-default',
                     scope: $scope
                 });
             }
         }
         else if (promotion.id == 0)//es una liquidacion
         {
             self.showItem = true;
             self.selectedMarkdownTitle = promotion.promotionName;//title
             self.selectedMarkdownPlanType = promotion.markdownPlanType;
             ngDialog.open({
                 template: 'downloadMarkdownDialog',
                 className: 'ngdialog-theme-default',
                 scope: $scope
             });
         }
         else //Si entro por el menu, le habilito ambos reportes para bajar si no es tienda. Si es de tienda, le habilito solo los que estén publicados al abrir la promo
         {
             //if (self.isStore)
             //{
             //    if (self.selectedPromotion.isComPPublished)
             //        self.showStore = true;
             //    else
             //        self.showStore = false;

             //    if (self.selectedPromotion.isComunicationPublished) {
             //        self.showItem = true;
             //        self.showStore = true;
             //    }
             //    else
             //        self.showItem = false;
             //}
             //else {
             //    self.showStore = true;
             //    self.showItem = true;
             //}

             if (companyJson.Promotions.AllowDownloadCommunications) {
                 self.showStore = true;
                 self.showItem = true;
                 ngDialog.open({
                     template: 'downloadDialog',
                     className: 'ngdialog-theme-default',
                     scope: $scope
                 });
             }
         }

     }

     self.generateAndDownloadItems = function () {
         self.isLoading = true;
         var promotionId = (self.task && self.task.promotionId) ? self.task.promotionId : self.selectedPromotion.id;

         promotionsService.reports.buildItemTasksReport(promotionId)

             .then(function (link) {
                 var p = self.promtoionAndMarkdownList.find(function (x) {
                     return x.id == promotionId;
                 })
                 if (p) {
                     p.communicationReportDownloaded = true;
                 }
                 self.linkItem = link;
                 self.showSwalWithLink(link);
                 self.isLoading = false;
             });


     }

     self.downloadMarkdownItems = function (expiredOnly, rejectedOnly) {
         self.isLoading = true;

         promotionsService.reports.buildMarkdownItemsReport({ title: self.selectedMarkdownTitle, expiredOnly: expiredOnly, rejectedOnly: rejectedOnly })

             .then(function (link) {
                 self.linkItem = link;
                 self.showSwalWithLink(link);
                 self.isLoading = false;
             });


     }

     self.generateAndDownloadItemsForStores = function () {
         self.isLoading = true;
         var promotionId = (self.task && self.task.promotionId) ? self.task.promotionId : self.selectedPromotion.id;

         promotionsService.reports.buildStoreItemTasksReport(promotionId)

             .then(function (link) {

                 self.linkItem = link;
                 self.showSwalWithLink(link);
                 self.isLoading = false;
             });


     }

     self.generateAndDownloadStores = function () {
         self.isLoading = true;
         var promotionId = (self.task && self.task.promotionId) ? self.task.promotionId : self.selectedPromotion.id;
         promotionsService.reports.buildItemStoreReport(promotionId)
             .then(function (link) {
                 var p = self.promtoionAndMarkdownList.find(function (x) {
                     return x.id == promotionId;
                 })
                 if (p) {
                     p.communicationReportDownloaded = true;
                 }
                     self.linkStore = link;
                     self.showSwalWithLink(link);
                     self.isLoading = false;
                 });
     }

     self.showSwalWithLink = function (link) {
         swal({

             title: "Reporte Generado!",
             text: 'Haga click <a href="' + link + '"><u style = "color: #1AB394;"><b style = "color: #1AB394;">AQUÍ</b></u></a> para descargar el archivo',
             confirmButtonText: "Cerrar",
             confirmButtonColor: "#c2c2c2",
             html: true
         });
        }

        self.downloadItemBarCodes = function (promotionId) {
            self.isLoading = true;
            promotionsService.reports.buildItemBarCodesReport(promotionId)
                .then(function (link) {
                    self.linkStore = link;
                    self.showSwalWithLink(link);
                    self.isLoading = false;
                });
        }

     self.informPromotion = function () {
         var promotionId = (self.task && self.task.promotionId) ? self.task.promotionId : 0;
         if (promotionId == 0)
             swal('Error', 'No se pudo detectar la promocion a informar.', 'error');
         else
             promotionsService.promotions.informPromotion(promotionId, self.task.id)
         .then(function () {
             swal('Promocion Informada!', '', 'success');
         });
     }

     self.comuicatePromotion = function () {
         var promotionId = (self.task && self.task.promotionId) ? self.task.promotionId : 0;
         if (promotionId == 0)
             swal('Error', 'No se pudo detectar la promocion a comunicar.', 'error');
         else
             promotionsService.promotions.comuicatePromotion(promotionId, self.task.id)
         .then(function () {
             swal('Promocion Comunicada!', '', 'success');
         });
     }

     //PAGINADO
     self.totalPages = function () {
         return Math.ceil(self.currentPromotionalActionsList.length / 50);
     }
     self.currentPage = 1;
     self.previousPage = function () {
         self.currentPage--;
         self.updateVisibleList();
     }

     self.nextPage = function () {
         self.currentPage++;
         self.updateVisibleList();
     }
     self.assistantList = [];
        self.updateVisibleList = function () {
            if (self.filter.status == 'activaplaneada')//por mal desarrollo de filtro por status me obliga a agregar este if en caso de filtro por activa y planeada a la vez
            {
                self.assistantList = self.currentPromotionalActionsList.filter(function (x) {
                    return self.filter.status.indexOf(x.status.toLocaleLowerCase()) > -1;
                });
            }
            else {
                self.assistantList = $filter('filter')(self.currentPromotionalActionsList, self.filter);
            }

            if (self.search != '')
                self.assistantList = $filter('filter')(self.assistantList, self.search);
            let begin = (self.currentPage - 1) * 50;
            let end = begin + 50;
            self.promtoionAndMarkdownList = self.assistantList.slice(begin, end);
        }

     //FIN DE PAGINADO

     function init() {

         promotionsService.campaigns.getCampaigns()
            .then(function (responseCampaings) {
                self.campaignsList = responseCampaings;
            });

         self.typeOfPromotionList = promotionsService.promotionMediaTypes;

         promotionsService.stores.getUserStores()
         .then(function (storesCode) {
             self.storesCode = storesCode;
         });

         if ($stateParams.taskId && $stateParams.taskId != 0) { //Entro por la tarea de comunicacion o comunicacion preliminar
             promotionsService.tasks.getTask($stateParams.taskId)
             .then(function (task) {
                 self.task = task;

                 if (task.name == 'Comunicación Preliminar')
                     self.showStore = true;
                 else if (task.name == 'Comunicación') {
                     self.showStore = true;
                     self.showItem = true;
                 }
                 promotionsService.communications.getCommunication(task.promotionId)
                     .then(function (promoComms) {
                         self.currentPromotionalActionsList.push(promoComms);
                         self.updateVisibleList();

                     });
             });
             self.isLoading = false;
         }
         else {
             promotionsService.communications.getCommunications().then(function (promoComms) {
                 //perfil de tienda no ve historicas
                 if (promotionsService.getUserRolesByArea("Operaciones").indexOf(authService.authentication.roles[0]) > -1)
                 {
                     self.isStoreProfile = true;
                     promoComms = promoComms.filter(function (p) {
                         let today = new Date();                        
                         let finishDate = new Date(p.finishDate);
                         let toDate = new Date(finishDate.setDate(finishDate.getDate() + 2));
                         return toDate >= today;
                     });
                 }

                 self.currentPromotionalActionsList = promoComms;

                 self.markdownPlanTypes = [];

                 _.forOwn(_.groupBy(promoComms, "markdownPlanTypeStr"), function (value, key) {
                     self.markdownPlanTypes.push(key);
                 });

                 self.updateVisibleList();

                 self.isLoading = false;
             });
         }



     }


     init();

 });