(function () {
    'use strict';

    agGrid.initialiseAgGridWithAngular1(angular);

    angular.module('prisma', [
          'ngAnimate',
          'ngCookies',
          'ngTouch',
          'ui.select',
          'ngSanitize',
          'ngResource',
          'ngMessages',
          'ngAria',
          'ngDialog',
          'ui.router',
          'ui.bootstrap',
          //'ui.bootstrap.datetimepicker',
          "pascalprecht.translate",
          "angular-flot",
          "gantt",
          'gantt.sortable',
          'gantt.movable',
          'gantt.drawtask',
          'gantt.tooltips',
          'gantt.bounds',
          'gantt.progress',
          'gantt.table',
          'gantt.tree',
          'gantt.groups',
          'gantt.dependencies',
          'gantt.overlap',
          'gantt.resizeSensor',
          "angularMoment",
          //"jsplumb",
          "agGrid",
          "angular-ladda",
          "ngFileUpload",
          "angucomplete-alt",
          "localytics.directives",
          "angular.filter",
          "LocalStorageModule",
          "color.picker",
          "rzModule",
          "dndLists",
          "laneolson.ui.dragdrop",
          //"slick",
          //"slick-carousel"
    ]);

})();
