angular.module("prisma")
    .controller("priceOutputCtrl", function ($scope, priceService, $translate, $rootScope, $window, $timeout) {
        var self = this;

        var allSelected = true;
        self.loadingPrices = false;
        self.informingPrices = false;

        self.pricesToInform = [];
        
        self.implementation = companyJson.Erp.Implementation;
        self.reasons = [];

        self.selectedReasons = [];
        self.selectedPriceType = null;

        self.showPriceGrid = false;

        //self.canInform = function () {
        //    return self.pricesToInform.length > 0;
        //}


        self.informToErp = function () {

            //if (!self.canInform()) {
            //    swal("No hay precios a informar", "Los filtros seleccionados no han devuelto ningun precio a informar.", "warning");
            //    return;
            //}

            var title = translations.ReportFilteredPricesToTheERP;
            var text = '';
            var closeOnConfirm = false;


            var swalOptions = {
                title: title,
                text: text,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: closeOnConfirm,
                closeOnCancel: true
            }
            swal(swalOptions, doInform);
        }

        function doInform(isConfirm) {

            if (!isConfirm)
                return;

            self.informingPrices = true;
            //preparo el dto reducido para el save
            //var idsToInform = getIdsToInform();

            var filters = {
                priceTypeId: self.selectedPriceType,
                reasons: (self.selectedPriceType == 4 || self.selectedPriceType == 5 || self.selectedPriceType == 6 || self.selectedPriceType == 2) ? self.selectedReasons.map(function (x) { return x.reason }) : [], //Solo lo envio si tengo precios de promocion o Competencia o Regular
            }

            if (self.implementation != 'LaRebaja' && filters.priceTypeId == null) {
                self.informingPrices = false;
                swalOptions.showLoaderOnConfirm = false; //fix queda cargando si no se elige motivo o type
                swal(translations.YouMustSelectAPriceType, translations.YouMustSelectAPriceTypeSubtitle, "error");
            }
            else if (self.implementation != 'LaRebaja' && (filters.priceTypeId == 4 || filters.priceTypeId == 5 || filters.priceTypeId == 6 || self.selectedPriceType == 2) && filters.reasons.length == 0) {
                self.informingPrices = false;
                swalOptions.showLoaderOnConfirm = false; //fix queda cargando si no se elige motivo o type
                swal(translations.YouMustSelectAReason, translations.YouMustSelectAReasonSubtitle, "error");
            }
            else {

                priceService.priceOutput.informToErp(filters).then(function (informResult) {

                    ////DESCARGAR AUTOMATICAMENTE EL EXCEL
                    //if (informResult && informResult.generatedReportUrl) {
                    //    $window.open(informResult.generatedReportUrl);
                    //}

                    swal(translations.GeneratedPriceReport, translations.GeneratedPriceReportSubtitle, "success");

                    //Vuelvo a cargar la info
                    //self.getFilteredPrices();
                    loadPreviousReports();

                    loadPreviousReports();
                    self.informingPrices = false;
                },
                    function (fallback) {
                        console.log(fallback);
                        self.informingPrices = false;
                        swal(translations.ErrorTitleSwal, translations.AnErrorHasOccurred, "error");
                    });

            }
        }

        self.downloadLastReport = function () {
            priceService.priceOutput.downloadLastOutput().then(function (reportUrl) {
                if (reportUrl) {
                    $window.open(reportUrl);
                }
            });
        }

        self.downloadFile = function (url) {
            $window.open(url);
        }

        //self.getFilteredPrices = function () {

        //    self.showPriceGrid = true;

        //    //if (self.gridOptions.api)
        //    //    self.gridOptions.api.showLoadingOverlay();

        //    var filters = {
        //        priceTypeId: self.selectedPriceType,
        //        reasons: (self.selectedPriceType == 4 || self.selectedPriceType == 5) ? self.selectedReasons.map(function (x) { return x.reason }) : [], //Solo lo envio si tengo precios de promocion o Competencia
        //    }

        //    if (filters.priceTypeId == null)
        //        swal("Debe seleccionar un tipo de precio.", " No se ha seleccionado ningun tipo de precio.", "error");
        //    else if ((filters.priceTypeId == 4 || filters.priceTypeId == 5) && filters.reasons.length == 0) {
        //        swal("Debe seleccionar un motivo para estos tipos de precio.", " En el caso de que no aparezca ninguno, no existen precios a informar del tipo seleccionado.", "error");
        //    }
        //    else {
        //        self.loadingPrices = true;
        //        priceService.priceOutput.getStorePricesToInformFiltered(filters)
        //            .then(function (pricesToInform) {
        //                self.pricesToInform = pricesToInform;
        //                self.loadingPrices = false;

        //                //self.gridOptions.api.setRowData(pricesToInform);
        //            });
        //    }
        //}

        ////grid config
        

        //function setColumnDefinitions() {
        //    var colWidth = 50;

        //    columnDefs = [
        //        {
        //            headerName: 'Informacion Básica',
        //            children: [
        //                {
        //                    headerName: "Tienda",
        //                    field: "storeCode",
        //                    checkboxSelection: true,
        //                    headerCheckboxSelection: true,
        //                    headerCheckboxSelectionFilteredOnly: true,
        //                    pinned: 'left',
        //                    width: colWidth + 20,
        //                    cellClass: $rootScope.getClassForCel
        //                },
        //                {
        //                    headerName: "Limite",
        //                    field: "storeLimit",
        //                    pinned: 'left',
        //                    width: colWidth + 20,
        //                },
        //                {
        //                    headerName: "Activo",
        //                    field: "isListed",
        //                    pinned: 'left',
        //                    width: colWidth + 20,
        //                    valueGetter: 'data.isListed ? "Sí" : "No"'
        //                },
        //                {
        //                    headerName: "Subcat. Cod",
        //                    field: "subcategoryCode",
        //                    columnGroupShow: 'open',
        //                    pinned: 'left',
        //                    width: colWidth + 50,
        //                    cellClass: $rootScope.getClassForCell
        //                },
        //                {
        //                    headerName: "Subcategoría",
        //                    field: "subcategory",
        //                    pinned: 'left',
        //                    width: colWidth + 50,
        //                    cellClass: $rootScope.getClassForCell
        //                },
        //                {
        //                    headerName: "Codigo",
        //                    field: "itemCode",
        //                    pinned: 'left',
        //                    width: colWidth + 50,
        //                    cellClass: $rootScope.getClassForCell
        //                },
        //                {
        //                    headerName: "Descripcion",
        //                    field: "itemName",
        //                    pinned: 'left',
        //                    width: colWidth + 200,
        //                    cellClass: $rootScope.getClassForCell
        //                },
        //                {
        //                    headerName: "UM",
        //                    field: "unitOfMeasureCode",
        //                    headerTooltip: "Unidad de Medida de Venta",
        //                    pinned: 'left',
        //                    width: colWidth + 10,
        //                    cellClass: $rootScope.getClassForCell
        //                }]
        //        },
        //        {
        //            headerName: 'Información Adicional',
        //            children: [{
        //                headerName: "Desc. Tienda",
        //                columnGroupShow: 'open',
        //                field: "storeName",
        //                //pinned: 'left',
        //                width: colWidth + 200,
        //                cellClass: $rootScope.getClassForCell
        //            },
        //            {
        //                headerName: "Sector",
        //                field: "categoryGroup",
        //                //pinned: 'left',
        //                width: colWidth + 50,
        //                cellClass: $rootScope.getClassForCell
        //            },
        //            {
        //                headerName: "Categoría",
        //                field: "category",
        //                //pinned: 'left',
        //                width: colWidth + 50,
        //                cellClass: $rootScope.getClassForCell
        //            },
        //            {
        //                headerName: "EAN",
        //                field: "itemEan",
        //                columnGroupShow: 'open',
        //                headerTooltip: "",
        //                //pinned: 'left',
        //                width: colWidth + 100,
        //                cellClass: $rootScope.getClassForCell
        //            }]
        //        },
        //        {
        //            headerName: 'Precio',
        //            children: [
        //                {
        //                    headerName: "TP",
        //                    field: "priceTypeDesc",
        //                    width: 100,
        //                    filter: 'text',
        //                    cellClass: $rootScope.getClassForCell,
        //                },
        //                {
        //                    headerName: "Actual",
        //                    field: "price",
        //                    width: 100,
        //                    filter: 'number',
        //                    cellClass: $rootScope.getClassForCell,
        //                    cellRenderer: $rootScope.moneyRenderer
        //                },
        //                {
        //                    headerName: "Nuevo",
        //                    field: "newPrice",
        //                    width: 100,
        //                    filter: 'number',
        //                    cellClass: $rootScope.getClassForCell,
        //                    cellRenderer: $rootScope.moneyRenderer
        //                },
        //                {
        //                    headerName: "F.Desde",
        //                    field: "newValidFromExtended",
        //                    width: 90,
        //                    filter: 'text',
        //                    cellRenderer: $rootScope.dateRenderer
        //                },
        //                {
        //                    headerName: "F.Hasta",
        //                    field: "newValidToExtended",
        //                    width: 90,
        //                    filter: 'text',
        //                    cellRenderer: $rootScope.dateRenderer
        //                },
        //                {
        //                    headerName: "Motivo",
        //                    field: "reason",
        //                    width: 200,
        //                    filter: 'text',
        //                }]
        //        }]
        //}

        //function setGridOptions() {

        //    setColumnDefinitions();


        //    self.gridOptions = {
        //        columnDefs: columnDefs,
        //        enableColResize: true,
        //        enableSorting: true,
        //        enableFilter: true,
        //        groupHeaders: true,
        //        groupKeys: undefined, //set as string of keys eg ["region","country"],
        //        groupHideGroupColumns: true,
        //        //groupUseEntireRow: false,
        //        groupSelectsChildren: false,
        //        rowHeight: 30,
        //        rowSelection: "multiple", // one of ['single','multiple'], leave blank for no selection
        //        suppressRowClickSelection: true,
        //        singleClickEdit: true,
        //        icons: {
        //            columnRemoveFromGroup: '<i class="fa fa-remove"/>',
        //            filter: '<i class="fa fa-filter"/>',
        //            sortAscending: '<i class="fa fa-long-arrow-down"/>',
        //            sortDescending: '<i class="fa fa-long-arrow-up"/>',
        //            groupExpanded: '<i class="fa fa-minus-square-o"/>',
        //            groupContracted: '<i class="fa fa-plus-square-o"/>',
        //            columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
        //            columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
        //        },
        //        suppressColumnMoveAnimation: $rootScope.suppressColumnMoveAnimation,
        //        suppressCellSelection: true,
        //        enableRangeSelection: false,
        //        //angularCompileRows: true,
        //        //onSelectionChanged: function () {
        //        //    $timeout(function () {
        //        //        self.selectedCount = self.gridOptions.api.getSelectedRows().length;
        //        //    }, 0);
        //        //}
        //    }
        //}

        //function getIdsToInform() {

        //    var idsToInform = self.pricesToInform.map(function (p) {
        //        return {
        //            priceType: p.priceTypeId,
        //            subcategoryId: p.subcategoryId,
        //            itemId: p.itemId,
        //            unitOfMeasureId: p.unitOfMeasureId,
        //            storeId: p.storeId,
        //            reason: p.reason,
        //        }
        //    })

        //    return idsToInform;
        //}



        function loadData() {

            loadPreviousReports();

        }

        self.getReasonsFilter = function () {
            self.loadingReasons = true;

            priceService.priceOutput.getReasonsFilter(self.selectedPriceType).then(function (reasons) {
                self.reasons = reasons;
                self.loadingReasons = false;
            },
                function (fallback) {
                    self.loadingReasons = false;
                    console.log(fallback);
                });
        }

        function loadPreviousReports() {
            priceService.priceOutput.getLastOutputReports()
                .then(function (reports) {
                    self.generatedFiles = reports;
                });

        }

        var translations = null;

        function init() {

            $translate(["ReportFilteredPricesToTheERP", "YouMustSelectAPriceType", "YouMustSelectAPriceTypeSubtitle"
                , "YouMustSelectAReason", "YouMustSelectAReasonSubtitle", "GeneratedPriceReport", "GeneratedPriceReportSubtitle"
                , "ErrorTitleSwal", "AnErrorHasOccurred", "CancelSwal", "ContinueSwal", "Normal", "Competitive", "Liquidation"])
                .then(function (all) {
                    translations = all;

                    self.distinctPriceTypes = [
                        { id: 6, name: translations.Normal },
                        { id: 5, name: translations.Competitive },
                        //{ id: 4, name: "Promoción y Bonus" },
                        //{ id: 3, name: "Bonus" },
                        { id: 2, name: translations.Liquidation }
                    ];
                });

            //setGridOptions();

            loadData();
        }

        init();
    });

