angular.module("prisma")
    .controller("EditTicketsTypesCtrl", function ($scope, $stateParams, $state, spaceService, $translate) {

   //Varibles
        var self = this;
        self.stateChange = false;

   var TICKET_TYPES = {
     TODOS: 0,
     PLANOGRAM: 1,
     ITEM: 2
   }

   var saveSwalTitle = "";

   self.validationMessage = "";
   self.saveSwalTitle = "";
   self.objTypes = [];
   self.ticketType = {
     id: $stateParams.ticketTypeId,
     name: '',
     type: null,
     needsDescription: false
   };


   self.cancel = function () {
     $state.go('spaces.ticketsTypes');
   }

   self.save = function () {

       swal(translations.SaveEditTicketTitle, translations.SaveEditTicketTitleSubtitle, 'success');

       var data = self.ticketType;

      //console.log('data', data);

      spaceService.tickets.saveTicketType(data).then(function () {
        //console.log('then after speceService')
        //swal("Confirmado", storeSaved, "success");
          
        self.cancel();
      })

      self.stateChange = false;
   }

  

   function validateBeforeSave() {
     return '';
   }

   self.isValid = function () {

     if (!self.ticketType.name) {
         self.validationMessage = translations.ValidationNameTicket;
       return false;
     } else if (!self.ticketType.type) {
         if(self.ticketType.type == 0){
           self.validationMessage = "";
           return true;
         }
         self.validationMessage = translations.ValidationTypeTicket;
         return false;
     } else {
         self.validationMessage = "";
         return true;
     }
   }

   $scope.$on('$stateChangeStart', function (event) {
       //console.log('$stateChangeStart', event)
       if (self.stateChange) {
           var answer = confirm(translations.AlertWillLoseChanges)
           if (!answer) {
               event.preventDefault();
           }
       }
   });

   var translations = null;

   function init() {
       $translate(["ValidationNameTicket", "ValidationTypeTicket", "AlertWillLoseChanges", "SaveEditTicketTitle",
           "SaveEditTicketTitleSubtitle", "All", "Planogram"])
           .then(function (all) {
               translations = all;

               self.objTypes = [
                   { name: translations.All, value: 0 },
                   { name: translations.Planogram, value: 1 },
                   { name: 'Item', value: 2 }
               ]
           });


       if ($stateParams.ticketTypeId > 0) {
           spaceService.tickets.getTicketTypeById($stateParams.ticketTypeId).then(function (ticketType) {

               self.ticketType.id = ticketType.id;
               self.ticketType.name = ticketType.name;
               self.ticketType.type = ticketType.type;
               self.ticketType.needsDescription = ticketType.needsDescription;
               console.log('ticketType', ticketType);

           });
       }

   }

   init();
 });