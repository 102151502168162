angular.module("prisma")
    .controller("ItemsToIgnoreCtrl", function ($scope, adminService, $translate, $timeout, $stateParams, $filter, ngDialog, $window) {
        var self = this;

        //self.itemsToIgnore = [];
        //self.search = '';

        self.downloadLastReport = function () {

            self.isLoading = true;
            adminService.reports.getItemsToIgnoreLastReport().then(function (link) {
                self.isLoading = false;
                $timeout(function () {
                    location.href = link;
                }, 100); // trigger download
            });

        }

        self.exportToExcel = function () {

            self.isLoading = true;
            adminService.reports.exportItemsToIgnore().then(function (link) {
                self.isLoading = false;
                swal({
                    title: "Reporte Generado!",
                    text: 'Haga click <a href="' + link + '"><u style = "color: #1AB394;"><b style = "color: #1AB394;">AQUÍ</b></u></a> para descargar el archivo',
                    confirmButtonText: "Cerrar",
                    confirmButtonColor: "#c2c2c2",
                    html: true
                });
            });
        }

    
        //function init() {

        //        self.isLoading = true;
        //        adminService.reports.getItemsToIgnore().then(function (itemsToIgnore) {
        //            self.itemsToIgnore = itemsToIgnore;
        //            self.isLoading = false;

        //        });
        //}

        ////Hacer scrolleable la tabla
        //$('tbody').scroll(function () {
        //    $('thead').css("left", -$("tbody").scrollLeft());
        //    //$('thead th:nth-child(1)').css("left", $("tbody").scrollLeft());
        //    $('tbody td:nth-child(1)').css("left", $("tbody").scrollLeft());
        //});


        //init();
    });