angular.module("prisma")
    .controller("EditCostCtrl", function ($scope, $state, $stateParams, priceService, $timeout, $translate) {

     //Variable globales
     var self = this;

     var currentDate = new Date();

     self.subsidy = 0;

     self.calculatedCost = 0;

     self.item = {
         id: 0,
         tax: 0,
         taxRate: 0,
         orderUnitOfMeasureId: 0,
         orderUnitOfMeasureCode: '',
         code: '',
         name: ''
     };

     self.cost = {
         id: 0,
         vendorId: 0,
         itemId: 0,
         storeId: 0,
         itemName: '',
         itemCode: '',
         storeCode: '',
         vendorCode: '',
         orderUnitOfMeasureId: 0,
         orderUnitOfMeasureCode: '',
         subsidy: 0,
         cost: 0,
         tax: 0,
         validFrom: currentDate
     }

     self.itemChanged = function () {
         //console.log(self.item);

         self.cost.itemId = self.item.id;
         self.cost.tax = self.item.tax;
         self.cost.taxRate = self.item.taxRate;
         self.cost.orderUnitOfMeasureId = self.item.orderUnitOfMeasureId;
         self.cost.orderUnitOfMeasureCode = self.item.orderUnitOfMeasureCode;
     }

     self.vendors = [];

     self.items = [];

     self.distributionCenters = [];



     self.isDisabled = function () {
         //console.log('cost', self.cost);
         if (!self.cost.cost) {
             return true;
         }

         if (!self.cost.vendorId) {
             return true;
         }



         if (!self.cost.itemId) {
             return true;
         }

         if (!self.cost.storeId) {
             return true;
         }

         if (!self.cost.truckCost) {
             return true;
         }

         //if (!self.cost.subsidy) {
         //    return true;
         //}
         return false;
     }

     $scope.$watch('ct.cost', function (newVal, oldVal) {
         if (newVal.cost > 0) {
             if (!newVal.subsidy)
                 newVal.subsidy = 0;

             self.calculatedCost = newVal.cost - (newVal.cost * newVal.subsidy / 100) + newVal.truckCost;
         }
     }, true);


     //Funciones
     self.save = function (generateSuggestions) {

         if (self.cost.id == 0 && self.cost.validFrom < currentDate) {
             swal(translations.ErrorTitleSwal, translations.ValidationOfDates, "error");

             self.cost.validFrom = currentDate;
         }
         else {
             var title = translations.YouWantToSaveThisNewCost;
             if (generateSuggestions)
                 title = translations.YouWantToSaveThisNewCostSubtitle;

             swal({
                 title: title,
                 type: "warning",
                 showCancelButton: true,
                 confirmButtonColor: "#1AB394",
                 confirmButtonText: translations.Save,
                 cancelButtonText: translations.Cancel,
                 showLoaderOnConfirm: true,
                 closeOnConfirm: false,
                 closeOnCancel: true
             },
                function (isConfirm) {
                    if (isConfirm) {
                        self.cost.cost = self.calculatedCost;

                        if (self.cost.subsidy > 1)
                            swal(translations.ErrorTitleSwal, translations.TheSubsidyCanNotBeGreaterThan100, 'error');
                        else {
                            if (!generateSuggestions) {
                                priceService.costs.save(self.cost)
                                   .then(function (status) {
                                       swal(translations.SavedCost, translations.HasBeenSuccessfullySavedSwal, 'success');

                                       $state.go('prices.costs');
                                   });
                            }
                            else {
                                priceService.costs.saveAndGenerateSuggestions(self.cost)
                                   .then(function (suggestions) {
                                       var message = translations.SavedCostSubtitle + (suggestions > 0 ? translations.SavedCostSubtitle1 + suggestions + translations.SavedCostSubtitle2 : '');
                                       swal(translations.SavedCost, message, 'success');

                                       $state.go('prices.costs');
                                   });
                            }
                        }
                    }
                });
         }
     }



     self.storeSelected = function () {
         angular.forEach(self.distributionCenters, function (distributionCenter) {
             if (distributionCenter.id == self.cost.storeId) {
                 self.cost.storeCode = distributionCenter.code;
             }
         });
     }

     self.vendorSelected = function () {
         angular.forEach(self.vendors, function (vendor) {
             if (vendor.id == self.cost.vendorId) {
                 self.cost.vendorCode = vendor.code;
             }
         });
     }

     self.itemSelected = function () {
         angular.forEach(self.items, function (item) {
             if (item.id == self.cost.itemId) {
                 self.cost.tax = item.tax;
                 self.cost.taxRate = item.taxRate;
                 self.cost.orderUnitOfMeasureId = item.orderUnitOfMeasureId;
                 self.cost.orderUnitOfMeasureCode = item.orderUnitOfMeasureCode;
             }
         });
     }


     function getVendors() {

         priceService.costs.getVendors()
            .then(function (vendors) {
                self.vendors = vendors;
                if (vendors != null && vendors.length > 0) {
                    self.cost.vendorCode = vendors[0].code;
                    self.cost.vendorId = vendors[0].id;
                }
            });

     }

     function getDistributionCenters() {

         priceService.costs.getDistributionCenters()
            .then(function (distributionCenters) {
                self.distributionCenters = distributionCenters;
            });

     }

     function getItems() {

         priceService.costs.getItems()
            .then(function (items) {
                self.items = items;
            });

     }

     var translations = null;

     function init() {

         $translate(["ErrorTitleSwal", "ValidationOfDates", "YouWantToSaveThisNewCost", "YouWantToSaveThisNewCostSubtitle", "Save", "Cancel"
             , "TheSubsidyCanNotBeGreaterThan100", "SavedCost", "HasBeenSuccessfullySavedSwal", "SavedCostSubtitle", "SavedCostSubtitle2"])
             .then(function (all) {
                 translations = all;
             });

         getVendors();
         getItems();
         getDistributionCenters();

     }

     init();

 });




