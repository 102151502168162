
angular.module("prisma")
    .controller("editSpacesLayoutCtrl", function ($state, $stateParams, $scope, $timeout, $rootScope, $window, $translate, $compile, $uibModal, spaceService, adminService, ngDialog, authService) {


        var TICKET_TYPES = {
            PLANOGRAM: 1,
            ITEM: 2
        }
        var self = this;
        self.layout = { stores: [] }
        self.levels = [];
        self.currentFloor = 0;
        self.polygonContainer = null;
        self.selectedLevelItemId = null;
        self.showAttributes = false;
        self.selectedLevelItem = null;
        self.layout = null;
        self.categories = [];
        self.layoutCategories = [];

        self.ticketsCount = 3;
        self.selectedSvg = null;
        self.fileLayout = null;
        self.stateChange = false;
        self.hasPermissionToEdit = authService.hasPermission('spaces_edit_layout');
        self.translationUnits = "";
        self.translationSales = "";
        self.translationMargin = "";
        self.translationPrice = "";
        self.translationInventory = "";
        self.translationStockOut = "";
        self.translationLow = "";
        self.translationMedium = "";
        self.translationHeightF = "";
        self.showSubcategories = false;        

        self.categoryColors = [
            '#2471A3',
            '#27AE60',
            '#F1C40F',
            '#D35400',
            '#7D3C98',
            '#82E0AA',
            '#F9E79F',
            '#3498DB',
            '#D2B4DE',
            '#E67E22',
            '#CD5C5C',
            '#D98880',
            '#27AE60',
            '#2471A3',
            '#AF7AC5',
            '#ABB2B9',
            '#E59866',
            '#FFA07A',
            '#27AE60',
            '#D4AC0D',
            '#F0B27A',
            '#CACFD2',
            '#85C1E9',
            '#D7BDE2',
            '#008080',
            '#e6beff',
            '#aa6e28',
            '#fffac8',
            '#800000',
            '#aaffc3',
            '#808000',
            '#ffd8b1',
            '#000080',
            '#e6194b',
            '#3cb44b',
            '#ffe119',
            '#0082c8',
            '#f58231',
            '#911eb4',
            '#46f0f0',
            '#f032e6',
            '#d2f53c',
            '#808080',
            '#ffcc00',
            '#ffccff',
            '#fabebe',
        ];

        self.zoom = 800;
        self.changeZoom = function (bool) {
            bool ? self.zoom += 50 : self.zoom -= 50;
            $timeout(function () {
                onSizeChanged();
            });
           
        }

        self.getBlockCategoryColorStyle = function (color) {
            return {
                'background-color': color,
                //'width': '50px',
            };
        };

        self.selectedStore = null;
        $scope.$watch('ct.selectedStore', function (newVal, oldVal) {
            if (newVal != oldVal && self.sales != null && self.sales.length > 0) {
                calculateSales();

                if (self.heatMapVariable)
                    self.setHeatMap(self.heatMapVariable, self.heatMapVariableVal, false);

            }
        });

        $scope.$on('$stateChangeStart', function (event) {
            //console.log('$stateChangeStart', event)
            if (self.stateChange) {
                var answer = confirm(translations.AlertWillLoseChanges)
                if (!answer) {
                    event.preventDefault();
                }
            }
        });

        function resetSales() {

            //limpio listado de la izquierda
            self.spacePlanograms.forEach(planogram => planogram && clearSalesInfo(planogram));

            //limpio los planogramas del piso
            self.levels.forEach(level => {
                clearSalesInfo(level);
                level.floorPlanograms.forEach(planogram => {
                    clearSalesInfo(planogram);
                });
            });

            //limpio las categorias
            self.categories.forEach(category => clearSalesInfo(category));
        }

        function clearSalesInfo(obj) {
            obj.sales = 0;
            obj.margin = 0;
            obj.units = 0;
            obj.skus = 0;
            obj.price = 0;
            obj.gmroi = 0;
            obj.gmros = 0;
            obj.daysOutOfStock = 0;
            obj.stockUnits = 0;
            obj.stock = 0;
            obj.floorSpace = 0;
        }

        function aggregateSalesInfo(obj, filteredSales) {

            filteredSales.forEach(sale => {
                obj.sales += sale.sales;
                obj.margin += sale.margin;
                obj.units += sale.units;
                obj.skus += sale.skus;
                obj.price += sale.price;
                obj.daysOutOfStock += sale.daysOutOfStock;
                obj.stockUnits += sale.inventoryUnits || sale.stockUnits;
                obj.stock += sale.inventory || sale.stock;
                obj.floorSpace += sale.floorSpace;
            });
        }

        function calcAverageByStore(obj) {

            //calculo promedio por tienda si no hay tienda seleccionada
            if (!self.selectedStore) {
                obj.sales /= self.layout.stores.length;
                obj.margin /= self.layout.stores.length;
                obj.units /= self.layout.stores.length;
                obj.skus /= self.layout.stores.length;
                obj.price /= self.layout.stores.length;
                obj.gmroi /= self.layout.stores.length;
                obj.daysOutOfStock /= self.layout.stores.length;
                obj.stockUnits /= self.layout.stores.length;
                obj.stock /= self.layout.stores.length;
                obj.floorSpace /= self.layout.stores.length;
            }
        }

        function calcGmrosAndGmroi(obj) {

            if (obj.stock)
                obj.gmroi = obj.margin / obj.stock;

            if (obj.floorSpace)
                obj.gmros = obj.margin / obj.floorSpace;
        }

        function calculateSales() {

            resetSales();

            //filtro la venta bajo analisis según las tiendas elegidas. 
            // si no se elgió ninguna tienda se promedian todas las tiendas del layout.
            var filteredSales = self.sales.filter(sale => !self.selectedStore || sale.storeId == self.selectedStore.id);

            //recorro los planogramas del listado de la izquierda
            self.spacePlanograms.forEach(planogram => {
                if (planogram) {
                    //sumarizo venta del planograma
                    aggregateSalesInfo(planogram, filteredSales.filter(sale => sale.spacePlanogramId == planogram.id));

                    //calculo promedios del planograma
                    calcAverageByStore(planogram);

                    //calculo gmros y gmroi del plangrama
                    calcGmrosAndGmroi(planogram);
                }
            });

            //piso seleccionado del plano
            var level = self.levels[self.currentFloor];
            if (level) {
                //recorro los planogramas del piso
                level.floorPlanograms.forEach(planogram => {

                    //sumarizo venta del planograma
                    aggregateSalesInfo(planogram, filteredSales.filter(sale => sale.spacePlanogramId == planogram.spacePlanogramId));

                    //calculo promedios del planograma
                    calcAverageByStore(planogram);

                    //calculo gmros y gmroi del plangrama
                    calcGmrosAndGmroi(planogram);

                });

                //sumarizo venta para del piso
                //no hace falta calcular promedios porque se construye desde la data de planogramas que ya esta promediada x tienda
                aggregateSalesInfo(level, level.floorPlanograms);

                //calculo gmros y gmroi del piso
                calcGmrosAndGmroi(level);


                //agrega la info a nivel categorias respetando filtro de tienda.
                var layoutCategoryIds = level.floorPlanograms.map(p => p.categoryId);
                self.layoutCategories = self.categories.filter(cat => layoutCategoryIds.indexOf(cat.id) != -1);

                self.layoutCategories.forEach(category => {

                    //sumarizo totales de la categoria desde los planogramas del piso
                    //no hace falta calcular promedios porque se construye desde la data de planogramas que ya esta promediada x tienda
                    aggregateSalesInfo(category, level.floorPlanograms.filter(planogram => planogram.categoryId == category.id));

                    //calculo gmros y gmroi del plangrama
                    calcGmrosAndGmroi(category);

                    //Una vez que tengo la info sumarizada calculo los shares y el sub/sobre espaciado.
                    category.salesShare = category.sales / level.sales;
                    category.spaceShare = category.floorSpace / level.floorSpace;
                    category.spaced = (category.spaceShare / category.salesShare) - 1;
                    category.isOverSpaced = category.spaced >= 0 ? true : false;
                    category.barWidth = Math.abs(category.spaced) > 1 ? 1 : Math.abs(category.spaced); //El maximo ancho de la barra es 100%
                });
            }
        }

        function calculateSales_Deprecated() {

            resetSales();

            //filtro la venta bajo analisis según las tiendas elegidas. 
            // si no se elgió ninguna tienda se promedian todas las tiendas del layout.
            var filteredSales = self.sales.filter(sale => !self.selectedStore || sale.storeId == self.selectedStore.id)


            self.levels.forEach(level => {

                //calculo totales para el piso
                filteredSales.forEach(sale => {
                    level.sales += sale.sales;
                    level.margin += sale.margin;
                    level.units += sale.units;
                    level.skus += sale.skus;
                    level.price += sale.price;
                    level.gmroi += sale.gmroi;
                    level.daysOutOfStock += sale.daysOutOfStock;
                    level.stockUnits += sale.inventoryUnits;
                    level.stock += sale.inventory;
                    level.floorSpace += sale.floorSpace;
                });


                level.gmros = level.margin / level.width * level.depth / 10000;

                //hago el promedio por tienda si no hay tienda seleccionada
                if (!self.selectedStore) {
                    level.sales /= self.layout.stores.length;
                    level.margin /= self.layout.stores.length;
                    level.units /= self.layout.stores.length;
                    level.skus /= self.layout.stores.length;
                    level.price /= self.layout.stores.length;
                    level.gmroi /= self.layout.stores.length;
                    level.daysOutOfStock /= self.layout.stores.length;
                    level.stockUnits /= self.layout.stores.length;
                    level.stock /= self.layout.stores.length;
                    level.floorSpace /= self.layout.stores.length;
                }

                //agrego la info a nivel planograma respetando filtro de tienda.
                level.floorPlanograms.forEach(planogram => {

                    filteredSales.forEach(sale => {
                        if (sale.spacePlanogramId == planogram.spacePlanogramId) {
                            planogram.sales += sale.sales;
                            planogram.margin += sale.margin;
                            planogram.units += sale.units;
                            planogram.skus += sale.skus;
                            planogram.price += sale.price;
                            planogram.gmroi += sale.gmroi;
                            planogram.daysOutOfStock += sale.daysOutOfStock;
                            planogram.stockUnits += sale.inventoryUnits;
                            planogram.stock += sale.inventory;
                            planogram.floorSpace += sale.floorSpace;
                        }
                    });

                    //hago el promedio por tienda si no hay tienda seleccionada
                    if (!self.selectedStore) {
                        planogram.sales /= self.layout.stores.length;
                        planogram.margin /= self.layout.stores.length;
                        planogram.units /= self.layout.stores.length;
                        planogram.skus /= self.layout.stores.length;
                        planogram.price /= self.layout.stores.length;
                        planogram.gmroi /= self.layout.stores.length;
                        planogram.daysOutOfStock /= self.layout.stores.length;
                        planogram.stockUnits /= self.layout.stores.length;
                        planogram.stock /= self.layout.stores.length;
                        planogram.floorSpace /= self.layout.stores.length;
                    }

                    //Una vez que tengo el margen final calculo el GMROS del planograma
                    planogram.gmros = planogram.margin / planogram.width * planogram.depth / 10000;

                });

            });

            //agrega la info a nivel categorias respetando filtro de tienda.
            var layoutCategoryIds = self.levels[self.currentFloor].floorPlanograms.map(p => p.categoryId);
            self.layoutCategories = self.categories.filter(cat => layoutCategoryIds.indexOf(cat.id) != -1);

            self.layoutCategories.forEach(category => {

                //En cada categoria acumulo los valores de la tienda seleccionada o de todas las tiendas si no se eligi� una.
                self.levels[self.currentFloor].floorPlanograms.forEach(planogram => {
                    if (planogram.categoryId == category.id) {
                        category.sales += planogram.sales;
                        category.margin += planogram.margin;
                        category.units += planogram.units;
                        category.skus += planogram.skus;
                        category.price += planogram.price;
                        category.gmroi += planogram.gmroi;
                        category.daysOutOfStock += planogram.daysOutOfStock;
                        category.stockUnits += planogram.inventoryUnits;
                        category.stock += planogram.inventory;
                        category.floorSpace += planogram.floorSpace;
                    }
                });

                //hago el promedio por tienda si no hay tienda seleccionada
                if (!self.selectedStore) {
                    category.sales /= self.layout.stores.length;
                    category.margin /= self.layout.stores.length;
                    category.units /= self.layout.stores.length;
                    category.skus /= self.layout.stores.length;
                    category.price /= self.layout.stores.length;
                    category.gmroi /= self.layout.stores.length;
                    category.daysOutOfStock /= self.layout.stores.length;
                    category.stockUnits /= self.layout.stores.length;
                    category.stock /= self.layout.stores.length;
                    category.floorSpace /= self.layout.stores.length;
                }
            });

            //var salesTotal = self.layoutCategories.reduce((acum, cat) => acum + cat.sales, 0);
            //var spaceTotal = self.layoutCategories.reduce((acum, cat) => acum + cat.floorSpace, 0);
            var salesTotal = self.levels[self.currentFloor].sales;
            var spaceTotal = self.levels[self.currentFloor].floorSpace;

            //Una vez que tengo la info sumarizada calculo los shares y el sub/sobre espaciado.
            self.layoutCategories.forEach(category => {

                category.salesShare = category.sales / salesTotal;
                category.spaceShare = category.floorSpace / spaceTotal;
                category.spaced = category.spaceShare / category.salesShare;
                category.isOverSpaced = category.spaced <= 1 ? false : true;
                category.barWidth = category.spaced > 2 ? 1 : Math.abs(category.spaced - 1); //El maximo ancho de la barra es 100%
            });
        }

        function getTotalSpace(sales) {

            var sortedSales = sales.sort((a, b) => a.spacePlanogramId - b.spacePlanogramId);
            var planogramId = 0;
            var spaceTotal = 0;

            sortedSales.forEach(sale => {
                if (sale.spacePlanogramId != planogramId) {
                    planogramId = sale.spacePlanogramId;
                    spaceTotal += sale.floorSpace
                }
            });

            return spaceTotal;
        }

        self.isEdit = function () {
            return $state.current.url.contains('edit');
        }

        self.isNew = self.isEdit() && (!$stateParams.layoutId || $stateParams.layoutId == 0);

        self.selectSpaceObject = function (spaceObject) {
            self.selectedSpaceObject = spaceObject;
        }

        self.goToTickets = function () {
            $state.go('spaces.tickets', { layoutObjectId: self.selectedLevelItem.id, planogramLevelItemId: 0 });

        }

        self.goToViewMode = function () {
            $state.go('spaces.viewLayout', { layoutId: $stateParams.layoutId });
        }

        self.goToEditMode = function () {
            $state.go('spaces.editLayout', { layoutId: $stateParams.layoutId });
        }

        self.setShowPlanogramsPanel = function () {
            self.showPlanogramsPanel = !self.showPlanogramsPanel;

            $timeout(function () {
                onSizeChanged();
            }, 5);

            self.showPlanogramsPanel ? self.buttonTextPlanogramsPanel = translations.HidePlanograms : self.buttonTextPlanogramsPanel = translations.ShowPlanograms;

        }

        self.setShowInformationPanel = function () {
            self.showInformationPanel = !self.showInformationPanel;
            $timeout(function () {
                onSizeChanged();
            }, 5);

            self.showInformationPanel ? self.buttonTextInformationPanel = translations.HideInformation : self.buttonTextInformationPanel = translations.ShowInformation
        }


        self.calcLayoutSize = function (level) {

            //angular.forEach(self.levels, function (level) {
            if (!level)
                return;

            var widthPx = level.levelDiv.clientWidth;

            var depthPx = level.depth * widthPx / level.width;

            level.widthPx = widthPx;
            level.depthPx = depthPx;

            level.levelDiv.style.height = depthPx + 'px';

            if (level.polygonContainer)
                drawLevelObject(level.polygonContainer[0], level);
            //});
        }

        self.calcPlanogramSize = function (planogram) {
            planogram.widthPx = planogram.width * self.levels[self.currentFloor].widthPx / self.levels[self.currentFloor].width;
            planogram.depthPx = planogram.depth * self.levels[self.currentFloor].depthPx / self.levels[self.currentFloor].depth;
        }

        self.storeClicked = function (store) {
            //console.log('storeclicked', store);
            self.selectedStore = store || null;
        }

        function percentagesToCM(percs) {
            var r = [];
            _.each(percs, function (perc) {
                r.push([percentageToCM(perc[0], self.levels[self.currentFloor].width), percentageToCM(perc[1], self.levels[self.currentFloor].depth)]);
            });
            return r;
        }

        function percentageToCM(perc, maxCM) {
            return perc * maxCM / 100;
        }

        self.showLabels = false;

        //self.newLayout = function () {
        //    $state.go('spaces.editLayout', { layoutId: 0 }, { reload: true });
        //}

        self.addLevel = function () {

            if (self.levels.length > 0 && self.levels[self.levels.length - 1].polygonContainer[0].points == undefined) {

                swal(translations.SwalEmptyFloors, translations.SwalEmptyFloorsSubtitle, 'error');
                return;
            }

            var modalInstance = $uibModal.open({
                animation: true,
                component: 'addLevelComponent',
                windowClass: 'app-modal-window',
                resolve: {
                    modalInfo: function () {
                        return {
                            dataSource: 'new',
                            isEdit: false
                        };
                    }
                }
            });

            //console.log('modalInstance', modalInstance, self.stateChange);
            //return;
            modalInstance.result.then(function (level) {

                self.stateChange = true;

                self.levels.push({
                    id: 0,
                    width: level.width,
                    depth: level.depth,
                    floorPlanograms: [],
                    order: self.levels.length + 1,
                    sellingAreaSpace: level.sellingAreaSpace,
                    totalSpace: level.totalSpace,
                    photoUri: level.photoUri,
                    get squareMeters() { return this.width * this.depth / 10000 },
                    get linearFeets() {
                        var total = 0;
                        angular.forEach(this.floorPlanograms, function (comp) {
                            total += comp.depth * comp.width;
                        });
                        return (total / 100).toFixed(0);
                    },
                });

                //console.log('level devuelto', level, self.levels);

                //})
                self.currentFloor = self.levels[self.levels.length - 1].order - 1;


                $timeout(function () {
                    //var i = self.levels.length - 1;
                    //var canvas = SVG('newLayoutCanvas' + self.levels[i].order).size('10000px', '10000px');

                    //self.levels[i].canvas = canvas;
                    //self.levels[i].levelDiv = document.getElementById('newLayoutCanvas' + self.levels[i].order),


                    //    self.calcLayoutSize(self.levels[i]);

                    //canvas.image(self.levels[i].photoUri).loaded(function (loader) {
                    //    var imgProportion = loader.height / loader.width

                    //    this.size(self.levels[i].widthPx, self.levels[i].widthPx * imgProportion)
                    //})

                    //self.levels[i].pointsPx = getDivPoints(self.levels[i].levelDiv);
                    //self.levels[i].points = [[0, 0], [100, 0], [100, 100], [0, 100]];

                    //self.levels[i].polygonContainer = [{
                    //    svgReference: canvas.polygon(self.levels[i].pointsPx.join(' ')).fill('transparent').stroke({ width: 1 }),
                    //    fill: 'transparent',
                    //    points: self.levels[i].points,
                    //    pointsPx: self.levels[i].pointsPx, // percentageToPixels(self.levels[i].points),
                    //    draggable: false,
                    //    selectable: false,
                    //    stroke: { width: 1, color: 'black' }
                    //}]


                    //self.levels[i].levelDiv.style.overflow = 'hidden';

                    ////self.changeLevel(self.levels[i]);



                    ////$timeout(self.calcLayoutSize, 500);
                    var idx = self.levels.length - 1;
                    var level = self.levels[idx];
                    self.createLevelCanvas(level, true);
                });


            }, function () {
                console.log('dismissed');
            });


            //$scope.gridOptions.data = result;

            //return;



            //}

        }

        self.editFloor = function () {

            if (self.currentFloor != null
                && self.currentFloor >= 0
                && self.levels
                && self.levels.length > self.currentFloor
                && self.levels[self.currentFloor]) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    component: 'addLevelComponent',
                    windowClass: 'app-modal-window',
                    resolve: {
                        modalInfo: function () {
                            return {
                                dataSource: 'pepe',
                                isEdit: true,
                                floor: self.levels[self.currentFloor]
                            };
                        }
                    }
                });
            }
            else {
                swal('Error', 'No se ha seleccionado un piso', 'error');
            }
        }

        self.changeLevel = function (level) {
            self.currentFloor = level.order - 1;

            $timeout(function () {
                onSizeChanged();
                //self.toggleLabels(true);
                self.highlightByType(self.highlightByTypeDisplayName);
                //if (!self.isEdit() && self.sales != null && self.sales.length > 0) {
                //    calculateSales();
                //}
                //self.calcLayoutSize(level);
            })
        }

        self.toggleLabels = function (show) {
            console.log('toggleLavels')
            var level = self.levels[self.currentFloor];
            angular.forEach(level.floorPlanograms, function (c) {
                if (!show) {
                    if (c.text != undefined) {
                        c.text.clear();
                        c.text = undefined;
                    }
                    return;

                } else if (c.text != undefined)
                    return;
                //Saco el punto medio de entre todos los puntos que conforman al poligono
                var middlePoint = getMiddlePoint(c.pointsPx);
                var temp = getWidthHeight(c.pointsPx);
                var nodeWidth = temp[0];
                var nodeHeight = temp[1];

                //Creo el texto
                var text = level.canvas.text(c.name).font({
                    size: '12px',
                    fill: '#6A6D6F',
                    weight: 'bold'
                });;
                //console.log(c.name, text);

                //Me fijo cuanto width y height ocupa el texto creado para luego acomodarlo al moverlo
                var rect = text.node.getBoundingClientRect();

                //Tengo que acomodarlo, porque si lo ubico directamente en el middlepoint, la primer leta arranca en el medio del poligono
                text.move(middlePoint[0] - ((rect.width) / 2), middlePoint[1] - (rect.height / 2))

                if (c.rotation) {
                    text.transform({ rotation: c.rotation, relative: true });
                }
                //  if (nodeWidth < nodeHeight) {
                //  text.transform({ rotation: 90, relative: true });
                //}
                c.text = text;
            });

            //console.log(self.radioOption);
            if (show)
                self.radioOption = 'show'
            else
                self.radioOption = 'hide'

            self.showLabels = show;

        }

        self.levelObjectClick = function (e) {
            //console.log('self.levelIbjectClick - e', e, self.selectedLevelItem);
            //already selected - do deselect
            if (self.selectedLevelItem && ((e.id !== 0 && self.selectedLevelItem.id === e.id) || (self.selectedLevelItem.isNew && self.selectedLevelItem.tempId === e.tempId))) {
                //if (!self.isEdit()) {
                //  if (e.isPlanogram) {

                self.selectedLevelItem = null;
                self.selectedPlanogram = null;

                angular.forEach(self.levels[self.currentFloor].floorPlanograms,
                    function (c) {
                        //console.log('object', c)
                        c.svgReference.opacity(1);
                        c.attr = {
                            'stroke-dasharray': '',
                            'stroke-width': 1,
                        };
                        c.svgReference.attr(c.attr);
                    });

                //  }
                //}
            }
            else {
                //console.log('false');
                //guardo el item seleccionado
                self.selectedLevelItem = e;

                //si estoy en view mode
                //if (!self.isEdit()) {
                //if (e.isPlanogram) {
                self.selectPlanogram(e);
                //}
                //}

                ////resaltar seleccionado
                angular.forEach(self.levels[self.currentFloor].floorPlanograms,
                    function (c) {
                        c.attr = {
                            'stroke-dasharray': '',
                            'stroke-width': 1
                        };
                        c.svgReference.attr(c.attr);
                    });
                e.attr = {
                    'stroke-dasharray': '10,5',
                    'stroke-width': 3,
                };
                e.svgReference.attr(e.attr);
            }

            $scope.$apply();
            //self.toggleLabels(false)
        }

        self.rotateLayoutItem = function (grades) {

            if (self.selectedLevelItem) {
                self.selectedLevelItem.rotation = self.selectedLevelItem.rotation ? 0 : grades;
                if (self.selectedLevelItem.rotation === 0 && angular.isDefined(self.selectedLevelItem.oldRotation)) {
                    delete self.selectedLevelItem.oldRotation;
                }

                self.selectedLevelItem.svgReference.transform({ rotation: self.selectedLevelItem.rotation });

                // TODO: Forzar redibujo.
                var $el = angular.element(self.selectedLevelItem.svgReference.node);
                var element = self.selectedLevelItem.svgReference.node;
                if ($el.is('g:has(polygon)')) {
                    element = $el.find('polygon').get(0);
                }

                c.pointsPx = getDivPoints(element);
                c.points = pixelsToPercentage(c.pointsPx, level);
                c.move = undefined;
                c.rotation = 0;
                c.svgReference.remove();
                drawLevelObject(c, level);

                //self.selectedLevelItem.svgReference.transform({ rotation: grades, relative: true });
                //self.selectedLevelItem.rotation = self.selectedLevelItem.rotation ? (self.selectedLevelItem.rotation + grades) % 360 : grades;
                //self.selectedLevelItem.svgReference.transform({ rotation: self.selectedLevelItem.rotation, relative: false });
                //console.log('self.selectedLevelItem', self.selectedLevelItem);
                //console.log('floorPlanograms', self.levels[self.currentFloor].floorPlanograms);
                //if (self.selectedLevelItem.text) {
                //    self.selectedLevelItem.text.transform({ rotation: grades, relative: true });
                //}
            }
        }

        self.deleteLayoutItem = function () {
            if (self.selectedLevelItem) {

                swal({
                    title: translations.SureDeletePlanogramSwal + ' ' + self.selectedLevelItem.name + '?',
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: translations.RemoveSwal,
                    cancelButtonText: translations.CancelSwal,
                    showLoaderOnConfirm: true,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                    function (isConfirm) {
                        if (isConfirm) {
                            var index = self.levels[self.currentFloor].floorPlanograms.indexOf(self.selectedLevelItem);
                            self.levels[self.currentFloor].floorPlanograms.splice(index, 1);

                            self.selectedLevelItem.svgReference.remove();
                            if (self.selectedLevelItem.text) {
                                self.selectedLevelItem.text.remove();
                                self.selectedLevelItem.text = undefined;
                            }

                            self.selectedLevelItem = null;

                            calculateSales();
                        }
                    });
            }

        }

        self.resizeLayoutItem = function () {

        }

        self.getComponentColor = function (categoryId) {
            var color = '';
            angular.forEach(self.categories, function (category) {
                if (categoryId === category.id && color == '') {
                    color = category.color;
                }
            });
            return color;
        }

        self.getComponentImage = function (component) {
            if (!component.icon)
                return '';
            return 'url(' + component.icon + ')';
        }

        self.highlightByType = function (displayName) {
            //console.log('pepe');
            self.highlightByTypeDisplayName = displayName;

            //if (displayName != undefined && isPlanogram != undefined) {
            //self.highlightByTypeValue = isPlanogram;
            //self.highlightByTypeDisplayName = displayName;
            //}

            angular.forEach(self.levels[self.currentFloor].floorPlanograms, function (levelObject) {
                levelObject.svgReference.opacity(0.6);
            });
        }

        self.viewPlanogram = function () {
            if (!self.hasPermissionToEdit) {
                $state.go('spaces.viewPlanogram', { planogramId: self.selectedLevelItem.spacePlanogramId });
            }
            else {
                $state.go('spaces.editPlanogram', { planogramId: self.selectedLevelItem.spacePlanogramId });
            }
        }

        self.setHeatMap = function (displayName, variable, reverse) {

            if (!self.levels[self.currentFloor])
                return;

            self.heatMapReversed = reverse === true;

            self.heatMapVariable = displayName;
            self.heatMapVariableVal = variable;

            //reset opacity
            //$('.dropped').css('opacity', 0.7);

            //reset selected planogram
            self.selectedPlanogram = null;



            var min = 9999999999999999;
            var max = 0;
            angular.forEach(self.levels[self.currentFloor].floorPlanograms, function (c) {
                c.svgReference.opacity(0.7);
                //if (!c[variable]) return;
                if (!c[variable])
                    c[variable] = 0;
                //console.log('variable', c[variable]);
                if (c[variable] > max)
                    max = c[variable];
                if (c[variable] < min)
                    min = c[variable];
            });

            var diff = max - min;
            var low = min + (diff * 0.1); //20%
            var mid = max - (diff * 0.4); //80%

            angular.forEach(self.levels[self.currentFloor].floorPlanograms, function (component) {

                if (variable == '') {
                    console.log(component.categoryId);
                    component.fill = self.getComponentColor(component.categoryId);
                }
                else {
                    if (!reverse) {

                        if (component[variable] <= low) {
                            component.fill = '#ED5666';
                        }
                        else if (component[variable] <= mid) {
                            component.fill = '#F8AC59';
                        }
                        else {
                            component.fill = '#1AB394';
                        }
                    } else {
                        if (component[variable] <= low) {
                            component.fill = '#1AB394';
                        }
                        else if (component[variable] <= mid) {
                            component.fill = '#F8AC59';
                        }
                        else {
                            component.fill = '#ED5666';
                        }
                    }
                }
                drawLevelObject(component, self.levels[self.currentFloor]);

            });
            //self.toggleLabels(true);
            onSizeChanged();

        }

        self.getRanking = function () {

            if (self.levels[self.currentFloor]) {

                var planograms = self.levels[self.currentFloor].floorPlanograms;

                planograms.sort((a, b) => b[self.heatMapVariableVal] - a[self.heatMapVariableVal]);

                return self.heatMapReversed ? planograms.reverse() : planograms;
            }
            

            //var rv = [];

            //if (self.heatMapVariable) {
            //    self.rankCount = 0;
            //    angular.forEach(self.levels, function (level) {
            //        rv = rv.concat(_.sortBy(_.filter(level.floorPlanograms, function (o) {
            //            return (o);
            //        }), self.heatMapVariableVal));
            //    });
            //}
            //if (self.heatMapVariable === self.translationStockOut)
            //    return rv;

            //return rv.reverse();
        }

        self.getRankCount = function () {
            return ++self.rankCount;
        }

        self.selectPlanogram = function (planogram) {
            //console.log('selectPlanogram Function', planogram)
            angular.forEach(self.levels, function (level) {
                angular.forEach(level.floorPlanograms, function (levelObject) {
                    if (levelObject.id == planogram.id) {
                        if (level.order != self.currentFloor + 1)
                            self.changeLevel(level);

                        $timeout(function () {
                            angular.forEach(level.floorPlanograms, function (c) {
                                c.svgReference.opacity(0.6);
                            });

                            planogram.svgReference.opacity(0.9);

                            self.selectedPlanogram = planogram;

                            renderPieCharts(planogram);
                        })
                    }
                });
            })


        }

        self.countPlanograms = function () {
            if (self.levels.length > 0)
                return _.filter(self.levels[self.currentFloor].floorPlanograms, { isPlanogram: true }).length;
            return 0;
        }

        self.getLayoutTotal = function (variable) {
            if (self.levels.length == 0)
                return 0;
            var rv = 0;
            angular.forEach(self.levels, function (level) {

                angular.forEach(level.floorPlanograms, function (planogram) {
                    if (planogram[variable])
                        rv += planogram[variable];
                })
            })

            return rv.toFixed(2);
        }

        self.showAssignStoresDialog = function () {

            self.searchStore = '';

            ngDialog.open({
                template: 'assignStoresDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            });
        }

        self.layoutStoresHaveChanged = false; //Si cambiaron las tiendas vuelvo a cargar los planogramas de la columna de la izquierda

        self.assignStoresToLayout = function () {
            var prevStores = self.layout.stores;

            self.layout.stores = [];
            angular.forEach(self.stores, function (store) {
                if (store.selected)
                    self.layout.stores.push({ id: store.id, name: store.name });
            });

            //Veo si cambiaron las stores
            if (prevStores.length != self.layout.stores.length)
                self.layoutStoresHaveChanged = true;

            for (var i = 0; i < prevStores.length; i++) {
                if (!self.layoutStoresHaveChanged) {
                    if (prevStores[i].id != self.layout.stores[i].id) {
                        self.layoutStoresHaveChanged = true;
                    }
                }
            }

            self.stateChange = true;
            //swal('Tiendas Asignadas', 'Se asignaron correctamente las tiendas al layout', 'success');
            ngDialog.close();

        }

        self.showNewTicketModal = function () {

            self.newTicket = {};


            ngDialog.open({
                template: 'ticketNewDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-500',
                scope: $scope
            });
        }

        function isValidField(field) {
            if (field == undefined || field == null || field == '')
                return false;

            return true;
        }

        self.saveNewTicket = function () {

            if (!isValidField(self.newTicket.description) && !isValidField(self.newTicket.priority)) {
                swal(translations.ValidationSwalTitle, translations.ValidationSwalSubtitle, 'error');
                return;
            }

            var dataTicket = {
                ticketTypeId: self.newTicket.title.id,
                priority: self.newTicket.priority.id,
                description: self.newTicket.description,
                layoutObjectId: self.selectedLevelItem.id
            }

            spaceService.tickets.saveTicket(dataTicket).then(function (res) {
                $timeout(function () {
                    self.selectedLevelItem.ticketCount++;
                });
                ngDialog.close();
                swal(translations.SavedTicketSwal, translations.SavedTicketSwalSubtitle, 'success');

            });
        }

        self.saveLayout = function () {

            swal({
                title: translations.SaveLayoutSwalTitle,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        if (!self.isSaving) {

                            self.isSaving = true;
                            //self.polygonContainer[0].points = pixelsToPercentage(self.polygonContainer[0].pointsPx);

                            var data = [];
                            angular.forEach(self.levels, function (level) {
                                data.push({
                                    id: level.id, //id del nivel
                                    layoutId: $stateParams.layoutId,
                                    order: level.order,
                                    width: level.width,
                                    depth: level.depth,
                                    photoUri: level.photoUri,
                                    sellingAreaSpace: level.sellingAreaSpace,
                                    totalSpace: level.totalSpace,
                                    //points: [[0, 0], [100, 0], [100, 100], [0, 100]],//level.polygonContainer[0].points,
                                    floorPlanograms: [],
                                });
                                angular.forEach(level.floorPlanograms, function (levelObject) {
                                    var copied = angular.copy(levelObject);
                                    delete copied.text;
                                    delete copied.svgReference;
                                    delete copied.pointsPx;
                                    data[data.length - 1].floorPlanograms.push(copied);
                                });
                            });

                            var layoutData = {

                                stores: self.layout.stores,
                                levels: data,
                                id: self.layout.id,
                                companyId: self.layout.companyId
                            }

                            spaceService.layouts.saveLevels(layoutData).then(function (res) {
                                if (res) {
                                    self.stateChange = false;
                                    //console.log('res.levels', res.levels);
                                    loadLevels(res.levels);
                                    swal(translations.SavedLayoutSwal, translations.SavedLayoutSwalSubtitle, 'success');
                                    //console.log("exito");
                                    self.selectedLevelItem = null;
                                    self.isSaving = false;

                                    //TODO: recargo los planogramas si cambio la asignacion a tiendas.
                                    //O recargar todo despues de la edicion de tiendas
                                    if (self.layoutStoresHaveChanged) {
                                        //TODO:
                                    }
                                }
                            },
                                function () {
                                    swal(translations.ErrorTitleSwal, "error", "error");
                                    self.isSaving = false;
                                });


                        }
                    }
                });
        }

        self.selectSvg = function (element) {
            self.selectedSvg = element;
        }

        self.showAttributesFunc = function () {
            if (self.showAttributes)
                return translations.ViewLess;
            else
                return translations.ViewMore;
        }

        function renderPieCharts(planogram) {


            var shareSales = self.getLayoutTotal('sales') / planogram.sales;
            var shareSpace = self.levels[self.currentFloor].squareMeters / ((planogram.width * planogram.depth) / 100);

            self.spaceResult = shareSales < shareSpace ? translations.Overpaced : translations.Subspaced;
            self.spaceResultIcon = shareSales < shareSpace ? 'up' : 'down';
            self.spaceResultColor = shareSales < shareSpace ? 'danger' : 'primary';

            if (self.sales != undefined && self.sales.length > 0) {
                // TODO: Verificar porque no viene el dato de ventas.
                var sales = planogram.sales || 0.0;

                //Share Ventas
                var chart = c3.generate({
                    bindto: '#saleschart',
                    size: {
                        width: 230
                    },
                    title: {
                        text: translations.SalesShare
                    },
                    data: {
                        columns: [
                            // Fix: No viene el dato de ventas.
                            //[planogram.name, planogram.sales],
                            //[translations.SalesRestPlanograms, self.getLayoutTotal('sales') - planogram.sales],
                            [planogram.name, sales],
                            [translations.SalesRestPlanograms, self.getLayoutTotal('sales') - sales],
                        ],
                        type: 'pie'
                    }
                });
            }

            var totalSpace = 0;
            angular.forEach(self.levels,
                function (level) {
                    angular.forEach(level.floorPlanograms,
                        function (c) {
                            totalSpace += (c.width * c.depth) / 10000;
                        });
                });
            console.log('totalSpace', totalSpace);
            //Share Espacio
            var chart = c3.generate({
                bindto: '#spacechart',
                size: {
                    width: 230
                },
                title: {
                    text: translations.SpaceShare + '(m2)'
                },
                data: {
                    columns: [
                        [planogram.name, (planogram.width * planogram.depth) / 10000],
                        [translations.SpaceRestPlanograms, totalSpace - (planogram.width * planogram.depth) / 10000],
                    ],
                    type: 'pie'
                }
            });

        }

        function getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        function onSizeChanged() {
            var level = self.levels[self.currentFloor];
            //angular.forEach(self.levels, function (level) {
            self.calcLayoutSize(level);
            //})

            angular.forEach(self.polygonContainer, function (container) {
                drawLevelObject(container, level);
            });

            if (level) {
                angular.forEach(level.floorPlanograms, function (planogram) {
                    self.calcPlanogramSize(planogram);
                    drawLevelObject(planogram, level);
                });

                //self.toggleLabels(!self.showLabels);
                //self.toggleLabels(self.showLabels);

                level.imgPlano.canvas.size(level.widthPx, level.widthPx * level.imgPlano.imgProportion)
            }
        }

        //function guid() {
        //    function s4() {
        //        return Math.floor((1 + Math.random()) * 0x10000)
        //          .toString(16)
        //          .substring(1);
        //    }
        //    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        //      s4() + '-' + s4() + s4() + s4();
        //}

        function loadPlanograms() {

        }

        self.planogramSales = [];
        self.storeSales = [];
        self.categorySales = [];

        function calculateAverageSales(sales, array) {
            //Ventas x Planograma. 1 Ordeno por spacePlanogramId
            sales.sort(function (a, b) {
                if (b.id > a.id)
                    return -1;
                if (b.id < a.id)
                    return 1;
                return 0;
            });

            //2. Agrupo por spacePlanogramId
            var id = 0;
            var saleObj = {};
            var totalSales = 0;
            var totalSpace = getTotalSpace(sales);
            //var totalSpace = 0;
            angular.forEach(sales, function (sale) {
                if (sale.id != id) {
                    saleObj = {
                        id: sale.id,
                        sales: 0,
                        margin: 0,
                        units: 0,
                        skus: 0,
                        gmroi: 0,
                        gmros: 0,
                        daysOutOfStock: 0,
                        inventoryUnits: 0,
                        inventory: 0,
                        floorSpace: 0,
                        count: 0,
                        categoryId: sale.categoryGroupId
                    }

                    array.push(saleObj);
                }

                saleObj.sales += sale.sales;
                saleObj.units += sale.units;
                saleObj.skus += sale.skus;
                saleObj.margin += sale.margin;
                saleObj.daysOutOfStock += sale.daysOutOfStock;
                saleObj.inventoryUnits += sale.inventoryUnits;
                saleObj.inventory += sale.inventory;
                saleObj.floorSpace = sale.floorSpace;
                saleObj.count++;

                id = sale.id;

                totalSales += sale.sales;
                totalSpace += sale.floorSpace;
            });

            //Calculo los promedios
            angular.forEach(array, function (sale) {
                sale.daysOutOfStock = sale.daysOutOfStock / sale.count;
                sale.gmroi = sale.margin / sale.inventory;
                sale.gmros = sale.margin / sale.floorSpace; //El Calculo del GMROS cambia si es una tienda
                sale.inventory = sale.inventory / sale.count;
                sale.inventoryUnits = sale.inventoryUnits / sale.count;
                sale.sales = sale.sales / sale.count;
                sale.units = sale.units / sale.count;
                sale.skus = sale.skus / sale.count;
                sale.margin = sale.margin / sale.count;
                sale.skus = sale.skus / sale.count;
                sale.salesShare = sale.sales / totalSales;
                sale.spaceShare = sale.floorSpace / totalSpace;
                sale.spaced = sale.spaceShare / sale.salesShare;
                sale.isOverSpaced = sale.spaced <= 1 ? false : true;
                sale.barWidth = sale.spaced > 2 ? 1 : Math.abs(sale.spaced - 1); //El maximo ancho de la barra es 100%
            });
        }


        function loadData() {

            spaceService.tickets.getTicketTypes(TICKET_TYPES.PLANOGRAM).then(function (ticketTypes) {
                self.ticketTypes = ticketTypes;
            })

            spaceService.layouts.getLayout($stateParams.layoutId).then(function (layout) {

                self.layout = layout;

                var storeIds = [];
                spaceService.stores.getStores().then(function (stores) {
                    self.stores = stores;

                    angular.forEach(self.stores, function (store) {
                        if (store.layoutId == layout.id) {
                            store.selected = true;

                            //if (self.selectedStore == null)
                            //    self.selectedStore = store;

                            storeIds.push(store.id);
                        }
                    });

                    spaceService.spaceObjects.getSpaceObjects(storeIds).then(function (spacePlanograms) {

                        spacePlanograms.sort(function (a, b) {
                            if (b.categoryId > a.categoryId)
                                return -1;
                            if (b.categoryId < a.categoryId)
                                return 1;
                            return 0;
                        });

                        var colorIndex = -1;
                        var planogramCategoryId = 0;
                        angular.forEach(spacePlanograms, function (planogram) {

                            if (planogram.categoryId != planogramCategoryId) {
                                colorIndex++;

                                self.categories.push({ id: planogram.categoryId, color: self.categoryColors[colorIndex], name: planogram.categoryName });
                            }

                            planogram.categoryColor = self.categoryColors[colorIndex];

                            planogramCategoryId = planogram.categoryId;
                        });

                        self.spacePlanograms = spacePlanograms;

                        loadLevels(layout.levels);

                        //Cargo la info de ventas
                        spaceService.layouts.getLayoutItemSales(layout.id).then(function (sales) {
                            //Esto trae las ventas con el detalle de Planogramas, Tiendas y Grupo de Categorias. Luego hay que agrupar x Planograma x Tienda y x Grupo de Categorias
                            self.sales = sales;

                            //sales.forEach(function (s) { s.id = s.spacePlanogramId });

                            //calculateAverageSales(sales, self.planogramSales);

                            //angular.forEach(self.planogramSales, function (sale) {
                            //    angular.forEach(self.spacePlanograms, function (planogram) {
                            //        if (sale.id == planogram.id) {
                            //            angular.merge(planogram, sale);
                            //        }
                            //    });

                            //    angular.forEach(self.levels, function (level) {
                            //        angular.forEach(level.floorPlanograms, function (levelObject) {
                            //            if (sale.id == levelObject.id) {
                            //                angular.merge(levelObject, sale);
                            //            }
                            //        })
                            //    });
                            //});


                            //sales.forEach(function (s) { s.id = s.storeId });

                            //calculateAverageSales(sales, self.storeSales);

                            //sales.forEach(function (s) { s.id = s.categoryGroupId });

                            //calculateAverageSales(sales, self.categorySales);

                            ////agrega datos de venta a las categorias
                            //angular.forEach(self.categorySales, function (sale) {
                            //    angular.forEach(self.categories, function (category) {
                            //        if (sale.id == category.id) {
                            //            angular.merge(category, sale);
                            //        }
                            //    });
                            //});

                            //agrega datos de venta a los planogramas del layout
                            calculateSales();


                        });

                    }).then(function () {
                        //self.spacePlanograms.push(null);

                        });
                });

                //self.showSubCat = function (categoryId, categoryName, spaced) {
                //    self.showSubcategories = true;

                //    self.isInfoPlanogramCollapsed = true;
                //    self.isInfoLayoutsCollapsed = true;
                //    self.isInfoPerformanceCollapsed = true;

                //    self.selectedCategory = { id: categoryId, name: categoryName, spaced: spaced };
                //}

                //self.hideSubCat = function () {
                //    self.showSubcategories = false;
                //    self.isInfoPlanogramCollapsed = false;
                //    self.isInfoLayoutsCollapsed = false;
                //    self.isInfoPerformanceCollapsed = false;
                //}

            })



            //$timeout(function () {
            .then(function () {
                if ($stateParams.planogramIdChange && $stateParams.planogramIdChange != 0) {
                    angular.forEach(self.levels[self.currentFloor].floorPlanograms, function (c) {
                        if (c.id == $stateParams.planogramIdChange)
                            self.levelObjectClick(c);
                    })
                }

                //self.spacePlanograms.push(null);
            });
            //}, 250);



        }


        self.showSubCat = function (categoryId, categoryName, spaced) {
            self.showSubcategories = true;

            self.isInfoPlanogramCollapsed = true;
            self.isInfoLayoutsCollapsed = true;
            self.isInfoPerformanceCollapsed = true;

            self.selectedCategory = { id: categoryId, name: categoryName, spaced: spaced };
        }

        self.hideSubCat = function () {
            self.showSubcategories = false;
            self.isInfoPlanogramCollapsed = false;
            self.isInfoLayoutsCollapsed = false;
            self.isInfoPerformanceCollapsed = false;
        }

        self.selectedCategory = {};

        function loadLevels(levels) {
            //console.log('loadLevels', levels)
            self.levels = [];
            for (var i = 0; i < levels.length; i++) {

                self.levels.push({

                    id: levels[i].id,
                    width: levels[i].width,
                    depth: levels[i].depth,
                    floorPlanograms: levels[i].floorPlanograms,
                    points: levels[i].points,
                    order: levels[i].order,
                    sellingAreaSpace: levels[i].sellingAreaSpace,
                    photoUri: levels[i].photoUri,
                    totalSpace: levels[i].totalSpace,
                    get squareMeters() { return this.width * this.depth / 10000 },
                    get linearFeets() {
                        var total = 0;
                        angular.forEach(this.floorPlanograms, function (comp) {
                            total += (comp.depth / 100) * (comp.width / 100);
                        });
                        return total.toFixed(2);
                    }

                });

                self.levels[self.levels.length - 1].floorPlanograms = levels[i].floorPlanograms;

                //angular.forEach(levels[i].floorPlanograms, function (levelObject) {
                //    self.levels[self.levels.length - 1].levelObject
                //});

                $timeout(function (i) {
                    //var canvas = SVG('newLayoutCanvas' + self.levels[i].order).size('10000px', '10000px');

                    //self.levels[i].canvas = canvas;

                    //self.levels[i].levelDiv = document.getElementById('newLayoutCanvas' + self.levels[i].order);

                    //self.calcLayoutSize(self.levels[i]);

                    ////self.imgPlano = {}
                    ////self.imgPlano.canvas = canvas.image(self.levels[i].photoUri).loaded(function (loader) {
                    ////    var imgProportion = loader.height / loader.width
                    ////    self.imgPlano.imgProportion = imgProportion
                    ////    this.size(self.levels[i].widthPx, self.levels[i].widthPx * imgProportion)
                    ////})
                    //self.imgPlano = self.createImage(self.levels[i]);

                    //self.levels[i].points = [[0, 0], [100, 0], [100, 100], [0, 100]];

                    //self.levels[i].pointsPx = percentageToPixels(self.levels[i].points, self.levels[i]),
                    //    self.levels[i].polygonContainer = [{
                    //        svgReference: canvas.polygon(self.levels[i].pointsPx.join(' ')).fill('transparent').stroke({ width: 1 }),
                    //        fill: 'transparent',
                    //        points: self.levels[i].points,
                    //        pointsPx: self.levels[i].pointsPx, // percentageToPixels(self.levels[i].points),
                    //        draggable: false,
                    //        selectable: false,
                    //        stroke: { width: 1, color: 'black' }
                    //    }]

                    //angular.forEach(self.levels[i].floorPlanograms, function (levelObject) {
                    //    levelObject.draggable = true;
                    //    levelObject.selectable = true;
                    //    drawLevelObject(levelObject, self.levels[i]);
                    //});

                    //self.levels[i].levelDiv.style.overflow = 'hidden';
                    var level = self.levels[i];
                    self.createLevelCanvas(level, false);

                }, 0, true, i);

            }


        }

        /**
         * Agrega un nuevo elemento canvas en el DOM.
         * @param {any} level nivel del layout.
         * @param {boolean} isNew indica si el nivel del layout es nuevo.
         * @returns {void}
         */
        self.createLevelCanvas = function createLevelCanvas(level, isNew) {
            var canvas = SVG('newLayoutCanvas' + level.order).size('10000px', '10000px');

            level.canvas = canvas;

            level.levelDiv = document.getElementById('newLayoutCanvas' + level.order);

            self.calcLayoutSize(level);

            level.imgPlano = self.createImage(level);

            level.points = [[0, 0], [100, 0], [100, 100], [0, 100]];

            if (isNew) {
                level.pointsPx = getDivPoints(level.levelDiv);
            }
            else {
                level.pointsPx = percentageToPixels(level.points, level);
            }
            
            level.polygonContainer = [{
                svgReference: canvas.polygon(level.pointsPx.join(' ')).fill('transparent').stroke({ width: 1 }),
                    fill: 'transparent',
                    points: level.points,
                    pointsPx: level.pointsPx, 
                    draggable: false,
                    selectable: false,
                    stroke: { width: 1, color: 'black' }
                }]

            if (!isNew) {
                angular.forEach(level.floorPlanograms, function (levelObject) {
                    levelObject.draggable = true;
                    levelObject.selectable = true;
                    drawLevelObject(levelObject, level);
                });
            }

            level.levelDiv.style.overflow = 'hidden';


        }

        self.createImage = function createImage(level) {
            var imgPlano = {};
            imgPlano.canvas = level.canvas.image(level.photoUri).loaded(function (loader) {
                var imgProportion = loader.height / loader.width;
                imgPlano.imgProportion = imgProportion;
                this.size(level.widthPx, level.widthPx * imgProportion);
            });

            return imgPlano;
        }

        self.showZoomButtons = function showZoomButtons() {   
            var level = self.levels[self.currentFloor];
            var ret = angular.isObject(level)
                && angular.isNumber(level.width)
                && level.width != 0;

            return ret;
        }

        function initEvents() {

            angular.element(window).on("resize.doResize", _.throttle(function () {

                $scope.$apply(function () {
                    onSizeChanged();
                });
            }, 500));

            var onGlobalMouseup = function (e) {
                if (e.toElement.className.toString().indexOf('filter-button') === -1
                    && $(event.target).closest('.filter-element').length == 0) {
                    self.closeFilters();
                }
            }

            $("body").on("mouseup", onGlobalMouseup);
           
            $scope.$on("$destroy", function () {
                angular.element(window).off("resize.doResize"); //remove the handler added earlier
                $("body").off("mouseup", onGlobalMouseup);
            });
        }

        function initDrag() {

        }

        var cloned;
        var mouseDown;

        function onMouseUp(event) {
            //Solo ejecuto si viene de un mouse down previo
            if (!mouseDown) return;
            //console.log('up', event);
            //Le saco el evento, por las dudas
            window.removeEventListener('mouseup', onMouseUp);
            //Dejo de arrastrar
            document.onmousemove = null;

            var level = self.levels[self.currentFloor];

            //Guardo los puntos para usarlos luego
            var pxPoints = getDivPoints(cloned);
            var points = pixelsToPercentage(pxPoints, level);

            var validPosition = isValidPosition(cloned);
            //Remuevo el planograma del canvas creado
            var parent = document.getElementById('auxPlanogram');
            parent.removeChild(cloned);

            //Si es una ubicacion no permitida, no lo agrego
            //if (_.includes(cloned.className, "forbidden"))
            if (!validPosition) {
                //console.log('no over');
                return;
            }

            var rect = level.levelDiv.getBoundingClientRect();

            //Solo si el elemento con el que estoy interactuando no existia todavia en la lista
            //if (!$(event.relatedTarget).attr('id')) {

            var componentClone = angular.copy(self.selectedSpaceObject);

            self.isPlanogramRepeat = false;


                    //swal('No puede repetir planogramas', 'El planograma arrastrado ya existe en el layout, no puede duplicar el planograma.', 'warning');
                    //return;
            

            //componentClone.id = guid();
            componentClone.points = points;

            level.floorPlanograms.push({
                isNew: true,
                tempId: level.floorPlanograms.length + 1,
                id: 0,
                spacePlanogramId: componentClone.id,
                depth: componentClone.depth,
                width: componentClone.width,
                points: componentClone.points,
                pointsPx: componentClone.pointsPx,
                rotation: componentClone.rotation ? componentClone.rotation : 0,
                name: componentClone.name,
                categoryId: componentClone.categoryId,
                categoryColor: componentClone.categoryColor
            });

            //vuelvo a calcular las ventas 
            calculateSales();

            //console.log('level.floorPlanograms', level.floorPlanograms[level.floorPlanograms.length - 1]);
            //console.log('clone', componentClone);


            drawLevelObject(level.floorPlanograms[level.floorPlanograms.length - 1], level);

            self.levelObjectClick(level.floorPlanograms[level.floorPlanograms.length - 1]);
            //console.log('level.floorPlanograms', level.floorPlanograms);
            //agrego el id al html
            //$(event.relatedTarget).attr('id', componentClone.id);

            //muestro etiquetas si corresponde
            //self.toggleLabels(self.showLabels);


            //actualizo filtros
            //self.highlightByType();

            $scope.$apply();


        }


        self.notRepeatPlanogram = function (spacePlanogram) {
            var level = self.levels[self.currentFloor];
            return level && level.floorPlanograms && level.floorPlanograms.some(function (planogram) {
                return planogram.spacePlanogramId === spacePlanogram.id;
            });
            
        }

        function isValidPosition(div, component) {
            var points = getDivPoints(div);
            //console.log(points[0],points[1],points[2],points[3]);

            if (!overContainer(points)) {
                //console.log('not over');
                return false;

            }

            if (areCollisions(points, component)) {
                //console.log('collides');
                return false;

            }

            return true;
        }

        function areCollisions(pointsToCheck, selectedSpaceObject) {
            var flag = false;
            _.each(self.levels[self.currentFloor].floorPlanograms, function (component) {
                _.each(pointsToCheck, function (point) {
                    if (selectedSpaceObject != undefined) {
                        if ((component.id != selectedSpaceObject.id && inside(point, component.pointsPx))) {
                            flag = true;
                        }
                    } else {
                        if ((inside(point, component.pointsPx))) {
                            flag = true;
                        }
                    }

                })
            });

            return flag;
        }

        function overContainer(pointsToCheck) {
            var flag = true;
            _.each(pointsToCheck, function (item) {
                if (inside(item, self.levels[self.currentFloor].polygonContainer[0].pointsPx) == false) {
                    flag = false;
                }
            })

            return flag;
        }

        self.componentMousedown = function (item, event) {
            self.stateChange = true;
            var level = self.levels[self.currentFloor];
            if (level.polygonContainer.length == 0 || level.polygonContainer[0].points == undefined) return;
            mouseDown = true;

            // create a clone of the currentTarget element
            cloned = event.currentTarget.cloneNode(true);

            //Le pongo las medidas correctas al planograma
            self.calcPlanogramSize(self.selectedSpaceObject);

            //resize clone
            cloned.style.width = self.selectedSpaceObject.widthPx + 'px';
            cloned.style.height = self.selectedSpaceObject.depthPx + 'px';
            cloned.style.opacity = '1';


            //div.innerHTML = "Hello";

            var parent = document.getElementById('auxPlanogram');
            parent.style.top = event.clientY + 'px';
            parent.style.left = event.clientX + 'px';

            parent.appendChild(cloned);
            window.addEventListener('mouseup', onMouseUp);


            document.onmousemove = function (oPssEvt2) {
                var oMsEvent2 = oPssEvt2 || /* IE */ window.event;
                parent.style.top = oMsEvent2.clientY + 'px';
                parent.style.left = oMsEvent2.clientX + 'px';

                if (isValidPosition(cloned)) {
                    cloned.className = cloned.className.replace('forbidden', '');
                } else {
                    if (!_.includes(cloned.className, 'forbidden')) {
                        cloned.className += ' forbidden';
                    }
                }
            };
        }

        function getDivPointsFromCoordenates(startX, startY, div) {
            var rect = self.levels[self.currentFloor].levelDiv.getBoundingClientRect();

            baseX = startX - rect.left;
            baseY = startY - rect.top;

            var points = [[baseX, baseY]];
            points.push([baseX + div.clientWidth, baseY]);
            points.push([baseX, baseY + div.clientHeight]);
            points.push([baseX + div.clientWidth, baseY + div.clientHeight]);

            return points;
        }

        function getDivPoints(div) {
            var rect = self.levels[self.currentFloor].levelDiv.getBoundingClientRect();
            var divRect = div.getBoundingClientRect();
            //console.log(divRect);

            baseX = divRect.left - rect.left;
            baseY = divRect.top - rect.top;

            var points = [[baseX, baseY]];
            points.push([baseX, baseY + divRect.height]);
            points.push([baseX + divRect.width, baseY + divRect.height]);
            points.push([baseX + divRect.width, baseY]);

            return points;
        }

        function inside(point, vs) {
            // ray-casting algorithm based on
            // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
            var x = point[0], y = point[1];

            var inside = false;
            for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
                var xi = vs[i][0], yi = vs[i][1];
                var xj = vs[j][0], yj = vs[j][1];

                var intersect = ((yi > y) != (yj > y))
                    && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
                if (intersect) inside = !inside;
            }

            return inside;
        };


        function drawLevelObject(c, level) {
            if (c.svgReference != undefined) {
                c.svgReference.remove();
                c.svgReference = undefined;
            }

            var pxPoints = percentageToPixels(c.points, level);
            c.pointsPx = pxPoints;
            var poly;
            if (!c.image) {

                var onlyPoly = angular.isDefined(c.fill) && c.fill === 'transparent';
                //c.svgReference = level.canvas.polygon(pxPoints.join(' '))
                //    .fill(self.getComponentColor(c))
                //    .stroke({ width: 1, opacity: '1' });
                ////.radius(5)

                // Crea los items.
                var fillColor = c.fill || self.getComponentColor(c.categoryId);
                poly /* Planograma */ = level.canvas.polygon(pxPoints.join(' '))
                    .fill(fillColor)
                    .stroke({
                        width: 1,
                        opacity: '1'
                    });

                // Workaround: Se dibuja un elemento que no es un planograma y es transparente.
                if (onlyPoly) {
                    c.svgReference = poly;
                } else {

                    var planograms = self.spacePlanograms.filter(function (planogram) {
                        var ret = planogram.id === c.spacePlanogramId;
                        return ret;
                    });



                    var orientationRightToLeft = true;
                    if (planograms.length) {
                        orientationRightToLeft = planograms[0].orientationRightToLeft;
                    }

                    var arrowRotation = angular.isDefined(c.oldRotation) && c.oldRotation !== c.rotation;
                    var polyBox = poly.node.getBBox();
                    var g /* agrupa todos los elemento */ = level.canvas.group();
                    var arrowNested /* Contenedor de la flecha para poder posicionarla */ = level.canvas.nested();
                    var gArrow /* Para poder cambiar el tama�o de la flecha y rotarla */ = level.canvas.group();
                    var arrow /* Flecha */;
                    var isHorizontal = polyBox.width > polyBox.height;
                    if (orientationRightToLeft) {
                        if (isHorizontal) {
                            arrow = level.canvas.path(
                                'm 10.975,2 2.847,2.828 -6.176,6.176 16.354,0 0,3.992 -16.354,0 6.176,6.176 L 10.975,24 0,13 Z');
                        } else {
                            arrow = level.canvas.path(
                                'm 22,10.975 -2.828,2.847 -6.176,-6.176 0,16.354 -3.992,0 0,-16.354 L 2.828,13.822 0,10.975 11,0 Z');
                        }
                    } else {
                        if (isHorizontal) {
                            arrow = level.canvas.path(
                                'm 13.025,2 -2.847,2.828 6.176,6.176 -16.354,0 0,3.992 16.354,0 -6.176,6.176 L 13.025,24 24,13 Z');
                        } else {
                            arrow = level.canvas.path(
                                'm 22,13.025 -2.828,-2.847 -6.176,6.176 0,-16.354 -3.992,0 0,16.354 L 2.828,10.178 0,13.025 11,24 Z');
                        }
                    }

                    // Aplica los estilos a la flecha.
                    arrow.fill('#fff')
                        .stroke({ width: 1, color: 'black' })
                        .attr({ 'stroke-dasharray': '' });

                    gArrow.add(arrow);
                    arrowNested.add(gArrow);
                    g.add(poly);
                    g.add(arrowNested);
                    c.svgReference = g;
                    var gArrorBox = gArrow.node.getBBox();

                    // Calcula el factor de la escala.
                    var scale = Math.min(polyBox.width, polyBox.height) / Math.max(gArrorBox.width, gArrorBox.height);

                    if (isNaN(scale)) {
                        scale = 1;
                    }

                    gArrow.translate(0, 0)
                        .transform({
                            scale: scale
                        });

                    gArrow.translate(0, 0);
                    arrowNested.translate(0, 0);
                    var arrowNestedBox = arrowNested.node.getBBox();
                    var arrowNestedBoxWidth = arrowNestedBox.width;
                    var arrowNestedBoxHeight = arrowNestedBox.height;

                    // Centra la flecha en el planograma.
                    // Toma la posici�n del pol�gono y centra la flecha, si la flecha esta rotada corrige la posici�n inicial.
                    var arrowX = polyBox.x + ((polyBox.width - arrowNestedBoxWidth) / 2);
                    var arrowY = polyBox.y + ((polyBox.height - arrowNestedBoxHeight) / 2);
                    arrowNested.move(arrowX, arrowY);
                }
            } else {
                //Si es una imagen, tengo que tratarlo un poco diferente
                //Tengo que crear un grupo, y asignar una imagen y un poligono (para que le rellene el fondo)

                var width = getPixelFromPercentage(c.imageWidth, self.levels[self.currentFloor].levelDiv.clientWidth);
                var height = getPixelFromPercentage(c.imageHeight, self.levels[self.currentFloor].levelDiv.clientHeight);

                var image = level.canvas.image(c.icon, width, height);
                if (c.imageOpacity)
                    image.opacity(c.imageOpacity);

                if (c.group) {
                    poly = level.canvas.polygon(pxPoints.join(' '))
                        .stroke({ width: 1, color: 'black' });

                    if (c.fill) {
                        poly.fill(c.fill);
                    } else {
                        console.log(c.categoryId);
                        poly.fill(self.getComponentColor(c.categoryId));

                    }

                    poly.opacity(0.1);

                    g = level.canvas.group();
                    g.add(poly);
                    g.add(image);

                    c.svgReference = g;
                } else {
                    c.svgReference = image;
                }

                if (c.imageMove) {
                    image.move(c.imageMove[0], c.imageMove[1]);
                }
            }

            if (c.stroke) {
                c.svgReference.stroke(c.stroke);
            }

            if (c.selectable == undefined || c.selectable === true)
                c.svgReference.click(function () {
                    self.levelObjectClick(c);
                });

            if (c.move) {
                c.svgReference.dmove(getPixelFromPercentage(c.move[0], level.layoutDiv.clientWidth),
                    getPixelFromPercentage(c.move[1], level.layoutDiv.clientHeight));
            }

            if (c.rotation) {
                c.svgReference.transform({ rotation: c.rotation });

                //c.svgReference.transform({ rotation: c.rotation, relative: true });
                //c.svgReference.rotate(c.rotation);
            }

            if (c.color) {
                //c.svgReference.fill(c.color);
                //c.svgReference.fill(getSectionColor(c.categoryId));
            }

            //Esto es por si quiero sobreescribir un color  
            //c.svgReference.fill('transparent');
            if (c.fill) {
                c.svgReference.fill(c.fill);
                //c.svgReference.stroke({ width: 1, color: c.fill })
            }

            if (c.back) {
                c.svgReference.back();
            }

            if (c.front) {
                c.svgReference.front();
            }

            if (c.attr) {
                c.svgReference.attr(c.attr);
            }

            if (c.svgReference._array) {
                c.pointsPx = angular.copy(c.svgReference._array.value);
            }

            if (self.isEdit() && (c.draggable == undefined || c.draggable === true)) {
                c.svgReference.draggable();

                c.svgReference.on('dragmove.namespace', function (event) {
                    if (!isValidPosition(event.srcElement, c)) {
                        //console.log('mal');
                        c.svgReference.fill('#ED5666');
                    } else {
                        //console.log('bien');
                        //console.log('c', c);
                        if (c.fill) {
                            c.svgReference.fill(c.fill);
                        } else if (c.color) {
                            c.svgReference.fill(c.color);
                        } else {
                            c.svgReference.fill(self.getComponentColor(c.categoryId));
                        }
                    }
                });

                c.svgReference.on('dragend.namespace', function (event) {
                    if (!isValidPosition(event.srcElement, c)) {
                        //console.log('mal');
                        c.svgReference.remove();
                        drawLevelObject(c, level);
                    } else {
                        //console.log('bien');
                        if (c.image) {
                            c.pointsPx = getDivPoints(event.srcElement);
                            c.points = pixelsToPercentage(c.pointsPx, level);
                            c.svgReference.remove();
                            drawLevelObject(c, level);
                            c.imageMove = angular.copy(c.pointsPx[0]);
                            c.move = undefined;
                        } else {
                            // c.pointsPx = getDivPoints(event.srcElement);
                            var $el = angular.element(event.srcElement);
                            var element = event.srcElement;
                            if ($el.is('g:has(polygon)')) {
                                element = $el.find('polygon').get(0);
                            }

                            c.pointsPx = getDivPoints(element);
                            c.points = pixelsToPercentage(c.pointsPx, level);
                            c.move = undefined;
                            if (c.rotation !== 0) {
                                c.oldRotation = c.rotation;
                            }

                            c.rotation = 0;
                            c.svgReference.remove();
                            drawLevelObject(c, level);
                        }
                    }

                    self.levelObjectClick(c);
                    //self.toggleLabels(false);
                });
            }
        }

        function drawInitialData() {
            angular.forEach(self.levels, function (level) {
                angular.forEach(level.floorPlanograms, function (c) {
                    drawLevelObject(c, level);
                });
            });
        }

        function polygonArea(points) {
            area = 0;         // Accumulates area in the loop
            j = points.length - 1;  // The last vertex is the 'previous' one to the first

            for (i = 0; i < points.length; i++) {
                area = area + (points[j][0] + points[i][0]) * (points[j][1] - points[i][1]);
                j = i;  //j is previous vertex to i
            }
            return area / 2;
        }

        function getSectionColor(sectionId) {
            switch (sectionId) {
                case 24: return '#7099AB';
                case 2: return '#AEDB9A';
                case 3: return '#ABB6CC';
                case 4: return '#d27c59';
                case 5: return '#96BCA7';
                case 6: return 'violet';
                case 7: return '#E7B16B';
                case 8: return '#93C6B7';
                case 9: return '#FFBE7A';
                case 10: return '#AEDBff';
            }

            return '';
        }

        function percentageToPixels(points, level) {
            var ret = [];
            var clientWidth = level.levelDiv.clientWidth;
            var clientHeight = level.levelDiv.clientHeight;
            _.each(points, function (point) {
                // r.push([getPixelFromPercentage(point[0], level.levelDiv.clientWidth), getPixelFromPercentage(point[1], level.levelDiv.clientHeight)]);
                var data0 = getPixelFromPercentage(point[0], clientWidth);
                var data1 = getPixelFromPercentage(point[1], clientHeight);
                var aux = [
                    data0,
                    data1
                ];
                ret.push(aux);
            });
            return ret;
        }

        function getPixelFromPercentage(perc, maxPx) {
            return perc * maxPx / 100;
        }

        function pixelsToPercentage(pixels, level) {
            //console.log('pixels', pixels);
            var ret = [];
            var clientWidth = level.levelDiv.clientWidth;
            var clientHeight = level.levelDiv.clientHeight;
            _.each(pixels, function (pixel) {
                //console.log('pixel',pixel);
                // r.push([getPercentageFromPixel(pixel[0], level.levelDiv.clientWidth), getPercentageFromPixel(pixel[1], level.levelDiv.clientHeight)]);
                var data0 = getPercentageFromPixel(pixel[0], clientWidth);
                var data1 = getPercentageFromPixel(pixel[1], clientHeight);
                var aux = [
                    data0,
                    data1
                ];
                ret.push(aux);
            });
            return ret;
        }

        function getPercentageFromPixel(pixel, maxPx) {
            return parseFloat((pixel * 100 / maxPx).toFixed(2));
        }

        function getMiddlePoint(points) {
            var rMax = [-1, -1];
            var rMin = [99999, 99999];
            _.each(points,
                function (p) {
                    rMin[0] = p[0] < rMin[0] ? p[0] : rMin[0];
                    rMin[1] = p[1] < rMin[1] ? p[1] : rMin[1];

                    rMax[0] = p[0] > rMax[0] ? p[0] : rMax[0];
                    rMax[1] = p[1] > rMax[1] ? p[1] : rMax[1];
                });

            return [(rMax[0] + rMin[0]) / 2, (rMax[1] + rMin[1]) / 2,];
        }

        function getWidthHeight(points) {
            var rMax = [-1, -1];
            var rMin = [99999, 99999];
            _.each(points,
                function (p) {
                    rMin[0] = p[0] < rMin[0] ? p[0] : rMin[0];
                    rMin[1] = p[1] < rMin[1] ? p[1] : rMin[1];

                    rMax[0] = p[0] > rMax[0] ? p[0] : rMax[0];
                    rMax[1] = p[1] > rMax[1] ? p[1] : rMax[1];
                });

            return [rMax[0] - rMin[0], rMax[1] - rMin[1]];
        }



        //Filtros

        self.showFilters = false;

        self.toggleFilters = function () {


            self.showFilters = !self.showFilters;

            //if (self.showFilters) {
            //    $timeout(function () {

            //        $window.onclick = function (event) {
            //            closeFilterWhenClickingOutside(event);
            //        }
            //    }, 100);
            //} else {
            //    $window.onclick = null;
            //}

        }

        self.clearFilter = function () {
            //self.selectedBrand = '!null';
            self.filterByBrand('!null');

            //self.selectedTagFilter = '!null';
            self.filterByTag('!null')


        }

        function closeFilterWhenClickingOutside(event) {

            var clickedElement = event.target;
            if (!clickedElement) return;

            var elementClasses = clickedElement.classList;
            var clickedOnFilterDiv = elementClasses.contains('isFilter')
                || elementClasses.contains('chosen-choice')
                || elementClasses.contains('chosen-choices')
                || elementClasses.contains('search-field')
                || elementClasses.contains('default')
                || elementClasses.contains('chosen-container')
                || elementClasses.contains('chosen-container')
            if (!clickedOnFilterDiv) {
                self.closeFilters();
            }

        }

        self.closeFilters = function () {
            $timeout(function () {
                self.showFilters = false;
                $window.onclick = null;
            });

        }

        self.getFilterClass = function () {
            if (self.showFilters == true)
                return 'move-right';
            else
                return '';
        }

        //Filtro collapse

        self.isNavCollapsed = true;
        self.isCollapsedHorizontal = false;
        self.isCollapsed = true;
        self.isSelectedStoreCollapsed = false;
        self.isHeatMapCollapsed = false;
        self.isInfoLayoutsCollapsed = false;
        self.isInfoPerformanceCollapsed = false;
        self.isInfoRankingCollapsed = false;
        self.isInfoCategoriesCollapsed = false;
        self.isInfoPlanogramCollapsed = false;

        var translations = null;

        function init() {

            $translate(["SaveLayoutSwalTitle", "SavedLayoutSwal", "SavedLayoutSwalSubtitle", "SavedTicketSwal", "SavedTicketSwalSubtitle", "ValidationSwalTitle", "ValidationSwalSubtitle", "CancelSwal", "ContinueSwal", "RemoveSwal", "SpaceRestPlanograms", "SureDeletePlanogramSwal", "SalesRestPlanograms", "SpaceShare", "SalesShare", "Overpaced", "Subspaced", "ShowInformation", "ShowPlanograms", "AlertWillLoseChanges", "SwalEmptyFloors", "HidePlanograms", "HideInformation", "ViewMore", "ViewLess", "ErrorTitleSwal", "Units", "Sales", "Margin", "Price", "Inventory", "StockOut", "Low", "Medium", "HeightF"])
                .then(function (all) {
                    translations = all;
                    self.translationUnits = translations.Units;
                    self.translationSales = translations.Sales;
                    self.translationMargin = translations.Margin;
                    self.translationPrice = translations.Price;
                    self.translationInventory = translations.Inventory;
                    self.translationStockOut = translations.StockOut;
                    self.translationLow = translations.Low;
                    self.translationMedium = translations.Medium;
                    self.translationHeightF = translations.HeightF;

                    self.priorityTickets = [
                        { id: 1, name: self.translationLow },
                        { id: 2, name: self.translationMedium },
                        { id: 3, name: self.translationHeightF },
                    ]

                })
                .then(function () {
                    self.buttonTextPlanogramsPanel = translations.HidePlanograms;
                    self.buttonTextInformationPanel = translations.HideInformation;
                });

            if (self.isEdit() && !self.hasPermissionToEdit) {
                $state.go('spaces.viewLayout', { layoutId: $stateParams.layoutId });
            }

            loadData();

            initEvents();

        }
        init();
    });
