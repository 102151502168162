'use strict';
angular.module("prisma")
    .factory('authService', ['$http', '$q', '$rootScope', '$cookieStore', '$location', 'localStorageService', 'ngAuthSettings', 'serverService', 'companyJson', 'ngDialog', function ($http, $q, $rootScope, $cookieStore, $location, localStorageService, ngAuthSettings, serverService, companyJson, ngDialog) {

        var serviceBase = serverService.getApiUrl();
        var authServiceFactory = {};

        var _authentication = {
            isAuth: false,
            userName: "",
            firstname: "",
            lastname: "",
            userId: null,
            companyId: null,
            companyCode: "",
            roles: [],
            permissions: [],
            categories: [],
            stores: [],
            useRefreshTokens: false,
            redirectUrl: null
        };

        var _login = function (loginData) {

            var data = { username: loginData.userName, password: loginData.password }

            if (loginData.useRefreshTokens) {
                data.client_id = ngAuthSettings.clientId;
            }

            var deferred = $q.defer();

            //$http.post(ian.urlCombine(serviceBase, 'token'), data).success(function (response) {
            $http.post(ian.urlCombine(serviceBase, 'account/login'), data).success(function (response) {

                if (response.authenticated) {

                    var userinfo = {};

                    localStorageService.set('authorizationData',
                        {
                            token: response.token,
                            userName: loginData.userName,
                            firstname: userinfo.firstname,
                            lastname: userinfo.lastname,
                            userId: userinfo.userId,
                            companyId: userinfo.companyId,
                            companyCode: userinfo.companyCode,
                            roles: userinfo.roles,
                            permissions: userinfo.permissions,
                            categories: userinfo.categoryIds,
                            stores: userinfo.storeIds,
                            local: true,
                            refreshToken: "",
                            useRefreshTokens: false,
                            redirectUrl: _authentication.redirectUrl
                        });

                    _fillAuthData();

                    deferred.resolve(_authentication);


                }
                else {
                    _logOut();

                    if (response.reason) {
                        switch (response.reason) {
                            case 'Unroled': swal('Usuario Incompleto', 'Su usuario necesita terminar de configurarse. Ponganse en contacto con seguridad informatica para completar su activación.', 'warning'); break;
                            case 'Disabled': swal('Usuario Bloqueado', 'Su usuario fue bloqueado por un administrador. Si cree que fue un error ponganse en contacto con seguridad informatica para pedir su activación.', 'warning'); break;
                        }
                    }


                    deferred.reject(err);
                }


            }).error(function (err, status) {
                _logOut();
                deferred.reject(err);
            });

            return deferred.promise;

        };

        var _getUserInfo = function () {

            var deferred = $q.defer();

            if (_authentication.isAuth) {

                $http.post(ian.urlCombine(serviceBase, 'token/userinfo'))
                    .success(function (userinfo) {
                        var authData = localStorageService.get('authorizationData');
                        localStorageService.set('authorizationData',
                            {
                                token: authData.token,
                                userName: authData.userName,
                                firstname: userinfo.firstname,
                                lastname: userinfo.lastname,
                                userId: userinfo.userId,
                                companyId: userinfo.companyId,
                                companyCode: userinfo.companyCode,
                                roles: userinfo.roles,
                                permissions: userinfo.permissions,
                                categories: userinfo.categoryIds,
                                stores: userinfo.storeIds,
                                refreshToken: authData.refreshToken,
                                useRefreshTokens: authData.useRefreshTokens
                            });

                        _fillAuthData();

                        deferred.resolve();
                    })
                    .error(function (err) {
                        deferred.reject(err);
                    });
            }


            return deferred.promise;
        }

        var _hasPermission = function (permission) {
            return _authentication.permissions.indexOf(permission) != -1;
        }

        var _logOut = function (redirectUrl) {

            localStorageService.remove('authorizationData');

            _authentication.isAuth = false;
            _authentication.userName = "";
            _authentication.firstname = "";
            _authentication.lastname = "";
            _authentication.userId = "";
            _authentication.companyId = "";
            _authentication.companyCode = "";
            _authentication.roles = [];
            _authentication.permissions = [];
            _authentication.categories = [];
            _authentication.stores = [];
            _authentication.useRefreshTokens = false;
            _authentication.redirectUrl = redirectUrl || null;

            $rootScope.username = '';
            $rootScope.role = '';
            if (_authentication.local == false && companyJson.Security.Federated) {
                $http.post('/auth/logout').success(function () {
                    if (redirectUrl) {
                        window.location.href = redirectUrl;
                    } else {
                        window.location.reload();
                    }
                });
            }
        };

        var _fillAuthData = function () {

            var authData = localStorageService.get('authorizationData');
            if (authData) {
                _authentication.isAuth = true;
                _authentication.local = authData.local;
                _authentication.userName = authData.userName;
                _authentication.firstname = authData.firstname;
                _authentication.lastname = authData.lastname;
                _authentication.userId = authData.userId;
                _authentication.companyId = authData.companyId;
                _authentication.companyCode = authData.companyCode;
                _authentication.roles = authData.roles;
                _authentication.permissions = authData.permissions;
                _authentication.categories = authData.categories;
                _authentication.stores = authData.storeIds;
                _authentication.useRefreshTokens = authData.useRefreshTokens;

                if (authData.roles && authData.roles.indexOf("Unroled") >= 0) {
                    _logOut();
                    swal('Usuario Incompleto', 'Su usuario necesita terminar de configurarse. Ponganse en contacto con seguridad informatica para completar su activación.', 'warning');
                }
            }

        };

        var _refreshToken = function () {
            var deferred = $q.defer();

            var authData = localStorageService.get('authorizationData');

            if (authData) {

                if (authData.useRefreshTokens) {

                    var data = "grant_type=refresh_token&refresh_token=" + authData.refreshToken + "&client_id=" + ngAuthSettings.clientId;

                    localStorageService.remove('authorizationData');

                    $http.post(ian.urlCombine(serviceBase, 'token'), data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).success(function (response) {

                        localStorageService.set('authorizationData', { token: response.access_token, userName: response.userName, refreshToken: response.refresh_token, useRefreshTokens: true });

                        deferred.resolve(response);

                    }).error(function (err, status) {
                        _logOut();
                        deferred.reject(err);
                    });
                }
            }

            return deferred.promise;
        };

        var _obtainAccessToken = function (externalData) {

            var deferred = $q.defer();

            $http.get(ian.urlCombine(serviceBase, 'account/ObtainLocalAccessToken'), { params: { provider: externalData.provider, externalAccessToken: externalData.externalAccessToken } }).success(function (response) {

                localStorageService.set('authorizationData', { token: response.access_token, userName: response.userName, refreshToken: "", useRefreshTokens: false });

                _authentication.isAuth = true;
                _authentication.userName = response.userName;
                _authentication.userRole = response.userRole;
                _authentication.useRefreshTokens = false;

                deferred.resolve(response);

            }).error(function (err, status) {
                _logOut();
                deferred.reject(err);
            });

            return deferred.promise;

        };

        var _prompSessionLimit = function () {
            swal({
                title: "Límite de sesiones",
                text: "Su usuario ha alcanzado el limite de sesiones activas. Desear salir de su otra sesion activa?",
                icon: "warning",
                showCancelButton: true,
                showConfirmButton: true,
                customClass: "sessions-alert"
            }, function (willClose) {
                if (willClose) {
                    window.location.href = '/auth/login?kick=true';
                } else {
                    _logOut();
                }
            });
            $('.sessions-alert').siblings('.sweet-overlay').css({ 'background-color': 'black', opacity: 1 });
        };

        var isOpen401 = false;
        var _promptSessionExpired = function () {
            if (isOpen401 === false) {
                isOpen401 = true;
                ngDialog.open({
                    template: 'sessionExpiredDialog',
                    className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-300',
                    preCloseCallback: function () {
                        isOpen401 = false;
                        _logOut();
                        $location.path('/login');
                    }
                });
            }

        }

        authServiceFactory.login = _login;
        authServiceFactory.logOut = _logOut;
        authServiceFactory.prompSessionLimit = _prompSessionLimit;
        authServiceFactory.fillAuthData = _fillAuthData;
        authServiceFactory.authentication = _authentication;
        authServiceFactory.refreshToken = _refreshToken;
        authServiceFactory.getUserInfo = _getUserInfo;
        authServiceFactory.hasPermission = _hasPermission;
        authServiceFactory.promptSessionExpired = _promptSessionExpired;

        authServiceFactory.obtainAccessToken = _obtainAccessToken;

        return authServiceFactory;
    }]);