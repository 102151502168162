angular.module("prisma")
  .controller("AssortmentOutputPrismaCtrl", function ($scope, $timeout, $rootScope, $stateParams, $filter, assortmentService, adminService) {
      var self = this;
      self.gridData = null;

      self.categoryId = null;
      self.categoryGroupId = null;
      self.categoriesGroup = [];
      self.allCategories = [];
      self.categories = [];

      self.exportData = function () {

          swal({
              title: "Esta seguro?",
              text: 'Esta seguro que desea exportar la informacion ?',
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#1AB394",
              confirmButtonText: "Continuar",
              cancelButtonText: "Cancelar",
              showLoaderOnConfirm: true,
              closeOnConfirm: true,
              closeOnCancel: true
          },
           function (isConfirm) {
               if (isConfirm) {
                   var params = {
                       skipHeader: false,
                       skipFooters: false,
                       skipGroups: false,
                       allColumns: true,
                       onlySelected: false,
                       suppressQuotes: false,
                       fileName: 'ExportDatosSurtido' + (new Date()).toISOString().replace(/[^0-9]/g, "").substring(0, 12) + '.csv',
                       columnSeparator: ','
                   };

                   self.gridOptions.api.exportDataAsCsv(params);
                   $timeout(function () { swal("Confirmado", "Se exporto correctamente", "success"); }, 100);

               }
           });
      }

      //grid config
      function setColumnDefinitions() {
          columnDefs = [
          {
              headerName: '',
              children: [
              {
                  headerName: "Codigo Tienda",
                  field: "storeCode",
                  width: 100,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              },
              {
                  headerName: "Codigo Item",
                  field: "itemCode",
                  width: 100,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              },
              {
                  headerName: "Desde",
                  field: "validFrom",
                  width: 80,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              }
              , {
                  headerName: "Hasta",
                  field: "validTo",
                  width: 80,
                  suppressMenu: true,
                  suppressSorting: true,
                  cellClass: $rootScope.getClassForCell,
              },
              //{
              //    headerName: "Cluster",
              //    field: "clusterCode",
              //    width: 80,
              //    suppressMenu: true,
              //    suppressSorting: true,
              //    cellClass: $rootScope.getClassForCell,
              //},
              //{
              //    headerName: "Categoria",
              //    field: "category",
              //    width: 120,
              //    suppressMenu: true,
              //    suppressSorting: true,
              //    cellClass: $rootScope.getClassForCell,
              //},
              //{
              //    headerName: "SubCategoria",
              //    field: "subCategory",
              //    width: 120,
              //    suppressMenu: true,
              //    suppressSorting: true,
              //    cellClass: $rootScope.getClassForCell,
              //}
              ]
          }]
      }
      function setGridOptions() {

          setColumnDefinitions();

          self.gridOptions = {
              columnDefs: columnDefs,
              enableColResize: true,
              enableSorting: true,
              enableFilter: true,
              groupHideGroupColumns: true,
              groupUseEntireRow: false,
              groupSelectsChildren: false,
              rowHeight: 30,
              rowSelection: "multiple",
              rowDeselection: true,
              showToolPanel: false,// window.innerWidth > 1500,
              suppressRowClickSelection: true,
              suppressCellSelection: true,
              singleClickEdit: true,
              icons: {
                  columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                  filter: '<i class="fa fa-filter"/>',
                  sortAscending: '<i class="fa fa-long-arrow-down"/>',
                  sortDescending: '<i class="fa fa-long-arrow-up"/>',
                  groupExpanded: '<i class="fa fa-minus-square-o"/>',
                  groupContracted: '<i class="fa fa-plus-square-o"/>',
                  columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                  columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
              },
              suppressColumnMoveAnimation: $rootScope.suppressColumnMoveAnimation(),
              enableRangeSelection: false,
              angularCompileRows: true,
              angularCompileHeaders: true,
          }
      }

      self.loadData = function () {
          //if (self.categoryId != null || self.categoryGroupId != null) {

              var catId = self.categoryId != null ? self.categoryId : self.categoryGroupId;
              assortmentService.output.getPrismaEndosedData(catId).then(function (data) {
                  self.gridData = data;

                  self.gridOptions.api.setRowData(self.gridData);
                  self.gridOptions.api.sizeColumnsToFit();
                  self.gridOptions.api.refreshView();
              });
          //}
      }
      function loadCategories() {
          adminService.categories.getCategories()
          .then(function (categories) {
              self.allCategories = categories;
          });
      }
      function loadCategoriesGroup() {
          adminService.categories.getCategoryGroups()
          .then(function (cgrp) {
              self.categoriesGroup = cgrp;
          });
      }

      self.categoryGroupChanged = function (catGrpId) {
          self.categoryId = null;
          self.categories = [];
          angular.forEach(self.allCategories, function (cat) {
              if (cat.parentId == catGrpId)
                  self.categories.push(cat);
          });
      }

      function init() {
          setGridOptions();
          loadCategoriesGroup();
          loadCategories();
      }

      init();
  });
