angular.module("prisma")
 .controller("promotionRulesCtrl", function ($scope, promotionsService) {
     var self = this;
     
    self.rules = [];
    self.selectedCategories = [];
    self.selectedBrands = [];
    self.selectedMediaTypes = [];
    self.selectedPageTypes = [];
    self.selectedItemTypes = [];

     self.isLoading = true;
     
     self.setCategories = function () {
         self.categories = self.ruleParams.categories.filter(function (x) { return x.level == 4 && x.parentId == self.selectedCategoriesGroup.categoryId });
     } 

     self.delete = function (rule) {

         swal({
             title: 'Esta seguro que desea borrar la Regla ' + rule.id + ' ?',
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: "Continuar",
             cancelButtonText: "Cancelar",
             showLoaderOnConfirm: true,
             closeOnConfirm: false,
             closeOnCancel: true
         }, function (isConfirm) {
             if (isConfirm) {
                 promotionsService.rules.deleteRule(rule.id).then(function () {
                     swal('Regla Borrada!', 'La Regla ' + rule.id + ' se ha borrado exitosamente', 'success');
                     var ix = self.rules.indexOf(rule);
                     self.rules.splice(ix, 1);
                 }, function () { swal('Hubo un error', 'Ocurrio un error intentando borrar la regla', 'error'); });
             }
         });
         
     }

     self.getRules = function () {
         self.isLoading = true;

         var categoriesIds = [];
         //seteo el total de categorias para filtrar
         if (self.selectedCategories.length > 0)
             categoriesIds = self.selectedCategories.map(function (x) { return x.categoryId });
         else
             categoriesIds = self.ruleParams.categories.filter(function (x) { return x.level == 4 && x.parentId == self.selectedCategoriesGroup.categoryId }).map(function (x) { return x.categoryId });

         if (self.selectedBrands.length == 0)
             self.selectedBrands = self.ruleParams.brands;
         if (self.selectedMediaTypes.length == 0)
             self.selectedMediaTypes = self.ruleParams.mediaTypes;
         if (self.selectedPageTypes.length == 0)
             self.selectedPageTypes = self.ruleParams.pageTypes;
         if (self.selectedItemTypes.length == 0)
             self.selectedItemTypes = self.ruleParams.itemTypes;

         var filters = {
             categoriesIds: categoriesIds,
             ruleTypes: self.selectedRuleTypes,
             brandsIds: self.selectedBrands.map(function (x) { return x.id }),
             mediaTypes: self.selectedMediaTypes,
             pageTypes: self.selectedPageTypes,
             itemTypes: self.selectedItemTypes
         }
         promotionsService.rules.getRulesFiltered(filters)
         .then(function (rules) {
         self.rules = rules;
         promotionsService.rules.extendRules(self.rules);
         self.isLoading = false;
         });
     }


     function init() {

         promotionsService.rules.getRuleParams().then(function (ruleParams) {
             self.ruleParams = ruleParams;

             self.categoriesGroups = self.ruleParams.categories.filter(function (x) { return x.level == 3 });

             self.isLoading = false;

         });
     }
         

     init();
 });