var _controller = function ($scope, $attrs, $filter, $timeout, $rootScope, priceService, homeService) {
    var self = this;
    console.log('auditTrial', self);
    //self.days = 7;
    self.trailsInDays = [];

    self.changeDays = function (days) {
        if (days == self.days) return;
        self.days = days;
        self.trailsInDays = [];

        var dateToCompare = new Date();
        //console.log('days',days);
        //console.log('dateToCompare', dateToCompare);
        dateToCompare.setDate(dateToCompare.getDate() - days);
        //console.log('dateToCompare', dateToCompare);

        angular.forEach(self.price.auditTrail, function (auditTrail) {
            var auditDate = new Date(auditTrail.createdDate);
            auditTrail.createdDate = auditDate.setHours(auditDate.getHours() + companyJson.TimeZone.Gmt);
            //console.log('fecha', auditDate);
            //console.log('dateToCompare', dateToCompare);
            if (auditDate > dateToCompare) {
                //console.log('agregando');
                self.trailsInDays.push(auditTrail);
            }
        })
    }
    self.changeDays(7);
   
}

angular.module('prisma').component('auditTrial', {
    templateUrl: 'app/shared_logic/components/auditTrial/auditTrial.component.html',
    bindings: {
        price: '<'
    },

    controller: _controller
});


