var _controller = function (componentsService, $scope, $element, $attrs, $q, $timeout) {
    var ctrl = this;
    console.log('ctrl', ctrl);

    ctrl.lastSeries;
    ctrl.lastProperty;


    ctrl.changeProperty = function (property) {
        if (property == ctrl.lastProperty) return;

        //ctrl.lastProperty = property;
        ctrl.chartConfiguration.changeProperty(property);
    }

    ctrl.getChartConfig = function (chartId, data) {
        var template = {
            bindto: chartId,

            data: {
                json: data,
                type: ctrl.chartConfiguration.chartType,

                labels: {
                    format: function (v, id, i, j) {

                        var format = d3.format('$');
                        return format(v)

                    },
                },
                //onclick: function () {
                //    if (ctrl.chartConfiguration.chartType == "bar")
                //        ctrl.chartConfiguration.updateChartType('pie');
                //    else if (ctrl.chartConfiguration.chartType == "pie")
                //        ctrl.chartConfiguration.updateChartType('bar');
                //}
            },
            tooltip: {
                //format: {
                //    /*...*/
                //},
                contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    //return ""
                    var $$ = this, config = $$.config,
                        titleFormat = config.tooltip_format_title || defaultTitleFormat,
                        nameFormat = config.tooltip_format_name || function (name) { return name; },
                        valueFormat = config.tooltip_format_value || defaultValueFormat,
                        text, i, title, value, name, bgcolor;
                    for (i = 0; i < d.length; i++) {
                        if (!(d[i] && (d[i].value || d[i].value === 0))) { continue; }
                        console.log(d[i]);

                        if (!text) {
                            title = titleFormat ? titleFormat(d[i].x) : d[i].x;
                            text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");
                        }

                        name = nameFormat(d[i].name);
                        value = valueFormat(d[i].value, d[i].ratio, d[i].id, d[i].index);
                        bgcolor = $$.levelColor ? $$.levelColor(d[i].value) : color(d[i].id);

                        text += "<tr class='" + $$.CLASS.tooltipName + "-" + d[i].id + "'>";
                        text += "<td class='name'><span style='background-color:" + bgcolor + "'></span>" + name + "</td>";
                        text += "<td class='value'>" + value + "</td>";
                        text += "</tr>";
                    }
                    return text + "</table>";
                }
            }


        }


        if (ctrl.chartConfiguration.chartType == 'pie') {

            var jsonData = {};
            var names = [];
            data.forEach(function (e) {
                names.push(e.name);
                jsonData[e.name] = e[ctrl.chartConfiguration.defaultProperty];
            })
            console.log('jsonData', jsonData);
            template.data.json = [jsonData];
            template.data.keys = { value: names }


            template.pie = {
                label: {
                    format: function (value, ratio, id,d) {
                        console.log(value, ratio, id, d);
                        return value;
                    }
                }
            };

            //template.data.keys.value.push(ctrl.chartConfiguration.defaultProperty);

        } else {

            template.data.keys = {
                value: [],
                x: 'name'
            };


            template.axis = {
                x: {
                    type: 'category',
                },
                y: {
                    tick: {
                        format: d3.format(",.0f")
                    }
                },
            }
        }

        if (ctrl.chartConfiguration.multipleValues && ctrl.chartConfiguration.chartType != 'pie') {


            template.data.types = {};

            for (var i = 0; i < ctrl.chartConfiguration.properties.length; i++) {
                template.data.keys.value.push(ctrl.chartConfiguration.properties[i].code);
                if (ctrl.chartConfiguration.properties[i].code != ctrl.chartConfiguration.defaultProperty)
                    template.data.types[ctrl.chartConfiguration.properties[i].code] = 'spline';

            }
            template.data.axes = {}
            template.data.axes[ctrl.chartConfiguration.defaultProperty] = 'y';
            if (ctrl.chartConfiguration.secondaryAxisProperty) {
                template.data.axes[ctrl.chartConfiguration.secondaryAxisProperty] = 'y2';
                template.axis.y2 = { show: true };
            }
        }




        console.log('template', template);
        return template;



    }



    ctrl.parseChartData = function (chartData, chart) {
        if (chartData == undefined || chartData.length == 0) return [];

        console.log('chartdata', chartData);
        var data = [];
        var competitors = [];
        //var dates = ['x'];
        var series = [];

        //obtengo competitors
        //var competitor = '';
        //console.log('chartdata', chartData);
        angular.forEach(chartData, function (d) {
            if (competitors.indexOf(d.name) == -1) {
                competitors.push(d.name);
                //competitor = d.competitor;
            }
        });


        //obtengo dates
        //var date = '';
        //angular.forEach(chartData, function (d) {
        //    var tempdate = new Date(d.observedDate);
        //    var currDate = tempdate.getFullYear() + '-' + (tempdate.getMonth() + 1) + '-' + tempdate.getDate();
        //    if (dates.indexOf(currDate) == -1) {
        //        dates.push(currDate);
        //    }
        //});

        function competitorInSerie(competitor) {
            for (var i = 0; i < series.length; i++) {
                if (series[i][0] == competitor)
                    return true;
            }
            return false;
        }

        //armo las series
        var serie = [];
        angular.forEach(chartData, function (d) {
            if (!competitorInSerie(d.name)) {

                serie = [];
                serie.push(d.name);
                serie.push(d[chart]);
                competitor = d.name;
                series.push(serie);
            }
            else {
                serie.push(d[chart]);
            }
        });
        //data.push(dates);
        angular.forEach(series, function (s) {
            data.push(s);
        });

        return data;
    }


    function fillDefaultValues() {
        if (ctrl.chartConfiguration.chartType == undefined) {
            ctrl.chartConfiguration.chartType = "bar";
        }

        if (ctrl.chartConfiguration.styles == undefined) {
            ctrl.chartConfiguration.styles = {};
        }

        if (ctrl.chartConfiguration.defaultProperty == undefined) {
            ctrl.chartConfiguration.defaultProperty = ctrl.chartConfiguration.properties[0].code;
        }
    }

    function init() {

        fillDefaultValues();

        ctrl.chartConfiguration.updateChart = function (series, property) {
            ctrl.lastSeries = series;
            ctrl.lastProperty = property;
            var dataParsed = parseData(ctrl.lastSeries, ctrl.lastProperty);

            ctrl.chartConfiguration.chartReference = c3.generate(ctrl.getChartConfig('#chartName-' + ctrl.chartConfiguration.chartName, dataParsed));
            //console.log('reference', ctrl.chartConfiguration.chartReference);
        }

        ctrl.chartConfiguration.updateChartType = function (_chartType) {
            ctrl.chartConfiguration.chartType = _chartType;
            var dataParsed = parseData(ctrl.lastSeries, ctrl.lastProperty);
            //var dataParsed = ctrl.lastSeries//; ctrl.parseChartData(ctrl.lastSeries, ctrl.lastProperty);
            ctrl.chartConfiguration.chartReference = c3.generate(ctrl.getChartConfig('#chartName-' + ctrl.chartConfiguration.chartName, dataParsed));
        }

        ctrl.chartConfiguration.changeProperty = function (property) {
            ctrl.lastProperty = property;
            var dataParsed = parseData(ctrl.lastSeries, ctrl.lastProperty);
            //var dataParsed = ctrl.lastSeries //ctrl.parseChartData(ctrl.lastSeries, ctrl.lastProperty);
            ctrl.chartConfiguration.chartReference = c3.generate(ctrl.getChartConfig('#chartName-' + ctrl.chartConfiguration.chartName, dataParsed));
        }
    }

    function parseData(series, property) {
        var dataParsed = [];
        console.log('parseando data');
        if (ctrl.chartConfiguration.chartType == 'pie') {
            for (var i = 0; i < series.length; i++) {
                if (series[i].label.toLowerCase() == property.toLowerCase()) {
                    dataParsed.push(series[i]);
                    console.log('agregando');
                }
            }
        } else {
            dataParsed = series;//ctrl.parseChartData(series, property);
        }
        return dataParsed;
    }

    init();
}

angular.module('prisma').component('chartComponent', {
    templateUrl: 'app/shared_logic/components/chartComponent/chart.component.html',
    bindings: {
        chartConfiguration: '=',
    },

    controller: _controller
});