"use strict";

angular.module("prisma")
    .service("priceService", function categoryService( $q, $rootScope, httpService, $translate) {
        var self = this;

        self.getPricesPromise;
        self.getPriceDashboardPromise;
        self.lastCategoryId = 0;
        self.lastEndorse = null;

        self.costs = {
            getCurrentCosts: function () {
                return httpService.get('/price/costs/getCurrentCosts');
            },
            getVendors: function () {
                return httpService.get('/price/costs/getVendors');
            },
            getItems: function () {
                return httpService.get('/price/costs/getItems');
            },
            getDistributionCenters: function () {
                return httpService.get('/price/costs/getDistributionCenters');
            },
            save: function (cost) {
                return httpService.post('/price/costs/save', cost);
            },
            saveAndGenerateSuggestions: function () {
                return httpService.post('/price/costs/saveAndGenerateSuggestions');
            }
        }

        self.decisions = {
            getKpiDashboardYTD: function (itemId, priceListId, sameStores, type) {
                return httpService.get('/price/decisions/price-dashboard/kpi-ytd-comparison/' + itemId + '/' + priceListId + '/' + sameStores + '/' + type);
            },
            getKPIDashboardCompetitorsPrices: function (parameters) {
                return httpService.post('/price/decisions/price-dashboard/getKPIDashboardCompetitorsPrices', parameters);
            },
            getCategorySummary: function (categoryMinLevel) {
                return httpService.get('/price/decisions/category-summary/' + categoryMinLevel);
            },
            getPriceDashboard: function (categoryId) {
                var fetchDataFromServer = false;
                if (self.lastCategoryId != categoryId || !self.getPriceDashboardPromise) {
                    fetchDataFromServer = true;
                }
                if (fetchDataFromServer) {
                    self.getPriceDashboardPromise = httpService.get('/price/decisions/price-dashboard/' + $rootScope.companyId + '/' + categoryId);
                }

                return self.getPriceDashboardPromise;
            },

            getPriceDashboardItem: function (categoryId, itemId) {

                //if (self.lastCategoryId != categoryId || !self.getPriceDashboardPromise) {
                return httpService.get('/price/decisions/price-dashboard/' + $rootScope.companyId + '/' + categoryId + '/' + itemId);
                //}
            },

            getPriceDashboardItemPriceList: function (categoryId, itemId, priceListId, sameStores) {

                //if (self.lastCategoryId != categoryId || !self.getPriceDashboardPromise) {
                return httpService.get('/price/decisions/price-dashboard/' + $rootScope.companyId + '/' + categoryId + '/' + itemId + '/' + priceListId + '/' + sameStores);
                //}
            },
            getPrices: function (categoryId, isEndorse, selectedStatus) {

                return httpService.get('/price/decisions/' + $rootScope.companyId + '/' + categoryId + '/' + isEndorse + '/' + selectedStatus);
                /*
                var fetchDataFromServer = false;
                if (self.lastCategoryId != categoryId || self.lastEndorse != isEndorse || !self.getPricesPromise) {
                    fetchDataFromServer = true;
                }
                if (fetchDataFromServer) {
                    self.getPricesPromise = httpService.get('/price/decisions/' + $rootScope.companyId + '/' + categoryId + '/' + isEndorse);
                }

                return self.getPricesPromise;
                */
            },
            getCompetitorsGroupPrices: function (itemId, priceListId) {

                return httpService.get('/price/decisions/getCompetitorsGroupPrices/' + itemId + '/' + priceListId);
            },
            getVolumeMeasurement_OXXO: function (itemId, storeId) {

                return httpService.get('/price/decisions/getVolumeMeasurement_OXXO/' + itemId + '/' + storeId);
            },
            getVolumeMeasurementAnnual_OXXO: function (itemId, storeId) {

                return httpService.get('/price/decisions/getVolumeMeasurementAnnual_OXXO/' + itemId + '/' + storeId);
            },

            getCompetitorsGroupPricesByPriceListIds: function (data) {

                return httpService.post('/price/decisions/getCompetitorsGroupPricesByPriceListIds', data);
            },

            markAsReviewed: function (categoryId, categorygroup, category, subcategory, priceType, ignorePendingSuggestions) {
                return httpService.post('/price/decisions/markAsReviewed/' + categoryId + '/' + categorygroup + '/' + category + '/' + subcategory + '/' + priceType + '/' + ignorePendingSuggestions);
            },
            markAsEndorsed: function (categoryId, categorygroup, category) {
                return httpService.post('/price/decisions/markAsEndorsed/' + categoryId + '/' + categorygroup + '/' + category);
            },
            saveNewPrices: function (categoryId, newPrices) {
                return httpService.post('/price/decisions/new-prices/' + categoryId, newPrices);
            },
            endorsePrices: function (newPrices) {
                return httpService.post('/price/decisions/endorse/', newPrices);
            },
            ignoreSuggestions: function (newPrices) {
                return httpService.post('/price/decisions/ignore-suggestions/', newPrices);
            },
            getEndorsePrices: function (categoryId) {
                return httpService.get('/price/decisions/endorse/' + categoryId);
            },
            getChatForItem: function (priceId) {
                return httpService.get('price/decisions/getChat/' + priceId);
            },
            saveChatForItem: function (chat) {
                return httpService.post('price/decisions/save-chat', chat);
            },
            rejectEndorse: function (pricesToReject, comment, url) {
                return httpService.post('price/decisions/reject-endorse', { prices: pricesToReject, reason: comment, url: url });
            },
            getCurrentPrices: function (itemId, salesUnitOfMeasureId, priceListId) {
                return httpService.get('price/decisions/getCurrentPrices/' + itemId + '/' + salesUnitOfMeasureId + '/' + priceListId);
            },
            getRegularPrice: function (itemId, salesUnitOfMeasureId, priceListId) {
                return httpService.get('price/decisions/getRegularPrice/' + itemId + '/' + salesUnitOfMeasureId + '/' + priceListId);
            }
        }

        self.competitors = {
            getCompetitorUnitSales: function (competitorId, itemId, isOwn, months) {
                return httpService.get('/price/competitors/get-units-sales/' + competitorId + '/' + itemId + '/' + isOwn + '/' + months);
            },
            getCompetitorGroups: function () {
                return httpService.get('/price/competitors/competitor-groups/');
            },

            getCompetitorGroupsByPriceList: function (priceListId) {
                return httpService.get('/price/competitors/competitor-groups/' + priceListId);
            },
            getCompetitorGroupsByPriceListIds: function (data) {
                return httpService.post('/price/competitors/competitor-groups', data);
            },
            getCompetitorGroupsParentByPriceList: function (priceListId) {
                return httpService.get('/price/competitors/competitor-groups-parent/' + priceListId);
            },
            getCompetitorGroupsParentByPriceListIds: function (data) {
                return httpService.post('/price/competitors/competitor-groups-parent', data);
            },
            getCompetitorGroupsParent: function () {
                return httpService.get('/price/competitors/competitor-groups-parent/');
            },
            getCompetitors: function (compGroupId) {
                return httpService.get('/price/competitors/competitors/' + compGroupId);
            },
            getCompetitorsWithoutGroup: function () {
                return httpService.get('/price/competitors/competitors-without-group/');
            },
            getCompetitorsTree: function () {
                return httpService.get('/price/competitors/competitors/getCompetitorsTree');
            },
            competitors: function () {
                return httpService.get('/price/competitors/competitors');
            },
            saveCompetitor: function (competitor) {
                return httpService.post('/price/competitors/competitors/save', competitor);
            },
            saveCompetitorsTree: function (competitorsTree) {
                return httpService.post('/price/competitors/competitorsGroup/save', competitorsTree);
            },
            saveCompetitorsTreeRelations: function (competitorsTreeRelations) {
                return httpService.post('/price/competitors/competitorsGroup/save-relations', competitorsTreeRelations);
            },
            deleteCompetitor: function (competitorId) {
                return httpService.get('/price/competitors/competitors/delete/' + competitorId);
            },
            deleteCompetitorsTree: function (CompetitorsTreeId) {
                return httpService.get('/price/competitors/competitorsGroup/delete/' + CompetitorsTreeId);
            }

        }

        self.exchangeRates = {
            getCurrent: function () {
                return httpService.get('/price/exchange-rates/get-current');
            },
        }

        self.pricelists = {
            getPriceLists: function () {
                return httpService.get('/price/priceLists/pricelists');
            },
            getAllPricelists: function () {
                return httpService.get('/price/priceLists/pricelists/all');
            },
            getPricelist: function (id) {
                return httpService.get('/price/priceLists/getPriceList/' + id);
            },
            deletePricelist: function (id) {
                return httpService.get('/price/priceLists/deletePriceList/' + id);
            },
            savePricelist: function (priceList) {
                return httpService.post('/price/priceLists/save', priceList);
            }
        }

        self.pricelistsSubsidies = {
            getPricelistsSubsidies: function () {
                return httpService.get('/price/pricelistsSubsidies/pricelistsSubsidies');
            },
            getAllPricelistsSubsidies: function () {
                return httpService.get('/price/pricelistsSubsidies/pricelistsSubsidies/all');
            },
            getPricelistSubsidy: function (id) {
                return httpService.get('/price/pricelistsSubsidies/getPriceListSubsidy/' + id);
            },
            deletePricelistSubsidy: function (id) {
                return httpService.get('/price/pricelistsSubsidies/deletePriceListSubsidy/' + id);
            },
            savePricelistSubsidy: function (priceList) {
                return httpService.post('/price/pricelistsSubsidies/save', priceList);
            }
        }

        self.regions = {
            getRegions: function () {
                return httpService.get('/price/regions/regions');
            },
            getRegion: function (id) {
                return httpService.get('/price/regions/getRegion/' + id);
            },
            saveRegion: function (region) {
                return httpService.post('/price/regions/save', region);
            }
        }

        self.pricesurveys = {
            onlyActive: function (itemId, priceListId, includeOwnPrices) {
                return httpService.get('/price/pricesurveys/onlyActive/' + itemId + '/' + priceListId + '/' + includeOwnPrices);
            },
            onlyActiveByPriceListIds: function (data) {
                return httpService.post('/price/pricesurveys/onlyActiveByPriceListIds', data);
            },
            getByPriceSuggestionId: function (id) {
                return httpService.get('/price/pricesurveys/getByPriceSuggestionId/' + id);
            },
            getHistorical: function (competitorId, itemId, salesUnitOfMeasurementId, priceType, isOwn) {
                //console.log('url', '/price/pricesurveys/getHistorical/' + competitorId + '/' + itemId + '/' + salesUnitOfMeasurementId + '/' + priceType + '/' + isOwn);
                return httpService.get('/price/pricesurveys/getHistorical/' + competitorId + '/' + itemId + '/' + salesUnitOfMeasurementId + '/' + priceType + '/' + isOwn);
            }

        }
        self.evolutionReport = {
            getData: function (itemId, priceListId, dateFrom, dateTo) {
                return httpService.get('/price/pricesurveys/GetPricesForEvolutionReport/' + itemId + '/' + priceListId + '/' + dateFrom + '/' + dateTo);
            },
            getOxxoData: function (itemId, priceListId, dateFrom, dateTo) {
                return httpService.get('/price/pricesurveys/GetOxxoPricesForEvolutionReport/' + itemId + '/' + priceListId + '/' + dateFrom + '/' + dateTo);
            },
            getUnitsData: function (itemId, priceListId, competitorId) {
                return httpService.get('/price/pricesurveys/GetUnitsForEvolutionReport/' + itemId + '/' + priceListId + '/' + competitorId);
            }
        }
        self.abTesting = {
            getCategories: function () {
                return httpService.get('/price/priceabtesting/categories');
            },
            getAbTestingGrid: function () {
                return httpService.get('/price/priceabtesting/ab-testing-grid');
            },
            itemsLookupByCategory: function (lookupParameter, categoryId) {
                return httpService.post('/price/rules/itemsLookup/' + categoryId, lookupParameter);
            },
            deleteTests: function (ids) {
                return httpService.post('/price/priceabtesting/delete-tests/', ids);
            },
            getStoresLookup: function (companyId) {
                return httpService.get('/price/priceabtesting/stores/' + companyId);
            },
            getDailySales: function (abTestingId) {
                return httpService.get('/price/priceabtesting/get-daily-sales/' + abTestingId);
            },
            getCurrentPrice: function (storeId, itemId) {
                return httpService.get('/price/priceabtesting/get-current-price/' + storeId + "/" + itemId);
            },
            saveAbTesting: function (abTesting) {
                return httpService.post('/price/priceabtesting/save-ab-testing', abTesting);
            },
        }

        self.rules = {
            getCategories: function (categoryMinLevel) {
                return httpService.get('/price/rules/category-rules/' + categoryMinLevel);
            },
            getExistingRules: function (categoryId) {
                return httpService.get('/price/rules/existing-rules/' + categoryId);
            },
            getItemRules: function (categoryId) {
                return httpService.get('/price/rules/item-rules/' + categoryId);
            },
            getParents: function (categoryId) {
                return httpService.get('/price/rules/parents/' + categoryId);
            },
            savePriceRuleCompetitive: function (priceRule) {
                return httpService.post('/price/rules/save-price-rule-competitive', priceRule);
            },
            savePriceRuleMargin: function (priceRule) {
                return httpService.post('/price/rules/save-price-rule-margin', priceRule);
            },
            savePriceRuleParent: function (priceRule) {
                return httpService.post('/price/rules/save-price-rule-parent', priceRule);
            },
            savePriceRuleZone: function (priceRule) {
                return httpService.post('/price/rules/save-price-rule-zone', priceRule);
            },
            saveCategoryRules: function (categories) {
                return httpService.post('/price/rules/save-category-rules', categories);
            },
            //getPriceRuleCategories: function (categoryId) {
            //    return httpService.get('/price/rules/getpricerulecategories/' + categoryId);
            //},
            getArticlesGroup: function (categoryId) {
                return httpService.get('/price/rules/getArticlesGroup/' + categoryId);
            },
            getPriceRuleTags: function (categoryId) {
                return httpService.get('/price/rules/getpriceruletags/' + categoryId);
            },
            getPriceRuleItems: function (categoryId) {
                return httpService.get('/price/rules/getpriceruleitems/' + categoryId);
            },
            getPriceRulePriceLists: function () {
                return httpService.get('/price/rules/getPricelists');
            },
            getPriceRuleVendors: function (categoryId) {
                return httpService.get('/price/rules/getVendors/' + categoryId);
            },
            getSubCategoriesById: function (categoryId) {
                return httpService.get('price/rules/getSubCategories/' + categoryId);
            },
            getItemSensitivities: function () {
                return httpService.get('price/rules/getItemSensitivities');
            },
            getParentUmvs: function (parentId) {
                return httpService.get('price/rules/getParentUmvs/' + parentId);
            },
            getPriceRulesForCategory: function (categoryId) {
                return httpService.get('price/rules/getpricerulesforCategory/' + categoryId)
            },
            getPriceRule: function (priceRuleId, priceRuleType) {
                return httpService.get('price/rules/getpricerule/' + priceRuleId + '/' + priceRuleType)
            },
            deletePriceRule: function (priceRuleId) {
                return httpService.get('price/rules/deletePriceRule/' + priceRuleId)
            },
            getGenericItem: function (parentId) {
                return httpService.post('/price/rules/getGenericItem/' + parentId);
            },
            validateRulesInBatchFile: function () {
                return httpService.post('/price/rules/validateRulesInBatchFile');
            },
            updateRulesInBatchFile: function () {
                return httpService.post('/price/rules/updateRulesInBatchFile');
            }
        }

        self.priceRoundings = {
            getPriceRoundings: function () {
                return httpService.get('/price/priceRounding/getPriceRoundings');
            },
            getPriceRoundingById: function (priceRoundingId) {
                return httpService.get('/price/priceRounding/getPriceRoundingById/' + priceRoundingId);
            },
            getPriceRoundingsByType: function (priceTypeId) {
                return httpService.get('/price/priceRounding/getPriceRoundingsByType/' + priceTypeId);
            },
            savePriceRounding: function (priceRounding) {
                return httpService.post('/price/priceRounding/savePriceRounding', priceRounding);
            },
            savePriceRoundings: function (priceRoundings) {
                return httpService.post('/price/priceRounding/savePriceRoundings', priceRoundings);
            },
            deletePriceRounding: function (priceRoundingId) {
                return httpService.get('/price/priceRounding/deletePriceRounding/' + priceRoundingId);
            },
            getRoundedNumber: function (priceType, numberToRound) {
                return httpService.get('/price/priceRounding/getRoundedValue/' + priceType + '/' + numberToRound);
            }
        }


        self.suggestion = {
            generateSuggestions: function (categoryId) {
                return httpService.post('/price/suggestion/generate-suggestions/' + categoryId);
            },
            generateCostSuggestionsForAll: function () {
                return httpService.post('/price/suggestion/generate-costsuggestions-for-all');
            },
            reviewCategory: function (categoryId, suggestionsType) {
                return httpService.post('/price/suggestion/review-category/' + categoryId + '/' + suggestionsType);
            }
        }

        self.itemSensitivities = {
            getItemSensitivities: function () {
                return httpService.get('/price/itemSensitivities/itemSensitivities');
            },
            getItemSensitivity: function (itemSensitivityId) {
                return httpService.get('/price/itemSensitivities/itemSensitivity/' + itemSensitivityId);
            },
            saveItemSensitivity: function (itemSensitivity) {
                return httpService.post('/price/itemSensitivities/save', itemSensitivity);
            }
        }

        self.lookups = {
            itemsLookupByCategory: function (lookupParameter, categoryId) {
                return httpService.post('/price/rules/itemsLookup/' + categoryId, lookupParameter);
            },
            itemLookupByMainItemId: function (mainItemId, mainItemUmvId) {
                return httpService.get('/price/rules/itemLookup/' + mainItemId + '/' + mainItemUmvId);
            }
        }

        self.getRoundingDecimalNames = function () {

            var deferred = $q.defer();

            $translate(['Rounding3', 'Rounding2', 'Rounding1', 'Rounding0', 'RoundingM1', 'RoundingM2', 'RoundingM3', 'RoundingM4', 'RoundingM5', 'RoundingM6']).then(function (translations) {

                var decimalNames = [{
                    decimals: 3,
                    name: translations.Rounding3
                },
                {
                    decimals: 2,
                    name: translations.Rounding2
                },
                {
                    decimals: 1,
                    name: translations.Rounding1
                },
                {
                    decimals: 0,
                    name: translations.Rounding0
                },
                {
                    decimals: -1,
                    name: translations.RoundingM1
                },
                {
                    decimals: -2,
                    name: translations.RoundingM2
                },
                {
                    decimals: -3,
                    name: translations.RoundingM3
                },
                {
                    decimals: -4,
                    name: translations.RoundingM4
                },
                {
                    decimals: -5,
                    name: translations.RoundingM5
                },
                {
                    decimals: -6,
                    name: translations.RoundingM6
                }
                ];

                deferred.resolve(decimalNames);

            }, function () {
                deferred.reject(rv);
            });

            return deferred.promise;
        }

        self.getPriceSurveyTypes = function () {

            var deferred = $q.defer();

            $translate(["Basket", "Promotion"]).then(function (translations) {

                var priceSurveyTypes = [{ "priceRuleId": 0, "priceSurveyType": 1, "priceSurveyTypeName": translations.Basket, "exclude": 0 },
                { "priceRuleId": 0, "priceSurveyType": 2, "priceSurveyTypeName": "Extra", "exclude": 0 },
                { "priceRuleId": 0, "priceSurveyType": 3, "priceSurveyTypeName": "PriceIndex", "exclude": 0 },
                { "priceRuleId": 0, "priceSurveyType": 4, "priceSurveyTypeName": translations.Promotion, "exclude": 0 },
                { "priceRuleId": 0, "priceSurveyType": 6, "priceSurveyTypeName": "Web", "exclude": 0 }];

                deferred.resolve(priceSurveyTypes);

            }, function () {
                deferred.reject(rv);
            });

            return deferred.promise;
        }

        self.getPriceTypes = function () {

            var deferred = $q.defer();

            $translate(["Regular", "Competitive", "All", "Current", "Exception", "Markdown", "Bonus", "Promotion"]).then(function (translations) {

                var priceTypes = [translations.All, translations.Current, translations.Exception, translations.Markdown, translations.Bonus, translations.Promotion, translations.Competitive, translations.Regular];

                deferred.resolve(priceTypes);

            }, function () {
                deferred.reject(rv);
            });

            return deferred.promise;
        }

        self.getPriceRoundingTypes = function () {

            var deferred = $q.defer();

            $translate(["RoundingStandard", "PricePoints", "Terminations"]).then(function (translations) {

                var priceRoundingTypes = [translations.RoundingStandard, translations.PricePoints, translations.Terminations];

                deferred.resolve(priceRoundingTypes);

            }, function () {
                deferred.reject(rv);
            });

            return deferred.promise;
        }

        self.reports = {
            getWeeklyReport: function (priceListId) {
                return httpService.get('/price/pricereport/weekly-report/' + priceListId);
            },
            getInformedPrices: function (dateFrom, dateTo) {
                return httpService.get('/price/pricereport/get-informed-prices/' + dateFrom + '/' + dateTo);
            },
            getInformedPricesExcel: function (dateFrom, dateTo) {
                return httpService.get('/price/pricereport/get-informed-prices-excel/' + dateFrom + '/' + dateTo);
            },
            getPricesDailyMargin: function () {
                return httpService.get('/price/pricereport/daily-margin');
            },
            getPricesDailyMarginExcel: function () {
                return httpService.get('/price/pricereport/daily-margin-excel');
            },
            getCompetitorsPricesExcel: function (dateFrom, dateTo) {
                return httpService.get('/price/pricereport/get-competitors-prices-excel/' + dateFrom + '/' + dateTo)
            },
            getCompetitiveIndex: function (params) {
                return httpService.post('/price/pricereport/get-competitive-index', params)
            },
            getVolumeMeasurement: function (compare, priceListId) {
                return httpService.get('/price/pricereport/getVolumeMeasurement/' + compare + '/' + priceListId );
            },
            getVolumeMeasurementCompetitors: function (compare, priceListId,brands) {
                return httpService.get('/price/pricereport/getVolumeMeasurementCompetitors/' + compare + '/' + priceListId + '/' + brands);
            },
            getCompetitivePositionReport: function (filters) {
                return httpService.post('/price/pricereport/getCompetitivePositionReport', filters);
            },
            getCompetitivePositionByPriceList: function (filters) {
                return httpService.post('/price/pricereport/getCompetitivePositionByPriceList', filters);
            },
            getCompetitiveReport: function (selectedMarketId, selectedPriceListId, selectedItemId, selectedClculationType, validFrom, validTo) {
                return httpService.get('/price/pricereport/getCompetitiveReport/' + selectedMarketId + '/' + selectedPriceListId + '/' + selectedItemId + '/' + selectedClculationType + '/' + validFrom + '/' + validTo);
            }
        }

        self.priceOutput = {
            getStorePricesToInform: function () {
                return httpService.get('/price/priceoutput/getStorePricesToInform');
            },
            informToErp: function (filters) {
                return httpService.post('/price/priceoutput/informToErp', filters);
            },
            informToOxxoErp: function (pricesToInform) {
                return httpService.post('/price/priceoutput/informToOxxoErp', pricesToInform);
            },
            importPrices: function () {
                return httpService.get('/price/priceoutput/importPrices');
            },
            downloadLastOutput: function () {
                return httpService.get('/price/priceoutput/downloadLastOutput');
            },
            getLastOutputReports: function () {
                return httpService.get('/price/priceoutput/getLastOutputReports');
            },
            getPriceOutputLimits: function (isReport, fromDate, toDate) {
                return httpService.get('/price/priceoutput/getPriceOutputLimits/' + isReport + '/' + fromDate + '/' + toDate);
            },
            getStoresFilter: function () {
                return httpService.get('/price/priceoutput/getStoresFilter');
            },
            getCategoriesFilter: function () {
                return httpService.get('/price/priceoutput/getCategoriesFilter');
            },
            getReasonsFilter: function (priceType) {
                return httpService.get('/price/priceoutput/getReasonsFilter/' + priceType);
            },
            //getStorePricesToInformFiltered: function (filters) {
            //    return httpService.post('/price/priceoutput/getStorePricesToInformFiltered', filters);
            //},
        }
    });