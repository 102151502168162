
angular.module("prisma")
    .controller("CategorySummaryCtrl", function ($state, $rootScope, priceService, companyJson, $translate) {
        var self = this;

        var numberColWidth = 120;

        var columnDefs = null;
        self.categoryLevel = 3;

        function setColumnDefinitions() {
            columnDefs = [
                {
                    headerName: translations.Category, //'Categoria',
                    width: numberColWidth + 250,
                    pinned: 'left',
                    field: 'name',
                    suppressMenu: true,
                    suppressSorting: true,
                    filter: false,
                    cellRenderer: {
                        renderer: 'group',
                        checkbox: false,
                        innerRenderer: function (params) {

                            var name = params.value + (params.node.group && params.node.children != null ? ' (' + params.node.children.length + ')' : '');

                            if (params.data.categoryId != null && params.data.categoryId != 0) {

                                var categoryGroup = "'" + params.data.categoryGroup + "'";
                                var category = "'" + params.data.category + "'";
                                var subcategory = "'" + params.data.subcategory + "'";

                                var anchor = '<a style="padding: 5px;" title="Click para ingresar a la categoria" ng-click="ct.goToDecisions(' + params.data.id + ',' + categoryGroup + ',' + category + ',' + subcategory + ',' + false + ')">' + name + '</a>';

                                var endorseLink = '';
                                if (params.data.new > 0 || params.data.newCompetitive > 0)
                                    endorseLink = '<a style="margin-left:4px;" title="Ir a endoso" ng-click="ct.goToDecisions(' + params.data.id + ',' + categoryGroup + ',' + category + ',' + subcategory + ',' + true + ')"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>';

                                return endorseLink + anchor;
                            }
                            else
                                return name;
                        }
                    }
                },
                {
                    headerName: translations.RegularSuggestions, //'Regular Suggestions', // ,
                    children: [
                        {
                            headerName: translations.Suggestions, //'Suggestions', 
                            field: "suggestions",
                            headerTooltip: translations.SuggestionsQuantity, //'Suggestions Quantity',
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell

                        },
                        {
                            headerName: translations.Impact, //'Impact',
                            field: "impact",
                            headerTooltip: translations.ShowsImpactInPesos, //'Shows the impact in margin of the suggestions',
                            width: numberColWidth,
                            filter: 'number',
                            cellRenderer: $rootScope.moneyRendererNoDecimals,
                            cellClass: $rootScope.getClassForImpactCell
                        },
                        /*
                        {
                            headerName: "Costos",
                            field: "costs",
                            headerTooltip: "Cantidad de cambios de precio generados por un cambio en los costos.",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: "Mercado",
                            field: "market",
                            headerTooltip: "Cantidad de cambios de precio generados por un cambio en el mercado.",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell
      
                        },
                        */
                    ]
                },
                {
                    headerName: translations.CompetitiveSuggestions,//'Competitive Suggestions', 
                    children: [
                        {
                            headerName: translations.Suggestions, //'Suggestions', 
                            field: "competitiveSuggestions",
                            headerTooltip: translations.NumberCompetitiveSuggestions, //'Suggestions Quantity', 
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell

                        },
                        {
                            headerName: translations.Impact, //"Impacto",
                            field: "competitiveImpact",
                            headerTooltip: translations.ShowsImpactInPesos, //'Shows the impact in margin of the suggestions',
                            width: numberColWidth,
                            filter: 'number',
                            cellRenderer: $rootScope.moneyRendererNoDecimals,
                            cellClass: $rootScope.getClassForImpactCell
                        }]
                },
                {
                    headerName: translations.NewRegular, //'New Regular',
                    children: [
                        {
                            headerName: translations.New,//'New',
                            field: "new",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: translations.Impact,//'Impact',
                            field: "newImpact",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForImpactCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: translations.BigDrop,//'Big Drop', 
                            field: "newPriceMinVariationViolation",
                            headerTooltip: translations.BigDropToolTip, //'New prices with big prices drops', 
                            width: numberColWidth,
                            filter: 'number',
                            columnGroupShow: 'open',
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: translations.BigIncrease, //'Big Increase', 
                            field: "newPriceMaxVariationViolation",
                            headerTooltip: translations.BigIncreaseToolTip, //'New prices with big increases',
                            width: numberColWidth,
                            filter: 'number',
                            columnGroupShow: 'open',
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: translations.MarginMin, //'< Min Margin',
                            field: "newPriceMinMarginViolation",
                            headerTooltip: translations.MarginMintTooltip, //"Precios nuevos que estan por debajo del margen minimo para esta categoria.",
                            width: numberColWidth,
                            filter: 'number',
                            columnGroupShow: 'open',
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: translations.MarginMax, //"> Margen Max",
                            field: "newPriceMaxMarginViolation",
                            headerTooltip: translations.MarginMaxTooltip, 
                            width: numberColWidth,
                            filter: 'number',
                            columnGroupShow: 'open',
                            cellClass: $rootScope.getClassForCell
                        }]
                },
                {
                    headerName: translations.NewCompetitive, //'New Competitive',
                    children: [
                        {
                            headerName: translations.New,//'New',
                            field: "newCompetitive",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: translations.Impact,//'Impact', 
                            field: "newCompetitiveImpact",
                            width: numberColWidth,
                            filter: 'number',
                            cellClass: $rootScope.getClassForImpactCell,
                            cellRenderer: $rootScope.moneyRendererNoDecimals
                        },
                        {
                            headerName: translations.BigDrop, //'Big Drop',
                            field: "newCompetitivePriceMinVariationViolation",
                            headerTooltip: translations.BigDropToolTip, 
                            width: numberColWidth,
                            filter: 'number',
                            columnGroupShow: 'open',
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: translations.BigIncrease, //'Big Increase',
                            field: "newCompetitivePriceMaxVariationViolation",
                            headerTooltip: translations.BigIncreaseToolTip, //".",
                            width: numberColWidth,
                            filter: 'number',
                            columnGroupShow: 'open',
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: translations.MarginMin, //'< Min Margin',
                            field: "newCompetitivePriceMinMarginViolation",
                            headerTooltip: translations.MarginMintTooltip, //".",
                            width: numberColWidth,
                            filter: 'number',
                            columnGroupShow: 'open',
                            cellClass: $rootScope.getClassForCell
                        },
                        {
                            headerName: translations.MarginMax, //'> Max Margin',
                            field: "newCompetitivePriceMaxMarginViolation",
                            headerTooltip: translations.MarginMaxTooltip, 
                            width: numberColWidth,
                            filter: 'number',
                            columnGroupShow: 'open',
                            cellClass: $rootScope.getClassForCell
                        }]
                }
            ];
        }

        self.gridOptions = {
            columnDefs: columnDefs,
            enableColResize: true,
            enableSorting: true,
            enableFilter: false,
            groupHeaders: false,
            suppressCellSelection: true,
            rowHeight: 35,
            groupHideGroupColumns: true,
            icons: {
                columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                filter: '<i class="fa fa-filter"/>',
                sortAscending: '<i class="fa fa-long-arrow-down"/>',
                sortDescending: '<i class="fa fa-long-arrow-up"/>',
                groupExpanded: '<i class="fa fa-minus-square-o"/>',
                groupContracted: '<i class="fa fa-plus-square-o"/>',
                columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
            },
            angularCompileRows: true,
            getNodeChildDetails: getNodeChildDetails
        }

        self.exportToExcel = function () {
            var params = {
                skipHeader: false,
                skipFooters: false,
                skipGroups: false,
                allColumns: true,
                onlySelected: false,
                suppressQuotes: false,
                fileName: 'ResumenPrecios.xls',
                columnSeparator: ','
            };

            self.gridOptions.api.exportDataAsCsv(params);
        }

        function getNodeChildDetails(rowItem) {
            if (rowItem.subcategories && rowItem.subcategories.length > 0) {
                return {
                    group: true,
                    expanded: false,
                    // provide ag-Grid with the children of this group
                    children: rowItem.subcategories,
                    // the key is used by the default group cellRenderer
                    key: rowItem.id
                };
            }
            else {
                return null;
            }
        }

        self.goToDecisions = function (id, categoryGroup, category, subcategory, isEndorse) {

            categoryGroup = categoryGroup.replace(/ /g, "-").replace(/\//g, "_");
            category = category.replace(/ /g, "-").replace(/\//g, "_");
            subcategory = subcategory.replace(/ /g, "-").replace(/\//g, "_");

            var encodedcategoryGroup = categoryGroup != 'null' ? encodeURIComponent(categoryGroup) : '';
            var encodedcategory = category != 'null' ? encodeURIComponent(category) : '';
            var encodedsubcategory = subcategory != 'null' ? encodeURIComponent(subcategory) : '';

            $state.go('prices.decisions', { categoryId: id, categoryGroup: encodedcategoryGroup, category: encodedcategory, subcategory: encodedsubcategory, isEndorse: isEndorse });
        }

        function setGridOptions() {

            

            self.gridOptions = {
                columnDefs: columnDefs,
                enableColResize: true,
                enableSorting: true,
                enableFilter: false,
                groupHeaders: false,
                suppressCellSelection: true,
                rowHeight: 35,
                groupHideGroupColumns: true,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                angularCompileRows: true,
                getNodeChildDetails: getNodeChildDetails
            }
        }

        //Filtro Buscador
        self.summary = null;

        self.copiedSummary = null;
        self.onSearchKeyUp = function () {
            //console.log('self.search', self.search);
            if (!self.search) {
                if (self.copiedSummary)
                    copyValuesFromFilteredToComplete(self.copiedSummary, self.summary);
                self.gridOptions.api.setRowData(self.summary);
            } else {
                self.copiedSummary = angular.copy(self.summary);
                filterSummary(self.summary, self.copiedSummary, self.search.toLowerCase());
                self.gridOptions.api.setRowData(self.copiedSummary);

            }
        }

        function copyValuesFromFilteredToComplete(filtered, completed) {
            if (filtered.length == 0 || completed.length == 0) return;
            for (var j = 0; j < completed.length; j++) {
                for (var i = 0; i < filtered.length; i++) {
                    if (completed[j].id == filtered[i].id) {
                        console.log('copying', filtered[i].name);
                        copyValuesFromFilteredToComplete(filtered[i].subcategories, completed[j].subcategories);
                    }
                }
            }
        }

        //source y dest tienen que ser copias uno del otro
        function filterSummary(source, dest, search) {
            if (!source || source.length == 0) return false;
            var idsToRemove = [];
            var anyOfThisAreValid = false;
            for (var i = 0; i < source.length; i++) {
                var anyChildrenValid = false;

                anyChildrenValid |= filterSummary(source[i].subcategories, dest[i].subcategories, search);

                if (!anyChildrenValid && source[i].name.toLowerCase().indexOf(search) == -1) {

                    idsToRemove.push(source[i].id);
                } else if (source[i].name.toLowerCase().indexOf(search) != -1) {
                    anyOfThisAreValid = true;
                } else if (anyChildrenValid) {
                    anyOfThisAreValid = true;
                }
            }

            for (var i = 0; i < idsToRemove.length; i++) {
                for (var j = 0; j < dest.length; j++) {
                    if (dest[j].id == idsToRemove[i]) {
                        dest.splice(j, 1);
                        break;
                    }
                }
            }

            return anyOfThisAreValid;
        }

        function loadData() {
            self.categoryLevel = companyJson.Category.Levels;

            priceService.decisions.getCategorySummary(self.categoryLevel)
                .then(function (items) {
                    self.summary = items;
                    self.gridOptions.api.setRowData(items);
                    self.gridOptions.api.sizeColumnsToFit();
                });
        }

        var translations = null;

        function init() {
            
            //init columnDefs and gridOptions
            setGridOptions();

            $translate(["Category", "RegularSuggestions", "Suggestions", "SuggestionsQuantity", "Impact", "ShowsImpactInPesos", "CompetitiveSuggestions", "NumberCompetitiveSuggestions", "Impact", "NewRegular", "New", "BigDrop", "BigDropToolTip", "BigIncrease", "BigIncreaseToolTip", "MarginMin", "MarginMintTooltip", "MarginMax", "MarginMaxTooltip", "NewCompetitive"])
                .then(function (all) {
                    translations = all;
                    setColumnDefinitions();
                    self.gridOptions.api.setColumnDefs(columnDefs);
                });

            //load data
            loadData();
        }

        init();

    });

