"use strict";

angular.module("prisma")

    .controller("CategoryTreeMapCtrl", function ($scope, $rootScope, $translate, homeService) {
        var self = this;

        self.selectedCategoryGroup = null;
        self.allCategoryGroups = null;

        var w = $("#categoryTreeMapDiv").parent().width() - 50,
            h = 800,
            x = d3.scale.linear().range([0, w]),
            y = d3.scale.linear().range([0, h]),
            color = d3.scale.category20b(),
            root,
            node;

        var treemap = d3.layout.treemap()
            .round(false)
            .size([w, h])
            .sticky(false)
            .value(function (d) { return d.sales; });

        var svg = d3.select("#categoryTreeMapDiv").append("div")
            .attr("class", "chart")
            .style("width", w + "px")
            .style("height", h + "px")
          .append("svg:svg")
            .attr("width", w)
            .attr("height", h)
          .append("svg:g")
            .attr("transform", "translate(.5,.5)");

        function zoom(d) {
            var kx = w / d.dx,
                ky = h / d.dy;
            x.domain([d.x, d.x + d.dx]);
            y.domain([d.y, d.y + d.dy]);

            var t = svg.selectAll("g.cell").transition()
                .duration(d3.event && d3.event.altKey ? 4000 : 750)
                .attr("transform", function (d) {
                    return "translate(" + x(d.x) + "," + y(d.y) + ")";
                });

            t.select("rect")
                .attr("width", function (d) {
                    var val = kx * d.dx - 1;
                    if (val < 0)
                        return 0;

                    return val;
                })
                .attr("height", function (d) {
                    var val = ky * d.dy - 1;
                    if (val < 0)
                        return 0;

                    return val;
                })

            t.select("text")
                .attr("x", function (d) {
                    return kx * d.dx / 2;
                })
                .attr("y", function (d) {
                    return ky * d.dy / 2;
                })
                .style("opacity", function (d) {
                    return kx * d.dx > d.w ? 1 : 0;
                });

            node = d;
            d3.event.stopPropagation();
        }

        function sales(d) {
            return d.sales;
        }

        function units(d) {
            return d.units;
        }

        function margin(d) {
            return d.margin;
        }

        function marginP(d) {
            return d.marginP;
        }

        function price(d) {
            return d.price;
        }

        function inventory(d) {
            return d.inventory;
        }

        self.legend = [];

        function loadData() {

            homeService.categories.getCategoryGroups().then(function (cg) {
                self.allCategoryGroups = cg;
            });
        }

        self.start = function () {

            d3.select("#categoryTreeMapDiv div").remove();

            svg = d3.select("#categoryTreeMapDiv").append("div")
                .attr("class", "chart")
                .style("width", w + "px")
                .style("height", h + "px")
              .append("svg:svg")
                .attr("width", w)
                .attr("height", h)
              .append("svg:g")
                .attr("transform", "translate(.5,.5)");

            self.legend = [];

            homeService.categoryTreeMap.getCategoryTreeMap(self.selectedCategoryGroup)

                .then(function (data) {
                    node = root = data;

                    var nodes = treemap.nodes(root)
                        .filter(function (d) { return !d.children; });

                    var cell = svg.selectAll("g")
                        .data(nodes)
                      .enter().append("svg:g")
                        .attr("class", "cell")
                        .attr("transform", function (d) {
                            return "translate(" + d.x + "," + d.y + ")";
                        })
                        .on("click", function (d) {
                            return zoom(node == d.parent ? root : d.parent);
                        });

                    cell.append("svg:rect")
                        .attr("width", function (d) {
                            var val = d.dx - 1;
                            if (val < 0)
                                return 0;

                            return val;
                        })
                        .attr("height", function (d) {
                            var val = d.dy - 1;
                            if (val < 0)
                                return 0;

                            return val;
                        })
                        .style("fill", function (d) {
                            var c = color(d.parent.name);

                            var colors = [];

                            var exists = false;
                            angular.forEach(self.legend, function (l) {
                                if (l.name === d.parent.name) exists = true;
                            });

                            if (!exists) {
                                self.legend.push({
                                    name: d.parent.name,
                                    color: c,
                                    node: d.parent
                                });
                            }

                            return c;
                        });
                        //.style("fill", function (d) { return color(d.parent.name); });

                    cell.append("svg:text")
                        .attr("x", function (d) { return d.dx / 2; })
                        .attr("y", function (d) { return d.dy / 2; })
                        .attr("dy", ".35em")
                        .attr("text-anchor", "middle")
                        .text(function (d) { return d.name; })
                        .style("opacity", function (d) { d.w = this.getComputedTextLength(); return d.dx > d.w ? 1 : 0; });

                    d3.select(window).on("click", function () { zoom(root); });

                    d3.select("select#variable").on("change", function () {
                        treemap.value(this.value == "sales" ? sales : this.value == "units" ? units : this.value == "margin" ? margin : this.value == "marginP" ? marginP : this.value == "price" ? price : inventory).nodes(root);
                        zoom(node);
                    });
                });
        }

        function init() {
            //load data
            loadData();
        }

        init();
    });

