angular.module("prisma")
    .controller("PricelistsSubsidiesCtrl", function ($scope, $state, $stateParams, priceService, $timeout, $translate) {

        //Variable globales

        var self = this;

        self.pricelistsSubsidies = [];

        self.decimalFormat = function (value) {
            if (value) {
                return value.toFixed(2);
            }
        }

        function loadPriceListsSubsidies() {
            priceService.pricelistsSubsidies.getAllPricelistsSubsidies()
                .then(function (pricelistsSubsidies) {
                    self.pricelistsSubsidies = pricelistsSubsidies;
                });
        }

        //Funciones

        self.generateSuggestions = function () {
            swal({
                title: translations.GenerateSuggestionsSwal,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        priceService.costs.saveAndGenerateSuggestions()
                            .then(function (isOk) {
                                swal(translations.SuggestionsGenerated, translations.SuggestionsGeneratedSubtitle, "success");
                            }, function (status) {
                                if (status == 502) {
                                    swal(translations.TheProcessContinues, translations.TheSuggestionsContinueToBeGeneratedSwal)
                                }
                                else {
                                    swal(translations.ErrorTitleSwal, translations.ErrorWhenGeneratingSuggestionsForThisCategorySwal, "error");
                                }
                            });
                    }
                });
        }


        self.disablePriceListSubsidy = function (id, excluded) {
            var excludedText = excluded ? translations.EnableTooltip : translations.ToDisable;
            swal({
                title: translations.AreYouSureSwalTitle + excludedText + translations.TheSubsidy,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {

                    if (isConfirm) {
                        priceService.pricelistsSubsidies.deletePricelistSubsidy(id).then(function () {
                            swal("", translations.UpdatedSubsidies, "success");
                            loadPriceListsSubsidies();
                        }, function (status) {
                            if (status == 502) {
                                swal(translations.TheProcessContinues);
                            }
                            else {
                                swal(translations.ErrorTitleSwal, translations.ErrorDisablingSubsidy, "error");
                            }
                        });
                    }
                });
        }

        self.editPricelistSubsidies = function (id) {
            $state.go('prices.editPricelistSubsidies', { priceListSubsidyId: id });
        }

        self.addNew = function () {
            $state.go('prices.editPricelistSubsidy', { priceListSubsidyId: 0 });
        }

        var translations = null;

        function init() {

            $translate(["GenerateSuggestionsSwal", "ContinueSwal", "CancelSwal", "SuggestionsGenerated", "SuggestionsGeneratedSubtitle"
                , "TheProcessContinues", "TheSuggestionsContinueToBeGeneratedSwal", "ErrorTitleSwal", "ErrorWhenGeneratingSuggestionsForThisCategorySwal"
                , "AreYouSureSwalTitle", "TheSubsidy", "UpdatedSubsidies", "ErrorDisablingSubsidy", "EnableTooltip", "ToDisable"])
                .then(function (all) {
                    translations = all;
                });

            loadPriceListsSubsidies();
        }

        if ($stateParams.reload) {
            loadPriceListsSubsidies();
        }

        init();

    });