var _controller = function () {
    var self = this;
    console.log('self', self);

    function validar() {
        for (var i = 0; i < self.resolve.params.categories.length; i++) {
            if (!self.resolve.params.categories[i].priceListId) {
                return false;
            }
        }
        return true;
    }

    self.confirm = function () {

        if (!validar()) {
            //console.log('invalido',self.level);

            swal('Datos incompletos', 'Debe asignar una zona de precios a cada categoria', 'error');

            return;
        }
        self.close({ $value: self.resolve.params.categories });
    }

    self.cancel = function () {
        self.dismiss({ $value: 'cancel' });
    }

}

angular.module('prisma').component('choosePriceListsForCategories', {
    templateUrl: 'app/shared_logic/components/choosePriceListsForCategories/choosePriceListsForCategories.component.html',
    bindings: {
        //categories: "=",
        //priceLists: "=",
        resolve: "=",
        close: '&',
        dismiss: '&'
    },

    controller: _controller
});


