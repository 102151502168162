"use strict";

angular.module("prisma")

    .directive('script', function () {
        return {
            restrict: 'E',
            scope: false,
            link: function (scope, elem, attr) {
                if (attr.dataLazyLoad) {
                    var code = elem.text();
                    var f = new Function(code);
                    f();
                }
            }
        };
    });

