angular.module("prisma")
 .controller("layoutsCtrl", function ($scope, promotionsService) {
     var self = this;

     self.layouts = [];

     self.isLoading = true;

     self.delete = function (layoutId, layoutName) {

         swal({
             title: 'Esta seguro que desea borrar el layout ' + layoutName + ' ?',
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: "Continuar",
             cancelButtonText: "Cancelar",
             showLoaderOnConfirm: true,
             closeOnConfirm: false,
             closeOnCancel: true
         },
      function (isConfirm) {
          if (isConfirm) {

              
              promotionsService.layouts.deleteLayout(layoutId)
               .then(function (isOk) {
                   swal('Layout Borrado!', 'El layout ' + layoutName + ' se ha borrado exitosamente', 'success');
                   loadData();
               }, function (status) {
                   if (status == 502) {
                       swal("El proceso continua...", "El proceso de borrado continua. Puede revisar el estado en unos minutos.")
                   }
                   else {
                       swal("Error", "Hubo un error al borrar este layout", "error");
                   }
               });
          }
      });
     }

     function loadData() {
         promotionsService.layouts.getLayouts(false, true)
            .then(function (layouts) {
                self.layouts = layouts;

                self.isLoading = false;
            });
     }

     function init() {
         loadData();
     }

     init();
 });