angular.module("prisma")
 .controller("editPromotionalCampaignCtrl", function ($scope, $state, $stateParams, promotionsService) {
     var self = this;

     self.campaign = null;
     var allCampaigns = null;

     if ($stateParams.campaignId != '0') {
         promotionsService.campaigns.getCampaign($stateParams.campaignId)
            .then(function (campaign) {
                campaign.validFrom = new Date(campaign.validFrom);
                campaign.validTo = new Date(campaign.validTo);
                self.campaign = campaign;
            });
     }

     self.save = function () {
         if (self.campaign != null) {
             let today = new Date(new Date().setHours(0, 0, 0, 0));
             if (self.campaign.validFrom < today) {
                 swal("Error", "La fecha de inicio debe ser igual o posterior a la fecha actual.", "error");
             }
             else {
                 if (self.campaign.validFrom < self.campaign.validTo) {
                     if (canSaveThisName(self.campaign)) {
                         swal({
                             title: 'Esta seguro que desea guardar la campaña?',
                             type: "warning",
                             showCancelButton: true,
                             confirmButtonColor: "#1AB394",
                             confirmButtonText: "Continuar",
                             cancelButtonText: "Cancelar",
                             showLoaderOnConfirm: true,
                             closeOnConfirm: false,
                             closeOnCancel: true
                         },
                  function (isConfirm) {
                      if (isConfirm) {


                          promotionsService.campaigns.saveCampaign(self.campaign)
                           .then(function (campai) {
                               swal('Campaña Guardada!', 'La campaña ' + self.campaign.name + ' se ha guardado exitosamente', 'success');

                               $state.go('promotions.campaigns');

                           }, function (status) {
                               if (status == 502) {
                                   swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                               }
                               else {
                                   swal("Error", "Hubo un error al guardar esta campaña", "error");
                               }
                           });
                      }
                  });
                     }
                     else {
                         swal("Error", "Ya existe ese nombre de campaña.", "error");
                     }
                 }
                 else {
                     swal("Error", "La fecha de inicio debe ser anterior a la de fin.", "error");
                 }
             }
         }
     }

     canSaveThisName = function (campaign) {
         var canSave = true;

         angular.forEach(allCampaigns, function (c) {
             if ((campaign.id == 0 || campaign.id == undefined) && c.name.toLowerCase() == campaign.name.toLowerCase())
                 canSave = false;
         });

         return canSave;
     }

     loadCampaigns = function () {
         promotionsService.campaigns.getCampaigns()
            .then(function (campaigns) {
                allCampaigns = campaigns;
            });
     }

     loadCampaigns();

 });