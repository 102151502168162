angular.module("prisma")
  .controller("PriceMapCtrl", function ($state, $stateParams, $scope, $rootScope, $filter, priceService, homeService) {
      var self = this;

      var priceMap = null;
      var country = '';

      self.priceSurveys = [];
      self.allPriceList = [{}];
      self.selectedPriceList = null;
      self.selectedPriceListName = '';
      self.itemName = "";
      self.itemCode = "";

      var markers = [];
      var markersLayer = new L.FeatureGroup();

      self.navigateToCompetitor = function (id) {
          for (var m in markers) {
              var marker = markers[m];
              if (marker && marker.options && id == marker.options.id) {

                  priceMap.setView(marker._latlng, 14);
                  marker.openPopup();
              };
          }
      }

      self.FilterByPriceList = function (priceList, reload) {
          self.selectedPriceList = priceList;

          if (priceList != null)
              self.selectedPriceListName = priceList.name;
          else
              self.selectedPriceListName = '';

          if (reload)
              loadData(priceList != null ? priceList.id : null);
      }

      function loadData(priceListId) {

          priceService.pricesurveys.onlyActive($stateParams.itemId, priceListId, true)
                  .then(function (priceSurveys) {

                      //Borro el Layer de los Markers
                      priceMap.removeLayer(markersLayer);

                      self.priceSurveys = priceSurveys;

                      if (self.priceSurveys.length > 0) {
                          self.itemName = self.priceSurveys[0].itemName;
                          self.itemCode = self.priceSurveys[0].itemCode;
                      }

                      priceSurveys.forEach(function (priceSurvey) {

                          if (priceSurvey.competitorLat != null && priceSurvey.competitorLong != null) {
                              var competitorIcon = L.icon({
                                  iconUrl: 'assets/images/competitors/' + priceSurvey.competitorLogo,

                                  iconSize: [24, 24], // size of the icon
                                  iconAnchor: [0, 0], // point of the icon which will correspond to marker's location
                                  popupAnchor: [12, 6] // point from which the popup should open relative to the iconAnchor
                              });

                              var marker = L.marker([priceSurvey.competitorLat, priceSurvey.competitorLong], { id: priceSurvey.id, icon: competitorIcon }).addTo(priceMap)
                                  .bindPopup('<b>' + priceSurvey.competitorCode + ' | ' + priceSurvey.competitor + '</b><br>' + $filter('currency')(priceSurvey.price) + '<br>' + $filter('date')(priceSurvey.observedDate, 'MMM d, y'));

                              markersLayer.addLayer(marker);

                              markers.push(marker);
                          }
                      });

                      priceMap.addLayer(markersLayer);
                  });
      }

      function loadPriceLists()
      {
          priceService.pricelists.getPriceLists()
        .then(function (priceLists) {
            self.priceLists = priceLists;

            //var priceListQuevieneDelarametro = $stateParams.priceListId;

            for (i = 0 ; i < self.priceLists.length ; i++) {
                if (self.priceLists[i].id == $stateParams.priceListId) {
                    self.FilterByPriceList(self.priceLists[i], false);
                }
            }

            loadData($stateParams.priceListId);
        });
      }

      function init() {

          homeService.dashboards.getCountry()
          .then(function (c) {
              country = c;

              if (country == "Peru") {
                  priceMap = L.map('priceMapid').setView([-8.8822898, -73.7699384], 5); //Per�
                  self.moneySymbol = '$';
              }
              if (country == "Colombia") {
                  priceMap = L.map('priceMapid').setView([1.8822898, -73.7699384], 5); //Colombia
                  self.moneySymbol = '$';
              }
              else if (country == "Argentina") {
                  priceMap = L.map('priceMapid').setView([-38.005, -61.0739], 4); //Argentina
                  self.moneySymbol = '$';
              }
              else if (country == "Mexico") {
                  priceMap = L.map('priceMapid').setView([21.695501, -101.666045], 5); //Mexico
                  self.moneySymbol = '$';
              }

              L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                  subdomains: ['a', 'b', 'c']
              }).addTo(priceMap);


              loadPriceLists();
          });
      }

      init();
  });


