angular.module("prisma")
 .controller("priceOutputWSCtrl", function ($scope, priceService, $rootScope, $window, $timeout) {
     var self = this;
     var allSelected = true;

     self.isOpen = false;
     var date = new Date();
     date.setDate(date.getDate() + 1);
     date.setHours(0)
     date.setMinutes(0);
     self.useCustomDate = false;
     self.dateToInform = date;
     self.pricesToInform = [];
     self.distinctPriceTypes = ["Todos", "Regular", "Competition", "Promotion", "Bonus"]

     self.openCalendar = function (e) {
         //e.preventDefault();
         //e.stopPropagation();

         //console.log('self.dateToInform', self.dateToInform);


         self.isOpen = !self.isOpen;
     };

     self.toggleSelection = function () {

         if (self.gridOptions.api.isAnyFilterPresent()) {
             //selecciono solo los filtrados
             self.gridOptions.api.forEachNodeAfterFilter(function (node) {
                 node.setSelected(!allSelected);
             });
         }
         else {
             //selecciono todos
             allSelected ? self.gridOptions.api.deselectAll() : self.gridOptions.api.selectAll();
         }

         //toggle select / deselect
         allSelected = !allSelected;
     }

     self.importPrices = function () {

         swal({
             title: 'Esta seguro que desea importar precios manualmente para todas las estaciones?',
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: "Continuar",
             cancelButtonText: "Cancelar",
             showLoaderOnConfirm: true,
             closeOnConfirm: false,
             closeOnCancel: true
         },
          function (isConfirm) {
              if (isConfirm) {

                  //seteo la propiedad isSelected segun los items seleccionados en la grilla
                  self.gridOptions.api.forEachNode(function (node) {
                      node.data.isSelected = node.isSelected();
                  });

                  //envio todo el paquete con el isSelected
                  priceService.priceOutput.importPrices().then(function (result) {

                      swal('Precios importados!', 'Se han importados precios para todas las estaciones desde el ERP', 'success');

                  }, function (status) {
                      if (status == 502) {
                          swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                      }
                      else {
                          swal("Error", "Hubo un error al querer Informar estos precios al ERP", "error");
                      }
                  });
              }
          });
     }

     self.informToErp = function () {

         swal({
             title: 'Esta seguro que desea Informar los precios seleccionados al ERP?',
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: "Continuar",
             cancelButtonText: "Cancelar",
             showLoaderOnConfirm: true,
             closeOnConfirm: false,
             closeOnCancel: true
         },
          function (isConfirm) {
              if (isConfirm) {

                  if (self.useCustomDate) {
                      console.log('usecustomDate', self.dateToInform.toISOString());
                      angular.forEach(self.pricesToInform, function (price) {
                          price.customValidFrom = self.dateToInform.toISOString();
                      });
                  }

                  //seteo la propiedad isSelected segun los items seleccionados en la grilla
                  self.gridOptions.api.forEachNode(function (node) {
                      node.data.isSelected = node.isSelected();
                  });

                  //envio todo el paquete con el isSelected
                  priceService.priceOutput.informToOxxoErp(self.pricesToInform).then(function (result) {
                      //if (reportUrl) {
                      //    $window.open(reportUrl);
                      //}

                      console.log('result', result.storePricesToInform);
                      var aux = [];
                      angular.forEach(result.storePricesToInform, function (s) {
                          if (s.itemName != '' && s.itemName != undefined)
                              aux.push(s);
                      });

                      $timeout(function () {
                          self.pricesToInform = aux;
                          self.gridOptions.api.setRowData(aux);

                      })


                      //for (var i = 0; i < self.pricesToInform.length; i++) {
                      //    for (var j = 0; j < result.length; j++) {

                      //    }

                      //}

                      swal('Reporte de Precios Generado!', 'Se genero el reporte de precios exitosamente para informar los cambios al ERP', 'success');

                      //Vuelvo a cargar la info
                      //loadData();

                  }, function (status) {
                      if (status == 502) {
                          swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                      }
                      else {
                          console.log(status);
                          swal("Error", "Hubo un error al querer Informar estos precios al ERP", "error");
                      }
                  });
              }
          });
     }

     self.filterByPriceType = function (type) {
         if (type == 'Todos')
             self.selectedFilterForPriceType = null;
         else if (self.selectedFilterForPriceType != type) {
             self.selectedFilterForPriceType = type;
         }

         self.gridOptions.api.onFilterChanged();
     }

     self.downloadLastReport = function () {
         priceService.priceOutput.downloadLastOutput().then(function (reportUrl) {
             if (reportUrl) {
                 $window.open(reportUrl);
             }
         });
     }

     self.downloadFile = function (url) {
         $window.open(url);
     }


     //grid config

     function setColumnDefinitions() {
         var colWidth = 50;

         columnDefs = [

             {
                 headerName: "Estacion",
                 field: "storeCode",
                 checkboxSelection: false,
                 headerCheckboxSelection: true,
                 headerCheckboxSelectionFilteredOnly: true,
                 width: colWidth + 40,
                 cellClass: $rootScope.getClassForCel
             },
             {
                 headerName: "Desc. Estacion",
                 field: "storeName",
                 //pinned: 'left',
                 width: colWidth + 200,
                 cellClass: $rootScope.getClassForCell
             },
             {
                 headerName: "Zona",
                 field: "priceListName",
                 width: 100,
                 filter: 'text',
                 cellClass: $rootScope.getClassForCell,
             },
             {
                 headerName: "Codigo",
                 field: "itemCode",
                 width: colWidth + 50,
                 cellClass: $rootScope.getClassForCell
             },
             {
                 headerName: "Descripcion",
                 field: "itemName",
                 width: colWidth + 200,
                 cellClass: $rootScope.getClassForCell
             },
             {
                 headerName: "UM",
                 field: "unitOfMeasureCode",
                 headerTooltip: "Unidad de Medida de Venta",
                 width: colWidth + 10,
                 cellClass: $rootScope.getClassForCell
             },
             {
                 headerName: "Tipo",
                 field: "priceTypeDesc",
                 width: 100,
                 filter: 'text',
                 cellClass: $rootScope.getClassForCell,
             },
             {
                 headerName: "Actual",
                 field: "price",
                 width: 100,
                 filter: 'number',
                 cellClass: $rootScope.getClassForCell,
                 cellRenderer: $rootScope.moneyRenderer
             },
             {
                 headerName: "Nuevo",
                 field: "newPrice",
                 width: 100,
                 filter: 'number',
                 cellClass: $rootScope.getClassForCell,
                 cellRenderer: $rootScope.moneyRenderer
             },
             {
                 headerName: "F.Desde",
                 field: "newValidFromExtended",
                 width: 90,
                 filter: 'number',
                 cellRenderer: $rootScope.dateRenderer
             },
             {
                 headerName: "Valido",
                 field: "isValid",
                 width: 75,
                 cellRenderer: {
                     renderer: 'group',
                     checkbox: false,
                     innerRenderer: function (params) {
                         //console.log('params', params);

                         if (params.data.isValid == undefined) {
                             return;
                         }

                         if (params.data.isValid == true) {
                             return '<img height="20" width="20" style="margin-top:-8px" src="assets/images/icons/icon_valid.png"/>';
                         }

                         if (params.data.isValid == false) {
                             return '<img height="20" width="20" title="' + params.data.errorMessage + '" style="margin-top:-8px" src="assets/images/icons/icon_invalid.png"/>';
                         }
                     }
                 }
             }
         ]
     }

     function setGridOptions() {

         setColumnDefinitions();

         self.gridOptions = {
             columnDefs: columnDefs,
             enableColResize: true,
             enableSorting: true,
             enableFilter: true,
             groupHeaders: true,
             groupKeys: undefined, //set as string of keys eg ["region","country"],
             groupHideGroupColumns: true,
             //groupUseEntireRow: false,
             groupSelectsChildren: false,
             rowHeight: 30,
             rowSelection: "multiple", // one of ['single','multiple'], leave blank for no selection
             singleClickEdit: true,
             icons: {
                 columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                 filter: '<i class="fa fa-filter"/>',
                 sortAscending: '<i class="fa fa-long-arrow-down"/>',
                 sortDescending: '<i class="fa fa-long-arrow-up"/>',
                 groupExpanded: '<i class="fa fa-minus-square-o"/>',
                 groupContracted: '<i class="fa fa-plus-square-o"/>',
                 columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                 columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
             },
             suppressColumnMoveAnimation: $rootScope.suppressColumnMoveAnimation,
             suppressCellSelection: true,
             enableRangeSelection: false,
             angularCompileRows: true,
             isExternalFilterPresent: isExternalFilterPresent,
             doesExternalFilterPass: doesExternalFilterPass,
             onGridSizeChanged: function (params) {
                 self.gridOptions.api.sizeColumnsToFit();
             }
         }
     }

     function isExternalFilterPresent() {
         return self.selectedFilterForPriceType != null;
     }
     function doesExternalFilterPass(node) {
         return node.data.priceTypeDesc == self.selectedFilterForPriceType;
     }

     function loadData() {

         if (self.gridOptions.api)
             self.gridOptions.api.showLoadingOverlay();

         priceService.priceOutput.getLastOutputReports()
            .then(function (reports) {
                self.generatedFiles = reports;


            });

         priceService.priceOutput.getStorePricesToInform()
            .then(function (pricesToInform) {

                angular.forEach(pricesToInform, function (price) {
                    price.isValid = undefined;
                });

                self.pricesToInform = pricesToInform;
                self.gridOptions.api.setRowData(pricesToInform);

                self.gridOptions.api.forEachNode(function (node) {
                    node.setSelected(node.data.isSelected);
                });

                self.gridOptions.api.sizeColumnsToFit();

            });


     }

     function init() {

         setGridOptions();

         loadData();
     }

     init();
 });

